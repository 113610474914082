import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { zip } from 'rxjs';
import { combineAll } from 'rxjs/operators';
import { MixpanelService } from '../../../../modules/mixpanel/mixpanel.service';
import { CustomPaService } from '../../../../services/rpa/rpa.custom-pa.service';
import { ClientManagementService } from '../../../../services/supervision/client-management.service';
import { DialogComponent } from '../../../dialog/dialog.component';
import { DuplicatePaDialogPayload } from '../duplicate.component';

@Component({
  selector: 'app-duplicate-to-controller',
  templateUrl: './to-controller.component.html',
  styleUrls: ['./to-controller.component.scss']
})
export class DuplicateToControllerComponent implements OnInit {
  paId: number;

  availableTargets: any[] = [];
  targets: number[] = [];

  pending = false;

  constructor(
    private clientManagement: ClientManagementService,
    private paService: CustomPaService,
    private dialogRef: MatDialogRef<DuplicateToControllerComponent>,
    private dialog: MatDialog,
    private translateService: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: DuplicatePaDialogPayload,
    private mixpanel: MixpanelService
  ) {
    this.paId = this.data.pa.paId;
    this.clientManagement.clients().subscribe(clients => {
      const currentController = parseInt(localStorage.getItem('currentUserControllerId'), 10);
      this.availableTargets = clients.filter(client => client._id !== currentController).sort((a, b) => a.controllerName?.localeCompare(b.controllerName) || 0);
    });
  }

  toggleTarget(event: any, controllerId: number) {
    if (event.checked) {
      this.targets.push(controllerId);
    } else {
      this.targets = this.targets.filter(id => id !== controllerId);
    }
  }

  ngOnInit(): void {
  }

  submit() {
    this.pending = true;

    this.mixpanel.track('supervision:duplicate_pa_across_controllers', {
      'Count': this.targets.length
    });

    zip(
      ...this.targets.map(target => this.paService.duplicateToController(this.paId, target))
    ).subscribe(
      (success) => {
        let message = null;
        const isSuccess = success.every(result => result.complete);
        if (!isSuccess) {
          message = this.translateService.instant('pa.duplicate.incompleteError');
        } else {
          message = this.translateService.instant('pa.duplicate.toController.success');
        }

        this.dialog.open(DialogComponent, { data: { message }, maxHeight: '90vh', width: '480px' })
            .afterClosed()
            .subscribe(() => {
              this.pending = false;
              this.dialogRef.close(isSuccess);
            });
      },
      (error) => {
        this.pending = false;
        const translatedMessage = this.translateService.instant('pa.duplicate.incompleteError');
          this.dialog.open(DialogComponent, { data: { message: translatedMessage }, maxHeight: '90vh', width: '480px' })
            .afterClosed()
            .subscribe(() => {
              this.dialogRef.close();
            });
      }
    );
  }

}

import { Injectable } from '@angular/core';

import { RequestService } from '../request.service';


export interface ChecklistItem {
  codename: string;
  text: string;
  type?: 'label' | 'item';
}


export type ChecklistValue = 'not-done' | 'done' | 'skipped';


export interface ChecklistData {
  [codename: string]: ChecklistValue;
}


export interface ChecklistResponse {
  items: ChecklistItem[];
  data: ChecklistData;
}


@Injectable()
export class ChecklistService {
  api = {
    root: '/checklists'
  };

  constructor(
    private client: RequestService
  ) {}

  public get(codename: string) {
    return this.client.get<ChecklistResponse>({
      uri: this.api.root + '/' + codename,
      handlers: {
        success: () => {},
        error: () => {},
      }
    });
  }

  public update(codename: string, data: ChecklistData) {
    return this.client.put({
      uri: this.api.root + '/' + codename,
      body: data,
      handlers: {
        success: () => {},
        error: () => {},
      }
    });
  }
}

<mat-dialog-content>
  {{ 'dsar.link-regen.hint' | translate }}
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-flat-button color="accent" mat-dialog-close>
    {{'word.cancel' | translate}}
  </button>
  <button mat-button color="warn" (click)="confirm()">
    {{ 'dsar.link-regen.actions.confirm' | translate }}
  </button>
</mat-dialog-actions>

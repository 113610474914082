import { Component, Injector, Input, OnDestroy, OnInit } from '@angular/core';
import {
  Audit,
  AuditConfiguration,
  AuditsService,
  Category,
  Question,
} from 'app/services/audits/audits.service';
import { Observable, Subject, Subscription } from 'rxjs';
import { debounceTime, switchMap, tap } from 'rxjs/operators';
import { AuditFieldState, fetchAuditLikeService } from '../../../util';

@Component({
  selector: 'app-audit-form-question-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
})
export class AuditFormQuestionInputComponent implements OnInit, OnDestroy {
  @Input() audit: Audit = null;
  @Input() question: Question = null;
  @Input() readonly = false;

  @Input() auditConfiguration: AuditConfiguration = null;

  @Input() path = '';
  @Input() nestLevel = 0;

  input: AuditFieldState<string>;

  constructor(private injector: Injector) {}

  get computedPath() {
    return `${this.path}`;
  }

  get childNestLevel() {
    return parseInt(this.nestLevel.toString(), 10) + 1;
  }

  ngOnInit() {
    const fullPath = `${this.path}_input`;
    const savedResponse = this.audit.answers[fullPath];
    const service = fetchAuditLikeService(
      this.auditConfiguration,
      this.injector
    );
    this.input = new AuditFieldState<string>(
      service,
      this.audit.id,
      'input',
      fullPath,
      savedResponse ? (savedResponse as any) : null
    );
  }

  ngOnDestroy() {
    this.input.clear();
  }
}

import { Node } from 'vis-network';

export interface ExtendedNode extends Node {
  ctxRenderer: any;
}

// eslint-disable-next-line no-shadow
export enum NodeType {
  ctrl = 'C',
  vendor = 'V',
  department = 'D',
}

export function encodeNode(id: number, type: NodeType) {
  if (type === NodeType.ctrl) {
    return type;
  }

  return type + id;
}

export function decodeNode(encoded) {
  const type = encoded[0] as NodeType;
  if (type === NodeType.ctrl) {
    return { type };
  }

  return {
    type,
    id: parseInt(encoded.substr(1), 10),
  };
}

<div class="vendor-picker">
  <div *ngIf="selectedVendor" (click)="showPicker()">
    <div class="vendor">
      <div class="avatar">
        <img
          *ngIf="selectedVendor.logoUrl"
          [src]="selectedVendor.logoUrl"
          onerror="this.onerror=null;this.src='/assets/default_vendor.svg';"
        />
        <mat-icon *ngIf="!selectedVendor.logoUrl" class="no-logo">web_asset</mat-icon>
      </div>

      <div class="info">
        <div class="title">
          {{ selectedVendor.name }}
        </div>
      </div>
    </div>
  </div>
  <button mat-stroked-button color="primary" (click)="showPicker()">{{ 'vendors.picker.choose' | translate }}</button>
</div>

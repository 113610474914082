import { NgModule, ModuleWithProviders } from '@angular/core';
import { Crisp } from './wrapper';
import { CrispService } from './crisp.service';

@NgModule({
  providers: [
    Crisp,
    CrispService
  ]
})
export class CrispModule {
  static forRoot(): ModuleWithProviders<CrispModule> {
    return {
      ngModule: CrispModule,
      providers: [
        CrispService,
      ]
    };
  }
}

<div class="container" (click)="pick()">
  <div class="label">
    {{placeholder}}
  </div>
  <div class="value">
    <ng-container *ngIf="node">
      {{node.title}}
    </ng-container>
    <ng-container *ngIf="!node">
      <i>
        {{'rpa.reference.tree.input.hint' | translate}}
        <!-- Click to select a node. -->
      </i>
    </ng-container>
  </div>
</div>
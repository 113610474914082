import { Injectable } from '@angular/core';

import {HttpErrorResponse} from '@angular/common/http';
import {BusService} from '../bus.service';
import {EventsService} from '../events.service';
import {
  IGetOptions,
  IPostOptions,
  RequestService
} from '../request.service';

interface IncidentsUpdateModel {
  id?: number;
  title?: string;
  description?: string;
  reportedOn?: Date;
  receivedOn?: Date;
  measuresTaken?: Date;
  dpoNotice?: Date;
  status?: string;
}

export type IncidentRecordable = Incident & { incidentId: number; recordableId: number; };

export interface Incident {
  id?: number;
  title?: string;
  details?: string;
  reportedOn?: Date;
  receivedOn?: Date;
  measuresTaken?: Date;
  dpoNotice?: Date;
  status?: string;
  customFields?: any;
}

export interface IncidentFormSettings {
  notifySupervisor: boolean;
  notifyDpo: boolean;
  notificationEmails: string[];
  formKey: string | null;
  formEnabled: boolean;
}

@Injectable()
export class IncidentsResourceService {

  api = {
    root: '/incidents',
    open: '/open',
    update: '/',
    create: '/',
    report: '/report',
    getAll: '/',
    getSingle: '/',
    delete: '/'
  };

  constructor(
    private bus: BusService,
    private events: EventsService,
    private requestService: RequestService
  ) {
    this.subscribe();
  }

  getAll() {
    return this.requestService.get<any>({
      uri: this.api.root + this.api.getAll,
      handlers: {
        success: documents => {
          this.bus.publish(this.events.received.data.incidents.all.success, documents);
        },
        error: error =>  {
          this.bus.publish(this.events.received.data.incidents.all.failure, error.error);
        },
      }
    });
  }

  getOpen() {
    return this.requestService.get<any>({
      uri: this.api.root + this.api.open,
      handlers: {
        success: () => {},
        error: () => {},
      }
    });
  }

  getSingle(id) {
    return this.requestService.get<any>({
      uri: this.api.root + this.api.getSingle + id,
      handlers: {
        success: documents => {
          this.bus.publish(this.events.received.data.incidents.single.success, documents);
        },
        error: error =>  {
          this.bus.publish(this.events.received.data.incidents.single.failure, error.error);
        },
      }
    });
  }

  create(model: IncidentsUpdateModel) {
    return this.requestService.post<IncidentsUpdateModel, { id: number; }>({
      uri: this.api.root + this.api.create,
      body: model,
      handlers: {
        success: response => {
          this.bus.publish(this.events.received.action.incidents.create.success, response);
        },
        error: error =>  {
          this.bus.publish(this.events.received.action.incidents.create.failure, error.error);
        },
      }
    });
  }

  report(key: string | null, incident: Incident) {
    if (!key) {
      return this.requestService.post<Incident, any>({
        uri: this.api.root + this.api.report + '/',
        body: incident
      });
    }

    return this.requestService.post<Incident, any>({
      uri: this.api.root + this.api.report + '/' + key,
      body: incident
    });
  }

  hasForm(key: string) {
    return this.requestService.get<any>({
      uri: this.api.root + this.api.report + '/' + key,
    });
  }

  getFormSettings() {
    return this.requestService.get<IncidentFormSettings>({
      uri: this.api.root + '/settings',
    });
  }

  updateFormSettings(settings: IncidentFormSettings) {
    return this.requestService.patch<IncidentFormSettings, any>({
      uri: this.api.root + '/settings',
      body: settings
    });
  }

  updateIncident(model: IncidentsUpdateModel) {
    return this.requestService.patch<IncidentsUpdateModel, any>({
      uri: this.api.root + this.api.update + model.id,
      body: model,
      handlers: {
        success: response => {
          this.bus.publish(this.events.received.action.incidents.update.success, response);
        },
        error: error =>  {
          this.bus.publish(this.events.received.action.incidents.update.failure, error.error);
        },
      }
    });
  }

  deleteIncident(id, deleteDocs) {
    return this.requestService.delete<any, any>({
      uri: this.api.root + this.api.delete + id,
      body: {
        deleteDocs,
      },
      handlers: {
        success: response => {
          this.bus.publish(this.events.received.action.incidents.delete.success, response);
        },
        error: error =>  {
          this.bus.publish(this.events.received.action.incidents.delete.failure, error.error);
        },
      }
    });
  }

  subscribe() {
    this.bus.subscribe(this.events.requested.action.incidents.create, this.create.bind(this));
    this.bus.subscribe(this.events.requested.action.incidents.update, this.updateIncident.bind(this));
    this.bus.subscribe(this.events.requested.action.incidents.delete, this.deleteIncident.bind(this));
    this.bus.subscribe(this.events.requested.data.incidents.all, this.getAll.bind(this));
    this.bus.subscribe(this.events.requested.data.incidents.single, this.getSingle.bind(this));
  }
}

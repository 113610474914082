import { Injectable } from '@angular/core';
import { IPostOptions, RequestService } from '../../request.service';
import { BusService } from '../../bus.service';
import { EventsService } from '../../events.service';
import { HttpErrorResponse } from '@angular/common/http';

interface IPostUpgradeRequest {
  newPlan: string;
}

interface IPostUpgradeResponse {
  any: any;
}

@Injectable()
export class PaymentUpgradePostService {
  private options: IPostOptions<IPostUpgradeRequest,
    IPostUpgradeResponse>;

  constructor(
    private bus: BusService,
    private events: EventsService,
    private requestService: RequestService
  ) {
    this.options = {
      uri: '/payment/upgrade',
      body: {
        newPlan: ''
      },
      handlers: {
        success: this.success.bind(this),
        error: this.error.bind(this)
      }
    };

    this.subscribe();
  }

  private subscribe(): void {
    this.bus.subscribe(this.events.requested.payment.upgrade.upgrade, this.request.bind(this));
  }

  public request(data: any) {
    this.bus.publish(this.events.notified.request.starting);
    this.options.body.newPlan = data.newPlan;

    this.requestService.post<IPostUpgradeRequest, IPostUpgradeResponse>(this.options);
  }

  public success(data: IPostUpgradeResponse) {
    this.bus.publish(this.events.received.payment.upgrade.upgrade.success, data);
  }

  public error(httpErrorResponse: HttpErrorResponse) {
    this.bus.publish(this.events.received.payment.upgrade.upgrade.failure, httpErrorResponse);
  }
}

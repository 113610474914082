import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SupervisorProfileService } from 'app/services/supervision/supervisor-profile.service';
import { ClientAccessService } from '../../../../services/supervision/client-access.service';
import { TwoFactorService } from '../../../../services/user/two-factor.service';
import { UserManagementService } from '../../../../services/user/user-management.service';
import { ConfirmSupervisorRemovalDialogComponent } from './confirm-supervisor-removal-dialog/confirm-supervisor-removal-dialog.component';

@Component({
  selector: 'app-edit-supervisor-dialog',
  templateUrl: './edit-supervisor-dialog.component.html',
  styleUrls: ['./edit-supervisor-dialog.component.scss']
})
export class EditSupervisorDialogComponent implements OnInit {

  user: any;
  readonly = false;
  isAdmin = false;

  twoFactorOrgRuleActive = false;
  twoFactorInfo: { setupDate: Date | null } | null = null;
  twoFactorInfoError = false;
  twoFactorPending = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) readonly data,
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<EditSupervisorDialogComponent>,
    private service: SupervisorProfileService,
    private clientAccessService: ClientAccessService,
    private twoFactor: TwoFactorService,
    private userManagement: UserManagementService
  ) {
    this.user = data.user;
    this.readonly = data.readonly;
    this.isAdmin = this.user.supervisorRole === 'admin';

    this.twoFactor.isRequired()
    .subscribe((required) => {
      this.twoFactorOrgRuleActive = required.required;

      this.userManagement.getTwoFactorInfo(this.user).subscribe((info) => {
        this.twoFactorInfo = info;
      }, (error) => {
        this.twoFactorInfo = null;
        this.twoFactorInfoError = true;
      });
    });
  }

  ngOnInit(): void {
  }

  remove() {
    this.dialog.open(ConfirmSupervisorRemovalDialogComponent, {
      width: '480px',
      maxHeight: '90vh',
      data: this.user
    }).afterClosed().subscribe(removed => {
      if (removed) {
        this.dialogRef.close();
      }
    });
  }

  updateRole() {
    this.service.setUserRole(this.user, this.isAdmin ? 'admin': 'supervisor');

    if (this.isAdmin && this.user.supervisorClientAccessMode !== 'all') {
      this.user.supervisorClientAccessMode = 'all';
      this.clientAccessService.setUserAccessMode(this.user.id, 'all').subscribe();
    }
  }

  disableTwoFactor() {
    this.twoFactorPending = true;
    this.userManagement.disableTwoFactor(this.user).subscribe(() => {
      this.twoFactorInfo = null;
      this.twoFactorInfoError = false;
      this.twoFactorPending = false;
    }, (error) => {
      this.twoFactorPending = false;
    });
  }

  setClientAccessMode(event) {
    this.clientAccessService.setUserAccessMode(this.user.id, event.checked ? 'all' : 'allow-list').subscribe();
  }
}

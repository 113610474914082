import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { EventsService } from '../../../../services/events.service';
import { BusService } from '../../../../services/bus.service';

import { VendorAddDialogComponent } from '../vendor-add-dialog/vendor-add-dialog.component';
import { VendorsService } from '../../../../services/vendors/vendors.service';


@Component({
  selector: 'app-vendor-search-dialog',
  templateUrl: './vendor-search-dialog.component.html',
  styleUrls: ['./vendor-search-dialog.component.scss']
})
export class VendorSearchDialogComponent implements OnInit, OnDestroy {

  _vendors = [];
  search = '';

  constructor(
    private bus: BusService,
    private events: EventsService,
    public dialogRef: MatDialogRef<VendorSearchDialogComponent>,
    private dialog: MatDialog,
    private vendorService: VendorsService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    const includeOther = !this.data || this.data.includeOther;
    this.vendorService.getByPopularity(includeOther).subscribe((vendors) => {
      this._vendors = vendors;
    });
  }

  ngOnDestroy() {
    // this.unsubscribe();
  }

  updateVendors(data) {
    this._vendors = data;
  }

  newVendor() {
    this.dialog.open(VendorAddDialogComponent, {
      width: '480px',
      maxHeight: '90vh'
    }).afterClosed().subscribe(vendor => {
      if (vendor) {
        this.dialogRef.close(Object.assign(vendor, {added : true}));
      }
    });
  }

  get vendors() {
    if (this.search) {
      const search = this.search.toLowerCase();
      return this._vendors.filter(vendor => vendor.name.toLowerCase().indexOf(search) !== -1);
    }

    return this._vendors;
  }

  subscribe() {
    this.bus.subscribe(this.events.received.data.vendors.get.success, this.updateVendors, this);
  }

  unsubscribe() {
    this.bus.unsubscribe(this.events.received.data.vendors.get.success, this.updateVendors);
  }
}

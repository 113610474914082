import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { TwoFactorService } from '../../../../services/user/two-factor.service';

@Component({
  selector: 'app-disable-two-factor',
  templateUrl: './disable.component.html',
  styleUrls: ['./disable.component.scss']
})
export class DisableTwoFactorComponent implements OnInit {

  enabled = false;
  requiredByOrg = false;

  code = new FormControl('', [Validators.required]);

  disabled: 'none' | 'pending' | 'success' | 'error' = 'none';

  constructor(
    private twoFactor: TwoFactorService
  ) {
    this.twoFactor.getInfo()
      .subscribe((info) => {
        if (info !== null) {
          this.enabled = true;
        }
      });

    this.twoFactor.isRequired()
      .subscribe((required) => {
        this.requiredByOrg = required.required;
      });
  }

  ngOnInit(): void {
  }

  disable() {
    if (!this.code.valid) {
      this.code.markAsTouched();
      return;
    }

    this.disabled = 'pending';

    this.twoFactor.disable(this.code.value)
      .subscribe((success) => {
        this.disabled = 'success';
        if (this.requiredByOrg) {
          this.twoFactor.lockForSetup();
        }
      }, (error) => {
        this.disabled = 'error';
        console.error(error);
        this.code.setErrors({ wrongCode: true });
      });
  }

}

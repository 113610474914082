import { Injectable } from '@angular/core';
import { RequestService } from '../request.service';

export interface ClientAccess {
  supervisorId: number;
  clientId: number;
  accessMode: ClientAccessMode;
  givenAt: Date;
}

type ClientAccessMode = 'allow' | 'deny';

type AccessMode = 'all' | 'allow-list';

@Injectable({
  providedIn: 'root'
})
export class ClientAccessService {

  constructor(
    private requestService: RequestService
  ) { }

  getAccessForClient(clientId: number) {
    return this.requestService.get<ClientAccess[]>({
      uri: `/supervision/access/${clientId}`,
    });
  }

  setUserAccessMode(supervisorId: number, mode: AccessMode) {
    return this.requestService.post<{ supervisorId: number; mode: AccessMode}, any>({
      uri: `/supervision/access/access-mode`,
      body: {
        supervisorId,
        mode
      }
    });
  }

  setClientAccess(supervisorId: number, clientId: number, accessMode: ClientAccessMode) {
    return this.requestService.post<{ supervisorId: number; clientId: number, accessMode: ClientAccessMode }, any>({
      uri: `/supervision/access`,
      body: {
        supervisorId,
        clientId,
        accessMode
      }
    });
  }

  deleteClientAccess(supervisorId: number, clientId: number) {
    return this.requestService.delete<{ supervisorId: number; clientId: number }, any>({
      uri: `/supervision/access`,
      body: {
        supervisorId,
        clientId
      }
    });
  }
}

<div class="content design-{{ design }}" [ngClass]="{'centered': align == 'center', 'left': align == 'left'}">
  <mat-form-field *ngIf="searchable && source && source.length > 0" appearance="standard" class="search" [ngClass]="{'filtered': filter}">
    <input matInput placeholder="{{ 'documents.miscList.search.placeholder' | translate }}"
      [(ngModel)]="filter"
      type="text"/>
      <button *ngIf="!filter" mat-icon-button class="search-button" matSuffix>
        <mat-icon>search</mat-icon>
      </button>
      <button *ngIf="filter" mat-icon-button class="clear-button" matSuffix (click)="resetFilter()">
        <mat-icon>close</mat-icon>
      </button>
  </mat-form-field>

  <div *ngIf="searchable && source && source.length > 0" class="tag-list filter-list">
    <div class="filtered" *ngIf="filter && isTagFilter">
      <button mat-icon-button (click)="resetFilter()">
        <mat-icon>close</mat-icon>
      </button>
      <div class="tag-item" [ngClass]="{ 'untagged': isFilteredUntagged }">
        {{filter}}
      </div>
    </div>
    <div class="not-filtered" *ngIf="design === 'default' && (!filter || !isTagFilter)">
      <div class="tag-item" *ngFor="let tag of tags" (click)="filterTags(tag)">
        {{ tag }}
      </div>
      <div class="tag-item untagged" (click)="filterUntagged()">
        {{ 'documents.taglist.untagged' | translate }}
      </div>
    </div>
  </div>

  <!-- default theme -->
  <div *ngIf="design === 'default'" class="document-list" [class.searchable]="searchable">
    <mat-card *ngFor="let document of documents" (click)="primaryAction(document)" [class.shared]="document.shared" [class.highlighted]="document.id == highlightedDocument" [class.has-tags]="hasTags(document) && searchable" [class.readonly]="readonly">
      <img *ngIf="!document.shared" mat-card-image [src]="docImage(document)"/>
      <div commentable
      comment-subject-codename="document-{{document.id}}"
      comment-subject-title="Document {{document.fileName}}">
        <mat-card-title>
          {{ document.fileName }}
        </mat-card-title>
      </div>
      <mat-card-content>
        <todoable subject="document-{{document.id}}" suggested-title="Document {{document.fileName}}" position="documents" (click)="$event.stopPropagation()"></todoable>

        <div *ngIf="document.shared" class="supervisor-message">
          <p class="message-title">{{ 'documents.shared.message.title' | translate }}</p>
          <p *ngIf="document.message">{{ document.message }}</p>
          <p *ngIf="!document.message" class="no-message">{{ 'documents.shared.message.noMessage' | translate }}</p>
        </div>

        <div class="file-info">
            <b *ngIf="document.extension">{{document.extension}}</b><br *ngIf="design === 'default'">
            {{'documents.miscList.uploadedBy.part1' | translate}}
            {{ uploaderName(document) }}
            {{'documents.miscList.uploadedBy.part2' | translate}}
            <br *ngIf="design === 'default'">
            {{'documents.miscList.uploadedAt.part1' | translate}}
            {{ document.uploadedAt | localizedDate }}{{'documents.miscList.uploadedAt.part2' | translate}}
        </div>
        <div *ngIf="searchable" class="card-tags-list">
          <div *ngIf="document.tags.length > 0" class="tag-list">
            <span class="tag-item" [title]="tag.value" *ngFor="let tag of limitTags(document.tags)">{{ tag.value }}</span>
            <span class="tag-item tag-more"
                  *ngIf="document.tags.length > 2"
                  matTooltip="{{ 'documents.miscList.document.action.tags' | translate }}"
                  (click)="$event.stopPropagation(); showTags(document)">...</span>
          </div>
        </div>
      </mat-card-content>

      <mat-card-actions align="end">
        <a mat-icon-button
          matTooltip="{{ 'documents.miscList.document.action.download' | translate }}"
          type="button"
          (click)="downloadAction($event, document)"
          href="{{ downloadLink(document) }}"
          target="_blank">
          <mat-icon color="accent">cloud_download</mat-icon>
        </a>
        <ng-container *ngIf="isInSupervisionMode">
          <button mat-icon-button
            *ngIf="!readonly && accessToSharedDocuments"
            matTooltip="{{ 'documents.share' | translate }}"
            (click)="shareDocument(document, $event)">
            <mat-icon color="accent">forward_to_inbox</mat-icon>
          </button>
        </ng-container>
        <button mat-icon-button
          *ngIf="!readonly"
          matTooltip="{{ 'word.edit' | translate }}"
          (click)="editDocument(document, $event)">
          <mat-icon color="accent">edit</mat-icon>
        </button>
        <button mat-icon-button
          *ngIf="!readonly || (document.shared && isInSupervisionMode)"
          matTooltip="{{ 'word.delete' | translate }}"
          (click)="deleteDocument(document, $event)">
          <mat-icon color="accent">delete_outline</mat-icon>
        </button>
      </mat-card-actions>
    </mat-card>

    <mat-card *ngIf="!readonly && writeEnabled && accessWriteEnabled" class="upload-file" (click)="addDocument()">
      <mat-card-content>
        <div class="inner">
          <mat-icon>cloud_upload</mat-icon>
          <div>
            <ng-container *ngIf="!uploadButtonText">
              {{ 'documents.miscList.upload' | translate }}
            </ng-container>
            <ng-container *ngIf="uploadButtonText">
              {{ uploadButtonText }}
            </ng-container>
          </div>
        </div>
      </mat-card-content>
    </mat-card>

    <mat-card *ngIf="!writeEnabled" class="upload-file locked">
      <mat-card-content>
        <div class="inner">
          <mat-icon>cloud_off</mat-icon>
          <div>
            {{ 'feature.locked.explanation.document.upload' | translate }}
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>

  <!-- fluid theme (list) -->
  <div *ngIf="design === 'fluid'" [class.with-path]="showPath" class="fluid-container fluid">
    <div *ngIf="showPath" class="folder-tree">
      <app-path-tree [(path)]="selectedPath" [treeSource]="folderPaths" [readonly]="true"></app-path-tree>
    </div>
    <div class="document-list fluid" [class.searchable]="searchable">
      <div *ngIf="!readonly && writeEnabled && accessWriteEnabled" class="file-item upload-file" (click)="addDocument()">
        <mat-icon>cloud_upload</mat-icon>
        <div>
          <ng-container *ngIf="!uploadButtonText">
            {{ 'documents.miscList.upload' | translate }}
          </ng-container>
          <ng-container *ngIf="uploadButtonText">
            {{ uploadButtonText }}
          </ng-container>
        </div>
      </div>

      <div *ngFor="let document of documents" class="file-item" (click)="primaryAction(document)" [class.shared]="document.shared" [class.highlighted]="document.id == highlightedDocument" [class.has-tags]="hasTags(document) && searchable" [class.readonly]="readonly">
        <div commentable
          comment-subject-codename="document-{{document.id}}"
          comment-subject-title="Document {{document.fileName}}">
          <todoable subject="document-{{document.id}}" suggested-title="Document {{document.fileName}}" position="documents-fluid" (click)="$event.stopPropagation()"></todoable>
          <div class="file-inner">
            <div class="file-info">
              <div class="name" title="{{ document.fileName }}">
                {{ document.fileName }}

                <span class="upload-date" *ngIf="document.shared && document.message">- {{ 'documents.shareWithClient.dialog.sharedOn' | translate }} {{ (document.sharedAt || document.uploadedAt) | localizedDate }}</span>
              </div>

              <div *ngIf="document.shared && document.message" class="document-subtitle supervisor-message">
                <p class="message" title="{{ document.message }}">{{ document.message }}</p>
              </div>

              <div *ngIf="document.shared && !document.message" class="document-subtitle">
                <p class="message">{{ 'documents.shareWithClient.dialog.sharedOn' | translate }} {{ (document.sharedAt || document.uploadedAt) | localizedDate }}</p>
              </div>

              <div *ngIf="!document.shared" class="document-subtitle">
                <p class="message">{{'documents.miscList.uploadedAt.part1' | translate}} {{ document.uploadedAt | localizedDate }}</p>
              </div>
            </div>

            <div class="actions">
              <a mat-icon-button *ngIf="canDownload"
                matTooltip="{{ 'documents.miscList.document.action.download' | translate }}"
                type="button"
                (click)="downloadAction($event, document)"
                href="{{ downloadLink(document) }}"
                target="_blank">
                <mat-icon color="accent">cloud_download</mat-icon>
              </a>
              <ng-container *ngIf="isInSupervisionMode">
                <button mat-icon-button
                  *ngIf="!readonly && accessToSharedDocuments"
                  matTooltip="{{ 'documents.share' | translate }}"
                  (click)="shareDocument(document, $event)">
                  <mat-icon color="accent">forward_to_inbox</mat-icon>
                </button>
              </ng-container>
              <button mat-icon-button
                *ngIf="!readonly"
                matTooltip="{{ 'word.edit' | translate }}"
                (click)="editDocument(document, $event)">
                <mat-icon color="accent">edit</mat-icon>
              </button>
              <button mat-icon-button
                *ngIf="!readonly || (document.shared && isInSupervisionMode)"
                matTooltip="{{ 'word.delete' | translate }}"
                (click)="deleteDocument(document, $event)">
                <mat-icon color="accent">delete_outline</mat-icon>
              </button>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="emptyHint && documents.length === 0" class="empty">
        <p>{{ emptyHint }}</p>
      </div>
    </div>
  </div>
</div>

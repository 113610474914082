import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { BusService } from '../../../services/bus.service';
import { EventsService } from '../../../services/events.service';

import { CopiedDialogComponent } from './copied-dialog/copied-dialog.component';
import { BadgeService } from '../../../services/badge/badge.service';
import { UserService } from '../../../services/user/user.service';
import { MixpanelService } from '../../../modules/mixpanel/mixpanel.service';


@Component({
  selector: 'app-get-badge',
  templateUrl: './get-badge.component.html',
  styleUrls: ['./get-badge.component.scss'],
})
export class GetBadgeComponent implements OnInit {

  imageUrl: string;
  infoUrl: string;
  error: any;

  constructor(
    private dialog: MatDialog,
    private bus: BusService,
    private events: EventsService,
    private badgeService: BadgeService,
    private userService: UserService,
    private mixPanel: MixpanelService,
  ) {
    this.bus.publish(this.events.requested.data.badge.get, this.userService.resolveUserJurisdiction());
  }

  ngOnInit() {
    this.subscribe();
    this.mixPanel.track('badge:getBadge');
  }

  subscribe() {
    this.bus.subscribe(this.events.received.data.badge.get.success, this.prepareInfoUrl, this);
  }

  copied() {
    this.dialog.open(CopiedDialogComponent, {
      width: '360px',
      maxHeight: '90vh',
      panelClass: 'copied-dialog',
    });
  }

  copy(element) {
    if ((document as any).selection) {
      const range = (document.body as any).createTextRange();
      range.moveToElementText(element);
      range.select().createTextRange();
      document.execCommand('copy');
      (document as any).selection.empty();
      this.copied();
    } else if (window.getSelection) {
      const range = document.createRange();
      range.selectNode(element);
      window.getSelection().addRange(range);
      document.execCommand('copy');
      window.getSelection().removeAllRanges();
      this.copied();
    }
  }

  badge(): string {
    return `assets/badge/${this.userService.resolveUserJurisdiction()}-gold.png`;
  }

  prepareInfoUrl(response) {
    this.infoUrl = response.infoUrl;
  }

  visitPage() {
    window.open(this.infoUrl, '_blank');
  }

  async downloadBadge() {
    const imageLink = document.createElement('a');
    imageLink.href = this.badge();
    imageLink.download = `ecomply_badge_${this.userService.resolveUserJurisdiction()}.png`;
    document.body.appendChild(imageLink).click();
    document.body.removeChild(imageLink);
  }
}

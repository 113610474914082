import { Injectable } from '@angular/core';
import { AppConfig } from '../../app.config';
import { getToken } from '../../util/token';
import { RequestService } from '../request.service';

type DpiaStatus = 'open' | 'submitted' | 'not-allowed' | 'approved';
type DpiaMeasureStatus = 'planned' | 'in-progress' | 'finished';

type DpiaData = any;

export interface DpiaModel {
  paId: number;
  status: DpiaStatus;
  lastStatusChange: Date;
  data: DpiaData;
  version: 'v1';
  creatorId: number;
}

export interface DpiaRisk {
  id: number;
  paId: number;
  riskSource: string;
  riskType: string;
  name: string;
  impact: number;
  probability: number;
  remediedOverallRisk: number;
  currentOverallRisk: number;
  creatorId: number;
  createdAt: Date;
  lastUpdated: Date;
  overallRiskLastUpdate: Date;
}

export interface DpiaMeasure {
  id: number;
  paId: number;
  riskId: number;
  name: string;
  status: DpiaMeasureStatus;
  creatorId: number;
  createdAt: Date;
  lastUpdated: Date;
  statusLastUpdate: Date;
}

interface Translatable {
  id: string;
  display: {
    de: string;
    en: string;
    'en-NG': string;
    pt: string;
    'pt-BR': string;
  };
}

export type RiskSource = Translatable & { risk_type_suggestions: string[]; };
export type RiskType = Translatable;
export type RiskExample = Translatable;

interface MeasureDisplay {
  measureTitle: string;
}

export interface MeasureExample {
  layer: string;
  sdmMeasureId: string;
  category: string;
  display: {
    de: MeasureDisplay;
    en: MeasureDisplay;
    'en-NG': MeasureDisplay;
    pt: MeasureDisplay;
    'pt-BR': MeasureDisplay;
  };
  measureTitle: string;
  P: boolean;
  D: boolean;
  C: boolean;
  A: boolean;
  availability: boolean;
  integrity: boolean;
  confidentialty: boolean;
  dataMinimisation: boolean;
  transparency: boolean;
  unlinkability: boolean;
  intervenability: boolean;
}

export interface RiskResources {
  risk_sources: RiskSource[];
  risk_types: RiskType[];
  risk_examples: RiskExample[];
}

@Injectable({
  providedIn: 'root'
})
export class DpiaService {

  constructor(
    private requestService: RequestService
  ) { }

  get(paId: number) {
    return this.requestService.get<DpiaModel>({
      uri: `/dpia/${paId}`,
    });
  }

  getMatrices(paId: number) {
    return this.requestService.get<{ before: string; current: string; after: string; }>({
      uri: `/dpia/${paId}/matrices`,
    });
  }

  getMatrixUrl(paId: number, name: 'before' | 'current' | 'after') {
    return `${AppConfig.apiUrl}/dpia/${paId}/matrices/${name}?token=${getToken()}&lang=${localStorage.getItem('lang')}`;
  }

  update(dpia: DpiaModel) {
    return this.requestService.put<{ dpia: DpiaModel }, DpiaModel>({
      uri: `/dpia/${dpia.paId}`,
      body: { dpia }
    });
  }

  create(paId: number) {
    return this.requestService.post<{}, DpiaModel>({
      uri: `/dpia/${paId}`,
      body: {}
    });
  }

  getRiskResources() {
    return this.requestService.get<RiskResources>({
      uri: `/dpia/risk-resources`,
    });
  }

  getMeasureExamples() {
    return this.requestService.get<MeasureExample[]>({
      uri: `/dpia/measure-examples`,
    });
  }

  getRisks(paId: number) {
    return this.requestService.get<DpiaRisk[]>({
      uri: `/dpia/${paId}/risks`,
    });
  }

  createRisk(paId: number, risk: Pick<DpiaRisk, 'name' | 'riskSource' | 'riskType'>) {
    return this.requestService.post<{ risk: Pick<DpiaRisk, 'name' | 'riskSource' | 'riskType'> }, DpiaRisk>({
      uri: `/dpia/${paId}/risks`,
      body: { risk }
    });
  }

  updateRisk(paId: number, risk: DpiaRisk) {
    return this.requestService.put<{ risk: DpiaRisk }, DpiaRisk>({
      uri: `/dpia/${paId}/risks/${risk.id}`,
      body: { risk }
    });
  }

  getMeasures(paId: number) {
    return this.requestService.get<DpiaMeasure[]>({
      uri: `/dpia/${paId}/measures`,
    });
  }

  createMeasure(paId: number, measure: Pick<DpiaMeasure, 'name' | 'riskId' | 'status'>) {
    return this.requestService.post<{ measure: Pick<DpiaMeasure, 'name' | 'riskId' | 'status'> }, DpiaMeasure>({
      uri: `/dpia/${paId}/measures`,
      body: { measure }
    });
  }

  updateMeasure(paId: number, measure: DpiaMeasure) {
    return this.requestService.put<{ measure: DpiaMeasure }, DpiaMeasure>({
      uri: `/dpia/${paId}/measures/${measure.id}`,
      body: { measure }
    });
  }

  deleteMeasure(paId: number, measureId) {
    return this.requestService.delete<any, any>({
      uri: `/dpia/${paId}/measures/${measureId}`,
      body: { }
    });
  }

  getReportLink(paId: number) {
    return `${AppConfig.apiUrl}/dpia/${paId}/report?token=${getToken()}&lang=${localStorage.getItem('lang')}`;
  }
}

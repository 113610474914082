import { HttpClient, HttpEvent, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, last, tap } from 'rxjs/operators';
import { AppConfig } from '../../app.config';
import { RequestService } from '../request.service';
import { UploadService } from '../upload.service';
import { Document } from './document-resource.service';

export type SharedDocument = Document & DocumentShare;
interface DocumentShare {
  basedOn: number;
  sharedBy: number;
  sharedAt: Date;
  message: string;
  shared: boolean;
};

@Injectable({
  providedIn: 'root'
})
export class SharedDocumentsService {

  constructor(
    private requestService: RequestService,
    private http: HttpClient,
    private uploadService: UploadService
  ) { }

  shareExistingDocument(documentId: number, fileName: string, message: string) {
    return this.requestService.post<{ message: string; fileName: string; documentId: number }, DocumentShare>({
      uri: '/documents/shared/existing',
      body: {documentId, fileName, message}
    });
  }

  getSharedDocuments() {
    return this.requestService.get<SharedDocument[]>({
      uri: '/documents/shared'
    });
  }

  getSharesForDocument(documentId: number) {
    return this.requestService.get<SharedDocument[]>({
      uri: `/documents/shared/${documentId}`
    });
  }

  deleteSharedDocument(shareId: number) {
    return this.requestService.delete<any, any>({
      uri: `/documents/shared/existing/${shareId}`
    });
  }

  public uploadBlobForSharedDocument(
    blob: Blob,
    filename: string,
    progressHandler?: (loaded: number, total: number) => any
  ): Observable<HttpEvent<any>> {
    const formData = new FormData();
    formData.append('document', new File([blob], filename));

    const req = new HttpRequest('POST', AppConfig.apiUrl + '/documents/shared/upload', formData, {
      reportProgress: true,
    });

    return this.http.request(req).pipe(
      tap(event => this.uploadService.handleEvent(event, progressHandler)),
      last(),
      catchError(this.uploadService.handleError),
    ) as Observable<HttpEvent<any>>;
  }
}

import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { BusService } from '../../../services/bus.service';
import { EventsService } from '../../../services/events.service';
import { AuthenticationService } from '../../../services/authentication.service';
import { ClientSupervisionService } from 'app/services/supervision/client-supervision.service';
import { DeleteUserDialogComponent } from '../delete-user-dialog/delete-user-dialog.component';

import { DialogComponent } from 'app/components/dialog/dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { zip } from 'rxjs';
import { AccessLevelService } from '../../../services/user/access-level.service';
import { TwoFactorService } from '../../../services/user/two-factor.service';
import { UserManagementService } from '../../../services/user/user-management.service';


@Component({
  selector: 'app-edit-user-dialog',
  templateUrl: './edit-user-dialog.component.html',
  styleUrls: ['./edit-user-dialog.component.scss']
})
export class EditUserDialogComponent implements OnInit {

  user: any;
  departments = [];
  accessLevel = {
    read: true,
    write: false,
  };

  twoFactorOrgRuleActive = false;
  twoFactorInfo: { setupDate: Date | null } | null = null;
  twoFactorInfoError = false;
  twoFactorPending = false;

  _currentUserEmail: string = '';

  roles: string[] = [];

  constructor(
    private bus: BusService,
    private events: EventsService,
    public auth: AuthenticationService,
    private supervision: ClientSupervisionService,
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<EditUserDialogComponent>,
    private translate: TranslateService,
    private accessService: AccessLevelService,
    private twoFactor: TwoFactorService,
    private userManagement: UserManagementService,
    @Inject(MAT_DIALOG_DATA) private data: any,
  ) {
    this.user = data.user;
    this.departments = data.departments;

    this.accessService.checkAccess({
      context: 'user-management',
    }).subscribe(response => this.accessLevel = response);

    this.accessService.availableRoles()
      .subscribe((roles) => {
        this.roles = roles;
      });

    this._currentUserEmail = (localStorage.getItem('isInSupervisionMode') === 'true') ? localStorage.getItem('currentUserEmail') : '';

    this.twoFactor.isRequired()
      .subscribe((required) => {
        this.twoFactorOrgRuleActive = required.required;

        this.userManagement.getTwoFactorInfo(this.user).subscribe((info) => {
          this.twoFactorInfo = info;
        }, (error) => {
          this.twoFactorInfo = null;
          this.twoFactorInfoError = true;
        });
      });
  }

  updateUser() {
    this.bus.publish(this.events.requested.action.controller.users.update, this.user);
  }

  isSupervisor() {
    return this.supervision.isInSupervisionMode;
  }

  openDeleteDialog() {

    if(this._currentUserEmail === this.user.email) {
      let $title = this.translate.get('word.WARNING');
      let $description = this.translate.get('user-management.delete.self-delete.error');

      return zip(
        $title,
        $description
      )
      .subscribe(([title, description]) => {
        this.dialog.open(DialogComponent, {
          width: 'auto',
          data: {
            header: title,
            message: description
          }
        });
      });
    }

    this.dialog.open(DeleteUserDialogComponent, {
      data: this.user,
      width: '500px'
    }).afterClosed().subscribe(deleted => {
      if (deleted) {
        this.bus.publish(this.events.requested.data.controller.users);
        this.dialogRef.close(true);
      }
    });
  }

  disableTwoFactor() {
    this.twoFactorPending = true;

    this.userManagement.disableTwoFactor(this.user).subscribe(() => {
      this.twoFactorInfo = null;
      this.twoFactorInfoError = false;
      this.twoFactorPending = false;
    }, (error) => {
      this.twoFactorPending = false;
    });
  }

  canDelete() {
    return (this.isSupervisor() ||
      (this.accessLevel.write && this.user.email != this.auth.email))
      && this.user.supervisorRole !== 'admin';
  }

  ngOnInit() {}
}

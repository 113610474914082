import { Injectable } from '@angular/core';
import {RequestService} from "./request.service";

export interface Authority {
  controllerId: number;
  name: string;
  contactName: string;
  addressStreet: string;
  addressPostcode: string;
  addressCity: string;
  addressCountry: string;
  email: string;
  phone: string;
}

@Injectable({
  providedIn: 'root'
})
export class AuthorityService {

  constructor(
    private request: RequestService
  ) { }

  getAuthority() {
    return this.request.get<Authority>({
      uri: '/authority'
    });
  }

  updateAuthority(authority: Authority) {
    return this.request.patch<Authority, Authority>({
      uri: '/authority',
      body: authority
    });
  }
}

import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-additional-authentication-dialog',
  templateUrl: './additional-authentication-dialog.component.html',
  styleUrls: ['./additional-authentication-dialog.component.scss']
})
export class AdditionalAuthenticationDialogComponent implements OnInit {

  public queryParams = {};

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    public ref: MatDialogRef<AdditionalAuthenticationDialogComponent>,
  ) {
    if (data.reason == 'interrupted-confirmation') {
      this.queryParams = {
        next: '/payment/finalize',
        intent: 're-enter',
      }
    }
    else {
      this.queryParams = {
        next: '/payment/finalize'
      }
    }
  }

  ngOnInit() {
  }
}

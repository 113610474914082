import { Component, OnInit } from '@angular/core';
import { WorkflowTasksService } from 'app/services/workflow-tasks/workflow-tasks.service';


@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'audit-prepare',
  templateUrl: './prepare.component.html',
  styleUrls: ['./prepare.component.scss']
})
export class AuditPrepareComponent implements OnInit {

  constructor(
    private tasks: WorkflowTasksService,
  ) { }

  ngOnInit(): void {
  }

  updateProgress(progress) {
    this.tasks.progressControllerTask({
      progress,
      workflowCodename: 'audit',
      taskCodename: 'ad_prepare_audit'
    });
  }
}

import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { CustomPaService } from 'app/services/rpa/rpa.custom-pa.service';
import { RpaReferenceService, TreeNode } from 'app/services/rpa/rpa.reference.service';
import { zip } from 'rxjs';

@Component({
  selector: 'app-new-pa-dialog',
  templateUrl: './new-pa-dialog.component.html',
  styleUrls: ['./new-pa-dialog.component.scss']
})
export class NewPaDialogComponent implements OnInit {

  referencePas: any[];
  tree: TreeNode[];
  freePas: any[] = [];

  constructor(
    private service: RpaReferenceService,
    private paService: CustomPaService,
    private ref: MatDialogRef<NewPaDialogComponent>,
  ) { }

  ngOnInit(): void {
    zip(
      this.service.getReferencePAs(),
      this.service.getTree(),
    )
    .subscribe(([pas, tree]) => {
      this.referencePas = pas;
      this.tree = tree;

      this.referencePas.forEach(pa => {
        if (pa.treeNode) {
          const node = this.service.findNode(pa.treeNode, tree);
          if (node) {
            node.pas = node.pas || [];
            node.pas.push(pa);
            return;
          }
        }

        this.freePas.push(pa);
      });
    });
  }

  create(pa: any) {
    this.paService.duplicate(pa.pa_id, {
      name: pa.pa_name,
      referenceController: pa.controller_id,
      options: {
        includeExternalProcessors: true,
        includeInternalProcessors: false,
      }
    }).subscribe(() => {
      this.ref.close(true);
    });
  }

  toggle(pa: any) {
    pa.expanded = !pa.expanded;
  }
}

import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Todo } from '../todo.interface';
import { TodoService } from '../../../services/todos/todo.service';
import { TodoDialogComponent } from '../todo-dialog/todo-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { BusService } from '../../../services/bus.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-todo-card',
  templateUrl: './todo-card.component.html',
  styleUrls: ['./todo-card.component.scss']
})
export class TodoCardComponent implements OnInit {

  @Input() dataIn: Todo;
  @Input() readonly = false;
  @Output() todoChange = new EventEmitter<Todo>();

  todo: Todo;
  currentUserId = localStorage.getItem('currentUserID');

  constructor(
    private todoService: TodoService,
    private dialog: MatDialog,
    private bus: BusService,
    private translate: TranslateService,
  ) { }

  ngOnInit(): void {
    this.todo = this.dataIn;
  }

  markDone(event) {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }

    this.todoService.markTodoAsDone(this.todo.id).subscribe(() => {
      this.todoChange.emit(this.todo);
      this.bus.publish('todo.update.event');

      // when a todo is marked as done send an event through the update todo bus
      this.todo.done = true;
    });
  }

  reopen(event) {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }

    this.todoService.reopenTodo(this.todo.id).subscribe(() => {
      this.todoChange.emit(this.todo);
      this.bus.publish('todo.update.event');

      // when a todo is marked as reopened send an event through the update todo bus
      this.todo.done = false;
    });
  }

  openTodo(event) {
    if(event) {
      event.stopPropagation();
    }
    this.dialog.open(TodoDialogComponent, {
      width: '500px',
      maxHeight: '90vh',
      data: this.todo
    }).afterClosed().subscribe(mutated => {
      // if (mutated) {
        this.todoChange.emit(this.todo);
      // }
    });
  }

  /**
   * Transformer for the todo description
   */
  todoDescription(): string {
    if (this.todo.description.length > 200) {
      return this.todo.description.slice(0, 200) + ' ...';
    }
    return this.todo.description;
  }

  /**
   * This will use only userId to test if whether the current user is the assignee or issuer.
   * Later we need a way to find if "supervisor / admin" then they can modify
   */
  canEdit(): boolean {
    return this.todo.assigneeId.toString() === this.currentUserId ||
      this.todo.issuerId.toString() === this.currentUserId;
  }

  get now() {
    return new Date();
  }

  get assignedToSelf() {
    return this.todo.assigneeId.toString() === this.currentUserId;
  }

  get issuedBySelf() {
    return this.todo.issuerId.toString() === this.currentUserId;
  }

  get title() {
    const title = this.translate.instant(this.todo.title);
    return title ?? this.todo.title;
  }

  get description() {
    let description = null;
    if (this.todo.description) {
      description = this.translate.instant(this.todo.description, {
        name: this.todo.subject
      });
    }
    return description ?? this.todo.description;
  }
}

import { Component, OnInit, OnDestroy, AfterViewInit, Inject, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { AppConfig } from '../../../app.config';
import { BusService } from '../../../services/bus.service';
import { EventsService } from '../../../services/events.service';

import { Router } from '@angular/router';
import { HttpEvent, HttpResponse } from '@angular/common/http';
import { DocumentResourceService } from '../../../services/documents/document-resource.service';
import { UserManagementService } from 'app/services/user/user-management.service';
import { getToken } from '../../../util/token';

@Component({
  selector: 'app-documents-upload-dialog',
  templateUrl: './documents-upload-dialog.component.html',
  styleUrls: ['./documents-upload-dialog.component.scss']
})
export class DocumentsUploadDialogComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('upload') upload;

  appConfig = AppConfig;

  fileName = new FormControl('', []);
  tags: Array<string> = [];
  folderPath: string[] = [];
  treeSource: string[][] = [];
  showPath = true;

  documentId: number = undefined;

  isExistingDocument = false;

  writeEnabled = true;

  users = [];
  uploadKey = 'document';

  constructor(
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<DocumentsUploadDialogComponent>,
    private bus: BusService,
    private events: EventsService,
    private router: Router,
    private userService: UserManagementService,
    private docService: DocumentResourceService,
    @Inject(MAT_DIALOG_DATA) private data: any
  ) {
    this.setExistingData();

    if (this.data && this.data.uploadKey) {
      this.uploadKey = this.data.uploadKey;
    }
  }

  setExistingData() {
    if (this.data && this.data.isExistingDocument) {
      this.fileName.setValue(this.data.document.fileName);
      this.folderPath = this.data.document.folderPath || [];
      this.tags = this.data.document.tags;
      this.isExistingDocument = true;
      this.documentId = this.data.document.id;
      this.writeEnabled = this.data.writeEnabled;
    }
    if (this.data) {
      this.treeSource = this.data.treeSource;
      this.folderPath = this.data.folderPath || this.data.document?.folderPath || [];
      if (this.data.pathEnabled !== undefined) {
        this.showPath = this.data.pathEnabled;
      }
    }
  }

  ngOnInit() {
    this.subscribe();
    this.userService.users(true).subscribe(response => {
      this.users = response.map(user => Object.assign({}, user, {
        name: `${user.firstName} ${user.lastName}`
      }));
    });
  }

  ngOnDestroy() {
    this.unsubscribe();
  }

  ngAfterViewInit() {
    if (this.isExistingDocument) {
      this.upload.load(AppConfig.documentDownloadUrl + this.documentId + `/file?token=${getToken()}`);
    }
  }

  updateDocument(response: HttpResponse<any>) {
    if (response.ok && response.body) {
      this.documentId = response.body.id;
      this.fileName.setValue(response.body.fileName);
    }
  }

  update() {
    if (this.documentId) {
      this.bus.publish(this.events.requested.action.documents.update, {
        id: this.documentId,
        fileName: this.fileName.value,
        tags: this.tags,
        folderPath: this.folderPath
      });
    }
  }

  updated() {
    this.dialogRef.close(true);
  }

  updateFailed(error) {
    console.error(error);
  }

  uploadUrl() {
    if (this.isExistingDocument) {
      return `/document/${this.documentId}/file`;
    } else if (this.data && this.data.uploadUrl) {
      return this.data.uploadUrl;
    } else {
      return '/document/file';
    }
  }

  subscribe() {
    this.bus.subscribe(this.events.received.action.documents.update.success, this.updated, this);
    this.bus.subscribe(this.events.received.action.documents.update.failure, this.updateFailed, this);
  }

  unsubscribe() {
    this.bus.unsubscribe(this.events.received.action.documents.update.success, this.updated);
    this.bus.unsubscribe(this.events.received.action.documents.update.failure, this.updateFailed);
  }
}

import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';

import { EventsService } from '../../../services/events.service';
import { BusService } from '../../../services/bus.service';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-offer-dpa',
  templateUrl: './offer-dpa.component.html',
  styleUrls: ['./offer-dpa.component.scss']
})
export class OfferDpaComponent implements OnInit, OnDestroy {

  badToken = false;

  vendor: any;
  controller: any;
  jurisdiction: string;
  private dpaToken: string;

  constructor(
    private events: EventsService,
    private bus: BusService,
    private activatedRoute: ActivatedRoute,
    private translate: TranslateService,
  ) { }

  ngOnInit() {
    this.subscribe();

    this.activatedRoute.queryParams.subscribe((params: Params)=> {
      this.dpaToken = params['dpa-token'] || '';
      this.bus.publish(this.events.requested.data.vendors.decodeDPAToken, this.dpaToken);
    });
  }

  ngOnDestroy() {
    this.unsubscribe();
  }

  tokenDecoded(response) {
    this.vendor = response.vendor;
    this.controller = response.controller;
    this.jurisdiction = response.jurisdiction;
    this.resolveLanguage();
  }

  tokenDecodingFailed(error) {
    this.badToken = true;
  }

  public get docs() {
    return `assets/documents/${localStorage['lang'] || 'en'}`;
  }

  subscribe() {
    this.bus.subscribe(this.events.received.data.vendors.decodeDPAToken.success, this.tokenDecoded, this);
    this.bus.subscribe(this.events.received.data.vendors.decodeDPAToken.failure, this.tokenDecodingFailed, this);
  }

  unsubscribe() {
    this.bus.unsubscribe(this.events.received.data.vendors.decodeDPAToken.success, this.tokenDecoded);
    this.bus.unsubscribe(this.events.received.data.vendors.decodeDPAToken.failure, this.tokenDecodingFailed);
  }

  resolveLanguage() {
    let lang;
    if (this.jurisdiction === 'GDPR') {
      lang = 'en';
    } else if (this.jurisdiction === 'DSGVO') {
      lang = 'de';
    } else if (this.jurisdiction === 'LGPD') {
      lang = 'pt-BR';
    } else if (this.jurisdiction === 'NDPR') {
      lang = 'en-NG';
    } else {
      lang = 'en';
    }
    localStorage.lang = lang;
    this.translate.use(lang);
  }
}

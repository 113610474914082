<br><br><br>
<div class="data-map container">
  <h1 class="title">
    {{'data-map.dashboard-card.title' | translate}}
  </h1>
  <img src="assets/data-map.svg" class="data-map-img"/>
  <p>
    {{'data-map.dashboard-card.description' | translate}}
  </p>
  <br>
  <button mat-flat-button color="primary" routerLink="/reports/data-map">
    {{'data-map.dashboard-card.action' | translate}}
  </button>
  <br><br>
</div>
<br><br>
<mat-divider></mat-divider>
<br><br>
<report-component></report-component>
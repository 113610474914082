import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CustomPaService } from 'app/services/rpa/rpa.custom-pa.service';


@Component({
  selector: 'app-starter-pas-create-dialog',
  templateUrl: './starter-pas-create-dialog.component.html',
  styleUrls: ['./starter-pas-create-dialog.component.scss']
})
export class StarterPasCreateDialogComponent implements OnInit {

  pas: any[] = [];
  cursor = 0;

  constructor(
    @Inject(MAT_DIALOG_DATA) pas: any[],
    private service: CustomPaService,
  ) {
    this.pas = pas;
  }

  ngOnInit(): void {
    this.step();
  }

  step() {
    if (this.cursor < this.pas.length) {
      const pa = this.pas[this.cursor];
      this.service.duplicate(pa.pa_id, {
        name: pa.pa_name,
        referenceController: pa.controller_id,
        options: {
          includeExternalProcessors: true,
          includeInternalProcessors: false,
        }
      }).subscribe(() => {
        this.cursor++;
        this.step();
      });
    }
  }

  get progress() {
    return this.cursor / this.pas.length * 100;
  }
}

<mat-dialog-content class="vendors-search-dialog-contents">
  <mat-form-field>
    <input placeholder="{{'pa.vendors.searchDialog.search' | translate}}"
      matInput type="text" [(ngModel)]="search" autofocus/>
    <button [ngClass]="{'active': !search}" class="search"
      mat-button matSuffix mat-icon-button>
      <mat-icon>search</mat-icon>
    </button>
    <button [ngClass]="{'active': search}"
      (click)="search=''" class="clear"
      mat-button matSuffix mat-icon-button>
      <mat-icon>clear</mat-icon>
    </button>
    <mat-hint innerHTML="{{'pa.vendors.searchDialog.searchHint' | translate}}">
    </mat-hint>
  </mat-form-field>

  <cdk-virtual-scroll-viewport class="vendor-list" itemSize="vendors.length">
    <div *cdkVirtualFor="let vendor of vendors"
      (click)="dialogRef.close(vendor)"
      class="vendor">
      <div class="avatar">
        <img *ngIf="vendor.logoUrl" [src]="vendor.logoUrl" onerror="this.onerror=null;this.src='/assets/default_vendor.svg';"/>
        <mat-icon *ngIf="!vendor.logoUrl" class="no-logo">location_city</mat-icon>
      </div>

      <div class="info">
        <div class="title">
          {{vendor.name}}
        </div>
        <ng-container *ngIf="vendor.type === 'vendor'">
          <div class="address">
            {{vendor.addressCity}}, {{vendor.addressCountry}}
          </div>
          <div class="email">
            {{vendor.email}}
          </div>
        </ng-container>
        <div *ngIf="vendor.type === 'other'" class="address">
          {{ 'data-recipients.vendor.typeOther' | translate }}
        </div>
      </div>
    </div>
  </cdk-virtual-scroll-viewport>
</mat-dialog-content>

<mat-dialog-actions *ngIf="!data || !data.readonly" align="center">
  <button mat-button color="primary" (click)="newVendor()">
    {{'pa.vendors.searchDialog.newVendor' | translate}}
  </button>
</mat-dialog-actions>

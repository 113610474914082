<div *ngIf="controller" class="supervision-client-details client-details">

  <div class="client-logo">
    <img
      src="{{appConfig.apiUrl}}/controllers/logo/{{controller._id}}/"
      onerror="this.src='/assets/eclogo-check.svg'"
      onabort="this.src='/assets/eclogo-check.svg'"/>
  </div>
  <br><br>
  <h1 class="title">{{ controller.controllerName }}</h1>
  <h2 (click)="openClientIdDialog()">
    <ng-container *ngIf="controller.clientId">
      {{controller.clientId}}
    </ng-container>
    <ng-container *ngIf="!controller.clientId">
      {{'supervision.clientDetails.controller.clientId.empty' | translate}}
    </ng-container>
    <mat-icon>create</mat-icon>
  </h2>

  <br><br><br><br>

  <div class="inner">
    <div fxLayout="row" fxLayoutGap="64px">
      <div fxFlex="50%">
        <div class="label">{{ 'supervision.clientDetails.contact' | translate }}</div>
        <ng-container *ngIf="controller.controllerContactName">
          <div class="contact-name">{{ controller.controllerContactName }}</div>
          <div class="contact-email">{{ controller.controllerEmailAddress }}</div>
        </ng-container>

        <ng-container *ngIf="!controller.controllerContactName">
          <div class="contact-name">{{ 'supervision.no-contact' | translate }}</div>
          <div class="contact-email">{{ 'supervision.no-contact-hint' | translate }}</div>
        </ng-container>

        <br>

        <div class="label">{{ 'supervision.clientDetails.dpCoordinator' | translate }}</div>
        <ng-container *ngIf="controller.controllerDpCoordination">
          <div class="contact-name">{{ controller.controllerDpCoordination }}</div>
        </ng-container>

        <ng-container *ngIf="!controller.controllerDpCoordination">
          <div class="contact-name">{{ 'supervision.no-contact' | translate }}</div>
          <div class="contact-email">{{ 'supervision.no-contact-hint' | translate }}</div>
        </ng-container>

        <br>

        <div class="label">
          {{'supervision.clientDetails.controller.correspondingSupervisor.label' | translate}}
        </div>
        <ng-container *ngIf="correspondingSupervisor">
          <div class="supervisor-row">
            <user-avatar [user]="correspondingSupervisor"></user-avatar>
            <div>
              {{correspondingSupervisor.firstName}}
              {{correspondingSupervisor.lastName}}
              <div class="contact-email">{{correspondingSupervisor.email}}</div>
            </div>
          </div>
          <br>
          <div style="text-align: right">
            <button mat-stroked-button color="primary" (click)="changeCorrespondingSupervisor()">
              {{'supervision.clientDetails.controller.correspondingSupervisor.change' | translate}}
            </button>
          </div>
        </ng-container>
        <ng-container *ngIf="!correspondingSupervisor">
          {{'supervision.clientDetails.controller.correspondingSupervisor.empty' | translate}}
          <br><br>
          <div style="text-align: right">
            <button mat-stroked-button color="primary" (click)="changeCorrespondingSupervisor()">
              {{'supervision.clientDetails.controller.correspondingSupervisor.add' | translate}}
            </button>
          </div>
        </ng-container>

      </div>

      <div fxFlex="50%">
        <!--
        <ng-container *ngIf="controller.controllerAddressPostcode">
          <iframe frameborder="0" style="border:0"
            [src]="googleMapsUrl()" allowfullscreen>
          </iframe>
        </ng-container>
        <div class="no-address" *ngIf="!controller.controllerAddressPostcode">
          <img src="/assets/no-location.svg"/>
        </div>
        -->
        <div class="no-address">
          <img src="/assets/no-location.svg"/>
        </div>

        <br>

        <div class="label">{{ 'supervision.clientDetails.address' | translate }}</div>
        <ng-container *ngIf="controller.controllerAddressPostcode">
          <div class="address">
            <div class="street">{{controller.controllerAddressStreet}}</div>
            <div class="city">{{controller.controllerAddressPostcode}} {{controller.controllerAddressCity}}</div>
            <div class="country">{{controller.controllerAddressCountry}}</div>
          </div>
        </ng-container>

        <ng-container *ngIf="!controller.controllerAddressPostcode">
          <div class="address">
            {{ 'supervision.clientDetails.no-address' | translate }}
          </div>
        </ng-container>

        <br>

        <div class="meeting">
          <ng-container *ngIf="controller.nextMeeting || controller.nextMeetingDate">
            <div class="label">
              {{'supervision.clientDetails.nextMeeting.label' | translate}}
            </div>
            {{ controller.nextMeeting?.title }} <br>
            {{ controller.nextMeeting?.date || controller.nextMeetingDate | localizedDate:"medium" }}
            <br><br>
            <div style="text-align: right">
              <button mat-button (click)="clearNextMeeting()" color="primary">
                {{'supervision.clientDetails.nextMeeting.clear' | translate}}
              </button>
              <button mat-stroked-button color="primary" (click)="openClientNextMeetingDialog()">
                {{'supervision.clientDetails.nextMeeting.change' | translate}}
              </button>
            </div>
          </ng-container>
          <ng-container *ngIf="!controller.nextMeeting && !controller.nextMeetingDate">
            <div style="text-align: right">
              <button mat-stroked-button color="primary" (click)="openClientNextMeetingDialog()">
                {{'supervision.clientDetails.nextMeeting.add' | translate}}
              </button>
            </div>
          </ng-container>
        </div>
      </div>
    </div>

    <br><br>
    <mat-divider></mat-divider>
    <br><br>

    <div flexLayout="row" fxLayoutGap="64px">
      <div fxFlex="100%">
        <div class="label">
          {{'supervision.clientDetails.controller.clientNotes.label' | translate}}
        </div>
        <ng-container *ngIf="controller.clientNotes">
          <div style="white-space: pre-wrap; word-break: break-all;">{{controller.clientNotes}}</div>
          <br><br>
          <div style="text-align: right">
            <button mat-stroked-button color="primary" (click)="openClientNotesDialog()">
              {{'supervision.clientDetails.controller.clientNotes.edit' | translate}}
            </button>
          </div>
        </ng-container>
        <ng-container *ngIf="!controller.clientNotes">
          {{'supervision.clientDetails.controller.clientNotes.empty' | translate}}
          <br><br>
          <div style="text-align: right">
            <button mat-stroked-button color="primary" (click)="openClientNotesDialog()">
              {{'supervision.clientDetails.controller.clientNotes.add' | translate}}
            </button>
          </div>
        </ng-container>
      </div>
    </div>

    <br><br>
    <mat-divider></mat-divider>
    <br><br>

    <!-- Client Type (Dashboard Only / Collaboration) -->
    <div class="dashboard">
      <div>
        <h3>{{ 'supervision.clientDetails.operationalMode.title' | translate }}</h3>
        <p>{{ 'supervision.clientDetails.operationalMode.hint' | translate }}</p>
      </div>

      <mat-radio-group [(ngModel)]="controller.serviced" (change)="updateServiced($event)" >
        <div class="dashboard-option full-collaboration">
          <mat-radio-button [value]="false">
            <span>
              {{ 'supervision.clientDetails.operationalMode.collaboration' | translate }}
            </span>
            <span class="hint">
              {{ 'supervision.clientDetails.operationalMode.collaboration.hint' | translate }}
            </span>
            <!-- icon placeholder -->
          </mat-radio-button>
        </div>
        <div class="dashboard-option only-dashboard">
          <mat-radio-button [value]="true">
            <span>
              {{ 'supervision.clientDetails.operationalMode.serviced' | translate }}
            </span>
            <span class="hint">
              {{ 'supervision.clientDetails.operationalMode.serviced.hint' | translate }}
            </span>
            <!-- icon placeholder -->
          </mat-radio-button>
        </div>
      </mat-radio-group>
    </div>
    <br><br>
  </div>
</div>

<div class="buttons">
  <div class="inner">
    <mat-divider></mat-divider>
    <br>
    <div class="btns">
      <button mat-flat-button color="primary" (click)="superviseController()">
        {{ 'supervision.clientDetails.superviseAction' | translate }}
      </button>
      <button mat-button (click)="goBack()">
        {{ 'supervision.clientDetails.back' | translate }}
      </button>
      <div class="spacer"></div>
      <button class="delete" *ngIf="controller && controller._id != controllerId" mat-button color="warn" (click)="deleteClient()">
        {{ 'supervision.clientDetails.delete' | translate }}
      </button>
    </div>
  </div>
</div>

<br><br><br><br>

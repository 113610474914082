import { Injectable } from '@angular/core';
import {Observable, of} from "rxjs";
import {RequestService} from "./request.service";
import {ClientSupervisionService} from "./supervision/client-supervision.service";

type MeasureStatus = 'planned' | 'in_progress' | 'done';
type MeasureCycle = 'monthly' | 'yearly';

export interface Measure {
  id: number;
  title: string;
  description: string | null;
  responsibleEntity: string;
  status: MeasureStatus;

  // contextUrl defines a place in the software this measure points to
  contextUrl: string | null;

  dueAt: Date;
  doneAt: Date | null;
  lastUpdateAt: Date | null;

  cycle: MeasureCycle | null;

  progress: number | null;

  showInDashboard: boolean;
  showInReport: boolean;

  // keys
  controllerId: number | null;
  createdBy: number;

  supervisorId: number | null;
}

@Injectable({
  providedIn: 'root'
})
export class MeasuresService {

  constructor(
    private request: RequestService,
    private supervision: ClientSupervisionService
  ) { }

  getMeasure(id: number): Observable<Measure> {
    return this.request.get<Measure>({
      uri: '/measures/' + id
    });
  }

  getMeasures(): Observable<Measure[]> {
    return this.request.get<Measure[]>({
      uri: '/measures'
    });
  }

  getSupervisorMeasures(): Observable<Measure[]> {
    return this.request.get<Measure[]>({
      uri: '/measures/supervisor'
    });
  }

  createMeasure(measure: Measure) {
    let uri = this.supervision.isInSupervisionMode ? '/measures' : '/measures/supervisor';

    return this.request.post<Measure, Measure>({
      uri,
      body: measure
    });
  }

  updateMeasure(measure: Measure) {
    return this.request.patch<Measure, Measure>({
      uri: '/measures/' + measure.id,
      body: measure
    });
  }

  deleteMeasure(measure: Measure) {
    return this.request.delete({
      uri: '/measures/' + measure.id
    });
  }
}

export function measureOverdue(measure: Measure) {
  return measure.dueAt && new Date(measure.dueAt) < new Date() && measure.status !== 'done';
}

export function measureDue(measure: Measure) {
  const today = new Date();
  const nearFuture = new Date();
  nearFuture.setDate(today.getDate() + 14);
  return measure.dueAt && new Date(measure.dueAt) < nearFuture && new Date(measure.dueAt) > today && measure.status !== 'done';
}

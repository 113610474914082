import { Component, OnInit, OnDestroy, ViewChild, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BusService } from 'app/services/bus.service';
import { EventsService } from 'app/services/events.service';
import { ActionsService } from 'app/services/actions/actions.service';
import { UserManagementService } from 'app/services/user/user-management.service';
import { Event } from '../../actions.component';

@Component({
  selector: 'app-actions-metadata-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class ActionsMetadataUsersComponent implements OnInit {

  @Input() event: Event;
  users = [];

  invite: any;

  constructor(
    private bus: BusService,
    private events: EventsService,
    private userService: UserManagementService,
  ) {
  }

  getUser(userId: number) {
    const candidates = this.users.filter(user => user.id === userId);
    if (candidates.length > 0) return candidates[0].name;
    return '---';
  }

  private receiveInvites(invites) {
    this.invite = invites.find((i) => i.id === this.event.metadata.inviteId);
  }

  ngOnInit() {
    this.userService.users(true).subscribe(response => {
      this.users = response.map(user => Object.assign({}, user, {
        name: `${user.firstName} ${user.lastName}`
      }));
    });

    this.bus.subscribe(this.events.received.data.controller.invites.success, this.receiveInvites, this);

    if (this.event.eventName === 'RESENT_INVITE') {
      this.bus.publish(this.events.requested.data.controller.invites);
    }
  }
}

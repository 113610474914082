import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Application, ApplicationService } from '../../../services/vendors/application.service';
import { AddApplicationDialogComponent } from '../add-application-dialog/add-application-dialog.component';

export interface SearchApplicationDialogPayload {
  allowCreate: boolean;
}
@Component({
  selector: 'app-search-application-dialog',
  templateUrl: './search-application-dialog.component.html',
  styleUrls: ['./search-application-dialog.component.scss']
})
export class SearchApplicationDialogComponent implements OnInit {

  _applications: Application[] = [];
  search = '';

  constructor(
    public dialogRef: MatDialogRef<SearchApplicationDialogComponent>,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: SearchApplicationDialogPayload,
    private applicationService: ApplicationService
  ) { }

  ngOnInit(): void {
    this.applicationService.getAllApplicationsByPopularity().subscribe(
      (applications) => {
        this._applications = applications;
      }, (error) => {
        console.error(error);
      }
    );
  }

  newApplication() {
    this.dialog.open(AddApplicationDialogComponent, {
      width: '480px',
      maxHeight: '90vh'
    }).afterClosed().subscribe(application => {
      if (application) {
        this.dialogRef.close(Object.assign(application, {added : true}));
      }
    });
  }

  get applications() {
    if (this.search) {
      const search = this.search.toLowerCase();
      return this._applications.filter(application => application.name.toLowerCase().indexOf(search) !== -1);
    }

    return this._applications;
  }

}

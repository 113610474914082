<h1 *ngIf="!update" mat-dialog-title>{{'measures.create.title' | translate}}</h1>
<h1 *ngIf="update" mat-dialog-title>{{'measures.create.updateTitle' | translate}}</h1>

<mat-dialog-content>
  <form [formGroup]="form">
    <h3 class="section-title">{{'measures.create.category.main' | translate}}</h3>
    <mat-form-field>
      <input matInput required formControlName="title" type="text" placeholder="{{ 'measures.create.field.title' | translate }}" />
      <mat-hint>{{ 'measures.create.field.title.hint' | translate }}</mat-hint>
    </mat-form-field>

    <mat-form-field>
      <input matInput formControlName="responsibleEntity" type="text" placeholder="{{ 'measures.create.field.responsibleEntity' | translate }}" />
      <mat-hint>{{ 'measures.create.field.responsibleEntity.hint' | translate }}</mat-hint>
    </mat-form-field>

    <mat-form-field>
      <textarea matInput formControlName="description" placeholder="{{ 'measures.create.field.description' | translate }}"></textarea>
    </mat-form-field>

    <mat-form-field>
      <input matInput [matDatepicker]="dueDatePicker" formControlName="dueAt"
             placeholder="{{'measures.create.field.dueAt'|translate}}">
      <mat-hint>{{ 'measures.create.field.dueAt.hint' | translate }}</mat-hint>
      <mat-datepicker-toggle matSuffix [for]="dueDatePicker"></mat-datepicker-toggle>
      <mat-datepicker #dueDatePicker></mat-datepicker>
    </mat-form-field>

    <h3 class="section-title">{{'measures.create.category.progress' | translate}}</h3>
    <mat-form-field>
      <mat-label>{{ 'measures.create.field.status' | translate }}</mat-label>
      <mat-select formControlName="status" required>
        <mat-option value="planned">{{ 'measure.status.planned' | translate }}</mat-option>
        <mat-option value="in_progress">{{ 'measure.status.in_progress' | translate }}</mat-option>
        <mat-option value="done">{{ 'measure.status.done' | translate }}</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <input matInput [matDatepicker]="doneDatePicker" formControlName="doneAt"
             placeholder="{{'measures.create.field.doneAt'|translate}}">
      <mat-datepicker-toggle matSuffix [for]="doneDatePicker"></mat-datepicker-toggle>
      <mat-datepicker #doneDatePicker></mat-datepicker>
    </mat-form-field>

    <div class="field">
      <mat-checkbox [checked]="form.value.progress !== null" (change)="toggleProgress()">{{ 'measures.create.field.showProgress' | translate }}</mat-checkbox>
      <div class="spacer" *ngIf="form.value.progress !== null"></div>
    </div>

    <div class="field">
      <mat-slider *ngIf="form.value.progress !== null" min="0" max="1" step="0.25" tickInterval="1" formControlName="progress" thumbLabel [displayWith]="formatLabel"></mat-slider>
    </div>

    <h3 class="section-title">{{'measures.create.category.reporting' | translate}}</h3>
    <div class="field">
      <mat-checkbox formControlName="showInDashboard">{{ 'measures.create.field.showInDashboard' | translate }}</mat-checkbox>
    </div>

    <!--<div class="field">
      <mat-checkbox formControlName="showInReport">{{ 'measures.create.field.showInReport' | translate }}</mat-checkbox>
    </div>-->
  </form>

</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button [mat-dialog-close]="null">{{ 'word.cancel' | translate}}</button>
  <button *ngIf="update" mat-flat-button (click)="save()" color="primary">{{ 'word.save' | translate}}</button>
  <button *ngIf="!update" mat-flat-button (click)="save()" color="primary">{{ 'word.create' | translate}}</button>
</mat-dialog-actions>

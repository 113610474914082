import { Inject, Injectable, PLATFORM_ID, Renderer2, RendererFactory2 } from '@angular/core';
import { IPostOptions, RequestService } from '../request.service';
import { BusService } from '../bus.service';
import { EventsService } from '../events.service';
import { HttpErrorResponse } from '@angular/common/http';
import { isNotCustomerInstance } from '../../util/environment';
import { DOCUMENT } from '@angular/common';

interface IPostSubscribeRequest {
  stripeToken: string;
  planName: string;
  couponCode: string;
  vatId: string;
  isEuCountry: boolean;
  existingCustomer: boolean;
}

interface IPostSubscribeResponse {
  periodEnd: Date;
  periodStart: Date;
  planInterval: string;
  discount?: any;
}

@Injectable()
export class PaymentSubscriptionService {
  private options: IPostOptions<IPostSubscribeRequest,
                                IPostSubscribeResponse>;

  private renderer: Renderer2;

  constructor(
    private bus: BusService,
    private events: EventsService,
    private requestService: RequestService,
    @Inject(PLATFORM_ID) protected platformId: Object,
    rendererFactory: RendererFactory2,
    @Inject(DOCUMENT) private document
  ) {
    this.options = {
      uri: '/payment/subscribe',
      body: {
        stripeToken: '',
        planName: '',
        couponCode: '',
        vatId: '',
        isEuCountry: false,
        existingCustomer: false
      },
      handlers: {
        success: this.success.bind(this),
        error: this.error.bind(this)
      }
    };

    this.renderer = rendererFactory.createRenderer(null, null);

    this.subscribe();
  }

  private subscribe(): void {
    this.bus.subscribe(this.events.requested.payment.subscribe, this.request.bind(this));
    this.bus.subscribe(this.events.requested.payment.currentPaymentIntent, this.getPaymentIntent.bind(this));
  }

  public getPaymentIntent() {
    this.requestService.get({
      uri: '/payment/subscribe/payment-intent',
      handlers: {
        success: invoice => {
          this.bus.publish(this.events.received.payment.currentPaymentIntent.success, invoice);
        },
        error: error => {
          this.bus.publish(this.events.received.payment.currentPaymentIntent.failure, error);
        },
      }
    });
  }

  public request(data: any) {
    this.bus.publish(this.events.notified.request.starting);
    this.options.body.stripeToken = data.stripeToken;
    this.options.body.planName = data.planName;
    this.options.body.couponCode = data.couponCode;
    this.options.body.vatId = data.vatId;
    this.options.body.isEuCountry = data.isEuCountry;
    this.options.body.existingCustomer = data.existingCustomer;

    this.requestService.post<IPostSubscribeRequest, IPostSubscribeResponse>(this.options);
  }

  public success(data: IPostSubscribeResponse) {
    this.bus.publish(this.events.received.payment.subscribe.success, data);
  }

  public error(httpErrorResponse: HttpErrorResponse) {
    this.bus.publish(this.events.received.payment.subscribe.failure, httpErrorResponse);
  }

  public mountStripe() {
    if (isNotCustomerInstance() && (!this.document.querySelector('script[src="https://js.stripe.com/v3/"]'))) {
      const script = this.renderer.createElement('script');

      script.src = `https://js.stripe.com/v3/`;

      this.renderer.appendChild(this.document.body, script);
    }
  }
}

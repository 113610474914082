<h1 mat-dialog-title>{{ 'departments.suggestions.title' | translate }}</h1>

<mat-dialog-content>
  <div class="search">
    <mat-form-field>
      <input matInput type="text" [(ngModel)]="query" placeholder="{{'governance.actions.addDepartment' | translate}}..."/>
      <button mat-button mat-icon-button matSuffix>
        <mat-icon>search</mat-icon>
      </button>
      <mat-hint>
        {{'department.suggestions.hint' | translate}}
      </mat-hint>
    </mat-form-field>
  </div>

  <div class="options">
    <div *ngIf="matchingSuggestions.length > 0" class="section-title">{{ 'departments.suggestions.suggestions' | translate }}</div>
    <ng-container *ngFor="let suggestion of matchingSuggestions; let last=last">
      <div class="suggestion" (click)="choose(suggestion)">{{ suggestion.name }}</div>
      <hr *ngIf="!last" />
    </ng-container>
  </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>{{ 'word.cancel' | translate }}</button>
  <button mat-button color="primary" [disabled]="!query" (click)="create()">{{ 'word.select' | translate }}</button>
</mat-dialog-actions>

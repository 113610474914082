<div class="contents">
  <h1 class="title">{{ 'supervision.measures.title' | translate }}</h1>
  <p>{{ 'supervision.measures.description' | translate }}</p>


  <div class="actions">
    <button mat-flat-button color="primary" (click)="createMeasure()">{{ 'supervision.measures.createMeasure' | translate }}</button>
  </div>

  <table mat-table matSort [dataSource]="measures" (matSortChange)="sortData($event)">
    <ng-container matColumnDef="title">
      <mat-header-cell *matHeaderCellDef mat-sort-header>{{'measures.list.item.title' | translate}}</mat-header-cell>
      <mat-cell *matCellDef="let measure">{{measure.title}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="showInDashboard">
      <mat-header-cell *matHeaderCellDef mat-sort-header>{{'measures.list.showInDashboard.title' | translate}}</mat-header-cell>
      <mat-cell *matCellDef="let measure">{{(measure.showInDashboard ? 'yesno.yes' : 'yesno.no') | translate}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="showInReport">
      <mat-header-cell *matHeaderCellDef mat-sort-header>{{'measures.list.showInReport.title' | translate}}</mat-header-cell>
      <mat-cell *matCellDef="let measure">{{(measure.showInReport ? 'yesno.yes' : 'yesno.no') | translate }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="actions">
      <mat-header-cell *matHeaderCellDef>{{'measures.list.item.actions' | translate}}</mat-header-cell>
      <mat-cell *matCellDef="let measure">
        <button mat-icon-button matTooltip="{{ 'measures.list.item.delete' | translate }}" color="warn" (click)="deleteMeasure($event, measure)">
          <mat-icon>delete</mat-icon>
        </button>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="columnsToDisplay"></mat-header-row>
    <mat-row *matRowDef="let row; columns: columnsToDisplay" matRipple (click)="showDetails($event, row)"></mat-row>
  </table>
</div>

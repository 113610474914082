import { Injectable } from '@angular/core';
import { AuthenticationService } from './authentication.service';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  constructor(
    private authService: AuthenticationService
  ) { }

  public get shouldOnlyShowDashboard() {
    return this.authService.isServiced && !(this.authService.productType === 'supervisor' || (localStorage.getItem('isInSupervisionMode') === 'true'));
  }
}

<h1 mat-dialog-title>{{ 'pa.duplicate.toController.title' | translate }}</h1>

<div mat-dialog-content>
  <p>{{ 'pa.duplicate.toController.message' | translate }}</p>
  <div class="controllers">
    <div *ngFor="let controller of availableTargets" class="controller">
      <mat-checkbox [value]="targets.includes(controller._id)" (change)="toggleTarget($event, controller._id)">
        {{ controller.controllerName }}
      </mat-checkbox>
    </div>
  </div>
</div>

<div mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>{{'word.cancel' | translate}}</button>
  <button type="button" mat-raised-button color="primary" [disabled]="pending || targets.length === 0" (click)="submit()">{{ 'pa.duplicate.toController.action' | translate : { count: targets.length } }}</button>
</div>

import { Injectable } from '@angular/core';
import { IPostOptions, RequestService, IGetOptions } from '../../request.service';
import { BusService } from '../../bus.service';
import { EventsService } from '../../events.service';
import { HttpErrorResponse } from '@angular/common/http';

/*
interface Coupon {
  id: string;
  percentOff: number;
  valid: boolean;
  metadata?: any;
}
*/

@Injectable()
export class PaymentUpgradePreviewGetService {
  private options: IGetOptions<any>;

  constructor(
    private bus: BusService,
    private events: EventsService,
    private requestService: RequestService
  ) {
    this.options = {
      uri: '/payment/upgrade-preview',
      handlers: {
        success: this.success.bind(this),
        error: this.error.bind(this)
      }
    };

    this.subscribe();
  }

  private subscribe(): void {
    this.bus.subscribe(this.events.requested.payment.upgrade.preview, this.request.bind(this));
  }

  public request(newPlanName) {
    this.options.uri = '/payment/upgrade-preview/' + newPlanName;
    this.bus.publish(this.events.notified.request.starting);

    this.requestService.get(this.options);
  }

  public success(data) {
    this.bus.publish(this.events.received.payment.upgrade.preview.success, data);
  }

  public error(httpErrorResponse: HttpErrorResponse) {
    this.bus.publish(this.events.received.payment.upgrade.preview.failure, httpErrorResponse);
  }
}

<div *ngIf="formExists === 'pending'" class="content">
  <h1 class="title">{{ 'records.incidents.report.title' | translate }}</h1>
  <p>{{'records.incidents.report.hint' | translate}}</p>

  <br><br>

  <div class="centered-spinner brand">
    <mat-spinner></mat-spinner>
  </div>
</div>

<div *ngIf="formExists === 'notFound'" class="content">
  <h1 class="title">{{ 'records.incidents.report.title' | translate }}</h1>
  <p>{{'records.incidents.report.hint' | translate}}</p>

  <br><br>

  <p>{{'records.incidents.report.formDoesNotExist' | translate}}</p>
</div>

<div *ngIf="submitted" class="content">
  <h1 class="title">{{'records.incidents.submit.success.title' | translate}}</h1>
  <p>{{'records.incidents.submit.success.subtitle' | translate}}</p>
</div>

<div *ngIf="formExists === 'exists' && !submitted" class="content">
  <h1 class="title">{{ 'records.incidents.report.title' | translate }}</h1>
  <p>{{'records.incidents.report.hint' | translate}}</p>

  <form #incidentForm="ngForm">
    <div class="with-subtitle">
      <h2>{{'records.incidents.overview.title' | translate}}</h2>
    </div>
    <mat-form-field>
      <input type="text" matInput [(ngModel)]="record.title"
        placeholder="{{'records.incidents.add.title.placeholder' | translate}}" required name="incidentTitle" />
      <mat-hint>
        {{'records.incidents.add.title.placeholder.hint' | translate}}
      </mat-hint>
    </mat-form-field>

    <mat-form-field>
      <input type="text" matInput [(ngModel)]="record.details"
        placeholder="{{'records.incidents.add.details.placeholder' | translate}}" required name="incidentDescription" />
      <mat-hint>
        {{'records.incidents.add.details.placeholder.hint' | translate}}
      </mat-hint>
    </mat-form-field>

    <br>
    <mat-divider></mat-divider>

    <div class="with-subtitle">
      <h2>{{'records.incidents.reporter.title' | translate}}</h2>
    </div>

    <!-- reporter name -->
    <mat-form-field>
      <input type="text" matInput [(ngModel)]="record.customFields.reporterName"
        placeholder="{{'records.incidents.reporter-name.placeholder' | translate}}" required name="reporterName" />
      <mat-hint>
        {{'records.incidents.reporter-name.hint' | translate}}
      </mat-hint>
    </mat-form-field>

    <!-- reporter email -->
    <mat-form-field>
      <input type="email" matInput [(ngModel)]="record.customFields.reporterEmail"
        placeholder="{{'records.incidents.reporter-email.placeholder' | translate}}" required name="reporterEmail" />
      <mat-hint>
        {{'records.incidents.reporter-email.hint' | translate}}
      </mat-hint>
    </mat-form-field>

    <!-- reporter phone number -->
    <mat-form-field>
      <input type="phone" matInput [(ngModel)]="record.customFields.reporterPhone"
        placeholder="{{'records.incidents.reporter-phone.placeholder' | translate}}" required
        name="reporterPhoneNumber" />
      <mat-hint>
        {{'records.incidents.reporter-phone.hint' | translate}}
      </mat-hint>
    </mat-form-field>

    <!-- reporter function -->
    <mat-form-field>
      <input type="text" matInput [(ngModel)]="record.customFields.reporterFunction"
        placeholder="{{'records.incidents.reporter-function.placeholder' | translate}}" required
        name="reporterFunction" />
      <mat-hint>
        {{'records.incidents.reporter-function.hint' | translate}}
      </mat-hint>
    </mat-form-field>

    <br>
    <mat-divider></mat-divider>

    <div class="with-subtitle">
      <h2>{{'records.incidents.incident-details.title' | translate}}</h2>
      <p class="subtitle">{{'records.incidents.incident-details.subtitle' | translate}}</p>
    </div>

    <!-- incident type -->
    <mat-form-field>
      <mat-select [(ngModel)]="record.customFields.incidentType" [ngModelOptions]="{standalone: true}"
        placeholder="{{'records.incidents.incident-type.placeholder' | translate}}" required>
        <mat-option value="incident.types.sending-error">{{'records.incidents.types.sending-error' | translate}}
        </mat-option>
        <mat-option value="incident.types.disclosure">{{'records.incidents.types.disclosure' | translate}}</mat-option>
        <mat-option value="incident.types.theft">{{'records.incidents.types.theft' | translate}}</mat-option>
        <mat-option value="incident.types.wrong-disposal">{{'records.incidents.types.wrong-disposal' | translate}}
        </mat-option>
        <mat-option value="incident.types.loss">{{'records.incidents.types.loss' | translate}}</mat-option>
        <mat-option value="incident.types.lack-of-encryption">{{'records.incidents.types.lack-of-encryption' |
          translate}}</mat-option>
        <mat-option value="incident.types.hacking">{{'records.incidents.types.hacking' | translate}}</mat-option>
        <mat-option value="incident.types.skimming">{{'records.incidents.types.skimming' | translate}}</mat-option>
        <mat-option value="incident.types.software-fault">{{'records.incidents.types.software-fault' | translate}}
        </mat-option>
        <mat-option value="incident.types.other">{{'records.incidents.types.other' | translate}}</mat-option>
      </mat-select>
      <mat-hint>
        {{'records.incidents.incident-type.hint' | translate}}
      </mat-hint>
    </mat-form-field>

    <mat-form-field *ngIf="record.customFields.incidentType === 'incident.types.other'">
      <input matInput type="text" [(ngModel)]="record.customFields.incidentTypeOther"
        placeholder="{{'records.incidents.incident-type-other.placeholder' | translate}}" name="incidentTypeOther" />
      <mat-hint>
        {{'records.incidents.incident-type-other.hint' | translate}}
      </mat-hint>
    </mat-form-field>

    <!-- incident time -->
    <br><label>{{'records.incidents.datetime.title' | translate}}</label><br>
    <mat-radio-group [(ngModel)]="record.customFields.timePeriodStatus" [ngModelOptions]="{standalone: true}" required>
      <mat-radio-button value="incident.time-period.once">
        {{'records.incidents.datetime.on' | translate}}
        <mat-form-field class="inline">
          <input matInput [(ngModel)]="record.customFields.incidentTime" [matDatepicker]="incidentTimePicker"
            name="incidentTime" />
          <mat-datepicker-toggle matSuffix [for]="incidentTimePicker"></mat-datepicker-toggle>
          <mat-datepicker #incidentTimePicker></mat-datepicker>
        </mat-form-field>
      </mat-radio-button>
      <br>
      <mat-radio-button value="incident.time-period.started-ongoing">
        {{'records.incidents.datetime.since' | translate}}
        <mat-form-field class="inline">
          <input matInput [(ngModel)]="record.customFields.incidentTimeStart" [matDatepicker]="incidentTimeStartPicker"
            name="incidentTimeStart" />
          <mat-datepicker-toggle matSuffix [for]="incidentTimeStartPicker"></mat-datepicker-toggle>
          <mat-datepicker #incidentTimeStartPicker></mat-datepicker>
        </mat-form-field>
        {{'records.incidents.datetime.until' | translate}}
      </mat-radio-button>
      <br>
      <mat-radio-button value="incident.time-period.started-finished">
        {{'records.incidents.datetime.between-beginning' | translate}}
        <mat-form-field class="inline">
          <input matInput [(ngModel)]="record.customFields.incidentTimeStart" [matDatepicker]="incidentTimeStartPicker2"
            name="incidentTimeStart2" />
          <mat-datepicker-toggle matSuffix [for]="incidentTimeStartPicker2"></mat-datepicker-toggle>
          <mat-datepicker #incidentTimeStartPicker2></mat-datepicker>
        </mat-form-field>
        {{'records.incidents.datetime.between-end' | translate}}
        <mat-form-field class="inline">
          <input matInput [(ngModel)]="record.customFields.incidentTimeEnd" [matDatepicker]="incidentTimeEndPicker"
            name="incidentTimeEnd" />
          <mat-datepicker-toggle matSuffix [for]="incidentTimeEndPicker"></mat-datepicker-toggle>
          <mat-datepicker #incidentTimeEndPicker></mat-datepicker>
        </mat-form-field>
      </mat-radio-button>
      <br>
      <mat-radio-button value="incident.time-period.unknown">
        {{'records.incidents.datetime.unknown' | translate}}
      </mat-radio-button>
      <br>
      <mat-radio-button value="incident.time-period.unknown-ongoing">
        {{'records.incidents.datetime.unknown-unfinished' | translate}}
      </mat-radio-button>
    </mat-radio-group>
    <br><br><br>

    <!-- incident detection time -->
    <mat-form-field>
      <input matInput [matDatepicker]="incidentDetectionTimePicker"
        [(ngModel)]="record.customFields.incidentDetectionTime"
        placeholder="{{'records.incidents.incident-detection-time.placeholder' | translate}}"
        name="incidentDetectionTime" required />
      <mat-datepicker-toggle matSuffix [for]="incidentDetectionTimePicker"></mat-datepicker-toggle>
      <mat-datepicker #incidentDetectionTimePicker></mat-datepicker>
      <mat-hint>
        {{'records.incidents.incident-detection-time.hint' | translate}}
      </mat-hint>
    </mat-form-field>

    <!-- type of access -->
    <mat-form-field>
      <mat-select [(ngModel)]="record.customFields.typeOfAccess" [ngModelOptions]="{standalone: true}"
        placeholder="{{'records.incidents.type-of-access.placeholder' | translate}}" multiple long-select required>
        <mat-option value="incident.access-types.read">
          {{'records.incidents.type-of-access.type-read' | translate}}
        </mat-option>
        <mat-option value="incident.access-types.copy">
          {{'records.incidents.type-of-access.type-copy' | translate}}
        </mat-option>
        <mat-option value="incident.access-types.change">
          {{'records.incidents.type-of-access.type-change' | translate}}
        </mat-option>
        <mat-option value="incident.access-types.delete">
          {{'records.incidents.type-of-access.type-delete' | translate}}
        </mat-option>
        <mat-option value="incident.access-types.steal">
          {{'records.incidents.type-of-access.type-theft' | translate}}
        </mat-option>
        <mat-option value="incident.access-types.other">
          {{'records.incidents.type-of-access.type-other' | translate}}
        </mat-option>
        <mat-option value="incident.access-types.unknown">
          {{'records.incidents.type-of-access.type-unknown' | translate}}
        </mat-option>
      </mat-select>
      <mat-hint>
        {{'records.incidents.type-of-access.hint' | translate}}
      </mat-hint>
    </mat-form-field>

    <!-- access type other -->
    <mat-form-field *ngIf="
      record.customFields.typeOfAccess &&
      record.customFields.typeOfAccess.includes('incident.access-types.other')">
      <input matInput [(ngModel)]="record.customFields.typeOfAccessOther"
        placeholder="{{'records.incidents.type-of-access.other.placeholder' | translate}}" name="typeOfAccess" />
      <mat-hint>
        {{'records.incidents.type-of-access.other.hint' | translate}}
      </mat-hint>
    </mat-form-field>

    <br>
    <mat-divider></mat-divider>

    <div class="with-subtitle">
      <h2>{{'records.incidents.data-subjects.title' | translate}}</h2>
    </div>

    <div class="number-precision">
      <!-- number of affected people precision -->
      <mat-form-field class="precision">
        <mat-select [(ngModel)]="record.customFields.affectedPeopleNumberPrecision"
          [ngModelOptions]="{standalone: true}"
          placeholder="{{'records.incidents.affected-people-number-precision.placeholder' | translate}}" required>
          <mat-option value="incident.precision.precise">
            {{'records.incidents.affected-people-number-precision.precise' | translate}}
          </mat-option>
          <mat-option value="incident.precision.estimate">
            {{'records.incidents.affected-people-number-precision.estimate' | translate}}
          </mat-option>
          <mat-option value="incident.precision.min">
            {{'records.incidents.affected-people-number-precision.min' | translate}}
          </mat-option>
          <mat-option value="incident.precision.max">
            {{'records.incidents.affected-people-number-precision.max' | translate}}
          </mat-option>
          <mat-option value="incident.precision.unknown">
            {{'records.incidents.affected-people-number-precision.unknown' | translate}}
          </mat-option>
        </mat-select>
        <mat-hint>
          {{'records.incidents.affected-people-number-precision.hint' | translate}}
        </mat-hint>
      </mat-form-field>

      <mat-form-field class="number">
        <input matInput [(ngModel)]="record.customFields.affectedPeopleNumber"
          placeholder="{{'records.incidents.affected-people-number.placeholder' | translate}}" type="number"
          name="affectedPeopleNumber" />
        <mat-hint>
          {{'records.incidents.affected-people-number.hint' | translate}}
        </mat-hint>
      </mat-form-field>
    </div>

    <!-- number of affected records -->
    <div class="number-precision">
      <!-- number of affected records precision -->
      <mat-form-field class="precision">
        <mat-select [(ngModel)]="record.customFields.affectedRecordsNumberPrecision"
          [ngModelOptions]="{standalone: true}"
          placeholder="{{'records.incidents.affected-records-number-precision.placeholder' | translate}}" required>
          <mat-option value="incident.precision.precise">
            {{'records.incidents.affected-records-number-precision.precise' | translate}}
          </mat-option>
          <mat-option value="incident.precision.estimate">
            {{'records.incidents.affected-records-number-precision.estimate' | translate}}
          </mat-option>
          <mat-option value="incident.precision.min">
            {{'records.incidents.affected-records-number-precision.min' | translate}}
          </mat-option>
          <mat-option value="incident.precision.max">
            {{'records.incidents.affected-records-number-precision.max' | translate}}
          </mat-option>
          <mat-option value="incident.precision.unknown">
            {{'records.incidents.affected-records-number-precision.unknown' | translate}}
          </mat-option>
        </mat-select>
        <mat-hint>
          {{'records.incidents.affected-records-number-precision.hint' | translate}}
        </mat-hint>
      </mat-form-field>
      <mat-form-field class="number">
        <input matInput [(ngModel)]="record.customFields.affectedRecordsNumber"
          placeholder="{{'records.incidents.affected-records-number.placeholder' | translate}}" type="number"
          name="affectedRecordsNumber" />
        <mat-hint>
          {{'records.incidents.affected-records-number.hint' | translate}}
        </mat-hint>
      </mat-form-field>
    </div>

    <!-- categories of data subjects -->
    <mat-form-field>
      <mat-select [(ngModel)]="record.customFields.dataSubjectCategories" [ngModelOptions]="{standalone: true}"
        placeholder="{{'records.incidents.data-subject-categories.placeholder' | translate}}" multiple long-select
        required>
        <mat-option value="incident.data-subject-category.members">
          {{'records.incidents.data-subject-categories.members' | translate}}
        </mat-option>
        <mat-option value="incident.data-subject-category.contractors">
          {{'records.incidents.data-subject-categories.contractors' | translate}}
        </mat-option>
        <mat-option value="incident.data-subject-category.employees">
          {{'records.incidents.data-subject-categories.employees' | translate}}
        </mat-option>
        <mat-option value="incident.data-subject-category.employer">
          {{'records.incidents.data-subject-categories.employer' | translate}}
        </mat-option>
        <mat-option value="incident.data-subject-category.supervisor">
          {{'records.incidents.data-subject-categories.supervisor' | translate}}
        </mat-option>
        <mat-option value="incident.data-subject-category.children">
          {{'records.incidents.data-subject-categories.children' | translate}}
        </mat-option>
        <mat-option value="incident.data-subject-category.other">
          {{'records.incidents.data-subject-categories.other' | translate}}
        </mat-option>
      </mat-select>
      <mat-hint>
        {{'records.incidents.data-subject-categories.hint' | translate}}
      </mat-hint>
    </mat-form-field>

    <!-- categories of data subjects other -->
    <mat-form-field *ngIf="
      record.customFields.dataSubjectCategories &&
      record.customFields.dataSubjectCategories.includes('incident.data-subject-category.other')">
      >
      <input matInput [(ngModel)]="record.customFields.dataSubjectCategoriesOther"
        placeholder="{{'records.incidents.data-subject-categories.other.placeholder' | translate}}" type="text"
        name="dataSubjectCategoriesOther" />
      <mat-hint>
        {{'records.incidents.data-subject-categories.other.hint' | translate}}
      </mat-hint>
    </mat-form-field>

    <br>
    <label>{{'records.incidents.dataCategories.title' | translate}}</label>

    <!-- data categories unknown justification -->
    <mat-form-field *ngIf="
      record.customFields.dataCategoriesUnknown">
      <textarea matInput [(ngModel)]="record.customFields.dataCategoriesUnknownJustification"
        placeholder="{{'records.incidents.personal-data-categories.unknown.justification.placeholder' | translate}}"
        rows="4" name="dataCategoriesUnknownJustification"></textarea>
      <mat-hint>
        {{'records.incidents.personal-data-categories.unknown.justification.hint' | translate}}
      </mat-hint>
    </mat-form-field>

    <!-- data categories contact -->
    <mat-form-field>
      <mat-select long-select [(ngModel)]="record.customFields.dataCategories" [ngModelOptions]="{standalone: true}"
        placeholder="{{'records.incidents.personal-data-categories.placeholder' | translate}}" multiple required>
        <mat-option value="incident.data-category.unknown">
          {{ 'records.incidents.personal-data-categories.unknown' | translate }}
        </mat-option>

        <mat-option value="incident.data-category.contact.name">
          {{'records.incidents.personal-data-categories.contact.name' | translate}}
        </mat-option>
        <mat-option value="incident.data-category.contact.address">
          {{'records.incidents.personal-data-categories.contact.address' | translate}}
        </mat-option>
        <mat-option value="incident.data-category.contact.phone">
          {{'records.incidents.personal-data-categories.contact.phone' | translate}}
        </mat-option>
        <mat-option value="incident.data-category.contact.email">
          {{'records.incidents.personal-data-categories.contact.email' | translate}}
        </mat-option>

        <mat-option value="incident.data-category.bank.contact">
          {{'records.incidents.personal-data-categories.bank.contact' | translate}}
        </mat-option>
        <mat-option value="incident.data-category.bank.credit-debit">
          {{'records.incidents.personal-data-categories.bank.credit-debit' | translate}}
        </mat-option>

        <mat-option value="incident.data-category.id.userid">
          {{'records.incidents.personal-data-categories.id.userid' | translate}}
        </mat-option>
        <mat-option value="incident.data-category.id.password">
          {{'records.incidents.personal-data-categories.id.password' | translate}}
        </mat-option>
        <mat-option value="incident.data-category.id.token">
          {{'records.incidents.personal-data-categories.id.token' | translate}}
        </mat-option>
        <mat-option value="incident.data-category.id.pin-puk">
          {{'records.incidents.personal-data-categories.id.pin-puk' | translate}}
        </mat-option>
        <mat-option value="incident.data-category.id.access-card">
          {{'records.incidents.personal-data-categories.id.access-card' | translate}}
        </mat-option>
        <mat-option value="incident.data-category.id.signature">
          {{'records.incidents.personal-data-categories.id.signature' | translate}}
        </mat-option>

        <mat-option value="incident.data-category.personal.gender">
          {{'records.incidents.personal-data-categories.personal.gender' | translate}}
        </mat-option>
        <mat-option value="incident.data-category.personal.age">
          {{'records.incidents.personal-data-categories.personal.age' | translate}}
        </mat-option>
        <mat-option value="incident.data-category.personal.birth-name">
          {{'records.incidents.personal-data-categories.personal.birth-name' | translate}}
        </mat-option>
        <mat-option value="incident.data-category.personal.kv-number">
          {{'records.incidents.personal-data-categories.personal.kv-number' | translate}}
        </mat-option>
        <mat-option value="incident.data-category.personal.rv-number">
          {{'records.incidents.personal-data-categories.personal.rv-number' | translate}}
        </mat-option>
        <mat-option value="incident.data-category.personal.employer-number">
          {{'records.incidents.personal-data-categories.personal.employer-number' | translate}}
        </mat-option>

        <mat-option value="incident.data-category.special.ethnicity">
          {{'records.incidents.personal-data-categories.special.ethnicity' | translate}}
        </mat-option>
        <mat-option value="incident.data-category.special.politics">
          {{'records.incidents.personal-data-categories.special.politics' | translate}}
        </mat-option>
        <mat-option value="incident.data-category.special.religion">
          {{'records.incidents.personal-data-categories.special.religion' | translate}}
        </mat-option>
        <mat-option value="incident.data-category.special.sex-life">
          {{'records.incidents.personal-data-categories.special.sex-life' | translate}}
        </mat-option>
        <mat-option value="incident.data-category.special.health">
          {{'records.incidents.personal-data-categories.special.health' | translate}}
        </mat-option>

        <mat-option value="incident.data-category.other">
          {{'records.incidents.personal-data-categories.other' | translate}}
        </mat-option>
      </mat-select>
      <mat-hint>
        {{'records.incidents.personal-data-categories.hint' | translate}}
      </mat-hint>
    </mat-form-field>

    <!-- data categories other -->
    <mat-form-field *ngIf="
      record.customFields.dataCategories &&
      record.customFields.dataCategories.includes('incident.data-category.other')">
      <input matInput [(ngModel)]="record.customFields.dataCategoriesOther"
        placeholder="{{'records.incidents.personal-data-categories.other.placeholder' | translate}}" type="text"
        name="dataCategoriesOther" />
      <mat-hint>
        {{'records.incidents.personal-data-categories.other.hint' | translate}}
      </mat-hint>
    </mat-form-field>


    <ng-container *ngIf="
      !record.customFields.dataCategories ||
      !record.customFields.dataCategories.includes('incident.data-category.unknown')">
      <!-- data categories additional info -->
      <mat-form-field>
        <textarea matInput [(ngModel)]="record.customFields.dataCategoriesAdditionalInfo"
          placeholder="{{'records.incidents.personal-data-categories.additional-info.placeholder' | translate}}"
          rows="1" name="dataCategoriesAdditionalInfo"></textarea>
        <mat-hint>
          {{'records.incidents.personal-data-categories.additional-info.hint' | translate}}
        </mat-hint>
      </mat-form-field>
    </ng-container>

    <!-- data categories unknown justification -->
    <mat-form-field *ngIf="
      record.customFields.dataCategories &&
      record.customFields.dataCategories.includes('incident.data-category.unknown')">
      <textarea matInput [(ngModel)]="record.customFields.dataCategoriesUnknownJustification"
        placeholder="{{'records.incidents.personal-data-categories.unknown.justification.placeholder' | translate}}"
        rows="4" name="dataCategoriesUnknownJustification"></textarea>
      <mat-hint>
        {{'records.incidents.personal-data-categories.unknown.justification.hint' | translate}}
      </mat-hint>
    </mat-form-field>

    <!-- name(s) of affected people -->
    <mat-form-field>
      <textarea matInput [(ngModel)]="record.customFields.affectedPeopleNames"
        placeholder="{{'records.incidents.affected-people-names.placeholder' | translate}}" rows="2"
        name="affectedPeopleNames"></textarea>
      <mat-hint>
        {{'records.incidents.affected-people-names.hint' | translate}}
      </mat-hint>
    </mat-form-field>

    <br>
    <mat-divider></mat-divider>

    <h2>{{'records.incidents.consequences.title' | translate}} </h2>
    <!-- anticipated consequences -->
    <mat-form-field>
      <mat-select [(ngModel)]="record.customFields.anticipatedConsequences" [ngModelOptions]="{standalone: true}"
        placeholder="{{'records.incidents.anticipated-consequences.placeholder' | translate}}" multiple long-select
        required>
        <mat-option value="incident.anticipated-consequences.control-loss">
          {{'records.incidents.anticipated-consequences.control-loss' | translate}}
        </mat-option>
        <mat-option value="incident.anticipated-consequences.limitation-of-rights">
          {{'records.incidents.anticipated-consequences.limitation-of-rights' | translate}}
        </mat-option>
        <mat-option value="incident.anticipated-consequences.discrimination">
          {{'records.incidents.anticipated-consequences.discrimination' | translate}}
        </mat-option>
        <mat-option value="incident.anticipated-consequences.identity-theft">
          {{'records.incidents.anticipated-consequences.identity-theft' | translate}}
        </mat-option>
        <mat-option value="incident.anticipated-consequences.financial-loss">
          {{'records.incidents.anticipated-consequences.financial-loss' | translate}}
        </mat-option>
        <mat-option value="incident.anticipated-consequences.danger-to-life">
          {{'records.incidents.anticipated-consequences.danger-to-life' | translate}}
        </mat-option>
        <mat-option value="incident.anticipated-consequences.pseudonymization-cancellation">
          {{'records.incidents.anticipated-consequences.pseudonymization-cancellation' | translate}}
        </mat-option>
        <mat-option value="incident.anticipated-consequences.reputation">
          {{'records.incidents.anticipated-consequences.reputation' | translate}}
        </mat-option>
        <mat-option value="incident.anticipated-consequences.professional-secrecy">
          {{'records.incidents.anticipated-consequences.professional-secrecy' | translate}}
        </mat-option>
        <mat-option value="incident.anticipated-consequences.social">
          {{'records.incidents.anticipated-consequences.social' | translate}}
        </mat-option>
        <mat-option value="incident.anticipated-consequences.other">
          {{'records.incidents.anticipated-consequences.other' | translate}}
        </mat-option>
      </mat-select>
      <mat-hint>
        {{'records.incidents.anticipated-consequences.hint' | translate}}
      </mat-hint>
    </mat-form-field>
    <mat-form-field *ngIf="
      record.customFields.anticipatedConsequences &&
      record.customFields.anticipatedConsequences.includes('incident.anticipated-consequences.other')">
      <input matInput [(ngModel)]="record.customFields.anticipatedConsequencesOther"
        placeholder="{{'records.incidents.anticipated-consequences.other.placeholder' | translate}}" type="text"
        name="anticipatedConsequencesOther" />
      <mat-hint>
        {{'records.incidents.anticipated-consequences.other.hint' | translate}}
      </mat-hint>
    </mat-form-field>

    <br>
    <mat-divider></mat-divider>

    <div class="with-subtitle">
      <h2>{{'records.incidents.additionalRemarks.title' | translate}}</h2>
    </div>
    <mat-form-field>
      <textarea matInput [(ngModel)]="record.customFields.reporterRemarks"
        placeholder="{{'records.incidents.reporter-remarks.placeholder' | translate}}"
        name="reporterRemarks"></textarea>
      <mat-hint>
        {{'records.incidents.reporter-remarks.hint' | translate}}
      </mat-hint>
    </mat-form-field>

    <br><br>

    <div class="actions">
      <!-- {{ 'records.incidents.submit.action.missingFields' | translate }} -->
      <button mat-flat-button color="primary" (click)="submit()" [disabled]="isSubmitting || !incidentForm.valid"
        matTooltip="{{ !incidentForm.valid ? ('records.incidents.submit.action.missingFields' | translate) : '' }}">
        {{'records.incidents.submit.action' | translate}}
      </button>
    </div>
  </form>
</div>
import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {Measure, MeasuresService} from "../../../services/measures.service";
import {FormControl, FormGroup, Validators} from "@angular/forms";

@Component({
  selector: 'app-create-measure-dialog',
  templateUrl: './create-measure-dialog.component.html',
  styleUrls: ['./create-measure-dialog.component.scss']
})
export class CreateMeasureDialogComponent implements OnInit {

  update = false;

  pending = false;

  form = new FormGroup({
    title: new FormControl('', [Validators.required]),
    description: new FormControl('', []),
    responsibleEntity: new FormControl('', []),
    status: new FormControl('planned', [Validators.required]),
    progress: new FormControl(null, []),
    dueAt: new FormControl(null, []),
    doneAt: new FormControl(null, []),
    showInDashboard: new FormControl(false, []),
    showInReport: new FormControl(false, []),
  });

  constructor(
    private ref: MatDialogRef<CreateMeasureDialogComponent>,
    private measureService: MeasuresService,
    @Inject(MAT_DIALOG_DATA) private data: { measure: Measure; copy?: boolean },
  ) {
    if (this.data && this.data.measure) {
      if (!this.data.copy) {
        this.update = true;
      }
      this.form.patchValue(this.data.measure);
    }
  }

  save() {
    if (this.form.valid) {
      this.pending = true;
      if (this.update) {
        this.measureService.updateMeasure(Object.assign(this.data.measure, this.form.value))
          .subscribe((updated) => {
            this.ref.close(updated);
          });
      } else {
        this.measureService.createMeasure(this.form.value)
          .subscribe((saved) => {
            this.ref.close(saved);
          });
      }
    }
  }

  toggleProgress() {
    if (this.form.value.progress === null) {
      this.form.get('progress').setValue(0);
      console.log(this.form.value.progress);
    } else {
      this.form.get('progress').setValue(null);
    }
  }

  ngOnInit(): void {
  }

}

import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Vendor, VendorsService } from '../../../services/vendors/vendors.service';
import { VendorAddDialogComponent } from '../../rpa/pa-external-processors/vendor-add-dialog/vendor-add-dialog.component';

@Component({
  selector: 'app-vendor-library',
  templateUrl: './library.component.html',
  styleUrls: ['./library.component.scss']
})
export class VendorLibraryComponent implements OnInit {
  vendors: Vendor[] = [];
  query = '';

  constructor(
    private vendorService: VendorsService,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.load();
  }

  get filtered() {
    if (!this.query) return this.vendors;
    return this.vendors.filter(v => v.name.toLocaleLowerCase().startsWith(this.query.toLocaleLowerCase()));
  }

  load() {
    this.vendorService.get().subscribe((vendors: Vendor[]) => {
      this.vendors = vendors.sort((a, b) => a.name.localeCompare(b.name));
    });
  }

  newVendor() {
    this.dialog.open(VendorAddDialogComponent, { width: '512px', maxHeight: '90vh'})
      .afterClosed()
      .subscribe((updated) => {
        this.load();
      });
  }

  edit(vendor: Vendor) {
    this.dialog.open(VendorAddDialogComponent, { width: '512px', maxHeight: '90vh', data: { vendor }})
      .afterClosed()
      .subscribe((updated) => {
        // const existing = this.vendors.findIndex(v => v.id);
        // this.vendors[existing] = updated;
        this.load();
      });
  }

}

<mat-dialog-content>
  <img src="/assets/create-template.svg"/>
  <h1 mat-dialog-title>
    {{'supervision.templates.create.title.part1' | translate }}
    {{controller.controllerName}}
    {{'supervision.templates.create.title.part2' | translate }}
  </h1>

  <p>
    {{'supervision.templates.create.hint.part1' | translate }}
    {{controller.controllerName}}
    {{'supervision.templates.create.hint.part2' | translate }}
  </p>
  <br>
  <mat-form-field>
    <input matInput type="text" placeholder="Template Name" [(ngModel)]="templateName"/>
    <mat-hint>
      {{'supervision.templates.create.name.hint' | translate }}
    </mat-hint>
  </mat-form-field>
  <div class="original-info" *ngIf="original">
    <div>
      {{'supervision.templates.create.createdFrom' | translate }}
      {{ original.sourceName }}
    </div>
    <div>
      {{'supervision.templates.create.lastUpdated' | translate }}
      {{ original.updatedAt | localizedDate : 'short' }}
    </div>
    <button mat-icon-button (click)="deselectTemplate()">
      <mat-icon>
        close
      </mat-icon>
    </button>
    <br>
  </div>
  <div class="buttons">
    <button mat-button color="primary" (click)="selectTemplate()">
      <!-- unfortunately this is necessary for translate service to pick up the message -->
      <!-- {{'supervision.templates.create.update' | translate }} -->
      <!-- {{'supervision.templates.create.chooseAnother' | translate }} -->
      {{ (original?'supervision.templates.create.chooseAnother':'supervision.templates.create.update') | translate }}
    </button>
  </div>
  <br>
  <mat-divider></mat-divider>
  <br>
  <p>
    {{'supervision.templates.create.settingsHint' | translate }}
  </p>
  <mat-slide-toggle color="primary" [(ngModel)]="settings.departments">
    {{'supervision.templates.settings.departments' | translate }}
  </mat-slide-toggle>
  <mat-slide-toggle color="primary" [(ngModel)]="settings.dpo">
    {{'supervision.templates.settings.dpo' | translate }}
  </mat-slide-toggle>
  <mat-slide-toggle color="primary" [(ngModel)]="settings.rpas">
    {{'supervision.templates.settings.rpas' | translate }}
  </mat-slide-toggle>
  <mat-slide-toggle color="primary" [(ngModel)]="settings.toms">
    {{'supervision.templates.settings.toms' | translate }}
  </mat-slide-toggle>
  <mat-slide-toggle color="primary" [(ngModel)]="settings.vendors">
    {{'supervision.templates.settings.vendors' | translate }}
  </mat-slide-toggle>

  <br/>

  <mat-expansion-panel displayMode="flat">
    <mat-expansion-panel-header>
      {{'supervision.templates.settings.advanced.title' | translate }}
      <mat-icon matTooltip="{{'supervision.templates.settings.advanced.hint' | translate }}">
        help
      </mat-icon>
    </mat-expansion-panel-header>
    <div class="advanced-settings">
      <mat-slide-toggle color="accent" [(ngModel)]="settings.companyInfo">
        {{'supervision.templates.settings.companyInfo' | translate }}
      </mat-slide-toggle>
      <mat-slide-toggle color="accent" [(ngModel)]="settings.dsars">
        {{'supervision.templates.settings.dsars' | translate }}
      </mat-slide-toggle>
      <mat-slide-toggle color="accent" [(ngModel)]="settings.incidents">
        {{'supervision.templates.settings.incidents' | translate }}
      </mat-slide-toggle>
    </div>
  </mat-expansion-panel>

  <br/>

</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>{{ 'word.cancel' | translate }}</button>
  <button mat-flat-button color="primary" (click)="done()" [disabled]="!templateName">
    <!-- unfortunately this is necessary for the translate service to pick the message up -->
    <!-- {{'word.create' | translate}} -->
    <!-- {{'word.update' | translate}} -->
    {{ (original?'word.update':'word.create') | translate }}
  </button>
</mat-dialog-actions>

<div class="container">
  <br /><br />
  <h1 class="title">{{ 'user.profileSafety.title' | translate }}</h1>

  <h2 class="title">
    {{ 'user.profile.title' | translate }}
  </h2>
  <br /><br />
  <div class="inner-container">
    <div class="avatar">
      <cool-upload
        #upload
        [preview]="true"
        upload-url="/account/profile/avatar"
        (uploaded)="avatarUpdated()"
        upload-key="avatar"
      >
        <span hint>
          {{ 'user.profile.avatar.hint' | translate }}
        </span>
      </cool-upload>
    </div>
    <div class="name">
      <mat-form-field>
        <input
          matInput
          required
          type="text"
          [(ngModel)]="firstName"
          placeholder="{{ 'user.profile.firstName.placeholder' | translate }}"
          (change)="save()"
        />
        <mat-hint *ngIf="!saving">
          {{ 'user.profile.firstName.hint' | translate }}
        </mat-hint>
        <mat-hint *ngIf="saving">
          {{ 'user.profile.firstName.saving' | translate }}
        </mat-hint>
        <mat-error *ngIf="!firstName">
          {{ 'user.profile.firstName.required' | translate }}
        </mat-error>
      </mat-form-field>
      <mat-form-field>
        <input
          matInput
          required
          type="text"
          [(ngModel)]="lastName"
          placeholder="{{ 'user.profile.lastName.placeholder' | translate }}"
          (change)="save()"
        />
        <mat-hint *ngIf="!saving">
          {{ 'user.profile.lastName.hint' | translate }}
        </mat-hint>
        <mat-hint *ngIf="saving">
          {{ 'user.profile.lastName.saving' | translate }}
        </mat-hint>
        <mat-error *ngIf="!lastName">
          {{ 'user.profile.lastName.required' | translate }}
        </mat-error>
      </mat-form-field>
    </div>
  </div>

  <br /><br /><br /><br />
  <mat-divider></mat-divider>
  <br />

  <h2 class="title">
    {{ 'user.profile.updateEmail.title' | translate }}
  </h2>
  {{ 'user.profile.updateEmail.currentEmail' | translate }} <b>{{ email }}</b>
  <br /><br />
  <button mat-stroked-button (click)="openChangeEmailDialog()">
    {{ 'user.profile.updateEmail.title' | translate }}
  </button>
  
  <br /><br /><br /><br />
  <mat-divider></mat-divider>
  <br />
  
  <h2 class="title">
    {{ 'user.changePassword.title' | translate }}
  </h2>
  <a mat-stroked-button routerLink="/change-password">{{
    'user.changePassword.action' | translate
  }}</a>
  
  <br /><br /><br /><br />
  <mat-divider></mat-divider>
  <br />

  <h2 class="title">
    {{ 'user.two-factor.title' | translate }}
  </h2>
  <app-two-factor-info></app-two-factor-info>
</div>

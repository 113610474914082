import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {Audit, AuditsService} from "../../../../services/audits/audits.service";
import {FormControl, Validators} from "@angular/forms";

@Component({
  selector: 'app-edit-audit-title-dialog',
  templateUrl: './edit-audit-title-dialog.component.html',
  styleUrls: ['./edit-audit-title-dialog.component.scss']
})
export class EditAuditTitleDialogComponent implements OnInit {

  name = new FormControl('', []);

  constructor(
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<EditAuditTitleDialogComponent>,
    private auditService: AuditsService,
    @Inject(MAT_DIALOG_DATA) private data: Audit
  ) {
    this.name.setValue(data.title);
  }

  ngOnInit(): void {
  }

  save() {
    this.data.title = this.name.value;

    this.auditService.updateAudit(this.data.id, this.data).subscribe(update => {
      this.dialogRef.close(this.data);
    });
  }
}

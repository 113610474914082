<div class="content dsar-records">
  <h1 class="title">{{ 'dsar.records.title' | translate }}</h1>
  <br><br>
  <p>
    {{ 'dsar.records.hint' | translate }}
    <a routerLink="/dsar/templates" color="primary">{{ 'dsar.start.templates.action' | translate }}</a>
  </p>
  <br>
  <div class="hint">
    {{ 'dsar.records.settings.hint' | translate }}
    <br><br>
    <div style="text-align: right">
      <button mat-flat-button color="primary" (click)="openSettings()">
        {{ 'dsar.records.settings.action' | translate }}
      </button>
    </div>
  </div>
  <br><br><br>

  <div class="actions">
    <div class="buttons">
      <button class="export-button" mat-button color="primary" matTooltip="{{ 'dsar.records.exportHint' | translate }}"
        (click)="exportToExcel()">{{ 'dsar.records.exportExcel' | translate }}</button>
      <button mat-flat-button color="primary" routerLink="/dsar/records/new">{{ 'dsar.records.create' | translate }}</button>
    </div>
  </div>

  <div class="record-items">
    <mat-table #table [dataSource]="dataSource" matSort multiTemplateDataRows>
      <ng-container matColumnDef="receivedOn">
        <mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'dsar.records.table.receivedOn' | translate }}
        </mat-header-cell>
        <mat-cell *matCellDef="let record">
          <span *ngIf="record.status === 'recordable.open'" class="danger-indicator"></span>
          {{ record.receivedOn | localizedDate }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="title">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ 'dsar.records.table.title' | translate }}
          <button mat-icon-button [color]="titleFilter.value?'primary':'initial'" (click)="openTitleFilter()">
            <mat-icon>filter_list</mat-icon>
          </button>
        </mat-header-cell>
        <mat-cell *matCellDef="let record"> <span
            [class.empty]="!record.title">{{ record.title || 'dsar.records.table.noTitle' | translate }}</span>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="details">
        <mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'dsar.records.table.details' | translate }}
        </mat-header-cell>
        <mat-cell *matCellDef="let record"> <span
            [class.empty]="!record.details">{{
              (record.details?.length > 64 ? record.details.substr(0, 64) + ' ...' : record.details) || 'dsar.records.table.noDetails' | translate  }}</span>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="department">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ 'dsar.records.table.department' | translate }}
          <!--<access-control level="dpo">-->
          <button mat-icon-button [color]="departmentFilter.value?'primary':'initial'" (click)="openDepartmentFilter()">
            <mat-icon>filter_list</mat-icon>
          </button>
          <!--</access-control>-->
        </mat-header-cell>
        <mat-cell *matCellDef="let record"> {{ getDepartment(record) }} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="user">
        <mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'dsar.records.table.user' | translate }}
        </mat-header-cell>
        <mat-cell *matCellDef="let record">
          {{ getUser(record) }}
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="columnsToDisplay"></mat-header-row>
      <mat-row *matRowDef="let row; columns: columnsToDisplay" matRipple class="element-row"
        [routerLink]="'/dsar/records/' + row.dsarId">
      </mat-row>
    </mat-table>
  </div>

  <div class="actions">
    <mat-paginator #paginator [pageSize]="20" [pageSizeOptions]="[10, 20, 50]">
    </mat-paginator>
  </div>
</div>

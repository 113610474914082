<div class="container">
  <h1 class="title">{{ 'user.two-factor.disable.title' | translate }}</h1>

  <ng-container *ngIf="!enabled">
    <p>{{ 'user.two-factor.disable.alreadyDisabled' | translate }}</p>
    <a mat-button color="warn" routerLink="/account/two-factor/enable">{{ 'user.two-factor.disable.enable' | translate }}</a>
  </ng-container>

  <ng-container *ngIf="enabled && disabled !== 'success'">
    <p>{{ 'user.two-factor.disable.action.explanation' | translate }}</p>

    <mat-form-field>
      <mat-label>{{ 'user.two-factor.disable.action.code' | translate }}</mat-label>
      <input matInput placeholder="{{ 'user.two-factor.disable.action.code.placeholder' | translate }}" [formControl]="code" inputmode="numeric" pattern="[0-9]*" autocomplete="one-time-code" />

      <mat-error *ngIf="code.hasError('wrongCode')">{{ 'login.two-factor.code.previousCodeWrong' | translate }}</mat-error>
    </mat-form-field>

    <br>

    <button mat-raised-button color="primary" [disabled]="disabled === 'pending'" (click)="disable()">{{ 'user.two-factor.disable.action' | translate }}</button>
  </ng-container>

  <ng-container *ngIf="disabled === 'success'">
    <p>{{ 'user.two-factor.disable.success' | translate }}</p>
    <a mat-button color="warn" routerLink="/account/two-factor/enable">{{ 'user.two-factor.disable.enable' | translate }}</a>
  </ng-container>
</div>

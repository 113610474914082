<div class="toms-category" *ngIf="category">
  <img class="cat-icon" src="/assets/{{category.icon}}" />
  <br><br>
  <h1>{{category.title}}</h1>
  <p>
    {{category.description}}
  </p>

  <p *ngIf="accessLevel && !accessLevel.write" class="warning">
    <span>{{'word.NOTICE' | translate}}</span>
    {{'word.readonlyAccess' | translate}}
  </p>
  <br><br>

  <br><br>

  <div class="entry-group" *ngFor="let tag of tags">
    <h2>{{tag}}</h2>
    <div class="entry" *ngFor="let entry of entries[tag]" commentable comment-subject-codename="toms-entry-{{entry.id}}"
      comment-subject-title="{{'toms.category.entry.commentSubject.part1' | translate}} {{entry.title}} {{'toms.category.entry.commentSubject.part2' | translate}}">
      <todoable subject="toms-entry-{{entry.id}}"
        suggested-title="{{'toms.category.entry.commentSubject.part1' | translate}} {{entry.title}} {{'toms.category.entry.commentSubject.part2' | translate}}">
      </todoable>
      <div class="inner">
        <div class="tom-option">
          <div class="list">
            <mat-menu #tomOptions="matMenu">
              <mat-option (click)="updateTomStatus(entry, 'implemented')">
                {{'toms.category.entry.options.implemented' | translate}}
              </mat-option>
              <mat-option (click)="updateTomStatus(entry, 'not-implemented')">
                {{'toms.category.entry.options.notImplemented' | translate}}
              </mat-option>
              <mat-option (click)="updateTomStatus(entry, 'not-applicable')">
                {{'toms.category.entry.options.notApplicable' | translate}}
              </mat-option>
              <mat-option *ngIf="entry.status != 'not-set'" (click)="updateTomStatus(entry, 'not-set')">
                {{'toms.category.entry.options.removeIndicator' | translate}}
              </mat-option>
            </mat-menu>
            <button *ngIf="entry.status === 'not-set' || !entry.status" mat-icon-button
              [matMenuTriggerFor]="canWrite ? tomOptions : no-menu">
              <mat-icon color="primary">check_box_outline_blank</mat-icon>
            </button>
            <button *ngIf="entry.status === 'implemented'" mat-icon-button [matMenuTriggerFor]="tomOptions">
              <mat-icon color="primary">check_circle</mat-icon>
            </button>
            <button *ngIf="entry.status === 'not-implemented'" mat-icon-button [matMenuTriggerFor]="tomOptions">
              <mat-icon color="warn">cancel</mat-icon>
            </button>
            <button *ngIf="entry.status === 'not-applicable'" mat-icon-button [matMenuTriggerFor]="tomOptions">
              <mat-icon color="primary">do_not_disturb_on</mat-icon>
            </button>
          </div>
        </div>
        <div class="title" (click)="openMenu()">{{entry.title}}</div>
        <div class="exp-button" (click)="toggleTomsEntryExp(entry)">
          <mat-icon color="accent" class="no-exp"
            *ngIf="!entry.explanation && !(focusedTomsExpId == entry.id && canWrite) && !(entry.status === 'implemented' && entry.requiresExplanation)">
            chat_bubble_outline
          </mat-icon>
          <mat-icon color="warn" class="no-exp-warn"
            matTooltip="{{'toms.category.entry.requiresExplanation' | translate}}"
            *ngIf="!entry.explanation && entry.status === 'implemented' && entry.requiresExplanation && !(focusedTomsExpId == entry.id && canWrite)">
            chat_bubble_outline
          </mat-icon>
          <mat-icon color="primary" class="no-exp-focused"
            *ngIf="!entry.explanation && (focusedTomsExpId == entry.id && canWrite)">
            chat_bubble_outline
          </mat-icon>
          <mat-icon color="primary" class="has-exp-focused"
            *ngIf="entry.explanation && (focusedTomsExpId == entry.id && canWrite)">
            message
          </mat-icon>
          <mat-icon color="accent" class="has-exp"
            *ngIf="entry.explanation && !(focusedTomsExpId == entry.id && canWrite)">
            message
          </mat-icon>
        </div>
      </div>
      <div class="exp"
        [ngClass]="{open: openedTomsExpId==entry.id || (entry.status === 'implemented' && (entry.requiresExplanation || entry.explanation))}">
        <mat-form-field>
          <textarea matInput matTextareaAutosize #explanation id="entry-exp-{{entry.id}}" minRows="1" maxRows="8"
            [required]="entry.status === 'implemented' && entry.requiresExplanation" [readonly]="!canWrite"
            (focus)="openedTomsExpId=focusedTomsExpId=entry.id" (blur)="openedTomsExpId=focusedTomsExpId=-1"
            [ngModel]="entry.explanation" (ngModelChange)="updateExplanation(entry, $event)"
            [errorStateMatcher]="expErrorMatcher(entry)"
            placeholder="{{'toms.category.entry.additionalDetails' | translate}}"></textarea>
          <mat-error>
            {{'toms.category.entry.requiresExplanation' | translate}}
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <br><br>
    <mat-divider></mat-divider>
    <br>
  </div>
  <br>
  <button mat-raised-button *ngIf="canWrite" [disabled]="!valid" color="primary" (click)="done()">
    {{'word.done' | translate}}
  </button>
  <button mat-button color="accent" *ngIf="!canWrite" routerLink="/toms">
    {{'toms.category.backToToms' | translate}}
  </button>
  <br><br><br><br>
</div>
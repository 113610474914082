<mat-dialog-content>
  <p>
    {{'supervision.supervisorProfile.confirmRemoval.hint.part1' | translate}}
    <a><b>{{user.firstName}} {{user.lastName}}</b></a>
    {{'supervision.supervisorProfile.confirmRemoval.hint.part2' | translate}}
  </p>
  <br>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>{{'word.cancel' | translate}}</button>
  <button mat-flat-button color="warn" (click)="remove()">
    {{'supervision.supervisorProfile.confirmRemoval.action.part1' | translate}}
    {{user.firstName}} {{user.lastName}}
    {{'supervision.supervisorProfile.confirmRemoval.action.part2' | translate}}
  </button>
</mat-dialog-actions>

import { Component, Input, OnInit } from '@angular/core';
import { DpoService } from 'app/services/dpo/dpo.service';

@Component({
  selector: 'dashboard-metrics-your-dpo',
  templateUrl: './your-dpo.component.html',
  styleUrls: ['./your-dpo.component.scss']
})
export class YourDpoComponent implements OnInit {
  dpo: any = null;

  constructor(
    private dpoService: DpoService
  ) { }

  ngOnInit(): void {
    this.dpoService.get().subscribe(data => this.dpo = data);
  }

}

<div class="container" [ngClass]="{ expanded: expanded }">
  <div class="top">
    <mat-icon class="expand" (click)="expanded = !expanded">expand_more</mat-icon>
    <div class="main">
      {{node.title}}
    </div>
    <div class="spacer"></div>
    <div class="actions">
      <button mat-icon-button class="edit" (click)="edit()">
        <mat-icon>edit</mat-icon>
      </button>
      <button mat-stroked-button color="primary" (click)="pick(node)">
        {{'word.pick' | translate}}
        <!-- Pick -->
      </button>
    </div>
  </div>

  <div class="children">
    <ng-container *ngIf="node.children">
      <pa-reference-tree-node *ngFor="let child of node.children"
        [node]="child" [tree]="tree"
        (picked)="pick($event)">
      </pa-reference-tree-node>
    </ng-container>
    <div class="new" (click)="add()">
      <mat-icon color="primary">add</mat-icon>
      <span>{{'word.add' | translate}}</span>
    </div>
  </div>
</div>
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppConfig } from '../../../../app.config';
import { Application, ApplicationService } from '../../../../services/vendors/application.service';

@Component({
  selector: 'app-client-applications-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.scss']
})
export class ClientApplicationsDetailComponent implements OnInit {

  applicationId: number = null;

  application: Application = null;
  clients = [];

  appConfig = AppConfig;

  constructor(
    private route: ActivatedRoute,
    private applicationService: ApplicationService,
    private router: Router
  ) {
    this.route.params.subscribe((params) => {
      if (params.applicationId) {
        this.applicationId = parseInt(params.applicationId, 10);
        this.load();
      } else {
        this.router.navigate(['not-found']);
      }
    });
  }

  load() {
    this.applicationService.getClientsForApplication(this.applicationId)
      .subscribe(clients => {
        for (const client of clients) {
          if (this.clients.findIndex(c => c._id === client._id) === -1) {
            this.clients.push(client);
          }
        }
      });

    this.applicationService.getApplication(this.applicationId)
      .subscribe(application => {
        this.application = application;
      });
  }

  ngOnInit(): void {
  }

}

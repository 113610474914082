<div class="container" *ngIf="paymentStatus && needsAction">
  <button mat-button color="accent" class="payment-notification" routerLink="/payment/finalize">
    <div class="message">
      <span class="insides">
        {{ 'payment.header-authenticate.finish' | translate }}
      </span>
    </div>
  </button>
  <div class="separator">|</div>
</div>

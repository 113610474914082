<div class="question-fields">
    <div *ngFor="let input of fields" class="input-field">
        <mat-form-field>
            <textarea matTextareaAutosize matInput placeholder="{{'audit.form.inputFields.field.placeholder.' + input.fieldName | translate}}"  [disabled]="readonly" (change)="input.submit($event.target.value)">{{ input.originalValue }}</textarea>
            <mat-hint [ngClass]="{'hidden': input.saving}" innerHTML="{{'audit.form.inputFields.field.hint.' + input.fieldName | translate}}">
            </mat-hint>
            <mat-hint [ngClass]="{'active': input.saving}" align="end" class="saving">
            {{ 'basicInfo.fields.saving' | translate }}
            </mat-hint>
        </mat-form-field>
    </div>
</div>

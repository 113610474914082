import {Injectable} from '@angular/core';
import {BusService} from './bus.service';
import {Router} from '@angular/router';
// import { NgXCookies } from "ngx-cookies";
import {EventsService} from './events.service';
import { BehaviorSubject } from 'rxjs';
import { MixpanelService } from '../modules/mixpanel/mixpanel.service';
import { getToken } from '../util/token';
import { TwoFactorService } from './user/two-factor.service';

@Injectable()
export class AuthenticationService {

  private _token: string;
  private _firstName: string;
  private _lastName: string;
  private _email: string;
  private _departmentId: number;
  private _intercomHash: string;
  private _crispHash: string;
  private _productType: string;
  private _controllerId: number;
  private _planMetadata: any;
  private _flags: string;
  private _avatarPath: string = '';
  private _access: string;
  private _serviced: boolean;
  public redirectUrl: string;

  private authorizedSubject: BehaviorSubject<boolean>;

  constructor(
    private bus: BusService,
    private events: EventsService,
    private router: Router,
    private mixpanel: MixpanelService,
    private twoFactor: TwoFactorService
  ) {
    bus.subscribe(events.received.authentication.success, this.receivedAuthentication, this);
    bus.subscribe(events.notified.user.avatarChanged, this.updateAvatar, this);
    bus.subscribe(events.notified.user.profileChanged, this.updateProfile, this);
    // bus.subscribe(events.received.authentication.failure, this.receivedLogout);
    bus.subscribe(events.notified.user.logout, this.receivedLogout, this);
    bus.subscribe(events.received.data.user.info.success, this.updateUserInfo, this);
    bus.subscribe(events.notified.supervision.client.changed, this.clientChanged, this);

    this.authorizedSubject = new BehaviorSubject<boolean>(false);

    if (this.token) {
      this.authorizedSubject.next(true);
      this.bus.publish(events.requested.data.user.info);
    }
  }

  public get authorized() {
    return this.authorizedSubject;
  }

  public get token(): string {
    // This behaviour breaks supervision since Angular will get caught inside the "same token loop" (see clientChanged)
    if (this._token) {
      return this._token;
    }
    // const tokenFromCookie = NgXCookies.getCookie("token");
    const tokenFromLocal = getToken();
    return tokenFromLocal ? tokenFromLocal : null;
  }

  public get firstName(): string {
    return this._firstName || localStorage.getItem('currentUserFirstName');
  }

  public get lastName(): string {
    return this._lastName || localStorage.getItem('currentUserLastName');
  }

  public get avatar(): string {
    return this._avatarPath || localStorage.getItem('currentUserAvatar');
  }

  public get email(): string {
    return this._email || localStorage.getItem('currentUserEmail');
  }

  public get intercomHash(): string {
    const hash = localStorage.getItem('currentUserIntercomHash');

    if (hash && hash !== 'undefined') {
      return this._intercomHash || hash;
    }

    return this._intercomHash;
  }

  public get crispHash(): string {
    const hash = localStorage.getItem('currentUserCrispHash');

    if (hash && hash !== 'undefined') {
      return this._crispHash || hash;
    }

    return this._crispHash;
  }

  public get departmentId(): number {
    // eslint-disable-next-line radix
    return this._departmentId || parseInt(localStorage.getItem('currentUserDepId'));
  }

  public get productType(): string {
    return this._productType || localStorage.getItem('currentUserProductType') || 'default';
  }

  public get controllerId(): number {
    return this._controllerId || parseInt(localStorage.getItem('currentUserControllerId'), 10);
  }

  public get planMetadata(): any {
    const data = localStorage.getItem('planMetadata');

    if (!data) {
      return undefined;
    }

    return this._planMetadata || JSON.parse(data);
  }

  public get flags(): string {
    return this._flags || localStorage.getItem('ec:account:flags');
  }

  public get access(): string {
    return this._access || localStorage.getItem('ec:account:access');
  }

  public get isServiced(): boolean {
    return this._serviced || (localStorage.getItem('ec:controller:serviced') === 'true');
  }

  private receivedAuthentication(data: any): void {
    // console.log(`Authentication Service. Authorize with token ${data.token}`);
    this.twoFactor.unlockAfterSetup();

    this._token = data.token;

    this.updateUserInfo(data);

    this.authorizedSubject.next(true);

    this.twoFactor.requiresSetup()
      .subscribe((required) => {
        if (required) {
          this.twoFactor.lockForSetup();
          this.router.navigate(['account/two-factor/enable'], { queryParams: { setupRequired: true, returnUrl: data.next } });
        } else {
          this.handleAuthNext(data);
        }
      }, (err) => {
        this.handleAuthNext(data);
      });
  }

  public redirectPublic(next: string) {
    this.redirect(next);
  }

  private handleAuthNext(data) {
    // this.setCookie();
    this.redirect(data.next, data.nextParams);
  }

  private clientChanged(payload) {
    const { newToken } = payload;

    // We have to update the token reference so requests will use the correct token.
    this._token = newToken || getToken();
    this._productType = localStorage.getItem('currentUserProductType');
  }

  updateUserInfo(data) {
    this._firstName = data.firstName || this._firstName;
    this._lastName = data.lastName || this._lastName;
    this._email = data.email || this._email;
    this._intercomHash = data.intercomHash || this._intercomHash;
    this._crispHash = data.crispHash || this._crispHash;
    this._departmentId = data.departmentId || this._departmentId;
    this._controllerId = data.controllerId || this._controllerId;
    this._planMetadata = data.planMetadata || this._planMetadata;
    this._avatarPath = data.avatarPath || this._avatarPath;
    this._flags = data.flags || this._flags;
    this._access = data.access || this._access;
    this._serviced = data.serviced || this._serviced || false;

    localStorage.setItem('currentUserFirstName', this._firstName);
    localStorage.setItem('currentUserLastName', this._lastName);
    localStorage.setItem('currentUserEmail', this._email);
    localStorage.setItem('currentUesrDepId', `${this._departmentId}`);
    // controllerId is only used for supervision until we find a better solution.
    localStorage.setItem('currentUserControllerId', `${this._controllerId}`);
    localStorage.setItem('currentUserAvatar', this._avatarPath);
    localStorage.setItem('ec:account:flags', JSON.stringify(this._flags));
    localStorage.setItem('ec:account:access', this._access);

    if (this._intercomHash) {
      localStorage.setItem('currentUserIntercomHash', this._intercomHash);
    }

    if (this._crispHash) {
      localStorage.setItem('currentUserCrispHash', this._crispHash);
    }

    if (this._planMetadata) {
      localStorage.setItem('planMetadata', JSON.stringify(this._planMetadata));
    }

    if (this._serviced) {
      localStorage.setItem('ec:controller:serviced', this._serviced.toString());
    }

    if (!localStorage.getItem('isInSupervisionMode')) {
      this._productType = data.productType || this._productType;
      localStorage.setItem('currentUserProductType', this._productType);
    }

    try {
      this.mixpanel.setUser({
        _id: data.Id || data.id,
        firstName: this._firstName,
        lastName: this._lastName,
        email: this._email,
        productType: localStorage.getItem('isInSupervisionMode') === 'true' ? 'supervisor' : this._productType,
        supervisorProfile: data.supervisorProfile
      });
    } catch (error) {
      // well we tried
    }
  }

  updateAvatar() {
    this._avatarPath = 'some-path';
    localStorage.setItem('currentUserAvatar', this._avatarPath);
  }

  updateProfile({ firstName, lastName }) {
    this._firstName = firstName;
    this._lastName = lastName;
    localStorage.setItem('currentUserFirstName', this._firstName);
    localStorage.setItem('currentUserLastName', this._lastName);
  }

  private redirect(next?: string, nextParams?: any): void {
    if (next) {
      this.router.navigate([next], {queryParams: nextParams || {}});
    } else if (this.redirectUrl && !this.redirectUrl.startsWith('/dashboard')) {
      this.router.navigate([this.redirectUrl]);
      this.redirectUrl = undefined;
    } else {
      // we only want to show the todolist to normal product users
      switch (this._productType) {
        case 'default':
          this.router.navigate(['dashboard']);
          break;
        case 'supervisor':
          this.router.navigate(['supervision/clients']);
          break;
      }
    }
  }

  private receivedLogout(): void {
    // console.log(`Authentication Service. Logout. Remove token ${this._token}`);
    this._token = null;
    localStorage.removeItem('currentUser');
    sessionStorage.removeItem('currentUser');
    localStorage.removeItem('currentUserFirstName');
    localStorage.removeItem('currentUserLastName');
    localStorage.removeItem('currentUserEmail');
    localStorage.removeItem('currentUserIntercomHash');
    localStorage.removeItem('currentUserCrispHash');
    localStorage.removeItem('currentUserProductType');
    localStorage.removeItem('currentUserControllerId');
    localStorage.removeItem('currentUserAvatar');
    localStorage.removeItem('ec:account:flags');
    localStorage.removeItem('ec:account:access');
    localStorage.removeItem('ec:controller:serviced');
    this._serviced = false;

    localStorage.removeItem('planMetadata');

    if (localStorage.getItem('isInSupervisionMode')) {
      localStorage.removeItem('isInSupervisionMode');
      localStorage.removeItem('supervisorToken');
    }

    localStorage.removeItem('supervision:expired');

    this.authorizedSubject.next(false);

    this.router.navigate(['home']);
  }

}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { zip } from 'rxjs';
import { filter, map, switchMap, take } from 'rxjs/operators';
import { AuditConfiguration, AuditQuestionnaire } from '../../../services/audits/audits.service';
import { VendorAudit, VendorAuditService } from '../../../services/vendors/vendor-audit.service';
import { Vendor, VendorsService } from '../../../services/vendors/vendors.service';
import { isJurisdiction } from '../../display-jurisdiction/util';

@Component({
  selector: 'app-vendor-audit',
  templateUrl: './audit.component.html',
  styleUrls: ['./audit.component.scss']
})
export class VendorAuditComponent implements OnInit {

  vendorAudit: VendorAudit = null;
  questionnaire: AuditQuestionnaire = null;
  auditConfiguration: AuditConfiguration = null;

  vendor: Vendor = null;

  vendorId: number = null;

  constructor(
    private activatedRoute: ActivatedRoute,
    private vendorService: VendorsService,
    private vendorAuditService: VendorAuditService,
    private translate: TranslateService
  ) {
    activatedRoute.params.subscribe((params) => {
      if (params.vendorId) {
        this.vendorId = parseInt(params.vendorId, 10);
        this.fetchData();
      }
    });
  }

  fetchData() {
    this.vendorService.getSingle(this.vendorId)
      .subscribe((vendor) => {
        this.vendor = vendor;
      });

    this.fetchAudit()
      .subscribe(([audit, questionnaire]) => {
        this.vendorAudit = audit;
        this.questionnaire = questionnaire;
        this.auditConfiguration = questionnaire;
      }, (error) => {
        console.log('vendor audit not found, creating new');
        this.createNewVendorAudit();
      });
  }

  createNewVendorAudit() {
    this.vendorAuditService.getVendorAuditTypes()
      .pipe(
        // filter out unfitting questionnaires
        map(types => {
          const currentLang = this.translate.currentLang || this.translate.defaultLang;
          return types.sort((a, b) => a.order - b.order).filter((a) => currentLang.startsWith(a.language) && isJurisdiction(currentLang, a.jurisdiction));
        }),
        // create new vendor audit
        switchMap(viableTypes => {
          if (viableTypes.length > 0) {
            return this.vendorAuditService.createVendorAudit(this.vendorId, viableTypes[0].type);
          }
          throw new Error('no viable type found');
        }),
        // fetch newly created audit
        switchMap(audit => this.fetchAudit())
      )
      .subscribe(([audit, questionnaire]) => {
        this.vendorAudit = audit;
        this.questionnaire = questionnaire;
        this.auditConfiguration = questionnaire;
      }, (error) => {
        // no types are available or creation failed, show generic error message instead
      });
  }

  fetchAudit() {
    return zip(
      this.vendorAuditService.getVendorAuditByVendorId(this.vendorId),
      this.vendorAuditService.getQuestionsForVendorAuditByVendorId(this.vendorId)
    );
  }

  ngOnInit(): void {
  }

}

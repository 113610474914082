<div class="content">
  <h1 class="title" *ngIf="intent == 'update'">{{'payment.update-payment-details.title' | translate}}</h1>
  <h1 class="title" *ngIf="intent == 're-enter'">Re-Enter Your Credit Card Information</h1>
  <form [ngClass]="{ 'pending': isLoading }" class="update-card-form" novalidate (ngSubmit)="updateCard($event)" [formGroup]="updateCardForm">
    <div *ngIf="cardError" class="card-error">
      {{ cardError }}
    </div>

    <mat-form-field class="form-field">
      <input matInput
            placeholder="{{'payment.create-subscription.payment.cardowner' | translate}}"
            type="text"
            name="name"
            formControlName="name"
            required/>
    </mat-form-field>

    <div id="card-element" class="form-field stripe-element"></div>

    <p class="update-hint">{{ 'payment.update-payment-details.hint' | translate }}</p>

    <button
      *ngIf="!isLoading"
      [disabled]="updateCardForm.invalid || isLoading"
      mat-raised-button
      color="primary"
      type="submit">
      {{ 'payment.update-payment-details.submit' | translate }}
    </button>
    <div class="loading-holder" *ngIf="isLoading">
      <mat-progress-spinner mode="indeterminate" color="primary"></mat-progress-spinner>
    </div>
  </form>
</div>

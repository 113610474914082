import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';

export enum StepState {
  current,
  past,
  future,
}

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'stepper-step',
  templateUrl: './step.component.html',
  styleUrls: ['./step.component.scss']
})
export class StepComponent implements OnInit {

  @Input() large = false;
  @Input() initial = false;
  @Input() id = undefined;
  @ViewChild('holder') public holder: ElementRef;
  @Output() activation: EventEmitter<void> = new EventEmitter<void>();

  index: number;
  public state: StepState = StepState.future;

  constructor() { }

  ngOnInit() {
  }

  activate() {
    this.state = StepState.current;
    this.activation.emit();
    this.holder.nativeElement.scrollIntoView({ behavior: 'smooth' });
  }

  pushBack() {
    this.state = StepState.past;
  }

  pushForward() {
    this.state = StepState.future;
  }

  public get states() { return StepState; }
}

import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { BusService } from '../../../services/bus.service';
import { EventsService } from '../../../services/events.service';
import { AuthenticationService } from "../../../services/authentication.service";


@Component({
  selector: 'app-confirm-invitation',
  templateUrl: './confirm-invitation.component.html',
  styleUrls: ['./confirm-invitation.component.scss']
})
export class ConfirmInvitationComponent implements OnInit, OnDestroy {

  wrongUser: boolean = false;
  inviteToken :string = null;
  invite: any;
  controller :any;

  constructor(
    private bus: BusService,
    private events: EventsService,
    private auth: AuthenticationService,
    private route: ActivatedRoute,
    private router: Router,
  ) {
    this.route.queryParams.subscribe(params => {
      this.inviteToken = params.invite;
      this.bus.publish(this.events.requested.data.controller.decodeInviteToken, this.inviteToken);
    });
  }

  ngOnInit() {
    this.subscribe();
  }

  ngOnDestroy() {
    this.unsubscribe();
  }

  updateInvite(response) {
    this.invite = response.invite;
    this.controller = response.controller;

    console.log(this.invite);

    if (this.auth.email && this.invite.email != this.auth.email) {
      this.wrongUser = true;
    }
  }

  confirm() {
    this.bus.publish(this.events.requested.action.controller.users.confirmInvite, this.inviteToken);
  }

  onConfirmed() {
    this.router.navigate(['']);
  }

  logout() {
    this.bus.publish(this.events.notified.user.logout);
    this.router.navigate(['/login'], {queryParams: {email: this.invite.email}});
  }

  subscribe() {
    this.bus.subscribe(this.events.received.data.controller.decodeInviteToken.success, this.updateInvite, this);
    this.bus.subscribe(this.events.received.action.controller.users.confirmInvite.success, this.onConfirmed, this);
  }

  unsubscribe() {
    this.bus.unsubscribe(this.events.received.data.controller.decodeInviteToken.success, this.updateInvite);
    this.bus.unsubscribe(this.events.received.action.controller.users.confirmInvite.success, this.onConfirmed);
  }
}

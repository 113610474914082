import { Component, EventEmitter, Output, Input, OnInit, OnChanges } from '@angular/core';
import { TodoService, TodoSearchCriteria } from '../../../services/todos/todo.service';
import { MatDialog } from '@angular/material/dialog';
import { TodoDialogComponent } from '../todo-dialog/todo-dialog.component';
import { Todo } from '../todo.interface';
import { TodoDetailsDialogComponent } from '../todo-details-dialog/todo-details-dialog.component';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { BusService } from '../../../services/bus.service';
import { ActivatedRoute, Route, Router } from '@angular/router';

@Component({
  selector: 'app-todos-list',
  templateUrl: './todos-list.component.html',
  styleUrls: ['./todos-list.component.scss'],
})
export class TodosListComponent implements OnInit, OnChanges {

  @Input() todosList: Todo[];
  @Input() showButtons: boolean;
  @Input() compactList = false;
  @Input() readonly = false;

  @Input() criteria: TodoSearchCriteria = {
    done: false,
    limit: 50,
    order: 'lastChange',
    keyword: '',
    user: 0,
  };

  keywordChange = new Subject<void>();
  @Output() fetch = new EventEmitter<TodoSearchCriteria>();

  usersList: any[] = [];
  currentPage = null;
  currentUserId = localStorage.getItem('currentUserID');
  updateTodo: any;

  currentTodo: Todo; // this is to store the todo data in case a user visits via url

  constructor(
    private todoService: TodoService,
    private dialog: MatDialog,
    private bus: BusService,
    private route: ActivatedRoute,
    private router: Router,
  ) {
  }

  ngOnInit(): void {
    this.fetch.emit(this.criteria);
    this.keywordChange.pipe(debounceTime(500)).subscribe(() => {
      this.fetch.emit(this.criteria);
      // console.log(this.criteria);
    });

    this.updateTodo = () => {
      this.refetch();
    };
    this.bus.subscribe('todo.update.event', this.updateTodo);

    this.route.queryParams.subscribe((params) => {
      if (params.todo) {
        this.todoService.getSingle(params.todo)
          .subscribe((t) => {
            this.openTodoDetails(null, t[0]);
          });
      }
    }).unsubscribe();
  }

  ngOnChanges(changes: { criteria?: TodoSearchCriteria }) {
    if (changes.criteria) {
      this.fetch.emit(this.criteria);
    }
  }

  fetchDone() {
    this.criteria.done = true;
    this.fetch.emit(this.criteria);
  }

  fetchInProgress() {
    this.criteria.done = false;
    this.fetch.emit(this.criteria);
  }

  refetch() {
    this.fetch.emit(this.criteria);
  }

  /**
   * Fetch current users todos
   */
  fetchCurrentUser(user?) {
    this.criteria.user = user ? null: parseInt(this.currentUserId, 10);
    this.fetch.emit(this.criteria);
  }

  /**
   * Opens an modal to create a new todo. After closing it will refresh the todos list.
   *
   * @param event
   */
  openTodo(event, todo?) {
    if (event) {
      event.stopPropagation();
    }
    this.dialog.open(TodoDialogComponent, {
      width: '500px',
      maxHeight: '90vh',
      data: todo,
    }).afterClosed().subscribe(() => {
      this.fetch.emit(this.criteria);
    });
  }

  openTodoDetails(event, todo) {
    if (event) {
      event.stopPropagation();
    }

    this.dialog.open(TodoDetailsDialogComponent, {
      width: '700px',
      maxHeight: '90vh',
      data: todo,
      autoFocus: false,
    }).afterClosed().subscribe(() => {
      this.dialog.closeAll();
    });
  }

  get now() {
    return new Date();
  }

}

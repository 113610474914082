<html>
<head>
  <meta content="text/html; charset=UTF-8" http-equiv="content-type">
  <style type="text/css">.lst-kix_list_2-6 > li:before {
    content: "\0025cf  "
  }

  .lst-kix_list_2-7 > li:before {
    content: "\0025cb  "
  }

  ul.lst-kix_list_1-0 {
    list-style-type: none
  }

  .lst-kix_list_2-4 > li:before {
    content: "\0025cb  "
  }

  .lst-kix_list_2-5 > li:before {
    content: "\0025a0  "
  }

  .lst-kix_list_2-8 > li:before {
    content: "\0025a0  "
  }

  ul.lst-kix_list_2-8 {
    list-style-type: none
  }

  ul.lst-kix_list_1-3 {
    list-style-type: none
  }

  ul.lst-kix_list_2-2 {
    list-style-type: none
  }

  .lst-kix_list_1-0 > li:before {
    content: "\0025cf  "
  }

  ul.lst-kix_list_1-4 {
    list-style-type: none
  }

  ul.lst-kix_list_2-3 {
    list-style-type: none
  }

  ul.lst-kix_list_1-1 {
    list-style-type: none
  }

  ul.lst-kix_list_2-0 {
    list-style-type: none
  }

  ul.lst-kix_list_1-2 {
    list-style-type: none
  }

  ul.lst-kix_list_2-1 {
    list-style-type: none
  }

  ul.lst-kix_list_1-7 {
    list-style-type: none
  }

  ul.lst-kix_list_2-6 {
    list-style-type: none
  }

  .lst-kix_list_1-1 > li:before {
    content: "\0025cb  "
  }

  .lst-kix_list_1-2 > li:before {
    content: "\0025a0  "
  }

  ul.lst-kix_list_1-8 {
    list-style-type: none
  }

  ul.lst-kix_list_2-7 {
    list-style-type: none
  }

  ul.lst-kix_list_1-5 {
    list-style-type: none
  }

  ul.lst-kix_list_2-4 {
    list-style-type: none
  }

  ul.lst-kix_list_1-6 {
    list-style-type: none
  }

  ul.lst-kix_list_2-5 {
    list-style-type: none
  }

  .lst-kix_list_1-3 > li:before {
    content: "\0025cf  "
  }

  .lst-kix_list_1-4 > li:before {
    content: "\0025cb  "
  }

  .lst-kix_list_1-7 > li:before {
    content: "\0025cb  "
  }

  .lst-kix_list_1-5 > li:before {
    content: "\0025a0  "
  }

  .lst-kix_list_1-6 > li:before {
    content: "\0025cf  "
  }

  .lst-kix_list_2-0 > li:before {
    content: "\0025cf  "
  }

  .lst-kix_list_2-1 > li:before {
    content: "\0025cb  "
  }

  .lst-kix_list_1-8 > li:before {
    content: "\0025a0  "
  }

  .lst-kix_list_2-2 > li:before {
    content: "\0025a0  "
  }

  .lst-kix_list_2-3 > li:before {
    content: "\0025cf  "
  }

  ol {
    margin: 0;
    padding: 0
  }

  table td, table th {
    padding: 0
  }

  .c31 {
    border-right-style: solid;
    padding-top: 10pt;
    border-top-width: 0pt;
    border-bottom-color: #dddddd;
    border-right-width: 0pt;
    padding-left: 7pt;
    border-left-color: #000000;
    padding-bottom: 9pt;
    line-height: 1.6000000000000003;
    border-right-color: #000000;
    border-left-width: 0pt;
    border-top-style: solid;
    margin-right: -7pt;
    margin-left: -7pt;
    border-left-style: solid;
    border-bottom-width: 0.8pt;
    border-top-color: #000000;
    border-bottom-style: dotted;
    orphans: 2;
    widows: 2;
    text-align: left;
    padding-right: 7pt
  }

  .c23 {
    border-right-style: solid;
    padding-top: 0pt;
    border-top-width: 0pt;
    border-bottom-color: #000000;
    border-right-width: 0pt;
    padding-left: 0pt;
    border-left-color: #000000;
    padding-bottom: 0pt;
    line-height: 1.0;
    border-right-color: #000000;
    border-left-width: 0pt;
    border-top-style: solid;
    border-left-style: solid;
    border-bottom-width: 0pt;
    border-top-color: #000000;
    border-bottom-style: solid;
    orphans: 2;
    widows: 2;
    text-align: left;
    padding-right: 0pt
  }

  .c1 {
    border-right-style: solid;
    padding-top: 18pt;
    border-top-width: 0pt;
    border-bottom-color: #000000;
    border-right-width: 0pt;
    padding-left: 0pt;
    border-left-color: #000000;
    padding-bottom: 0pt;
    line-height: 1.6000000000000003;
    border-right-color: #000000;
    border-left-width: 0pt;
    border-top-style: solid;
    border-left-style: solid;
    border-bottom-width: 0pt;
    border-top-color: #000000;
    border-bottom-style: solid;
    orphans: 2;
    widows: 2;
    text-align: left;
    padding-right: 0pt
  }

  .c16 {
    border-right-style: solid;
    padding-top: 14pt;
    border-top-width: 0pt;
    border-bottom-color: #000000;
    border-right-width: 0pt;
    padding-left: 0pt;
    border-left-color: #000000;
    padding-bottom: 0pt;
    line-height: 1.0;
    border-right-color: #000000;
    border-left-width: 0pt;
    border-top-style: solid;
    border-left-style: solid;
    border-bottom-width: 0pt;
    border-top-color: #000000;
    border-bottom-style: solid;
    orphans: 2;
    widows: 2;
    text-align: left;
    padding-right: 0pt
  }

  .c21 {
    border-right-style: solid;
    padding-top: 0pt;
    border-top-width: 0pt;
    border-bottom-color: #000000;
    border-right-width: 0pt;
    padding-left: 0pt;
    border-left-color: #000000;
    padding-bottom: 0pt;
    line-height: 1.6000000000000003;
    border-right-color: #000000;
    border-left-width: 0pt;
    border-top-style: solid;
    border-left-style: solid;
    border-bottom-width: 0pt;
    border-top-color: #000000;
    border-bottom-style: solid;
    orphans: 2;
    widows: 2;
    text-align: left;
    padding-right: 0pt
  }

  .c34 {
    border-right-style: solid;
    padding-top: 0pt;
    border-top-width: 0pt;
    border-bottom-color: #000000;
    border-right-width: 0pt;
    padding-left: 0pt;
    border-left-color: #000000;
    padding-bottom: 11pt;
    line-height: 1.6000000000000003;
    border-right-color: #000000;
    border-left-width: 0pt;
    border-top-style: solid;
    border-left-style: solid;
    border-bottom-width: 0pt;
    border-top-color: #000000;
    border-bottom-style: solid;
    orphans: 2;
    widows: 2;
    text-align: left;
    padding-right: 0pt
  }

  .c8 {
    border-right-style: solid;
    padding-top: 8pt;
    border-top-width: 0pt;
    border-bottom-color: #000000;
    border-right-width: 0pt;
    padding-left: 0pt;
    border-left-color: #000000;
    padding-bottom: 0pt;
    line-height: 1.6000000000000003;
    border-right-color: #000000;
    border-left-width: 0pt;
    border-top-style: solid;
    border-left-style: solid;
    border-bottom-width: 0pt;
    border-top-color: #000000;
    border-bottom-style: solid;
    orphans: 2;
    widows: 2;
    text-align: left;
    padding-right: 0pt
  }

  .c7 {
    border-right-style: solid;
    padding-top: 8pt;
    border-top-width: 0pt;
    border-bottom-color: #000000;
    border-right-width: 0pt;
    padding-left: 0pt;
    border-left-color: #000000;
    padding-bottom: 0pt;
    line-height: 1.6000000000000003;
    border-right-color: #000000;
    border-left-width: 0pt;
    border-top-style: solid;
    border-left-style: solid;
    border-bottom-width: 0pt;
    border-top-color: #000000;
    border-bottom-style: solid;
    orphans: 2;
    widows: 2;
    text-align: justify;
    padding-right: 0pt
  }

  .c19 {
    border-right-style: solid;
    padding-top: 14pt;
    border-top-width: 0pt;
    border-bottom-color: #000000;
    border-right-width: 0pt;
    padding-left: 0pt;
    padding-bottom: 0pt;
    line-height: 1.0;
    border-right-color: #000000;
    border-top-style: solid;
    margin-left: 36pt;
    border-bottom-width: 0pt;
    border-top-color: #000000;
    border-bottom-style: solid;
    orphans: 2;
    widows: 2;
    text-align: left;
    padding-right: 0pt
  }

  .c3 {
    border-right-style: solid;
    padding-top: 0pt;
    border-top-width: 0pt;
    border-bottom-color: #000000;
    border-right-width: 0pt;
    padding-left: 0pt;
    padding-bottom: 0pt;
    line-height: 1.6000000000000003;
    border-right-color: #000000;
    border-top-style: solid;
    margin-left: 36pt;
    border-bottom-width: 0pt;
    border-top-color: #000000;
    border-bottom-style: solid;
    orphans: 2;
    widows: 2;
    text-align: justify;
    padding-right: 0pt
  }

  .c11 {
    border-right-style: solid;
    padding-top: 8pt;
    border-top-width: 0pt;
    border-bottom-color: #000000;
    border-right-width: 0pt;
    padding-bottom: 0pt;
    line-height: 1.6000000000000003;
    border-right-color: #000000;
    border-top-style: solid;
    margin-left: 36pt;
    border-bottom-width: 0pt;
    border-top-color: #000000;
    border-bottom-style: solid;
    orphans: 2;
    widows: 2;
    text-align: left;
    padding-right: 0pt;
    height: 11pt
  }

  .c5 {
    border-right-style: solid;
    padding-top: 8pt;
    border-top-width: 0pt;
    border-bottom-color: #000000;
    border-right-width: 0pt;
    padding-left: 0pt;
    padding-bottom: 0pt;
    line-height: 1.6000000000000003;
    border-right-color: #000000;
    border-top-style: solid;
    margin-left: 36pt;
    border-bottom-width: 0pt;
    border-top-color: #000000;
    border-bottom-style: solid;
    orphans: 2;
    widows: 2;
    text-align: left;
    padding-right: 0pt
  }

  .c10 {
    border-right-style: solid;
    padding-top: 8pt;
    border-top-width: 0pt;
    border-bottom-color: #000000;
    border-right-width: 0pt;
    padding-left: 0pt;
    padding-bottom: 0pt;
    line-height: 1.6000000000000003;
    border-right-color: #000000;
    border-top-style: solid;
    margin-left: 36pt;
    border-bottom-width: 0pt;
    border-top-color: #000000;
    border-bottom-style: solid;
    orphans: 2;
    widows: 2;
    text-align: justify;
    padding-right: 0pt
  }

  .c25 {
    border-right-style: solid;
    padding-top: 0pt;
    border-top-width: 0pt;
    border-bottom-color: #000000;
    border-right-width: 0pt;
    padding-left: 0pt;
    padding-bottom: 0pt;
    line-height: 1.6000000000000003;
    border-right-color: #000000;
    border-top-style: solid;
    margin-left: 36pt;
    border-bottom-width: 0pt;
    border-top-color: #000000;
    border-bottom-style: solid;
    orphans: 2;
    widows: 2;
    text-align: left;
    padding-right: 0pt
  }

  .c29 {
    border-right-style: solid;
    padding-top: 0pt;
    border-top-width: 0pt;
    border-bottom-color: #000000;
    border-right-width: 0pt;
    padding-bottom: 0pt;
    line-height: 1.6000000000000003;
    border-right-color: #000000;
    border-top-style: solid;
    margin-left: 36pt;
    border-bottom-width: 0pt;
    border-top-color: #000000;
    border-bottom-style: solid;
    orphans: 2;
    widows: 2;
    text-align: left;
    padding-right: 0pt
  }

  .c15 {
    border-right-style: solid;
    padding-top: 9pt;
    border-top-width: 0pt;
    border-bottom-color: #dddddd;
    border-right-width: 0pt;
    padding-bottom: 9pt;
    line-height: 1.6000000000000003;
    border-right-color: #000000;
    border-top-style: solid;
    margin-right: -7pt;
    border-bottom-width: 0.8pt;
    border-top-color: #000000;
    border-bottom-style: dotted;
    orphans: 2;
    widows: 2;
    text-align: left;
    padding-right: 18pt
  }

  .c27 {
    border-right-style: solid;
    padding-top: 14pt;
    border-top-width: 0pt;
    border-bottom-color: #000000;
    border-right-width: 0pt;
    padding-bottom: 0pt;
    line-height: 1.0;
    border-right-color: #000000;
    border-top-style: solid;
    border-bottom-width: 0pt;
    border-top-color: #000000;
    border-bottom-style: solid;
    orphans: 2;
    widows: 2;
    text-align: left;
    padding-right: 0pt
  }

  .c6 {
    color: #000000;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 10.5pt;
    font-family: "Arial";
    font-style: normal
  }

  .c0 {
    color: #666666;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 10.5pt;
    font-family: "Arial";
    font-style: normal
  }

  .c4 {
    color: #333333;
    font-weight: 700;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 10pt;
    font-family: "Arial";
    font-style: normal
  }

  .c13 {
    color: #777777;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 10.5pt;
    font-family: "Arial";
    font-style: normal
  }

  .c26 {
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1.1500000000000001;
    orphans: 2;
    widows: 2;
    text-align: left
  }

  .c30 {
    padding-top: 24pt;
    padding-bottom: 11pt;
    line-height: 1.1500000000000001;
    orphans: 2;
    widows: 2;
    text-align: left
  }

  .c17 {
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-family: "Arial";
    font-style: normal
  }

  .c9 {
    font-weight: 700;
    text-decoration: none;
    vertical-align: baseline;
    font-family: "Arial";
    font-style: normal
  }

  .c20 {
    background-color: #ffffff;
    max-width: 451.5pt;
    padding: 72pt 72pt 72pt 72pt
  }

  .c18 {
    color: #141414;
    font-size: 12pt
  }

  .c14 {
    padding: 0;
    margin: 0
  }

  .c33 {
    color: #141414;
    font-size: 13.5pt
  }

  .c32 {
    color: #59636d;
    font-size: 12pt
  }

  .c2 {
    color: #59636d;
    font-size: 10pt
  }

  .c24 {
    font-size: 10.5pt;
    color: #777777
  }

  .c28 {
    color: #000000;
    font-size: 11pt
  }

  .c22 {
    font-weight: 700
  }

  .c12 {
    height: 11pt
  }

  .title {
    padding-top: 0pt;
    color: #000000;
    font-size: 26pt;
    padding-bottom: 3pt;
    font-family: "Arial";
    line-height: 1.1500000000000001;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
  }

  .subtitle {
    padding-top: 0pt;
    color: #666666;
    font-size: 15pt;
    padding-bottom: 16pt;
    font-family: "Arial";
    line-height: 1.1500000000000001;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
  }

  li {
    color: #000000;
    font-size: 11pt;
    font-family: "Arial"
  }

  p {
    margin: 0;
    color: #000000;
    font-size: 11pt;
    font-family: "Arial"
  }

  h1 {
    padding-top: 20pt;
    color: #000000;
    font-size: 20pt;
    padding-bottom: 6pt;
    font-family: "Arial";
    line-height: 1.1500000000000001;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
  }

  h2 {
    padding-top: 18pt;
    color: #000000;
    font-size: 16pt;
    padding-bottom: 6pt;
    font-family: "Arial";
    line-height: 1.1500000000000001;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
  }

  h3 {
    padding-top: 16pt;
    color: #434343;
    font-size: 14pt;
    padding-bottom: 4pt;
    font-family: "Arial";
    line-height: 1.1500000000000001;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
  }

  h4 {
    padding-top: 14pt;
    color: #666666;
    font-size: 12pt;
    padding-bottom: 4pt;
    font-family: "Arial";
    line-height: 1.1500000000000001;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
  }

  h5 {
    padding-top: 12pt;
    color: #666666;
    font-size: 11pt;
    padding-bottom: 4pt;
    font-family: "Arial";
    line-height: 1.1500000000000001;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
  }

  h6 {
    padding-top: 12pt;
    color: #666666;
    font-size: 11pt;
    padding-bottom: 4pt;
    font-family: "Arial";
    line-height: 1.1500000000000001;
    page-break-after: avoid;
    font-style: italic;
    orphans: 2;
    widows: 2;
    text-align: left
  }</style>
</head>
<body class="c20"><h2 class="c34" id="h.gjdgxs"><span class="c9 c33">Pol&iacute;tica de Cookies do ECOMPLY.io</span>
</h2>
<p class="c7"><span class="c13">Os cookies consistem em partes do c&oacute;digo instaladas no navegador que auxiliam o Propriet&aacute;rio a fornecer o Servi&ccedil;o de acordo com as finalidades descritas. Algumas das finalidades para as quais os cookies s&atilde;o instalados tamb&eacute;m podem exigir o consentimento do usu&aacute;rio.</span>
</p>
<p class="c7 c12"><span class="c13"></span></p>
<p class="c7"><span class="c13">Quando a instala&ccedil;&atilde;o de Cookies &eacute; baseada no consentimento, tal consentimento pode ser livremente retirado a qualquer momento, seguindo as instru&ccedil;&otilde;es fornecidas neste documento.</span>
</p>
<p class="c8 c12"><span class="c13"></span></p>
<h3 class="c1" id="h.30j0zll"><span class="c9 c18">Cookies t&eacute;cnicos and Cookies que servem fins estat&iacute;sticos agregados</span>
</h3>
<h3 class="c15" id="h.1fob9te"><span class="c4">Atividade estritamente necess&aacute;ria para o funcionamento do Servi&ccedil;o</span>
</h3>
<ul class="c14 lst-kix_list_2-0 start">
  <li class="c3"><span class="c2">Este site usa cookies para salvar a sess&atilde;o do usu&aacute;rio e realizar outras atividades estritamente necess&aacute;rias para a opera&ccedil;&atilde;o deste site, por exemplo, em rela&ccedil;&atilde;o &agrave; distribui&ccedil;&atilde;o de tr&aacute;fego.</span>
  </li>
</ul>
<h3 class="c15" id="h.3znysh7"><span class="c4">Atividade relacionada &agrave; economia de prefer&ecirc;ncias, otimiza&ccedil;&atilde;o e estat&iacute;sticas</span>
</h3>
<ul class="c14 lst-kix_list_2-0">
  <li class="c3"><span class="c2">Este site usa cookies para salvar as prefer&ecirc;ncias de navega&ccedil;&atilde;o e otimizar a experi&ecirc;ncia de navega&ccedil;&atilde;o do usu&aacute;rio. Entre esses Cookies, est&atilde;o, por exemplo, aqueles usados para definir prefer&ecirc;ncias de idioma e moeda ou para o gerenciamento de estat&iacute;sticas de primeira parte empregadas diretamente pelo propriet&aacute;rio do site.</span>
  </li>
</ul>
<h3 class="c1" id="h.2et92p0"><span class="c9 c18">Outros tipos de cookies ou cookies de terceiros instalados.</span>
</h3>
<p class="c21"><span class="c13">Alguns dos servi&ccedil;os listados abaixo coletam estat&iacute;sticas de forma an&ocirc;nima e agregada e podem n&atilde;o exigir o consentimento do Usu&aacute;rio ou podem ser gerenciados diretamente pelo Propriet&aacute;rio - dependendo de como eles s&atilde;o descritos - sem a ajuda de terceiros.</span>
</p>
<p class="c7"><span class="c13">Se quaisquer servi&ccedil;os operados por terceiros estiverem listados entre as ferramentas abaixo, eles poder&atilde;o ser usados para rastrear os h&aacute;bitos de navega&ccedil;&atilde;o dos usu&aacute;rios, al&eacute;m das informa&ccedil;&otilde;es especificadas aqui e sem o conhecimento do propriet&aacute;rio. Por favor, consulte a pol&iacute;tica de privacidade dos servi&ccedil;os listados para obter informa&ccedil;&otilde;es detalhadas.</span>
</p>
<h3 class="c15" id="h.tyjcwt"><span class="c4">Propagandas</span></h3>
<ul class="c14 lst-kix_list_1-0 start">
  <li class="c3"><span class="c2">Esse tipo de servi&ccedil;o permite que os dados do usu&aacute;rio sejam utilizados para fins de comunica&ccedil;&atilde;o de publicidade exibidos na forma de banners e outros an&uacute;ncios neste site, possivelmente com base nos interesses do usu&aacute;rio.</span>
  </li>
  <li class="c3"><span class="c2">Isso n&atilde;o significa que todos os dados pessoais sejam usados para essa finalidade. Informa&ccedil;&otilde;es e condi&ccedil;&otilde;es de uso s&atilde;o mostradas abaixo.</span>
  </li>
  <li class="c3"><span class="c2">Alguns dos servi&ccedil;os listados abaixo podem usar cookies para identificar usu&aacute;rios ou podem usar a t&eacute;cnica de redirecionamento comportamental, ou seja, exibir an&uacute;ncios adaptados aos interesses e ao comportamento do usu&aacute;rio, incluindo aqueles detectados fora deste site. Para mais informa&ccedil;&otilde;es, por favor, verifique as pol&iacute;ticas de privacidade dos servi&ccedil;os relevantes.</span>
  </li>
  <li class="c3"><span class="c2">Al&eacute;m de qualquer desativa&ccedil;&atilde;o oferecida por qualquer um dos servi&ccedil;os abaixo, o usu&aacute;rio pode desativar o uso de cookies de um servi&ccedil;o de terceiros visitando a p&aacute;gina de desativa&ccedil;&atilde;o da Network Advertising Initiative.</span>
  </li>
  <li class="c19"><h4 id="h.3dy6vkm" style="display:inline"><span class="c2 c22">Google AdSense (Google Inc.)</span>
  </h4></li>
  <li class="c10"><span class="c2">O Google AdSense &eacute; um servi&ccedil;o de publicidade fornecido pela Google Inc. Esse servi&ccedil;o usa o cookie &ldquo;Doubleclick&rdquo;, que rastreia o uso deste site e o comportamento do usu&aacute;rio em rela&ccedil;&atilde;o a an&uacute;ncios, produtos e servi&ccedil;os oferecidos.</span>
  </li>
  <li class="c10"><span class="c2">Os usu&aacute;rios podem decidir desativar todos os cookies da DoubleClick clicando em: google.com/settings/ads/onweb/optout.</span>
  </li>
  <li class="c5"><span class="c2">Dados Pessoais coletados: Cookies e Dados de Uso.</span></li>
  <li class="c5"><span class="c2">Local de processamento: Estados Unidos - Pol&iacute;tica de Privacidade - Recusar. Participante do Privacy Shield.</span>
  </li>
</ul>
<h3 class="c15" id="h.1t3h5sf"><span class="c4">Analytics</span></h3>
<ul class="c14 lst-kix_list_1-0">
  <li class="c25"><span class="c2">Os servi&ccedil;os contidos nesta se&ccedil;&atilde;o permitem que o propriet&aacute;rio monitore e analise o tr&aacute;fego da Web e possa ser usado para acompanhar o comportamento do usu&aacute;rio.</span>
  </li>
  <li class="c19"><h4 id="h.4d34og8" style="display:inline"><span class="c2 c22">Google Analytics com IP anonimizado (Google Inc.)</span>
  </h4></li>
  <li class="c5"><span class="c2">O Google Analytics &eacute; um servi&ccedil;o de an&aacute;lise da web fornecido pela Google Inc. (&quot;Google&quot;). O Google utiliza os dados coletados para acompanhar e examinar o uso deste site, preparar relat&oacute;rios sobre suas atividades e compartilh&aacute;-los com outros servi&ccedil;os do Google.</span>
  </li>
  <li class="c10"><span class="c2">O Google pode usar os dados coletados para contextualizar e personalizar os an&uacute;ncios de sua pr&oacute;pria rede de publicidade.</span>
  </li>
  <li class="c10"><span class="c2">Esta integra&ccedil;&atilde;o do Google Analytics anonimiza o seu endere&ccedil;o IP. Funciona encurtando os endere&ccedil;os IP dos Utilizadores nos estados membros da Uni&atilde;o Europeia ou noutros estados contratantes do Acordo sobre o Espa&ccedil;o Econ&oacute;mico Europeu. Somente em casos excepcionais, o endere&ccedil;o IP completo ser&aacute; enviado para um servidor do Google e encurtado nos EUA.</span>
  </li>
  <li class="c5"><span class="c2">Dados Pessoais coletados: Cookies e Dados de Uso.</span></li>
  <li class="c5"><span class="c2">Local de processamento: Estados Unidos - Pol&iacute;tica de Privacidade - Recusar. Participante do Privacy Shield.</span>
  </li>
</ul>
<h4 class="c27" id="h.2s8eyo1"><span
  class="c9 c2">Google AdWords acompanhamento de convers&otilde;es (Google Inc.)</span></h4>
<ul class="c14 lst-kix_list_1-0">
  <li class="c10"><span class="c2">O acompanhamento de convers&otilde;es do Google AdWords &eacute; um servi&ccedil;o de an&aacute;lise fornecido pela Google Inc. que conecta dados da rede de publicidade do Google AdWords a a&ccedil;&otilde;es realizadas neste site.</span>
  </li>
  <li class="c5"><span class="c2">Dados Pessoais coletados: Cookies e Dados de Uso.</span></li>
  <li class="c5"><span class="c2">Local de processamento: Estados Unidos - Pol&iacute;tica de Privacidade. Participante do Privacy Shield.</span>
  </li>
</ul>
<h3 class="c15" id="h.17dp8vu"><span class="c4">Displaying content from external platforms</span></h3>
<ul class="c14 lst-kix_list_1-0">
  <li class="c3"><span class="c2">Esse tipo de servi&ccedil;o permite que voc&ecirc; visualize o conte&uacute;do hospedado em plataformas externas diretamente nas p&aacute;ginas deste site e interaja com eles.</span>
  </li>
  <li class="c3"><span class="c2">Esse tipo de servi&ccedil;o ainda pode coletar dados de tr&aacute;fego da Web para as p&aacute;ginas em que o servi&ccedil;o est&aacute; instalado, mesmo quando os Usu&aacute;rios n&atilde;o o utilizam.</span>
  </li>
</ul>
<p class="c12 c29"><span class="c17 c2"></span></p><h4 class="c27" id="h.3rdcrjn"><span class="c9 c2">YouTube video ferramenta (Google Inc.)</span>
</h4>
<ul class="c14 lst-kix_list_1-0">
  <li class="c10"><span class="c2">O YouTube &eacute; um servi&ccedil;o de visualiza&ccedil;&atilde;o de conte&uacute;do de v&iacute;deo fornecido pela Google Inc. que permite que este site incorpore conte&uacute;do desse tipo em suas p&aacute;ginas.</span>
  </li>
  <li class="c10"><span class="c2">Dados Pessoais coletados: Cookies e Dados de Uso.</span></li>
  <li class="c10"><span class="c2">Local de processamento: Estados Unidos - Pol&iacute;tica de Privacidade. Participante do Privacy Shield.</span>
  </li>
</ul>
<h3 class="c15" id="h.26in1rg"><span class="c4">Mapeamento de calor e grava&ccedil;&atilde;o de sess&atilde;o</span>
</h3>
<ul class="c14 lst-kix_list_1-0">
  <li class="c3"><span class="c2">Os servi&ccedil;os de mapeamento de calor s&atilde;o usados para exibir as &aacute;reas de uma p&aacute;gina na qual os usu&aacute;rios movem o mouse com mais freq&uuml;&ecirc;ncia ou clicam. Isso mostra onde est&atilde;o os pontos de interesse. Esses servi&ccedil;os permitem monitorar e analisar o tr&aacute;fego da Web e acompanhar o comportamento do usu&aacute;rio.</span>
  </li>
  <li class="c3"><span class="c2">Alguns desses servi&ccedil;os podem gravar sess&otilde;es e disponibiliz&aacute;-las para posterior reprodu&ccedil;&atilde;o visual.</span>
  </li>
</ul>
<h4 class="c27" id="h.lnxbz9"><span class="c9 c2">Smartlook (Smartsupp.com, s.r.o.)</span></h4>
<ul class="c14 lst-kix_list_1-0">
  <li class="c5"><span class="c2">Smartlook &eacute; um servi&ccedil;o de grava&ccedil;&atilde;o de sess&atilde;o e mapeamento de calor fornecido pelo Smartsupp.com, s.r.o.</span>
  </li>
  <li class="c5"><span class="c2">Dados Pessoais coletados: Cookies, Dados de Uso e v&aacute;rios tipos de Dados, conforme especificado na pol&iacute;tica de privacidade do servi&ccedil;o.</span>
  </li>
  <li class="c5"><span
    class="c2">Local de processamento: Rep&uacute;blica Checa - Pol&iacute;tica de Privacidade.</span></li>
</ul>
<p class="c11"><span class="c17 c2"></span></p>
<h3 class="c15" id="h.35nkun2"><span class="c4">Gerenciamento de banco de dados do usu&aacute;rio</span></h3>
<ul class="c14 lst-kix_list_1-0">
  <li class="c25"><span class="c2">This type of service allows the Owner to build user profiles by starting from an email address, a personal name, or other information that the User provides to this Website, as well as to track User activities through analytics features. This Personal Data may also be matched with publicly available information about the User (such as social networks&#39; profiles) and used to build private profiles that the Owner can display and use for improving this Website.</span>
  </li>
  <li class="c25"><span class="c2">Some of these services may also enable the sending of timed messages to the User, such as emails based on specific actions performed on this Website.</span>
  </li>
</ul>
<h4 class="c27" id="h.1ksv4uv"><span class="c9 c2">Intercom (Intercom Inc.)</span></h4>
<ul class="c14 lst-kix_list_1-0">
  <li class="c5"><span class="c2">O Intercom &eacute; um servi&ccedil;o de gerenciamento de banco de dados do Usu&aacute;rio fornecido pela Intercom Inc. O Intercom tamb&eacute;m pode ser usado como um meio para comunica&ccedil;&otilde;es, seja por email ou atrav&eacute;s de mensagens dentro deste site.</span>
  </li>
  <li class="c5"><span class="c2">Dados Pessoais coletados: Cookies, endere&ccedil;o de e-mail, Dados de Uso e v&aacute;rios tipos de Dados, conforme especificado na pol&iacute;tica de privacidade do servi&ccedil;o.</span>
  </li>
  <li class="c5"><span class="c2">Local de processamento: Estados Unidos - Pol&iacute;tica de Privacidade.</span></li>
</ul>
<h3 class="c15" id="h.44sinio"><span class="c4">First promoter</span></h3>
<ul class="c14 lst-kix_list_1-0">
  <li class="c3"><span class="c2">O First Promoter &eacute; um servi&ccedil;o de afilia&ccedil;&atilde;o comercial fornecido pela Igil Webs SRL.</span>
  </li>
  <li class="c3"><span class="c2">Dados Pessoais coletados: Cookies, endere&ccedil;o de e-mail, nome, sobrenome e Dados de Uso.</span>
  </li>
  <li class="c3"><span class="c2">Local de processamento: Rom&eacute;nia</span></li>
  <li class="c3"><span class="c2">Pol&iacute;tica de Privacidade: https://firstpromoter.com/privacy</span></li>
</ul>
<h3 class="c15" id="h.2jxsxqh"><span class="c4">Salesflare</span></h3>
<ul class="c14 lst-kix_list_1-0">
  <li class="c3"><span class="c2">Os dados inseridos no formul&aacute;rio de contato e nos pedidos s&atilde;o armazenados em nosso sistema CRM Salesflare para fins de processamento de pedidos e hist&oacute;rico do cliente. O Salesflare &eacute; uma oferta da &ldquo;Salesflare BVBA, Technologiepark 19, B-9052 Ghent, B&eacute;lgica&rdquo;. A declara&ccedil;&atilde;o de privacidade de dados do Salesflare pode ser encontrada no seguinte link: https://salesflare.com/privacy.html</span>
  </li>
</ul>
<h3 class="c1" id="h.z337ya"><span class="c9 c18">Como fornecer ou retirar o consentimento para a instala&ccedil;&atilde;o de cookies</span>
</h3>
<p class="c7"><span class="c13">Al&eacute;m do que &eacute; especificado neste documento, o usu&aacute;rio pode gerenciar as prefer&ecirc;ncias de cookies diretamente de seu pr&oacute;prio navegador e impedir que - por exemplo - terceiros instalam cookies.</span>
</p>
<p class="c7"><span class="c13">Atrav&eacute;s das prefer&ecirc;ncias do navegador, tamb&eacute;m &eacute; poss&iacute;vel excluir os cookies instalados no passado, incluindo os cookies que podem ter salvo o consentimento inicial para a instala&ccedil;&atilde;o de cookies por este site.</span>
</p>
<p class="c7"><span class="c13">Os usu&aacute;rios podem, por exemplo, encontrar informa&ccedil;&otilde;es sobre como gerenciar cookies nos navegadores mais utilizados nos seguintes endere&ccedil;os: Google Chrome, Mozilla Firefox, Apple Safari e Microsoft Internet Explorer.</span>
</p>
<p class="c7"><span class="c13">Com rela&ccedil;&atilde;o aos Cookies instalados por terceiros, os Usu&aacute;rios podem gerenciar suas prefer&ecirc;ncias e retirar seu consentimento clicando no link de desativa&ccedil;&atilde;o relacionado (se fornecido), usando os meios fornecidos na pol&iacute;tica de privacidade do terceiro ou entrando em contato com o terceiro. .</span>
</p>
<p class="c7"><span class="c13">N&atilde;o obstante o acima exposto, o Propriet&aacute;rio informa que os Usu&aacute;rios podem seguir as instru&ccedil;&otilde;es fornecidas nas iniciativas subsequentemente vinculadas pela EDAA (EU), pela Network Advertising Initiative (EUA) e pela Digital Advertising Alliance (EUA), DAAC (Canad&aacute;), DDAI (Jap&atilde;o). ) ou outros servi&ccedil;os similares. Essas iniciativas permitem que os usu&aacute;rios selecionem suas prefer&ecirc;ncias de rastreamento para a maioria das ferramentas de publicidade. O Propriet&aacute;rio, portanto, recomenda que os Usu&aacute;rios fa&ccedil;am uso desses recursos al&eacute;m das informa&ccedil;&otilde;es fornecidas neste documento.</span>
</p>
<p class="c8 c12"><span class="c13"></span></p>
<h3 class="c1" id="h.3j2qqm3"><span class="c9 c18">Propriet&aacute;rio e Controlador de Dados.</span></h3>
<p class="c21"><span class="c13">ECOMPLY GmbH </span></p>
<p class="c21"><span class="c13">Bernt-Notke-Weg 1 </span></p>
<p class="c21"><span class="c13">81927 M&uuml;nchen</span></p>
<p class="c8"><span class="c22 c24">Owner contact email:</span><span class="c13">&nbsp;contact@ecomply.io</span></p>
<p class="c7"><span class="c13">Como a instala&ccedil;&atilde;o de cookies de terceiros e outros sistemas de rastreamento atrav&eacute;s dos servi&ccedil;os usados neste site n&atilde;o pode ser tecnicamente controlada pelo propriet&aacute;rio, quaisquer refer&ecirc;ncias espec&iacute;ficas a cookies e sistemas de rastreamento instalados por terceiros devem ser consideradas indicativas. </span>
</p>
<p class="c7"><span class="c13">Para obter informa&ccedil;&otilde;es completas, solicitamos que o Usu&aacute;rio consulte a pol&iacute;tica de privacidade dos respectivos servi&ccedil;os de terceiros listados neste documento.</span>
</p>
<p class="c7"><span class="c13">Dada a complexidade objetiva em torno da identifica&ccedil;&atilde;o de tecnologias baseadas em Cookies, os Usu&aacute;rios s&atilde;o encorajados a entrar em contato com o Propriet&aacute;rio, caso desejem receber mais informa&ccedil;&otilde;es sobre o uso de Cookies por este Site.</span>
</p>
<p class="c8 c12"><span class="c13"></span></p>
<h3 class="c31" id="h.1y810tw"><span class="c4">Definitions and legal references</span></h3><h4 class="c23"
                                                                                                id="h.4i7ojhp"><span
  class="c9 c2">Dados Pessoais (ou Data)</span></h4>
<p class="c7"><span class="c17 c2">Qualquer informa&ccedil;&atilde;o que direta, indiretamente ou em conex&atilde;o com outras informa&ccedil;&otilde;es - incluindo um n&uacute;mero de identifica&ccedil;&atilde;o pessoal - permite a identifica&ccedil;&atilde;o ou identificabilidade de uma pessoa f&iacute;sica.</span>
</p><h4 class="c16" id="h.2xcytpi"><span class="c9 c2">Dados Utilizados</span></h4>
<p class="c7"><span class="c17 c2">Informa&ccedil;&otilde;es coletadas automaticamente por meio deste site (ou servi&ccedil;os de terceiros empregados neste site), que podem incluir: os endere&ccedil;os IP ou nomes de dom&iacute;nio dos computadores utilizados pelos usu&aacute;rios que usam este site, os endere&ccedil;os de URI (Uniform Resource Identifier), o tempo da solicita&ccedil;&atilde;o, o m&eacute;todo utilizado para enviar a solicita&ccedil;&atilde;o ao servidor, o tamanho do arquivo recebido em resposta, o c&oacute;digo num&eacute;rico indicando o status da resposta do servidor (resultado bem-sucedido, erro, etc.), o pa&iacute;s de origem, os recursos do navegador e do sistema operacional utilizados pelo usu&aacute;rio, os v&aacute;rios detalhes de tempo por visita (por exemplo, o tempo gasto em cada p&aacute;gina dentro do Aplicativo) e os detalhes sobre o caminho seguido dentro do Aplicativo, com refer&ecirc;ncia especial &agrave; sequ&ecirc;ncia de p&aacute;ginas visitadas e outros par&acirc;metros sobre o sistema operacional do dispositivo e / ou o ambiente de TI do usu&aacute;rio.</span>
</p><h4 class="c16" id="h.1ci93xb"><span class="c9 c2">Usu&aacute;rio</span></h4>
<p class="c8"><span class="c17 c2">O indiv&iacute;duo usando este site que, a menos que especificado de outra forma, coincide com o Titular dos Dados.</span>
</p><h4 class="c16" id="h.3whwml4"><span class="c9 c2">Titular de Dados</span></h4>
<p class="c8"><span class="c17 c2">A pessoa natural a quem os dados pessoais se referem.</span></p><h4 class="c16"
                                                                                                       id="h.2bn6wsx">
  <span class="c9 c2">Processador de dados (ou Supervisor de Dados)</span></h4>
<p class="c7"><span class="c17 c2">A pessoa f&iacute;sica ou jur&iacute;dica, autoridade p&uacute;blica, ag&ecirc;ncia ou outro &oacute;rg&atilde;o que processa Dados Pessoais em nome do Controlador, conforme descrito nesta pol&iacute;tica de privacidade.</span>
</p><h4 class="c16" id="h.qsh70q"><span class="c9 c2">Controlador (ou propriet&aacute;rio)</span></h4>
<p class="c7"><span class="c17 c2">A pessoa singular ou colectiva, autoridade p&uacute;blica, ag&ecirc;ncia ou outro organismo que, individualmente ou em conjunto com outras pessoas, determine os objectivos e meios do processamento de Dados Pessoais, incluindo as medidas de seguran&ccedil;a relativas ao funcionamento e utiliza&ccedil;&atilde;o deste Website. O Controlador de Dados, a menos que especificado de outra forma, &eacute; o propriet&aacute;rio deste site.</span>
</p><h4 class="c16" id="h.3as4poj"><span class="c9 c2">Este Website (ou esta Aplica&ccedil;&atilde;o)</span></h4>
<p class="c8"><span class="c17 c2">Os meios pelos quais os Dados Pessoais do Usu&aacute;rio s&atilde;o coletados e processados.</span>
</p><h4 class="c16" id="h.1pxezwc"><span class="c2 c9">Servi&ccedil;o</span></h4>
<p class="c8"><span class="c17 c2">O servi&ccedil;o fornecido por este site, conforme descrito nos termos relativos (se dispon&iacute;vel) e neste site / aplicativo.</span>
</p><h4 class="c16" id="h.49x2ik5"><span class="c9 c2">Uni&atilde;o Europeia (ou EU)</span></h4>
<p class="c7"><span class="c17 c2">Salvo disposi&ccedil;&atilde;o em contr&aacute;rio, todas as refer&ecirc;ncias feitas neste documento &agrave; Uni&atilde;o Europ&eacute;ia incluem todos os estados membros atuais da Uni&atilde;o Europ&eacute;ia e do Espa&ccedil;o Econ&ocirc;mico Europeu.</span>
</p><h4 class="c16" id="h.2p2csry"><span class="c9 c2">Cookies</span></h4>
<p class="c8"><span class="c2 c17">Pequenos conjuntos de dados armazenados no dispositivo do usu&aacute;rio.</span></p>
<hr>
<p class="c12 c30"><span class="c17 c32"></span></p><h4 class="c16" id="h.147n2zr"><span class="c9 c2">Informa&ccedil;&atilde;o legal</span>
</h4>
<p class="c7"><span class="c17 c2">Esta declara&ccedil;&atilde;o de privacidade foi preparada com base em disposi&ccedil;&otilde;es de m&uacute;ltiplas legisla&ccedil;&otilde;es, incluindo o art. 13/14 do Regulamento (UE) 2016/679 (Regulamento Geral de Prote&ccedil;&atilde;o de Dados).</span>
</p>
<p class="c7"><span class="c17 c2">Esta pol&iacute;tica de privacidade refere-se apenas a este site, se n&atilde;o indicado de outra forma neste documento.</span>
</p>
<p class="c8 c12"><span class="c17 c2"></span></p>
<p class="c12 c26"><span class="c17 c28"></span></p></body>
</html>

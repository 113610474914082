import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { BusService } from 'app/services/bus.service';
import { EventsService } from 'app/services/events.service';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Params } from '@angular/router';
import { Crisp } from '../../../modules/crisp/wrapper';


@Component({
  selector: 'app-payment-billing',
  templateUrl: './billing.component.html',
  styleUrls: ['./billing.component.scss']
})
export class BillingComponent implements OnInit, OnDestroy {
  latestPaymentInfo = undefined;
  subscriptionDetails = undefined;

  showCardUpdateSuccess = false;

  constructor(
    private bus: BusService,
    private events: EventsService,
    private translate: TranslateService,
    private crisp: Crisp,
    private activatedRoute: ActivatedRoute
  ) {
    this.activatedRoute.queryParams.subscribe((params: Params) => {
      const cardUpdateSuccess = params['cardUpdate'];

      if (cardUpdateSuccess === 'success') {
        this.showCardUpdateSuccess = true;
      }
    });
  }

  get isIncomplete() {
    if (this.subscriptionDetails) {
      return this.subscriptionDetails.status === 'incomplete';
    }

    return false;
  }

  private subscribe() {
    this.bus.subscribe(this.events.received.payment.info.latest.success, this.latestInfoSuccess, this);
    this.bus.subscribe(this.events.received.payment.info.latest.failure, this.latestInfoFailure, this);

    this.bus.subscribe(this.events.received.payment.info.subscriptionDetails.success, this.subscriptionDetailsSuccess, this);
    this.bus.subscribe(this.events.received.payment.info.subscriptionDetails.failure, this.subscriptionDetailsFailure, this);
  }

  private loadData() {
    this.bus.publish(this.events.requested.payment.info.latest);
    this.bus.publish(this.events.requested.payment.info.subscriptionDetails);
  }

  // Data Actions

  private latestInfoSuccess(data) {
    this.latestPaymentInfo = data;
  }

  private latestInfoFailure(error) {
    this.latestPaymentInfo = undefined;
  }

  private subscriptionDetailsSuccess(data) {
    // Angular currency pipe needs uppercase
    data.planCurrency = data.planCurrency.toUpperCase();

    this.subscriptionDetails = data;

  }

  private subscriptionDetailsFailure(error) {
    this.subscriptionDetails = undefined;
  }

  // Getters

  get isStripeSubscription() {
    if (this.latestPaymentInfo) {
      return this.latestPaymentInfo.paymentType === 'stripe';
    } else {
      return true;
    }
  }

  get isSubscriptionActive() {
    if (!this.latestPaymentInfo) {
      return false;
    } else {
      if ((this.subscriptionDetails.status === 'active' ||
        this.subscriptionDetails.status === 'trialing') &&
        new Date(this.latestPaymentInfo.periodEnd) > new Date()) {
        return true;
      }
    }

    return false;
  }

  get lang() {
    return this.translate.currentLang || this.translate.defaultLang;
  }

  get isLifetimeFree() {
    if (this.subscriptionDetails?.discount) {
      return this.subscriptionDetails.discount.duration === 'forever' && this.subscriptionDetails.discount.percentOff === 100;
    }

    return false;
  }

  // Actions

  public cancelMessage() {
    this.crisp.startWithMessage('I want to cancel my subscription.');
  }

  public contactSupport() {
    this.crisp.open();
  }

  ngOnInit() {
    this.subscribe();
    this.loadData();
  }

  ngOnDestroy() {

  }
}

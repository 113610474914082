<div class="question-default">
    <mat-radio-group [disabled]="readonly" [value]="this.applies.originalValue" (change)="this.applies.submit($event.value)">
        <mat-radio-button value="true">{{ 'audit.form.question.default.applies' | translate }}</mat-radio-button>
        <mat-radio-button value="false">{{ 'audit.form.question.default.appliesNot' | translate }}</mat-radio-button>
    </mat-radio-group>

    <ng-content></ng-content>

    <div *ngIf="shouldShowChildren" class="children">
        <app-audit-form-question *ngFor="let question of question.children" [audit]="audit" [question]="question" [auditConfiguration]="auditConfiguration" path="{{ computedPath }}" nestLevel="{{ childNestLevel }}"></app-audit-form-question>
    </div>
</div>

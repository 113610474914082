<h1 mat-dialog-title>
{{'supervision.supervisorProfile.coSupervisors.invites.confirm.dialog.title' | translate}}
</h1>

<mat-dialog-content>
{{
  'supervision.supervisorProfile.coSupervisors.invites.confirm.dialog.hint.pt1' | translate
}} {{invite.profile.displayName}}{{
  'supervision.supervisorProfile.coSupervisors.invites.confirm.dialog.hint.pt2' | translate
}}
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-flat-button color="primary" mat-dialog-close>{{'word.ok' | translate}}</button>
</mat-dialog-actions>

<mat-dialog-content>
  <img src="/assets/apply-template.svg"/>
  <h1 mat-dialog-title>
    {{'supervision.templates.apply.title.part1' | translate }}
    {{template?.name}}
    {{'supervision.templates.apply.title.part2' | translate }}
    {{controller.controllerName}}
    {{'supervision.templates.apply.title.part3' | translate }}
  </h1>

  <p>
    {{'supervision.templates.apply.hint.part1' | translate }}
    {{controller.controllerName}}
    {{'supervision.templates.apply.hint.part2' | translate }}
    {{template?.name}}
    {{'supervision.templates.apply.hint.part3' | translate }}
    {{controller.controllerName}}
    {{'supervision.templates.apply.hint.part4' | translate }}
  </p>

  <div class="template-info" *ngIf="template">
    <div><span>
      {{'supervision.templates.apply.templateName' | translate }}
      </span> <b><a>{{template.name}}</a></b>
    </div>
    <div><span>
      {{'supervision.templates.apply.createdFrom' | translate }}
      {{template.sourceName}}
    </span></div>
    <div><span>
      {{'supervision.templates.apply.lastUpdated' | translate }}
      {{template.updatedAt | localizedDate : 'short'}}
    </span></div>
  </div>
  <div class="buttons">
    <button mat-button color="primary" (click)="selectTemplate()">
      {{'supervision.templates.apply.chooseAnother' | translate }}
    </button>
  </div>
  <br>
  <mat-divider></mat-divider>
  <br>
  <p>
    {{'supervision.templates.apply.settingsHint' | translate }}
  </p>
  <mat-slide-toggle color="primary" [(ngModel)]="settings.departments">
    {{'supervision.templates.settings.departments' | translate }}
  </mat-slide-toggle>
  <mat-slide-toggle color="primary" [(ngModel)]="settings.dpo">
    {{'supervision.templates.settings.dpo' | translate }}
  </mat-slide-toggle>
  <mat-slide-toggle color="primary" [(ngModel)]="settings.rpas">
    {{'supervision.templates.settings.rpas' | translate }}
  </mat-slide-toggle>
  <mat-slide-toggle color="primary" [(ngModel)]="settings.toms">
    {{'supervision.templates.settings.toms' | translate }}
  </mat-slide-toggle>
  <mat-slide-toggle color="primary" [(ngModel)]="settings.vendors">
    {{'supervision.templates.settings.vendors' | translate }}
  </mat-slide-toggle>

  <br/>

  <mat-expansion-panel displayMode="flat">
    <mat-expansion-panel-header>
      {{'supervision.templates.settings.advanced.title' | translate }}
      <mat-icon matTooltip="{{'supervision.templates.settings.advanced.hint' | translate }}">
        help
      </mat-icon>
    </mat-expansion-panel-header>
    <div class="advanced-settings">
      <mat-slide-toggle color="accent" [(ngModel)]="settings.companyInfo">
        {{'supervision.templates.settings.companyInfo' | translate }}
      </mat-slide-toggle>
      <mat-slide-toggle color="accent" [(ngModel)]="settings.dsars">
        {{'supervision.templates.settings.dsars' | translate }}
      </mat-slide-toggle>
      <mat-slide-toggle color="accent" [(ngModel)]="settings.incidents">
        {{'supervision.templates.settings.incidents' | translate }}
      </mat-slide-toggle>
    </div>
  </mat-expansion-panel>

  <br/>

</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>{{'word.cancel' | translate}}</button>
  <button mat-flat-button color="primary" (click)="done()" [disabled]="!template">
    {{'supervision.templates.apply.apply' | translate }}
  </button>
</mat-dialog-actions>

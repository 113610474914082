<ng-container *ngIf="!data.success">
  <img mat-card-image src="assets/not-found.svg"/>
  <h1 mat-dialog-title>{{ 'login.dialog.failed.titel' | translate }}</h1>
  <mat-dialog-content>
    {{ 'login.dialog.failed.description' | translate }}
  </mat-dialog-content>
</ng-container>

<ng-container *ngIf="data.success">
  <img mat-card-image src="assets/authentication.svg"/>
  <h1 mat-dialog-title>{{'word.success' | translate}}</h1>
  <mat-dialog-content>
    {{ 'login.dialog.success' | translate}}
  </mat-dialog-content>
</ng-container>

<mat-dialog-actions align="end">
  <a *ngIf="!data.success" mat-button (click)="dialogRef.close()" color="primary" routerLink="/forgot-password">{{ 'forgotpw.question' | translate }}</a>
  <button mat-button mat-dialog-close>{{ 'word.close' | translate }}</button>
</mat-dialog-actions>

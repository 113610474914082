import {Injectable} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';

import {BusService} from '../bus.service';
import {EventsService} from '../events.service';
import {
  IGetOptions,
  IPostOptions,
  RequestService
} from '../request.service';


interface Task {
  title: string;
  link: string;
  progress: number;
}

interface Step {
  index: number;
  milestone: boolean;
  task: Task;
}

interface WorkflowsResponse {
  title: string;
  codename: string;
  active: boolean;
  steps: Array<Step>;
}

interface ProgressTaskRequest {
  progress: number;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ProgressTaskResponse {}

interface TaskProgressResponse {
  progress: number;
}

interface MilestoneProgressResponse {
  aggregate: number;
}

@Injectable()
export class WorkflowTasksService {
  private api = {
    root: '/workflow-tasks',
    workfow: {
      root: '/workflow',
      activate: '/activate'
    },
    workflows: '/workflows',
    progressControllerTask: '/progress-controller-task',
    milestone: '/milestone',
  };

  constructor(
    private bus: BusService,
    private events: EventsService,
    private requestService: RequestService
  ) {
    this.subscribe();
  }

  public requestWorkflows() {
    this.requestService.get<WorkflowsResponse>({
      uri: this.api.root + this.api.workflows,
      handlers: {
        success: workflows => this.bus.publish(this.events.received.data.todos.workflows.success, workflows),
        error: error => this.bus.publish(this.events.received.data.todos.workflows.failure, error),
      }
    });
  }

  public milestoneOneProgress() {
    this.requestService.get<MilestoneProgressResponse>({
      uri: this.api.root + this.api.milestone + '/one',
      handlers: {
        success: response => this.bus.publish(this.events.received.data.todos.milestoneOne.success, response),
        error: error => this.bus.publish(this.events.received.data.todos.milestoneOne.failure, error),
      }
    });
  }

  public milestoneTwoProgress() {
    this.requestService.get<MilestoneProgressResponse>({
      uri: this.api.root + this.api.milestone + '/two',
      handlers: {
        success: response => this.bus.publish(this.events.received.data.todos.milestoneTwo.success, response),
        error: error => this.bus.publish(this.events.received.data.todos.milestoneTwo.failure, error),
      }
    });
  }

  public progressControllerTask(event: {
    progress: number,
    workflowCodename: string,
    taskCodename: string,
  }) {
    return this.requestService.post<ProgressTaskRequest, ProgressTaskResponse>({
      uri: `${this.api.root + this.api.progressControllerTask}/${event.workflowCodename}/${event.taskCodename}/`,
      body: {
        progress: event.progress,
      },
      handlers: {
        success: () => this.bus.publish(this.events.received.action.tasks.progressControllerTask.success),
        error: () => this.bus.publish(this.events.received.action.tasks.progressControllerTask.failure),
      }
    });
  }

  public controllerTaskProgress(event) {
    this.requestService.get<TaskProgressResponse>({
      uri: `${this.api.root + this.api.progressControllerTask}/${event.workflowCodename}/${event.taskCodename}/`,
      handlers: {
        success: response => this.bus.publish(this.events.received.data.todos.controllerTaskProgress.success, response),
        error: error => this.bus.publish(this.events.received.data.todos.controllerTaskProgress.failure, error),
      }
    });
  }

  public switchWorkflow(codename: string) {
    return this.requestService.post({
      uri: `${this.api.root}${this.api.workfow.root}/${codename}${this.api.workfow.activate}`,
      body: {},
      handlers: {
        success: () => {},
        error: () => {}
      }
    });
  }

  private subscribe() {
    this.bus.subscribe(this.events.requested.data.todos.workflows, this.requestWorkflows.bind(this));
    this.bus.subscribe(this.events.requested.action.tasks.progressControllerTask, this.progressControllerTask.bind(this));
    this.bus.subscribe(this.events.requested.data.todos.milestoneOneProgress, this.milestoneOneProgress.bind(this));
    this.bus.subscribe(this.events.requested.data.todos.milestoneTwoProgress, this.milestoneTwoProgress.bind(this));
    this.bus.subscribe(this.events.requested.data.todos.controllerTaskProgress, this.controllerTaskProgress.bind(this));
  }
}

import { Injectable } from '@angular/core';
import {RequestService} from '../request.service';
import {EventsService} from '../events.service';
import {BusService} from '../bus.service';

@Injectable()
export class BadgeService {
  private api = {
    root: '/badge',
    info: '/info',
  };

  constructor(
    private bus: BusService,
    private events: EventsService,
    private requests: RequestService,
  ) {
    this.subscribe();
  }

  get(value) {
    this.requests.get<any>({
      uri: this.api.root + '?jur=' + value,
      handlers: {
        success: info => this.bus.publish(this.events.received.data.badge.get.success, info),
        error: error => this.bus.publish(this.events.received.data.badge.get.failure, error),
      }
    });
  }

  info(token) {
    this.requests.get<any>({
      uri: this.api.root + this.api.info + '/' + token,
      handlers: {
        success: info => this.bus.publish(this.events.received.data.badge.info.success, info),
        error: error => this.bus.publish(this.events.received.data.badge.info.failure, error),
      }
    });
  }

  subscribe() {
    this.bus.subscribe(this.events.requested.data.badge.get, this.get.bind(this));
    this.bus.subscribe(this.events.requested.data.badge.info, this.info.bind(this));
  }
}

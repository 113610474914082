<h1 mat-dialog-title>{{ 'documents.select-path.dialog.title' | translate }}</h1>

<mat-dialog-content>
  <div class="mat-tree-node root" [class.selected]="selected === null">
    <div class="tree-item" (click)="selected=null">{{ 'documents.select-path.empty' | translate }}</div>

    <button *ngIf="!readonly" class="create-folder" matTooltip="{{ 'documents.select-path.dialog.addFolder' | translate }}" (click)="addPathFragment()" mat-icon-button>
      <mat-icon>create_new_folder</mat-icon>
    </button>
  </div>

  <mat-tree [dataSource]="dataSource" [treeControl]="treeControl" class="tree">
    <mat-tree-node *matTreeNodeDef="let node" [class.selected]="isSelected(node)" matTreeNodeToggle>
      <div class="tree-item" (click)="selected=node">{{ node.label }}</div>
      <button *ngIf="!readonly" class="create-folder" matTooltip="{{ 'documents.select-path.dialog.addFolder' | translate }}" (click)="addPathFragment(node)" mat-icon-button>
        <mat-icon>create_new_folder</mat-icon>
      </button>
    </mat-tree-node>
    <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
      <div class="mat-tree-node has-children" [class.selected]="isSelected(node)">
        <button mat-icon-button matTreeNodeToggle>
          <mat-icon class="mat-icon-rtl-mirror">
            {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
          </mat-icon>
        </button>
        <div class="tree-item" (click)="selected=node">{{ node.label }}</div>
        <button *ngIf="!readonly" class="create-folder" matTooltip="{{ 'documents.select-path.dialog.addFolder' | translate }}" (click)="addPathFragment(node)" mat-icon-button>
          <mat-icon>create_new_folder</mat-icon>
        </button>
      </div>
      <div [class.tree-invisible]="!treeControl.isExpanded(node)" class="children" role="group">
        <ng-container matTreeNodeOutlet></ng-container>
      </div>
    </mat-nested-tree-node>
  </mat-tree>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>{{ 'word.cancel' | translate }}</button>
  <button mat-button color="primary" (click)="submit()">{{ 'word.select' | translate }}</button>
</mat-dialog-actions>

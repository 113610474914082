<h1 mat-dialog-title>
  {{ 'dsar.settings.title' | translate }}
</h1>

<mat-dialog-content *ngIf="settings">
  <feature-access feature="dsar_forms" centered>
    {{ 'dsar.settings.link.title' | translate }}
    <div class="link" (click)="copyLink()">
      {{link}}
    </div>

    <div class="options-row">
      <div>
        <mat-form-field>
          <mat-label>{{ 'word.jurisdiction' | translate }}</mat-label>
          <mat-select [(ngModel)]="jurisdiction" required>
            <mat-option value="gdpr">{{ 'jurisdiction.gdpr' | translate }}</mat-option>
            <mat-option value="lgpd">{{ 'jurisdiction.lgpd' | translate }}</mat-option>
            <mat-option value="ndpr">{{ 'jurisdiction.ndpr' | translate }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="actions">
        <button mat-button mat-icon-button color="warn" tabindex="-1" (click)="renewLink()">
          <mat-icon>autorenew</mat-icon>
        </button>
        <button mat-flat-button color="primary" (click)="copyLink()">
          {{ 'dsar.settings.link.actions.copy' | translate }}
        </button>
      </div>
    </div>

    <br>
    <hr>
    <br>
    <mat-slide-toggle [(ngModel)]="settings.notifySupervisor" color="primary" (change)="update()">
      {{ 'dsar.settings.notify_supervisor.hint' | translate }}
    </mat-slide-toggle>
    <mat-slide-toggle [(ngModel)]="settings.notifyDpo" color="primary" (change)="update()">
      {{ 'dsar.settings.notify_dpo.hint' | translate }}
    </mat-slide-toggle>
    <br><br>
    <hr>
  </feature-access>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button color="primary" mat-dialog-close>
  {{'word.done' | translate}}
  </button>
</mat-dialog-actions>

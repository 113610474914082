<html>

<head>
    <meta content="text/html; charset=UTF-8" http-equiv="content-type">
    <style type="text/css">
        .lst-kix_hp5w6vum06pm-3>li:before {
            content: "" counter(lst-ctn-kix_hp5w6vum06pm-3, decimal) ". "
        }

        .lst-kix_hp5w6vum06pm-2>li:before {
            content: "" counter(lst-ctn-kix_hp5w6vum06pm-2, lower-roman) ". "
        }

        .lst-kix_hp5w6vum06pm-4>li:before {
            content: "" counter(lst-ctn-kix_hp5w6vum06pm-4, lower-latin) ". "
        }

        .lst-kix_29l9atrxjrcc-2>li:before {
            content: "" counter(lst-ctn-kix_29l9atrxjrcc-2, lower-roman) ". "
        }

        .lst-kix_29l9atrxjrcc-4>li:before {
            content: "" counter(lst-ctn-kix_29l9atrxjrcc-4, lower-latin) ". "
        }

        .lst-kix_hp5w6vum06pm-1>li:before {
            content: "" counter(lst-ctn-kix_hp5w6vum06pm-1, lower-latin) ". "
        }

        .lst-kix_hp5w6vum06pm-5>li:before {
            content: "" counter(lst-ctn-kix_hp5w6vum06pm-5, lower-roman) ". "
        }

        ol.lst-kix_jtgxrppdhs5b-1.start {
            counter-reset: lst-ctn-kix_jtgxrppdhs5b-1 0
        }

        .lst-kix_p1xw0vc0x49w-1>li {
            counter-increment: lst-ctn-kix_p1xw0vc0x49w-1
        }

        .lst-kix_29l9atrxjrcc-1>li:before {
            content: "" counter(lst-ctn-kix_29l9atrxjrcc-1, lower-latin) ". "
        }

        .lst-kix_29l9atrxjrcc-5>li:before {
            content: "" counter(lst-ctn-kix_29l9atrxjrcc-5, lower-roman) ". "
        }

        .lst-kix_29l9atrxjrcc-3>li:before {
            content: "" counter(lst-ctn-kix_29l9atrxjrcc-3, decimal) ". "
        }

        .lst-kix_nsh5xilkuf1a-1>li {
            counter-increment: lst-ctn-kix_nsh5xilkuf1a-1
        }

        .lst-kix_lr8xgw4v5wfg-0>li {
            counter-increment: lst-ctn-kix_lr8xgw4v5wfg-0
        }

        ol.lst-kix_lr8xgw4v5wfg-4.start {
            counter-reset: lst-ctn-kix_lr8xgw4v5wfg-4 0
        }

        ol.lst-kix_p1xw0vc0x49w-1.start {
            counter-reset: lst-ctn-kix_p1xw0vc0x49w-1 0
        }

        ol.lst-kix_jtgxrppdhs5b-8 {
            list-style-type: none
        }

        ol.lst-kix_jtgxrppdhs5b-6 {
            list-style-type: none
        }

        .lst-kix_hp5w6vum06pm-0>li:before {
            content: "" counter(lst-ctn-kix_hp5w6vum06pm-0, decimal) ". "
        }

        ol.lst-kix_t05e6zjr7d8z-2.start {
            counter-reset: lst-ctn-kix_t05e6zjr7d8z-2 0
        }

        .lst-kix_29l9atrxjrcc-6>li:before {
            content: "" counter(lst-ctn-kix_29l9atrxjrcc-6, decimal) ". "
        }

        .lst-kix_29l9atrxjrcc-8>li:before {
            content: "" counter(lst-ctn-kix_29l9atrxjrcc-8, lower-roman) ". "
        }

        ol.lst-kix_jtgxrppdhs5b-7 {
            list-style-type: none
        }

        .lst-kix_p1xw0vc0x49w-3>li:before {
            content: "" counter(lst-ctn-kix_p1xw0vc0x49w-3, decimal) ". "
        }

        ol.lst-kix_jtgxrppdhs5b-4 {
            list-style-type: none
        }

        .lst-kix_hp5w6vum06pm-3>li {
            counter-increment: lst-ctn-kix_hp5w6vum06pm-3
        }

        ol.lst-kix_jtgxrppdhs5b-5 {
            list-style-type: none
        }

        ol.lst-kix_jtgxrppdhs5b-2 {
            list-style-type: none
        }

        .lst-kix_29l9atrxjrcc-7>li:before {
            content: "" counter(lst-ctn-kix_29l9atrxjrcc-7, lower-latin) ". "
        }

        ol.lst-kix_jtgxrppdhs5b-3 {
            list-style-type: none
        }

        .lst-kix_p1xw0vc0x49w-4>li:before {
            content: "" counter(lst-ctn-kix_p1xw0vc0x49w-4, lower-latin) ". "
        }

        ol.lst-kix_jtgxrppdhs5b-0 {
            list-style-type: none
        }

        ol.lst-kix_nsh5xilkuf1a-3 {
            list-style-type: none
        }

        ol.lst-kix_jtgxrppdhs5b-1 {
            list-style-type: none
        }

        ol.lst-kix_nsh5xilkuf1a-2 {
            list-style-type: none
        }

        ol.lst-kix_nsh5xilkuf1a-1 {
            list-style-type: none
        }

        .lst-kix_p1xw0vc0x49w-5>li:before {
            content: "" counter(lst-ctn-kix_p1xw0vc0x49w-5, lower-roman) ". "
        }

        .lst-kix_p1xw0vc0x49w-7>li:before {
            content: "" counter(lst-ctn-kix_p1xw0vc0x49w-7, lower-latin) ". "
        }

        ol.lst-kix_nsh5xilkuf1a-0 {
            list-style-type: none
        }

        .lst-kix_p1xw0vc0x49w-6>li:before {
            content: "" counter(lst-ctn-kix_p1xw0vc0x49w-6, decimal) ". "
        }

        ol.lst-kix_nsh5xilkuf1a-1.start {
            counter-reset: lst-ctn-kix_nsh5xilkuf1a-1 0
        }

        ol.lst-kix_29l9atrxjrcc-3.start {
            counter-reset: lst-ctn-kix_29l9atrxjrcc-3 0
        }

        ol.lst-kix_t05e6zjr7d8z-8.start {
            counter-reset: lst-ctn-kix_t05e6zjr7d8z-8 0
        }

        .lst-kix_29l9atrxjrcc-8>li {
            counter-increment: lst-ctn-kix_29l9atrxjrcc-8
        }

        .lst-kix_p1xw0vc0x49w-8>li:before {
            content: "" counter(lst-ctn-kix_p1xw0vc0x49w-8, lower-roman) ". "
        }

        .lst-kix_vkmonltz3m4x-0>li {
            counter-increment: lst-ctn-kix_vkmonltz3m4x-0
        }

        .lst-kix_hp5w6vum06pm-1>li {
            counter-increment: lst-ctn-kix_hp5w6vum06pm-1
        }

        .lst-kix_29l9atrxjrcc-0>li:before {
            content: "" counter(lst-ctn-kix_29l9atrxjrcc-0, decimal) ". "
        }

        .lst-kix_t05e6zjr7d8z-0>li {
            counter-increment: lst-ctn-kix_t05e6zjr7d8z-0
        }

        .lst-kix_jtgxrppdhs5b-0>li {
            counter-increment: lst-ctn-kix_jtgxrppdhs5b-0
        }

        ol.lst-kix_hp5w6vum06pm-6.start {
            counter-reset: lst-ctn-kix_hp5w6vum06pm-6 0
        }

        ol.lst-kix_nsh5xilkuf1a-0.start {
            counter-reset: lst-ctn-kix_nsh5xilkuf1a-0 0
        }

        ol.lst-kix_t05e6zjr7d8z-5 {
            list-style-type: none
        }

        ol.lst-kix_t05e6zjr7d8z-4 {
            list-style-type: none
        }

        .lst-kix_nsh5xilkuf1a-3>li {
            counter-increment: lst-ctn-kix_nsh5xilkuf1a-3
        }

        ol.lst-kix_t05e6zjr7d8z-7 {
            list-style-type: none
        }

        ol.lst-kix_t05e6zjr7d8z-6 {
            list-style-type: none
        }

        .lst-kix_vkmonltz3m4x-2>li {
            counter-increment: lst-ctn-kix_vkmonltz3m4x-2
        }

        ol.lst-kix_t05e6zjr7d8z-1 {
            list-style-type: none
        }

        ol.lst-kix_t05e6zjr7d8z-0 {
            list-style-type: none
        }

        ol.lst-kix_t05e6zjr7d8z-3 {
            list-style-type: none
        }

        ol.lst-kix_t05e6zjr7d8z-2 {
            list-style-type: none
        }

        ol.lst-kix_29l9atrxjrcc-2 {
            list-style-type: none
        }

        ol.lst-kix_29l9atrxjrcc-3 {
            list-style-type: none
        }

        ol.lst-kix_29l9atrxjrcc-4 {
            list-style-type: none
        }

        ol.lst-kix_29l9atrxjrcc-5 {
            list-style-type: none
        }

        ol.lst-kix_nsh5xilkuf1a-7.start {
            counter-reset: lst-ctn-kix_nsh5xilkuf1a-7 0
        }

        ol.lst-kix_29l9atrxjrcc-6 {
            list-style-type: none
        }

        ol.lst-kix_jtgxrppdhs5b-6.start {
            counter-reset: lst-ctn-kix_jtgxrppdhs5b-6 0
        }

        ol.lst-kix_29l9atrxjrcc-7 {
            list-style-type: none
        }

        ol.lst-kix_t05e6zjr7d8z-8 {
            list-style-type: none
        }

        ol.lst-kix_29l9atrxjrcc-8 {
            list-style-type: none
        }

        ol.lst-kix_hp5w6vum06pm-0.start {
            counter-reset: lst-ctn-kix_hp5w6vum06pm-0 0
        }

        ol.lst-kix_t05e6zjr7d8z-7.start {
            counter-reset: lst-ctn-kix_t05e6zjr7d8z-7 0
        }

        ol.lst-kix_29l9atrxjrcc-4.start {
            counter-reset: lst-ctn-kix_29l9atrxjrcc-4 0
        }

        ol.lst-kix_p1xw0vc0x49w-7.start {
            counter-reset: lst-ctn-kix_p1xw0vc0x49w-7 0
        }

        ol.lst-kix_hp5w6vum06pm-7.start {
            counter-reset: lst-ctn-kix_hp5w6vum06pm-7 0
        }

        ol.lst-kix_29l9atrxjrcc-0 {
            list-style-type: none
        }

        ol.lst-kix_29l9atrxjrcc-1 {
            list-style-type: none
        }

        ol.lst-kix_nsh5xilkuf1a-8 {
            list-style-type: none
        }

        ol.lst-kix_nsh5xilkuf1a-7 {
            list-style-type: none
        }

        ol.lst-kix_nsh5xilkuf1a-6 {
            list-style-type: none
        }

        ol.lst-kix_nsh5xilkuf1a-5 {
            list-style-type: none
        }

        .lst-kix_29l9atrxjrcc-4>li {
            counter-increment: lst-ctn-kix_29l9atrxjrcc-4
        }

        ol.lst-kix_nsh5xilkuf1a-4 {
            list-style-type: none
        }

        ol.lst-kix_nsh5xilkuf1a-6.start {
            counter-reset: lst-ctn-kix_nsh5xilkuf1a-6 0
        }

        ol.lst-kix_jtgxrppdhs5b-7.start {
            counter-reset: lst-ctn-kix_jtgxrppdhs5b-7 0
        }

        .lst-kix_t05e6zjr7d8z-2>li {
            counter-increment: lst-ctn-kix_t05e6zjr7d8z-2
        }

        .lst-kix_p1xw0vc0x49w-2>li:before {
            content: "" counter(lst-ctn-kix_p1xw0vc0x49w-2, lower-roman) ". "
        }

        .lst-kix_p1xw0vc0x49w-5>li {
            counter-increment: lst-ctn-kix_p1xw0vc0x49w-5
        }

        .lst-kix_p1xw0vc0x49w-1>li:before {
            content: "" counter(lst-ctn-kix_p1xw0vc0x49w-1, lower-latin) ". "
        }

        ol.lst-kix_p1xw0vc0x49w-6.start {
            counter-reset: lst-ctn-kix_p1xw0vc0x49w-6 0
        }

        .lst-kix_p1xw0vc0x49w-0>li:before {
            content: "" counter(lst-ctn-kix_p1xw0vc0x49w-0, decimal) ". "
        }

        .lst-kix_jtgxrppdhs5b-7>li {
            counter-increment: lst-ctn-kix_jtgxrppdhs5b-7
        }

        ol.lst-kix_lr8xgw4v5wfg-5.start {
            counter-reset: lst-ctn-kix_lr8xgw4v5wfg-5 0
        }

        .lst-kix_hp5w6vum06pm-5>li {
            counter-increment: lst-ctn-kix_hp5w6vum06pm-5
        }

        .lst-kix_hp5w6vum06pm-7>li:before {
            content: "" counter(lst-ctn-kix_hp5w6vum06pm-7, lower-latin) ". "
        }

        .lst-kix_hp5w6vum06pm-8>li {
            counter-increment: lst-ctn-kix_hp5w6vum06pm-8
        }

        .lst-kix_hp5w6vum06pm-6>li:before {
            content: "" counter(lst-ctn-kix_hp5w6vum06pm-6, decimal) ". "
        }

        .lst-kix_hp5w6vum06pm-8>li:before {
            content: "" counter(lst-ctn-kix_hp5w6vum06pm-8, lower-roman) ". "
        }

        .lst-kix_p1xw0vc0x49w-8>li {
            counter-increment: lst-ctn-kix_p1xw0vc0x49w-8
        }

        ol.lst-kix_hp5w6vum06pm-1.start {
            counter-reset: lst-ctn-kix_hp5w6vum06pm-1 0
        }

        ol.lst-kix_jtgxrppdhs5b-0.start {
            counter-reset: lst-ctn-kix_jtgxrppdhs5b-0 0
        }

        ol.lst-kix_p1xw0vc0x49w-0.start {
            counter-reset: lst-ctn-kix_p1xw0vc0x49w-0 0
        }

        ol.lst-kix_lr8xgw4v5wfg-8 {
            list-style-type: none
        }

        .lst-kix_29l9atrxjrcc-1>li {
            counter-increment: lst-ctn-kix_29l9atrxjrcc-1
        }

        ol.lst-kix_29l9atrxjrcc-2.start {
            counter-reset: lst-ctn-kix_29l9atrxjrcc-2 0
        }

        ol.lst-kix_lr8xgw4v5wfg-6 {
            list-style-type: none
        }

        ol.lst-kix_lr8xgw4v5wfg-7 {
            list-style-type: none
        }

        ol.lst-kix_lr8xgw4v5wfg-4 {
            list-style-type: none
        }

        ol.lst-kix_lr8xgw4v5wfg-5 {
            list-style-type: none
        }

        ol.lst-kix_lr8xgw4v5wfg-2 {
            list-style-type: none
        }

        ol.lst-kix_lr8xgw4v5wfg-3 {
            list-style-type: none
        }

        ol.lst-kix_lr8xgw4v5wfg-0 {
            list-style-type: none
        }

        ol.lst-kix_lr8xgw4v5wfg-1 {
            list-style-type: none
        }

        .lst-kix_lr8xgw4v5wfg-5>li {
            counter-increment: lst-ctn-kix_lr8xgw4v5wfg-5
        }

        ol.lst-kix_nsh5xilkuf1a-2.start {
            counter-reset: lst-ctn-kix_nsh5xilkuf1a-2 0
        }

        .lst-kix_lr8xgw4v5wfg-6>li {
            counter-increment: lst-ctn-kix_lr8xgw4v5wfg-6
        }

        .lst-kix_jtgxrppdhs5b-6>li:before {
            content: "" counter(lst-ctn-kix_jtgxrppdhs5b-6, decimal) ". "
        }

        .lst-kix_jtgxrppdhs5b-8>li:before {
            content: "" counter(lst-ctn-kix_jtgxrppdhs5b-8, lower-roman) ". "
        }

        ol.lst-kix_hp5w6vum06pm-5.start {
            counter-reset: lst-ctn-kix_hp5w6vum06pm-5 0
        }

        .lst-kix_vkmonltz3m4x-6>li {
            counter-increment: lst-ctn-kix_vkmonltz3m4x-6
        }

        ol.lst-kix_hp5w6vum06pm-2.start {
            counter-reset: lst-ctn-kix_hp5w6vum06pm-2 0
        }

        ol.lst-kix_nsh5xilkuf1a-5.start {
            counter-reset: lst-ctn-kix_nsh5xilkuf1a-5 0
        }

        .lst-kix_jtgxrppdhs5b-4>li:before {
            content: "" counter(lst-ctn-kix_jtgxrppdhs5b-4, lower-latin) ". "
        }

        .lst-kix_t05e6zjr7d8z-5>li {
            counter-increment: lst-ctn-kix_t05e6zjr7d8z-5
        }

        .lst-kix_jtgxrppdhs5b-0>li:before {
            content: "" counter(lst-ctn-kix_jtgxrppdhs5b-0, decimal) ". "
        }

        .lst-kix_lr8xgw4v5wfg-2>li:before {
            content: "" counter(lst-ctn-kix_lr8xgw4v5wfg-2, lower-roman) ". "
        }

        .lst-kix_lr8xgw4v5wfg-6>li:before {
            content: "" counter(lst-ctn-kix_lr8xgw4v5wfg-6, decimal) ". "
        }

        ol.lst-kix_lr8xgw4v5wfg-6.start {
            counter-reset: lst-ctn-kix_lr8xgw4v5wfg-6 0
        }

        .lst-kix_jtgxrppdhs5b-2>li:before {
            content: "" counter(lst-ctn-kix_jtgxrppdhs5b-2, lower-roman) ". "
        }

        .lst-kix_lr8xgw4v5wfg-0>li:before {
            content: "" counter(lst-ctn-kix_lr8xgw4v5wfg-0, decimal) ". "
        }

        .lst-kix_lr8xgw4v5wfg-8>li:before {
            content: "" counter(lst-ctn-kix_lr8xgw4v5wfg-8, lower-roman) ". "
        }

        ol.lst-kix_p1xw0vc0x49w-2.start {
            counter-reset: lst-ctn-kix_p1xw0vc0x49w-2 0
        }

        ol.lst-kix_vkmonltz3m4x-0.start {
            counter-reset: lst-ctn-kix_vkmonltz3m4x-0 0
        }

        ol.lst-kix_p1xw0vc0x49w-5.start {
            counter-reset: lst-ctn-kix_p1xw0vc0x49w-5 0
        }

        .lst-kix_lr8xgw4v5wfg-4>li {
            counter-increment: lst-ctn-kix_lr8xgw4v5wfg-4
        }

        .lst-kix_jtgxrppdhs5b-3>li {
            counter-increment: lst-ctn-kix_jtgxrppdhs5b-3
        }

        ol.lst-kix_jtgxrppdhs5b-8.start {
            counter-reset: lst-ctn-kix_jtgxrppdhs5b-8 0
        }

        .lst-kix_lr8xgw4v5wfg-4>li:before {
            content: "" counter(lst-ctn-kix_lr8xgw4v5wfg-4, lower-latin) ". "
        }

        .lst-kix_nsh5xilkuf1a-3>li:before {
            content: "" counter(lst-ctn-kix_nsh5xilkuf1a-3, decimal) ". "
        }

        .lst-kix_nsh5xilkuf1a-5>li:before {
            content: "" counter(lst-ctn-kix_nsh5xilkuf1a-5, lower-roman) ". "
        }

        .lst-kix_p1xw0vc0x49w-4>li {
            counter-increment: lst-ctn-kix_p1xw0vc0x49w-4
        }

        ol.lst-kix_p1xw0vc0x49w-3.start {
            counter-reset: lst-ctn-kix_p1xw0vc0x49w-3 0
        }

        ol.lst-kix_hp5w6vum06pm-4.start {
            counter-reset: lst-ctn-kix_hp5w6vum06pm-4 0
        }

        ol.lst-kix_nsh5xilkuf1a-3.start {
            counter-reset: lst-ctn-kix_nsh5xilkuf1a-3 0
        }

        .lst-kix_nsh5xilkuf1a-8>li {
            counter-increment: lst-ctn-kix_nsh5xilkuf1a-8
        }

        .lst-kix_nsh5xilkuf1a-1>li:before {
            content: "" counter(lst-ctn-kix_nsh5xilkuf1a-1, lower-latin) ". "
        }

        .lst-kix_p1xw0vc0x49w-3>li {
            counter-increment: lst-ctn-kix_p1xw0vc0x49w-3
        }

        ol.lst-kix_lr8xgw4v5wfg-8.start {
            counter-reset: lst-ctn-kix_lr8xgw4v5wfg-8 0
        }

        ol.lst-kix_p1xw0vc0x49w-4.start {
            counter-reset: lst-ctn-kix_p1xw0vc0x49w-4 0
        }

        ol.lst-kix_29l9atrxjrcc-1.start {
            counter-reset: lst-ctn-kix_29l9atrxjrcc-1 0
        }

        .lst-kix_jtgxrppdhs5b-2>li {
            counter-increment: lst-ctn-kix_jtgxrppdhs5b-2
        }

        .lst-kix_nsh5xilkuf1a-2>li {
            counter-increment: lst-ctn-kix_nsh5xilkuf1a-2
        }

        .lst-kix_29l9atrxjrcc-0>li {
            counter-increment: lst-ctn-kix_29l9atrxjrcc-0
        }

        .lst-kix_t05e6zjr7d8z-4>li {
            counter-increment: lst-ctn-kix_t05e6zjr7d8z-4
        }

        .lst-kix_29l9atrxjrcc-6>li {
            counter-increment: lst-ctn-kix_29l9atrxjrcc-6
        }

        .lst-kix_vkmonltz3m4x-1>li {
            counter-increment: lst-ctn-kix_vkmonltz3m4x-1
        }

        .lst-kix_nsh5xilkuf1a-7>li:before {
            content: "" counter(lst-ctn-kix_nsh5xilkuf1a-7, lower-latin) ". "
        }

        .lst-kix_vkmonltz3m4x-7>li {
            counter-increment: lst-ctn-kix_vkmonltz3m4x-7
        }

        .lst-kix_29l9atrxjrcc-7>li {
            counter-increment: lst-ctn-kix_29l9atrxjrcc-7
        }

        ol.lst-kix_lr8xgw4v5wfg-7.start {
            counter-reset: lst-ctn-kix_lr8xgw4v5wfg-7 0
        }

        .lst-kix_nsh5xilkuf1a-0>li {
            counter-increment: lst-ctn-kix_nsh5xilkuf1a-0
        }

        ol.lst-kix_vkmonltz3m4x-2.start {
            counter-reset: lst-ctn-kix_vkmonltz3m4x-2 0
        }

        .lst-kix_hp5w6vum06pm-2>li {
            counter-increment: lst-ctn-kix_hp5w6vum06pm-2
        }

        .lst-kix_t05e6zjr7d8z-1>li {
            counter-increment: lst-ctn-kix_t05e6zjr7d8z-1
        }

        .lst-kix_p1xw0vc0x49w-0>li {
            counter-increment: lst-ctn-kix_p1xw0vc0x49w-0
        }

        ol.lst-kix_jtgxrppdhs5b-4.start {
            counter-reset: lst-ctn-kix_jtgxrppdhs5b-4 0
        }

        .lst-kix_jtgxrppdhs5b-8>li {
            counter-increment: lst-ctn-kix_jtgxrppdhs5b-8
        }

        ol.lst-kix_vkmonltz3m4x-6 {
            list-style-type: none
        }

        ol.lst-kix_hp5w6vum06pm-3.start {
            counter-reset: lst-ctn-kix_hp5w6vum06pm-3 0
        }

        ol.lst-kix_vkmonltz3m4x-7 {
            list-style-type: none
        }

        ol.lst-kix_vkmonltz3m4x-8 {
            list-style-type: none
        }

        ol.lst-kix_t05e6zjr7d8z-5.start {
            counter-reset: lst-ctn-kix_t05e6zjr7d8z-5 0
        }

        ol.lst-kix_29l9atrxjrcc-6.start {
            counter-reset: lst-ctn-kix_29l9atrxjrcc-6 0
        }

        ol.lst-kix_vkmonltz3m4x-0 {
            list-style-type: none
        }

        .lst-kix_29l9atrxjrcc-5>li {
            counter-increment: lst-ctn-kix_29l9atrxjrcc-5
        }

        ol.lst-kix_vkmonltz3m4x-1 {
            list-style-type: none
        }

        ol.lst-kix_vkmonltz3m4x-2 {
            list-style-type: none
        }

        ol.lst-kix_vkmonltz3m4x-3 {
            list-style-type: none
        }

        ol.lst-kix_vkmonltz3m4x-4 {
            list-style-type: none
        }

        ol.lst-kix_vkmonltz3m4x-5 {
            list-style-type: none
        }

        .lst-kix_vkmonltz3m4x-8>li:before {
            content: "" counter(lst-ctn-kix_vkmonltz3m4x-8, lower-roman) ". "
        }

        .lst-kix_hp5w6vum06pm-4>li {
            counter-increment: lst-ctn-kix_hp5w6vum06pm-4
        }

        ol.lst-kix_vkmonltz3m4x-7.start {
            counter-reset: lst-ctn-kix_vkmonltz3m4x-7 0
        }

        ol.lst-kix_29l9atrxjrcc-0.start {
            counter-reset: lst-ctn-kix_29l9atrxjrcc-0 0
        }

        .lst-kix_lr8xgw4v5wfg-1>li {
            counter-increment: lst-ctn-kix_lr8xgw4v5wfg-1
        }

        ol.lst-kix_nsh5xilkuf1a-4.start {
            counter-reset: lst-ctn-kix_nsh5xilkuf1a-4 0
        }

        .lst-kix_vkmonltz3m4x-0>li:before {
            content: "" counter(lst-ctn-kix_vkmonltz3m4x-0, decimal) ". "
        }

        .lst-kix_t05e6zjr7d8z-3>li:before {
            content: "" counter(lst-ctn-kix_t05e6zjr7d8z-3, decimal) ". "
        }

        .lst-kix_vkmonltz3m4x-2>li:before {
            content: "" counter(lst-ctn-kix_vkmonltz3m4x-2, lower-roman) ". "
        }

        .lst-kix_vkmonltz3m4x-1>li:before {
            content: "" counter(lst-ctn-kix_vkmonltz3m4x-1, lower-latin) ". "
        }

        .lst-kix_t05e6zjr7d8z-0>li:before {
            content: "" counter(lst-ctn-kix_t05e6zjr7d8z-0, decimal) ". "
        }

        .lst-kix_t05e6zjr7d8z-4>li:before {
            content: "" counter(lst-ctn-kix_t05e6zjr7d8z-4, lower-latin) ". "
        }

        .lst-kix_t05e6zjr7d8z-3>li {
            counter-increment: lst-ctn-kix_t05e6zjr7d8z-3
        }

        .lst-kix_t05e6zjr7d8z-1>li:before {
            content: "" counter(lst-ctn-kix_t05e6zjr7d8z-1, lower-latin) ". "
        }

        .lst-kix_vkmonltz3m4x-7>li:before {
            content: "" counter(lst-ctn-kix_vkmonltz3m4x-7, lower-latin) ". "
        }

        .lst-kix_t05e6zjr7d8z-2>li:before {
            content: "" counter(lst-ctn-kix_t05e6zjr7d8z-2, lower-roman) ". "
        }

        .lst-kix_vkmonltz3m4x-8>li {
            counter-increment: lst-ctn-kix_vkmonltz3m4x-8
        }

        .lst-kix_lr8xgw4v5wfg-3>li {
            counter-increment: lst-ctn-kix_lr8xgw4v5wfg-3
        }

        ol.lst-kix_hp5w6vum06pm-3 {
            list-style-type: none
        }

        ol.lst-kix_hp5w6vum06pm-2 {
            list-style-type: none
        }

        ol.lst-kix_hp5w6vum06pm-5 {
            list-style-type: none
        }

        .lst-kix_vkmonltz3m4x-3>li {
            counter-increment: lst-ctn-kix_vkmonltz3m4x-3
        }

        .lst-kix_jtgxrppdhs5b-1>li {
            counter-increment: lst-ctn-kix_jtgxrppdhs5b-1
        }

        ol.lst-kix_hp5w6vum06pm-4 {
            list-style-type: none
        }

        ol.lst-kix_hp5w6vum06pm-7 {
            list-style-type: none
        }

        .lst-kix_vkmonltz3m4x-6>li:before {
            content: "" counter(lst-ctn-kix_vkmonltz3m4x-6, decimal) ". "
        }

        .lst-kix_nsh5xilkuf1a-4>li {
            counter-increment: lst-ctn-kix_nsh5xilkuf1a-4
        }

        ol.lst-kix_hp5w6vum06pm-6 {
            list-style-type: none
        }

        .lst-kix_vkmonltz3m4x-5>li:before {
            content: "" counter(lst-ctn-kix_vkmonltz3m4x-5, lower-roman) ". "
        }

        ol.lst-kix_hp5w6vum06pm-8 {
            list-style-type: none
        }

        .lst-kix_vkmonltz3m4x-4>li:before {
            content: "" counter(lst-ctn-kix_vkmonltz3m4x-4, lower-latin) ". "
        }

        .lst-kix_t05e6zjr7d8z-8>li {
            counter-increment: lst-ctn-kix_t05e6zjr7d8z-8
        }

        ol.lst-kix_vkmonltz3m4x-1.start {
            counter-reset: lst-ctn-kix_vkmonltz3m4x-1 0
        }

        .lst-kix_vkmonltz3m4x-3>li:before {
            content: "" counter(lst-ctn-kix_vkmonltz3m4x-3, decimal) ". "
        }

        .lst-kix_p1xw0vc0x49w-2>li {
            counter-increment: lst-ctn-kix_p1xw0vc0x49w-2
        }

        ol.lst-kix_hp5w6vum06pm-8.start {
            counter-reset: lst-ctn-kix_hp5w6vum06pm-8 0
        }

        ol.lst-kix_vkmonltz3m4x-8.start {
            counter-reset: lst-ctn-kix_vkmonltz3m4x-8 0
        }

        .lst-kix_nsh5xilkuf1a-7>li {
            counter-increment: lst-ctn-kix_nsh5xilkuf1a-7
        }

        ol.lst-kix_t05e6zjr7d8z-0.start {
            counter-reset: lst-ctn-kix_t05e6zjr7d8z-0 0
        }

        ol.lst-kix_hp5w6vum06pm-1 {
            list-style-type: none
        }

        ol.lst-kix_hp5w6vum06pm-0 {
            list-style-type: none
        }

        .lst-kix_t05e6zjr7d8z-8>li:before {
            content: "" counter(lst-ctn-kix_t05e6zjr7d8z-8, lower-roman) ". "
        }

        .lst-kix_t05e6zjr7d8z-6>li:before {
            content: "" counter(lst-ctn-kix_t05e6zjr7d8z-6, decimal) ". "
        }

        .lst-kix_t05e6zjr7d8z-7>li:before {
            content: "" counter(lst-ctn-kix_t05e6zjr7d8z-7, lower-latin) ". "
        }

        .lst-kix_t05e6zjr7d8z-5>li:before {
            content: "" counter(lst-ctn-kix_t05e6zjr7d8z-5, lower-roman) ". "
        }

        ol.lst-kix_29l9atrxjrcc-5.start {
            counter-reset: lst-ctn-kix_29l9atrxjrcc-5 0
        }

        ol.lst-kix_t05e6zjr7d8z-6.start {
            counter-reset: lst-ctn-kix_t05e6zjr7d8z-6 0
        }

        .lst-kix_vkmonltz3m4x-5>li {
            counter-increment: lst-ctn-kix_vkmonltz3m4x-5
        }

        ol.lst-kix_lr8xgw4v5wfg-0.start {
            counter-reset: lst-ctn-kix_lr8xgw4v5wfg-0 0
        }

        .lst-kix_t05e6zjr7d8z-6>li {
            counter-increment: lst-ctn-kix_t05e6zjr7d8z-6
        }

        .lst-kix_hp5w6vum06pm-7>li {
            counter-increment: lst-ctn-kix_hp5w6vum06pm-7
        }

        .lst-kix_jtgxrppdhs5b-4>li {
            counter-increment: lst-ctn-kix_jtgxrppdhs5b-4
        }

        .lst-kix_p1xw0vc0x49w-7>li {
            counter-increment: lst-ctn-kix_p1xw0vc0x49w-7
        }

        .lst-kix_p1xw0vc0x49w-6>li {
            counter-increment: lst-ctn-kix_p1xw0vc0x49w-6
        }

        ol.lst-kix_vkmonltz3m4x-6.start {
            counter-reset: lst-ctn-kix_vkmonltz3m4x-6 0
        }

        .lst-kix_jtgxrppdhs5b-7>li:before {
            content: "" counter(lst-ctn-kix_jtgxrppdhs5b-7, lower-latin) ". "
        }

        ol.lst-kix_nsh5xilkuf1a-8.start {
            counter-reset: lst-ctn-kix_nsh5xilkuf1a-8 0
        }

        .lst-kix_jtgxrppdhs5b-5>li:before {
            content: "" counter(lst-ctn-kix_jtgxrppdhs5b-5, lower-roman) ". "
        }

        .lst-kix_hp5w6vum06pm-6>li {
            counter-increment: lst-ctn-kix_hp5w6vum06pm-6
        }

        .lst-kix_jtgxrppdhs5b-3>li:before {
            content: "" counter(lst-ctn-kix_jtgxrppdhs5b-3, decimal) ". "
        }

        .lst-kix_lr8xgw4v5wfg-1>li:before {
            content: "" counter(lst-ctn-kix_lr8xgw4v5wfg-1, decimal) ". "
        }

        .lst-kix_lr8xgw4v5wfg-5>li:before {
            content: "" counter(lst-ctn-kix_lr8xgw4v5wfg-5, lower-roman) ". "
        }

        .lst-kix_lr8xgw4v5wfg-7>li {
            counter-increment: lst-ctn-kix_lr8xgw4v5wfg-7
        }

        .lst-kix_jtgxrppdhs5b-1>li:before {
            content: "" counter(lst-ctn-kix_jtgxrppdhs5b-1, lower-latin) ". "
        }

        .lst-kix_lr8xgw4v5wfg-7>li:before {
            content: "" counter(lst-ctn-kix_lr8xgw4v5wfg-7, lower-latin) ". "
        }

        ol.lst-kix_jtgxrppdhs5b-2.start {
            counter-reset: lst-ctn-kix_jtgxrppdhs5b-2 0
        }

        ol.lst-kix_vkmonltz3m4x-3.start {
            counter-reset: lst-ctn-kix_vkmonltz3m4x-3 0
        }

        ol.lst-kix_t05e6zjr7d8z-1.start {
            counter-reset: lst-ctn-kix_t05e6zjr7d8z-1 0
        }

        .lst-kix_29l9atrxjrcc-2>li {
            counter-increment: lst-ctn-kix_29l9atrxjrcc-2
        }

        ol.lst-kix_jtgxrppdhs5b-5.start {
            counter-reset: lst-ctn-kix_jtgxrppdhs5b-5 0
        }

        ol.lst-kix_lr8xgw4v5wfg-3.start {
            counter-reset: lst-ctn-kix_lr8xgw4v5wfg-3 0
        }

        ol.lst-kix_p1xw0vc0x49w-8.start {
            counter-reset: lst-ctn-kix_p1xw0vc0x49w-8 0
        }

        .lst-kix_lr8xgw4v5wfg-3>li:before {
            content: "" counter(lst-ctn-kix_lr8xgw4v5wfg-3, decimal) ". "
        }

        .lst-kix_nsh5xilkuf1a-6>li {
            counter-increment: lst-ctn-kix_nsh5xilkuf1a-6
        }

        .lst-kix_nsh5xilkuf1a-4>li:before {
            content: "" counter(lst-ctn-kix_nsh5xilkuf1a-4, lower-latin) ". "
        }

        ol.lst-kix_t05e6zjr7d8z-4.start {
            counter-reset: lst-ctn-kix_t05e6zjr7d8z-4 0
        }

        .lst-kix_nsh5xilkuf1a-2>li:before {
            content: "" counter(lst-ctn-kix_nsh5xilkuf1a-2, lower-roman) ". "
        }

        .lst-kix_nsh5xilkuf1a-6>li:before {
            content: "" counter(lst-ctn-kix_nsh5xilkuf1a-6, decimal) ". "
        }

        .lst-kix_lr8xgw4v5wfg-8>li {
            counter-increment: lst-ctn-kix_lr8xgw4v5wfg-8
        }

        .lst-kix_jtgxrppdhs5b-6>li {
            counter-increment: lst-ctn-kix_jtgxrppdhs5b-6
        }

        ol.lst-kix_lr8xgw4v5wfg-2.start {
            counter-reset: lst-ctn-kix_lr8xgw4v5wfg-2 0
        }

        ol.lst-kix_vkmonltz3m4x-4.start {
            counter-reset: lst-ctn-kix_vkmonltz3m4x-4 0
        }

        .lst-kix_lr8xgw4v5wfg-2>li {
            counter-increment: lst-ctn-kix_lr8xgw4v5wfg-2
        }

        ol.lst-kix_29l9atrxjrcc-7.start {
            counter-reset: lst-ctn-kix_29l9atrxjrcc-7 0
        }

        .lst-kix_nsh5xilkuf1a-0>li:before {
            content: "" counter(lst-ctn-kix_nsh5xilkuf1a-0, decimal) ". "
        }

        .lst-kix_hp5w6vum06pm-0>li {
            counter-increment: lst-ctn-kix_hp5w6vum06pm-0
        }

        .lst-kix_jtgxrppdhs5b-5>li {
            counter-increment: lst-ctn-kix_jtgxrppdhs5b-5
        }

        ol.lst-kix_t05e6zjr7d8z-3.start {
            counter-reset: lst-ctn-kix_t05e6zjr7d8z-3 0
        }

        ol.lst-kix_29l9atrxjrcc-8.start {
            counter-reset: lst-ctn-kix_29l9atrxjrcc-8 0
        }

        .lst-kix_29l9atrxjrcc-3>li {
            counter-increment: lst-ctn-kix_29l9atrxjrcc-3
        }

        .lst-kix_nsh5xilkuf1a-5>li {
            counter-increment: lst-ctn-kix_nsh5xilkuf1a-5
        }

        li.li-bullet-0:before {
            margin-left: -18pt;
            white-space: nowrap;
            display: inline-block;
            min-width: 18pt
        }

        ol.lst-kix_lr8xgw4v5wfg-1.start {
            counter-reset: lst-ctn-kix_lr8xgw4v5wfg-1 0
        }

        ol.lst-kix_p1xw0vc0x49w-7 {
            list-style-type: none
        }

        .lst-kix_t05e6zjr7d8z-7>li {
            counter-increment: lst-ctn-kix_t05e6zjr7d8z-7
        }

        ol.lst-kix_p1xw0vc0x49w-6 {
            list-style-type: none
        }

        ol.lst-kix_p1xw0vc0x49w-8 {
            list-style-type: none
        }

        ol.lst-kix_p1xw0vc0x49w-3 {
            list-style-type: none
        }

        ol.lst-kix_p1xw0vc0x49w-2 {
            list-style-type: none
        }

        ol.lst-kix_p1xw0vc0x49w-5 {
            list-style-type: none
        }

        ol.lst-kix_jtgxrppdhs5b-3.start {
            counter-reset: lst-ctn-kix_jtgxrppdhs5b-3 0
        }

        ol.lst-kix_p1xw0vc0x49w-4 {
            list-style-type: none
        }

        .lst-kix_vkmonltz3m4x-4>li {
            counter-increment: lst-ctn-kix_vkmonltz3m4x-4
        }

        ol.lst-kix_vkmonltz3m4x-5.start {
            counter-reset: lst-ctn-kix_vkmonltz3m4x-5 0
        }

        .lst-kix_nsh5xilkuf1a-8>li:before {
            content: "" counter(lst-ctn-kix_nsh5xilkuf1a-8, lower-roman) ". "
        }

        ol.lst-kix_p1xw0vc0x49w-1 {
            list-style-type: none
        }

        ol.lst-kix_p1xw0vc0x49w-0 {
            list-style-type: none
        }

        ol {
            margin: 0;
            padding: 0
        }

        table td,
        table th {
            padding: 0
        }

        .c3 {
            background-color: #ffffff;
            margin-left: 72pt;
            padding-top: 18pt;
            padding-left: 0pt;
            padding-bottom: 0pt;
            line-height: 1.15;
            orphans: 2;
            widows: 2;
            text-align: left
        }

        .c5 {
            background-color: #ffffff;
            margin-left: 72pt;
            padding-top: 21pt;
            padding-left: 0pt;
            padding-bottom: 0pt;
            line-height: 1.15;
            orphans: 2;
            widows: 2;
            text-align: left
        }

        .c0 {
            margin-left: 72pt;
            padding-top: 0pt;
            padding-left: 0pt;
            padding-bottom: 8pt;
            line-height: 1.15;
            orphans: 2;
            widows: 2;
            text-align: left
        }

        .c1 {
            margin-left: 36pt;
            padding-top: 0pt;
            padding-left: 0pt;
            padding-bottom: 8pt;
            line-height: 1.15;
            orphans: 2;
            widows: 2;
            text-align: left
        }

        .c2 {
            color: #666666;
            font-weight: 700;
            text-decoration: none;
            vertical-align: baseline;
            font-size: 10.5pt;
            font-family: "Arial";
            font-style: normal
        }

        .c6 {
            padding-top: 0pt;
            padding-bottom: 0pt;
            line-height: 1.15;
            orphans: 2;
            widows: 2;
            text-align: left;
            height: 11pt
        }

        .c4 {
            color: #666666;
            font-weight: 400;
            text-decoration: none;
            vertical-align: baseline;
            font-size: 10.5pt;
            font-family: "Arial";
            font-style: normal
        }

        .c11 {
            color: #4d4d4d;
            font-weight: 400;
            text-decoration: none;
            vertical-align: baseline;
            font-size: 10.5pt;
            font-family: "Arial";
            font-style: normal
        }

        .c7 {
            color: #000000;
            font-weight: 400;
            text-decoration: none;
            vertical-align: baseline;
            font-size: 11pt;
            font-family: "Arial";
            font-style: normal
        }

        .c18 {
            padding-top: 0pt;
            padding-bottom: 8pt;
            line-height: 1.15;
            orphans: 2;
            widows: 2;
            text-align: left
        }

        .c10 {
            padding-top: 15pt;
            padding-bottom: 8pt;
            line-height: 1.6;
            orphans: 2;
            widows: 2;
            text-align: left
        }

        .c21 {
            padding-top: 15pt;
            padding-bottom: 8pt;
            line-height: 1.6;
            orphans: 2;
            widows: 2;
            text-align: center
        }

        .c12 {
            font-weight: 400;
            text-decoration: none;
            vertical-align: baseline;
            font-family: "Arial";
            font-style: normal
        }

        .c8 {
            text-decoration-skip-ink: none;
            font-size: 10.5pt;
            -webkit-text-decoration-skip: none;
            color: #1155cc;
            text-decoration: underline
        }

        .c14 {
            background-color: #ffffff;
            color: #4d4d4d;
            font-size: 24pt
        }

        .c19 {
            background-color: #ffffff;
            font-size: 10.5pt;
            color: #4d4d4d
        }

        .c17 {
            background-color: #ffffff;
            color: #4d4d4d;
            font-size: 42.5pt
        }

        .c22 {
            background-color: #ffffff;
            font-size: 9pt;
            color: #4d4d4d
        }

        .c20 {
            background-color: #ffffff;
            max-width: 451.4pt;
            padding: 72pt 72pt 72pt 72pt
        }

        .c15 {
            padding: 0;
            margin: 0
        }

        .c23 {
            color: inherit;
            text-decoration: inherit
        }

        .c9 {
            color: #4d4d4d;
            font-size: 19.5pt
        }

        .c13 {
            color: #666666;
            font-size: 10.5pt
        }

        .c16 {
            font-weight: 700
        }

        .title {
            padding-top: 0pt;
            color: #000000;
            font-size: 26pt;
            padding-bottom: 3pt;
            font-family: "Arial";
            line-height: 1.15;
            page-break-after: avoid;
            orphans: 2;
            widows: 2;
            text-align: left
        }

        .subtitle {
            padding-top: 0pt;
            color: #666666;
            font-size: 15pt;
            padding-bottom: 16pt;
            font-family: "Arial";
            line-height: 1.15;
            page-break-after: avoid;
            orphans: 2;
            widows: 2;
            text-align: left
        }

        li {
            color: #000000;
            font-size: 11pt;
            font-family: "Arial"
        }

        p {
            margin: 0;
            color: #000000;
            font-size: 11pt;
            font-family: "Arial"
        }

        h1 {
            padding-top: 20pt;
            color: #000000;
            font-size: 20pt;
            padding-bottom: 6pt;
            font-family: "Arial";
            line-height: 1.15;
            page-break-after: avoid;
            orphans: 2;
            widows: 2;
            text-align: left
        }

        h2 {
            padding-top: 18pt;
            color: #000000;
            font-size: 16pt;
            padding-bottom: 6pt;
            font-family: "Arial";
            line-height: 1.15;
            page-break-after: avoid;
            orphans: 2;
            widows: 2;
            text-align: left
        }

        h3 {
            padding-top: 16pt;
            color: #434343;
            font-size: 14pt;
            padding-bottom: 4pt;
            font-family: "Arial";
            line-height: 1.15;
            page-break-after: avoid;
            orphans: 2;
            widows: 2;
            text-align: left
        }

        h4 {
            padding-top: 14pt;
            color: #666666;
            font-size: 12pt;
            padding-bottom: 4pt;
            font-family: "Arial";
            line-height: 1.15;
            page-break-after: avoid;
            orphans: 2;
            widows: 2;
            text-align: left
        }

        h5 {
            padding-top: 12pt;
            color: #666666;
            font-size: 11pt;
            padding-bottom: 4pt;
            font-family: "Arial";
            line-height: 1.15;
            page-break-after: avoid;
            orphans: 2;
            widows: 2;
            text-align: left
        }

        h6 {
            padding-top: 12pt;
            color: #666666;
            font-size: 11pt;
            padding-bottom: 4pt;
            font-family: "Arial";
            line-height: 1.15;
            page-break-after: avoid;
            font-style: italic;
            orphans: 2;
            widows: 2;
            text-align: left
        }
    </style>
</head>

<body class="c20">
    <h1 class="c21" id="h.kmee7y6atvnl"><span class="c14">Terms and Conditions of </span><span
            class="c14 c16">ECOMPLY.io GmbH</span></h1>
    <h2 class="c10" id="h.g3e29ydlm4aj"><span class="c22">IMPORTANT! PLEASE NOTE: You agree to the German Terms and
            Conditions which have been translated to English for your convenience only. The German Terms and Conditions
            are binding.</span></h2>
    <p class="c18"><span class="c19">These Terms and Conditions are part of a contract between Ecomply GmbH (hereinafter
            referred to as &quot;Ecomply.io&quot;) and its customers on the use of Ecomply.io software and
            relat</span><span class="c4">ed solutions and services.</span></p>
    <h3 class="c10" id="h.5ivqql7tnzne"><span class="c12 c9">1. Definitions and Terms of Service</span></h3>
    <ol class="c15 lst-kix_vkmonltz3m4x-0 start" start="1">
        <li class="c1 li-bullet-0"><span class="c4">Software are software add-ins, browser extensions, mobile
                applications, websites or other named media.</span></li>
        <li class="c1 li-bullet-0"><span class="c4">&quot;User&quot; refers to users of the software as well as all our
                other services.</span></li>
        <li class="c1 li-bullet-0"><span class="c4">&quot;Customers&quot; refers to organizations, institutions, local
                governments, industries or companies that are using Ecomply.io software.</span></li>
        <li class="c1 li-bullet-0"><span class="c13">The scope of our services results from their description on
            </span><span class="c8"><a class="c23"
                    href="https://www.google.com/url?q=http://www.ecomply.io&amp;sa=D&amp;source=editors&amp;ust=1657024275441509&amp;usg=AOvVaw02jEMnID3JdyQ2kaD9GBoU">www.Ecomply.io</a></span><span
                class="c13">. </span>
            <hr>
        </li>
    </ol>
    <h3 class="c10" id="h.b5kn431tjjiq"><span class="c12 c9">2. Rights and obligations of Ecomply.io</span></h3>
    <ol class="c15 lst-kix_29l9atrxjrcc-0 start" start="1">
        <li class="c1 li-bullet-0"><span class="c4">Ecomply.io reserves the right to change Ecomply.io software in a
                reasonable way for the customer at any time, for example, to further develop it or to improve the
                quality.</span></li>
        <li class="c1 li-bullet-0"><span class="c4">Ecomply.io reserves the right to modify these Terms and Conditions
                at any time without giving further notice to the user. The revised Terms and Conditions will be
                communicated to the customer via e-mail at least two weeks before being implemented. If a customer does
                not contradict the validity of the new Terms and Conditions within two weeks after receiving the e-mail,
                the changed Terms and Conditions shall be deemed accepted. Ecomply.io will inform the customer about the
                two week period in the e-mail announcing the revised Terms and Conditions.</span></li>
        <li class="c1 li-bullet-0"><span class="c4">Eventual technical problems and maintenance may lead to a
                temporarily limited usability. Ecomply.io will always deal with any errors as fast as possible to keep
                their times as short as possible.Ecomply.io will inform customers - whenever possible - about
                predictable disturbances.</span></li>
        <li class="c1 li-bullet-0"><span class="c4">Ecomply.io limits its services temporarily if this is necessary with
                regard to capacity limits, the security or integrity of the server or to carry out technical measures,
                when this contributes to the proper or an improved provision of used services (maintenance). In these
                cases Ecomply.io considers the legitimate interests of the customer, e.g. by providing timely
                information.</span></li>
        <li class="c1 li-bullet-0"><span class="c4">Ecomply.io is entitled to use and store usage data for the purposes
                of evaluation, inspection, improvement of services, analysis, marketing and advertising, in accordance
                with the Ecomply.io privacy policy and the Ecomply.io cookie policy. </span></li>
        <li class="c1 li-bullet-0"><span class="c4">Ecomply.io can take action if there are concrete indications that a
                customer violates legal regulations, rights of third parties, the Ecomply.io Terms and Conditions or if
                Ecomply.io has any other legitimate interest, in particular to protect users against fraudulent
                activity.</span></li>
        <li class="c1 li-bullet-0"><span class="c4">Ecomply.io is permitted to use customer information as a reference
                from the time the contract has been signed. This includes in particular the use of the name and logo of
                the customer as well as any mentions in media coverage.</span></li>
    </ol>
    <hr>
    <p class="c6"><span class="c7"></span></p>
    <h3 class="c10" id="h.4yr94wgkg3vc"><span class="c12 c9">3. Rights and obligations of the customer</span></h3>
    <ol class="c15 lst-kix_lr8xgw4v5wfg-0 start" start="1">
        <li class="c1 li-bullet-0"><span class="c2">Authorization</span></li>
    </ol>
    <ol class="c15 lst-kix_lr8xgw4v5wfg-1 start" start="1">
        <li class="c0 li-bullet-0"><span class="c4">During the ordering process The customer must specify all the
                necessary personal or company-related data completely and truthfully. He/She is responsible for the
                accuracy of the data itself and guarantees Ecomply.io their accuracy and timeliness.</span></li>
        <li class="c0 li-bullet-0"><span class="c4">The customer must ensure that third parties can not have access to
                his/her account or license key. The customer is solely responsible for any kind of abuse.</span></li>
    </ol>
    <ol class="c15 lst-kix_lr8xgw4v5wfg-0" start="2">
        <li class="c1 li-bullet-0"><span class="c2">License and Use</span></li>
    </ol>
    <ol class="c15 lst-kix_lr8xgw4v5wfg-1 start" start="1">
        <li class="c0 li-bullet-0"><span class="c4">After concluding the ordering process, the customer receives from
                Ecomply.io the number of license keys that were specified by the customer during the ordering process.
                Ecomply.io thus grants the customer the right to use the ordered software for the specified period of
                use.</span></li>
        <li class="c0 li-bullet-0"><span class="c4">The Customer shall not allow its Users to provide access to the
                Software or Documentation to any third party (except Users) or otherwise permit a third party to use or
                benefit from the Software or Documentation.</span></li>
        <li class="c0 li-bullet-0"><span class="c4">The Customer may disclose documents generated by the Software to its
                customers and data protection authorities.</span></li>
        <li class="c0 li-bullet-0"><span class="c4">The Customer warrants to Ecomply.io not to violate any statutory
                provisions and to comply with the applicable privacy policies. In particular, he/she does not violate
                ownership, protection and use of third party rights.</span></li>
        <li class="c0 li-bullet-0"><span class="c4">The customer relieves Ecomply.io on first request from all claims
                against Ecomply.io asserted by other customers, users or third parties for violation of their rights by
                means of results or data obtained through Ecomply.io software. The customer assumes the costs for the
                legal defense of Ecomply.io including all court costs and attorney fees at the statutory rate. This does
                not apply if the infringement of the customer is not at fault. In the event of a claim by a third party
                the customer is obliged to immediately, truthfully and completely provide Ecomply.io with all the
                necessary information for the examination of the claims and a defense.</span></li>
        <li class="c3 li-bullet-0"><span class="c13">All customers will be entitled to </span><span class="c13">up to 2
                hours of personal live onboarding</span><span class="c4">&nbsp;upon becoming a customer of
                Ecomply.io.</span></li>
        <li class="c5 li-bullet-0"><span class="c4">Chat support is available unlimited to all customers.</span></li>
        <li class="c5 li-bullet-0"><span class="c13">Depending on the plan, the customer is entitled </span><span
                class="c13">to a total of 2 hours of personal live assistance every six months</span><span
                class="c4">;</span></li>
        <li class="c5 li-bullet-0"><span class="c4">Virtual meetings and onboardings outside of the previously
                stipulated terms will be billed at an hourly rate;</span></li>
        <li class="c5 li-bullet-0"><span class="c13">Virtual meetings and onboardings must be scheduled a minimum of 07
                days in advance, and non-attendance without prior notice will result in payment in full.</span></li>
    </ol>
    <ol class="c15 lst-kix_lr8xgw4v5wfg-0" start="3">
        <li class="c1 li-bullet-0"><span class="c2">Security</span></li>
    </ol>
    <ol class="c15 lst-kix_lr8xgw4v5wfg-1 start" start="1">
        <li class="c0 li-bullet-0"><span class="c4">The Customer is obliged not to send spam messages and not to use
                automated electronic programs or robots, or to use his/her Ecomply.io access for this purpose.</span>
        </li>
        <li class="c0 li-bullet-0"><span class="c4">The customer agrees not to send, post or spread any viruses,
                malware, malicious links, or links to illegal websites and malicious content.</span></li>
    </ol>
    <hr>
    <p class="c6"><span class="c7"></span></p>
    <h3 class="c10" id="h.121if9j8g2dv"><span class="c12 c9">4. Remuneration, maturity, delay</span></h3>
    <ol class="c15 lst-kix_t05e6zjr7d8z-0 start" start="1">
        <li class="c1 li-bullet-0"><span class="c4">All prices are to be understood net. Respective VAT is owed as
                well.</span></li>
        <li class="c1 li-bullet-0"><span class="c4">Ecomply.io can change the prices at any time. Price changes will be
                notified to customers in good time and in writing before the effective date.</span></li>
        <li class="c1 li-bullet-0"><span class="c4">The customer only has a right to a set-off if his counterclaims have
                been legally established or these have been recognized by Ecomply.io.</span></li>
        <li class="c1 li-bullet-0"><span class="c4">The customer only has a right of retention to the extent that
                his-her counterclaim arises from the same contractual relation.</span></li>
    </ol>
    <hr>
    <p class="c6"><span class="c7"></span></p>
    <h3 class="c10" id="h.7s45r9f088vy"><span class="c9">5. Warranty, liability</span></h3>
    <ol class="c15 lst-kix_nsh5xilkuf1a-0 start" start="1">
        <li class="c1 li-bullet-0"><span class="c4">Ecomply.io is liable for intent or gross negligence, for injury to
                life, limb or health, in accordance with the provisions of the Product Liability Act and, to the extent
                of a warranty acquired by Ecomply.io, contractual or statutory claims will not be impacted &nbsp;by
                these liability provision. </span></li>
        <li class="c1 li-bullet-0"><span class="c4">Without prejudice to clause 5.1 shall be liable Ecomply.io for
                simple negligence only for breach of contractual obligations, that the breach of obligations, facilitate
                the fulfillment of the proper execution of the license agreement in the first place and on whose
                compliance the customer may trust regularly (&quot;cardinal obligation&quot;). In a negligent breach of
                a cardinal obligation the liability of Ecomply.io is also limited to the typical contractual foreseeable
                damage.</span></li>
        <li class="c1 li-bullet-0"><span class="c4">The typical contractual foreseeable damage in accordance with
                Section 5.2 Sentence 2 is per contract year a maximum of 100% of the annual contract volume (by the
                customer within the contract year in which the damage falls on Ecomply.io remuneration to be paid for
                all services of Ecomply.io regarding the use of the service by the customer) or EUR 10,000 whichever is
                the higher amount applies.</span></li>
        <li class="c1 li-bullet-0"><span class="c4">The limitation of liability in section 5.2 also applies to lost
                profits or savings.</span></li>
        <li class="c1 li-bullet-0"><span class="c4">Without prejudice to clause 5.1, the no-fault liability of
                Ecomply.io according to &sect; 536a BGB, based on defects which were already present at the time of
                concluding the contract, is expressly excluded.</span></li>
        <li class="c1 li-bullet-0"><span class="c4">The foregoing limitations of liability also apply in case of fault
                of a vicarious agent of Ecomply.io and the personal liability of employees, representatives and organs
                of Ecomply.io.</span></li>
        <li class="c1 li-bullet-0"><span class="c4">The limitation of claims for damages by the customer due to the
                liability specified in paragraph 5.1 is calculated according to statutory provisions. The statute of
                limitations with respect to other claims for damages of the customer is one year. It begins at the end
                of the year in which the claim arose and the client learned of justified claim circumstances and the
                debtor was aware of, or has not obtained as a result gross negligence, but not later than five years
                after their origin and ten years from committing the act, breach of duty or other cause, causing the
                damage.</span></li>
        <li class="c1 li-bullet-0"><span class="c4">Ecomply.io strives to provide the highest possible availability of
                the software, is not responsible for the availability and the faultless address and contact data
                generation and is expressly not responsible the legality and availability of generated data.</span></li>
        <li class="c1 li-bullet-0"><span class="c4">The customer is liable for all consequences and disadvantages that
                arise from the improper or illegal use of the Services of Ecomply.io or the fact that the customer does
                not meet his/her other obligations under these Terms and Conditions.</span></li>
    </ol>
    <hr>
    <p class="c6"><span class="c7"></span></p>
    <h3 class="c10" id="h.ndckbmyymidg"><span class="c12 c9">6. Contract duration, contract termination</span></h3>
    <ol class="c15 lst-kix_hp5w6vum06pm-0 start" start="1">
        <li class="c1 li-bullet-0"><span class="c4">The contractual term of the usage is specified in the order.</span>
        </li>
        <li class="c1 li-bullet-0"><span class="c4">If the contract is not terminated at least three months before the
                end of the contract term by the customer, it is automatically renewed for the same period on the same
                terms.</span></li>
        <li class="c1 li-bullet-0"><span class="c4">Ecomply.io can cancel the contract at any time with a period of
                three months to the end of the month.</span></li>
        <li class="c1 li-bullet-0"><span class="c4">A notice must be in writing.</span></li>
    </ol>
    <hr>
    <p class="c6"><span class="c7"></span></p>
    <h3 class="c10" id="h.83wegrerc4m7"><span class="c9">7. Data protection</span></h3>
    <ol class="c15 lst-kix_jtgxrppdhs5b-0 start" start="1">
        <li class="c1 li-bullet-0"><span class="c4">Ecomply.io collects, stores and processes company and personal data
                (inventory data) and other data acquired in relation to the contractual relationship and arising in
                connection with the use by Ecomply.io, for the purpose of contract management, billing, as well as the
                implementation of the services claimed by the customer, complying with the German legal data protection
                regulations.</span></li>
        <li class="c1 li-bullet-0"><span class="c4">By concluding the contract, the customer agrees explicitly that
                Ecomply.io uses customer data, usage patterns and their analysis in order to improve its services and to
                guarantee the business purpose of its use. This data can be made anonymous to any third party under the
                applicable German (BDSG), Brazilian (LGPD) and European (GDPR) data protection guidelines and will be
                used anonymously for communication to the outside.</span></li>
    </ol>
    <hr>
    <p class="c6"><span class="c7"></span></p>
    <h3 class="c10" id="h.tlbvd7mcgn6z"><span class="c9">8. Final clauses</span></h3>
    <ol class="c15 lst-kix_p1xw0vc0x49w-0 start" start="1">
        <li class="c1 li-bullet-0"><span class="c4">The law of the Federal Republic of Germany applies, excluding the UN
                Sales Convention (CISG).</span></li>
        <li class="c1 li-bullet-0"><span class="c4">Place of performance is the headquarters of Ecomply.io GmbH. The
                exclusive place of jurisdiction for all disputes arising from the contract is the seat of Ecomply.io
                GmbH if the customer is a merchant within the meaning of the German Commercial Code or when the customer
                does not have a seat or habitual abode in the Federal Republic of Germany.</span></li>
    </ol>
    <p class="c6"><span class="c7"></span></p>
</body>

</html>
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../services/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class DashboardOnlyGuard implements CanActivate {
  constructor(
    private router: Router,
    private authService: AuthenticationService,
  ) {

  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    // only check dashboard only access if user is not supervisor
    if (localStorage.getItem('isInSupervisionMode') || this.authService.productType === 'supervisor') {
      return true;
    }

    // if user only has dashboard access, redirect to dashboard
    if (this.authService.isServiced) {
      this.router.navigate(['/dashboard'], { queryParams: { fromNonDashboard: true }});
      return false;
    }

    return true;
  }
}

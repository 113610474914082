import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TreeNode } from 'app/services/rpa/rpa.reference.service';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'starter-pa-tree-node',
  templateUrl: './starter-pa-tree-node.component.html',
  styleUrls: ['./starter-pa-tree-node.component.scss']
})
export class StarterPaTreeNodeComponent implements OnInit {

  @Input() actionStyle: 'none' | 'stroked' | 'flat' = 'flat';
  @Input() node: TreeNode;
  @Input() action: string;
  @Input() toggleAction: string | undefined = undefined;
  @Output() picked = new EventEmitter<any>();
  @Output() unpicked = new EventEmitter<any>();

  expanded = false;

  constructor() { }

  ngOnInit(): void {
  }

  pick(pa: any) {
    this.picked.emit(pa);
  }

  unpick(pa: any) {
    this.unpicked.emit(pa);
  }
}

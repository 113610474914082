<ng-container *ngIf="contentReady">
  <div class="header">
    <h1 class="title">{{ 'dpia.assistant.title' | translate }}</h1>
    <p class="title">{{'word.for' | translate }} {{ paTitle }}</p>
    <div class="back"><a routerLink="/rpa/pa-details/{{ paId }}"><mat-icon>arrow_left</mat-icon> {{ 'dpia.assistant.title.backToPa' | translate }}</a></div>
  </div>

  <div class="navigation">
    <div class="inner">
      <div class="steps">
        <a [ngClass]="{'active': step === 'welcome'}" routerLink="/rpa/dpia/{{ paId }}/welcome">{{ 'dpia.assistant.nav.welcome' | translate }}</a>

        <a [ngClass]="{'active': step === 'proportionality'}" routerLink="/rpa/dpia/{{ paId }}/proportionality">{{ 'dpia.assistant.nav.proportionality' | translate }}</a>

        <a [ngClass]="{'active': step === 'risk-identification'}" routerLink="/rpa/dpia/{{ paId }}/risk-identification">{{ 'dpia.assistant.nav.riskIdentification' | translate }}</a>

        <a [ngClass]="{'active': step === 'risk-analysis'}" routerLink="/rpa/dpia/{{ paId }}/risk-analysis">{{ 'dpia.assistant.nav.riskAnalysis' | translate }}</a>

        <a [ngClass]="{'active': step === 'measure-definition'}" routerLink="/rpa/dpia/{{ paId }}/measure-definition">{{ 'dpia.assistant.nav.measureDefinition' | translate }}</a>

        <a [ngClass]="{'active': step === 'documents'}" routerLink="/rpa/dpia/{{ paId }}/documents">{{ 'dpia.assistant.nav.documents' | translate }}</a>

        <a [ngClass]="{'active': step === 'summary'}" routerLink="/rpa/dpia/{{ paId }}/summary">{{ 'dpia.assistant.nav.summary' | translate }}</a>
      </div>
    </div>
  </div>

  <div class="dpia-assistant-container">
    <ng-container *ngIf="step === 'welcome'">
      <h2 class="title">{{ 'dpia.assistant.welcome.title' | translate }}</h2>
      <p class="help-text" [innerHTML]="'dpia.assistant.welcome.helpText' | translate"></p>

      <div class="action-navigation text-width">
        <!--<button mat-button color="primary" routerLink="/rpa/pa-details/{{ paId }}">{{ 'dpia.assistant.actionNav.back' | translate }}</button>-->
        <div class="spacer"></div>
        <button mat-raised-button color="primary" routerLink="/rpa/dpia/{{ paId }}/proportionality">{{ 'dpia.assistant.actionNav.next' | translate }}</button>
      </div>
    </ng-container>

    <ng-container *ngIf="step === 'proportionality'">
      <h2 class="title">{{ 'dpia.assistant.proportionality.title' | translate }}</h2>
      <p class="help-text">{{ 'dpia.assistant.proportionality.helpText' | translate }}</p>


      <div class="assistant-form">
        <!-- proportionality -->
        <mat-divider></mat-divider>

        <!-- Need of Processing -->
        <h3>{{ 'dpia.assistant.proportionality.need.title' | translate }}</h3>
        <p class="help-text">{{ 'dpia.assistant.proportionality.need.helpText' | translate }}</p>

        <div class="pa-information columns">
          <div *ngIf="pa.paDataSubjectCategories" class="info-column">
            <h4>{{ 'dpia.assistant.proportionality.paInfo.dataSubjectCategories' | translate }}</h4>
            <ul>
              <li *ngFor="let cat of pa.paDataSubjectCategories">{{ cat | translate }}</li>
              <li *ngIf="pa.paDataSubjectCategories.includes('pa.dataSubject.categories.other') && pa.paDataSubjectCategoriesOther">{{ pa.paDataSubjectCategoriesOther }}</li>
            </ul>
          </div>
          <div *ngIf="pa.paDataCategoriesDescription" class="info-column">
            <h4>{{ 'dpia.assistant.proportionality.paInfo.dataCategories' | translate }}</h4>
            <ul>
              <li *ngFor="let cat of pa.paDataCategoriesDescription">{{ cat | translate }}</li>
              <li *ngIf="pa.paDataCategoriesDescription.includes('pa.dataSubject.dataCategories.other') && pa.paDataCategoriesDescriptionOther">{{ pa.paDataCategoriesDescriptionOther }}</li>
            </ul>
          </div>
        </div>

        <div commentable comment-subject-codename="dpia-{{paId}}-proportionality-necessity"
        comment-subject-title="{{'dpia.assistant.commentSubject.title' | translate }} VT-{{pa?.paId}} - {{ 'dpia.assistant.proportionality.need.check' | translate }}">
          <todoable subject="dpia-{{paId}}-proportionality-necessity" suggested-title="{{'dpia.assistant.todoSubject.title' | translate }} VT-{{pa?.paId}}: "></todoable>
          <mat-form-field>
            <mat-label>{{ 'dpia.assistant.proportionality.need.check' | translate }}</mat-label>
            <mat-select [(ngModel)]="dpia.data.proportionality.processingNecessary" (selectionChange)="update()" [disabled]="readonly">
              <mat-option [value]="true">{{ 'yesno.yes' | translate }}</mat-option>
              <mat-option [value]="false">{{ 'yesno.no' | translate }}</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field *ngIf="dpia.data.proportionality.processingNecessary">
            <textarea (change)="update()" matTextareaAutosize matInput type="text" placeholder="{{ 'dpia.assistant.proportionality.need.explanation.placeholder' | translate }}"
              [(ngModel)]="dpia.data.proportionality.processingNecessaryExplanation" [disabled]="readonly"></textarea>
            <mat-hint>
              {{ 'dpia.assistant.proportionality.need.explanation.hint' | translate }}
            </mat-hint>
          </mat-form-field>
        </div>

        <!-- Available Alternatives -->
        <h3>{{ 'dpia.assistant.proportionality.alternatives.title' | translate }}</h3>
        <p class="help-text">{{ 'dpia.assistant.proportionality.alternatives.helpText' | translate }}</p>

        <div commentable comment-subject-codename="dpia-{{paId}}-proportionality-alternatives"
        comment-subject-title="{{'dpia.assistant.commentSubject.title' | translate }} VT-{{pa?.paId}} - {{ 'dpia.assistant.proportionality.alternatives.check' | translate }}">
          <todoable subject="dpia-{{paId}}-proportionality-necessity" suggested-title="{{'dpia.assistant.todoSubject.title' | translate }} VT-{{pa?.paId}}: "></todoable>
          <mat-form-field>
            <mat-label>{{ 'dpia.assistant.proportionality.alternatives.check' | translate }}</mat-label>
            <mat-select [(ngModel)]="dpia.data.proportionality.noAlternativeProcessing" (selectionChange)="update()" [disabled]="readonly">
              <mat-option [value]="true">{{ 'yesno.yes' | translate }}</mat-option>
              <mat-option [value]="false">{{ 'yesno.no' | translate }}</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field *ngIf="dpia.data.proportionality.noAlternativeProcessing">
            <textarea (change)="update()" matTextareaAutosize matInput type="text" placeholder="{{ 'dpia.assistant.proportionality.alternatives.explanation.placeholder' | translate }}"
              [(ngModel)]="dpia.data.proportionality.noAlternativeProcessingExplanation" [disabled]="readonly"></textarea>
            <mat-hint>
              {{ 'dpia.assistant.proportionality.alternatives.explanation.hint' | translate }}
            </mat-hint>
          </mat-form-field>
        </div>

        <div *ngIf="proportionalityAnswered" class="proportionality-hint" [ngClass]="{ 'proportional': isProcessingProportional, 'not-proportional': !isProcessingProportional }">
          <ng-container *ngIf="isProcessingProportional">
            <p class="prop-title">{{ 'dpia.assistant.proportionality.result.proportional.title' | translate }}</p>
            <p class="prop-description">{{ 'dpia.assistant.proportionality.result.proportional.description' | translate }}</p>
          </ng-container>
          <ng-container *ngIf="!isProcessingProportional">
            <p class="prop-title">{{ 'dpia.assistant.proportionality.result.notProportional.title' | translate }}</p>
            <p class="prop-description">{{ 'dpia.assistant.proportionality.result.notProportional.description' | translate }}</p>
          </ng-container>
        </div>

        <div *ngIf="!proportionalityAnswered" class="proportionality-hint unanswered">
          <p class="prop-title">{{ 'dpia.assistant.proportionality.result.toBeFilled.title' | translate }}</p>
          <p class="prop-description">{{ 'dpia.assistant.proportionality.result.toBeFilled.description' | translate }}</p>
        </div>

        <mat-divider></mat-divider>

        <div class="action-navigation">
          <button mat-button color="primary" routerLink="/rpa/dpia/{{ paId }}/welcome">{{ 'dpia.assistant.actionNav.back' | translate }}</button>
          <button [disabled]="!proportionalityAnswered" mat-raised-button color="primary" routerLink="/rpa/dpia/{{ paId }}/risk-identification">{{ 'dpia.assistant.actionNav.next' | translate }}</button>
        </div>
      </div>


      <!-- dpo consultation -->
    </ng-container>

    <ng-container *ngIf="step === 'risk-identification'">
      <div commentable comment-subject-codename="dpia-{{paId}}-risk-identification"
        comment-subject-title="{{'dpia.assistant.commentSubject.title' | translate }} VT-{{pa?.paId}} - {{ 'dpia.assistant.riskIdentification.title' | translate }}">
        <todoable subject="dpia-{{paId}}-risk-identification" suggested-title="{{'dpia.assistant.todoSubject.title' | translate }} VT-{{pa?.paId}}: "></todoable>
        <h2 class="title">{{ 'dpia.assistant.riskIdentification.title' | translate }}</h2>
        <p class="help-text">{{ 'dpia.assistant.riskIdentification.helpText' | translate }}</p>
      </div>

      <div *ngIf="!riskResources" class="centered-spinner brand">
        <mat-spinner></mat-spinner>
      </div>

      <ng-container *ngIf="riskResources">
        <p class="risk-count-container">
          {{ 'dpia.assistant.riskIdentification.selectedRisks' | translate }}
          <span class="risk-identification risk-count" [@riskCountAnimation]="riskCount">{{ riskCount }}</span>
        </p>

        <mat-divider class="text-width"></mat-divider>

        <div class="risk-identification-tool">
          <div class="risk-source-selection tool-section">
            <h4 class="title">{{ 'dpia.assistant.riskIdentification.riskSource.title' | translate }}</h4>

            <ul>
              <li *ngFor="let source of riskResources.risk_sources" (click)="selectSource(source)"
                [class.applies]="getSourceAppliesStatus(source) === 'applies'"
                [class.not-applies]="getSourceAppliesStatus(source) === 'not-applies'"
                [class.unset]="getSourceAppliesStatus(source) === 'unset'"
                [class.selected]="isSourceSelected(source)"
                >
                <div class="content">
                  <span class="display-name">
                    {{ getDisplay(source) }}
                    <span *ngIf="noRisksFilledForSource(source)" class="unfilled-text">{{ 'dpia.assistant.riskIdentification.missingRisks' | translate }}</span>
                  </span>
                  <span class="actions">
                    <mat-checkbox [disabled]="readonly" [checked]="getSourceAppliesStatus(source) === 'applies'" (click)="$event.stopPropagation()" (change)="setRiskSourceApplies($event, source)"></mat-checkbox>
                  </span>
                </div>
                <span class="arrow">
                  <mat-icon>chevron_right</mat-icon>
                </span>
              </li>
            </ul>
          </div>

          <div class="risk-type-selection tool-section" [class.with-selection]="selectedSource">
            <h4 class="title">{{ 'dpia.assistant.riskIdentification.riskType.title' | translate }}</h4>

            <p *ngIf="!selectedSource" class="empty-hint">{{ 'dpia.assistant.riskIdentification.riskType.empty' | translate }}</p>

            <ul *ngIf="selectedSource">
              <li *ngFor="let type of getRiskTypes()" (click)="selectType(type)"
                [class.applies]="getTypeAppliesStatus(type) === 'applies'"
                [class.not-applies]="getTypeAppliesStatus(type) === 'not-applies'"
                [class.unset]="getTypeAppliesStatus(type) === 'unset'"
                [class.selected]="isTypeSelected(type)"
                >
                <div class="content">
                  <span class="display-name">
                    {{ getDisplay(type) }}
                    <span *ngIf="noRisksFilledForType(selectedSource, type)" class="unfilled-text">{{ 'dpia.assistant.riskIdentification.missingRisks' | translate }}</span>
                  </span>
                  <span class="actions">
                    <mat-checkbox [disabled]="readonly" [checked]="getTypeAppliesStatus(type) === 'applies'" (change)="setRiskTypeApplies($event, type)"></mat-checkbox>
                  </span>
                </div>
                <span class="arrow">
                  <mat-icon>chevron_right</mat-icon>
                </span>
              </li>
            </ul>
          </div>

          <div *ngIf="selectedSource" class="risk-entry tool-section" [class.with-selection]="selectedType">
            <h4 class="title">{{ 'dpia.assistant.riskIdentification.riskEntry.title' | translate }}</h4>

            <p *ngIf="!selectedType" class="empty-hint">{{ 'dpia.assistant.riskIdentification.riskEntry.empty' | translate }}</p>

            <ng-container *ngIf="selectedType">
              <ul>
                <li *ngFor="let risk of riskResources.risk_examples"
                  [class.applies]="getExampleAppliesStatus(risk) === 'applies'"
                  [class.not-applies]="getExampleAppliesStatus(risk) === 'not-applies'"
                  [class.unset]="getExampleAppliesStatus(risk) === 'unset'"
                  >
                  <div class="content">
                    <span class="display-name">{{ getDisplay(risk) }}</span>
                    <span class="actions">
                      <mat-checkbox [disabled]="readonly" [checked]="getExampleAppliesStatus(risk) === 'applies'" (change)="setRiskExampleApplies($event, risk)"></mat-checkbox>
                    </span>
                  </div>
                </li>
                <li *ngFor="let risk of getMatchedRisks()"
                  [class.applies]="getCustomRiskAppliesStatus(risk) === 'applies'">
                  <div class="content">
                    <span class="display-name">{{ risk.name }}</span>
                    <span class="actions">
                      <mat-checkbox [disabled]="readonly" [checked]="getCustomRiskAppliesStatus(risk) === 'applies'" (change)="setCustomRiskApplies($event, risk)"></mat-checkbox>
                    </span>
                  </div>
                </li>
              </ul>

              <div class="risk-creation">
                <mat-form-field>
                  <input matInput type="text" placeholder="{{ 'dpia.assistant.riskIdentification.riskEntry.newRisk.name' | translate }}" [(ngModel)]="newRisk.name" [disabled]="readonly" />
                </mat-form-field>
                <button mat-raised-button (click)="addRisk()" [disabled]="readonly">{{ 'dpia.assistant.riskIdentification.riskEntry.newRisk.action' | translate }}</button>
              </div>
            </ng-container>

          </div>
        </div>
      </ng-container>

      <mat-divider class="text-width"></mat-divider>
      <div class="action-navigation text-width">
        <button mat-button color="primary" routerLink="/rpa/dpia/{{ paId }}/welcome">{{ 'dpia.assistant.actionNav.back' | translate }}</button>
        <button [disabled]="riskCount === 0" mat-raised-button color="primary" routerLink="/rpa/dpia/{{ paId }}/risk-analysis">{{ 'dpia.assistant.actionNav.next' | translate }}</button>
      </div>
    </ng-container>

    <ng-container *ngIf="step === 'risk-analysis'">
      <h2 class="title">{{ 'dpia.assistant.riskAnalysis.title' | translate }}</h2>
      <p class="help-text">{{ 'dpia.assistant.riskAnalysis.helpText' | translate }}</p>

      <mat-divider class="text-width"></mat-divider>

      <div *ngIf="!riskResources" class="centered-spinner brand">
        <mat-spinner></mat-spinner>
      </div>

      <ng-container *ngIf="riskResources">
        <div class="empty" *ngIf="!risksOrdered || risksOrdered.length === 0">
          <p>{{ 'dpia.assistant.riskAnalysis.noRisks' | translate }}</p>
        </div>

        <div class="risk-table" *ngIf="risksOrdered && risksOrdered.length > 0">
          <div class="row header">
            <div class="risk-name">{{ 'dpia.assistant.riskAnalysis.tableHeader.riskName' | translate : { count: riskCount } }}</div>
            <div class="impact">{{'dpia.assistant.riskAnalysis.tableHeader.impact'|translate}}</div>
            <div class="probability">{{'dpia.assistant.riskAnalysis.tableHeader.probability'|translate}}</div>
            <div class="overall-rating">{{'dpia.assistant.riskAnalysis.tableHeader.overallRating'|translate}}</div>
          </div>
          <div class="risk row" *ngFor="let risk of risksOrdered">
              <div class="risk-name" title="{{ getTypeDisplay(risk.riskType) }}, {{ getSourceDisplay(risk.riskSource) }}: {{ risk.name }}">
                <span class="source">{{ getSourceDisplay(risk.riskSource) }}</span>
                <mat-icon>chevron_right</mat-icon>
                <span class="type">{{ getTypeDisplay(risk.riskType) }}</span>
                <mat-icon>chevron_right</mat-icon>
                <span class="name">{{ risk.name }}</span>
              </div>

              <div class="impact with-indicator">
                <div class="indicator simple level-{{ risk.impact }}"></div>
                <div class="select">
                  <mat-select [(ngModel)]="risk.impact" (selectionChange)="updateRisk(risk)" [disabled]="readonly" placeholder="{{'dpia.assistant.riskAnalysis.risk.pleaseSelect'|translate}}">
                    <mat-option [value]="1">{{'dpia.severity.labels.low'|translate}}</mat-option>
                    <mat-option [value]="2">{{'dpia.severity.labels.medium'|translate}}</mat-option>
                    <mat-option [value]="3">{{'dpia.severity.labels.high'|translate}}</mat-option>
                    <mat-option [value]="4">{{'dpia.severity.labels.very-high'|translate}}</mat-option>
                  </mat-select>
                </div>
              </div>

              <div class="probability with-indicator">
                <div class="indicator simple level-{{ risk.probability }}"></div>
                <div class="select">
                  <mat-select [(ngModel)]="risk.probability" (selectionChange)="updateRisk(risk)" [disabled]="readonly" placeholder="{{'dpia.assistant.riskAnalysis.risk.pleaseSelect'|translate}}">
                    <mat-option [value]="1">{{'dpia.risk.labels.low'|translate}}</mat-option>
                    <mat-option [value]="2">{{'dpia.risk.labels.medium'|translate}}</mat-option>
                    <mat-option [value]="3">{{'dpia.risk.labels.high'|translate}}</mat-option>
                    <mat-option [value]="4">{{'dpia.risk.labels.very-high'|translate}}</mat-option>
                  </mat-select>
                </div>
              </div>

              <div commentable comment-subject-codename="dpia-{{paId}}-risk-analysis-r-{{ risk.id }}"
                comment-subject-title="{{'dpia.assistant.commentSubject.title' | translate }} VT-{{pa?.paId}} - {{ 'dpia.assistant.riskAnalysis.title' | translate }}">
                <todoable subject="dpia-{{paId}}-risk-analysis-r-{{ risk.id }}" suggested-title="{{'dpia.assistant.todoSubject.title' | translate }} VT-{{pa?.paId}}: "></todoable>
                <div class="overall-rating with-indicator">
                  <mat-icon>chevron_right</mat-icon>

                  <div class="indicator detailed level-{{ getOverallRating(risk) }}"></div>

                  <ng-container *ngIf="getOverallRating(risk) === 0">{{ 'dpia.assistant.riskAnalysis.overall.unset' | translate }}</ng-container>
                  <ng-container *ngIf="getOverallRating(risk) > 0 && getOverallRating(risk) < 4">{{ 'dpia.assistant.riskAnalysis.overall.low' | translate }}</ng-container>
                  <ng-container *ngIf="getOverallRating(risk) >= 4 && getOverallRating(risk) < 8">{{ 'dpia.assistant.riskAnalysis.overall.medium' | translate }}</ng-container>
                  <ng-container *ngIf="getOverallRating(risk) >= 8 && getOverallRating(risk) < 12">{{ 'dpia.assistant.riskAnalysis.overall.high' | translate }}</ng-container>
                  <ng-container *ngIf="getOverallRating(risk) >= 12 && getOverallRating(risk) <= 16">{{ 'dpia.assistant.riskAnalysis.overall.very-high' | translate }}</ng-container>

                  ({{ getOverallRating(risk) }})
                </div>
              </div>
            </div>
        </div>
      </ng-container>

      <mat-divider class="text-width"></mat-divider>
      <div class="action-navigation text-width">
        <button mat-button color="primary" routerLink="/rpa/dpia/{{ paId }}/risk-identification">{{ 'dpia.assistant.actionNav.back' | translate }}</button>
        <button [disabled]="risksIncompletelyMeasured" matTooltip="{{ risksIncompletelyMeasured ? ('dpia.assistant.riskAnalysis.risksIncompletelyMeasured' | translate) : '' }}" mat-raised-button color="primary" routerLink="/rpa/dpia/{{ paId }}/measure-definition">{{ 'dpia.assistant.actionNav.next' | translate }}</button>
      </div>
    </ng-container>

    <ng-container *ngIf="step === 'measure-definition'">
      <h2 class="title">{{ 'dpia.assistant.measureDefinition.title' | translate }}</h2>
      <p class="help-text">{{ 'dpia.assistant.measureDefinition.helpText' | translate }}</p>

      <mat-divider class="text-width"></mat-divider>

      <div class="empty" *ngIf="!risksOrdered || risksOrdered.length === 0">
        <p>{{ 'dpia.assistant.riskAnalysis.noRisks' | translate }}</p>
      </div>

      <!-- TODO: check if risks have been analyzed -->

      <ng-container *ngIf="risksOrdered && risksOrdered.length > 0">
        <div class="measure-table">
          <div *ngFor="let risk of risksOrdered; let last = last" class="risk row">
            <div commentable comment-subject-codename="dpia-{{paId}}-measure-definition-r-{{ risk.id }}"
              comment-subject-title="{{'dpia.assistant.commentSubject.title' | translate }} VT-{{pa?.paId}} - {{ 'dpia.assistant.measureDefinition.title' | translate }}">
              <todoable subject="dpia-{{paId}}-measure-definition-r-{{ risk.id }}" suggested-title="{{'dpia.assistant.todoSubject.title' | translate }} VT-{{pa?.paId}}: "></todoable>
              <div class="header">
                <div class="risk-name" title="{{ getSourceDisplay(risk.riskSource) }}, {{ getTypeDisplay(risk.riskType) }}: {{ risk.name }}">
                  <span class="source">{{ getSourceDisplay(risk.riskSource) }}</span>
                  <mat-icon>chevron_right</mat-icon>
                  <span class="type">{{ getTypeDisplay(risk.riskType) }}</span>
                  <mat-icon>chevron_right</mat-icon>
                  <span class="name">{{ risk.name }}</span>
                  <mat-icon>chevron_right</mat-icon>

                  <div class="overall-rating with-indicator" id="initial-risk-{{ risk.id }}">
                    <div class="indicator detailed level-{{ getOverallRating(risk) }}"></div>

                    <ng-container *ngIf="getOverallRating(risk) === 0">{{ 'dpia.assistant.riskAnalysis.overall.unset' | translate }}</ng-container>
                    <ng-container *ngIf="getOverallRating(risk) > 0 && getOverallRating(risk) < 4">{{ 'dpia.assistant.riskAnalysis.overall.low' | translate }}</ng-container>
                    <ng-container *ngIf="getOverallRating(risk) >= 4 && getOverallRating(risk) < 8">{{ 'dpia.assistant.riskAnalysis.overall.medium' | translate }}</ng-container>
                    <ng-container *ngIf="getOverallRating(risk) >= 8 && getOverallRating(risk) < 12">{{ 'dpia.assistant.riskAnalysis.overall.high' | translate }}</ng-container>
                    <ng-container *ngIf="getOverallRating(risk) >= 12 && getOverallRating(risk) <= 16">{{ 'dpia.assistant.riskAnalysis.overall.very-high' | translate }}</ng-container>

                    ({{ getOverallRating(risk) }})
                  </div>
                </div>
              </div>
            </div>

            <div class="risk-body">
              <div class="measure-list">
                <div class="actions">
                  <button mat-stroked-button (click)="addOwnMeasure(risk)" [disabled]="readonlyMeasures">{{ 'dpia.assistant.measureDefinition.addOwnMeasure' | translate }}</button>
                  <button mat-stroked-button (click)="addMeasure(risk)" [disabled]="readonlyMeasures">{{ 'dpia.assistant.measureDefinition.addMeasure' | translate }}</button>
                </div>

                <div *ngFor="let measure of getMeasures(risk); let i = index">
                  <div commentable comment-subject-codename="dpia-{{paId}}-measure-definition-r-{{ risk.id }}-measure-{{ measure.id}}"
                  comment-subject-title="{{'dpia.assistant.commentSubject.title' | translate }} VT-{{pa?.paId}} - {{ risk.name }} - {{ measure.name }}">
                    <todoable subject="dpia-{{paId}}-measure-definition-r-{{ risk.id }}-measure-{{ measure.id}}" suggested-title="{{'dpia.assistant.todoSubject.title' | translate }} VT-{{pa?.paId}}: "></todoable>
                    <div class="measure">
                      <span class="count">{{ i + 1 }}.</span>
                      <span class="input">
                        <mat-form-field class="measure-name">
                          <mat-label>{{ 'dpia.assistant.measureDefinition.measure.name' | translate }}</mat-label>
                          <input matInput type="text" [(ngModel)]="measure.name" (change)="updateMeasure(measure)" [disabled]="readonlyMeasures" required />
                        </mat-form-field>
                      </span>
                      <span class="actions">
                        <button mat-icon-button [disabled]="readonlyMeasures" (click)="deleteMeasure(measure)">
                          <mat-icon>delete_outline</mat-icon>
                        </button>
                      </span>
                    </div>
                  </div>
                </div>

                <p class="action-hint" *ngIf="getMeasures(risk).length === 0 && getOverallRating(risk) === 0">{{ 'dpia.assistant.measureDefinition.overallRating.ratingNecessary' | translate }}</p>
                <p class="information-hint" *ngIf="getMeasures(risk).length === 0 && getOverallRating(risk) <= 3 && getOverallRating(risk) !== 0">{{ 'dpia.assistant.measureDefinition.empty' | translate }}</p>
                <p class="action-hint" *ngIf="getMeasures(risk).length === 0 && getOverallRating(risk) > 3">{{ 'dpia.assistant.measureDefinition.emptyButRemedyNeeded' | translate }}</p>
                <p class="information-hint" *ngIf="getMeasures(risk).length > 0 && getOverallRating(risk) <= 3 && getOverallRating(risk) !== 0">{{ 'dpia.assistant.measureDefinition.noAdditionalAssessmentNeeded' | translate }}</p>
              </div>

              <ng-container *ngIf="getMeasures(risk).length > 0 && getOverallRating(risk) > 3">
                <div class="overall-rating">
                  <div class="question">
                    <p class="title">{{ 'dpia.assistant.measureDefinition.overallRating.title' | translate }}</p>
                    <p *ngIf="getMeasures(risk).length > 0" class="explanation" [innerHtml]="'dpia.assistant.measureDefinition.overallRating.hint' | translate : { measures: getMeasuresJoined(risk), risk: (risk.impact * risk.probability) }"></p>
                  </div>

                  <div class="noshrink" commentable comment-subject-codename="dpia-{{paId}}-measure-definition-r-{{ risk.id }}-remediedOverall"
                    comment-subject-title="{{'dpia.assistant.commentSubject.title' | translate }} VT-{{pa?.paId}} - {{ risk.name }} - {{ 'dpia.assistant.measureDefinition.overallRating.title' | translate }}">
                    <todoable subject="dpia-{{paId}}-measure-definition-r-{{ risk.id }}-remediedOverall" suggested-title="{{'dpia.assistant.todoSubject.title' | translate }} VT-{{pa?.paId}}: "></todoable>

                    <div class="slider-container">
                      <div class="slider">
                        <span class="low">{{ 'dpia.assistant.riskAnalysis.overall.low' | translate }}</span>
                        <mat-slider
                          ngDefaultControl
                          [(ngModel)]="risk.remediedOverallRisk"
                          matTooltip="{{ getOverallRating(risk) === 0 ? ('dpia.assistant.measureDefinition.overallRating.ratingNecessary' | translate) : '' }}"
                          [disabled]="getOverallRating(risk) === 0 || readonlyMeasures"
                          (change)="updateRisk(risk)"
                          min="1" max="16" step="1" thumbLabel tickInterval="1"></mat-slider>
                        <span class="high">{{ 'dpia.assistant.riskAnalysis.overall.very-high' | translate }}</span>
                      </div>
                      <div class="overall-rating with-indicator">
                        <div class="indicator detailed level-{{ risk.remediedOverallRisk || '0' }}"></div>

                        <ng-container *ngIf="(risk.remediedOverallRisk || 0) === 0">{{ 'dpia.assistant.riskAnalysis.overall.unset' | translate }}</ng-container>
                        <ng-container *ngIf="risk.remediedOverallRisk > 0 && risk.remediedOverallRisk < 4">{{ 'dpia.assistant.riskAnalysis.overall.low' | translate }}</ng-container>
                        <ng-container *ngIf="risk.remediedOverallRisk >= 4 && risk.remediedOverallRisk < 8">{{ 'dpia.assistant.riskAnalysis.overall.medium' | translate }}</ng-container>
                        <ng-container *ngIf="risk.remediedOverallRisk >= 8 && risk.remediedOverallRisk < 12">{{ 'dpia.assistant.riskAnalysis.overall.high' | translate }}</ng-container>
                        <ng-container *ngIf="risk.remediedOverallRisk >= 12 && risk.remediedOverallRisk <= 16">{{ 'dpia.assistant.riskAnalysis.overall.very-high' | translate }}</ng-container>

                        ({{ risk.remediedOverallRisk || 0 }})
                      </div>
                    </div>
                  </div>
                </div>
              </ng-container>
            </div>

            <mat-divider *ngIf="!last"></mat-divider>
          </div>
        </div>
      </ng-container>

      <mat-divider class="text-width"></mat-divider>
      <div class="action-navigation text-width">
        <button mat-button color="primary" routerLink="/rpa/dpia/{{ paId }}/risk-analysis">{{ 'dpia.assistant.actionNav.back' | translate }}</button>
        <button [disabled]="risksIncompletelyRemedied" matTooltip="{{ risksIncompletelyRemedied ? ('dpia.assistant.measureDefinition.risksIncompletelyRemedied' | translate) : '' }}" mat-raised-button color="primary" routerLink="/rpa/dpia/{{ paId }}/documents">{{ 'dpia.assistant.actionNav.next' | translate }}</button>
      </div>
    </ng-container>

    <ng-container *ngIf="step === 'documents'">
      <h2 class="title">{{'dpia.assistant.documents.title' | translate }}</h2>
      <p class="help-text" [innerHtml]="'dpia.assistant.documents.helpText' | translate"></p>

      <div commentable comment-subject-codename="dpia-{{paId}}-documents"
        comment-subject-title="{{'pa.documents.commentSubject.part1' | translate }} VT-{{paId}} {{'pa.documents.commentSubject.part2' | translate }}">

        <todoable subject="dpia-{{paId}}-documents"
            suggested-title="VT-{{pa?.paId}}: "></todoable>
        <app-documents-container [upload-url]="uploadUrl" upload-key="document" [source]="pa?.documents"
          [readonly]="readonly" (documentChanged)="updateDocumentList()"></app-documents-container>
      </div>

      <div class="action-navigation text-width">
        <button mat-button color="primary" routerLink="/rpa/dpia/{{ paId }}/measure-definition">{{ 'dpia.assistant.actionNav.back' | translate }}</button>
        <button mat-raised-button color="primary" routerLink="/rpa/dpia/{{ paId }}/summary">{{ 'dpia.assistant.actionNav.next' | translate }}</button>
      </div>
    </ng-container>

    <ng-container *ngIf="step === 'summary'">
      <h2 class="title">{{ 'dpia.assistant.summary.title' | translate }}</h2>
      <p class="help-text">{{ 'dpia.assistant.summary.helpText' | translate }}</p>

      <a class="report-button" mat-button color="primary" href="{{ reportLink() }}" target="_blank">{{ 'dpia.assistant.summary.downloadReport' | translate }}</a>

      <div class="summary">
        <section class="risk-matrix">
          <h3>{{ 'dpia.assistant.summary.riskMatrix.title' | translate }}</h3>

          <div class="empty" *ngIf="!risksOrdered || risksOrdered.length === 0">
            <p>{{ 'dpia.assistant.riskAnalysis.noRisks' | translate }}</p>
          </div>

          <ng-container *ngIf="risksOrdered && risksOrdered.length > 0">
            <div *ngIf="riskMatrices" class="matrices">
              <div class="before">
                <div id="risk-before" [innerHtml]="riskMatrices.before"></div>
                <label for="risk-before">{{ 'dpia.assistant.summary.riskMatrix.before' | translate }}</label>
              </div>
              <div class="after">
                <div id="risk-after" [innerHtml]="riskMatrices.after"></div>
                <label for="risk-after">{{ 'dpia.assistant.summary.riskMatrix.after' | translate }}</label>
              </div>
            </div>
            <div *ngIf="!riskMatrices" class="centered-spinner">
              <mat-spinner></mat-spinner>
            </div>
          </ng-container>

        </section>

          <section class="proportionality">
            <h3>{{ 'dpia.assistant.summary.proportionality.title' | translate }}</h3>

            <div class="empty" *ngIf="!proportionalityAnswered">
              <p>{{ 'dpia.assistant.summary.proportionality.unanswered' | translate }}</p>
            </div>

            <!-- overall -->
            <ng-container *ngIf="proportionalityAnswered">
              <p *ngIf="isProcessingProportional">{{ 'dpia.assistant.summary.proportionality.proportional' | translate }}</p>
              <p *ngIf="!isProcessingProportional">{{ 'dpia.assistant.summary.proportionality.unproportional' | translate }}</p>
            </ng-container>

            <ul *ngIf="proportionalityAnswered">
              <li>
                <!-- processing necessary -->
                <ng-container *ngIf="dpia.data.proportionality.processingNecessary === true">
                  {{ 'dpia.assistant.summary.proportionality.processingNecessary.true' | translate }} {{ dpia.data.proportionality.processingNecessaryExplanation }}
                </ng-container>
                <ng-container *ngIf="dpia.data.proportionality.processingNecessary === false">
                  {{ 'dpia.assistant.summary.proportionality.processingNecessary.false' | translate }}
                </ng-container>
              </li>

              <li>
                <!-- processing necessary -->
                <ng-container *ngIf="dpia.data.proportionality.noAlternativeProcessing === true">
                  {{ 'dpia.assistant.summary.proportionality.noAlternativeProcessing.true' | translate }} {{ dpia.data.proportionality.noAlternativeProcessingExplanation }}
                </ng-container>
                <ng-container *ngIf="dpia.data.proportionality.noAlternativeProcessing === false">
                  {{ 'dpia.assistant.summary.proportionality.noAlternativeProcessing.false' | translate }}
                </ng-container>
              </li>
            </ul>
          </section>

          <section class="risks">
            <h3>{{ 'dpia.assistant.summary.risks.title' | translate }}</h3>

            <div class="empty" *ngIf="!risksOrdered || risksOrdered.length === 0">
              <p>{{ 'dpia.assistant.riskAnalysis.noRisks' | translate }}</p>
            </div>

            <ul class="risk-table with-measures">
              <li class="risk" *ngFor="let risk of risksOrdered; let last = last">
                <div class="risk-name">
                  <span class="source">{{ getSourceDisplay(risk.riskSource) }}</span>
                  <mat-icon>chevron_right</mat-icon>
                  <span class="type">{{ getTypeDisplay(risk.riskType) }}</span>
                  <mat-icon>chevron_right</mat-icon>
                  <span class="name">{{ risk.name }}</span>
                </div>
                <div class="risk-values">
                  <span class="overall">
                    {{ 'dpia.assistant.summary.risk.before' | translate }}:
                    <div class="overall-rating with-indicator">
                      <div class="indicator detailed level-{{ getOverallRating(risk) }}"></div>

                      {{ getOverallRating(risk) }}
                    </div>
                  </span>
                  <span class="overall">
                    {{ 'dpia.assistant.summary.risk.remedied' | translate }}:
                    <div class="overall-rating with-indicator">
                      <div class="indicator detailed level-{{ risk.remediedOverallRisk || 0 }}"></div>

                      {{ risk.remediedOverallRisk || 0 }}
                    </div>
                  </span>
                </div>
                <div class="measures">
                  <h4>{{ 'dpia.assistant.summary.risks.risk.measures' | translate }}</h4>
                  <ul>
                    <li *ngFor="let measure of getMeasures(risk)">{{ measure.name }}</li>
                  </ul>

                  <p class="empty" *ngIf="getMeasures(risk).length === 0">{{ 'dpia.assistant.summary.risks.risk.measures.empty' | translate }}</p>
                </div>

                <mat-divider *ngIf="!last"></mat-divider>
              </li>
            </ul>
          </section>
        </div>

        <a class="report-button" mat-button color="primary" href="{{ reportLink() }}" target="_blank">{{ 'dpia.assistant.summary.downloadReport' | translate }}</a>
    </ng-container>
  </div>
</ng-container>

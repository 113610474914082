import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'big-round-checkbox',
  templateUrl: './big-round-checkbox.component.html',
  styleUrls: ['./big-round-checkbox.component.scss']
})
export class BigRoundCheckboxComponent implements OnInit {

  @Input() readonly = false;
  @Input() propagateClick = false;
  @Output() valueChanged: EventEmitter<boolean> = new EventEmitter<boolean>();

  private _value: boolean;
  hovered = false;

  constructor() { }

  ngOnInit() {
  }

  @Input()
  get value(): boolean {
    return this._value;
  }

  set value(_value: boolean) {
    if (this._value !== _value) {
      this._value = _value;
      this.hovered = false;
      this.valueChanged.emit(this._value);
    }
  }

  toggleValue(event) {
    if (event && !this.propagateClick) {
      event.stopPropagation();
    }

    if (!this.readonly) {
      this.value = !this.value;
    }
  }
}

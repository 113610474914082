import { Component, Input, OnInit } from '@angular/core';
import { getInstanceName } from '../../util/environment';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'display-environment',
  templateUrl: './display-environment.component.html',
  styleUrls: ['./display-environment.component.css']
})
export class DisplayEnvironmentComponent implements OnInit {
  @Input() is: string = null;
  @Input() not: string = null;

  display = false;

  constructor() { }

  ngOnInit(): void {
    getInstanceName().subscribe(instanceName => {
      this.display = (this.is === instanceName) || (this.not !== instanceName);
    });
  }
}

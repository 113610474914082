import { Injectable } from '@angular/core';

import {BusService} from './bus.service';
import {EventsService} from './events.service';
import {
  IGetOptions,
  IPostOptions,
  RequestService
} from './request.service';
import { Router } from '@angular/router';
import { AuthenticationService } from './authentication.service';
import { ClientSupervisionService } from './supervision/client-supervision.service';
import { of } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable()
export class ProductFeaturesService {

  constructor(
    private bus: BusService,
    private events: EventsService,
    private requestService: RequestService,
    private router: Router,
    private auth: AuthenticationService,
    private supervision: ClientSupervisionService,
    private request: RequestService
  ) {

  }

  hasAccessToFeatureLocal(featureName: string, includeClients = false, cacheAccess = false) {
    if (this.getFeatureAccessFromCache(featureName) !== null && cacheAccess) {
      return of({ access: this.getFeatureAccessFromCache(featureName) });
    }

    return this.request.get<{ access: boolean }>({ uri: '/feature-access/' + featureName + (includeClients ? '?includeClients=true' : '')})
      .pipe(
        tap(response => {
          if (response && cacheAccess) {
            this.cacheFeatureAccess(featureName, response.access);
          }
        })
      );
  }

  /**
   * Determines if the current controller has access to a feature.
   * @param featureName
   */
  hasAccessToFeature(featureName: string) {
    // EE-1869: Skip for supervision
    if (this.supervision.isInSupervisionMode) {
      return true;
    }

    const planMetadata = this.auth.planMetadata;

    // EE-1869: Assume access for trial users or when token has no metadata (old token)
    if (!planMetadata) {
      return true;
    }

    const featureAccess = planMetadata[featureName];

    /** softly restrict feature access, but kind of leave it open if it isn't set (so we don't have to maintain all old plans) */
    if (featureAccess === 'true' || featureAccess === undefined || featureAccess === null) {
      return true;
    }

    if (featureAccess === 'false') {
      return false;
    }
  }

  private cacheFeatureAccess(name: string, access: boolean) {
    const cache = sessionStorage.getItem('ec:featureAccess');
    if (cache) {
      const parsed = JSON.parse(cache);
      parsed[name] = access;
      sessionStorage.setItem('ec:featureAccess', JSON.stringify(parsed));
    } else {
      sessionStorage.setItem('ec:featureAccess', JSON.stringify({ [name]: access }));
    }
  }

  private getFeatureAccessFromCache(name: string): boolean {
    const cache = sessionStorage.getItem('ec:featureAccess');
    if (cache) {
      const parsed = JSON.parse(cache);
      return parsed[name] || null;
    } else {
      return null;
    }
  }
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SelectPathDialogComponent } from './select-path-dialog/select-path-dialog.component';

@Component({
  selector: 'app-documents-select-path',
  templateUrl: './select-path.component.html',
  styleUrls: ['./select-path.component.scss']
})
export class SelectPathComponent implements OnInit {
  @Input() path: string[] = [];
  @Input() treeSource: string[][] = [];
  @Input() readonly = false;
  @Output() pathChange = new EventEmitter<string[]>();

  constructor(
    private dialog: MatDialog
  ) { }

  choose() {
    this.dialog.open(SelectPathDialogComponent, {
      data: {
        path: this.path,
        treeSource: this.treeSource,
        readonly: this.readonly
      },
      width: '450px'
    }).afterClosed().subscribe(result => {
      this.path = result || (!this.path ? [] : this.path);
      this.pathChange.emit(this.path);
    }
    );
  }

  ngOnInit(): void {
  }

}

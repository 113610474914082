import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';

import { EventsService } from '../../../services/events.service';
import { BusService } from '../../../services/bus.service';
import { AccessLevelService, AccessLevel } from '../../../services/user/access-level.service';
import { WorkflowTasksService } from '../../../services/workflow-tasks/workflow-tasks.service';


@Component({
  selector: 'app-pa-assigning',
  templateUrl: './pa-assigning.component.html',
  styleUrls: ['./pa-assigning.component.scss']
})
export class PaAssigningComponent implements OnInit, OnDestroy {

  processingActivities = [];
  departments = [];

  selectedPa = undefined;
  accessLevel: AccessLevel;

  constructor(
    private bus: BusService,
    private events: EventsService,
    private accessService: AccessLevelService,
    private tasks: WorkflowTasksService,
    private router: Router,
  ) {
    this.accessService.checkAccess({
      context: 'pa-assigning',
    }).subscribe(response => this.accessLevel = response);
  }

  ngOnInit() {
    this.subscribe();
    this.bus.publish(this.events.requested.data.rpa.getApplicable);
    this.bus.publish(this.events.requested.data.departments.get);
  }

  ngOnDestroy() {
    this.unsubscribe();
  }

  updatePas(data) {
    this.processingActivities = data;
  }

  updateDepartments(data) {
    this.departments = data;
  }

  assignDepartment(department) {
    if (this.canWrite) {
      if (this.selectedPa) {
        this.selectedPa.assignedDepartment = department.id;
        this.bus.publish(this.events.requested.action.rpa.assign, {
          pa: this.selectedPa,
          department: department,
        });
      }
    }
  }

  assignedCount(department?) {
    return this.processingActivities
              .filter(pa =>
                (department && pa.assignedDepartment == department.id) ||
                (!department && pa.assignedDepartment))
              .length;
  }

  get done(): boolean {
    return this.processingActivities.filter(pa => pa.assignedDepartment).length ==
          this.processingActivities.length;
  }

  public get canWrite() {
    return this.accessLevel && this.accessLevel.write;
  }

  finish() {
    this.tasks.progressControllerTask({
      progress: 1,
      workflowCodename: 'path_to_gdpr',
      taskCodename: 'ptg_pa_assigning',
    }).subscribe(() => {
      this.router.navigate(['']);
    });
  }

  subscribe() {
    this.bus.subscribe(this.events.received.data.rpa.getApplicable.success, this.updatePas, this);
    this.bus.subscribe(this.events.received.data.departments.get.success, this.updateDepartments, this);
  }

  unsubscribe() {
    this.bus.unsubscribe(this.events.received.data.rpa.getApplicable.success, this.updatePas);
    this.bus.unsubscribe(this.events.received.data.departments.get.success, this.updateDepartments);
  }
}

<div class="contents">
  <h1 class="title">
    {{ 'rpa.component.titel' | translate }}
  </h1>
  <br/><br/>
  <p>
    {{ 'rpa.component.subtitel' | translate }}
  </p>

  <big-round-button routerLink="pa-selection">
    <mat-icon button-icon color="primary">filter_list</mat-icon>
    <a button-text>
      {{ 'rpa.component.paChaffBtn' | translate }}
    </a>
  </big-round-button>

  <big-round-button routerLink="applicable">
    <mat-icon button-icon color="primary">storage</mat-icon>
    <a button-text>
      {{ 'rpa.component.paDetailsBtn' | translate }}
    </a>
  </big-round-button>

  <big-round-button routerLink="pa-assigning">
    <mat-icon button-icon color="primary">business</mat-icon>
    <a button-text>
      {{ 'rpa.component.paAssignBtn' | translate }}
      <!--
      Departments Responsible for each Activity
      -->
    </a>
  </big-round-button>

<!--
  <big-round-button routerLink="pa-internal-processors">
    <mat-icon button-icon color="primary">usb</mat-icon>
    <a button-text>
      {{ 'rpa.component.paInternalProcessorsBtn' | translate }}
    </a>
  </big-round-button>

  <big-round-button routerLink="pa-external-processors">
    <mat-icon button-icon color="primary">device_hub</mat-icon>
    <a button-text>
      {{ 'rpa.component.paExternalProcessorsBtn' | translate }}
    </a>
  </big-round-button>
  -->

  <big-round-button routerLink="/rpa/custom-pa">
    <mat-icon button-icon color="primary">create</mat-icon>
    <a button-text>
      {{ 'rpa.component.customPaBtn' | translate }}
      <!--
      Create Custom Activities
      -->
    </a>
  </big-round-button>

  <br/><br/><br/>
  <mat-divider></mat-divider>
  <br/><br/>
</div>

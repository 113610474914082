<div class="contents">
  <br><br>
  <h1 class="title">{{ 'payment.create-subscription.title' | translate }}</h1>
  <br><br>
  <p>
    {{ 'payment.create-subscription.plans.message' | translate }}
  </p>
  <div class="current-subscription">
    <div *ngIf="loadingSubscription" class="subscription-info">
      <p>{{ 'payment.create-subscription.payment.upgrade.plan.loading' | translate }}</p>
    </div>
    <div *ngIf="!alreadySubscribed">
      <mat-divider></mat-divider>
      <br>
    </div>
  </div>

  <!-- We're not offering monthly plans any more, so we'll only show the plans instead.
  <div>
    <mat-radio-group [(ngModel)]="paymentInterval" name="paymentInterval" (change)="changePaymentInterval()">
      <mat-radio-button value="year" color="primary">
        <div class="interval-label">
          {{ 'payment.create-subscription.plans.interval.year' | translate }}
          <mat-chip-list>
            <mat-chip selected="true">{{ 'payment.create-subscription.plans.interval.savetext' | translate }}</mat-chip>
          </mat-chip-list>
        </div>
      </mat-radio-button>
      <mat-radio-button value="month" color="primary">
        <div class="interval-label">
          {{ 'payment.create-subscription.plans.interval.month' | translate }}
        </div>
      </mat-radio-button>
    </mat-radio-group>
  </div>
  -->

  <br>

  <div class="loading-plans" *ngIf="!intervalPlans">
    <mat-progress-spinner mode="indeterminate" color="primary"></mat-progress-spinner>
  </div>

  <div *ngIf="intervalPlans" class="plans">
    <div *ngFor="let plan of intervalPlans" class="product-plan-container">
      <ng-container *ngIf="alreadySubscribed">
        <div class="current-plan" *ngIf="isCurrentPlan(plan.metadata.name, plan.interval)">
          {{ 'payment.create-subscription.payment.upgrade.plan.current' | translate }}
        </div>
        <div class="plan-spacer" *ngIf="!isCurrentPlan(plan.metadata.name, plan.interval)"></div>
      </ng-container>
      <mat-card class="product-plan" (click)="selectPlan(plan)" [class.selected]="isSelected(plan.stripeId)"
        [class.subscribed]="isCurrentPlan(plan.metadata.name, plan.interval)">
        <mat-card-title>
          <!-- {{ 'payment.create-subscription.plans.gdpr-basics' | translate }} -->
          <!-- {{ 'payment.create-subscription.plans.small-business' | translate }} -->
          <!-- {{ 'payment.create-subscription.plans.team' | translate }} -->
          <!-- {{ 'payment.create-subscription.plans.small-business-2020' | translate }} -->
          <!-- {{ 'payment.create-subscription.plans.team-2020' | translate }} -->
          <!-- {{ 'payment.create-subscription.plans.essentials-2021' | translate }} -->
          {{ plan.metadata.name | translate }}
        </mat-card-title>

        <mat-card-subtitle>
          <div class="features">
            <div class="feature reference" *ngIf="plan.child">
              <span>
                <!-- All Features from -->
                {{'payment.create-subscription.plans.plan-ref.part1' | translate}}
                {{plan.child.metadata.name | translate}}
                {{'payment.create-subscription.plans.plan-ref.part2' | translate}}
              </span>
              <mat-icon>done_all</mat-icon>
            </div>
            <div class="feature users" *ngIf="!impliedFeature(plan, 'max_users')">
              <span>
                <span *ngIf="plan.child" class="plus">+</span>
                {{ 'payment.create-subscription.plans.features.maxUsers.part1' | translate }}
                {{ plan.metadata.max_users }}
                {{ 'payment.create-subscription.plans.features.maxUsers.part2' | translate }}
              </span>
              <mat-icon>account_circle</mat-icon>
            </div>
            <div class="feature basics" *ngIf="plan.metadata.gdpr_basics=='true'
            && !impliedFeature(plan, 'gdpr_basics')">
              <span>
                <span *ngIf="plan.child" class="plus">+</span>
                {{ 'payment.create-subscription.plans.features.basic-functionality' | translate }}
                <!-- Basic Functionality for GDPR Compliance -->
              </span>
              <mat-icon>assignment_turned_in</mat-icon>
            </div>
            <div class="feature documents" *ngIf="plan.metadata.document_management=='true'
            && !impliedFeature(plan, 'document_management')">
              <span>
                <span *ngIf="plan.child" class="plus">+</span>
                {{ 'payment.create-subscription.plans.features.document-management' | translate }}
                <!-- Document Management -->
              </span>
              <mat-icon>class</mat-icon>
            </div>
            <div class="feature logbook" *ngIf="plan.metadata.logbook=='true'
            && !impliedFeature(plan, 'logbook')">
              <span>
                <span *ngIf="plan.child" class="plus">+</span>
                {{ 'payment.create-subscription.plans.features.logbook' | translate }}
                <!-- Logbook -->
              </span>
              <mat-icon>receipt</mat-icon>
            </div>
            <div class="feature logbook" *ngIf="plan.metadata.reminders=='true'
            && !impliedFeature(plan, 'reminders')">
              <span>
                <span *ngIf="plan.child" class="plus">+</span>
                {{ 'payment.create-subscription.plans.features.reminders' | translate }}
                <!-- Reminders & Warnings -->
              </span>
              <mat-icon>notifications_active</mat-icon>
            </div>
            <div class="feature logbook" *ngIf="plan.metadata.dpia=='true'
            && !impliedFeature(plan, 'dpia')">
              <span>
                <span *ngIf="plan.child" class="plus">+</span>
                {{ 'payment.create-subscription.plans.features.dpia' | translate }}
                <!-- DPIA Check -->
              </span>
              <mat-icon>insert_chart</mat-icon>
            </div>
            <div class="feature premium_support" *ngIf="plan.metadata.premium_support=='true'
            && !impliedFeature(plan, 'premium_support')">
              <span>
                <span *ngIf="plan.child" class="plus">+</span>
                {{ 'payment.create-subscription.plans.features.premium-support' | translate }}
                <!-- Premium Support -->
              </span>
              <mat-icon>headset_mic</mat-icon>
            </div>
            <div class="feature premium_support" *ngIf="plan.metadata.collaboration_tools=='true'
            && !impliedFeature(plan, 'collaboration_tools')">
              <span>
                <span *ngIf="plan.child" class="plus">+</span>
                {{ 'payment.create-subscription.plans.features.collaboration-tools' | translate }}
                <!-- Collaboration Tools -->
              </span>
              <mat-icon>comment</mat-icon>
            </div>
            <div class="feature badge" *ngIf="plan.metadata.badge_access=='true'
            && !impliedFeature(plan, 'badge_access')">
              <span matTooltip="{{'payment.create-subscription.plans.features.compliance-badge.tooltip' | translate}}">
                <span *ngIf="plan.child" class="plus">+</span>
                {{'payment.create-subscription.plans.features.compliance-badge.text' | translate}}<sup><b>*</b></sup>
              </span>
              <mat-icon>stars</mat-icon>
            </div>
          </div>
        </mat-card-subtitle>

        <mat-card-content>
          <a class="price">{{ plan.price / 100 | localizedCurrency: plan.currency }}</a>
          <br>
          <mat-chip-list>
            <mat-chip [selected]="isSelected(plan.stripeId)" color="accent">
              {{ 'payment.create-subscription.plan.card.interval.' + plan.interval | translate }}
            </mat-chip>
          </mat-chip-list>
        </mat-card-content>
      </mat-card>
    </div>
  </div>

  <br><br>
  <mat-divider></mat-divider>
  <br>

  <div *ngIf="alreadySubscribed" class="payment-info existing-subscription">
    <div *ngIf="paymentPreview && selectedPlan && !isDowngrade" class="upgrade-summary">
      <div class="plan-summary">
        {{ 'payment.create-subscription.payment.selectedplan' | translate }}
        <a class="plan-name"> {{selectedPlan.metadata.name | translate}}</a>,
        <a class="plan-price">
          <ng-container>
            <p>{{ 'payment.create-subscription.payment.upgrade.price.next' | translate }}</p>
            <b>{{ paymentPreview.remaining / 100 | localizedCurrency : selectedPlan.currency : true : '1.2-2' }}</b>
            <p>{{ 'payment.create-subscription.payment.upgrade.price.following' | translate }}</p>
            <b>{{ selectedPlan.price / 100 | localizedCurrency : selectedPlan.currency : true : '1.2-2' }}</b>
          </ng-container>
        </a>

        {{ 'payment.create-subscription.plan.card.interval.' + selectedPlan.interval | translate }}
      </div>

      <div class="plan-tax">
        <ng-container *ngIf="paymentPreview.taxPercent !== 0">
          <p>{{ 'payment.create-subscription.payment.upgrade.vat.first' | translate }}:
            {{ paymentPreview.tax / 100 | localizedCurrency : selectedPlan.currency : 'symbol' : '1.2-2'}} (16%)</p>
          <p>{{ 'payment.create-subscription.payment.upgrade.vat.following' | translate }}:
            {{ priceWithTax | localizedCurrency : selectedPlan.currency : 'symbol' : '1.2-2'}} (16%)</p>
        </ng-container>
      </div>
    </div>

    <div *ngIf="!isDowngrade && !paymentPreview && isPlanSelected || isLoading" class="upgrade-summary loading-data">
      <mat-progress-spinner mode="indeterminate" strokeWidth="1" diameter="60" color="accent" class="payment-progress">
      </mat-progress-spinner>
    </div>

    <br>

    <div *ngIf="subscriptionError" class="payment-error">
      <mat-card>
        <mat-card-header>
          <mat-icon mat-card-avatar color="warn">error</mat-icon>
          <mat-card-title>
            {{'payment.create-subscription.error.upgrade' | translate}}
          </mat-card-title>
        </mat-card-header>
        <mat-card-content>
          {{'payment.create-subscription.error.hint' | translate}}
        </mat-card-content>
        <mat-card-actions align="end">
          <button mat-flat-button color="accent" routerLink="/payment/update-details">
            {{ 'payment.billing.current.updateDetails' | translate }}
          </button>
        </mat-card-actions>
      </mat-card>
    </div>

    <div *ngIf="isDowngrade" class="payment-downgrade">
      <p>{{ 'payment.create-subscription.upgrade.noDowngrades' | translate }}</p>
    </div>

    <button [disabled]="!isPlanSelected || isLoading || isDowngrade || upgrade3DSIssue" mat-raised-button
      color="primary" (click)="upgradePlan($event)">
      {{ 'payment.create-subscription.payment.subscribe' | translate }}
    </button>
  </div>

  <div *ngIf="!alreadySubscribed" class="payment-info new-subscription">
    <ng-container *ngIf="!inactiveSubscription">
      <br>
      <p>
        {{ 'payment.create-subscription.creditcard.message' | translate }}
      </p>
      <br>
      <br>
    </ng-container>

    <div class="vat-eu">
      <!--
      <div class="vat-id-message">
        <p>{{ 'payment.create-subscription.payment.vat.message' | translate }}</p>
      </div>
      -->
      <mat-checkbox [(ngModel)]="isEuCountry" name="isEuCountry" value="false">
        {{ 'payment.create-subscription.payment.vat.iseu' | translate }}
      </mat-checkbox>
    </div>
    <br>

    <ng-container *ngIf="!inactiveSubscription">
      <form class="payment-form" novalidate (ngSubmit)="checkout($event)" [formGroup]="paymentForm">
        <div *ngIf="subscriptionError" class="payment-error">
          {{ subscriptionError }}
        </div>

        <mat-form-field class="form-field">
          <input matInput placeholder="{{'payment.create-subscription.payment.cardowner' | translate}}" type="text"
            name="name" formControlName="name" required />
        </mat-form-field>

        <mat-form-field *ngIf="isEuCountry" class="form-field">
          <input matInput placeholder="{{'payment.create-subscription.payment.vat.vatid' | translate}}" type="text"
            name="vatId" formControlName="vatId" />
        </mat-form-field>

        <div id="card-element" class="form-field stripe-element"></div>

        <mat-form-field class="form-field">
          <input matInput placeholder="{{'payment.create-subscription.payment.couponcode' | translate}}" type="text"
            name="couponCode" formControlName="couponCode" />
        </mat-form-field>

        <br><br>

        <div class="plan-form-footer">

          <div class="payment-summary" *ngIf="isPlanSelected">
            <div class="plan-summary">
              {{ 'payment.create-subscription.payment.selectedplan' | translate }}
              <a class="plan-name"> {{ selectedPlan.metadata.name | translate }}</a>,

              <a class="plan-price">
                <ng-container *ngIf="!coupon || !canUseCouponWithPlan">
                  <b>{{ selectedPlan.price / 100 | localizedCurrency: selectedPlan.currency}}</b>
                </ng-container>
                <ng-container *ngIf="coupon && canUseCouponWithPlan">
                  <span class="old-price">{{ selectedPlan.price / 100 | localizedCurrency: selectedPlan.currency}}</span>
                  <b>{{ couponPrice / 100 | localizedCurrency : selectedPlan.currency : true : '1.2-2' }}</b>
                </ng-container>
              </a>

              {{ 'payment.create-subscription.plan.card.interval.' + selectedPlan.interval | translate }}
            </div>

            <div class="plan-tax">
              <ng-container *ngIf="requiresTax">
                <p>+19% {{ 'payment.create-subscription.payment.vat.vat' | translate }}:
                  {{ priceWithTax | localizedCurrency : selectedPlan.currency : 'symbol' : '1.2-2'}}</p>
              </ng-container>
            </div>

            <div class="coupon-hint" *ngIf="coupon && canUseCouponWithPlan">
              {{ 'payment.create-subscription.payment.coupon.title' | translate }}
              <div *ngIf="coupon">
                <div *ngIf="coupon.amountOff">
                  <b>{{ coupon.id}} -{{ coupon.amountOff / 100 | localizedCurrency: selectedPlan.currency}}</b>
                </div>
                <div *ngIf="coupon.percentOff">
                  <b>{{ coupon.id}} -{{ coupon.percentOff }}%</b>
                </div>
              </div>
              ,
              {{ 'payment.create-subscription.payment.coupon.saved' | translate }}
              <b>{{ couponSaving | localizedCurrency : selectedPlan.currency : 'symbol' : '1.2-2' }}</b>
            </div>
          </div>

          <br>

          <div *ngIf="isLoading" class="payment-summary loading-data">
            <mat-progress-spinner mode="indeterminate" strokeWidth="1" diameter="60" color="accent"
              class="payment-progress">
            </mat-progress-spinner>
          </div>

          <div *ngIf="!isPlanSelected" class="no-plan-selected-hint">
            <p>{{ 'payment.create-subscription.payment.noPlanSelected' | translate }}</p>
          </div>

          <div class="no-plan-selected-hint" *ngIf="!canUseCouponWithPlan">
            <p>{{ 'payment.create-subscription.payment.couponNotForPlan' | translate }}</p>
          </div>

          <!-- {{ 'payment.create-subscription.payment.subscribe' | translate }} -->
          <!-- {{ 'payment.create-subscription.payment.useCoupon' | translate }} -->

          <button [disabled]="!isPlanSelected || paymentForm.invalid || isLoading" mat-raised-button color="primary"
            type="submit">
            {{ (isFreeCoupon && canUseCouponWithPlan ?  'payment.create-subscription.payment.useCoupon' : 'payment.create-subscription.payment.subscribe') | translate }}
          </button>
        </div>
      </form>
    </ng-container>

    <ng-container *ngIf="inactiveSubscription">
      <form class="payment-form" novalidate (ngSubmit)="createSubscription($event)" [formGroup]="paymentForm">
        <div *ngIf="subscriptionError && !isLoading" class="payment-error">
          <mat-card>
            <mat-card-header>
              <mat-icon mat-card-avatar color="warn">error</mat-icon>
              <mat-card-title>
                {{'payment.create-subscription.error.subscription' | translate}}
              </mat-card-title>
            </mat-card-header>
            <mat-card-content>
              {{'payment.create-subscription.error.hint' | translate}}
            </mat-card-content>
            <mat-card-actions align="end">
              <button mat-flat-button color="accent" routerLink="/payment/update-details">
                {{ 'payment.billing.current.updateDetails' | translate }}
              </button>
            </mat-card-actions>
          </mat-card>
        </div>

        <mat-form-field *ngIf="isEuCountry" class="form-field">
          <input matInput placeholder="{{'payment.create-subscription.payment.vat.vatid' | translate}}" type="text"
            name="vatId" formControlName="vatId" />
        </mat-form-field>

        <br>
        <br>

        <div class="plan-form-footer">

          <div class="payment-summary" *ngIf="isPlanSelected">
            <div class="plan-summary">
              {{ 'payment.create-subscription.payment.selectedplan' | translate }}
              <a class="plan-name"> {{ selectedPlan.metadata.name | translate }}</a>,

              <a class="plan-price">
                <ng-container *ngIf="!coupon">
                  <b>{{ selectedPlan.price / 100 | localizedCurrency: selectedPlan.currency }}</b>
                </ng-container>
                <ng-container *ngIf="coupon">
                  <span class="old-price">{{ selectedPlan.price / 100 | localizedCurrency: selectedPlan.currency }}</span>
                  <b>{{ couponPrice / 100 | localizedCurrency : selectedPlan.currency : true : '1.2-2' }}</b>
                </ng-container>
              </a>

              {{ 'payment.create-subscription.plan.card.interval.' + selectedPlan.interval | translate }}
            </div>

            <div class="plan-tax">
              <ng-container *ngIf="requiresTax">
                <p>+16% {{ 'payment.create-subscription.payment.vat.vat' | translate }}: {{ priceWithTax | localizedCurrency : selectedPlan.currency : 'symbol'
                  : '1.2-2'}}</p>
              </ng-container>
            </div>

            <div class="coupon-hint" *ngIf="coupon">
              {{ 'payment.create-subscription.payment.coupon.title' | translate }}
              <b>{{ coupon.id }} ({{ couponDescriptor }})</b>
              , {{ 'payment.create-subscription.payment.coupon.saved' | translate }}
              <b>{{ couponSaving | localizedCurrency : selectedPlan.currency : 'symbol' : '1.2-2' }}</b>
            </div>
          </div>

          <br>

          <div *ngIf="isLoading" class="payment-summary loading-data">
            <mat-progress-spinner mode="indeterminate" strokeWidth="1" diameter="60" color="accent"
              class="payment-progress">
            </mat-progress-spinner>
          </div>

          <div *ngIf="!isPlanSelected" class="no-plan-selected-hint">
            <p>{{ 'payment.create-subscription.payment.noPlanSelected' | translate }}</p>
          </div>

          <button [disabled]="!isPlanSelected || isLoading" mat-raised-button color="primary" type="submit">
            {{ 'payment.create-subscription.payment.subscribe' | translate }}
          </button>
        </div>
      </form>
    </ng-container>

  </div>

  <br><br><br><br>

  <!-- Some extra stuff
  {{ 'payment.create-subscription.plan.card.interval.month' | translate }}
  {{ 'payment.create-subscription.plan.card.interval.year' | translate }}
  -->

</div>

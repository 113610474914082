import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface DpiaNecessityItem {
  identifier: string;
  applies: boolean;
  score: number;
}

const DPIA_NECESSITY_ITEMS = [
  {
    identifier: 'sensitive-data',
    score: 2,
    readonly: true
  },
  // {
  //   identifier: 'social-data',
  //   score: 2
  // },
  {
    identifier: 'new-technologies',
    score: 2
  },
  {
    identifier: 'automatic-scoring',
    score: 2
  },
  {
    identifier: 'public-surveillance',
    score: 2
  },
  {
    identifier: 'third-country-transfers',
    score: 2
  },
  {
    identifier: 'surveillance-control',
    score: 1
  },
  {
    identifier: 'high-volume-processing',
    score: 1
  },
  {
    identifier: 'two-processings-merge',
    score: 1
  },
  {
    identifier: 'vulnerable-people',
    score: 1
  },
  {
    identifier: 'limited-right-exercise',
    score: 1
  },
];

const SENSITIVE_DATA_CATEGORIES = [
  'pa.dataSubject.dataCategories.criminal',
  'pa.dataSubject.dataCategories.biometricInfo',
  'pa.dataSubject.dataCategories.geneticData',
  'pa.dataSubject.dataCategories.ethnicity',
  'pa.dataSubject.dataCategories.healthData',
  'pa.dataSubject.dataCategories.religion',
  'pa.dataSubject.dataCategories.tradeUnion',
  'pa.dataSubject.dataCategories.politicalInformation',
  'pa.dataSubject.dataCategories.sex',
  'pa.dataSubject.dataCategories.social-sensitive',
  'pa.dataSubject.dataCategories.indicatorsOfBehaviour',
  'pa.dataSubject.dataCategories.personalityTraits',
];

@Component({
  selector: 'app-dpia-necessity-dialog',
  templateUrl: './dpia-necessity-dialog.component.html',
  styleUrls: ['./dpia-necessity-dialog.component.scss']
})
export class DpiaNecessityDialogComponent implements OnInit {

  answered: DpiaNecessityItem[] = [];

  constructor(
    public ref: MatDialogRef<DpiaNecessityDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data: any
  ) {
    this.answered = data.items || [];
    this.prepareSensitiveDataQuestion(data.pa);
  }

  prepareSensitiveDataQuestion(pa) {
    const existing = this.answered.find(i => i.identifier === 'sensitive-data');
    const applies = pa.paDataCategoriesDescription ? pa.paDataCategoriesDescription.some(c => SENSITIVE_DATA_CATEGORIES.indexOf(c) >= 0) : false;

    if (!existing) {
      this.answered.push({
        identifier: 'sensitive-data',
        score: 2,
        applies
      });
    } else {
      existing.applies = applies;
    }
  }

  getQuestions() {
    const countToShow = this.dpiaNecessary ? this.answered.length :  this.answered.length + 1;

    return this.questions.slice(0, countToShow);
  }

  close() {
    this.ref.close(this.answered);
  }

  getItem(identifier: string) {
    return this.answered.find(a => a.identifier === identifier)?.applies;
  }

  setItem(item: DpiaNecessityItem, $event) {
    item.applies = $event.value;

    const existing = this.answered.findIndex(a => a.identifier === item.identifier);
    if (existing === -1) {
      this.answered.push(item);
    } else {
      this.answered[existing].applies = item.applies;
    }
  }

  get allFilled() {
    return this.answered.length === DPIA_NECESSITY_ITEMS.length;
  }

  get answeredScore() {
    const items = this.answered.filter(n => n.applies).map(n => n.score);

    if (items.length === 0) return 0;

    return items.reduce((o, n) => o + n);
  }

  get dpiaNecessary() {
    return this.answeredScore >= 2;
  }

  get questions() {
    return DPIA_NECESSITY_ITEMS;
  }

  ngOnInit(): void {
  }

}

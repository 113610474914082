import { Component, Injector, Input, OnDestroy, OnInit } from '@angular/core';
import {
  Audit,
  AuditConfiguration,
  AuditsService,
  Question,
} from 'app/services/audits/audits.service';
import { AuditFieldState, fetchAuditLikeService } from '../../../util';

@Component({
  selector: 'app-audit-form-question-input-fields',
  templateUrl: './input-fields.component.html',
  styleUrls: ['./input-fields.component.scss'],
})
export class AuditFormQuestionInputFieldsComponent
  implements OnInit, OnDestroy
{
  @Input() audit: Audit = null;
  @Input() question: Question = null;
  @Input() readonly = false;

  @Input() auditConfiguration: AuditConfiguration = null;

  @Input() path = '';
  @Input() nestLevel = 0;

  fields: AuditFieldState<string>[] = [];

  constructor(private injector: Injector) {}

  ngOnInit() {
    for (const inputField of this.auditConfiguration.inputFields) {
      const path = `${this.path}_${inputField}`;
      const value = this.audit.answers[path];
      const service = fetchAuditLikeService(
        this.auditConfiguration,
        this.injector
      );

      const f = new AuditFieldState<string>(
        service,
        this.audit.id,
        inputField,
        path,
        value
      );
      this.fields.push(f);
    }
  }

  ngOnDestroy() {
    this.fields.map((f) => f.clear());
  }
}

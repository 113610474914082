import {Component, OnInit, OnDestroy, Inject} from '@angular/core';
import {Router, ActivatedRoute } from '@angular/router';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {TranslateService} from '@ngx-translate/core';

import RegistrationModel from './registration.model';
import RegistrationForm from './registration.form';
import {BusService} from '../../services/bus.service';
import {EventsService} from '../../services/events.service';
import {SupervisionClientInvitationService} from '../../services/supervision/client-invitation.service';
import { SupervisorProfileService } from 'app/services/supervision/supervisor-profile.service';
import { RegistrationRequestService, RegistrationToken } from '../../services/authentication-request/registration.service';
import { getInstanceName } from 'app/util/environment';
import { Validators } from '@angular/forms';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'registration-component',
  templateUrl: './registration.template.html',
  styleUrls: ['./registration.styles.scss']
})
export class RegistrationComponent implements OnInit, OnDestroy {

  private model: RegistrationModel;
  private form: RegistrationForm;
  private loading = false;
  public isFormErrorMessage = false;

  public state = 'initial';
  private email: string = null;
  private resending = false;

  public inviteToken: string = null;
  public inviteData: any = null;

  public coSupervisionInviteToken: string = null;
  public coSupervisionInvite: any = null;

  public clientInviteToken: string = null;
  public clientInviteSupervisor: any = null;
  public clientVerified = false;

  public registrationToken: string = null;
  public registrationPayload: any = null;

  constructor(
    private router: Router,
    private bus: BusService,
    private events: EventsService,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private clientInvitationService: SupervisionClientInvitationService,
    private supervisorProfileService: SupervisorProfileService,
    private registrationService: RegistrationRequestService
  ) {
    this.model = new RegistrationModel();
    this.form = new RegistrationForm(this.model);

    getInstanceName().subscribe((instance) => {
      if (instance !== 'vater') {
        this.form.formGroup.controls.tos.setValidators([Validators.requiredTrue]);
      } 
    });

    route.queryParams.subscribe(params => {
      if (params.invite) {
        this.inviteToken = params.invite;
        this.bus.publish(this.events.requested.data.controller.decodeInviteToken, this.inviteToken);
      }
      if (params.clientInvite) {
        this.clientInviteToken = params.clientInvite;
        this.clientInvitationService.get(this.clientInviteToken).subscribe(result => {
          this.updateSupervisor(result);
        });
      }
      if (params['co-supervisor-invite']) {
        this.coSupervisionInviteToken = params['co-supervisor-invite'];
        this.supervisorProfileService.getInvite(this.coSupervisionInviteToken)
        .subscribe(
          invite => this.updateCoSupervisionInvite(invite),
          () => {
            this.coSupervisionInviteToken = undefined;
          }
        );
      }
      if (params.registrationToken) {
        this.registrationToken = params.registrationToken;
        this.registrationPayload = this.registrationService.decodeToken(this.registrationToken);
        if (this.registrationPayload) {
          this.updateRegistration(this.registrationPayload);
        } else {
          this.router.navigate(['/']);
        }
      }
      if (params.ref) {
        if (params.ref.length > 0) {
          this.form.patchForm({
            heardAboutUsHow: params.ref
          });
        } else {
          this.router.navigate(['/']);
        }
      }
    });
  }

  private invalidForm(): void {
    this.form.validate();
    this.isFormErrorMessage = true;
  }

  private submit(): void {
    this.bus.publish(this.events.notified.validation.form.aggregation, this.form.isValid);
    this.bus.publish(this.events.notified.validation.form.action);
  }

  private registration(): void {
    if (this.inviteToken) {
      this.model.inviteToken = this.inviteToken;
    }
    if (this.clientInviteToken) {
      this.model.clientInviteToken = this.clientInviteToken;
    }
    if (this.coSupervisionInviteToken) {
      this.model.coSupervisionInviteToken = this.coSupervisionInviteToken;
    }
    if (this.registrationToken) {
      this.model.registrationToken = this.registrationToken;
    }

    this.bus.publish(this.events.requested.data.registration, this.model);
  }

  private failure(error): void {
    let reason = 'bad-data';
    if (error.status === 500) {
      reason = 'internal';
    }
    if (error.status === 403) {
      reason = 'free-email';
    }

    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    this.dialog.open(RegistrationDialogComponent,
      {width: '350px', data: {'reason': reason}});
  }

  private success(result): void {
    if (this.inviteData) {
      this.email = this.inviteData.invite.email;
    } else {
      this.email = result.email;
    }

    this.state = 'success';
    if (this.clientInviteSupervisor) {
      this.clientVerified = result.verified;
    }

    if (this.coSupervisionInvite) {
      this.clientVerified = result.verified;
    }

    // firstpromoter.com signup tracking
    // NOTE: so this fails sometimes, as a result I'm putting it in try catch.
    try {
      window['$FPROM'].trackSignup({
          email: this.email
        },
      );
    } catch (err) {}
  }

  public resendEmail(): void {
    if (this.email && !this.resending) {
      this.bus.publish(this.events.requested.action.resendVerificationEmail, {email: this.email});
      this.resending = true;
      setTimeout(() => {
        this.resending = false;
      }, 1000);
    }
  }

  updateInvite(response) {
    this.inviteData = response;

    this.translate.get('registration.invited.heardAboutUsHow').subscribe(msg => {
      this.form.patchForm({
        email: response.invite.email,
        firstName: response.invite.firstName,
        lastName: response.invite.lastName,
        company: response.controller.controllerName,
        heardAboutUsHow: `${msg} ${response.inviter.first_name} ${response.inviter.last_name} (${response.inviter.email})`,
      });
    });
  }

  updateSupervisor(request) {
    this.clientInviteSupervisor = request.supervisor;

    this.translate.get('registration.invited.heardAboutUsHow').subscribe(msg => {
      this.form.patchForm({
        email: request.email,
        heardAboutUsHow: `${msg} ${request.supervisor.displayName} (${request.createdBy.email})`,
      });
    });
  }

  updateCoSupervisionInvite(invite) {
    this.coSupervisionInvite = invite;

    this.translate.get('registration.invited.heardAboutUsHow').subscribe(msg => {
      this.form.patchForm({
        email: invite.email,
        company: invite.controller?.controllerName,
        heardAboutUsHow: invite.inviter ? `${msg} ${invite.profile.displayName} (${invite.inviter.email})` : '',
      });
    });
  }

  updateRegistration(payload: RegistrationToken) {
    this.translate.get('registration.invited.heardAboutUsHow').subscribe(msg => {
      this.form.patchForm({
        email: payload.email,
        heardAboutUsHow: payload.invitedBy ? `${msg} ${payload.invitedBy}` : '',
      });
    });
  }

  public subscribe(): void {
    this.bus.subscribe(this.events.received.validation.failure, this.invalidForm, this);
    this.bus.subscribe(this.events.received.validation.success, this.registration, this);
    this.bus.subscribe(this.events.received.registration.failure, this.failure, this);
    this.bus.subscribe(this.events.received.registration.success, this.success, this);
    this.bus.subscribe(this.events.received.data.controller.decodeInviteToken.success, this.updateInvite, this);
  }

  public unSubscribe(): void {
    this.bus.unsubscribe(this.events.received.validation.failure, this.invalidForm);
    this.bus.unsubscribe(this.events.received.validation.success, this.registration);
    this.bus.unsubscribe(this.events.received.registration.failure, this.failure);
    this.bus.unsubscribe(this.events.received.registration.success, this.success);
    this.bus.unsubscribe(this.events.received.data.controller.decodeInviteToken.success, this.updateInvite);
  }

  public ngOnInit(): void {
    this.subscribe();
    this.bus.publish(this.events.ui.requested.sidenav.expand);
  }

  public ngOnDestroy(): void {
    this.unSubscribe();
    this.bus.publish(this.events.ui.requested.sidenav.collapse);
  }
}

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'registration-dialog',
  templateUrl: './registration.dialog.template.html'
})
export class RegistrationDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<RegistrationDialogComponent>,
  ) {
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MixpanelService } from '../../../modules/mixpanel/mixpanel.service';
import { SharedDocument, SharedDocumentsService } from '../../../services/documents/shared.service';
import {Document, DocumentResourceService} from "../../../services/documents/document-resource.service";

@Component({
  selector: 'app-shared-documents',
  templateUrl: './shared.component.html',
  styleUrls: ['./shared.component.scss']
})
export class SharedDocumentsComponent implements OnInit {
  @Input() showTitle = true;

  sharedDocuments: SharedDocument[] = [];
  supervisorDocuments: Document[] = [];
  highlighted: number = null;

  constructor(
    private sharedDocumentsService: SharedDocumentsService,
    private documentsService: DocumentResourceService,
    private mixpanel: MixpanelService,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.update();
    this.route.queryParams.subscribe(params => {
      this.highlighted = params.sharedDocument;
    });
  }

  update() {
    this.sharedDocumentsService.getSharedDocuments()
      .subscribe(documents => {
        this.sharedDocuments = documents;
      });

    this.documentsService.getAllSupervisor()
      .subscribe(documents => {
        this.supervisorDocuments = documents;
      });
  }

  downloaded(e) {
    this.mixpanel.track('Supervisor document downloaded');
  }


}

import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ControllerService } from 'app/services/controller/controller.service';

@Component({
  selector: 'app-supervision-client-id-dialog',
  templateUrl: './supervision-client-id-dialog.component.html',
  styleUrls: ['./supervision-client-id-dialog.component.scss']
})
export class SupervisionClientIdDialogComponent implements OnInit {

  ogId: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public controller: any,
    private dialogRef: MatDialogRef<SupervisionClientIdDialogComponent>,
    private service: ControllerService,
  ) { }

  ngOnInit(): void {
    this.ogId = this.controller.clientId;
    this.dialogRef.beforeClosed().subscribe(saved => {
      if (!saved) {
        this.controller.clientId = this.ogId;
      }
    });
  }

  submit() {
    this.service.requestPut(this.controller).subscribe(() => {
      this.dialogRef.close(true);
    },
    () => {
      this.dialogRef.close();
    });
  }
}

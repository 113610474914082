import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { BusService } from '../../../services/bus.service';
import { EventsService } from '../../../services/events.service';
import { AuthenticationService } from '../../../services/authentication.service';
import { SupervisionClientInvitationService } from 'app/services/supervision/client-invitation.service';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-confirm-supervision-invitation',
  templateUrl: './invitation.component.html',
  styleUrls: ['./invitation.component.scss']
})
export class ConfirmSupervisionInvitationComponent implements OnInit, OnDestroy {

  inviteToken: string = null;
  request: any;
  isSupervised: boolean;

  constructor(
    private bus: BusService,
    private events: EventsService,
    private auth: AuthenticationService,
    private route: ActivatedRoute,
    private router: Router,
    private clientInvitationService: SupervisionClientInvitationService,
    private translate: TranslateService
  ) {
    this.route.queryParams.subscribe(params => {
      this.inviteToken = params.clientInvite;
      this.clientInvitationService.get(this.inviteToken).subscribe(request => {
        this.request = request;
      });
      this.bus.publish(this.events.requested.data.supervision.clientInvitation.info);
    });
  }

  ngOnInit() {
    this.subscribe();
  }

  ngOnDestroy() {
    this.unsubscribe();
  }

  get supervisor() {
    return this.request.supervisor;
  }

  confirm() {
    this.clientInvitationService.accept(this.inviteToken).subscribe(() => {
      this.router.navigate(['']);
    });
  }

  infoSuccess() {
    this.isSupervised = true;
  }

  subscribe() {
    this.bus.subscribe(this.events.received.data.supervision.clientInvitation.info.success, this.infoSuccess, this);
  }

  unsubscribe() {
    this.bus.unsubscribe(this.events.received.data.supervision.clientInvitation.info.success, this.infoSuccess);
  }
}

import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { GroupListService } from '../../../../services/supervision/group-list.service';
import { BusService } from '../../../../services/bus.service';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-create-group-dialog',
  templateUrl: './create-group-dialog.component.html',
  styleUrls: ['./create-group-dialog.component.scss'],
})
export class CreateGroupDialogComponent implements OnInit, OnDestroy {

  title = new FormControl('', [Validators.required]);

  constructor(
    private groupListService: GroupListService,
    private dialogRef: MatDialogRef<CreateGroupDialogComponent>,
    private bus: BusService,
  ) { }

  ngOnInit(): void {
    this.subscribe();
  }

  ngOnDestroy() {
    this.unsubscribe();
  }

  create() {
    if ( this.title.valid) {
      this.groupListService.createGroup({
        title: this.title.value,
      });
    }
  }

  created() {
    this.dialogRef.close(true);
  }

  createFailed(error) {
    console.error(error);
  }

  subscribe() {
    this.bus.subscribe('create-group-event', this.created, this);
    this.bus.subscribe('create-group-failed-event', this.createFailed, this);
  }

  unsubscribe() {
    this.bus.unsubscribe('create-group-event', this.created);
    this.bus.unsubscribe('create-group-failed-event', this.createFailed);
  }

}

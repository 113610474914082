import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AppConfig } from 'app/app.config';
import { MetricsService } from 'app/services/metrics/metrics.service';
import { curveMonotoneX } from 'd3-shape';

import { riskAnalysisClassificationByValue, RiskAnalysisClassificationColors, RiskAnalysisClassificationLabels } from 'app/components/rpa/pa-details/risk-analysis/risk-analysis.consts';
import { zip } from 'rxjs';
import { map } from 'rxjs/operators';
import { el } from 'date-fns/locale';

@Component({
  selector: 'app-metrics',
  templateUrl: './metrics.component.html',
  styleUrls: ['./metrics.component.scss']
})
export class MetricsComponent implements OnInit {

  appConfig = AppConfig;

  curveEvents = curveMonotoneX;
  riskDistColors = {
    domain: []
  };

  controller: any;

  constructor(
    private metrics: MetricsService,
    private translate: TranslateService
  ) {
  }

  ngOnInit(): void {
    this.metrics.getMetrics().subscribe(data => this.setController(data))
  }

  async setController(controller) {
    this.controller = controller;

    if (this.controller._extra) {
      this.controller._extra.auditProgress = this.controller._extra.audit.reduce(
        (total, task) => total + task.progress, 0) / this.controller._extra.audit.length;

      this.controller._extra.rpaProgress = this.controller._extra.rpa.reduce(
        (total, task) => total + task.progress, 0) / this.controller._extra.rpa.length;

      this.controller._extra.dsarIncidentTimeline = [{
        name: 'DSAR',
        series: [],
      }, {
        name: 'Incidents',
        series: [],
      }];

      this.translate.get('supervision.clientDetails.controller.open-dsars.label').subscribe(
        trans => this.controller._extra.dsarIncidentTimeline[0].name = trans
      );
      this.translate.get('supervision.clientDetails.controller.reported-incidents.label').subscribe(
        trans => this.controller._extra.dsarIncidentTimeline[1].name = trans
      );
      if (this.controller._extra.dsarHistory) {
        this.createDSARTimeSeries();
      }
      if (this.controller._extra.incidentHistory) {
        this.createIncidentTimeSeries();
      }

      /**
       * Refactored this piece of code to stop showing the Unassigned label.
       * Dist = distribution... What department each pa belongs to.
       * This uses the assignedDepartment id, not the department name;
       */
      this.controller._extra.paDist = []; // pa distribution array

      if (this.controller._extra.cpas && this.controller._extra.departments) {
        this.controller._extra.cpas.forEach(pa => {

          this.populatePaDist(pa)

        });
      }

      this.controller._extra.paRiskDist = [
        { signifier: 0, name: 'safe', value: 0, },
        { signifier: 0, name: 'mostly-safe', value: 0, },
        { signifier: 0, name: 'warning', value: 0, },
        { signifier: 0, name: 'serious-warning', value: 0, },
        { signifier: 0, name: 'danger', value: 0, },
        { signifier: 0, name: 'catastrophe', value: 0, },
        { signifier: 0, name: 'unknown', value: 0, },
        { signifier: 0, name: 'needs-clarification', value: 0, }
      ];
      if (this.controller._extra.cpas) {
        this.controller._extra.cpas.forEach(cpa => {
          const classification = riskAnalysisClassificationByValue(cpa.pa_risk_analysis_value);
          let entry = this.controller._extra.paRiskDist.find(e => e.name === classification);
          // if (!entry) {
          //   entry = {
          //     signifier: cpa.pa_risk_analysis_value,
          //     name: classification,
          //     value: 0,
          //   };
          //   this.controller._extra.paRiskDist.push(entry);
          // }
          entry.signifier = cpa.pa_risk_analysis_value;

          entry.value++;
        });
        this.controller._extra.paRiskDist.sort((a, b) => a.signifier - b.signifier);
        this.controller._extra.paRiskDist.forEach(group => {
          this.riskDistColors.domain.push(RiskAnalysisClassificationColors[group.name]);
        });
      }

      zip(
        ...Object.entries(RiskAnalysisClassificationLabels).map(
          entry => this.translate.get(entry[1]).pipe(map(trans => [entry[0], trans]))
        )
      ).subscribe(trans => {
        const dict = trans.reduce((d, entry) => {
          d[entry[0]] = entry[1];
          return d;
        }, {});

        this.controller._extra.paRiskDist.forEach(entry => {
          entry.name = dict[entry.name];
        });
      });

      this.controller._extra.eventsTimeSeries = [{
        name: 'Events',
        series: []
      }];

      if (this.controller._extra.events) {
        this.createEventsTimeSeries();
        this.translate.get('supervision.clientDetails.charts.events.axis').subscribe(n => {
          this.controller._extra.eventsTimeSeries[0].name = n;
        });
      }
    }
  }

  populatePaDist(pa) {
    if (!pa.assignedDepartment) {
      this.unassignedDepartment()
      return;
    }

    let dept = this.controller._extra.departments.find(dep => dep.id === pa.assignedDepartment) // current department
    let currentDept = this.controller._extra.paDist.find(entry => entry.id === pa.assignedDepartment) // current distribution

    if (!currentDept) { // if the current distribution of pas is not on the list
      let entry = {
        id: dept.id,
        name: dept.name,
        value: 1
      }
      this.controller._extra.paDist.push(entry) // add a new distribution item
    } else {

      currentDept.value++;
    }
  }

  unassignedDepartment() {
    let unnassigned = this.controller._extra.paDist.find(el => el.name === 'Unassigned');
    if (unnassigned) {
      unnassigned.value++;
    } else {
      this.controller._extra.paDist.push({
        // id: 'Unassigned',
        name: 'Unassigned',
        value: 1
      })
    }
  }

  createEventsTimeSeries() {
    const start = new Date();
    const end = new Date();
    start.setMonth(start.getMonth() - 6);

    this.controller._extra.events.forEach(event => {
      event.timestamp_at = new Date(event.timestamp_at);
    });

    for (let cursor = new Date(start); cursor <= end; cursor.setDate(cursor.getDate() + 7)) {
      const aWeekAfter = new Date(cursor);
      aWeekAfter.setDate(aWeekAfter.getDate() + 7);

      this.controller._extra.eventsTimeSeries[0].series.push({
        name: `${cursor.getDate()}/${cursor.getMonth() + 1}`,
        value: this.controller._extra.events.filter(event =>
          event.timestamp_at >= cursor && event.timestamp_at < aWeekAfter
        ).length
      });
    }
  }

  createDSARTimeSeries() {
    const start = new Date();
    const end = new Date();
    start.setFullYear(start.getFullYear() - 1);

    this.controller._extra.dsarHistory.forEach(dsar => {
      dsar.receivedOn = new Date(dsar.receivedOn);
      dsar.completionOn = dsar.completionOn ? new Date(dsar.completionOn) : new Date('1-1-3000');
    });

    for (let cursor = new Date(start); cursor <= end; cursor.setMonth(cursor.getMonth() + 1)) {
      this.controller._extra.dsarIncidentTimeline[0].series.push({
        name: `${cursor.toLocaleString('default', { month: 'short' })} ${cursor.getFullYear()}`,
        value: this.controller._extra.dsarHistory.filter(dsar =>
          dsar.receivedOn <= cursor && dsar.completionOn > cursor
        ).length
      });
    }
  }

  createIncidentTimeSeries() {
    const start = new Date();
    const end = new Date();
    start.setFullYear(start.getFullYear() - 1);

    this.controller._extra.incidentHistory.forEach(incident => {
      incident.reportedOn = new Date(incident.reportedOn);
    });

    for (let cursor = new Date(start); cursor <= end; cursor.setMonth(cursor.getMonth() + 1)) {
      this.controller._extra.dsarIncidentTimeline[1].series.push({
        name: `${cursor.toLocaleString('default', { month: 'short' })} ${cursor.getFullYear()}`,
        value: this.controller._extra.incidentHistory.filter(incident =>
          (incident.reportedOn.getMonth() === cursor.getMonth() && incident.reportedOn.getFullYear() === cursor.getFullYear())
        ).length
      });
    }
  }

  get tomsProgress() {
    return this.controller?._extra?.audit?.find(t => t.code_name === 'ad_toms')?.progress || 0;
  }

  get dpasProgress() {
    return this.controller?._extra?.audit?.find(t => t.code_name === 'ptg_vendor_dpas')?.progress || 0;
  }

  /**
   * round helper
   */
  roundValue(value: number): number {
    return Math.round(value);
  }
  integerLabel(value) {
    if (value % 1 === 0) {
      return value;
    }
    return '';
  }

}

import { Component, OnInit } from '@angular/core';
import { Application, ApplicationService } from '../../../../services/vendors/application.service';

@Component({
  selector: 'app-client-applications',
  templateUrl: './client-applications.component.html',
  styleUrls: ['./client-applications.component.scss']
})
export class ClientApplicationsComponent implements OnInit {

  applications: Application[] = [];
  pending = true;

  constructor(
    private applicationService: ApplicationService
  ) { }

  ngOnInit(): void {
    this.applicationService.getClientApplications()
      .subscribe((applications) => {
        this.pending = false;
        for (const app of applications) {
          if (this.applications.findIndex(a => a.id === app.application.id) === -1) {
            this.applications.push(app.application);
          }
        }
      });
  }

}

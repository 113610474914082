/**
 * Parses the accountFlags saved in localStorage. Will return null if flag is not present.
 *
 * @param flagName name of the flag
 * @returns the value of the flag or null if not present
 */
export function getAccountFlag<T>(flagName: string): T | null {
  const flags = localStorage.getItem('ec:account:flags');
    if (flags) {
      try {
        const flagsObj = JSON.parse(flags);
        if (flagsObj[flagName]) {
          return flagsObj[flagName];
        }
      } catch (error) {
      }
    }
  return null;
}

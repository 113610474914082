<div class="login">
  <h1 class="title">{{ 'login.titel' | translate }}</h1>
  <br/>
  <mat-card>
    <img mat-card-image src="assets/authentication.svg"/>
    <ng-container>
      <form name="form" [formGroup]="form.formGroup" novalidate (keyup.enter)="submit()">
        <div *ngIf="loading" class="loading">
          <mat-spinner></mat-spinner>
        </div>
        <div class="form-group">
          <mat-form-field class="form-field">
            <input [readonly]="loading" matInput placeholder="{{ 'placeholder.email' | translate }}" type="text" class="form-control" name="email" formControlName="email" required autofocus/>
            <mat-error>{{ 'login.emailrequ' | translate }}</mat-error>
          </mat-form-field>
        </div>
        <div class="form-group">
          <mat-form-field class="form-field">
            <input [readonly]="loading" matInput placeholder="{{ 'placeholder.password' | translate }}" type="password" class="form-control" name="password" formControlName="password" required />
            <mat-hint>{{ 'login.question' | translate }}<a routerLink="/forgot-password">{{ 'login.here' | translate }}</a></mat-hint>
            <mat-error>{{ 'login.pwrequ' | translate }}<a routerLink="/forgot-password">{{ 'login.here' | translate }}</a></mat-error>
          </mat-form-field>
        </div>
        <mat-card-actions align="end">
          <a mat-button *ngIf="ENV.selfSignUp" [routerLink]="['/registration']" [queryParams]="registerParams">{{ 'login.registration' | translate}}</a>
          <button [disabled]="loading" type="button" mat-raised-button color="primary" (click)="submit()" tabindex="3">{{ 'login.login' | translate }}</button>
        </mat-card-actions>
      </form>
    </ng-container>
  </mat-card>
</div>

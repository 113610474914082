import {Component, OnInit, OnDestroy} from '@angular/core';
import {BusService} from '../../../services/bus.service';
import {EventsService} from '../../../services/events.service';
import {AppConfig} from '../../../app.config';
import {TranslateService} from '@ngx-translate/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { downloadGeneratedDocument, getGeneratedDocumentLink } from '../../../util/documentGenerator';
import { ReportGetService } from '../../../services/report/report.get.service';
import { RpaService } from '../../../services/rpa/rpa.service';
import { getToken } from '../../../util/token';
import { SharedDocumentsService } from '../../../services/documents/shared.service';
import { ShareDocumentDialogComponent } from '../../documents/shared/share-dialog/share-dialog.component';
import { LocalFeatureAccessComponent } from '../../product-features/local-feature-access/access.component';
import { ProductFeaturesService } from '../../../services/product-features.service';
import {AuthenticationService} from "../../../services/authentication.service";


@Component({
  selector: 'report-component',
  templateUrl: './report.rpa.template.html',
  styleUrls: ['./report.rpa.styles.scss']
})
export class ReportComponent implements OnInit, OnDestroy {
  public jsonLink = `${AppConfig.apiReport}/json?token=${getToken()}&lang=${localStorage.getItem('lang')}`;
  public xlsLink = `${AppConfig.apiReport}/xls?token=${getToken()}&lang=${localStorage.getItem('lang')}`;
  public tomsLink = `${AppConfig.apiReport}/toms?token=${getToken()}&lang=${localStorage.getItem('lang')}`;
  public erasureTimesLink = `${AppConfig.apiReport}/erasure-times?token=${getToken()}&lang=${localStorage.getItem('lang')}`;

  public hasGoals = false;
  public goalsLink = `${AppConfig.apiUrl}/controllers/goals/?token=${getToken()}`;

  public hasDpo = false;
  public dpoLink = `${AppConfig.apiUrl}/dpo/appointment/?token=${getToken()}`;

  accessToSharedDocuments = false;

  constructor(
    private bus: BusService,
    private events: EventsService,
    private translate: TranslateService,
    private http: HttpClient,
    private dialog: MatDialog,
    private reportService: ReportGetService,
    private paService: RpaService,
    private shareService: SharedDocumentsService,
    private featureAccess: ProductFeaturesService,
    private authService: AuthenticationService
  ) {
    this.bus.publish(this.events.requested.data.controller.hasGoalsDoc);
    this.bus.publish(this.events.requested.data.dpo.hasAppointment);

    this.featureAccess.hasAccessToFeatureLocal('shared-documents')
      .subscribe(access => {
        this.accessToSharedDocuments = access.access;
      });
  }

  get isSupervisor() {
    return localStorage.getItem('isInSupervisionMode') === 'true';
  }

  public getFileName(disposition: string): string {
    const utf8FilenameRegex = /filename\*=UTF-8''([\w%\-\.]+)(?:; ?|$)/i;
    const asciiFilenameRegex = /filename=(["']?)(.*?[^\\])\1(?:; ?|$)/i;

    let fileName: string = null;
    if (utf8FilenameRegex.test(disposition)) {
      fileName = decodeURIComponent(utf8FilenameRegex.exec(disposition)[1]);
    } else {
      const matches = asciiFilenameRegex.exec(disposition);
      if (matches != null && matches[2]) {
        fileName = matches[2];
      }
    }
    return fileName;
  }

  downloadDocument(translateString: string) {
    return downloadGeneratedDocument(translateString, null, this.translate, this.http, this.dialog);
  }

  shareByLink(documentLink: string) {
    this.dialog.open(ShareDocumentDialogComponent, { data: { sourceUrl: documentLink }, width: '450px' });
  }

  shareGenerated(identifier: string) {
    this.dialog.open(ShareDocumentDialogComponent, { data: { sourceUrl: getGeneratedDocumentLink(identifier, null, this.translate) }, width: '450px' });
  }

  downloadArt13() {
    this.paService.getPAs({ processingOnBehalf: false, status: 'Applicable' })
      .subscribe((pas) => {
        const ids = pas.map(pa => pa.paId);

        this.reportService.downloadArt13Zip(ids);
      });
  }

  shareArt13() {
    this.paService.getPAs({ processingOnBehalf: false, status: 'Applicable' })
      .subscribe((pas) => {
        const ids = pas.map(pa => pa.paId);

        const paramsString = new HttpParams()
          .append('pas', ids.join(','))
          .append('pob', 'false')
          .toString();
        const url =
          `${AppConfig.apiReport}/pas/word-art13?${paramsString}`;

        this.dialog.open(ShareDocumentDialogComponent, { data: { sourceUrl: url }, width: '450px' });
      });
  }

  downloadWord() {
    this.paService.getPAs({ processingOnBehalf: false, status: 'Applicable' })
      .subscribe((pas) => {
        const ids = pas.map(pa => pa.paId);

        this.reportService.downloadWordZip(ids, false);
      });
  }

  shareWord(event: any) {
    if (event) event.stopPropagation();

    this.paService.getPAs({ processingOnBehalf: false, status: 'Applicable' })
      .subscribe((pas) => {
        const ids = pas.map(pa => pa.paId);

        this.reportService.shareWordZip(ids, false);
      });
  }

  downloadExcel(pob: boolean) {
    this.paService.getPAs({ processingOnBehalf: pob, status: 'Applicable' })
      .subscribe((pas) => {
        const ids = pas.map(pa => pa.paId);

        this.reportService.downloadXlsx(ids, pob, 'Applicable');
      });
  }

  shareExcel(event: any, pob: boolean) {
    if (event) event.stopPropagation();

    this.paService.getPAs({ processingOnBehalf: pob, status: 'Applicable' })
      .subscribe((pas) => {
        const ids = pas.map(pa => pa.paId);

        this.reportService.shareXlsx(ids, pob, 'Applicable');
      });
  }

  downloadPdf(event: any, pob: boolean) {
    if (event) event.stopPropagation();
    this.reportService.openPasReport(pob, 'Applicable');
  }

  sharePdf(event: any, pob: boolean) {
    if (event) event.stopPropagation();

    this.paService.getPAs({ processingOnBehalf: pob, status: 'Applicable' })
      .subscribe((pas) => {
        const ids = pas.map(pa => pa.paId);

        this.reportService.sharePasPdf(ids, pob, 'Applicable');
      });
  }

  // subscribe on report request success
  public subscribe(): void {
    this.bus.subscribe(this.events.received.data.report.success, () => {}, this);
    this.bus.subscribe(this.events.received.data.controller.hasGoalsDoc.success, this.updateGoals, this);
    this.bus.subscribe(this.events.received.data.dpo.hasAppointment.success, this.updateDpo, this);
  }

  // unsubscribe on report request success
  public unSubscribe(): void {
    this.bus.unsubscribe(this.events.received.data.report.success, () => {});
    this.bus.unsubscribe(this.events.received.data.controller.hasGoalsDoc.success, this.updateGoals);
    this.bus.unsubscribe(this.events.received.data.dpo.hasAppointment.success, this.updateDpo);
  }

  // make subscribe on a component initialization
  public ngOnInit(): void {
    this.subscribe();
  }

  // make unsubscribe before destroying component
  public ngOnDestroy(): void {
    this.unSubscribe();
  }
  public updateGoals(data) {
    this.hasGoals = data.hasGoalsDoc;
  }

  public updateDpo(data) {
    this.hasDpo = data.hasAppointmentDoc;
  }
}

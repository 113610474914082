<h1 mat-dialog-title>{{ 'login.two-factor.codeRequired' | translate }}</h1>
<mat-dialog-content>
  <p>{{ 'login.two-factor.explanation' | translate }}</p>

  <form>
    <mat-form-field>
      <mat-label>{{ 'login.two-factor.code' | translate }}</mat-label>
      <input matInput placeholder="{{ 'login.two-factor.code.placeholder' | translate }}" [formControl]="code" inputmode="numeric" pattern="[0-9]*" autocomplete="one-time-code" />

      <mat-error *ngIf="data.wrongCode">{{ 'login.two-factor.code.previousCodeWrong' | translate }}</mat-error>
    </mat-form-field>

    <button hidden type="submit" (click)="submit()"></button>
  </form>

</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>{{ 'word.cancel' | translate }}</button>
  <button mat-button color="primary" (click)="submit()">{{ 'login.two-factor.submit' | translate }}</button>
</mat-dialog-actions>

<h1 mat-dialog-title>
  {{'rpa.reference.tree.select.title' | translate}}
  <!-- Select a Node -->
</h1>

<mat-dialog-content>
  <div class="list">
    <pa-reference-tree-node *ngFor="let node of tree"
      [node]="node" [tree]="tree"
      (picked)="pick($event)">
    </pa-reference-tree-node>
    <div class="new" (click)="add()">
      <mat-icon color="primary">add</mat-icon>
      <span>{{'word.add' | translate}}</span>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>{{'word.cancel' | translate}}</button>
  <button mat-button color="primary" *ngIf="allowRoot" (click)="pick(undefined)">
    {{'word.root' | translate}}
  </button>
</mat-dialog-actions>
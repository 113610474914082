import { Component, Input, OnInit } from '@angular/core';
import { Document, DocumentResourceService } from '../../../services/documents/document-resource.service';

@Component({
  selector: 'app-document-group-container',
  templateUrl: './group-container.component.html',
  styleUrls: ['./group-container.component.scss']
})
export class DocumentGroupContainerComponent implements OnInit {

  @Input() groupName: string = null;
  @Input() exactGroup = false;

  documents: Document[] = [];

  constructor(
    private documentService: DocumentResourceService
  ) { }

  fetch() {
    this.documentService.getAllForGroup(this.groupName, this.exactGroup)
      .subscribe((documents) => {
        if (documents) {
          this.documents = documents;
        }
      });
  }

  ngOnInit(): void {
    this.fetch();
  }

  updateList() {
    this.fetch();
  }

  get groupUploadUrl() {
    return `/document/file/${this.groupName}`;
  }

}

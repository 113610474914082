
<mat-toolbar>

  <div class="flex-spacer"></div>

  <mat-menu #menu="matMenu" xPosition="before" [overlapTrigger]="false">
    <ng-container *ngIf="!readonly">
      <ng-container *ngIf="!isSupervisor">
        <button mat-menu-item routerLink="/user-management">
          {{ 'header.userManagement' | translate }}
        </button>
        <button *ngIf="!controllerInfo?.controllerSupervisor" mat-menu-item routerLink="/payment/billing">
          {{ 'header.billing' | translate}}
        </button>
      </ng-container>
    </ng-container>
    <button mat-menu-item routerLink="/profile">{{'header.profile' | translate}}</button>
    <button mat-menu-item (click)="logout()">{{ 'header.logout' | translate }}</button>
  </mat-menu>

  <mat-menu #supervisionMenu="matMenu" xPosition="before" [overlapTrigger]="false">
    <button mat-menu-item (click)="exitSupervision()">
      {{ 'header.supervision.switch' | translate }}
    </button>
    <button mat-menu-item (click)="applyTemplate()">
      {{'supervision.templates.menu.apply' | translate}}
    </button>
    <button mat-menu-item (click)="createTemplate()">
      {{'supervision.templates.menu.create' | translate}}
    </button>
  </mat-menu>

  <ng-container *ngIf="loggedIn">

    <ng-container *ngIf="!readonly">
      <a class="nav-item"
                href="{{'tutorial.link' | translate}}"
                target="_blank">
        <button mat-button class="tutorial-button">
          <mat-icon>video_library</mat-icon>
          <span style="font-size: 14px;" innerHTML="{{ 'nav.learn' | translate }}"></span>
        </button>
      </a>
    </ng-container>

    <app-payment-header-authentication></app-payment-header-authentication>
    <button mat-button class="button" [matMenuTriggerFor]="menu">
      <user-name></user-name>
      <user-avatar></user-avatar>
    </button>

    <ng-container *ngIf="isInSupervisionMode">
      <div class="separator">|</div>

      <div class="controller-info">
        <button mat-button [matMenuTriggerFor]="supervisionMenu">
          <div class="label">
            {{ 'header.supervision.controller' | translate }}
          </div>
          <ng-container *ngIf="controllerInfo">
            <div class="controller-name">
              {{
                controllerInfo.controllerName.length > 24 ?
                (controllerInfo.controllerName.substr(0, 24) + '...') :
                controllerInfo.controllerName
              }}
            </div>
          </ng-container>
          <ng-container *ngIf="!controllerInfo">
            <div class="controller-name">
              &nbsp;
            </div>
          </ng-container>
        </button>
      </div>

      <div class="separator">|</div>

      <button (click)="exitSupervision()" mat-button class="button">
        {{ 'header.exitSupervision' | translate }}
        <mat-icon>exit_to_app</mat-icon>
      </button>
    </ng-container>
  </ng-container>
</mat-toolbar>
<div class="empty-space"></div>

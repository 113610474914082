<h1 mat-dialog-title>{{ 'documents.shareWithClient.dialog.title' | translate }}</h1>

<mat-dialog-content *ngIf="!pendingUpload && !pendingShare">
  <!-- show share history -->
  <div *ngIf="shares && shares.length > 0" class="history">
    <p>{{ 'documents.shareWithClient.dialog.history' | translate }}</p>
    <ul>
      <li *ngFor="let doc of shares">{{ doc.fileName }} - {{ 'documents.shareWithClient.dialog.sharedOn' | translate }} {{ doc.sharedAt | localizedDate : 'short' }}</li>
    </ul>
  </div>

  <!-- document share options -->
  <div class="share-options">
    <mat-form-field>
      <input matInput type="text" [formControl]="fileName" placeholder="{{ 'documents.shareWithClient.dialog.fileName.placeholder' | translate }}" />
    </mat-form-field>
    <mat-form-field>
      <textarea matInput rows="3" [formControl]="shareMessage" placeholder="{{ 'documents.shareWithClient.dialog.message.placeholder' | translate }}"></textarea>
      <mat-hint>{{ 'documents.shareWithClient.dialog.message.tip' | translate }}</mat-hint>
    </mat-form-field>
  </div>
</mat-dialog-content>

<mat-dialog-content *ngIf="pendingUpload">
  <div class="centered-spinner brand">
    <mat-spinner></mat-spinner>
  </div>
  <p class="uploading">{{ 'documents.shareWithClient.dialog.uploadingHint' | translate }}</p>
</mat-dialog-content>

<mat-dialog-content *ngIf="pendingShare">
  <div class="centered-spinner brand">
    <mat-spinner></mat-spinner>
  </div>
  <p class="uploading">{{ 'documents.shareWithClient.dialog.shareHint' | translate }}</p>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button (click)="cancel()">
    {{'word.cancel' | translate}}
  </button>
  <button mat-button [disabled]="pendingUpload" color="primary" (click)="share()">
    {{'documents.shareWithClient.dialog.action' | translate}}
  </button>
</mat-dialog-actions>

<h2 matDialogTitle>{{data.header}}</h2>
<mat-dialog-content>
  <p>{{data.message}}</p>
  <p *ngIf="data.html" [innerHTML]="data.html"></p>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button color="primary" *ngIf="!data.confirm" matDialogClose>{{ 'dialog.close' | translate }}</button>
  <button mat-button color="primary" *ngIf="data.confirm" matDialogClose>{{ 'word.cancel' | translate }}</button>
  <button mat-button color="primary" *ngIf="data.confirm" (click)="confirm()">{{ 'word.ok' | translate }}</button>
</mat-dialog-actions>

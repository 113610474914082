import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-supervisor-profile-invite-confirm-dialog',
  templateUrl: './supervisor-profile-invite-confirm-dialog.component.html',
  styleUrls: ['./supervisor-profile-invite-confirm-dialog.component.scss']
})
export class SupervisorProfileInviteConfirmDialogComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public invite: any) { }

  ngOnInit() {
  }
}

import { Component, OnInit } from '@angular/core';
import { AppConfig } from '../../app.config';
import { ControllerModel, ControllerService } from '../../services/controller/controller.service';
import { DashboardService } from '../../services/dashboard.service';
import {ProductFeaturesService} from "../../services/product-features.service";
import {zip} from "rxjs";

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  controller: ControllerModel;
  appConfig: any;

  hasSupervisor = true;
  hasDocumentAccess = true;

  constructor(
    private controllerService: ControllerService,
    private dashboardService: DashboardService,
    private featureAccess: ProductFeaturesService
  ) {
    this.appConfig = AppConfig;
  }



  ngOnInit(): void {
    this.controllerService.requestGet()
      .subscribe((controller) => {
        this.controller = controller;
        this.hasSupervisor = this.controller.supervisor !== null;
      });

    zip(
      this.featureAccess.hasAccessToFeatureLocal('supervisor-documents'),
      this.featureAccess.hasAccessToFeatureLocal('shared-documents')
    )
      .subscribe(([supervisor, shared]) => {
        this.hasDocumentAccess = supervisor.access || shared.access;
      });
  }

  public get isStandalone() {
    return this.dashboardService.shouldOnlyShowDashboard;
  }
}

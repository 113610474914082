import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { EventsService } from 'app/services/events.service';
import { BusService } from 'app/services/bus.service';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { AppConfig } from 'app/app.config';
import { FormControl, Validators } from '@angular/forms';
import { SupervisorProfile, SupervisorProfileService } from '../../../services/supervision/supervisor-profile.service';
import { SupervisorProfileInviteDialogComponent } from './invite-dialog/supervisor-profile-invite-dialog.component';
import { AuthenticationService } from 'app/services/authentication.service';
import { EditSupervisorDialogComponent } from './edit-supervisor-dialog/edit-supervisor-dialog.component';
import { RevokeSupervisorInviteDialogComponent } from './revoke-supervisor-invite-dialog/revoke-supervisor-invite-dialog.component';
import { getToken } from '../../../util/token';

@Component({
  selector: 'app-supervision-supervisor-profile',
  templateUrl: './supervisor-profile.component.html',
  styleUrls: ['./supervisor-profile.component.scss']
})
export class SupervisorProfileComponent implements OnInit, OnDestroy {

  @ViewChild('upload') upload;
  displayName = new FormControl('', [Validators.required]);

  profile: SupervisorProfile;
  users: any;
  invites: any;

  loading = false;
  updateIcon: string = undefined;

  constructor(
    private bus: BusService,
    private events: EventsService,
    private dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private profileService: SupervisorProfileService,
    private auth: AuthenticationService,
  ) {

  }

  setProfile(profile: SupervisorProfile) {
    if (profile) {
      this.profile = profile;

      this.displayName.setValue(profile.displayName);
      if (profile.logoPath) {
        this.updateLogo();
      }
    }
  }

  public updateLogo() {
    this.upload.load(
      `${AppConfig.apiUrl}/supervision/supervisor/profile/logo/` +
      `?token=${getToken()}&rand=${Math.floor(Math.random() * 1000)}`
    );
  }

  submit() {
    this.loading = true;
    this.bus.publish(this.events.requested.action.supervision.supervisor.profile.update, { displayName: this.displayName.value });
    this.displayName.markAsPristine();
  }

  updateSuccess() {
    this.loading = false;
    this.updateIcon = 'done';
  }

  updateFailure() {
    this.loading = false;
    this.updateIcon = 'error_outline';
  }

  subscribe() {
    this.bus.subscribe(this.events.received.data.supervision.supervisor.profile.success, this.setProfile, this);
    this.bus.subscribe(this.events.received.action.supervision.supervisor.profile.update.success, this.updateSuccess, this);
    this.bus.subscribe(this.events.received.action.supervision.supervisor.profile.update.failure, this.updateFailure, this);
  }

  public ngOnInit(): void {
    this.subscribe();
    this.bus.publish(this.events.requested.data.supervision.supervisor.profile);
    this.fetchUsers();
    this.fetchInvites();
  }

  public ngOnDestroy(): void {
    this.bus.publish(this.events.ui.requested.sidenav.collapse);
  }

  public openInviteDialog() {
    this.dialog.open(SupervisorProfileInviteDialogComponent, {
      width: '500px',
      maxHeight: '90vh',
      data: this.users.map(user => user.email).concat(this.invites.map(invite => invite.email)),
    }).afterClosed().subscribe(() => {
      this.fetchInvites();
    });
  }

  public editSupervisor(user) {
    this.dialog.open(EditSupervisorDialogComponent, {
      width: '500px',
      maxHeight: '90vh',
      data: {
        user,
        readonly: !(this.isAdmin && user.email !== this.auth.email),
      }
    }).afterClosed().subscribe(() => {
      this.fetchUsers();
    });
  }

  public editInvite(invite) {
    this.dialog.open(RevokeSupervisorInviteDialogComponent, {
      width: '500px',
      maxHeight: '90vh',
      data: {
        invite,
        readonly: !this.isAdmin,
        users: this.users,
      }
    }).afterClosed().subscribe(() => {
      this.fetchInvites();
    });
  }

  logoUpdated() {
    this.bus.publish(this.events.notified.user.logoUpdate);
  }

  fetchUsers() {
    this.profileService.getUsers().subscribe(users => {
      this.users = users;
    });
  }

  fetchInvites() {
    this.profileService.getInvites().subscribe(invites => this.invites = invites);
  }

  get isAdmin() {
    if (this.users) {
      const curr = this.users.find(u => u.email === this.auth.email);
      return curr && curr.supervisorRole === 'admin';
    }

    return false;
  }
}

<br><br><br><br>

<div class="layout-holder">
  <img src="/assets/3d-secure.svg"/>
  <div class="content">

    <div class="additional-authentication-content">
      <h1 class="title">{{ 'payment.additional-authentication.title' | translate }}</h1>
      <p>{{ 'payment.additional-authentication.explainer' | translate }}</p>
    </div>
    <div *ngIf="isLoading || waitingForVerification" class="loading">
      <mat-progress-spinner mode="indeterminate" color="primary"></mat-progress-spinner>
    </div>

    <p *ngIf="currentPaymentIntent && paymentIncomplete &&
              !hasPaymentError && !hasSourceError &&
              interruptedConfirmation && !waitingForVerification"
              [ngClass]="{ 'pending': waitingForVerification }"
              class="additional-authentication-action">

        {{'payment.additional-authentication.interrupted.msg' | translate}}
    </p>

    <p *ngIf="!paymentIncomplete" class="additional-authentication-success">
      {{ 'payment.additional-authentication.success' | translate }}
    </p>

    <p *ngIf="hasPaymentError" class="additional-authentication-error">
      {{ 'payment.additional-authentication.error.msg' | translate }}
    </p>

    <p *ngIf="hasSourceError" class="additional-authentication-error">
      {{ 'payment.additional-authentication.sourceError' | translate }}
    </p>

    <br>
    <mat-divider></mat-divider>
    <br>

    <div class="actions">
      <!-- Billing info button -->
      <a
        routerLink="/payment/billing"
        mat-button
        color="primary"
        class="subscribe-to-plan">
        {{ 'payment.thank-you.billinginfo' | translate }}
      </a>

      <ng-container *ngIf="currentPaymentIntent && paymentIncomplete && !hasPaymentError && !hasSourceError"
      class="additional-authentication-action">

        <!-- Confirm Button -->
        <ng-container *ngIf="!interruptedConfirmation">
          <button [disabled]="waitingForVerification" mat-flat-button color="primary" (click)="completePayment()">{{ 'payment.additional-authentication.completeAction' | translate }}</button>
        </ng-container>

        <!-- Re-enter because interupted button -->
        <ng-container *ngIf="interruptedConfirmation && !waitingForVerification">
          <a
            routerLink="/payment/update-details"
            [queryParams]="{'next': '/payment/finalize', 'intent': 're-enter'}"
            mat-flat-button
            color="primary"
            class="update-details">
            {{'payment.additional-authentication.interrupted.action' | translate}}
          </a>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="hasSourceError || hasPaymentError">
        <a
          routerLink="/payment/update-details"
          [queryParams]="{'next': '/payment/finalize'}"
          mat-flat-button
          color="primary"
          class="update-details">
          {{'payment.additional-authentication.error.action' | translate}}
        </a>
      </ng-container>
    </div>
  </div>
</div>

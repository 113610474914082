import {Injectable} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import {BusService} from '../bus.service';
import {EventsService} from '../events.service';
import {
  IGetOptions,
  IPostOptions,
  RequestService
} from '../request.service';
import * as XLSX from 'xlsx';
import { TranslateService } from '@ngx-translate/core';
import { VendorAudit } from './vendor-audit.service';


export interface Vendor {
  id: number;
  name: string;
  email: string;
  telephone: string;
  addressStreet: string;
  addressPostcode: string;
  addressCity: string;
  addressCountry: string;
  logoUrl: string;
  introducedBy: number;
  reviewedAt?: Date;
}

export interface ControllerDPA {
  docPath?: string;
  offeredDocPath?: string;
  signed: boolean;
  contractEnd?: Date;
  contractStart?: Date;
  notRequired: boolean;
}

export type ControllerVendor = Vendor & ControllerDPA & VendorAudit & { paCount: number };

interface AddRequest {
  name: string;
  email: string;
  telephone: string;
  addressStreet: string;
  addressPostcode: string;
  addressCity: string;
  addressCountry: string;
}

interface ContactRequest {
  vendorId: number;
}

interface AddResponse {
  id: number;
}

interface DecodeDPATokenResponse {
  controller: {
    controllerName: string;
    controllerEmailAddress: string;
  };
  vendor: {
    name: string;
    email: string;
  };
}

@Injectable()
export class VendorsService {

  private api = {
    root: '/vendors',
    controller: '/controller',
    contact: '/contact',
    toggleRequired: '/toggleRequired',
    decodeDPAToken: '/dpa-token/'
  };

  constructor(
    private bus: BusService,
    private events: EventsService,
    private requestService: RequestService,
    private translate: TranslateService
  ) {
    this.subscribe();
  }

  public get() {
    return this.requestService.get<any>({
      uri: this.api.root,
      handlers: {
        success: vendors => this.bus.publish(this.events.received.data.vendors.get.success, vendors),
        error: error => this.bus.publish(this.events.received.data.vendors.get.failure, error),
      }
    });
  }

  public getByPopularity(includeOther = true) {
    return this.requestService.get<any>({
      uri: this.api.root + `/by-popularity?includeOther=${includeOther}`,
    });
  }

  public getSingle(id: number) {
    return this.requestService.get<Vendor>({
      uri: this.api.root + `/${id}`
    });
  }

  public add(data: AddRequest) {
    this.requestService.post<AddRequest, AddResponse>({
      uri: this.api.root,
      body: data,
      handlers: {
        success: response => {
          this.bus.publish(this.events.received.data.vendors.add.success, Object.assign({}, data, response));
        },
        error: error =>
          this.bus.publish(this.events.received.data.vendors.add.failure, error),
      }
    });
  }

  public update(vendor: Vendor) {
    return this.requestService.patch<Vendor, Vendor>({
      uri: this.api.root + `/${vendor.id}`,
      body: vendor
    });
  }

  public getControllerVendors() {
    return this.requestService.get<any>({
      uri: this.api.root + this.api.controller,
      handlers: {
        success: vendors => this.bus.publish(this.events.received.data.vendors.controllerVendors.success, vendors),
        error: error => this.bus.publish(this.events.received.data.vendors.controllerVendors.failure, error),
      }
    });
  }

  public getVendorsForPa(paId: number) {
    return this.requestService.get<any>({
      uri: this.api.root + this.api.controller + `/pa/${paId}`
    });
  }

  public addControllerVendor(vendorId: number) {
    return this.requestService.post<any, any>({
      uri: this.api.root + `/controller/${vendorId}`,
      body: {}
    });
  }

  public removeControllerVendor(vendorId: number) {
    return this.requestService.delete<any, any>({
      uri: this.api.root + `/controller/${vendorId}`,
    });
  }

  public contactForDPA(data: ContactRequest) {
    this.requestService.post<any, any>({
      uri: this.api.root + this.api.contact + `/${data.vendorId}/`,
      body: {},
      handlers: {
        success: () => this.bus.publish(this.events.received.action.vendors.contactForDPA.success),
        error: error => this.bus.publish(this.events.received.action.vendors.contactForDPA.failure, error),
      }
    });
  }

  public toggleDPARequired(data: { vendorId: number; notRequired: boolean }) {
    return this.requestService.post<any, any>({
      uri: this.api.root + this.api.toggleRequired + `/${data.vendorId}/`,
      body: { notRequired: data.notRequired },
      handlers: {
        success: () => this.bus.publish(this.events.received.action.vendors.toggleDPARequired.success),
        error: error => this.bus.publish(this.events.received.action.vendors.toggleDPARequired.failure, error),
      }
    });
  }

  public toggleDPASigned(vendorId: number, signed: boolean) {
    return this.requestService.post<any, any>({
      uri: this.api.root + `/toggleSigned/${vendorId}`,
      body: { signed },
    });
  }

  public updateDPAContractExpiry(vendorId: number, expiry: Date) {
    return this.requestService.post<any, any>({
      uri: this.api.root + `/updateContractExpiry/${vendorId}`,
      body: { expiry },
    });
  }

  public updateDPA(vendorId: number, dpa: Partial<ControllerDPA>) {
    return this.requestService.post<any, any>({
      uri: this.api.root + `/update-dpa/${vendorId}`,
      body: dpa
    });
  }

  public decodeDPAToken(dpaToken) {
    this.requestService.get<DecodeDPATokenResponse>({
      uri: this.api.root + this.api.decodeDPAToken + `?dpa-token=${dpaToken}`,
      handlers: {
        success: response => this.bus.publish(this.events.received.data.vendors.decodeDPAToken.success, response),
        error: error => this.bus.publish(this.events.received.data.vendors.decodeDPAToken.failure, error),
      }
    });
  }

  public exportExcel(vendors: ControllerVendor[]) {
    this.translate.get([
      'vendors.list.export.header.name',
      'vendors.list.export.header.email',
      'vendors.list.export.header.address',
      'vendors.list.export.header.dpaNecessary',
      'vendors.list.export.header.dpaPresent',
      'vendors.list.export.header.dpaSigned',
      'yesno.yes',
      'yesno.no',
      'vendors.list.export.excelTitle'
    ]).subscribe((t) => {
      console.log(t);
      const data = vendors.map(vendor => ({
        [t['vendors.list.export.header.name']]: vendor.name,
        [t['vendors.list.export.header.email']]: vendor.email,
        [t['vendors.list.export.header.address']]: vendor.addressStreet + ', ' + vendor.addressPostcode + ' ' + vendor.addressCity + ', ' + vendor.addressCountry,
        [t['vendors.list.export.header.dpaNecessary']]: !vendor.notRequired ? t['yesno.yes'] : t['yesno.no'],
        [t['vendors.list.export.header.dpaPresent']]: (vendor.docPath || vendor.offeredDocPath) ? t['yesno.yes'] : t['yesno.no'],
        [t['vendors.list.export.header.dpaSigned']]: vendor.signed ? t['yesno.yes'] : t['yesno.no'],
      }));
      const ws = XLSX.utils.json_to_sheet(data);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, t['vendors.list.export.excelTitle']);
      XLSX.writeFile(wb, t['vendors.list.export.excelTitle'] + '.xlsx');
    });
  }

  private subscribe() {
    this.bus.subscribe(this.events.requested.data.vendors.get, this.get.bind(this));
    this.bus.subscribe(this.events.requested.data.vendors.add, this.add.bind(this));
    this.bus.subscribe(this.events.requested.data.vendors.controllerVendors, this.getControllerVendors.bind(this));
    this.bus.subscribe(this.events.requested.action.vendors.contactForDPA, this.contactForDPA.bind(this));
    this.bus.subscribe(this.events.requested.action.vendors.toggleDPARequired, this.toggleDPARequired.bind(this));
    this.bus.subscribe(this.events.requested.data.vendors.decodeDPAToken, this.decodeDPAToken.bind(this));
  }
}

import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params} from '@angular/router';
import { BusService } from 'app/services/bus.service';
import { EventsService } from 'app/services/events.service';
import { MatDialog } from '@angular/material/dialog';
import { UserManagementService } from 'app/services/user/user-management.service';
import {IncidentRecordable, IncidentsResourceService} from 'app/services/incidents/incidents-resource.service';
import { DepartmentService } from 'app/services/departments/department.service';
import { IncidentDpoDetailsDialogComponent } from '../dpo-details-dialog/dpo-details-dialog.component';
import { DeleteIncidentComponent } from '../delete-confirm-dialog/delete.component';
import {AccessLevelService} from "../../../../services/user/access-level.service";
import {getImpactScore} from "../util";
import {downloadGeneratedDocument} from "../../../../util/documentGenerator";
import {TranslateService} from "@ngx-translate/core";
import {HttpClient} from "@angular/common/http";

@Component({
  selector: 'app-incidents-record-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.scss']
})
export class IncidentRecordDetailsComponent implements OnInit, OnDestroy {
  record: IncidentRecordable = {
    recordableId: null,
    incidentId: null,
    title: '',
    details: '',
    customFields: {}
  };

  documents = [];
  departments = [];

  readonly = false;

  creating = false;

  constructor(
    private bus: BusService,
    private events: EventsService,
    private dialog: MatDialog,
    private incidentsService: IncidentsResourceService,
    private userService: UserManagementService,
    private activatedRoute: ActivatedRoute,
    private departmentService: DepartmentService,
    private router: Router,
    private accessLevel: AccessLevelService,
    private translate: TranslateService,
    private http: HttpClient
  ) {

  }

  get incident() {
    return this.record;
  }

  get uploadUrl() {
    return '/recordables/' + this.incident.recordableId + '/file';
  }

  get statusOpen() {
    return this.incident?.status === 'recordable.open';
  }

  set statusOpen(open: boolean) {
    if (open && !this.statusOpen) {
      this.incident.status = 'recordable.open';
      this.incidentsService.updateIncident({
        ...this.incident,
        id: this.incident.incidentId,
      }).subscribe(() => this.bus.publish(this.events.notified.incident.status.updated));
    }

    if (!open && this.statusOpen) {
      this.incident.status = 'recordable.archived';
      this.incidentsService.updateIncident({
        ...this.incident,
        id: this.incident.incidentId,
      }).subscribe(() => this.bus.publish(this.events.notified.incident.status.updated));
    }
  }

  setRisk(source: 'legal' | 'department', riskScore: number) {
    const field = source === 'legal' ? 'legalRisk' : 'risk';
    switch(riskScore) {
      case 1: {
        this.record.customFields[field] = 'incident.risk.low';
        break;
      }

      case 2: {
        this.record.customFields[field] = 'incident.risk.medium';
        break;
      }

      case 3: {
        this.record.customFields[field] = 'incident.risk.high';
        break;
      }
    }

    this.update();
    this.calculateOverallImpact(source);
  }

  setSeverity(source: 'legal' | 'department', severityScore: number) {
    const field = source === 'legal' ? 'legalSeverity' : 'severity';
    switch(severityScore) {
      case 1: {
        this.record.customFields[field] = 'incident.severity.low';
        break;
      }

      case 2: {
        this.record.customFields[field] = 'incident.severity.medium';
        break;
      }

      case 3: {
        this.record.customFields[field] = 'incident.severity.high';
        break;
      }
    }

    this.update();
    this.calculateOverallImpact(source);
  }

  setImpact(field: string, impactScore: number) {
    switch(impactScore) {
      case 1: {
        this.record.customFields[field] = 'incident.impact.minor';
        break;
      }

      case 2: {
        this.record.customFields[field] = 'incident.impact.medium';
        break;
      }

      case 3: {
        this.record.customFields[field] = 'incident.impact.high';
        break;
      }
    }

    this.update();
  }

  getRiskScore(field: string) {
    if (this.record.customFields[field]) {
      switch (this.record.customFields[field]) {
        case 'incident.risk.low': {
          return 1;
        }

        case 'incident.risk.medium': {
          return 2;
        }

        case 'incident.risk.high': {
          return 3;
        }
      }
    }

    return 0;
  }

  getImpactScore(field: string) {
    return getImpactScore(this.record.customFields[field]);
  }

  getSeverityScore(field: string) {
    if (this.record.customFields[field]) {
      switch (this.record.customFields[field]) {
        case 'incident.severity.low': {
          return 1;
        }

        case 'incident.severity.medium': {
          return 2;
        }

        case 'incident.severity.high': {
          return 3;
        }
      }
    }

    return 0;
  }

  getOverallImpact(source: 'legal' | 'department') {
    if (this.getRiskScore(source === 'legal' ? 'legalRisk' : 'risk') > 0 && this.getSeverityScore(source === 'legal' ? 'legalSeverity' : 'severity') > 0) {
      return Math.round((this.getRiskScore(source === 'legal' ? 'legalRisk' : 'risk') + this.getSeverityScore(source === 'legal' ? 'legalSeverity' : 'severity')) / 2);
    }

    return 0;
  }

  calculateOverallImpact(source: 'legal' | 'department') {
    const impactField = source === 'legal' ? 'legalImpact' : 'impact';
    const severity = source === 'legal' ? this.record.customFields.legalSeverity : this.record.customFields.severity;
    const risk = source === 'legal' ? this.record.customFields.legalRisk : this.record.customFields.risk;

    if (severity && risk) {
      // calculate overall (rounded)
      const overall = this.getOverallImpact(source);

      // translate to string
      switch (overall) {
        case 1: {
          this.record.customFields[impactField] = 'incident.impact.minor';
          break;
        }

        case 2: {
          this.record.customFields[impactField] = 'incident.impact.medium';
          break;
        }

        case 3: {
          this.record.customFields[impactField] = 'incident.impact.high';
          break;
        }

        default: {
          this.record.customFields[impactField] = null;
          break;
        }
      }

      this.update();
    } else {
      this.record.customFields[impactField] = null
    }
  }

  delete() {
    this.dialog.open(DeleteIncidentComponent,{
      width: '512px',
      maxHeight: '90vh',
      data: 'hi',
    });
  }
  doDelete(deleteDocs) {
    this.incidentsService.deleteIncident(this.incident.incidentId, deleteDocs).subscribe(() => {
      this.router.navigate(['/incidents/records']);
    });
  }

  close() {
    this.dialog.open(IncidentDpoDetailsDialogComponent, {
      width: '600px',
      maxHeight: '90vh',
      data: this.incident
    }).afterClosed().subscribe(mutated => {
      if (mutated) {
        this.bus.publish(this.events.requested.data.incidents.single, this.incident.incidentId);
      }
    });
  }

  downloadWord(template: string) {
    downloadGeneratedDocument(template, `incidentId=${this.ref}`, this.translate, this.http, this.dialog);
  }

  addDocument(response) {
    this.documents.push(response.body);
  }

  private receiveRecord(record) {
    this.record = record.incident;
    this.documents = record.documents;
  }

  recordError(error) {
    console.error(error);
  }

  updateList() {
    this.bus.publish(this.events.requested.data.incidents.single, this.incident.incidentId);
  }

  ngOnInit() {
    this.bus.subscribe(this.events.received.data.incidents.single.success, this.receiveRecord.bind(this));
    this.bus.subscribe(this.events.received.data.incidents.single.failure, this.recordError.bind(this));
    this.bus.subscribe('delete-incident', this.doDelete.bind(this));

    this.activatedRoute.params.subscribe((params: Params) => {
      if (params.id !== 'create') {
        this.bus.publish(this.events.requested.data.incidents.single, params.id);
      }
    });

    this.accessLevel.checkAccess({ context: 'incidents' })
      .subscribe((access) => {
        this.readonly = !access.write;
      });

    this.departmentService.requestGetAll()
      .subscribe((departments) => {
        this.departments = departments;
      });
  }

  get ref() {
    return this.incident?.incidentId;
  }

  get identifier() {
    return this.ref;
  }

  update() {
    if (!this.creating && !this.record.incidentId) {
      this.creating = true;
      this.incidentsService.create(this.record).subscribe((response) => {
        this.creating = false;
        this.record.incidentId = response.id;
        this.router.navigate(['/incidents/records', this.record.incidentId], { replaceUrl: true });
      });
    } else {
      this.incidentsService.updateIncident(Object.assign(this.record, { id: this.record.incidentId })).subscribe(() => {});
    }
  }

  ngOnDestroy() {
    this.bus.unsubscribe('delete-incident', this.doDelete);
  }
}

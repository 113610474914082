import { NestedTreeControl } from '@angular/cdk/tree';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { buildFileTreeFromPaths } from 'app/util/fileTree';
import { FileTreeNode } from '../select-path-dialog/select-path-dialog.component';

@Component({
  selector: 'app-path-tree',
  templateUrl: './path-tree.component.html',
  styleUrls: ['./path-tree.component.scss']
})
export class PathTreeComponent implements OnInit, OnChanges {
  @Input() path: string[] = [];
  @Input() readonly = false;
  @Input() treeSource: string[][] = [];
  @Output() pathChange = new EventEmitter<string[]>();

  tree: FileTreeNode[] = [];

  treeControl = new NestedTreeControl<FileTreeNode>(node => node.children);
  dataSource = new MatTreeNestedDataSource<FileTreeNode>();

  selected: FileTreeNode = null;

  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.treeSource) {
      this.updateTree();
    }
    if (changes.path) {
      this.selected = { fullPath: this.path.join('/') } as any;
    }
  }

  ngOnInit(): void {
    this.selected = { fullPath: this.path.join('/') } as any;
  }

  isSelected = (node: FileTreeNode) => node.fullPath === this.selected?.fullPath;

  hasChild = (_: number, node: FileTreeNode) => !!node.children && node.children.length > 0;

  select(node) {
    this.selected = node;
    if (node) {
      this.pathChange.emit(node.fullPath.split('/'));
    } else {
      this.pathChange.emit([]);
    }
  }

  private updateTree() {
    this.tree = buildFileTreeFromPaths(this.treeSource);
    this.dataSource.data = this.tree;
    this.treeControl.dataNodes = this.dataSource.data;
    this.treeControl.expandAll();
  }
}

import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import { BusService } from 'app/services/bus.service';
import { EventsService } from 'app/services/events.service';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { UserManagementService } from 'app/services/user/user-management.service';
import { DocumentsUploadDialogComponent } from 'app/components/documents/upload/documents-upload-dialog.component';
import { IncidentsResourceService } from 'app/services/incidents/incidents-resource.service';
import { AddIncidentDialogComponent } from './add-incident-dialog/add-incident-dialog.component';
import { IncidentsFilterTitleDialogComponent } from './incidents-filter-title-dialog/incidents-filter-title-dialog.component';
import { IncidentsFilterDepartmentDialogComponent } from './incidents-filter-department-dialog/incidents-filter-department-dialog.component';
import * as XLSX from 'xlsx';
import {FormSettingsComponent} from "../form-settings/form-settings.component";

@Component({
  selector: 'app-incidents-records',
  templateUrl: './records.component.html',
  styleUrls: ['./records.component.scss']
})
export class IncidentRecordsComponent implements OnInit, OnDestroy {
  incidentItems;
  users = [];
  departments = [];

  departmentFilter = { enabled: false, value: undefined };
  titleFilter = { enabled: true, value: undefined };

  columnsToDisplay = ['happenedOn', /* 'status', */ 'title', 'description', 'department', 'user'];
  dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(
    private bus: BusService,
    private events: EventsService,
    private dialog: MatDialog,
    private incidentsService: IncidentsResourceService,
    private userService: UserManagementService,
  ) {

  }

  addIncident() {
    this.dialog.open(AddIncidentDialogComponent, {
      width: '600px',
      maxHeight: '90vh',
    }).afterClosed().subscribe(mutated => {
      if (mutated) {
        this.bus.publish(this.events.requested.data.incidents.all);
      }
    });
  }

  getUser(record) {
    const candidates = this.users.filter(user => user.id === record.userId);
    if (candidates.length > 0) return candidates[0].name;
    return '---';
  }

  getDepartment(record) {
    const departments = this.departments.filter(dep => dep.id === record.departmentId);
    if (departments.length > 0) return departments[0].name;
    return '---';
  }

  orderArray(ascending: boolean) {
    this.incidentItems = this.incidentItems.sort((a, b) => {
      if (ascending) {
        const c = a;
        a = b;
        b = c;
      }

      return (new Date(b.happenedOn) as any) - (new Date(a.happenedOn) as any);
    });
  }

  openTitleFilter() {
    this.dialog.open(IncidentsFilterTitleDialogComponent, {
      width: '512px',
      maxHeight: '90vh',
      data: this.titleFilter.value || "",
    }).afterClosed().subscribe(response => {
      if (response.value !== undefined) {
        this.titleFilter.value = response.value;
        this.applyFilters();
      }

    });
  }

  openDepartmentFilter() {
    this.dialog.open(IncidentsFilterDepartmentDialogComponent, {
      width: '512px',
      maxHeight: '90vh',
      data: {
        departments: this.departments,
        value: this.departmentFilter.value
      }
    }).afterClosed().subscribe(response => {
      if (response.value !== undefined) {
        this.departmentFilter.value = response.value;
        this.applyFilters();
      }
    });
  }

  openFormSettings() {
    this.dialog.open(FormSettingsComponent, { width: '600px' });
  }

  private receiveIncidents(items: Array<any>) {
    this.incidentItems = items;
    this.orderArray(false);
    this.dataSource = new MatTableDataSource<any>(this.incidentItems);
    this.dataSource.paginator = this.paginator;
    this.applyFilters();
  }

  applyFilters() {
    const items: Array<any> = this.incidentItems;

    // Filter by department
    let filtered = items.filter((item) => {
      if (!this.departmentFilter.enabled && !this.departmentFilter.value) return true;

      return item.reportingDepartmentId === this.departmentFilter.value;
    });

    const titleFilterValue = this.titleFilter.value;
    // Filter by Title
    filtered = filtered.filter((item) => {
      if (!this.titleFilter.enabled || (!titleFilterValue || titleFilterValue.length === 0)) return true;

      return item.title.toLowerCase().indexOf(titleFilterValue.toLowerCase()) !== -1;
    });

    this.dataSource = new MatTableDataSource<any>(filtered);
    this.dataSource.paginator = this.paginator;
  }

  resetFilters() {
    this.titleFilter.value = undefined;
    this.departmentFilter.value = undefined;

    this.dataSource = new MatTableDataSource<any>(this.incidentItems);
    this.dataSource.paginator = this.paginator;
  }

  exportToExcel() {
    const allDepartments = this.dataSource.data.map((incident) => {
      if (incident.reportingDepartmentId) {
        return incident.reportingDepartmentId;
      }
    });
    const allUsers = this.dataSource.data.map((incident) => incident.userId);

    const affectedDepartments = this.departments.filter((dep) => allDepartments.includes(dep.id));
    const affectedUsers = this.users.filter((user) => allUsers.includes(user.id));

    /* generate worksheet */
    const incidents: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.dataSource.data);
    const deps: XLSX.WorkSheet = XLSX.utils.json_to_sheet(affectedDepartments.map((dep) => {
      return {
        id: dep.id,
        name: dep.name
      };
    }));
    const users: XLSX.WorkSheet = XLSX.utils.json_to_sheet(affectedUsers.map((user) => {
      return {
        id: user.id,
        firstName: user.firstName,
        lastName: user.lastName
      };
    }));

    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, incidents, 'Incidents');
    XLSX.utils.book_append_sheet(wb, deps, 'Departments');
    XLSX.utils.book_append_sheet(wb, users, 'Users');

    const dateString = `${new Date().getFullYear()}-${new Date().getMonth()}-${new Date().getDate()}`;
    XLSX.writeFile(wb, `Incidents_Export_${dateString}.xlsx`);
  }

  deleteIncident(incident, event) {

  }

  incidentsError(error) {
    console.error(error);
  }

  departmentsSuccess(departments) {
    this.departments = departments;
  }

  departmentsFailure(error) {
    console.error(error);
  }

  ngOnInit() {
    this.bus.subscribe(this.events.received.data.incidents.all.success, this.receiveIncidents.bind(this));
    this.bus.subscribe(this.events.received.data.incidents.all.failure, this.incidentsError.bind(this));
    this.bus.subscribe(this.events.received.data.departments.get.success, this.departmentsSuccess, this);
    this.bus.subscribe(this.events.received.data.departments.get.failure, this.departmentsFailure, this);

    this.userService.users(true).subscribe(response => {
      this.users = response.map(user => Object.assign({}, user, {
        name: `${user.firstName} ${user.lastName}`
      }));
    });

    this.bus.publish(this.events.requested.data.incidents.all);
    this.bus.publish(this.events.requested.data.departments.get);
  }

  ngOnDestroy() {
  }
}

<div class="question" [attr.data-indent]="nestLevel">
  <div class="indenter" [attr.data-indent]="nestLevel"></div>

  <div class="question-content">
    <div class="question-header">
      <div commentable
        comment-subject-title="{{ question.question }}"
        [comment-subject-codename]="'audit-q-' + question.id">
        <todoable subject="{{ 'audit-q-' + question.id }}" suggested-title="{{ question.question }}"></todoable>
        <p>{{ question.question }}</p>
      </div>

      <!-- {{'audit.form.question.severity.name.unknown' | translate}}  -->
      <!-- {{'audit.form.question.severity.name.low' | translate}}  -->
      <!-- {{'audit.form.question.severity.name.intermediate' | translate}}  -->
      <!-- {{'audit.form.question.severity.name.high' | translate}}  -->

      <div *ngIf="shouldShowSeveritySelection" class="severity-selection">
        <traffic-light-selector
          [readonly]="readonly"
          [value]="severity.originalValue"
          (valueChange)="severity.submit($event)"
          [mapping]="severityMapping">
        </traffic-light-selector>
      </div>
    </div>

    <!-- {{'audit.form.inputFields.field.placeholder.measure' | translate}} -->
    <!-- {{'audit.form.inputFields.field.placeholder.status' | translate}} -->
    <!-- {{'audit.form.inputFields.field.placeholder.response' | translate}} -->
    <!-- {{'audit.form.inputFields.field.placeholder.comment' | translate}} -->
    <!-- {{'audit.form.inputFields.field.hint.measure' | translate}} -->
    <!-- {{'audit.form.inputFields.field.hint.status' | translate}} -->
    <!-- {{'audit.form.inputFields.field.hint.response' | translate}} -->
    <!-- {{'audit.form.inputFields.field.hint.comment' | translate}} -->


    <!-- Optional Extension: Document Upload, show before other elements -->
    <ng-container *ngIf="hasDocumentExtension">
      <app-audit-form-extension-document-upload
        [audit]="audit"
        [question]="question"
        [auditConfiguration]="auditConfiguration"
        [readonly]="readonly"
        path="{{ computedPath }}"
      >
      </app-audit-form-extension-document-upload>
    </ng-container>

    <!--
        Explanation for putting the fields inside each question type:
        Since we want to show the questions before the status/measure input, we can use ng-content to tell our child component where to show this content inside itself. Therefore, we can show the questions and don't have different components "care" about which children to show when.
        It enables us to fully separate those concerns.
        -->
    <div class="question-body">
      <div class="subtext" *ngIf="question.questionSubtext" [innerHtml]="question.questionSubtext"></div>

      <app-audit-form-question-default
        *ngIf="question.type === 'question'"
        [audit]="audit"
        [question]="question"
        [auditConfiguration]="auditConfiguration"
        [readonly]="readonly"
        path="{{ computedPath }}"
        nestLevel="{{ nestLevel }}"
      >
        <app-audit-form-question-input-fields
          *ngIf="shouldShowInputFields"
          [audit]="audit"
          [question]="question"
          [auditConfiguration]="auditConfiguration"
          [readonly]="readonly"
          path="{{ computedPath }}"
          nestLevel="{{ nestLevel }}"
        ></app-audit-form-question-input-fields>
      </app-audit-form-question-default>

      <app-audit-form-question-split
        *ngIf="question.type === 'split'"
        [audit]="audit"
        [question]="question"
        [auditConfiguration]="auditConfiguration"
        [readonly]="readonly"
        path="{{ computedPath }}"
        nestLevel="{{ nestLevel }}"
      >
        <app-audit-form-question-input-fields
          *ngIf="shouldShowInputFields"
          [audit]="audit"
          [question]="question"
          [auditConfiguration]="auditConfiguration"
          [readonly]="readonly"
          path="{{ computedPath }}"
          nestLevel="{{ nestLevel }}"
        ></app-audit-form-question-input-fields>
      </app-audit-form-question-split>

      <app-audit-form-question-multiselect
        *ngIf="question.type === 'multiselect'"
        [audit]="audit"
        [question]="question"
        [auditConfiguration]="auditConfiguration"
        [readonly]="readonly"
        path="{{ computedPath }}"
        nestLevel="{{ nestLevel }}"
      >
        <app-audit-form-question-input-fields
          *ngIf="shouldShowInputFields"
          [audit]="audit"
          [question]="question"
          [auditConfiguration]="auditConfiguration"
          [readonly]="readonly"
          path="{{ computedPath }}"
          nestLevel="{{ nestLevel }}"
        ></app-audit-form-question-input-fields>
      </app-audit-form-question-multiselect>

      <app-audit-form-question-input
        *ngIf="question.type === 'input'"
        [audit]="audit"
        [question]="question"
        [auditConfiguration]="auditConfiguration"
        [readonly]="readonly"
        path="{{ computedPath }}"
        nestLevel="{{ nestLevel }}"
      >
        <!-- No other input fields are shown if the question is of type input -->
      </app-audit-form-question-input>

      <div *ngIf="question.type == 'documentation'">
        <app-audit-form-question-input-fields
          *ngIf="shouldShowInputFields"
          [audit]="audit"
          [question]="question"
          [auditConfiguration]="auditConfiguration"
          [readonly]="readonly"
          path="{{ computedPath }}"
          nestLevel="{{ nestLevel }}"
        ></app-audit-form-question-input-fields>
      </div>
    </div>
  </div>
</div>

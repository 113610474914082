<div class="contents">
  <ng-container *ngIf="request && !isSupervised">
    <h1 class="title">
      {{'supervision.client.invitation.title' | translate}}
    </h1>
    <br><br>
    <p>
      <a>{{supervisor.displayName}}</a> <span innerHTML="{{'supervision.client.invitation.message' | translate}}"></span>
    </p>
    <mat-divider></mat-divider>
    <div class="sameuser" *ngIf="isSameUser">
      {{ 'supervision.client.invitation.sameUser' | translate }}
    </div>
    <br><br>
    <div class="buttons" (click)="confirm()">
      <button mat-raised-button color="primary">
        {{'supervision.client.invitation.action.part1' | translate}}
        {{supervisor.displayName}}
        {{'supervision.client.invitation.action.part2' | translate}}
      </button>
    </div>
  </ng-container>

  <ng-container *ngIf="!request">
    <mat-card>
      <img mat-card-image src="/assets/not-found.svg"/>
      <mat-card-title>
        {{'vendors.offerDpa.badToken.title' | translate}}
      </mat-card-title>
      <mat-card-content>
        <span innerHtml="{{'vendors.offerDpa.badToken.message' | translate}}"></span>
      </mat-card-content>
    </mat-card>
  </ng-container>

  <ng-container *ngIf="isSupervised && request">
    <mat-card>
      <img mat-card-image src="/assets/not-found.svg"/>
      <mat-card-title>
        {{'supervision.client.invitation.alreadySupervised.title' | translate}}
      </mat-card-title>
      <mat-card-content>
        <span innerHtml="{{'supervision.client.invitation.alreadySupervised.message' | translate}}"></span>
      </mat-card-content>
    </mat-card>
  </ng-container>
</div>

import { Component, OnInit, Inject, ViewChild, AfterViewInit, ElementRef } from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA, MatDialog} from '@angular/material/dialog';

import { UserManagementService } from '../../../services/user/user-management.service';
import { Subject, NewComment, CommentService, CommentTypes } from '../../../services/comment/comment.service';
import { BusService } from 'app/services/bus.service';
import { EventsService } from 'app/services/events.service';
import { AuthenticationService } from 'app/services/authentication.service';
import { MentionsService } from 'app/services/mentions/mentions.service';
import { _ } from 'app/trans';
import { Router } from '@angular/router';
import { ClientSupervisionService } from 'app/services/supervision/client-supervision.service';
import { RequestService } from 'app/services/request.service';
import {TranslateService} from "@ngx-translate/core";
import {zip} from "rxjs";
import {DialogComponent} from "../../dialog/dialog.component";


@Component({
  selector: 'app-comment-box-dialog',
  templateUrl: './comment-box-dialog.component.html',
  styleUrls: ['./comment-box-dialog.component.scss']
})
export class CommentBoxDialogComponent implements OnInit, AfterViewInit {

  @ViewChild('comments') commentsHolder: ElementRef;

  commentTypes = CommentTypes;

  users: { id: number; name: string }[] = [];
  mentionables: {
    id: number;
    name: string;
    supervisorClientAccessMode: string;
    productType: string;
  }[] = [];
  comment: NewComment;

  mentionConfig = {
    maxItems: 3,
    labelKey: 'name',
    disableStyle: true,
    commentMentionsList: [],
  };

  reminderPending = false;
  subjectHost: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public subject: Subject,
    private dialogRef: MatDialogRef<CommentBoxDialogComponent>,
    private userService: UserManagementService,
    private comments: CommentService,
    private auth: AuthenticationService,
    private clientSupervisionService: ClientSupervisionService,
    private bus: BusService,
    private events: EventsService,
    private router: Router,
    private mentions: MentionsService,
    private translate: TranslateService,
    private dialog: MatDialog
  ) {
    this.subjectHost = subject.host;
    this.comment = {
      subject: this.subject,
      text: '',
      type: CommentTypes.text,
    };
  }

  ngOnInit() {
    this.userService.users(true).subscribe(response => {
      const polished = (response)
        .map(user => Object.assign({}, user, {
        name: `${user.firstName} ${user.lastName}`
      }));

      this.users = polished;
      this.mentionables = polished.filter(user => !user.deleted && user.email !== this.auth.email);
    });

    /**
     * The code above works fine for SMEs and on the clients supervision page...
     * This will allow us to filter and get only users who are allowed to see all clients,
     *  but only when inside a client!
     * First we remove anyone who is not set to access all clients.
     * Then we run a GET request to fetch a list of users who are allowed to access the current client.
     * Merge the response list with the current mentionables list.
     * Last we run a helper method to remove any duplicates.
     *
     * To allow filtering out deleted supervisors we need to check if the product type is default.
     */
    if(!this.router.url.includes('supervision/clients')) { // I dont want user to be in supervision mode to get allowed users of a client
      this.mentions.getUsers().subscribe(response => {
        this.mentionables = this.mentionables.filter(u => u.supervisorClientAccessMode === 'all');
        const polished = response
          .map(user => Object.assign({}, user, {
            name: `${user.firstName} ${user.lastName}`
          })
        );

        this.mentionables = [...this.mentionables, ...polished.filter(u => u.email !== this.auth.email)];
        this.removeDuplicates();
      });
    }

    this.comment.text = localStorage.getItem(`comment-draft-${this.subject.codename}`) || '';

    this.dialogRef.beforeClosed().subscribe(() => {
      localStorage.setItem(`comment-draft-${this.subject.codename}`, this.comment.text || '');
    });
  }

  get isSupervisor() {
    return this.clientSupervisionService.isInSupervisionMode;
  }

  /**
   * Helper method to remove duplicates.
   */
  removeDuplicates() {
    const tempArray = [];
    this.mentionables.map(user => {
      if(tempArray.length === 0) {
        tempArray.push(user);
      } else if(!tempArray.find(u => u.id === user.id)) {
        tempArray.push(user);
      }
    });
    this.mentionables = tempArray;
  }

  ngAfterViewInit() {
    setTimeout(() => this.scrollDown(), 500);
  }

  scrollDown() {
    this.commentsHolder.nativeElement.scrollTop = this.commentsHolder.nativeElement.scrollHeight;
  }

  send() {
    this.comment.mentions = [];
    this.users.forEach(user => {
      if (this.comment.text.indexOf(`@${user.name}`) !== -1 && !this.comment.mentions.includes(user)) {
        this.comment.mentions.push(user);
      }
    });

    this.comments.post(this.comment)
          .subscribe(() => this.updateSubject());
  }

  close() {
    this.comments.post({
      subject: this.subject,
      type: CommentTypes.close,
    }).subscribe(() => this.dialogRef.close());
  }

  reminder() {
    if (this.reminderPending) {
      return;
    }

    this.reminderPending = true;

    this.comments.post({
      subject: this.subject,
      text: this.subject.title,
      type: CommentTypes.reminder,
    }).subscribe(() => {
      this.updateSubject(false);
      setTimeout(() => this.scrollDown(), 500);
      this.bus.publish(this.events.notified.reminders.updated);
      this.reminderPending = false;
    });
  }

  resolveReminder() {
    if (this.reminderPending) {
      return;
    }

    this.reminderPending = true;

    this.comments.post({
      subject: this.subject,
      type: CommentTypes.reminderResolved,
    }).subscribe(() => {
      this.updateSubject(false);
      this.bus.publish(this.events.notified.reminders.updated);
      this.reminderPending = false;
    });
  }

  updateSubject(clearDraft = true) {
    this.comments.get(this.subject.codename)
      .subscribe(subject => {
        if (subject) {
          this.subject = Object.assign(this.subject, subject, {host: this.subjectHost});
          if (clearDraft) {
            this.comment = {
              subject: subject,
              text: '',
              type: CommentTypes.text,
            };
          }

          setTimeout(() => this.scrollDown(), 500);
        }
      });
  }

  deleteConversation() {
    zip(
      this.translate.get('commentBox.dialog.deleteConversation.title'),
      this.translate.get('commentBox.dialog.deleteConversation.message')
    )
      .subscribe(([title, message]) => {
        this.dialog.open(DialogComponent, { maxWidth: '450px', data: { header: title, message, confirm: true }})
          .afterClosed()
          .subscribe((confirmed) => {
            if (confirmed) {
              this.comments.deleteConversation(this.subject.codename)
                .subscribe(() => {
                  this.dialogRef.close();
                });
            }
          });
      });
  }

  author(comment) {
    return this.users.find(user => user.id === comment.authorId);
  }

  keypress(event: KeyboardEvent) {
    if (event.key === 'Enter' && !event.shiftKey) {
      this.send();
    }
  }

  deleteComment(comment) {
    this.comments.delete(comment.id, comment.subjectId)
      .subscribe(() => {
        this.updateSubject();
      });
  }
}

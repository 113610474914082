<div class="container">
  <h1 class="title">{{ 'supervision.documents.title' | translate }}</h1>
  <br><br><br>
  <p>{{ 'supervision.documents.explanation' | translate }}</p>

  <app-documents-container
    align="center"
    [upload-url]="uploadUrl"
    upload-key="document"
    design="fluid"
    [showPath]="false"
    [source]="documents" (documentChanged)="updateList($event)" searchable="true" uploadButtonText="{{ 'supervision.documents.upload' | translate }}"></app-documents-container>
</div>

import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DepartmentService } from '../../../services/departments/department.service';
import { CustomPaService } from '../../../services/rpa/rpa.custom-pa.service';
import { DialogComponent } from '../../dialog/dialog.component';

export interface DuplicatePaDialogPayload {
  pa: any;
}

@Component({
  selector: 'app-duplicate-pa-dialog',
  templateUrl: './duplicate.component.html',
  styleUrls: ['./duplicate.component.scss']
})
export class DuplicatePaComponent implements OnInit {

  form: FormGroup;
  departments = [];

  paId: number;

  pending = false;

  constructor(
    private paService: CustomPaService,
    private departmentService: DepartmentService,
    private dialogRef: MatDialogRef<DuplicatePaComponent>,
    private dialog: MatDialog,
    private translateService: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: DuplicatePaDialogPayload,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.paId = this.data.pa.paId;
    this.departmentService.requestGetAll().subscribe((departments) => {
      this.departments = departments;
    });

    const copyTranslated = this.translateService.instant('pa.duplicate.copy');

    this.form = new FormGroup({
      name: new FormControl(this.data.pa.paName + ` ${copyTranslated}`, [Validators.required, Validators.minLength(1)]),
      department: new FormControl(this.data.pa.dep.id, [Validators.required]),
      includeExternalProcessors: new FormControl(true),
      includeInternalProcessors: new FormControl(true)
    });
  }

  submit() {
    if (this.form.valid) {
      this.pending = true;
      const value = this.form.value;
      this.paService.duplicate(this.paId, {
        name: value.name,
        department: value.department,
        options: {
          includeExternalProcessors: value.includeExternalProcessors,
          includeInternalProcessors: value.includeInternalProcessors
        }
      })
      .subscribe((result) => {
        if (result && result.complete) {
          this.router.navigate([`/rpa/pa-details/${result.id}`]);
          this.dialogRef.close();
        } else if (result && !result.complete) {
          const translatedMessage = this.translateService.instant('pa.duplicate.incompleteError');
          this.dialog.open(DialogComponent, { data: { message: translatedMessage }, maxHeight: '90vh', width: '480px' })
            .afterClosed()
            .subscribe(() => {
              this.router.navigate([`/rpa/pa-details/${result.id}`]);
              this.dialogRef.close();
            });
        } else {
          this.pending = false;
        }
      });
    }
  }

}

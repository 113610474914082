import { Component, OnInit, OnDestroy, AfterViewInit, Inject, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {DatePipe} from '@angular/common';

import { BusService } from '../../../../services/bus.service';
import { EventsService } from '../../../../services/events.service';

import { Router } from '@angular/router';
import { HttpResponse } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { DepartmentService } from 'app/services/departments/department.service';
import { IncidentsResourceService } from 'app/services/incidents/incidents-resource.service';

@Component({
  selector: 'app-incidents-records-add-dialog',
  templateUrl: './add-incident-dialog.component.html',
  styleUrls: ['./add-incident-dialog.component.scss']
})
export class AddIncidentDialogComponent implements OnInit, OnDestroy {
  title = new FormControl('', []);
  details = new FormControl('', []);
  happenedOn = new FormControl(undefined, [Validators.required]);
  reportedOn = new FormControl(undefined, []);

  incidentId: number = undefined;

  isExistingIncident = false;

  reportingDepartmentId: number;
  allDepartments = [];

  constructor(
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<AddIncidentDialogComponent>,
    private bus: BusService,
    private events: EventsService,
    private router: Router,
    private incidentService: IncidentsResourceService,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private translate: TranslateService,
    private departmentService: DepartmentService,
    private datePipe: DatePipe,
  ) {
    this.setExistingData();
  }

  get isValid() {
    return this.happenedOn.valid;
  }

  setExistingData() {
    if (this.data) {
      this.title.setValue(this.data.title);
      this.details.setValue(this.data.details);
      this.happenedOn.setValue(this.formatDate(new Date(this.data.receivedOn)));
      this.reportedOn.setValue(this.formatDate(new Date(this.data.reportedOn) || undefined));

      if (this.data.reportedOn !== null) {
        this.reportedOn.setValue(new Date(this.data.reportedOn));
      }

      this.isExistingIncident = true;
      this.incidentId = this.data.incidentId;
      this.reportingDepartmentId = this.data.departmentId;
    }
  }

  ngOnInit() {
    this.subscribe();
    this.bus.publish(this.events.requested.data.departments.get);
  }

  ngOnDestroy() {
    this.unsubscribe();
  }

  updateDocument(response: HttpResponse<any>) {
    if (response.ok) {
    }
  }

  update() {
    if (this.incidentId) {
      this.bus.publish(this.events.requested.action.incidents.update, {
        id: this.incidentId,
        title: this.title.value,
        details: this.details.value,
        measuresTaken: this.data.measuresTaken,
        dpoNotice: this.data.dpoNotice,
        receivedOn: this.formatDate(this.happenedOn.value),
        reportedOn: this.formatDate(this.reportedOn.value),
        departmentId: this.reportingDepartmentId,
        status: this.data.status
      });
    } else {
      this.bus.publish(this.events.requested.action.incidents.create, {
        title: this.title.value,
        details: this.details.value,
        measuresTaken: '',
        dpoNotice: '',
        receivedOn: this.formatDate(this.happenedOn.value),
        reportedOn: this.formatDate(this.reportedOn.value),
        departmentId: this.reportingDepartmentId
      });
    }
  }

  updated() {
    this.dialogRef.close(true);
  }

  created() {
    this.dialogRef.close(true);
  }

  updateFailed(error) {
    console.error(error);
  }

  createFailed(error) {
    console.error(error);
  }

  departmentsSuccess(departments) {
    this.allDepartments = departments;
  }

  departmentsFailure(error) {
    console.error(error);
  }

  subscribe() {
    this.bus.subscribe(this.events.received.data.departments.get.success, this.departmentsSuccess, this);
    this.bus.subscribe(this.events.received.data.departments.get.failure, this.departmentsFailure, this);
    this.bus.subscribe(this.events.received.action.incidents.update.success, this.updated, this);
    this.bus.subscribe(this.events.received.action.incidents.create.success, this.created, this);
    this.bus.subscribe(this.events.received.action.incidents.update.failure, this.updateFailed, this);
    this.bus.subscribe(this.events.received.action.incidents.create.failure, this.createFailed, this);
  }

  unsubscribe() {
    this.bus.unsubscribe(this.events.received.action.incidents.update.success, this.updated);
    this.bus.unsubscribe(this.events.received.action.incidents.update.failure, this.updateFailed);
    this.bus.unsubscribe(this.events.received.action.incidents.create.success, this.created);
    this.bus.unsubscribe(this.events.received.action.incidents.create.failure, this.createFailed);
  }

  formatDate(date: Date) {
    return this.datePipe.transform(date, 'yyyy-MM-dd')
  }
}

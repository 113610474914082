import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { GroupListService } from '../../../../services/supervision/group-list.service';
import { FormControl } from '@angular/forms';
import { BusService } from '../../../../services/bus.service';

@Component({
  selector: 'app-group-add-client-dialog',
  templateUrl: './group-add-client-dialog.component.html',
  styleUrls: ['./group-add-client-dialog.component.scss']
})
export class GroupAddClientDialogComponent implements OnInit, OnDestroy {

  groups: any[];

  selectedGroup = new FormControl();
  controller: any;

  constructor(
    private dialogRef: MatDialogRef<GroupAddClientDialogComponent>,
    private groupListService: GroupListService,
    private bus: BusService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.groups = data.groups;
    this.controller = data.controller;
  }

  ngOnInit(): void {
    this.subscribe();
  }

  ngOnDestroy() {
    this.unsubscribe();
  }

  addToGroup() {
    if (this.selectedGroup.valid && this.selectedGroup.value) {
      this.groupListService.addClientToGroup(this.selectedGroup.value, this.controller._id);
    }
  }

  added() {
    this.dialogRef.close(true);
  }

  addFail(error) {
    console.error(error);
  }

  subscribe() {
    this.bus.subscribe('add-to-group-event', this.added, this);
    this.bus.subscribe('add-to-group-failed-event', this.addFail, this);
  }

  unsubscribe() {
    this.bus.unsubscribe('add-to-group-event', this.added);
    this.bus.unsubscribe('add-to-group-fail-event', this.addFail);
  }
}

<div class="change-password">
    <h1 class="title">{{ 'change-password.titel' | translate }}</h1>
    <br/>
    <mat-card>
      <img mat-card-image src="assets/password-change.svg"/>
      <form name="form" [formGroup]="form.formGroup" novalidate>
        <div class="form-group">
            <mat-form-field class="form-field">
              <input matInput placeholder="{{ 'change-password.newPw' | translate }}" type="password" class="form-control" name="password" formControlName="password" required autofocus/>
              <mat-error *ngIf="form.getControl('password').hasError('minlength') && !form.getControl('password').hasError('required')">{{ 'change-password.requirements' | translate }}</mat-error>
              <mat-error *ngIf="form.getControl('password').hasError('required')">{{ 'change-password.psIsRequired' | translate }}</mat-error>
            </mat-form-field>
        </div>
        <div class="form-group">
            <mat-form-field class="form-field">
              <input matInput placeholder="{{ 'change-password.confirmPw' | translate }}" type="password" class="form-control" name="passwordConfirm" formControlName="passwordConfirm" required />
              <mat-error *ngIf="form.getControl('passwordConfirm').hasError('sameValue') && !form.getControl('passwordConfirm').hasError('required')">{{ 'change-password.pwDoNotMatch' | translate }}</mat-error>
              <mat-error *ngIf="form.getControl('passwordConfirm').hasError('required')">{{ 'change-password.confirmPw' | translate }}</mat-error>
            </mat-form-field>
        </div>
        <mat-card-actions align="end">
          <button
            mat-raised-button
            type="button"
            color="primary"
            (click)="submit()">
            {{ 'change-password.changePwBtn' | translate }}
          </button>
        </mat-card-actions>
      </form>
    </mat-card>
</div>

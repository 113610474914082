import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Audit, AuditConfiguration, Category } from 'app/services/audits/audits.service';

@Component({
  selector: 'app-audit-form-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.scss'],
})
export class AuditFormCategoryComponent implements OnInit, OnDestroy {
  @Input() audit: Audit = null;
  @Input() category: Category = null;
  @Input() readonly = false;

  @Input() auditConfiguration: AuditConfiguration = null;

  @Input() path = '';
  @Input() nestLevel = 0;

  collapsed = false;

  constructor(
  ) {}

  get hasSubcategory() {
      if (this.category.content.length > 0) {
          return (this.category.content[0] as any).hasOwnProperty('categoryName');
      }

      return false; // it really doesn't matter here
  }

  get computedPath() {
      return `${this.path}${this.path.length > 0 ? '_' : ''}${this.category.categoryName}`;
  }

  get childNestLevel() {
    return parseInt(this.nestLevel.toString(), 10) + 1;
  }

  toggleCollapse() {
    this.collapsed = !this.collapsed;
    localStorage.setItem('audit-cat-collapse-' + this.category.categoryName, this.collapsed.toString());
  }

  ngOnInit() {
    this.collapsed =
      localStorage.getItem('audit-cat-collapse-' + this.category.categoryName) === 'true';
  }

  ngOnDestroy() {}
}

import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MixpanelService } from './mixpanel.service';



@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ]
})
export class MixpanelModule {
  static forRoot(): ModuleWithProviders<MixpanelModule> {
    return {
      ngModule: MixpanelModule,
      providers: [
        MixpanelService,
      ]
    };
  }
}

<div class="contents">

  <app-todos></app-todos>

  <access-control atLeast="user-management.role.controller.contributor">

    <div class="section workflow" *ngFor="let workflow of ongoingWorkflows">
      <h1 class="title">{{ workflow.title }}</h1>
      <div class="steps-list">
        <ng-container *ngFor="let step of workflow.steps">
          <div class="step" commentable
            comment-subject-codename="workflow-{{workflow.codename}}-step-{{step.task.codename}}"
            comment-subject-title="{{workflow.title}}, ({{step.index}}) {{step.task.title}}" [routerLink]="step.task.link"
            [ngClass]="{'complete': step.task.progress>=1, 'started': step.task.progress>0}">
            <!-- <todoable subject="workflow-{{workflow.codename}}-step-{{step.task.codename}}" suggested-title="{{workflow.title}}, ({{step.index}}) {{step.task.title}}"></todoable> -->

            <div class="step-index">
              <div class="top connector"></div>
              <div class="bottom connector"></div>
              <div class="current-circle" *ngIf="step.task.progress>0 && step.task.progress<1"></div>
              <div class="milestone-circle" *ngIf="step.milestone"></div>
              <div class="circle">{{step.index}}</div>
            </div>

            <div class="step-status">
              <mat-icon color="primary">done</mat-icon>
            </div>

            <div class="step-title">
              <span *ngIf="step.milestone"
                class="milestone-indicator">{{ 'todolist.workflow.milestone' | translate }}</span>
              {{ step.task.title }}
            </div>

          </div>
        </ng-container>
      </div>
    </div>

    <div class="section workflow" *ngFor="let workflow of completedWorkflows">
      <h1 class="title">{{ workflow.title }}</h1>
      <div class="steps-list">
        <ng-container *ngFor="let step of workflow.steps">
          <div class="step" commentable
            comment-subject-codename="workflow-{{workflow.codename}}-step-{{step.task.codename}}"
            comment-subject-title="{{workflow.title}}, ({{step.index}}) {{step.task.title}}"
            [routerLink]="step.task.link"
            [ngClass]="{'complete': step.task.progress>=1, 'started': step.task.progress>0}">
            <!-- <todoable subject="workflow-{{workflow.codename}}-step-{{step.task.codename}}" suggested-title="{{workflow.title}}, ({{step.index}}) {{step.task.title}}"></todoable> -->

            <div class="step-index">
              <div class="top connector"></div>
              <div class="bottom connector"></div>
              <div class="current-circle" *ngIf="step.task.progress>0 && step.task.progress<1"></div>
              <div class="milestone-circle" *ngIf="step.milestone"></div>
              <div class="circle">{{step.index}}</div>
            </div>

            <div class="step-status">
              <mat-icon color="primary">done</mat-icon>
            </div>

            <div class="step-title">
              <span *ngIf="step.milestone"
                class="milestone-indicator">{{ 'todolist.workflow.milestone' | translate }}</span>
              {{ step.task.title }}
            </div>

          </div>
        </ng-container>
      </div>
    </div>
    <neat-action-bar *ngIf="workflowActive('audit')">
      <button mat-flat-button color="primary"
        (click)="switchWorkflow('path_to_gdpr')">
          {{'todolist.workflow.actions.activateRPAs' | translate}}
      </button>
    </neat-action-bar>
    <neat-action-bar *ngIf="workflowActive('path_to_gdpr')">
      <button mat-button color="primary"
        (click)="switchWorkflow('audit')">
          {{'todolist.workflow.actions.activateAudit' | translate}}
      </button>
    </neat-action-bar>
    <br><br><br>
  </access-control>
</div>

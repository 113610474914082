<h1 class="title" mat-dialog-title>{{ data.title }}</h1>

<mat-dialog-content>
  <app-document-group-container [groupName]="data.groupName" [exactGroup]="data.exact"></app-document-group-container>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>
    {{'word.close' | translate}}
  </button>
</mat-dialog-actions>

<div class="question-multiselect">
    <mat-form-field [ngClass]="{ 'wide': isWideInput }" appearance="fill">
        <mat-label>{{ 'audit.form.question.multiselect.label' | translate }}</mat-label>
        <mat-select [disabled]="readonly" [value]="multiselectChoices.originalValue" (selectionChange)="save($event.value)" multiple>
          <mat-option *ngFor="let choice of question.choices; let i = index" [value]="i" title="{{ choice }}">{{choice}}</mat-option>
        </mat-select>
    </mat-form-field>

    <ng-content></ng-content>

    <div *ngIf="multiselectChoices.updatedValue !== null && question.conditionChildren">
        <div *ngFor="let selection of multiselectChoices.updatedValue">
          <app-audit-form-question *ngFor="let question of question.conditionChildren[selection]" [readonly]="readonly" [audit]="audit" [question]="question" [auditConfiguration]="auditConfiguration" path="{{ computedPath }}" nestLevel="{{ childNestLevel }}"></app-audit-form-question>
        </div>
        <div *ngIf="multiselectChoices.updatedValue.length > 0 && question.conditionChildren.any !== null">
          <app-audit-form-question *ngFor="let question of question.conditionChildren['any']" [readonly]="readonly" [audit]="audit" [question]="question" [auditConfiguration]="auditConfiguration" path="{{ computedPath }}" nestLevel="{{ childNestLevel }}"></app-audit-form-question>
        </div>
    </div>
</div>

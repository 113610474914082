<h2 matDialogTitle>
  {{'supervision.group.addClient.title' | translate}}
</h2>

<mat-dialog-content>
  <mat-form-field>
    <mat-label>{{'supervision.group.addClient.groups' | translate}}</mat-label>
    <mat-select [formControl]="selectedGroup">
      <mat-option *ngFor="let group of groups" [value]="group.id">{{group.title}}</mat-option>
    </mat-select>
  </mat-form-field>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button [matDialogClose]="false">{{'word.cancel' | translate}}</button>
  <button mat-button color="primary" (click)="addToGroup()">{{'word.add' | translate}}</button>
</mat-dialog-actions>

<div class="container">
  <h1 class="title">{{ 'pa.select.titel' | translate }}</h1>
  <p>
    {{'pa.starter-pas.hint' | translate}}
  </p>

  <br><br>

  <div class="list">
    <starter-pa-tree-node *ngFor="let node of tree"
      [node]="node"
      actionStyle="stroked"
      action="{{ 'word.pick' | translate }}"
      toggleAction="{{ 'word.remove' | translate }}"
      (picked)="pick($event)"
      (unpicked)="unpick($event)">
    </starter-pa-tree-node>
    <starter-pa *ngFor="let pa of freePas" [pa]="pa"
      actionStyle="stroked"
      actionStyle="stroked"
      action="{{ 'word.pick' | translate }}"
      toggleAction="{{ 'word.remove' | translate }}"
      (picked)="pick(pa)"
      (unpicked)="unpick(pa)">
    </starter-pa>
  </div>

  <br><br>

  <div class="actions">
    <button mat-button (click)="close()">
      {{'word.skip' | translate}}
    </button>
    <button mat-flat-button [disabled]="pickedPas.length < 1" color="primary" (click)="create()">
      {{'pa.starter-pas.action.pt1' | translate}} {{pickedPas.length}} {{'pa.starter-pas.action.pt2' | translate}}
    </button>
  </div>
</div>

import {Injectable} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';

import {BusService} from '../bus.service';
import {EventsService} from '../events.service';
import {RequestService} from '../request.service';
import { share } from 'rxjs/operators';


@Injectable()
export class UserService {
  api = {
    root: '/account',
    profile: '/profile',
    info: '/info',
  }

  constructor(
    private bus: BusService,
    private events: EventsService,
    private requestService: RequestService
  ) {
    this.subscribe();
  }

  userInfo() {
    return this.requestService.get<any>({
      uri: this.api.root + this.api.info,
      handlers: {
        success: data => this.bus.publish(this.events.received.data.user.info.success, data),
        error: error => this.bus.publish(this.events.received.data.user.info.failure, error),
      }
    }).pipe(share());
  }

  updateProfile(firstName: string, lastName: string) {
    return this.requestService.put<any, any>({
      uri: this.api.root + this.api.profile,
      body: {
        firstName,
        lastName
      },
      handlers: {
        success: () => this.bus.publish(this.events.notified.user.profileChanged, { firstName, lastName }),
        error: () => {},
      }
    });
  }

  updateEmailAddress(newEmail: string) {
    return this.requestService.put<any, any>({
      uri: this.api.root + this.api.profile + '/update-email',
      body: {
        newEmail,
      },
      handlers: {
        success: () => this.bus.publish('change-email.success'), // success handler
        error: () => this.bus.publish('change-email.error'), // error handler
      }
    });
  }

  currentSelectedLanguage() {
    return localStorage.getItem('lang');
  }

  resolveUserJurisdiction(): string {
    const currentSelectedLanguage = this.currentSelectedLanguage();

    if (currentSelectedLanguage === 'en-NG') return 'ndpr';
    if (currentSelectedLanguage === 'pt-BR') return 'lgpd';

    return 'gdpr';
  }

  subscribe() {
    this.bus.subscribe(this.events.requested.data.user.info, this.userInfo.bind(this));
  }
}

<h1 mat-dialog-title>
  <ng-container *ngIf="node.id">
    {{'rpa.reference.tree.edit-node.title' | translate}}
    <!-- Edit Node -->
  </ng-container>
  <ng-container *ngIf="!node.id">
    {{'rpa.reference.tree.create-node.title' | translate}}
    <!-- Create Node -->
  </ng-container>
</h1>

<mat-dialog-content>
  <mat-form-field>
    <input matInput [(ngModel)]="node.title" placeholder="Title" type="text"/>
  </mat-form-field>
  <mat-form-field>
    <input matInput [(ngModel)]="node.description" placeholder="Description" type="text"/>
  </mat-form-field>
  <pa-reference-tree-input placeholder="Parent" [(node)]="parent" [tree]="tree" [allowRoot]="true">
  </pa-reference-tree-input>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button color="warn" (click)="remove()" *ngIf="node.id">{{'word.delete' | translate}}</button>
  <button mat-button (click)="cancel()">{{'word.cancel' | translate}}</button>
  <button mat-flat-button color="primary" (click)="save()" *ngIf="node.id">{{'word.save' | translate}}</button>
  <button mat-flat-button color="primary" (click)="create()" *ngIf="!node.id">{{'word.create' | translate}}</button>
</mat-dialog-actions>
import { Component, Input, OnInit } from '@angular/core';
import { AuthenticationService } from '../../services/authentication.service';
import {AccessLevelService} from "../../services/user/access-level.service";



@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'access-control',
  templateUrl: './access-control.component.html',
  styleUrls: ['./access-control.component.scss']
})
export class AccessControlComponent implements OnInit {

  @Input() atLeast: string = null;
  @Input() lowerThan: string = null;
  @Input() only: string = null;

  constructor(
    private authService: AuthenticationService,
    private accessLevel: AccessLevelService
  ) { }


  get canDisplay() {
    if (!this.atLeast && !this.lowerThan && !this.only) return true;

    if (this.only) {
      return this.accessLevel.checkRole(this.only) === 0;
    }

    if (this.atLeast) {
      return this.accessLevel.checkRole(this.atLeast) >= 0;
    }

    if (this.lowerThan) {
      return this.accessLevel.checkRole(this.lowerThan) < 0;
    }

    return false;
  }

  ngOnInit(): void {
  }

}

import { Injector } from '@angular/core';
import { Observable, of, Subject, Subscription } from 'rxjs';
import { catchError, debounceTime, switchMap, tap } from 'rxjs/operators';
import { Audit, AuditConfiguration, AuditsService } from '../../../services/audits/audits.service';
import { VendorAuditService } from '../../../services/vendors/vendor-audit.service';

export abstract class AuditLikeService {
  abstract updateAnswerField<T>(id: number, fieldName: string, value: T): Observable<Audit>;
  abstract handleFieldError(error: Error, payload: { fieldName: string });
}

export function fetchAuditLikeService(configuration: AuditConfiguration, injector: Injector): AuditLikeService {
  if (configuration.auditKind === 'Audit') {
    return injector.get(AuditsService);
  }
  if (configuration.auditKind === 'VendorAudit') {
    return injector.get(VendorAuditService);
  }

  return null;
}


export class AuditFieldState<T = string> {
  originalValue: T = null;
  updatedValue: T = null;
  fullyQualifiedFieldName = '';
  fieldName = '';
  saving = false;
  failed = false;

  private auditService: AuditLikeService = null;
  private auditId: number = null;

  private subject = new Subject<T>();
  private subscription: Subscription;

  constructor(
    service: AuditLikeService,
    auditId: number,
    fieldName: string,
    fullFieldName: string,
    originalValue: T
  ) {
    this.auditService = service;
    this.auditId = auditId;
    this.fieldName = fieldName;
    this.fullyQualifiedFieldName = fullFieldName;
    this.originalValue = originalValue;
    this.updatedValue = originalValue;

    this.subscription = this.subject
      .pipe(
        tap(() => {
          this.saving = true;
        }),
        debounceTime(1000),
        switchMap((value) =>
          this.auditService.updateAnswerField(
            this.auditId,
            this.fullyQualifiedFieldName,
            value
          )
        ),
        catchError((error) => {
          console.error('could not save audit field');
          console.error(error);

          this.failed = true;

          this.auditService.handleFieldError(error, { fieldName: this.fullyQualifiedFieldName });

          return of(null);
        }),
        tap(() => {
          this.saving = false;
        })
      )
      .subscribe();
  }

  submit(updateValue: T, withFormatter?: (v: T) => any) {

    // I know ts will complain for this '==' but I need to check the value regardless of type
    // on load thie originalValue is passed as number but updateValue is type T - string
    if(this.originalValue == updateValue) {
      updateValue = 0 as unknown as T;
    }

    if (withFormatter) {
      this.subject.next(withFormatter(updateValue));
    } else {
      this.subject.next(updateValue);
    }

    this.updatedValue = updateValue;
    this.originalValue = updateValue;
  }

  clear() {
    this.subscription.unsubscribe();
  }
}

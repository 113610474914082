<mat-dialog-content>
  <div class="target">
    <div>
      <user-avatar [user]="user"></user-avatar>
    </div>
    <div class="name">
      <user-name [user]="user"></user-name>
      <div class="email">{{user.email}}</div>
    </div>
  </div>
  <br>
  {{'user-management.delete.hint.pt1' | translate}}
  <b><user-name [user]="user"></user-name></b>
  {{'user-management.delete.hint.pt2' | translate}}

  <br><br>
  <mat-form-field color="warn">
    <input matInput [(ngModel)]="confirmEmail" autocomplete="off"
      type="text" placeholder="{{'user-management.delete.confirm.label' | translate}}"/>
    <mat-hint>
      {{'user-management.delete.confirm.hint.pt1' | translate}}
      {{user.email}}{{'user-management.delete.confirm.hint.pt2' | translate}}

    </mat-hint>
  </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-flat-button color="warn" [disabled]="!confirmed" (click)="delete()">
    {{'user-management.delete.action.pt1' | translate}}
    <user-name [user]="user"></user-name>
    {{'user-management.delete.action.pt2' | translate}}
  </button>
  <button mat-button mat-dialog-close>
    {{'word.cancel' | translate}}
  </button>
</mat-dialog-actions>

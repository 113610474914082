// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  envName: 'staging',
  apiUrl: 'https://charizard.ecomply.io/api',
  intercomAppId: 'xus804jh',
  stripePublishable: 'pk_test_FJ47bdo0u58x38wO8hLBPoIV',
  selfSignUp: false,
  recaptchaKey: '6Ld5xpYaAAAAAAQ12MAK7dCBHxpjxyJf8F1cwZVt',
  mixpanelKey: '77f1db49d854e5401d6fa22d8177c8fd',
  enablePlausible: false,
};

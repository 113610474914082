import { Injectable } from '@angular/core';

import {HttpErrorResponse} from '@angular/common/http';
import {BusService} from '../bus.service';
import {EventsService} from '../events.service';
import {
  IGetOptions,
  IPostOptions,
  RequestService
} from '../request.service';


interface AddClientRequest {
  email: string;
}

interface AddControllerRequest {
  controllerName: string;
  clientId: string;
}

@Injectable()
export class ClientManagementService {

  api = {
    root: '/supervision/client-management',
    clients: {
      root: '/clients',
    },
    requests: {
      root: '/requests',
      pending: '/pending',
      resend: '/resend',
    }
  };

  constructor(
    private bus: BusService,
    private events: EventsService,
    private requestService: RequestService
  ) {
    this.subscribe();
  }

  clients() {
    return this.requestService.get<any>({
      uri: this.api.root + this.api.clients.root,
      handlers: {
        success: clients => this.bus.publish(this.events.received.data.supervision.clients.all.success, clients),
        error: error => this.bus.publish(this.events.received.data.supervision.clients.all.failure, error.error)
      }
    });
  }

  pendingRequests() {
    this.requestService.get<any>({
      uri: this.api.root + this.api.requests.root + this.api.requests.pending,
      handlers: {
        success: clients => this.bus.publish(this.events.received.data.supervision.requests.pending.success, clients),
        error: error => this.bus.publish(this.events.received.data.supervision.requests.pending.failure, error.error)
      }
    });
  }

  getSingle(id: number) {
    this.requestService.get<any>({
      uri: this.api.root + this.api.clients.root + `/${id}`,
      handlers: {
        success: client => this.bus.publish(this.events.received.data.supervision.clients.single.success, client),
        error: error => this.bus.publish(this.events.received.data.supervision.clients.single.failure, error.error),
      }
    });
  }

  addRequest(data: AddClientRequest) {
    this.requestService.post<AddClientRequest, void>({
      uri: this.api.root + this.api.requests.root,
      body: data,
      handlers: {
        success: () => this.bus.publish(this.events.received.action.supervision.requests.add.success),
        error: error => this.bus.publish(this.events.received.action.supervision.requests.add.failure, error.error),
      }
    });
  }

  addClient(data: AddControllerRequest) {
    return this.requestService.post<AddControllerRequest, { clientId: number }>({
      uri: this.api.root + this.api.clients.root,
      body: data,
      handlers: {
        success: () => this.bus.publish(this.events.received.action.supervision.clients.add.success),
        error: error => this.bus.publish(this.events.received.action.supervision.clients.add.failure, error.error),
      }
    });
  }

  deleteClient(controllerId) {
    this.requestService.delete<any, any>({
      uri: this.api.root + this.api.clients.root + `/${controllerId}`,
      handlers: {
        success: () => this.bus.publish(this.events.received.action.supervision.clients.delete.success),
        error: error => this.bus.publish(this.events.received.action.supervision.clients.delete.failure, error)
      }
    });
  }

  deleteRequest(requestId) {
    this.requestService.delete<any, any>({
      uri: this.api.root + this.api.requests.root + `/${requestId}`,
      handlers: {
        success: () => this.bus.publish(this.events.received.action.supervision.requests.delete.success),
        error: error => this.bus.publish(this.events.received.action.supervision.requests.delete.failure, error)
      }
    });
  }

  resendInvitation(requestId) {
    this.requestService.post<any, any>({
      uri: this.api.root + this.api.requests.root + `/${requestId}` + this.api.requests.resend,
      body: {},
      handlers: {
        success: () => this.bus.publish(this.events.received.action.supervision.clientInvitation.resend.success),
        error: error => this.bus.publish(this.events.received.action.supervision.clientInvitation.resend.failure, error)
      }
    });
  }

  subscribe() {
    this.bus.subscribe(this.events.requested.data.supervision.clients.all, this.clients.bind(this));
    this.bus.subscribe(this.events.requested.data.supervision.requests.pending, this.pendingRequests.bind(this));
    this.bus.subscribe(this.events.requested.action.supervision.requests.add, this.addRequest.bind(this));
    this.bus.subscribe(this.events.requested.action.supervision.clients.add, this.addClient.bind(this));
    this.bus.subscribe(this.events.requested.action.supervision.clients.delete, this.deleteClient.bind(this));
    this.bus.subscribe(this.events.requested.action.supervision.requests.delete, this.deleteRequest.bind(this));
    this.bus.subscribe(this.events.requested.data.supervision.clients.single, this.getSingle.bind(this));
    this.bus.subscribe(this.events.requested.action.supervision.clientInvitation.resend, this.resendInvitation.bind(this));
  }
}

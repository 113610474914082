<div (click)="choose()" class="path-select">
  <div *ngIf="path.length === 0" class="path-element empty">
    <mat-icon>folder</mat-icon>
    <div class="segments">
      <div class="segment empty">
        {{ 'documents.select-path.empty' | translate }}
      </div>
    </div>
  </div>
  <div *ngIf="path.length > 0" class="path-element">
    <mat-icon>folder</mat-icon>
    <div class="segments">
      <div *ngFor="let segment of path" class="segment">
        /{{ segment }}
      </div>
    </div>
  </div>
</div>

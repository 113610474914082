import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClientModule, HttpClient } from '@angular/common/http';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import {DateAdapter, MAT_DATE_LOCALE, MatNativeDateModule, MatRippleModule} from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';

import { AppConfig } from './app.config';

import { FlexLayoutModule } from '@angular/flex-layout';
import { OverlayContainer } from '@angular/cdk/overlay';
import { ClipboardModule } from 'ngx-clipboard';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { RecaptchaModule } from 'ng-recaptcha';

import { MentionModule } from 'angular-mentions';

import { CrispModule } from './modules/crisp/crisp.module';

// ngx-translate
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { NgxFileDropModule } from 'ngx-file-drop';

// Stripe
import { NgxStripeModule } from 'ngx-stripe';

import { MarkdownModule } from 'ngx-markdown';

import { NgSelectModule } from '@ng-select/ng-select';

// import { CdkTableModule, DataSource } from '@angular/cdk/table';
import { ScrollingModule } from '@angular/cdk/scrolling';

// import { ChartsModule } from 'ng2-charts';
// Routes
import { RoutingConfig } from './app.routes';

// Components
import { AppComponent } from './app.component';
import { HomeComponent } from './components/home/home.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { RegistrationComponent, RegistrationDialogComponent } from './components/registration/registration.component';
import { VerificationComponent } from './components/registration/verification/verification.component';
import { LoginComponent, LoginDialogComponent } from './components/login/login.component';
import { EmailLoginComponent } from './components/login/email/email.login.component';
import { DialogComponent } from './components/dialog/dialog.component';
import { BasicInfoComponent } from './components/rpa/basic-info/basic-info.rpa.component';
import { InfoRpaComponent } from './components/rpa/info/info.rpa.component';
import { PaFormComponent } from './components/rpa/info/pa-form/pa-form.rpa.component';
import { PaListComponent } from './components/rpa/pa-list/pa-list.component';
import { PaSelectionComponent } from './components/rpa/pa-selection/pa-selection.rpa.component';
import { ReportComponent } from './components/rpa/report/report.rpa.component';
import {
  ChangePasswordComponent,
  ChangePasswordDialogComponent
} from './components/settings/change-password/change-password.settings.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { EmailNotificationComponent } from './components/settings/email-notification/email-notification.settings.component';
import { ProfilePictureComponent } from './components/settings/profile-picture/profile-picture.settings.component';
import { ImprintComponent } from './components/footer/imprint/imprint.component';
import { UnderConstructionComponent } from './components/under-construction/under-construction.component';
import { UserExpiredComponent } from './components/user-expired/user-expired.component';
import { CustomPaComponent } from './components/rpa/custom-pa/custom-pa.rpa.component';
import { CreateSubscriptionComponent } from './components/payment/create-subscription/create-subscription.component';
import { ThankYouComponent } from './components/payment/thank-you/thank-you.component';
import { BillingComponent } from './components/payment/billing/billing.component';
import { UpdatePaymentDetailsComponent } from './components/payment/update-payment-details/update-payment-details.component';
import {
  AdditionalPaymentAuthenticationComponent
} from './components/payment/additional-authentication/additional-authentication.component';
import { MetricsComponent } from './components/metrics/metrics.component';

// Supervision
import { SupervisionClientListComponent } from './components/supervision/client-list/client-list.component';
import { ConfirmSupervisionInvitationComponent } from './components/supervision/invitation/invitation.component';


// Interlayer
import { AuthenticationService } from './services/authentication.service';
import { BasicInfoGetService } from './services/basic-info/basic-info.get.service';
import { BasicInfoPostService } from './services/basic-info/basic-info.post.service';
import { RpaGetAllService } from './services/rpa/rpa.get-all.service';
import { RpaGetService } from './services/rpa/rpa.get.service';
import { RpaPostService } from './services/rpa/rpa.post.service';
import { RpaUpdateStatusService } from './services/rpa/rpa.update-status.service';
import { ReportGetService } from './services/report/report.get.service';
import { ChangePasswordService } from './services/settings/change-password.service';
import { EmailNotificationService } from './services/settings/email-notification.service';
import { ProfilePictureService } from './services/settings/profile-picture.service';

// Services
import { AuthenticationRequestService } from './services/authentication-request/authentication-request.service';
import { EmailAuthService } from './services/authentication-request/email-auth.service';
import { ForgotPasswordService } from './services/authentication-request/forgot-password.service';
import { SendVerificationEmailService } from './services/authentication-request/send-verification.service';
import { RegistrationRequestService } from './services/authentication-request/registration.service';
import { RequestService } from './services/request.service';
import { BusService } from './services/bus.service';
import { EventsService } from './services/events.service';
import { ValidationService } from './services/validation.service';
import { VerificationService } from './services/authentication-request/verification.service';
import { AdminBroadcastService } from './services/admin-broadcast.service';
import { CustomPaService } from './services/rpa/rpa.custom-pa.service';
import { DepartmentService } from './services/departments/department.service';
import { WorkflowTasksService } from './services/workflow-tasks/workflow-tasks.service';
import { EmailCheckService } from './services/authentication-request/email-check.service';
import { UploadService } from './services/upload.service';
import { RpaService } from './services/rpa/rpa.service';
import { RpaInternallyProcessedService } from './services/rpa/rpa.internally-processed.service';
import { RpaExternallyProcessedService } from './services/rpa/rpa.externally-processed.service';

import { PaymentSubscriptionService } from './services/payment/subscription.service';
import { PaymentPlanGetService } from './services/payment/plan.get.service';
import { PaymentCouponGetService } from './services/payment/coupon.get.service';
import { PaymentInfoGetLatestService } from './services/payment/info/info.latest.get.service';
import { PaymentInfoGetSubscriptionDetailsService } from './services/payment/info/info.subscription-details.get.service';
import { PaymentUpgradePreviewGetService } from './services/payment/upgrade/upgrade.preview.get.service';
import { PaymentUpgradePostService } from './services/payment/upgrade/upgrade.post.service';
import { UpdatePaymentDetailsService } from './services/payment/update-payment-details.service';

import { DpoService } from './services/dpo/dpo.service';
import { CrispService } from './modules/crisp/crisp.service';
import { UserService } from './services/user/user.service';
import { VendorsService } from './services/vendors/vendors.service';
import { RpasOnBehalfService } from './services/rpas-on-behalf/rpas-on-behalf.service';
import { UserManagementService } from './services/user/user-management.service';
import { AccessLevelService } from './services/user/access-level.service';
import { TomsService } from './services/toms/toms.service';
import { BadgeService } from './services/badge/badge.service';
import { ClientManagementService } from './services/supervision/client-management.service';
import { SupervisionClientInvitationService } from './services/supervision/client-invitation.service';
import { SupervisionTemplatesService } from './services/supervision/templates.service';
import { SupervisionTemplatesBackendService } from './services/supervision/templates-backend.service';
import { CommentService } from './services/comment/comment.service';
import { SupervisorProfileService } from './services/supervision/supervisor-profile.service';
import { DocumentResourceService } from './services/documents/document-resource.service';
import { ActionsService } from './services/actions/actions.service';

// Guards
import { AuthenticationGuard } from './guards/authentication.guard';
import { NotAuthenticatedGuard } from './guards/not-authenticated.guard';
import { ProductTypeGuard } from './guards/product-type.guard';
import { ProductFeaturesGuard } from './guards/product-features.guard';
import { SelfSignUpGuard } from './guards/self-signup.guard';

// Interceptors
import { I18nInterceptor } from './interceptors/interceptors.i18n';
import { AuthenticationInterceptor } from './interceptors/interceptors.authentication';
import { ExpiryInterceptor } from './interceptors/interceptors.expiry';

import { LanguageComponent } from './components/language/language.component';
import { SideNavComponent } from './components/side-nav/side-nav.component';
import { TodolistComponent } from './components/todolist/todolist.component';
import { ProgressIndicatorComponent } from './components/progress-indicator/progress-indicator.component';
import { DepartmentsComponent, EditDepartmentDialogComponent } from './components/organisation/department/departments.component';
import { StepperComponent } from './components/stepper/stepper.component';
import { StepComponent } from './components/stepper/step/step.component';
import { ControllerComponent } from './components/organisation/controller/controller.component';
import { _ControllerService } from './services/basic-info/controller.service';
import { ControllerService } from './services/controller/controller.service';
import { CoolUploadComponent } from './components/cool-upload/cool-upload.component';
import { DpGoalsComponent } from './components/organisation/dp-goals/dp-goals.component';
import { DeclareDpoComponent } from './components/organisation/declare-dpo/declare-dpo.component';
import { MilestoneOneComponent } from './components/milestones/milestone-one/milestone-one.component';
import { BigRoundButtonComponent } from './components/big-round-button/big-round-button.component';
import { OrganisationComponent } from './components/organisation/organisation.component';
import { RpaComponent } from './components/rpa/rpa.component';
import { PaAssigningComponent } from './components/rpa/pa-assigning/pa-assigning.component';
import { RpaTutorialComponent } from './components/rpa/tutorial/tutorial.component';
import { ApplicableComponent } from './components/rpa/applicable/applicable.component';
import { PaDetailsComponent } from './components/rpa/pa-details/pa-details.component';
import { PaInternalProcessorsComponent } from './components/rpa/pa-internal-processors/pa-internal-processors.component';
import { PaExternalProcessorsComponent } from './components/rpa/pa-external-processors/pa-external-processors.component';
import { VendorSearchDialogComponent } from './components/rpa/pa-external-processors/vendor-search-dialog/vendor-search-dialog.component';
import { VendorAddDialogComponent } from './components/rpa/pa-external-processors/vendor-add-dialog/vendor-add-dialog.component';
import { MilestoneTwoComponent } from './components/milestones/milestone-two/milestone-two.component';
import { DpaDialogComponent } from './components/vendors/dpas/dpa-dialog/dpa-dialog.component';
import {
  DpaRequestEmailSentDialogComponent
} from './components/vendors/dpas/dpa-dialog/dpa-request-email-sent-dialog/dpa-request-email-sent-dialog.component';
import { OfferDpaComponent } from './components/vendors/offer-dpa/offer-dpa.component';
import { AuthTokenLoginComponent } from './components/auth-token-login/auth-token-login.component';
import { UserManagementComponent } from './components/user-management/user-management.component';
import { EditUserDialogComponent } from './components/user-management/edit-user-dialog/edit-user-dialog.component';
import { InviteUserDialogComponent } from './components/user-management/invite-user-dialog/invite-user-dialog.component';
import { ManageInviteDialogComponent } from './components/user-management/manage-invite-dialog/manage-invite-dialog.component';
import { InvitationResultDialogComponent } from './components/user-management/invitation-result-dialog/invitation-result-dialog.component';
import { ConfirmInvitationComponent } from './components/user-management/confirm-invitation/confirm-invitation.component';
import { PrivacyPolicyEnComponent } from './components/footer/privacy-policy-en/privacy-policy-en.component';
import { PrivacyPolicyDeComponent } from './components/footer/privacy-policy-de/privacy-policy-de.component';
import { TosDeComponent } from './components/footer/tos-de/tos-de.component';
import { TosEnComponent } from './components/footer/tos-en/tos-en.component';
import { CookiePolicyDeComponent } from './components/footer/cookie-policy-de/cookie-policy-de.component';
import { CookiePolicyEnComponent } from './components/footer/cookie-policy-en/cookie-policy-en.component';
import { TodosCompleteComponent } from './components/todolist/todos-complete/todos-complete.component';
import { TomsComponent } from './components/toms/toms.component';
import { TomsCategoryComponent } from './components/toms/toms-category/toms-category.component';
import { BigRoundCheckboxComponent } from './components/big-round-checkbox/big-round-checkbox.component';
import { GetBadgeComponent } from './components/badge/get-badge/get-badge.component';
import { CopiedDialogComponent } from './components/badge/get-badge/copied-dialog/copied-dialog.component';
import { BadgeInfoComponent } from './components/badge/badge-info/badge-info.component';
import { IsProcessorComponent } from './components/rpa-on-behalf/is-processor/is-processor.component';
import { AddClientDialogComponent } from './components/supervision/add-client-dialog/add-client-dialog.component';
import { DeleteClientDialogComponent } from './components/supervision/delete-client-dialog/delete-client-dialog.component';
import { SupervisionClientDetailsComponent } from './components/supervision/client-details/client-details.component';
import { SupervisionClientTokenService } from './services/supervision/client-token.service';
import { ClientSupervisionService } from './services/supervision/client-supervision.service';
import { CommentBoxComponent } from './components/comment-box/comment-box.component';
import { CommentBoxDialogComponent } from './components/comment-box/comment-box-dialog/comment-box-dialog.component';
import { SupervisorProfileComponent } from './components/supervision/profile/supervisor-profile.component';
import { WebsiteComplianceComponent } from './components/website-compliance/website-compliance.component';
import { DsarTemplatesComponent } from './components/dsar/templates/templates.component';
import { AddClientEmailComponent } from './components/supervision/add-client/email/add-client-email.component';
import { AddClientControllerComponent } from './components/supervision/add-client/controller/add-client-controller.component';
import { DocumentsMiscListComponent } from './components/documents/misc-list/documents-misc-list.component';
import { DocumentsUploadDialogComponent } from './components/documents/upload/documents-upload-dialog.component';
import { DeleteDocumentDialogComponent } from './components/documents/delete/delete-document-dialog.component';
import { DocumentTaglistDialogComponent } from './components/documents/taglist/document-taglist-dialog.component';
import { GeneratorDashboardComponent } from './components/documents/generator/generator-dashboard.component';
import { FilesDashboardComponent } from './components/documents/files/files-dashboard.component';
import { ApplyTemplateDialogComponent } from './components/supervision/templates/apply-template-dialog/apply-template-dialog.component';
import { CreateTemplateDialogComponent } from './components/supervision/templates/create-template-dialog/create-template-dialog.component';
import { SelectTemplateDialogComponent } from './components/supervision/templates/select-template-dialog/select-template-dialog.component';
import { TemplateInfoDialogComponent } from './components/supervision/templates/template-info-dialog/template-info-dialog.component';
import { DeleteTemplateDialogComponent } from './components/supervision/templates/delete-template-dialog/delete-template-dialog.component';
import { ActionsComponent } from './components/actions/actions.component';
import { CdkDetailRowDirective } from './components/actions/cdk-detail-row.directive';
import { ActionsMetadataUsersComponent } from './components/actions/metadata-info/users/users.component';
import { ActionsMetadataDepartmentComponent } from './components/actions/metadata-info/department/department.component';
import { ActionsMetadataDocumentComponent } from './components/actions/metadata-info/document/document.component';
import { ActionsMetadataRpaProcessorsComponent } from './components/actions/metadata-info/rpa-processors/rpa-processors.component';
import { ActionsMetadataRpaComponent } from './components/actions/metadata-info/rpa/rpa.component';
import { ChangelogComponent } from './components/changelog/changelog.component';
import { ActionsMetadataVendorsComponent } from './components/actions/metadata-info/vendors/vendors.component';
import { ActionsMetadataTomsComponent } from './components/actions/metadata-info/toms/toms.component';
import { DsarRecordsComponent } from './components/dsar/records/records.component';
import { DsarResourceService } from './services/dsar/dsar-resource.service';
import { DsarRecordDetailsComponent } from './components/dsar/records/details/details.component';
import { DocumentsContainerComponent } from './components/documents/container/documents-container.component';
import { PaymentHeaderTimerComponent } from './components/payment/payment-header-timer/payment-header-timer.component';
import { IncidentTemplatesComponent } from './components/incident/templates/templates.component';
import { IncidentRecordsComponent } from './components/incident/records/records.component';
import { IncidentRecordDetailsComponent } from './components/incident/records/details/details.component';
import {
  IncidentsFilterDepartmentDialogComponent
} from './components/incident/records/incidents-filter-department-dialog/incidents-filter-department-dialog.component';
import { IncidentDpoDetailsDialogComponent } from './components/incident/records/dpo-details-dialog/dpo-details-dialog.component';
import {
  IncidentsFilterTitleDialogComponent
} from './components/incident/records/incidents-filter-title-dialog/incidents-filter-title-dialog.component';
import { AddIncidentDialogComponent } from './components/incident/records/add-incident-dialog/add-incident-dialog.component';
import { IncidentsResourceService } from './services/incidents/incidents-resource.service';
import {
  DsarFilterDepartmentDialogComponent
} from './components/dsar/records/dsar-filter-department-dialog/dsar-filter-department-dialog.component';
import { DsarFilterTitleDialogComponent } from './components/dsar/records/dsar-filter-title-dialog/dsar-filter-title-dialog.component';
import { RiskAnalysisRiskDialogComponent } from './components/rpa/pa-details/risk-analysis/risk-dialog/risk-dialog.component';
import { RiskAnalysisSeverityDialogComponent } from './components/rpa/pa-details/risk-analysis/severity-dialog/severity-dialog.component';

import { ProductFeaturesService } from './services/product-features.service';
import { LockedFeatureComponent } from './components/product-features/locked-feature/locked.component';
import { FeatureAccessComponent } from './components/product-features/feature-access/access.component';
import { CookiePolicyPtComponent } from './components/footer/cookie-policy-pt/cookie-policy-pt.component';
import { PrivacyPolicyPtComponent } from './components/footer/privacy-policy-pt/privacy-policy-pt.component';
import { TosPtComponent } from './components/footer/tos-pt/tos-pt.component';
import { EmailUnsubscribeComponent } from './components/email-unsubscribe/email-unsubscribe.component';
import { EmailUnsubscribeService } from './services/email-unsubscribe.service';
import { PrivacyPolicyPtBrComponent } from './components/footer/privacy-policy-pt-br/privacy-policy-pt-br.component';
import { CookiePolicyPtBrComponent } from './components/footer/cookie-policy-pt-br/cookie-policy-pt-br.component';
import { TosPtBrComponent } from './components/footer/tos-pt-br/tos-pt-br.component';
import {
  PaymentHeaderAuthenticationComponent
} from './components/payment/payment-header-authentication/payment-header-authentication.component';
import {
  AdditionalAuthenticationDialogComponent
} from './components/payment/additional-authentication/additional-authentication-dialog/additional-authentication-dialog.component';
import { Upgrade3dsIssueDialogComponent } from './components/payment/upgrade-3ds-issue-dialog/upgrade-3ds-issue-dialog.component';
import { CouponThanksComponent } from './components/coupon/thanks/thanks.component';
import { CouponComponent } from './components/coupon/coupon.component';
import { PromotionCouponsService } from './services/payment/promotion.coupons.service';
import { NewValueComponent } from './components/coupon/promotions/new-value/new-value.component';
import {
  SupervisorProfileInviteDialogComponent
} from './components/supervision/profile/invite-dialog/supervisor-profile-invite-dialog.component';
import {
  SupervisorProfileInvitationComponent
} from './components/supervision/profile/invitation/supervisor-profile-invitation.component';
import {
  SupervisorProfileInviteConfirmDialogComponent
} from './components/supervision/profile/invitation/confirm-dialog/supervisor-profile-invite-confirm-dialog.component';
import { DataMapComponent } from './components/data-map/data-map.component';
import { LocalizedDatePipe } from './pipes/localized-date-pipe';
import { LocalizedCurrencyPipe } from './pipes/localized-currency-pipe';
import { DatePipe, CurrencyPipe } from '@angular/common';
import { ActionsListComponent } from './components/actions/list/list.component';
import { ActionDetailsDialogComponent } from './components/actions/list/details/details-dialog.component';
import { EventPathComponent } from './components/actions/list/event-path/event-path.component';
import { AuditsService } from './services/audits/audits.service';
import { AuditListComponent } from './components/audit/list/list.component';
import { AuditFormComponent } from './components/audit/form/form.component';
import { AuditFormCategoryComponent } from './components/audit/form/components/category/category.component';
import { AuditFormQuestionComponent } from './components/audit/form/components/question/question.component';
import { AuditFormQuestionDefaultComponent } from './components/audit/form/components/question/default/default.component';
import { AuditFormQuestionSplitComponent } from './components/audit/form/components/question/split/split.component';
import { AuditFormQuestionMultiselectComponent } from './components/audit/form/components/question/multiselect/multiselect.component';
import { AuditFormQuestionInputComponent } from './components/audit/form/components/question/input/input.component';
import { AuditListNewDialogComponent } from './components/audit/list/new/new.dialog.component';
import { AuditPrepareComponent } from './components/audit/prepare/prepare.component';
import { AuditCheckComponent } from './components/audit/check/check.component';
import { AuditWelcomeComponent } from './components/audit/welcome/welcome.component';
import { AuditReportComponent } from './components/audit/report/report.component';
import { ChecklistService } from './services/checklists/checklists.service';
import { ChecklistComponent } from './components/checklist/checklist.component';
import { NeatActionBarComponent } from './components/neat-action-bar/neat-action-bar.component';
import { AuditFormQuestionInputFieldsComponent } from './components/audit/form/components/question/input-fields/input-fields.component';
import { TrafficLightSelectorComponent } from './components/traffic-light-selector/traffic-light-selector.component';
import { ChangeAuditTypeDialogComponent } from './components/audit/form/change-type-dialog/change-type-dialog.component';
import { DisplayJurisdictionComponent } from './components/display-jurisdiction/display-jurisdiction.component';
import { HelpPopupDialogComponent } from './components/help-popup/help-popup.component';
import { HelpPopupDirective } from './components/help-popup/help-popup.directive';
import {
  SupervisionClientIdDialogComponent
} from './components/supervision/client-details/supervision-client-id-dialog/supervision-client-id-dialog.component';
import { SelectUserComponent } from './components/select-user/select-user.component';
import {
  SupervisionClientNotesDialogComponent
} from './components/supervision/client-details/supervision-client-notes-dialog/supervision-client-notes-dialog.component';
import { WelcomeWorkflowComponent } from './components/welcome/workflow/workflow.component';
import {
  SupervisionClientNextMeetingDialogComponent
} from './components/supervision/client-details/supervision-client-next-meeting-dialog/supervision-client-next-meeting-dialog.component';
import { UserProfileComponent } from './components/user-profile/user-profile.component';
import { AvatarComponent } from './components/user-profile/avatar/avatar.component';
import { EditSupervisorDialogComponent } from './components/supervision/profile/edit-supervisor-dialog/edit-supervisor-dialog.component';
import { ConfirmSupervisorRemovalDialogComponent } from './components/supervision/profile/edit-supervisor-dialog/confirm-supervisor-removal-dialog/confirm-supervisor-removal-dialog.component';
import { RevokeSupervisorInviteDialogComponent } from './components/supervision/profile/revoke-supervisor-invite-dialog/revoke-supervisor-invite-dialog.component';
import { AuditErrorDialogComponent } from './components/audit/form/components/error-dialog/error-dialog.component';
import { UserNameComponent } from './components/user-profile/user-name/user-name.component';
import { ClientAccessUserDialogComponent } from './components/supervision/client-access/client-access-user-dialog/client-access-user-dialog.component';
import { DisplayLanguageComponent } from './components/display-language/display-language.component';
import { DeleteUserDialogComponent } from './components/user-management/delete-user-dialog/delete-user-dialog.component';
import { SupervisionExpiredComponent } from './components/supervision/supervision-expired/supervision-expired.component';
import { AddApplicationDialogComponent } from './components/applications/add-application-dialog/add-application-dialog.component';
import { SearchApplicationDialogComponent } from './components/applications/search-application-dialog/search-application-dialog.component';
import { ClientApplicationsComponent } from './components/supervision/applications/client-applications/client-applications.component';
import { ClientApplicationsDetailComponent } from './components/supervision/applications/detail/detail.component';
import { ApplicationInputComponent } from './components/applications/input/input.component';
import { DuplicatePaComponent } from './components/rpa/duplicate/duplicate.component';
import { DsarSettings } from './services/dsar/dsar-settings.service';
import { DsarSettingsDialogComponent } from './components/dsar/settings-dialog/settings-dialog.component';
import { DsarLinkCopiedDialogComponent } from './components/dsar/settings-dialog/dsar-link-copied-dialog/dsar-link-copied-dialog.component';
import { DsarLinkRegenConfirmDialogComponent } from './components/dsar/settings-dialog/dsar-link-regen-confirm-dialog/dsar-link-regen-confirm-dialog.component';
import { SubmitDsarComponent } from './components/dsar/submit-dsar/submit-dsar.component';
import { AddDemoClientComponent } from './components/supervision/add-demo-client/add-demo-client.component';
import { FullDpiaEditRiskComponent } from './components/rpa/pa-details/full-dpia/edit-risk/edit-risk.component';
import { FullDpiaEditMeasureComponent } from './components/rpa/pa-details/full-dpia/edit-measure/edit-measure.component';
import { PaNameDialogComponent } from './components/rpa/pa-details/pa-name-dialog/pa-name-dialog.component';
import { NewPaDialogComponent } from './components/rpa/new-pa-dialog/new-pa-dialog.component';
import { RpaReferenceService } from './services/rpa/rpa.reference.service';
import { PaReferenceTreeInputComponent } from './components/rpa/reference-tree/pa-reference-tree-input/pa-reference-tree-input.component';
import { PaReferenceTreeSelectDialogComponent } from './components/rpa/reference-tree/pa-reference-tree-select-dialog/pa-reference-tree-select-dialog.component';
import { PaReferenceTreeEditDialogComponent } from './components/rpa/reference-tree/pa-reference-tree-edit-dialog/pa-reference-tree-edit-dialog.component';
import { PaReferenceTreeNodeComponent } from './components/rpa/reference-tree/pa-reference-tree-node/pa-reference-tree-node.component';
import { StarterPaTreeNodeComponent } from './components/rpa/starter-pas/starter-pa-tree-node/starter-pa-tree-node.component';
import { StarterPaComponent } from './components/rpa/starter-pas/starter-pa/starter-pa.component';
import { StarterPasComponent } from './components/rpa/starter-pas/starter-pas.component';
import { StarterPasCreateDialogComponent } from './components/rpa/starter-pas/starter-pas-create-dialog/starter-pas-create-dialog.component';
import { VendorAuditComponent } from './components/vendors/audit/audit.component';
import { VendorAuditService } from './services/vendors/vendor-audit.service';
import { AuditDocumentUploadComponent } from './components/audit/form/components/question/extensions/document-upload/document-upload.component';
import { DocumentGroupContainerComponent } from './components/documents/group-container/group-container.component';
import { DocumentGroupContainerDialogComponent } from './components/documents/group-container/dialog/dialog.component';
import { VendorListComponent } from './components/vendors/list/list.component';
import { TodosListComponent } from './components/todos/todos-list/todos-list.component';
import { TodoDetailsDialogComponent } from './components/todos/todo-details-dialog/todo-details-dialog.component';
import { CanCreateTodoComponent } from './components/todos/todoable/todoable.component';
import { SubjectTodoListComponent } from './components/todos/subject-todo-list/subject-todo-list.component';
import { TodoCardComponent } from './components/todos/todo-card/todo-card.component';
import { TodosComponent } from './components/todos/todos.component';
import { TodoDialogComponent } from './components/todos/todo-dialog/todo-dialog.component';
import { DpiaAssistantComponent } from './components/rpa/dpia-assistant/dpia-assistant.component';
import { MeasureRecommendationDialogComponent } from './components/rpa/dpia-assistant/measure-recommendation-dialog/measure-recommendation-dialog.component';
import { DpiaService } from './services/rpa/dpia.service';
import { DpiaNecessityDialogComponent } from './components/rpa/pa-details/risk-analysis/dpia-necessity-dialog/dpia-necessity-dialog.component';
import { LocalFeatureAccessComponent } from './components/product-features/local-feature-access/access.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { AssigneeSearchComponent } from './components/todos/assignee-search/assignee-search.component';
import { TodoEntryComponent } from './components/todos/todo-entry/todo-entry.component';
import { DeleteDsarComponent } from './components/dsar/records/delete-confirm-dialog/delete.component';
import { DeleteIncidentComponent } from './components/incident/records/delete-confirm-dialog/delete.component';
import { MixpanelModule } from './modules/mixpanel/mixpanel.module';
import { QRCodeModule } from 'angularx-qrcode';
import { ClientLimitWarningDialogComponent } from './components/supervision/client-limit-warning-dialog/client-limit-warning-dialog.component';
import { SetupTwoFactorComponent } from './components/user-profile/two-factor/setup/setup.component';
import { DisableTwoFactorComponent } from './components/user-profile/two-factor/disable/disable.component';
import { TwoFactorInfoComponent } from './components/user-profile/two-factor/info/info.component';
import { TwoFactorInputDialogComponent } from './components/login/two-factor-input-dialog/two-factor-input-dialog.component';
import { SupervisorDocumentsAdminListComponent } from './components/documents/supervisor/admin-list/admin-list.component';
import { SupervisorDocumentsClientListComponent } from './components/documents/supervisor/client-list/client-list.component';
import { ChooseDateDialogComponent } from './components/rpa/pa-details/choose-date-dialog/choose-date-dialog.component';
import { AccessControlComponent } from './components/access-control/access-control.component';
import { GroupListService } from './services/supervision/group-list.service';
import { CreateGroupDialogComponent } from './components/supervision/group-list/create-group-dialog/create-group-dialog.component';
import { GroupAddClientDialogComponent } from './components/supervision/group-list/group-add-client-dialog/group-add-client-dialog.component';
import { DeleteGroupDialogComponent } from './components/supervision/group-list/delete-group-dialog/delete-group-dialog.component';
import { LiaDialogComponent } from './components/rpa/lia-dialog/lia-dialog.component';
import { DisplayEnvironmentComponent } from './components/display-environment/display-environment.component';
import { DuplicateToControllerComponent } from './components/rpa/duplicate/to-controller/to-controller.component';
import { UpdateEmailDialogComponent } from './components/user-profile/update-email/update-email.component';
import { RemoveVendorConfirmationComponent } from './components/vendors/dpas/dpa-dialog/remove-vendor-confirmation/remove-vendor-confirmation.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { DashboardTodoListComponent } from './components/dashboard/todo-list/todo-list.component';
import { DocumentListComponent } from './components/documents/list/list.component';
import { SharedDocumentsComponent } from './components/documents/shared/shared.component';
import { ShareDocumentDialogComponent } from './components/documents/shared/share-dialog/share-dialog.component';
import { isNotCustomerInstance } from './util/environment';
import { DepartmentSuggestionDialogComponent } from './components/rpa/pa-details/department-suggestion/department-suggestion.component';
import { DataRecipientsComponent } from './components/data-recipients/data-recipients.component';
import { RecipientProcessingActivitiesComponent } from './components/data-recipients/recipient-processing-activities/recipient-processing-activities.component';
import { RecipientDpaComponent } from './components/data-recipients/recipient-dpa/recipient-dpa.component';
import { VendorLibraryComponent } from './components/vendors/library/library.component';
import { ListComponent } from './components/applications/list/list.component';
import { ControllerApplicationListComponent } from './components/applications/controller-applications/controller-applications.component';
import { VendorInputComponent } from './components/vendors/input/input.component';
import { SelectPathComponent } from './components/documents/upload/select-path/select-path.component';
import { SelectPathDialogComponent } from './components/documents/upload/select-path/select-path-dialog/select-path-dialog.component';
import {MatTreeModule} from '@angular/material/tree';
import { SelectPathNewFolderComponent } from './components/documents/upload/select-path/select-path-dialog/new-folder/new-folder.component';
import { DashboardMetricsComponent } from './components/dashboard/metrics/metrics.component';
import { PaRiskDistributionChartComponent } from './components/dashboard/metrics/items/pa-risk-distribution-chart/pa-risk-distribution-chart.component';
import { ActionsTimeseriesChartComponent } from './components/dashboard/metrics/items/actions-timeseries/actions-timeseries.component';
import { PathTreeComponent } from './components/documents/upload/select-path/path-tree/path-tree.component';
import { YourDpoComponent } from './components/dashboard/metrics/items/your-dpo/your-dpo.component';
import { PaDepartmentChartComponent } from './components/dashboard/metrics/items/pa-department-chart/pa-department-chart.component';
import { PaDataCategoriesMostUsedComponent } from './components/dashboard/metrics/items/pa-data-categories-most-used/pa-data-categories-most-used.component';
import { PaLegalBasisMostUsedComponent } from './components/dashboard/metrics/items/pa-legal-basis-most-used/pa-legal-basis-most-used.component';
import { AuthorityComponent } from './components/organisation/authority/authority.component';
import { EditAuditTitleDialogComponent } from './components/audit/form/edit-audit-title-dialog/edit-audit-title-dialog.component';
import { PickAuthorityDialogComponent } from './components/organisation/authority/pick-authority-dialog/pick-authority-dialog.component';
import { DashboardMeasuresComponent } from './components/dashboard/measures/measures.component';
import {MeasuresListComponent} from "./components/measures/list/list.component";
import {MatSortModule} from "@angular/material/sort";
import { CreateMeasureDialogComponent } from './components/measures/create-measure-dialog/create-measure-dialog.component';
import { SupervisorMeasuresComponent } from './components/measures/supervisor-measures/supervisor-measures.component';
import { ImportMeasuresDialogComponent } from './components/measures/import-measures-dialog/import-measures-dialog.component';
import {PlausibleModule} from "./modules/plausible/plausible.module";
import { MeasuresTableComponent } from './components/measures/list/table/measures-table.component';
import {MatMomentDateModule, MomentDateAdapter} from "@angular/material-moment-adapter";
import { ReportIncidentComponent } from './components/incident/report-incident/report-incident.component';
import { FormSettingsComponent } from './components/incident/form-settings/form-settings.component';

export const httpLoaderFactory = (http: HttpClient) => new TranslateHttpLoader(http);

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HeaderComponent,
    FooterComponent,
    PageNotFoundComponent,
    RegistrationComponent,
    RegistrationDialogComponent,
    VerificationComponent,
    LoginComponent,
    LoginDialogComponent,
    EmailLoginComponent,
    DialogComponent,
    BasicInfoComponent,
    InfoRpaComponent,
    PaFormComponent,
    PaListComponent,
    PaSelectionComponent,
    ReportComponent,
    ChangePasswordComponent,
    ChangePasswordDialogComponent,
    ForgotPasswordComponent,
    EmailNotificationComponent,
    ProfilePictureComponent,
    ImprintComponent,
    UserExpiredComponent,
    UnderConstructionComponent,
    CustomPaComponent,
    LanguageComponent,
    SideNavComponent,
    TodolistComponent,
    ProgressIndicatorComponent,
    DepartmentsComponent,
    EditDepartmentDialogComponent,
    StepperComponent,
    StepComponent,
    ControllerComponent,
    CoolUploadComponent,
    DpGoalsComponent,
    DeclareDpoComponent,
    MilestoneOneComponent,
    BigRoundButtonComponent,
    OrganisationComponent,
    RpaComponent,
    RpaTutorialComponent,
    PaAssigningComponent,
    ApplicableComponent,
    PaDetailsComponent,
    PaInternalProcessorsComponent,
    PaExternalProcessorsComponent,
    VendorSearchDialogComponent,
    VendorAddDialogComponent,
    CreateSubscriptionComponent,
    ThankYouComponent,
    BillingComponent,
    MilestoneTwoComponent,
    DpaDialogComponent,
    DpaRequestEmailSentDialogComponent,
    OfferDpaComponent,
    AuthTokenLoginComponent,
    UserManagementComponent,
    EditUserDialogComponent,
    InviteUserDialogComponent,
    ManageInviteDialogComponent,
    InvitationResultDialogComponent,
    ConfirmInvitationComponent,
    PrivacyPolicyEnComponent,
    PrivacyPolicyDeComponent,
    TosDeComponent,
    TosEnComponent,
    CookiePolicyDeComponent,
    CookiePolicyEnComponent,
    TodosCompleteComponent,
    TomsComponent,
    TomsCategoryComponent,
    BigRoundCheckboxComponent,
    GetBadgeComponent,
    CopiedDialogComponent,
    BadgeInfoComponent,
    IsProcessorComponent,
    SupervisionClientListComponent,
    AddClientDialogComponent,
    ConfirmSupervisionInvitationComponent,
    DeleteClientDialogComponent,
    SupervisionClientDetailsComponent,
    CommentBoxComponent,
    CommentBoxDialogComponent,
    UpdatePaymentDetailsComponent,
    SupervisorProfileComponent,
    WebsiteComplianceComponent,
    DsarTemplatesComponent,
    AddClientEmailComponent,
    AddClientControllerComponent,
    DocumentsMiscListComponent,
    DocumentsUploadDialogComponent,
    DeleteDocumentDialogComponent,
    DocumentTaglistDialogComponent,
    GeneratorDashboardComponent,
    FilesDashboardComponent,
    ApplyTemplateDialogComponent,
    CreateTemplateDialogComponent,
    SelectTemplateDialogComponent,
    TemplateInfoDialogComponent,
    DeleteTemplateDialogComponent,
    IncidentTemplatesComponent,
    IncidentRecordsComponent,
    IncidentRecordDetailsComponent,
    IncidentsFilterDepartmentDialogComponent,
    IncidentDpoDetailsDialogComponent,
    IncidentsFilterTitleDialogComponent,
    AddIncidentDialogComponent,
    ActionsComponent,
    CdkDetailRowDirective,
    ActionsMetadataUsersComponent,
    ActionsMetadataDepartmentComponent,
    ActionsMetadataDocumentComponent,
    ActionsMetadataRpaProcessorsComponent,
    ActionsMetadataRpaComponent,
    ChangelogComponent,
    ActionsMetadataVendorsComponent,
    ActionsMetadataTomsComponent,
    DsarRecordsComponent,
    DsarFilterDepartmentDialogComponent,
    DsarFilterTitleDialogComponent,
    DsarRecordDetailsComponent,
    DocumentsContainerComponent,
    PaymentHeaderTimerComponent,
    RiskAnalysisRiskDialogComponent,
    RiskAnalysisSeverityDialogComponent,
    LockedFeatureComponent,
    FeatureAccessComponent,
    CookiePolicyPtComponent,
    PrivacyPolicyPtComponent,
    TosPtComponent,
    EmailUnsubscribeComponent,
    PrivacyPolicyPtBrComponent,
    CookiePolicyPtBrComponent,
    TosPtBrComponent,
    AdditionalPaymentAuthenticationComponent,
    PaymentHeaderAuthenticationComponent,
    AdditionalAuthenticationDialogComponent,
    Upgrade3dsIssueDialogComponent,
    CouponComponent,
    CouponThanksComponent,
    NewValueComponent,
    SupervisorProfileInviteDialogComponent,
    SupervisorProfileInvitationComponent,
    SupervisorProfileInviteConfirmDialogComponent,
    DataMapComponent,
    LocalizedDatePipe,
    LocalizedCurrencyPipe,
    ActionsListComponent,
    ActionDetailsDialogComponent,
    EventPathComponent,
    AuditListComponent,
    AuditFormComponent,
    AuditFormCategoryComponent,
    AuditFormQuestionComponent,
    AuditFormQuestionDefaultComponent,
    AuditFormQuestionSplitComponent,
    AuditFormQuestionMultiselectComponent,
    AuditFormQuestionInputComponent,
    AuditListNewDialogComponent,
    AuditWelcomeComponent,
    AuditPrepareComponent,
    AuditCheckComponent,
    AuditReportComponent,
    ChecklistComponent,
    NeatActionBarComponent,
    AuditFormQuestionInputFieldsComponent,
    TrafficLightSelectorComponent,
    ChangeAuditTypeDialogComponent,
    DisplayJurisdictionComponent,
    HelpPopupDialogComponent,
    HelpPopupDirective,
    SupervisionClientIdDialogComponent,
    SelectUserComponent,
    SupervisionClientNotesDialogComponent,
    WelcomeWorkflowComponent,
    SupervisionClientNextMeetingDialogComponent,
    UserProfileComponent,
    AvatarComponent,
    EditSupervisorDialogComponent,
    ConfirmSupervisorRemovalDialogComponent,
    RevokeSupervisorInviteDialogComponent,
    AuditErrorDialogComponent,
    UserNameComponent,
    ClientAccessUserDialogComponent,
    DisplayLanguageComponent,
    DeleteUserDialogComponent,
    SupervisionExpiredComponent,
    AddApplicationDialogComponent,
    SearchApplicationDialogComponent,
    ClientApplicationsComponent,
    ClientApplicationsDetailComponent,
    ApplicationInputComponent,
    DuplicatePaComponent,
    DsarSettingsDialogComponent,
    DsarLinkCopiedDialogComponent,
    DsarLinkRegenConfirmDialogComponent,
    SubmitDsarComponent,
    AddDemoClientComponent,
    FullDpiaEditRiskComponent,
    FullDpiaEditMeasureComponent,
    PaNameDialogComponent,
    NewPaDialogComponent,
    PaReferenceTreeInputComponent,
    PaReferenceTreeSelectDialogComponent,
    PaReferenceTreeEditDialogComponent,
    PaReferenceTreeNodeComponent,
    StarterPaTreeNodeComponent,
    StarterPaComponent,
    StarterPasComponent,
    StarterPasCreateDialogComponent,
    VendorAuditComponent,
    AuditDocumentUploadComponent,
    DocumentGroupContainerComponent,
    DocumentGroupContainerDialogComponent,
    VendorListComponent,
    MetricsComponent,
    TodosListComponent,
    TodoDetailsDialogComponent,
    CanCreateTodoComponent,
    SubjectTodoListComponent,
    TodoCardComponent,
    TodosComponent,
    TodoDialogComponent,
    MetricsComponent,
    DpiaAssistantComponent,
    MeasureRecommendationDialogComponent,
    DpiaNecessityDialogComponent,
    LocalFeatureAccessComponent,
    AssigneeSearchComponent,
    TodoEntryComponent,
    DeleteDsarComponent,
    DeleteIncidentComponent,
    ClientLimitWarningDialogComponent,
    SetupTwoFactorComponent,
    DisableTwoFactorComponent,
    TwoFactorInfoComponent,
    TwoFactorInputDialogComponent,
    SupervisorDocumentsAdminListComponent,
    SupervisorDocumentsClientListComponent,
    ChooseDateDialogComponent,
    AccessControlComponent,
    LiaDialogComponent,
    CreateGroupDialogComponent,
    GroupAddClientDialogComponent,
    DeleteGroupDialogComponent,
    DisplayEnvironmentComponent,
    DuplicateToControllerComponent,
    UpdateEmailDialogComponent,
    RemoveVendorConfirmationComponent,
    DepartmentSuggestionDialogComponent,
    DataRecipientsComponent,
    RecipientProcessingActivitiesComponent,
    RecipientDpaComponent,
    VendorLibraryComponent,
    ListComponent,
    ControllerApplicationListComponent,
    VendorInputComponent,
    DashboardComponent,
    DashboardTodoListComponent,
    SharedDocumentsComponent,
    DocumentListComponent,
    SharedDocumentsComponent,
    ShareDocumentDialogComponent,
    SelectPathComponent,
    SelectPathDialogComponent,
    SelectPathNewFolderComponent,
    DashboardMetricsComponent,
    PaRiskDistributionChartComponent,
    ActionsTimeseriesChartComponent,
    PathTreeComponent,
    YourDpoComponent,
    PaDepartmentChartComponent,
    PaDataCategoriesMostUsedComponent,
    PaLegalBasisMostUsedComponent,
    AuthorityComponent,
    EditAuditTitleDialogComponent,
    PickAuthorityDialogComponent,
    DashboardMeasuresComponent,
    MeasuresListComponent,
    CreateMeasureDialogComponent,
    SupervisorMeasuresComponent,
    ImportMeasuresDialogComponent,
    MeasuresTableComponent,
    ReportIncidentComponent,
    FormSettingsComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    MatProgressBarModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    RoutingConfig,
    MatDialogModule,
    MatButtonModule,
    MatInputModule,
    MatListModule,
    MatSelectModule,
    MatCardModule,
    MatToolbarModule,
    MatTableModule,
    MatSortModule,
    MatRadioModule,
    MatTooltipModule,
    MatMenuModule,
    MatIconModule,
    MatCheckboxModule,
    MatChipsModule,
    MatExpansionModule,
    FlexLayoutModule,
    NgxFileDropModule,
    //    ChartsModule,
    NgxChartsModule,
    MarkdownModule.forRoot(),
    MatProgressSpinnerModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSnackBarModule,
    MatSlideToggleModule,
    MatSliderModule,
    MatTabsModule,
    MatSidenavModule,
    ClipboardModule,
    MentionModule,
    MatPaginatorModule,
    RecaptchaModule,

    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    CrispModule.forRoot(),
    ((isNotCustomerInstance) ? NgxStripeModule.forRoot(AppConfig.stripePublishable) : []),
    NgSelectModule,
    ScrollingModule,
    MatAutocompleteModule,
    MixpanelModule.forRoot(),
    PlausibleModule.forRoot(),
    QRCodeModule,
    MatTreeModule,
    MatRippleModule,
    MatMomentDateModule
    // CdkTableModule,
    // DataSource
    // ,
    // OverlayContainer
  ],
  providers: [
    // Guards
    AuthenticationGuard,
    NotAuthenticatedGuard,
    ProductTypeGuard,
    ProductFeaturesGuard,
    SelfSignUpGuard,

    // Interlayer
    AuthenticationService,
    BasicInfoGetService,
    BasicInfoPostService,
    RpaGetAllService,
    RpaGetService,
    RpaPostService,
    RpaUpdateStatusService,
    ReportGetService,
    ChangePasswordService,
    EmailNotificationService,
    ProfilePictureService,

    // Services
    ControllerService,
    RequestService,
    BusService,
    EventsService,
    AuthenticationRequestService,
    EmailAuthService,
    ForgotPasswordService,
    SendVerificationEmailService,
    RegistrationRequestService,
    ValidationService,
    VerificationService,
    AdminBroadcastService,
    EmailCheckService,
    CustomPaService,
    DepartmentService,
    WorkflowTasksService,
    UploadService,
    ControllerService,
    _ControllerService,
    DpoService,
    RpaService,
    CrispService,
    RpaInternallyProcessedService,
    RpaExternallyProcessedService,
    UserService,
    VendorsService,
    PaymentSubscriptionService,
    PaymentPlanGetService,
    PaymentCouponGetService,
    PaymentInfoGetLatestService,
    PaymentInfoGetSubscriptionDetailsService,
    PaymentUpgradePreviewGetService,
    PaymentUpgradePostService,
    UpdatePaymentDetailsService,
    UserManagementService,
    AccessLevelService,
    TomsService,
    BadgeService,
    RpasOnBehalfService,
    ClientManagementService,
    SupervisionClientInvitationService,
    SupervisionClientTokenService,
    ClientSupervisionService,
    SupervisionTemplatesService,
    SupervisionTemplatesBackendService,
    CommentService,
    SupervisorProfileService,
    DocumentResourceService,
    ActionsService,
    DsarResourceService,
    IncidentsResourceService,
    ProductFeaturesService,
    EmailUnsubscribeService,
    PromotionCouponsService,
    AuditsService,
    ChecklistService,
    RpaReferenceService,
    DsarSettings,
    VendorAuditService,
    DpiaService,
    GroupListService,

    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE]
    },

    // Interceptors
    {
      provide: HTTP_INTERCEPTORS,
      useClass: I18nInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthenticationInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ExpiryInterceptor,
      multi: true,
    },

    DatePipe,
    LocalizedDatePipe,
    CurrencyPipe,
    LocalizedCurrencyPipe
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    DialogComponent,
    RegistrationDialogComponent,
    LoginDialogComponent,
    ChangePasswordDialogComponent,
    EditDepartmentDialogComponent,
    VendorSearchDialogComponent,
    VendorAddDialogComponent,
    DpaDialogComponent,
    DpaRequestEmailSentDialogComponent,
    EditUserDialogComponent,
    InviteUserDialogComponent,
    ManageInviteDialogComponent,
    InvitationResultDialogComponent,
    CopiedDialogComponent,
    AddClientDialogComponent,
    DeleteClientDialogComponent,
    CommentBoxDialogComponent,
    AddClientEmailComponent,
    AddClientControllerComponent,
    DocumentsUploadDialogComponent,
    DeleteDocumentDialogComponent,
    DocumentTaglistDialogComponent,
    ApplyTemplateDialogComponent,
    CreateTemplateDialogComponent,
    SelectTemplateDialogComponent,
    TemplateInfoDialogComponent,
    DeleteTemplateDialogComponent,
    IncidentsFilterDepartmentDialogComponent,
    IncidentDpoDetailsDialogComponent,
    IncidentsFilterTitleDialogComponent,
    AddIncidentDialogComponent,
    DsarFilterDepartmentDialogComponent,
    DsarFilterTitleDialogComponent,
    RiskAnalysisRiskDialogComponent,
    RiskAnalysisSeverityDialogComponent,
    AdditionalAuthenticationDialogComponent,
    Upgrade3dsIssueDialogComponent,
    SupervisorProfileInviteDialogComponent,
    SupervisorProfileInviteConfirmDialogComponent,
    ActionDetailsDialogComponent,
    AuditListNewDialogComponent,
    SupervisionClientIdDialogComponent,
    SupervisionClientNextMeetingDialogComponent,
    SelectUserComponent,
    EditSupervisorDialogComponent,
    ConfirmSupervisorRemovalDialogComponent,
    RevokeSupervisorInviteDialogComponent,
    DeleteUserDialogComponent,
    DsarSettingsDialogComponent,
    DsarLinkCopiedDialogComponent,
    DsarLinkRegenConfirmDialogComponent,
    PaNameDialogComponent,
    NewPaDialogComponent,
    PaReferenceTreeSelectDialogComponent,
    PaReferenceTreeEditDialogComponent,
    PickAuthorityDialogComponent,
  ]
})

export class AppModule {
  constructor(
    private overlayContainer: OverlayContainer,
    // Request Service
    private authenticationService: AuthenticationService,
    // Interlayer
    private authenticationRequestService: AuthenticationRequestService,
    private emailAuthService: EmailAuthService,
    private snedVerificationEmailService: SendVerificationEmailService,
    private forgotPasswordService: ForgotPasswordService,
    private registrationRequestService: RegistrationRequestService,
    private validationService: ValidationService,
    private verificationService: VerificationService,
    private basicInfoGetService: BasicInfoGetService,
    private basicInfoPostService: BasicInfoPostService,
    private rpaGetAllService: RpaGetAllService,
    private rpaGetService: RpaGetService,
    private rpaPostService: RpaPostService,
    private rpaUpdateStatusService: RpaUpdateStatusService,
    private reportGetService: ReportGetService,
    private changePasswordService: ChangePasswordService,
    private emailNotificationService: EmailNotificationService,
    private profilePictureService: ProfilePictureService,
    private adminBroadcastService: AdminBroadcastService,
    private customPaService: CustomPaService,
    private departmentService: DepartmentService,
    private workflowTasksService: WorkflowTasksService,
    private uploadService: UploadService,
    private controllerService: ControllerService,
    private _controllerService: _ControllerService,
    private dpoService: DpoService,
    private rpaService: RpaService,
    private rpaInternallyProcessedService: RpaInternallyProcessedService,
    private rpaExternallyProcessedService: RpaExternallyProcessedService,
    private userService: UserService,
    private vendorsService: VendorsService,
    private paymentSubscriptionService: PaymentSubscriptionService,
    private paymentPlanGetService: PaymentPlanGetService,
    private paymentCouponGetService: PaymentCouponGetService,
    private paymentInfoGetLatestService: PaymentInfoGetLatestService,
    private paymentInfoGetSubscriptionDetailsService: PaymentInfoGetSubscriptionDetailsService,
    private paymentUpgradePreviewGetService: PaymentUpgradePreviewGetService,
    private paymentUpgradePostService: PaymentUpgradePostService,
    private userManagementService: UserManagementService,
    private accessLevelService: AccessLevelService,
    private tomsService: TomsService,
    private badgeService: BadgeService,
    private rpasOnBehalfService: RpasOnBehalfService,
    private supervisionTemplatesService: SupervisionTemplatesService,
    private supervisionTemplatesBackendService: SupervisionTemplatesBackendService,
    private commentService: CommentService,
    private documentService: DocumentResourceService,
    private auditsService: AuditsService,
    // private paramsService: ParamsService,
  ) {
    overlayContainer.getContainerElement().classList.add('unicorn-dark-theme');
  }
}

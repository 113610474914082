<div class="top-bar">
  <mat-menu #appMenu="matMenu">
    <button mat-menu-item matTooltip="{{'commentBox.dialog.tooltip' | translate}}" (click)="close()">
      {{'commentBox.dialog.close' | translate}}
    </button>
    <button *ngIf="isSupervisor" mat-menu-item matTooltip="{{'commentBox.dialog.deleteConversation.tooltip' | translate}}" (click)="deleteConversation()">
      {{'commentBox.dialog.deleteConversation' | translate}}
    </button>
  </mat-menu>

  <button mat-icon-button [matMenuTriggerFor]="appMenu" *ngIf="subject.open" tabindex="-1">
    <mat-icon>more_horiz</mat-icon>
  </button>

  <button mat-icon-button *ngIf="subject.reminder"
    matTooltip="{{'commentBox.dialog.reminder.clear' | translate}}"
    (click)="resolveReminder()"
    tabindex="-1">
    <mat-icon color="primary">bookmark</mat-icon>
  </button>

  <button mat-icon-button mat-dialog-close matTooltip="{{'word.close' | translate}}" tabindex="-1">
    <mat-icon>close</mat-icon>
  </button>
</div>
<h1 class="title" mat-dialog-title>
  {{'commentBox.dialog.titlePrefix' | translate}}
  {{subject.title}}
</h1>
<br>
<mat-dialog-content>
  <div class="comments" #comments>
    <div class="comment" *ngFor="let comment of subject.comments">

      <mat-menu #moreMenu="matMenu">
        <button mat-button matTooltip="{{'commentBox.dialog.deleteComment.hint' | translate}}" (click)="deleteComment(comment)">
          {{'commentBox.dialog.deleteComment' | translate}}
        </button>
      </mat-menu>

      <ng-container *ngIf="comment.type == commentTypes.text">
        <div class="header">
          <div class="icon">
            <user-avatar [user]="author(comment)"></user-avatar>
          </div>
          <div class="stuff">
            <div class="name">
              <user-name [user]="author(comment)"></user-name>
            </div>
            <div class="date">{{comment.date | localizedDate:'medium'}}</div>
          </div>
          <button matPrefix mat-icon-button class="more-menu-icon" [matMenuTriggerFor]="moreMenu" tabindex="-1">
            <mat-icon>more_horiz</mat-icon>
          </button>
        </div>
        <div class="text">
          <!-- {{ 'commentBox.dialog.commentDeleted' | translate }} -->
          {{comment.text | translate}}
        </div>
      </ng-container>
      <ng-container *ngIf="comment.type == commentTypes.close">
        <div class="header">
          <div class="icon">
            <mat-icon>block</mat-icon>
          </div>
          <div class="stuff">
            <div class="name">
              {{'commentBox.dialog.msgClosed.part1' | translate}}
              <user-name [user]="author(comment)"></user-name>
              {{'commentBox.dialog.msgClosed.part2' | translate}}
            </div>
            <div class="date">{{comment.date | localizedDate:'medium'}}</div>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="comment.type == commentTypes.reminder">
        <div class="header">
          <div class="icon">
            <mat-icon>bookmark</mat-icon>
          </div>
          <div class="stuff">
            <div class="name">
              {{'commentBox.dialog.msgReminder.set.part1' | translate}}
              <user-name [user]="author(comment)"></user-name>
              {{'commentBox.dialog.msgReminder.set.part2' | translate}}
            </div>
            <div class="date">{{comment.date | localizedDate:'medium'}}</div>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="comment.type == commentTypes.reminderResolved">
        <div class="header">
          <div class="icon">
            <mat-icon>bookmark_border</mat-icon>
          </div>
          <div class="stuff">
            <div class="name">
              {{'commentBox.dialog.msgReminder.cleared.part1' | translate}}
              <user-name [user]="author(comment)"></user-name>
              {{'commentBox.dialog.msgReminder.cleared.part2' | translate}}
            </div>
            <div class="date">{{comment.date | localizedDate:'medium'}}</div>
          </div>
        </div>
      </ng-container>
      <br>
      <mat-divider></mat-divider>
      <br>
    </div>
  </div>
</mat-dialog-content>
<br>
<mat-form-field>
  <!-- {{ 'commentBox.dialog.placeholder.comment' | translate }} -->
  <!-- {{ 'commentBox.dialog.placeholder.reply' | translate }} -->
  <textarea #commentBox mwlTextInputElement matInput matTextareaAutosize [(ngModel)]="comment.text"
    placeholder="{{(subject.open?'commentBox.dialog.placeholder.reply':'commentBox.dialog.placeholder.comment') | translate}}"
    (keypress)="keypress($event)"
    [mention]="mentionables" [mentionConfig]="mentionConfig"
    tabindex="0"></textarea>
  <mat-hint>
    {{'commentBox.dialog.mentionHint' | translate}}
  </mat-hint>

  <button matSuffix mat-button mat-icon-button color="primary" [disabled]="!comment.text" (click)="send()" class="send">
    <mat-icon>send</mat-icon>
  </button>
</mat-form-field>

<h1 mat-dialog-title>
  {{'supervision.deleteClient.title' | translate}}
</h1>

<mat-dialog-content>
  {{'supervision.deleteClient.message' | translate}}
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button color="warn" (click)="delete()">
    {{'supervision.deleteClient.action' | translate}}
  </button>
  <button mat-button mat-dialog-close>
    {{'word.cancel' | translate}}
  </button>
</mat-dialog-actions>

import { Inject, Component, OnInit, OnDestroy, EventEmitter } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { TemplatingEvent, TemplateAbstractionEvent, TemplateApplicationEvent,
        CreatingTemplateEvent, TemplateCreatedEvent,
        UpdatingTemplateEvent, TemplateUpdatedEvent,
        ApplyingTemplateEvent, TemplateAppliedEvent,
      } from '../events';

@Component({
  selector: 'app-template-info-dialog',
  templateUrl: './template-info-dialog.component.html',
  styleUrls: ['./template-info-dialog.component.scss']
})
export class TemplateInfoDialogComponent implements OnInit {

  private handler: any;

  constructor(
    private dialogRef: MatDialogRef<TemplateInfoDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: {
      event: TemplatingEvent,
      emitter: EventEmitter<any>
    }
  ) {
  }

  ngOnInit() {
    if (this.data.emitter) {
      this.handler = this.data.emitter.subscribe(event => {
        this.data.event = event;
      });
    }
  }

  ngOnDestroy() {
    if (this.handler) this.handler.unsubscribe();
  }

  public get abstraction(): boolean { return this.data.event instanceof TemplateAbstractionEvent; }
  public get application(): boolean { return this.data.event instanceof TemplateApplicationEvent; }
  
  public get creating(): boolean { return this.data.event instanceof CreatingTemplateEvent; }
  public get created(): boolean { return this.data.event instanceof TemplateCreatedEvent; }
  public get updating(): boolean { return this.data.event instanceof UpdatingTemplateEvent; }
  public get updated(): boolean { return this.data.event instanceof TemplateUpdatedEvent; }
  public get applying(): boolean { return this.data.event instanceof ApplyingTemplateEvent; }
  public get applied(): boolean { return this.data.event instanceof TemplateAppliedEvent; }
}

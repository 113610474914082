<div class="contents">
  <h1 class="title">
    {{ "applications.title" | translate }}
  </h1>

  <p>
    {{ "applications.message" | translate }}
  </p>

  <app-application-list [applications]="applications"></app-application-list>
</div>

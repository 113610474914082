<div class="contents">
  <ng-container *ngIf="controller && !wrongUser">
    <h1 class="title">
      {{'user-management.confirm-invite.title.part1' | translate}}
      {{controller.controllerName}}
      {{'user-management.confirm-invite.title.part2' | translate}}
    </h1>
    <br><br><br><br>
    <p>
      {{'user-management.confirm-invite.message.part1' | translate}}
      <a>{{controller.controllerName}}</a>
      {{'user-management.confirm-invite.message.part2' | translate}}
      <a>{{controller.controllerName}}</a>{{'user-management.confirm-invite.message.part3' | translate}}
    </p>
    <p class="controller-warning">
      <span innerHTML="{{'user-management.confirm-invite.warning.part1' | translate}}"></span>
      {{controller.controllerName}}{{'user-management.confirm-invite.warning.part2' | translate}}
    </p>
    <br>
    <br>
    <mat-divider></mat-divider>
    <br><br>
    <div class="buttons" (click)="confirm()">
      <button mat-raised-button color="primary">
        {{'user-management.confirm-invite.action.part1' | translate}}
        {{controller.controllerName}}
        {{'user-management.confirm-invite.action.part2' | translate}}
      </button>
    </div>
  </ng-container>

  <ng-container *ngIf="!controller">
    <mat-card>
      <img mat-card-image src="/assets/not-found.svg"/>
      <mat-card-title>
        {{'vendors.offerDpa.badToken.title' | translate}}
      </mat-card-title>
      <mat-card-content>
        <span innerHtml="{{'vendors.offerDpa.badToken.message' | translate}}"></span>
      </mat-card-content>
    </mat-card>
  </ng-container>

  <ng-container *ngIf="wrongUser">
    <h1 class="title">
      {{'user-management.confirm-invite.wrong-user.title' | translate}}
    </h1>
    <br>
    <p>
      {{'user-management.confirm-invite.wrong-user.message' | translate}}
    </p>
    <br><br>
    <button mat-button color="primary" (click)="logout()">
      {{'user-management.confirm-invite.wrong-user.action' | translate}}
    </button>
  </ng-container>
</div>

<div class="email-notification">
  <div class="container">
    <mat-card>
      <form>
        <h1>Email Notification</h1>
        <mat-radio-group [(ngModel)]="permission" name="permission" (change)="submit()">
          <mat-radio-button value="true">
            Yes
          </mat-radio-button>
          <mat-radio-button value="false">
            No
          </mat-radio-button>
        </mat-radio-group>
      </form>
    </mat-card>
  </div>
</div>

import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { BusService } from 'app/services/bus.service';
import { EventsService } from 'app/services/events.service';


@Component({
  selector: 'app-payment-thank-you',
  templateUrl: './thank-you.component.html',
  styleUrls: ['./thank-you.component.scss']
})
export class ThankYouComponent implements OnInit, OnDestroy {
  constructor(
    private bus: BusService,
    private events: EventsService,
  ) {
  }

  private subscribe() {

  }

  ngOnInit() {
    this.subscribe();
  }

  ngOnDestroy() {

  }
}

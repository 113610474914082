import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ControllerService } from 'app/services/controller/controller.service';

@Component({
  selector: 'app-supervision-client-notes-dialog',
  templateUrl: './supervision-client-notes-dialog.component.html',
  styleUrls: ['./supervision-client-notes-dialog.component.scss']
})
export class SupervisionClientNotesDialogComponent implements OnInit {

  private ogNotes;

  constructor(
    @Inject(MAT_DIALOG_DATA) public controller: any,
    private ref: MatDialogRef<SupervisionClientNotesDialogComponent>,
    private service: ControllerService,
  ) { }

  ngOnInit(): void {
    this.ogNotes = this.controller.clientNotes;
    this.ref.beforeClosed().subscribe(saved => {
      if (!saved) {
        this.controller.clientNotes = this.ogNotes;
      }
    });
  }

  submit() {
    this.service.requestPut(this.controller).subscribe(
      () => { this.ref.close(true); },
      () => {
        this.ref.close();
      }
    );
  }
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { share } from 'rxjs/operators';


@Injectable()
export class EmailUnsubscribeService {
  constructor(private client: HttpClient) {}

  public unsubscribe(url, token) {
    return this.client.post(url, {
      unsub_token: token
    }).pipe(share());
  }
}

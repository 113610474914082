import { Injectable } from '@angular/core';
import { BusService } from '../bus.service';
import { EventsService } from '../events.service';
import { RequestService } from '../request.service';
import { AppConfig } from 'app/app.config';
import { MatDialog } from '@angular/material/dialog';
import { AuditErrorDialogComponent } from '../../components/audit/form/components/error-dialog/error-dialog.component';
import { AuditLikeService } from '../../components/audit/form/util';
import { getToken } from '../../util/token';
import {zip} from "rxjs";
import {TodoDialogComponent} from "../../components/todos/todo-dialog/todo-dialog.component";
import {TranslateService} from "@ngx-translate/core";

interface ConditionChildren {
  [key: string]: Question[];
}

interface AuditAnswers {
  [key: string]: string;
}

interface SeverityType {
  level: number;
  name: string;
  order: number;
}

export interface AuditType {
  type: string;
  name: string;
  description?: string;
  caption?: string;
  order: number;
  language: string;
  jurisdiction: string;
  hidden: boolean | null;
}

export interface Question {
  id: string;
  question: string;
  questionSubtext?: string;
  type: string;
  children: Question[];
  choices?: string[];
  conditionChildren?: ConditionChildren;
  childCondition?: boolean;
  extensions?: string[];
}

export type CategoryContent = Category[] | Question[];

export type AuditKind = 'Audit' | 'VendorAudit';

export interface Category {
  categoryName: string;
  categoryDisplay: string;
  content: CategoryContent;
}

export interface Audit {
  id: number;
  title: string;
  auditType: string;
  updatedAt: Date;
  createdAt: Date;
  userId?: number;
  controllerId: number;
  answers?: AuditAnswers;
  status: string;
  statusChangedAt: Date;
  reportDoc?: number;
}

export interface AuditConfiguration {
  severityTypes?: SeverityType[];
  inputFields: string[];
  auditKind: AuditKind;
}

export interface AuditQuestionnaire {
  severityTypes?: SeverityType[];
  inputFields: string[];
  language: string;
  jurisdiction: string;
  description?: string;
  questions: Category[];
  auditKind: AuditKind;
  displayName: string;
}

export const SESSION_SUPPRESS_FORM_ERRORS = 'audit_suppress_errors';

@Injectable()
export class AuditsService implements AuditLikeService {
  private api = {
    root: '/audits/',
    questions: '/questions',
    report: '/report',
    reportDoc: '/report-doc',
    answer: '/answer',
    types: 'types',
    latest: 'latest'
  };

  constructor(
    private bus: BusService,
    private events: EventsService,
    private requestService: RequestService,
    private dialog: MatDialog,
    private translate: TranslateService
  ) {
    this.subscribe();
  }

  handleFieldError(error: Error, payload: { fieldName: string }) {
    console.error('handling field error with following error: ', error);
    console.error('field name:', payload.fieldName);

    if (!sessionStorage.getItem(SESSION_SUPPRESS_FORM_ERRORS)) {
      this.dialog.open(AuditErrorDialogComponent, { width: '600px', maxHeight: '90vh' }).afterClosed().subscribe((keepShowing) => {
        if (!keepShowing) {
          sessionStorage.setItem(SESSION_SUPPRESS_FORM_ERRORS, 'true');
        }
      });
    } else {
      console.log('user asked to not show errors for this session, therefore not showing any');
    }
  }

  getAllAudits() {
    return this.requestService.get<[Audit]>({
      uri: this.api.root,
      handlers: {
        success: (all) =>
          this.bus.publish(this.events.received.data.audits.all.success, all),
        error: (error) =>
          this.bus.publish(
            this.events.received.data.audits.all.failure,
            error.error
          ),
      },
    });
  }

  getAuditTypes() {
    return this.requestService.get<[AuditType]>({
      uri: `${this.api.root}${this.api.types}`,
      handlers: {
        success: (all) =>
          this.bus.publish(this.events.received.data.audits.types.success, all),
        error: (error) =>
          this.bus.publish(
            this.events.received.data.audits.types.failure,
            error.error
          ),
      },
    });
  }

  getAuditById(id: number) {
    return this.requestService.get<Audit>({
      uri: this.api.root + id,
      handlers: {
        success: (all) =>
          this.bus.publish(
            this.events.received.data.audits.single.success,
            all
          ),
        error: (error) =>
          this.bus.publish(
            this.events.received.data.audits.single.failure,
            error.error
          ),
      },
    });
  }

  updateAudit(id: number, update: Audit) {
    return this.requestService.patch<Audit, Audit>({
      uri: `${this.api.root}${id}`,
      body: update,
      handlers: {
        success: (all) =>
          this.bus.publish(
            this.events.received.data.audits.update.success,
            all
          ),
        error: (error) =>
          this.bus.publish(
            this.events.received.data.audits.update.failure,
            error.error
          ),
      },
    });
  }

  duplicateAudit(audit: Audit) {
    return this.requestService.post<{ auditId: number }, Audit>({
      uri: `${this.api.root}/duplicate`,
      body: {
        auditId: audit.id
      }
    });
  }

  deleteAudit(audit: Audit) {
    return this.requestService.delete({
      uri: `${this.api.root}${audit.id}`,
    });
  }

  getLatestAudit() {
    return this.requestService.get<Audit>({
      uri: this.api.root + this.api.latest,
      handlers: {
        success: (all) =>
          this.bus.publish(
            this.events.received.data.audits.single.success,
            all
          ),
        error: (error) =>
          this.bus.publish(
            this.events.received.data.audits.single.failure,
            error.error
          ),
      },
    });
  }

  getQuestionsForAuditById(id: number) {
    return this.requestService.get<AuditQuestionnaire>({
      uri: `${this.api.root}${id}${this.api.questions}`,
      handlers: {
        success: (all) =>
          this.bus.publish(
            this.events.received.data.audits.questions.success,
            all
          ),
        error: (error) =>
          this.bus.publish(
            this.events.received.data.audits.questions.failure,
            error.error
          ),
      },
    });
  }

  addAudit(type: string) {
    return this.requestService.post<{ type: string }, Audit>({
      uri: this.api.root,
      body: { type },
      handlers: {
        success: (all) =>
          this.bus.publish(
            this.events.received.data.audits.add.success,
            all
          ),
        error: (error) =>
          this.bus.publish(
            this.events.received.data.audits.add.failure,
            error.error
          ),
      },
    });
  }

  updateAnswerField<T>(auditId: number, fieldName: string, value: T) {
    return this.requestService.put<{ value: T }, Audit>({
      uri: `${this.api.root}${auditId}${this.api.answer}/${fieldName}`,
      body: { value },
      handlers: {
        success: () =>
          this.bus.publish(
            this.events.received.data.audits.answer.success,
            { auditId, fieldName, value }
          ),
        error: (error) =>
          this.bus.publish(
            this.events.received.data.audits.answer.failure,
            error.error
          ),
      },
    });
  }

  delegateAudit(audit: Audit) {
    const $title = this.translate.get('audits.form.delegate.title');
    const $description = this.translate.get('audits.form.delegate.description');
    const subject = `audit-${audit.id}`;

    zip(
      $title,
      $description
    )
      .subscribe(([title, description]) => {
        this.dialog.open(TodoDialogComponent, {
          width: '700px',
          data: {
            suggestedTitle: `${title}`,
            suggestedDescription: description,
            overrideLink: `/audit/${audit.id}`,
            subject: subject,
            link: true,
            fromField: true
          }
        })
          .afterClosed()
          .subscribe((todoCreated) => {
            if (todoCreated) {

            }
          });
      });
  }

  auditDownloadLink(auditId: number) {
    return `${AppConfig.apiUrl}${this.api.root}${auditId}${this.api.report}?token=${getToken()}`;
  }

  auditUploadDocLink(auditId: number) {
    return `${this.api.root}${auditId}${this.api.reportDoc}`;
  }

  subscribe() {
    this.bus.subscribe(
      this.events.requested.data.audits.all,
      this.getAllAudits.bind(this)
    );
    this.bus.subscribe(
      this.events.requested.data.audits.types,
      this.getAuditTypes.bind(this)
    );
    this.bus.subscribe(
      this.events.requested.data.audits.single,
      this.getAuditById.bind(this)
    );
    this.bus.subscribe(
      this.events.requested.data.audits.questions,
      this.getQuestionsForAuditById.bind(this)
    );
    this.bus.subscribe(
        this.events.requested.data.audits.add,
        this.addAudit.bind(this)
      );
    this.bus.subscribe(
      this.events.requested.data.audits.answer,
      this.updateAnswerField.bind(this)
    );
  }
}

import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';

import { EventsService } from '../../../services/events.service';
import { BusService } from '../../../services/bus.service';
import { AuthenticationService } from "../../../services/authentication.service";
import { AccessLevelService, AccessLevel } from '../../../services/user/access-level.service';
import { WorkflowTasksService } from '../../../services/workflow-tasks/workflow-tasks.service';


@Component({
  selector: 'app-pa-internal-processors',
  templateUrl: './pa-internal-processors.component.html',
  styleUrls: ['./pa-internal-processors.component.scss']
})
export class PaInternalProcessorsComponent implements OnInit, OnDestroy {

  processingActivities = [];
  departments = [];
  associations = [];
  selectedPa = undefined;
  showOther = false;

  accessLevel: AccessLevel;

  constructor(
    private bus: BusService,
    private events: EventsService,
    private auth: AuthenticationService,
    private router: Router,
    private accessService: AccessLevelService,
    private tasks: WorkflowTasksService,
  ) {
    this.accessService.checkAccess({
      context: 'pa-internal-processors',
    }).subscribe(response => this.accessLevel = response);
  }

  ngOnInit() {
    this.subscribe();
    this.bus.publish(this.events.requested.data.user.info);
    this.bus.publish(this.events.requested.data.departments.get);
    this.bus.publish(this.events.requested.data.rpa.internallyProcessed.pas);
    this.bus.publish(this.events.requested.data.rpa.internallyProcessed.associations);
  }

  ngOnDestroy() {
    this.unsubscribe();
  }

  resolved(pa) {
    return this.associations.some(entry => entry.paId == pa.paId);
  }

  get resolvedCount(): number {
    return this.processingActivities.filter(pa => this.resolved(pa)).length;
  }

  get done(): boolean {
    return this.resolvedCount == this.processingActivities.length;
  }

  updateDepartments(data) {
    this.departments = data;
  }

  updateProcessingActivities(data) {
    this.processingActivities = data;
  }

  updateAssociations(data) {
    this.associations = data;
  }

  get myActivities() {
    return this.processingActivities
      .filter(activity => activity.assignedDepartment == this.auth.departmentId);
  }

  get otherActivities() {
    return this.processingActivities
      .filter(activity => activity.assignedDepartment != this.auth.departmentId);
  }

  public get canWrite() {
    return this.accessLevel && this.accessLevel.write;
  }

  canChange(pa) {
    return this.canWrite || pa.assignedDepartment == this.auth.departmentId;
  }

  responsibleDepartment(pa) {
    return this.departments.filter(dep => dep.id == pa.assignedDepartment)[0];
  }

  nonResponsibleDepartments(pa) {
    return this.departments.filter(dep => dep.id != pa.assignedDepartment);
  }

  isAssociated(department, pa) {
    return this.associations.some(entry => entry.paId == pa.paId && entry.departmentId == department.id);
  }

  toggleAssociation(department, pa) {
    if (this.isAssociated(department, pa)) this.disassociate(department, pa);
    else this.associate(department, pa);
  }

  associate(department, pa) {
    if (this.canChange(pa)) {
      if (!this.isAssociated(department, pa)) {
        this.bus.publish(this.events.requested.data.rpa.internallyProcessed.associate, {
          paId: pa.paId,
          departmentId: department.id,
        });
      }
    }
  }

  associated(event) {
    this.associations.push({paId : event.paId, departmentId: event.departmentId});
  }

  disassociate(department, pa) {
    if (this.canChange(pa)) {
      if (this.isAssociated(department, pa)) {
        this.bus.publish(this.events.requested.data.rpa.internallyProcessed.disassociate, {
          paId: pa.paId,
          departmentId: department.id,
        });
      }
    }
  }

  disassociated(event) {
    this.associations =
      this.associations
        .filter(entry => entry.paId != event.paId || entry.departmentId != event.departmentId);
  }

  bypass() {
    if (this.processingActivities.length == 0) {
      this.bus.publish(this.events.requested.action.tasks.progressControllerTask, {
        progress: 1,
        workflowCodename: 'path_to_gdpr',
        taskCodename: 'ptg_pa_internal_processors',
      });
    }
  }

  bypassed() {
    this.router.navigate([""]);
  }

  finish() {
    this.tasks.progressControllerTask({
      progress: 1,
      workflowCodename: 'path_to_gdpr',
      taskCodename: 'ptg_pa_internal_processors',
    }).subscribe(() => {
      this.router.navigate(['']);
    });
  }

  subscribe() {
    this.bus.subscribe(this.events.received.data.departments.get.success, this.updateDepartments, this);
    this.bus.subscribe(this.events.received.data.rpa.internallyProcessed.pas.success, this.updateProcessingActivities, this);
    this.bus.subscribe(this.events.received.data.rpa.internallyProcessed.associations.success, this.updateAssociations, this);
    this.bus.subscribe(this.events.received.data.rpa.internallyProcessed.associate.success, this.associated, this);
    this.bus.subscribe(this.events.received.data.rpa.internallyProcessed.disassociate.success, this.disassociated, this);

    this.bus.subscribe(this.events.received.action.tasks.progressControllerTask.success, this.bypassed, this);
  }

  unsubscribe() {
    this.bus.unsubscribe(this.events.received.data.departments.get.success, this.updateDepartments);
    this.bus.unsubscribe(this.events.received.data.rpa.internallyProcessed.pas.success, this.updateProcessingActivities);
    this.bus.unsubscribe(this.events.received.data.rpa.internallyProcessed.associations.success, this.updateAssociations);
    this.bus.unsubscribe(this.events.received.data.rpa.internallyProcessed.associate.success, this.associated);
    this.bus.unsubscribe(this.events.received.data.rpa.internallyProcessed.disassociate.success, this.disassociated);

    this.bus.unsubscribe(this.events.received.action.tasks.progressControllerTask.success, this.bypassed);
  }
}

/*
Attention: For all regards in charizard and user display, these things are called 'Events'.
Unfortunately, we already had an internal event manager in Angular which is why this component is called 'actions'...
*/

import { Injectable } from '@angular/core';

import { HttpErrorResponse } from '@angular/common/http';
import { BusService } from '../bus.service';
import { EventsService } from '../events.service';
import {
  IGetOptions,
  IPostOptions,
  RequestService
} from '../request.service';


@Injectable()
export class ActionsService {

  api = {
    root: '/events/',
    records: '/events/records/'
  };

  constructor(
    private bus: BusService,
    private events: EventsService,
    private requestService: RequestService
  ) {
    this.subscribe();
  }

  getRangedActions(limitCount: number, lastTimestamp: Date, endDate?: Date) {
    let params = "?count=" + limitCount + "&start=" + lastTimestamp.toISOString();

    if (endDate) {
      params = params + "&end=" + endDate.toISOString();
    }

    console.log(params);

    this.requestService.get<any>({
      uri: this.api.root + 'ranged' + params,
      handlers: {
        success: all => this.bus.publish(this.events.received.data.actions.ranged.success, all),
        error: error => this.bus.publish(this.events.received.data.actions.ranged.failure, error.error),
      }
    });
  }

  getAllActions() {
    return this.requestService.get<any>({
      uri: this.api.root,
      handlers: {
        success: all => this.bus.publish(this.events.received.data.actions.all.success, all),
        error: error => this.bus.publish(this.events.received.data.actions.all.failure, error.error),
      }
    });
  }

  getActionsByRecord(id: number) {
    this.requestService.get<any>({
      uri: this.api.records + id,
      handlers: {
        success: all => this.bus.publish(this.events.received.data.actions.forRecords.success, all),
        error: error => this.bus.publish(this.events.received.data.actions.forRecords.failure, error.error),
      }
    });
  }

  getActionsForPas() {
    return this.requestService.get<any>({
      uri: this.api.root + 'processing-activity',
    });
  }

  getAction(id: number) {
    this.requestService.get<any>({
      uri: this.api.root + id,
      handlers: {
        success: (action) => this.bus.publish(this.events.received.data.actions.single.success, action),
        error: error => this.bus.publish(this.events.received.data.actions.single.failure, error.error),
      }
    });
  }

  subscribe() {
    this.bus.subscribe(this.events.requested.data.actions.all, this.getAllActions.bind(this));
    this.bus.subscribe(this.events.requested.data.actions.ranged, this.getRangedActions.bind(this));
    this.bus.subscribe(this.events.requested.data.actions.single, this.getAction.bind(this));
    this.bus.subscribe(this.events.requested.data.actions.forRecords, this.getActionsByRecord.bind(this));
  }
}

<div class="contents">
  <br/><br/>
  <h1 class="title">
    {{'audit.welcome.title' | translate}}
  </h1>
  <br><br>
  <p>
    {{'audit.welcome.hint' | translate}}
  </p>
  <mat-card class="company-card">
    <div class="company-logo"
        commentable
        comment-box-position="bottom"
        comment-subject-codename="controller-logo"
        comment-subject-title="{{ 'controller.placeholder.controllerLogo' | translate }}">
      <todoable position="bottom" subject="controller-logo" suggested-title="{{ 'controller.placeholder.controllerLogo' | translate }}"></todoable>

      <cool-upload #upload
        upload-url="/controllers/logo"
        upload-key="logo"
        (uploaded)="logoUpdated()"
        [preview]="true">
        <span hint>
          {{'audit.welcome.logo.placeholder' | translate}}
        </span>
      </cool-upload>
    </div>

    <div class="company-name"
      commentable
      comment-subject-codename="controller-name"
      comment-subject-title="{{ 'controller.placeholder.controllerName' | translate }}">
      <todoable subject="controller-name" suggested-title="{{ 'controller.placeholder.controllerName' | translate }}"></todoable>

      <div class="inner">
        <mat-form-field>
          <input matInput placeholder="{{'audit.welcome.companyName.placeholder' | translate}}"
                          name="name" type="text"
                          [formControl]="companyName" required autofocus/>
          <mat-error *ngIf="companyName.hasError('required')">
            {{'audit.welcome.companyName.errors.required' | translate}}
            <!-- Client name is required! -->
          </mat-error>
        </mat-form-field>

        <mat-card-actions align="end">
          <button
            mat-raised-button
            color="primary"
            (click)="submit()"
            [disabled]="upload.uploading||!companyName.valid">
            {{'audit.welcome.actions.done' | translate}}
          </button>
        </mat-card-actions>
      </div>
    </div>
  </mat-card>
</div>

export default class RegistrationModel {
  public firstName = '';
  public lastName = '';
  public company = '';
  public password = '';
  public email = '';
  public tos = false;
  public heardAboutUsHow = '';

  public inviteToken?: string;
  public clientInviteToken?: string;
  public coSupervisionInviteToken?: string;
  public registrationToken?: string;
}

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../../services/authentication.service';
import { BusService } from '../../../services/bus.service';
import { EventsService } from '../../../services/events.service';

@Component({
  selector: 'app-supervision-expired',
  templateUrl: './supervision-expired.component.html',
  styleUrls: ['./supervision-expired.component.scss']
})
export class SupervisionExpiredComponent implements OnInit {

  constructor(
    private router: Router,
    private bus: BusService,
    private events: EventsService,
    private auth: AuthenticationService,
  ) { }

  ngOnInit(): void {
  }

  logout() {
    let email = '' + this.auth.email; // yep to clone it.

    this.bus.publish(this.events.notified.user.logout);
    this.router.navigate(['/login'], {queryParams: {email: email}});
  }

}

<h1 mat-dialog-title>
  {{'pa.fullDpia.measures.edit' | translate}}
</h1>

<mat-dialog-content>
  <form [formGroup]="formGroup">
    <div class="risk">
      <mat-form-field>
        <input matInput placeholder="{{ 'pa.fullDpia.measures.risk.placeholder' | translate }}"
          formControlName="risk" />
        <mat-hint>{{'pa.fullDpia.measures.risk.tip' | translate}}</mat-hint>
      </mat-form-field>
    </div>
    <div class="description">
      <mat-form-field>
        <input matInput placeholder="{{ 'pa.fullDpia.measures.description.placeholder' | translate }}"
          formControlName="description" />
        <mat-hint>{{'pa.fullDpia.measures.description.tip' | translate}}</mat-hint>
      </mat-form-field>
    </div>
    <div class="settings">
      <mat-form-field>
        <mat-select placeholder="{{'pa.fullDpia.measures.effect.placeholder' | translate}}"
          formControlName="effect">
          <mat-option value="eliminated">{{'pa.fullDpia.measures.effect.eliminated' | translate}}</mat-option>
          <mat-option value="reduced">{{'pa.fullDpia.measures.effect.reduced' | translate}}</mat-option>
          <mat-option value="accepted">{{'pa.fullDpia.measures.effect.accepted' | translate}}</mat-option>
        </mat-select>
        <mat-hint>{{'pa.fullDpia.measures.effect.tip' | translate}}</mat-hint>
      </mat-form-field>
      <mat-form-field>
        <mat-select placeholder="{{'pa.fullDpia.measures.residualRisk.placeholder' | translate}}"
          formControlName="residualRisk">
          <mat-option value="low">{{'pa.fullDpia.risks.risk.low' | translate}}</mat-option>
          <mat-option value="medium">{{'pa.fullDpia.risks.risk.medium' | translate}}</mat-option>
          <mat-option value="high">{{'pa.fullDpia.risks.risk.high' | translate}}</mat-option>
        </mat-select>
        <mat-hint>{{'pa.fullDpia.measures.residualRisk.tip' | translate}}</mat-hint>
      </mat-form-field>
    </div>
  </form>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close tabindex="-1">{{'word.cancel' | translate}}</button>
  <button mat-flat-button color="primary" (click)="done()">{{'word.done' | translate}}</button>
</mat-dialog-actions>

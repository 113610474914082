import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { BusService } from '../../../../services/bus.service';
import { EventsService } from '../../../../services/events.service';

import { Router } from '@angular/router';
import { HttpResponse } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { DepartmentService } from 'app/services/departments/department.service';
import { IncidentsResourceService } from 'app/services/incidents/incidents-resource.service';

@Component({
  selector: 'app-incidents-records-dpo-details-dialog',
  templateUrl: './dpo-details-dialog.component.html',
  styleUrls: ['./dpo-details-dialog.component.scss']
})
export class IncidentDpoDetailsDialogComponent implements OnInit, OnDestroy {
  measuresTaken = new FormControl('', []);
  dpoNotice = new FormControl('', []);
  closeIncident = true;

  incidentId: number = undefined;

  isExistingIncident = false;

  incident;

  constructor(
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<IncidentDpoDetailsDialogComponent>,
    private bus: BusService,
    private events: EventsService,
    private router: Router,
    private incidentService: IncidentsResourceService,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private translate: TranslateService,
    private departmentService: DepartmentService
  ) {
    this.setExistingData();
  }

  setExistingData() {
    if (this.data) {
      this.measuresTaken.setValue(this.data.measuresTaken);
      this.dpoNotice.setValue(this.data.dpoNotice);
      this.closeIncident = true;

      this.isExistingIncident = true;
      this.incidentId = this.data.id;

      this.incident = this.data;
      // this.reportingDepartmentId = this.data.reportingDepartmentId;
    }
  }

  ngOnInit() {
    this.subscribe();
  }

  ngOnDestroy() {
    this.unsubscribe();
  }

  updateDocument(response: HttpResponse<any>) {
    if (response.ok) {
    }
  }

  update() {
    let status = 'open';
    if (this.closeIncident) {
      status = 'closed';
    }

    this.bus.publish(this.events.requested.action.incidents.update, {
      id: this.incidentId,
      title: this.data.title,
      description: this.data.description,
      measuresTaken: this.measuresTaken.value,
      dpoNotice: this.dpoNotice.value,
      happenedOn: this.data.happenedOn,
      reportedOn: this.data.reportedOn,
      reportingDepartmentId: this.data.reportingDepartmentId,
      status: status
    });
  }

  updated() {
    this.dialogRef.close(true);
  }

  created() {
    this.dialogRef.close(true);
  }

  updateFailed(error) {
    console.error(error);
  }

  createFailed(error) {
    console.error(error);
  }

  subscribe() {
    this.bus.subscribe(this.events.received.action.incidents.update.success, this.updated, this);
    this.bus.subscribe(this.events.received.action.incidents.update.failure, this.updateFailed, this);
  }

  unsubscribe() {
    this.bus.unsubscribe(this.events.received.action.incidents.update.success, this.updated);
    this.bus.unsubscribe(this.events.received.action.incidents.update.failure, this.updateFailed);
  }
}

import { Component, OnInit, OnDestroy, ViewChild, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BusService } from 'app/services/bus.service';
import { EventsService } from 'app/services/events.service';
import { ActionsService } from 'app/services/actions/actions.service';
import { UserManagementService } from 'app/services/user/user-management.service';
import { Event } from '../../actions.component';
import { DepartmentService } from 'app/services/departments/department.service';

@Component({
  selector: 'app-actions-metadata-department',
  templateUrl: './department.component.html',
  styleUrls: ['./department.component.scss']
})
export class ActionsMetadataDepartmentComponent implements OnInit {

  @Input() event: Event;
  department: any;

  constructor(
    private bus: BusService,
    private events: EventsService,
    private userService: UserManagementService,
    private departmentService: DepartmentService
  ) {
  }


  private receiveDepartments(departments) {
    this.department = departments.find((d) => d.id === parseInt(this.event.metadata.departmentId, 10));
  }

  ngOnInit() {
    this.bus.subscribe(this.events.received.data.departments.get.success, this.receiveDepartments, this);

    this.bus.publish(this.events.requested.data.departments.get);
  }
}

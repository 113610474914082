import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-help-popup',
  templateUrl: './help-popup.component.html',
  styleUrls: ['./help-popup.component.scss']
})
export class HelpPopupDialogComponent implements OnInit {

  content = '';

  constructor(
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<HelpPopupDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any
  ) { 
    this.content = this.data.contentTranslate;
  }

  ngOnInit(): void {
  }

}

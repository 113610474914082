<div class="content incidents-records">
  <h1 class="title">{{ 'incidents.records.title' | translate }}</h1>
  <br><br>
  <p>
    {{ 'incidents.records.hint' | translate }}
  </p>
  <br>
  <div class="hint">
    {{ 'incidents.records.settings.hint' | translate }}
    <button mat-button color="primary" (click)="openFormSettings()">
      <mat-icon>settings</mat-icon>
      {{ 'incidents.records.settings.action' | translate }}
    </button>
  </div>

  <div class="actions">
    <div class="buttons">
      <button mat-button color="primary" matTooltip="{{ 'incidents.records.exportHint' | translate }}"
        class="export-button" (click)="exportToExcel()">
        {{ 'incidents.records.exportExcel' | translate }}</button>
      <a mat-flat-button color="primary" routerLink="/incidents/records/create">{{ 'incidents.records.create' | translate }}</a>
    </div>
  </div>

  <div class="record-items">
    <mat-table #table [dataSource]="dataSource" matSort multiTemplateDataRows>
      <ng-container matColumnDef="happenedOn">
        <mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'incidents.records.table.happenedOn' | translate }}
        </mat-header-cell>
        <mat-cell *matCellDef="let record">
          <span *ngIf="record.status === 'recordable.open'" class="danger-indicator"></span>
          {{ (record.customFields?.incidentTime || record.receivedOn) | localizedDate }}
        </mat-cell>
      </ng-container>

      <!--
      <ng-container matColumnDef="status">
        <mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'incidents.records.table.status' | translate }} </mat-header-cell>
        <mat-cell *matCellDef="let record">{{ 'incidents.records.table.status.' + record.status | translate }}</mat-cell>
      </ng-container>
      -->

      <ng-container matColumnDef="title">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ 'incidents.records.table.title' | translate }}
          <button mat-icon-button [color]="titleFilter.value?'primary':'initial'" (click)="openTitleFilter()">
            <mat-icon>filter_list</mat-icon>
          </button>
        </mat-header-cell>
        <mat-cell *matCellDef="let record"><span [class.empty]="!record.title">{{ record.title ||
            'incidents.records.table.noTitle' | translate }}</span>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="description">
        <mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'incidents.records.table.description' | translate }}
        </mat-header-cell>
        <mat-cell *matCellDef="let record"> <span [class.empty]="!record.details">
            {{ (record.details?.length > 64 ? record.details.substr(0, 64) + ' ...' : record.details)
            || 'incidents.records.table.noDescription' | translate }}
          </span>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="department">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ 'incidents.records.table.department' | translate }}
          <!--<access-control level="dpo">-->
          <button mat-icon-button [color]="departmentFilter.value?'primary':'initial'" (click)="openDepartmentFilter()">
            <mat-icon>filter_list</mat-icon>
          </button>
          <!--</access-control>-->
        </mat-header-cell>
        <mat-cell *matCellDef="let record"> {{ getDepartment(record) }} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="user">
        <mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'incidents.records.table.user' | translate }}
        </mat-header-cell>
        <mat-cell *matCellDef="let record">
          {{ getUser(record) }}
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="columnsToDisplay"></mat-header-row>
      <mat-row *matRowDef="let row; columns: columnsToDisplay" matRipple class="element-row"
        [routerLink]="'/incidents/records/' + row.incidentId">
      </mat-row>
    </mat-table>
  </div>

  <div class="actions">
    <mat-paginator #paginator [pageSize]="20" [pageSizeOptions]="[10, 20, 50]" [showFirstLastButtons]="true">
    </mat-paginator>

  </div>
</div>

import { Component, OnInit } from '@angular/core';
import { TwoFactorService } from '../../../../services/user/two-factor.service';

@Component({
  selector: 'app-two-factor-info',
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.scss']
})
export class TwoFactorInfoComponent implements OnInit {

  enabled: Date = null;

  constructor(
    private twoFactor: TwoFactorService
  ) {
    this.twoFactor.getInfo()
      .subscribe((info) => {
        if (info) {
          this.enabled = info.setupDate;
        }
      });
  }

  ngOnInit(): void {
  }

}

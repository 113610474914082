import { Injectable } from '@angular/core';
import { IPostOptions, RequestService, IGetOptions } from '../../request.service';
import { BusService } from '../../bus.service';
import { EventsService } from '../../events.service';
import { HttpErrorResponse } from '@angular/common/http';
import { share } from 'rxjs/operators';

interface PaymentInfo {
  id: number;
  periodStart: Date;
  periodEnd: Date;
  subscriptionStatus: string;
  paymentInterval: string;
  planName: string;
}

@Injectable()
export class PaymentInfoGetLatestService {
  private options: IGetOptions<PaymentInfo>;

  constructor(
    private bus: BusService,
    private events: EventsService,
    private requestService: RequestService
  ) {
    this.options = {
      uri: '/payment/info/latest',
      handlers: {
        success: this.success.bind(this),
        error: this.error.bind(this)
      }
    };

    this.subscribe();
  }

  private subscribe(): void {
    this.bus.subscribe(this.events.requested.payment.info.latest, this.request.bind(this));
  }

  public request() {
    this.bus.publish(this.events.notified.request.starting);

    return this.requestService.get(this.options).pipe(share());
  }

  public success(data: PaymentInfo) {
    localStorage.setItem('planName', data.planName);
    this.bus.publish(this.events.received.payment.info.latest.success, data);
  }

  public error(httpErrorResponse: HttpErrorResponse) {
    this.bus.publish(this.events.received.payment.info.latest.failure, httpErrorResponse);
  }
}

<div class="contents">
  <h1 class="title">
    {{ 'organisation.title' | translate }}
  </h1>
  <br /><br />
  <p>
    {{ 'organisation.tip' | translate }}
  </p>

  <big-round-button routerLink="controller">
    <mat-icon button-icon color="primary">info_outline</mat-icon>
    <a button-text>
      {{ 'organisation.nav.controller' | translate }}
    </a>
  </big-round-button>

  <big-round-button routerLink="/audit/welcome">
    <mat-icon button-icon color="primary">photo</mat-icon>
    <a button-text>
      {{ 'organisation.nav.company-logo' | translate }}
    </a>
  </big-round-button>

  <big-round-button routerLink="dpo">
    <mat-icon button-icon color="primary">person</mat-icon>
    <a button-text>
      {{ 'organisation.nav.dpo' | translate }}
    </a>
  </big-round-button>

  <big-round-button routerLink="authority">
    <mat-icon button-icon color="primary">gavel</mat-icon>
    <a button-text>
      {{ 'organisation.nav.authority' | translate }}
    </a>
  </big-round-button>

  <big-round-button routerLink="dp-goals">
    <mat-icon button-icon color="primary">book</mat-icon>
    <a button-text>
      {{'organisation.nav.dpgoals' | translate }}
    </a>
  </big-round-button>


  <big-round-button routerLink="departments">
    <mat-icon button-icon color="primary">business</mat-icon>
    <a button-text>
      {{ 'organisation.nav.departments' | translate }}
    </a>
  </big-round-button>

  <big-round-button routerLink="/toms">
    <mat-icon button-icon color="primary">build</mat-icon>
    <a button-text>
      {{ 'organisation.nav.toms' | translate }}
    </a>
  </big-round-button>

  <big-round-button routerLink="/website">
    <mat-icon button-icon color="primary">language</mat-icon>
    <a button-text>
      {{ 'organisation.nav.website' | translate }}
    </a>
  </big-round-button>

  <display-environment not="vater">
    <big-round-button routerLink="/badge/get">
      <mat-icon button-icon color="primary">verified</mat-icon>
      <a button-text>
        {{ 'organisation.nav.badge' | translate }}
      </a>
    </big-round-button>
  </display-environment>
</div>

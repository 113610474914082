<div class="dpo">
  <h1 class="title"> {{ 'organisation.dpo.title' | translate}} </h1>
  <br />
  <div class="form-holder">
    <p>
      {{'organisation.dpo.message' | translate}}
    </p>
    <p *ngIf="accessLevel && !accessLevel.write" class="warning">
      <span>{{'word.NOTICE' | translate}}</span>
      {{'word.readonlyAccess' | translate}}
    </p>
    <form name="form" [formGroup]="formGroup" novalidate>
      <div commentable comment-subject-codename="dpo-name"
        comment-subject-title="{{'basicInfo.dpo.nameFull' | translate}}">
        <mat-form-field>
          <input matInput placeholder="{{ 'basicInfo.dpo.nameField.titel' | translate }}" [readonly]="!canWrite"
            type="text" (change)="submit('name')" formControlName="name" required autofocus />
          <mat-hint *ngIf="fieldsBeingSaved['name']">{{'basicInfo.fields.saving' | translate}}</mat-hint>
          <mat-hint *ngIf="!fieldsBeingSaved['name']">{{'basicInfo.dpo.nameField.hint' | translate}}</mat-hint>
          <mat-error *ngIf="formGroup.get('name').hasError('required')">
            {{'basicInfo.dpo.nameField.required' | translate}}
          </mat-error>
        </mat-form-field>
      </div>

      <div commentable comment-subject-codename="dpo-address-street"
        comment-subject-title="{{'basicInfo.dpo.streetFull' | translate}}">
        <mat-form-field>
          <input matInput placeholder="{{'basicInfo.dpo.streetField.titel' | translate}}" [readonly]="!canWrite"
            type="text" (change)="submit('addressStreet')" formControlName="addressStreet" required />
          <mat-hint *ngIf="fieldsBeingSaved['addressStreet']">{{'basicInfo.fields.saving' | translate}}</mat-hint>
          <mat-hint *ngIf="!fieldsBeingSaved['addressStreet']">{{'basicInfo.dpo.streetField.hint' | translate}}
          </mat-hint>
          <mat-error *ngIf="formGroup.get('addressStreet').hasError('required')">
            {{'basicInfo.dpo.streetField.required' | translate}}
          </mat-error>
        </mat-form-field>
      </div>

      <div commentable comment-subject-codename="dpo-address-postcode"
        comment-subject-title="{{'basicInfo.dpo.postcodeFull' | translate}}">
        <mat-form-field>
          <input matInput placeholder="{{'basicInfo.dpo.postCodeField.titel' | translate}}" [readonly]="!canWrite"
            type="text" (change)="submit('addressPostcode')" formControlName="addressPostcode" required />
          <mat-hint *ngIf="!fieldsBeingSaved['addressPostcode']">
            {{'basicInfo.dpo.postCodeField.hint' | translate}}
          </mat-hint>
          <mat-hint *ngIf="fieldsBeingSaved['addressPostcode']">{{'basicInfo.fields.saving' | translate}}</mat-hint>
          <mat-error *ngIf="formGroup.get('addressPostcode').hasError('required')">
            {{'basicInfo.dpo.postCodeField.required' | translate}}
          </mat-error>
        </mat-form-field>
      </div>

      <div commentable comment-subject-codename="dpo-address-city"
        comment-subject-title="{{'basicInfo.dpo.cityFull' | translate}}">
        <mat-form-field>
          <input matInput placeholder="{{'basicInfo.dpo.cityField.titel' | translate}}" [readonly]="!canWrite"
            type="text" (change)="submit('addressCity')" formControlName="addressCity" required />
          <mat-hint *ngIf="!fieldsBeingSaved['addressCity']">
            {{'basicInfo.dpo.cityField.hint' | translate}}
          </mat-hint>
          <mat-hint *ngIf="fieldsBeingSaved['addressCity']">{{'basicInfo.fields.saving' | translate}}</mat-hint>
          <mat-error *ngIf="formGroup.get('addressCity').hasError('required')">
            {{'basicInfo.dpo.cityField.required' | translate}}
          </mat-error>
        </mat-form-field>
      </div>

      <div commentable comment-subject-codename="dpo-address-country"
        comment-subject-title="{{'basicInfo.dpo.countryFull' | translate}}">
        <mat-form-field>
          <input matInput placeholder="{{'basicInfo.dpo.countryField.titel' | translate}}" [readonly]="!canWrite"
            type="text" (change)="submit('addressCountry')" formControlName="addressCountry" required />
          <mat-hint *ngIf="!fieldsBeingSaved['addressCountry']">
            {{'basicInfo.dpo.countryField.hint' | translate}}
          </mat-hint>
          <mat-hint *ngIf="fieldsBeingSaved['addressCountry']">{{'basicInfo.fields.saving' | translate}}</mat-hint>
          <mat-error *ngIf="formGroup.get('addressCountry').hasError('required')">
            {{'basicInfo.dpo.countryField.required' | translate}}
          </mat-error>
        </mat-form-field>
      </div>

      <div commentable comment-subject-codename="dpo-telephone"
        comment-subject-title="{{'basicInfo.dpo.telephoneFull' | translate}}">
        <mat-form-field>
          <input matInput placeholder="{{'basicInfo.dpo.phoneField.titel' | translate}}" [readonly]="!canWrite"
            type="text" (change)="submit('telephoneNumber')" formControlName="telephoneNumber" required />
          <mat-hint *ngIf="!fieldsBeingSaved['telephoneNumber']">
            {{'basicInfo.dpo.phoneField.hint' | translate}}
          </mat-hint>
          <mat-hint *ngIf="fieldsBeingSaved['telephoneNumber']">{{'basicInfo.fields.saving' | translate}}</mat-hint>
          <mat-error *ngIf="formGroup.get('telephoneNumber').hasError('required')">
            {{'basicInfo.dpo.phoneField.required' | translate}}
          </mat-error>
        </mat-form-field>
      </div>

      <div commentable comment-subject-codename="dpo-email"
        comment-subject-title="{{'basicInfo.dpo.emailFull' | translate}}">
        <mat-form-field>
          <input matInput placeholder="{{'basicInfo.dpo.emailField.titel' | translate}}" [readonly]="!canWrite"
            type="text" (change)="submit('email')" formControlName="email" required />
          <mat-hint *ngIf="!fieldsBeingSaved['email']">
            {{'basicInfo.dpo.emailField.hint' | translate}}
          </mat-hint>
          <mat-hint *ngIf="fieldsBeingSaved['email']">{{'basicInfo.fields.saving' | translate}}</mat-hint>
          <mat-error *ngIf="!formGroup.get('email').hasError('required')&&formGroup.get('email').hasError('email')">
            {{'basicInfo.dpo.emailField.invalid' | translate}}
          </mat-error>
          <mat-error *ngIf="formGroup.get('email').hasError('required')">
            {{'basicInfo.dpo.emailField.required' | translate}}
          </mat-error>
        </mat-form-field>
      </div>

      <div commentable comment-subject-codename="dpo-reported-to-authority"
           comment-subject-title="{{'basicInfo.dpo.reportedToAuthority' | translate}}">
        <mat-checkbox [disabled]="!canWrite" (change)="submit('reportedToAuthority')" formControlName="reportedToAuthority">
          {{'basicInfo.dpo.reportedToAuthority' | translate}}
        </mat-checkbox>
        <br />
        <mat-hint class="saving" *ngIf="fieldsBeingSaved['reportedToAuthority']">{{'basicInfo.fields.saving' | translate}}</mat-hint>
      </div>

      <div class="upload-holder" commentable comment-box-position="bottom-right"
        comment-subject-codename="dpo-appointment-doc"
        comment-subject-title="{{'basicInfo.dpo.appointmentDoc' | translate}}">
        <cool-upload #upload [readonly]="!canWrite" upload-url="/dpo/appointment" upload-key="doc">
          <span hint>
            {{'organisation.dpo.slide3.uploadAppointmentHint' | translate}}
          </span>
        </cool-upload>
      </div>

      <div class="buttons">
        <button type="button" mat-raised-button color="primary" routerLink="">{{ 'word.done' | translate }}</button>
      </div>
    </form>
  </div>
</div>

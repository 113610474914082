<div *ngIf="!shouldNotShow" class="holder"
      [ngClass]="{'expanded' : expanded}"
      (mouseover)="tempExpand()"
      (mouseout)="tempCollapse()"
      fxHide.xs>
  <div class="inner">
    <div class="controller-logo"
      *ngIf="loggedIn"
      comment-box-position="bottom"
      comment-subject-codename="controller-logo"
      comment-subject-title="{{ 'controller.placeholder.controllerLogo' | translate }}"
      >
      <img
        [routerLink]="logoLink"
        onerror="this.src='/assets/eclogo-white.png'"
        onabort="this.src='/assets/eclogo-white.png'"
        #controllerLogo alt=""/>
    </div>

    <div class="controller-logo" *ngIf="!loggedIn">
      <img src="/assets/eclogo-white.png"/>
    </div>

    <div class="logo-holder">
      <img src="assets/eclogo-40.png" class="logo small"/>
      <a href="https://ecomply.io/" target="_blank"><img src="assets/eclogo-white.png" class="logo big"/></a>
    </div>

    <div class="nav">
      <ng-container *ngIf="loggedIn">
        <ng-container *ngIf="!isSupervisor">
          <a routerLink="/dashboard">
            <div class="nav-item" [ngClass]="{'current': route.startsWith('/dashboard')}">
              <mat-icon>insights</mat-icon>
              <span>{{'nav.metrics' | translate}}</span>
            </div>
          </a>

          <access-control atLeast="user-management.role.controller.contributor">
            <a routerLink="todolist">
              <div class="nav-item" [ngClass]="{'current': route === '/todolist'}">
                <mat-icon>done_all</mat-icon>
                <span [translate]="'nav.todolist'"></span>
                <div class="notification" *ngIf="openTodos"></div>
              </div>
            </a>
          </access-control>

          <access-control atLeast="user-management.role.controller.vater_solution_premium">
            <a routerLink="/audit">
              <div class="nav-item" [ngClass]="{'current': route.startsWith('/audit')}">
                  <mat-icon>question_answer</mat-icon>
                  <span>{{ 'nav.audits' | translate }}</span>
              </div>
            </a>

            <a routerLink="/toms">
              <div class="nav-item" [ngClass]="{'current': route.startsWith('/toms')}">
                  <mat-icon>security</mat-icon>
                  <span>{{ 'nav.toms' | translate }}</span>
              </div>
            </a>

            <a routerLink="/measures">
              <div class="nav-item" [ngClass]="{'current': route.startsWith('/measures')}">
                <mat-icon>straighten</mat-icon>
                <span>{{ 'nav.measures' | translate }}</span>
              </div>
            </a>

            <a routerLink="/rpa/applicable">
              <div class="nav-item" [ngClass]="{'current': route.startsWith('/rpa')}">
                  <mat-icon>storage</mat-icon>
                  <span>{{ 'nav.pas' | translate }}</span>
              </div>
            </a>
          </access-control>

          <access-control atLeast="user-management.role.controller.contributor">
            <a routerLink="/vendors">
              <div class="nav-item" [ngClass]="{'current': route.startsWith('/vendors')}">
                  <mat-icon>device_hub</mat-icon>
                  <span>{{ 'nav.dpas' | translate }}</span>
              </div>
            </a>

            <div class="nav-spacer"></div>

            <a routerLink="/dsar/records">
              <div class="nav-item" [ngClass]="{'current': route.startsWith('/dsar')}">
                  <mat-icon>people</mat-icon>
                  <span>{{ 'nav.dsar' | translate }}</span>
                  <div class="notification" *ngIf="openDSARs > 0"></div>
              </div>
            </a>

            <a routerLink="/incidents/records">
              <div class="nav-item" [ngClass]="{'current': route.startsWith('/incident')}">
                  <mat-icon>warning</mat-icon>
                  <span>{{ 'nav.incident' | translate }}</span>
                  <div class="notification" *ngIf="openIncidents > 0"></div>
              </div>
            </a>
          </access-control>

          <access-control atLeast="user-management.role.controller.vater_solution_premium">
            <a routerLink="/generator">
              <div class="nav-item" [ngClass]="{'current': route.startsWith('/reports')}">
                <mat-icon>receipt_long</mat-icon>
                <span>{{'nav.docGenerator' | translate}}</span>
              </div>
            </a>
          </access-control>

          <access-control atLeast="user-management.role.controller.vater_solution_premium">
            <a routerLink="/files">
              <div class="nav-item" [ngClass]="{'current': route.startsWith('/reports')}">
                <mat-icon>
                  folder_open
                  </mat-icon>
                <span>{{'nav.fileStorage' | translate}}</span>
              </div>
            </a>
          </access-control>

          <access-control atLeast="user-management.role.controller.contributor">
            <a routerLink="/organisation">
              <div class="nav-item" [ngClass]="{'current': route.startsWith('/organisation')}">
                  <mat-icon>domain</mat-icon>
                  <span>{{ 'nav.organisation' | translate }}</span>
              </div>
            </a>

            <a routerLink="/actions">
              <div class="nav-item" [ngClass]="{'current': route.startsWith('/actions')}">
                  <mat-icon>timeline</mat-icon>
                  <span>{{ 'nav.actions' | translate }}</span>
              </div>
            </a>
          </access-control>

          <display-jurisdiction jurisdiction="lgpd">
            <div class="nav-spacer"></div>
            <a class="nav-item"
            href="https://lgpd-brasil.info/"
            target="_blank">
              <mat-icon>gavel</mat-icon>
              <span innerHTML="{{'nav.lawReference' | translate}}"></span>
            </a>
          </display-jurisdiction>

        </ng-container>

        <ng-container *ngIf="isSupervisor">
          <div class="nav-spacer"></div>

          <a routerLink="supervision/clients">
            <div class="nav-item" [ngClass]="{'current': route.startsWith('/supervision/client')}">
                <mat-icon>supervisor_account</mat-icon>
                <span>{{ 'nav.supervision.clients' | translate }}</span>
            </div>
          </a>

          <feature-access-local featureName="vendor-library" [clientOnly]="false">
            <a routerLink="supervision/vendors">
              <div class="nav-item" [ngClass]="{'current': route.startsWith('/supervision/vendors')}">
                  <mat-icon>location_city</mat-icon>
                  <span>{{ 'nav.supervision.vendorLibrary' | translate }}</span>
              </div>
            </a>
          </feature-access-local>

          <a routerLink="supervision/applications">
            <div class="nav-item" [ngClass]="{'current': route.startsWith('/supervision/applications')}">
                <mat-icon>web_asset</mat-icon>
                <span>{{ 'nav.supervision.applications' | translate }}</span>
            </div>
          </a>

          <feature-access-local featureName="supervisor-documents" [includeClients]="true" [clientOnly]="false">
            <a routerLink="supervision/documents">
              <div class="nav-item" [ngClass]="{'current': route.startsWith('/supervision/documents')}">
                  <mat-icon>upload_file</mat-icon>
                  <span>{{ 'nav.supervision.sharedDocuments' | translate }}</span>
              </div>
            </a>
          </feature-access-local>

          <a routerLink="supervision/measures">
            <div class="nav-item" [ngClass]="{'current': route.startsWith('/supervision/measures')}">
              <mat-icon>straighten</mat-icon>
              <span>{{ 'nav.supervision.measures' | translate }}</span>
            </div>
          </a>

          <div class="nav-spacer"></div>

          <a routerLink="supervision/supervisor/profile">
            <div class="nav-item" [ngClass]="{'current': route.startsWith('/supervision/supervisor/profile')}">
                <mat-icon>account_circle</mat-icon>
                <span>{{ 'nav.supervision.profile' | translate }}</span>
            </div>
          </a>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="!loggedIn">
        <div class="nav-spacer"></div>

        <a routerLink="login">
          <div class="nav-item" [ngClass]="{'current': route === '/login'}">
              <mat-icon>https</mat-icon>
              <span>{{'login.login' | translate}}</span>
          </div>
        </a>

        <a routerLink="registration" *ngIf="ENV.selfSignUp">
          <div class="nav-item" [ngClass]="{'current': route === '/registration'}">
              <mat-icon>assignment_turned_in</mat-icon>
              <span>{{ 'registration.registration' | translate }}</span>
          </div>
        </a>

        <div class="nav-spacer" *ngIf="ENV.selfSignUp"></div>

        <a routerLink="forgot-password">
          <div class="nav-item" [ngClass]="{'current': route === '/forgot-password'}">
              <mat-icon>vpn_key</mat-icon>
              <span>{{ 'forgotpw.question' | translate }}</span>
          </div>
        </a>
      </ng-container>
    </div>
  </div>
</div>

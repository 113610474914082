import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../../services/authentication.service';
import { TodoService } from '../../../services/todos/todo.service';
import { Todo } from '../../todos/todo.interface';
import {AccessLevelService, userRoles} from "../../../services/user/access-level.service";
import {TodoDetailsDialogComponent} from "../../todos/todo-details-dialog/todo-details-dialog.component";
import {MatDialog} from "@angular/material/dialog";
import {Measure} from "../../../services/measures.service";

@Component({
  selector: 'dashboard-todo-list',
  templateUrl: './todo-list.component.html',
  styleUrls: ['./todo-list.component.scss']
})
export class DashboardTodoListComponent implements OnInit {

  openTodos: Todo[] = [];
  readonly = false;

  constructor(
    private todoService: TodoService,
    private authService: AuthenticationService,
    private accessService: AccessLevelService,
    private dialog: MatDialog,
    private accessLevel: AccessLevelService
  ) { }

  get canNavigateAway() {
    return this.accessLevel.checkRole(userRoles.inspector) >= 0;
  }

  get todos() {
    return this.openTodos.slice(0, 10);
  }

  get hasMore() {
    return this.openTodos.length > 10;
  }

  ngOnInit(): void {
    this.accessService.checkAccess({ context: 'todos' })
      .subscribe((access) => {
        this.readonly = !access.write;
      });

    this.update();
  }

  update() {
    this.todoService.fetchTodos({ done: false })
      .subscribe((todos) => {
        this.openTodos = todos;
      });
  }

  openTodoDetails(event, todo) {
    if (event) {
      event.stopPropagation();
    }

    this.dialog.open(TodoDetailsDialogComponent, {
      width: '700px',
      maxHeight: '90vh',
      data: todo,
      autoFocus: false,
    }).afterClosed().subscribe(() => {
      this.dialog.closeAll();
      this.update();
    });
  }

  todoOverdue(todo: Todo) {
    return todo.dueDate && new Date(todo.dueDate) < new Date() && !todo.done;
  }

  todoDue(todo: Todo) {
    const today = new Date();
    const nearFuture = new Date();
    nearFuture.setDate(today.getDate() + 14);
    return todo.dueDate && new Date(todo.dueDate) < nearFuture && new Date(todo.dueDate) > today && !todo.done;
  }
}

import { Injectable } from '@angular/core';
import { AuthenticationService } from '../authentication.service';
import { RequestService } from '../request.service';

@Injectable({
  providedIn: 'root'
})
export class MentionsService {

  constructor(
    private requestService: RequestService,
    private authService: AuthenticationService
  ) { }

  getUsers(includeSupervisors = false, client?) {
    return this.requestService.get<any>({
      uri: `/controller/users/allowed${(includeSupervisors) ? '?supervisors=true' : ''}${(client) ? '&client='+ client : ''}`
    });
  }

  /**
   * This method is ported from comment-box-dialog to allow us to sanitize our list of users
   * This will be useful for todo / todo system in case we get duplicates
   * We want this list to still show ourselves so we be the assigned user.
   * Lets also do a filter here and remove the "deleted" users (in reality they are only not assigned to any supervisor)
   *
   * @param mentionables array that we want to sanitize
   * @returns a sanitized array of users
   */
  sanitizeList(mentionables: any[]) {
    const tempArray = [];
    mentionables.map(user => {
      if(tempArray.length === 0) {
        tempArray.push(user);
      } else if(!tempArray.find(u => u.id === user.id)) {
        tempArray.push(user);
      }
    });
    // return tempArray.filter(u => this.authService.email !== u.email);
    // return tempArray.filter(u => u.supervisorProfile !== null);
    return tempArray;
  }
}

<!-- {{ 'coupons.promotion.new-value.thanks.title' | translate }} -->
<!-- {{ 'coupons.promotion.new-value.thanks.note.part1' | translate }} -->
<!-- {{ 'coupons.promotion.new-value.thanks.note.part2' | translate }} -->
<!-- {{ 'coupons.promotion.new-value.thanks.error.title' | translate }} -->
<!-- {{ 'coupons.promotion.new-value.thanks.error.message' | translate }} -->


<div class="contents" *ngIf="success">
  <div>
    <h1 class="title">
      {{ 'coupons.promotion.new-value.thanks.title' | translate }}
    </h1>
    <br>
    <p>
      {{ 'coupons.promotion.new-value.thanks.note.part1' | translate }}
      <b><a>{{email}}</a></b>
      {{ 'coupons.promotion.new-value.thanks.note.part2' | translate }}
    </p>
  </div>
</div>

<div class="contents" *ngIf="!success">
  <mat-card>
    <img mat-card-image src="assets/not-found.svg" />
    <mat-card-title>
      {{ 'coupons.promotion.new-value.thanks.error.title' | translate }}
    </mat-card-title>
    <mat-card-content>
      {{ 'coupons.promotion.new-value.thanks.error.message' | translate }}
    </mat-card-content>
  </mat-card>
</div>
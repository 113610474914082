<div class="submit-dsar contents" *ngIf="name">
  <img class="logo" *ngIf="logo" [src]="logoUrl()"/>

  <ng-container *ngIf="!submitted">
    <h2 class="title">
      {{ 'dsar.submit.title.pt1' | translate }}
      {{name}}
      {{ 'dsar.submit.title.pt2' | translate }}
    </h2>

    <div *ngIf="jurisdiction === 'lgpd'">
      <mat-form-field>
        <mat-select [(ngModel)]='customFields.subjectRelation'
                    placeholder="{{ 'dsar.submit.subject.relation' | translate }}"
                    required>
          <mat-option value='dsar.submit.subject.client'>
            {{ 'dsar.submit.subject.client' | translate }}
          </mat-option>
          <mat-option value='dsar.submit.subject.vendor'>
            {{ 'dsar.submit.subject.vendor' | translate }}
          </mat-option>
          <mat-option value='dsar.submit.subject.consumer'>
            {{ 'dsar.submit.subject.consumer' | translate }}
          </mat-option>
          <mat-option value='dsar.submit.subject.employee'>
            {{ 'dsar.submit.subject.employee' | translate }}
          </mat-option>
<!--          <mat-option value='dsar.submit.subject.attorney'>-->
<!--            {{ 'dsar.submit.subject.attorney' | translate }}-->
<!--          </mat-option>-->
        </mat-select>
      </mat-form-field>

      <mat-form-field>
        <input matInput type='text' [(ngModel)]="customFields.subjectName" placeholder="{{ 'dsar.submit.subject.name' | translate }}"
               required>
      </mat-form-field>

      <mat-form-field>
        <input matInput type='text' [(ngModel)]='customFields.lgpdCPF'
               placeholder="{{ 'dsar.submit.subject.lgpd.cpf' | translate }}"
               required>
        <mat-hint>
          {{ 'dsar.submit.subject.lgpd.cpf.hint' | translate }}
        </mat-hint>
      </mat-form-field>

      <mat-form-field>
        <input matInput type='text' [(ngModel)]='customFields.lgpdRG'
               placeholder="{{ 'dsar.submit.subject.lgpd.rg' | translate }}"
               required>
        <mat-hint>
          {{ 'dsar.submit.subject.lgpd.rg.hint' | translate }}
        </mat-hint>
      </mat-form-field>
    </div>

  <mat-form-field>
      <input matInput type="text" id="email" [formControl]="email"
        placeholder="{{ 'dsar.submit.email.placeholder' | translate }}" required
      />
      <mat-hint>
        {{ 'dsar.submit.email.hint.pt1' | translate }}
        {{name}}{{ 'dsar.submit.email.hint.pt2' | translate }}
      </mat-hint>
      <mat-error *ngIf="email.invalid">
        {{ 'helper.pleaseEnterValidEmail' | translate }}
      </mat-error>
    </mat-form-field>

    <mat-form-field>
      <mat-select [(ngModel)]="requestType"
        placeholder="{{ 'dsar.submit.request_type.placeholder' | translate }}"
        required>
        <mat-option value="dsar.request_type.data"
                    matTooltip="{{'dsar.request_types.data' | translate}}" matTooltipPosition="above">
          {{'dsar.request_types.data' | translate}}
        </mat-option>
        <mat-option value="dsar.request_type.deletion"
                    matTooltip="{{'dsar.request_types.deletion' | translate}}" matTooltipPosition="above">
          {{'dsar.request_types.deletion' | translate}}
        </mat-option>

        <div *ngIf="jurisdiction === 'lgpd'">

          <!-- We want to keep the dropdown clean so we cannot have very long strings wrapping to the next line...
           The best solution, as below, is to show a mat-tooltip when the user hovers on the option.
           If a better solution comes to mind we can always come back and optimize it. -->
          <mat-option value="dsar.request_types.lgpd.processingConfirmation"
                      matTooltip="{{'dsar.request_types.lgpd.processingConfirmation' | translate}}" matTooltipPosition="above">
            {{'dsar.request_types.lgpd.processingConfirmation' | translate}}
          </mat-option>
          <mat-option value="dsar.request_types.lgpd.correctionOfData"
                      matTooltip="{{'dsar.request_types.lgpd.correctionOfData' | translate}}" matTooltipPosition="above">
            {{'dsar.request_types.lgpd.correctionOfData' | translate}}
          </mat-option>
          <mat-option value="dsar.request_types.lgpd.deletionOfNonCompliant"
                      matTooltip="{{'dsar.request_types.lgpd.deletionOfNonCompliant' | translate}}" matTooltipPosition="above">
            {{'dsar.request_types.lgpd.deletionOfNonCompliant' | translate}}
          </mat-option>
          <mat-option value="dsar.request_types.lgpd.portDataOut"
                      matTooltip="{{'dsar.request_types.lgpd.portDataOut' | translate}}" matTooltipPosition="above">
            {{'dsar.request_types.lgpd.portDataOut' | translate}}
          </mat-option>
          <mat-option value="dsar.request_types.lgpd.sharingConfirmation"
                      matTooltip="{{'dsar.request_types.lgpd.sharingConfirmation' | translate}}" matTooltipPosition="above">
            {{'dsar.request_types.lgpd.sharingConfirmation' | translate}}
          </mat-option>
          <mat-option value="dsar.request_types.lgpd.consentConfirmation"
                      matTooltip="{{'dsar.request_types.lgpd.consentConfirmation' | translate}}" matTooltipPosition="above">
            {{'dsar.request_types.lgpd.consentConfirmation' | translate}}
          </mat-option>
          <mat-option value="dsar.request_types.lgpd.consentRevocation"
                      matTooltip="{{'dsar.request_types.lgpd.consentRevocation' | translate}}" matTooltipPosition="above">
            {{'dsar.request_types.lgpd.consentRevocation' | translate}}
          </mat-option>
        </div>

      </mat-select>
      <mat-hint>
        {{ 'dsar.submit.request_type.hint.pt1' | translate }}
        {{name}}
        {{ 'dsar.submit.request_type.hint.pt2' | translate }}
        {{name}}
        {{ 'dsar.submit.request_type.hint.pt3' | translate }}
      </mat-hint>
    </mat-form-field>

    <mat-form-field>
      <textarea  matInput type="text"
        placeholder="{{ 'dsar.submit.comments.placeholder' | translate }}"
        [(ngModel)]="comments"></textarea>
      <mat-hint>
        {{ 'dsar.submit.comments.hint' | translate }}
      </mat-hint>
    </mat-form-field>

    <re-captcha
      (resolved)="recaptcha = $event"
      [siteKey]="recpatchaKey"
    ></re-captcha>

    <br/>

    <div style="text-align: right">
      <button mat-flat-button color="primary" [disabled]="!valid" (click)="submit()">
        {{ 'dsar.submit.actions.submit' | translate }}
      </button>
    </div>
  </ng-container>

  <ng-container *ngIf="submitted">
    <h2 class="title">
      {{ 'dsar.submit.success.title.pt1' | translate }}
      {{name}}
      {{ 'dsar.submit.success.title.pt2' | translate }}
    </h2>
    <p>
      {{ 'dsar.submit.success.hint.pt1' | translate }}
      {{name}}
      {{ 'dsar.submit.success.hint.pt2' | translate }}
      {{ctrlEmail}}
      {{ 'dsar.submit.success.hint.pt3' | translate }}
    </p>
  </ng-container>
</div>

<div class="contents" *ngIf="error">
  <img src="assets/uc-bg.svg"/>
  <p>
    {{ 'dsar.submit.errors.broken-link.hint' | translate }}
  </p>
</div>
<div class="actions-metadata-info metadata-rpa-processors">
  <!-- External Processors -->

  <ng-container *ngIf="event.eventName === 'EP.ADD'">
    <ng-container *ngIf="!pa || !vendor">
      <div class="event-loading metadata-center">
        <mat-icon>bar_chart</mat-icon>
        <span>{{ 'events.metadata.loading' | translate }}</span>
      </div>
    </ng-container>
    <ng-container *ngIf="pa && vendor">
      <div class="event-left">
        <span class="specimen">{{ vendor.name }}</span>
        <span class="type">
          {{ 'events.metadata.rpaProcessors.vendor' | translate }}
        </span>
      </div>
      <div class="event-action">
        <span class="icon">
          <mat-icon>link</mat-icon>
        </span>
        <span class="action">
          {{ 'events.metadata.rpa.linked' | translate }}
        </span>
      </div>
      <div class="event-item">
        <span class="specimen">{{ pa.paName }}</span>
        <span class="type">
          {{ 'events.metadata.rpa.pa' | translate }}
        </span>
      </div>
    </ng-container>
  </ng-container>
  <ng-container *ngIf="event.eventName === 'EP.DELETE'">
    <ng-container *ngIf="!pa || !vendor">
      <div class="event-loading metadata-center">
        <mat-icon>bar_chart</mat-icon>
        <span>{{ 'events.metadata.loading' | translate }}</span>
      </div>
    </ng-container>
    <ng-container *ngIf="pa && vendor">
      <div class="event-left">
        <span class="specimen">{{ vendor.name }}</span>
        <span class="type">
          {{ 'events.metadata.rpaProcessors.vendor' | translate }}
        </span>
      </div>
      <div class="event-action">
        <span class="icon">
          <mat-icon>link_off</mat-icon>
        </span>
        <span class="action">
          {{ 'events.metadata.rpa.unlinked' | translate }}
        </span>
      </div>
      <div class="event-item">
        <span class="specimen">{{ pa.paName }}</span>
        <span class="type">
          {{ 'events.metadata.rpa.pa' | translate }}
        </span>
      </div>
    </ng-container>
  </ng-container>

  <!-- Internal Processors -->

  <ng-container *ngIf="event.eventName === 'IP.ADD'">
    <ng-container *ngIf="!pa || !department">
      <div class="event-loading metadata-center">
        <mat-icon>bar_chart</mat-icon>
        <span>{{ 'events.metadata.loading' | translate }}</span>
      </div>
    </ng-container>
    <ng-container *ngIf="pa && department">
      <div class="event-left">
        <span class="specimen">{{ department.name }}</span>
        <span class="type">
          {{ 'events.metadata.rpa.department' | translate }}
        </span>
      </div>
      <div class="event-action">
        <span class="icon">
          <mat-icon>link</mat-icon>
        </span>
        <span class="action">
          {{ 'events.metadata.rpa.linked' | translate }}
        </span>
      </div>
      <div class="event-item">
        <span class="specimen">{{ pa.paName }}</span>
        <span class="type">
          {{ 'events.metadata.rpa.pa' | translate }}
        </span>
      </div>
    </ng-container>
  </ng-container>
  <ng-container *ngIf="event.eventName === 'IP.DELETE'">
    <ng-container *ngIf="!pa || !department">
      <div class="event-loading metadata-center">
        <mat-icon>bar_chart</mat-icon>
        <span>{{ 'events.metadata.loading' | translate }}</span>
      </div>
    </ng-container>
    <ng-container *ngIf="pa && department">
      <div class="event-left">
        <span class="specimen">{{ department.name }}</span>
        <span class="type">
          {{ 'events.metadata.rpa.department' | translate }}
        </span>
      </div>
      <div class="event-action">
        <span class="icon">
          <mat-icon>link_off</mat-icon>
        </span>
        <span class="action">
          {{ 'events.metadata.rpa.unlinked' | translate }}
        </span>
      </div>
      <div class="event-item">
        <span class="specimen">{{ pa.paName }}</span>
        <span class="type">
          {{ 'events.metadata.rpa.pa' | translate }}
        </span>
      </div>
    </ng-container>
  </ng-container>
</div>

import { Injectable } from "@angular/core";
import { HttpErrorResponse } from "@angular/common/http";
import { BusService } from "../bus.service";
import {
  IPostOptions,
  RequestService
} from "../request.service";
import { EventsService } from "../events.service";

interface IPostCredentialsRequest {
  email: string;
  password: string;
  code?: string;
}

interface IPostCredentialsResponse {
  _id: string;
  isExpired: string;
  token: string;
  firstName: string;
  lastName: string;
  email: string;
  intercomHash: string;
  crispHash: string;
  productType: string;
  controllerId: number;
  planMetadata: any;
  avatarPath?: string;
  sessionOnly: boolean;
  serviced: boolean;
}

/*
  An interlayer service for authentication between request service and login component
*/
@Injectable()
export class AuthenticationRequestService {

  private options: IPostOptions<IPostCredentialsRequest, IPostCredentialsResponse>;
  private next: string = null;
  private nextParams: any = {};

  constructor(
    private bus: BusService,
    private events: EventsService,
    private requestService: RequestService
  ) {
    this.options = {
      uri: "/account/authenticate",
      body: {
        email: "",
        password: ""
      },
      handlers: {
        success: this.success.bind(this),
        error: this.error.bind(this)
      }
    };
    this.subscribe();
  }

  public success(user: IPostCredentialsResponse): void {
    if (user.sessionOnly) {
      sessionStorage.setItem('currentUser', user.token);
    } else {
      localStorage.setItem('currentUser', user.token);
    }
    localStorage.setItem('currentUserID', user._id);
    localStorage.setItem('isExpired', user.isExpired);
    this.bus.publish(this.events.received.authentication.success, {
      _id: user._id,
      token: user.token,
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      intercomHash: user.intercomHash,
      crispHash: user.crispHash,
      next: this.next,
      nextParams: this.nextParams,
      productType: user.productType,
      controllerId: user.controllerId,
      planMetadata: user.planMetadata,
      avatarPath: user.avatarPath,
      serviced: user.serviced
    });
  }

  public error(httpErrorResponse: HttpErrorResponse): void {
    this.bus.publish(this.events.received.authentication.failure, httpErrorResponse);
  }

  private request(data): void {
    this.bus.publish(this.events.notified.request.starting);
    this.options.body.email = data.email;
    this.options.body.password = data.password;
    this.options.body.code = data.code;

    this.next = data.next;
    this.nextParams = data.nextParams;

    this.requestService.post<IPostCredentialsRequest, IPostCredentialsResponse>(this.options);
  }

  public subscribe(): void {
    this.bus.subscribe(this.events.requested.data.authentication, this.request.bind(this));
  }
}

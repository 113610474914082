import { Component, Injector, Input, OnDestroy, OnInit } from '@angular/core';
import {
  Audit,
  AuditConfiguration,
  AuditsService,
  Category,
  Question,
} from 'app/services/audits/audits.service';
import { Observable, Subject, Subscription } from 'rxjs';
import { debounceTime, switchMap, tap } from 'rxjs/operators';
import { AuditFieldState, fetchAuditLikeService } from '../../../util';

@Component({
  selector: 'app-audit-form-question-multiselect',
  templateUrl: './multiselect.component.html',
  styleUrls: ['./multiselect.component.scss'],
})
export class AuditFormQuestionMultiselectComponent implements OnInit, OnDestroy {
  @Input() audit: Audit = null;
  @Input() question: Question = null;
  @Input() readonly = false;

  @Input() auditConfiguration: AuditConfiguration = null;

  @Input() path = '';
  @Input() nestLevel = 0;

  multiselectChoices: AuditFieldState<string[]>;

  constructor(private injector: Injector) {}

  get computedPath() {
    return `${this.path}`;
  }

  get childNestLevel() {
    return parseInt(this.nestLevel.toString(), 10) + 1;
  }

  get isWideInput() {
    return Math.max(...this.question.choices?.map(c => c.length)) > 40;
  }

  save(value: string[]) {
    this.multiselectChoices.submit(value, (v) => v.join(','));
  }

  ngOnInit() {
    const fullPath = `${this.path}_multiselect_choices`;

    // because of how we save multiselect anwers (mostly because the postgres client interprets arrays differently), we have to destructure our answers to make it readable
    let savedResponse: string | number[] = this.audit.answers[fullPath];
    if (savedResponse) {
      savedResponse = savedResponse.split(',').map((r) => parseInt(r, 10));
    }

    const service = fetchAuditLikeService(
      this.auditConfiguration,
      this.injector
    );

    this.multiselectChoices = new AuditFieldState<string[]>(
      service,
      this.audit.id,
      'multiselect_choices',
      fullPath,
      savedResponse ? (savedResponse as any) : null
    );
  }

  ngOnDestroy() {
    this.multiselectChoices.clear();
  }
}

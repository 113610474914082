<div class="toms lang-{{lang}}">
  <h1 class="title">
    {{'toms.title' | translate}}
  </h1>
  <br><br>
  <p>
    {{'toms.message' | translate}}
  </p>
  <br><br>
  <div class="cats">
    <div class="cat" *ngFor="let cat of cats" routerLink="/toms/category/{{cat.id}}">
      <img class="icon" src="/assets/{{cat.icon}}"/>
      <div class="title">{{cat.title}}</div>
      <div class="desc">{{cat.description}}</div>
      <div class="progholder">
        <img *ngIf="calculateProgress(cat.id) >= 100" src="/assets/complete.svg" alt="Complete"/>
        <progress-indicator [progress]="calculateProgress(cat.id) / 100" *ngIf="!calculateProgress(cat.id) || calculateProgress(cat.id) < 100"></progress-indicator>
      </div>
    </div>
  </div>

  <br><br>
  <button mat-raised-button [disabled]="!allFinished" color="primary" (click)="continue()" routerLink="">{{ 'word.done' | translate }}</button>
</div>

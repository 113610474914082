import {Injectable} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import {BusService} from '../bus.service';
import {
  IPostOptions,
  RequestService
} from '../request.service';
import {EventsService} from '../events.service';

interface IPostCredentialsRequest {
}

interface IPostCredentialsResponse {
}

/*
  An interlayer service for rpa update request between request service and rpa component
*/
@Injectable()
export class RpaPostService {

  private options: IPostOptions<IPostCredentialsRequest, IPostCredentialsResponse>;

  constructor(
    private bus: BusService,
    private events: EventsService,
    private requestService: RequestService
  ) {
    this.options = {
      uri: '/pa',
      body: {},
      handlers: {
        success: this.success.bind(this),
        error: this.error.bind(this)
      }
    };
    this.subscribe();
  }

  public success(data: IPostCredentialsResponse): void {
    this.bus.publish(this.events.received.data.rpa.update.success, data);
    setTimeout(() => this.bus.publish(this.events.requested.data.rpa.getAll), 400);
  }

  public error(httpErrorResponse: HttpErrorResponse): void {
    this.bus.publish(this.events.received.data.rpa.update.failure, httpErrorResponse);
  }

  // TODO more comments
  private request(model: any): void {
    this.bus.publish(this.events.notified.saving.starting);
    if (model.data.formData) {
      Object.keys(model.data.formData).forEach(input => {
        this.options.body[input] = model.data.formData[input];
      });
      this.options.uri = `/pa/${this.options.body['paId']}`;
    } else {
      Object.keys(model.data).forEach(input => {
        if (input !== 'paId') {
          this.options.body[input] = model.data[input];
        }
      });
      //console.log('__________________STATUS________________________', this.options.body['paStatus'])
      this.options.uri = `/pa/${model.data.paId}`;
    }
    // this.options.body['paStatusLastChange'] = new Date().toJSON().replace(/-/g,'/').replace(/T/g,'/').slice(0,23);
    this.options.body['paStatusLastChange'] = new Date();

    // this check when other fields are updating its a string so toISOString method can not be applied
    if (typeof this.options.body['paCreatedOn'] === 'object') {
      let date = new Date(this.options.body['paCreatedOn']);

      let createdOn = new Date();
      createdOn.setDate(date.getDate());

      this.options.body['paCreatedOn'] = createdOn.toISOString();
    }

    // console.log(this.options.body['paCreatedOn'])
    // console.log(this.options)

    this.requestService.post<IPostCredentialsRequest, IPostCredentialsResponse>(this.options);
  }

  public subscribe(): void {
    this.bus.subscribe(this.events.requested.data.rpa.update, this.request.bind(this));
  }
}

import { Component, OnInit, OnDestroy, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BusService } from '../../services/bus.service';
import { EventsService } from '../../services/events.service';
import { ActionsService } from 'app/services/actions/actions.service';
import { UserManagementService } from 'app/services/user/user-management.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { state, trigger, style, transition, animate } from '@angular/animations';

export interface Event {
  id: number;
  eventName: string;
  category: string;
  label?: string;
  metadata?: any;
  timestampAt: Date;
  userId: number;
}

const TRACKING_START_DATE = '2018-12-15';

@Component({
  selector: 'app-actions',
  templateUrl: './actions.component.html',
  styleUrls: ['./actions.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('void', style({ height: '0px', minHeight: '0', visibility: 'hidden' })),
      state('*', style({ height: '*', visibility: 'visible' })),
      transition('void <=> *', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ]
})
export class ActionsComponent implements OnInit, OnDestroy {

  events: Array<Event>;
  users = [];
  columnsToDisplay = ['timestampAt', 'category', 'eventName', 'user'];
  dataSource: MatTableDataSource<Event>;

  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(
    private bus: BusService,
    private eventsService: EventsService,
    private route: ActivatedRoute,
    private actionRequest: ActionsService,
    private userService: UserManagementService,
  ) {
  }

  isExpansionDetailRow = (i: number, row: any) => row.hasOwnProperty('detailRow');

  hasMetadata(category: string, event: Event) {
    const fullName = this.getFullName(event);
    const results = METADATA_EVENTS.findIndex((e) => {
      if (category) {
        return e.category === category && e.fullName.toLowerCase() === fullName;
      }

      return e.fullName.toLowerCase() === fullName;
    });

    return results !== -1;
  }

  getUser(event: Event) {
    const candidates = this.users.filter(user => user.id === event.userId);
    if (candidates.length > 0) return candidates[0].name;
    return '---';
  }

  getCategory(event: Event) {
    const splitted = event.category.split('.');
    if (splitted.length > 1) {
      return splitted[1].toLowerCase();
    }

    return splitted[0].toLowerCase();
  }

  getFullName(event: Event) {
    return event.category.toLowerCase() + '.' + event.eventName.toLowerCase();
  }

  orderArray(ascending: boolean) {
    this.events = this.events.sort((a, b) => {
      if (ascending) {
        const c = a;
        a = b;
        b = c;
      }

      return (new Date(b.timestampAt) as any) - (new Date(a.timestampAt) as any);
    });
  }

  private receiveAll(events: Array<Event>) {
    this.events = events;

    // Add "start of tracking" event.
    this.events.push({ eventName: 'TRACKING_START', category: 'SYSTEM', timestampAt: new Date(TRACKING_START_DATE), id: 0, userId: 0 });

    this.orderArray(false);
    this.dataSource = new MatTableDataSource<Event>(this.events);
    this.dataSource.paginator = this.paginator;
  }

  ngOnInit() {
    this.bus.subscribe(this.eventsService.received.data.actions.all.success, this.receiveAll, this);

    this.userService.users(true).subscribe(response => {
      this.users = response.map(user => Object.assign({}, user, {
        name: `${user.firstName} ${user.lastName}`
      }));
    });

    this.bus.publish(this.eventsService.requested.data.actions.all);
  }

  ngOnDestroy() {
    this.bus.unsubscribe(this.eventsService.received.data.actions.all.success, this.receiveAll);
  }
}

const METADATA_EVENTS = [
  { category: 'department', fullName: 'CONTROLLER.DEPARTMENT.ADD' },
  { category: 'department', fullName: 'CONTROLLER.DEPARTMENT.UPDATE' },
  { category: 'document', fullName: 'CONTROLLER.DOCUMENT.UPLOADED' },
  { category: 'document', fullName: 'CONTROLLER.DOCUMENT.REPLACE_FILE' },
  { category: 'document', fullName: 'CONTROLLER.DOCUMENT.UPDATE_DETAILS' },
  { category: 'users', fullName: 'CONTROLLER.USERS.CONFIRMED_INVITE' },
  { category: 'users', fullName: 'CONTROLLER.USERS.RESENT_INVITE' },
  { category: 'users', fullName: 'CONTROLLER.USERS.INVITED_USER' },
  { category: 'rpa', fullName: 'CONTROLLER.RPA.ASSIGN' },
  { category: 'rpa', fullName: 'CONTROLLER.RPA.UPDATE_STATUS' },
  { category: 'rpa', fullName: 'CONTROLLER.RPA.UPDATE_FIELD' },
  { category: 'rpa', fullName: 'CONTROLLER.RPA.CUSTOM.ADD' },
  { category: 'rpa', fullName: 'CONTROLLER.RPA.CUSTOM.UPDATE' },
  { category: 'rpa-processors', fullName: 'CONTROLLER.RPA.IP.ADD' },
  { category: 'rpa-processors', fullName: 'CONTROLLER.RPA.IP.DELETE' },
  { category: 'rpa-processors', fullName: 'CONTROLLER.RPA.EP.ADD' },
  { category: 'rpa-processors', fullName: 'CONTROLLER.RPA.EP.DELETE' },
  { category: 'vendors', fullName: 'CONTROLLER.VENDORS.ADD_DPA' },
  { category: 'vendors', fullName: 'CONTROLLER.VENDORS.REQUEST_DPA' },
  { category: 'vendors', fullName: 'CONTROLLER.VENDORS.DPA_TOGGLE_REQUIRED' },
  { category: 'toms', fullName: 'CONTROLLER.TOMS.ENTRY.SAVE' },
  { category: 'toms', fullName: 'CONTROLLER.TOMS.ENTRY.APPLICABLE_UPDATE' },
  { category: 'toms', fullName: 'CONTROLLER.TOMS.ENTRY.EXPLANATION_UPDATE' },
];

import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Application, ApplicationService } from '../../../services/vendors/application.service';
import { SearchApplicationDialogComponent } from '../search-application-dialog/search-application-dialog.component';

@Component({
  selector: 'app-application-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss']
})
export class ApplicationInputComponent implements OnInit, OnChanges {

  /**
   * The pre-set Application ID
   */
  @Input() value: string;

  /**
   * Emits the selected application when changed.
   */
  @Output() applicationChange = new EventEmitter<Application>();

  selectedApplication: Application = null;

  fetchApplicationPending = false;

  constructor(
    private applicationService: ApplicationService,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.fetchApplication();
  }

  showPicker() {
    this.dialog.open(SearchApplicationDialogComponent, { width: '480px', maxHeight: '90vh' })
      .afterClosed()
      .subscribe((application) => {
        if (application) {
          this.selectedApplication = application;
          this.applicationChange.emit(application);
        }
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.value.currentValue) {
      this.fetchApplication();
    } else {
      this.selectedApplication = null;
    }
  }

  private fetchApplication() {
    if (this.value) {
      this.applicationService.getApplication(parseInt(this.value, 10))
      .subscribe((application) => {
        if (application) {
          this.selectedApplication = application;
        }
      });
    } else {
      this.selectedApplication = null;
    }
  }
}

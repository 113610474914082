import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';


@Component({
  selector: 'app-thanks',
  templateUrl: './thanks.component.html',
  styleUrls: ['./thanks.component.scss']
})
export class CouponThanksComponent implements OnInit {

  success: boolean = false;
  email: string = '';

  constructor(
    private route: ActivatedRoute,
  ) { }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.success = params.success == 'true';
      this.email = params.email || 'eugene@ecomply.io';
    });
  }
}

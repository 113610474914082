//NOTE: Deprecated Component. Do not use!
//

import { Component, OnDestroy, OnInit, Inject } from "@angular/core";
import { BusService } from "../../services/bus.service";
import { EventsService } from "../../services/events.service";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
  selector: "dialog-component",
  templateUrl: "./dialog.template.html",
  styleUrls: ["./dialog.styles.scss"]
})

export class DialogComponent implements OnInit, OnDestroy {

  constructor(
    private bus: BusService,
    private events: EventsService,
    public dialogRef: MatDialogRef<DialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
  }

  public closeDialog(): void {
    this.dialogRef.close();
  }

  confirm() {
    this.dialogRef.close(true);
  }

  public ngOnInit(): void {
  }

  public ngOnDestroy(): void {
  }
}

import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { DpiaService, MeasureExample } from '../../../../services/rpa/dpia.service';
import { getMeasureDisplay } from '../util';

@Component({
  selector: 'app-measure-recommendation-dialog',
  templateUrl: './measure-recommendation-dialog.component.html',
  styleUrls: ['./measure-recommendation-dialog.component.scss']
})
export class MeasureRecommendationDialogComponent implements OnInit {

  measures: MeasureExample[] = [];
  keyword = '';

  constructor(
    private dialogRef: MatDialogRef<MeasureRecommendationDialogComponent>,
    private dpiaService: DpiaService,
    @Inject(MAT_DIALOG_DATA) private data: { measures: MeasureExample[] },
    private translation: TranslateService
  ) {
    this.measures = data.measures;
  }

  get options() {
    if (this.keyword.toLowerCase().trim().length > 0) {
      return this.measures.filter(m =>
        this.getDisplay(m).measureTitle.toLowerCase().includes(this.keyword) ||
        m.category.toLowerCase().includes(this.keyword) ||
        m.layer.toLowerCase().includes(this.keyword));
    }

    return this.measures;
  }

  getDisplay(item: { display: { de: any; en: any; pt: any; 'pt-BR': any } }) {
    return getMeasureDisplay(this.translation, item);
  }

  select(measure: MeasureExample) {
    this.dialogRef.close(measure);
  }

  addOwn() {
    this.dialogRef.close({ measureTitle: '' });
  }

  ngOnInit(): void {
  }

}

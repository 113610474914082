import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import { BusService } from 'app/services/bus.service';
import { EventsService } from 'app/services/events.service';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { UserManagementService } from 'app/services/user/user-management.service';
import { DocumentsUploadDialogComponent } from 'app/components/documents/upload/documents-upload-dialog.component';
import { DsarResourceService } from 'app/services/dsar/dsar-resource.service';
import { DsarFilterTitleDialogComponent } from './dsar-filter-title-dialog/dsar-filter-title-dialog.component';
import { DsarFilterDepartmentDialogComponent } from './dsar-filter-department-dialog/dsar-filter-department-dialog.component';
import * as XLSX from 'xlsx';
import { DsarSettings } from 'app/services/dsar/dsar-settings.service';
import { DsarSettingsDialogComponent } from '../settings-dialog/settings-dialog.component';

@Component({
  selector: 'app-dsar-records',
  templateUrl: './records.component.html',
  styleUrls: ['./records.component.scss']
})
export class DsarRecordsComponent implements OnInit, OnDestroy {
  @ViewChild(MatPaginator) paginator: MatPaginator;

  dsarItems;
  users = [];
  departments = [];

  departmentFilter = { enabled: false, value: undefined };
  titleFilter = { enabled: true, value: undefined };

  columnsToDisplay = ['receivedOn', 'title', 'details', 'department', 'user'];
  dataSource: MatTableDataSource<any>;

  constructor(
    private bus: BusService,
    private events: EventsService,
    private dialog: MatDialog,
    private dsarService: DsarResourceService,
    private userService: UserManagementService,
  ) {
  }

  getUser(record) {
    const candidates = this.users.filter(user => user.id === record.userId);
    if (candidates.length > 0) {
      return candidates[0].name;
    }
    return '---';
  }

  getDepartment(record) {
    const departments = this.departments.filter(dep => dep.id === record.departmentId);
    if (departments.length > 0) {
      return departments[0].name;
    }
    return '---';
  }

  orderArray(ascending: boolean) {
    this.dsarItems = this.dsarItems.sort((a, b) => {
      if (ascending) {
        const c = a;
        a = b;
        b = c;
      }

      return (new Date(b.receivedOn) as any) - (new Date(a.receivedOn) as any);
    });
  }

  openTitleFilter() {
    this.dialog.open(DsarFilterTitleDialogComponent, {
      width: '512px',
      maxHeight: '90vh',
      data: this.titleFilter.value || '',
    }).afterClosed().subscribe(response => {
      if (response.value !== undefined) {
        this.titleFilter.value = response.value;
        this.applyFilters();
      }

    });
  }

  openDepartmentFilter() {
    this.dialog.open(DsarFilterDepartmentDialogComponent, {
      width: '512px',
      maxHeight: '90vh',
      data: {
        departments: this.departments,
        value: this.departmentFilter.value
      }
    }).afterClosed().subscribe(response => {
      if (response.value !== undefined) {
        this.departmentFilter.value = response.value;
        this.applyFilters();
      }
    });
  }

  applyFilters() {
    const items: Array<any> = this.dsarItems;

    // Filter by department
    let filtered = items.filter((item) => {
      if (!this.departmentFilter.enabled && !this.departmentFilter.value) {
        return true;
      }

      return item.departmentId === this.departmentFilter.value;
    });

    const titleFilterValue = this.titleFilter.value;
    // Filter by Title
    filtered = filtered.filter((item) => {
      if (!this.titleFilter.enabled || (!titleFilterValue || titleFilterValue.length === 0)) {
        return true;
      }

      return item.title.toLowerCase().indexOf(titleFilterValue.toLowerCase()) !== -1;
    });

    this.dataSource = new MatTableDataSource<any>(filtered);
    this.dataSource.paginator = this.paginator;
  }

  resetFilters() {
    this.titleFilter.value = undefined;
    this.departmentFilter.value = undefined;

    this.dataSource = new MatTableDataSource<any>(this.dsarItems);
    this.dataSource.paginator = this.paginator;
  }

  exportToExcel() {
    const allDepartments = this.dataSource.data.map((dsar) => {
      if (dsar.departmentId) {
        return dsar.departmentId;
      }
    });
    const allUsers = this.dataSource.data.map((dsar) => dsar.userId);

    const affectedDepartments = this.departments.filter((dep) => allDepartments.includes(dep.id));
    const affectedUsers = this.users.filter((user) => allUsers.includes(user.id));

    /* generate worksheet */
    const requests: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.dataSource.data);

    const deps: XLSX.WorkSheet = XLSX.utils.json_to_sheet(affectedDepartments.map((dep) => ({
        id: dep.id,
        name: dep.name
      })));

    const users: XLSX.WorkSheet = XLSX.utils.json_to_sheet(affectedUsers.map((user) => ({
        id: user.id,
        firstName: user.firstName,
        lastName: user.lastName
      })));

    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, requests, 'Requests');
    XLSX.utils.book_append_sheet(wb, deps, 'Departments');
    XLSX.utils.book_append_sheet(wb, users, 'Users');

    const dateString = `${new Date().getFullYear()}-${new Date().getMonth()}-${new Date().getDate()}`;
    XLSX.writeFile(wb, `DSAR_Export_${dateString}.xlsx`);
  }

  deleteDsar(dsar, event) {

  }

  dsarError(error) {
    console.error(error);
  }

  departmentsSuccess(departments) {
    this.departments = departments;
  }

  departmentsFailure(error) {
    console.error(error);
  }

  openSettings() {
    this.dialog.open(DsarSettingsDialogComponent, {
      width: '640px'
    });
  }

  ngOnInit() {
    this.bus.subscribe(this.events.received.data.dsar.all.success, this.receiveDsar.bind(this));
    this.bus.subscribe(this.events.received.data.dsar.all.failure, this.dsarError.bind(this));
    this.bus.subscribe(this.events.received.data.departments.get.success, this.departmentsSuccess, this);
    this.bus.subscribe(this.events.received.data.departments.get.failure, this.departmentsFailure, this);

    this.userService.users(true).subscribe(response => {
      this.users = response.map(user => Object.assign({}, user, {
        name: `${user.firstName} ${user.lastName}`
      }));
    });

    this.bus.publish(this.events.requested.data.dsar.all);
    this.bus.publish(this.events.requested.data.departments.get);
  }

  ngOnDestroy() {
  }

  private receiveDsar(items: Array<any>) {
    this.dsarItems = items;
    this.orderArray(false);
    this.dataSource = new MatTableDataSource<any>(this.dsarItems);
    this.dataSource.paginator = this.paginator;
    this.applyFilters();
  }
}

import { Inject, Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { ControllerType, SettingsType, Template } from '../types';
import { SelectTemplateDialogComponent } from '../select-template-dialog/select-template-dialog.component';
import { SupervisionTemplatesBackendService } from '../../../../services/supervision/templates-backend.service';


@Component({
  selector: 'app-apply-template-dialog',
  templateUrl: './apply-template-dialog.component.html',
  styleUrls: ['./apply-template-dialog.component.scss']
})
export class ApplyTemplateDialogComponent implements OnInit {

  public controller: ControllerType;
  public template: Template;
  public settings: SettingsType = {
    departments: true,
    dpo: true,
    rpas: true,
    toms: true,
    vendors: false,
    companyInfo: false,
    dsars: false,
    incidents: false,
  };

  constructor(
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<ApplyTemplateDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data: { controller: ControllerType },
    private backend: SupervisionTemplatesBackendService,
  ) {
    this.controller = data.controller;
  }

  ngOnInit() {
    this.selectTemplate();
  }

  selectTemplate() {
    this.dialog.open(SelectTemplateDialogComponent, {
      width: '350px',
      maxHeight: '90vh',
    }).afterClosed().subscribe((selected: Template) => {
      if (!selected && !this.template) {
        this.dialogRef.close();
      }

      if (selected) {
        this.template = selected;
        this.backend.get(selected.id).subscribe((template: Template) => {
          this.template = template;
          this.template.updatedAt = new Date(template.updatedAt as string);
          this.template.createdAt = new Date(template.createdAt as string);

          this.settings = {
            departments: template.data.departments && Object.keys(template.data.departments).length > 0,
            dpo: template.data.dpo && Object.keys(template.data.dpo).length > 0,
            rpas: template.data.rpas && Object.keys(template.data.rpas).length > 0,
            toms: template.data.toms && Object.keys(template.data.toms).length > 0,
            vendors: false,
            companyInfo: false,
            dsars: false,
            incidents: false,
          };
        });
      }
    });
  }

  done() {
    this.dialogRef.close({
      template: this.template,
      settings: this.settings,
    });
  }
}

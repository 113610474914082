<h1 mat-dialog-title>
  {{ isEditing ? title.value : 'todos.create.modalTitle' | translate }} - {{controllerData.name}}
</h1>

<mat-dialog-content>
  <div class="incidents-info">

    <mat-form-field class="title-input">
      <input matInput type="text" name="title"
             placeholder="{{'todos.create.title'|translate}}"
             [formControl]="title" required/>
      <mat-hint>
        {{'todos.create.title.info'|translate}}
      </mat-hint>
    </mat-form-field>

    <mat-form-field>
      <textarea matInput type="text" name="description"
                 placeholder="{{'todos.create.description'|translate}}"
                 [formControl]="description"></textarea>
      <mat-hint>
        {{'todos.create.description.info'|translate}}
      </mat-hint>
    </mat-form-field>

    <app-assignee-search [assignee]='assignee' [assigneeList]='assigneeList'></app-assignee-search>

    <mat-form-field>
      <input matInput [matDatepicker]="dueDatePicker" [formControl]="dueDate"
             placeholder="{{'todos.create.dueDate'|translate}}">
      <mat-datepicker-toggle matSuffix [for]="dueDatePicker"></mat-datepicker-toggle>
      <mat-datepicker #dueDatePicker></mat-datepicker>
    </mat-form-field>

  </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>
    {{'word.cancel' | translate}}
  </button>
<!--  <button *ngIf="isEditing" class='delete' mat-button (click)="delete($event, currentTodoId)">-->
<!--    Delete-->
<!--  </button>-->
  <button mat-flat-button color="primary" (click)='createTodo()'>
    {{ (isEditing ? 'word.save' : 'word.create') | translate}}
  </button>
</mat-dialog-actions>

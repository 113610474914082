<!-- {{'user-management.role.controller.dashboard' | translate }} -->
<!-- {{'user-management.role.controller.dashboard.hint' | translate }} -->
<!-- {{'user-management.role.controller.vater_solution_dashboard' | translate }} -->
<!-- {{'user-management.role.controller.vater_solution_dashboard.hint' | translate }} -->
<!-- {{'user-management.role.controller.vater_solution_premium' | translate }} -->
<!-- {{'user-management.role.controller.vater_solution_premium.hint' | translate }} -->
<!-- {{'user-management.role.controller.contributor' | translate }} -->
<!-- {{'user-management.role.controller.contributor.hint' | translate }} -->
<!-- {{'user-management.role.controller.inspector' | translate }} -->
<!-- {{'user-management.role.controller.inspector.hint' | translate }} -->
<!-- {{'user-management.role.controller.manager' | translate }} -->
<!-- {{'user-management.role.controller.manager.hint' | translate }} -->

<img mat-card-image src="/assets/invite.svg"/>

<h1 mat-dialog-title>
  {{'user-management.invite.title' | translate}}
</h1>

<mat-dialog-content>
  <mat-form-field>
    <input matInput type="text" name="email" required
      placeholder="{{'user-management.invite.email.placeholder' | translate}}"
      [formControl]="email"/>
    <mat-hint>
      {{'user-management.invite.email.hint' | translate}}
    </mat-hint>
    <mat-error *ngIf="email.hasError('required')">
      {{'user-management.invite.email.required' | translate}}
    </mat-error>
    <mat-error *ngIf="!email.hasError('required') && email.hasError('email')">
      {{'helper.pleaseEnterValidEmail' | translate}}
    </mat-error>
    <mat-error *ngIf="email.hasError('duplicateEmail')">
      {{'user-management.invite.email.self-invite' | translate}}
    </mat-error>
  </mat-form-field>

  <mat-form-field>
    <input matInput type="text" name="first name"
      placeholder="{{'user-management.invite.first-name.placeholder' | translate}}"
      [formControl]="firstName"/>
    <mat-hint>
      {{'user-management.invite.first-name.hint' | translate}}
    </mat-hint>
  </mat-form-field>

  <mat-form-field>
    <input matInput type="text" name="last name"
      placeholder="{{'user-management.invite.last-name.placeholder' | translate}}"
      [formControl]="lastName"/>
    <mat-hint>
      {{'user-management.invite.last-name.hint' | translate}}
    </mat-hint>
  </mat-form-field>

  <mat-form-field>
    <mat-select
      placeholder="{{'user-management.role' | translate}}"
      [formControl]="access">
      <mat-option *ngFor="let r of roles" [value]="r">
        {{ r | translate }}
      </mat-option>
    </mat-select>
    <mat-hint *ngIf="!this.access || !this.access.value">
      {{ 'user-management.invite.role.hint' | translate}}
    </mat-hint>
    <mat-hint *ngIf="this.access && this.access.value">
      {{ (this.access.value + '.hint') | translate }}
    </mat-hint>
  </mat-form-field>

  <mat-form-field>
    <mat-select
      placeholder="{{'user-management.invite.department.placeholder' | translate}}"
      [formControl]="departmentId">
      <mat-option> {{'user-management.invite.department.none' | translate}} </mat-option>
      <mat-option *ngFor="let department of departments" [value]="department.id">
        {{department.name}}
      </mat-option>
    </mat-select>
    <mat-hint>
      {{ 'user-management.invite.department.hint' | translate }}
    </mat-hint>
  </mat-form-field>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button color="primary" (click)="invite()">
    {{'user-management.invite.invite' | translate}}
  </button>
  <button mat-button mat-dialog-close>
    {{'word.cancel' | translate}}
  </button>
</mat-dialog-actions>

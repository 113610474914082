import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthenticationService } from 'app/services/authentication.service';
import { MentionsService } from 'app/services/mentions/mentions.service';
import { UserManagementService } from 'app/services/user/user-management.service';
import { Todo } from '../todo.interface';
import { TodoDialogComponent } from '../todo-dialog/todo-dialog.component';
import { BusService } from '../../../services/bus.service';
import { TodoService } from '../../../services/todos/todo.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-todo-details-dialog',
  templateUrl: './todo-details-dialog.component.html',
  styleUrls: ['./todo-details-dialog.component.scss'],
})
export class TodoDetailsDialogComponent implements OnInit {

  todo: Todo = undefined;
  updateTodo: any;

  todoLink = undefined;
  todoLinkParams = {};

  canGoToTask = true;

  constructor(
    @Inject(MAT_DIALOG_DATA) data: any,
    private ref: MatDialogRef<TodoDetailsDialogComponent>,
    private userService: UserManagementService,
    private auth: AuthenticationService,
    private mentions: MentionsService,
    private router: Router,
    private dialog: MatDialog,
    private bus: BusService,
    private todoService: TodoService,
    private translate: TranslateService,
  ) {
    if (data) {
      this.todo = (data.todo) ? data.todo : data;

      if (this.todo.link) {
        if (data.canGoToTask !== undefined) {
          this.canGoToTask = data.canGoToTask;
        }
        this.todoLinkParams = Object.assign({}, {
          todo: this.todo.id,
          subject: this.todo.subject,
        }, router.parseUrl(this.todo.link).queryParams);
        this.todoLink = this.todo.link.split('?')[0];
      }
    }
  }

  ngOnInit(): void {
    // TODO: Needs fixing this
    this.updateTodo = (todo: Todo) => {
      if (todo && this.todo && todo.id === this.todo.id) {
        this.todo = Object.assign({}, todo);
      }
    };
    // this.bus.subscribe('updateTodo.event', this.updateTodo);
    this.bus.subscribe('todo.update.event', this.updateTodo);
  }

  /**
   * Go to task if it belongs to a subject
   */
  goToTask() {
    this.ref.close();
    this.ref.afterClosed().subscribe(() => {
      if (this.canGoToTask && this.todoLink) {
        this.router.navigate([this.todoLink], {
          queryParams: this.todoLinkParams,
        });
      }
    });
  }

  /**
   * Mark as done
   */
  markDone(event) {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }

    this.todoService.markTodoAsDone(this.todo.id).subscribe(() => {
      this.todo.done = true;
      this.bus.publish('todo.update.event', this.todo);
    });
  }

  /**
   * Reopen Todo
   */
  reopen(event) {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }

    this.todoService.reopenTodo(this.todo.id).subscribe(() => {
      this.todo.done = false;
      this.bus.publish('todo.update.event', this.todo);
    });
  }

  /**
   * Open edit from todo details dialog
   */
  edit(event) {
    if (event) {
      event.stopPropagation();
    }
    this.dialog.open(TodoDialogComponent, {
      width: '500px',
      maxHeight: '90vh',
      data: this.todo,
    }).afterClosed().subscribe(() => {
    });
  }

  /**
   * close dialog
   */
  close() {
    this.ref.close();
  }

  get title() {
    const title = this.translate.instant(this.todo.title);
    return title ?? this.todo.title;
  }

  get description() {
    let description = null;
    if (this.todo.description) {
      description = this.translate.instant(this.todo.description, {
        name: this.todo.subject
      });
    }
    return description ?? this.todo.description;
  }

}

import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {AccessLevel, AccessLevelService} from '../../../services/user/access-level.service';
import {FormControl, FormGroup} from '@angular/forms';
import {Authority, AuthorityService} from '../../../services/authority.service';
import { PickAuthorityDialogComponent } from './pick-authority-dialog/pick-authority-dialog.component';

@Component({
  selector: 'app-authority',
  templateUrl: './authority.component.html',
  styleUrls: ['./authority.component.scss']
})
export class AuthorityComponent implements OnInit {
  accessLevel: AccessLevel = null;
  fieldsBeingSaved = {};

  authority: Authority = null;

  formGroup = new FormGroup({
    name: new FormControl('', []),
    addressStreet: new FormControl('', []),
    addressPostcode: new FormControl('', []),
    addressCity: new FormControl('', []),
    addressState: new FormControl('', []),
    addressCountry: new FormControl('', []),
  });

  private timeout: any;

  constructor(
    private authorityService: AuthorityService,
    private access: AccessLevelService,
    private dialog: MatDialog,
  ) {
    this.access.checkAccess({
      context: 'controller',
    }).subscribe(response => this.accessLevel = response);
  }

  get canWrite() {
    return this.accessLevel && this.accessLevel.write;
  }

  public submit(field?): void {
    clearTimeout(this.timeout);
    if (field) {
      this.fieldsBeingSaved[field] = true;
    }
    this.timeout = setTimeout( () => {
      if (field) {
        this.fieldsBeingSaved[field] = false;
      }
      this.put();
    }, 1000);
  }

  ngOnInit(): void {
    this.authorityService.getAuthority()
      .subscribe(authority => {
        this.authority = authority;
        this.formGroup.patchValue(authority);
      });
  }

  pickAuthority(event) {
    event.stopPropagation();

    this.dialog.open(PickAuthorityDialogComponent, { width: '512px', maxHeight: '90vh' })
      .afterClosed()
      .subscribe(authority=> {
        this.formGroup.controls['name'].setValue(authority.title);
        this.formGroup.controls['addressStreet'].setValue(authority.addressStreet);
        this.formGroup.controls['addressCity'].setValue(authority.addressCity);
        this.formGroup.controls['addressPostcode'].setValue(authority.addressPostcode);
        this.formGroup.controls['addressState'].setValue(authority.addressState);
        this.formGroup.controls['addressCountry'].setValue(authority.addressCountry);

        this.put();
      }
      );
  }

  private put() {
    this.authorityService.updateAuthority({...this.authority, ...this.formGroup.value })
      .subscribe(authority => {
      });
  }
}

import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { AppConfig } from 'app/app.config';
import { AuthenticationService } from 'app/services/authentication.service';
import { BusService } from 'app/services/bus.service';
import { EventsService } from 'app/services/events.service';
import { getToken } from '../../../util/token';


const _Me = {};

@Component({
  selector: 'user-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss']
})
export class AvatarComponent implements OnInit, OnChanges, OnDestroy {

  @Input() user: {_id?: string, id?: string, avatarPath?: string} = _Me;

  hasAvatar = false;
  src = '';

  constructor(
    private auth: AuthenticationService,
    private bus: BusService,
    private events: EventsService,
  ) { }

  ngOnInit(): void {
    this.init();
    this.bus.subscribe(this.events.notified.user.avatarChanged, this.init, this);
  }

  ngOnDestroy() {
    this.bus.unsubscribe(this.events.notified.user.avatarChanged, this.init);
  }

  ngOnChanges() {
    this.init();
  }

  init() {
    if (this.user === _Me) {
      this.hasAvatar = !!this.auth.avatar;
      this.src = `${AppConfig.apiUrl}/account/profile/avatar/` +
      `?token=${getToken()}&rand=${Math.floor(Math.random() * 1000)}`
    } else if (this.user) {
      this.hasAvatar = (this.user.id || this.user._id) && !!this.user.avatarPath;
      this.src = `${AppConfig.apiUrl}/account/profile/avatar/${this.user._id || this.user.id}`
    } else {
      this.hasAvatar = false;
    }
  }
}

import { HttpClient } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { format } from 'date-fns';
import { catchError } from 'rxjs/operators';
import { saveAs } from 'file-saver';
import { AppConfig } from '../app.config';
import { DialogComponent } from '../components/dialog/dialog.component';

export function getFileName(disposition: string): string {
  const utf8FilenameRegex = /filename\*=UTF-8''([\w%\-\.]+)(?:; ?|$)/i;
  const asciiFilenameRegex = /filename=(["']?)(.*?[^\\])\1(?:; ?|$)/i;

  let fileName: string = null;
  if (utf8FilenameRegex.test(disposition)) {
    fileName = decodeURIComponent(utf8FilenameRegex.exec(disposition)[1]);
  } else {
    const matches = asciiFilenameRegex.exec(disposition);
    if (matches != null && matches[2]) {
      fileName = matches[2];
    }
  }
  return fileName;
}

export function downloadFromGenerator(url: string, filename: string, translate: TranslateService, http: HttpClient, dialog: MatDialog) {
  return http.get(url, { responseType: 'blob', observe: 'response' })
    .pipe(
      catchError(async err => {
        let downloadError = null;
        try {
          const text = await (err.error as Blob).text();
          downloadError = JSON.parse(text);
        } catch (error) {
          console.error('could not handle error');
          console.error(error);
          throw new Error('could not handle error');
        }

        throw downloadError;
      })
    )
    .subscribe((response) => {
      const backendFilename = getFileName(response.headers.get('Content-Disposition'));
      saveAs(response.body, backendFilename && backendFilename.length > 0 ? decodeURI(backendFilename) : filename);
    }, (error) => {
      const genericMessage = translate.instant('documents.generated.errorMessageGeneric');
      console.error('handling error', error);
      dialog.open(DialogComponent, { data: { html: genericMessage }, maxWidth: '400px' });
    });
}

export function getGeneratedDocumentLink(name: string, params: string, translate: TranslateService) {
  const identifier = translate.instant(name);
  return `${AppConfig.apiUrl}/document/generate/${identifier}${params ? '?' + params : ''}`;
}

export function downloadGeneratedDocument(name: string, params: string, translate: TranslateService, http: HttpClient, dialog: MatDialog) {
  const identifier = translate.instant(name);
  const filename = `${identifier} ${format(new Date(), 'dd-MM-yy')}.docx`;
  return downloadFromGenerator(`${AppConfig.apiUrl}/document/generate/${identifier}${params ? '?' + params : ''}`, filename, translate, http, dialog);
}

<div class="contents">
    <div>
      <h1 class="title">{{ 'user-expired.titel' | translate }}</h1>
      <br/>
      <p>{{ 'supervision.expired.message' | translate }}</p>
      <br>
      <mat-divider></mat-divider>
      <br><br>
      <p>
        {{'supervision.expired.relogin.msg' | translate}}
      </p>
      <br>
      <button mat-button color="primary" (click)="logout()">
        {{'user-expired.relogin.action' | translate}}
      </button>
    </div>
</div>
  
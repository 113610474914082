<h1 mat-dialog-title>
  {{ 'incidents.records.dpoDetails.title' | translate}}
</h1>

<mat-dialog-content>
  <div *ngIf="incident" class="incidents-info">
    <p class="current-status">
      {{ 'incidents.records.dpoDetails.currentStatus' | translate }}: {{ 'incidents.records.dpoDetails.currentStatus.' + incident.status | translate }}
    </p>

    <mat-form-field>
      <textarea  matInput type="text" name="measuresTaken"
        placeholder="{{'incidents.records.add.measuresTaken.placeholder' | translate}}"
        [formControl]="measuresTaken"></textarea>
      <mat-hint>
        {{'incidents.records.add.measuresTaken.placeholder.hint' | translate}}
      </mat-hint>
    </mat-form-field>

    <mat-form-field>
      <textarea  matInput type="text" name="dpoNotice"
        placeholder="{{'incidents.records.add.dpoNotice.placeholder' | translate}}"
        [formControl]="dpoNotice"></textarea>
      <mat-hint>
        {{'incidents.records.add.dpoNotice.placeholder.hint' | translate}}
      </mat-hint>
    </mat-form-field>

    <mat-checkbox color="primary" matInput [(ngModel)]="closeIncident">
      {{ ((incident.status === 'open')? 'incidents.records.dpoDetails.closeIncident' : 'incidents.records.dpoDetails.keepClosed') | translate }}
    </mat-checkbox>
  </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>
    {{'word.cancel' | translate}}
  </button>
  <button mat-flat-button color="primary" (click)="update()">
    {{ 'incidents.records.update.save' | translate}}
  </button>
</mat-dialog-actions>

import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AuditErrorDialogComponent } from '../../components/audit/form/components/error-dialog/error-dialog.component';
import { AuditLikeService } from '../../components/audit/form/util';
import { Audit, AuditQuestionnaire, AuditType, SESSION_SUPPRESS_FORM_ERRORS } from '../audits/audits.service';
import { RequestService } from '../request.service';

export type VendorAudit = Audit & { vendorId: number };

@Injectable({
  providedIn: 'root'
})
export class VendorAuditService implements AuditLikeService {
  private api = {
    root: '/vendors/audit',
    questions: '/questions',
    answer: '/answer',
    types: 'types'
  };

  constructor(
    private requestService: RequestService,
    private dialog: MatDialog
  ) { }

  handleFieldError(error: Error, payload: { fieldName: string }) {
    console.error('handling field error with following error: ', error);
    console.error('field name:', payload.fieldName);

    if (!sessionStorage.getItem(SESSION_SUPPRESS_FORM_ERRORS)) {
      this.dialog.open(AuditErrorDialogComponent, { width: '600px', maxHeight: '90vh' }).afterClosed().subscribe((keepShowing) => {
        if (!keepShowing) {
          sessionStorage.setItem(SESSION_SUPPRESS_FORM_ERRORS, 'true');
        }
      });
    } else {
      console.log('user asked to not show errors for this session, therefore not showing any');
    }
  }

  getVendorAuditTypes() {
    return this.requestService.get<[AuditType]>({
      uri: `${this.api.root}/${this.api.types}`
    });
  }

  getVendorAuditByVendorId(vendorId: number) {
    return this.requestService.get<VendorAudit>({
      uri: this.api.root + `/${vendorId}`
    });
  }

  getAllVendorAudits() {
    return this.requestService.get<VendorAudit[]>({
      uri: this.api.root + `/`
    });
  }

  getQuestionsForVendorAuditByVendorId(vendorId: number) {
    return this.requestService.get<AuditQuestionnaire>({
      uri: `${this.api.root}/${vendorId}${this.api.questions}`
    });
  }

  createVendorAudit(vendorId: number, type: string) {
    return this.requestService.post<{ vendorId: number; type: string }, VendorAudit>({
      uri: this.api.root,
      body: { vendorId, type }
    });
  }

  updateAnswerField<T>(vendorAuditId: number, fieldName: string, value: T) {
    return this.requestService.put<{ value: T }, VendorAudit>({
      uri: `${this.api.root}/${vendorAuditId}${this.api.answer}/${fieldName}`,
      body: { value }
    });
  }
}

<mat-dialog-content>
  <img src="/assets/create-template.svg"
    *ngIf="abstraction"/>
  <img src="/assets/apply-template.svg"
    *ngIf="application"/>

  <div class="progress-holder">
    <mat-spinner color="primary" *ngIf="updating || creating || applying">
    </mat-spinner>
  </div>

  <p *ngIf="updating">
    {{'supervision.templates.info.updating.part1' | translate }}
    {{data.event.newTemplateName}}
    <span *ngIf="data.event.newTemplateName != data.event.template.name">({{data.event.template.name}})</span>
    {{'supervision.templates.info.updating.part2' | translate }}
    {{data.event.controller.controllerName}}
    {{'supervision.templates.info.updating.part3' | translate }}
  </p>
  <p *ngIf="updated">
    {{'supervision.templates.info.updated.part1' | translate }}
    {{data.event.initiationEvent.newTemplateName}}
    <span *ngIf="data.event.initiationEvent.newTemplateName != data.event.initiationEvent.template.name">
      ({{data.event.initiationEvent.template.name}})</span>
    {{'supervision.templates.info.updated.part2' | translate }}
    {{data.event.controller.controllerName}}
    {{'supervision.templates.info.updated.part3' | translate }}
  </p>
  <p *ngIf="creating">
    {{'supervision.templates.info.creating.part1' | translate }}
    {{data.event.templateName}}
    {{'supervision.templates.info.creating.part2' | translate }}
    {{data.event.controller.controllerName}}
    {{'supervision.templates.info.creating.part3' | translate }}
  </p>
  <p *ngIf="created">
    {{'supervision.templates.info.created.part1' | translate }}
    {{data.event.initiationEvent.templateName}}
    {{'supervision.templates.info.created.part2' | translate }}
    {{data.event.controller.controllerName}}
    {{'supervision.templates.info.created.part3' | translate }}
  </p>
  <p *ngIf="applying">
    {{'supervision.templates.info.applying.part1' | translate }}
    {{data.event.template.name}}
    {{'supervision.templates.info.applying.part2' | translate }}
    {{data.event.controller.controllerName}}
    {{'supervision.templates.info.applying.part3' | translate }}
  </p>
  <p *ngIf="applied">
    {{'supervision.templates.info.applied.part1' | translate }}
    {{data.event.initiationEvent.template.name}}
    {{'supervision.templates.info.applied.part2' | translate }}
    {{data.event.controller.controllerName}}
    {{'supervision.templates.info.applied.part3' | translate }}
  </p>
</mat-dialog-content>


<mat-dialog-actions align="end">
  <button mat-dialog-close mat-flat-button color="primary" *ngIf="updated || created || applied">
    {{'word.ok' | translate}}
  </button>
</mat-dialog-actions>

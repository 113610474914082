import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '../../../../services/authentication.service';
import { TwoFactorService } from '../../../../services/user/two-factor.service';
import { getInstanceName, isNotCustomerInstance } from '../../../../util/environment';

@Component({
  selector: 'app-setup-two-factor',
  templateUrl: './setup.component.html',
  styleUrls: ['./setup.component.scss']
})
export class SetupTwoFactorComponent implements OnInit {

  alreadyEnabled = false;

  setupRequiredByOrg = false;
  promptAfterSignin = false;
  returnUrl = null;

  secret: string = null;
  userEmail: string = null;

  code = new FormControl('', [Validators.required]);

  step: 'intro' | 'pair' | 'done' = 'intro';

  sanitizedAppleOtp: SafeUrl = null;

  private totpIssuer = 'ECOMPLY';

  constructor(
    private twoFactor: TwoFactorService,
    private authService: AuthenticationService,
    private sanitizer: DomSanitizer,
    private route: ActivatedRoute
  ) {
    this.twoFactor.getInfo()
      .subscribe((info) => {
        this.alreadyEnabled = info !== null;
      });
    this.userEmail = localStorage.getItem('currentUserEmail');
    this.twoFactor.isRequired()
      .subscribe((required) => {
        this.setupRequiredByOrg = required.required;
      });

    this.route.queryParams.subscribe(params => {
      this.promptAfterSignin = params.setupRequired ? true : false;
      this.returnUrl = params.returnUrl;
    });
  }

  ngOnInit(): void {
    if (!isNotCustomerInstance()) {
      getInstanceName().subscribe((name) => {
        this.totpIssuer = 'ECOMPLY ' + name.toUpperCase();
      });
    }
  }

  get codeUrl() {
    return `otpauth://totp/ECOMPLY:${encodeURIComponent(this.userEmail)}?secret=${this.secret}&issuer=${this.totpIssuer}&digits=6&period=30`;
  }

  private get appleOtp() {
    return `apple-otpauth://totp/ECOMPLY:${encodeURIComponent(this.userEmail)}?secret=${this.secret}&issuer=ecomply.io&digits=6&period=30`;
  }

  setup() {
    this.twoFactor.createDevice()
      .subscribe((response) => {
        this.secret = response.secret;
        this.step = 'pair';

        this.sanitizedAppleOtp = this.sanitizer.bypassSecurityTrustUrl(this.appleOtp);
      });
  }

  verify() {
    if (!this.code.valid) {
      this.code.markAsTouched();
      return;
    }

    this.twoFactor.enable(this.code.value)
      .subscribe(() => {
        this.step = 'done';
        this.twoFactor.unlockAfterSetup();
      }, (error) => {
        console.error(error);
        this.code.setErrors({ wrongCode: true });
      });
  }

  redirect() {
    // this.authService
    if (this.returnUrl) {
      this.authService.redirectPublic(this.returnUrl);
    }
  }

}

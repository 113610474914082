<mat-dialog-content>
  <p>
    {{'pa.starter-pas.creating.hint' | translate}}
    <!-- Creating Processing Activities ... -->
  </p>
  <mat-progress-bar color="primary" [value]="progress"></mat-progress-bar>
  <div class="count">
    {{cursor}} / {{pas.length}}
  </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-flat-button color="primary" *ngIf="progress == 100" mat-dialog-close>
    {{'word.done' | translate}}
  </button>
</mat-dialog-actions>

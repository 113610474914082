<div *ngIf="audit && questionnaire" class="contents">
  <br><br>
  <div commentable comment-subject-title="{{ questionnaire.displayName }}" [comment-subject-codename]="'audit-' + audit.id">
    <todoable subject="{{ 'audit-' + audit.id }}" suggested-title="{{ questionnaire.displayName }}"></todoable>
    <h1 class="title" *ngIf="audit.title">{{ audit.title }}</h1>
    <h1 class="title" *ngIf="!audit.title">{{ questionnaire.displayName }}</h1>
  </div>
  <div class="header">
    <p class="subtitle">{{ "audits.form.subtitle" | translate }}</p>
    <p class="subtitle custom-subtitle" *ngIf="questionnaire && questionnaire.description">{{ questionnaire.description }}</p>
    <neat-action-bar>
      <button
        mat-button
        color="primary"
        (click)="editTitleDialog()"
        >{{ "audits.form.edit-title" | translate }}</button>
      <button
        mat-button
        *ngIf="isSupervisor"
        color="primary"
        (click)="delegate()"
      >{{ "audits.list.item.delegate" | translate }}</button>
      <a
        mat-flat-button
        color="primary"
        href="{{ downloadLink }}"
        target="blank"
        >{{ "audits.form.download" | translate }}</a
      >
      <feature-access-local *ngIf="isSupervisor" featureName="shared-documents">
        <button mat-icon-button
          matTooltip="{{ 'documents.share' | translate }}"
          (click)="shareReport()">
          <mat-icon color="primary">forward_to_inbox</mat-icon>
        </button>
      </feature-access-local>
    </neat-action-bar>

    <!-- {{ 'audit.list.item.status.open' | translate }} -->

    <div *ngIf="audit" class="metadata">
      <p>
        {{ "audit.list.item.last-changed" | translate }}
        {{ audit.updatedAt | localizedDate }}
      </p>
      <p>•</p>
      <p>
        {{ "audits.list.item.no-title" | translate }}
        {{ audit.createdAt | localizedDate }}
      </p>
      <p>•</p>
      <p>
        {{ "audit.list.item.status" | translate }}
        {{ "audit.list.item.status." + audit.status | translate }}
      </p>
      <ng-container *ngIf="audit.title">
        <p>•</p>
        <p>{{ questionnaire.displayName }}</p>
      </ng-container>
    </div>
  </div>

  <br><br>

  <!-- show task hint -->
  <div class="audit-task" *ngIf="ownAuditTodos.length > 0">
    <p>{{ 'audit.form.delegated.hint' | translate }}</p>
    <button mat-raised-button color="primary" (click)="closeTodos()">{{ 'audit.form.delegated.closeTodos' | translate }}</button>
  </div>

  <br><br>

  <div class="questions {{ audit.auditType }}" *ngIf="audit && questionnaire">
    <app-audit-form-category
      *ngFor="let category of questionnaire.questions"
      [audit]="audit"
      [category]="category"
      [auditConfiguration]="auditConfiguration"
      [readonly]="readonly"
    ></app-audit-form-category>
  </div>

  <neat-action-bar [borders]="false" *ngIf="audit && questionnaire" class="last">
    <button mat-flat-button color="primary" routerLink="/">{{'word.done' | translate}}</button>
  </neat-action-bar>
</div>

<div *ngIf="!audit || !questionnaire" class="contents">
  <div class="centered-spinner brand">
    <mat-spinner></mat-spinner>
  </div>
</div>


<br><br><br><br>

import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Vendor, VendorsService } from '../../../services/vendors/vendors.service';
import { VendorSearchDialogComponent } from '../../rpa/pa-external-processors/vendor-search-dialog/vendor-search-dialog.component';

@Component({
  selector: 'app-vendor-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss']
})
export class VendorInputComponent implements OnInit, OnChanges {
  @Input() value: string = null;
  @Output() vendorChange = new EventEmitter<Vendor>();

  selectedVendor: Vendor = null;

  fetchVendorPending = false;

  constructor(
    private vendorService: VendorsService,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.fetchVendor();
  }

  showPicker() {
    this.dialog.open(VendorSearchDialogComponent, { width: '480px', maxHeight: '90vh', data: { readonly: true } })
      .afterClosed()
      .subscribe((vendor) => {
        if (vendor) {
          this.selectedVendor = vendor;
          this.vendorChange.emit(vendor);
        }
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.value.currentValue) {
      this.fetchVendor();
    } else {
      this.selectedVendor = null;
    }
  }

  private fetchVendor() {
    if (this.value) {
      this.vendorService.getSingle(parseInt(this.value, 10))
      .subscribe((vendor) => {
        if (vendor) {
          this.selectedVendor = vendor;
        }
      });
    } else {
      this.selectedVendor = null;
    }
  }

}

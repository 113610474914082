import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { AuthenticationService } from 'app/services/authentication.service';
import { BusService } from 'app/services/bus.service';
import { EventsService } from 'app/services/events.service';

const _Me = {};

@Component({
  selector: 'user-name',
  templateUrl: './user-name.component.html',
  styleUrls: ['./user-name.component.scss']
})
export class UserNameComponent implements OnInit, OnChanges, OnDestroy {

  @Input() user: {
    id?: string; _id?: string;
    firstName?: string;
    first_name?: string;
    lastName?: string
    last_name?: string
  } = _Me;

  @Input() placeholder = '---';

  exists = false;
  firstName = '';
  lastName = '';

  constructor(
    private auth: AuthenticationService,
    private bus: BusService,
    private events: EventsService,
  ) { }

  ngOnInit(): void {
    this.init();
    this.bus.subscribe(this.events.notified.user.profileChanged, this.init, this);
  }

  ngOnChanges() {
    this.init();
  }

  ngOnDestroy() {
    this.bus.unsubscribe(this.events.notified.user.profileChanged, this.init);
  }

  init() {
    if (this.user === _Me) {
      this.exists = !!this.auth.token;
      this.firstName = this.auth.firstName;
      this.lastName = this.auth.lastName;
    } else if (this.user) {
      this.exists = !!this.user.id || !!this.user._id;
      this.firstName = this.user.firstName || this.user.first_name || '';
      this.lastName = this.user.lastName || this.user.lastName || '';
    } else {
      this.exists = false;
    }
  }
}

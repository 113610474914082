<div class="pa-external-processors">
  <h1 class="title">
    {{'pa.externalProcessors.title' | translate}}
  </h1>

  <ng-container *ngIf="processingActivities.length > 0">
    <p>
      {{'pa.externalProcessors.message' | translate}}
    </p>

    <div class="pane">
      <div class="pas" *ngIf="canWrite">
        <div class="pa"
            *ngFor="let pa of processingActivities"
            (click)="selectedPa = pa"
            [ngClass]="{'active': selectedPa == pa, 'resolved': resolved(pa)}">
            <mat-icon class="marker"
              color="primary"
              *ngIf="resolved(pa)">done</mat-icon>
            <div commentable
                comment-subject-codename="pa-{{pa.paId}}-external-processors"
                comment-subject-title="{{'pa.externalProcessors.commentSubject.part1' | translate}} {{pa.paName}} {{'pa.externalProcessors.commentSubject.part2' | translate}}">
              <todoable subject="pa-{{pa.paId}}-external-processors" suggested-title="{{'pa.externalProcessors.commentSubject.part1' | translate}} {{pa.paName}} {{'pa.externalProcessors.commentSubject.part2' | translate}}"></todoable>
                {{pa.paName}}
            </div>
            <a target="_blank" routerLink="/rpa/pa-details/{{ pa.paId }}">
              <mat-icon color="primary">edit</mat-icon>
            </a>
        </div>
      </div>

      <div class="pas" *ngIf="!canWrite">
        <div class="pa"
            *ngFor="let pa of myActivities"
            (click)="selectedPa = pa"
            [ngClass]="{'active': selectedPa == pa, 'resolved': resolved(pa)}">
            <mat-icon class="marker"
              color="primary"
              *ngIf="resolved(pa)">done</mat-icon>
          {{pa.paName}}
          <!-- <a *ngIf="pa.paOwner" target="_blank" routerLink="/rpa/custom-pa" [queryParams]="{id: pa.paId}">
            <mat-icon color="primary">edit</mat-icon>
          </a> -->
        </div>

        <br/>
        <mat-divider></mat-divider>
        <br/>
        <div class="showOther" (click)="showOther = !showOther">
          <span *ngIf="!showOther">{{'pa.internalProcessors.showOtherPas' | translate}}</span>
          <span *ngIf="showOther">{{'pa.internalProcessors.hideOtherPas' | translate}}</span>
        </div>
        <ng-container *ngIf="showOther">
          <br>
          <mat-divider></mat-divider>
          <br>
          <div class="pa"
              *ngFor="let pa of otherActivities"
              (click)="selectedPa = pa"
              [ngClass]="{'active': selectedPa == pa, 'resolved': resolved(pa)}">
              <mat-icon class="marker"
                color="primary"
                *ngIf="resolved(pa)">done</mat-icon>
            {{pa.paName}}
          </div>
        </ng-container>
      </div>

      <div class="vendors">
        <ng-container *ngIf="selectedPa">
          <p *ngIf="!canChange(selectedPa)" class="warning">
            <span>{{'word.NOTICE' | translate}}</span>
            {{'word.readonlyAccess' | translate}}
          </p>

          <div class="vendor" *ngFor="let vendor of associatedVendors(selectedPa)">
            <div class="avatar">
              <img *ngIf="vendor.logoUrl" [src]="vendor.logoUrl" onerror="this.onerror=null;this.src='/assets/default_vendor.svg';"/>
              <mat-icon *ngIf="!vendor.logoUrl" class="no-logo">location_city</mat-icon>
            </div>
            <div class="info">
              <div class="title">
                {{vendor.name}}
              </div>
              <div class="address">
                {{vendor.addressCity}}, {{vendor.addressCountry}}
              </div>
              <div class="email">
                {{vendor.email}}
              </div>
            </div>
            <button mat-icon-button color="primary" class="close" (click)="removeVendor(vendor)" *ngIf="canChange(selectedPa)">
              <mat-icon>close</mat-icon>
            </button>
          </div>


          <button mat-button class="add-vendor" (click)="addVendor()" *ngIf="canChange(selectedPa)">
            <mat-icon color="primary">add_circle_outline</mat-icon>
            <span>
              {{'pa.externalProcessors.addCompany' | translate}}
            </span>
          </button>
        </ng-container>
      </div>

      <div class="buttons">
        <button *ngIf="done" mat-raised-button color="primary" (click)="finish()">
          {{'word.done' | translate}}
        </button>
        <div *ngIf="!done" class="remaining">
          {{processingActivities.length - resolvedCount}}
          {{'pa.internalProcessors.paRemaining' | translate}}
        </div>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="processingActivities.length == 0">
    <br><br>
    <img src="/assets/empty.svg"/>
    <p>
      {{'pa.externalProcessors.empty' | translate}}
    </p>
    <br><br>
    <mat-divider></mat-divider>
    <br><br>
    <button mat-button color="primary" (click)="bypass()">
      {{'pa.internalProcessors.dontHave' | translate}}
    </button>
  </ng-container>
</div>

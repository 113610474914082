<h1 mat-dialog-title>{{ 'pa.duplicate.title' | translate }}</h1>
<div mat-dialog-content>
  <form name="form" [formGroup]="form" novalidate>
    <!-- {{ 'pa.duplicate.incompleteError' | translate }}-->
    <!-- {{ 'pa.duplicate.copy' | translate }}-->
    <mat-form-field>
      <input matInput placeholder="{{ 'custom-pa.namePa' | translate }}"
                      name="name" type="text"
                      formControlName="name" required autofocus/>
      <mat-error *ngIf="form.get('name').hasError('minlength') && !form.get('name').hasError('required')">
        {{ 'custom-pa.reqsNameMinLength' | translate }}
      </mat-error>
      <mat-error *ngIf="form.get('name').hasError('required')">
        {{ 'custom-pa.reqsName' | translate }}
      </mat-error>
    </mat-form-field>
    <mat-form-field>
      <mat-select placeholder="{{ 'custom-pa.responsibleDept' | translate }}"
        formControlName="department" required>
        <mat-option *ngFor="let department of departments" [value]="department.id">
          {{department.name}}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="form.get('department').hasError('required')">
          {{ 'custom-pa.reqsDept' | translate }}
        </mat-error>
    </mat-form-field>
    <mat-checkbox formControlName="includeExternalProcessors">{{ 'pa.duplicate.includeExternalProcessors' | translate }}</mat-checkbox>
    <mat-checkbox formControlName="includeInternalProcessors">{{ 'pa.duplicate.includeInternalProcessors' | translate }}</mat-checkbox>
  </form>
</div>
<div mat-dialog-actions align="end">
  <button type="button" mat-raised-button color="primary" [disabled]="pending" (click)="submit()">{{ 'pa.duplicate.action' | translate }}</button>
</div>

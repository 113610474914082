import { Component, Input, OnInit } from '@angular/core';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'neat-action-bar',
  templateUrl: './neat-action-bar.component.html',
  styleUrls: ['./neat-action-bar.component.scss']
})
export class NeatActionBarComponent implements OnInit {

  @Input() borders = true;
  @Input() align: 'start' | 'center' | 'end' = 'end';

  constructor() { }

  ngOnInit(): void {
  }
}

<div class="contents">
  <ng-container *ngIf="!loading && controller">
    <img src="{{badge()}}" alt="Gold Badge" />
    <h1 class="title">{{controller.name}}</h1>
    <a target="_blank" href="{{controller.url}}">{{controller.url}}</a>
    <br><br>
    <p>
      {{'badge.info.message.part1' | translate}}
      <b>{{controller.name}}</b>
      {{'badge.info.message.part2' | translate : { 'jur': jurisdiction.toUpperCase() } }}
    </p>
    <br>

    <ng-container *ngIf="dpo">
      <p>
        {{'badge.info.milestone1.part1' | translate}}
        <b>{{controller.name}}</b>
        {{'badge.info.milestone1.part2' | translate}}
        <br><br>
        <span class="name">{{dpo.name}}</span>
        <br>
        <a class="email" href="mailto:{{dpo.email}}">{{dpo.email}}</a>
      </p>
      <br><br>
    </ng-container>

    <ng-container>
      <p>
        {{'badge.info.milestone2.part1' | translate}}
        <b>{{controller.name}}</b>
        {{'badge.info.milestone2.part2' | translate}}
      </p>
      <br><br>
    </ng-container>

    <mat-divider></mat-divider>
    <br><br>
    <p>
      <span innerHTML="{{'badge.info.ad.part1' | translate : {'jur': jurisdiction.toUpperCase()} }}"></span>
      <a routerLink="/registration">
        {{'badge.info.ad.register' | translate}}
      </a>
      <span innerHTML="{{'badge.info.ad.part2' | translate}}"></span>
    </p>
    <img class="ecomply-logo" src="/assets/eclogo-check2.svg" />

    <br><br>
    <p class="small">
      <b>{{'word.NOTICE' | translate}}</b>:
      {{'badge.info.disclaimer.part1' | translate}}
      {{controller.name}}{{'badge.info.disclaimer.part2' | translate}}
      {{controller.name}}{{'badge.info.disclaimer.part3' | translate : {'jur': jurisdiction.toUpperCase()} }}
    </p>
    <br><br>

  </ng-container>

  <ng-container *ngIf="!loading && !controller">
    <mat-card>
      <img mat-card-image src="/assets/not-found.svg" />
      <mat-card-title>
        {{'badge.info.broken.title' | translate}}
      </mat-card-title>
      <mat-card-content>
        {{'badge.info.broken.message' | translate}}
      </mat-card-content>
    </mat-card>
  </ng-container>
</div>

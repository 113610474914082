import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-dsar-link-regen-confirm-dialog',
  templateUrl: './dsar-link-regen-confirm-dialog.component.html',
  styleUrls: ['./dsar-link-regen-confirm-dialog.component.scss']
})
export class DsarLinkRegenConfirmDialogComponent implements OnInit {

  constructor(
    private ref: MatDialogRef<DsarLinkRegenConfirmDialogComponent>
  ) { }

  ngOnInit(): void {
  }

  confirm() {
    this.ref.close(true);
  }
}

<mat-form-field>
  <input matInput [(ngModel)]="value" type="text" placeholder="{{'dsar.records.titleFilter.placeholder' | translate}}"/>
  <mat-hint>
    {{'dsar.records.titleFilter.hint' | translate}}
  </mat-hint>
</mat-form-field>

<mat-dialog-actions align="end">
  <button mat-button
    color="primary"
    [mat-dialog-close]="{value: ''}">{{'word.reset' | translate}}</button>
  <button mat-flat-button
    color="primary"
    [mat-dialog-close]="{value: value}">{{'word.apply' | translate}}</button>
</mat-dialog-actions>

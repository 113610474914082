<div class="contents">
  <br><br>
  <h1 class="title">
    {{'audit.report.title' | translate}}
  </h1>
  <br>
  <p>
    {{'audit.report.hint' | translate}}
  </p>

  <br><br>

  <div class="flow">
    <a mat-flat-button class="download" color="accent" href="{{ downloadURL }}"
        target="blank">
      <mat-icon color="primary">cloud_download</mat-icon><br>
      <span>
        {{'audit.report.actions.download-auto-gen' | translate}}
      </span>
    </a>
    <div class="arrow"></div>
    <p>
      {{'audit.report.actions.finalize' | translate}}
    </p>
    <div class="arrow"></div>
    <cool-upload #upload
      [upload-url]="uploadDocURL"
      upload-key="report"
      (uploaded)="docUploaded()">
      <span hint>
        {{'audit.report.actions.upload-doc' | translate}}
      </span>
    </cool-upload>
  </div>

  <br><br><br><br>

  <neat-action-bar>
    <button mat-flat-button
      [disabled]="!uploaded"
      color="primary"
      routerLink="/">{{'word.done' | translate}}</button>
  </neat-action-bar>

  <br><br><br>
</div>
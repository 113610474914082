import {Injectable} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import {BusService} from '../bus.service';
import {
  IPostOptions,
  RequestService
} from '../request.service';
import {Router} from '@angular/router';
import {EventsService} from '../events.service';
import jwt from 'jwt-decode';

interface IPostCredentialsRequest {
  firstName: string;
  lastName: string;
  password: string;
  email: string;
}

interface IPostCredentialsResponse {
  email: string;
  verified?: boolean;
}

export interface RegistrationToken {
  email: string;
  expiry: Date;
  invitedBy: string;
}

/*
  An interlayer service for registration between request service and registration component
*/
@Injectable()
export class RegistrationRequestService {

  private options: IPostOptions<IPostCredentialsRequest, IPostCredentialsResponse>;

  constructor(
    private bus: BusService,
    private events: EventsService,
    private requestService: RequestService,
    private router: Router
  ) {
    this.options = {
      uri: '/account/registration',
      body: {
        firstName: '',
        lastName: '',
        password: '',
        email: '',
      },
      handlers: {
        success: this.success.bind(this),
        error: this.error.bind(this)
      }
    };
    this.subscribe();
  }

  public success(user: IPostCredentialsResponse): void {
    this.bus.publish(this.events.received.registration.success, {
      email: user.email,
      verified: user.verified,
    });
  }

  public error(httpErrorResponse: HttpErrorResponse): void {
    this.bus.publish(this.events.received.registration.failure, httpErrorResponse);
  }

  public decodeToken(token: string) {
    try {
      const reg: any = jwt(token);
      if (reg) {
        return reg.registrationToken as RegistrationToken;
      }
    } catch (error) {
      console.error(error);
    }
    return null;
  }

  private request(data: any): void {
    // console.log('registration: ', data);
    this.bus.publish(this.events.notified.request.starting);
    Object.keys(data).forEach(key => {
      this.options.body[key] = data[key];
    });

    this.requestService.post<IPostCredentialsRequest, IPostCredentialsResponse>(this.options);
  }

  public subscribe(): void {
    this.bus.subscribe(this.events.requested.data.registration, this.request.bind(this));
  }
}

import { Component, OnInit, OnDestroy } from '@angular/core';

import { PaymentInfoGetLatestService } from '../../../services/payment/info/info.latest.get.service';
import { UserService } from '../../../services/user/user.service';
import { PaymentSubscriptionService } from 'app/services/payment/subscription.service';
import { PaymentInfoGetSubscriptionDetailsService } from 'app/services/payment/info/info.subscription-details.get.service';
import { BusService } from 'app/services/bus.service';
import { EventsService } from 'app/services/events.service';

@Component({
  selector: 'app-payment-header-authentication',
  templateUrl: './payment-header-authentication.component.html',
  styleUrls: ['./payment-header-authentication.component.scss']
})
export class PaymentHeaderAuthenticationComponent implements OnInit, OnDestroy {
  paymentStatus;

  constructor(
    private bus: BusService,
    private events: EventsService,
    private info: PaymentInfoGetLatestService,
    private user: UserService,
    private subscription: PaymentInfoGetSubscriptionDetailsService
  ) { }

  get needsAction() {
    if (this.paymentStatus) {
      return this.paymentStatus === 'incomplete';
    }

    return false;
  }

  private subscriptionDetailsSuccess(details) {
    this.paymentStatus = details.status;
  }

  private subscriptionDetailsFailure(error) {
    this.paymentStatus = null;
  }

  ngOnInit() {
    this.subscription.request().subscribe(result => {
      this.paymentStatus = result.status;
    }, error => {
      this.paymentStatus = undefined;
    });

    this.bus.subscribe(this.events.received.payment.info.subscriptionDetails.success, this.subscriptionDetailsSuccess, this);
    this.bus.subscribe(this.events.received.payment.info.subscriptionDetails.failure, this.subscriptionDetailsFailure, this);
  }

  ngOnDestroy() {
  }
}

import { TranslateService } from '@ngx-translate/core';
import { DpiaModel } from '../../../services/rpa/dpia.service';

function isProportional(dpia: DpiaModel) {
  return dpia.data.proportionality
    && dpia.data.proportionality.processingNecessary === true
    && dpia.data.proportionality.noAlternativeProcessing === true;
}

function isProportionalityAnswered(dpia: DpiaModel) {
  return dpia.data.proportionality
    && typeof dpia.data.proportionality.processingNecessary !== 'undefined'
    && typeof dpia.data.proportionality.noAlternativeProcessing !== 'undefined';
}

function getMeasureDisplay(translate: TranslateService, item: { display: { de: any; en: any } }) {
  if (!item) return null;

  const lang = translate.currentLang || translate.defaultLang;
  return item.display[lang] || null;
}

export {
  isProportional,
  isProportionalityAnswered,
  getMeasureDisplay
}

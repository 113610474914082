<mat-dialog-content>
  <h2 matDialogTitle>{{'incidents.records.delete.warning.title' | translate}}</h2>

  <p innerHTML="{{'incidents.records.delete.warning' | translate}}"></p>

  <div class="check">
    <mat-slide-toggle color="primary"
                      [(ngModel)]="deleteDocuments">
      {{'word.removeDocs' | translate}}
    </mat-slide-toggle>
  </div>

  <div class="actions">
    <button mat-button color="warn" (click)="confirm()" mat-dialog-close>{{'word.delete' | translate}}</button>
    <button mat-button mat-dialog-close>{{'word.cancel' | translate}}</button>
  </div>
</mat-dialog-content>
<mat-dialog-content>
  {{'supervision.templates.delete.title.part1' | translate }}
  {{ template.name }}
  {{'supervision.templates.delete.title.part2' | translate }}
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>{{'word.cancel' | translate}}</button>
  <button mat-button color="warn" (click)="delete()">{{'word.delete' | translate}}</button>
</mat-dialog-actions>

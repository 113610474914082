import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';

import { AppConfig } from '../../../app.config';

import { BusService } from '../../../services/bus.service';
import { EventsService } from '../../../services/events.service';
import { AccessLevelService, AccessLevel } from '../../../services/user/access-level.service';
import { getToken } from '../../../util/token';


@Component({
  selector: 'dp-goals',
  templateUrl: './dp-goals.component.html',
  styleUrls: ['./dp-goals.component.scss']
})
export class DpGoalsComponent implements OnInit, OnDestroy {

  companyName: string;
  @ViewChild('upload') upload;

  accessLevel: AccessLevel;

  constructor(
    private bus: BusService,
    private events: EventsService,
    private accessService: AccessLevelService,
  ) {
    this.accessService.checkAccess({
      context: 'dp-goals',
    }).subscribe(response => this.accessLevel = response);
  }

  ngOnInit() {
    this.subscribe();
    this.bus.publish(this.events.requested.data.controller.name);
    this.bus.publish(this.events.requested.data.controller.hasGoalsDoc);
  }

  ngOnDestroy() {
    this.unsubscribe();
  }

  public updateCompanyName(data) {
    if (data.controllerName) {
      this.companyName = data.controllerName;
    }
  }

  public updateGoalsDoc(data) {
    if (data.hasGoalsDoc) {
      //Well the goals are already here,
      //so the task can be considered done.
      //
      this.progress(1);
      this.upload.load(
        `${AppConfig.apiUrl}/controllers/goals/`+
        `?token=${getToken()}`);
    }
  }

  public progress(progress) {
    this.bus.publish(this.events.requested.action.tasks.progressControllerTask, {
      progress: progress,
      workflowCodename: 'path_to_gdpr',
      taskCodename: 'ptg_dp_goals',
    });
  }

  public get docs() {
    return `assets/documents/${localStorage['lang'] || 'en'}`;
  }

  public get canWrite() {
    return this.accessLevel && this.accessLevel.write;
  }

  subscribe() {
    this.bus.subscribe(this.events.received.data.controller.name.success, this.updateCompanyName, this);
    this.bus.subscribe(this.events.received.data.controller.hasGoalsDoc.success, this.updateGoalsDoc, this);
  }

  unsubscribe() {
    this.bus.unsubscribe(this.events.received.data.controller.name.success, this.updateCompanyName);
    this.bus.unsubscribe(this.events.received.data.controller.hasGoalsDoc.success, this.updateGoalsDoc);
  }
}

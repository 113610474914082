<div class="pa-assigning">
  <h1 class="title">
    {{'pa.assign.title' | translate}}
  </h1>
  <ng-container *ngIf="departments.length > 0 && processingActivities.length > 0">
    <p innerHTML="{{'pa.assign.hint' | translate}}">
    </p>
    <p *ngIf="accessLevel && !accessLevel.write" class="warning">
      <span>{{'word.NOTICE' | translate}}</span>
      {{'word.readonlyAccess' | translate}}
    </p>

    <div class="pane">
      <div class="pas">
        <div class="pa"
            *ngFor="let pa of processingActivities"
            (click)="selectedPa = pa"
            [ngClass]="{'active': selectedPa == pa, 'done' : pa.assignedDepartment}">
          <mat-icon class="marker"
            color="primary"
            *ngIf="pa.assignedDepartment">done</mat-icon>
          <div commentable
            comment-subject-codename="pa-{{pa.paId}}-assignment"
            comment-subject-title="{{'pa.assign.commentSubject.part1' | translate}} {{pa.paName}} {{'pa.assign.commentSubject.part2' | translate}}">
            <todoable subject="pa-{{pa.paId}}-assignment" suggested-title="{{'pa.assign.commentSubject.part1' | translate}} {{pa.paName}} {{'pa.assign.commentSubject.part2' | translate}}"></todoable>
          {{pa.paName}}
          </div>
          <a target="_blank" routerLink="/rpa/pa-details/{{ pa.paId }}">
            <mat-icon color="primary">edit</mat-icon>
          </a>
        </div>
      </div>

      <div class="departments" [ngClass]="{'active': selectedPa}">
        <div class="box">
          <div class="department"
            *ngFor="let department of departments"
            (click)="assignDepartment(department)"
            [ngClass]="{'active': selectedPa && selectedPa.assignedDepartment == department.id}">
            <div>
            {{department.name}}
            <br/>
            <a class="count">{{assignedCount(department)}}</a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="buttons">
      <button *ngIf="done" mat-raised-button color="primary" (click)="finish()">
        {{'pa.assign.complete' | translate}}
      </button>
      <div *ngIf="!done" class="remaining">
        {{processingActivities.length - assignedCount()}}
        {{'pa.assign.remaining' | translate}}
      </div>
    </div>
  </ng-container>

  <div class="empty" *ngIf="departments.length == 0 || processingActivities.length == 0">
    <br><br>
    <img src="/assets/empty.svg"/>
    <p>
      <span  *ngIf="departments.length == 0">
        {{'pa.assign.empty.noDepartments' | translate}}
      </span>
      <br>
      <span *ngIf="processingActivities.length == 0">
        {{'pa.assign.empty.noPAs' | translate}}
      </span>
    </p>
    <br>
    <button mat-raised-button color="primary" routerLink="">
      {{'word.doNotRush' | translate}}
    </button>
  </div>
</div>

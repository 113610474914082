<footer [ngClass]="{'pushed': pushed}">
  <div class="container">
    <p class="text-muted">{{ 'footer.foot.rightsreserved' | translate }}
      <a routerLink="imprint">{{ 'footer.foot.imprint' | translate }}</a> |
      <a routerLink="{{'link.privacy-policy' | translate}}">{{ 'footer.foot.privacypol' | translate }}</a> | 
      <a routerLink="{{'link.terms' | translate}}">{{ 'footer.foot.termsconditions' | translate }}</a> |
      <display-environment not="vater"><a routerLink="{{'link.cookie-policy' | translate}}">{{ 'footer.foot.cookie-policy' | translate }}</a> | </display-environment>
      <a routerLink="changelog">{{ 'footer.foot.changelog' | translate}}</a> |
      <language></language>
    </p>
  </div>
</footer>

<div class="actions-metadata-info metadata-toms">
  <ng-container *ngIf="event.eventName === 'ENTRY.SAVE'">
    <ng-container *ngIf="!entry">
      <div class="event-loading metadata-center">
        <mat-icon>bar_chart</mat-icon>
        <span>{{ 'events.metadata.loading' | translate }}</span>
      </div>
    </ng-container>
    <ng-container *ngIf="entry">
      <div class="event-left">
        <span class="specimen">{{ entry.title | slice:0:140 }}</span>
        <span class="type">
          {{ 'events.metadata.toms.entry' | translate }}
        </span>
      </div>
      <div class="event-action">
        <span class="icon">
          <mat-icon>check_circle_outline</mat-icon>
        </span>
        <span class="action">
          {{ 'events.metadata.toms.updated' | translate }}
        </span>
      </div>
    </ng-container>
  </ng-container>
  <ng-container *ngIf="event.eventName === 'ENTRY.EXPLANATION_UPDATE'">
    <ng-container *ngIf="!entry">
      <div class="event-loading metadata-center">
        <mat-icon>bar_chart</mat-icon>
        <span>{{ 'events.metadata.loading' | translate }}</span>
      </div>
    </ng-container>
    <ng-container *ngIf="entry">
      <div class="event-left">
        <span class="specimen">{{ entry.title | slice:0:140 }}</span>
        <span class="type">
          {{ 'events.metadata.toms.entry' | translate }}
        </span>
      </div>
      <div class="event-action">
        <span class="icon">
          <mat-icon>check_circle_outline</mat-icon>
        </span>
        <span class="action">
          {{ 'events.metadata.toms.updatedExplanation' | translate }}
        </span>
      </div>
    </ng-container>
  </ng-container>
  <ng-container *ngIf="event.eventName === 'ENTRY.APPLICABLE_UPDATE'">
    <ng-container *ngIf="!entry">
      <div class="event-loading metadata-center">
        <mat-icon>bar_chart</mat-icon>
        <span>{{ 'events.metadata.loading' | translate }}</span>
      </div>
    </ng-container>
    <ng-container *ngIf="entry">
      <div class="event-left">
        <span class="specimen">{{ entry.title | slice:0:140 }}</span>
        <span class="type">
          {{ 'events.metadata.toms.entry' | translate }}
        </span>
      </div>
      <div class="event-action">
        <span class="icon">
          <mat-icon>check_circle_outline</mat-icon>
        </span>
        <span class="action">
          {{ 'events.metadata.toms.updatedApplicable' | translate }}
        </span>
      </div>
    </ng-container>
  </ng-container>
</div>

import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {NavigationEnd, Router, ActivatedRoute, Params} from '@angular/router';
import {DatePipe} from '@angular/common';
import { BusService } from 'app/services/bus.service';
import { EventsService } from 'app/services/events.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { UserManagementService } from 'app/services/user/user-management.service';
import { DocumentsUploadDialogComponent } from 'app/components/documents/upload/documents-upload-dialog.component';
import { DsarResourceService } from 'app/services/dsar/dsar-resource.service';
import { DepartmentService } from 'app/services/departments/department.service';
import { take } from 'rxjs/operators';
import { DeleteDsarComponent } from '../delete-confirm-dialog/delete.component';
import { CommentBoxDialogComponent } from '../../../comment-box/comment-box-dialog/comment-box-dialog.component';
import { UserService } from '../../../../services/user/user.service';

@Component({
  selector: 'app-dsar-record-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.scss']
})
export class DsarRecordDetailsComponent implements OnInit, OnDestroy {
  record = {};
  // TODO: Interface this
  dsar = {
    id: undefined,
    dsarId: undefined,
    recordableId: undefined,
    status: 'recordable.open',
    title: '',
    departmentId: undefined,
    receivedOn: new Date(),
    completionOn: undefined,
    details: '',
    subjectEmail: '',
    requestType: 'dsar.request_type.unknown',
    subjectComments: '',
    customFields: {
      subjectRelation: '',
      subjectName: '',
      lgpdCPF: '',
      lgpdRG: '',
      jur: '',
    },
  };
  department;
  allDepartments = [];
  documents = [];
  departmentId = undefined;
  changed = false;

  constructor(
    private bus: BusService,
    private events: EventsService,
    private dialog: MatDialog,
    private dsarService: DsarResourceService,
    private userService: UserManagementService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private departmentService: DepartmentService,
    private datePipe: DatePipe,
    private user: UserService,
  ) {
    departmentService.requestGetAll().subscribe(departments => {
      this.allDepartments = departments;
    });
  }

  get uploadUrl() {
    return '/recordables/' + this.dsar.recordableId + '/file';
  }

  save() {
    Object.assign(this.dsar, {
      receivedOn: new Date(this.transformDate(this.dsar.receivedOn)),
      completionOn: (this.dsar.completionOn !== undefined) ? new Date(this.transformDate(this.dsar.completionOn)) : undefined,
    });
    if (this.dsar.id) {
      this.dsarService.updateDsar({
        ...this.dsar,
        id: this.dsar.dsarId,
      }).subscribe(() => {
        this.changed = false;
        this.bus.publish(this.events.notified.dsar.status.updated);
      });
    } else {
      this.dsarService.create(this.dsar).subscribe(response => {
        this.dsar.id = response.id;
        this.changed = false;
        this.router.navigate(['/dsar/records/' + this.dsar.id]);
      });
    }
  }

  transformDate(date: Date) {
    return this.datePipe.transform(date, 'yyyy-MM-dd');
  }

  get statusOpen() {
    return this.dsar?.status === 'recordable.open';
  }

  set statusOpen(open: boolean) {
    if (open && !this.statusOpen) {
      this.dsar.status = 'recordable.open';
      if (this.dsar.id) {
        this.save();
      }
    }

    if (!open && this.statusOpen) {
      this.dsar.status = 'recordable.archived';
      if (this.dsar.id) {
        this.save();
      }
    }
  }

  addDocument(response) {
    this.documents.push(response.body);
  }

  recordError(error) {
    console.error(error);
  }

  updateList() {
    this.bus.publish(this.events.requested.data.dsar.single, this.dsar.dsarId);
  }

  receiveDepartment(department) {
    this.department = department;
  }

  departmentError(error) {
    console.error(error);
  }

  delete() {
    this.dialog.open(DeleteDsarComponent,{
      width: '512px',
      maxHeight: '90vh',
      data: 'hi',
    });
  }
  doDelete(deleteDocs) {
    this.dsarService.deleteDsar(this.dsar.dsarId, deleteDocs).subscribe(() => {
      this.router.navigate(['/dsar/records']);
    });
  }

  ngOnInit() {
    this.bus.subscribe(this.events.received.data.dsar.single.success, this.receiveRecord.bind(this));
    this.bus.subscribe(this.events.received.data.dsar.single.failure, this.recordError.bind(this));
    this.bus.subscribe('delete-dsar', this.doDelete.bind(this));

    /*
    this.bus.subscribe(this.events.received.data.department.single.success, this.receiveDepartment, this);
    this.bus.subscribe(this.events.received.data.department.single.failure, this.departmentError, this);
    */

    this.activatedRoute.params.pipe(take(1)).subscribe((params: Params) => {
      if (params.id) {
        this.bus.publish(this.events.requested.data.dsar.single, params.id);
      }
    });
  }

  ngOnDestroy() {
    this.bus.unsubscribe('delete-dsar', this.doDelete);
  }

  private receiveRecord(record) {
    this.record = record;
    this.dsar = record.dsar;

    this.documents = record.documents;

    if (record.dsar.departmentId) {
      this.departmentService.requestGetAll().subscribe(deps => {
        this.department = deps.find(d => d.id === record.dsar.departmentId);
      });
    } else {
      this.department = undefined;
    }
  }

  currentSelectedLanguage() {
    return this.user.currentSelectedLanguage();
  }
}

<div class="report">
  <h1 class="title">
    {{ 'documents.title' | translate }}
  </h1>
  <p>
    {{ 'documents.hint' | translate }}
  </p>
  <div class="docs">
    <mat-card>
      <img mat-card-image src="/assets/docs/rpas.svg"/>
      <mat-card-title>
        {{'documents.rpa.title' | translate}}
      </mat-card-title>
      <mat-card-content>
        {{'documents.rpa.hint' | translate}}
      </mat-card-content>
      <mat-card-actions align="end">
        <button mat-icon-button color="primary" matTooltip="{{ 'documents.download.generic' | translate }}" [matMenuTriggerFor]="rpaDownloadMenu">
          <mat-icon>cloud_download</mat-icon>
        </button>

        <button *ngIf="accessToSharedDocuments && isSupervisor" mat-icon-button color="primary" matTooltip="{{ 'documents.share' | translate }}" [matMenuTriggerFor]="rpaShareMenu">
          <mat-icon color="primary">forward_to_inbox</mat-icon>
        </button>

        <mat-menu #rpaDownloadMenu="matMenu" [overlapTrigger]="false">
          <button mat-button color="primary"
                  (click)="downloadPdf($event, false)">
            {{ 'documents.download.pdf' | translate }}
          </button>
          <br>
          <button mat-button color="primary"
            (click)="downloadExcel(false)">
            {{ 'documents.download.xls' | translate }}
          </button>
          <br>
          <button mat-button (click)="downloadWord()" color="primary">
            {{ 'documents.download.docx' | translate }}
          </button>
          <br>
          <a mat-button color="primary"
            href="{{jsonLink}}" target="_blank">
            {{'documents.download.json' | translate}}
          </a>
        </mat-menu>

        <mat-menu #rpaShareMenu="matMenu" [overlapTrigger]="false">
          <button mat-button color="primary"
                  (click)="sharePdf($event, false)">
            {{ 'documents.share.pdf' | translate }}
          </button>
          <br>
          <button mat-button color="primary"
                  (click)="shareExcel($event, false)">
            {{ 'documents.share.xls' | translate }}
          </button>
          <br>
          <button mat-button (click)="shareWord($event)" color="primary">
            {{ 'documents.share.docx' | translate }}
          </button>
          <br>
        </mat-menu>
      </mat-card-actions>
    </mat-card>

      <mat-card>
        <img mat-card-image src="/assets/docs/rpas-pob.svg"/>
        <mat-card-title>
          {{'documents.rpa.title.onBehalf' | translate}}
        </mat-card-title>
        <mat-card-content>
          {{'documents.rpa-on-behalf.hint' | translate}}
        </mat-card-content>
        <mat-card-actions align="end">

          <button mat-icon-button color="primary" matTooltip="{{ 'documents.download.generic' | translate }}" [matMenuTriggerFor]="pobDownloadMenu">
            <mat-icon>cloud_download</mat-icon>
          </button>

          <button *ngIf="accessToSharedDocuments && isSupervisor" mat-icon-button color="primary" matTooltip="{{ 'documents.share' | translate }}" [matMenuTriggerFor]="pobShareMenu">
            <mat-icon color="primary">forward_to_inbox</mat-icon>
          </button>

          <mat-menu #pobDownloadMenu="matMenu" [overlapTrigger]="false">
            <button mat-button color="primary"
                    (click)="downloadPdf($event, true)">
              {{ 'documents.download.pdf' | translate }}
            </button>
            <br>
            <button mat-button color="primary"
                    (click)="downloadExcel(true)">
              {{ 'documents.download.xls' | translate }}
            </button>
          </mat-menu>

          <mat-menu #pobShareMenu="matMenu" [overlapTrigger]="false">
            <button mat-button color="primary"
                    (click)="sharePdf($event, true)">
              {{ 'documents.share.pdf' | translate }}
            </button>
            <br>
            <button mat-button color="primary"
                    (click)="shareExcel($event, true)">
              {{ 'documents.share.xls' | translate }}
            </button>
          </mat-menu>
      </mat-card-actions>
    </mat-card>

    <display-language language="de">
      <mat-card>
        <img mat-card-image src="/assets/docs/rpas.svg"/>
        <mat-card-title>
          {{'documents.art13PaInfo.title' | translate}}
        </mat-card-title>
        <mat-card-content>
          {{'documents.art13PaInfo.hint' | translate}}
        </mat-card-content>
        <mat-card-actions align="end">

          <button mat-icon-button matTooltip="{{'documents.download.docx' | translate}}" color="primary"
            (click)="downloadArt13()" target="_blank">
            <mat-icon>cloud_download</mat-icon>
          </button>

          <button *ngIf="accessToSharedDocuments && isSupervisor" mat-icon-button
            matTooltip="{{ 'documents.share' | translate }}"
            (click)="shareArt13()">
            <mat-icon color="primary">forward_to_inbox</mat-icon>
          </button>
        </mat-card-actions>
      </mat-card>
    </display-language>

    <mat-card>
      <img mat-card-image src="/assets/docs/toms.svg"/>
      <mat-card-title>
        {{'documents.toms.title' | translate}}
      </mat-card-title>
      <mat-card-content>
        {{'documents.toms.hint' | translate}}
      </mat-card-content>
      <mat-card-actions align="end">
        <a mat-icon-button color="primary"
          href="{{tomsLink}}" matTooltip="{{'documents.download.pdf' | translate}}" target="_blank">
          <mat-icon>cloud_download</mat-icon>
        </a>

          <button *ngIf="accessToSharedDocuments && isSupervisor" mat-icon-button
            matTooltip="{{ 'documents.share' | translate }}"
            (click)="shareByLink(tomsLink)">
          <mat-icon color="primary">forward_to_inbox</mat-icon>
        </button>
      </mat-card-actions>
    </mat-card>

    <mat-card>
      <img mat-card-image src="/assets/docs/deletion-concept.svg"/>
      <mat-card-title>
        {{'documents.erasureTimes.title' | translate}}
      </mat-card-title>
      <mat-card-content>
        {{'documents.erasureTimes.hint' | translate}}
      </mat-card-content>
      <mat-card-actions align="end">
        <a mat-icon-button color="primary"
          href="{{erasureTimesLink}}" matTooltip="{{'documents.download.pdf' | translate}}" target="_blank"><mat-icon>cloud_download</mat-icon></a>
        <button *ngIf="accessToSharedDocuments && isSupervisor" mat-icon-button
          matTooltip="{{ 'documents.share' | translate }}"
          (click)="shareByLink(erasureTimesLink)">
          <mat-icon color="primary">forward_to_inbox</mat-icon>
        </button>
      </mat-card-actions>
    </mat-card>

    <mat-card *ngIf="hasGoals">
      <img mat-card-image src="/assets/docs/goals.svg"/>
      <mat-card-title>
        {{'documents.dpgoals.title' | translate}}
      </mat-card-title>
      <mat-card-content>
        {{'documents.dpgoals.hint' | translate}}
      </mat-card-content>
      <mat-card-actions align="end">
        <button mat-button color="primary"
          routerLink="/organisation/dp-goals">{{'documents.update' | translate}}</button>
        <a mat-icon-button color="primary"
          href="{{goalsLink}}" matTooltip="{{'documents.download.generic' | translate}}" target="_blank"><mat-icon>cloud_download</mat-icon></a>

        <button *ngIf="accessToSharedDocuments && isSupervisor" mat-icon-button
          matTooltip="{{ 'documents.share' | translate }}"
          (click)="shareByLink(goalsLink)">
          <mat-icon color="primary">forward_to_inbox</mat-icon>
        </button>
      </mat-card-actions>
    </mat-card>

    <mat-card *ngIf="hasDpo">
      <img mat-card-image src="/assets/docs/dpo.svg"/>
      <mat-card-title>
        {{'documents.dpo.title' | translate}}
      </mat-card-title>
      <mat-card-content>
        {{'documents.dpo.hint' | translate}}
      </mat-card-content>
      <mat-card-actions align="end">
        <button mat-button color="primary"
          routerLink="/organisation/dpo">{{'documents.update' | translate}}</button>
        <a mat-icon-button color="primary"
          href="{{dpoLink}}" matTooltip="{{'documents.download.generic' | translate}}" target="_blank"><mat-icon>cloud_download</mat-icon></a>
        <button *ngIf="accessToSharedDocuments && isSupervisor" mat-icon-button
          matTooltip="{{ 'documents.share' | translate }}"
          (click)="shareByLink(dpoLink)">
          <mat-icon color="primary">forward_to_inbox</mat-icon>
        </button>
      </mat-card-actions>
    </mat-card>

    <mat-card>
      <img mat-card-image src="/assets/docs/dpa.svg"/>
      <mat-card-title>
        {{'documents.dpa.title' | translate}}
      </mat-card-title>
      <mat-card-content>
        {{'documents.dpa.hint' | translate}}
      </mat-card-content>
      <mat-card-actions align="end">
        <button mat-flat-button
          routerLink="/vendors"
          color="primary">{{'documents.dpa.action' | translate}}</button>
      </mat-card-actions>
    </mat-card>

    <!-- {{ 'documents.generated.errorMessageGeneric' | translate }} -->

    <feature-access-local showWhenHasNot="true" featureName="hide-generator-documents">
      <display-language language="de,en">
        <!-- {{ 'documents.generated.art13applicants' | translate }} -->
        <!-- {{ 'documents.generated.art13customers' | translate }} -->
        <!-- {{ 'documents.generated.art13employees' | translate }} -->
        <!-- {{ 'documents.generated.art9lgpdapplicants' | translate }} -->
        <!-- {{ 'documents.generated.art9lgpdcustomers' | translate }} -->
        <!-- {{ 'documents.generated.art9lgpdemployees' | translate }} -->
        <!-- {{ 'documents.generated.confidentialityInternal' | translate }} -->
        <!-- {{ 'documents.generated.confidentialityExternal' | translate }} -->
        <!-- {{ 'documents.generated.employeePhotos' | translate }} -->
        <!-- {{ 'documents.generated.dpaController' | translate }} -->
        <!-- {{ 'documents.generated.dpGuidelines' | translate }} -->

        <mat-card *ngIf="hasDpo">
          <img mat-card-image src="/assets/docs/generic.svg"/>
          <mat-card-title>
            {{'documents.generated.art13applicants.title' | translate}}
          </mat-card-title>
          <mat-card-content>
            {{'documents.generated.art13applicants.hint' | translate}}
          </mat-card-content>
          <mat-card-actions align="end">
            <button mat-icon-button matTooltip="{{ 'documents.download.generic' | translate}}" (click)="downloadDocument('documents.generated.art13applicants')"
                    color="primary"><mat-icon>cloud_download</mat-icon></button>

            <button *ngIf="accessToSharedDocuments && isSupervisor" mat-icon-button
                    matTooltip="{{ 'documents.share' | translate }}"
                    (click)="shareGenerated('documents.generated.art13applicants')">
              <mat-icon color="primary">forward_to_inbox</mat-icon>
            </button>
          </mat-card-actions>
        </mat-card>
        <mat-card *ngIf="hasDpo">
          <img mat-card-image src="/assets/docs/generic.svg"/>
          <mat-card-title>
            {{'documents.generated.art13customers.title' | translate}}
          </mat-card-title>
          <mat-card-content>
            {{'documents.generated.art13customers.hint' | translate}}
          </mat-card-content>
          <mat-card-actions align="end">
            <button mat-icon-button matTooltip="{{ 'documents.download.generic' | translate}}" (click)="downloadDocument('documents.generated.art13customers')"
                    color="primary"><mat-icon>cloud_download</mat-icon></button>
            <button *ngIf="accessToSharedDocuments && isSupervisor" mat-icon-button
                    matTooltip="{{ 'documents.share' | translate }}"
                    (click)="shareGenerated('documents.generated.art13customers')">
              <mat-icon color="primary">forward_to_inbox</mat-icon>
            </button>
          </mat-card-actions>
        </mat-card>
        <mat-card *ngIf="hasDpo">
          <img mat-card-image src="/assets/docs/generic.svg"/>
          <mat-card-title>
            {{'documents.generated.art13employees.title' | translate}}
          </mat-card-title>
          <mat-card-content>
            {{'documents.generated.art13employees.hint' | translate}}
          </mat-card-content>
          <mat-card-actions align="end">
            <button mat-icon-button matTooltip="{{ 'documents.download.generic' | translate}}" (click)="downloadDocument('documents.generated.art13employees')"
                    color="primary"><mat-icon>cloud_download</mat-icon></button>
            <button *ngIf="accessToSharedDocuments && isSupervisor" mat-icon-button
                    matTooltip="{{ 'documents.share' | translate }}"
                    (click)="shareGenerated('documents.generated.art13employees')">
              <mat-icon color="primary">forward_to_inbox</mat-icon>
            </button>
          </mat-card-actions>
        </mat-card>
        <mat-card>
          <img mat-card-image src="/assets/docs/generic.svg"/>
          <mat-card-title>
            {{'documents.generated.confidentialityInternal.title' | translate}}
          </mat-card-title>
          <mat-card-content>
            {{'documents.generated.confidentialityInternal.hint' | translate}}
          </mat-card-content>
          <mat-card-actions align="end">
            <button mat-icon-button matTooltip="{{ 'documents.download.generic' | translate}}" (click)="downloadDocument('documents.generated.confidentialityInternal')"
                    color="primary"><mat-icon>cloud_download</mat-icon></button>
            <button *ngIf="accessToSharedDocuments && isSupervisor" mat-icon-button
                    matTooltip="{{ 'documents.share' | translate }}"
                    (click)="shareGenerated('documents.generated.confidentialityInternal')">
              <mat-icon color="primary">forward_to_inbox</mat-icon>
            </button>
          </mat-card-actions>
        </mat-card>
        <mat-card>
          <img mat-card-image src="/assets/docs/generic.svg"/>
          <mat-card-title>
            {{'documents.generated.confidentialityExternal.title' | translate}}
          </mat-card-title>
          <mat-card-content>
            {{'documents.generated.confidentialityExternal.hint' | translate}}
          </mat-card-content>
          <mat-card-actions align="end">
            <button mat-icon-button matTooltip="{{ 'documents.download.generic' | translate}}" (click)="downloadDocument('documents.generated.confidentialityExternal')"
                    color="primary"><mat-icon>cloud_download</mat-icon></button>
            <button *ngIf="accessToSharedDocuments && isSupervisor" mat-icon-button
                    matTooltip="{{ 'documents.share' | translate }}"
                    (click)="shareGenerated('documents.generated.confidentialityExternal')">
              <mat-icon color="primary">forward_to_inbox</mat-icon>
            </button>
          </mat-card-actions>
        </mat-card>
        <mat-card>
          <img mat-card-image src="/assets/docs/generic.svg"/>
          <mat-card-title>
            {{'documents.generated.employeePhotos.title' | translate}}
          </mat-card-title>
          <mat-card-content>
            {{'documents.generated.employeePhotos.hint' | translate}}
          </mat-card-content>
          <mat-card-actions align="end">
            <button mat-icon-button matTooltip="{{ 'documents.download.generic' | translate}}" (click)="downloadDocument('documents.generated.employeePhotos')"
                    color="primary"><mat-icon>cloud_download</mat-icon></button>
            <button *ngIf="accessToSharedDocuments && isSupervisor" mat-icon-button
                    matTooltip="{{ 'documents.share' | translate }}"
                    (click)="shareGenerated('documents.generated.employeePhotos')">
              <mat-icon color="primary">forward_to_inbox</mat-icon>
            </button>
          </mat-card-actions>
        </mat-card>
        <!-- <mat-card>
          <img mat-card-image src="/assets/docs/generic.svg"/>
          <mat-card-title>
            {{'documents.generated.dpaController.title' | translate}}
          </mat-card-title>
          <mat-card-content>
            {{'documents.generated.dpaController.hint' | translate}}
          </mat-card-content>
          <mat-card-actions align="end">
            <button mat-button (click)="downloadDocument('documents.generated.dpaController')"
              color="primary">{{ 'documents.download.generic' | translate}}</button>
            <button *ngIf="accessToSharedDocuments" mat-icon-button
              matTooltip="{{ 'documents.share' | translate }}"
              (click)="shareGenerated('documents.generated.dpaController')">
              <mat-icon color="primary">forward_to_inbox</mat-icon>
            </button>
          </mat-card-actions>
        </mat-card> -->

        <display-language language="de">
          <mat-card>
            <img mat-card-image src="/assets/docs/generic.svg"/>
            <mat-card-title>
              {{'documents.generated.dpGuidelines.title' | translate}}
            </mat-card-title>
            <mat-card-content>
              {{'documents.generated.dpGuidelines.hint' | translate}}
            </mat-card-content>
            <mat-card-actions align="end">
              <button mat-icon-button matTooltip="{{ 'documents.download.generic' | translate}}" (click)="downloadDocument('documents.generated.dpGuidelines')"
                      color="primary"><mat-icon>cloud_download</mat-icon></button>
              <button *ngIf="accessToSharedDocuments && isSupervisor" mat-icon-button
                      matTooltip="{{ 'documents.share' | translate }}"
                      (click)="shareGenerated('documents.generated.dpGuidelines')">
                <mat-icon color="primary">forward_to_inbox</mat-icon>
              </button>
            </mat-card-actions>
          </mat-card>
        </display-language>
      </display-language>

      <display-jurisdiction jurisdiction="lgpd">
        <mat-card>
          <img mat-card-image src="/assets/docs/generic.svg"/>
          <mat-card-title>
            {{'documents.generated.art9lgpdapplicants.title' | translate}}
          </mat-card-title>
          <mat-card-content>
            {{'documents.generated.art9lgpdapplicants.hint' | translate}}
          </mat-card-content>
          <mat-card-actions align="end">
            <button mat-icon-button matTooltip="{{ 'documents.download.generic' | translate}}" (click)="downloadDocument('documents.generated.art9lgpdapplicants')"
                    color="primary"><mat-icon>cloud_download</mat-icon></button>
            <button *ngIf="accessToSharedDocuments && isSupervisor" mat-icon-button
                    matTooltip="{{ 'documents.share' | translate }}"
                    (click)="shareGenerated('documents.generated.art9lgpdapplicants')">
              <mat-icon color="primary">forward_to_inbox</mat-icon>
            </button>
          </mat-card-actions>
        </mat-card>
        <mat-card>
          <img mat-card-image src="/assets/docs/generic.svg"/>
          <mat-card-title>
            {{'documents.generated.art9lgpdcustomers.title' | translate}}
          </mat-card-title>
          <mat-card-content>
            {{'documents.generated.art9lgpdcustomers.hint' | translate}}
          </mat-card-content>
          <mat-card-actions align="end">
            <button mat-icon-button matTooltip="{{ 'documents.download.generic' | translate}}" (click)="downloadDocument('documents.generated.art9lgpdcustomers')"
                    color="primary"><mat-icon>cloud_download</mat-icon></button>
            <button *ngIf="accessToSharedDocuments && isSupervisor" mat-icon-button
                    matTooltip="{{ 'documents.share' | translate }}"
                    (click)="shareGenerated('documents.generated.art9lgpdcustomers')">
              <mat-icon color="primary">forward_to_inbox</mat-icon>
            </button>
          </mat-card-actions>
        </mat-card>
        <mat-card>
          <img mat-card-image src="/assets/docs/generic.svg"/>
          <mat-card-title>
            {{'documents.generated.art9lgpdemployees.title' | translate}}
          </mat-card-title>
          <mat-card-content>
            {{'documents.generated.art9lgpdemployees.hint' | translate}}
          </mat-card-content>
          <mat-card-actions align="end">
            <button mat-icon-button matTooltip="{{ 'documents.download.generic' | translate}}" (click)="downloadDocument('documents.generated.art9lgpdemployees')"
                    color="primary"><mat-icon>cloud_download</mat-icon></button>
            <button *ngIf="accessToSharedDocuments && isSupervisor" mat-icon-button
                    matTooltip="{{ 'documents.share' | translate }}"
                    (click)="shareGenerated('documents.generated.art9lgpdemployees')">
              <mat-icon color="primary">forward_to_inbox</mat-icon>
            </button>
          </mat-card-actions>
        </mat-card>
        <mat-card>
          <img mat-card-image src="/assets/docs/generic.svg"/>
          <mat-card-title>
            {{'documents.generated.confidentialityInternal.title' | translate}}
          </mat-card-title>
          <mat-card-content>
            {{'documents.generated.confidentialityInternal.hint' | translate}}
          </mat-card-content>
          <mat-card-actions align="end">
            <button mat-icon-button matTooltip="{{ 'documents.download.generic' | translate}}" (click)="downloadDocument('documents.generated.confidentialityInternal')"
                    color="primary"><mat-icon>cloud_download</mat-icon></button>
            <button *ngIf="accessToSharedDocuments && isSupervisor" mat-icon-button
                    matTooltip="{{ 'documents.share' | translate }}"
                    (click)="shareGenerated('documents.generated.confidentialityInternal')">
              <mat-icon color="primary">forward_to_inbox</mat-icon>
            </button>
          </mat-card-actions>
        </mat-card>
        <mat-card>
          <img mat-card-image src="/assets/docs/generic.svg"/>
          <mat-card-title>
            {{'documents.generated.confidentialityExternal.title' | translate}}
          </mat-card-title>
          <mat-card-content>
            {{'documents.generated.confidentialityExternal.hint' | translate}}
          </mat-card-content>
          <mat-card-actions align="end">
            <button mat-icon-button matTooltip="{{ 'documents.download.generic' | translate}}" (click)="downloadDocument('documents.generated.confidentialityExternal')"
                    color="primary"><mat-icon>cloud_download</mat-icon></button>
            <button *ngIf="accessToSharedDocuments && isSupervisor" mat-icon-button
                    matTooltip="{{ 'documents.share' | translate }}"
                    (click)="shareGenerated('documents.generated.confidentialityExternal')">
              <mat-icon color="primary">forward_to_inbox</mat-icon>
            </button>
          </mat-card-actions>
        </mat-card>
        <mat-card>
          <img mat-card-image src="/assets/docs/generic.svg"/>
          <mat-card-title>
            {{'documents.generated.employeePhotos.title' | translate}}
          </mat-card-title>
          <mat-card-content>
            {{'documents.generated.employeePhotos.hint' | translate}}
          </mat-card-content>
          <mat-card-actions align="end">
            <button mat-icon-button matTooltip="{{ 'documents.download.generic' | translate}}" (click)="downloadDocument('documents.generated.employeePhotos')"
                    color="primary"><mat-icon>cloud_download</mat-icon></button>
            <button *ngIf="accessToSharedDocuments && isSupervisor" mat-icon-button
                    matTooltip="{{ 'documents.share' | translate }}"
                    (click)="shareGenerated('documents.generated.employeePhotos')">
              <mat-icon color="primary">forward_to_inbox</mat-icon>
            </button>
          </mat-card-actions>
        </mat-card>
      </display-jurisdiction>
    </feature-access-local>
    <br><br>
  </div>

  <br><br>
</div>

import { Injectable } from '@angular/core';
import { IPostOptions, RequestService } from '../request.service';
import { BusService } from '../bus.service';
import { EventsService } from '../events.service';
import { HttpErrorResponse } from '@angular/common/http';

interface IPostUpdatePaymentDetailsRequest {
  stripeToken: string;
}
@Injectable()
export class UpdatePaymentDetailsService {
  private options: IPostOptions<IPostUpdatePaymentDetailsRequest,
                                any>;

  constructor(
    private bus: BusService,
    private events: EventsService,
    private requestService: RequestService
  ) {
    this.options = {
      uri: '/payment/update-card',
      body: {
        stripeToken: '',
      },
      handlers: {
        success: this.success.bind(this),
        error: this.error.bind(this)
      }
    };

    this.subscribe();
  }

  private subscribe(): void {
    this.bus.subscribe(this.events.requested.payment.details.updateCard, this.request.bind(this));
  }

  public request(data: any) {
    this.bus.publish(this.events.notified.request.starting);
    this.options.body.stripeToken = data.stripeToken;

    this.requestService.post<IPostUpdatePaymentDetailsRequest, any>(this.options);
  }

  public success(data: any) {
    this.bus.publish(this.events.received.payment.details.updateCard.success, data);
  }

  public error(httpErrorResponse: HttpErrorResponse) {
    this.bus.publish(this.events.received.payment.details.updateCard.failure, httpErrorResponse);
  }
}

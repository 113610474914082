import {Component, OnInit, OnDestroy, ViewChild, ChangeDetectorRef} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import {FormGroup, FormControl} from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import {EventsService} from '../../../services/events.service';
import {BusService} from '../../../services/bus.service';
import {AccessLevelService, AccessLevel} from '../../../services/user/access-level.service';

import { DepartmentService } from '../../../services/departments/department.service';
import { Vendor, VendorsService } from '../../../services/vendors/vendors.service';
import { RpaInternallyProcessedService } from '../../../services/rpa/rpa.internally-processed.service';
import { RpaExternallyProcessedService } from '../../../services/rpa/rpa.externally-processed.service';

import { VendorSearchDialogComponent } from '../pa-external-processors/vendor-search-dialog/vendor-search-dialog.component';

import { RiskAnalysisRiskConstants, RiskAnalysisSeverityConstants, riskAnalysisClassification, riskAnalysisClassificationByValue } from './risk-analysis/risk-analysis.consts';
import { RiskAnalysisRiskDialogComponent } from './risk-analysis/risk-dialog/risk-dialog.component';
import { RiskAnalysisSeverityDialogComponent } from './risk-analysis/severity-dialog/severity-dialog.component';

import { ProductFeaturesService } from 'app/services/product-features.service';
import { TranslateService } from '@ngx-translate/core';
import { MatSelect } from '@angular/material/select';
import { MatOption } from '@angular/material/core';
import { SearchApplicationDialogComponent } from '../../applications/search-application-dialog/search-application-dialog.component';
import { Application, ApplicationService } from '../../../services/vendors/application.service';
import { FullDpiaEditRiskComponent } from './full-dpia/edit-risk/edit-risk.component';
import { FullDpiaEditMeasureComponent } from './full-dpia/edit-measure/edit-measure.component';
import { PaNameDialogComponent } from './pa-name-dialog/pa-name-dialog.component';
import { DpiaNecessityDialogComponent } from './risk-analysis/dpia-necessity-dialog/dpia-necessity-dialog.component';
import { DpiaModel, DpiaService } from '../../../services/rpa/dpia.service';
import { isProportional, isProportionalityAnswered } from '../dpia-assistant/util';
import { downloadGeneratedDocument, getGeneratedDocumentLink } from '../../../util/documentGenerator';
import { ReportGetService } from '../../../services/report/report.get.service';
import { ChooseDateDialogComponent } from './choose-date-dialog/choose-date-dialog.component';

import { LiaDialogComponent } from '../lia-dialog/lia-dialog.component';
import { DepartmentSuggestionDialogComponent } from './department-suggestion/department-suggestion.component';
import { ShareDocumentDialogComponent } from '../../documents/shared/share-dialog/share-dialog.component';
import {AuthenticationService} from "../../../services/authentication.service";

export interface FullDpiaRisk {
  description: string;
  likelihood: string;
  severity: string;
  risk: string;
}

export interface FullDpiaMeasure {
  risk: string;
  description: string;
  effect: string;
  residualRisk: string;
  approved: boolean;
}

@Component({
  selector: 'app-pa-details',
  templateUrl: './pa-details.component.html',
  styleUrls: ['./pa-details.component.scss']
})
export class PaDetailsComponent implements OnInit, OnDestroy {

  @ViewChild('lgpdPrinciples') lgpdPrinciplesSelect: MatSelect;

  pa: any;
  paId: number;
  paName: string;
  formGroup: FormGroup;
  fieldsBeingSaved = {};

  documents = [];

  accessLevel: AccessLevel;

  departments = [];
  depAssociations = [];

  vendors = [];
  vendorAssociations = [];

  applications: Application[] = [];
  applicationAssociations: Application[] = [];

  incidentProbability = 0;
  incidentSeverity = 0;
  incidentProbabilityReasons = [];
  incidentSeverityReasons = [];

  riskConstants = RiskAnalysisRiskConstants;
  manualRiskAssessment = false;

  severityThresholds = RiskAnalysisSeverityConstants.thresholds;
  riskThresholds = RiskAnalysisRiskConstants.thresholds;

  severityRanges = RiskAnalysisSeverityConstants.thresholds.map((item, index) => {
      if (index < RiskAnalysisSeverityConstants.thresholds.length - 1) {
        return [item, RiskAnalysisSeverityConstants.thresholds[index + 1]];
      }
    }).filter(_ => _);

  severityConstants = RiskAnalysisSeverityConstants;
  manualSeverityAssessment = false;

  riskRanges = RiskAnalysisRiskConstants.thresholds.map((item, index) => {
      if (index < RiskAnalysisRiskConstants.thresholds.length - 1) {
        return [item, RiskAnalysisRiskConstants.thresholds[index + 1]];
      }
    }).filter(_ => _);

  fullDpia = {
    risks: null,
    measures: null
  };

  dpia: DpiaModel;

  referenceNodeId;

  hasCustomErasureTime = false;

  addResponsiblePerson = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private bus: BusService,
    private events: EventsService,
    private accessService: AccessLevelService,
    private departmentsService: DepartmentService,
    private vendorsService: VendorsService,
    private internallyProcessed: RpaInternallyProcessedService,
    private externallyProcessed: RpaExternallyProcessedService,
    private applicationService: ApplicationService,
    private dialog: MatDialog,
    private features: ProductFeaturesService,
    private translate: TranslateService,
    private changeDetector: ChangeDetectorRef,
    private dpiaService: DpiaService,
    private http: HttpClient,
    private reportService: ReportGetService,
    private authService: AuthenticationService
  ) {
    this.route.paramMap.subscribe(params => {
      this.paId = parseInt(params.get('paId'), 10);

      this.accessService.checkAccess({
        context: 'pa-details',
        details: {
          paId: this.paId,
        },
      }).subscribe(response => this.accessLevel = response);
    });

    this.formGroup = new FormGroup({
      assignedDepartment: new FormControl(0),
      paDescription: new FormControl(''),
      paPurpose: new FormControl(''),
      paLegalBasis: new FormControl(''),
      paLegalBasisGdprArt9: new FormControl(''),
      paLegalBasisArt11: new FormControl(''),
      paLegalBasisOther: new FormControl(''),
      paLegitimateInterestJustification: new FormControl(''),
      paDataSubjectCategories: new FormControl(''),
      paDataSubjectExplanation: new FormControl(''),
      paDataCategoriesDescription: new FormControl(''),
      paDataCategoriesDescriptionOther: new FormControl(''),
      paDataSource: new FormControl(''),
      paStorageLocation: new FormControl(''),
      erasureTimeSelect: new FormControl(''),
      erasureTime: new FormControl(''),
      erasureTimeConsideration: new FormControl(''),
      customErasureTime: new FormControl(''),
      toms: new FormControl(''),
      paAdditionalRemarks: new FormControl(''),
      paRiskAnalysisAdditionalRemarks: new FormControl(''),

      paUseFullDpia: new FormControl(false),
      paFullDpiaNeed: new FormControl(''),
      paFullDpiaNature: new FormControl(''),
      paFullDpiaScope: new FormControl(''),
      paFullDpiaContext: new FormControl(''),
      paFullDpiaPurposes: new FormControl(''),
      paFullDpiaCompliance: new FormControl(''),

      paLgpdPrinciples: new FormControl(''),

      processedInternally: new FormControl(undefined),
      processedExternally: new FormControl(undefined),

      processingOnBehalf: new FormControl(false),
      jointController: new FormControl(false),
      additionalControllerInfo: new FormControl(''),

      startOfProcessing: new FormControl(null),
      endOfProcessing: new FormControl(null),
      responsiblePerson: new FormControl(null)
    });
  }

  ngOnInit() {
    this.subscribe();
    if (this.paId) {
      this.bus.publish(this.events.requested.data.rpa.get, this.paId);
    }

    this.loadDepartmentData();
    this.loadVendorData();
  }

  ngOnDestroy() {
    this.unsubscribe();
  }

  public get currentLanguage() {
    return this.translate.currentLang || this.translate.defaultLang;
  }

  get hasLegitimateInterest() {
    if (this.formGroup.get('paLegalBasis').value) {
      return (this.formGroup.get('paLegalBasis').value as Array<string>).includes('pa.legalbasis.legitimateInterest');
    }
  }

  get hasLegalBasisOther() {
    if (this.formGroup.get('paLegalBasis').value) {
      return (this.formGroup.get('paLegalBasis').value as Array<string>).includes('pa.legalbasis.other');
    }
  }

  get hasOtherDataSubject() {
    if (this.formGroup.get('paDataSubjectCategories').value) {
      return (this.formGroup.get('paDataSubjectCategories').value as Array<string>).includes('pa.dataSubject.categories.other');
    }
  }

  get hasLegacyErasureTime() {
    if (this.formGroup.get('erasureTime').value) {
      const et = this.formGroup.get('erasureTime').value as string;
      return et && et.trim().length > 0;
    }
  }

  public get canWrite() {
    return this.accessLevel && this.accessLevel.write;
  }

  get isArchived() {
    return this.pa && this.pa.paStatus === 'Archived';
  }

  get isSupervisor() {
    return localStorage.getItem('isInSupervisionMode') === 'true';
  }

  // Documents
  addDocument(response) {
    this.documents.push(response.body);
  }

  get uploadUrl() {
    return '/pa/basics/' + this.paId + '/file';
  }

  updateDocumentList() {
    this.bus.publish(this.events.requested.data.rpa.get, this.paId);
  }

  update(data) {
    this.pa = data;
    this.paName = data.paName;
    this.documents = data.documents;
    this.referenceNodeId = data.treeNode;

    this.fullDpia = {
      risks: data.paFullDpiaRisks || [],
      measures: data.paFullDpiaMeasures || []
    };

    setTimeout(() => {
      this.formGroup.patchValue(data);
      this.loadDpiaData(data.paRiskAnalysis);
    }, 10);
  }

  submit(fieldName) {
    if (this.formGroup.get(fieldName).valid) {
      if (this.fieldsBeingSaved[fieldName]) {
        clearTimeout(this.fieldsBeingSaved[fieldName]);
      }

      this.fieldsBeingSaved[fieldName] = setTimeout(() => {
        this.bus.publish(this.events.requested.data.rpa.basics.updateField, {
          paId: this.paId,
          fieldName,
          value: this.formGroup.get(fieldName).value,
        });
      }, 1000);
    }
  }

  submitCustomField(fieldName, value) {
    if (this.fieldsBeingSaved['customFields' + fieldName]) {
      clearTimeout(this.fieldsBeingSaved['customFields' + fieldName]);
    }

    this.pa.customFields[fieldName] = value;

    this.fieldsBeingSaved['customFields' + fieldName] = setTimeout(() => {
      this.bus.publish(this.events.requested.data.rpa.basics.updateField, {
        paId: this.paId,
        fieldName: 'customFields',
        value: this.pa.customFields,
      });
    }, 1000);
  }

  editStartDate() {
    this.dialog.open(ChooseDateDialogComponent, { width: '400px', data: { date: this.pa.startOfProcessing } })
      .afterClosed()
      .subscribe((date) => {
        if (date && date.update) {
          this.pa.startOfProcessing = date.date;
          this.formGroup.patchValue({
            startOfProcessing: this.pa.startOfProcessing
          });
          this.submit('startOfProcessing');
        }
      });
  }

  editEndDate() {
    this.dialog.open(ChooseDateDialogComponent, { width: '400px', data: { date: this.pa.endOfProcessing } })
      .afterClosed()
      .subscribe((date) => {
        if (date && date.update) {
          this.pa.endOfProcessing = date.date;
          this.formGroup.patchValue({
            endOfProcessing: this.pa.endOfProcessing
          });
          this.submit('endOfProcessing');
        }
      });
  }

  editName() {
    this.dialog.open(PaNameDialogComponent, {
      width: '400px',
      data: {
        paId : this.paId,
        paName: this.paName,
        nodeId: this.referenceNodeId,
        processingOnBehalf: this.formGroup.get('processingOnBehalf').value,
        jointController: this.formGroup.get('jointController').value,
        paStatus: this.pa.paStatus
      }
    }).afterClosed().subscribe(res => {
      if (!!res) {
        if (typeof res.name === 'string' && res.name.length > 0) {
          this.paName = res.name;
        }

        if (res.processingOnBehalf !== this.formGroup.get('processingOnBehalf').value) {
          this.formGroup.patchValue({
            processingOnBehalf: res.processingOnBehalf
          });
          this.submit('processingOnBehalf');
        }

        // some logic to handle joint controller
        // we can use the same extra controller info field from processing on behalf
        // to handle the extra data
        if (res.jointController !== this.formGroup.get('jointController').value) {
          this.formGroup.patchValue({
            jointController: res.jointController
          });
          this.submit('jointController');
        }
      }
    });
  }

  addDepartment() {
    this.dialog.open(DepartmentSuggestionDialogComponent, { width: '600px', data: { existingDepartments: this.departments }})
      .afterClosed()
      .subscribe((department) => {
        if (department) {
          const existing = this.departments.find(d => d.id === department.id);
          if (!existing) {
            this.departments.push(department);
          }

          this.formGroup.controls.assignedDepartment.setValue(department.id);
          this.submit('assignedDepartment');
        }
      });
  }

  submitted(fieldName) {
    // clear customFields saving hint
    if (fieldName === 'customFields') {
      for (const key in this.fieldsBeingSaved) {
        if (key.startsWith('customFields')) {
          delete this.fieldsBeingSaved[key];
        }
      }
    }

    delete this.fieldsBeingSaved[fieldName];
  }

  done() {
    Object.values(this.formGroup.controls).forEach(control => control.markAsTouched());
    if (this.formGroup.valid) {
      this.router.navigate(['/rpa/applicable']);
    }
  }

  dataCategoriesHasOther() {
    const descr = this.formGroup.get('paDataCategoriesDescription').value;
    if (descr) {
      return descr.indexOf('pa.dataSubject.dataCategories.other') > -1;
    } else {
      return false;
    }
  }

  subscribe() {
    this.bus.subscribe(this.events.received.data.rpa.get.success, this.update, this);
    this.bus.subscribe(this.events.received.data.rpa.basics.updateField.success, this.submitted, this);

    this.applicationService.getApplicationsForPa(this.paId)
      .subscribe((applications) => {
        this.applications = applications;
      });

    this.dpiaService.get(this.paId)
      .subscribe((dpia) => {
        if (dpia) {
          this.dpia = dpia;
        }
      }, (error) => {
        // do nothing
      });
  }

  unsubscribe() {
    this.bus.unsubscribe(this.events.received.data.rpa.get.success, this.update);
    this.bus.unsubscribe(this.events.received.data.rpa.basics.updateField.success, this.submitted);
  }

  /***=============== DEPARTMENTS STUFF ===============***/

  loadDepartmentData() {
    this.departmentsService.requestGetAll().subscribe(response => {
      this.departments = response as any;
    });

    this.internallyProcessed.associations().subscribe(response => {
      this.depAssociations = response;
      this.updateIncidentProbability();
    });
  }

  get responsibleDepartment() {
    return this.departments.find(dep => dep.id === this.formGroup.value.assignedDepartment);
  }

  get nonResponsibleDepartments() {
    return this.departments.filter(dep => dep.id !== this.formGroup.value.assignedDepartment);
  }

  isAssociated(department) {
    return this.depAssociations
            .some(entry => entry.paId === this.paId && entry.departmentId === department.id);
  }

  associate(department) {
    this.internallyProcessed.associate({
      paId: this.paId,
      departmentId: department.id,
    }).subscribe(() => {
      if (!this.isAssociated(department)) {
        this.depAssociations.push({
          paId: this.paId,
          departmentId: department.id,
        });
        this.updateIncidentProbability(true);
        this.updateInternallyProcessed();
      }
    });
  }

  disassociate(department) {
    this.internallyProcessed.disassociate({
      paId: this.paId,
      departmentId: department.id,
    }).subscribe(() => {
      this.depAssociations =
        this.depAssociations
          .filter(entry => entry.paId !== this.paId || entry.departmentId !== department.id);
      this.updateIncidentProbability(true);
      this.updateInternallyProcessed();
    });
  }

  updateInternallyProcessed() {
      this.formGroup.controls.processedInternally.setValue(this.depAssociations.length > 0); // shortcut. re-using the FormControl object.
      this.submit('processedInternally');
  }

  updateExternallyProcessed(override = false) {
    this.formGroup.controls.processedExternally.setValue(override || this.vendorAssociations.length > 0); // shortcut. re-using the FormControl object.
    this.submit('processedExternally');
  }

  selectAllPrinciples() {
    this.lgpdPrinciplesSelect.options.forEach((item: MatOption) => item.select());
    this.lgpdPrinciplesSelect.close();
  }

  /*
  get principlesDisplay() {
    console.log(this.formGroup?.get('paLgpdPrinciples').value);
    zip(
      ... (this.formGroup?.get('paLgpdPrinciples').value as[])
        .map(s => this.translate.get(s))
    )
  }
  */

  /***=============== VENDORS STUFF ===============***/

  loadVendorData() {
    this.vendorsService.getVendorsForPa(this.paId)
      .subscribe((vendors) => {
        this.vendorAssociations = vendors;
        this.updateIncidentProbability();
      });

    this.applicationService.getApplicationsForPa(this.paId)
      .subscribe((applications) => {
        this.applications = applications;
      });
  }

  addVendor() {
    this.dialog.open(VendorSearchDialogComponent, {
      width: '512px',
      maxHeight: '90vh',
    }).afterClosed().subscribe(vendor => {
      if (vendor) {
        if (this.vendorAssociations.findIndex(entry => entry.vendorId === vendor.id) === -1) {
          this.externallyProcessed.associate({
            paId: this.paId,
            vendorId: vendor.id,
          }).subscribe(() => {
            this.loadVendorData();
            this.updateIncidentProbability(true);
          });
        }
      }
    });
  }

  updateApplications() {
    this.applicationService.getApplicationsForPa(this.paId)
      .subscribe((applications) => {
        this.applications = applications;
      });
  }

  updateVendors(vendor: Vendor) {
    // we only need to update the PA list when a vendor has been removed
    if (!vendor) {
      this.vendorsService.getVendorsForPa(this.paId)
        .subscribe((vendors) => {
          this.vendorAssociations = vendors;
          this.updateIncidentProbability();
        });
    }
  }

  addApplication() {
    this.dialog.open(SearchApplicationDialogComponent, { data: { allowCreate: true}, width: '480px', maxHeight: '90vh' })
      .afterClosed()
      .subscribe(application => {
        if (application) {
          if (this.applications.findIndex(a => a.id === application.id) === -1) {
            this.applicationService.addPaApplication(this.paId, application.id)
              .subscribe(
                () => {
                  this.updateExternallyProcessed(true);
                  this.loadVendorData();
                });
          }
        }
    });
  }

  removeVendor(vendor) {
    this.externallyProcessed.disassocaite({
      paId: this.paId,
      vendorId: vendor.id,
    }).subscribe(() => {
      this.vendorAssociations = this.vendorAssociations.filter(
          entry => entry.paId !== this.paId || entry.vendorId !== vendor.id);
      this.updateIncidentProbability(true);
      this.updateExternallyProcessed();
    });
  }

  /***=============== DPIA STUFF ===============***/

  get riskAnalysisAccess(): boolean {
    return this.features.hasAccessToFeature('dpia');
  }

  doAutoIncidentProb() {
    this.manualRiskAssessment = false;
    this.updateIncidentProbability();
    this.saveDpiaData();
  }

  updateIncidentProbability(save: boolean = false) {
    if (this.manualRiskAssessment) {
      return;
    }

    let vendorSafetyFactor =
      this.vendorAssociations
        .filter(entry => entry.paId === this.paId)
        .reduce((t, _) => t * RiskAnalysisRiskConstants.vendorSafetyFactor, 1);

    if (this.formGroup.get('processedExternally').value !== true) {
      vendorSafetyFactor = 1;
    } else if(this.formGroup.get('processedExternally').value === true && this.vendorAssociations.length === 0) {
      vendorSafetyFactor = 0.95;
    }

    let departmentSafetyFactor =
      this.depAssociations
        .filter(entry => entry.paId === this.paId)
        .reduce((t, _) => t * RiskAnalysisRiskConstants.departmentSafetyFactor, RiskAnalysisRiskConstants.departmentSafetyFactor);

    if (this.formGroup.get('processedInternally').value !== true) {
      departmentSafetyFactor = RiskAnalysisRiskConstants.departmentSafetyFactor;
    }

    this.incidentProbability = 1 - vendorSafetyFactor * departmentSafetyFactor;

    const reasons = [];
    if (vendorSafetyFactor === 1) {
      reasons.push({systematic: true, text: 'risk-analysis.risk.reasons.no-external'});
    } else if (vendorSafetyFactor >= .9) {
      reasons.push({systematic: true, text: 'risk-analysis.risk.reasons.limited-external'});
    } else if (vendorSafetyFactor >= .8) {
      reasons.push({systematic: true, text: 'risk-analysis.risk.reasons.external'});
    } else if (vendorSafetyFactor >= .7) {
      reasons.push({systematic: true, text: 'risk-analysis.risk.reasons.multiple-external'});
    } else {
      reasons.push({systematic: true, text: 'risk-analysis.risk.reasons.excessive-external'});
    }

    if (departmentSafetyFactor === RiskAnalysisRiskConstants.departmentSafetyFactor) {
      reasons.push({systematic: true, text: 'risk-analysis.risk.reasons.no-internal'});
    } else if (departmentSafetyFactor > .9) {
      reasons.push({systematic: true, text: 'risk-analysis.risk.reasons.limited-internal'});
    } else if (departmentSafetyFactor > .8) {
      reasons.push({systematic: true, text: 'risk-analysis.risk.reasons.multiple-internal'});
    } else {
      reasons.push({systematic: true, text: 'risk-analysis.risk.reasons.excessive-internal'});
    }

    this.incidentProbabilityReasons = reasons;

    //
    // we should not send the data to the backend every time this calculation is done.
    //
    if (save) {
      this.saveDpiaData();
    }
  }

  doAutoSeverity() {
    this.manualSeverityAssessment = false;
    this.updateIncidentSeverity();
    this.saveDpiaData();
  }

  openDpiaNecessityModal() {
    // we need to create a deep copy to break the reference here, otherwise the user experience is weird
    // yes, this is the way to go, honestly: https://stackoverflow.com/a/52764907/7735299
    const dpiaNeed = this.pa.paDpiaNecessity ? this.pa.paDpiaNecessity.map(i => ({ ...i })) : null;

    this.dialog.open(DpiaNecessityDialogComponent, { data: { items: dpiaNeed, pa: this.formGroup.value }, width: '650px' })
      .afterClosed()
      .subscribe((need) => {
        if (need) {
          this.pa.paDpiaNecessity = need;

          this.bus.publish(this.events.requested.data.rpa.basics.updateField, {
            paId: this.paId,
            fieldName: 'paDpiaNecessity',
            value: JSON.stringify(need)
          });

          this.changeDetector.detectChanges();
        }
      });
  }

  overrideNecessity() {
    this.pa.paDpiaNecessity = [];

    this.bus.publish(this.events.requested.data.rpa.basics.updateField, {
      paId: this.paId,
      fieldName: 'paDpiaNecessity',
      value: JSON.stringify([])
    });

    this.changeDetector.detectChanges();
  }

  get dpiaNecessary() {
    if (!this.pa.paDpiaNecessity) {
      return false;
    }

    const items = this.pa.paDpiaNecessity.filter(n => n.applies).map(n => n.score);

    if (items.length === 0) {
      return false;
    }

    return items.reduce((o, n) => o + n) >= 2;
  }

  updateIncidentSeverity(save: boolean = false) {
    if (this.manualSeverityAssessment) {
      return;
    }

    if (this.formGroup.get('paDataSubjectCategories') && this.formGroup.get('paDataCategoriesDescription')) {
      const subjects = this.formGroup.get('paDataSubjectCategories').value;
      const categories = this.formGroup.get('paDataCategoriesDescription').value;

      if (!subjects || !categories || subjects.length === 0 || categories.length === 0) {
        this.incidentSeverity = -1;
      } else {
        const scaleFactor = subjects
          .map(item => RiskAnalysisSeverityConstants.scaleFactor[item] || 1)
          .reduce((s, i) => s + i, 0);

        const sensitivityFactor = categories
          .map(item => RiskAnalysisSeverityConstants.sensitivityFactor[item] || 1)
          .reduce((s, i) => s + i, 0);

        this.incidentSeverity = scaleFactor * sensitivityFactor;

        const reasons = [];
        if (scaleFactor < 3) {
          reasons.push({systematic: true, text: 'risk-analysis.severity.reasons.limited-subjects'});
        } else if (scaleFactor < 7) {
          reasons.push({systematic: true,
            text: 'risk-analysis.severity.reasons.many-subjects'});
        } else {
          reasons.push({systematic: true,
            text: 'risk-analysis.severity.reasons.many-sensitive-subjects'});
        }

        if (sensitivityFactor < 5) {
          reasons.push({systematic: true, text: 'risk-analysis.severity.reasons.limited-nonsensitive-info'});
        } else if (sensitivityFactor < 10) {
          reasons.push({systematic: true, text: 'risk-analysis.severity.reasons.nonsensitive-info'});
        } else {
          const maxSensitivity = categories
            .map(item => RiskAnalysisSeverityConstants.sensitivityFactor[item] || 1)
            .reduce((s, i) => Math.max(s, i), 0);

          if (maxSensitivity >= 40) {
            reasons.push({systematic: true, text: 'risk-analysis.severity.reasons.extra-sensitive-info'});
          } else if (maxSensitivity >= 20) {
            reasons.push({systematic: true, text: 'risk-analysis.severity.reasons.highly-sensitive-info'});
          } else if (maxSensitivity >= 10) {
            reasons.push({systematic: true, text: 'risk-analysis.severity.reasons.sensitive-info'});
          }

          if (categories.length > 10) {
            reasons.push({systematic: true, text: 'risk-analysis.severity.reasons.excessive-range'});
          } else if (categories.length > 5) {
            reasons.push({systematic: true, text: 'risk-analysis.severity.reasons.wide-range'});
          }
        }

        this.incidentSeverityReasons = reasons;
      }

      //
      // we should not send the data to the backend every time this calculation is done.
      //
      if (save) {
        this.saveDpiaData();
      }
    }
  }

  riskAnalysisGridClass(riskIndex, severityIndex) {
    return riskAnalysisClassification(riskIndex, severityIndex);
  }

  openDpiaRiskDialog() {
    this.dialog.open(RiskAnalysisRiskDialogComponent, {
      width: '700px',
      maxHeight: '90vh',
      data: {
        probability: this.incidentProbability,
        probabilityReasons: this.incidentProbabilityReasons,
      }
    }).afterClosed().subscribe(data => {
      if (data && data.mutated) {
        this.incidentProbability = data.probability;
        this.incidentProbabilityReasons = data.reasons;
        this.manualRiskAssessment = true;

        this.saveDpiaData();
      }
    });
  }

  openDpiaSeverityDialog() {
    this.dialog.open(RiskAnalysisSeverityDialogComponent, {
      width: '700px',
      maxHeight: '90vh',
      data: {
        severity: this.incidentSeverity,
        severityReasons: this.incidentSeverityReasons,
      }
    }).afterClosed().subscribe(data => {
      if (data && data.mutated) {
        this.incidentSeverity = data.severity;
        this.incidentSeverityReasons = data.reasons;
        this.manualSeverityAssessment = true;

        this.saveDpiaData();
      }
    });
  }

  saveDpiaData() {
    const data = {
      risk: {
        value: this.incidentProbability,
        reasons: this.incidentProbabilityReasons || [],
        manual: this.manualRiskAssessment,
      },
      severity: {
        value: this.incidentSeverity,
        reasons: this.incidentSeverityReasons || [],
        manual: this.manualSeverityAssessment,
      }
    };

    this.bus.publish(this.events.requested.data.rpa.basics.updateField, {
      paId: this.paId,
      fieldName: 'paRiskAnalysis',
      value: data,
    });

    // in order to allow sorting by dpia results, we also store a 'dpia value' every time we update the dpia.
    // this could also be done on the backend.
    if (data && data.risk && data.severity) {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      const dpia_value = data.risk.value * data.severity.value;
      this.bus.publish(this.events.requested.data.rpa.basics.updateField, {
        paId: this.paId,
        fieldName: 'paRiskAnalysisValue',
        value: dpia_value,
      });
    }
  }

  loadDpiaData(data) {
    if (data) {
      if (data.risk) {
        this.incidentProbability = data.risk.value;
        this.incidentProbabilityReasons = data.risk.reasons;
        this.manualRiskAssessment = data.risk.manual;
      }

      if (data.severity) {
        this.incidentSeverity = data.severity.value;
        this.incidentSeverityReasons = data.severity.reasons;
        this.manualSeverityAssessment = data.severity.manual;
      }
    }

    this.updateIncidentProbability();
    this.updateIncidentSeverity();
    // EC-2409: We don't need to save the data we just fetched from the backend (it also messes with our logs)
    // this.saveDpiaData();
  }

  downloadWord(template: string) {
    downloadGeneratedDocument(template, `paId=${this.paId}`, this.translate, this.http, this.dialog);
  }

  shareWord(event: any, template: string) {
    if (event) event.stopPropagation();

    this.dialog.open(ShareDocumentDialogComponent, { data: { sourceUrl: getGeneratedDocumentLink(template, `paId=${this.paId}`, this.translate) }, width: '450px' });

  }

  downloadPdf() {
    this.reportService.openPasPdf([this.paId], this.formGroup.get('processingOnBehalf').value, this.pa.paStatus);
  }

  sharePdf(event: any) {
    if (event) event.stopPropagation();

    this.reportService.sharePasPdf([this.paId], this.formGroup.get('processingOnBehalf').value, this.pa.paStatus);
  }

  /***=============== FULL DPIA Stuff ===============***/

  addFullDpia() {
    // 1. prefill and setup full DPIA values

    // the nature of the PA is often covered by the description
    this.formGroup.get('paFullDpiaNature').setValue(this.formGroup.value.paDescription);

    // the purpose can also be copied over by the paPurpose field
    this.formGroup.get('paFullDpiaPurposes').setValue(this.formGroup.value.paPurpose);

    this.fullDpia = {
      risks: [],
      measures: []
    };
    this.saveFullDpiaRisks();
    this.saveFullDpiaMeasures();

    // 2. finalize setup, save, display full DPIA
    this.formGroup.get('paUseFullDpia').setValue(true);
    this.submit('paUseFullDpia');
    this.submit('paFullDpiaNature');
    this.submit('paFullDpiaPurposes');
  }

  addFullDpiaRisk() {
    this.editFullDpiaRisk({
      description: '',
      likelihood: null,
      severity: null,
      risk: null
    }, null);
  }

  addFullDpiaMeasure() {
    this.editFullDpiaMeasure({
      risk: '',
      description: '',
      effect: null,
      residualRisk: null,
      approved: false
    }, null);
  }

  editFullDpiaRisk(risk: FullDpiaRisk, index?: number) {
    this.dialog.open(FullDpiaEditRiskComponent, { data: { risk }, width: '400px' })
      .afterClosed()
      .subscribe((updated) => {
        if (updated) {
          if (typeof index === 'number') {
            this.fullDpia.risks[index] = updated;
          } else {
            this.fullDpia.risks.push(updated);
          }
          this.saveFullDpiaRisks();
        }
      });
  }

  removeFullDpiaRisk(index: number) {
    this.fullDpia.risks.splice(index, 1);
    this.saveFullDpiaRisks();
  }

  editFullDpiaMeasure(measure: FullDpiaMeasure, index?: number) {
    this.dialog.open(FullDpiaEditMeasureComponent, { data: { measure }, width: '400px' })
      .afterClosed()
      .subscribe((updated) => {
        if (updated) {
          if (typeof index === 'number') {
            this.fullDpia.measures[index] = updated;
          }  else {
            this.fullDpia.measures.push(updated);
          }
          this.saveFullDpiaMeasures();
        }
      });
  }

  removeFullDpiaMeasure(index: number) {
    this.fullDpia.measures.splice(index, 1);
    this.saveFullDpiaMeasures();
  }

  saveFullDpiaRisks() {
    this.bus.publish(this.events.requested.data.rpa.basics.updateField, {
      paId: this.paId,
      fieldName: 'paFullDpiaRisks',
      value: JSON.stringify(this.fullDpia.risks),
    });
  }

  saveFullDpiaMeasures() {
    this.bus.publish(this.events.requested.data.rpa.basics.updateField, {
      paId: this.paId,
      fieldName: 'paFullDpiaMeasures',
      value: JSON.stringify(this.fullDpia.measures),
    });
  }

  getRiskColorClass(fieldValue: string) {
    // low values, see the possible values in the model
    if (['remote', 'minimal', 'low'].includes(fieldValue)) {
      return riskAnalysisClassificationByValue(0);
    }

    // medium values
    if (['possible', 'significant', 'medium'].includes(fieldValue)) {
      return riskAnalysisClassificationByValue(10);
    }

    // high values
    if (['probable', 'severe', 'high'].includes(fieldValue)) {
      return riskAnalysisClassificationByValue(100);
    }
  }

  getMeasureColorClass(fieldValue: string) {
    if (fieldValue === 'eliminated') {
      return riskAnalysisClassificationByValue(0);
    }

    if (fieldValue === 'reduced') {
      return riskAnalysisClassificationByValue(10);
    }

    if (fieldValue === 'accepted') {
      return riskAnalysisClassificationByValue(50);
    }
  }

  get dpiaMatrixUrlBefore() {
    return this.dpiaService.getMatrixUrl(this.paId, 'before');
  }

  get dpiaMatrixUrlAfter() {
    return this.dpiaService.getMatrixUrl(this.paId, 'after');
  }

  get isDpiaProportionalityAnswered() {
    return isProportionalityAnswered(this.dpia);
  }

  get isDpiaProportional() {
    return isProportional(this.dpia);
  }

  /**
   * Custom Erasure Time
   */
  // customErasureTimeList: string = 'sdfsdf'
  isCustomErasureTime() {
    this.hasCustomErasureTime = !this.hasCustomErasureTime;
  }

  // lia
  legitimateInterestAssessment() {
    this.dialog.open(LiaDialogComponent, {
      width: '840px',
      maxHeight: '90vh',
      data: {
        paId: this.paId,
        liaResponse: this.formGroup.get('paLegitimateInterestJustification').value,
      }
    }).afterClosed().subscribe((content) => {
      if (content) {
        this.formGroup.get('paLegitimateInterestJustification').setValue(content);
        this.submit('paLegitimateInterestJustification');
      }
    });
  }

  showAddResponsiblePerson() {
    this.addResponsiblePerson = true;
  }
}

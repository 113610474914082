<div class="custom-pa">
  <h1 class="title">{{ 'custom-pa.titel' | translate }}</h1>
  <br/>
  <br/>
  <img src="assets/custom-pa.svg"/>
  <br/><br/>
  <p>
    {{ 'custom-pa.instructions' | translate }}<mat-icon color="primary">edit</mat-icon>
  </p>
  <br/>
  <form name="form" [formGroup]="formGroup" novalidate>
    <mat-form-field>
      <input matInput placeholder="{{ 'custom-pa.namePa' | translate }}"
                      name="name" type="text"
                      formControlName="name" required autofocus/>
      <mat-error *ngIf="formGroup.get('name').hasError('minlength') && !formGroup.get('name').hasError('required')">
        {{ 'custom-pa.reqsNameMinLength' | translate }}
      </mat-error>
      <mat-error *ngIf="formGroup.get('name').hasError('required')">
        {{ 'custom-pa.reqsName' | translate }}
      </mat-error>
    </mat-form-field>
    <div class="toggle">
      <mat-slide-toggle color="primary" formControlName="processingOnBehalf">
        {{ 'pa.processingOnBehalf' | translate }}
      </mat-slide-toggle>
    </div>
    <div class="buttons">
      <button type="button" mat-raised-button color="primary" (click)="submit()">{{ 'word.save' | translate }}</button>
    </div>
  </form>
  <br><br>
</div>

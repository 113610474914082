<div class="contents">
  <br><br>
  <h1 class="title">
    {{'audit.check.title' | translate}}
  </h1>
  <p>
    {{'audit.check.hint' | translate}}
  </p>

  <br><br>

  <checklist codename="audit-check" (progress)="updateProgress($event)"></checklist>

  <br><br>

  <neat-action-bar>
    <button routerLink="/audit" mat-button color="primary">
      {{'audit.check.actions.review' | translate}}
    </button>
    <button routerLink="/" mat-flat-button color="primary">
      {{'audit.check.actions.done' | translate}}
    </button>
  </neat-action-bar>

  <br><br><br><br>
</div>
import { NestedTreeControl } from '@angular/cdk/tree';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { buildFileTreeFromPaths } from '../../../../../util/fileTree';
import { SelectPathNewFolderComponent } from './new-folder/new-folder.component';

export interface FileTreeNode {
  label: string;
  fullPath: string;
  children: FileTreeNode[];
}

@Component({
  selector: 'app-select-path-dialog',
  templateUrl: './select-path-dialog.component.html',
  styleUrls: ['./select-path-dialog.component.scss']
})
export class SelectPathDialogComponent implements OnInit {
  treeControl = new NestedTreeControl<FileTreeNode>(node => node.children);
  dataSource = new MatTreeNestedDataSource<FileTreeNode>();

  selected: FileTreeNode = null;

  readonly = false;

  constructor(
    private ref: MatDialogRef<SelectPathDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: { path: string[]; tree: any; treeSource: string[][]; readonly: boolean },
    private dialog: MatDialog
  ) {
    this.dataSource.data = buildFileTreeFromPaths(this.data.treeSource);
    this.treeControl.dataNodes = this.dataSource.data;
    this.treeControl.expandAll();

    if (this.data.path.length > 0) {
      this.selected = { fullPath: this.data.path.join('/') } as any;
    }
    if (this.data) {
      this.readonly = this.data.readonly || false;
    }
  }

  isSelected = (node: FileTreeNode) => node.fullPath === this.selected?.fullPath;

  hasChild = (_: number, node: FileTreeNode) => !!node.children && node.children.length > 0;

  ngOnInit(): void {
  }

  submit() {
    if (this.selected) {
      this.ref.close(this.selected.fullPath.split('/'));
    } else {
      this.ref.close([]);
    }
  }

  addPathFragment(node?: FileTreeNode) {
    this.dialog.open(SelectPathNewFolderComponent, { width: '350px' })
      .afterClosed()
      .subscribe((folder) => {
        if (folder) {
          const newPath = node ? node.fullPath.split('/').concat(folder) : [folder];
          this.data.treeSource.push(newPath);
          this.dataSource.data = buildFileTreeFromPaths(this.data.treeSource);
          this.selected = { fullPath: newPath.join('/') } as any;
          this.treeControl.dataNodes = this.dataSource.data;
          this.treeControl.expandAll();
        }
      });
  }

}

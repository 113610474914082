<div class="metrics">
  <div class="grid" *ngIf="metrics">
    <!-- DPO -->
    <div class="card large info">
      <dashboard-metrics-your-dpo></dashboard-metrics-your-dpo>
      <div class="label">{{ 'dashboard.metrics.yourDpo' | translate }}</div>
    </div>

    <a [routerLink]="canNavigateAway ? '/incidents/records' : null" class="card small number">
      <div class="value">{{ countOpenIncidents }}</div>
      <div class="label">{{ 'dashboard.metrics.openIncidents' | translate }}</div>
    </a>
    <a [routerLink]="canNavigateAway ? '/dsar/records' : null" class="card small number">
      <div class="value">{{ countOpenDsar }}</div>
      <div class="label">{{ 'dashboard.metrics.openDsar' | translate }}</div>
    </a>

    <!-- PA Stats-->
    <a [routerLink]="canNavigateAway ? '/rpa/applicable' : null" class="card large high graph">
      <div class="graph">
        <dashboard-metrics-pa-risk-distribution-chart [pas]="metrics._extra.cpas" [height]="150"></dashboard-metrics-pa-risk-distribution-chart>
      </div>
      <div class="label">{{ 'dashboard.metrics.paRiskGroup' | translate }}</div>
    </a>
    <a [routerLink]="canNavigateAway ? '/rpa/applicable' : null" class="card small number">
      <div class="value">{{ countNormalPas }}</div>
      <div class="label">{{ 'dashboard.metrics.normalPasCount' | translate }}</div>
    </a>
    <a [routerLink]="canNavigateAway ? '/rpa/applicable' : null" class="card small number">
      <div class="value">{{ countPasOnBehalf }}</div>
      <div class="label">{{ 'dashboard.metrics.pobCount' | translate }}</div>
    </a>
    <a [routerLink]="canNavigateAway ? '/rpa/applicable' : null" class="card large high graph">
      <div class="graph">
        <dashboard-metrics-pa-department-chart [pas]="metrics._extra.cpas" [departments]="metrics._extra.departments" [height]="175"></dashboard-metrics-pa-department-chart>
      </div>
      <div class="label">{{ 'dashboard.metrics.paDepartment' | translate }}</div>
    </a>
    <feature-access-local [showWhenHasNot]="true" featureName="dashboard-hide-data-categories">
      <a *ngIf="metrics._extra.cpas.length > 0" [routerLink]="canNavigateAway ? '/rpa/applicable' : null" class="card large high graph">
        <div class="graph">
          <dashboard-metrics-pa-data-categories-most-used [pas]="metrics._extra.cpas"></dashboard-metrics-pa-data-categories-most-used>
        </div>
        <div class="label">{{ 'dashboard.metrics.dataCategories' | translate }}</div>
      </a>
    </feature-access-local>
    <feature-access-local [showWhenHasNot]="true" featureName="dashboard-hide-legal-basis">
      <a *ngIf="metrics._extra.cpas.length > 0" [routerLink]="canNavigateAway ? '/rpa/applicable' : null" class="card large high graph">
        <div class="graph">
          <dashboard-metrics-pa-legal-basis-most-used [pas]="metrics._extra.cpas"></dashboard-metrics-pa-legal-basis-most-used>
        </div>
        <div class="label">{{ 'dashboard.metrics.legalBasis' | translate }}</div>
      </a>
    </feature-access-local>

    <!-- Other Metrics -->
    <a [routerLink]="canNavigateAway ? '/vendors' : null" class="card small number">
      <div class="value">{{ countVendors }}</div>
      <div class="label">{{ 'dashboard.metrics.vendorCount' | translate }}</div>
    </a>

    <feature-access-local [showWhenHasNot]="true" featureName="dashboard-hide-toms">
      <a [routerLink]="canNavigateAway ? '/toms' : null" class="card small number">
        <div class="value">{{ countApplicableToms }}</div>
        <div class="label">{{ 'dashboard.metrics.tomsCount' | translate }}</div>
      </a>
    </feature-access-local>

    <a [routerLink]="canNavigateAway ? '/user-management' : null" class="card small number">
      <div class="value">{{ countUsers }}</div>
      <div class="label">{{ 'dashboard.metrics.users' | translate }}</div>
    </a>

    <!-- Activity -->
    <feature-access-local [showWhenHasNot]="true" featureName="dashboard-hide-activity">
      <a [routerLink]="canNavigateAway ? '/actions' : null" class="card large high graph">
        <div class="graph">
          <dashboard-actions-timeseries-chart [events]="metrics._extra.events" [height]="150"></dashboard-actions-timeseries-chart>
        </div>
        <div class="label">{{ 'dashboard.metrics.actionCount' | translate }}</div>
      </a>
    </feature-access-local>
  </div>
</div>

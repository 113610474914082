<html>

<head>
    <meta content="text/html; charset=UTF-8" http-equiv="content-type">
    <style type="text/css">
        .lst-kix_hp5w6vum06pm-3>li:before {
            content: "" counter(lst-ctn-kix_hp5w6vum06pm-3, decimal) ". "
        }

        .lst-kix_hp5w6vum06pm-2>li:before {
            content: "" counter(lst-ctn-kix_hp5w6vum06pm-2, lower-roman) ". "
        }

        .lst-kix_hp5w6vum06pm-4>li:before {
            content: "" counter(lst-ctn-kix_hp5w6vum06pm-4, lower-latin) ". "
        }

        .lst-kix_29l9atrxjrcc-2>li:before {
            content: "" counter(lst-ctn-kix_29l9atrxjrcc-2, lower-roman) ". "
        }

        .lst-kix_29l9atrxjrcc-4>li:before {
            content: "" counter(lst-ctn-kix_29l9atrxjrcc-4, lower-latin) ". "
        }

        .lst-kix_hp5w6vum06pm-1>li:before {
            content: "" counter(lst-ctn-kix_hp5w6vum06pm-1, lower-latin) ". "
        }

        .lst-kix_hp5w6vum06pm-5>li:before {
            content: "" counter(lst-ctn-kix_hp5w6vum06pm-5, lower-roman) ". "
        }

        .lst-kix_29l9atrxjrcc-1>li:before {
            content: "" counter(lst-ctn-kix_29l9atrxjrcc-1, lower-latin) ". "
        }

        .lst-kix_29l9atrxjrcc-5>li:before {
            content: "" counter(lst-ctn-kix_29l9atrxjrcc-5, lower-roman) ". "
        }

        ol.lst-kix_fczziklbivy6-8 {
            list-style-type: none
        }

        .lst-kix_29l9atrxjrcc-3>li:before {
            content: "" counter(lst-ctn-kix_29l9atrxjrcc-3, decimal) ". "
        }

        .lst-kix_r4skt585moyl-2>li {
            counter-increment: lst-ctn-kix_r4skt585moyl-2
        }

        .lst-kix_lr8xgw4v5wfg-0>li {
            counter-increment: lst-ctn-kix_lr8xgw4v5wfg-0
        }

        ol.lst-kix_lr8xgw4v5wfg-4.start {
            counter-reset: lst-ctn-kix_lr8xgw4v5wfg-4 0
        }

        ol.lst-kix_jtgxrppdhs5b-8 {
            list-style-type: none
        }

        ol.lst-kix_jtgxrppdhs5b-6 {
            list-style-type: none
        }

        .lst-kix_hp5w6vum06pm-0>li:before {
            content: "" counter(lst-ctn-kix_hp5w6vum06pm-0, decimal) ". "
        }

        ol.lst-kix_t05e6zjr7d8z-2.start {
            counter-reset: lst-ctn-kix_t05e6zjr7d8z-2 0
        }

        .lst-kix_29l9atrxjrcc-6>li:before {
            content: "" counter(lst-ctn-kix_29l9atrxjrcc-6, decimal) ". "
        }

        .lst-kix_29l9atrxjrcc-8>li:before {
            content: "" counter(lst-ctn-kix_29l9atrxjrcc-8, lower-roman) ". "
        }

        ol.lst-kix_jtgxrppdhs5b-7 {
            list-style-type: none
        }

        .lst-kix_p1xw0vc0x49w-3>li:before {
            content: "" counter(lst-ctn-kix_p1xw0vc0x49w-3, decimal) ". "
        }

        ol.lst-kix_jtgxrppdhs5b-4 {
            list-style-type: none
        }

        .lst-kix_hp5w6vum06pm-3>li {
            counter-increment: lst-ctn-kix_hp5w6vum06pm-3
        }

        ol.lst-kix_jtgxrppdhs5b-5 {
            list-style-type: none
        }

        ol.lst-kix_jtgxrppdhs5b-2 {
            list-style-type: none
        }

        .lst-kix_29l9atrxjrcc-7>li:before {
            content: "" counter(lst-ctn-kix_29l9atrxjrcc-7, lower-latin) ". "
        }

        ol.lst-kix_jtgxrppdhs5b-3 {
            list-style-type: none
        }

        .lst-kix_p1xw0vc0x49w-4>li:before {
            content: "" counter(lst-ctn-kix_p1xw0vc0x49w-4, lower-latin) ". "
        }

        ol.lst-kix_jtgxrppdhs5b-0 {
            list-style-type: none
        }

        ol.lst-kix_jtgxrppdhs5b-1 {
            list-style-type: none
        }

        .lst-kix_fczziklbivy6-2>li {
            counter-increment: lst-ctn-kix_fczziklbivy6-2
        }

        .lst-kix_p1xw0vc0x49w-5>li:before {
            content: "" counter(lst-ctn-kix_p1xw0vc0x49w-5, lower-roman) ". "
        }

        .lst-kix_p1xw0vc0x49w-7>li:before {
            content: "" counter(lst-ctn-kix_p1xw0vc0x49w-7, lower-latin) ". "
        }

        ol.lst-kix_273ltq9wy9y6-7.start {
            counter-reset: lst-ctn-kix_273ltq9wy9y6-7 0
        }

        .lst-kix_p1xw0vc0x49w-6>li:before {
            content: "" counter(lst-ctn-kix_p1xw0vc0x49w-6, decimal) ". "
        }

        ol.lst-kix_nsh5xilkuf1a-1.start {
            counter-reset: lst-ctn-kix_nsh5xilkuf1a-1 0
        }

        .lst-kix_29l9atrxjrcc-8>li {
            counter-increment: lst-ctn-kix_29l9atrxjrcc-8
        }

        .lst-kix_p1xw0vc0x49w-8>li:before {
            content: "" counter(lst-ctn-kix_p1xw0vc0x49w-8, lower-roman) ". "
        }

        ol.lst-kix_v38dn35flf8h-2 {
            list-style-type: none
        }

        ol.lst-kix_v38dn35flf8h-3 {
            list-style-type: none
        }

        ol.lst-kix_v38dn35flf8h-4 {
            list-style-type: none
        }

        ol.lst-kix_v38dn35flf8h-5 {
            list-style-type: none
        }

        ol.lst-kix_v38dn35flf8h-0 {
            list-style-type: none
        }

        ol.lst-kix_v38dn35flf8h-1 {
            list-style-type: none
        }

        .lst-kix_29l9atrxjrcc-0>li:before {
            content: "" counter(lst-ctn-kix_29l9atrxjrcc-0, decimal) ". "
        }

        ol.lst-kix_v38dn35flf8h-6 {
            list-style-type: none
        }

        ol.lst-kix_v38dn35flf8h-7 {
            list-style-type: none
        }

        .lst-kix_273ltq9wy9y6-7>li {
            counter-increment: lst-ctn-kix_273ltq9wy9y6-7
        }

        ol.lst-kix_v38dn35flf8h-8 {
            list-style-type: none
        }

        ol.lst-kix_hp5w6vum06pm-6.start {
            counter-reset: lst-ctn-kix_hp5w6vum06pm-6 0
        }

        .lst-kix_amhh9wwmrxzs-7>li:before {
            content: "" counter(lst-ctn-kix_amhh9wwmrxzs-7, lower-latin) ". "
        }

        ol.lst-kix_t05e6zjr7d8z-5 {
            list-style-type: none
        }

        ol.lst-kix_t05e6zjr7d8z-4 {
            list-style-type: none
        }

        .lst-kix_nsh5xilkuf1a-3>li {
            counter-increment: lst-ctn-kix_nsh5xilkuf1a-3
        }

        ol.lst-kix_t05e6zjr7d8z-7 {
            list-style-type: none
        }

        ol.lst-kix_t05e6zjr7d8z-6 {
            list-style-type: none
        }

        .lst-kix_amhh9wwmrxzs-5>li:before {
            content: "" counter(lst-ctn-kix_amhh9wwmrxzs-5, lower-roman) ". "
        }

        .lst-kix_vkmonltz3m4x-2>li {
            counter-increment: lst-ctn-kix_vkmonltz3m4x-2
        }

        ol.lst-kix_t05e6zjr7d8z-1 {
            list-style-type: none
        }

        ol.lst-kix_t05e6zjr7d8z-0 {
            list-style-type: none
        }

        .lst-kix_amhh9wwmrxzs-6>li:before {
            content: "" counter(lst-ctn-kix_amhh9wwmrxzs-6, decimal) ". "
        }

        ol.lst-kix_t05e6zjr7d8z-3 {
            list-style-type: none
        }

        ol.lst-kix_t05e6zjr7d8z-2 {
            list-style-type: none
        }

        .lst-kix_r4skt585moyl-6>li {
            counter-increment: lst-ctn-kix_r4skt585moyl-6
        }

        .lst-kix_v38dn35flf8h-8>li {
            counter-increment: lst-ctn-kix_v38dn35flf8h-8
        }

        .lst-kix_273ltq9wy9y6-3>li {
            counter-increment: lst-ctn-kix_273ltq9wy9y6-3
        }

        ol.lst-kix_t05e6zjr7d8z-8 {
            list-style-type: none
        }

        .lst-kix_amhh9wwmrxzs-0>li:before {
            content: "" counter(lst-ctn-kix_amhh9wwmrxzs-0, decimal) ". "
        }

        .lst-kix_amhh9wwmrxzs-8>li:before {
            content: "" counter(lst-ctn-kix_amhh9wwmrxzs-8, lower-roman) ". "
        }

        ol.lst-kix_t05e6zjr7d8z-7.start {
            counter-reset: lst-ctn-kix_t05e6zjr7d8z-7 0
        }

        ol.lst-kix_29l9atrxjrcc-4.start {
            counter-reset: lst-ctn-kix_29l9atrxjrcc-4 0
        }

        ol.lst-kix_p1xw0vc0x49w-7.start {
            counter-reset: lst-ctn-kix_p1xw0vc0x49w-7 0
        }

        .lst-kix_amhh9wwmrxzs-1>li:before {
            content: "" counter(lst-ctn-kix_amhh9wwmrxzs-1, lower-latin) ". "
        }

        .lst-kix_amhh9wwmrxzs-2>li:before {
            content: "" counter(lst-ctn-kix_amhh9wwmrxzs-2, lower-roman) ". "
        }

        .lst-kix_amhh9wwmrxzs-3>li:before {
            content: "" counter(lst-ctn-kix_amhh9wwmrxzs-3, decimal) ". "
        }

        ol.lst-kix_e6qtjfbzpvfl-4.start {
            counter-reset: lst-ctn-kix_e6qtjfbzpvfl-4 0
        }

        ol.lst-kix_mh7larxc34v5-4.start {
            counter-reset: lst-ctn-kix_mh7larxc34v5-4 0
        }

        .lst-kix_amhh9wwmrxzs-4>li:before {
            content: "" counter(lst-ctn-kix_amhh9wwmrxzs-4, lower-latin) ". "
        }

        .lst-kix_29l9atrxjrcc-4>li {
            counter-increment: lst-ctn-kix_29l9atrxjrcc-4
        }

        ol.lst-kix_nsh5xilkuf1a-6.start {
            counter-reset: lst-ctn-kix_nsh5xilkuf1a-6 0
        }

        ol.lst-kix_jtgxrppdhs5b-7.start {
            counter-reset: lst-ctn-kix_jtgxrppdhs5b-7 0
        }

        .lst-kix_t05e6zjr7d8z-2>li {
            counter-increment: lst-ctn-kix_t05e6zjr7d8z-2
        }

        .lst-kix_p1xw0vc0x49w-2>li:before {
            content: "" counter(lst-ctn-kix_p1xw0vc0x49w-2, lower-roman) ". "
        }

        .lst-kix_1uhaebmjlx87-2>li {
            counter-increment: lst-ctn-kix_1uhaebmjlx87-2
        }

        .lst-kix_p1xw0vc0x49w-1>li:before {
            content: "" counter(lst-ctn-kix_p1xw0vc0x49w-1, lower-latin) ". "
        }

        ol.lst-kix_fczziklbivy6-2.start {
            counter-reset: lst-ctn-kix_fczziklbivy6-2 0
        }

        .lst-kix_p1xw0vc0x49w-0>li:before {
            content: "" counter(lst-ctn-kix_p1xw0vc0x49w-0, decimal) ". "
        }

        ol.lst-kix_r4skt585moyl-0 {
            list-style-type: none
        }

        ol.lst-kix_fczziklbivy6-5 {
            list-style-type: none
        }

        ol.lst-kix_r4skt585moyl-1 {
            list-style-type: none
        }

        ol.lst-kix_fczziklbivy6-4 {
            list-style-type: none
        }

        ol.lst-kix_fczziklbivy6-7 {
            list-style-type: none
        }

        ol.lst-kix_fczziklbivy6-6 {
            list-style-type: none
        }

        ol.lst-kix_fczziklbivy6-1 {
            list-style-type: none
        }

        ol.lst-kix_fczziklbivy6-0 {
            list-style-type: none
        }

        ol.lst-kix_fczziklbivy6-3 {
            list-style-type: none
        }

        ol.lst-kix_fczziklbivy6-2 {
            list-style-type: none
        }

        ol.lst-kix_r4skt585moyl-8 {
            list-style-type: none
        }

        .lst-kix_hp5w6vum06pm-7>li:before {
            content: "" counter(lst-ctn-kix_hp5w6vum06pm-7, lower-latin) ". "
        }

        ol.lst-kix_r4skt585moyl-6 {
            list-style-type: none
        }

        .lst-kix_hp5w6vum06pm-6>li:before {
            content: "" counter(lst-ctn-kix_hp5w6vum06pm-6, decimal) ". "
        }

        .lst-kix_hp5w6vum06pm-8>li:before {
            content: "" counter(lst-ctn-kix_hp5w6vum06pm-8, lower-roman) ". "
        }

        ol.lst-kix_r4skt585moyl-7 {
            list-style-type: none
        }

        ol.lst-kix_hp5w6vum06pm-1.start {
            counter-reset: lst-ctn-kix_hp5w6vum06pm-1 0
        }

        ol.lst-kix_r4skt585moyl-4 {
            list-style-type: none
        }

        ol.lst-kix_jtgxrppdhs5b-0.start {
            counter-reset: lst-ctn-kix_jtgxrppdhs5b-0 0
        }

        ol.lst-kix_r4skt585moyl-5 {
            list-style-type: none
        }

        ol.lst-kix_p1xw0vc0x49w-0.start {
            counter-reset: lst-ctn-kix_p1xw0vc0x49w-0 0
        }

        ol.lst-kix_r4skt585moyl-2 {
            list-style-type: none
        }

        ol.lst-kix_r4skt585moyl-3 {
            list-style-type: none
        }

        .lst-kix_mh7larxc34v5-8>li {
            counter-increment: lst-ctn-kix_mh7larxc34v5-8
        }

        ol.lst-kix_lr8xgw4v5wfg-8 {
            list-style-type: none
        }

        .lst-kix_29l9atrxjrcc-1>li {
            counter-increment: lst-ctn-kix_29l9atrxjrcc-1
        }

        ol.lst-kix_29l9atrxjrcc-2.start {
            counter-reset: lst-ctn-kix_29l9atrxjrcc-2 0
        }

        .lst-kix_1uhaebmjlx87-1>li:before {
            content: "" counter(lst-ctn-kix_1uhaebmjlx87-0, decimal) "." counter(lst-ctn-kix_1uhaebmjlx87-1, decimal) ". "
        }

        ol.lst-kix_lr8xgw4v5wfg-6 {
            list-style-type: none
        }

        ol.lst-kix_lr8xgw4v5wfg-7 {
            list-style-type: none
        }

        ol.lst-kix_lr8xgw4v5wfg-4 {
            list-style-type: none
        }

        ol.lst-kix_lr8xgw4v5wfg-5 {
            list-style-type: none
        }

        .lst-kix_1uhaebmjlx87-3>li:before {
            content: "" counter(lst-ctn-kix_1uhaebmjlx87-0, decimal) "." counter(lst-ctn-kix_1uhaebmjlx87-1, decimal) "." counter(lst-ctn-kix_1uhaebmjlx87-2, decimal) "." counter(lst-ctn-kix_1uhaebmjlx87-3, decimal) ". "
        }

        ol.lst-kix_lr8xgw4v5wfg-2 {
            list-style-type: none
        }

        ol.lst-kix_lr8xgw4v5wfg-3 {
            list-style-type: none
        }

        ol.lst-kix_lr8xgw4v5wfg-0 {
            list-style-type: none
        }

        ol.lst-kix_lr8xgw4v5wfg-1 {
            list-style-type: none
        }

        .lst-kix_v38dn35flf8h-5>li {
            counter-increment: lst-ctn-kix_v38dn35flf8h-5
        }

        .lst-kix_jtgxrppdhs5b-6>li:before {
            content: "" counter(lst-ctn-kix_jtgxrppdhs5b-6, decimal) ". "
        }

        .lst-kix_jtgxrppdhs5b-8>li:before {
            content: "" counter(lst-ctn-kix_jtgxrppdhs5b-8, lower-roman) ". "
        }

        ol.lst-kix_7a1f1loavfm8-0.start {
            counter-reset: lst-ctn-kix_7a1f1loavfm8-0 0
        }

        ol.lst-kix_mh7larxc34v5-7.start {
            counter-reset: lst-ctn-kix_mh7larxc34v5-7 0
        }

        ol.lst-kix_amhh9wwmrxzs-0.start {
            counter-reset: lst-ctn-kix_amhh9wwmrxzs-0 0
        }

        .lst-kix_vkmonltz3m4x-6>li {
            counter-increment: lst-ctn-kix_vkmonltz3m4x-6
        }

        .lst-kix_jtgxrppdhs5b-4>li:before {
            content: "" counter(lst-ctn-kix_jtgxrppdhs5b-4, lower-latin) ". "
        }

        .lst-kix_v38dn35flf8h-4>li {
            counter-increment: lst-ctn-kix_v38dn35flf8h-4
        }

        .lst-kix_t05e6zjr7d8z-5>li {
            counter-increment: lst-ctn-kix_t05e6zjr7d8z-5
        }

        .lst-kix_jtgxrppdhs5b-0>li:before {
            content: "" counter(lst-ctn-kix_jtgxrppdhs5b-0, decimal) ". "
        }

        .lst-kix_lr8xgw4v5wfg-2>li:before {
            content: "" counter(lst-ctn-kix_lr8xgw4v5wfg-2, lower-roman) ". "
        }

        .lst-kix_amhh9wwmrxzs-5>li {
            counter-increment: lst-ctn-kix_amhh9wwmrxzs-5
        }

        ol.lst-kix_1uhaebmjlx87-5 {
            list-style-type: none
        }

        .lst-kix_lr8xgw4v5wfg-6>li:before {
            content: "" counter(lst-ctn-kix_lr8xgw4v5wfg-6, decimal) ". "
        }

        ol.lst-kix_1uhaebmjlx87-6 {
            list-style-type: none
        }

        ol.lst-kix_1uhaebmjlx87-7 {
            list-style-type: none
        }

        ol.lst-kix_1uhaebmjlx87-8 {
            list-style-type: none
        }

        ol.lst-kix_lr8xgw4v5wfg-6.start {
            counter-reset: lst-ctn-kix_lr8xgw4v5wfg-6 0
        }

        .lst-kix_jtgxrppdhs5b-2>li:before {
            content: "" counter(lst-ctn-kix_jtgxrppdhs5b-2, lower-roman) ". "
        }

        .lst-kix_lr8xgw4v5wfg-0>li:before {
            content: "" counter(lst-ctn-kix_lr8xgw4v5wfg-0, decimal) ". "
        }

        .lst-kix_lr8xgw4v5wfg-8>li:before {
            content: "" counter(lst-ctn-kix_lr8xgw4v5wfg-8, lower-roman) ". "
        }

        ol.lst-kix_v38dn35flf8h-6.start {
            counter-reset: lst-ctn-kix_v38dn35flf8h-6 0
        }

        ol.lst-kix_p1xw0vc0x49w-2.start {
            counter-reset: lst-ctn-kix_p1xw0vc0x49w-2 0
        }

        .lst-kix_1uhaebmjlx87-7>li:before {
            content: "" counter(lst-ctn-kix_1uhaebmjlx87-0, decimal) "." counter(lst-ctn-kix_1uhaebmjlx87-1, decimal) "." counter(lst-ctn-kix_1uhaebmjlx87-2, decimal) "." counter(lst-ctn-kix_1uhaebmjlx87-3, decimal) "." counter(lst-ctn-kix_1uhaebmjlx87-4, decimal) "." counter(lst-ctn-kix_1uhaebmjlx87-5, decimal) "." counter(lst-ctn-kix_1uhaebmjlx87-6, decimal) "." counter(lst-ctn-kix_1uhaebmjlx87-7, decimal) ". "
        }

        ol.lst-kix_fczziklbivy6-7.start {
            counter-reset: lst-ctn-kix_fczziklbivy6-7 0
        }

        .lst-kix_1uhaebmjlx87-5>li:before {
            content: "" counter(lst-ctn-kix_1uhaebmjlx87-0, decimal) "." counter(lst-ctn-kix_1uhaebmjlx87-1, decimal) "." counter(lst-ctn-kix_1uhaebmjlx87-2, decimal) "." counter(lst-ctn-kix_1uhaebmjlx87-3, decimal) "." counter(lst-ctn-kix_1uhaebmjlx87-4, decimal) "." counter(lst-ctn-kix_1uhaebmjlx87-5, decimal) ". "
        }

        ol.lst-kix_vkmonltz3m4x-0.start {
            counter-reset: lst-ctn-kix_vkmonltz3m4x-0 0
        }

        .lst-kix_7a1f1loavfm8-3>li {
            counter-increment: lst-ctn-kix_7a1f1loavfm8-3
        }

        ol.lst-kix_p1xw0vc0x49w-5.start {
            counter-reset: lst-ctn-kix_p1xw0vc0x49w-5 0
        }

        .lst-kix_lr8xgw4v5wfg-4>li {
            counter-increment: lst-ctn-kix_lr8xgw4v5wfg-4
        }

        .lst-kix_lr8xgw4v5wfg-4>li:before {
            content: "" counter(lst-ctn-kix_lr8xgw4v5wfg-4, lower-latin) ". "
        }

        ol.lst-kix_r4skt585moyl-3.start {
            counter-reset: lst-ctn-kix_r4skt585moyl-3 0
        }

        ol.lst-kix_fczziklbivy6-4.start {
            counter-reset: lst-ctn-kix_fczziklbivy6-4 0
        }

        .lst-kix_nsh5xilkuf1a-3>li:before {
            content: "" counter(lst-ctn-kix_nsh5xilkuf1a-3, decimal) ". "
        }

        .lst-kix_nsh5xilkuf1a-5>li:before {
            content: "" counter(lst-ctn-kix_nsh5xilkuf1a-5, lower-roman) ". "
        }

        ol.lst-kix_hp5w6vum06pm-4.start {
            counter-reset: lst-ctn-kix_hp5w6vum06pm-4 0
        }

        ol.lst-kix_r4skt585moyl-1.start {
            counter-reset: lst-ctn-kix_r4skt585moyl-1 0
        }

        .lst-kix_mh7larxc34v5-4>li {
            counter-increment: lst-ctn-kix_mh7larxc34v5-4
        }

        ol.lst-kix_nsh5xilkuf1a-3.start {
            counter-reset: lst-ctn-kix_nsh5xilkuf1a-3 0
        }

        ol.lst-kix_1uhaebmjlx87-0 {
            list-style-type: none
        }

        ol.lst-kix_1uhaebmjlx87-1 {
            list-style-type: none
        }

        ol.lst-kix_1uhaebmjlx87-2 {
            list-style-type: none
        }

        ol.lst-kix_1uhaebmjlx87-3 {
            list-style-type: none
        }

        ol.lst-kix_1uhaebmjlx87-4 {
            list-style-type: none
        }

        ol.lst-kix_e6qtjfbzpvfl-7.start {
            counter-reset: lst-ctn-kix_e6qtjfbzpvfl-7 0
        }

        .lst-kix_e6qtjfbzpvfl-3>li {
            counter-increment: lst-ctn-kix_e6qtjfbzpvfl-3
        }

        .lst-kix_nsh5xilkuf1a-1>li:before {
            content: "" counter(lst-ctn-kix_nsh5xilkuf1a-1, lower-latin) ". "
        }

        .lst-kix_p1xw0vc0x49w-3>li {
            counter-increment: lst-ctn-kix_p1xw0vc0x49w-3
        }

        ol.lst-kix_273ltq9wy9y6-0 {
            list-style-type: none
        }

        ol.lst-kix_v38dn35flf8h-8.start {
            counter-reset: lst-ctn-kix_v38dn35flf8h-8 0
        }

        .lst-kix_jtgxrppdhs5b-2>li {
            counter-increment: lst-ctn-kix_jtgxrppdhs5b-2
        }

        .lst-kix_nsh5xilkuf1a-2>li {
            counter-increment: lst-ctn-kix_nsh5xilkuf1a-2
        }

        .lst-kix_29l9atrxjrcc-0>li {
            counter-increment: lst-ctn-kix_29l9atrxjrcc-0
        }

        .lst-kix_amhh9wwmrxzs-6>li {
            counter-increment: lst-ctn-kix_amhh9wwmrxzs-6
        }

        ol.lst-kix_273ltq9wy9y6-8 {
            list-style-type: none
        }

        ol.lst-kix_273ltq9wy9y6-7 {
            list-style-type: none
        }

        .lst-kix_fczziklbivy6-6>li {
            counter-increment: lst-ctn-kix_fczziklbivy6-6
        }

        .lst-kix_vkmonltz3m4x-1>li {
            counter-increment: lst-ctn-kix_vkmonltz3m4x-1
        }

        ol.lst-kix_273ltq9wy9y6-6 {
            list-style-type: none
        }

        .lst-kix_nsh5xilkuf1a-7>li:before {
            content: "" counter(lst-ctn-kix_nsh5xilkuf1a-7, lower-latin) ". "
        }

        ol.lst-kix_273ltq9wy9y6-5 {
            list-style-type: none
        }

        ol.lst-kix_273ltq9wy9y6-4 {
            list-style-type: none
        }

        ol.lst-kix_273ltq9wy9y6-3 {
            list-style-type: none
        }

        ol.lst-kix_273ltq9wy9y6-2 {
            list-style-type: none
        }

        ol.lst-kix_273ltq9wy9y6-1 {
            list-style-type: none
        }

        .lst-kix_29l9atrxjrcc-7>li {
            counter-increment: lst-ctn-kix_29l9atrxjrcc-7
        }

        .lst-kix_e6qtjfbzpvfl-3>li:before {
            content: "" counter(lst-ctn-kix_e6qtjfbzpvfl-3, decimal) ". "
        }

        ol.lst-kix_fczziklbivy6-5.start {
            counter-reset: lst-ctn-kix_fczziklbivy6-5 0
        }

        .lst-kix_e6qtjfbzpvfl-2>li:before {
            content: "" counter(lst-ctn-kix_e6qtjfbzpvfl-2, lower-roman) ". "
        }

        .lst-kix_e6qtjfbzpvfl-6>li:before {
            content: "" counter(lst-ctn-kix_e6qtjfbzpvfl-6, decimal) ". "
        }

        ol.lst-kix_7a1f1loavfm8-7.start {
            counter-reset: lst-ctn-kix_7a1f1loavfm8-7 0
        }

        .lst-kix_7a1f1loavfm8-0>li {
            counter-increment: lst-ctn-kix_7a1f1loavfm8-0
        }

        .lst-kix_v38dn35flf8h-0>li {
            counter-increment: lst-ctn-kix_v38dn35flf8h-0
        }

        .lst-kix_t05e6zjr7d8z-1>li {
            counter-increment: lst-ctn-kix_t05e6zjr7d8z-1
        }

        ol.lst-kix_mh7larxc34v5-6.start {
            counter-reset: lst-ctn-kix_mh7larxc34v5-6 0
        }

        .lst-kix_273ltq9wy9y6-6>li {
            counter-increment: lst-ctn-kix_273ltq9wy9y6-6
        }

        .lst-kix_e6qtjfbzpvfl-7>li:before {
            content: "" counter(lst-ctn-kix_e6qtjfbzpvfl-7, lower-latin) ". "
        }

        .lst-kix_e6qtjfbzpvfl-0>li {
            counter-increment: lst-ctn-kix_e6qtjfbzpvfl-0
        }

        ol.lst-kix_vkmonltz3m4x-6 {
            list-style-type: none
        }

        ol.lst-kix_hp5w6vum06pm-3.start {
            counter-reset: lst-ctn-kix_hp5w6vum06pm-3 0
        }

        ol.lst-kix_vkmonltz3m4x-7 {
            list-style-type: none
        }

        ol.lst-kix_vkmonltz3m4x-8 {
            list-style-type: none
        }

        ol.lst-kix_t05e6zjr7d8z-5.start {
            counter-reset: lst-ctn-kix_t05e6zjr7d8z-5 0
        }

        ol.lst-kix_amhh9wwmrxzs-2.start {
            counter-reset: lst-ctn-kix_amhh9wwmrxzs-2 0
        }

        ol.lst-kix_1uhaebmjlx87-2.start {
            counter-reset: lst-ctn-kix_1uhaebmjlx87-2 0
        }

        ol.lst-kix_vkmonltz3m4x-0 {
            list-style-type: none
        }

        ol.lst-kix_vkmonltz3m4x-1 {
            list-style-type: none
        }

        ol.lst-kix_vkmonltz3m4x-2 {
            list-style-type: none
        }

        ol.lst-kix_vkmonltz3m4x-3 {
            list-style-type: none
        }

        ol.lst-kix_vkmonltz3m4x-4 {
            list-style-type: none
        }

        ol.lst-kix_vkmonltz3m4x-5 {
            list-style-type: none
        }

        .lst-kix_hp5w6vum06pm-4>li {
            counter-increment: lst-ctn-kix_hp5w6vum06pm-4
        }

        ol.lst-kix_29l9atrxjrcc-0.start {
            counter-reset: lst-ctn-kix_29l9atrxjrcc-0 0
        }

        .lst-kix_r4skt585moyl-3>li {
            counter-increment: lst-ctn-kix_r4skt585moyl-3
        }

        .lst-kix_lr8xgw4v5wfg-1>li {
            counter-increment: lst-ctn-kix_lr8xgw4v5wfg-1
        }

        .lst-kix_273ltq9wy9y6-4>li {
            counter-increment: lst-ctn-kix_273ltq9wy9y6-4
        }

        .lst-kix_r4skt585moyl-2>li:before {
            content: "" counter(lst-ctn-kix_r4skt585moyl-2, lower-roman) ". "
        }

        .lst-kix_t05e6zjr7d8z-3>li:before {
            content: "" counter(lst-ctn-kix_t05e6zjr7d8z-3, decimal) ". "
        }

        ol.lst-kix_1uhaebmjlx87-1.start {
            counter-reset: lst-ctn-kix_1uhaebmjlx87-1 0
        }

        ol.lst-kix_7a1f1loavfm8-2.start {
            counter-reset: lst-ctn-kix_7a1f1loavfm8-2 0
        }

        .lst-kix_r4skt585moyl-3>li:before {
            content: "" counter(lst-ctn-kix_r4skt585moyl-3, decimal) ". "
        }

        .lst-kix_vkmonltz3m4x-2>li:before {
            content: "" counter(lst-ctn-kix_vkmonltz3m4x-2, lower-roman) ". "
        }

        .lst-kix_v38dn35flf8h-2>li {
            counter-increment: lst-ctn-kix_v38dn35flf8h-2
        }

        .lst-kix_vkmonltz3m4x-1>li:before {
            content: "" counter(lst-ctn-kix_vkmonltz3m4x-1, lower-latin) ". "
        }

        .lst-kix_t05e6zjr7d8z-3>li {
            counter-increment: lst-ctn-kix_t05e6zjr7d8z-3
        }

        ol.lst-kix_1uhaebmjlx87-8.start {
            counter-reset: lst-ctn-kix_1uhaebmjlx87-8 0
        }

        .lst-kix_r4skt585moyl-7>li:before {
            content: "" counter(lst-ctn-kix_r4skt585moyl-7, lower-latin) ". "
        }

        ol.lst-kix_amhh9wwmrxzs-1.start {
            counter-reset: lst-ctn-kix_amhh9wwmrxzs-1 0
        }

        .lst-kix_t05e6zjr7d8z-2>li:before {
            content: "" counter(lst-ctn-kix_t05e6zjr7d8z-2, lower-roman) ". "
        }

        .lst-kix_r4skt585moyl-6>li:before {
            content: "" counter(lst-ctn-kix_r4skt585moyl-6, decimal) ". "
        }

        ol.lst-kix_hp5w6vum06pm-3 {
            list-style-type: none
        }

        .lst-kix_lr8xgw4v5wfg-3>li {
            counter-increment: lst-ctn-kix_lr8xgw4v5wfg-3
        }

        ol.lst-kix_hp5w6vum06pm-2 {
            list-style-type: none
        }

        .lst-kix_fczziklbivy6-7>li {
            counter-increment: lst-ctn-kix_fczziklbivy6-7
        }

        ol.lst-kix_hp5w6vum06pm-5 {
            list-style-type: none
        }

        ol.lst-kix_hp5w6vum06pm-4 {
            list-style-type: none
        }

        ol.lst-kix_hp5w6vum06pm-7 {
            list-style-type: none
        }

        .lst-kix_vkmonltz3m4x-6>li:before {
            content: "" counter(lst-ctn-kix_vkmonltz3m4x-6, decimal) ". "
        }

        .lst-kix_nsh5xilkuf1a-4>li {
            counter-increment: lst-ctn-kix_nsh5xilkuf1a-4
        }

        ol.lst-kix_hp5w6vum06pm-6 {
            list-style-type: none
        }

        ol.lst-kix_v38dn35flf8h-5.start {
            counter-reset: lst-ctn-kix_v38dn35flf8h-5 0
        }

        .lst-kix_vkmonltz3m4x-5>li:before {
            content: "" counter(lst-ctn-kix_vkmonltz3m4x-5, lower-roman) ". "
        }

        ol.lst-kix_hp5w6vum06pm-8 {
            list-style-type: none
        }

        ol.lst-kix_amhh9wwmrxzs-8.start {
            counter-reset: lst-ctn-kix_amhh9wwmrxzs-8 0
        }

        .lst-kix_t05e6zjr7d8z-8>li {
            counter-increment: lst-ctn-kix_t05e6zjr7d8z-8
        }

        .lst-kix_r4skt585moyl-5>li {
            counter-increment: lst-ctn-kix_r4skt585moyl-5
        }

        .lst-kix_e6qtjfbzpvfl-2>li {
            counter-increment: lst-ctn-kix_e6qtjfbzpvfl-2
        }

        ol.lst-kix_7a1f1loavfm8-8.start {
            counter-reset: lst-ctn-kix_7a1f1loavfm8-8 0
        }

        .lst-kix_mh7larxc34v5-8>li:before {
            content: "" counter(lst-ctn-kix_mh7larxc34v5-8, lower-roman) ". "
        }

        ol.lst-kix_t05e6zjr7d8z-0.start {
            counter-reset: lst-ctn-kix_t05e6zjr7d8z-0 0
        }

        ol.lst-kix_hp5w6vum06pm-1 {
            list-style-type: none
        }

        .lst-kix_1uhaebmjlx87-5>li {
            counter-increment: lst-ctn-kix_1uhaebmjlx87-5
        }

        ol.lst-kix_1uhaebmjlx87-7.start {
            counter-reset: lst-ctn-kix_1uhaebmjlx87-7 0
        }

        ol.lst-kix_hp5w6vum06pm-0 {
            list-style-type: none
        }

        .lst-kix_7a1f1loavfm8-2>li {
            counter-increment: lst-ctn-kix_7a1f1loavfm8-2
        }

        ol.lst-kix_amhh9wwmrxzs-7.start {
            counter-reset: lst-ctn-kix_amhh9wwmrxzs-7 0
        }

        .lst-kix_t05e6zjr7d8z-6>li:before {
            content: "" counter(lst-ctn-kix_t05e6zjr7d8z-6, decimal) ". "
        }

        .lst-kix_t05e6zjr7d8z-7>li:before {
            content: "" counter(lst-ctn-kix_t05e6zjr7d8z-7, lower-latin) ". "
        }

        ol.lst-kix_7a1f1loavfm8-1.start {
            counter-reset: lst-ctn-kix_7a1f1loavfm8-1 0
        }

        ol.lst-kix_v38dn35flf8h-4.start {
            counter-reset: lst-ctn-kix_v38dn35flf8h-4 0
        }

        ol.lst-kix_t05e6zjr7d8z-6.start {
            counter-reset: lst-ctn-kix_t05e6zjr7d8z-6 0
        }

        ol.lst-kix_amhh9wwmrxzs-7 {
            list-style-type: none
        }

        ol.lst-kix_amhh9wwmrxzs-6 {
            list-style-type: none
        }

        ol.lst-kix_amhh9wwmrxzs-3.start {
            counter-reset: lst-ctn-kix_amhh9wwmrxzs-3 0
        }

        ol.lst-kix_amhh9wwmrxzs-5 {
            list-style-type: none
        }

        ol.lst-kix_amhh9wwmrxzs-4 {
            list-style-type: none
        }

        ol.lst-kix_amhh9wwmrxzs-3 {
            list-style-type: none
        }

        ol.lst-kix_1uhaebmjlx87-6.start {
            counter-reset: lst-ctn-kix_1uhaebmjlx87-6 0
        }

        .lst-kix_mh7larxc34v5-1>li {
            counter-increment: lst-ctn-kix_mh7larxc34v5-1
        }

        ol.lst-kix_amhh9wwmrxzs-2 {
            list-style-type: none
        }

        ol.lst-kix_amhh9wwmrxzs-1 {
            list-style-type: none
        }

        ol.lst-kix_amhh9wwmrxzs-0 {
            list-style-type: none
        }

        .lst-kix_amhh9wwmrxzs-4>li {
            counter-increment: lst-ctn-kix_amhh9wwmrxzs-4
        }

        .lst-kix_t05e6zjr7d8z-6>li {
            counter-increment: lst-ctn-kix_t05e6zjr7d8z-6
        }

        ol.lst-kix_1uhaebmjlx87-3.start {
            counter-reset: lst-ctn-kix_1uhaebmjlx87-3 0
        }

        .lst-kix_1uhaebmjlx87-2>li:before {
            content: "" counter(lst-ctn-kix_1uhaebmjlx87-0, decimal) "." counter(lst-ctn-kix_1uhaebmjlx87-1, decimal) "." counter(lst-ctn-kix_1uhaebmjlx87-2, decimal) ". "
        }

        .lst-kix_p1xw0vc0x49w-6>li {
            counter-increment: lst-ctn-kix_p1xw0vc0x49w-6
        }

        .lst-kix_e6qtjfbzpvfl-7>li {
            counter-increment: lst-ctn-kix_e6qtjfbzpvfl-7
        }

        .lst-kix_jtgxrppdhs5b-7>li:before {
            content: "" counter(lst-ctn-kix_jtgxrppdhs5b-7, lower-latin) ". "
        }

        ol.lst-kix_v38dn35flf8h-0.start {
            counter-reset: lst-ctn-kix_v38dn35flf8h-0 0
        }

        ol.lst-kix_v38dn35flf8h-3.start {
            counter-reset: lst-ctn-kix_v38dn35flf8h-3 0
        }

        .lst-kix_mh7larxc34v5-5>li:before {
            content: "" counter(lst-ctn-kix_mh7larxc34v5-5, lower-roman) ". "
        }

        .lst-kix_hp5w6vum06pm-6>li {
            counter-increment: lst-ctn-kix_hp5w6vum06pm-6
        }

        .lst-kix_jtgxrppdhs5b-3>li:before {
            content: "" counter(lst-ctn-kix_jtgxrppdhs5b-3, decimal) ". "
        }

        .lst-kix_lr8xgw4v5wfg-1>li:before {
            content: "" counter(lst-ctn-kix_lr8xgw4v5wfg-1, decimal) ". "
        }

        .lst-kix_lr8xgw4v5wfg-5>li:before {
            content: "" counter(lst-ctn-kix_lr8xgw4v5wfg-5, lower-roman) ". "
        }

        .lst-kix_amhh9wwmrxzs-2>li {
            counter-increment: lst-ctn-kix_amhh9wwmrxzs-2
        }

        ol.lst-kix_amhh9wwmrxzs-6.start {
            counter-reset: lst-ctn-kix_amhh9wwmrxzs-6 0
        }

        ol.lst-kix_7a1f1loavfm8-3.start {
            counter-reset: lst-ctn-kix_7a1f1loavfm8-3 0
        }

        ol.lst-kix_mh7larxc34v5-2.start {
            counter-reset: lst-ctn-kix_mh7larxc34v5-2 0
        }

        ol.lst-kix_fczziklbivy6-1.start {
            counter-reset: lst-ctn-kix_fczziklbivy6-1 0
        }

        ol.lst-kix_t05e6zjr7d8z-1.start {
            counter-reset: lst-ctn-kix_t05e6zjr7d8z-1 0
        }

        .lst-kix_mh7larxc34v5-1>li:before {
            content: "" counter(lst-ctn-kix_mh7larxc34v5-1, lower-latin) ". "
        }

        .lst-kix_29l9atrxjrcc-2>li {
            counter-increment: lst-ctn-kix_29l9atrxjrcc-2
        }

        ol.lst-kix_7a1f1loavfm8-6.start {
            counter-reset: lst-ctn-kix_7a1f1loavfm8-6 0
        }

        .lst-kix_1uhaebmjlx87-6>li:before {
            content: "" counter(lst-ctn-kix_1uhaebmjlx87-0, decimal) "." counter(lst-ctn-kix_1uhaebmjlx87-1, decimal) "." counter(lst-ctn-kix_1uhaebmjlx87-2, decimal) "." counter(lst-ctn-kix_1uhaebmjlx87-3, decimal) "." counter(lst-ctn-kix_1uhaebmjlx87-4, decimal) "." counter(lst-ctn-kix_1uhaebmjlx87-5, decimal) "." counter(lst-ctn-kix_1uhaebmjlx87-6, decimal) ". "
        }

        .lst-kix_nsh5xilkuf1a-6>li {
            counter-increment: lst-ctn-kix_nsh5xilkuf1a-6
        }

        ol.lst-kix_7a1f1loavfm8-2 {
            list-style-type: none
        }

        ol.lst-kix_t05e6zjr7d8z-4.start {
            counter-reset: lst-ctn-kix_t05e6zjr7d8z-4 0
        }

        ol.lst-kix_7a1f1loavfm8-3 {
            list-style-type: none
        }

        ol.lst-kix_v38dn35flf8h-2.start {
            counter-reset: lst-ctn-kix_v38dn35flf8h-2 0
        }

        ol.lst-kix_7a1f1loavfm8-0 {
            list-style-type: none
        }

        .lst-kix_1uhaebmjlx87-0>li {
            counter-increment: lst-ctn-kix_1uhaebmjlx87-0
        }

        ol.lst-kix_7a1f1loavfm8-1 {
            list-style-type: none
        }

        .lst-kix_273ltq9wy9y6-2>li:before {
            content: "" counter(lst-ctn-kix_273ltq9wy9y6-0, decimal) "." counter(lst-ctn-kix_273ltq9wy9y6-1, decimal) "." counter(lst-ctn-kix_273ltq9wy9y6-2, decimal) ". "
        }

        ol.lst-kix_7a1f1loavfm8-6 {
            list-style-type: none
        }

        .lst-kix_nsh5xilkuf1a-2>li:before {
            content: "" counter(lst-ctn-kix_nsh5xilkuf1a-2, lower-roman) ". "
        }

        .lst-kix_nsh5xilkuf1a-6>li:before {
            content: "" counter(lst-ctn-kix_nsh5xilkuf1a-6, decimal) ". "
        }

        ol.lst-kix_7a1f1loavfm8-7 {
            list-style-type: none
        }

        .lst-kix_lr8xgw4v5wfg-8>li {
            counter-increment: lst-ctn-kix_lr8xgw4v5wfg-8
        }

        ol.lst-kix_7a1f1loavfm8-4 {
            list-style-type: none
        }

        ol.lst-kix_7a1f1loavfm8-5 {
            list-style-type: none
        }

        ol.lst-kix_amhh9wwmrxzs-5.start {
            counter-reset: lst-ctn-kix_amhh9wwmrxzs-5 0
        }

        ol.lst-kix_7a1f1loavfm8-8 {
            list-style-type: none
        }

        .lst-kix_273ltq9wy9y6-6>li:before {
            content: "" counter(lst-ctn-kix_273ltq9wy9y6-0, decimal) "." counter(lst-ctn-kix_273ltq9wy9y6-1, decimal) "." counter(lst-ctn-kix_273ltq9wy9y6-2, decimal) "." counter(lst-ctn-kix_273ltq9wy9y6-3, decimal) "." counter(lst-ctn-kix_273ltq9wy9y6-4, decimal) "." counter(lst-ctn-kix_273ltq9wy9y6-5, decimal) "." counter(lst-ctn-kix_273ltq9wy9y6-6, decimal) ". "
        }

        ol.lst-kix_mh7larxc34v5-1.start {
            counter-reset: lst-ctn-kix_mh7larxc34v5-1 0
        }

        ol.lst-kix_1uhaebmjlx87-4.start {
            counter-reset: lst-ctn-kix_1uhaebmjlx87-4 0
        }

        .lst-kix_1uhaebmjlx87-7>li {
            counter-increment: lst-ctn-kix_1uhaebmjlx87-7
        }

        .lst-kix_v38dn35flf8h-7>li {
            counter-increment: lst-ctn-kix_v38dn35flf8h-7
        }

        ol.lst-kix_fczziklbivy6-0.start {
            counter-reset: lst-ctn-kix_fczziklbivy6-0 0
        }

        .lst-kix_jtgxrppdhs5b-5>li {
            counter-increment: lst-ctn-kix_jtgxrppdhs5b-5
        }

        ol.lst-kix_7a1f1loavfm8-5.start {
            counter-reset: lst-ctn-kix_7a1f1loavfm8-5 0
        }

        ol.lst-kix_mh7larxc34v5-6 {
            list-style-type: none
        }

        ol.lst-kix_mh7larxc34v5-5 {
            list-style-type: none
        }

        .lst-kix_7a1f1loavfm8-7>li {
            counter-increment: lst-ctn-kix_7a1f1loavfm8-7
        }

        ol.lst-kix_mh7larxc34v5-4 {
            list-style-type: none
        }

        ol.lst-kix_v38dn35flf8h-1.start {
            counter-reset: lst-ctn-kix_v38dn35flf8h-1 0
        }

        ol.lst-kix_amhh9wwmrxzs-4.start {
            counter-reset: lst-ctn-kix_amhh9wwmrxzs-4 0
        }

        ol.lst-kix_mh7larxc34v5-3 {
            list-style-type: none
        }

        ol.lst-kix_t05e6zjr7d8z-3.start {
            counter-reset: lst-ctn-kix_t05e6zjr7d8z-3 0
        }

        ol.lst-kix_mh7larxc34v5-8 {
            list-style-type: none
        }

        ol.lst-kix_mh7larxc34v5-7 {
            list-style-type: none
        }

        ol.lst-kix_mh7larxc34v5-2 {
            list-style-type: none
        }

        ol.lst-kix_mh7larxc34v5-1 {
            list-style-type: none
        }

        ol.lst-kix_mh7larxc34v5-0 {
            list-style-type: none
        }

        ol.lst-kix_mh7larxc34v5-0.start {
            counter-reset: lst-ctn-kix_mh7larxc34v5-0 0
        }

        .lst-kix_vkmonltz3m4x-4>li {
            counter-increment: lst-ctn-kix_vkmonltz3m4x-4
        }

        ol.lst-kix_7a1f1loavfm8-4.start {
            counter-reset: lst-ctn-kix_7a1f1loavfm8-4 0
        }

        .lst-kix_v38dn35flf8h-0>li:before {
            content: "" counter(lst-ctn-kix_v38dn35flf8h-0, decimal) ". "
        }

        .lst-kix_v38dn35flf8h-2>li:before {
            content: "" counter(lst-ctn-kix_v38dn35flf8h-2, lower-roman) ". "
        }

        ol.lst-kix_v38dn35flf8h-7.start {
            counter-reset: lst-ctn-kix_v38dn35flf8h-7 0
        }

        ol.lst-kix_jtgxrppdhs5b-1.start {
            counter-reset: lst-ctn-kix_jtgxrppdhs5b-1 0
        }

        .lst-kix_v38dn35flf8h-1>li:before {
            content: "" counter(lst-ctn-kix_v38dn35flf8h-1, lower-latin) ". "
        }

        .lst-kix_p1xw0vc0x49w-1>li {
            counter-increment: lst-ctn-kix_p1xw0vc0x49w-1
        }

        ol.lst-kix_mh7larxc34v5-3.start {
            counter-reset: lst-ctn-kix_mh7larxc34v5-3 0
        }

        .lst-kix_273ltq9wy9y6-5>li {
            counter-increment: lst-ctn-kix_273ltq9wy9y6-5
        }

        .lst-kix_e6qtjfbzpvfl-1>li {
            counter-increment: lst-ctn-kix_e6qtjfbzpvfl-1
        }

        .lst-kix_v38dn35flf8h-7>li:before {
            content: "" counter(lst-ctn-kix_v38dn35flf8h-7, lower-latin) ". "
        }

        .lst-kix_v38dn35flf8h-8>li:before {
            content: "" counter(lst-ctn-kix_v38dn35flf8h-8, lower-roman) ". "
        }

        .lst-kix_fczziklbivy6-4>li {
            counter-increment: lst-ctn-kix_fczziklbivy6-4
        }

        .lst-kix_nsh5xilkuf1a-1>li {
            counter-increment: lst-ctn-kix_nsh5xilkuf1a-1
        }

        ol.lst-kix_p1xw0vc0x49w-1.start {
            counter-reset: lst-ctn-kix_p1xw0vc0x49w-1 0
        }

        .lst-kix_mh7larxc34v5-6>li {
            counter-increment: lst-ctn-kix_mh7larxc34v5-6
        }

        ol.lst-kix_r4skt585moyl-7.start {
            counter-reset: lst-ctn-kix_r4skt585moyl-7 0
        }

        .lst-kix_1uhaebmjlx87-4>li {
            counter-increment: lst-ctn-kix_1uhaebmjlx87-4
        }

        .lst-kix_7a1f1loavfm8-1>li {
            counter-increment: lst-ctn-kix_7a1f1loavfm8-1
        }

        ol.lst-kix_fczziklbivy6-8.start {
            counter-reset: lst-ctn-kix_fczziklbivy6-8 0
        }

        .lst-kix_7a1f1loavfm8-5>li:before {
            content: "" counter(lst-ctn-kix_7a1f1loavfm8-5, lower-roman) ". "
        }

        ol.lst-kix_nsh5xilkuf1a-3 {
            list-style-type: none
        }

        ol.lst-kix_nsh5xilkuf1a-2 {
            list-style-type: none
        }

        .lst-kix_7a1f1loavfm8-4>li:before {
            content: "" counter(lst-ctn-kix_7a1f1loavfm8-4, lower-latin) ". "
        }

        .lst-kix_7a1f1loavfm8-6>li:before {
            content: "" counter(lst-ctn-kix_7a1f1loavfm8-6, decimal) ". "
        }

        ol.lst-kix_nsh5xilkuf1a-1 {
            list-style-type: none
        }

        ol.lst-kix_nsh5xilkuf1a-0 {
            list-style-type: none
        }

        ol.lst-kix_29l9atrxjrcc-3.start {
            counter-reset: lst-ctn-kix_29l9atrxjrcc-3 0
        }

        .lst-kix_7a1f1loavfm8-1>li:before {
            content: "" counter(lst-ctn-kix_7a1f1loavfm8-1, lower-latin) ". "
        }

        .lst-kix_7a1f1loavfm8-2>li:before {
            content: "" counter(lst-ctn-kix_7a1f1loavfm8-2, lower-roman) ". "
        }

        .lst-kix_7a1f1loavfm8-3>li:before {
            content: "" counter(lst-ctn-kix_7a1f1loavfm8-3, decimal) ". "
        }

        ol.lst-kix_t05e6zjr7d8z-8.start {
            counter-reset: lst-ctn-kix_t05e6zjr7d8z-8 0
        }

        .lst-kix_vkmonltz3m4x-0>li {
            counter-increment: lst-ctn-kix_vkmonltz3m4x-0
        }

        ol.lst-kix_e6qtjfbzpvfl-3.start {
            counter-reset: lst-ctn-kix_e6qtjfbzpvfl-3 0
        }

        .lst-kix_v38dn35flf8h-6>li:before {
            content: "" counter(lst-ctn-kix_v38dn35flf8h-6, decimal) ". "
        }

        .lst-kix_v38dn35flf8h-5>li:before {
            content: "" counter(lst-ctn-kix_v38dn35flf8h-5, lower-roman) ". "
        }

        .lst-kix_hp5w6vum06pm-1>li {
            counter-increment: lst-ctn-kix_hp5w6vum06pm-1
        }

        .lst-kix_v38dn35flf8h-4>li:before {
            content: "" counter(lst-ctn-kix_v38dn35flf8h-4, lower-latin) ". "
        }

        .lst-kix_7a1f1loavfm8-0>li:before {
            content: "" counter(lst-ctn-kix_7a1f1loavfm8-0, decimal) ". "
        }

        ol.lst-kix_mh7larxc34v5-8.start {
            counter-reset: lst-ctn-kix_mh7larxc34v5-8 0
        }

        .lst-kix_t05e6zjr7d8z-0>li {
            counter-increment: lst-ctn-kix_t05e6zjr7d8z-0
        }

        .lst-kix_r4skt585moyl-0>li {
            counter-increment: lst-ctn-kix_r4skt585moyl-0
        }

        .lst-kix_v38dn35flf8h-3>li:before {
            content: "" counter(lst-ctn-kix_v38dn35flf8h-3, decimal) ". "
        }

        .lst-kix_jtgxrppdhs5b-0>li {
            counter-increment: lst-ctn-kix_jtgxrppdhs5b-0
        }

        ol.lst-kix_1uhaebmjlx87-5.start {
            counter-reset: lst-ctn-kix_1uhaebmjlx87-5 0
        }

        ol.lst-kix_nsh5xilkuf1a-0.start {
            counter-reset: lst-ctn-kix_nsh5xilkuf1a-0 0
        }

        .lst-kix_fczziklbivy6-3>li:before {
            content: "" counter(lst-ctn-kix_fczziklbivy6-3, decimal) ". "
        }

        .lst-kix_fczziklbivy6-5>li:before {
            content: "" counter(lst-ctn-kix_fczziklbivy6-5, lower-roman) ". "
        }

        ol.lst-kix_fczziklbivy6-3.start {
            counter-reset: lst-ctn-kix_fczziklbivy6-3 0
        }

        .lst-kix_fczziklbivy6-4>li:before {
            content: "" counter(lst-ctn-kix_fczziklbivy6-4, lower-latin) ". "
        }

        ol.lst-kix_29l9atrxjrcc-2 {
            list-style-type: none
        }

        .lst-kix_fczziklbivy6-0>li:before {
            content: "" counter(lst-ctn-kix_fczziklbivy6-0, decimal) ". "
        }

        ol.lst-kix_29l9atrxjrcc-3 {
            list-style-type: none
        }

        ol.lst-kix_29l9atrxjrcc-4 {
            list-style-type: none
        }

        ol.lst-kix_29l9atrxjrcc-5 {
            list-style-type: none
        }

        .lst-kix_fczziklbivy6-7>li:before {
            content: "" counter(lst-ctn-kix_fczziklbivy6-7, lower-latin) ". "
        }

        ol.lst-kix_nsh5xilkuf1a-7.start {
            counter-reset: lst-ctn-kix_nsh5xilkuf1a-7 0
        }

        ol.lst-kix_29l9atrxjrcc-6 {
            list-style-type: none
        }

        ol.lst-kix_jtgxrppdhs5b-6.start {
            counter-reset: lst-ctn-kix_jtgxrppdhs5b-6 0
        }

        ol.lst-kix_29l9atrxjrcc-7 {
            list-style-type: none
        }

        ol.lst-kix_29l9atrxjrcc-8 {
            list-style-type: none
        }

        .lst-kix_amhh9wwmrxzs-7>li {
            counter-increment: lst-ctn-kix_amhh9wwmrxzs-7
        }

        .lst-kix_fczziklbivy6-6>li:before {
            content: "" counter(lst-ctn-kix_fczziklbivy6-6, decimal) ". "
        }

        ol.lst-kix_hp5w6vum06pm-0.start {
            counter-reset: lst-ctn-kix_hp5w6vum06pm-0 0
        }

        ol.lst-kix_hp5w6vum06pm-7.start {
            counter-reset: lst-ctn-kix_hp5w6vum06pm-7 0
        }

        .lst-kix_7a1f1loavfm8-8>li {
            counter-increment: lst-ctn-kix_7a1f1loavfm8-8
        }

        ol.lst-kix_29l9atrxjrcc-0 {
            list-style-type: none
        }

        ol.lst-kix_29l9atrxjrcc-1 {
            list-style-type: none
        }

        .lst-kix_7a1f1loavfm8-8>li:before {
            content: "" counter(lst-ctn-kix_7a1f1loavfm8-8, lower-roman) ". "
        }

        .lst-kix_fczziklbivy6-1>li:before {
            content: "" counter(lst-ctn-kix_fczziklbivy6-1, lower-latin) ". "
        }

        ol.lst-kix_nsh5xilkuf1a-8 {
            list-style-type: none
        }

        .lst-kix_7a1f1loavfm8-7>li:before {
            content: "" counter(lst-ctn-kix_7a1f1loavfm8-7, lower-latin) ". "
        }

        ol.lst-kix_nsh5xilkuf1a-7 {
            list-style-type: none
        }

        ol.lst-kix_nsh5xilkuf1a-6 {
            list-style-type: none
        }

        ol.lst-kix_nsh5xilkuf1a-5 {
            list-style-type: none
        }

        .lst-kix_fczziklbivy6-2>li:before {
            content: "" counter(lst-ctn-kix_fczziklbivy6-2, lower-roman) ". "
        }

        ol.lst-kix_nsh5xilkuf1a-4 {
            list-style-type: none
        }

        .lst-kix_e6qtjfbzpvfl-5>li {
            counter-increment: lst-ctn-kix_e6qtjfbzpvfl-5
        }

        ol.lst-kix_1uhaebmjlx87-0.start {
            counter-reset: lst-ctn-kix_1uhaebmjlx87-0 0
        }

        .lst-kix_p1xw0vc0x49w-5>li {
            counter-increment: lst-ctn-kix_p1xw0vc0x49w-5
        }

        ol.lst-kix_p1xw0vc0x49w-6.start {
            counter-reset: lst-ctn-kix_p1xw0vc0x49w-6 0
        }

        .lst-kix_jtgxrppdhs5b-7>li {
            counter-increment: lst-ctn-kix_jtgxrppdhs5b-7
        }

        ol.lst-kix_lr8xgw4v5wfg-5.start {
            counter-reset: lst-ctn-kix_lr8xgw4v5wfg-5 0
        }

        .lst-kix_mh7larxc34v5-2>li {
            counter-increment: lst-ctn-kix_mh7larxc34v5-2
        }

        .lst-kix_fczziklbivy6-8>li:before {
            content: "" counter(lst-ctn-kix_fczziklbivy6-8, lower-roman) ". "
        }

        .lst-kix_hp5w6vum06pm-5>li {
            counter-increment: lst-ctn-kix_hp5w6vum06pm-5
        }

        .lst-kix_hp5w6vum06pm-8>li {
            counter-increment: lst-ctn-kix_hp5w6vum06pm-8
        }

        .lst-kix_p1xw0vc0x49w-8>li {
            counter-increment: lst-ctn-kix_p1xw0vc0x49w-8
        }

        .lst-kix_e6qtjfbzpvfl-8>li {
            counter-increment: lst-ctn-kix_e6qtjfbzpvfl-8
        }

        ol.lst-kix_amhh9wwmrxzs-8 {
            list-style-type: none
        }

        ol.lst-kix_273ltq9wy9y6-6.start {
            counter-reset: lst-ctn-kix_273ltq9wy9y6-6 0
        }

        ol.lst-kix_e6qtjfbzpvfl-5.start {
            counter-reset: lst-ctn-kix_e6qtjfbzpvfl-5 0
        }

        .lst-kix_lr8xgw4v5wfg-5>li {
            counter-increment: lst-ctn-kix_lr8xgw4v5wfg-5
        }

        .lst-kix_7a1f1loavfm8-5>li {
            counter-increment: lst-ctn-kix_7a1f1loavfm8-5
        }

        ol.lst-kix_nsh5xilkuf1a-2.start {
            counter-reset: lst-ctn-kix_nsh5xilkuf1a-2 0
        }

        .lst-kix_lr8xgw4v5wfg-6>li {
            counter-increment: lst-ctn-kix_lr8xgw4v5wfg-6
        }

        ol.lst-kix_hp5w6vum06pm-5.start {
            counter-reset: lst-ctn-kix_hp5w6vum06pm-5 0
        }

        .lst-kix_mh7larxc34v5-4>li:before {
            content: "" counter(lst-ctn-kix_mh7larxc34v5-4, lower-latin) ". "
        }

        ol.lst-kix_hp5w6vum06pm-2.start {
            counter-reset: lst-ctn-kix_hp5w6vum06pm-2 0
        }

        ol.lst-kix_nsh5xilkuf1a-5.start {
            counter-reset: lst-ctn-kix_nsh5xilkuf1a-5 0
        }

        .lst-kix_r4skt585moyl-8>li {
            counter-increment: lst-ctn-kix_r4skt585moyl-8
        }

        .lst-kix_mh7larxc34v5-6>li:before {
            content: "" counter(lst-ctn-kix_mh7larxc34v5-6, decimal) ". "
        }

        .lst-kix_273ltq9wy9y6-1>li {
            counter-increment: lst-ctn-kix_273ltq9wy9y6-1
        }

        .lst-kix_mh7larxc34v5-2>li:before {
            content: "" counter(lst-ctn-kix_mh7larxc34v5-2, lower-roman) ". "
        }

        .lst-kix_v38dn35flf8h-3>li {
            counter-increment: lst-ctn-kix_v38dn35flf8h-3
        }

        ol.lst-kix_r4skt585moyl-0.start {
            counter-reset: lst-ctn-kix_r4skt585moyl-0 0
        }

        ol.lst-kix_mh7larxc34v5-5.start {
            counter-reset: lst-ctn-kix_mh7larxc34v5-5 0
        }

        .lst-kix_mh7larxc34v5-0>li:before {
            content: "" counter(lst-ctn-kix_mh7larxc34v5-0, decimal) ". "
        }

        .lst-kix_jtgxrppdhs5b-3>li {
            counter-increment: lst-ctn-kix_jtgxrppdhs5b-3
        }

        ol.lst-kix_jtgxrppdhs5b-8.start {
            counter-reset: lst-ctn-kix_jtgxrppdhs5b-8 0
        }

        .lst-kix_p1xw0vc0x49w-4>li {
            counter-increment: lst-ctn-kix_p1xw0vc0x49w-4
        }

        ol.lst-kix_p1xw0vc0x49w-3.start {
            counter-reset: lst-ctn-kix_p1xw0vc0x49w-3 0
        }

        .lst-kix_273ltq9wy9y6-3>li:before {
            content: "" counter(lst-ctn-kix_273ltq9wy9y6-0, decimal) "." counter(lst-ctn-kix_273ltq9wy9y6-1, decimal) "." counter(lst-ctn-kix_273ltq9wy9y6-2, decimal) "." counter(lst-ctn-kix_273ltq9wy9y6-3, decimal) ". "
        }

        .lst-kix_r4skt585moyl-0>li:before {
            content: "" counter(lst-ctn-kix_r4skt585moyl-0, decimal) ". "
        }

        .lst-kix_fczziklbivy6-0>li {
            counter-increment: lst-ctn-kix_fczziklbivy6-0
        }

        .lst-kix_e6qtjfbzpvfl-4>li {
            counter-increment: lst-ctn-kix_e6qtjfbzpvfl-4
        }

        .lst-kix_273ltq9wy9y6-1>li:before {
            content: "" counter(lst-ctn-kix_273ltq9wy9y6-0, decimal) "." counter(lst-ctn-kix_273ltq9wy9y6-1, decimal) ". "
        }

        .lst-kix_273ltq9wy9y6-8>li {
            counter-increment: lst-ctn-kix_273ltq9wy9y6-8
        }

        .lst-kix_273ltq9wy9y6-7>li:before {
            content: "" counter(lst-ctn-kix_273ltq9wy9y6-0, decimal) "." counter(lst-ctn-kix_273ltq9wy9y6-1, decimal) "." counter(lst-ctn-kix_273ltq9wy9y6-2, decimal) "." counter(lst-ctn-kix_273ltq9wy9y6-3, decimal) "." counter(lst-ctn-kix_273ltq9wy9y6-4, decimal) "." counter(lst-ctn-kix_273ltq9wy9y6-5, decimal) "." counter(lst-ctn-kix_273ltq9wy9y6-6, decimal) "." counter(lst-ctn-kix_273ltq9wy9y6-7, decimal) ". "
        }

        .lst-kix_nsh5xilkuf1a-8>li {
            counter-increment: lst-ctn-kix_nsh5xilkuf1a-8
        }

        .lst-kix_mh7larxc34v5-3>li {
            counter-increment: lst-ctn-kix_mh7larxc34v5-3
        }

        .lst-kix_273ltq9wy9y6-2>li {
            counter-increment: lst-ctn-kix_273ltq9wy9y6-2
        }

        .lst-kix_273ltq9wy9y6-5>li:before {
            content: "" counter(lst-ctn-kix_273ltq9wy9y6-0, decimal) "." counter(lst-ctn-kix_273ltq9wy9y6-1, decimal) "." counter(lst-ctn-kix_273ltq9wy9y6-2, decimal) "." counter(lst-ctn-kix_273ltq9wy9y6-3, decimal) "." counter(lst-ctn-kix_273ltq9wy9y6-4, decimal) "." counter(lst-ctn-kix_273ltq9wy9y6-5, decimal) ". "
        }

        ol.lst-kix_273ltq9wy9y6-8.start {
            counter-reset: lst-ctn-kix_273ltq9wy9y6-8 0
        }

        ol.lst-kix_r4skt585moyl-2.start {
            counter-reset: lst-ctn-kix_r4skt585moyl-2 0
        }

        ol.lst-kix_lr8xgw4v5wfg-8.start {
            counter-reset: lst-ctn-kix_lr8xgw4v5wfg-8 0
        }

        ol.lst-kix_p1xw0vc0x49w-4.start {
            counter-reset: lst-ctn-kix_p1xw0vc0x49w-4 0
        }

        ol.lst-kix_29l9atrxjrcc-1.start {
            counter-reset: lst-ctn-kix_29l9atrxjrcc-1 0
        }

        .lst-kix_7a1f1loavfm8-4>li {
            counter-increment: lst-ctn-kix_7a1f1loavfm8-4
        }

        .lst-kix_r4skt585moyl-7>li {
            counter-increment: lst-ctn-kix_r4skt585moyl-7
        }

        .lst-kix_r4skt585moyl-1>li {
            counter-increment: lst-ctn-kix_r4skt585moyl-1
        }

        ol.lst-kix_fczziklbivy6-6.start {
            counter-reset: lst-ctn-kix_fczziklbivy6-6 0
        }

        .lst-kix_amhh9wwmrxzs-0>li {
            counter-increment: lst-ctn-kix_amhh9wwmrxzs-0
        }

        .lst-kix_t05e6zjr7d8z-4>li {
            counter-increment: lst-ctn-kix_t05e6zjr7d8z-4
        }

        .lst-kix_29l9atrxjrcc-6>li {
            counter-increment: lst-ctn-kix_29l9atrxjrcc-6
        }

        .lst-kix_vkmonltz3m4x-7>li {
            counter-increment: lst-ctn-kix_vkmonltz3m4x-7
        }

        ol.lst-kix_e6qtjfbzpvfl-8.start {
            counter-reset: lst-ctn-kix_e6qtjfbzpvfl-8 0
        }

        .lst-kix_e6qtjfbzpvfl-5>li:before {
            content: "" counter(lst-ctn-kix_e6qtjfbzpvfl-5, lower-roman) ". "
        }

        .lst-kix_mh7larxc34v5-7>li {
            counter-increment: lst-ctn-kix_mh7larxc34v5-7
        }

        ol.lst-kix_lr8xgw4v5wfg-7.start {
            counter-reset: lst-ctn-kix_lr8xgw4v5wfg-7 0
        }

        .lst-kix_nsh5xilkuf1a-0>li {
            counter-increment: lst-ctn-kix_nsh5xilkuf1a-0
        }

        .lst-kix_1uhaebmjlx87-3>li {
            counter-increment: lst-ctn-kix_1uhaebmjlx87-3
        }

        ol.lst-kix_vkmonltz3m4x-2.start {
            counter-reset: lst-ctn-kix_vkmonltz3m4x-2 0
        }

        .lst-kix_hp5w6vum06pm-2>li {
            counter-increment: lst-ctn-kix_hp5w6vum06pm-2
        }

        .lst-kix_e6qtjfbzpvfl-4>li:before {
            content: "" counter(lst-ctn-kix_e6qtjfbzpvfl-4, lower-latin) ". "
        }

        .lst-kix_p1xw0vc0x49w-0>li {
            counter-increment: lst-ctn-kix_p1xw0vc0x49w-0
        }

        ol.lst-kix_jtgxrppdhs5b-4.start {
            counter-reset: lst-ctn-kix_jtgxrppdhs5b-4 0
        }

        .lst-kix_jtgxrppdhs5b-8>li {
            counter-increment: lst-ctn-kix_jtgxrppdhs5b-8
        }

        .lst-kix_e6qtjfbzpvfl-8>li:before {
            content: "" counter(lst-ctn-kix_e6qtjfbzpvfl-8, lower-roman) ". "
        }

        .lst-kix_amhh9wwmrxzs-8>li {
            counter-increment: lst-ctn-kix_amhh9wwmrxzs-8
        }

        ol.lst-kix_29l9atrxjrcc-6.start {
            counter-reset: lst-ctn-kix_29l9atrxjrcc-6 0
        }

        .lst-kix_29l9atrxjrcc-5>li {
            counter-increment: lst-ctn-kix_29l9atrxjrcc-5
        }

        .lst-kix_fczziklbivy6-5>li {
            counter-increment: lst-ctn-kix_fczziklbivy6-5
        }

        ol.lst-kix_e6qtjfbzpvfl-6.start {
            counter-reset: lst-ctn-kix_e6qtjfbzpvfl-6 0
        }

        .lst-kix_vkmonltz3m4x-8>li:before {
            content: "" counter(lst-ctn-kix_vkmonltz3m4x-8, lower-roman) ". "
        }

        ol.lst-kix_vkmonltz3m4x-7.start {
            counter-reset: lst-ctn-kix_vkmonltz3m4x-7 0
        }

        .lst-kix_e6qtjfbzpvfl-1>li:before {
            content: "" counter(lst-ctn-kix_e6qtjfbzpvfl-1, lower-latin) ". "
        }

        ol.lst-kix_273ltq9wy9y6-4.start {
            counter-reset: lst-ctn-kix_273ltq9wy9y6-4 0
        }

        ol.lst-kix_nsh5xilkuf1a-4.start {
            counter-reset: lst-ctn-kix_nsh5xilkuf1a-4 0
        }

        .lst-kix_e6qtjfbzpvfl-0>li:before {
            content: "" counter(lst-ctn-kix_e6qtjfbzpvfl-0, decimal) ". "
        }

        .lst-kix_vkmonltz3m4x-0>li:before {
            content: "" counter(lst-ctn-kix_vkmonltz3m4x-0, decimal) ". "
        }

        .lst-kix_amhh9wwmrxzs-1>li {
            counter-increment: lst-ctn-kix_amhh9wwmrxzs-1
        }

        .lst-kix_t05e6zjr7d8z-0>li:before {
            content: "" counter(lst-ctn-kix_t05e6zjr7d8z-0, decimal) ". "
        }

        .lst-kix_t05e6zjr7d8z-4>li:before {
            content: "" counter(lst-ctn-kix_t05e6zjr7d8z-4, lower-latin) ". "
        }

        .lst-kix_r4skt585moyl-4>li:before {
            content: "" counter(lst-ctn-kix_r4skt585moyl-4, lower-latin) ". "
        }

        .lst-kix_r4skt585moyl-5>li:before {
            content: "" counter(lst-ctn-kix_r4skt585moyl-5, lower-roman) ". "
        }

        .lst-kix_t05e6zjr7d8z-1>li:before {
            content: "" counter(lst-ctn-kix_t05e6zjr7d8z-1, lower-latin) ". "
        }

        .lst-kix_vkmonltz3m4x-7>li:before {
            content: "" counter(lst-ctn-kix_vkmonltz3m4x-7, lower-latin) ". "
        }

        .lst-kix_vkmonltz3m4x-8>li {
            counter-increment: lst-ctn-kix_vkmonltz3m4x-8
        }

        .lst-kix_vkmonltz3m4x-3>li {
            counter-increment: lst-ctn-kix_vkmonltz3m4x-3
        }

        .lst-kix_jtgxrppdhs5b-1>li {
            counter-increment: lst-ctn-kix_jtgxrppdhs5b-1
        }

        ol.lst-kix_r4skt585moyl-4.start {
            counter-reset: lst-ctn-kix_r4skt585moyl-4 0
        }

        .lst-kix_r4skt585moyl-8>li:before {
            content: "" counter(lst-ctn-kix_r4skt585moyl-8, lower-roman) ". "
        }

        .lst-kix_vkmonltz3m4x-4>li:before {
            content: "" counter(lst-ctn-kix_vkmonltz3m4x-4, lower-latin) ". "
        }

        .lst-kix_fczziklbivy6-1>li {
            counter-increment: lst-ctn-kix_fczziklbivy6-1
        }

        ol.lst-kix_vkmonltz3m4x-1.start {
            counter-reset: lst-ctn-kix_vkmonltz3m4x-1 0
        }

        .lst-kix_vkmonltz3m4x-3>li:before {
            content: "" counter(lst-ctn-kix_vkmonltz3m4x-3, decimal) ". "
        }

        .lst-kix_mh7larxc34v5-7>li:before {
            content: "" counter(lst-ctn-kix_mh7larxc34v5-7, lower-latin) ". "
        }

        .lst-kix_p1xw0vc0x49w-2>li {
            counter-increment: lst-ctn-kix_p1xw0vc0x49w-2
        }

        ol.lst-kix_hp5w6vum06pm-8.start {
            counter-reset: lst-ctn-kix_hp5w6vum06pm-8 0
        }

        ol.lst-kix_273ltq9wy9y6-5.start {
            counter-reset: lst-ctn-kix_273ltq9wy9y6-5 0
        }

        ol.lst-kix_vkmonltz3m4x-8.start {
            counter-reset: lst-ctn-kix_vkmonltz3m4x-8 0
        }

        .lst-kix_nsh5xilkuf1a-7>li {
            counter-increment: lst-ctn-kix_nsh5xilkuf1a-7
        }

        ol.lst-kix_r4skt585moyl-5.start {
            counter-reset: lst-ctn-kix_r4skt585moyl-5 0
        }

        .lst-kix_mh7larxc34v5-5>li {
            counter-increment: lst-ctn-kix_mh7larxc34v5-5
        }

        .lst-kix_t05e6zjr7d8z-8>li:before {
            content: "" counter(lst-ctn-kix_t05e6zjr7d8z-8, lower-roman) ". "
        }

        .lst-kix_t05e6zjr7d8z-5>li:before {
            content: "" counter(lst-ctn-kix_t05e6zjr7d8z-5, lower-roman) ". "
        }

        ol.lst-kix_29l9atrxjrcc-5.start {
            counter-reset: lst-ctn-kix_29l9atrxjrcc-5 0
        }

        .lst-kix_1uhaebmjlx87-0>li:before {
            content: "" counter(lst-ctn-kix_1uhaebmjlx87-0, decimal) ". "
        }

        .lst-kix_vkmonltz3m4x-5>li {
            counter-increment: lst-ctn-kix_vkmonltz3m4x-5
        }

        ol.lst-kix_lr8xgw4v5wfg-0.start {
            counter-reset: lst-ctn-kix_lr8xgw4v5wfg-0 0
        }

        .lst-kix_e6qtjfbzpvfl-6>li {
            counter-increment: lst-ctn-kix_e6qtjfbzpvfl-6
        }

        .lst-kix_hp5w6vum06pm-7>li {
            counter-increment: lst-ctn-kix_hp5w6vum06pm-7
        }

        .lst-kix_273ltq9wy9y6-0>li {
            counter-increment: lst-ctn-kix_273ltq9wy9y6-0
        }

        .lst-kix_jtgxrppdhs5b-4>li {
            counter-increment: lst-ctn-kix_jtgxrppdhs5b-4
        }

        .lst-kix_p1xw0vc0x49w-7>li {
            counter-increment: lst-ctn-kix_p1xw0vc0x49w-7
        }

        ol.lst-kix_273ltq9wy9y6-3.start {
            counter-reset: lst-ctn-kix_273ltq9wy9y6-3 0
        }

        ol.lst-kix_e6qtjfbzpvfl-2.start {
            counter-reset: lst-ctn-kix_e6qtjfbzpvfl-2 0
        }

        .lst-kix_1uhaebmjlx87-8>li {
            counter-increment: lst-ctn-kix_1uhaebmjlx87-8
        }

        ol.lst-kix_vkmonltz3m4x-6.start {
            counter-reset: lst-ctn-kix_vkmonltz3m4x-6 0
        }

        ol.lst-kix_nsh5xilkuf1a-8.start {
            counter-reset: lst-ctn-kix_nsh5xilkuf1a-8 0
        }

        .lst-kix_mh7larxc34v5-0>li {
            counter-increment: lst-ctn-kix_mh7larxc34v5-0
        }

        .lst-kix_jtgxrppdhs5b-5>li:before {
            content: "" counter(lst-ctn-kix_jtgxrppdhs5b-5, lower-roman) ". "
        }

        ol.lst-kix_273ltq9wy9y6-0.start {
            counter-reset: lst-ctn-kix_273ltq9wy9y6-0 0
        }

        .lst-kix_fczziklbivy6-8>li {
            counter-increment: lst-ctn-kix_fczziklbivy6-8
        }

        .lst-kix_lr8xgw4v5wfg-7>li {
            counter-increment: lst-ctn-kix_lr8xgw4v5wfg-7
        }

        .lst-kix_mh7larxc34v5-3>li:before {
            content: "" counter(lst-ctn-kix_mh7larxc34v5-3, decimal) ". "
        }

        .lst-kix_jtgxrppdhs5b-1>li:before {
            content: "" counter(lst-ctn-kix_jtgxrppdhs5b-1, lower-latin) ". "
        }

        .lst-kix_lr8xgw4v5wfg-7>li:before {
            content: "" counter(lst-ctn-kix_lr8xgw4v5wfg-7, lower-latin) ". "
        }

        ol.lst-kix_jtgxrppdhs5b-2.start {
            counter-reset: lst-ctn-kix_jtgxrppdhs5b-2 0
        }

        ol.lst-kix_vkmonltz3m4x-3.start {
            counter-reset: lst-ctn-kix_vkmonltz3m4x-3 0
        }

        ol.lst-kix_r4skt585moyl-6.start {
            counter-reset: lst-ctn-kix_r4skt585moyl-6 0
        }

        .lst-kix_v38dn35flf8h-6>li {
            counter-increment: lst-ctn-kix_v38dn35flf8h-6
        }

        .lst-kix_1uhaebmjlx87-4>li:before {
            content: "" counter(lst-ctn-kix_1uhaebmjlx87-0, decimal) "." counter(lst-ctn-kix_1uhaebmjlx87-1, decimal) "." counter(lst-ctn-kix_1uhaebmjlx87-2, decimal) "." counter(lst-ctn-kix_1uhaebmjlx87-3, decimal) "." counter(lst-ctn-kix_1uhaebmjlx87-4, decimal) ". "
        }

        .lst-kix_1uhaebmjlx87-8>li:before {
            content: "" counter(lst-ctn-kix_1uhaebmjlx87-0, decimal) "." counter(lst-ctn-kix_1uhaebmjlx87-1, decimal) "." counter(lst-ctn-kix_1uhaebmjlx87-2, decimal) "." counter(lst-ctn-kix_1uhaebmjlx87-3, decimal) "." counter(lst-ctn-kix_1uhaebmjlx87-4, decimal) "." counter(lst-ctn-kix_1uhaebmjlx87-5, decimal) "." counter(lst-ctn-kix_1uhaebmjlx87-6, decimal) "." counter(lst-ctn-kix_1uhaebmjlx87-7, decimal) "." counter(lst-ctn-kix_1uhaebmjlx87-8, decimal) ". "
        }

        ol.lst-kix_jtgxrppdhs5b-5.start {
            counter-reset: lst-ctn-kix_jtgxrppdhs5b-5 0
        }

        .lst-kix_7a1f1loavfm8-6>li {
            counter-increment: lst-ctn-kix_7a1f1loavfm8-6
        }

        ol.lst-kix_lr8xgw4v5wfg-3.start {
            counter-reset: lst-ctn-kix_lr8xgw4v5wfg-3 0
        }

        ol.lst-kix_p1xw0vc0x49w-8.start {
            counter-reset: lst-ctn-kix_p1xw0vc0x49w-8 0
        }

        .lst-kix_lr8xgw4v5wfg-3>li:before {
            content: "" counter(lst-ctn-kix_lr8xgw4v5wfg-3, decimal) ". "
        }

        .lst-kix_1uhaebmjlx87-6>li {
            counter-increment: lst-ctn-kix_1uhaebmjlx87-6
        }

        .lst-kix_273ltq9wy9y6-0>li:before {
            content: "" counter(lst-ctn-kix_273ltq9wy9y6-0, decimal) ". "
        }

        .lst-kix_nsh5xilkuf1a-4>li:before {
            content: "" counter(lst-ctn-kix_nsh5xilkuf1a-4, lower-latin) ". "
        }

        .lst-kix_r4skt585moyl-1>li:before {
            content: "" counter(lst-ctn-kix_r4skt585moyl-1, lower-latin) ". "
        }

        ol.lst-kix_r4skt585moyl-8.start {
            counter-reset: lst-ctn-kix_r4skt585moyl-8 0
        }

        ol.lst-kix_e6qtjfbzpvfl-0.start {
            counter-reset: lst-ctn-kix_e6qtjfbzpvfl-0 0
        }

        .lst-kix_jtgxrppdhs5b-6>li {
            counter-increment: lst-ctn-kix_jtgxrppdhs5b-6
        }

        ol.lst-kix_vkmonltz3m4x-4.start {
            counter-reset: lst-ctn-kix_vkmonltz3m4x-4 0
        }

        ol.lst-kix_lr8xgw4v5wfg-2.start {
            counter-reset: lst-ctn-kix_lr8xgw4v5wfg-2 0
        }

        .lst-kix_lr8xgw4v5wfg-2>li {
            counter-increment: lst-ctn-kix_lr8xgw4v5wfg-2
        }

        ol.lst-kix_273ltq9wy9y6-1.start {
            counter-reset: lst-ctn-kix_273ltq9wy9y6-1 0
        }

        ol.lst-kix_29l9atrxjrcc-7.start {
            counter-reset: lst-ctn-kix_29l9atrxjrcc-7 0
        }

        .lst-kix_273ltq9wy9y6-8>li:before {
            content: "" counter(lst-ctn-kix_273ltq9wy9y6-0, decimal) "." counter(lst-ctn-kix_273ltq9wy9y6-1, decimal) "." counter(lst-ctn-kix_273ltq9wy9y6-2, decimal) "." counter(lst-ctn-kix_273ltq9wy9y6-3, decimal) "." counter(lst-ctn-kix_273ltq9wy9y6-4, decimal) "." counter(lst-ctn-kix_273ltq9wy9y6-5, decimal) "." counter(lst-ctn-kix_273ltq9wy9y6-6, decimal) "." counter(lst-ctn-kix_273ltq9wy9y6-7, decimal) "." counter(lst-ctn-kix_273ltq9wy9y6-8, decimal) ". "
        }

        .lst-kix_273ltq9wy9y6-4>li:before {
            content: "" counter(lst-ctn-kix_273ltq9wy9y6-0, decimal) "." counter(lst-ctn-kix_273ltq9wy9y6-1, decimal) "." counter(lst-ctn-kix_273ltq9wy9y6-2, decimal) "." counter(lst-ctn-kix_273ltq9wy9y6-3, decimal) "." counter(lst-ctn-kix_273ltq9wy9y6-4, decimal) ". "
        }

        .lst-kix_nsh5xilkuf1a-0>li:before {
            content: "" counter(lst-ctn-kix_nsh5xilkuf1a-0, decimal) ". "
        }

        .lst-kix_hp5w6vum06pm-0>li {
            counter-increment: lst-ctn-kix_hp5w6vum06pm-0
        }

        .lst-kix_v38dn35flf8h-1>li {
            counter-increment: lst-ctn-kix_v38dn35flf8h-1
        }

        .lst-kix_1uhaebmjlx87-1>li {
            counter-increment: lst-ctn-kix_1uhaebmjlx87-1
        }

        ol.lst-kix_e6qtjfbzpvfl-6 {
            list-style-type: none
        }

        ol.lst-kix_e6qtjfbzpvfl-5 {
            list-style-type: none
        }

        ol.lst-kix_e6qtjfbzpvfl-4 {
            list-style-type: none
        }

        ol.lst-kix_e6qtjfbzpvfl-3 {
            list-style-type: none
        }

        ol.lst-kix_e6qtjfbzpvfl-8 {
            list-style-type: none
        }

        ol.lst-kix_29l9atrxjrcc-8.start {
            counter-reset: lst-ctn-kix_29l9atrxjrcc-8 0
        }

        ol.lst-kix_e6qtjfbzpvfl-7 {
            list-style-type: none
        }

        ol.lst-kix_e6qtjfbzpvfl-1.start {
            counter-reset: lst-ctn-kix_e6qtjfbzpvfl-1 0
        }

        .lst-kix_29l9atrxjrcc-3>li {
            counter-increment: lst-ctn-kix_29l9atrxjrcc-3
        }

        .lst-kix_nsh5xilkuf1a-5>li {
            counter-increment: lst-ctn-kix_nsh5xilkuf1a-5
        }

        .lst-kix_r4skt585moyl-4>li {
            counter-increment: lst-ctn-kix_r4skt585moyl-4
        }

        li.li-bullet-0:before {
            margin-left: -18pt;
            white-space: nowrap;
            display: inline-block;
            min-width: 18pt
        }

        ol.lst-kix_lr8xgw4v5wfg-1.start {
            counter-reset: lst-ctn-kix_lr8xgw4v5wfg-1 0
        }

        ol.lst-kix_p1xw0vc0x49w-7 {
            list-style-type: none
        }

        .lst-kix_t05e6zjr7d8z-7>li {
            counter-increment: lst-ctn-kix_t05e6zjr7d8z-7
        }

        ol.lst-kix_p1xw0vc0x49w-6 {
            list-style-type: none
        }

        ol.lst-kix_p1xw0vc0x49w-8 {
            list-style-type: none
        }

        ol.lst-kix_p1xw0vc0x49w-3 {
            list-style-type: none
        }

        ol.lst-kix_273ltq9wy9y6-2.start {
            counter-reset: lst-ctn-kix_273ltq9wy9y6-2 0
        }

        ol.lst-kix_p1xw0vc0x49w-2 {
            list-style-type: none
        }

        ol.lst-kix_p1xw0vc0x49w-5 {
            list-style-type: none
        }

        ol.lst-kix_jtgxrppdhs5b-3.start {
            counter-reset: lst-ctn-kix_jtgxrppdhs5b-3 0
        }

        ol.lst-kix_p1xw0vc0x49w-4 {
            list-style-type: none
        }

        ol.lst-kix_vkmonltz3m4x-5.start {
            counter-reset: lst-ctn-kix_vkmonltz3m4x-5 0
        }

        .lst-kix_fczziklbivy6-3>li {
            counter-increment: lst-ctn-kix_fczziklbivy6-3
        }

        .lst-kix_nsh5xilkuf1a-8>li:before {
            content: "" counter(lst-ctn-kix_nsh5xilkuf1a-8, lower-roman) ". "
        }

        ol.lst-kix_p1xw0vc0x49w-1 {
            list-style-type: none
        }

        ol.lst-kix_p1xw0vc0x49w-0 {
            list-style-type: none
        }

        ol.lst-kix_e6qtjfbzpvfl-2 {
            list-style-type: none
        }

        ol.lst-kix_e6qtjfbzpvfl-1 {
            list-style-type: none
        }

        .lst-kix_amhh9wwmrxzs-3>li {
            counter-increment: lst-ctn-kix_amhh9wwmrxzs-3
        }

        ol.lst-kix_e6qtjfbzpvfl-0 {
            list-style-type: none
        }

        ol {
            margin: 0;
            padding: 0
        }

        table td,
        table th {
            padding: 0
        }

        .c0 {
            margin-left: 72pt;
            padding-top: 20pt;
            padding-left: 0pt;
            padding-bottom: 8pt;
            line-height: 1.15;
            orphans: 2;
            widows: 2;
            text-align: left
        }

        .c5 {
            margin-left: 36pt;
            padding-top: 20pt;
            padding-left: 0pt;
            padding-bottom: 8pt;
            line-height: 1.15;
            orphans: 2;
            widows: 2;
            text-align: left
        }

        .c1 {
            padding-top: 0pt;
            padding-bottom: 0pt;
            line-height: 1.15;
            orphans: 2;
            widows: 2;
            text-align: left;
            height: 11pt
        }

        .c14 {
            padding-top: 20pt;
            padding-bottom: 8pt;
            line-height: 1.6;
            page-break-after: avoid;
            orphans: 2;
            widows: 2;
            text-align: center
        }

        .c2 {
            color: #666666;
            font-weight: 400;
            text-decoration: none;
            vertical-align: baseline;
            font-size: 11pt;
            font-family: "Arial";
            font-style: normal
        }

        .c3 {
            color: #666666;
            font-weight: 400;
            text-decoration: none;
            vertical-align: baseline;
            font-size: 10.5pt;
            font-family: "Arial";
            font-style: normal
        }

        .c12 {
            color: #666666;
            font-weight: 400;
            text-decoration: none;
            vertical-align: baseline;
            font-size: 9pt;
            font-family: "Arial";
            font-style: normal
        }

        .c7 {
            color: #434343;
            font-weight: 400;
            text-decoration: none;
            vertical-align: baseline;
            font-size: 19.5pt;
            font-family: "Arial";
            font-style: normal
        }

        .c17 {
            padding-top: 20pt;
            padding-bottom: 8pt;
            line-height: 1.6;
            orphans: 2;
            widows: 2;
            text-align: left
        }

        .c6 {
            padding-top: 20pt;
            padding-bottom: 8pt;
            line-height: 1.15;
            orphans: 2;
            widows: 2;
            text-align: left
        }

        .c11 {
            font-weight: 700;
            text-decoration: none;
            vertical-align: baseline;
            font-family: "Arial";
            font-style: normal
        }

        .c10 {
            text-decoration-skip-ink: none;
            font-size: 10.5pt;
            -webkit-text-decoration-skip: none;
            color: #1155cc;
            text-decoration: underline
        }

        .c16 {
            background-color: #ffffff;
            max-width: 451.4pt;
            padding: 72pt 72pt 72pt 72pt
        }

        .c4 {
            padding: 0;
            margin: 0
        }

        .c15 {
            color: inherit;
            text-decoration: inherit
        }

        .c8 {
            color: #666666;
            font-size: 10.5pt
        }

        .c13 {
            color: #666666;
            font-size: 24pt
        }

        .c9 {
            height: 11pt
        }

        .title {
            padding-top: 0pt;
            color: #000000;
            font-size: 26pt;
            padding-bottom: 3pt;
            font-family: "Arial";
            line-height: 1.15;
            page-break-after: avoid;
            orphans: 2;
            widows: 2;
            text-align: left
        }

        .subtitle {
            padding-top: 0pt;
            color: #666666;
            font-size: 15pt;
            padding-bottom: 16pt;
            font-family: "Arial";
            line-height: 1.15;
            page-break-after: avoid;
            orphans: 2;
            widows: 2;
            text-align: left
        }

        li {
            color: #000000;
            font-size: 11pt;
            font-family: "Arial"
        }

        p {
            margin: 0;
            color: #000000;
            font-size: 11pt;
            font-family: "Arial"
        }

        h1 {
            padding-top: 20pt;
            color: #000000;
            font-size: 20pt;
            padding-bottom: 6pt;
            font-family: "Arial";
            line-height: 1.15;
            page-break-after: avoid;
            orphans: 2;
            widows: 2;
            text-align: left
        }

        h2 {
            padding-top: 18pt;
            color: #000000;
            font-size: 16pt;
            padding-bottom: 6pt;
            font-family: "Arial";
            line-height: 1.15;
            page-break-after: avoid;
            orphans: 2;
            widows: 2;
            text-align: left
        }

        h3 {
            padding-top: 16pt;
            color: #434343;
            font-size: 14pt;
            padding-bottom: 4pt;
            font-family: "Arial";
            line-height: 1.15;
            page-break-after: avoid;
            orphans: 2;
            widows: 2;
            text-align: left
        }

        h4 {
            padding-top: 14pt;
            color: #666666;
            font-size: 12pt;
            padding-bottom: 4pt;
            font-family: "Arial";
            line-height: 1.15;
            page-break-after: avoid;
            orphans: 2;
            widows: 2;
            text-align: left
        }

        h5 {
            padding-top: 12pt;
            color: #666666;
            font-size: 11pt;
            padding-bottom: 4pt;
            font-family: "Arial";
            line-height: 1.15;
            page-break-after: avoid;
            orphans: 2;
            widows: 2;
            text-align: left
        }

        h6 {
            padding-top: 12pt;
            color: #666666;
            font-size: 11pt;
            padding-bottom: 4pt;
            font-family: "Arial";
            line-height: 1.15;
            page-break-after: avoid;
            font-style: italic;
            orphans: 2;
            widows: 2;
            text-align: left
        }
    </style>
</head>

<body class="c16">
    <h1 class="c14" id="h.8fl7wwe7m6x0"><span class="c13">Termos e condi&ccedil;&otilde;es da </span><span
            class="c11 c13">ECOMPLY.io GmbH</span></h1>
    <p class="c17"><span class="c12">IMPORTANTE! OBSERVA&Ccedil;&Atilde;O: Voc&ecirc; concorda com os Termos e
            Condi&ccedil;&otilde;es alem&atilde;es que foram traduzidos para o portugu&ecirc;s somente para sua
            conveni&ecirc;ncia. Os Termos e Condi&ccedil;&otilde;es em alem&atilde;o s&atilde;o
            obrigat&oacute;rios.</span></p>
    <p class="c6"><span class="c3">Estes Termos e Condi&ccedil;&otilde;es s&atilde;o parte de um contrato entre a
            Ecomply. GmbH (doravante denominada &quot;Ecomply.io&quot;) e seus clientes sobre o uso do software
            Ecomply.io e solu&ccedil;&otilde;es e servi&ccedil;os relacionados.</span></p>
    <h3 class="c6" id="h.e96juz2bz0z1"><span class="c7">1. Defini&ccedil;&otilde;es e Termos de Servi&ccedil;o</span>
    </h3>
    <ol class="c4 lst-kix_v38dn35flf8h-0 start" start="1">
        <li class="c5 li-bullet-0"><span class="c3">Os softwares s&atilde;o add-ins de software, extens&otilde;es de
                navegador, aplica&ccedil;&otilde;es m&oacute;veis, websites ou outras m&iacute;dias nomeadas.</span>
        </li>
        <li class="c5 li-bullet-0"><span class="c3">&quot;Usu&aacute;rio&quot; refere-se aos usu&aacute;rios do
                software, bem como a todos os nossos outros servi&ccedil;os.</span></li>
        <li class="c5 li-bullet-0"><span class="c3">&quot;Clientes&quot; refere-se a organiza&ccedil;&otilde;es,
                institui&ccedil;&otilde;es, governos locais, ind&uacute;strias ou empresas que est&atilde;o usando o
                software Ecomply.io. </span></li>
        <li class="c5 li-bullet-0"><span class="c8">O escopo de nossos servi&ccedil;os resulta de sua
                descri&ccedil;&atilde;o em </span><span class="c10"><a class="c15"
                    href="https://www.google.com/url?q=http://www.ecomply.io&amp;sa=D&amp;source=editors&amp;ust=1657024313994215&amp;usg=AOvVaw3f3PNfrSShuyvTCG8HoA-p">www.Ecomply.io</a></span><span
                class="c3">. </span></li>
    </ol>
    <hr>
    <p class="c1"><span class="c3"></span></p>
    <h3 class="c6" id="h.hfzxr5u1z4ix"><span class="c7">2. Direitos e obriga&ccedil;&otilde;es do Ecomply.io</span></h3>
    <ol class="c4 lst-kix_e6qtjfbzpvfl-0 start" start="1">
        <li class="c5 li-bullet-0"><span class="c3">Ecomply.io se reserva o direito de alterar o software Ecomply.io de
                forma razo&aacute;vel para o cliente a qualquer momento, por exemplo, para desenvolv&ecirc;-lo ou para
                melhorar a qualidade.</span></li>
        <li class="c5 li-bullet-0"><span class="c3">Ecomply.io se reserva o direito de modificar estes Termos e
                Condi&ccedil;&otilde;es a qualquer momento, sem aviso pr&eacute;vio ao usu&aacute;rio. Os Termos e
                Condi&ccedil;&otilde;es revisados ser&atilde;o comunicados ao cliente por e-mail pelo menos duas semanas
                antes de serem implementados. Se um cliente n&atilde;o contradizer a validade dos novos Termos e
                Condi&ccedil;&otilde;es dentro de duas semanas ap&oacute;s o recebimento do e-mail, os Termos e
                Condi&ccedil;&otilde;es alterados ser&atilde;o considerados aceitos. A Ecomply.io informar&aacute; o
                cliente sobre o per&iacute;odo de duas semanas no e-mail anunciando os Termos e Condi&ccedil;&otilde;es
                revisados.</span></li>
        <li class="c5 li-bullet-0"><span class="c3">Eventuais problemas t&eacute;cnicos e de manuten&ccedil;&atilde;o
                podem levar a uma usabilidade temporariamente limitada. A Ecomply.io sempre lidar&aacute; com quaisquer
                erros o mais r&aacute;pido poss&iacute;vel para manter seus tempos t&atilde;o curtos quanto
                poss&iacute;vel.</span></li>
        <li class="c5 li-bullet-0"><span class="c3">O Ecomply.io limita temporariamente seus servi&ccedil;os se isso for
                necess&aacute;rio em rela&ccedil;&atilde;o aos limites de capacidade, &agrave; seguran&ccedil;a ou
                integridade do servidor ou para realizar medidas t&eacute;cnicas, quando isso contribuir para a
                presta&ccedil;&atilde;o adequada ou melhorada de servi&ccedil;os usados (manuten&ccedil;&atilde;o).
                Nestes casos, a Ecomply.io considera os leg&iacute;timos interesses do cliente, por exemplo, fornecendo
                informa&ccedil;&otilde;es em tempo h&aacute;bil.</span></li>
        <li class="c5 li-bullet-0"><span class="c3">A Ecomply.io tem o direito de usar e armazenar dados de uso para
                fins de avalia&ccedil;&atilde;o, inspe&ccedil;&atilde;o, melhoria dos servi&ccedil;os, an&aacute;lise,
                marketing e publicidade, de acordo com a pol&iacute;tica de privacidade da Ecomply.io e a
                pol&iacute;tica de cookies da Ecomply.io. </span></li>
        <li class="c5 li-bullet-0"><span class="c3">O Ecomply.io pode tomar medidas se houver ind&iacute;cios concretos
                de que um cliente viole regulamentos legais, direitos de terceiros, os Termos e Condi&ccedil;&otilde;es
                do Ecomply.io ou se o Ecomply.io tiver qualquer outro interesse leg&iacute;timo, em particular para
                proteger os usu&aacute;rios contra atividades fraudulentas.</span></li>
        <li class="c5 li-bullet-0"><span class="c3">O Ecomply.io est&aacute; autorizado a utilizar as
                informa&ccedil;&otilde;es do cliente como refer&ecirc;ncia a partir do momento em que o contrato for
                assinado. Isto inclui em particular o uso do nome e logotipo do cliente, bem como quaisquer
                men&ccedil;&otilde;es na cobertura da m&iacute;dia.</span></li>
    </ol>
    <hr>
    <p class="c6 c9"><span class="c2"></span></p>
    <h3 class="c6" id="h.bb8mkjd6ldp8"><span class="c7">3. Direitos e obriga&ccedil;&otilde;es do cliente</span></h3>
    <ol class="c4 lst-kix_273ltq9wy9y6-0 start" start="1">
        <li class="c5 li-bullet-0"><span class="c8 c11">Autoriza&ccedil;&atilde;o</span></li>
    </ol>
    <ol class="c4 lst-kix_273ltq9wy9y6-1 start" start="1">
        <li class="c0 li-bullet-0"><span class="c3">Durante o processo de pedido o cliente deve especificar todos os
                dados pessoais ou relacionados &agrave; empresa de forma completa e verdadeira. Ele/ela &eacute;
                respons&aacute;vel pela exatid&atilde;o dos pr&oacute;prios dados e garante a Ecomply.io sua
                exatid&atilde;o e atualidade.</span></li>
        <li class="c0 li-bullet-0"><span class="c3">O cliente deve garantir que terceiros n&atilde;o possam ter acesso
                &agrave; sua conta ou chave de licen&ccedil;a. O cliente &eacute; o &uacute;nico respons&aacute;vel por
                qualquer tipo de abuso.</span></li>
    </ol>
    <ol class="c4 lst-kix_273ltq9wy9y6-0" start="2">
        <li class="c5 li-bullet-0"><span class="c11 c8">Licen&ccedil;a e uso</span></li>
    </ol>
    <ol class="c4 lst-kix_273ltq9wy9y6-1 start" start="1">
        <li class="c0 li-bullet-0"><span class="c3">Ap&oacute;s concluir o processo de compra, o cliente recebe da
                Ecomply.io o n&uacute;mero de chaves de licen&ccedil;a que foram especificadas pelo cliente durante o
                processo. Ecomply.io concede assim ao cliente o direito de utilizar o software encomendado durante o
                per&iacute;odo de uso especificado.</span></li>
        <li class="c0 li-bullet-0"><span class="c3">O Cliente n&atilde;o permitir&aacute; que seus Usu&aacute;rios
                forne&ccedil;am acesso ao Software ou Documenta&ccedil;&atilde;o a terceiros (exceto Usu&aacute;rios) ou
                de outra forma permitam que terceiros utilizem ou se beneficiem do Software ou
                Documenta&ccedil;&atilde;o.</span></li>
        <li class="c0 li-bullet-0"><span class="c3">O Cliente poder&aacute; divulgar documentos gerados pelo Software a
                seus clientes e autoridades de prote&ccedil;&atilde;o de dados.</span></li>
        <li class="c0 li-bullet-0"><span class="c3">O Cliente garante &agrave; Ecomply.io n&atilde;o violar nenhuma
                disposi&ccedil;&atilde;o estatut&aacute;ria e cumprir com as pol&iacute;ticas de privacidade
                aplic&aacute;veis. Em particular, ele n&atilde;o viola a propriedade, prote&ccedil;&atilde;o e uso de
                direitos de terceiros.</span></li>
        <li class="c0 li-bullet-0"><span class="c3">O cliente isenta a Ecomply.io, mediante solicita&ccedil;&atilde;o
                pr&eacute;via, de todas as reivindica&ccedil;&otilde;es contra a Ecomply.io reivindicadas por outros
                clientes, usu&aacute;rios ou terceiros por viola&ccedil;&atilde;o de seus direitos por meio de
                resultados ou dados obtidos atrav&eacute;s do software Ecomply.io. O cliente assume os custos para a
                defesa legal do Ecomply.io, incluindo todas as custas judiciais e honor&aacute;rios advocat&iacute;cios
                &agrave; taxa legal. Isto n&atilde;o se aplica se a infra&ccedil;&atilde;o do cliente n&atilde;o for
                culpa do mesmo. No caso de uma reclama&ccedil;&atilde;o de terceiros, o cliente &eacute; obrigado a
                fornecer &agrave; Ecomply.io, imediata, verdadeira e completamente, todas as informa&ccedil;&otilde;es
                necess&aacute;rias para o exame das reclama&ccedil;&otilde;es e uma defesa.</span></li>
        <li class="c0 li-bullet-0"><span class="c3">Todos os clientes ter&atilde;o direito a at&eacute; 2 horas de
                onboarding pessoal ao se tornarem clientes da Ecomply.io.</span></li>
        <li class="c0 li-bullet-0"><span class="c3">O suporte por bate-papo est&aacute; dispon&iacute;vel ilimitadamente
                para todos os clientes.</span></li>
        <li class="c0 li-bullet-0"><span class="c3">Dependendo do plano, o cliente ter&aacute; direito a um total de 2
                horas de assist&ecirc;ncia pessoal ao vivo a cada seis meses.</span></li>
        <li class="c0 li-bullet-0"><span class="c3">Reuni&otilde;es virtuais e onboardings fora dos termos previamente
                estipulados ser&atilde;o faturados a uma taxa hor&aacute;ria.</span></li>
        <li class="c0 li-bullet-0"><span class="c3">As reuni&otilde;es virtuais e os onboardings devem ser programados
                com um m&iacute;nimo de 07 dias de anteced&ecirc;ncia, e a n&atilde;o compar&ecirc;ncia sem aviso
                pr&eacute;vio resultar&aacute; no pagamento integral.</span></li>
    </ol>
    <ol class="c4 lst-kix_273ltq9wy9y6-0" start="3">
        <li class="c5 li-bullet-0"><span class="c11 c8">Seguran&ccedil;a</span></li>
    </ol>
    <ol class="c4 lst-kix_273ltq9wy9y6-1 start" start="1">
        <li class="c0 li-bullet-0"><span class="c3">O Cliente &eacute; obrigado a n&atilde;o enviar mensagens de spam e
                a n&atilde;o utilizar programas eletr&ocirc;nicos automatizados ou rob&ocirc;s, ou a utilizar seu acesso
                Ecomply.io para este fim.</span></li>
        <li class="c0 li-bullet-0"><span class="c3">O cliente concorda em n&atilde;o enviar, publicar ou disseminar
                quaisquer v&iacute;rus, malware, links maliciosos ou links para sites ilegais e conte&uacute;do
                malicioso.</span></li>
    </ol>
    <hr>
    <p class="c1"><span class="c3"></span></p>
    <h3 class="c6" id="h.g90t011cj94j"><span class="c7">4. Remunera&ccedil;&atilde;o, maturidade, atraso</span></h3>
    <ol class="c4 lst-kix_r4skt585moyl-0 start" start="1">
        <li class="c5 li-bullet-0"><span class="c3">Todos os pre&ccedil;os devem ser entendidos l&iacute;quidos. O
                respectivo ICMS tamb&eacute;m &eacute; devido.</span></li>
        <li class="c5 li-bullet-0"><span class="c3">O Ecomply.io pode alterar os pre&ccedil;os a qualquer momento. As
                altera&ccedil;&otilde;es de pre&ccedil;os ser&atilde;o notificadas aos clientes em tempo h&aacute;bil e
                por escrito antes da data efetiva.</span></li>
        <li class="c5 li-bullet-0"><span class="c3">O cliente s&oacute; tem direito a uma compensa&ccedil;&atilde;o se
                seus pedidos reconvencionais tiverem sido legalmente estabelecidos ou se estes tiverem sido reconhecidos
                pela Ecomply.io.</span></li>
        <li class="c5 li-bullet-0"><span class="c3">O cliente s&oacute; tem o direito de reten&ccedil;&atilde;o na
                medida em que sua reconven&ccedil;&atilde;o decorra da mesma rela&ccedil;&atilde;o contratual.</span>
        </li>
    </ol>
    <hr>
    <p class="c6 c9"><span class="c2"></span></p>
    <h3 class="c6" id="h.yp7y98we68az"><span class="c7">5. Garantia, responsabilidade</span></h3>
    <ol class="c4 lst-kix_fczziklbivy6-0 start" start="1">
        <li class="c5 li-bullet-0"><span class="c3">A Ecomply.io &eacute; respons&aacute;vel por inten&ccedil;&atilde;o
                ou neglig&ecirc;ncia grave, por les&atilde;o &agrave; vida, membro ou sa&uacute;de, de acordo com as
                disposi&ccedil;&otilde;es da Lei de Responsabilidade pelo Produto e, na medida de uma garantia adquirida
                pela Ecomply.io, reclama&ccedil;&otilde;es contratuais ou estatut&aacute;rias n&atilde;o ser&atilde;o
                afetadas por estas disposi&ccedil;&otilde;es de responsabilidade. </span></li>
        <li class="c5 li-bullet-0"><span class="c3">Sem preju&iacute;zo da cl&aacute;usula 5.1, a Ecomply.io ser&aacute;
                respons&aacute;vel apenas por simples neglig&ecirc;ncia por descumprimento das obriga&ccedil;&otilde;es
                contratuais, que o descumprimento das obriga&ccedil;&otilde;es facilite o cumprimento do contrato de
                licen&ccedil;a em primeiro lugar e em cujo cumprimento o cliente possa confiar regularmente
                (&quot;obriga&ccedil;&atilde;o cardinal&quot;). Em uma viola&ccedil;&atilde;o negligente de uma
                obriga&ccedil;&atilde;o cardinal, a responsabilidade da Ecomply.io tamb&eacute;m &eacute; limitada aos
                t&iacute;picos danos contratuais previs&iacute;veis.</span></li>
        <li class="c5 li-bullet-0"><span class="c3">O dano contratual previs&iacute;vel t&iacute;pico de acordo com a
                Se&ccedil;&atilde;o 5.2 Senten&ccedil;a 2 &eacute; por ano contratual um m&aacute;ximo de 100% do volume
                contratual anual (pelo cliente dentro do ano contratual no qual o dano recai sobre a
                remunera&ccedil;&atilde;o Ecomply.io a ser paga por todos os servi&ccedil;os da Ecomply.io relativos ao
                uso do servi&ccedil;o pelo cliente) ou EUR 10.000, o que for o valor mais alto aplic&aacute;vel.</span>
        </li>
        <li class="c5 li-bullet-0"><span class="c3">A limita&ccedil;&atilde;o de responsabilidade na se&ccedil;&atilde;o
                5.2 tamb&eacute;m se aplica a lucros cessantes ou economias.</span></li>
        <li class="c5 li-bullet-0"><span class="c3">Sem preju&iacute;zo da cl&aacute;usula 5.1, a responsabilidade sem
                culpa da Ecomply.io, de acordo com o &sect; 536a BGB, baseada em defeitos j&aacute; presentes no momento
                da celebra&ccedil;&atilde;o do contrato, est&aacute; expressamente exclu&iacute;da.</span></li>
        <li class="c5 li-bullet-0"><span class="c3">As limita&ccedil;&otilde;es de responsabilidade acima tamb&eacute;m
                se aplicam em caso de culpa de um agente da Ecomply.io e a responsabilidade pessoal dos
                funcion&aacute;rios, representantes e &oacute;rg&atilde;os da Ecomply.io.</span></li>
        <li class="c5 li-bullet-0"><span class="c3">A limita&ccedil;&atilde;o de pedidos de indeniza&ccedil;&atilde;o
                pelo cliente em raz&atilde;o da responsabilidade especificada no par&aacute;grafo 5.1 &eacute; calculada
                de acordo com as disposi&ccedil;&otilde;es legais. O prazo de prescri&ccedil;&atilde;o com
                rela&ccedil;&atilde;o a outras reivindica&ccedil;&otilde;es por danos do cliente &eacute; de um ano. Ela
                come&ccedil;a no final do ano em que a reclama&ccedil;&atilde;o surgiu e o cliente tomou conhecimento de
                circunst&acirc;ncias de reclama&ccedil;&atilde;o justificadas e o devedor estava ciente ou n&atilde;o
                obteve como resultado neglig&ecirc;ncia grosseira, mas n&atilde;o mais tarde do que cinco anos
                ap&oacute;s sua origem e dez anos de ter cometido o ato, viola&ccedil;&atilde;o do dever ou outra causa,
                causando o dano.</span></li>
        <li class="c5 li-bullet-0"><span class="c3">Ecomply.io se esfor&ccedil;a para fornecer a mais alta
                disponibilidade poss&iacute;vel do software, n&atilde;o &eacute; respons&aacute;vel pela disponibilidade
                e pelo endere&ccedil;o sem falhas e gera&ccedil;&atilde;o de dados de contato e n&atilde;o &eacute;
                expressamente respons&aacute;vel pela legalidade e disponibilidade dos dados gerados.</span></li>
        <li class="c5 li-bullet-0"><span class="c3">O cliente &eacute; respons&aacute;vel por todas as
                consequ&ecirc;ncias e desvantagens que surjam do uso impr&oacute;prio ou ilegal dos Servi&ccedil;os do
                Ecomply.io ou pelo fato do cliente n&atilde;o cumprir suas outras obriga&ccedil;&otilde;es sob estes
                Termos e Condi&ccedil;&otilde;es.</span></li>
    </ol>
    <hr>
    <p class="c1"><span class="c3"></span></p>
    <h3 class="c6" id="h.pzvaiq8q7boj"><span class="c7">6. Dura&ccedil;&atilde;o do contrato, rescis&atilde;o do
            contrato</span></h3>
    <ol class="c4 lst-kix_7a1f1loavfm8-0 start" start="1">
        <li class="c5 li-bullet-0"><span class="c3">O termo contratual do uso &eacute; especificado no pedido.</span>
        </li>
        <li class="c5 li-bullet-0"><span class="c3">Se o contrato n&atilde;o for rescindido pelo menos tr&ecirc;s meses
                antes do final do prazo contratual pelo cliente, ele &eacute; automaticamente renovado pelo mesmo
                per&iacute;odo nos mesmos termos.</span></li>
        <li class="c5 li-bullet-0"><span class="c3">Ecomply.io pode cancelar o contrato a qualquer momento com um
                per&iacute;odo de tr&ecirc;s meses at&eacute; o final do m&ecirc;s.</span></li>
        <li class="c5 li-bullet-0"><span class="c3">Uma notifica&ccedil;&atilde;o deve ser feita por escrito.</span>
        </li>
    </ol>
    <hr>
    <p class="c6 c9"><span class="c3"></span></p>
    <h3 class="c6" id="h.9hlhrmljc4a7"><span class="c7">7. Prote&ccedil;&atilde;o de dados</span></h3>
    <ol class="c4 lst-kix_mh7larxc34v5-0 start" start="1">
        <li class="c5 li-bullet-0"><span class="c3">A Ecomply.io coleta, armazena e processa dados empresariais e
                pessoais (dados de invent&aacute;rio) e outros dados adquiridos em rela&ccedil;&atilde;o &agrave;
                rela&ccedil;&atilde;o contratual e decorrentes do uso pela Ecomply.io, para fins de gerenciamento de
                contratos, faturamento, bem como a implementa&ccedil;&atilde;o dos servi&ccedil;os reclamados pelo
                cliente, em conformidade com as normas legais alem&atilde;s de prote&ccedil;&atilde;o de dados.</span>
        </li>
        <li class="c5 li-bullet-0"><span class="c3">Ao celebrar o contrato, o cliente concorda explicitamente que a
                Ecomply.io utiliza os dados do cliente, padr&otilde;es de uso e suas an&aacute;lises para melhorar seus
                servi&ccedil;os e garantir a finalidade comercial de seu uso. Estes dados podem ser tornados
                an&ocirc;nimos para qualquer terceiro sob as diretrizes de prote&ccedil;&atilde;o de dados alem&atilde;s
                (BDSG), brasileiras (LGPD) e europeias (GDPR) aplic&aacute;veis e ser&atilde;o usados anonimamente para
                comunica&ccedil;&atilde;o ao exterior.</span></li>
    </ol>
    <hr>
    <p class="c1"><span class="c3"></span></p>
    <h3 class="c6" id="h.lxq2nfwdj4au"><span class="c7">8. Cl&aacute;usulas finais</span></h3>
    <ol class="c4 lst-kix_amhh9wwmrxzs-0 start" start="1">
        <li class="c5 li-bullet-0"><span class="c3">Aplica-se a lei da Rep&uacute;blica Federal da Alemanha, excluindo a
                Conven&ccedil;&atilde;o de Vendas da ONU (CISG).</span></li>
        <li class="c5 li-bullet-0"><span class="c3">O local de atua&ccedil;&atilde;o &eacute; a sede da Ecomply.io GmbH.
                O local exclusivo de jurisdi&ccedil;&atilde;o para todas as disputas decorrentes do contrato &eacute; a
                sede da Ecomply.io GmbH se o cliente for um comerciante na acep&ccedil;&atilde;o do C&oacute;digo
                Comercial Alem&atilde;o ou quando o cliente n&atilde;o tiver uma sede ou domic&iacute;lio habitual na
                Rep&uacute;blica Federal da Alemanha.</span></li>
    </ol>
</body>

</html>
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'starter-pa',
  templateUrl: './starter-pa.component.html',
  styleUrls: ['./starter-pa.component.scss']
})
export class StarterPaComponent implements OnInit {

  @Input() actionStyle: 'flat' | 'stroked' | 'none' = 'flat';
  @Input() pa: any;
  @Input() action: string;
  @Input() toggleAction: string | undefined = undefined;
  @Output() picked = new EventEmitter<any>();
  @Output() unpicked = new EventEmitter<any>();

  expanded = false;
  toggled = false;

  constructor() { }

  ngOnInit(): void {
  }

  pick() {
    this.picked.emit(this.pa);
    this.toggled = true;
  }

  unpick() {
    this.unpicked.emit(this.pa);
    this.toggled = false;
  }
}

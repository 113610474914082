import { VendorAudit } from '../../../services/vendors/vendor-audit.service';

export function auditRiskClass(vendorAudit: VendorAudit, field: string) {
  if (vendorAudit) {
    if (vendorAudit.answers?.[field]) {
      return 'audit-risk-' + vendorAudit.answers?.[field];
    }
  }
  return 'unknown';
}

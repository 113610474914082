<div class="boundary" style="width: {{size}}px; height: {{size}}px">
  <div class="text-holder" style="line-height: {{size}}px; font-size: {{fontSize}}px;" *ngIf="progress < 100">{{ progress | percent }}</div>
  <div class="text-holder" style="line-height: {{size}}px; font-size: {{fontSize}}px;" *ngIf="progress==100">100%</div>
  <div class="progress-holder back">
    <mat-progress-spinner
      mode="determinate"
      strokeWidth="1"
      [diameter]="size - 12"
      color="accent"
      [value]="100">
    </mat-progress-spinner>
  </div>
  <div class="progress-holder front">
    <mat-progress-spinner
      mode="determinate"
      [strokeWidth]="size / 7"
      [diameter]="size"
      color="accent"
      [value]="progress * 100">
    </mat-progress-spinner>
  </div>
</div>

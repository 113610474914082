<div class="contents" [class.archived]="isArchived">
  <div *ngIf="isArchived" class="pa-status-container pinned">
    <div class="pa-status-indicator">
      <span>{{ 'rpas.workflow.status.title' | translate  }}:</span>
      <div class="status">
        <h4 class="archived" *ngIf="isArchived">
          {{ 'rpas.workflows.status.archived' | translate | uppercase }}
        </h4>
      </div>
    </div>
  </div>

  <h1 class="title">
    {{(formGroup.get('processingOnBehalf').value)
      ? ('pa.details.form.title.onBehalf' | translate)
      : ('pa.details.form.title' | translate) }}: <br />
    {{paName}}
    <!--
    <a *ngIf="paOwner" routerLink="/rpa/custom-pa" [queryParams]="{id: paId}">
      <mat-icon color="primary">edit</mat-icon>
    </a>
    -->
    <mat-icon *ngIf="canWrite" color="primary" (click)="editName()" style="cursor: pointer">edit</mat-icon>
  </h1>
  <br>
  <div *ngIf="pa" class="processing-dates">
    <div class="date-item start">
      <span>{{ 'pa.details.form.startDate' | translate }}:</span>
      <span>&nbsp;</span>
      <span class="date" *ngIf="pa.startOfProcessing">{{ pa.startOfProcessing | localizedDate: 'shortDate'}}</span>
      <span *ngIf="!pa.startOfProcessing">---</span>
      <button [disabled]="!canWrite" mat-icon-button (click)="editStartDate()" color="primary">
        <mat-icon>edit</mat-icon>
      </button>
    </div>

    <div *ngIf="isArchived" class="date-item end">
      <span>{{ 'pa.details.form.endDate' | translate }}:</span>
      <span>&nbsp;</span>
      <span class="date" *ngIf="pa.endOfProcessing">{{ pa.endOfProcessing | localizedDate: 'shortDate'}}</span>
      <span *ngIf="!pa.endOfProcessing">---</span>
      <button [disabled]="!canWrite" mat-icon-button (click)="editEndDate()" color="primary">
        <mat-icon>edit</mat-icon>
      </button>
    </div>
  </div>
  <br><br>
  <div class="download-icons">
    <ng-container *ngIf="!formGroup.get('processingOnBehalf').value">
      <display-jurisdiction jurisdiction="gdpr,lgpd">
        <button mat-icon-button color="primary" matTooltip="{{ 'pa.details.export' | translate }}" [matMenuTriggerFor]="downloadMenu"><mat-icon>cloud_download</mat-icon></button>
        <feature-access-local *ngIf="isSupervisor" featureName="shared-documents">
          <button mat-icon-button color="primary" matTooltip="{{ 'documents.share' | translate }}" [matMenuTriggerFor]="shareMenu"><mat-icon>forward_to_inbox</mat-icon></button>
        </feature-access-local>

        <mat-menu #downloadMenu="matMenu" [overlapTrigger]="false">
          <button mat-menu-item color="primary" (click)="downloadPdf()">
            {{ 'documents.download.pdf' | translate }}
          </button>
          <!-- {{ 'documents.generated.paDetails' | translate }} -->
          <button mat-menu-item color="primary" (click)="downloadWord('documents.generated.paDetails')">
            {{ 'documents.download.docx' | translate }}
          </button>
          <display-language language="de">
            <!-- {{ 'documents.generated.paArt13Info' | translate }}-->
            <button mat-menu-item color="primary" (click)="downloadWord('documents.generated.paArt13Info')">
              {{ 'documents.download.art13InfoPa' | translate }}
            </button>
          </display-language>
        </mat-menu>
        <mat-menu #shareMenu="matMenu" [overlapTrigger]="false">
          <button mat-menu-item color="primary" (click)="sharePdf($event)">
            {{ 'documents.share.pdf' | translate }}
          </button>
          <!-- {{ 'documents.generated.paDetails' | translate }} -->
          <button mat-menu-item color="primary" (click)="shareWord($event, 'documents.generated.paDetails')">
            {{ 'documents.share.docx' | translate }}
          </button>
          <display-language language="de">
            <!-- {{ 'documents.generated.paArt13Info' | translate }}-->
            <button mat-menu-item color="primary" (click)="shareWord($event, 'documents.generated.paArt13Info')">
              {{ 'documents.share.art13InfoPa' | translate }}
            </button>
          </display-language>
        </mat-menu>
      </display-jurisdiction>
      <br /><br />
    </ng-container>
  </div>

  <br><br>
  <mat-divider></mat-divider>
  <h2>
    {{ 'pa.details.sections.basics.title' | translate }}
  </h2>
  <mat-divider></mat-divider>
  <br><br>

  <p *ngIf="accessLevel && !accessLevel.write" class="warning">
    <span>{{'word.NOTICE' | translate}}</span>
    {{'word.readonlyAccess' | translate}}
  </p>

  <form name="form" [formGroup]="formGroup" novalidate>

    <!---=========== DEPARTMENTS ===========-->
    <div commentable comment-subject-codename="pa-{{paId}}-responsible-department"
        comment-subject-title="{{'pa.assignedDepartment.commentSubject.part1' | translate}} {{paName}} {{'pa.assignedDepartment.commentSubject.part2' | translate}}">
      <todoable subject="pa-{{paId}}-responsible-department" suggested-title="VT-{{paId}}: "></todoable>
      <mat-form-field>
        <mat-select placeholder="{{'pa.assignedDepartment.description' | translate}}"
          formControlName="assignedDepartment"
          (selectionChange)="submit('assignedDepartment')" [disabled]="!canWrite">

          <mat-option *ngFor="let dep of departments" [value]="dep.id">
            {{dep.name}} <ng-container *ngIf="dep.headFirstName || dep.headLastName">({{dep.headFirstName}} {{dep.headLastName}})</ng-container>
          </mat-option>

          <mat-option (click)="addDepartment()" [value]="null">
            {{ 'pa.createDepartment' | translate}}
          </mat-option>
        </mat-select>
        <mat-hint [ngClass]="{'hidden': fieldsBeingSaved['assignedDepartment']}">
          {{'pa.assignedDepartment.tip' | translate}} <a *ngIf="!addResponsiblePerson && !pa.responsiblePerson" (click)="canWrite ? showAddResponsiblePerson() : {}">{{ 'pa.assignedDepartment.addResponsiblePerson' | translate }}</a>
        </mat-hint>
        <mat-hint [ngClass]="{'active': fieldsBeingSaved['assignedDepartment']}" align="end" class="saving">
          {{'basicInfo.fields.saving' | translate}}
        </mat-hint>
      </mat-form-field>
    </div>

    <div style="margin-top: -60px" *ngIf="addResponsiblePerson || pa.responsiblePerson" commentable comment-subject-codename="pa-{{paId}}-responsible-person"
      comment-subject-title="{{'pa.responsiblePerson.commentSubject.part1' | translate}} {{paName}} {{'pa.responsiblePerson.commentSubject.part2' | translate}}">
      <todoable subject="pa-{{paId}}-responsible-person" suggested-title="VT-{{paId}}: "></todoable>
      <mat-form-field>
        <textarea matTextareaAutosize matInput rows="3" placeholder="{{'pa.responsiblePerson.description' | translate}}" formControlName="responsiblePerson"
          [readonly]="!canWrite" (change)="submit('responsiblePerson')" [value]="pa.responsiblePerson"></textarea>
        <mat-hint [ngClass]="{'hidden': fieldsBeingSaved['responsiblePerson']}" innerHTML="{{'pa.responsiblePerson.tip' | translate}}"></mat-hint>
        <mat-hint [ngClass]="{'active': fieldsBeingSaved['responsiblePerson']}" align="end" class="saving">
          {{'basicInfo.fields.saving' | translate}}
        </mat-hint>
      </mat-form-field>
    </div>

    <!-- for backward/legacy compatibility, this section has an old comment subject codename. -->
    <div commentable comment-subject-codename="pa-{{paId}}-processed-internally"
        comment-subject-title="{{'pa.processedInternally.commentSubject.part1' | translate}} {{paName}} {{'pa.processedInternally.commentSubject.part2' | translate}}">
        <todoable subject="pa-{{paId}}-processed-internally" suggested-title="VT-{{paId}}: "></todoable>
      <div class="departments">
        <p class="hint">
          {{ 'pa.internalProcessors.tip' | translate }}
        </p>
        <br>
        <ng-container *ngFor="let department of nonResponsibleDepartments">
          <button *ngIf="isAssociated(department)" [disabled]="!canWrite" class="department" mat-flat-button color="accent"
            (click)="disassociate(department)">
            {{department.name}}
          </button>

          <button *ngIf="!isAssociated(department)" [disabled]="!canWrite" class="department" mat-stroked-button color="accent"
            (click)="associate(department)">
            {{department.name}}
          </button>
        </ng-container>
        <ng-container *ngIf="nonResponsibleDepartments.length === 0 && canWrite">
          <button mat-button class="add-vendor" routerLink="/organisation/departments">
            <mat-icon color="primary">add_circle_outline</mat-icon>
            <span>
              {{'pa.departments.setUpNew' | translate}}
            </span>
          </button>
        </ng-container>
      </div>

     </div>

   <br><br>

    <div commentable comment-subject-codename="pa-{{paId}}-description"
      comment-subject-title="{{'pa.description.commentSubject.part1' | translate}} {{paName}} {{'pa.description.commentSubject.part2' | translate}}">
      <todoable subject="pa-{{paId}}-description" suggested-title="VT-{{paId}}: "></todoable>
      <mat-form-field>
        <textarea matTextareaAutosize matInput rows="3" placeholder="{{'pa.description.description' | translate}}"
          [readonly]="!canWrite" (change)="submit('paDescription')" formControlName="paDescription"></textarea>
        <mat-hint [ngClass]="{'hidden': fieldsBeingSaved['paDescription']}"
          innerHTML="{{'pa.description.tip' | translate}}"></mat-hint>
        <mat-hint [ngClass]="{'active': fieldsBeingSaved['paDescription']}" align="end" class="saving">
          {{'basicInfo.fields.saving' | translate}}
        </mat-hint>
      </mat-form-field>
    </div>

    <div *ngIf="formGroup.get('processingOnBehalf').value || formGroup.get('jointController').value">
      <mat-form-field>
        <textarea matTextareaAutosize matInput rows="3" formControlName="additionalControllerInfo"
          (change)="submit('additionalControllerInfo')" [readonly]="!canWrite"
          placeholder="{{ 'pa.processingOnBehalf.additionalInfo' | translate }}"></textarea>
        <mat-hint [ngClass]="{'hidden': fieldsBeingSaved['additionalControllerInfo']}">
          {{ 'pa.processingOnBehalf.additionalInfo.hint' | translate }}
        </mat-hint>
        <mat-hint [ngClass]="{'active': fieldsBeingSaved['additionalControllerInfo']}" align="end" class="saving">
          {{'basicInfo.fields.saving' | translate}}
        </mat-hint>
      </mat-form-field>
    </div>

    <display-jurisdiction jurisdiction="lgpd">
      <div commentable comment-subject-codename="pa-{{paId}}-lgpd-principles"
        comment-subject-title="{{'pa.lgpdPrinciples.commentSubject.part1' | translate}} {{paName}} {{'pa.lgpdPrinciples.commentSubject.part2' | translate}}">
        <todoable subject="pa-{{paId}}-lgpd-principles" suggested-title="VT-{{paId}}: "></todoable>
        <div class="with-button">
          <mat-form-field>

            <mat-select #lgpdPrinciples multiple placeholder="{{'pa.lgpdPrinciples.description' | translate}}" multiple="true" [disabled]="!canWrite"
                (selectionChange)="submit('paLgpdPrinciples')" formControlName="paLgpdPrinciples" class="lgpd-principles">
                <mat-option value="pa.lgpdPrinciples.purpose">{{'pa.lgpdPrinciples.purpose' | translate}}</mat-option>
                <mat-option value="pa.lgpdPrinciples.adequacy">{{'pa.lgpdPrinciples.adequacy' | translate}}</mat-option>
                <mat-option value="pa.lgpdPrinciples.necessity">{{'pa.lgpdPrinciples.necessity' | translate}}</mat-option>
                <mat-option value="pa.lgpdPrinciples.freeAccess">{{'pa.lgpdPrinciples.freeAccess' | translate}}</mat-option>
                <mat-option value="pa.lgpdPrinciples.quality">{{'pa.lgpdPrinciples.quality' | translate}}</mat-option>
                <mat-option value="pa.lgpdPrinciples.transparency">{{'pa.lgpdPrinciples.transparency' | translate}}</mat-option>
                <mat-option value="pa.lgpdPrinciples.security">{{'pa.lgpdPrinciples.security' | translate}}</mat-option>
                <mat-option value="pa.lgpdPrinciples.prevention">{{'pa.lgpdPrinciples.prevention' | translate}}</mat-option>
                <mat-option value="pa.lgpdPrinciples.nonDiscrimination">{{'pa.lgpdPrinciples.nonDiscrimination' | translate}}</mat-option>
                <mat-option value="pa.lgpdPrinciples.accountability">{{'pa.lgpdPrinciples.accountability' | translate}}</mat-option>
            </mat-select>
            <mat-hint [ngClass]="{'hidden': fieldsBeingSaved['lgpdPrinciples']}"
              innerHTML="{{'pa.lgpdPrinciples.tip' | translate}}"></mat-hint>
            <mat-hint [ngClass]="{'active': fieldsBeingSaved['lgpdPrinciples']}" align="end" class="saving">
              {{'basicInfo.fields.saving' | translate}}
            </mat-hint>
          </mat-form-field>
          <button matTooltip="{{ 'pa.lgpdPrinciples.selectAll' | translate }}" mat-icon-button (click)="selectAllPrinciples()"><mat-icon>done_all</mat-icon></button>
        </div>
      </div>
  </display-jurisdiction>

    <div commentable comment-subject-codename="pa-{{paId}}-purpose"
      comment-subject-title="{{'pa.purpose.commentSubject.part1' | translate}} {{paName}} {{'pa.purpose.commentSubject.part2' | translate}}">
      <todoable subject="pa-{{paId}}-purpose" suggested-title="VT-{{paId}}: "></todoable>
      <mat-form-field>
        <textarea matTextareaAutosize matInput rows="3" placeholder="{{'pa.purpose.description' | translate}}"
          [readonly]="!canWrite" (change)="submit('paPurpose')" formControlName="paPurpose"></textarea>
        <mat-hint [ngClass]="{'hidden': fieldsBeingSaved['paPurpose']}" innerHTML="{{'pa.purpose.tip' | translate}}">
        </mat-hint>
        <mat-hint [ngClass]="{'active': fieldsBeingSaved['paPurpose']}" align="end" class="saving">
          {{'basicInfo.fields.saving' | translate}}
        </mat-hint>
      </mat-form-field>
    </div>

    <div *ngIf="!formGroup.get('processingOnBehalf').value"> <!-- Processing on behalf -->

      <display-jurisdiction jurisdiction="gdpr">
        <div commentable comment-subject-codename="pa-{{paId}}-legalbasis"
        comment-subject-title="{{'pa.legalbasis.commentSubject.part1' | translate}} {{paName}} {{'pa.legalbasis.commentSubject.part2' | translate}}">
          <todoable subject="pa-{{paId}}-legalbasis" suggested-title="VT-{{paId}}: "></todoable>
          <mat-form-field>
            <mat-select placeholder="{{'pa.legalbasis.description' | translate}}" multiple="true" [disabled]="!canWrite"
              (selectionChange)="submit('paLegalBasis')" formControlName="paLegalBasis">
                <mat-option value="pa.legalbasis.consent">{{'pa.legalbasis.consent' | translate}}</mat-option>
                <mat-option value="pa.legalbasis.contractualNecessity">{{'pa.legalbasis.contractualNecessity' | translate}}
                </mat-option>
                <mat-option value="pa.legalbasis.complianceWithOther">{{'pa.legalbasis.complianceWithOther' | translate}}
                </mat-option>
                <mat-option value="pa.legalbasis.vitalInterest">{{'pa.legalbasis.vitalInterest' | translate}}</mat-option>
                <mat-option value="pa.legalbasis.publicInterest">{{'pa.legalbasis.publicInterest' | translate}}</mat-option>
                <mat-option value="pa.legalbasis.legitimateInterest">{{'pa.legalbasis.legitimateInterest' | translate}}
                </mat-option>
                <mat-option *ngIf="pa?.paLegalBasis?.includes('pa.legalbasis.members')"value="pa.legalbasis.members">{{'pa.legalbasis.members' | translate}}</mat-option><!--Legacy support. Only display if already set so it can be UNSET.-->
                <mat-option *ngIf="pa?.paLegalBasis?.includes('pa.legalbasis.law')" value="pa.legalbasis.law">{{'pa.legalbasis.law' | translate}}</mat-option><!--Legacy support. Only display if already set so it can be UNSET.-->
                <mat-option value="pa.legalbasis.26bdsg">{{'pa.legalbasis.26bdsg' | translate}}</mat-option>

                <display-language language="de">
                  <mat-option value="pa.legalbasis.contract26bdsg">{{'pa.legalbasis.contract26bdsg' | translate}}</mat-option>
                </display-language>

                <mat-option value="pa.legalbasis.other">{{'pa.legalbasis.other' | translate}}</mat-option>
            </mat-select>
            <mat-hint [ngClass]="{'hidden': fieldsBeingSaved['paLegalBasis']}"
              innerHTML="{{'pa.legalbasis.tip' | translate}}"></mat-hint>
            <mat-hint [ngClass]="{'active': fieldsBeingSaved['paLegalBasis']}" align="end" class="saving">
              {{'basicInfo.fields.saving' | translate}}
            </mat-hint>
          </mat-form-field>
        </div>
      </display-jurisdiction>

      <display-jurisdiction jurisdiction="ndpr">
        <div commentable comment-subject-codename="pa-{{paId}}-legalbasis"
        comment-subject-title="{{'pa.legalbasis.commentSubject.part1' | translate}} {{paName}} {{'pa.legalbasis.commentSubject.part2' | translate}}">
          <todoable subject="pa-{{paId}}-legalbasis" suggested-title="VT-{{paId}}: "></todoable>
          <mat-form-field>
            <mat-select placeholder="{{'pa.legalbasis.description' | translate}}" multiple="true" [disabled]="!canWrite"
              (selectionChange)="submit('paLegalBasis')" formControlName="paLegalBasis">
                <mat-option value="pa.legalbasis.consent">{{'pa.legalbasis.consent' | translate}}</mat-option>
                <mat-option value="pa.legalbasis.contractualNecessity">{{'pa.legalbasis.contractualNecessity' | translate}}
                </mat-option>
                <mat-option value="pa.legalbasis.complianceWithOther">{{'pa.legalbasis.complianceWithOther' | translate}}
                </mat-option>
                <mat-option value="pa.legalbasis.vitalInterest">{{'pa.legalbasis.vitalInterest' | translate}}</mat-option>
                <mat-option value="pa.legalbasis.publicInterest">{{'pa.legalbasis.publicInterest' | translate}}</mat-option>
                <mat-option value="pa.legalbasis.legitimateInterest">{{'pa.legalbasis.legitimateInterest' | translate}}
                </mat-option>
                <mat-option value="pa.legalbasis.other">{{'pa.legalbasis.other' | translate}}</mat-option>
            </mat-select>
            <mat-hint [ngClass]="{'hidden': fieldsBeingSaved['paLegalBasis']}"
              innerHTML="{{'pa.legalbasis.tip' | translate}}"></mat-hint>
            <mat-hint [ngClass]="{'active': fieldsBeingSaved['paLegalBasis']}" align="end" class="saving">
              {{'basicInfo.fields.saving' | translate}}
            </mat-hint>
          </mat-form-field>
        </div>
      </display-jurisdiction>

      <display-jurisdiction jurisdiction="ndpr">
        <div commentable comment-subject-codename="pa-{{paId}}-legalbasis"
        comment-subject-title="{{'pa.legalbasis.commentSubject.part1' | translate}} {{paName}} {{'pa.legalbasis.commentSubject.part2' | translate}}">
          <mat-form-field>
            <mat-select placeholder="{{'pa.legalbasis.description' | translate}}" multiple="true" [disabled]="!canWrite"
              (selectionChange)="submit('paLegalBasis')" formControlName="paLegalBasis">
                <mat-option value="pa.legalbasis.consent">{{'pa.legalbasis.consent' | translate}}</mat-option>
                <mat-option value="pa.legalbasis.contractualNecessity">{{'pa.legalbasis.contractualNecessity' | translate}}
                </mat-option>
                <mat-option value="pa.legalbasis.complianceWithOther">{{'pa.legalbasis.complianceWithOther' | translate}}
                </mat-option>
                <mat-option value="pa.legalbasis.vitalInterest">{{'pa.legalbasis.vitalInterest' | translate}}</mat-option>
                <mat-option value="pa.legalbasis.publicInterest">{{'pa.legalbasis.publicInterest' | translate}}</mat-option>
                <mat-option value="pa.legalbasis.legitimateInterest">{{'pa.legalbasis.legitimateInterest' | translate}}
                </mat-option>
            </mat-select>
            <mat-hint [ngClass]="{'hidden': fieldsBeingSaved['paLegalBasis']}"
              innerHTML="{{'pa.legalbasis.tip' | translate}}"></mat-hint>
            <mat-hint [ngClass]="{'active': fieldsBeingSaved['paLegalBasis']}" align="end" class="saving">
              {{'basicInfo.fields.saving' | translate}}
            </mat-hint>
          </mat-form-field>
        </div>
      </display-jurisdiction>

      <display-jurisdiction jurisdiction="lgpd">
        <div class="with-button">
          <div commentable comment-subject-codename="pa-{{paId}}-legalbasis"
          comment-subject-title="{{'pa.legalbasis.commentSubject.part1' | translate}} {{paName}} {{'pa.legalbasis.commentSubject.part2' | translate}}">
            <todoable subject="pa-{{paId}}-legalbasis-art11" suggested-title="VT-{{paId}}: "></todoable>
            <mat-form-field>
              <mat-select placeholder="{{'pa.legalbasis.lgpdArt7.title' | translate}}" multiple="true" [disabled]="!canWrite"
                (selectionChange)="submit('paLegalBasis')" formControlName="paLegalBasis">
                <mat-option value="pa.legalbasis.consent" title="{{'pa.legalbasis.consent' | translate}}">{{'pa.legalbasis.consent' | translate}}</mat-option>
                <mat-option value="pa.legalbasis.complianceWithOther" title="{{'pa.legalbasis.complianceWithOther' | translate}}">{{'pa.legalbasis.complianceWithOther' | translate}}</mat-option>
                <mat-option value="pa.legalbasis.lgpdAdministrationPolicies" title="{{'pa.legalbasis.lgpdAdministrationPolicies' | translate}}">{{'pa.legalbasis.lgpdAdministrationPolicies' | translate}}</mat-option>
                <mat-option value="pa.legalbasis.lgpdStudies" title="{{'pa.legalbasis.lgpdStudies' | translate}}">{{'pa.legalbasis.lgpdStudies' | translate}}</mat-option>
                <mat-option value="pa.legalbasis.contractualNecessity" title="{{'pa.legalbasis.contractualNecessity' | translate}}">{{'pa.legalbasis.contractualNecessity' | translate}}</mat-option>
                <mat-option value="pa.legalbasis.vitalInterest" title="{{'pa.legalbasis.vitalInterest' | translate}}">{{'pa.legalbasis.vitalInterest' | translate}}</mat-option>
                <mat-option value="pa.legalbasis.lgpdVitalThirdParty" title="{{'pa.legalbasis.lgpdVitalThirdParty' | translate}}">{{'pa.legalbasis.lgpdVitalThirdParty' | translate}}</mat-option>
                <mat-option value="pa.legalbasis.lgpdHealthSupervision" title="{{'pa.legalbasis.lgpdHealthSupervision' | translate}}">{{'pa.legalbasis.lgpdHealthSupervision' | translate}}</mat-option>
                <mat-option value="pa.legalbasis.legitimateInterest" title="{{'pa.legalbasis.legitimateInterest' | translate}}">{{'pa.legalbasis.legitimateInterest' | translate}}</mat-option>
                <mat-option value="pa.legalbasis.brCredit" title="{{'pa.legalbasis.brCredit' | translate}}">{{'pa.legalbasis.brCredit' | translate}}</mat-option>
                <mat-option value="pa.legalbasis.lgpdSensitiveData" title="{{'pa.legalbasis.lgpdSensitiveData' | translate}}">{{'pa.legalbasis.lgpdSensitiveData' | translate}}</mat-option>
                <mat-option value="pa.legalbasis.lgpdSensitiveDataIndispensable" title="{{'pa.legalbasis.lgpdSensitiveDataIndispensable' | translate}}">{{'pa.legalbasis.lgpdSensitiveDataIndispensable' | translate}}</mat-option>
                <mat-option value="pa.legalbasis.lgpdChildren" title="{{'pa.legalbasis.lgpdChildren' | translate}}">{{'pa.legalbasis.lgpdChildren' | translate}}</mat-option>
                <mat-option value="pa.legalbasis.lgpdArt4NotApplies" title="{{'pa.legalbasis.lgpdArt4NotApplies' | translate}}">{{'pa.legalbasis.lgpdArt4NotApplies' | translate}}</mat-option>
                <mat-option value="pa.legalbasis.other">{{'pa.legalbasis.other' | translate}}</mat-option>
              </mat-select>
              <mat-hint [ngClass]="{'hidden': fieldsBeingSaved['paLegalBasis']}"
                innerHTML="{{'pa.legalbasis.tip' | translate}}"></mat-hint>
              <mat-hint [ngClass]="{'active': fieldsBeingSaved['paLegalBasis']}" align="end" class="saving">
                {{'basicInfo.fields.saving' | translate}}
              </mat-hint>
            </mat-form-field>
          </div>
          <!-- {{ 'pa.legalbasis.lgpdexplanation' | translate }} -->
          <button mat-icon-button [appHelpPopup]="'pa.legalbasis.lgpdexplanation'">
            <mat-icon>help</mat-icon>
          </button>
        </div>
      </display-jurisdiction>

      <div *ngIf="hasLegitimateInterest">
        <div commentable
        comment-subject-codename="pa-{{paId}}-legalbasis-legitimateInterestJustification"
        comment-subject-title="{{'pa.legalbasis.commentSubject.part1' | translate}} {{paName}} {{'pa.legalbasis.commentSubject.part2' | translate}}: {{ 'pa.legalbasis.legitimateInterest' | translate }}">
          <mat-form-field>
            <textarea matTextareaAutosize matInput rows="3"
            placeholder="{{'pa.legitimateInterestAssessment.description' | translate}}" [readonly]="!canWrite"
            (change)="submit('paLegitimateInterestJustification')"
            formControlName="paLegitimateInterestJustification"></textarea>
            <mat-hint [ngClass]="{'hidden': fieldsBeingSaved['paLegitimateInterestJustification']}">
              {{'pa.legitimateInterestAssessment.tip' | translate}} <a style="cursor: pointer;" (click)="legitimateInterestAssessment()">{{'pa.legitimateInterestAssessment.tip2' | translate}}</a>.
            </mat-hint>
            <mat-hint [ngClass]="{'active': fieldsBeingSaved['paLegitimateInterestJustification']}" align="end"
            class="saving">
            {{'basicInfo.fields.saving' | translate}}
            </mat-hint>
          </mat-form-field>
        </div>
      </div>

      <display-jurisdiction jurisdiction="gdpr">
        <div commentable comment-subject-codename="pa-{{paId}}-legalbasis-art-9"
        comment-subject-title="{{'pa.legalbasis.commentSubject.part1' | translate}} {{paName}} {{'pa.legalbasis.commentSubject.part2' | translate}}">
          <todoable subject="pa-{{paId}}-legalbasis-art-9" suggested-title="VT-{{paId}}: "></todoable>
          <mat-form-field>
            <mat-select placeholder="{{ 'pa.legalBasis.gdpr.art9.description' | translate }}" multiple="true" [disabled]="!canWrite"
                        (selectionChange)="submit('paLegalBasisGdprArt9')" formControlName="paLegalBasisGdprArt9">
              <mat-option value="pa.legalBasis.gdpr.art9.explicitContent">{{ 'pa.legalBasis.gdpr.art9.explicitContent' | translate }}</mat-option>
              <mat-option value="pa.legalBasis.gdpr.art9.employmentSocial">{{ 'pa.legalBasis.gdpr.art9.employmentSocial' | translate }}</mat-option>
              <mat-option value="pa.legalBasis.gdpr.art9.vitalInterests">{{ 'pa.legalBasis.gdpr.art9.vitalInterests' | translate }}</mat-option>
              <mat-option value="pa.legalBasis.gdpr.art9.notForProfit">{{ 'pa.legalBasis.gdpr.art9.notForProfit' | translate }}</mat-option>
              <mat-option value="pa.legalBasis.gdpr.art9.madePublic">{{ 'pa.legalBasis.gdpr.art9.madePublic' | translate }}</mat-option>
              <mat-option value="pa.legalBasis.gdpr.art9.legalClaims">{{ 'pa.legalBasis.gdpr.art9.legalClaims' | translate }}</mat-option>
              <mat-option value="pa.legalBasis.gdpr.art9.publicInterest">{{ 'pa.legalBasis.gdpr.art9.publicInterest' | translate }}</mat-option>
              <mat-option value="pa.legalBasis.gdpr.art9.healthOrSocialCare">{{ 'pa.legalBasis.gdpr.art9.healthOrSocialCare' | translate }}</mat-option>
              <mat-option value="pa.legalBasis.gdpr.art9.publicHealth">{{ 'pa.legalBasis.gdpr.art9.publicHealth' | translate }}</mat-option>
              <mat-option value="pa.legalBasis.gdpr.art9.archivingResearch">{{ 'pa.legalBasis.gdpr.art9.archivingResearch' | translate }}</mat-option>
            </mat-select>
            <mat-hint [ngClass]="{'hidden': fieldsBeingSaved['paLegalBasisGdprArt9']}"
                      innerHTML="{{'pa.legalBasis.gdpr.art9.hint' | translate}}"></mat-hint>
            <mat-hint [ngClass]="{'active': fieldsBeingSaved['paLegalBasisGdprArt9']}" align="end" class="saving">
              {{'basicInfo.fields.saving' | translate}}
            </mat-hint>
          </mat-form-field>
        </div>
      </display-jurisdiction>

      <display-jurisdiction jurisdiction="lgpd">
        <div commentable comment-subject-codename="pa-{{paId}}-legalbasis-art11"
        comment-subject-title="{{'pa.legalbasis.commentSubject.part1' | translate}} {{paName}} {{'pa.legalbasis.commentSubject.part2' | translate}}">
          <mat-form-field>
            <mat-select placeholder="{{'pa.legalbasis.lgpdArt11.title' | translate}}" multiple="true" formControlName="paLegalBasisArt11" (selectionChange)="submit('paLegalBasisArt11')">
              <mat-option value="pa.legalbasis.consent" title="{{ 'pa.legalbasis.consent' | translate }}">{{ 'pa.legalbasis.consent' | translate }}</mat-option>
              <mat-option value="pa.legalbasis.complianceWithOther" title="{{ 'pa.legalbasis.complianceWithOther' | translate}}">{{ 'pa.legalbasis.complianceWithOther' | translate}}</mat-option>
              <mat-option value="pa.legalbasis.lgpdAdministrationPolicies" title="{{ 'pa.legalbasis.lgpdAdministrationPolicies' | translate}}">{{ 'pa.legalbasis.lgpdAdministrationPolicies' | translate}}</mat-option>
              <mat-option value="pa.legalbasis.brArbitration" title="{{'pa.legalbasis.brArbitration' | translate}}">{{'pa.legalbasis.brArbitration' | translate}}</mat-option>
              <mat-option value="pa.legalbasis.lgpdStudies" title="{{ 'pa.legalbasis.lgpdStudies' | translate}}">{{ 'pa.legalbasis.lgpdStudies' | translate}}</mat-option>
              <mat-option value="pa.legalbasis.lgpdVitalThirdParty" title="{{ 'pa.legalbasis.lgpdVitalThirdParty' | translate}}">{{ 'pa.legalbasis.lgpdVitalThirdParty' | translate}}</mat-option>
              <mat-option value="pa.legalbasis.lgpdHealthSupervision" title="{{ 'pa.legalbasis.lgpdHealthSupervision' | translate}}">{{ 'pa.legalbasis.lgpdHealthSupervision' | translate}}</mat-option>
              <mat-option value="pa.legalbasis.lgpdFraudPrevention" title="{{ 'pa.legalbasis.lgpdFraudPrevention' | translate}}">{{ 'pa.legalbasis.lgpdFraudPrevention' | translate}}</mat-option>
            </mat-select>
            <mat-hint [ngClass]="{'hidden': fieldsBeingSaved['paLegalBasisArt11']}"
              innerHTML="{{'pa.legalbasis.tip.art11' | translate}}"></mat-hint>
            <mat-hint [ngClass]="{'active': fieldsBeingSaved['paLegalBasisArt11']}" align="end" class="saving">
              {{'basicInfo.fields.saving' | translate}}
            </mat-hint>
          </mat-form-field>
        </div>
      </display-jurisdiction>

      <div *ngIf="hasLegalBasisOther" commentable
        comment-subject-codename="pa-{{paId}}-legalbasis-other"
        comment-subject-title="{{'pa.legalbasis.commentSubject.part1' | translate}} {{paName}} {{'pa.legalbasis.commentSubject.part2' | translate}}: {{ 'pa.legalbasis.other' | translate }}">
        <mat-form-field>
          <textarea matTextareaAutosize matInput rows="3"
            placeholder="{{'pa.legalBasis.other.description' | translate}}" [readonly]="!canWrite"
            (change)="submit('paLegalBasisOther')"
            formControlName="paLegalBasisOther"></textarea>
          <mat-hint [ngClass]="{'hidden': fieldsBeingSaved['paLegalBasisOther']}"
            innerHTML="{{'pa.legalBasis.other.tip' | translate}}"></mat-hint>
          <mat-hint [ngClass]="{'active': fieldsBeingSaved['paLegalBasisOther']}" align="end"
            class="saving">
            {{'basicInfo.fields.saving' | translate}}
          </mat-hint>
        </mat-form-field>
      </div>
    </div> <!-- Processing on behalf -->

    <div commentable comment-subject-codename="pa-{{paId}}-data-subjects"
      comment-subject-title="{{'pa.dataSubjectCategories.commentSubject.part1' | translate}} {{paName}} {{'pa.dataSubjectCategories.commentSubject.part2' | translate}}">
      <todoable subject="pa-{{paId}}-data-subjects" suggested-title="VT-{{paId}}: "></todoable>
      <mat-form-field>
        <mat-select placeholder="{{'pa.dataSubjectCategories.description' | translate}}" multiple="true"
          [disabled]="!canWrite" (selectionChange)="submit('paDataSubjectCategories'); updateIncidentSeverity(true)"
          formControlName="paDataSubjectCategories">
          <mat-option value="pa.dataSubject.categories.employees">{{'pa.dataSubject.categories.employees' | translate}}
          </mat-option>
          <mat-option value="pa.dataSubject.categories.applicants">
            {{'pa.dataSubject.categories.applicants' | translate}}</mat-option>
          <mat-option value="pa.dataSubject.categories.trainees">{{'pa.dataSubject.categories.trainees' | translate}}
          </mat-option>
          <mat-option value="pa.dataSubject.categories.temporaryWorkers">
            {{'pa.dataSubject.categories.temporaryWorkers' | translate}}</mat-option>
          <mat-option value="pa.dataSubject.categories.managers">{{'pa.dataSubject.categories.managers' | translate}}
          </mat-option>
          <mat-option value="pa.dataSubject.categories.commercialAgent">
            {{'pa.dataSubject.categories.commercialAgent' | translate}}</mat-option>
          <mat-option value="pa.dataSubject.categories.serviceProvider">
            {{'pa.dataSubject.categories.serviceProvider' | translate}}</mat-option>
          <mat-option value="pa.dataSubject.categories.suppliers">{{'pa.dataSubject.categories.suppliers' | translate}}
          </mat-option>
          <mat-option value="pa.dataSubject.categories.interestedParties">
            {{'pa.dataSubject.categories.interestedParties' | translate}}</mat-option>
          <mat-option value="pa.dataSubject.categories.contractualPartner">
            {{'pa.dataSubject.categories.contractualPartner' | translate}}</mat-option>
          <mat-option value="pa.dataSubject.categories.customers">{{'pa.dataSubject.categories.customers' | translate}}
          </mat-option>
          <mat-option value="pa.dataSubject.categories.customersOfCustomers">
            {{'pa.dataSubject.categories.customersOfCustomers' | translate}}</mat-option>
          <mat-option value="pa.dataSubject.categories.employeesOfCustomers">
            {{'pa.dataSubject.categories.employeesOfCustomers' | translate}}</mat-option>
          <mat-option value="pa.dataSubject.categories.members">{{'pa.dataSubject.categories.members' | translate}}
          </mat-option>
          <mat-option value="pa.dataSubject.categories.debtor">{{'pa.dataSubject.categories.debtor' | translate}}
          </mat-option>
          <mat-option value="pa.dataSubject.categories.investor">{{'pa.dataSubject.categories.investor' | translate}}
          </mat-option>
          <mat-option value="pa.dataSubject.categories.jointController">
            {{'pa.dataSubject.categories.jointController' | translate}}</mat-option>
          <mat-option value="pa.dataSubject.categories.patients">{{'pa.dataSubject.categories.patients' | translate}}
          </mat-option>
          <mat-option value="pa.dataSubject.categories.other">{{'pa.dataSubject.categories.other' | translate}}
          </mat-option>
        </mat-select>
        <mat-hint [ngClass]="{'hidden': fieldsBeingSaved['paDataSubjectCategories']}">
          {{'pa.dataSubject.description' | translate}}
        </mat-hint>
        <mat-hint [ngClass]="{'active': fieldsBeingSaved['paDataSubjectCategories']}" align="end" class="saving">
          {{'basicInfo.fields.saving' | translate}}
        </mat-hint>
      </mat-form-field>
    </div>

    <div *ngIf="hasOtherDataSubject" commentable comment-subject-codename="pa-{{paId}}-data-subject-other-explanation"
      comment-subject-title="{{'pa.dataSubjectCategories.commentSubject.part1' | translate}} {{paName}} {{'pa.dataSubjectCategories.commentSubject.part2' | translate}}: {{ 'pa.dataSubject.categories.other' | translate }}">
      <todoable subject="pa-{{paId}}-data-subject-other-explanation" suggested-title="VT-{{paId}}: "></todoable>
      <mat-form-field>
        <textarea matTextareaAutosize matInput rows="3"
          placeholder="{{'pa.dataSubjectExplanation.description' | translate}}" [readonly]="!canWrite"
          (change)="submit('paDataSubjectExplanation')" formControlName="paDataSubjectExplanation"></textarea>
        <mat-hint [ngClass]="{'hidden': fieldsBeingSaved['paDataSubjectExplanation']}"
          innerHTML="{{'pa.dataSubjectExplanation.tip' | translate}}"></mat-hint>
        <mat-hint [ngClass]="{'active': fieldsBeingSaved['paDataSubjectExplanation']}" align="end" class="saving">
          {{'basicInfo.fields.saving' | translate}}
        </mat-hint>
      </mat-form-field>
    </div>

    <div commentable comment-subject-codename="pa-{{paId}}-data-categories"
      comment-subject-title="{{'pa.dataCategoriesDescription.commentSubject.part1' | translate}} {{paName}} {{'pa.dataCategoriesDescription.commentSubject.part2' | translate}}">
      <todoable subject="pa-{{paId}}-data-categories" suggested-title="VT-{{paId}}: "></todoable>
      <mat-form-field>
        <mat-select placeholder="{{'pa.dataCategoriesDescription.description' | translate}}" multiple="true"
          [disabled]="!canWrite" (selectionChange)="submit('paDataCategoriesDescription'); updateIncidentSeverity(true)"
          formControlName="paDataCategoriesDescription">
          <mat-option value="pa.dataSubject.dataCategories.name">{{'pa.dataSubject.dataCategories.name' | translate}}
          </mat-option>
          <mat-option value="pa.dataSubject.dataCategories.address">
            {{'pa.dataSubject.dataCategories.address' | translate}}</mat-option>
          <mat-option value="pa.dataSubject.dataCategories.email">{{'pa.dataSubject.dataCategories.email' | translate}}
          </mat-option>
          <mat-option value="pa.dataSubject.dataCategories.phone">{{'pa.dataSubject.dataCategories.phone' | translate}}
          </mat-option>
          <mat-option value="pa.dataSubject.dataCategories.fax">{{'pa.dataSubject.dataCategories.fax' | translate}}
          </mat-option>
          <mat-option value="pa.dataSubject.dataCategories.login">{{'pa.dataSubject.dataCategories.login' | translate}}
          </mat-option>
          <mat-option value="pa.dataSubject.dataCategories.messengerIdentity">
            {{'pa.dataSubject.dataCategories.messengerIdentity' | translate}}</mat-option>
          <mat-option value="pa.dataSubject.dataCategories.ageRanges">
            {{'pa.dataSubject.dataCategories.ageRanges' | translate}}</mat-option>
          <mat-option value="pa.dataSubject.dataCategories.birthInformation">
            {{'pa.dataSubject.dataCategories.birthInformation' | translate}}</mat-option>
          <mat-option value="pa.dataSubject.dataCategories.family">
            {{'pa.dataSubject.dataCategories.family' | translate}}</mat-option>
          <mat-option value="pa.dataSubject.dataCategories.maritalStatus">
            {{'pa.dataSubject.dataCategories.maritalStatus' | translate}}</mat-option>
          <mat-option value="pa.dataSubject.dataCategories.likes">{{'pa.dataSubject.dataCategories.likes' | translate}}
          </mat-option>
          <mat-option value="pa.dataSubject.dataCategories.citizenship">
            {{'pa.dataSubject.dataCategories.citizenship' | translate}}</mat-option>
          <mat-option value="pa.dataSubject.dataCategories.passportId">
            {{'pa.dataSubject.dataCategories.passportId' | translate}}</mat-option>
          <mat-option value="pa.dataSubject.dataCategories.educationalInfo">
            {{'pa.dataSubject.dataCategories.educationalInfo' | translate}}</mat-option>
          <mat-option value="pa.dataSubject.dataCategories.employmentInformation">
            {{'pa.dataSubject.dataCategories.employmentInformation' | translate}}</mat-option>
          <mat-option value="pa.dataSubject.dataCategories.licensePlate">
            {{'pa.dataSubject.dataCategories.licensePlate' | translate}}</mat-option>
          <mat-option value="pa.dataSubject.dataCategories.insuranceInformation">
            {{'pa.dataSubject.dataCategories.insuranceInformation' | translate}}</mat-option>
          <mat-option value="pa.dataSubject.dataCategories.salaryInformation">
            {{'pa.dataSubject.dataCategories.salaryInformation' | translate}}</mat-option>
          <mat-option value="pa.dataSubject.dataCategories.bankDetails">
            {{'pa.dataSubject.dataCategories.bankDetails' | translate}}</mat-option>
          <mat-option value="pa.dataSubject.dataCategories.orderHistory">
            {{'pa.dataSubject.dataCategories.orderHistory' | translate}}</mat-option>
          <mat-option value="pa.dataSubject.dataCategories.ip">{{'pa.dataSubject.dataCategories.ip' | translate}}
          </mat-option>
          <!--<mat-option value="pa.dataSubject.dataCategories.gps">{{'pa.dataSubject.dataCategories.gps' | translate}}</mat-option>-->
          <mat-option value="pa.dataSubject.dataCategories.location">
            {{'pa.dataSubject.dataCategories.location' | translate}}</mat-option>
          <mat-option value="pa.dataSubject.dataCategories.social">
            {{'pa.dataSubject.dataCategories.social' | translate}}</mat-option>
          <mat-option value="pa.dataSubject.dataCategories.criminal">
            {{'pa.dataSubject.dataCategories.criminal' | translate}}</mat-option>
          <mat-option value="pa.dataSubject.dataCategories.biometricInfo">
            {{'pa.dataSubject.dataCategories.biometricInfo' | translate}}</mat-option>
          <mat-option value="pa.dataSubject.dataCategories.geneticData">
            {{'pa.dataSubject.dataCategories.geneticData' | translate}}</mat-option>
          <mat-option value="pa.dataSubject.dataCategories.physicalTraits">
            {{'pa.dataSubject.dataCategories.physicalTraits' | translate}}</mat-option>
          <mat-option value="pa.dataSubject.dataCategories.imageData">
            {{'pa.dataSubject.dataCategories.imageData' | translate}}</mat-option>
          <mat-option value="pa.dataSubject.dataCategories.ethnicity">
            {{'pa.dataSubject.dataCategories.ethnicity' | translate}}</mat-option>
          <mat-option value="pa.dataSubject.dataCategories.healthData">
            {{'pa.dataSubject.dataCategories.healthData' | translate}}</mat-option>
          <mat-option value="pa.dataSubject.dataCategories.religion">
            {{'pa.dataSubject.dataCategories.religion' | translate}}</mat-option>
          <mat-option value="pa.dataSubject.dataCategories.tradeUnion">
            {{'pa.dataSubject.dataCategories.tradeUnion' | translate}}</mat-option>
          <mat-option value="pa.dataSubject.dataCategories.politicalInformation">
            {{'pa.dataSubject.dataCategories.politicalInformation' | translate}}</mat-option>
          <mat-option value="pa.dataSubject.dataCategories.sex">{{'pa.dataSubject.dataCategories.sex' | translate}}
          </mat-option>
          <mat-option value="pa.dataSubject.dataCategories.indicatorsOfBehaviour">
            {{'pa.dataSubject.dataCategories.indicatorsOfBehaviour' | translate}}
          </mat-option>
          <mat-option value="pa.dataSubject.dataCategories.personalityTraits">
            {{'pa.dataSubject.dataCategories.personalityTraits' | translate}}
          </mat-option>
          <display-jurisdiction jurisdiction="lgpd">
            <mat-option value="pa.dataSubject.dataCategories.otherIdNumbers">
              {{'pa.dataSubject.dataCategories.otherIdNumbers' | translate}}
            </mat-option>
          </display-jurisdiction>
          <mat-option value="pa.dataSubject.dataCategories.other">{{'pa.dataSubject.dataCategories.other' | translate}}
          </mat-option>
        </mat-select>
        <mat-hint [ngClass]="{'hidden': fieldsBeingSaved['paDataCategoriesDescription']}">
          {{'pa.dataCategoriesDescription.tip' | translate}}
        </mat-hint>
        <mat-hint [ngClass]="{'active': fieldsBeingSaved['paDataCategoriesDescription']}" align="end" class="saving">
          {{'basicInfo.fields.saving' | translate}}
        </mat-hint>
      </mat-form-field>

      <mat-form-field *ngIf="dataCategoriesHasOther()">
        <textarea matTextareaAutosize matInput rows="3"
          placeholder="{{'pa.dataCategoriesDescription.other' | translate}}" [readonly]="!canWrite"
          (change)="submit('paDataCategoriesDescriptionOther')"
          formControlName="paDataCategoriesDescriptionOther"></textarea>
        <mat-hint [ngClass]="{'hidden': fieldsBeingSaved['paDataCategoriesDescriptionOther']}"
          innerHTML="{{'pa.dataCategoriesDescriptionOther.tip' | translate}}"></mat-hint>
        <mat-hint [ngClass]="{'active': fieldsBeingSaved['paDataCategoriesDescriptionOther']}" align="end"
          class="saving">
          {{'basicInfo.fields.saving' | translate}}
        </mat-hint>
      </mat-form-field>
    </div>

    <!-- Data Source -->
    <br><br>
    <mat-divider></mat-divider>
    <h2>
      {{ 'pa.details.sections.data-and-deletion.title' | translate }}
    </h2>
    <mat-divider></mat-divider>
    <br><br>

    <div commentable comment-subject-codename="pa-{{paId}}-data-source"
      comment-subject-title="{{'pa.dataSource.commentSubject.part1' | translate}} {{paName}} {{'pa.dataSource.commentSubject.part2' | translate}}">
      <todoable subject="pa-{{paId}}-data-source" suggested-title="VT-{{paId}}: "></todoable>
      <mat-form-field>
        <textarea matTextareaAutosize matInput rows="3"
          placeholder="{{'pa.dataSource.description' | translate}}" [readonly]="!canWrite"
          (change)="submit('paDataSource')" formControlName="paDataSource"></textarea>
        <mat-hint [ngClass]="{'hidden': fieldsBeingSaved['paDataSource']}"
          innerHTML="{{'pa.dataSource.tip' | translate}}"></mat-hint>
        <mat-hint [ngClass]="{'active': fieldsBeingSaved['paDataSource']}" align="end" class="saving">
          {{'basicInfo.fields.saving' | translate}}
        </mat-hint>
      </mat-form-field>
    </div>

    <!-- Storage Location -->

    <div commentable comment-subject-codename="pa-{{paId}}-storage-location"
      comment-subject-title="{{'pa.storageLocation.commentSubject.part1' | translate}} {{paName}} {{'pa.storageLocation.commentSubject.part2' | translate}}">
      <todoable subject="pa-{{paId}}-storage-location" suggested-title="VT-{{paId}}: "></todoable>
      <mat-form-field>
        <textarea matTextareaAutosize matInput rows="3"
          placeholder="{{'pa.storageLocation.description' | translate}}" [readonly]="!canWrite"
          (change)="submit('paStorageLocation')" formControlName="paStorageLocation"></textarea>
        <mat-hint [ngClass]="{'hidden': fieldsBeingSaved['paStorageLocation']}"
          innerHTML="{{'pa.storageLocation.tip' | translate}}"></mat-hint>
        <mat-hint [ngClass]="{'active': fieldsBeingSaved['paStorageLocation']}" align="end" class="saving">
          {{'basicInfo.fields.saving' | translate}}
        </mat-hint>
      </mat-form-field>
    </div>

    <!-- New Erasure Limit -->

    <div commentable comment-subject-codename="pa-{{paId}}-erasure-limit-select"
      comment-subject-title="{{'pa.erasureTimeSelect.commentSubject.part1' | translate}} {{paName}} {{'pa.erasureTimeSelect.commentSubject.part2' | translate}}">
      <todoable subject="pa-{{paId}}-erasure-limit-select" suggested-title="VT-{{paId}}: "></todoable>
      <mat-form-field>
        <mat-select class="erasure-time-select" placeholder="{{'pa.erasureTimeSelect.description' | translate}}" multiple="true" [disabled]="!canWrite"
          (selectionChange)="submit('erasureTimeSelect')" formControlName="erasureTimeSelect">
          <mat-option title="{{'pa.erasureTime.72hours' | translate}}" value="pa.erasureTime.72hours">{{'pa.erasureTime.72hours' | translate}}</mat-option>
          <mat-option title="{{'pa.erasureTime.1year' | translate}}" value="pa.erasureTime.1year">{{'pa.erasureTime.1year' | translate}}</mat-option>
          <mat-option title="{{'pa.erasureTime.1yearOrConsentRevoked' | translate}}" value="pa.erasureTime.1yearOrConsentRevoked">{{'pa.erasureTime.1yearOrConsentRevoked' | translate}}</mat-option>
          <mat-option title="{{'pa.erasureTime.3yearsAfterDoubleOptIn' | translate}}" value="pa.erasureTime.3yearsAfterDoubleOptIn">{{'pa.erasureTime.3yearsAfterDoubleOptIn' | translate}}</mat-option>
          <mat-option title="{{'pa.erasureTime.3yearsAfterEndOfBusinessRelation' | translate}}" value="pa.erasureTime.3yearsAfterEndOfBusinessRelation">{{'pa.erasureTime.3yearsAfterEndOfBusinessRelation' | translate}}</mat-option>
          <mat-option title="{{'pa.erasureTime.3years' | translate}}" value="pa.erasureTime.3years">{{'pa.erasureTime.3years' | translate}}</mat-option>
          <mat-option title="{{'pa.erasureTime.3yearsAfterEndOfMembership' | translate}}" value="pa.erasureTime.3yearsAfterEndOfMembership">{{'pa.erasureTime.3yearsAfterEndOfMembership' | translate}}</mat-option>
          <mat-option title="{{'pa.erasureTime.10years' | translate}}" value="pa.erasureTime.10years">{{'pa.erasureTime.10years' | translate}}</mat-option>
          <mat-option title="{{'pa.erasureTime.6monthsAfterRejection' | translate}}" value="pa.erasureTime.6monthsAfterRejection">{{'pa.erasureTime.6monthsAfterRejection' | translate}}</mat-option>
          <mat-option title="{{'pa.erasureTime.afterEmployeeLeft' | translate}}" value="pa.erasureTime.afterEmployeeLeft">{{'pa.erasureTime.afterEmployeeLeft' | translate}}</mat-option>
          <mat-option title="{{'pa.erasureTime.afterEmployeeLeftOrConsentRevoked' | translate}}" value="pa.erasureTime.afterEmployeeLeftOrConsentRevoked">{{'pa.erasureTime.afterEmployeeLeftOrConsentRevoked' | translate}}</mat-option>
          <mat-option title="{{'pa.erasureTime.3yearsAfterEmployeeLeft' | translate}}" value="pa.erasureTime.3yearsAfterEmployeeLeft">{{'pa.erasureTime.3yearsAfterEmployeeLeft' | translate}}</mat-option>
          <mat-option title="{{'pa.erasureTime.accordingToRegulations' | translate}}" value="pa.erasureTime.accordingToRegulations">{{'pa.erasureTime.accordingToRegulations' | translate}}</mat-option>
          <mat-option title="{{'pa.erasureTime.afterLegalObjectionPeriod' | translate}}" value="pa.erasureTime.afterLegalObjectionPeriod">{{'pa.erasureTime.afterLegalObjectionPeriod' | translate}}</mat-option>
          <mat-option title="{{'pa.erasureTime.custom' | translate}}" value="pa.erasureTime.custom" (onSelectionChange)="isCustomErasureTime()">{{'pa.erasureTime.custom' | translate}}</mat-option>
        </mat-select>
        <mat-hint [ngClass]="{'hidden': fieldsBeingSaved['erasureTimeSelect']}"
          innerHTML="{{'pa.erasureTimeSelect.tip' | translate}}"></mat-hint>
        <mat-hint [ngClass]="{'active': fieldsBeingSaved['erasureTimeSelect']}" align="end" class="saving">
          {{'basicInfo.fields.saving' | translate}}
        </mat-hint>
      </mat-form-field>
    </div>

    <div *ngIf="hasCustomErasureTime" commentable comment-subject-codename="pa-{{paId}}-custom-erasure-limit"
      comment-subject-title="{{'pa.erasureTime.custom.commentSubject.part1' | translate}} {{paName}} {{'pa.erasureTime.custom.commentSubject.part2' | translate}}">
      <todoable subject="pa-{{paId}}-custom-erasure-limit" suggested-title="VT-{{paId}}: "></todoable>
      <mat-form-field>
        <mat-label>{{'pa.erasureTime.custom' | translate}}</mat-label>
        <input matInput
          (change)="submit('customErasureTime')" formControlName="customErasureTime">
        <mat-hint [ngClass]="{'hidden': fieldsBeingSaved['customErasureTime']}"
          innerHTML="{{'pa.erasureTime.custom.description' | translate}}"></mat-hint>
        <mat-hint [ngClass]="{'active': fieldsBeingSaved['customErasureTime']}" align="end" class="saving">
          {{'basicInfo.fields.saving' | translate}}
        </mat-hint>
      </mat-form-field>
    </div>

    <!-- LEGACY Erasure Limit (only show when not empty) -->

    <div *ngIf="hasLegacyErasureTime" commentable comment-subject-codename="pa-{{paId}}-erasure-limit"
      comment-subject-title="{{'pa.erasureTime.commentSubject.part1' | translate}} {{paName}} {{'pa.erasureTime.commentSubject.part2' | translate}}">
      <todoable subject="pa-{{paId}}-erasure-limit" suggested-title="VT-{{paId}}: "></todoable>
      <mat-form-field>
        <textarea matTextareaAutosize matInput rows="3" placeholder="{{'pa.erasureTime.description' | translate}}"
          [readonly]="!canWrite" (change)="submit('erasureTime')" formControlName="erasureTime"></textarea>
        <mat-hint [ngClass]="{'hidden': fieldsBeingSaved['erasureTime']}"
          innerHTML="{{'pa.erasureTime.tip' | translate}}"></mat-hint>
        <mat-hint [ngClass]="{'active': fieldsBeingSaved['erasureTime']}" align="end" class="saving">
          {{'basicInfo.fields.saving' | translate}}
        </mat-hint>
      </mat-form-field>
    </div>

    <div commentable comment-subject-title="{{'pa.erasureTimeConsideration.commentSubject.part1' | translate}} {{paName}} {{'pa.erasureTimeConsideration.commentSubject.part2' | translate}}">
      <todoable subject="pa-{{paId}}-erasure-consideration" suggested-title="VT-{{paId}}: "></todoable>
      <mat-form-field>
        <textarea matTextareaAutosize matInput rows="3"
          placeholder="{{'pa.erasureTimeConsideration.description' | translate}}" [readonly]="!canWrite"
          (change)="submit('erasureTimeConsideration')" formControlName="erasureTimeConsideration"></textarea>
        <mat-hint [ngClass]="{'hidden': fieldsBeingSaved['erasureTimeConsideration']}"
          innerHTML="{{'pa.erasureTimeConsideration.tip' | translate}}"></mat-hint>
        <mat-hint [ngClass]="{'active': fieldsBeingSaved['erasureTimeConsideration']}" align="end" class="saving">
          {{'basicInfo.fields.saving' | translate}}
        </mat-hint>
      </mat-form-field>
    </div>

    <!-- Vater Erasure Time Fields -->
    <feature-access-local featureName="pa-custom-fields-2">
      <ng-container *ngIf="pa?.customFields">
        <div commentable comment-subject-codename="pa-{{paId}}-custom-responsible-deleter"
          comment-subject-title="{{'pa.customFields.responsibleDeleter.part1' | translate}} {{paName}} {{'pa.customFields.responsibleDeleter.commentSubject.part2' | translate}}">
          <todoable subject="pa-{{paId}}-custom-responsible-deleter" suggested-title="VT-{{paId}}: "></todoable>
          <mat-form-field>
            <textarea matTextareaAutosize matInput rows="3" placeholder="{{'pa.customFields.responsibleDeleter.description' | translate}}"
              [readonly]="!canWrite" (change)="submitCustomField('responsibleDeleter', $event.target.value)" [value]="pa.customFields.responsibleDeleter"></textarea>
            <mat-hint [ngClass]="{'hidden': fieldsBeingSaved['customFieldsresponsibleDeleter']}" innerHTML="{{'pa.customFields.responsibleDeleter.tip' | translate}}"></mat-hint>
            <mat-hint [ngClass]="{'active': fieldsBeingSaved['customFieldsresponsibleDeleter']}" align="end" class="saving">
              {{'basicInfo.fields.saving' | translate}}
            </mat-hint>
          </mat-form-field>
        </div>

        <div commentable comment-subject-codename="pa-{{paId}}-custom-deletion-insurance"
          comment-subject-title="{{'pa.customFields.deletionInsurance.part1' | translate}} {{paName}} {{'pa.customFields.deletionInsurance.commentSubject.part2' | translate}}">
          <todoable subject="pa-{{paId}}-custom-deletion-insurance" suggested-title="VT-{{paId}}: "></todoable>
          <mat-form-field>
            <textarea matTextareaAutosize matInput rows="3" placeholder="{{'pa.customFields.deletionInsurance.description' | translate}}"
              [readonly]="!canWrite" (change)="submitCustomField('deletionInsurance', $event.target.value)" [value]="pa.customFields.deletionInsurance"></textarea>
            <mat-hint [ngClass]="{'hidden': fieldsBeingSaved['customFieldsdeletionInsurance']}" innerHTML="{{'pa.customFields.deletionInsurance.tip' | translate}}"></mat-hint>
            <mat-hint [ngClass]="{'active': fieldsBeingSaved['customFieldsdeletionInsurance']}" align="end" class="saving">
              {{'basicInfo.fields.saving' | translate}}
            </mat-hint>
          </mat-form-field>
        </div>
      </ng-container>
    </feature-access-local>

    <div commentable comment-subject-codename="pa-{{paId}}-toms"
      comment-subject-title="{{'pa.toms.commentSubject.part1' | translate}} {{paName}} {{'pa.toms.commentSubject.part2' | translate}}">
      <todoable subject="pa-{{paId}}-toms" suggested-title="VT-{{paId}}: "></todoable>
      <mat-form-field>
        <textarea matTextareaAutosize matInput rows="3" placeholder="{{'pa.toms.description' | translate}}"
          [readonly]="!canWrite" (change)="submit('toms')" formControlName="toms"></textarea>
        <mat-hint [ngClass]="{'hidden': fieldsBeingSaved['toms']}" innerHTML="{{'pa.toms.tip' | translate}}"></mat-hint>
        <mat-hint [ngClass]="{'active': fieldsBeingSaved['toms']}" align="end" class="saving">
          {{'basicInfo.fields.saving' | translate}}
        </mat-hint>
      </mat-form-field>
    </div>

    <br><br>
    <mat-divider></mat-divider>
    <h2>
      {{ 'pa.details.sections.other.title' | translate }}
    </h2>
    <mat-divider></mat-divider>
    <br><br>

    <!-- ================ CUSTOM FIELDS SECTION START ================ -->
    <!-- this is an awful name, but if we're hard-coding, then we be hard-coding -->
    <!-- pa-custom-fields-1: MDK IT; pa-custom-fields-2: Vater Gruppe -->
    <ng-container *ngIf="pa?.customFields">
      <feature-access-local featureName="pa-custom-fields-1">
        <div commentable comment-subject-codename="pa-{{paId}}-custom-information-obligation"
          comment-subject-title="{{'pa.customFields.informationObligationcommentSubject.part1' | translate}} {{paName}} {{'pa.customFields.informationObligation.commentSubject.part2' | translate}}">
          <todoable subject="pa-{{paId}}-custom-information-obligation" suggested-title="VT-{{paId}}: "></todoable>
          <mat-form-field>
            <textarea matTextareaAutosize matInput rows="3" placeholder="{{'pa.customFields.informationObligation.description' | translate}}"
              [readonly]="!canWrite" (change)="submitCustomField('informationObligation', $event.target.value)" [value]="pa.customFields.informationObligation"></textarea>
            <mat-hint [ngClass]="{'hidden': fieldsBeingSaved['customFieldsinformationObligation']}" innerHTML="{{'pa.customFields.informationObligation.tip' | translate}}"></mat-hint>
            <mat-hint [ngClass]="{'active': fieldsBeingSaved['customFieldsinformationObligation']}" align="end" class="saving">
              {{'basicInfo.fields.saving' | translate}}
            </mat-hint>
          </mat-form-field>
        </div>
      </feature-access-local>

      <feature-access-local featureName="pa-custom-fields-1,pa-custom-fields-2">
        <div commentable comment-subject-codename="pa-{{paId}}-custom-technical-procesors"
          comment-subject-title="{{'pa.customFields.technicalProcessors.part1' | translate}} {{paName}} {{'pa.customFields.technicalProcessors.commentSubject.part2' | translate}}">
          <todoable subject="pa-{{paId}}-custom-technical-procesors" suggested-title="VT-{{paId}}: "></todoable>
          <mat-form-field>
            <textarea matTextareaAutosize matInput rows="3" placeholder="{{'pa.customFields.technicalProcessors.description' | translate}}"
              [readonly]="!canWrite" (change)="submitCustomField('technicalProcessors', $event.target.value)" [value]="pa.customFields.technicalProcessors"></textarea>
            <mat-hint [ngClass]="{'hidden': fieldsBeingSaved['customFieldstechnicalProcessors']}" innerHTML="{{'pa.customFields.technicalProcessors.tip' | translate}}"></mat-hint>
            <mat-hint [ngClass]="{'active': fieldsBeingSaved['customFieldstechnicalProcessors']}" align="end" class="saving">
              {{'basicInfo.fields.saving' | translate}}
            </mat-hint>
          </mat-form-field>
        </div>
      </feature-access-local>

      <feature-access-local featureName="pa-custom-fields-1">
        <div commentable comment-subject-codename="pa-{{paId}}-custom-interfaces"
          comment-subject-title="{{'pa.customFields.interfaces.part1' | translate}} {{paName}} {{'pa.customFields.interfaces.commentSubject.part2' | translate}}">
          <todoable subject="pa-{{paId}}-custom-interfaces" suggested-title="VT-{{paId}}: "></todoable>
          <mat-form-field>
            <textarea matTextareaAutosize matInput rows="3" placeholder="{{'pa.customFields.interfaces.description' | translate}}"
              [readonly]="!canWrite" (change)="submitCustomField('interfaces', $event.target.value)" [value]="pa.customFields.interfaces"></textarea>
            <mat-hint [ngClass]="{'hidden': fieldsBeingSaved['customFieldsinterfaces']}" innerHTML="{{'pa.customFields.interfaces.tip' | translate}}"></mat-hint>
            <mat-hint [ngClass]="{'active': fieldsBeingSaved['customFieldsinterfaces']}" align="end" class="saving">
              {{'basicInfo.fields.saving' | translate}}
            </mat-hint>
          </mat-form-field>
        </div>
      </feature-access-local>

      <feature-access-local featureName="pa-custom-fields-2">
        <div commentable comment-subject-codename="pa-{{paId}}-custom-recipient-categories"
          comment-subject-title="{{'pa.customFields.recipientCategories.part1' | translate}} {{paName}} {{'pa.customFields.recipientCategories.commentSubject.part2' | translate}}">
          <todoable subject="pa-{{paId}}-custom-recipient-categories" suggested-title="VT-{{paId}}: "></todoable>
          <mat-form-field>
            <textarea matTextareaAutosize matInput rows="3" placeholder="{{'pa.customFields.recipientCategories.description' | translate}}"
              [readonly]="!canWrite" (change)="submitCustomField('recipientCategories', $event.target.value)" [value]="pa.customFields.recipientCategories"></textarea>
            <mat-hint [ngClass]="{'hidden': fieldsBeingSaved['customFieldsrecipientCategories']}" innerHTML="{{'pa.customFields.recipientCategories.tip' | translate}}"></mat-hint>
            <mat-hint [ngClass]="{'active': fieldsBeingSaved['customFieldsrecipientCategories']}" align="end" class="saving">
              {{'basicInfo.fields.saving' | translate}}
            </mat-hint>
          </mat-form-field>
        </div>
      </feature-access-local>

      <feature-access-local featureName="pa-custom-fields-2">
        <div commentable comment-subject-codename="pa-{{paId}}-custom-dpo-recommendation"
          comment-subject-title="{{'pa.customFields.dpoRecommendation.part1' | translate}} {{paName}} {{'pa.customFields.dpoRecommendation.commentSubject.part2' | translate}}">
          <todoable subject="pa-{{paId}}-custom-dpo-recommendation" suggested-title="VT-{{paId}}: "></todoable>
          <mat-form-field>
            <textarea matTextareaAutosize matInput rows="3" placeholder="{{'pa.customFields.dpoRecommendation.description' | translate}}"
              [readonly]="!canWrite" (change)="submitCustomField('dpoRecommendation', $event.target.value)" [value]="pa.customFields.dpoRecommendation"></textarea>
            <mat-hint [ngClass]="{'hidden': fieldsBeingSaved['customFieldsdpoRecommendation']}" innerHTML="{{'pa.customFields.dpoRecommendation.tip' | translate}}"></mat-hint>
            <mat-hint [ngClass]="{'active': fieldsBeingSaved['customFieldsdpoRecommendation']}" align="end" class="saving">
              {{'basicInfo.fields.saving' | translate}}
            </mat-hint>
          </mat-form-field>
        </div>
      </feature-access-local>

      <feature-access-local featureName="pa-custom-fields-2">
        <div commentable comment-subject-codename="pa-{{paId}}-custom-third-country-transfers"
          comment-subject-title="{{'pa.customFields.thirdCountryTransfers.part1' | translate}} {{paName}} {{'pa.customFields.thirdCountryTransfers.commentSubject.part2' | translate}}">
          <todoable subject="pa-{{paId}}-custom-third-country-transfers" suggested-title="VT-{{paId}}: "></todoable>
          <mat-form-field>
            <mat-select placeholder="{{'pa.customFields.thirdCountryTransfers.description' | translate}}" multiple="false"
              [disabled]="!canWrite" (selectionChange)="submitCustomField('thirdCountryTransfers', $event.value)"
              [value]="pa.customFields.thirdCountryTransfers">
              <mat-option [value]="false">{{'yesno.no' | translate}}</mat-option>
              <mat-option [value]="true">{{'yesno.yes' | translate}}</mat-option>
            </mat-select>

            <mat-hint [ngClass]="{'hidden': fieldsBeingSaved['customFieldsthirdCountryTransfers']}" innerHTML="{{'pa.customFields.thirdCountryTransfers.tip' | translate}}"></mat-hint>
            <mat-hint [ngClass]="{'active': fieldsBeingSaved['customFieldsthirdCountryTransfers']}" align="end" class="saving">
              {{'basicInfo.fields.saving' | translate}}
            </mat-hint>
          </mat-form-field>
        </div>

        <!-- Acceptable Safety Measures? -->
        <ng-container *ngIf="pa.customFields.thirdCountryTransfers">
          <div commentable comment-subject-codename="pa-{{paId}}-custom-third-country-transfers-safety"
            comment-subject-title="{{'pa.customFields.thirdCountryTransfers.safety.part1' | translate}} {{paName}} {{'pa.customFields.thirdCountryTransfers.safety.commentSubject.part2' | translate}}">
            <todoable subject="pa-{{paId}}-custom-third-country-transfers-safety" suggested-title="VT-{{paId}}: "></todoable>
            <mat-form-field>
              <mat-select placeholder="{{'pa.customFields.thirdCountryTransfers.safety.description' | translate}}" multiple="false"
                [disabled]="!canWrite" (selectionChange)="submitCustomField('thirdCountryTransfersSafety', $event.value)"
                [value]="pa.customFields.thirdCountryTransfersSafety">
                <mat-option [value]="false">{{'yesno.no' | translate}}</mat-option>
                <mat-option [value]="true">{{'yesno.yes' | translate}}</mat-option>
              </mat-select>

              <mat-hint [ngClass]="{'hidden': fieldsBeingSaved['customFieldsthirdCountryTransfersSafety']}" innerHTML="{{'pa.customFields.thirdCountryTransfers.safety.tip' | translate}}"></mat-hint>
              <mat-hint [ngClass]="{'active': fieldsBeingSaved['customFieldsthirdCountryTransfersSafety']}" align="end" class="saving">
                {{'basicInfo.fields.saving' | translate}}
              </mat-hint>
            </mat-form-field>
          </div>

          <!-- if no, display warning -->
          <div *ngIf="pa.customFields.thirdCountryTransfersSafety === false" class="safety-warning">
            <mat-icon>warning</mat-icon>
            {{'pa.customFields.thirdCountryTransfers.safety.warning' | translate}}
          </div>

          <!-- If yes, ask for security  measures -->
          <ng-container *ngIf="pa.customFields.thirdCountryTransfersSafety">
            <div commentable comment-subject-codename="pa-{{paId}}-custom-third-country-transfers-safety-measures"
              comment-subject-title="{{'pa.customFields.thirdCountryTransfers.safetyMeasures.part1' | translate}} {{paName}} {{'pa.customFields.thirdCountryTransfers.safetyMeasures.commentSubject.part2' | translate}}">
              <todoable subject="pa-{{paId}}-custom-third-country-transfers-safety-measures" suggested-title="VT-{{paId}}: "></todoable>
              <mat-form-field>
                <mat-select placeholder="{{'pa.customFields.thirdCountryTransfers.safetyMeasures.description' | translate}}" multiple="false"
                  [disabled]="!canWrite" (selectionChange)="submitCustomField('thirdCountryTransfersSafetyMeasures', $event.value)"
                  [value]="pa.customFields.thirdCountryTransfersSafetyMeasures" multiple="true">
                  <mat-option [value]="'pa.customFields.thirdCountryTransfers.safetyMeasures.scc'">{{'pa.customFields.thirdCountryTransfers.safetyMeasures.scc' | translate}}</mat-option>
                  <mat-option [value]="'pa.customFields.thirdCountryTransfers.safetyMeasures.bcr'">{{'pa.customFields.thirdCountryTransfers.safetyMeasures.bcr' | translate}}</mat-option>
                  <mat-option [value]="'pa.customFields.thirdCountryTransfers.safetyMeasures.behaviorRules'">{{'pa.customFields.thirdCountryTransfers.safetyMeasures.behaviorRules' | translate}}</mat-option>
                  <mat-option [value]="'pa.customFields.thirdCountryTransfers.safetyMeasures.certification'">{{'pa.customFields.thirdCountryTransfers.safetyMeasures.certification' | translate}}</mat-option>
                  <mat-option [value]="'pa.customFields.thirdCountryTransfers.safetyMeasures.other'">{{'pa.customFields.thirdCountryTransfers.safetyMeasures.other' | translate}}</mat-option>
                </mat-select>

                <mat-hint [ngClass]="{'hidden': fieldsBeingSaved['customFieldsthirdCountryTransfersSafetyMeasures']}" innerHTML="{{'pa.customFields.thirdCountryTransfers.safetyMeasures.tip' | translate}}"></mat-hint>
                <mat-hint [ngClass]="{'active': fieldsBeingSaved['customFieldsthirdCountryTransfersSafetyMeasures']}" align="end" class="saving">
                  {{'basicInfo.fields.saving' | translate}}
                </mat-hint>
              </mat-form-field>
            </div>

            <div *ngIf="pa.customFields.thirdCountryTransfersSafetyMeasures && pa.customFields.thirdCountryTransfersSafetyMeasures.includes('pa.customFields.thirdCountryTransfers.safetyMeasures.other')"
              commentable comment-subject-codename="pa-{{paId}}-custom-third-country-transfers-safety-measures-other"
              comment-subject-title="{{'pa.customFields.thirdCountryTransfers.safetyMeasuresOther.part1' | translate}} {{paName}} {{'pa.customFields.thirdCountryTransfers.safetyMeasuresOther.commentSubject.part2' | translate}}">
              <todoable subject="pa-{{paId}}-custom-third-country-transfers-safety-measures-other" suggested-title="VT-{{paId}}: "></todoable>
              <mat-form-field>
                <textarea matTextareaAutosize matInput rows="3" placeholder="{{'pa.customFields.thirdCountryTransfers.safetyMeasuresOther.description' | translate}}"
                   [readonly]="!canWrite" (change)="submitCustomField('thirdCountryTransfersSafetyMeasuresOther', $event.target.value)" [value]="pa.customFields.thirdCountryTransfersSafetyMeasuresOther"></textarea>

                <mat-hint [ngClass]="{'hidden': fieldsBeingSaved['customFieldsthirdCountryTransfersSafetyMeasuresOther']}" innerHTML="{{'pa.customFields.thirdCountryTransfers.safetyMeasuresOther.tip' | translate}}"></mat-hint>
                <mat-hint [ngClass]="{'active': fieldsBeingSaved['customFieldsthirdCountryTransfersSafetyMeasuresOther']}" align="end" class="saving">
                  {{'basicInfo.fields.saving' | translate}}
                </mat-hint>
              </mat-form-field>
            </div>

            <!-- additional guarantees-->
            <div commentable comment-subject-codename="pa-{{paId}}-custom-third-country-transfers-safety-guarantees"
              comment-subject-title="{{'pa.customFields.thirdCountryTransfers.safetyGuarantees.part1' | translate}} {{paName}} {{'pa.customFields.thirdCountryTransfers.safetyGuarantees.commentSubject.part2' | translate}}">
              <todoable subject="pa-{{paId}}-custom-third-country-transfers-safety-guarantees" suggested-title="VT-{{paId}}: "></todoable>
              <mat-form-field>
                <mat-select placeholder="{{'pa.customFields.thirdCountryTransfers.safetyGuarantees.description' | translate}}" multiple="false"
                  [disabled]="!canWrite" (selectionChange)="submitCustomField('thirdCountryTransfersSafetyGuarantees', $event.value)"
                  [value]="pa.customFields.thirdCountryTransfersSafetyGuarantees" multiple="true">
                  <mat-option [value]="'pa.customFields.thirdCountryTransfers.safetyGuarantees.encryption'">{{'pa.customFields.thirdCountryTransfers.safetyGuarantees.encryption' | translate}}</mat-option>
                  <mat-option [value]="'pa.customFields.thirdCountryTransfers.safetyGuarantees.other'">{{'pa.customFields.thirdCountryTransfers.safetyGuarantees.other' | translate}}</mat-option>
                </mat-select>

                <mat-hint [ngClass]="{'hidden': fieldsBeingSaved['customFieldsthirdCountryTransfersSafetyGuarantees']}" innerHTML="{{'pa.customFields.thirdCountryTransfers.safetyGuarantees.tip' | translate}}"></mat-hint>
                <mat-hint [ngClass]="{'active': fieldsBeingSaved['customFieldsthirdCountryTransfersSafetyGuarantees']}" align="end" class="saving">
                  {{'basicInfo.fields.saving' | translate}}
                </mat-hint>
              </mat-form-field>
            </div>

            <div *ngIf="pa.customFields.thirdCountryTransfersSafetyGuarantees && pa.customFields.thirdCountryTransfersSafetyGuarantees.includes('pa.customFields.thirdCountryTransfers.safetyGuarantees.other')"
              commentable comment-subject-codename="pa-{{paId}}-custom-third-country-transfers-safety-guarantees-other"
              comment-subject-title="{{'pa.customFields.thirdCountryTransfers.safetyGuaranteesOther.part1' | translate}} {{paName}} {{'pa.customFields.thirdCountryTransfers.safetyGuaranteesOther.commentSubject.part2' | translate}}">
              <todoable subject="pa-{{paId}}-custom-third-country-transfers-safety-guarantees-other" suggested-title="VT-{{paId}}: "></todoable>
              <mat-form-field>
                <textarea matTextareaAutosize matInput rows="3" placeholder="{{'pa.customFields.thirdCountryTransfers.safetyGuaranteesOther.description' | translate}}"
                   [readonly]="!canWrite" (change)="submitCustomField('thirdCountryTransfersSafetyGuaranteesOther', $event.target.value)" [value]="pa.customFields.thirdCountryTransfersSafetyGuaranteesOther"></textarea>

                <mat-hint [ngClass]="{'hidden': fieldsBeingSaved['customFieldsthirdCountryTransfersSafetyGuaranteesOther']}" innerHTML="{{'pa.customFields.thirdCountryTransfers.safetyGuaranteesOther.tip' | translate}}"></mat-hint>
                <mat-hint [ngClass]="{'active': fieldsBeingSaved['customFieldsthirdCountryTransfersSafetyGuaranteesOther']}" align="end" class="saving">
                  {{'basicInfo.fields.saving' | translate}}
                </mat-hint>
              </mat-form-field>
            </div>
          </ng-container>
        </ng-container>

      </feature-access-local>
    </ng-container>
    <!-- ================ CUSTOM FIELDS SECTION END ================ -->

    <div commentable comment-subject-codename="pa-{{paId}}-additional-remarks"
      comment-subject-title="{{'pa.additionalRemarks.commentSubject' | translate}} {{paName}} {{'pa.additionalRemarks.commentSubject.part2' | translate}}">
      <todoable subject="pa-{{paId}}-additional-remarks" suggested-title="VT-{{paId}}: "></todoable>
      <mat-form-field>
        <textarea matTextareaAutosize matInput rows="3" placeholder="{{'pa.additionalRemarks.description' | translate}}"
          [readonly]="!canWrite" (change)="submit('paAdditionalRemarks')"
          formControlName="paAdditionalRemarks"></textarea>
        <mat-hint [ngClass]="{'hidden': fieldsBeingSaved['paAdditionalRemarks']}"
          innerHTML="{{'pa.additionalRemarks.tip' | translate}}"></mat-hint>
        <mat-hint [ngClass]="{'active': fieldsBeingSaved['paAdditionalRemarks']}" align="end" class="saving">
          {{'basicInfo.fields.saving' | translate}}
        </mat-hint>
      </mat-form-field>
    </div>

    <br />


    <!---================ VENDORS SECTION ================-->

    <br><br>
    <mat-divider></mat-divider>
    <div commentable comment-subject-codename="pa-{{paId}}-processed-externally"
      comment-subject-title="{{'pa.processedExternally.commentSubject.part1' | translate}} {{paName}} {{'pa.processedExternally.commentSubject.part2' | translate}}">
      <todoable subject="pa-{{paId}}-processed-externally" suggested-title="VT-{{paId}}: "></todoable>
      <h2>
        {{ 'pa.details.sections.vendors.title' | translate }}
      </h2>
    </div>
    <mat-divider></mat-divider>
    <br><br>

    <p class="help-text">{{ 'pa.externalProcessors.tip' | translate }}</p>

    <div class="vendors">
      <div class="add-button">
        <button *ngIf="accessLevel && accessLevel.write" mat-flat-button color="primary" class="add-vendor" (click)="addVendor()">
          {{'pa.externalProcessors.addCompany' | translate}}
        </button>
      </div>

      <app-data-recipients [vendors]="vendorAssociations" [paId]="paId" [readonly]="!canWrite" (updated)="updateVendors($event)"></app-data-recipients>
    </div>

    <br><br><br><br>

    <!---================ APPLICATIONS SECTION ================-->
    <br><br>
    <mat-divider></mat-divider>
    <h2>
      {{ 'pa.details.sections.applications.title' | translate }}
    </h2>
    <mat-divider></mat-divider>
    <br><br>

    <p class="help-text">{{ 'pa.externalProcessors.applications.tip' | translate }}</p>

    <div class="applications">
      <div class="add-button">
        <button *ngIf="accessLevel && accessLevel.write" mat-flat-button color="primary" class="add-vendor" (click)="addApplication()">
          {{'pa.externalProcessors.addApplication' | translate}}
        </button>
      </div>

      <app-application-list [applications]="applications" [paId]="paId" [readonly]="!canWrite" (updated)="updateApplications()"></app-application-list>
    </div>
    <!---================ Risk Analysis SECTION ================-->

    <br><br>
    <mat-divider></mat-divider>
    <h2>
      {{'risk-analysis.title' | translate }}
    </h2>
    <mat-divider></mat-divider>
    <br><br>

    <ng-container *ngIf="!riskAnalysisAccess">
      <div class="no-dpia">
        <br><br>
        <mat-icon>cloud_off</mat-icon>
        <br><br>
        <p>
          {{'risk-analysis.no-access.hint' | translate}}
        </p>
        <br><br>
      </div>
    </ng-container>

    <ng-container *ngIf="riskAnalysisAccess">
      <div class="risk-analysis">
        <p class="hint">
          {{'risk-analysis.hint' | translate}}
        </p>

        <br><br>

        <div class="risk-analysis-chart">
          <div class="axis risk">
            <div class="label">{{'risk-analysis.chart.axis.risk' | translate}}</div>
            <div class="ranges">
              <div class="range" *ngFor="let range of riskRanges; index as ri">
                {{riskConstants.thresholdLabels[ri] | translate}}
              </div>
            </div>
          </div>
          <div class="axis severity">
            <div class="ranges">
              <div class="range" *ngFor="let range of severityRanges; index as si">
                {{severityConstants.thresholdLabels[si] | translate}}
              </div>
            </div>
            <div class="label">{{'risk-analysis.chart.axis.severity' | translate}}</div>
          </div>
          <div class="grid-row" *ngFor="let riskRange of riskRanges; index as ri">
            <div *ngFor="let severityRange of severityRanges; index as si" class="grid-cell {{riskAnalysisGridClass(ri, si)}}"
              [ngClass]="{'active':
                incidentProbability >= riskRange[0] && incidentProbability < riskRange[1] &&
                incidentSeverity >= severityRange[0] && incidentSeverity < severityRange[1]}">
              <div *ngIf="incidentProbability >= riskRange[0] && incidentProbability < riskRange[1] &&
                incidentSeverity >= severityRange[0] && incidentSeverity < severityRange[1]">
                <div>
                  <mat-icon>close</mat-icon><br>
                  {{'risk-analysis.chart.position-marker' | translate}}
                </div>
              </div>
            </div>
          </div>
        </div>

        <br>

        <div
            commentable
            comment-subject-codename="pa-{{paId}}-dpia-risk-severity"
            comment-subject-title="{{'risk-analysis.risk-severity.commentSubject' | translate}}: {{paName}}">
            <todoable subject="pa-{{paId}}-dpia-risk-severity" suggested-title="VT-{{paId}}: "></todoable>
          <div class="reasons">
          <div class="risk">
            <div class="contents">
              <p class="hint">
                {{'risk-analysis.risk.assessment-hint.part1' | translate}}
                <ng-container *ngFor="let riskRange of riskRanges; index as ri">
                  <b *ngIf="incidentProbability >= riskRange[0] && incidentProbability < riskRange[1]">
                    {{riskConstants.thresholdLabels[ri] | translate}}
                  </b>
                </ng-container>
                {{'risk-analysis.risk.assessment-hint.part2' | translate}}
              </p>
              <ul>
                <li *ngFor="let reason of incidentProbabilityReasons">
                  <p class="hint" *ngIf="reason.systematic">
                    {{reason.text | translate}}
                  </p>
                  <p class="hint" *ngIf="!reason.systematic">
                    {{reason.text}}
                  </p>
                </li>
              </ul>
            </div>
            <div class="controls">
              <button mat-icon-button (click)="openDpiaRiskDialog()"
                matTooltip="{{'risk-analysis.risk.modify-assessment' | translate}}">
                <mat-icon>edit</mat-icon>
              </button>
              <button mat-icon-button *ngIf="manualRiskAssessment" (click)="doAutoIncidentProb()"
                matTooltip="{{'risk-analysis.risk.automatic-assessment' | translate}}">
                <mat-icon>android</mat-icon>
              </button>
            </div>
          </div>

          <div class="severity">
            <div class="contents">
              <ng-container *ngIf="incidentSeverity == -1">
                <p class="hint">
                  {{'risk-analysis.severity.assessment-hint.no-assessment' | translate}}
                </p>
              </ng-container>
              <ng-container *ngIf="incidentSeverity != -1">
                <p class="hint">
                  {{'risk-analysis.severity.assessment-hint.part1' | translate}}
                  <ng-container *ngFor="let severityRange of severityRanges; index as si">
                    <b *ngIf="incidentSeverity >= severityRange[0] && incidentSeverity < severityRange[1]">
                      {{severityConstants.thresholdLabels[si] | translate}}
                    </b>
                  </ng-container>
                  {{'risk-analysis.severity.assessment-hint.part2' | translate}}
                </p>
                <ul>
                  <li *ngFor="let reason of incidentSeverityReasons">
                    <p class="hint" *ngIf="reason.systematic">
                      {{reason.text | translate}}
                    </p>
                    <p class="hint" *ngIf="!reason.systematic">
                      {{reason.text}}
                    </p>
                  </li>
                </ul>
              </ng-container>
            </div>
            <div class="controls" *ngIf="canWrite">
              <button mat-icon-button (click)="openDpiaSeverityDialog()"
                matTooltip="{{'risk-analysis.severity.modify-assessment' | translate}}">
                <mat-icon>edit</mat-icon>
              </button>
              <button mat-icon-button *ngIf="manualSeverityAssessment" (click)="doAutoSeverity()"
                matTooltip="{{'risk-analysis.severity.automatic-assessment' | translate}}">
                <mat-icon>android</mat-icon>
              </button>
            </div>
          </div>
        </div>
      </div>
      </div>



      <div
        commentable
        comment-subject-codename="pa-{{paId}}-dpia-additional-remakrs"
        comment-subject-title="{{'risk-analysis.additional-remarks.commentSubject' | translate}}: {{paName}}">
        <todoable subject="pa-{{paId}}-dpia-additional-remakrs" suggested-title="VT-{{paId}}: "></todoable>
        <mat-form-field>
          <textarea matInput placeholder="{{'risk-analysis.additional-remarks.placeholder' | translate}}" [readonly]="!canWrite"
            (change)="submit('paRiskAnalysisAdditionalRemarks')" formControlName="paRiskAnalysisAdditionalRemarks">
          </textarea>
          <mat-hint [ngClass]="{'hidden': fieldsBeingSaved['paRiskAnalysisAdditionalRemarks']}">
            {{'risk-analysis.additional-remarks.hint' | translate}}
          </mat-hint>
          <mat-hint [ngClass]="{'active': fieldsBeingSaved['paRiskAnalysisAdditionalRemarks']}" align="end" class="saving">
            {{'basicInfo.fields.saving' | translate}}
          </mat-hint>
        </mat-form-field>
      </div>

      <feature-access-local featureName="dpia">
        <ng-container>
          <!-- DPIA Necessity Assessment -->
          <ng-container *ngIf="pa">
            <div commentable comment-subject-codename="pa-{{paId}}-dpia-necessity"
              comment-subject-title="{{'dpia.necessity.commentSubject.part1' | translate}} VT-{{pa?.paId}} {{'dpia.necessity.commentSubject.part2' | translate}}"
              [comment-access-token]="providableAccessToken">
              <todoable subject="pa-{{paId}}-dpia-necessity"
                        suggested-title="VT-{{pa?.paId}}: "
                        [access-token]="providableAccessToken"></todoable>

              <section class="dpia-necessity">
                <mat-divider></mat-divider>
                <h2>{{ 'dpia.necessity.title' | translate }}</h2>
                <mat-divider></mat-divider>
                <br><br>

                <p class="help-text">{{ 'dpia.necessity.helpText' | translate }}</p>

                <div class="dpia-necessary-flow">
                  <div class="dpia-necessary-button">
                    <button mat-raised-button color="primary" (click)="openDpiaNecessityModal()" [disabled]="!canWrite">{{ 'dpia.necessity.openModal' | translate }}</button>
                  </div>

                  <mat-icon *ngIf="pa.paDpiaNecessity">arrow_downward</mat-icon>

                  <div class="dpia-necessary-result" *ngIf="pa.paDpiaNecessity">
                    <!-- dpia not necessary -->
                    <ng-container *ngIf="!dpiaNecessary">
                      <p>{{ 'dpia.necessity.dpiaNotNecessary.text' | translate }}</p>
                    </ng-container>

                    <!-- if dpia is necessary -->
                    <ng-container *ngIf="dpiaNecessary">
                      <p>{{ 'dpia.necessity.dpiaNecessary.text' | translate }}</p>
                    </ng-container>

                    <div class="dpia-supervisor-override" *ngIf="isSupervisor && dpiaNecessary">
                      <button title="{{ 'dpia.necessity.supervisorOverride' | translate }}" mat-button (click)="overrideNecessity()">{{ 'dpia.necessity.supervisorOverride.action' | translate }}</button>
                    </div>  
                  </div>

                  <mat-icon *ngIf="dpiaNecessary">arrow_downward</mat-icon>

                  <div class="dpia-necessary-action" *ngIf="dpiaNecessary">
                    <a *ngIf="!dpia" mat-raised-button color="primary" routerLink="/rpa/dpia/{{ paId }}/welcome">{{ 'dpia.necessity.dpiaNecessary.doDpia' | translate }}</a>
                    <a *ngIf="dpia" mat-raised-button color="primary" routerLink="/rpa/dpia/{{ paId }}/welcome">{{ 'pa.dpia.editDpia' | translate }}</a>
                  </div>

                </div>
              </section>
              <br><br>
            </div>
          </ng-container>


          <!-- DPIA Details Section (only when there is a DPIA) -->

          <div *ngIf="dpia" commentable comment-subject-codename="pa-{{paId}}-dpia"
            comment-subject-title="{{'pa.dpia.commentSubject.part1' | translate}} VT-{{pa?.paId}} {{'pa.dpia.commentSubject.part2' | translate}}"
            [comment-access-token]="providableAccessToken">
            <todoable subject="pa-{{paId}}-dpia"
                      suggested-title="VT-{{pa?.paId}}: "
                      [access-token]="providableAccessToken"></todoable>

            <section class="dpia-details">
              <mat-divider></mat-divider>
              <h2>{{ 'pa.dpia.title' | translate }}</h2>
              <mat-divider></mat-divider>
              <br><br>

              <div class="dpia-columns">
                <div class="dpia-details">
                  <!--<div class="dpia-detail"><label>{{'pa.dpia.status' | translate}}:</label> {{ ('dpia.assistant.status.' + dpia.status) | translate }}</div>-->
                  <div class="dpia-detail">
                    <label>{{'pa.dpia.proportionality' | translate}}: </label>
                    <ng-container *ngIf="!isDpiaProportionalityAnswered">{{'pa.dpia.proportionality.unanswered' | translate}}</ng-container>
                    <ng-container *ngIf="isDpiaProportionalityAnswered && isDpiaProportional">{{'pa.dpia.proportionality.true' | translate}}</ng-container>
                    <ng-container *ngIf="isDpiaProportionalityAnswered && !isDpiaProportional">{{'pa.dpia.proportionality.false' | translate}}</ng-container>
                  </div>
                  <a routerLink="/rpa/dpia/{{ paId }}/welcome">{{'pa.dpia.editDpia' | translate}}</a>
                </div>

                <a routerLink="/rpa/dpia/{{ paId }}/summary">
                  <div class="risk-matrix">
                    <div class="matrix">
                      <img id="dpia-matrix-before" [src]="dpiaMatrixUrlBefore" />
                      <label for="dpia-matrix-before">{{'dpia.assistant.summary.riskMatrix.before' | translate}}</label>
                    </div>
                    <mat-icon>chevron_right</mat-icon>
                    <div class="matrix">
                      <img id="dpia-matrix-after" [src]="dpiaMatrixUrlAfter" />
                      <label for="dpia-matrix-after">{{'dpia.assistant.summary.riskMatrix.after' | translate}}</label>
                    </div>
                  </div>
                </a>
              </div>
            </section>
          </div>
        </ng-container>
      </feature-access-local>


      <!---================ (LEGACY) Full DPIA Section ================-->
      <!-- Only show for legacy users (that use this version of DPIA) -->

      <section *ngIf="formGroup.get('paUseFullDpia') && formGroup.get('paUseFullDpia').value === true">
        <br><br>
        <mat-divider></mat-divider>
        <h2>
          {{'pa.fullDpia.title' | translate }}
        </h2>
        <mat-divider></mat-divider>
        <br><br>

        <div class="full-dpia">
          <p class="hint">
            {{'pa.fullDpia.hint' | translate}}
          </p>

          <ng-container >
            <div commentable comment-subject-codename="pa-{{paId}}-full-dpia-need"
              comment-subject-title="{{'pa.fullDpia.need.commentSubject' | translate}} {{paName}} {{'pa.fullDpia.need.commentSubject.part2' | translate}}">
              <todoable subject="pa-{{paId}}-full-dpia-need" suggested-title="VT-{{paId}}: "></todoable>
              <mat-form-field>
                <textarea matTextareaAutosize matInput rows="3" placeholder="{{'pa.fullDpia.need.description' | translate}}"
                  [readonly]="!canWrite" (change)="submit('paFullDpiaNeed')"
                  formControlName="paFullDpiaNeed"></textarea>
                <mat-hint [ngClass]="{'hidden': fieldsBeingSaved['paFullDpiaNeed']}"
                  innerHTML="{{'pa.fullDpia.need.tip' | translate}}"></mat-hint>
                <mat-hint [ngClass]="{'active': fieldsBeingSaved['paFullDpiaNeed']}" align="end" class="saving">
                  {{'basicInfo.fields.saving' | translate}}
                </mat-hint>
              </mat-form-field>
            </div>

            <div commentable comment-subject-codename="pa-{{paId}}-full-dpia-nature"
              comment-subject-title="{{'pa.fullDpia.nature.commentSubject' | translate}} {{paName}} {{'pa.fullDpia.nature.commentSubject.part2' | translate}}">
              <todoable subject="pa-{{paId}}-full-dpia-nature" suggested-title="VT-{{paId}}: "></todoable>
              <mat-form-field>
                <textarea matTextareaAutosize matInput rows="3" placeholder="{{'pa.fullDpia.nature.description' | translate}}"
                  [readonly]="!canWrite" (change)="submit('paFullDpiaNature')"
                  formControlName="paFullDpiaNature"></textarea>
                <mat-hint [ngClass]="{'hidden': fieldsBeingSaved['paFullDpiaNature']}"
                  innerHTML="{{'pa.fullDpia.nature.tip' | translate}}"></mat-hint>
                <mat-hint [ngClass]="{'active': fieldsBeingSaved['paFullDpiaNature']}" align="end" class="saving">
                  {{'basicInfo.fields.saving' | translate}}
                </mat-hint>
              </mat-form-field>
            </div>

            <div commentable comment-subject-codename="pa-{{paId}}-full-dpia-scope"
              comment-subject-title="{{'pa.fullDpia.scope.commentSubject' | translate}} {{paName}} {{'pa.fullDpia.scope.commentSubject.part2' | translate}}">
              <todoable subject="pa-{{paId}}-full-dpia-scope" suggested-title="VT-{{paId}}: "></todoable>
              <mat-form-field>
                <textarea matTextareaAutosize matInput rows="3" placeholder="{{'pa.fullDpia.scope.description' | translate}}"
                  [readonly]="!canWrite" (change)="submit('paFullDpiaScope')"
                  formControlName="paFullDpiaScope"></textarea>
                <mat-hint [ngClass]="{'hidden': fieldsBeingSaved['paFullDpiaScope']}"
                  innerHTML="{{'pa.fullDpia.scope.tip' | translate}}"></mat-hint>
                <mat-hint [ngClass]="{'active': fieldsBeingSaved['paFullDpiaScope']}" align="end" class="saving">
                  {{'basicInfo.fields.saving' | translate}}
                </mat-hint>
              </mat-form-field>
            </div>

            <div commentable comment-subject-codename="pa-{{paId}}-full-dpia-context"
              comment-subject-title="{{'pa.fullDpia.context.commentSubject' | translate}} {{paName}} {{'pa.fullDpia.context.commentSubject.part2' | translate}}">
              <todoable subject="pa-{{paId}}-full-dpia-context" suggested-title="VT-{{paId}}: "></todoable>
              <mat-form-field>
                <textarea matTextareaAutosize matInput rows="3" placeholder="{{'pa.fullDpia.context.description' | translate}}"
                  [readonly]="!canWrite" (change)="submit('paFullDpiaContext')"
                  formControlName="paFullDpiaContext"></textarea>
                <mat-hint [ngClass]="{'hidden': fieldsBeingSaved['paFullDpiaContext']}"
                  innerHTML="{{'pa.fullDpia.context.tip' | translate}}"></mat-hint>
                <mat-hint [ngClass]="{'active': fieldsBeingSaved['paFullDpiaContext']}" align="end" class="saving">
                  {{'basicInfo.fields.saving' | translate}}
                </mat-hint>
              </mat-form-field>
            </div>

            <div commentable comment-subject-codename="pa-{{paId}}-full-dpia-purposes"
              comment-subject-title="{{'pa.fullDpia.purposes.commentSubject' | translate}} {{paName}} {{'pa.fullDpia.purposes.commentSubject.part2' | translate}}">
              <todoable subject="pa-{{paId}}-full-dpia-purposes" suggested-title="VT-{{paId}}: "></todoable>
              <mat-form-field>
                <textarea matTextareaAutosize matInput rows="3" placeholder="{{'pa.fullDpia.purposes.description' | translate}}"
                  [readonly]="!canWrite" (change)="submit('paFullDpiaPurposes')"
                  formControlName="paFullDpiaPurposes"></textarea>
                <mat-hint [ngClass]="{'hidden': fieldsBeingSaved['paFullDpiaPurposes']}"
                  innerHTML="{{'pa.fullDpia.purposes.tip' | translate}}"></mat-hint>
                <mat-hint [ngClass]="{'active': fieldsBeingSaved['paFullDpiaPurposes']}" align="end" class="saving">
                  {{'basicInfo.fields.saving' | translate}}
                </mat-hint>
              </mat-form-field>
            </div>

            <div commentable comment-subject-codename="pa-{{paId}}-full-dpia-compliance"
              comment-subject-title="{{'pa.fullDpia.compliance.commentSubject' | translate}} {{paName}} {{'pa.fullDpia.compliance.commentSubject.part2' | translate}}">
              <todoable subject="pa-{{paId}}-full-dpia-compliance" suggested-title="VT-{{paId}}: "></todoable>
              <mat-form-field>
                <textarea matTextareaAutosize matInput rows="3" placeholder="{{'pa.fullDpia.compliance.description' | translate}}"
                  [readonly]="!canWrite" (change)="submit('paFullDpiaCompliance')"
                  formControlName="paFullDpiaCompliance"></textarea>
                <mat-hint [ngClass]="{'hidden': fieldsBeingSaved['paFullDpiaCompliance']}"
                  innerHTML="{{'pa.fullDpia.compliance.tip' | translate}}"></mat-hint>
                <mat-hint [ngClass]="{'active': fieldsBeingSaved['paFullDpiaCompliance']}" align="end" class="saving">
                  {{'basicInfo.fields.saving' | translate}}
                </mat-hint>
              </mat-form-field>
            </div>

            <!-- DPIA Risk & Measures Tables -->
            <div class="tables">
              <div class="risks-table">
                <h3>{{ 'pa.fullDpia.risks.title' | translate }}</h3>
                <p class="hint">{{ 'pa.fullDpia.risks.hint' | translate }}</p>
                <div class="list risks">
                  <div class="row header">
                    <span class="name description">
                      {{ 'pa.fullDpia.risks.header.description' | translate }}
                    </span>
                    <span class="color likelihood" title="{{ 'pa.fullDpia.risks.header.likelihood' | translate }}">
                      {{ 'pa.fullDpia.risks.header.likelihood' | translate }}
                    </span>
                    <span class="color severity" title="{{ 'pa.fullDpia.risks.header.severity' | translate }}">
                      {{ 'pa.fullDpia.risks.header.severity' | translate }}
                    </span>
                    <span class="color risk" title="{{ 'pa.fullDpia.risks.header.risk' | translate }}">
                      {{ 'pa.fullDpia.risks.header.risk' | translate }}
                    </span>
                    <span class="actions" title="{{ 'pa.fullDpia.risks.header.actions' | translate }}">
                      {{ 'pa.fullDpia.risks.header.actions' | translate }}
                    </span>
                  </div>
                  <div *ngFor="let risk of fullDpia.risks; index as idx" class="row item risk">
                    <span class="name description">
                      {{ risk.description }}
                    </span>
                    <span class="color likelihood">
                      <div *ngIf="risk.likelihood" class="dpia-box dpia-value" [ngClass]="getRiskColorClass(risk.likelihood)"
                        matTooltip="{{ ('pa.fullDpia.risks.likelihood.' + risk.likelihood) |  translate }}"></div>
                    </span>
                    <span class="color severity">
                      <div *ngIf="risk.severity" class="dpia-box dpia-value" [ngClass]="getRiskColorClass(risk.severity)"
                        matTooltip="{{ ('pa.fullDpia.risks.severity.' + risk.severity) |  translate }}"></div>
                    </span>
                    <span class="color risk">
                      <div *ngIf="risk.risk" class="dpia-box dpia-value" [ngClass]="getRiskColorClass(risk.risk)"
                        matTooltip="{{ ('pa.fullDpia.risks.risk.' + risk.risk) |  translate }}"></div>
                    </span>
                    <div class="actions">
                      <button mat-icon-button [disabled]="!canWrite" matTooltip="{{ 'pa.fullDpia.table.edit' | translate }}" (click)="editFullDpiaRisk(risk, idx)">
                        <mat-icon>edit</mat-icon>
                      </button>
                      <button mat-icon-button [disabled]="!canWrite" matTooltip="{{ 'pa.fullDpia.table.delete' | translate }}" (click)="removeFullDpiaRisk(idx)">
                        <mat-icon>delete</mat-icon>
                      </button>
                    </div>
                  </div>
                  <button mat-button class="add-vendor add-table-item" [disabled]="!canWrite" (click)="addFullDpiaRisk()">
                    <mat-icon color="primary">add_circle_outline</mat-icon>
                    <span>
                      {{'pa.fullDpia.risks.add' | translate}}
                    </span>
                  </button>
                </div>
              </div>
              <div class="measures-table">
                <h3>{{ 'pa.fullDpia.measures.title' | translate }}</h3>
                <p class="hint">{{ 'pa.fullDpia.measures.hint' | translate }}</p>
                <div class="list risks">
                  <div class="row header">
                    <span class="name description">
                      {{ 'pa.fullDpia.measures.header.description' | translate }}
                    </span>
                    <span class="color effect">
                      {{ 'pa.fullDpia.measures.header.effect' | translate }}
                    </span>
                    <span class="color residual">
                      {{ 'pa.fullDpia.measures.header.residual' | translate }}
                    </span>
                    <span class="actions">
                      {{ 'pa.fullDpia.risks.header.actions' | translate }}
                    </span>
                  </div>
                  <div *ngFor="let measure of fullDpia.measures; index as idx" class="row item measure">
                    <span class="name description">
                      <p class="measure-risk">{{ measure.risk }}</p>
                      <p class="measure-description">{{ measure.description }}</p>
                    </span>
                    <span class="color effect">
                      <div class="dpia-box dpia-value" [ngClass]="getMeasureColorClass(measure.effect)"
                        matTooltip="{{ ('pa.fullDpia.measures.effect.' + measure.effect) |  translate }}"></div>
                    </span>
                    <span class="color residual">
                      <div class="dpia-box dpia-value" [ngClass]="getRiskColorClass(measure.residualRisk)"
                        matTooltip="{{ ('pa.fullDpia.risks.risk.' + measure.residualRisk) |  translate }}"></div>
                    </span>
                    <div class="actions">
                      <button mat-icon-button [disabled]="!canWrite" matTooltip="{{ 'pa.fullDpia.table.edit' | translate }}" (click)="editFullDpiaMeasure(measure, idx)">
                        <mat-icon>edit</mat-icon>
                      </button>
                      <button mat-icon-button [disabled]="!canWrite" matTooltip="{{ 'pa.fullDpia.table.delete' | translate }}" (click)="removeFullDpiaMeasure(idx)">
                        <mat-icon>delete</mat-icon>
                      </button>
                    </div>
                  </div>
                  <button mat-button class="add-vendor add-table-item" [disabled]="!canWrite" (click)="addFullDpiaMeasure()">
                    <mat-icon color="primary">add_circle_outline</mat-icon>
                    <span>
                      {{'pa.fullDpia.measure.add' | translate}}
                    </span>
                  </button>
                </div>
              </div>
            </div>

          </ng-container>
        </div>
      </section>
    </ng-container>


    <!---================ XXXXXXXXXX ================--->

    <feature-access feature="document_management">
      <br><br>
      <mat-divider></mat-divider>
      <h2> {{'pa.documents.title' | translate }}</h2>
      <mat-divider></mat-divider>
      <br><br>
      <div commentable comment-subject-codename="pa-{{paId}}-documents"
        comment-subject-title="{{'pa.documents.commentSubject.part1' | translate }} {{paName}} {{'pa.documents.commentSubject.part2' | translate }}">
        <todoable subject="pa-{{paId}}-documents" suggested-title="VT-{{paId}}: "></todoable>
        <app-documents-container [upload-url]="uploadUrl" upload-key="document" [source]="documents"
          (documentChanged)="updateDocumentList()" [readonly]="!canWrite"></app-documents-container>
      </div>
    </feature-access>
    <br><br>
  </form>

  <div class="buttons">
    <button mat-button color="primary" routerLink="/rpa/applicable">
      {{'pa.list.backToList' | translate}}
    </button>
    <button mat-raised-button color="primary" (click)="done()">
      {{'word.done' | translate}}
    </button>
  </div>

  <br /><br /><br />
</div>

import { Injectable } from '@angular/core';

import {BusService} from '../bus.service';
import {EventsService} from '../events.service';
import { RequestService } from '../request.service';
import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

interface UpdateProfileRequest {
  displayName: string;
}

type SupervisorPlan = 'default' | 'lite' | 'pro' | 'trial';

export interface SupervisorProfile {
  id: number;
  supervisorId: number;
  displayName: string;
  logoPath: string;
  expireDate: Date;
  planName: SupervisorPlan;
  clients?: number;
}

@Injectable()
export class SupervisorProfileService {

  api = {
    root: '/supervision/supervisor/profile',
    users: '/users',
    role: '/role',
    invites: '/invites',
  };

  constructor(
    private bus: BusService,
    private events: EventsService,
    private requestService: RequestService
  ) {
    this.subscribe();
  }

  getProfile() {
    return this.requestService.get<any>({
      uri: this.api.root,
      handlers: {
        success: profile => this.bus.publish(this.events.received.data.supervision.supervisor.profile.success, profile),
        error: error => this.bus.publish(this.events.received.data.supervision.supervisor.profile.failure, error.error),
      }
    });
  }

  updateProfile(data: UpdateProfileRequest) {
    this.requestService.post<UpdateProfileRequest, void>({
      uri: this.api.root,
      body: data,
      handlers: {
        success: () => this.bus.publish(this.events.received.action.supervision.supervisor.profile.update.success),
        error: error => this.bus.publish(this.events.received.action.supervision.supervisor.profile.update.failure, error.error),
      }
    });
  }

  getUsers() {
    return this.requestService.get<any>({
      uri: this.api.root + this.api.users,
      handlers: {
        success: () => {},
        error: () => {},
      }
    });
  }

  removeUser(user) {
    return this.requestService.delete<any, any>({
      uri: this.api.root + this.api.users + '/' + user.id,
      handlers: {
        success: () => {},
        error: () => {},
      }
    });
  }

  setUserRole(user, role: 'admin' | 'supervisor') {
    return this.requestService.put<any, any>({
      uri: this.api.root + this.api.users + '/' + user.id + this.api.role,
      body: { role },
      handlers: {
        success: () => {},
        error: () => {},
      }
    })
  }

  getInvites() {
    return this.requestService.get<any>({
      uri: this.api.root + this.api.invites,
      handlers: {
        success() {},
        error() {},
      }
    });
  }

  invite(email: string) {
    return this.requestService.post<any, any>({
      uri: this.api.root + this.api.invites,
      body: { email },
      handlers: {
        success() {},
        error() {},
      }
    });
  }

  removeInvite(email: string) {
    return this.requestService.delete<any, any>({
      uri: this.api.root + this.api.invites + '/' + email,
      handlers: {
        success: () => {},
        error: () => {},
      }
    });
  }

  getInvite(token: string) {
    return this.requestService.get<any>({
      uri: this.api.root + this.api.invites + '/' + token,
      handlers: {
        success() {},
        error() {},
      }
    });
  }

  confirmInvite(token: string) {
    return this.requestService.put<any, any>({
      uri: this.api.root + this.api.invites + '/' + token,
      body: {
        status: 'confirmed',
      },
      handlers: {
        success() {},
        error() {},
      }
    });
  }

  rejectInvite(token: string) {
    return this.requestService.put<any, any>({
      uri: this.api.root + this.api.invites + '/' + token,
      body: {
        status: 'rejected',
      },
      handlers: {
        success() {},
        error() {},
      }
    });
  }

  isAccessExpired() {
    const expired = localStorage.getItem('supervision:expired');
    if (expired) {
      const parsed = JSON.parse(expired) as { expired: boolean; checked: Date };

      if (this.differenceInDays(new Date(parsed.checked), new Date()) <= 1) {
        return of(parsed.expired);
      }
    }

    return this.getProfile()
      .pipe(
        catchError(err => of(null)),
        map(profile => {
          if (profile && profile.expireDate) {
            const expired = new Date(profile.expireDate) < new Date();

            localStorage.setItem('supervision:expired', JSON.stringify({ expired, checked: new Date() }));

            return expired;
          }

          return false;
        })
      )
  }

  private differenceInDays(date1: Date, date2: Date) {
    const diffTime = Math.abs(date2.getTime() - date1.getTime() as any);
    return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  }

  subscribe() {
    this.bus.subscribe(this.events.requested.data.supervision.supervisor.profile, this.getProfile.bind(this));
    this.bus.subscribe(this.events.requested.action.supervision.supervisor.profile.update, this.updateProfile.bind(this));
  }
}

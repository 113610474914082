import {Component, OnInit} from '@angular/core';
import {Measure, MeasuresService} from "../../../services/measures.service";
import {MatDialog} from "@angular/material/dialog";
import {CreateMeasureDialogComponent} from "../create-measure-dialog/create-measure-dialog.component";
import {MatTableDataSource} from "@angular/material/table";
import {ActivatedRoute} from "@angular/router";
import {ImportMeasuresDialogComponent} from "../import-measures-dialog/import-measures-dialog.component";
import {ClientSupervisionService} from "../../../services/supervision/client-supervision.service"
import {TranslateService} from "@ngx-translate/core";
import {compareDate} from "../../../util/ordering";

@Component({
  selector: 'app-measures-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class MeasuresListComponent implements OnInit {

  measures: Measure[] = [];
  dataSource = new MatTableDataSource<Measure>();

  constructor(
    private measureService: MeasuresService,
    private dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private supervision: ClientSupervisionService,
    private translate: TranslateService
  ) { }

  get isSupervisor() {
    return this.supervision.isInSupervisionMode;
  }

  ngOnInit(): void {
    this.fetch();

    this.activatedRoute.queryParams.subscribe((params) => {
      if (params.measure) {
        this.measureService.getMeasure(params.measure)
          .subscribe((measure) => {
            this.showDetails(null, measure);
          });
      }
    });
  }

  showDetails(event: any, measure: Measure) {
    this.dialog.open(CreateMeasureDialogComponent, { width: '600px', data: { measure } })
      .afterClosed()
      .subscribe((updated: Measure) => {
        // if updated
        if (updated) {
          Object.assign(measure, updated);
        }
      });
  }

  update() {
    this.measureService.getMeasures().subscribe(measures => {
      this.measures = measures;
    });
  }

  fetch() {
    this.measureService.getMeasures().subscribe(measures => {
      this.measures = measures.sort((a, b) => compareDate(new Date(a.dueAt), new Date(b.dueAt), true));
    });
  }

  create() {
    this.dialog.open(CreateMeasureDialogComponent, { width: '600px' })
      .afterClosed()
      .subscribe((measure: Measure) => {
        if (measure) {
          this.measures.push(measure);
          this.measures = this.measures.slice();
        }
      });
  }

  import() {
    this.dialog.open(ImportMeasuresDialogComponent, { width: '600px' })
      .afterClosed()
      .subscribe((measures: Measure[]) => {
        if (measures) {
          this.measures = this.measures.concat(measures);
          this.measures = this.measures.slice();
        }
      });
  }

  get measuresDone() {
    return this.measures.filter(measure => measure.status === 'done');
  }

  get measuresNotDone() {
    return this.measures.filter(measure => measure.status !== 'done');
  }
}

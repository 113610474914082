import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-select-path-new-folder',
  templateUrl: './new-folder.component.html',
  styleUrls: ['./new-folder.component.scss']
})
export class SelectPathNewFolderComponent implements OnInit {

  folderName = new FormControl('', [Validators.required]);

  constructor(
    private dialogRef: MatDialogRef<SelectPathNewFolderComponent>
  ) { }

  ngOnInit(): void {
  }

  save() {
    this.dialogRef.close(this.folderName.value);
  }

}

<div class="contents">
  <h1 class="title">{{ 'measures.list.title' | translate }}</h1>
  <p>{{ 'measures.list.description' | translate }}</p>

  <div class="table-actions">
    <button *ngIf="isSupervisor" mat-button color="primary" (click)="import()">{{ 'measures.list.import' | translate }}</button>
    <button mat-flat-button color="primary" (click)="create()">{{ 'measures.list.create' | translate }}</button>
  </div>

  <h2>{{ 'measures.list.ongoingMeasures' | translate }}</h2>
  <app-measures-table [measures]="measuresNotDone" [showDoneAt]="false" (update)="update()"></app-measures-table>

  <p *ngIf="measures.length === 0" class="no-measures">{{ 'measures.list.empty' | translate }}</p>

  <br/>
  <br/>
  <br/>
  <h2>{{ 'measures.list.completedMeasures' | translate }}</h2>

  <app-measures-table [measures]="measuresDone" (update)="update()"></app-measures-table>
</div>

<div class="contents">
    <h1 class="title">
      {{ 'supervision.applications.title' | translate }}
    </h1>
    <br>
    <p>
      {{ 'supervision.applications.message' | translate }}
    </p>

    <ng-container *ngIf="applications.length > 0">
        <div class="applications">
            <a class="application" *ngFor="let application of applications" routerLink="/supervision/applications/{{ application.id }}">
                <div class="avatar">
                  <img *ngIf="application.logoUrl" [src]="application.logoUrl" onerror="this.onerror=null;this.src='/assets/default_vendor.svg';"/>
                  <mat-icon *ngIf="!application.logoUrl" class="no-logo">web_asset</mat-icon>
                </div>

                <div class="info">
                  <div class="title">
                    {{application.name}}
                  </div>
                  <div class="vendor-name">
                    {{ application.vendor.name }}
                  </div>
                </div>
            </a>
        </div>
    </ng-container>
    <div class="empty" *ngIf="applications.length === 0 && !pending">
      <img src="/assets/empty.svg" />
      <p>
        {{'supervision.applications.empty' | translate}}
      </p>
    </div>
</div>

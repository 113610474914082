<div class="actions-metadata-info metadata-rpa">
  <!-- Plain PA -->

  <ng-container *ngIf="event.eventName === 'ASSIGN'">
    <ng-container *ngIf="!pa || !department">
      <div class="event-loading metadata-center">
        <mat-icon>bar_chart</mat-icon>
        <span>{{ 'events.metadata.loading' | translate }}</span>
      </div>
    </ng-container>
    <ng-container *ngIf="pa && department">
      <div class="event-left">
        <span class="specimen">{{ department.name }}</span>
        <span class="type">
          {{ 'events.metadata.rpa.department' | translate }}
        </span>
      </div>
      <div class="event-action">
        <span class="icon">
          <mat-icon>link</mat-icon>
        </span>
        <span class="action">
          {{ 'events.metadata.rpa.linked' | translate }}
        </span>
      </div>
      <div class="event-item">
        <span class="specimen">{{ pa.paName }}</span>
        <span class="type">
          {{ 'events.metadata.rpa.pa' | translate }}
        </span>
      </div>
    </ng-container>
  </ng-container>
  <ng-container *ngIf="event.eventName === 'UPDATE_STATUS'">
    <ng-container *ngIf="!pa">
      <div class="event-loading metadata-center">
        <mat-icon>bar_chart</mat-icon>
        <span>{{ 'events.metadata.loading' | translate }}</span>
      </div>
    </ng-container>
    <ng-container *ngIf="pa">
      <div class="event-left">
        <span class="specimen">{{ pa.paName }}</span>
        <span class="type">
          {{ 'events.metadata.rpa.pa' | translate }}
        </span>
      </div>
      <div class="event-action">
        <span class="icon">
          <mat-icon>update</mat-icon>
        </span>
        <span class="action">
          {{ 'events.metadata.rpa.edited' | translate }}
        </span>
      </div>
    </ng-container>
  </ng-container>
  <ng-container *ngIf="event.eventName === 'UPDATE_FIELD'">
    <ng-container *ngIf="!pa">
      <div class="event-loading metadata-center">
        <mat-icon>bar_chart</mat-icon>
        <span>{{ 'events.metadata.loading' | translate }}</span>
      </div>
    </ng-container>
    <ng-container *ngIf="pa">
      <div class="event-left">
        <span class="specimen">{{ pa.paName }}</span>
        <span class="type">
          {{ 'events.metadata.rpa.pa' | translate }}
        </span>
      </div>
      <div class="event-action">
        <span class="icon">
          <mat-icon>update</mat-icon>
        </span>
        <span class="action">
          {{ 'events.metadata.rpa.edited' | translate }}
        </span>
      </div>
    </ng-container>
  </ng-container>

  <!-- Custom PA -->

  <ng-container *ngIf="event.eventName === 'CUSTOM.ADD'">
    <ng-container *ngIf="!pa">
      <div class="event-loading metadata-center">
        <mat-icon>bar_chart</mat-icon>
        <span>{{ 'events.metadata.loading' | translate }}</span>
      </div>
    </ng-container>
    <ng-container *ngIf="pa">
      <div class="event-left">
        <span class="specimen">{{ pa.paName }}</span>
        <span class="type">
          {{ 'events.metadata.rpa.customPa' | translate }}
        </span>
      </div>
      <div class="event-action">
        <span class="icon">
          <mat-icon>add</mat-icon>
        </span>
        <span class="action">
          {{ 'events.metadata.rpa.created' | translate }}
        </span>
      </div>
    </ng-container>
  </ng-container>

  <ng-container *ngIf="event.eventName === 'CUSTOM.UPDATE'">
    <ng-container *ngIf="!pa">
      <div class="event-loading metadata-center">
        <mat-icon>bar_chart</mat-icon>
        <span>{{ 'events.metadata.loading' | translate }}</span>
      </div>
    </ng-container>
    <ng-container *ngIf="pa">
      <div class="event-left">
        <span class="specimen">{{ pa.paName }}</span>
        <span class="type">
          {{ 'events.metadata.rpa.customPa' | translate }}
        </span>
      </div>
      <div class="event-action">
        <span class="icon">
          <mat-icon>update</mat-icon>
        </span>
        <span class="action">
          {{ 'events.metadata.rpa.edited' | translate }}
        </span>
      </div>
    </ng-container>
  </ng-container>
</div>

import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SupervisorProfileService } from 'app/services/supervision/supervisor-profile.service';

@Component({
  selector: 'app-revoke-supervisor-invite-dialog',
  templateUrl: './revoke-supervisor-invite-dialog.component.html',
  styleUrls: ['./revoke-supervisor-invite-dialog.component.scss']
})
export class RevokeSupervisorInviteDialogComponent implements OnInit {

  readonly = false;
  invite: any;
  inviter: any;
  users: any[];

  constructor(
    @Inject(MAT_DIALOG_DATA) data,
    private service: SupervisorProfileService,
    private dialogRef: MatDialogRef<RevokeSupervisorInviteDialogComponent>,
  ) {
    this.readonly = data.readonly;
    this.invite = data.invite;
    this.users = data.users;
  }

  ngOnInit(): void {
    if (this.invite.inviter) {
      this.inviter = this.users.find(u => u.id === this.invite.inviter);
    }
  }

  revoke() {
    this.service.removeInvite(this.invite.email).subscribe(() => this.dialogRef.close());
  }
}

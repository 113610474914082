<ng-container>
  <img mat-card-image src="assets/not-found.svg"/>
  <h1 mat-dialog-title>Registration Failed</h1>
  <mat-dialog-content *ngIf="data.reason == 'internal'">
    {{ 'registration.dialog.errorInternal' | translate }}
  </mat-dialog-content>
  <mat-dialog-content *ngIf="data.reason == 'bad-data'">
    {{ 'registration.dialog.errorData' | translate }}
  </mat-dialog-content>
  <mat-dialog-content *ngIf="data.reason == 'free-email'">
    {{ 'registration.dialog.errorNotCorporateEmail' | translate }}
  </mat-dialog-content>
</ng-container>

<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>{{ 'word.close' | translate }}</button>
</mat-dialog-actions>

<table mat-table matSort [dataSource]="dataSource">
  <ng-container matColumnDef="metadata">
    <mat-header-cell *matHeaderCellDef mat-sort-header="metadata">{{ 'data-recipients.table.name' | translate }}</mat-header-cell>
    <mat-cell *matCellDef="let element">
      <div class="logo">
        <img *ngIf="element.logoUrl" [src]="element.logoUrl" />
        <mat-icon *ngIf="!element.logoUrl" class="no-logo">location_city</mat-icon>
      </div>
      <div class="name">
        <span class="vendor-name">{{ element.name }}</span>
        <span *ngIf="element.type === 'vendor'" class="vendor-country">{{ element.addressCountry }}</span>
        <span *ngIf="element.type === 'other'" class="vendor-country">{{ 'data-recipients.vendor.typeOther' | translate }}</span>
      </div>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="processingStart">
    <mat-header-cell *matHeaderCellDef mat-sort-header="start">{{ 'data-recipients.table.start' | translate }}</mat-header-cell>
    <mat-cell *matCellDef="let element">
      {{ element.contractStart | localizedDate }}
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="processingEnd">
    <mat-header-cell *matHeaderCellDef mat-sort-header="end">{{ 'data-recipients.table.end' | translate }}</mat-header-cell>
    <mat-cell *matCellDef="let element">
      {{ element.contractEnd | localizedDate }}
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="countPas">
    <mat-header-cell *matHeaderCellDef mat-sort-header="paCount">{{ 'data-recipients.table.paCount' | translate }}</mat-header-cell>
    <mat-cell *matCellDef="let element">
      {{ element.paCount }}
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="auditScore">
    <mat-header-cell *matHeaderCellDef mat-sort-header="score">{{ 'data-recipients.table.score' | translate }}</mat-header-cell>
    <mat-cell *matCellDef="let element">
      <div class="vendor-status-item risk">
        <span class="risk-indicator {{ auditOverallRisk(element) }}" matTooltip="{{ 'vendor.audit.preview.overallRating' | translate }}"></span>
        <div class="vendor-status-divider" [ngClass]="{'visible': element.answers && auditOverallRisk(element) !== 'unknown' }"></div>
      </div>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="hasDpa">
    <mat-header-cell *matHeaderCellDef mat-sort-header="dpa">{{ 'data-recipients.table.dpa' | translate }}</mat-header-cell>
    <mat-cell *matCellDef="let element">
      <div class="status-item">
        <mat-icon *ngIf="element.docPath || element.notRequired" class="done icon">check</mat-icon>
        <mat-icon *ngIf="!element.docPath && !element.notRequired" class="done icon">close</mat-icon>
      </div>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="dpaSigned">
    <mat-header-cell *matHeaderCellDef mat-sort-header="signed">{{ 'data-recipients.table.dpaSigned' | translate }}</mat-header-cell>
    <mat-cell *matCellDef="let element">
      <div class="status-item">
        <mat-icon *ngIf="element.signed" class="done icon">check</mat-icon>
        <mat-icon *ngIf="!element.signed" class="done icon">close</mat-icon>
      </div>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="transfers">
    <mat-header-cell *matHeaderCellDef mat-sort-header="transfers">{{ 'data-recipients.table.hasTransfers' | translate }}</mat-header-cell>
    <mat-cell *matCellDef="let element">
      <ng-container *ngIf="element.answers && element.answers['_third_country_transfers_applies'] !== null">
        <div class="audit-indicator"
          matTooltip="{{ (element.answers['_third_country_transfers_applies'] == 'true' ? 'vendor.audit.preview.thirdCountryTransfers.yes' : 'vendor.audit.preview.thirdCountryTransfers.no') | translate }}">

          <mat-icon *ngIf="element.answers['_third_country_transfers_applies'] == 'true'" class="icon">adjust</mat-icon>
          <mat-icon *ngIf="element.answers['_third_country_transfers_applies'] == 'false'" class="icon">close</mat-icon>
          <span></span>
        </div>
      </ng-container>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="deleteAction">
    <mat-header-cell *matHeaderCellDef></mat-header-cell>
    <mat-cell *matCellDef="let element">
      <button *ngIf="!readonly" mat-icon-button color="primary" matTooltip="{{ 'word.unlink' | translate }}" (click)="removeAction($event, element)">
        <mat-icon>link_off</mat-icon>
      </button>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="comments">
    <mat-header-cell *matHeaderCellDef></mat-header-cell>
    <mat-cell *matCellDef="let element">
      <span class="comment-container">
        <div commentable
          comment-subject-codename="vendor-{{element.vendorId}}-dpa"
          comment-subject-title="{{'vendors.list.commentSubject.part1' | translate}} {{element.name}} {{'vendors.list.commentSubject.part2' | translate}}">
          <todoable subject="vendor-{{element.vendorId}}-dpa" suggested-title="{{'vendors.list.commentSubject.part1' | translate}} {{element.name}} {{'vendors.list.commentSubject.part2' | translate}}"></todoable>
        </div>
      </span>
    </mat-cell>
  </ng-container>


  <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
  <mat-row *matRowDef="let row; columns: displayedColumns;" matRipple class="element-row" [class.other]="row.type==='other'"
    [cdkDetailRow]="row" [cdkDetailRowTpl]="detailsTemplate" [cdkDetailRowEnable]="row.type === 'vendor'"></mat-row>
</table>


<ng-template #detailsTemplate let-element>
  <div class="mat-row detail-row" [@detailExpand] style="overflow: hidden">
    <button mat-icon-button class="button-overlay-close">
      <mat-icon class="overlay-close">close</mat-icon>
    </button>
    <div class="vendor-details">
      <div class="contact-details">
        <h4 class="title">{{ 'data-recipients.recipient.details' | translate }}</h4>
        <div class="email"><a href="mailto:{{ element.email }}">{{ element.email }}</a></div>
        <div class="address" *ngIf="addressComplete(element)">
          <span class="street">{{ element.addressStreet }}</span>
          <br/>
          <span class="city">{{ element.addressCity }} {{ element.addressPostalCode }}</span>
          <br/>
          <span class="country">{{ element.addressCountry }}</span>
        </div>
        <feature-access-local featureName="vendor-library" [clientOnly]="false">
          <button mat-stroked-button color="primary" [disabled]="readonly" (click)="editVendor(element)">{{ 'data-recipients.recipient.editContact' | translate }}</button>
        </feature-access-local>
      </div>
      <!-- <div class="processing-activities">
        <app-recipient-processing-activities [vendor]="vendorId"></app-recipient-processing-activities>
      </div> -->
      <div class="dpa-details">
        <h4 class="title">{{ 'data-recipients.recipient.dpa' | translate }}</h4>
        <app-recipient-dpa [vendor]="element" [readonly]="readonly"></app-recipient-dpa>
      </div>
    </div>
    <mat-divider></mat-divider>
    <div class="vendor-actions">
      <a [disabled]="readonly" routerLink="/vendors/{{ element.vendorId  }}/audit" mat-stroked-button color="primary">{{ 'data-recipients.recipient.auditAction' | translate }}</a>
      <button [disabled]="readonly" mat-stroked-button color="warn" (click)="removeAction($event, element)">{{ 'data-recipients.recipient.removeVendor' | translate }}</button>
    </div>
	</div>
</ng-template>

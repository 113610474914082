import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
  ChangeDetectorRef
} from '@angular/core';
import { FileSystemFileEntry, NgxFileDropEntry } from 'ngx-file-drop';

import { AppConfig } from '../../app.config';
import { UploadService } from '../../services/upload.service';
import { HttpEvent, HttpResponse } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { zip } from 'rxjs';
import { DialogComponent } from '../dialog/dialog.component';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'cool-upload',
  templateUrl: './cool-upload.component.html',
  styleUrls: ['./cool-upload.component.scss']
})
export class CoolUploadComponent implements OnInit {
  @Input() fileLink: string = undefined;
  @Input() readonly = false;
  @Input() preview = false;
  @Input() multiple = false;
  @Input() small = false;
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('upload-url') uploadUrl: string;
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('upload-key') uploadKey = 'file';
  @Output() pick: EventEmitter<File | Array<File>> = new EventEmitter<
    File | Array<File>
  >();
  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() progress: EventEmitter<number> = new EventEmitter<number>();
  @Output() uploaded: EventEmitter<File | Array<File>> = new EventEmitter<
    File | Array<File>
  >();
  @Output() uploadedResponse: EventEmitter<HttpEvent<any>> = new EventEmitter<
    HttpEvent<any>
  >();

  @ViewChild('preview') imagePreview: ElementRef;
  @ViewChild('download') downloadLink: ElementRef;
  @ViewChild('input', { static: true }) inputItem: ElementRef;

  public loaded = false;
  public filePicked = false;
  public uploading = false;
  public uploadProgress = 0;

  constructor(
    private cdr: ChangeDetectorRef,
    private uploader: UploadService,
    private dialog: MatDialog,
    private translator: TranslateService
  ) {}

  ngOnInit() {
    if (this.fileLink) {
      this.load(this.fileLink);
    }
    this.inputItem.nativeElement.multiple = this.multiple;
  }

  public get config() {
    return AppConfig;
  }

  public dropped(event: NgxFileDropEntry[]) {
    if (this.readonly) return;

    const files = event;

    if (files.length > 0) {
      if (files[0].fileEntry.isFile) {
        this.filePicked = true;
        if (this.multiple) {
          const fileEntries = files.map(
            fe => fe.fileEntry
          ) as FileSystemFileEntry[];
          fileEntries.map(fe => fe.file(file => this.pickFile(file)));
        } else {
          const fileEntry = files[0].fileEntry as any;
          fileEntry.file((file: File) => this.pickFile(file));
        }
      }
    }
  }

  public selected(event) {
    if (this.readonly) return;

    const files: FileList = event.target.files;
    if (files.length > 0) {
      this.filePicked = true;
      if (this.multiple) {
        this.toFileArray(files).map(file => this.pickFile(file));
      } else {
        this.pickFile(files[0]);
      }
    }
  }

  public pickFile(file: File) {
    this.pick.emit(file);
    this.loadPreview(file);

    if (this.uploadUrl) {
      this.uploading = true;
      this.cdr.detectChanges();
      this.uploader
        .upload(
          this.uploadUrl,
          this.uploadKey,
          file,
          (loaded: number, total: number) => {
            this.uploadProgress = (loaded * 1.0) / total;
            this.cdr.detectChanges();
            this.progress.emit(this.uploadProgress);
          }
        )
        .subscribe(response => {
          setTimeout(() => {
            this.uploading = false;
            this.cdr.detectChanges();
            this.uploaded.emit(file);
            this.uploadedResponse.emit(response);
          }, 500);
        });
    } else {
      this.uploadProgress = 1;
    }
  }

  public loadPreview(file: File) {
    if (this.preview) {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        this.imagePreview.nativeElement.src = e.target.result;
      };
      reader.readAsDataURL(file);
    }
  }

  public clear() {
    if (this.uploadUrl && this.loaded) {
      zip(
        this.translator.get('upload.delete.title'),
        this.translator.get('upload.delete.message')
      ).subscribe(([header, message]) => {
        this.dialog.open(DialogComponent, { width: '400px', data: { header, message, confirm: true } })
          .afterClosed()
          .subscribe(result => {
            if (result) {
              // we need to delete the file from the server if possible
              this.uploader.delete(this.uploadUrl)
              .subscribe((success) => {
                // everything went well
              }, (error) => {
                // for some routes, we can't delete files (imagine documents, for example)
                // so we'll just fail silently
              });
            }
          });
      });
    }

    this.filePicked = false;
    this.uploadProgress = 0;
    this.loaded = false;
  }

  public load(url) {
    this.loaded = true;
    this.filePicked = true;
    this.uploadProgress = 1;
    this.cdr.detectChanges();
    if (this.preview) {
      this.imagePreview.nativeElement.src = url;
    } else {
      this.downloadLink.nativeElement.href = url;
    }
  }

  private toFileArray(fileList: FileList): Array<File> {
    const array = [];
    // eslint-disable-next-line @typescript-eslint/prefer-for-of
    for (let i = 0; i < fileList.length; i++) {
      const file = fileList[i];
      array.push(file);
    }

    return array;
  }
}

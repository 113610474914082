<h1 mat-dialog-title>
  {{'supervision.supervisorProfile.editInvite.title.part1' | translate}}
  {{invite.email}}
  {{'supervision.supervisorProfile.editInvite.title.part2' | translate}}
</h1>

<mat-dialog-content>
  <div *ngIf="inviter">
    <b>
      {{'supervision.supervisorProfile.editInvite.labels.inviter' | translate}}:
    </b> {{ inviter.firstName }} {{ inviter.lastName }}
  </div>
  <div>
    <b>
      {{'supervision.supervisorProfile.editInvite.labels.date' | translate}}:
    </b> {{invite.createdAt | date}}
  </div>

  <br>
  <mat-divider></mat-divider>
  <br>

  <div>
    {{'supervision.supervisorProfile.editInvite.hint' | translate}}
  </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button color="warn" *ngIf="!readonly" tabindex="-1" (click)="revoke()">
    {{'supervision.supervisorProfile.editInvite.actions.revoke' | translate}}
  </button>
  <button mat-flat-button color="primary" mat-dialog-close tabindex="-1">{{'word.done' | translate}}</button>
</mat-dialog-actions>
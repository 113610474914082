import { Component, OnDestroy, OnInit } from '@angular/core';
import { Todo } from './todo.interface';
import { TodoSearchCriteria, TodoService } from '../../services/todos/todo.service';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, map, switchMap, tap } from 'rxjs/operators';

@Component({
  selector: 'app-todos',
  templateUrl: './todos.component.html',
  styleUrls: ['./todos.component.scss']
})
export class TodosComponent implements OnInit, OnDestroy {

  todosList: Todo[] = [];
  assigned: Todo[] = [];
  showIssuer: (todo: Todo) => boolean;

  todoCriteria: TodoSearchCriteria;
  assignedCriteria: TodoSearchCriteria;

  fetchTodos = new Subject<TodoSearchCriteria>();
  fetchAssigned = new Subject<TodoSearchCriteria>();
  subs = new Subscription();

  constructor(
    private todoService: TodoService,
  ) {
  }

  ngOnInit(): void {
    this.subs.add(
      this.fetchTodos.pipe(
        tap(criteria => this.todoCriteria = criteria),
        debounceTime(0),
        switchMap(criteria => this.todoService.fetchTodos(criteria)),
        this.todoService.polish,
      ).subscribe(todos => this.todosList = todos as Todo[])
    );

    this.subs.add(
      this.fetchAssigned.pipe(
        tap(criteria => this.assignedCriteria = criteria),
        debounceTime(0),
        switchMap(criteria => this.todoService.getAssigned(criteria)),
        map(response => response.todos),
        this.todoService.polish,
      ).subscribe(todos => this.todosList = todos as Todo[])
    );
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

}

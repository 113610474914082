<div class="actions-metadata-info metadata-department">
  <ng-container *ngIf="event.eventName === 'ADD'">
    <ng-container *ngIf="!department">
      <div class="event-loading metadata-center">
        <mat-icon>bar_chart</mat-icon>
        <span>{{ 'events.metadata.loading' | translate }}</span>
      </div>
    </ng-container>
    <ng-container *ngIf="department">
      <div class="event-left">
        <span class="specimen">{{ department.name }}</span>
        <span class="type">
          {{ 'events.metadata.rpa.department' | translate }}
        </span>
      </div>
      <div class="event-action">
        <span class="icon">
          <mat-icon>add</mat-icon>
        </span>
        <span class="action">
          {{ 'events.metadata.rpa.created' | translate }}
        </span>
      </div>
    </ng-container>
  </ng-container>
  <ng-container *ngIf="event.eventName === 'UPDATE'">
    <ng-container *ngIf="!department">
      <div class="event-loading metadata-center">
        <mat-icon>bar_chart</mat-icon>
        <span>{{ 'events.metadata.loading' | translate }}</span>
      </div>
    </ng-container>
    <ng-container *ngIf="department">
      <div class="event-left">
        <span class="specimen">{{ department.name }}</span>
        <span class="type">
          {{ 'events.metadata.rpa.department' | translate }}
        </span>
      </div>
      <div class="event-action">
        <span class="icon">
          <mat-icon>update</mat-icon>
        </span>
        <span class="action">
          {{ 'events.metadata.rpa.edited' | translate }}
        </span>
      </div>
    </ng-container>
  </ng-container>
</div>

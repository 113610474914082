<div class="container" [ngClass]="{ expanded: expanded }">
  <div class="top" (click)="expanded = !expanded">
    <div class="title">
      <mat-icon class="expand">expand_more</mat-icon>
      {{node.title}}
    </div>
    <div class="description" *ngIf="expanded">{{node.description}}</div>
  </div>
  <div class="children">
    <starter-pa-tree-node *ngFor="let child of node.children"
      [node]="child"
      [actionStyle]="actionStyle"
      [action]="action"
      [toggleAction]="toggleAction"
      (picked)="pick($event)"
      (unpicked)="unpick($event)">
    </starter-pa-tree-node>
    <starter-pa *ngFor="let pa of node.pas" [pa]="pa"
      [actionStyle]="actionStyle"
      [action]="action"
      [toggleAction]="toggleAction"
      (picked)="pick($event)"
      (unpicked)="unpick($event)">
    </starter-pa>
  </div>
</div>
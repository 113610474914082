<div class="container">
  <div class="dpa-upload">
    <ng-container *ngIf="vendor.offeredDocPath && !vendor.docPath  && !vendor.notRequired">
      <div class="upload-holder">
        <cool-upload #offered [readonly]="true" [small]="true"></cool-upload>
      </div>
    </ng-container>

    <div class="upload-holder" *ngIf="!vendor.notRequired">
      <cool-upload #upload
                   [readonly]="readonly"
                   (uploaded)="dpaUploaded()"
                   upload-url="/vendors/{{vendor.vendorId}}/dpa/"
                   upload-key="doc"
                   [small]="true">
        <span hint>
          {{'vendors.dpas.dialog.uploadHint' | translate}}
        </span>
      </cool-upload>
    </div>
  </div>

  <div class="dpa-settings">
    <ng-container *ngIf="noDpa">
      <div class="message">
        <mat-slide-toggle [disabled]="readonly" color="primary"
                          [(ngModel)]="vendor.notRequired" (change)="toggleRequired()"
                          title="{{ 'vendors.dpas.dialog.notRequired.toggle.part1' | translate}}">
          {{'vendors.dpas.dialog.notRequired.toggle.part1' | translate}} {{'vendors.dpas.dialog.notRequired.toggle.part2' | translate}}
        </mat-slide-toggle>
      </div>
    </ng-container>

    <ng-container *ngIf="!noDpa">
      <mat-checkbox [checked]="vendor.signed" [disabled]="readonly" color="primary" (change)="toggleVendorSigned()">
        {{'vendors.dpas.dialog.vendorSigned' | translate}}
      </mat-checkbox>

      <div class="contract-dates">
        <mat-form-field>
          <input matInput (dateChange)="updateDpa()" [matDatepicker]="datePickerStart" [formControl]="contractStart" placeholder="{{'vendors.dpa.contractStart.placeholder' | translate}}">
          <mat-datepicker-toggle matSuffix [for]="datePickerStart"></mat-datepicker-toggle>
          <mat-datepicker #datePickerStart></mat-datepicker>
        </mat-form-field>

        <mat-form-field>
          <input matInput (dateChange)="updateDpa()" [matDatepicker]="datePickerEnd" [formControl]="contractEnd" placeholder="{{'vendors.dpa.contractEnd.placeholder' | translate}}">
          <mat-datepicker-toggle matSuffix [for]="datePickerEnd"></mat-datepicker-toggle>
          <mat-datepicker #datePickerEnd></mat-datepicker>
        </mat-form-field>
      </div>
    </ng-container>
  </div>
</div>


import { Component, OnInit } from '@angular/core';
import { MixpanelService } from '../../../../modules/mixpanel/mixpanel.service';
import { Document, DocumentResourceService } from '../../../../services/documents/document-resource.service';

@Component({
  selector: 'app-supervisor-documents-admin-list',
  templateUrl: './admin-list.component.html',
  styleUrls: ['./admin-list.component.scss']
})
export class SupervisorDocumentsAdminListComponent implements OnInit {
  documents: Document[] = [];

  constructor(
    private documentService: DocumentResourceService,
    private mixpanel: MixpanelService
    ) { }

  ngOnInit(
  ): void {
    this.updateList();
  }

  get uploadUrl() {
    return `/document/supervisor/file`;
  }

  updateList(e: { document: Document; change: 'created' | 'updated' | 'deleted' } = null) {
    if (e && e.change === 'created') {
      this.mixpanel.track('Supervisor document uploaded');
    }

    this.documentService.getAllSupervisor().subscribe(documents => {
      this.documents = documents;
    });
  }

}

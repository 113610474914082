import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subject, Observable, combineLatest } from 'rxjs';

import { BusService } from 'app/services/bus.service';
import { EventsService } from 'app/services/events.service';
import { StripeService } from 'ngx-stripe';
import { environment } from 'environments/environment';

import { AdditionalAuthenticationDialogComponent }
  from './additional-authentication-dialog/additional-authentication-dialog.component';

@Component({
  selector: 'app-payment-additional-authentication',
  templateUrl: './additional-authentication.component.html',
  styleUrls: ['./additional-authentication.component.scss']
})
export class AdditionalPaymentAuthenticationComponent implements OnInit, OnDestroy {
  latestInfo;
  currentPaymentIntent;

  latestInfoReceived = new Subject();
  currentPaymentIntentReceived = new Subject();

  noLatestInfo = false;
  noCurrentPaymentIntent = false;

  paymentIncomplete = true;
  waitingForVerification = false;

  hasPaymentError = false;
  hasSourceError = false;

  constructor(
    private bus: BusService,
    private events: EventsService,
    private stripe: StripeService,
    private dialog: MatDialog,
  ) {
    combineLatest(
      this.latestInfoReceived,
      this.currentPaymentIntentReceived
    ).subscribe(() => {
      if (this.currentPaymentIntent && this.paymentIncomplete && !this.interruptedConfirmation)
        this.completePayment();
      else {
        if (this.interruptedConfirmation)
          this.openDialog('interrupted-confirmation');
        else if (this.hasSourceError || this.hasPaymentError)
          this.openDialog('source-error');
      }
    });
  }

  get interruptedConfirmation(): boolean {
    return localStorage.getItem('ongoing-stripe-confirmation') === 'true';
  }

  get isLoading() {
    return !this.hasError && (!this.latestInfo || !this.currentPaymentIntent);
  }

  get hasError() {
    return this.noLatestInfo || this.noCurrentPaymentIntent;
  }

  completePayment() {
    this.waitingForVerification = true;
    // EE-1888: use raw Stripe instance because ngx-stripe doesn't support full flow yet
    localStorage.setItem('ongoing-stripe-confirmation', 'true');
    this.stripe
      .getInstance()
      .handleCardPayment(this.currentPaymentIntent.secret)
          .then((results) => {
            localStorage.removeItem('ongoing-stripe-confirmation');
            if (results.error) {
              console.error(results.error);
              this.hasPaymentError = true;
              this.waitingForVerification = false;
              this.openDialog('source-error');
            } else {
              this.paymentIncomplete = false;
              this.waitingForVerification = false;
            }
          })
          .catch((error) => {
            localStorage.removeItem('ongoing-stripe-confirmation');
            console.error(error);
            this.waitingForVerification = false;
          });
  }

  private latestInfoSuccess(latestInfo) {
    this.latestInfo = latestInfo;
    this.latestInfoReceived.next(this.latestInfo);
  }

  private latestInfoFailure(error) {
    this.noLatestInfo = true;
  }

  private currentPaymentIntentSuccess(paymentIntent) {
    this.currentPaymentIntent = paymentIntent;

    if (paymentIntent.status === 'succeeded') {
      this.paymentIncomplete = false;
    }

    if (paymentIntent.status === 'requires_source') {
      this.hasSourceError = true;
    }

    this.currentPaymentIntentReceived.next(this.currentPaymentIntent);
  }

  private currentPaymentIntentFailure(error) {
    this.noCurrentPaymentIntent = true;
  }

  private subscribe() {
    this.bus.subscribe(this.events.received.payment.info.latest.success, this.latestInfoSuccess, this);
    this.bus.subscribe(this.events.received.payment.info.latest.failure, this.latestInfoFailure, this);

    this.bus.subscribe(this.events.received.payment.currentPaymentIntent.success, this.currentPaymentIntentSuccess, this);
    this.bus.subscribe(this.events.received.payment.currentPaymentIntent.failure, this.currentPaymentIntentFailure, this);
  }

  private unsubscribe() {
    this.bus.unsubscribe(this.events.received.payment.info.latest.success, this.latestInfoSuccess);
    this.bus.unsubscribe(this.events.received.payment.info.latest.failure, this.latestInfoFailure);

    this.bus.unsubscribe(this.events.received.payment.currentPaymentIntent.success, this.currentPaymentIntentSuccess);
    this.bus.unsubscribe(this.events.received.payment.currentPaymentIntent.failure, this.currentPaymentIntentFailure);
  }

  private publish() {
    this.bus.publish(this.events.requested.payment.info.latest);
    this.bus.publish(this.events.requested.payment.currentPaymentIntent);
  }


  ngOnInit() {
    this.subscribe();
    this.publish();
  }

  ngOnDestroy() {
    this.currentPaymentIntentReceived.unsubscribe();
    this.latestInfoReceived.unsubscribe();
    this.unsubscribe();
  }

  openDialog(reason: string) {
    this.dialog.open(AdditionalAuthenticationDialogComponent, {
      width: '512px',
      maxHeight: '90vh',
      data: { reason }
    });
  }
}

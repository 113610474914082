import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatAutocomplete } from '@angular/material/autocomplete';
import { Location } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable ,  ReplaySubject, zip } from 'rxjs';
import { filter, debounceTime, tap, switchMap, delay } from 'rxjs/operators';
import { isEqual, cloneDeep } from 'lodash';

import { RpaService, PASearchCriteria, PASearchOrdering } from '../../../services/rpa/rpa.service';
// import { RpaXLSXExportService } from '../../../services/rpa/rpa.xlsx-export.service';
import { DepartmentService } from '../../../services/departments/department.service';
import { riskAnalysisClassificationByValue, riskAnalysisGridClassByValues } from '../pa-details/risk-analysis/risk-analysis.consts';
import { ReportGetService } from 'app/services/report/report.get.service';
import { Application } from '../../../services/vendors/application.service';
import { DuplicatePaComponent } from '../duplicate/duplicate.component';
import { NewPaDialogComponent } from '../new-pa-dialog/new-pa-dialog.component';
import { RequestService } from '../../../services/request.service';
import { DialogComponent } from '../../dialog/dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from '../../../services/authentication.service';
import { DuplicateToControllerComponent } from '../duplicate/to-controller/to-controller.component';
import { MatMenu, MatMenuTrigger } from '@angular/material/menu';
import { ActionsService } from '../../../services/actions/actions.service';
import { Vendor } from '../../../services/vendors/vendors.service';


@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'pa-list',
  templateUrl: './pa-list.component.html',
  styleUrls: ['./pa-list.component.scss']
})
export class PaListComponent implements OnInit, OnDestroy {
  @ViewChild('auto', { static: false }) auto: MatAutocomplete;
  @ViewChild('duplicateMenuTrigger', { static: false}) menuTrigger: MatMenuTrigger;

  deps: any[];
  userDeps: any[];
  pas: any[] = [];
  lastChange: any = {};

  collapse = false;
  loading = false;
  receiving = false;

  filters = {
    keyword: '',
    departments: [],
    subjects: [],
    order: PASearchOrdering.LastStatusChange,
    application: null,
    vendor: null,
    processingOnBehalf: false,
    status: 'Applicable'
  } as PASearchCriteria;

  selectedApplication: Application;

  lastFilter = {} as PASearchCriteria; // --> this is the last filter we've queried with, to compare against.

  _suggestedTags: string[] = [];
  filters$ = new ReplaySubject(1); // --> this subject will emit when there is a change in filters

  pasProgress = {};
  pasComplete: boolean; // this is just to give users a visual feedback
  showMarkAsCompleteButton = false;

  constructor(
    private rpas: RpaService,
    private departments: DepartmentService,
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
    // private xlsx: RpaXLSXExportService,
    private reportService: ReportGetService,
    private dialog: MatDialog,
    private request: RequestService,
    private translate: TranslateService,
    private auth: AuthenticationService,
    private actions: ActionsService
  ) {
  }

  reset() {
    this.filters.keyword = '';
    this.filters.departments = [];
    this.filters.subjects = [];
    this.filters.application = null;
    this.filters.vendor = null;
    this.notifyFilterChange();
  }

  /*
  exportXls() {
    // this.xlsx.export(this.pas);
  }

  */

  exportPdf() {
    const ids = this.pas.map(pa => pa.paId);
    this.reportService.openPasPdf(ids, this.filters.processingOnBehalf, this.filters.status);
  }

  exportWord() {
    const ids = this.pas.map(pa => pa.paId);
    this.reportService.downloadWordZip(ids, this.filters.processingOnBehalf);
  }

  exportArt13() {
    const ids = this.pas.map(pa => pa.paId);
    this.reportService.downloadArt13Zip(ids);
  }

  exportXls() {
    const ids = this.pas.map(pa => pa.paId);
    this.reportService.downloadXlsx(ids, this.filters.processingOnBehalf, this.filters.status);
  }

  ngOnInit() {
    if (window.innerWidth < 1100) {
      this.collapse = true;
    }

    this.loading = true;

    this.lastFilter = cloneDeep(this.filters);
    const data = zip(                       // --> we need departments to sanitize pa data, so we need to do both:
      this.rpas.getPAs(this.filters),     // ... getting all pas
      this.departments.requestGetAll(),   // ... getting all departments
      // this.departments.userDepartments(), // ... getting user's departments
    ).subscribe(([pas, deps /*, userDeps */]) => {
      this.deps = deps as any;
      // this.userDeps = userDeps as any;
      this.receivePAs(pas as any);

      this.filters$                                         // --> when filters change
        .pipe(tap(() => {                                     // --> update the url based on the filters
          const tree = this.router.createUrlTree([], {
            relativeTo: this.route,
            queryParams: this._buildQueryParamsFromFilters(),
          });

          this.location.go(tree.toString());
        }))
        .pipe(tap(() => this.loading = true))                // --> now we are going into `loading` mode
        .pipe(debounceTime(700))                             // --> wait for user to finish setting filters
        .pipe(filter(f => {                                  // --> check if filter has really changed
          const different = !isEqual(f, this.lastFilter);
          if (!different) {
            setTimeout(() => this.loading = false, 300);     // --> if not, exit `loading` mode
          }
          return different;
        }))
        .pipe(tap(f => this.lastFilter = cloneDeep(f)))      // --> set the current filter as the last used filter
        .pipe(switchMap(f =>
          this.rpas.getPAs(f as PASearchCriteria)                                // --> get pas based on the filter
            .pipe(tap(() => this.receiving = true))            // --> now we are in `reciving` mode
            .pipe(delay(300))                                  // --> wait for pas to fade
            .pipe(tap(received => this.receivePAs(received as [any])))  // --> set the new pas
            .pipe(delay(300))                                  // --> wait a bit (so transition is smooth)
            .pipe(tap(() => {                                  // --> turn off `loading` and `receiving` mode.
              this.loading = false;
              this.receiving = false;
            }))
        ))
        .subscribe();
    });

    this.actions.getActionsForPas()
      .subscribe(actions => {
        this.lastChange = actions;
      });

    this.route.queryParams.subscribe(queryParams => {
      this._buildFiltersFromQueryParams(queryParams);
      this.notifyFilterChange();
    });

    this.checkPasProgress();
  }

  ngOnDestroy() {
    this.filters$.complete();
  }

  get ordering() {
    return PASearchOrdering;
  }

  get isInSupervisionMode() {
    return localStorage.getItem('isInSupervisionMode') === 'true';
  }

  receivePAs(pas: [any]) {
    this.pas = pas;
    this.pas.forEach(pa => {
      pa.dep = this.deps.find(dep => dep.id === pa.assignedDepartment);
      pa.lastChanged = new Date(pa.lastStatusChange);
    });
  }

  notifyFilterChange() {
    this.filters$.next(this.filters);
  }

  dpiaValueClass(pa) {
    if (pa.paRiskAnalysis && pa.paRiskAnalysisValue) {
      return riskAnalysisClassificationByValue(pa.paRiskAnalysisValue);
    }
  }

  dpiaRiskClass(pa) {
    return '';
  }

  dpiaSeverityClass(pa) {
    return '';
  }

  setApplicationFilter(event: Application) {
    this.filters.application = event.id;
    this.notifyFilterChange();
  }

  setVendorFilter(event: Vendor) {
    this.filters.vendor = event.id;
    this.notifyFilterChange();
  }

  openDuplicateDialog(event: MouseEvent, pa) {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }

    if (!pa.assignedDepartment || pa.assignedDepartment === '') {
      return this.dialog.open(DialogComponent, {
        data: {
          header: this.translate.instant('pa.duplicate.error.title'),
          message: this.translate.instant('pa.duplicate.error.message'),
        },
        width: '500px',
      });
    }

    this.dialog.open(DuplicatePaComponent, { data: { pa }, width: '480px', maxHeight: '90vh' })
      .afterClosed()
      .subscribe(() => {
        if (this.menuTrigger) {
          this.menuTrigger.closeMenu();
        }
      });
  }

  openDuplicateToControllerDialog(event: MouseEvent, pa) {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }

    this.dialog.open(DuplicateToControllerComponent, { data: { pa }, width: '480px', maxHeight: '90vh' })
      .afterClosed()
      .subscribe(() => {
        if (this.menuTrigger) {
          this.menuTrigger.closeMenu();
        }
      });
  }

  newPa() {
    this.dialog.open(NewPaDialogComponent, {
      width: '512px'
    }).afterClosed().subscribe(changed => {
      if (changed) {
        this._invalidate();
      }
    });
  }

  makeComplete() {
    this.request.patch({
      uri: '/workflow-tasks/pa-progress/complete',
      body: {},
    }).subscribe((r: any) => {
      if (r.data.progress) {
        this.showMarkAsCompleteButton = r.data.progress < 1;
      }

      // set this to true to show a complete message in place of the button. only shows once too.
      this.pasComplete = true;
    });
  }

  checkPasProgress() {
    this.request.get({
      uri: '/workflow-tasks/pa-progress/status'
    }).subscribe((r: any) => {
      if (r.data.progress) {
        this.showMarkAsCompleteButton = r.data.progress < 1;
      }
    });
  }

  getLastChange(paId: number) {
    if (!this.lastChange) return null;

    return this.lastChange[paId];
  }

  private _invalidate() {
    this.lastFilter.invalidate = true;
    this.notifyFilterChange();
  }

  private _buildQueryParamsFromFilters() {
    const queryParams = {};

    if (this.filters.keyword.length > 0) {
      queryParams['fk'] = this.filters.keyword;
    }
    if (this.filters.departments.length > 0) {
      queryParams['fd'] = this.filters.departments.join(',');
    }
    if (this.filters.subjects.length > 0) {
      queryParams['fs'] = this.filters.subjects.join(',');
    }
    if (this.filters.order) {
      queryParams['fo'] = this.filters.order;
    }
    if (this.filters.vendor) {
      queryParams['fv'] = this.filters.vendor;
    }
    if (this.filters.application) {
      queryParams['fa'] = this.filters.application;
    }
    if(this.filters.status) {
      queryParams['fst'] = this.filters.status;
    }

    return queryParams;
  }

  private _buildFiltersFromQueryParams(queryParams) {
    if (queryParams.fk) {
      this.filters.keyword = queryParams.fk;
    }
    if (queryParams.fd) {
      queryParams.fd.split(',').forEach(item => {
        try {
          // eslint-disable-next-line radix
          this.filters.departments.push(parseInt(item));
        } catch (err) { }
      });
    }
    if (queryParams.fs) {
      this.filters.subjects.push(...queryParams.fs.split(','));
    }
    if (queryParams.fo) {
      this.filters.order = queryParams.fo;
    }
    if (queryParams.fv) {
      this.filters.vendor = parseInt(queryParams.fv, 10);
    }
    if (queryParams.fa) {
      this.filters.application = parseInt(queryParams.fa, 10);
    }
    if (queryParams.fst) {
      this.filters.status = queryParams.fst;
    }
  }
}

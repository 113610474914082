<div class="contents">
  <h1 class="title">
    {{'organisation.dpGoals.title' | translate}}
  </h1>
  <p *ngIf="accessLevel && !accessLevel.write" class="warning">
    <span>{{'word.NOTICE' | translate}}</span>
    {{'word.readonlyAccess' | translate}}
  </p>

  <stepper #stepper [navigation]="false">
    <stepper-step initial>
      <div class="step-inner">
        <p>
          {{'organisation.dpGoals.slide1.message.beforeCompanyName' | translate}}
          <a>{{companyName}}</a>
          {{'organisation.dpGoals.slide1.message.afterCompanyName' | translate}}
        </p>
        <p>
          {{'organisation.dpGoals.slide1.message.finalPart' | translate}}
        </p>
        <div class="round-buttons-holder">
          <big-round-button (click)="stepper.switch('upload')">
            <mat-icon color="primary" button-icon>assignment_turned_in</mat-icon>
            <a button-text>{{'organisation.dpGoals.alreadyHaveGoals' | translate}}</a>
          </big-round-button>

          <big-round-button (click)="stepper.switch('help')">
            <mat-icon color="primary" button-icon>help_outline</mat-icon>
            <a button-text>{{'organisation.dpGoals.helpMeCreateGoals' | translate}}</a>
          </big-round-button>
        </div>
      </div>
    </stepper-step>

    <stepper-step id="help" (activation)="progress(.33)">
      <div class="step-inner">
        <p>
          {{'organisation.dpGoals.slideHelp.message.part1' | translate}}
        </p>
        <div class="buttons">
          <a mat-raised-button color="primary" target="_blank"
          href="{{docs}}/DP-Goals-Template.docx">
            {{'organisation.dpGoals.slideHelp.action.downloadTemplate' | translate}}
          </a>
        </div>
        <br/>
        <mat-divider></mat-divider>
        <br/>
        <p>
          {{'organisation.dpGoals.slideHelp.message.part2' | translate}}
        </p>
        <div class="buttons">
          <button mat-raised-button (click)="stepper.switch('upload')">
            {{'organisation.dpGoals.slideHelp.action.goToUpload' | translate}}
          </button>
        </div>
      </div>
    </stepper-step>

    <stepper-step id="upload" (activation)="progress(0.66)">
      <div class="step-inner centered">
        <p>
          {{'organisation.dpGoals.slideUpload.message' | translate}}
        </p>
        <div commentable
              comment-box-position="bottom"
              comment-subject-codename="dp-goals-doc"
              comment-subject-title="{{'organisation.dpGoals.commentSubject' | translate}}">
          <todoable subject="dp-goals-doc" suggested-title="{{'organisation.dpGoals.commentSubject' | translate}}"></todoable>

          <cool-upload #upload
            [readonly]="!canWrite"
            upload-url="/controllers/goals"
            upload-key="doc">
            <span hint>
              {{'organisation.dpGoals.upload.hint' | translate}}
            </span>
          </cool-upload>
        </div>
        <br/><br/>
        <button mat-raised-button color="primary" routerLink="">
          {{'organisation.dpGoals.slideUpload.action.done' | translate}}
        </button>
        <br/><br/>
        <mat-divider></mat-divider>
        <br/>
        <button mat-button color="primary" (click)="stepper.switch('help')">
          {{'organisation.dpGoals.slideUpload.action.needHelp' | translate}}
        </button>
      </div>
    </stepper-step>
  </stepper>
</div>

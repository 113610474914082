import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthenticationService } from 'app/services/authentication.service';
import { CustomPaService } from 'app/services/rpa/rpa.custom-pa.service';
import { RpaReferenceService, TreeNode } from 'app/services/rpa/rpa.reference.service';
import { RpaService } from 'app/services/rpa/rpa.service';
import { RpaUpdateStatusService } from 'app/services/rpa/rpa.update-status.service';
import { zip } from 'rxjs';

@Component({
  selector: 'app-pa-name-dialog',
  templateUrl: './pa-name-dialog.component.html',
  styleUrls: ['./pa-name-dialog.component.scss'],
})
export class PaNameDialogComponent implements OnInit {

  paName: string;
  paId: number;

  isReference = false;
  tree: TreeNode[];
  node: TreeNode;

  processingOnBehalf: boolean;
  jointController: boolean;

  constructor(
    @Inject(MAT_DIALOG_DATA) public pa: any,
    private auth: AuthenticationService,
    private service: RpaService,
    private reference: RpaReferenceService,
    private customService: CustomPaService,
    private statusService: RpaUpdateStatusService,
    private router: Router,
    private dialogRef: MatDialogRef<PaNameDialogComponent>,
  ) {
    this.paId = pa.paId;
    this.paName = pa.paName;
    this.processingOnBehalf = pa.processingOnBehalf;
    this.jointController = pa.jointController;

    this.reference.isReferenceController().subscribe(is => {
      this.isReference = is;
    });

    this.reference.getTree().subscribe(tree => {
      this.tree = tree;
      this.node = this.reference.findNode(pa.nodeId, this.tree);
    });
  }

  ngOnInit(): void {
  }

  save() {
    zip(
      this.service.updateBasicField({
        paId: this.paId,
        fieldName: 'paName',
        value: this.paName,
      }),
      this.reference.assignPaToTreeNode(this.paId, this.node),
    ).subscribe(() => {
      this.dialogRef.close({
        name: this.paName,
        node: this.node?.id,
        processingOnBehalf: this.processingOnBehalf,
        jointController: this.jointController,
      });
    });
  }

  deletePa() {
    this.statusService.request({
      id: this.paId,
      status: 'Not Applicable',
    }).subscribe(() => {
      this.dialogRef.close();
      this.router.navigate(['/rpa/applicable']);
    });
  }

  archivePa() {
    this.statusService.request({
      id: this.paId,
      status: 'Archived',
    }).subscribe(() => {
      this.dialogRef.close();
      this.router.navigateByUrl('/', {skipLocationChange: true})
        .then((_) => {
          this.router.navigate(['/rpa/pa-details', this.paId]);
        });
    });
  }

  unarchivePa() {
    this.statusService.request({
      id: this.paId,
      status: 'Applicable',
    }).subscribe(() => {
      this.dialogRef.close();
      this.router.navigateByUrl('/', {skipLocationChange: true})
        .then((_) => {
          this.router.navigate(['/rpa/pa-details', this.paId]);
        });
    });
  }

  handleProcessingOnBehalf() {
    // eslint-disable-next-line
    if (!this.processingOnBehalf && !this.jointController) return;

    if (this.jointController) {
      return this.jointController = false;
    }
  }

  handleJointController() {
    // eslint-disable-next-line
    if (!this.processingOnBehalf && !this.jointController) return;

    if (this.processingOnBehalf) {
      return this.processingOnBehalf = false;
    }
  }
}

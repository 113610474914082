<h1 class="title">{{'coupons.promotion.new-value.title' | translate}}</h1>

<br>

<p>{{'coupons.promotion.new-value.description.part1' | translate}}</p>

<br>

<div class="coupon">
  <div class="logos">
    <img src="assets/new-value-logo.png" />
    <div>+</div>
    <img src="assets/eclogo-check2.svg" />
  </div>
  <div class="info">
    <div class="price">{{promotion.currencySign}}&nbsp;{{promotion.price}}</div>
    <ul>
      <li innerHTML="{{'coupons.promotion.new-value.point1' | translate}}"></li>
      <li innerHTML="{{'coupons.promotion.new-value.point2' | translate}}"></li>
    </ul>
  </div>
</div>

<br>

<p>
  {{'coupons.promotion.new-value.description.part2' | translate:{'currency': promotion.currencySign, 'price': promotion.price} }}
</p>
import { Component, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { zip, Subject } from 'rxjs';
import { Node, Edge, Options } from 'vis-network';
import { Network } from 'vis-network/peer/esm/vis-network';
import { DataSet } from 'vis-data/peer/esm/vis-data';

import { AppConfig } from '../../app.config';
import { ControllerService, ControllerModel } from 'app/services/controller/controller.service';
import { VendorsService } from 'app/services/vendors/vendors.service';
import { RpaExternallyProcessedService } from 'app/services/rpa/rpa.externally-processed.service';
import {
  riskAnalysisClassification,
  incidentProbabilityIndex,
  incidentSeverityIndex,
  RiskAnalysisRiskConstants,
  RiskAnalysisSeverityConstants
} from 'app/components/rpa/pa-details/risk-analysis/risk-analysis.consts';
import { nodeDrawer } from './utils/node-drawer';
import { decodeNode, encodeNode, ExtendedNode, NodeType } from './utils/types';
import { DepartmentService } from 'app/services/departments/department.service';
import { RpaInternallyProcessedService } from 'app/services/rpa/rpa.internally-processed.service';
import { RpaService } from 'app/services/rpa/rpa.service';
import { getToken } from '../../util/token';


@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'data-map',
  templateUrl: './data-map.component.html',
  styleUrls: ['./data-map.component.scss']
})
export class DataMapComponent implements OnInit, AfterViewInit {

  @ViewChild('container') container: ElementRef;

  viewInit = new Subject();
  controller: ControllerModel;
  vendors: any[];
  departments: any[];
  pas: any[];
  intAssoc: any[];
  extAssoc: any[];
  focused: any;
  infoCollapsed = false;

  network: Network;
  nodes = new DataSet<ExtendedNode>();
  edges = new DataSet<Edge>();
  networkOptions: Options = {
    edges: {
      color: 'lightgray',
      arrows: {
        middle: {
          enabled: true,
          type: 'triangle',
          scaleFactor: .33,
        }
      }
    },
    interaction: {
      hover: true,
    }
  };

  constructor(
    controllerService: ControllerService,
    departmentService: DepartmentService,
    vendorService: VendorsService,
    rpaIpService: RpaInternallyProcessedService,
    rpaEpService: RpaExternallyProcessedService,
    rpaService: RpaService,
  ) {
    zip(
      controllerService.requestGet(),
      departmentService.requestGetAll(),
      vendorService.getControllerVendors(),
      rpaIpService.associations(),
      rpaEpService.associations(),
      rpaService.getPAs(),
      this.viewInit,
    ).subscribe(([
      controller,
      departments,
      vendors,
      intAssoc,
      extAssoc,
      pas,
    ]) => {
      this.controller = controller;

      this.departments = departments.map((department) => {
        const recPas = intAssoc
          .filter(association => association.departmentId === department.id)
          .map(association => pas.find(pa => pa.paId === association.paId))
          .filter(_ => !!_)
        ;

        const resPas = pas.filter(pa => pa.assignedDepartment === department.id);

        return {
          ...department, pas: resPas, recPas,
        };
      });

      this.vendors = vendors.map((vendor) => {
        const recPas = extAssoc
          .filter(association => association.vendorId === vendor.vendorId)
          .map(association => pas.find(pa => pa.paId === association.paId))
          .filter(_ => !!_)
        ;

        return {
          ...vendor, pas: recPas,
        };
      });

      this.intAssoc = intAssoc.map(association => {
        const pa = pas.find(_pa => _pa.paId === association.paId);

        if (pa) {
          const src = this.departments.find(dep => pa.assignedDepartment === dep.id);
          const target = this.departments.find(dep => dep.id === association.departmentId);

          return {
            ...association, pa, src, target
          };
        } else {
          return undefined;
        }
      }).filter(_ => !!_);

      this.extAssoc = extAssoc.map(association => {
        const pa = pas.find(_pa => _pa.paId === association.paId);

        if (pa) {
          const src = this.departments.find(dep => pa.assignedDepartment === dep.id);
          const target = this.vendors.find(vendor => vendor.vendorId === association.vendorId);

          return {
            ...association, pa, src, target
          };
        } else {
          return undefined;
        }
      }).filter(_ => !!_);

      this.initNetwork();
    });
  }

  initNetwork() {
    this.addControllerNode();
    this.departments.forEach(department => this.addDepartmentNode(department));
    this.vendors.forEach(vendor => this.addVendorNode(vendor));

    this.addInternalLinks();
    this.addExternalLinks();

    this.network = new Network(this.container.nativeElement, {
      nodes: this.nodes,
      edges: this.edges,
    }, this.networkOptions);

    this.network.on('click', event => {
      if (event.nodes.length > 0) {
        this.selectNode(this.nodes.get(event.nodes[0]));
      } else if (event.edges.length > 0) {
        this.selectEdge(this.edges.get(event.edges[0]));
      } else {
        this.unfocus();
      }
    });
  }

  addControllerNode() {
    this.nodes.add({
      id: encodeNode(undefined, NodeType.ctrl),
      label: this.controller.controllerName,
      shape: 'custom',
      ctxRenderer: nodeDrawer({
        image: this.ctrlLogo,
        size: 64,
        fixed: true,
        redraw: () => this.network.redraw(),
      }),
    });
  }

  addDepartmentNode(department: any) {
    const ctrl$ = encodeNode(undefined, NodeType.ctrl);
    const dep$ = encodeNode(department.id, NodeType.department);

    this.nodes.add({
      id: dep$,
      shape: 'custom',
      label: department.name,
      ctxRenderer: nodeDrawer({
        image: this.ctrlLogo,
        size: 48,
        redraw: () => this.network.redraw(),
      }),
    });

    if (department.pas.length > 0) {
      this.edges.add({
        from: ctrl$,
        to: dep$,
      });
    }
  }

  addVendorNode(vendor: any) {
    this.nodes.add({
      id: encodeNode(vendor.vendorId, NodeType.vendor),
      shape: 'custom',
      label: vendor.name,
      ctxRenderer: nodeDrawer({
        image: vendor.logoUrl || '/assets/default_vendor.png',
        size: 48,
        redraw: () => this.network.redraw(),
      })
    });
  }

  addInternalLinks() {
    const map = {};
    const ctrl$ = encodeNode(undefined, NodeType.ctrl);

    console.log(this.intAssoc);

    this.intAssoc.forEach(association => {
      const from = association.src
        ? encodeNode(association.src.id, NodeType.department)
        : ctrl$;

      const to = encodeNode(association.departmentId, NodeType.department);
      const key = `${from}:${to}`;

      if (!(key in map)) {
        map[key] = { from, to };
      }
    });

    Object.values(map).forEach((edge: any) => this.edges.add(edge));
  }

  addExternalLinks() {
    const map = {};
    const ctrl$ = encodeNode(undefined, NodeType.ctrl);

    this.extAssoc.forEach(association => {
      const from = association.src
        ? encodeNode(association.src.id, NodeType.department)
        : ctrl$;

      const to = encodeNode(association.vendorId, NodeType.vendor);
      const key = `${from}:${to}`;

      if (!(key in map)) {
        map[key] = { from, to };
      }
    });

    Object.values(map).forEach(edge => this.edges.add(edge));
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.viewInit.next();
  }

  get ctrlLogo() {
    return `${AppConfig.apiUrl}/controllers/logo/` +
      `?token=${getToken()}`;
  }

  selectNode(node) {
    const { type, id } = decodeNode(node.id);

    if (type === NodeType.vendor) {
      this.focusVendor(this.vendors.find(v => v.vendorId === id));
    } else if (type === NodeType.department) {
      this.focusDepartment(this.departments.find(d => d.id === id));
    } else if (type === NodeType.ctrl) {
      this.focusCtrl();
    } else {
      this.unfocus();
    }
  }

  resolve(decoded) {
    if (decoded.id) {
      if (decoded.type === NodeType.department) {
        const dep = this.departments.find(d => d.id === decoded.id);
        return {
          name: dep.name,
          pas: dep.recPas,
        };
      } else if (decoded.type === NodeType.vendor) {
        const vendor = this.vendors.find(v => v.vendorId === decoded.id);
        return {
          name: vendor.name,
          pas: vendor.pas,
        };
      }
    }

    return {
      name: this.controller.controllerName
    };
  }

  selectEdge(edge) {
    const to = this.resolve(decodeNode(edge.to));
    const from = this.resolve(decodeNode(edge.from));

    this.focused = {
      name: from.name + ' → ' + to.name,
      type: 'connection',
      pas: to.pas,
    };
  }

  focusCtrl() {
    this.focused = {
      name: this.controller.controllerName,
      type: 'ctrl',
      pas: []
    };
  }

  focusDepartment(department: any) {
    this.focused = {
      name: department.name,
      type: 'department',
      pas: department.pas,
      recPas: department.recPas,
    };
  }

  focusVendor(vendor: any) {
    this.focused = {
      name: vendor.name,
      type: 'vendor',
      pas: vendor.pas,
    };
    this.infoCollapsed = false;
  }

  unfocus() {
    this.focused = undefined;
  }

  openInfo() {
    this.infoCollapsed = false;
  }

  collapseInfo() {
    this.infoCollapsed = true;
    this.unfocus();
  }

  riskAnalysisClass(pa: any) {
    return riskAnalysisClassification(
      incidentProbabilityIndex(pa.paRiskAnalysis?.risk.value || -1),
      incidentSeverityIndex(pa.paRiskAnalysis?.severity.value || -1),
    );
  }

  riskAnalysisRiskLabel(pa: any) {
    return RiskAnalysisRiskConstants.thresholdLabels[incidentProbabilityIndex(pa.paRiskAnalysis?.risk.value || -1)] || '---';
  }

  riskAnalysisSeverityLabel(pa: any) {
    return RiskAnalysisSeverityConstants.thresholdLabels[incidentSeverityIndex(pa.paRiskAnalysis?.severity.value || -1)] || '---';
  }

  /**
   * This will grab the canvas tag and generate an image from it.
   * First get the canvas tag content, then assign it to context.
   * With the context set we will set the background to white
   * ('globalCompositeOperation = destination-over' sets the background behind the existing data,
   *  so what this is really doing is just generating a new rectangle behind the main canvas content)
   * Generate a base64 string from the canvas and then use fetch to get a blob from it.
   *
   * (if there is any other alternative to generate a blob please recommend because some users with older browsers might have problems)
   * Use fetch to generate a blob and a file from the blob.
   *
   * After having the image generated the browser will start the download.
   * The last step is to revert the canvas to its original state with no background, this is because the canvas breaks if we leave the white background
   *
   * @originalData -> saves the original canvas/image data to restore without the user having to refresh the page
   * @context -> the canvas data, used to set a white background
   * @imgData -> base64 string from the canvas content
   */
  downloadDataMap() {
    const canvas = document.getElementsByTagName('canvas');
    let originalData: any = null;

    const context = canvas[0].getContext('2d');
    const [w, h] = [
      canvas[0].width,
      canvas[0].height];

    originalData = context.getImageData(0,0,w,h);
    const compositData = context.globalCompositeOperation;
    context.fillStyle = '#FFFFFF';
    context.globalCompositeOperation = 'destination-over';
    context.fillRect(0,0,w,h);

    const imgData = canvas[0].toDataURL('image/jpeg', 1.0);

    fetch(imgData).then(res => res.blob())
      .then(blob => {
        const file = new File([blob], 'image', {type: 'image/jpeg'});
        this.doDownload(file);
      });

    context.clearRect(0,0,w,h);
    context.putImageData(originalData,0,0);
    context.globalCompositeOperation = compositData;
  }

  /**
   * Helper function to download the canvas as an image
   * Creates a link tag and sets the file name and url.
   * Clicks it then removes from the DOM tree.
   *
   * @param file
   */
  doDownload(file: File) {
    const downloadLink = document.createElement('a');
    downloadLink.download = 'dataMap.jpg';
    downloadLink.href = URL.createObjectURL(file);
    document.body.appendChild(downloadLink);
    downloadLink.click();
    downloadLink.remove();
  }
}

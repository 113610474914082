import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'select-user',
  templateUrl: './select-user.component.html',
  styleUrls: ['./select-user.component.scss']
})
export class SelectUserComponent implements OnInit {

  users: any[];
  selected: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) data: any,
    private ref: MatDialogRef<SelectUserComponent>,
  ) {
    this.users = data.users;
    this.selected = data.selected;
  }

  ngOnInit(): void {
  }

  pick(user) {
    if (this.selected === user) {
      this.ref.close(this.selected);
    } else {
      this.selected = user;
    }
  }
}

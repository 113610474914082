import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';

import { BusService } from '../../services/bus.service';
import { EventsService } from '../../services/events.service';
import { CommentService, Subject } from 'app/services/comment/comment.service';
import { WorkflowTasksService } from 'app/services/workflow-tasks/workflow-tasks.service';


@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'todolist',
  templateUrl: './todolist.component.html',
  styleUrls: ['./todolist.component.scss']
})
export class TodolistComponent implements OnInit, OnDestroy {

  workflows = [];

  constructor(
    private bus: BusService,
    private events: EventsService,
    private comments: CommentService,
    private router: Router,
    private service: WorkflowTasksService,
  ) { }

  ngOnInit() {
    this.subscribe();
    this.bus.publish(this.events.ui.requested.sidenav.expand);
    this.bus.publish(this.events.requested.data.todos.workflows);
  }

  ngOnDestroy() {
    this.unsubscribe();
    this.bus.publish(this.events.ui.requested.sidenav.collapse);
  }

  updateWorkflows(workflows) {
    if (workflows.some(workflow => workflow.active)) {
      this.workflows = workflows.filter(workflow => workflow.active);
    } else {
      this.workflows = workflows;
    }
  }

  get ongoingWorkflows() {
    return this.workflows.filter(workflow => workflow.steps.some(step => step.task.progress < 1));
  }

  get completedWorkflows() {
    return this.workflows.filter(workflow => workflow.steps.every(step => step.task.progress >= 1));
  }

  public refresh() {
    this.bus.publish(this.events.requested.data.todos.workflows);
  }

  // this is a workaround for displaying expired accounts correctly on the first visit. we handle expiry by setting
  // localStorage. On the first visit, localStorage is not set yet. So we manually check it again... This is here
  // because we assume that the todolist is the first visited page after login.
  workflowsFailure(error) {
    if (JSON.parse(localStorage.getItem('isExpired') || 'false')) {
      this.router.navigate(['/access-expired']);
    }
  }

  workflowActive(codename: string) {
    return this.workflows.filter(w => w.active).map(w => w.codename).includes(codename);
  }

  switchWorkflow(codename: string) {
    this.service.switchWorkflow(codename).subscribe(res => {
      this.updateWorkflows(res);
    });
  }

  private subscribe() {
    this.bus.subscribe(this.events.received.data.todos.workflows.success, this.updateWorkflows, this);
    this.bus.subscribe(this.events.received.data.todos.workflows.failure, this.workflowsFailure, this);
    this.bus.subscribe(this.events.received.action.tasks.progressControllerTask.success, this.refresh, this);
  }

  private unsubscribe() {
    this.bus.unsubscribe(this.events.received.data.todos.workflows.success, this.updateWorkflows);
    this.bus.unsubscribe(this.events.received.data.todos.workflows.failure, this.workflowsFailure);
    this.bus.unsubscribe(this.events.received.action.tasks.progressControllerTask.success, this.refresh);
  }
}

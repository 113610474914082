import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { BusService } from '../bus.service';
import { EventsService } from '../events.service';
import {
  IGetOptions,
  IPostOptions,
  RequestService
} from '../request.service';

interface AddRequest {
  name: string;
  description: string;
  department: string;
}

interface AddResponse {
  id: number;
}

interface UpdateRequest {
  id: number;
  name: string;
  description?: string;
  department?: string;
}

interface GetRequest {
  id: number;
}

interface GetResponse {
  name: string;
  description: string;
  department: string;
}

interface DeleteRequest {
  id: number;
}

export interface DuplicatePaPayload {
  name: string;
  department?: number;
  referenceController?: number;

  options: {
    includeExternalProcessors: boolean;
    includeInternalProcessors: boolean;
  };
}

@Injectable()
export class CustomPaService {

  constructor(
    private bus: BusService,
    private events: EventsService,
    private requestService: RequestService
  ) {
    this.subscribe();
  }

  requestAdd(data: AddRequest) {
    this.requestService.post<AddRequest, AddResponse>({
      uri: '/custom-pa/',
      body: data,
      handlers: {
        success: result =>
          this.bus.publish(this.events.received.data.rpa.customPa.add.success, result),
        error: error =>
          this.bus.publish(this.events.received.data.rpa.customPa.add.failure, error),
      }
    });
  }

  requestGet(data: GetRequest) {
    this.requestService.get<GetRequest>({
      uri: `/custom-pa/${data.id}/`,
      handlers: {
        success: result => this.bus.publish(this.events.received.data.rpa.customPa.get.success, result),
        error: error =>
          this.bus.publish(this.events.received.data.rpa.customPa.get.failure, error),
      }
    });
  }

  duplicate(paId: number, payload: DuplicatePaPayload) {
    return this.requestService.post<DuplicatePaPayload, { id: number; complete: boolean }>({
      uri: `/custom-pa/${paId}/duplicate`,
      body: payload
    });
  }

  duplicateToController(paId: number, targetController: number) {
    return this.requestService.post<{ targetController: number }, { id: number; complete: boolean }>({
      uri: `/custom-pa/${paId}/duplicate-to-controller`,
      body: { targetController }
    });
  }

  private subscribe() {
    this.bus.subscribe(this.events.requested.data.rpa.customPa.add, this.requestAdd.bind(this));
    this.bus.subscribe(this.events.requested.data.rpa.customPa.get, this.requestGet.bind(this));
  }
}

import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { RiskAnalysisSeverityConstants } from '../risk-analysis.consts';


@Component({
  selector: 'app-risk-analysis-severity-dialog',
  templateUrl: './severity-dialog.component.html',
  styleUrls: ['./severity-dialog.component.scss']
})
export class RiskAnalysisSeverityDialogComponent implements OnInit {

  changed: boolean = false;

  severity: number;
  // if systematic==true, then the reasons are system-generated and *can* be translated. (User-generated reasons cannot.)
  severityReasons: {systematic: boolean, text: string}[] = [];

  severityRanges = RiskAnalysisSeverityConstants.thresholds.map((item, index) => {
      if (index < RiskAnalysisSeverityConstants.thresholds.length - 1) {
        return [item, RiskAnalysisSeverityConstants.thresholds[index + 1]];
      }
    }).filter(_ => _);

  severityConstants = RiskAnalysisSeverityConstants;

  focusedReason = undefined;

  constructor(
    public ref: MatDialogRef<RiskAnalysisSeverityDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data: any
  ) {
    this.severity = data.severity;
    this.severityReasons = data.severityReasons.map(_ => Object.assign({}, _));
  }

  ngOnInit() {
  }

  setSeverity(range) {
    if (this.severity < range[0] || this.severity >= range[1]) {
      this.severity = (range[0] + range[1])/2;
      this.changed = true;
    }
  }

  addReason() {
    this.changed = true;
    this.focusedReason = {systematic: false, text: ''};
    this.severityReasons.push(this.focusedReason);
  }

  removeReason(reason) {
    this.changed = true;
    this.severityReasons = this.severityReasons.filter(r => r != reason);
  }

  done() {
    this.ref.close({
      mutated: this.changed,
      severity: this.severity,
      reasons: this.severityReasons
    });
  }
}

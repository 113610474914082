import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { BusService } from '../../services/bus.service';
import { EventsService } from '../../services/events.service';
import { TomsCategory, TomsService } from '../../services/toms/toms.service';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-toms',
  templateUrl: './toms.component.html',
  styleUrls: ['./toms.component.scss']
})
export class TomsComponent implements OnInit, OnDestroy {

  cats: TomsCategory[];

  loadingEntries = true;
  entries: any[];

  allFinished = true;

  constructor(
    private bus: BusService,
    private events: EventsService,
    private router: Router,
    private translate: TranslateService,
    private toms: TomsService,
  ) { }

  ngOnInit() {
    this.subscribe();
    this.bus.publish(this.events.requested.data.toms.categories);
    this.toms.controllerEntries().subscribe((data) => {
      this.entries = data;
      this.loadingEntries = false;
      this.resolveAllFinished();
    });
  }

  ngOnDestroy() {
    this.unsubscribe();
  }

  update(cats) {
    this.cats = cats;
  }

  resolveAllFinished() {
    if (this.cats && !this.loadingEntries) {
      this.cats.forEach(c => {
        if (+this.calculateProgress(c.id) !== 100) {
          this.allFinished = false;
        }
      });
    }
  }

  public progress(progress) {
    console.log('HERE!')
    this.bus.publish(this.events.requested.action.tasks.progressControllerTask, {
      progress,
      workflowCodename: 'audit',
      taskCodename: 'ad_toms',
    });
  }

  get lang() {
    return this.translate.currentLang || this.translate.defaultLang;
  }

  continue() {
    console.log('CONEITETET')
    this.progress(1);
    this.router.navigate(['']);
  }

  subscribe() {
    this.bus.subscribe(this.events.received.data.toms.categories.success, this.update, this);
  }

  unsubscribe() {
    this.bus.unsubscribe(this.events.received.data.toms.categories.success, this.update);
  }

  calculateProgress(categoryId) {
    if (!this.loadingEntries) {
      const toms: any[] = this.categoryEntries(categoryId);
      const countable: any[] = this.filterCountable(toms);
      return (countable.length * 100 / toms.length).toFixed(0);
    }
  }

  categoryEntries(categoryId) {
    return this.entries.filter(t => t.categoryId === categoryId);
  }

  filterNotApplicable(toms: any[]) {
    return toms.filter(t => t.status !== 'not-applicable');
  }

  filterCountable(toms: any[]) {
    return toms.filter(t => t.status !== 'not-set' && t.status);
  }
}

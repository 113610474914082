import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DateAdapter } from "@angular/material/core";

const Languages = [
  { name: 'English', identifier: 'en', legislation: 'gdpr' },
  { name: 'English (Nigeria)', identifier: 'en-NG', legislation: 'ndpr' },
  { name: 'Deutsch', identifier: 'de', legislation: 'dsgvo' },
  { name: 'Português (EU)', identifier: 'pt', legislation: 'rgpd' },
  { name: 'Português (Brazil)', identifier: 'pt-BR', legislation: 'lgpd' }
];

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'language',
  templateUrl: './language.component.html',
  styleUrls: ['./language.component.scss']
})
export class LanguageComponent implements OnInit {
  selectedLanguage: string;
  currentLang: string;

  constructor(
    private translate: TranslateService,
    private dateAdapter: DateAdapter<any>
  ) {
  }

  ngOnInit() {
    if (localStorage.getItem('lang')) {
      this.setLanguage(localStorage.getItem('lang'));
    }
  }

  get languages() {
    return Languages;
  }

  public get current() {
    return this.translate.currentLang || this.translate.defaultLang;
  }

  onChange(event) {
    // this.selectedLanguage = event;
    this.setLanguage(event);
  }

  compareLanguages(o1: any, o2: any): boolean {
    return o1.identifier === o2.identifier;
  }

  public setLanguage(lang) {
    if (Languages.map((l) => l.identifier).includes(lang)) {
      this.translate.use(lang);
      localStorage.setItem('lang', lang);
      this.dateAdapter.setLocale(lang);

      if (this.selectedLanguage && this.selectedLanguage !== lang) {
        setTimeout(() => window.location.reload(), 200);
      }

      this.selectedLanguage = lang || this.translate.currentLang || this.translate.defaultLang;
    }
  }
}

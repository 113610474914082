import { Component, OnInit } from '@angular/core';
import {Incident, IncidentsResourceService} from "../../../services/incidents/incidents-resource.service";
import {ActivatedRoute} from "@angular/router";
import {AuthenticationService} from "../../../services/authentication.service";

@Component({
  selector: 'app-report-incident',
  templateUrl: './report-incident.component.html',
  styleUrls: ['./report-incident.component.scss']
})
export class ReportIncidentComponent implements OnInit {

  record: Incident = {
    title: '',
    details: '',
    customFields: {}
  };

  isSubmitting = false;
  submitted = false;
  formExists: 'pending' | 'exists' | 'notFound' = 'pending';

  key = null;

  departments = [];

  constructor(
    private incidentService: IncidentsResourceService,
    private route: ActivatedRoute,
    private authService: AuthenticationService
  ) {
    route.params.subscribe(params => {
      if (params['key']) {
        this.key = params['key'];

        this.incidentService.hasForm(params['key']).subscribe((exists) => {
          this.formExists = 'exists';
        }, (error) => {
          this.formExists = 'notFound';
        });
      } else if (this.authService.token) {
        this.formExists = 'exists';
      }
    });
  }

  ngOnInit(): void {
  }

  submit() {
    this.isSubmitting = true;
    this.incidentService.report(this.key, this.record).subscribe((record) => {
      this.isSubmitting = false;
      this.submitted = true;
    }, (error) => {
      this.isSubmitting = false;
    });
  }

}

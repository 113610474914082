import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, zip } from 'rxjs';
import { combineAll } from 'rxjs/operators';
import { Department, DepartmentService } from '../../../../services/departments/department.service';

type Suggestion = Pick<Department, 'name'>;

@Component({
  selector: 'app-department-suggestion',
  templateUrl: './department-suggestion.component.html',
  styleUrls: ['./department-suggestion.component.scss']
})
export class DepartmentSuggestionDialogComponent implements OnInit {

  suggestions: Suggestion[] = [];
  query = '';

  selected: Suggestion | Department;

  constructor(
    private dialogRef: MatDialogRef<DepartmentSuggestionDialogComponent>,
    private departmentService: DepartmentService,
    @Inject(MAT_DIALOG_DATA) public data: { existingDepartments: Department[] },
    private translate: TranslateService
  ) { }

  ngOnInit(): void {
    this.buildSuggestions();
  }

  get matchingSuggestions() {
    // show all suggestions if there is no query
    if (!this.query.trim()) {
      return this.suggestions;
    }
    return this.suggestions.filter(suggestion => suggestion.name.toLowerCase().includes(this.query.toLowerCase()));
  }

  get matchingDepartments() {
    // show no matching departments if there is no query
    if (!this.query.trim()) {
      return [];
    }
    return this.data.existingDepartments.filter(department => department.name.toLowerCase().includes(this.query.toLowerCase()));
  }

  choose(choice: Suggestion | Department) {
    this.selected = choice;
    this.query = choice.name;
  }

  create() {
    // if the query matches an existing department, use this
    if (this.selected && (this.selected as Department).id && this.selected.name.toLowerCase() === this.query.toLowerCase()) {
      this.dialogRef.close(this.selected as Department);
    } else {
      // create department
      this.departmentService.requestAdd({
        name: this.query,
        dpCoordinatorEmail: '',
        dpCoordinatorFirstName: '',
        dpCoordinatorLastName: '' ,
        headEmail: '',
        headFirstName: '',
        headLastName: ''
      }).subscribe(department => {
        this.dialogRef.close(department);
      });
    }
  }

  private buildSuggestions() {
    combineLatest([
      this.translate.get('departments.suggestion.occupationalSafety'),
      this.translate.get('departments.suggestion.it'),
      this.translate.get('departments.suggestion.accounting'),
      this.translate.get('departments.suggestion.controlling'),
      this.translate.get('departments.suggestion.hr'),
      this.translate.get('departments.suggestion.export'),
      this.translate.get('departments.suggestion.logistics'),
      this.translate.get('departments.suggestion.marketing'),
      this.translate.get('departments.suggestion.sales'),
      this.translate.get('departments.suggestion.workshop'),
      this.translate.get('departments.suggestion.management'),
      this.translate.get('departments.suggestion.administration'),
      this.translate.get('departments.suggestion.secretary'),
      this.translate.get('departments.suggestion.reception'),
      this.translate.get('departments.suggestion.purchasing'),
      this.translate.get('departments.suggestion.maintenance'),
      this.translate.get('departments.suggestion.production'),
      this.translate.get('departments.suggestion.fleetManagement'),
      this.translate.get('departments.suggestion.dispatch'),
      this.translate.get('departments.suggestion.travelManagement'),
      this.translate.get('departments.suggestion.workersCouncil'),
      this.translate.get('departments.suggestion.service'),
      this.translate.get('departments.suggestion.customerService'),
      this.translate.get('departments.suggestion.crisisManagement')
    ]).subscribe(suggestions => {
      this.suggestions = suggestions.map(suggestion => ({ name: suggestion }));
      this.suggestions = this.suggestions.filter(suggestion => !this.data.existingDepartments.some(department => department.name === suggestion.name));
      this.suggestions.sort((a, b) => a.name.localeCompare(b.name));
    });
  }

}

import { HttpClient } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { catchError, flatMap, map } from 'rxjs/operators';
import { DialogComponent } from '../components/dialog/dialog.component';
import { SharedDocumentsService } from '../services/documents/shared.service';
import { getFileName } from './documentGenerator';

export function downloadAndShare(
  url: string,
  filename: string,
  shareService: SharedDocumentsService,
  translate: TranslateService,
  http: HttpClient,
  dialog: MatDialog
  ): Observable<{ id: number; fileName: string }> {
  return http.get(url, { responseType: 'blob', observe: 'response' })
    .pipe(
      catchError(async err => {
        let downloadError = null;
        try {
          const text = await (err.error as Blob).text();
          downloadError = JSON.parse(text);
        } catch (error) {
          console.error('could not handle error');
          console.error(error);
          throw new Error('could not handle error');
        }

        throw downloadError;
      }),
      flatMap(response => {
        const backendFilename = getFileName(response.headers.get('Content-Disposition'));
        return shareService.uploadBlobForSharedDocument(response.body, backendFilename && backendFilename.length > 0 ? decodeURI(backendFilename) : filename);
      }),
      map(response => (response as any)?.body),
      catchError((err, observable) => {
        const genericMessage = translate.instant('documents.generated.errorMessageGeneric');
        console.error('handling error', err);
        dialog.open(DialogComponent, { data: { html: genericMessage }, maxWidth: '400px' });
        return of(null);
      }),
    );
}

import { Injectable } from '@angular/core';

import {BusService} from '../bus.service';
import {EventsService} from '../events.service';
import {
  RequestService
} from '../request.service';


interface InviteRequest {
  firstName: string;
  lastName: string;
  email: string;
  departmentId: number;
  access: string;
}

@Injectable()
export class UserManagementService {

  api = {
    root: '/controller',
    users: {
      root: '/users',
      invites: {
        root: '/invites',
        decode: '/decode',
        resend: '/resend',
        confirm: '/confirm',
      },
    }
  };

  constructor(
    private bus: BusService,
    private events: EventsService,
    private requestService: RequestService
  ) {
    this.subscribe();
  }

  getTwoFactorInfo(user) {
    return this.requestService.get<{ setupDate: Date } | null>({
      uri: this.api.root + this.api.users.root + `/${user.id}/two-factor`,
    });
  }

  disableTwoFactor(user) {
    return this.requestService.delete<any, any>({
      uri: this.api.root + this.api.users.root + `/${user.id}/two-factor`
    });
  }

  users(includeSupervisors = false) {
    return this.requestService.get<any>({
      uri: this.api.root + this.api.users.root + (includeSupervisors ? '?supervisors=true' : ''),
      handlers: {
        success: users => this.bus.publish(this.events.received.data.controller.users.success, users),
        error: error => this.bus.publish(this.events.received.data.controller.users.failure, error),
      }
    });
  }

  updateUser(user) {
    return this.requestService.put<any, any>({
      uri: this.api.root + this.api.users.root + `/${user.id}`,
      body: user,
      handlers: {
        success: () => this.bus.publish(this.events.received.action.controller.users.update.success),
        error: error => this.bus.publish(this.events.received.action.controller.users.update.failure, error),
      }
    });
  }

  deleteUser(user) {
    return this.requestService.delete<any, any>({
      uri: this.api.root + this.api.users.root + `/${user.id}`,
      handlers: {
        success: () => {},
        error: () => {},
      }
    })
  }

  invites() {
    return this.requestService.get<any>({
      uri: this.api.root + this.api.users.root + this.api.users.invites.root,
      handlers: {
        success: invites => this.bus.publish(this.events.received.data.controller.invites.success, invites),
        error: error => this.bus.publish(this.events.received.data.controller.invites.failure, error),
      }
    });
  }

  invite(data: InviteRequest) {
    this.requestService.post<InviteRequest, void>({
      uri: this.api.root + this.api.users.root + this.api.users.invites.root,
      body: data,
      handlers: {
        success: () => this.bus.publish(this.events.received.action.controller.users.invite.success),
        error: error => this.bus.publish(this.events.received.action.controller.users.invite.failure, error),
      }
    });
  }

  deleteInvite(inviteId) {
    return this.requestService.delete<any, any>({
      uri: this.api.root + this.api.users.root + this.api.users.invites.root + `/${inviteId}`,
      handlers: {
        success: () => this.bus.publish(this.events.received.action.controller.users.deleteInvite.success),
        error: error => this.bus.publish(this.events.received.action.controller.users.deleteInvite.failure)
      }
    });
  }

  resendInvite(inviteId) {
    return this.requestService.post<any, any>({
      uri: this.api.root + this.api.users.root
          + this.api.users.invites.root + this.api.users.invites.resend
          + `/${inviteId}`,
      body: {},
      handlers: {
        success: () => this.bus.publish(this.events.received.action.controller.users.deleteInvite.success),
        error: error => this.bus.publish(this.events.received.action.controller.users.deleteInvite.failure)
      }
    });
  }

  decodeInviteToken(token) {
    return this.requestService.get<any>({
      uri: this.api.root + this.api.users.root + this.api.users.invites.root
          + this.api.users.invites.decode + `?invite-token=${token}`,
      handlers: {
        success: response => this.bus.publish(this.events.received.data.controller.decodeInviteToken.success, response),
        error: error => this.bus.publish(this.events.received.data.controller.decodeInviteToken.failure, error),
      }
    });
  }

  confirmInvite(token) {
    return this.requestService.post<any, any>({
      uri: this.api.root + this.api.users.root + this.api.users.invites.root
        + this.api.users.invites.confirm + `?invite-token=${token}`,
      body: {},
      handlers: {
        success: () => this.bus.publish(this.events.received.action.controller.users.confirmInvite.success),
        error: error => this.bus.publish(this.events.received.action.controller.users.confirmInvite.failure, error),
      }
    });
  }

  subscribe() {
    this.bus.subscribe(this.events.requested.data.controller.users, this.users.bind(this));
    this.bus.subscribe(this.events.requested.action.controller.users.update, this.updateUser.bind(this));
    this.bus.subscribe(this.events.requested.data.controller.invites, this.invites.bind(this));
    this.bus.subscribe(this.events.requested.action.controller.users.invite, this.invite.bind(this));
    this.bus.subscribe(this.events.requested.action.controller.users.deleteInvite, this.deleteInvite.bind(this));
    this.bus.subscribe(this.events.requested.action.controller.users.resendInvite, this.resendInvite.bind(this));
    this.bus.subscribe(this.events.requested.data.controller.decodeInviteToken, this.decodeInviteToken.bind(this));
    this.bus.subscribe(this.events.requested.action.controller.users.confirmInvite, this.confirmInvite.bind(this));
  }
}

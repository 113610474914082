import { Component, OnInit } from '@angular/core';
import { Application, ApplicationService } from '../../../services/vendors/application.service';

@Component({
  selector: 'app-controller-applications',
  templateUrl: './controller-applications.component.html',
  styleUrls: ['./controller-applications.component.scss']
})
export class ControllerApplicationListComponent implements OnInit {
  applications: Application[] = [];

  constructor(
    private applicationService: ApplicationService
  ) { }

  ngOnInit(): void {
    this.applicationService.getControllerApplications()
      .subscribe((applications) => {
        this.applications = applications;
      });
  }

}

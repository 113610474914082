import { Component, OnInit } from '@angular/core';
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {IncidentFormSettings, IncidentsResourceService} from "../../../services/incidents/incidents-resource.service";
import {zip} from "rxjs";
import {DialogComponent} from "../../dialog/dialog.component";
import {
  DsarLinkCopiedDialogComponent
} from "../../dsar/settings-dialog/dsar-link-copied-dialog/dsar-link-copied-dialog.component";
import {ClipboardService} from "ngx-clipboard";

@Component({
  selector: 'app-form-settings',
  templateUrl: './form-settings.component.html',
  styleUrls: ['./form-settings.component.scss']
})
export class FormSettingsComponent implements OnInit {

  settings: IncidentFormSettings = {
    notifySupervisor: false,
    notifyDpo: false,
    notificationEmails: [],
    formKey: null,
    formEnabled: false
  };

  constructor(
    private ref: MatDialogRef<FormSettingsComponent>,
    private incidentService: IncidentsResourceService,
    private clipboard: ClipboardService,
    private dialog: MatDialog
  ) {
    this.incidentService.getFormSettings()
      .subscribe((settings) => {
        if (settings) {
          this.settings = settings;
        }
      });
  }

  toggleLink() {
    if (!this.settings.formKey) {
      this.generateFormKey();
    }
  }

  get formLink() {
    return window.location.origin + '/incidents/report/' + this.settings.formKey;
  }

  generateFormKey() {
    this.settings.formKey = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
  }

  ngOnInit(): void {
  }

  save() {
    this.incidentService.updateFormSettings(this.settings)
      .subscribe(() => {
        this.ref.close();
      });
  }

  copyLink() {
    this.clipboard.copy(this.formLink);
    this.dialog.open(DsarLinkCopiedDialogComponent, {
      width: '350px'
    });
  }
}

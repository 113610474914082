import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { getToken } from '../util/token';

@Injectable()
export class NotAuthenticatedGuard implements CanActivate {
  // prevents confusion for when the user is already logged in.

  constructor(
    private router: Router
  ) { }

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (getToken()) {
      this.router.navigate(['todolist']);
      return false;
    }

    return true;
  }
}

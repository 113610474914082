import {Component, Injector, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {AppConfig} from './app.config';
import { StripeService } from 'ngx-stripe';
import { Router, NavigationEnd, ActivationEnd } from '@angular/router';
import { CrispService } from './modules/crisp/crisp.service';
import { MixpanelService } from './modules/mixpanel/mixpanel.service';
import { AuthenticationService } from './services/authentication.service';
import { UserService } from './services/user/user.service';
import { PaymentSubscriptionService } from './services/payment/subscription.service';
import { isNotCustomerInstance } from './util/environment';
import {PlausibleService} from "./modules/plausible/plausible.service";
import {DateAdapter} from "@angular/material/core";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],

})
export class AppComponent implements OnInit {
  constructor(
    private translate: TranslateService,
    private crisp: CrispService,
    private router: Router,
    private mixpanel: MixpanelService,
    private auth: AuthenticationService,
    private userService: UserService,
    private plausibleService: PlausibleService,
    private injector: Injector,
    private dateAdapter: DateAdapter<any>
  ) {
    // IETF short form, i.e. 'en' instead of 'en-US':
    if (!localStorage.getItem('lang')) {
      let lang;
      if (navigator.language !== 'pt-BR' && navigator.language !== 'en-NG') { // CLEAN THIS UP LATER
        lang = (navigator.language).slice(0, 2);
      } else {
        lang = navigator.language;
      }
      const supportedLanguages = AppConfig.supportedLanguages;
      if (!supportedLanguages.includes(lang)) {
        lang = AppConfig.defaultLang; // default
      }

      translate.setDefaultLang(lang);
      localStorage.setItem('lang', lang);
      this.dateAdapter.setLocale(lang);
    }
  }

  ngOnInit() {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        window.scrollTo(0, 0);
      } else if (event instanceof ActivationEnd) {
        this.mixpanel.track('core:pageview', {
          url: event.snapshot.url,
          normalizedUrl: event.snapshot.routeConfig?.path
        });
      }
    });

    this.auth.authorized.subscribe((authorized) => {
      if (authorized) {
        try {
          this.mixpanel.initialize();
          this.mixpanel.identify(localStorage.getItem('currentUserID'));
          this.userService.userInfo().subscribe(() => {});
        } catch (error) {
          // do nothing
        }
      }
    });

    // this.subscriptionService.mountStripe();
    if (isNotCustomerInstance()) {
      // setup stripe
      const stripe = this.injector.get(StripeService);
      // inject plausible
      this.plausibleService.mount();
    }
  }
}

import {Injectable} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import {BusService} from '../bus.service';
import {RequestService} from '../request.service';
import {EventsService} from '../events.service';


interface AssociateRequest {
  paId: number;
  vendorId: number;
}

@Injectable()
export class RpaExternallyProcessedService {
  private api = {
    root: '/pa/externally-processed',
    pas: '/pas',
  };

  constructor(
    private bus: BusService,
    private events: EventsService,
    private requestService: RequestService
  ) {
    this.subscribe();
  }

  getExternallyProcessedPas() {
    return this.requestService.get<any>({
      uri: this.api.root + this.api.pas,
      handlers: {
        success: data => this.bus.publish(this.events.received.data.rpa.externallyProcessed.pas.success, data),
        error: error => this.bus.publish(this.events.received.data.rpa.externallyProcessed.pas.failure, error),
      }
    });
  }

  associations() {
    return this.requestService.get<any>({
      uri: this.api.root,
      handlers: {
        success: data => this.bus.publish(this.events.received.data.rpa.externallyProcessed.vendors.success, data),
        error: error => this.bus.publish(this.events.received.data.rpa.externallyProcessed.vendors.failure, error),
      }
    });
  }

  associate(event: AssociateRequest) {
    return this.requestService.put<AssociateRequest, any>({
      uri: this.api.root,
      body: event,
      handlers: {
        success: () => this.bus.publish(this.events.received.data.rpa.externallyProcessed.associate.success, event),
        error: error => this.bus.publish(this.events.received.data.rpa.externallyProcessed.associate.failure, error),
      }
    });
  }

  disassocaite(event: AssociateRequest) {
    return this.requestService.delete<any, any>({
      uri: this.api.root + `/${event.paId}/${event.vendorId}`,
      handlers: {
        success: () => this.bus.publish(this.events.received.data.rpa.externallyProcessed.disassociate.success, event),
        error: error => this.bus.publish(this.events.received.data.rpa.externallyProcessed.disassociate.failure, error),
      }
    });
  }

  subscribe() {
    this.bus.subscribe(this.events.requested.data.rpa.externallyProcessed.pas, this.getExternallyProcessedPas.bind(this));
    this.bus.subscribe(this.events.requested.data.rpa.externallyProcessed.vendors, this.associations.bind(this));
    this.bus.subscribe(this.events.requested.data.rpa.externallyProcessed.associate, this.associate.bind(this));
    this.bus.subscribe(this.events.requested.data.rpa.externallyProcessed.disassociate, this.disassocaite.bind(this));
  }
}

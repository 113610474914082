<h1 mat-dialog-title>{{ 'records.incidents.formSettings.title' | translate }}</h1>

<p class="link-hint">{{ 'records.incidents.formSettings.publicPage' | translate }}</p>

<mat-dialog-content>
  <h3>{{ 'records.incidents.formSettings.publicPage.title' | translate }}</h3>
  <mat-slide-toggle color="primary" (change)="toggleLink()" [(ngModel)]="settings.formEnabled">{{
    'records.incidents.formSettings.formEnabled' | translate }}</mat-slide-toggle>
  <ng-container *ngIf="settings.formEnabled">
    <a class="link" [href]="formLink">{{ formLink }}</a>

    <div class="actions">
      <button mat-button mat-icon-button color="warn" tabindex="-1" (click)="generateFormKey()">
        <mat-icon>autorenew</mat-icon>
      </button>
      <button mat-flat-button color="primary" (click)="copyLink()">
        {{ 'dsar.settings.link.actions.copy' | translate }}
      </button>
    </div>
  </ng-container>


  <h3>{{ 'records.incidents.formSettings.notification' | translate }}</h3>
  <p class="link-hint">{{ 'records.incidents.formSettings.notifications' | translate }}</p>
  <mat-checkbox [(ngModel)]="settings.notifySupervisor">{{ 'records.incidents.formSettings.notifySupervisor' | translate
    }}</mat-checkbox>
  <mat-checkbox [(ngModel)]="settings.notifyDpo">{{ 'records.incidents.formSettings.notifyDpo' | translate }}
  </mat-checkbox>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button [mat-dialog-close]="null">{{ 'word.cancel' | translate }}</button>
  <button mat-button color="primary" (click)="save()">{{ 'word.save' | translate }}</button>
</mat-dialog-actions>

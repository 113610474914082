<div class="contents">
  <h1 class="title">
    {{'user-management.title' | translate}}
  </h1>
  <br><br>
  <p innerHTML="{{'user-management.message' | translate}}">
  </p>
  <p *ngIf="!accessLevel.write" class="warning">
    <span>{{'word.NOTICE' | translate}}</span>
    {{'word.readonlyAccess' | translate}}
  </p>
  <br><br>
  <div class="users">
    <div class="user" *ngFor="let user of users" (click)="openUserDialog(user)">
      <div>
        <div class="info access">
          <user-avatar [user]="user"></user-avatar>
          <mat-icon *ngIf="user.access == 'user-management.role.controller.manager'"
            matTooltip="{{'user-management.access.readAll-writeAll.tooltip' | translate}}"
            color="accent">
            visibility
          </mat-icon>
          <mat-icon *ngIf="user.access != 'user-management.role.controller.manager'"
            matTooltip="{{'user-management.access.readAll-writeDep.tooltip' | translate}}"
            color="accent" class="off">
            visibility_off
          </mat-icon>
        </div>

        <div class="info name">
        {{user.firstName}} {{user.lastName}}
        </div>
        <div class="info email">
          {{user.email}}
        </div>
        <div class="info department" *ngIf="userDepartment(user)">
          <a>{{userDepartment(user).name}}</a>
        </div>
        <div class="info department no-dep" *ngIf="!userDepartment(user)">
          {{'user-management.no-department' | translate}}
        </div>
      </div>
    </div>

    <ng-container *ngFor="let invite of invites">
      <div class="user invite"
          *ngIf="invite.status == 'users.invite.issued'"
          (click)="openInviteDialog(invite)"
          matTooltip="{{'user-management.invited' | translate}}">
        <div>
          <div class="info access">
            <mat-icon *ngIf="invite.access == 'user-management.role.controller.manager'"
              matTooltip="{{'user-management.access.readAll-writeAll.tooltip' | translate}}"
              color="accent">
              visibility
            </mat-icon>
            <mat-icon *ngIf="invite.access !== 'user-management.role.controller.manager'"
              matTooltip="{{'user-management.access.readAll-writeDep.tooltip' | translate}}"
              color="accent" class="off">
              visibility_off
            </mat-icon>
          </div>
          <div class="info name">
            {{invite.firstName}} {{invite.lastName}}
          </div>
          <div class="info email">
            {{invite.email}}
          </div>
          <div class="info department" *ngIf="userDepartment(invite)">
            {{userDepartment(invite).name}}
          </div>
          <div class="info department no-dep" *ngIf="!userDepartment(invite)">
            {{'user-management.no-department' | translate}}
          </div>
        </div>
      </div>
    </ng-container>

    <div class="user invite" matTooltip="{{'user-management.inviteMore' | translate}}" (click)="newInvitation()" *ngIf="accessLevel.write">
      <mat-icon class="add">person_add</mat-icon>
    </div>
  </div>
</div>

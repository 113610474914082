import {
    Component,
    OnInit,
    OnDestroy,
    AfterViewInit,
    Inject,
    ViewChild
  } from "@angular/core";
  import { Validators } from "@angular/forms";
  import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
  
  import { AppConfig } from "../../../../app.config";
  import { BusService } from "../../../../services/bus.service";
  import { EventsService } from "../../../../services/events.service";
  
  import { Router } from "@angular/router";
  import { HttpEvent, HttpResponse } from "@angular/common/http";
  import { UserManagementService } from "app/services/user/user-management.service";
  import { Event, getEntityIdentifier, getEventShortCategory } from "../event";
  
  @Component({
    selector: "app-actions-details-dialog",
    templateUrl: "./details-dialog.component.html",
    styleUrls: ["./details-dialog.component.scss"]
  })
  export class ActionDetailsDialogComponent
    implements OnInit, OnDestroy, AfterViewInit {
    users = [];
    event: Event;
  
    identifier: string;
    category: string;
  
    constructor(
      private dialog: MatDialog,
      private dialogRef: MatDialogRef<ActionDetailsDialogComponent>,
      private bus: BusService,
      private events: EventsService,
      private router: Router,
      private userService: UserManagementService,
      @Inject(MAT_DIALOG_DATA) private data: any
    ) {
      this.event = data.event;
  
      this.setIdentifier();
      this.setCategory();
    }
  
    ngOnInit() {
      this.subscribe();
      this.userService.users(true).subscribe(response => {
        this.users = response;
        console.log(this.user);
      });
    }
  
    ngOnDestroy() {
      this.unsubscribe();
    }
  
    ngAfterViewInit() {}
  
    getTranslatableFieldName() {
      // todo: skip for now
    }

    get user() {
      const candidates = this.users.filter(user => user.id === this.event.userId); 
      return candidates.length > 0 ? candidates[0] : null;
    }
  
    get directoryId() {
      const candidates = this.users.filter(user => user.id === this.event.userId);
      if (candidates.length > 0) {
        return '(' + candidates[0].directoryId + ')';
      }
      return '';
    }
  
    get hasUsableMetadata() {
      if (this.event.metadata.changes && this.event.metadata.changes.length > 0) {
        return true;
      }
  
      return false;
    }
  
    jsonPretty(value) {
      return JSON.stringify(value, null, 2);
    }
  
    setIdentifier() {
      this.identifier = getEntityIdentifier(this.event);
    }
  
    setCategory() {
      this.category = getEventShortCategory(this.event);
    }
  
    subscribe() {}
  
    unsubscribe() {}
  }
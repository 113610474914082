<h1 mat-dialog-title>{{ 'pa.details.chooseDate.title' | translate }}</h1>

<mat-dialog-content>
  <!-- {{ 'pa.details.chooseDate.generic' | translate }} -->
  <p>{{ data.explanation || ('pa.details.chooseDate.generic' | translate)}}</p>

  <mat-form-field>
    <input matInput required [matDatepicker]="datePicker" [formControl]="selectedDate" placeholder="{{'pa.details.chooseDate.placeholder' | translate}}">
    <mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
    <mat-datepicker #datePicker></mat-datepicker>
  </mat-form-field>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button color="warn" (click)="reset()" tabindex="1">{{ 'pa.details.chooseDate.reset' | translate }}</button>
  <button mat-button (click)="close()" tabindex="2">{{ 'word.cancel' | translate }}</button>
  <button mat-button color="primary" (click)="save()" tabindex="99">{{ 'word.save' | translate }}</button>
</mat-dialog-actions>

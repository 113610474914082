<div class="lia-dialog">
  <h1 mat-dialog-title>{{'pa.legitimateInterestAssessment.dialog.title' | translate}}</h1>

  <mat-dialog-content>
    <div style="display: flex; flex-direction: column;" [formGroup]="liaFormGroup">

      <mat-form-field>
        <input matInput placeholder="{{'pa.legitimateInterestAssessment.dialog.purpose.question' | translate}}"
        formControlName="purpose" (focusout)="onInputFocusOut('purpose')" />
      </mat-form-field>

      <mat-form-field>
        <input matInput placeholder="{{'pa.legitimateInterestAssessment.dialog.benefits.question' | translate}}"
        formControlName="benefit" (focusout)="onInputFocusOut('benefit')" />
      </mat-form-field>

      <div class="radio-select">
        <p>
          {{'pa.legitimateInterestAssessment.dialog.otherParties.question' | translate}}
        </p>

        <mat-radio-group [value]="liaFormGroup.get('otherParties').value" (change)="onOptionChange('otherParties', $event)">
          <mat-radio-button [value]="true">{{'yesno.yes' | translate}}</mat-radio-button>
          <mat-radio-button [value]="false">{{'yesno.no' | translate}}</mat-radio-button>
        </mat-radio-group>
      </div>

      <mat-form-field *ngIf="liaFormGroup.get('otherParties').value">
        <input matInput placeholder="{{'pa.legitimateInterestAssessment.dialog.otherPartiesList.question' | translate}}"
        formControlName="otherPartiesList" (focusout)="onInputFocusOut('otherPartiesList')" />
      </mat-form-field>

      <!-- <hr> -->

      <div class="radio-select">
        <p>
          {{'pa.legitimateInterestAssessment.dialog.publicInterest.question' | translate}}
        </p>

        <mat-radio-group [value]="liaFormGroup.get('publicInterest').value" (change)="onOptionChange('publicInterest', $event)">
          <mat-radio-button [value]="true">{{'yesno.yes' | translate}}</mat-radio-button>
          <mat-radio-button [value]="false">{{'yesno.no' | translate}}</mat-radio-button>
        </mat-radio-group>
      </div>

      <!-- <hr> -->

      <mat-form-field>
        <input matInput placeholder="{{'pa.legitimateInterestAssessment.dialog.importanceOfBenefits.question' | translate}}"
        formControlName="importanceOfBenefits" (focusout)="onInputFocusOut('importanceOfBenefits')" />
      </mat-form-field>

      <div class="radio-select">
        <p>
          {{'pa.legitimateInterestAssessment.dialog.controllerAdheres.question' | translate}}
        </p>

        <mat-radio-group [value]="liaFormGroup.get('controllerAdheres').value" (change)="onOptionChange('controllerAdheres', $event)">
          <mat-radio-button [value]="true">{{'yesno.yes' | translate}}</mat-radio-button>
          <mat-radio-button [value]="false">{{'yesno.no' | translate}}</mat-radio-button>
        </mat-radio-group>
      </div>

      <!-- <hr> -->

      <div class="radio-select">
        <p>
          {{'pa.legitimateInterestAssessment.dialog.assistsTarget.question' | translate}}
        </p>

        <mat-radio-group [value]="liaFormGroup.get('assistsAchievingTargetBenefits').value" (change)="onOptionChange('assistsAchievingTargetBenefits', $event)">
          <mat-radio-button [value]="true">{{'yesno.yes' | translate}}</mat-radio-button>
          <mat-radio-button [value]="false">{{'yesno.no' | translate}}</mat-radio-button>
        </mat-radio-group>
      </div>

      <!-- <hr> -->

      <div class="radio-select">
        <p>
          {{'pa.legitimateInterestAssessment.dialog.targetCanBenefit.question' | translate}}
        </p>

        <mat-radio-group [value]="liaFormGroup.get('targetCanBenefit').value" (change)="onOptionChange('targetCanBenefit', $event)">
          <mat-radio-button [value]="true">{{'yesno.yes' | translate}}</mat-radio-button>
          <mat-radio-button [value]="false">{{'yesno.no' | translate}}</mat-radio-button>
        </mat-radio-group>
      </div>

      <!-- <hr> -->

      <div class="radio-select">
        <p>
          {{'pa.legitimateInterestAssessment.dialog.minimallyInvasive.question' | translate}}
        </p>

        <mat-radio-group [value]="liaFormGroup.get('minimallyInvasive').value" (change)="onOptionChange('minimallyInvasive', $event)">
          <mat-radio-button [value]="true">{{'yesno.yes' | translate}}</mat-radio-button>
          <mat-radio-button [value]="false">{{'yesno.no' | translate}}</mat-radio-button>
        </mat-radio-group>
      </div>

    </div>

    <br>
    <mat-divider></mat-divider>

    <h4>
      {{'pa.legitimateInterestAssessment.dialog.preview' | translate}}
    </h4>
    <div class="lia-preview" [innerHtml]="getLiaPreview()">
    </div>

  </mat-dialog-content>

  <!-- <hr> -->

  <mat-dialog-actions align="end">
    <button mat-flat-button color="error" matDialogClose>{{'word.cancel' | translate}}</button>
    <button mat-flat-button color="primary" (click)="submitLia()">{{'pa.legitimateInterestAssessment.dialog.useAssessment' | translate}}</button>
  </mat-dialog-actions>
</div>

<mat-dialog-content *ngIf='todo !== undefined'>

  <div class='top-row'>
    <div class='title'>
      {{title}}
    </div>
    <div class='buttons'>
      <button mat-icon-button (click)='edit($event)'>
        <mat-icon>edit</mat-icon>
      </button>
      <button mat-icon-button (click)='close()'>
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>

  <div class='desc' commentable comment-subject-codename='todo-{{todo.id}}'
       comment-subject-title='{{title}}' current-todo='{{todo.id}}' [comment-mention-list]='[todo.issuerId, todo.assigneeId]'>
    {{description}}
  </div>

  <div class='todo-info'>
    <div class='info' *ngIf="todo.dueDate"><!-- due date -->
      <b>{{'todos.dueIn'|translate}}</b> {{todo.dueDate | localizedDate}}
    </div>
    <mat-divider></mat-divider>
    <div class='info'><!-- issuer -->
      <b>{{'todos.issuedBy'|translate}}</b> {{todo.issuer.firstName}} {{todo.issuer.lastName}}
    </div>
    <mat-divider></mat-divider>
    <div class='info'><!-- assignee -->
      <b>{{'todos.assignedTo'|translate}}</b> {{todo.assignee.firstName}} {{todo.assignee.lastName}}
    </div>
    <mat-divider></mat-divider>
    <div class='info'><!-- created at -->
      <b>{{'todos.createdIn'|translate}}</b> {{todo.createdAt | localizedDate}}
    </div>
    <mat-divider></mat-divider>
    <div class='info'><!-- last updated -->
      <b>{{'todos.updatedIn'|translate}}</b> {{todo.lastChange | localizedDate}}
    </div>
  </div>

  <div class='top-row'>
    <!--    <button mat-stroked-button>{{'todos.reply'|translate}}</button>-->
    <div>
      <comment-button [commentButton]='true' comment-subject-codename='todo-{{todo.id}}'
                      comment-subject-title='{{todo.title}}' current-todo='{{todo.id}}'></comment-button>
    </div>
    <div>
      <button mat-stroked-button class='left-button' *ngIf='todo.done' (click)='reopen($event)'>{{'todos.reopen'|translate}}</button>
      <button mat-stroked-button class='left-button' *ngIf='!todo.done' (click)='markDone($event)'>{{'todos.markDone'|translate}}</button>
      <button mat-stroked-button *ngIf='todo.link && !todo.done' (click)='goToTask()' class='go-to'>{{'todos.goTo'|translate}}</button>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-content *ngIf='!todo'>
  This todo does not exist or has been marked as complete.
</mat-dialog-content>

<div class="under-construction">
  <h1 class="title">{{ 'under-construction.titel' | translate}}</h1>
  <br/>
  <mat-card>
    <img mat-card-image src="assets/under-construction.svg"/>
    <mat-card-content>
      {{ 'under-construction.message' | translate}}
    </mat-card-content>
    <mat-card-actions align="end">
      <button mat-button color="primary" routerLink="/rpa">{{ 'under-construction.checkoutnewfeatures' | translate}}</button>
    </mat-card-actions>
  </mat-card>
</div>

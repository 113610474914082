import {Component, OnInit, OnDestroy} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {BusService} from '../../../services/bus.service';
import {EventsService} from '../../../services/events.service';
import { AccessLevelService, AccessLevel } from '../../../services/user/access-level.service';

@Component({
  selector: 'app-controller',
  templateUrl: './controller.component.html',
  styleUrls: ['./controller.component.scss']
})
export class ControllerComponent implements OnInit, OnDestroy {

  formGroup: FormGroup;
  private timeout: any;
  fieldsBeingSaved = {};
  accessLevel: AccessLevel;
  controller: any;

  constructor(private bus: BusService,
              private events: EventsService,
              private accessService: AccessLevelService,
            ) {

    this.formGroup = new FormGroup({
      controllerName: new FormControl('', [Validators.required]),
      controllerUrl: new FormControl('', [Validators.required]),
      controllerAddressStreet: new FormControl('', [Validators.required]),
      controllerAddressPostcode: new FormControl('', [Validators.required]),
      controllerAddressCity: new FormControl('', [Validators.required]),
      controllerAddressCountry: new FormControl('', [Validators.required]),
      controllerContactName: new FormControl('', [Validators.required]),
      controllerContactRole: new FormControl('', [Validators.required]),
      controllerDpCoordination: new FormControl('', [Validators.required]),
      controllerEmailAddress: new FormControl('', [Validators.required]),
      controllerNumEmployees: new FormControl('', [Validators.required]),
      controllerTelephoneNumber: new FormControl('', [Validators.required]),
      provisions: new FormGroup({
        backups: new FormControl('', []),
        guidelines: new FormControl('', []),
        itSecurity: new FormControl('', []),
        permissions: new FormControl('', []),
        deletion: new FormControl('', []),
      }),
      thirdCountryTransfers: new FormControl('hidden', [])
    });

    this.accessService.checkAccess({
      context: 'controller',
    }).subscribe(response => this.accessLevel = response);
  }

  private fetch(data) {
    this.controller = data;
    console.log(data);
    this.formGroup.patchValue({
      controllerName: data.controllerName,
      controllerUrl: data.controllerUrl,
      controllerAddressStreet: data.controllerAddressStreet,
      controllerAddressPostcode: data.controllerAddressPostcode,
      controllerAddressCity: data.controllerAddressCity,
      controllerAddressCountry: data.controllerAddressCountry,
      controllerContactName: data.controllerContactName,
      controllerDpCoordination: data.controllerDpCoordination,
      controllerContactRole: data.controllerContactRole,
      controllerEmailAddress: data.controllerEmailAddress,
      controllerNumEmployees: data.controllerNumEmployees,
      controllerTelephoneNumber: data.controllerTelephoneNumber,
      provisions: data.provisions,
      thirdCountryTransfers: data.thirdCountryTransfers
    });
  }

  public submit(field?): void {
    clearTimeout(this.timeout);
    if (field) {
      this.fieldsBeingSaved[field] = true;
    }
    this.timeout = setTimeout( () => {
      if (field) {
        this.fieldsBeingSaved[field] = false;
      }
      this.controllerPut();
    }, 1000);
  }

  private controllerPut(): void {
    this.bus.publish(this.events.requested.data.controller.update,
      { ...this.controller, ... this.formGroup.value });
  }

  private subscribe(): void {
    this.bus.subscribe(this.events.received.data.controller.get.success, this.fetch, this);
  }

  private unsubscribe(): void {
    this.bus.unsubscribe(this.events.received.data.controller.get.success, this.fetch);
  }

  public get canWrite() {
    return this.accessLevel && this.accessLevel.write;
  }

  ngOnInit() {
    this.subscribe();

    this.bus.publish(this.events.requested.data.controller.get);
  }

  ngOnDestroy() {
    this.unsubscribe();
  }

}

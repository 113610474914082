import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FullDpiaMeasure } from '../../pa-details.component';

@Component({
  selector: 'app-full-dpia-edit-measure',
  templateUrl: './edit-measure.component.html',
  styleUrls: ['./edit-measure.component.scss']
})
export class FullDpiaEditMeasureComponent implements OnInit {

  formGroup = new FormGroup({
    risk: new FormControl(''),
    description: new FormControl(''),
    effect: new FormControl(''),
    residualRisk: new FormControl('')
  });

  constructor(
    public ref: MatDialogRef<FullDpiaEditMeasureComponent>,
    @Inject(MAT_DIALOG_DATA) data: { measure: FullDpiaMeasure }
  ) {
    this.formGroup.patchValue(data.measure);
  }

  done() {
    this.ref.close(this.formGroup.value);
  }


  ngOnInit(): void {
  }

}

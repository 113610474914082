import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { BusService } from '../../services/bus.service';
import { EventsService } from '../../services/events.service';
import { AuthenticationService } from '../../services/authentication.service';

import { MatDialog } from '@angular/material/dialog';
import { ClientSupervisionService } from '../../services/supervision/client-supervision.service';
import { SupervisionTemplatesService } from '../../services/supervision/templates.service';
import { getToken } from '../../util/token';
import { DashboardService } from '../../services/dashboard.service';


@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'header-component',
  templateUrl: './header.template.html',
  styleUrls: ['./header.styles.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {
  controllerInfo = null;

  constructor(
    private router: Router,
    private bus: BusService,
    private events: EventsService,
    private auth: AuthenticationService,
    private dialog: MatDialog,
    private supervisionService: ClientSupervisionService,
    private supervisionTemplates: SupervisionTemplatesService,
    private dashboardService: DashboardService
  ) {
  }

  public applyTemplate() {
    if (this.isInSupervisionMode) {
      this.supervisionTemplates.applyTemplate(this.controllerInfo);
    }
  }

  public createTemplate() {
    if (this.controllerInfo && this.isInSupervisionMode) {
      this.supervisionTemplates.createTemplate(this.controllerInfo);
    }
  }

  get loggedIn() {
    return getToken();
  }

  get isSupervisor() {
    return this.auth.productType === 'supervisor';
  }

  get isInSupervisionMode() {
    return localStorage.getItem('isInSupervisionMode') === 'true';
  }

  public get readonly() {
    return this.dashboardService.shouldOnlyShowDashboard;
  }

  exitSupervision() {
    this.supervisionService.endSupervision();
  }

  public logout(): void {
    this.bus.publish(this.events.notified.user.logout);
    this.router.navigate(['/home']);
  }

  private setControllerInfo(basicInfo: any) {
    this.controllerInfo = basicInfo;
  }

  public subscribe(): void {
    this.bus.subscribe(this.events.received.data.basicInfo.get.success, this.setControllerInfo, this);

    this.bus.publish(this.events.requested.data.basicInfo.get);

    this.bus.subscribe(this.events.notified.supervision.client.started, () => {
      this.controllerInfo = null;
      this.bus.publish(this.events.requested.data.basicInfo.get);
    }, this);

    this.bus.subscribe(this.events.notified.supervision.client.ended, () => {
      this.controllerInfo = null;
    }, this);
  }


  public ngOnInit(): void {
    this.subscribe();
  }

  public ngOnDestroy(): void {
  }
}

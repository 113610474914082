<p>{{ 'user.two-factor.info.explanation' | translate }}</p>

<ng-container *ngIf="enabled">
  <p class="enabled-hint">{{ 'user.two-factor.info.enabled' | translate }}: {{ enabled | localizedDate }}</p>
  <a mat-stroked-button color="warn" routerLink="/account/two-factor/disable">{{ 'user.two-factor.info.disable' | translate }}</a>
</ng-container>

<ng-container *ngIf="!enabled">
  <p class="enabled-hint">{{ 'user.two-factor.info.notActive' | translate }}</p>
  <a mat-stroked-button color="primary" routerLink="/account/two-factor/enable">{{ 'user.two-factor.info.activate' | translate }}</a>
</ng-container>

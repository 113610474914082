import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-two-factor-input-dialog',
  templateUrl: './two-factor-input-dialog.component.html',
  styleUrls: ['./two-factor-input-dialog.component.scss']
})
export class TwoFactorInputDialogComponent implements OnInit {

  code = new FormControl('', [Validators.required]);

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { wrongCode: boolean },
    private dialogRef: MatDialogRef<TwoFactorInputDialogComponent>
  ) {
    if (data.wrongCode) {
      this.code.markAsTouched();
    }
  }

  ngOnInit(): void {
  }

  submit() {
    if (!this.code.valid) {
      this.code.markAsTouched();
    } else {
      this.dialogRef.close(this.code.value);
    }
  }

}

<!--
  {{ 'feature.locked.identifier.logbook' | translate }}
  {{ 'feature.locked.identifier.documents' | translate }}
  {{ 'feature.locked.identifier.document_management' | translate }}
  {{ 'feature.locked.identifier.dsar_forms' | translate }}
-->

<div class="feature-locked">
  <h1 class="title">{{ 'feature.locked.title' | translate }}</h1>
  <br>
  <mat-card>
    <img mat-card-image src="assets/not-found.svg"/>
    <mat-card-content>
      {{ 'feature.locked.explanation.1' | translate }} <a>{{ 'feature.locked.identifier.' + featureName | translate }}</a> {{ 'feature.locked.explanation.2' | translate }}
    </mat-card-content>
    <mat-card-actions align="end">
      <a mat-raised-button color="primary" routerLink="/payment/subscribe">{{ 'feature.locked.upgrade' | translate }}</a>
    </mat-card-actions>
  </mat-card>
</div>

<div class="email-login">
  <h1 class="title">{{ 'login.email.emaillogin' | translate}}</h1>
  <br>
  <mat-card *ngIf="state=='initial'">
    <img mat-card-image src="assets/email-auth.svg"/>
    <mat-card-content>
      {{ 'login.email.patient' | translate }}
      <br><br>
      <div class="spinner-container">
        <mat-spinner></mat-spinner>
      </div>
    </mat-card-content>
  </mat-card>

  <mat-card *ngIf="state=='failed'">
    <img mat-card-image src="assets/not-found.svg"/>
    {{ 'login.email.noauth' | translate }}
    <ul>
      <li>{{ 'login.email.usedlink' | translate }}</li>
      <li>{{ 'login.email.expiredlink' | translate }}</li>
    </ul>
    <mat-card-actions align="end">
      <a mat-button color="primary" routerLink="/home">{{ 'login.email.backhome' | translate }}</a>
    </mat-card-actions>
  </mat-card>
</div>

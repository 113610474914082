<div class="milestone">
  <h1 class="title">
    {{ 'milestone2.titel' | translate }}
  </h1>

  <br/><br/><br/>

  <div *ngIf="milestoneProgress < 1"
       class="unfinished">
    <div class="progress-holder">
      <progress-indicator [progress]="milestoneProgress">
      </progress-indicator>
    </div>
    <br/><br/>
    <p>
      {{ 'milestone2.unfinishedMessage' | translate }}
    </p>
    <button mat-raised-button color="primary" routerLink="">
      {{ 'word.goBack' | translate }}
    </button>
  </div>

  <ng-container *ngIf="milestoneProgress >= 1">
    <p>
      {{ 'milestone2.congratulationsMessage' | translate }}
    </p>
    <br/>
    <div class="achievements"
         fxLayout="row">
      <div fxFlex="50%">

        <mat-card class="achievement">
          <mat-card-header>
            <img mat-card-avatar src="assets/complete.svg"/>
            <mat-card-title>
              {{ 'milestone2.applicablePASubtitle' | translate }}
            </mat-card-title>
          </mat-card-header>
          <mat-card-content>
            <div *ngIf="controller">
              {{'milestone2.applicablePAMessage.part1' | translate}}
              <b>{{pasApplicable.length}}</b>
              {{'milestone2.applicablePAMessage.part2' | translate}}
              <b>{{pasTotal.length}}</b>
              {{'milestone2.applicablePAMessage.part3' | translate}}
              <a>{{controller.controllerName}}</a>{{'milestone2.applicablePAMessage.part4' | translate}}
            </div>
          </mat-card-content>
        </mat-card>

        <mat-card class="achievement">
          <mat-card-header>
            <img mat-card-avatar src="assets/complete.svg"/>
            <mat-card-title>
              {{ 'milestone2.complexitySubtitle' | translate }}
            </mat-card-title>
          </mat-card-header>

          <mat-card-content class="complex-pa">
            {{'milestone2.complexityMessage.part1' | translate}}
            <br><br>
            <span class="name">{{complexPaName}}</span>
            <br>
            <span class="stats">
              {{'milestone2.complexityMessage.part2' | translate}}
              <b>{{complexPa.external_count}}</b>
              {{'milestone2.complexityMessage.part3' | translate}}
              <b>{{complexPa.internal_count}}</b>
              {{'milestone2.complexityMessage.part4' | translate}}
            </span>
            <br><br>
          </mat-card-content>
        </mat-card>

        <mat-card class="achievement">
          <mat-card-header>
            <img mat-card-avatar src="assets/complete.svg"/>
            <mat-card-title>
              {{ 'milestone2.rpaSubtitle' | translate }}
            </mat-card-title>
          </mat-card-header>
          <mat-card-content>
            {{ 'milestone2.rpaMessage' | translate }}
            <br><br>
            <div class="upload-holder">
              <cool-upload
                [readonly]="true"
                fileLink="{{rpaLink}}">
              </cool-upload>
            </div>
          </mat-card-content>
        </mat-card>

        <mat-card class="achievement">
          <img mat-card-lg-image style="display: block; margin: 0 auto" src="/assets/badge.svg"/>
          <br>
          <mat-card-content>
            {{'milestone2.badge.message' | translate}}
          </mat-card-content>
          <mat-card-actions align="center">
            <button mat-raised-button color="primary" routerLink="/badge/get">
              {{'milestone2.badge.action' | translate}}
            </button>
          </mat-card-actions>
        </mat-card>

      </div>

      <div fxFlex="50%">

        <mat-card class="achievement">
          <mat-card-header>
            <img mat-card-avatar src="assets/complete.svg"/>
            <mat-card-title *ngIf="controller">
              {{ 'milestone2.vendorsSubtitle.part1' | translate }}
              {{controller.controllerName}}
              {{ 'milestone2.vendorsSubtitle.part2' | translate }}
            </mat-card-title>
          </mat-card-header>

          <mat-card-content>
            <ng-container *ngFor="let vendor of vendors">
              <div class="vendor">
                <div class="avatar">
                  <img *ngIf="vendor.logoUrl" [src]="vendor.logoUrl" onerror="this.onerror=null;this.src='/assets/default_vendor.svg';"/>
                  <mat-icon *ngIf="!vendor.logoUrl" class="no-logo">location_city</mat-icon>
                </div>
                <div class="info">
                  <div class="title">
                    {{vendor.name}}
                  </div>
                  <div class="address">
                    {{vendor.addressCity}}, {{vendor.addressCountry}}
                  </div>
                  <div class="email">
                    {{vendor.email}}
                  </div>
                </div>
              </div>
              <mat-divider></mat-divider>
            </ng-container>
          </mat-card-content>

        </mat-card>

        <mat-card class="achievement">
          <mat-card-header>
            <img mat-card-avatar src="assets/complete.svg"/>
            <mat-card-title>
              {{ 'milestone2.departmentsSubtitle' | translate }}
            </mat-card-title>
          </mat-card-header>

          <mat-card-content>
            <ul>
              <li>
                {{'milestone2.departmentsSubtitle.internal.part1' | translate}}
                <b>{{PAsInternallyProcessed.length}}</b>
                {{'milestone2.departmentsSubtitle.internal.part2' | translate}}
              </li>
              <li>
                {{'milestone2.departmentsSubtitle.external.part1' | translate}}
                <b>{{PAsExternallyProcessed.length}}</b>
                {{'milestone2.departmentsSubtitle.external.part2' | translate}}
              </li>
            </ul>
          </mat-card-content>
        </mat-card>

      </div>
    </div>
    <br/><br/>
    <mat-divider></mat-divider>
    <br/>
    <div class="buttons">
      <button mat-raised-button color="primary" (click)="continue()">
        {{ 'milestone1.endButton' | translate }}
      </button>
    </div>
  </ng-container>
</div>

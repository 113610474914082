<h1 mat-dialog-title>
  {{'supervision.templates.select.title' | translate }}
</h1>

<mat-dialog-content class="select-supervision-template-dialog">
  <div class="template" *ngFor="let template of templates" (click)="select(template)">
    <div class="name">{{template.name}}</div>
    <div class="source">
      {{'supervision.templates.select.createdFrom' | translate }}
      {{template.sourceName}}
    </div>
    <div class="last-update">
      {{'supervision.templates.select.lastUpdated' | translate }}
      {{template.updatedAt | localizedDate : 'short'}}
    </div>
    <button mat-icon-button (click)="delete(template, $event)">
      <mat-icon>delete_outline</mat-icon>
    </button>
  </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>{{'word.cancel' | translate}}</button>
</mat-dialog-actions>

import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserManagementService } from 'app/services/user/user-management.service';

@Component({
  selector: 'app-delete-user-dialog',
  templateUrl: './delete-user-dialog.component.html',
  styleUrls: ['./delete-user-dialog.component.scss']
})
export class DeleteUserDialogComponent implements OnInit {

  confirmEmail: ''

  constructor(
    @Inject(MAT_DIALOG_DATA) public user: any,
    private service: UserManagementService,
    private dialogRef: MatDialogRef<DeleteUserDialogComponent>,
  ) { }

  ngOnInit(): void {
  }

  get confirmed() {
    return this.user.email === this.confirmEmail;
  }

  delete() {
    this.service.deleteUser(this.user).subscribe(() => {
      this.dialogRef.close(true);
    });
  }
}

import { Injectable } from "@angular/core";
import { HttpErrorResponse } from "@angular/common/http";
import { BusService } from "../bus.service";
import {
  IGetOptions,
  IPostOptions,
  RequestService
} from "../request.service";
import { EventsService } from "../events.service";

export interface PostControllerNameRequest {
  controllerName: string;
}

export interface PostControllerNameResponse {}

export interface GetControllerNameResponse {
  controllerName: string;
}

export interface HasLogoResponse {
  hasLogo: boolean
}

export interface HasGoalsDocResponse {
  hasGoalsDoc: boolean
}

/**
 *
 * TODO: merge this with the other controller service later.
 *
 */
@Injectable()
export class _ControllerService {
  private api = {
    root: '/controllers',
    name: '/name',
    hasLogo : '/has-logo',
    hasGoalsDoc: '/has-goals-doc',
  }

  constructor(
    private bus: BusService,
    private events: EventsService,
    private requestService: RequestService
  ) {
    this.subscribe();
  }

  getControllerName() {
    this.requestService.get<GetControllerNameResponse>({
      uri: this.api.root + this.api.name,
      handlers: {
        success: response => this.bus.publish(this.events.received.data.controller.name.success, response),
        error: error => this.bus.publish(this.events.received.data.controller.name.failure, error),
      }
    });
  }

  postControllerName(event : PostControllerNameRequest) {
    this.requestService.post<PostControllerNameRequest, PostControllerNameResponse>({
      uri: this.api.root + this.api.name,
      body: event,
      handlers: {
        success: () => this.bus.publish(this.events.received.data.controller.updateName.success),
        error: error => this.bus.publish(this.events.received.data.controller.updateName.failure),
      }
    });
  }

  hasLogo() {
    this.requestService.get<HasLogoResponse>({
      uri: this.api.root + this.api.hasLogo,
      handlers: {
        success: response => this.bus.publish(this.events.received.data.controller.hasLogo.success, response),
        error: error => this.bus.publish(this.events.received.data.controller.hasLogo.failure, error),
      }
    });
  }

  hasGoalsDoc() {
    this.requestService.get<HasLogoResponse>({
      uri: this.api.root + this.api.hasGoalsDoc,
      handlers: {
        success: response => this.bus.publish(this.events.received.data.controller.hasGoalsDoc.success, response),
        error: error => this.bus.publish(this.events.received.data.controller.hasGoalsDoc.failure, error),
      }
    });
  }

  subscribe() {
    this.bus.subscribe(this.events.requested.data.controller.name, this.getControllerName.bind(this));
    this.bus.subscribe(this.events.requested.data.controller.updateName, this.postControllerName.bind(this));
    this.bus.subscribe(this.events.requested.data.controller.hasLogo, this.hasLogo.bind(this));
    this.bus.subscribe(this.events.requested.data.controller.hasGoalsDoc, this.hasGoalsDoc.bind(this));
  }
}

import { Component, OnInit, OnDestroy } from '@angular/core';
import { EventsService } from 'app/services/events.service';
import { BusService } from 'app/services/bus.service';
import { MatDialog } from '@angular/material/dialog';
import { AppConfig } from '../../../app.config';
import { DocumentsUploadDialogComponent } from '../upload/documents-upload-dialog.component';
import { DocumentResourceService } from '../../../services/documents/document-resource.service';
import { UserService } from '../../../services/user/user.service';
import { UserManagementService } from 'app/services/user/user-management.service';
import { DeleteDocumentDialogComponent } from '../delete/delete-document-dialog.component';
import { DocumentTaglistDialogComponent } from '../taglist/document-taglist-dialog.component';


@Component({
  selector: 'app-generator-dashboard',
  templateUrl: './generator-dashboard.component.html',
  styleUrls: ['./generator-dashboard.component.scss']
})
export class GeneratorDashboardComponent implements OnInit, OnDestroy {

  constructor(
    private bus: BusService,
    private events: EventsService,
    private dialog: MatDialog,
    private docService: DocumentResourceService,
    private userService: UserManagementService,
  ) {

  }

  public ngOnInit(): void {
  }

  public ngOnDestroy(): void {
  }

}

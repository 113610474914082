import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Audit, AuditConfiguration, AuditQuestionnaire, AuditsService, AuditType, CategoryContent } from 'app/services/audits/audits.service';
import { UserManagementService } from 'app/services/user/user-management.service';
import {combineLatest, of, zip} from 'rxjs';
import {catchError, combineAll, map, share} from 'rxjs/operators';
import { AppConfig } from '../../../app.config';
import { BusService } from '../../../services/bus.service';
import { EventsService } from '../../../services/events.service';
import { ShareDocumentDialogComponent } from '../../documents/shared/share-dialog/share-dialog.component';
import { AuditListNewDialogComponent } from '../list/new/new.dialog.component';
import { ChangeAuditTypeDialogComponent } from './change-type-dialog/change-type-dialog.component';
import {AccessLevelService} from "../../../services/user/access-level.service";
import {AuthenticationService} from "../../../services/authentication.service";
import {EditAuditTitleDialogComponent} from "./edit-audit-title-dialog/edit-audit-title-dialog.component";
import {TodoService} from "../../../services/todos/todo.service";

@Component({
  selector: 'app-audit-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss'],
})
export class AuditFormComponent implements OnInit, OnDestroy {
  auditId: number = null;

  audit: Audit = null;
  questionnaire: AuditQuestionnaire = null;
  auditConfiguration: AuditConfiguration = null;

  users = [];

  readonly = true;

  currentUserId = parseInt(localStorage.getItem('currentUserID') || '0', 10);
  ownAuditTodos = [];

  constructor(
    private auditService: AuditsService,
    private userService: UserManagementService,
    private activatedRoute: ActivatedRoute,
    private accessLevel: AccessLevelService,
    private dialog: MatDialog,
    private events: EventsService,
    private bus: BusService,
    private todoService: TodoService,
    private authService: AuthenticationService
  ) {}

  get isSupervisor() {
    return localStorage.getItem('isInSupervisionMode') === 'true';
  }


  fetchAudit() {
    if (this.auditId) {
      zip(
        this.auditService.getAuditById(this.auditId),
        this.auditService.getQuestionsForAuditById(this.auditId)
      )
      .pipe(
          map(([audit, questionnaire]) => ({ audit, questionnaire }))
      )
      .subscribe(result => {
          this.audit = result.audit;
          this.questionnaire = result.questionnaire;
          this.auditConfiguration = this.questionnaire;
      });
    }
  }

  editTitleDialog() {
    this.dialog.open(EditAuditTitleDialogComponent, {
      width: '450px',
      data: this.audit
    }).afterClosed().subscribe(updated => {
      if (updated) {
        this.audit.title = updated.title;
      }
    });
  }

  delegate() {
    this.auditService.delegateAudit(this.audit);
  }

  closeTodos() {
    combineLatest(this.ownAuditTodos.map(t => this.todoService.markTodoAsDone(t.id)))
      .subscribe((_) => {
        this.ownAuditTodos = [];
      });
  }

  get downloadLink() {
    return this.auditService.auditDownloadLink(this.auditId);
  }

  shareReport() {
    this.dialog.open(ShareDocumentDialogComponent, { data: { sourceUrl: this.downloadLink }, width: '450px' });
  }

  ngOnInit() {
    this.activatedRoute.params.subscribe(params => {
        const auditId = params['id'];

        if (auditId) {
          this.auditId = parseInt(auditId, 10);
        }
        this.fetchAudit();
    });

    this.userService.users(true).subscribe((response) => {
      this.users = response.map((user) =>
        Object.assign({}, user, {
          name: `${user.firstName} ${user.lastName}`,
        })
      );
    });

    this.accessLevel.checkAccess({ context: 'audit' })
      .subscribe((access) => {
        this.readonly = !access.write;
      });

    this.loadAuditTodo();

    this.bus.subscribe(this.events.received.data.audits.answer.success, this.updateAnswer.bind(this));
  }

  /**
   * EC-2496: Update the base audit answers to always have the current state (otherwise collapsing a category and opening it doesn't reflect the changes)
   * @param param the payload with auditId, fieldName and value
   */
  updateAnswer({ auditId, fieldName, value }) {
    // just to make sure we update the right audit (in case any really late responses may arrive)
    if (this.auditId === auditId) {
      this.audit.answers[fieldName] = value;
    }
  }

  ngOnDestroy() {
    this.bus.unsubscribe(this.events.received.data.audits.answer.success, this.updateAnswer);
  }

  private loadAuditTodo() {
    const subject = `audit-${this.auditId}`;

    this.todoService.fetchTodos({ done: false, subject })
      .subscribe((todos) => {
        const auditTodos =  todos.filter(t => t.assigneeId == this.currentUserId);
        if (auditTodos.length > 0) {
          this.ownAuditTodos = todos.filter(t => t.assigneeId == this.currentUserId);
        }
      });
  }
}

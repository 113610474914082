import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Document, DocumentResourceService } from '../../../../services/documents/document-resource.service';
import { SharedDocument, SharedDocumentsService } from '../../../../services/documents/shared.service';
import { downloadAndShare } from '../../../../util/shareDocuments';
import {zip} from "rxjs";
import {DialogComponent} from "../../../dialog/dialog.component";

@Component({
  selector: 'app-share-document-dialog',
  templateUrl: './share-dialog.component.html',
  styleUrls: ['./share-dialog.component.scss']
})
export class ShareDocumentDialogComponent implements OnInit {

  fileName = new FormControl('', [Validators.required]);
  shareMessage = new FormControl('');

  shares: SharedDocument[] = [];

  pendingUpload = false;
  pendingShare = false;

  constructor(
    private dialogRef: MatDialogRef<ShareDocumentDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: { document: Document; sourceUrl?: string },
    private docService: DocumentResourceService,
    private sharedDocumentService: SharedDocumentsService,
    private documentService: DocumentResourceService,
    private translate: TranslateService,
    private http: HttpClient,
    private dialog: MatDialog
  ) {
    if (this.data.sourceUrl) {
      this.pendingUpload = true;
      downloadAndShare(this.data.sourceUrl, 'document', this.sharedDocumentService, this.translate, this.http, this.dialog)
        .subscribe((response) => {
          if (response) {
            this.data.document = { id: response.id, fileName: response.fileName } as any;
            this.init();
            this.pendingUpload = false;
          } else {
            // could not load document, close share modal
            this.dialogRef.close(false);
          }
        }, (error) => {
          this.dialogRef.close(false);
        });
    } else {
      this.init();
    }
  }


  ngOnInit(): void {
  }

  cancel() {
    // delete document uploaded for sharing if the user doesn't want to share it
    if (this.data.sourceUrl && !this.pendingUpload && this.data.document.id) {
      this.documentService.deleteDocument(this.data.document)
        .subscribe(() => {
          this.dialogRef.close(false);
        });
    } else {
      this.dialogRef.close(false);
    }
  }

  share() {
    this.pendingShare = true;

    this.sharedDocumentService.shareExistingDocument(this.data.document.id, this.fileName.value, this.shareMessage.value)
      .subscribe((success) => {
        zip(
          this.translate.get('documents.shareWithClient.successTitle'),
          this.translate.get('documents.shareWithClient.successMessage', { fileName: this.fileName.value, today: new Date().toLocaleDateString() })
        )
        .subscribe(([title, message]) => {
          this.pendingShare = false;
          this.dialogRef.close(true);
          this.dialog.open(DialogComponent, { maxWidth: '450px', data: { header: title, html: message } });
        });
      });
  }

  private init() {
    this.fileName.setValue(this.data.document.fileName);
    this.sharedDocumentService.getSharesForDocument(this.data.document.id)
      .subscribe((shares) => {
        this.shares = shares;
      });
  }
}

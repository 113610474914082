<h1 mat-dialog-title>
  {{ 'payment.upgrade-3ds-issue-dialog.title' | translate }}
</h1>

<mat-dialog-content>
  {{'payment.upgrade-3ds-issue-dialog.text.pt1' | translate}}
  <!-- As a result of the -->
  <a href="https://en.wikipedia.org/wiki/Strong_customer_authentication" target="_blank">
  {{'payment.upgrade-3ds-issue-dialog.text.pt2' | translate}}
  <!-- Strong Customer Authentication Act -->
  </a>
  {{'payment.upgrade-3ds-issue-dialog.text.pt3' | translate}}
  <!--
  that has recently been enforced in the EU, you need to confirm this payment
  with your bank first. An invoice for the upgrade
  has been created and sent to you. Confirm the invoice, and our support team will be notified and upgrade
  your plan as soon as possible.
  -->
  <br><br>
  {{'payment.upgrade-3ds-issue-dialog.text.pt4' | translate}}
  <!--
  Please DO NOT retry upgrading until notified by our support team. If you have received multiple invoices
  on this front, please only confirm one of them.
  -->
  <br><br>
  {{'payment.upgrade-3ds-issue-dialog.text.pt5' | translate}}
  <!--
  We appologize for any inconvenience
  this might incur on you in advance, and assure you that our teams are hard at work to make the process of
  compliance with the new regulation as smooth as possible.
  -->
</mat-dialog-content>

<mat-dialog-actions align="end">
  <a routerLink="/" mat-flat-button color="primary" (click)="ref.close()">
    {{ 'payment.upgrade-3ds-issue-dialog.action' | translate }}
  </a>
</mat-dialog-actions>

import { Injectable } from '@angular/core';

import {HttpErrorResponse} from '@angular/common/http';
import {BusService} from '../bus.service';
import {EventsService} from '../events.service';
import {
  IGetOptions,
  IPostOptions,
  RequestService
} from '../request.service';
import { map } from 'rxjs/operators';

export enum CommentTypes {
  text = 'comments.type.text',
  close = 'comments.type.close',
  reminder = 'comments.type.reminder',
  reminderResolved = 'comments.type.reminder-resolved',
}

export interface NewComment {
  subject: Subject;
  text?: string;
  mentions?: { id: number; }[];
  type: CommentTypes;
}

export interface Comment {
  id: number;
  authorId: number;
  date: string | Date;
  text: string;
  type: CommentTypes;
}

export interface Subject {
  title?: string;
  host?: string;
  codename: string;
  open: boolean;
  reminder: boolean;
  lastReminderLink?: string;
  lastReminderDate?: string;
  lastReminderTitle?: string;
  comments: Comment[];
}


export interface RemindedSubject extends Subject {
  reminder: true;
  lastComment?: {
    text: string;
    date: string | Date;
    author: {
      firstName: string;
      lastName: string;
    }
  };
  lastReminder: {
    date: string | Date;
    author: {
      firstName: string;
      lastName: string;
    }
  };
}


@Injectable()
export class CommentService {
  api = {
    root: '/comments',
    reminders: '/reminders',
  };

  constructor(
    private bus: BusService,
    private events: EventsService,
    private requestService: RequestService
  ) {
    this.subscribe();
  }

  get(subject: string) {
    return this.requestService.get<Subject>({
      uri: this.api.root + `/${subject}/`,
      handlers: {
        success: sub => this.bus.publish(this.events.received.data.comments.get.success, sub),
        error: error => this.bus.publish(this.events.received.data.comments.get.failure, error),
      }
    }).pipe(map(sub => {
      sub.comments = sub.comments?.map(comment => {
        comment.date = new Date(comment.date);
        return comment;
      });
      return sub;
    }));
  }

  post(comment: NewComment) {
    return this.requestService.post<NewComment, void>({
      uri: this.api.root + `/${comment.subject.codename}/`,
      body: comment,
      handlers: {
        success: () => this.bus.publish(this.events.received.data.comments.post.success, comment),
        error: error => this.bus.publish(this.events.received.data.comments.post.failure, error),
      }
    });
  }

  delete(id: number, subjectId: number) {
    return this.requestService.delete({
      uri: this.api.root + `/comment/${id}`,
      body: {
        subjectId
      }
    });
  }

  deleteConversation(subject: string) {
    return this.requestService.delete({
      uri: this.api.root + `/${subject}`
    });
  }

  reminders() {
    return this.requestService.get<RemindedSubject[]>({
      uri: this.api.root + this.api.reminders,
      handlers: {
        success: () => {},
        error: () => {},
      }
    }).pipe(map(subjects => subjects.map(subject => {
      subject.lastReminder.date = new Date(subject.lastReminder.date);
      if (subject.lastComment) {
        subject.lastComment.date = new Date(subject.lastComment.date);
      }

      return subject;
    })));
  }

  subscribe() {
    this.bus.subscribe(this.events.requested.data.comments.get, this.get.bind(this));
    this.bus.subscribe(this.events.requested.data.comments.post, this.post.bind(this));
  }
}

import { Component, OnInit, AfterViewInit, OnDestroy, ViewChild, ElementRef, Inject } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';

import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { EventsService } from '../../../../services/events.service';
import { BusService } from '../../../../services/bus.service';
import { ProductFeaturesService } from '../../../../services/product-features.service';
import { Vendor, VendorsService } from '../../../../services/vendors/vendors.service';


@Component({
  selector: 'app-vendor-add-dialog',
  templateUrl: './vendor-add-dialog.component.html',
  styleUrls: ['./vendor-add-dialog.component.scss']
})
export class VendorAddDialogComponent implements OnInit, AfterViewInit, OnDestroy {

  formGroup: FormGroup;
  @ViewChild('dialogContent') content: ElementRef;


  @ViewChild('nameField') nameField: ElementRef;
  @ViewChild('emailField') emailField: ElementRef;
  @ViewChild('streetField') streetField: ElementRef;
  @ViewChild('cityField') cityField: ElementRef;
  @ViewChild('postcodeField') postcodeField: ElementRef;
  @ViewChild('countryField') countryField: ElementRef;

  fieldMap: {[name: string]: ElementRef};

  vendorLibraryAccess = false;

  constructor(
    private bus: BusService,
    private events: EventsService,
    public dialogRef: MatDialogRef<VendorAddDialogComponent>,
    private feature: ProductFeaturesService,
    private vendorService: VendorsService,
    @Inject(MAT_DIALOG_DATA) private data: { vendor: Vendor }
  ) {
    this.formGroup = new FormGroup({
      name: new FormControl('', [Validators.minLength(2)]),
      addressStreet: new FormControl('', []),
      addressPostcode: new FormControl('', []),
      addressCity: new FormControl('', []),
      addressCountry: new FormControl('', []),
      type: new FormControl('vendor', []),
      email: new FormControl('', [Validators.email]),
    });

    if (this.data && this.data.vendor) {
      this.formGroup.patchValue(this.data.vendor);
    }
  }

  get required() {
    return this.formGroup.value.type === 'vendor' && !this.vendorLibraryAccess;
  }

  save() {
    Object.values(this.formGroup.controls).forEach(control => control.markAsTouched());
    if (this.formGroup.valid) {
      if (!this.data) {
        this.bus.publish(this.events.requested.data.vendors.add, this.formGroup.value);
      } else {
        this.vendorService.update(Object.assign(this.data.vendor, this.formGroup.value))
          .subscribe((updated) => {
            this.dialogRef.close(updated);
          });
      }
    } else {
      let [name, control] = Object.entries(this.formGroup.controls).find(([_, c]) => !c.valid);
      this.fieldMap[name].nativeElement.scrollIntoView({ behavior: 'smooth' });
    }
  }

  saved(event) {
    this.dialogRef.close(event);
  }

  ngOnInit() {
    this.subscribe();
  }

  ngOnDestroy() {
    this.unsubscribe();
  }

  ngAfterViewInit() {
    this.fieldMap = {
      name: this.nameField,
      email: this.emailField,
      addressStreet: this.streetField,
      addressPostcode: this.postcodeField,
      addressCity: this.cityField,
      addressCountry: this.countryField
    };

    // EC-3297: if the customer has access to the vendor library, they can edit the vendors freely afterwards
    this.feature.hasAccessToFeatureLocal('vendor-library').subscribe(response => {
      if (response.access) {
        this.vendorLibraryAccess = true;
      }
    });
  }

  subscribe() {
    this.bus.subscribe(this.events.received.data.vendors.add.success, this.saved, this);
  }

  unsubscribe() {
    this.bus.unsubscribe(this.events.received.data.vendors.add.success, this.saved);
  }
}

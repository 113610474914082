import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { BusService } from '../../services/bus.service';
import { EventsService } from '../../services/events.service';
import { environment } from '../../../environments/environment';

import LoginModel from './login.model';
import LoginForm from './login.form';
import { TwoFactorInputDialogComponent } from './two-factor-input-dialog/two-factor-input-dialog.component';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'login-dialog',
  templateUrl: './login.dialog.template.html',
  styleUrls: ['./login.dialog.styles.scss']
})
export class LoginDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<LoginDialogComponent>,
  ) {}
}

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'login-component',
  templateUrl: './login.template.html',
  styleUrls: ['./login.styles.scss']
})
export class LoginComponent implements OnInit, OnDestroy {

  readonly ENV = environment;

  private model: LoginModel;
  private next: string = null;
  private nextParams: any = {};
  public loading = false;
  public isFormErrorMessage = false;

  form: LoginForm;

  constructor(
    private activatedRoute: ActivatedRoute,
    private bus: BusService,
    private events: EventsService,
    private dialog: MatDialog,
  ) {
    this.model = new LoginModel();
    this.form = new LoginForm(this.model);
  }

  // start form validation (to show what is wrong)
  private invalidForm(): void {
    this.form.validate();
    this.isFormErrorMessage = true;
  }

  // start validation functionality
  public submit(): void {
    this.bus.publish(this.events.notified.validation.form.aggregation, this.form.isValid);
    this.bus.publish(this.events.notified.validation.form.action);
  }

  // start login request
  private login(): void {
    if (this.loading) {
      return;
    }

    this.bus.publish(this.events.requested.data.authentication, {
      email: this.model.email,
      password: this.model.password,
      code: this.model.code,
      next: this.next,
      nextParams: this.nextParams,
    });
    this.loading = true;
  }

  private loginFailed(error): void {
    console.log(error);

    if (['error.user.codeRequired', 'error.user.invalidCode'].includes(error.error)) {
      this.dialog.open(TwoFactorInputDialogComponent, { width: '400px', data: { wrongCode: error.error === 'error.user.invalidCode' } })
        .afterClosed()
        .subscribe((code: string) => {
          this.loading = false;
          if (code) {
            this.model.code = code;
            this.login();
          } else {
            this.model.code = null;
          }
        });
    } else {
      this.loading = false;
      this.dialog.open(LoginDialogComponent, {
        width: '350px',
        maxHeight: '90vh',
        data: {'success': false}
      })
      .afterClosed().subscribe(() => {
        setTimeout(() => this.loading = false, 100);
      });
    }

  }

  private loginSuccessful(): void {
    this.loading = false;
  }

  public paramsContain(param: string): boolean {
    if (this.nextParams[param]) {
      return true;
    }

    return false;
  }

  get registerParams() {
    if (this.paramsContain('clientInvite')) {
      return  {clientInvite: this.nextParams.clientInvite};
    }

    return {};
  }

  // subscribe on validation success/failure
  public subscribe(): void {
    this.bus.subscribe(this.events.received.validation.failure, this.invalidForm, this);
    this.bus.subscribe(this.events.received.validation.success, this.login, this);
    this.bus.subscribe(this.events.received.authentication.failure, this.loginFailed, this);
    this.bus.subscribe(this.events.received.authentication.success, this.loginSuccessful, this);
  }

  // unsubscribe on validation success/failure
  public unSubscribe(): void {
    this.bus.unsubscribe(this.events.received.validation.failure, this.invalidForm);
    this.bus.unsubscribe(this.events.received.validation.success, this.login);
    this.bus.unsubscribe(this.events.received.authentication.failure, this.loginFailed);
    this.bus.unsubscribe(this.events.received.authentication.success, this.loginSuccessful);
  }

  // make subscribe on a component initialization
  public ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe((params: Params) => {
      if ('email' in params) {
        this.form.patchForm({
          email: params['email']
        });
      }

      if ('next' in params) {
        this.next = params['next'];
        this.nextParams = Object.assign({}, params);
        delete this.nextParams['next'];

        console.log(this.nextParams);
      }
    });
    this.subscribe();
    this.bus.publish(this.events.ui.requested.sidenav.expand);
  }

  // make unsubscribe before destroying component
  public ngOnDestroy(): void {
    this.unSubscribe();
    this.bus.publish(this.events.ui.requested.sidenav.collapse);
  }
}

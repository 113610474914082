import {_} from '../../../trans';


export class InfoRPAComponentModel {
  public department: string;
  public processingActivityName: string;
}

export class InfoRPAComponentAnswersModel {
  public PAAnswers: any = [];
}

export const PAForms = [
  {
    'title': _('pa.basicInformation.titel'),
    'description': _('pa.basicInformation.description'), //'This section should contain basic information about the processing activity.',
    'fields': [
      {
        'field': 'paCreatedOn',
        'description': _('pa.createdOn.description'), //'﻿Created On'
        'tip': _('pa.createdOn.tip'), //'When was this activity first created? This is the date when the processing activity was created. If you do not know when was the processing activity created, you can start from the day you started documenting this processing activity.',
        'type': 'datepicker',
        'fieldName': _('pa.createdOn.fieldName'), //'Created On',
        'placeholder': _('pa.placeholder.type'), //'Type...'
      },
      {
        'field': 'paLastChangedOn',
        'description': _('pa.lastChangedOn.description'), //'Last Changed On',
        'tip': _('pa.lastChangedOn.tip'), //'When was this activity last changed? This is the last date you updated or modified the processing activity.',
        'type': 'text',
        'fieldName': _('pa.lastChangedOn.fieldName'), //'Last Changed On',
        'placeholder': '',
        'readonly': true,
      },
      {
        'field': 'paDescription',
        'description': _('pa.description.description'), //'Description of Processing Activity',
        'tip': _('pa.description.tip'), //`
                        // A description of the processing activity is, for example, IT setup for employees. In the IT setup for employees, each receives a new email address, login details and access to the software we use. The employee receives his name, email login, and an automatic password generated. Each employee after receiving these details gets an access to the company files, and fellow colleagues contact details.
                        // <br/><br/>
                        // Another example of the data processing activity is an inbound contact form, where customers land on the company’s website and reach out to understand the company’s products and services.
                        // <br/><br/>
                        // <i>Recommendation</i>: The description should be written by the respective department in a clear, concise and understandable language.
                        // `,
        'type': 'textarea',
        'fieldName': _('pa.description.fieldName'), //'Description',
        'placeholder': _('pa.placeholder.type'), //'Type...'
      },
      {
        'field': 'paResponsibleDepartment',
        'description': _('pa.responsibleDept.description'), //'Responsible Department',
        'tip': _('pa.responsibleDept.tip'), //'Name of the department this processing activity belongs to:',
        'type': 'text',
        'fieldName': _('pa.responsibleDept.fieldName'), //'Responsible Department',
        'placeholder': _('pa.placeholder.type'), //'Type...'
      },
      {
        'field': 'paContactName',
        'description': _('pa.contactName.description'), //'Name of the Processing Activity Owner',
        'tip': _('pa.contactName.tip'), //'Who is the primary contact person for this activity in your company?',
        'type': 'text',
        'fieldName': _('pa.contactName.fieldName'), //'Contact Name',
        'placeholder': _('pa.placeholder.type'), //'Type...'
      },
      {
        'field': 'paContactTelephoneNumber',
        'description': _('pa.contactTelNo.description'), //'Telephone Number of the Processing Activity Owner',
        'tip': _('pa.contactTelNo.tip'), //'How can that person be contacted by phone?',
        'type': 'text',
        'fieldName': _('pa.contactTelNo.fieldName'), //'Contact Telefon Number',
        'placeholder': _('pa.placeholder.type'), //'Type...'
      },
      {
        'field': 'paContactEmailAddress',
        'description': _('pa.contactEmail.description'), //'Email Address of the Processing Activity Owner',
        'tip': _('pa.contactEmail.tip'), //'How can that person be contacted by email?',
        'type': 'text',
        'fieldName': _('pa.contactEmail.fieldName'), //'Contact Email Address',
        'placeholder': _('pa.placeholder.type'), //'Type...'
      },
    ]
  },
  {
    'title': _('pa.purpose.titel'), //'Purpose & Legal Basis',
    'description': _('pa.purposeLegalBasis.description'), //`
          // Personal data shall be obtained and processed only for specified purposes, and shall not be further processed in any manner incompatible with those specified. Moreover every process related to personal data must be based on a legal basis which can be consent or a specific law.
          // <br/><br/>
          // <b>Purpose</b>: In practice, it means that you must:
          // <ul>
          // <li>be clear from the outset about why you are collecting personal data and what you intend to do with it;</li>
          // <li>limit the amount of personal data collected to the absolute necessary to achieve the purpose you have chosen;</li>
          // <li>make sure the data collected is correct;</li>
          // <li>be transparent about how you intend to use the data and give privacy notices to individuals when collecting their personal data;</li>
          // <li>make arrangements so that personal information is automatically deleted or anonymized once its purpose is achieved; and</li>
          // <li>keep your records of processing activities directory up to date!</li>
          // </ul>
          //
          // <b>Legal Basis</b>: In practice, it means that - for every specific purpose you need personal data - you must:
          // <ul>
          // <li>have informed consent of the individuals concerned or</li>
          // <li>legitimate grounds for collecting and using the personal data;</li>
          // <li>not use the data in ways that have unjustified adverse effects on the individuals concerned;</li>
          // <li>handle people’s personal data only in ways they would reasonably expected; and</li>
          // <li>ensure that if you wish to use or disclose the personal data for any purpose that is additional to or different from the originally specified purpose, the new use or disclosure is fair.</li>
          // </ul>
          // `,
    'fields': [
      {
        'field': 'paPurpose',
        'description': _('pa.purpose.description'), //'What are reasons for this processing activity?',
        'tip': _('pa.purpose.tip'), //`
                        // You should explain the key purpose of doing this activity. It could explain how it adds value in your business process while processing the personal information or, in case, why you are legally obliged to deal with the data in that way.
                        // <br/><br/>
                        // Examples:
                        // <ul>
                        // <li>Data processing for the purpose of transmission (address trading, provision of credit reports)</li>
                        // <li>Data processing for the purpose of anonymous transmission (market and opinion research)</li>
                        // <li>Data processing for the purpose of selecting, recruiting and employing suitable temporary help (assistants)</li>
                        // <li>Data processing for the purpose of improvement of product</li>
                        // <li>Data processing for the purpose of educating the customers</li>
                        // <li>Data processing for the purpose of working law requirements</li>
                        // <li>Data processing for the purpose of tax law requirements</li>
                        // </ul>
                        // `,
        'type': 'textarea',
        'fieldName': _('pa.purpose.fieldName'), //'Purpose',
        'placeholder': _('pa.placeholder.type'), //'Type...'
      },
      {
        'field': 'paLegalBasis',
        'description': _('pa.legalbasis.description'), //'What is the legal basis of processing this activity?',
        'tip': _('pa.legalbasis.tip'), //`
                        // Every processing activity which concerns personal data requires authorization. That is what you call “legal basis”. According to GDPR there are only the following - any processing activity which concerns personal data must fulfill the requirements of at least one:
                        // <ul>
                        // <li>Consent - any freely given, specifically purpose related, informed and unambiguous indication of the data subject's wishes by which he or she, by a statement or by a clear affirmative action, signifies agreement to the processing of personal data relating to him or her </li>
                        // <li>Processing is necessary for the performance of a contract </li>
                        // <li>Processing is necessary in order to protect vital interests - to protect an interest which is essential for the life of the data subject or that of another natural person</li>
                        // <li>Processing is necessary for the performance of a task carried out in the public interest</li>
                        // <li>Processing is permitted/required by specific legislation other than Federal Data Protection Act / GDPR, e.g.: tax law</li>
                        // <li>Most frequently: Legitimate interests - to the stake that the company processing the personal data may have in that processing. This may imply a benefit inherent in processing for that company itself or perhaps for wider society.</li>
                        // </ul>
                        // `,
        'type': 'selector',
        'multiple': true,
        'options': [
          ['pa.legalbasis.consent', _('pa.legalbasis.consent')], //'Consent'
          ['pa.legalbasis.contractualNecessity', _('pa.legalbasis.contractualNecessity')],
          ['pa.legalbasis.complianceWithOther', _('pa.legalbasis.complianceWithOther')],
          ['pa.legalbasis.vitalInterest', _('pa.legalbasis.vitalInterest')],
          ['pa.legalbasis.publicInterest', _('pa.legalbasis.publicInterest')],
          ['pa.legalbasis.legitimateInterest', _('pa.legalbasis.legitimateInterest')],
          ['pa.legalbasis.members', _('pa.legalbasis.members')],
          ['pa.legalbasis.law', _('pa.legalbasis.law')],
        ],
        'fieldName': _('pa.legalbasis.fieldName'), //'Legal Basis',
        'placeholder': _('pa.placeholder.select'), //'Select applicable options...',
      },
      {
        'field': 'paSensitiveData',
        'description': _('pa.sensitiveData.description'), //'Do you process any sensitive data in this processing activity?',
        'tip': _('pa.sensitiveData.tip'), //
      // `For example, health data, racial or ethnic origin, political opinions, religious background, trade union membership, sexual orientation, genetic data, and biometric data.
      //                   <br/><br/>
      //                   Circumstances that enable sensitive data to be processed lawfully, other than explicit consent are:
      //                   <ul>
      //                     <li>some university activities relating to employment;</li>
      //                     <li>protecting the vital interests of an individual where consent cannot be given (such as a life threatening medical emergency);</li>
      //                     <li>where the data has already been made public by the data subject;</li>
      //                     <li>for legal proceedings (including prospective legal proceedings);</li>
      //                     <li>for some medical purposes undertaken by a health professional, or equivalent;</li>
      //                     <li>for some equal opportunity purposes.</li>
      //                   </ul>
      //                   `,
        'type': 'selector',
        'options': [
          ['yesno.yes', _('yesno.yes')],
          ['yesno.no', _('yesno.no')],
          ['yesno.dontKnow', _('yesno.dontKnow')],
        ],
        'fieldName': _('pa.sensitiveData.fieldName'), //'Sensitive Data',
        'placeholder': _('pa.placeholder.select'), //'Select...'
      },
      {
        'field': 'paSensitiveDataLegalBasis',
        'description': _('pa.sensitiveDataLegalBasis.description'), //'What is the legal basis of processing the sensitive data?',
        'type': 'selector',
        'condition': {
          'field': 'paSensitiveData',
          'value': 'yesno.yes',
        },
        'multiple': true,
        'options': [
          ['pa.sensitiveData.legalBasis.explicitConsent',
            _('pa.sensitiveData.legalBasis.explicitConsent')],
          ['pa.sensitiveData.legalBasis.employmentLaw',
            _('pa.sensitiveData.legalBasis.employmentLaw')],
          ['pa.sensitiveData.legalBasis.otherCompliance',
            _('pa.sensitiveData.legalBasis.otherCompliance')],
          ['pa.sensitiveData.legalBasis.vitalInterest',
            _('pa.sensitiveData.legalBasis.vitalInterest')],
          ['pa.sensitiveData.legalBasis.publicInterest',
            _('pa.sensitiveData.legalBasis.publicInterest')],
          ['pa.sensitiveData.legalBasis.legitimateInterest',
            _('pa.sensitiveData.legalBasis.legitimateInterest')],
          ['pa.sensitiveData.legalBasis.members',
            _('pa.sensitiveData.legalBasis.members')],
          ['pa.sensitiveData.legalBasis.law',
            _('pa.sensitiveData.legalBasis.law')],
          ['pa.sensitiveData.legalBasis.charity',
            _('pa.sensitiveData.legalBasis.charity')],
          ['pa.sensitiveData.legalBasis.subject',
            _('pa.sensitiveData.legalBasis.subject')],
          ['pa.sensitiveData.legalBasis.medical',
            _('pa.sensitiveData.legalBasis.medical')],
          ['pa.sensitiveData.legalBasis.legalClaim',
            _('pa.sensitiveData.legalBasis.legalClaim')],
          ['pa.sensitiveData.legalBasis.publicHealth',
            _('pa.sensitiveData.legalBasis.publicHealth')],
          ['pa.sensitiveData.legalBasis.science',
            _('pa.sensitiveData.legalBasis.science')],
          ['pa.sensitiveData.legalBasis.nationalExemption',
            _('pa.sensitiveData.legalBasis.nationalExemption')],
        ],
        'fieldName': _('pa.sensitiveDataLegalBasis.fieldName'), //'Sensitive Data Legal Basis',
        'placeholder': _('pa.placeholder.select'), //'Select applicable options...',
      },
      {
        'field': 'paSensitiveDataLegalJustification',
        'description': _('pa.sensitiveDataLegalJustification.description'), //'Justify further the legal basis of processing the sensitive data for this processing activity',
        'tip': _('pa.sensitiveDataLegalJustification.tip'), //'For example Legal claim: Art. 9 (2) (f) GDPR justifies processing of sensitive data when it is necessary for the establishment, exercise or defence of legal claims, or for courts acting in their judicial capacity.',
        'condition': {
          'field': 'paSensitiveData',
          'value': 'yesno.yes',
        },
        'type': 'textarea',
        'fieldName': _('pa.sensitiveDataLegalJustification.fieldName'), //'Sensitive Data Legal Justification',
        'placeholder': _('pa.placeholder.type'), //'Type...',
      },
    ],
  },
  {
    'title': _('pa.dataCollection.titel'), //'Data Collection',
    'description': _('pa.dataCollection.description'), //'This section should contain information describing how the data of this processing activity is obtained.',
    'fields': [
      {
        'field': 'paPersonalDataCollection',
        'description': _('pa.personalDataCollection.description'), //'Are you collecting personally identifiable information data such as name, email, passport, identification number, phone number or bank details, etc ?',
        'tip': _('pa.personalDataCollection.tip'), //'Personal Data is data that relates to a living individual who can be identified. This includes business data.',
        'type': 'selector',
        'options': [
          ['yesno.yes', _('yesno.yes')],
          ['yesno.no', _('yesno.no')],
          ['yesno.maybe', _('yesno.maybe')],
        ],
        'fieldName': _('pa.personalDataCollection.fieldName'), //'Personal Data Collection',
        'placeholder': _('pa.placeholder.select'), //'Select...',
      },
      {
        'field': 'paDataCollectionSource',
        'description': _('pa.dataCollectionSource.description'), //'What is the source of the data collection?',
        'tip': _('pa.dataCollectionSource.tip'), //'Where did you get this data from? For example, we received this data through my website contact form or LinkedIn. Another example would be, we received this data from a headhunting agency.',
        'type': 'textarea',
        'fieldName': _('pa.dataCollectionSource.fieldName'), //'Data Collection Source',
        'placeholder': _('pa.placeholder.type'), //'Type...'
      },
      {
        'field': 'paDataCollectionConsent',
        'description': _('pa.dataCollectionConsent.description'), //'Was the data collected with the consent of the data subject?',
        'tip': _('pa.dataCollectionConsent.tip'), //'Did you explicitly take consent from the data subject in this processing activity? It could be added in your consent form, inbound forms, recruitment process or others.',
        'type': 'selector',
        'options': [
          ['yesno.yes', _('yesno.yes')],
          ['yesno.no', _('yesno.no')],
          ['yesno.dontKnow', _('yesno.dontKnow')],
        ],
        'fieldName': _('pa.dataCollectionConsent.fieldName'), //'Data Collection Consent',
        'placeholder': _('pa.placeholder.select'), //'Select...',
      },
    ],
  },
  {
    'title': _('pa.dataProcessing.titel'), //'Data Processing',
    'description': _('pa.dataProcessing.description'), //`
          // Data Processing means dealing with information or data in a wide sense. It includes any form of obtaining, recording or holding the information or data - both by analog and digital means - as well as carrying out any operation or set of operations on the information or data, including
          // <ol type="a">
          // <li>retrieval, consultation or use of the information or data,</li>
          // <li>organisation, adaptation or alteration of the information or data,</li>
          // <li>alignment or combination of the information or data,</li>
          // <li>disclosure of the information or data by transmission, dissemination or otherwise making available, or</li>
          // <li>blocking, erasure or destruction of the information or data.</li>
          // </ol>
          // This topic helps to understand the usage and processing of your data process activity.
          // `,
    'fields': [
      {
        'field': 'paDataRecipientCategories',
        'description': _('pa.dataRecipientCategories.description'), //'To whom the personal data has been disclosed?',
        'type': 'selector',
        'options': [
          ['pa.dataProcessor.internal', _('pa.dataProcessor.internal')],
          ['pa.dataProcessor.external', _('pa.dataProcessor.external')],
        ],
        'fieldName': _('pa.dataRecipientCategories.fieldName'), //'Data Recipient Categories',
        'placeholder': _('pa.placeholder.select'),
      },
      {
        'field': 'paDataRecipient',
        'description': _('pa.dataRecipientInt.description'), //'Additional information about the internal data processor',
        'tip': _('pa.dataRecipientInt.tip'), //'Internal department and function',
        'type': 'textarea',
        'fieldName': _('pa.dataRecipientInt.fieldName'), //'Data Recipient',
        'placeholder': _('pa.placeholder.type'), //'Type...',
        'condition': {
          'field': 'paDataRecipientCategories',
          'value': 'pa.dataProcessor.internal',
        }
      },
      {
        'field': 'paDataRecipient',
        'description': _('pa.dataRecipientExt.description'), //'Additional information about the external data processor',
        'tip': _('pa.dataRecipientExt.tip'), //'External company and address',
        'type': 'textarea',
        'fieldName': _('pa.dataRecipientExt.fieldName'), //'Data Recipient',
        'placeholder': _('pa.placeholder.type'), //'Type...',
        'condition': {
          'field': 'paDataRecipientCategories',
          'value': 'pa.dataProcessor.external',
        }
      },
    ],
  },
  {
    'title': _('pa.dataSubjectCategories.titel'), //'Data Subject & Categories',
    'description': _('pa.dataSubject.description'), //'The Data Subject is a living individual to whom data, which is subject to data processing activities, relates personally. Categories of data subjects help to identify groups of persons mainly affected by the processing, e. g. employee groups, consultants, customers or suppliers.',
    'fields': [
      {
        'field': 'paDataSubjectCategories',
        'description': _('pa.dataSubjectCategories.description'), //'Data Subject Categories in this processing activity',
        'tip': _('pa.dataSubjectCategories.tip'), //'What categories of data have you processed in this activity?',
        'type': 'selector',
        'multiple': true,
        'options': [
          ['pa.dataSubject.categories.employees',
            _('pa.dataSubject.categories.employees')],
          ['pa.dataSubject.categories.jointController',
            _('pa.dataSubject.categories.jointController')],
          ['pa.dataSubject.categories.trainees',
            _('pa.dataSubject.categories.trainees')],
          ['pa.dataSubject.categories.temporaryWorkers',
            _('pa.dataSubject.categories.temporaryWorkers')],
          ['pa.dataSubject.categories.managers',
            _('pa.dataSubject.categories.managers')],
          ['pa.dataSubject.categories.commercialAgent',
            _('pa.dataSubject.categories.commercialAgent')],
          ['pa.dataSubject.categories.serviceProvider',
            _('pa.dataSubject.categories.serviceProvider')],
          ['pa.dataSubject.categories.suppliers',
            _('pa.dataSubject.categories.suppliers')],
          ['pa.dataSubject.categories.interestedParties',
            _('pa.dataSubject.categories.interestedParties')],
          ['pa.dataSubject.categories.contractualPartner',
            _('pa.dataSubject.categories.contractualPartner')],
          ['pa.dataSubject.categories.customers',
            _('pa.dataSubject.categories.customers')],
          ['pa.dataSubject.categories.customersOfCustomers',
            _('pa.dataSubject.categories.customersOfCustomers')],
          ['pa.dataSubject.categories.employeesOfCustomers',
            _('pa.dataSubject.categories.employeesOfCustomers')],
          ['pa.dataSubject.categories.members',
            _('pa.dataSubject.categories.members')],
          ['pa.dataSubject.categories.debtor',
            _('pa.dataSubject.categories.debtor')],
          ['pa.dataSubject.categories.investor',
            _('pa.dataSubject.categories.investor')],
          ['pa.dataSubject.categories.other',
            _('pa.dataSubject.categories.other')],
        ],
        'fieldName': _('pa.dataSubjectCategories.fieldName'), //'Data Subject Categories',
        'placeholder': _('pa.placeholder.select'), //'Select applicable options...'
      },
      {
        'field': 'paDataCategoriesDescription',
        'description': _('pa.dataCategoriesDescription.description'), //'Data Categories Description',
        'tip': _('pa.dataCategoriesDescription.tip'), //'What kind of personal data categories do you collect?',
        'type': 'selector',
        'multiple': true,
        'options': [
          ['pa.dataSubject.dataCategories.name',
            _('pa.dataSubject.dataCategories.name')],
          ['pa.dataSubject.dataCategories.phone',
            _('pa.dataSubject.dataCategories.phone')],
          ['pa.dataSubject.dataCategories.birthInformation',
            _('pa.dataSubject.dataCategories.birthInformation')],
          ['pa.dataSubject.dataCategories.ip',
            _('pa.dataSubject.dataCategories.ip')],
          ['pa.dataSubject.dataCategories.address',
            _('pa.dataSubject.dataCategories.address')],
          ['pa.dataSubject.dataCategories.email',
            _('pa.dataSubject.dataCategories.email')],
          ['pa.dataSubject.dataCategories.salaryInformation',
            _('pa.dataSubject.dataCategories.salaryInformation')],
          ['pa.dataSubject.dataCategories.licensePlate',
            _('pa.dataSubject.dataCategories.licensePlate')],
          ['pa.dataSubject.dataCategories.bankDetails',
            _('pa.dataSubject.dataCategories.bankDetails')],
          ['pa.dataSubject.dataCategories.insuranceInformation',
            _('pa.dataSubject.dataCategories.insuranceInformation')],
          ['pa.dataSubject.dataCategories.passportId',
            _('pa.dataSubject.dataCategories.passportId')],
          ['pa.dataSubject.dataCategories.religion',
            _('pa.dataSubject.dataCategories.religion')],
          ['pa.dataSubject.dataCategories.maritalStatus',
            _('pa.dataSubject.dataCategories.maritalStatus')],
          ['pa.dataSubject.dataCategories.biometricInfo',
            _('pa.dataSubject.dataCategories.biometricInfo')],
          ['pa.dataSubject.dataCategories.geneticData',
            _('pa.dataSubject.dataCategories.geneticData')],
          ['pa.dataSubject.dataCategories.fax',
            _('pa.dataSubject.dataCategories.fax')],
          ['pa.dataSubject.dataCategories.orderHistory',
            _('pa.dataSubject.dataCategories.orderHistory')],
          ['pa.dataSubject.dataCategories.bankDetails',
            _('pa.dataSubject.dataCategories.bankDetails')],
          ['pa.dataSubject.dataCategories.citizenship',
            _('pa.dataSubject.dataCategories.citizenship')],
          ['pa.dataSubject.dataCategories.address',
            _('pa.dataSubject.dataCategories.address')],
          ['pa.dataSubject.dataCategories.location',
            _('pa.dataSubject.dataCategories.location')],
          ['pa.dataSubject.dataCategories.gps',
            _('pa.dataSubject.dataCategories.gps')],
          ['pa.dataSubject.dataCategories.ethnicity',
            _('pa.dataSubject.dataCategories.ethnicity')],
          ['pa.dataSubject.dataCategories.tradeUnion',
            _('pa.dataSubject.dataCategories.tradeUnion')],
          ['pa.dataSubject.dataCategories.social',
            _('pa.dataSubject.dataCategories.social')],
          ['pa.dataSubject.dataCategories.employmentInformation',
            _('pa.dataSubject.dataCategories.employmentInformation')],
          ['pa.dataSubject.dataCategories.login',
            _('pa.dataSubject.dataCategories.login')],
          ['pa.dataSubject.dataCategories.criminal',
            _('pa.dataSubject.dataCategories.criminal')],
          ['pa.dataSubject.dataCategories.family',
            _('pa.dataSubject.dataCategories.family')],
          ['pa.dataSubject.dataCategories.sex',
            _('pa.dataSubject.dataCategories.sex')],
          ['pa.dataSubject.dataCategories.ageRanges',
            _('pa.dataSubject.dataCategories.ageRanges')],
          ['pa.dataSubject.dataCategories.healthData',
            _('pa.dataSubject.dataCategories.healthData')],
          ['pa.dataSubject.dataCategories.physicalTraits',
            _('pa.dataSubject.dataCategories.physicalTraits')],
          ['pa.dataSubject.dataCategories.likes',
            _('pa.dataSubject.dataCategories.likes')],
          ['pa.dataSubject.dataCategories.other',
            _('pa.dataSubject.dataCategories.other')],
        ],
        'fieldName': _('pa.dataCategoriesDescription.fieldName'), //'Data Categories Description',
        'placeholder': _('pa.placeholder.select'), //'Select applicable options...'
      },
    ]
  },
  {
    'title': _('pa.dataStorage.titel'), //'Data Storage & Transfer',
    'description': _('pa.dataStorage.description'), //'Personal data shall not be transferred to a country or territory outside the EEA unless that country or territory ensures an adequate level of protection for the rights and freedoms of data subjects in relation to the processing of personal data. This part of the records of processing activities wants you to make sure the data storage and transfer takes place and it helps you to understand the transfer of data.',
    'fields': [
      {
        'field': 'paInternalDataStorage',
        'description': _('pa.internalDataStorage.description'), //'Where is this data stored within your organization?',
        'tip': _('pa.internalDataStorage.tip'), //'The data could be stored in your own servers and local data center. For example, the customer research data is stored in the shared cloud hosted in the organization. It could be on Google drive, dropbox, etc.',
        'type': 'text',
        'fieldName': _('pa.internalDataStorage.fieldName'), //'Internal Data Storage',
        'placeholder': _('pa.placeholder.type'), //'Type...',
      },
      {
        'field': 'paInternalDataStorageLocation',
        'description': _('pa.internalDataStorageLocation.description'), //'Where is this data located?',
        'tip': _('pa.internalDataStorageLocation.tip'), //'Please write down the address, city, and country here.',
        'type': 'textarea',
        'fieldName': _('pa.internalDataStorageLocation.fieldName'), //'Internal Data Storage Location',
        'placeholder': _('pa.placeholder.type'), //'Type...',
      },
      {
        'field': 'paIsDataTransferredTC',
        'description': _('pa.isDataTransferredTC.description'), //'Does this Processing Activity require transfer of personal data to third countries or to international organisations in accordance with the GDPR?',
        'tip': _('pa.isDataTransferredTC.tip'), //`
                          // You can see the list of third countries: All non-EU and non-EEA countries are considered third countries
                          // <a target="_blank" href="https://webgate.ec.europa.eu/sanco/traces/output/non_eu_listsPerCountry_en.htm">here</a>.
                          // `,
        'type': 'selector',
        'fieldName': _('pa.isDataTransferredTC.fieldName'), //'Is Data Transferred TC',
        'options': [
          ['yesno.yes', _('yesno.yes')],
          ['yesno.no', _('yesno.no')],
        ],
        'placeholder': _('pa.placeholder.select'), //'Select...'
      },
      {
        'field': 'paDataTransferTCDestinationCountry',
        'description': _('pa.dataTransferTCDestinationCountry.description'), //'Which country is the data transferred to?',
        'tip': _('pa.dataTransferTCDestinationCountry.tip'), //'Name of the third country.',
        'type': 'text',
        'fieldName': _('pa.dataTransferTCDestinationCountry.fieldName'), //'Data Transfer TC Destination Country',
        'placeholder': _('pa.placeholder.type'), //'Type...',
        'condition': {
          'field': 'paIsDataTransferredTC',
          'value': 'yesno.yes',
        },
      },
      {
        'field': 'paDataTransferTCDestinationCompany',
        'description': _('pa.dataTransferTCDestinationCompany.description'), //'Which organisation is the data trasnferred to?',
        'tip': _('pa.dataTransferTCDestinationCompany.tip'), //'Name of the organisation.',
        'type': 'text',
        'fieldName': _('pa.dataTransferTCDestinationCompany.fieldName'), //'Data Transfer TC Destination Company',
        'placeholder': _('pa.placeholder.type'), //'Type...',
        'condition': {
          'field': 'paIsDataTransferredTC',
          'value': 'yesno.yes',
        }
      },
      {
        'field': 'paDataTransferTCDestination',
        'description': _('pa.dataTransferTCDestination.description'), //'Where is the organisation that the data is transferred to?',
        'tip': _('pa.dataTransferTCDestination.tip'), //'Please write down the address and city of the organisation.',
        'type': 'text',
        'fieldName': _('pa.dataTransferTCDestination.fieldName'), //'Data Transferred TC Destination',
        'placeholder': _('pa.placeholder.type'), //'Type...',
        'condition': {
          'field': 'paIsDataTransferredTC',
          'value': 'yesno.yes',
        }
      },
    ],
  },
  {
    'title': _('pa.dataDeletion.titel'), //'Data Deletion & Retention',
    'description': _('pa.dataDeletion.description'), //`
          // In practice, Data Retention means that you will need to:
          // <ul>
          // <li>review the length of time you keep personal data;</li>
          // <li>consider the purpose or purposes you hold the personal data for deciding whether (and for how long) to retain it;</li>
          // <li>if necessary, update personal data and its legal basis (e.g. consent) before it gets outdated; and</li>
          // <li>securely delete or anonymize personal data that is no longer needed for the purpose or purposes.</li>
          // </ul>
          // What determines the length of a retention period?<br/>
          // Personal data will need to be retained for longer in some cases than in others. Sometimes, legal requirements require personal data to be stored. If not, how long different categories of personal data are retained should be based on individual business needs. A judgement must be made about: Is there a legitimate interest, e.g. a strong business interest, or not? Aspects to consider are:
          // <ul>
          // <li>The category of personal data - e.g. sensitive data must not be retained after the original purpose to hold it is obsolete;</li>
          // <li>the current and future value of the information;</li>
          // <li>the costs, risks and liabilities associated with retaining the information; and</li>
          // <li>the ease or difficulty of making sure it remains accurate and up to date.</li>
          // </ul>
          // `,
    'fields': [
      {
        'field': 'erasureTime',
        'description': _('pa.erasureTime.description'), //'Envisaged time limit for erasure',
        'tip': _('pa.erasureTime.tip'), //`When does the data get deleted? Enter the period of time after which the data will be deleted. A concrete indication of the source must be given. A general reference to "legal regulations" is not sufficient.`,
        'type': 'text',
        'fieldName': _('pa.erasureTime.fieldName'), //'Erasure Time',
        'placeholder': _('pa.placeholder.type'), //'Type...'
      },
    ]
  },
  /*      {
            "title": "other",
            "fields": [
                    {
                          "field": "paProcessDescription",
                          "description": "Comments",
                          "tip": "Please write down additional comments here",
                          "type": "text",
                          "fieldName": "Process Description",
                          "placeholder": "Type..."
                    }
            ]
        }*/
];

export class InfoRPAComponentFormsModel {
  public PAForms: any = [
    {
      id: 1,
      forms: PAForms,
    }
  ];
}

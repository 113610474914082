<h1 mat-dialog-title>{{ 'documents.select-path.dialog.new-folder.title' | translate }}</h1>

<mat-dialog-content>
  <mat-form-field>
    <input matInput [formControl]="folderName" type="text" placeholder="{{ 'documents.select-path.dialog.new-folder.placeholder' | translate }}">
  </mat-form-field>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>{{ 'word.cancel' | translate }}</button>
  <button mat-button [disabled]="!folderName.valid" color="primary" (click)="save()">{{ 'word.save' | translate }}</button>
</mat-dialog-actions>

import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { environment } from '../../environments/environment';

@Injectable()
export class SelfSignUpGuard implements CanActivate {
  // prevents accessing parts that are dependent on self-sign up being allowed

  constructor(
    private router: Router
  ) { }

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (!environment.selfSignUp
      && !route.queryParams.invite
      && !route.queryParams['co-supervisor-invite']
      && !route.queryParams['registrationToken']
      && !route.queryParams['ref']) {
      this.router.navigate(['/home']);
      return false;
    }

    return true;
  }
}

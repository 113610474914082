<div class="application-picker">
  <div *ngIf="selectedApplication" (click)="showPicker()">
    <div class="application">
      <div class="avatar">
        <img
          *ngIf="selectedApplication.logoUrl"
          [src]="selectedApplication.logoUrl"
          onerror="this.onerror=null;this.src='/assets/default_vendor.svg';"
        />
        <mat-icon *ngIf="!selectedApplication.logoUrl" class="no-logo">web_asset</mat-icon>
      </div>

      <div class="info">
        <div class="title">
          {{ selectedApplication.name }}
        </div>
      </div>
    </div>
  </div>
  <button mat-stroked-button color="primary" (click)="showPicker()">{{ 'applications.picker.choose' | translate }}</button>
</div>

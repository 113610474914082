import {Injectable} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import {BusService} from '../bus.service';
import {EventsService} from '../events.service';
import {
  IGetOptions,
  IPostOptions,
  RequestService
} from '../request.service';

interface AddResponse {
  id: number;
}

interface AddRequest {
  name: string;
  headFirstName: string;
  headLastName: string;
  headEmail: string;
  dpCoordinatorFirstName: string;
  dpCoordinatorLastName: string;
  dpCoordinatorEmail: string;
  controllerId?: number;
}

interface UpdateRequest {
  id: number;
  name: string;
  headFirstName: string;
  headLastName: string;
  headEmail: string;
  dpCoordinatorFirstName: string;
  dpCoordinatorLastName: string;
  dpCoordinatorEmail: string;
  controllerId: number;
}

interface GetAllRequest {
}

type GetAllResponse = Department[];

export interface Department {
  id: number;
  controllerId: string;
  name: string;
  headFirstName: string;
  headLastName: string;
  headEmail: string;
  dpCoordinatorFirstName: string;
  dpCoordinatorLastName: string;
  dpCoordinatorEmail: string;
}

@Injectable()
export class DepartmentService {

  private api = {
    root: '/department'
  };

  constructor(
    private bus: BusService,
    private events: EventsService,
    private requestService: RequestService
  ) {
    this.subscribe();
  }

  public requestGetAll() {
    return this.requestService.get<GetAllResponse>({
      uri: this.api.root,
      handlers: {
        success: departments => this.bus.publish(this.events.received.data.departments.get.success, departments),
        error: error => this.bus.publish(this.events.received.data.departments.get.failure, error),
      }
    });
  }

  public requestAdd(data: AddRequest) {
    return this.requestService.post<AddRequest, AddResponse>({
      uri: this.api.root,
      body: data,
      handlers: {
        success: () => {
          this.bus.publish(this.events.received.data.department.add.success);
        },
        error: error =>
          this.bus.publish(this.events.received.data.department.add.failure, error),
      }
    });
  }

  requestUpdate(data: UpdateRequest) {
    this.requestService.put<UpdateRequest, {}>({
      uri: this.api.root + `/${data.id}/`,
      body: data,
      handlers: {
        success: () => this.bus.publish(this.events.received.data.department.update.success),
        error: error =>
          this.bus.publish(this.events.received.data.department.update.failure, error),
      }
    });
  }

  requestDelete(id) {
    this.requestService.delete({
      uri: this.api.root + `/${id}/`,
      handlers: {
        success: () => {
          this.bus.publish(this.events.received.data.department.delete.success)
        },
        error: error => this.bus.publish(this.events.received.data.department.delete.failure, error),
      }
    });
  }

  private subscribe() {
    this.bus.subscribe(this.events.requested.data.departments.get, this.requestGetAll.bind(this));
    this.bus.subscribe(this.events.requested.data.department.add, this.requestAdd.bind(this));
    this.bus.subscribe(this.events.requested.data.department.update, this.requestUpdate.bind(this));
    this.bus.subscribe(this.events.requested.data.department.delete, this.requestDelete.bind(this));
  }
}

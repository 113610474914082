import { Component, OnInit } from '@angular/core';
import {Measure, MeasuresService} from "../../../services/measures.service";
import {zip} from "rxjs";
import {MatDialogRef} from "@angular/material/dialog";

type SelectableMeasure = Measure & { selected: boolean };

@Component({
  selector: 'app-import-measures-dialog',
  templateUrl: './import-measures-dialog.component.html',
  styleUrls: ['./import-measures-dialog.component.scss']
})
export class ImportMeasuresDialogComponent implements OnInit {

  supervisorMeasures: SelectableMeasure[] = [];

  pending = false;

  constructor(
    private ref: MatDialogRef<ImportMeasuresDialogComponent>,
    private measureService: MeasuresService
  ) { }

  ngOnInit(): void {
    this.measureService.getSupervisorMeasures()
      .subscribe((measures) => {
        this.supervisorMeasures = measures.map(m => {
          (m as any).selected = true;
          return m as SelectableMeasure;
        }).sort((a, b) => a.title.localeCompare(b.title));
      });
  }

  selectAll() {
    this.supervisorMeasures = this.supervisorMeasures.map(m => {
      (m as any).selected = true;
      return m as SelectableMeasure;
    });
  }

  unselectAll() {
    this.supervisorMeasures = this.supervisorMeasures.map(m => {
      (m as any).selected = false;
      return m as SelectableMeasure;
    });
  }

  get selectedMeasures() {
    return this.supervisorMeasures.filter(m => m.selected);
  }

  import() {
    this.pending = true;

    const candidates = this.selectedMeasures;

    zip(...candidates.map((measure) =>
      this.measureService.createMeasure(measure)
    )).subscribe((measures) => {
      this.pending = false;
      this.ref.close(measures);
    });
  }

}

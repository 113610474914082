import {Component, OnDestroy, OnInit} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {Router} from '@angular/router';
import {BusService} from '../../../services/bus.service';
import {EventsService} from '../../../services/events.service';
import {AppConfig} from '../../../app.config';
import { getToken } from '../../../util/token';

@Component({
  selector: 'app-milestone-two',
  templateUrl: './milestone-two.component.html',
  styleUrls: ['./milestone-two.component.scss']
})
export class MilestoneTwoComponent implements OnInit, OnDestroy {

  milestoneProgress = 0;
  pasApplicable = [];
  complexPa = [];
  complexPaName = '';
  pasTotal = [];
  // eslint-disable-next-line @typescript-eslint/naming-convention
  PAsInternallyProcessed = [];
  // eslint-disable-next-line @typescript-eslint/naming-convention
  PAsExternallyProcessed = [];
  vendors = [];
  controller = undefined;
  rpaLink =
    `${AppConfig.apiReport}?token=${getToken()}&lang=${localStorage.getItem('lang')}`;

  constructor(
    private events: EventsService,
    private bus: BusService,
    private sanitizer: DomSanitizer,
    private router: Router
  ) {
  }

  ngOnInit() {
    this.subscribe();
    this.bus.publish(this.events.requested.data.todos.milestoneTwoProgress);
    this.bus.publish(this.events.requested.data.rpa.getApplicable);
    this.bus.publish(this.events.requested.data.rpa.getComplex);
    this.bus.publish(this.events.requested.data.rpa.getAll);
    this.bus.publish(this.events.requested.data.controller.get);
    this.bus.publish(this.events.requested.data.rpa.externallyProcessed.vendors);
    this.bus.publish(this.events.requested.data.rpa.externallyProcessed.pas);
    this.bus.publish(this.events.requested.data.rpa.internallyProcessed.pas);
  }

  ngOnDestroy() {
    this.unsubscribe();
  }

  updateController(data) {
    this.controller = data;
  }

  updatePAsApplicable(data) {
    this.pasApplicable = data;
  }

  updatePAsComplex(data) {
    this.complexPa = data;
    if (data) {
      this.getComplexPa(data.pa_id);
    }
  }

  getComplexPa(id) {
    this.pasApplicable.forEach((item) => {
        if (item.paId === id) {
          this.complexPaName = item.paName;
        }
      }
    );
  }

  updatePAsTotal(data) {
    this.pasTotal = data;
  }

  updateMilestoneProgress(data) {
    if (data.aggregate !== undefined) {
      this.milestoneProgress = data.aggregate;
    }
  }

  updateVendors(data) {
    data = this.arrUnique(data);
    this.vendors = data;
  }

  // quick fix until Eugene publishes new vendor methods
  arrUnique(arr) {
    const cleaned = [];
    arr.forEach((itm) => {
      let unique = true;
      cleaned.forEach((itm2) => {
        if (itm.vendorId === itm2.vendorId) {
          unique = false;
        }
      });
      if (unique) {
        cleaned.push(itm);
      }
    });
    return cleaned;
  }

  updatePasInternallyProcessed(data) {
    this.PAsInternallyProcessed = data;
  }

  updatePasExternallyProcessed(data) {
    this.PAsExternallyProcessed = data;
  }

  public progress(progress) {
    this.bus.publish(this.events.requested.action.tasks.progressControllerTask, {
      progress,
      workflowCodename: 'path_to_gdpr',
      taskCodename: 'ptg_milestone_ii',
    });
  }

  continue() {
    this.progress(1);
    this.router.navigate(['']);
  }

  subscribe() {
    this.bus.subscribe(this.events.received.data.todos.milestoneTwo.success, this.updateMilestoneProgress, this);
    this.bus.subscribe(this.events.received.data.rpa.getApplicable.success, this.updatePAsApplicable, this);
    this.bus.subscribe(this.events.received.data.rpa.getComplex.success, this.updatePAsComplex, this);
    this.bus.subscribe(this.events.received.data.rpa.getAll.success, this.updatePAsTotal, this);
    this.bus.subscribe(this.events.received.data.controller.get.success, this.updateController, this);
    this.bus.subscribe(this.events.received.data.rpa.externallyProcessed.vendors.success, this.updateVendors, this);
    this.bus.subscribe(this.events.received.data.rpa.externallyProcessed.pas.success, this.updatePasExternallyProcessed, this);
    this.bus.subscribe(this.events.received.data.rpa.internallyProcessed.pas.success, this.updatePasInternallyProcessed, this);
  }

  unsubscribe() {
    this.bus.unsubscribe(this.events.received.data.todos.milestoneTwo.success, this.updateMilestoneProgress);
    this.bus.unsubscribe(this.events.received.data.rpa.getApplicable.success, this.updatePAsApplicable);
    this.bus.unsubscribe(this.events.received.data.rpa.getComplex.success, this.updatePAsComplex);
    this.bus.unsubscribe(this.events.received.data.rpa.externallyProcessed.vendors.success, this.updateVendors);
    this.bus.unsubscribe(this.events.received.data.rpa.externallyProcessed.pas.success, this.updatePasExternallyProcessed);
    this.bus.unsubscribe(this.events.received.data.rpa.internallyProcessed.pas.success, this.updatePasInternallyProcessed);
    this.bus.unsubscribe(this.events.received.data.rpa.getAll.success, this.updatePAsTotal);
    this.bus.unsubscribe(this.events.received.data.controller.get.success, this.updateController);
  }
}

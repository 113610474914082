import { Injectable } from '@angular/core';

import { BusService } from '../bus.service';
import { EventsService } from '../events.service';
import { RequestService } from '../request.service';


@Injectable()
export class RpasOnBehalfService {

  api = {
    root: '/rpaob',
    workflow: '/workflow',
  }

  constructor(
    private bus: BusService,
    private events: EventsService,
    private requests: RequestService,
  ) {
    this.subscribe();
  }

  addWorkflow() {
    return this.requests.post<{}, any>({
      uri: this.api.root + this.api.workflow,
      body: {},
      handlers: {
        success: () => this.bus.publish(this.events.received.action.rpaOnBehalf.workflow.add.success),
        error: error => this.bus.publish(this.events.received.action.rpaOnBehalf.workflow.add.failure, error),
      }
    });
  }

  deleteWorkflow() {
    return this.requests.delete<any, any>({
      uri: this.api.root + this.api.workflow,
      handlers: {
        success: () => this.bus.publish(this.events.received.action.rpaOnBehalf.workflow.delete.success),
        error: error => this.bus.publish(this.events.received.action.rpaOnBehalf.workflow.delete.failure, error),
      }
    });
  }

  subscribe() {
    this.bus.subscribe(this.events.requested.action.rpaOnBehalf.workflow.add, this.addWorkflow.bind(this));
    this.bus.subscribe(this.events.requested.action.rpaOnBehalf.workflow.delete, this.deleteWorkflow.bind(this));
  }
}

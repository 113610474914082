import {
  Component, OnInit, OnDestroy, ViewChild,
  ElementRef, HostListener, Inject
} from '@angular/core';
import { DOCUMENT } from "@angular/common";
import {InfoRPAComponentModel, InfoRPAComponentFormsModel, InfoRPAComponentAnswersModel, PAForms} from './info.rpa.model';
import {BusService} from '../../../services/bus.service';
import {EventsService} from '../../../services/events.service';

@Component({
  selector: 'info-rpa-component',
  templateUrl: './info.rpa.template.html',
  styleUrls: ['./info.rpa.styles.scss']
})

export class InfoRpaComponent implements OnInit, OnDestroy {

  PAs: any = [];

  public selectedPAForm: any;
  public selectedPA: any;
  public answer: any = [];
  public answerSelected: any;
  public status: string;
  public formPermission: any = [];

  public formView: InfoRPAComponentFormsModel = new InfoRPAComponentFormsModel();
  public answers: any = new InfoRPAComponentAnswersModel().PAAnswers;

  public openList: string = 'Applicable';

  @ViewChild('outer') outer: ElementRef;
  mode: string = 'list';

  private scrollOnList: number = 0;

  constructor(
    private bus: BusService,
    private events: EventsService,
    @Inject(DOCUMENT) private document: Document,
  ) {
  }

  //TODO: much (or all) of this is redundant. remove it.
  //
  private setData(data: any): void {
    this.PAs = data;
    this.formView.PAForms = [];
    this.PAs.forEach((pa, index) => {
      this.answers[index] = {
        paId: pa.paId,
        paContactEmailAddress: null,
        paContactName: null,
        paContactTelephoneNumber: null,
        paCreatedOn: null,

        paLegalBasis: null,
        paSensitiveData: null,
        paSensitiveDataLegalBasis: null,
        paSensitiveDataLegalJustification: null,

        paPersonalDataCollection: null,
        paDataCollectionSource: null,
        paDataCollectionConsent: null,

        paDataRecipientCategories: null,
        paDataRecipient: null,

        paDataSubjectCategories: null,
        paDataCategoriesDescription: null,

        paInternalDataStorage: null,
        paInternalDataStorageLocation: null,
        paIsDataTransferredTC: null,
        paDataTransferTCDestinationCountry: null,
        paDataTransferTCDestinationCompany: null,
        paDataTransferTCDestination: null,

        erasureTime: null,

        paLastChangedOn: null,
        paPurpose: null,
        paStatus: pa.paStatus,
        paResponsibleDepartment: null,
      };

      this.formView.PAForms.push({
        id: pa.paId,
        forms: PAForms,
      });
    });
  }

  // set answers if !!data ar takes a default value
  private setAnswers(data: any): void {
    if (data) {
      this.answerSelected = data;
      this.answers.forEach(answer => {
        if (answer.paId === data.paId) {
          answer.paStatus = data.paStatus;
        }
      });
      this.formPermission = this.formPermission.map(permission => permission = false);
      this.formPermission[this.selectedPA.paId] = this.answerSelected && this.answerSelected.paStatus === 'Applicable' ? true : false;
    } else {
      this.answerSelected = this.answers.find(element => element.paId === this.selectedPA.paId);
    }
  }

  // toggle a pa status (available/not) and publish a pa-update request
  private toggleStatus(data: any): void {
    this.selectedPA.paStatus = data;
    this.answerSelected.paStatus = data;
    this.formPermission = this.formPermission.map(permission => permission = false);
    this.formPermission[this.selectedPA.paId] = this.answerSelected && this.answerSelected.paStatus === 'Applicable' ? true : false;
    this.bus.publish(this.events.requested.data.rpa.update, {data: this.answerSelected, id: localStorage.getItem('currentUserID')});
  }

  public focusOnForms(): void {
    if (this.mode == 'form' || this.mode == 'changing') return;

    this.scrollOnList = window.scrollY;
    let container = this.outer.nativeElement;

    this.mode = 'changing';
    setTimeout(() => {
      this.mode = 'form';
      window.scrollTo(0, 0);
      container.scrollBy(container.offsetWidth, 0);
    }, 150);
  }

  public focusOnList(): void {
    if (this.mode == 'list' || this.mode == 'changing') return;

    let container = this.outer.nativeElement;

    this.mode = 'changing';
    setTimeout(() => {
      this.mode = 'list';
      window.scrollTo(0, this.scrollOnList);
      container.scrollBy(-container.offsetWidth, 0);
    }, 150);
  }

  @HostListener('window:resize', ['$event'])
  public _resizeConsistency(event) {
    if (this.mode == 'form')
      this.outer.nativeElement.scrollBy(this.outer.nativeElement.offsetWidth, 0);
    else
      this.outer.nativeElement.scrollBy(-this.outer.nativeElement.offsetWidth, 0);
  }

  // select a pa and make a get-one-pa request
  public onSelect(selectedPA: any): void {
    this.selectedPA = this.PAs.find(pa => +pa.paId === selectedPA.paId);
    this.selectedPAForm = this.formView.PAForms.find(element => +element.id === selectedPA.paId);
    this.bus.publish(this.events.requested.data.rpa.get, this.selectedPA.paId);

    setTimeout(() => {
      this.focusOnForms();
    }, 100);
  }

  // make subscribe on get-all-pas and get-one-pa requests
  public subscribe(): void {
    this.bus.subscribe(this.events.received.data.rpa.getAll.success, this.setData, this);
    this.bus.subscribe(this.events.received.data.rpa.get.success, this.setAnswers, this);
  }

  // make unsubscribe on get-all-pas and get-one-pa requests
  public unSubscribe(): void {
    this.bus.unsubscribe(this.events.received.data.rpa.getAll.success, this.setData);
    this.bus.unsubscribe(this.events.received.data.rpa.get.success, this.setAnswers);
  }

  // make subscribe and start a get-all-pas request on a component initialization
  public ngOnInit(): void {
    this.subscribe();
    this.bus.publish(this.events.requested.data.rpa.getAll, localStorage.getItem('currentUserID'));
  }

  // make unsubscribe before destroying component
  public ngOnDestroy(): void {
    this.unSubscribe();
  }

}

<div class="home">
  <h1 class="title">{{ 'home.welcome' | translate }}</h1>
  <br/>
  <mat-card>
    <img mat-card-image src="assets/home.svg"/>
    <mat-card-content>
    {{ 'home.explanation' | translate }}
    </mat-card-content>

    <mat-card-actions align="end">
      <button *ngIf="ENV.selfSignUp" mat-button [routerLink]='["/registration"]' class="btn btn-default">{{ 'home.register' | translate }}</button>
      <button mat-raised-button color="primary" [routerLink]='["/login"]' class="btn btn-default">{{ 'home.login' | translate }}</button>
    </mat-card-actions>
  </mat-card>
</div>

<div class="imprint">
  <mat-card>
    <div class="row">
      <div class="data">
        <mat-card-title>{{ 'footer.imprint.office.title' | translate }}</mat-card-title>
        <mat-card-content class="rows">{{ 'footer.imprint.ecomply' | translate }}</mat-card-content>
        <mat-card-content class="rows">{{ 'footer.imprint.office.co' | translate }}</mat-card-content>
        <mat-card-content class="rows">{{ 'footer.imprint.office.address' | translate }}</mat-card-content>
        <mat-card-content class="rows">{{ 'footer.imprint.office.postalcode' | translate }}</mat-card-content>
        <br/>
        <mat-card-title>{{ 'footer.imprint.angaben5tmg' | translate }}</mat-card-title>
        <mat-card-content class="rows">{{ 'footer.imprint.ecomply' | translate }}</mat-card-content>
        <mat-card-content class="rows">{{ 'footer.imprint.address' | translate }}</mat-card-content>
        <mat-card-content class="rows">{{ 'footer.imprint.postalcode' | translate }}</mat-card-content>
        <br/>
        <mat-card-content class="rows">{{ 'footer.imprint.geschaftsfuhrer' | translate }}</mat-card-content>
        <mat-card-content class="rows">{{ 'footer.imprint.geschaftsfuhrername' | translate }}</mat-card-content>
        <br/>
        <mat-card-content class="rows">{{ 'footer.imprint.handelsregtxt' | translate }}</mat-card-content>
        <mat-card-content class="rows">{{ 'footer.imprint.handelsregnr' | translate }}</mat-card-content>
        <br/>
        <mat-card-content class="rows">{{ 'footer.imprint.vatIdTitle' | translate }}</mat-card-content>
        <mat-card-content class="rows">{{ 'footer.imprint.vatId' | translate }}</mat-card-content>
        <br/>
        <mat-card-content class="rows">{{ 'footer.imprint.reggerichttxt' | translate }}</mat-card-content>
        <mat-card-content class="rows">{{ 'footer.imprint.reggericht' | translate }}</mat-card-content>
        <br/>
        <mat-card-title>{{ 'footer.imprint.contact' | translate }}</mat-card-title>
        <mat-card-content class="rows">{{ 'footer.imprint.teltxt' | translate }} {{ 'footer.imprint.telnr' | translate }}</mat-card-content>
        <mat-card-content class="rows">{{ 'footer.imprint.emailtxt' | translate }} {{ 'footer.imprint.email' | translate}}</mat-card-content>
        <mat-card-content class="rows">{{ 'footer.imprint.websitetxt' | translate }} {{ 'footer.imprint.website' | translate }}</mat-card-content>
        <br/>
      </div>
    </div>
  </mat-card>
  <mat-card>
    <mat-card-title>{{ 'footer.imprint.disclaimer.titel' | translate }}</mat-card-title>
    <br/><br/>
    <mat-card-subtitle>{{ 'footer.imprint.disclaimer.conttitel' | translate }}</mat-card-subtitle>
    <mat-card-content>
      {{ 'footer.imprint.disclaimer.content1' | translate }}
    </mat-card-content>
    <mat-card-content>
      {{ 'footer.imprint.disclaimer.content2' | translate }}
    </mat-card-content>
    <br/><br/>
    <mat-card-subtitle>{{ 'footer.imprint.disclaimer.linkstitel' | translate }}</mat-card-subtitle>
    <mat-card-content>
      {{ 'footer.imprint.disclaimer.links1' | translate}}
    </mat-card-content>
    <mat-card-content>
      {{ 'footer.imprint.disclaimer.links2' | translate }}
    </mat-card-content>
    <br/><br/>
    <mat-card-subtitle>{{'footer.imprint.disclaimer.urheberrechttitel' | translate }}</mat-card-subtitle>
    <mat-card-content>
      {{ 'footer.imprint.disclaimer.urheberrecht1' | translate }}
    </mat-card-content>
    <mat-card-content>
      {{ 'footer.imprint.disclaimer.urheberrecht2' | translate }}
    </mat-card-content>
  </mat-card>
</div>

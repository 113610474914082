<h1 mat-dialog-title>
    {{ 'audit.form.error-dialog.title' | translate }}
</h1>
  
<mat-dialog-content>
    <div class="error-content">
        <mat-icon>error_outline</mat-icon>

        <div class="error-explanation">
            <p class="explanation">{{ 'audit.form.error-dialog.explanation' | translate }}</p>
            <mat-checkbox [(ngModel)]="continueShowing">{{ 'audit.form.error-dialog.keepShowing' | translate }}</mat-checkbox>
        </div>
    </div>
</mat-dialog-content>
  
<mat-dialog-actions align="end">
    <button mat-button (click)="closeDialog()">
        {{'word.close' | translate}}
    </button>
</mat-dialog-actions>
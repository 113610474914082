import { Component, Input, OnInit } from '@angular/core';
import { combineLatest } from 'rxjs';
import { combineAll } from 'rxjs/operators';
import { ProductFeaturesService } from '../../../services/product-features.service';

@Component({
  selector: 'feature-access-local',
  templateUrl: './access.component.html',
  styleUrls: ['./access.component.scss']
})
export class LocalFeatureAccessComponent implements OnInit {

  @Input() featureName: string;
  @Input() showWhenHasNot = false;
  @Input() includeClients = false;
  @Input() clientOnly = true;

  has = false;

  constructor(
    private featureService: ProductFeaturesService,
  ) {
  }

  ngOnInit(): void {
    const featureNames = this.featureName.split(',');

    combineLatest(featureNames.map(featureName => this.featureService.hasAccessToFeatureLocal(featureName, this.includeClients, !this.clientOnly)))
      .subscribe(hasFeatures => {
        this.has = hasFeatures.some(hasFeature => hasFeature.access);
      });
  }

}

import { image } from './image';
import { roundRect } from './round-rect';


export function nodeDrawer(style) {
  const img = new Image(style.size, style.size);
  img.crossOrigin = 'anonymous'; // the browser complains about cors when downloading the canvas...

  const padding = 4;
  img.style.objectFit = 'contain';

  let loaded = false;
  img.onload = () => {
    loaded = true;
    style.redraw();
  };
  img.src = style.image;

 return ({ctx, x, y, state: { selected, hover }, label}) => ({
    drawNode() {
      ctx.fillStyle = 'white';
      if (selected || hover) {
        ctx.shadowColor = 'rgba(0, 0, 0, .18)';
        ctx.shadowBlur = 32;
        ctx.shadowOffsetY = 12;
      } else {
        ctx.shadowColor = 'rgba(0, 0, 0, .06)';
        ctx.shadowBlur = 16;
        ctx.shadowOffsetY = 8;
      }
      roundRect(ctx, x - style.size / 2, y - style.size / 2, style.size, style.size, 6, true, false);

      if (loaded) {
        image(ctx, img, x - style.size / 2 + padding, y - style.size / 2 + padding, style.size - 2 * padding);
      }
    },

    drawExternalLabel() {
      ctx.font = `${style.size / 5}px Nunito Sans`;
      ctx.textAlign = 'center';

      ctx.strokeStyle = '#fafafa';
      ctx.lineWidth = 4;
      ctx.strokeText(label, x, y + style.size - 4);

      ctx.globalAlpha = (selected || hover) ? 1 : .5;

      ctx.fillStyle = '#212121';
      ctx.fillText(label, x, y + style.size - 4);
    },

    nodeDimensions: { width: style.size, height: style.size, }
  });
}


import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RpaReferenceService, TreeNode } from 'app/services/rpa/rpa.reference.service';

@Component({
  selector: 'app-pa-reference-tree-edit-dialog',
  templateUrl: './pa-reference-tree-edit-dialog.component.html',
  styleUrls: ['./pa-reference-tree-edit-dialog.component.scss']
})
export class PaReferenceTreeEditDialogComponent implements OnInit {

  node: TreeNode;
  parent: TreeNode;
  tree: TreeNode[];
  snapshot: TreeNode;

  constructor(
    @Inject(MAT_DIALOG_DATA) data: { node?: TreeNode; tree: TreeNode[]; parent?: TreeNode },
    private ref: MatDialogRef<PaReferenceTreeEditDialogComponent>,
    private service: RpaReferenceService,
  ) {
    this.node = data.node;
    this.tree = data.tree;
    this.parent = data.parent;

    if (!this.node) {
      this.node = {
        title: '',
        description: '',
        parent: this.parent?.id,
      };
    } else if (this.node.parent) {
      this.parent = this.service.findNode(this.node.parent, this.tree);
    }

    this.snapshot = {
      title: this.node.title,
      description: this.node.description,
      parent: this.node.parent,
    };
  }

  ngOnInit(): void {
  }

  save() {
    this.syncParentToNode();
    this.service.updateTreeNode(this.node).subscribe(() => {
      if ((!this.parent && this.snapshot.parent) || this.parent?.id !== this.snapshot.parent) {
        this.unsyncNodeFromOldParent();
        this.syncNodeToParent();
      }

      this.ref.close(true);
    });
  }

  create() {
    this.syncParentToNode();
    this.service.addTreeNode(this.node).subscribe(node => {
      this.node = node;
      this.syncNodeToParent();
      this.ref.close(true);
    });
  }

  remove() {
    this.revert();
    this.service.deleteTreeNode(this.node).subscribe(() => {
      this.unsyncNodeFromOldParent();
      this.ref.close(true);
    });
  }

  cancel() {
    this.revert();
    this.ref.close();
  }

  revert() {
    this.node.title = this.snapshot.title;
    this.node.description = this.snapshot.description;
    this.node.parent = this.snapshot.parent;
  }

  unsyncNodeFromOldParent() {
    if (this.snapshot.parent) {
      const ogparent = this.service.findNode(this.snapshot.parent, this.tree);
      ogparent.children = ogparent.children.filter(n => n !== this.node);
    } else {
      const index = this.tree.indexOf(this.node);
      this.tree.splice(index, 1);
    }
  }

  syncParentToNode() {
    if (this.parent) {
      this.node.parent = this.parent.id;
    } else {
      this.node.parent = undefined;
    }
  }

  syncNodeToParent() {
    if (this.parent) {
      this.parent.children = this.parent.children || [];
      this.parent.children.push(this.node);
    } else {
      this.tree.push(this.node);
    }
  }
}

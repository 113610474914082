<div class="contents">
  <ng-container *ngIf="application">
    <h1 class="title">
      {{ "supervision.applications.detail.title" | translate }}
      <span class="app-name">{{ application.name }}</span>
    </h1>
    <div class="application">
      <div class="avatar">
        <img
          *ngIf="application.logoUrl"
          [src]="application.logoUrl"
          onerror="this.onerror=null;this.src='/assets/default_vendor.svg';"
        />
        <mat-icon *ngIf="!application.logoUrl" class="no-logo">web_asset</mat-icon>
      </div>

      <div class="info">
        <div class="title">
          {{ application.name }}
        </div>
        <div class="vendor-name">
          {{ application.vendor.name }}
        </div>
      </div>
    </div>
    <br />

    <div class="clients">
      <a class="client" *ngFor="let client of clients" routerLink="/supervision/client/{{ client._id }}">
        <mat-card>
          <mat-card-header>
            <div mat-card-avatar>
              <img
                src="{{appConfig.apiUrl}}/controllers/logo/{{client._id}}/"
                onerror="this.src='/assets/eclogo-40.png'"
                onabort="this.src='/assets/eclogo-40.png'"/>
            </div>
            <mat-card-title class="client-label" title="{{ client.controllerName }}">{{ client.controllerName }}</mat-card-title>
            <mat-card-subtitle
              *ngIf="client.controllerAddressStreet && client.controllerAddressPostcode && client.controllerAddressCity">
              {{ client.controllerAddressStreet }},
              {{ client.controllerAddressPostcode }},
              {{ client.controllerAddressCity }}
            </mat-card-subtitle>

            <span class="client-id" *ngIf="client.clientId" title="{{ client.clientId }}">{{ client.clientId.length > 12 ? client.clientId.substr(0, 12) + '…' : client.clientId }}</span>
          </mat-card-header>
        </mat-card>
      </a>
    </div>
  </ng-container>
</div>

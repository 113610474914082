import { Injectable } from '@angular/core';
import { RequestService } from '../request.service';
import { Vendor } from './vendors.service';

export interface Application {
  id: number;
  name: string;
  vendorId: number;
  vendor?: Vendor;
  logoUrl: string;
  categories: any;
  introducedBy: number;
  reviewedAt?: Date;
}

@Injectable({
  providedIn: 'root'
})
export class ApplicationService {

  private api = {
    root: '/applications',
    controller: '/controller',
    clients: '/supervisor/clients'
  };

  private externallyProcessed = {
    root: '/pa/externally-processed',
    application: '/application',
  };

  constructor(
    private requestService: RequestService
  ) { }

  getAllApplications() {
    return this.requestService.get<Application[]>({
      uri: this.api.root
    });
  }

  getAllApplicationsByPopularity() {
    return this.requestService.get<Application[]>({
      uri: this.api.root + `/by-popularity`
    });
  }

  getApplication(id: number) {
    return this.requestService.get<Application>({
      uri: `${this.api.root}/${id}`
    });
  }

  getControllerApplications() {
    return this.requestService.get<Application[]>({
      uri: `${this.api.root}/controller`
    });
  }

  createApplication(data: Partial<Application>) {
    return this.requestService.post<Partial<Application>, Application>({
      uri: this.api.root,
      body: data
    });
  }

  getApplicationsForPa(paId: number) {
    return this.requestService.get<Application[]>({
      uri: `${this.externallyProcessed.root}/${paId}${this.externallyProcessed.application}`
    });
  }

  addPaApplication(paId: number, applicationId: number) {
    return this.requestService.post<{ paId: number; applicationId: number }, any>({
      uri: this.externallyProcessed.root + this.externallyProcessed.application,
      body: { paId, applicationId }
    });
  }

  removePaApplication(paId: number, applicationId: number) {
    return this.requestService.delete<{ paId: number; applicationId: number }, any>({
      uri: this.externallyProcessed.root + this.externallyProcessed.application,
      body: { paId, applicationId }
    });
  }

  /**
   * ==========================
   *    Supervisor Requests
   * ==========================
   */

  getClientApplications() {
    return this.requestService.get<{ application: Application; controllerId: number }[]>({
      uri: `${this.api.root}${this.api.clients}`
    });
  }

  getClientsForApplication(applicationId: number) {
    return this.requestService.get<any[]>({
      uri: `${this.api.root}${this.api.clients}/${applicationId}`
    });
  }
}

<div class="content">
    <mat-card>
      <img mat-card-image src="assets/success.svg"/>
      <mat-card-content>
        <div class="thank-you-content">
          <h1 class="title">{{ 'payment.thank-you.title' | translate }}</h1>
          <p>{{ 'payment.thank-you.emailtext' | translate }}</p>
        </div>
      </mat-card-content>
      <mat-card-actions align="end">
        <a
          routerLink="/payment/billing"
          mat-button
          color="primary"
          class="subscribe-to-plan">
          {{ 'payment.thank-you.billinginfo' | translate }}
        </a>
      </mat-card-actions>
    </mat-card>
</div>

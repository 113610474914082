import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { map, startWith } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { FormControl } from '@angular/forms';

// TODO: firstName and lastName into one name only
export interface Assignee {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  name?: string;
}

@Component({
  selector: 'app-assignee-search',
  templateUrl: './assignee-search.component.html',
  styleUrls: ['./assignee-search.component.scss'],
})
export class AssigneeSearchComponent implements OnInit {

  @Input() assigneeList: Assignee[] = [];
  @Input() assignee: FormControl;
  filteredAssignees: Observable<Assignee[]>;

  constructor() {
  }

  ngOnInit(): void {
    this.filteredAssignees = this.assignee.valueChanges.pipe(
      startWith(''),
      map(value => (typeof value === 'string' ? value : value.firstName)),
      map(name => (name ? this._filter(name) : this.assigneeList.slice())),
    );
  }

  showAssignees(assignee: Assignee): string {
    return assignee ? `${assignee.firstName} ${assignee.lastName} <${assignee.email}>` : '';
  }

  /**
   * The search will look at first name, last name and email
   *
   * @param value
   * @private
   */
  private _filter(value: string): Assignee[] {
    const filterValue = value.toLowerCase();

    return this.assigneeList.filter(option => option.firstName.toLowerCase().includes(filterValue) ||
      option.lastName.toLowerCase().includes(filterValue) ||
      option.email.toLowerCase().includes(filterValue));
  }

}

import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { MostUsed } from '../pa-data-categories-most-used/pa-data-categories-most-used.component';

@Component({
  selector: 'dashboard-metrics-pa-legal-basis-most-used',
  templateUrl: './pa-legal-basis-most-used.component.html',
  styleUrls: ['./pa-legal-basis-most-used.component.scss']
})
export class PaLegalBasisMostUsedComponent implements OnInit, AfterViewInit {
  @Input() pas = [];

  mostUsed: MostUsed[] = [];

  constructor() { }

  ngAfterViewInit(): void {
    this.buildMostUsedDataCategories();
  }

  ngOnInit(): void {
  }

  private buildMostUsedDataCategories() {
    // get the five topmost data categories from pas -> pa_legal_basis, which is an array of strings
    const legalBasis = this.pas.map(pa => pa.pa_legal_basis).filter(b => b).flat();
    const legalBasisCount = legalBasis.reduce((acc, curr) => {
      if (acc[curr]) {
        acc[curr]++;
      } else {
        acc[curr] = 1;
      }
      return acc;
    }, {});
    const legalBasisCountSorted = Object.keys(legalBasisCount).sort((a, b) => legalBasisCount[b] - legalBasisCount[a]);
    this.mostUsed = legalBasisCountSorted.slice(0, 5).map(legalBasis => {
      return {
        label: legalBasis,
        count: legalBasisCount[legalBasis]
      };
    });
  }

}

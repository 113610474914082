import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router, ActivatedRoute, Params } from '@angular/router';

import { EventsService } from '../../../services/events.service';
import { BusService } from '../../../services/bus.service';
import { AccessLevelService, AccessLevel } from '../../../services/user/access-level.service';
import { WorkflowTasksService } from '../../../services/workflow-tasks/workflow-tasks.service';

import { DpaDialogComponent } from '../dpas/dpa-dialog/dpa-dialog.component';
import { Application, ApplicationService } from '../../../services/vendors/application.service';
import { ControllerVendor, Vendor, VendorsService } from '../../../services/vendors/vendors.service';
import { zip } from 'rxjs';
import { map } from 'rxjs/operators';
import { VendorAudit, VendorAuditService } from '../../../services/vendors/vendor-audit.service';
import { auditRiskClass } from '../audit/util';
import { VendorSearchDialogComponent } from '../../rpa/pa-external-processors/vendor-search-dialog/vendor-search-dialog.component';


@Component({
  selector: 'app-vendor-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class VendorListComponent implements OnInit, OnDestroy {

  vendors = [];
  progress = 0;

  vendorAudits: VendorAudit[] = [];

  accessLevel: AccessLevel;

  constructor(
    private bus: BusService,
    private events: EventsService,
    private dialog: MatDialog,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private accessService: AccessLevelService,
    private tasks: WorkflowTasksService,
    private applicationService: ApplicationService,
    private vendorService: VendorsService,
    private vendorAuditService: VendorAuditService
  ) {
    this.accessService.checkAccess({
      context: 'vendor-dpas',
    }).subscribe(response => this.accessLevel = response);
  }

  ngOnInit() {
    this.subscribe();
    this.requestUpdate();
  }

  ngOnDestroy() {
    this.unsubscribe();
  }

  requestUpdate() {
    this.bus.publish(this.events.requested.data.todos.controllerTaskProgress, {
      workflowCodename: 'path_to_gdpr',
      taskCodename: 'ptg_vendor_dpas',
    });

    // EC-2229: as we both want normal vendors as well as application vendors, we need to include them here
    zip(
      this.vendorService.getControllerVendors(),
      this.applicationService.getControllerApplications(),
      // fetch all vendor audits to match them then
    ).subscribe(([vendors, applications]) => {
      this.updateVendors(vendors);
    });
  }

  updateVendors(vendors) {
    this.vendors = vendors;
  }

  updateProgress(response) {
    this.progress = response.progress;
  }

  public get canWrite() {
    return this.accessLevel && this.accessLevel.write;
  }

  get done(): boolean {
    if (this.vendors.some(vendor => !vendor.docPath)) return false;
    return true;
  }

  bypass() {
    if (this.vendors.length == 0) {
      this.bus.publish(this.events.requested.action.tasks.progressControllerTask, {
        progress: 1,
        workflowCodename: 'path_to_gdpr',
        taskCodename: 'ptg_vendor_dpas',
      });
    }
  }

  bypassed() {
    this.router.navigate([""]);
  }

  finish() {
    this.tasks.progressControllerTask({
      progress: 1,
      workflowCodename: 'path_to_gdpr',
      taskCodename: 'ptg_vendor_dpas',
    }).subscribe(() => {
      this.router.navigate(['']);
    });
  }

  updated(vendor: ControllerVendor) {
    if (!vendor) {
      this.requestUpdate();
    }
  }

  addVendor(event) {
    event.stopPropagation();

    this.dialog.open(VendorSearchDialogComponent, { width: '512px', maxHeight: '90vh', data: { includeOther: true } })
      .afterClosed()
      .subscribe(vendor => {
        if (vendor) {
          // check if we already have this vendor
          const idx = this.vendors.findIndex(v => v.id === vendor.id);
          if (idx === -1) {
            this.vendorService.addControllerVendor(vendor.id)
              .subscribe((added) => {
                this.requestUpdate();
              });
          }
        }
      });
  }

  downloadExcel() {
    this.vendorService.exportExcel(this.vendors);
  }

  subscribe() {
    // this.bus.subscribe(this.events.received.data.vendors.controllerVendors.success, this.updateVendors, this);
    this.bus.subscribe(this.events.received.data.todos.controllerTaskProgress.success, this.updateProgress, this);

    this.bus.subscribe(this.events.received.action.tasks.progressControllerTask.success, this.bypassed, this);

    this.vendorAuditService.getAllVendorAudits().subscribe((audits) => {
      this.vendorAudits = audits;
    });
  }

  unsubscribe() {
    this.bus.unsubscribe(this.events.received.data.vendors.controllerVendors.success, this.updateVendors);
    this.bus.unsubscribe(this.events.received.data.todos.controllerTaskProgress.success, this.updateProgress);

    this.bus.unsubscribe(this.events.received.action.tasks.progressControllerTask.success, this.bypassed);
  }
}

<div class="container">
  <ng-container *ngFor="let i of items">
    <div commentable *ngIf="i.type !== 'label'"
        comment-subject-title="{{i.text}}"
        [comment-subject-codename]="'checklist-' + codename + '-' + i.codename">
      <todoable subject="checklist-{{codename}}-{{i.codename}}" suggested-title="{{i.text}}"></todoable>
      <div class="item"
          (click)="update(i, !(value(i) === 'done'))">
        <big-round-checkbox
          [value]="value(i) === 'done'"
          (valueChanged)="update(i, $event)"></big-round-checkbox>
        <div class="text"
          [ngClass]="{skipped: value(i) === 'skipped'}"
          (click)="update(i, !(value(i) === 'done'), $event)">{{i.text}}</div>
        <button mat-button color="primary" (click)="skip(i, $event)" [disabled]="value(i) !== 'not-done'">
          <ng-container *ngIf="value(i) !== 'skipped'">
            {{'checklists.buttons.skip' | translate}}
            <!-- Skip -->
          </ng-container>
          <ng-container *ngIf="value(i) === 'skipped'">
            {{'checklists.buttons.skipped' | translate}}
            <!-- Skipped -->
          </ng-container>
        </button>
      </div>
    </div>
    <div class="label" *ngIf="i.type === 'label'">
      {{i.text}}
    </div>
  </ng-container>
</div>

import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'progress-indicator',
  templateUrl: './progress-indicator.component.html',
  styleUrls: ['./progress-indicator.component.scss']
})
export class ProgressIndicatorComponent implements OnInit {
  @Input() progress;
  @Input() size = 72;
  @Input() fontSize = 18;

  constructor() { }

  ngOnInit() {
  }
}

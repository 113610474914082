import { Component, OnInit, Input, OnChanges, ElementRef, ViewChild, OnDestroy } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Params, Router } from '@angular/router';

import { CommentBoxDialogComponent } from './comment-box-dialog/comment-box-dialog.component';
import { Subject, CommentService } from '../../services/comment/comment.service';
import { Subscription } from 'rxjs';
import { debounceTime, tap } from 'rxjs/operators';


@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'div[commentable], comment-button', // 2 selectors to allow me to add a "comment" button to another component
  templateUrl: './comment-box.component.html',
  styleUrls: ['./comment-box.component.scss']
})
export class CommentBoxComponent implements OnInit, OnChanges, OnDestroy {

  @ViewChild('container') container: ElementRef;

  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('comment-box-position')
  position: 'left' | 'right' | 'top' | 'bottom' |
    'top-left' | 'bottom-left' |
    'top-right' | 'bottom-right' = 'right';
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('comment-subject-codename') subjectCodename: string;
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('comment-subject-title') subjectTitle: string;
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('comment-mention-list') commentMentionsList: number[] = [];

  @Input() commentable: string;

  // true if it is to be a button
  @Input() commentButton: boolean;
  @Input('current-todo') currentTodo: string;

  subject: Subject;
  dialogRef: MatDialogRef<CommentBoxDialogComponent>;
  sub: Subscription;

  constructor(
    private dialog: MatDialog,
    private comments: CommentService,
    private route: ActivatedRoute,
    private router: Router,
  ) { }

  ngOnInit() {
  }

  ngOnChanges() {
    // First a default
    //
    // setting the commentable attribute and then setting it to false might seem pointless. The reason is to preserve legacy information on
    // places where comments used to exist (and might come back in the future), but are removed for the time being. This is because
    // commenting out HTML attributes is not possible.
    if (this.commentable !== 'false') {
      if (!this.subject) {
        this.subject = {
          codename: this.subjectCodename,
          host: this.route.snapshot.url.join('/'),
          comments: [],
          open: false,
          reminder: false,
        };
      }

      this.subject.title = this.subjectTitle;

      if (this.dialogRef) {
        this.dialogRef.componentInstance.subject = this.subject;
      }

      if (this.subject.codename) {
        this.update(true);
      }
    }
  }

  ngOnDestroy() {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }

  update(openDialog = false) {
    this.comments.get(this.subject.codename)
      .subscribe(subject => {
        this.subject = Object.assign(this.subject, subject);
        if (this.subject.codename && openDialog) {
          this.sub = this.route.queryParams.pipe(
            debounceTime(50),                                                          // --> wait 50 ms
            tap(params => {
              if (this.sub.closed) {                                                   // --> we are removed,
                return;                                                                // --> just move along
              }

              if (params['comment-subject-codename'] === this.subjectCodename) {
                this.container.nativeElement.scrollIntoView({                          // --> focus the comment element
                  behavior: 'auto',
                  block: 'center',
                });
              }
            }),
            debounceTime(450),                                                         // --> wait for scrolling
          )
            .subscribe((params: Params) => {
              if (this.sub.closed) {                                                     // --> we are removed,
                return;                                                                  // --> move along
              }

              if (params['comment-subject-codename'] === this.subjectCodename) {
                this.openDialog();
                this.router.navigate([], {
                  relativeTo: this.route,
                  queryParams: { 'comment-subject-codename': undefined },
                  queryParamsHandling: 'merge',
                  replaceUrl: true,
                });

                this.dialogRef.afterClosed().subscribe(() => {
                  setTimeout(() => this.container.nativeElement.scrollIntoView({
                    behavior: 'auto',
                    block: 'center'
                  }), 50);
                });
              }
            });
        }
      });
  }

  openDialog(event?) {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }

    if (!this.dialogRef) {
      this.dialogRef = this.dialog.open(CommentBoxDialogComponent, {
        width: '512px',
        maxHeight: '90vh',
        data: this.subject
      });

      this.dialogRef.componentInstance.mentionConfig.commentMentionsList = this.commentMentionsList;

      this.dialogRef.afterClosed().subscribe(() => {
        this.update();
        this.dialogRef = undefined;
      });
    }
  }
}

<div class="actions-metadata-info metadata-document">
  <ng-container *ngIf="!document">
    <div class="event-loading metadata-center">
      <mat-icon>bar_chart</mat-icon>
      <span>{{ 'events.metadata.loading' | translate }}</span>
    </div>
  </ng-container>
  <ng-container *ngIf="document">
    <div class="event-left">
      <span class="specimen">{{ document.fileName || '---' }}</span>
      <span class="type">
        {{ 'events.metadata.document.document' | translate }}
      </span>
    </div>
    <ng-container *ngIf="event.eventName === 'UPLOADED'">
      <div class="event-action">
        <span class="icon">
          <mat-icon>cloud_upload</mat-icon>
        </span>
        <span class="action">
          {{ 'events.metadata.document.uploaded' | translate }}
        </span>
      </div>
    </ng-container>
    <ng-container *ngIf="event.eventName === 'REPLACE_FILE'">
      <div class="event-action">
        <span class="icon">
          <mat-icon>swap_horiz</mat-icon>
        </span>
        <span class="action">
          {{ 'events.metadata.document.changedFile' | translate }}
        </span>
      </div>
    </ng-container>
    <ng-container *ngIf="event.eventName === 'UPDATE_DETAILS'">
      <div class="event-action">
        <span class="icon">
          <mat-icon>update</mat-icon>
        </span>
        <span class="action">
          {{ 'events.metadata.document.updatedDetails' | translate }}
        </span>
      </div>
    </ng-container>
  </ng-container>
</div>

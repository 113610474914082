<mat-form-field>
  <mat-label>{{'word.language' | translate}}</mat-label>
  <mat-select [compareWith]="compareLanguages" [ngModel]="currentLang" (ngModelChange)="onChange($event)">
    <mat-option *ngFor="let lang of languages" [value]="lang.identifier">
      <div class="language-item">
        <span class="language-name">
          {{lang.name}}
        </span>
        <span class="legislation {{ lang.legislation}}">
          {{ lang.legislation }}
        </span>
      </div>
      
    </mat-option>
  </mat-select>
</mat-form-field>

import {Injectable} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import {BusService} from '../bus.service';
import {
  IPostOptions,
  RequestService
} from '../request.service';
import {EventsService} from '../events.service';

interface IPostCredentialsRequest {
  conrollerId: string;
  controllerName: string;
  controllerContactName: string;
  controllerContactRole: string;
  controllerDpCoordination: string;
  controllerAddressStreet: string;
  controllerAddressPostcode: string;
  controllerAddressCity: string;
  controllerAddressCountry: string;
  controllerTelephoneNumber: string;
  controllerEmailAddress: string;
  controllerUrl: string;

  dpoId: string;
  dpoName: string;
  dpoAddressStreet: string;
  dpoAddressPostcode: string;
  dpoAddressCity: string;
  dpoAddressCountry: string;
  dpoTelephoneNumber: string;
  dpoEmailAddress: string;
  total: number;
  current: number;
}

interface IPostCredentialsResponse {
}


/*
  An interlayer service for basic-info update request between request service and basic-info component
*/
@Injectable()
export class BasicInfoPostService {

  private options: IPostOptions<IPostCredentialsRequest, IPostCredentialsResponse>;

  constructor(
    private bus: BusService,
    private events: EventsService,
    private requestService: RequestService
  ) {
    this.options = {
      uri: '/basic-info',
      body: {
        conrollerId: '',
        controllerName: '',
        controllerContactName: '',
        controllerContactRole: '',
        controllerDpCoordination: '',
        controllerAddressStreet: '',
        controllerAddressPostcode: '',
        controllerAddressCity: '',
        controllerAddressCountry: '',
        controllerTelephoneNumber: '',
        controllerEmailAddress: '',
        controllerUrl: '',

        dpoId: '',
        dpoName: '',
        dpoAddressStreet: '',
        dpoAddressPostcode: '',
        dpoAddressCity: '',
        dpoAddressCountry: '',
        dpoTelephoneNumber: '',
        dpoEmailAddress: '',
        total: 17,
        current: 0
      },
      handlers: {
        success: this.success.bind(this),
        error: this.error.bind(this)
      }
    };
    this.subscribe();
  }

  public success(data: IPostCredentialsResponse): void {
    this.bus.publish(this.events.received.data.basicInfo.update.success, data);
  }

  public error(httpErrorResponse: HttpErrorResponse): void {
    this.bus.publish(this.events.received.data.basicInfo.update.failure, httpErrorResponse);
  }

  private request(model: any): void {
    this.bus.publish(this.events.notified.saving.starting);
    Object.keys(model.data).forEach(key => {
      this.options.body[key] = model.data[key];
    });
    this.options.uri = `/basic-info`;
    this.requestService.post<IPostCredentialsRequest, IPostCredentialsResponse>(this.options);
  }

  public subscribe(): void {
    this.bus.subscribe(this.events.requested.data.basicInfo.update, this.request.bind(this));
  }
}

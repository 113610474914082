<div class="contents">
  <h1 class="title">{{ 'supervision.supervisorProfile.title' | translate }}</h1>

  <p class="message">{{ 'supervision.supervisorProfile.message' | translate }}</p>


  <mat-card class="profile-form">
    <div class="form-items">
      <div class="logo">
        <cool-upload #upload
          [preview]="true"
          upload-url="/supervision/supervisor/profile/logo"
          (uploaded)="logoUpdated()"
          upload-key="logo">
          <span hint>
            {{ 'supervision.supervisorProfile.form.logo' | translate }}
          </span>
        </cool-upload>
      </div>

      <div class="name">
        <mat-form-field>
          <input matInput placeholder="{{'supervision.supervisorProfile.form.name.placeholder' | translate}}"
                          name="name" type="text"
                          [formControl]="displayName" required autofocus/>
          <mat-hint>
            {{'supervision.supervisorProfile.form.name.hint' | translate}}
          </mat-hint>
          <mat-error *ngIf="displayName.hasError('required')">
            {{'supervision.supervisorProfile.form.name.required' | translate}}
          </mat-error>
        </mat-form-field>
      </div>

      <mat-icon *ngIf="updateIcon && displayName.pristine" class="update-icon">
        {{ updateIcon }}
      </mat-icon>

    </div>

    <mat-card-actions align="end">
      <button
        mat-raised-button
        color="primary"
        (click)="submit()"
        [disabled]="upload.uploading || !displayName.valid || loading">
        {{'supervision.supervisorProfile.form.save' | translate}}
      </button>
    </mat-card-actions>
  </mat-card>

  <br><br><mat-divider></mat-divider><br><br>

  <div style="text-align: left">
    <h2>
    {{'supervision.supervisorProfile.coSupervisors.title' | translate}}
    </h2>
    <p>
    {{'supervision.supervisorProfile.coSupervisors.hint' | translate}}
    </p>
    <div class="user" *ngFor="let user of users" (click)="editSupervisor(user)">
      <user-avatar [user]="user"></user-avatar>
      <!-- <mat-icon>account_circle</mat-icon> -->
      <div class="info">
        <div class="main">{{user.firstName}} {{user.lastName}}</div>
        <div class="sub">{{user.email}}</div>
      </div>
    </div>
    <div class="user invite" *ngFor="let invite of invites" (click)="editInvite(invite)">
      <mat-icon>email</mat-icon>
      <div class="info">
        <div class="main">{{invite.email}}</div>
        <div class="sub">
        {{'supervision.supervisorProfile.coSupervisors.invites.issued.hint' | translate}}
        </div>
      </div>
    </div>
    <div class="user button" (click)="openInviteDialog()">
      <mat-icon>person_add</mat-icon>
      <div class="info">
      {{'supervision.supervisorProfile.coSupervisors.invites.new' | translate}}
      </div>
    </div>
  </div>

  <ng-container *ngIf="profile && profile.planName !== 'default'">
    <br><br><mat-divider></mat-divider><br><br>
    <div class="plan-info">
      <h2>
        {{ 'supervision.supervisorProfile.plan.title' | translate }}
      </h2>
      <p>
        {{ 'supervision.supervisorProfile.plan' | translate }}

        <span class="plan-badge {{ profile.planName }}">
          <!-- {{ 'supervision.supervisorProfile.plan.default' | translate }} -->
          <!-- {{ 'supervision.supervisorProfile.plan.pro' | translate }} -->
          <!-- {{ 'supervision.supervisorProfile.plan.lite' | translate }} -->
          <!-- {{ 'supervision.supervisorProfile.plan.trial' | translate }} -->

          {{ 'supervision.supervisorProfile.plan.' + profile.planName | translate }}
        </span>
      </p>

      <p class="hint">{{ 'supervision.supervisorProfile.plan.explanation' | translate }}</p>
    </div>
  </ng-container>
</div>

<br><br><br>

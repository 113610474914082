<div class="contents">
  <div>
    <h1 class="title">{{ 'user-expired.titel' | translate }}</h1>
    <br/>
    <p>{{ 'user-expired.message' | translate }}</p>
    <br>
    <a
      routerLink="/payment/subscribe"
      mat-raised-button
      color="primary"
      class="subscribe">
      {{ 'user-expired.subscribe' | translate }}
    </a>
    <br><br><br>
    <mat-divider></mat-divider>
    <br><br>
    <p>
      {{'user-expired.relogin.msg' | translate}}
    </p>
    <br>
    <button mat-button color="primary" (click)="logout()">
      {{'user-expired.relogin.action' | translate}}
    </button>
  </div>
</div>

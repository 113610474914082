<!--{{'incidents.records.add.dialogTitle' | translate}}-->
<!--{{'incidents.records.update.dialogTitle' | translate}}-->
<!--{{'incidents.records.add.save' | translate}}-->
<!--{{'incidents.records.update.save' | translate}}-->
<h1 mat-dialog-title>
  {{ (!isExistingIncident? 'incidents.records.add.dialogTitle' : 'incidents.records.update.dialogTitle') | translate}}
</h1>

<mat-dialog-content>
  <div class="incidents-info">

    <mat-form-field class="title-input">
      <input matInput type="text" name="title"
        placeholder="{{'incidents.records.add.title.placeholder' | translate}}"
        [formControl]="title"/>
      <mat-hint>
        {{'incidents.records.add.title.placeholder.hint' | translate}}
      </mat-hint>
    </mat-form-field>

    <ng-select
      [items]="allDepartments"
      required
      bindLabel="name"
      bindValue="id"
      [(ngModel)]="reportingDepartmentId"
      placeholder="{{ 'incidents.records.add.department.placeholder' | translate }}">
      <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
        <div class="department-item-info">
          <div class="name">
            {{item.name}}
          </div>
        </div>
      </ng-template>
    </ng-select>

    <mat-form-field>
      <input matInput required [matDatepicker]="happenedOnPicker" [formControl]="happenedOn" placeholder="{{'incidents.records.add.happenedOn.placeholder' | translate}}">
      <mat-datepicker-toggle matSuffix [for]="happenedOnPicker"></mat-datepicker-toggle>
      <mat-datepicker #happenedOnPicker></mat-datepicker>
    </mat-form-field>

    <mat-form-field>
      <input matInput [matDatepicker]="reportedOnPicker" [formControl]="reportedOn" placeholder="{{'incidents.records.add.reportedOn.placeholder' | translate}}">
      <mat-datepicker-toggle matSuffix [for]="reportedOnPicker"></mat-datepicker-toggle>
      <mat-datepicker #reportedOnPicker></mat-datepicker>
    </mat-form-field>

    <mat-form-field>
      <textarea  matInput type="text" name="details"
        placeholder="{{'incidents.records.add.description.placeholder' | translate}}"
        [formControl]="details"></textarea>
      <mat-hint>
        {{'incidents.records.add.description.placeholder.hint' | translate}}
      </mat-hint>
    </mat-form-field>
  </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>
    {{'word.cancel' | translate}}
  </button>
  <button mat-flat-button color="primary" [disabled]="!isValid" (click)="update()">
    {{ (!isExistingIncident? 'incidents.records.add.save' : 'incidents.records.update.save') | translate}}
  </button>
</mat-dialog-actions>

<div class="users">
  <div class="user" *ngFor="let user of users"
    [ngClass]="{selected: user === selected}"
    (click)="pick(user)">
    <!-- <mat-icon>account_circle</mat-icon> -->
    <div>
      <!--
      <div class="name">
        {{user.firstName}} {{user.lastName}}
      </div>
      <div class="email">
        {{user.email}}
      </div>
      -->
      <user-avatar [user]="user"></user-avatar>
      <user-name [user]="user"></user-name>
    </div>
  </div>
</div>

<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>
    {{'word.cancel' | translate}}
  </button>
  <button mat-flat-button color="primary" [disabled]="!selected" (click)="pick(selected)">
    {{'word.select' | translate}}
  </button>
</mat-dialog-actions>
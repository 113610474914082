import { Component, OnInit } from '@angular/core';
import { BusService } from '../../../../services/bus.service';

@Component({
  selector: 'app-delete',
  templateUrl: './delete.component.html',
  styleUrls: ['./delete.component.scss']
})
export class DeleteIncidentComponent implements OnInit {

  deleteDocuments = true;

  constructor(
    private bus: BusService,
  ) { }

  ngOnInit(): void {
  }

  confirm() {
    this.bus.publish('delete-incident', this.deleteDocuments);
  }

}

<div class="contents">
  <br><br>
  <h1 class="title">
    {{'audit.prepare.title' | translate}}
  </h1>
  <p>
    {{'audit.prepare.hint' | translate}}
  </p>

  <br><br>

  <checklist codename="audit-prepare" (progress)="updateProgress($event)"></checklist>

  <br><br>

  <neat-action-bar>
    <button routerLink="/" mat-flat-button color="primary">
      {{'audit.prepare.actions.done' | translate}}
      <!-- Done -->
    </button>
  </neat-action-bar>

  <br><br><br><br>
</div>
<html>

<head>
    <meta content="text/html; charset=UTF-8" http-equiv="content-type">
    <style type="text/css">
        ol.lst-kix_ieztagj8ibd1-6 {
            list-style-type: none
        }

        ol.lst-kix_ieztagj8ibd1-5 {
            list-style-type: none
        }

        ol.lst-kix_ieztagj8ibd1-4 {
            list-style-type: none
        }

        ol.lst-kix_ieztagj8ibd1-3 {
            list-style-type: none
        }

        ol.lst-kix_ieztagj8ibd1-2 {
            list-style-type: none
        }

        .lst-kix_9yw4aj593kqb-2>li {
            counter-increment: lst-ctn-kix_9yw4aj593kqb-2
        }

        ol.lst-kix_ieztagj8ibd1-1 {
            list-style-type: none
        }

        ol.lst-kix_2odx4ejznonj-7.start {
            counter-reset: lst-ctn-kix_2odx4ejznonj-7 0
        }

        ol.lst-kix_ieztagj8ibd1-0 {
            list-style-type: none
        }

        .lst-kix_18aazqgvyehw-2>li {
            counter-increment: lst-ctn-kix_18aazqgvyehw-2
        }

        ol.lst-kix_fbic073lo147-3.start {
            counter-reset: lst-ctn-kix_fbic073lo147-3 0
        }

        .lst-kix_6l90blqj72rg-8>li {
            counter-increment: lst-ctn-kix_6l90blqj72rg-8
        }

        ol.lst-kix_ieztagj8ibd1-8 {
            list-style-type: none
        }

        .lst-kix_869vfw3c8ejv-1>li {
            counter-increment: lst-ctn-kix_869vfw3c8ejv-1
        }

        ol.lst-kix_ieztagj8ibd1-7 {
            list-style-type: none
        }

        ol.lst-kix_9yw4aj593kqb-8.start {
            counter-reset: lst-ctn-kix_9yw4aj593kqb-8 0
        }

        .lst-kix_fbic073lo147-2>li {
            counter-increment: lst-ctn-kix_fbic073lo147-2
        }

        .lst-kix_5rugzp9z4g8h-5>li {
            counter-increment: lst-ctn-kix_5rugzp9z4g8h-5
        }

        .lst-kix_ieztagj8ibd1-0>li {
            counter-increment: lst-ctn-kix_ieztagj8ibd1-0
        }

        ol.lst-kix_qpvilqatnhpj-4.start {
            counter-reset: lst-ctn-kix_qpvilqatnhpj-4 0
        }

        ol.lst-kix_5rugzp9z4g8h-5.start {
            counter-reset: lst-ctn-kix_5rugzp9z4g8h-5 0
        }

        .lst-kix_qpvilqatnhpj-7>li {
            counter-increment: lst-ctn-kix_qpvilqatnhpj-7
        }

        .lst-kix_18aazqgvyehw-4>li {
            counter-increment: lst-ctn-kix_18aazqgvyehw-4
        }

        .lst-kix_fbic073lo147-0>li {
            counter-increment: lst-ctn-kix_fbic073lo147-0
        }

        ol.lst-kix_6l90blqj72rg-7.start {
            counter-reset: lst-ctn-kix_6l90blqj72rg-7 0
        }

        ol.lst-kix_9yw4aj593kqb-2.start {
            counter-reset: lst-ctn-kix_9yw4aj593kqb-2 0
        }

        .lst-kix_6l90blqj72rg-6>li {
            counter-increment: lst-ctn-kix_6l90blqj72rg-6
        }

        ol.lst-kix_9yw4aj593kqb-1 {
            list-style-type: none
        }

        ol.lst-kix_9yw4aj593kqb-0 {
            list-style-type: none
        }

        ol.lst-kix_18aazqgvyehw-7.start {
            counter-reset: lst-ctn-kix_18aazqgvyehw-7 0
        }

        ol.lst-kix_9yw4aj593kqb-3 {
            list-style-type: none
        }

        ol.lst-kix_9yw4aj593kqb-2 {
            list-style-type: none
        }

        ol.lst-kix_9yw4aj593kqb-5 {
            list-style-type: none
        }

        ol.lst-kix_9yw4aj593kqb-4 {
            list-style-type: none
        }

        ol.lst-kix_9yw4aj593kqb-7 {
            list-style-type: none
        }

        ol.lst-kix_9yw4aj593kqb-6 {
            list-style-type: none
        }

        ol.lst-kix_9yw4aj593kqb-8 {
            list-style-type: none
        }

        ol.lst-kix_869vfw3c8ejv-6.start {
            counter-reset: lst-ctn-kix_869vfw3c8ejv-6 0
        }

        ol.lst-kix_6l90blqj72rg-1.start {
            counter-reset: lst-ctn-kix_6l90blqj72rg-1 0
        }

        ol.lst-kix_ieztagj8ibd1-5.start {
            counter-reset: lst-ctn-kix_ieztagj8ibd1-5 0
        }

        .lst-kix_2odx4ejznonj-7>li {
            counter-increment: lst-ctn-kix_2odx4ejznonj-7
        }

        ol.lst-kix_fbic073lo147-4.start {
            counter-reset: lst-ctn-kix_fbic073lo147-4 0
        }

        .lst-kix_5rugzp9z4g8h-3>li {
            counter-increment: lst-ctn-kix_5rugzp9z4g8h-3
        }

        ol.lst-kix_6l90blqj72rg-8.start {
            counter-reset: lst-ctn-kix_6l90blqj72rg-8 0
        }

        .lst-kix_9yw4aj593kqb-4>li {
            counter-increment: lst-ctn-kix_9yw4aj593kqb-4
        }

        ol.lst-kix_ieztagj8ibd1-1.start {
            counter-reset: lst-ctn-kix_ieztagj8ibd1-1 0
        }

        ol.lst-kix_2odx4ejznonj-1.start {
            counter-reset: lst-ctn-kix_2odx4ejznonj-1 0
        }

        ol.lst-kix_5rugzp9z4g8h-0.start {
            counter-reset: lst-ctn-kix_5rugzp9z4g8h-0 0
        }

        ol.lst-kix_ieztagj8ibd1-6.start {
            counter-reset: lst-ctn-kix_ieztagj8ibd1-6 0
        }

        .lst-kix_2odx4ejznonj-0>li:before {
            content: "" counter(lst-ctn-kix_2odx4ejznonj-0, decimal) ". "
        }

        ol.lst-kix_9yw4aj593kqb-7.start {
            counter-reset: lst-ctn-kix_9yw4aj593kqb-7 0
        }

        ol.lst-kix_2odx4ejznonj-2.start {
            counter-reset: lst-ctn-kix_2odx4ejznonj-2 0
        }

        ol.lst-kix_2odx4ejznonj-8.start {
            counter-reset: lst-ctn-kix_2odx4ejznonj-8 0
        }

        .lst-kix_qpvilqatnhpj-5>li {
            counter-increment: lst-ctn-kix_qpvilqatnhpj-5
        }

        .lst-kix_ieztagj8ibd1-2>li {
            counter-increment: lst-ctn-kix_ieztagj8ibd1-2
        }

        ol.lst-kix_5rugzp9z4g8h-6.start {
            counter-reset: lst-ctn-kix_5rugzp9z4g8h-6 0
        }

        ol.lst-kix_ieztagj8ibd1-0.start {
            counter-reset: lst-ctn-kix_ieztagj8ibd1-0 0
        }

        .lst-kix_6l90blqj72rg-4>li {
            counter-increment: lst-ctn-kix_6l90blqj72rg-4
        }

        ol.lst-kix_6l90blqj72rg-2.start {
            counter-reset: lst-ctn-kix_6l90blqj72rg-2 0
        }

        .lst-kix_869vfw3c8ejv-3>li {
            counter-increment: lst-ctn-kix_869vfw3c8ejv-3
        }

        .lst-kix_qpvilqatnhpj-0>li:before {
            content: "" counter(lst-ctn-kix_qpvilqatnhpj-0, decimal) ". "
        }

        .lst-kix_18aazqgvyehw-8>li:before {
            content: "" counter(lst-ctn-kix_18aazqgvyehw-8, lower-roman) ". "
        }

        ol.lst-kix_18aazqgvyehw-3.start {
            counter-reset: lst-ctn-kix_18aazqgvyehw-3 0
        }

        .lst-kix_18aazqgvyehw-7>li:before {
            content: "" counter(lst-ctn-kix_18aazqgvyehw-7, lower-latin) ". "
        }

        .lst-kix_2odx4ejznonj-4>li {
            counter-increment: lst-ctn-kix_2odx4ejznonj-4
        }

        .lst-kix_qpvilqatnhpj-2>li:before {
            content: "" counter(lst-ctn-kix_qpvilqatnhpj-2, lower-roman) ". "
        }

        ol.lst-kix_9yw4aj593kqb-1.start {
            counter-reset: lst-ctn-kix_9yw4aj593kqb-1 0
        }

        .lst-kix_qpvilqatnhpj-4>li:before {
            content: "" counter(lst-ctn-kix_qpvilqatnhpj-4, lower-latin) ". "
        }

        ol.lst-kix_869vfw3c8ejv-5.start {
            counter-reset: lst-ctn-kix_869vfw3c8ejv-5 0
        }

        .lst-kix_fbic073lo147-8>li {
            counter-increment: lst-ctn-kix_fbic073lo147-8
        }

        .lst-kix_18aazqgvyehw-3>li:before {
            content: "" counter(lst-ctn-kix_18aazqgvyehw-3, decimal) ". "
        }

        .lst-kix_18aazqgvyehw-5>li:before {
            content: "" counter(lst-ctn-kix_18aazqgvyehw-5, lower-roman) ". "
        }

        ol.lst-kix_869vfw3c8ejv-8.start {
            counter-reset: lst-ctn-kix_869vfw3c8ejv-8 0
        }

        ol.lst-kix_2odx4ejznonj-8 {
            list-style-type: none
        }

        .lst-kix_869vfw3c8ejv-7>li {
            counter-increment: lst-ctn-kix_869vfw3c8ejv-7
        }

        ol.lst-kix_2odx4ejznonj-4 {
            list-style-type: none
        }

        ol.lst-kix_2odx4ejznonj-5 {
            list-style-type: none
        }

        ol.lst-kix_2odx4ejznonj-6 {
            list-style-type: none
        }

        .lst-kix_18aazqgvyehw-1>li:before {
            content: "" counter(lst-ctn-kix_18aazqgvyehw-1, lower-latin) ". "
        }

        ol.lst-kix_2odx4ejznonj-7 {
            list-style-type: none
        }

        ol.lst-kix_2odx4ejznonj-0 {
            list-style-type: none
        }

        .lst-kix_18aazqgvyehw-8>li {
            counter-increment: lst-ctn-kix_18aazqgvyehw-8
        }

        .lst-kix_2odx4ejznonj-1>li:before {
            content: "" counter(lst-ctn-kix_2odx4ejznonj-1, lower-latin) ". "
        }

        ol.lst-kix_2odx4ejznonj-1 {
            list-style-type: none
        }

        ol.lst-kix_2odx4ejznonj-2 {
            list-style-type: none
        }

        ol.lst-kix_2odx4ejznonj-3 {
            list-style-type: none
        }

        .lst-kix_2odx4ejznonj-3>li {
            counter-increment: lst-ctn-kix_2odx4ejznonj-3
        }

        ol.lst-kix_qpvilqatnhpj-0.start {
            counter-reset: lst-ctn-kix_qpvilqatnhpj-0 0
        }

        .lst-kix_2odx4ejznonj-3>li:before {
            content: "" counter(lst-ctn-kix_2odx4ejznonj-3, decimal) ". "
        }

        .lst-kix_869vfw3c8ejv-8>li {
            counter-increment: lst-ctn-kix_869vfw3c8ejv-8
        }

        .lst-kix_9yw4aj593kqb-8>li {
            counter-increment: lst-ctn-kix_9yw4aj593kqb-8
        }

        ol.lst-kix_ieztagj8ibd1-4.start {
            counter-reset: lst-ctn-kix_ieztagj8ibd1-4 0
        }

        ol.lst-kix_qpvilqatnhpj-8 {
            list-style-type: none
        }

        .lst-kix_2odx4ejznonj-7>li:before {
            content: "" counter(lst-ctn-kix_2odx4ejznonj-7, lower-latin) ". "
        }

        ol.lst-kix_qpvilqatnhpj-7 {
            list-style-type: none
        }

        ol.lst-kix_fbic073lo147-8 {
            list-style-type: none
        }

        ol.lst-kix_qpvilqatnhpj-6 {
            list-style-type: none
        }

        .lst-kix_6l90blqj72rg-0>li {
            counter-increment: lst-ctn-kix_6l90blqj72rg-0
        }

        .lst-kix_5rugzp9z4g8h-2>li {
            counter-increment: lst-ctn-kix_5rugzp9z4g8h-2
        }

        ol.lst-kix_qpvilqatnhpj-5 {
            list-style-type: none
        }

        ol.lst-kix_fbic073lo147-6 {
            list-style-type: none
        }

        ol.lst-kix_qpvilqatnhpj-4 {
            list-style-type: none
        }

        ol.lst-kix_fbic073lo147-7 {
            list-style-type: none
        }

        .lst-kix_2odx4ejznonj-5>li:before {
            content: "" counter(lst-ctn-kix_2odx4ejznonj-5, lower-roman) ". "
        }

        ol.lst-kix_qpvilqatnhpj-3 {
            list-style-type: none
        }

        ol.lst-kix_6l90blqj72rg-0.start {
            counter-reset: lst-ctn-kix_6l90blqj72rg-0 0
        }

        ol.lst-kix_fbic073lo147-4 {
            list-style-type: none
        }

        ol.lst-kix_qpvilqatnhpj-2 {
            list-style-type: none
        }

        .lst-kix_ieztagj8ibd1-3>li:before {
            content: "" counter(lst-ctn-kix_ieztagj8ibd1-3, decimal) ". "
        }

        ol.lst-kix_fbic073lo147-5 {
            list-style-type: none
        }

        ol.lst-kix_qpvilqatnhpj-1 {
            list-style-type: none
        }

        ol.lst-kix_fbic073lo147-2 {
            list-style-type: none
        }

        ol.lst-kix_qpvilqatnhpj-0 {
            list-style-type: none
        }

        ol.lst-kix_fbic073lo147-3 {
            list-style-type: none
        }

        ol.lst-kix_fbic073lo147-0 {
            list-style-type: none
        }

        ol.lst-kix_fbic073lo147-5.start {
            counter-reset: lst-ctn-kix_fbic073lo147-5 0
        }

        ol.lst-kix_fbic073lo147-1 {
            list-style-type: none
        }

        .lst-kix_fbic073lo147-6>li {
            counter-increment: lst-ctn-kix_fbic073lo147-6
        }

        .lst-kix_ieztagj8ibd1-5>li:before {
            content: "" counter(lst-ctn-kix_ieztagj8ibd1-5, lower-roman) ". "
        }

        ol.lst-kix_ieztagj8ibd1-2.start {
            counter-reset: lst-ctn-kix_ieztagj8ibd1-2 0
        }

        ol.lst-kix_18aazqgvyehw-0.start {
            counter-reset: lst-ctn-kix_18aazqgvyehw-0 0
        }

        .lst-kix_5rugzp9z4g8h-0>li {
            counter-increment: lst-ctn-kix_5rugzp9z4g8h-0
        }

        .lst-kix_2odx4ejznonj-2>li {
            counter-increment: lst-ctn-kix_2odx4ejznonj-2
        }

        .lst-kix_ieztagj8ibd1-7>li:before {
            content: "" counter(lst-ctn-kix_ieztagj8ibd1-7, lower-latin) ". "
        }

        ol.lst-kix_2odx4ejznonj-0.start {
            counter-reset: lst-ctn-kix_2odx4ejznonj-0 0
        }

        .lst-kix_5rugzp9z4g8h-5>li:before {
            content: "" counter(lst-ctn-kix_5rugzp9z4g8h-5, lower-roman) ". "
        }

        .lst-kix_qpvilqatnhpj-3>li {
            counter-increment: lst-ctn-kix_qpvilqatnhpj-3
        }

        .lst-kix_5rugzp9z4g8h-3>li:before {
            content: "" counter(lst-ctn-kix_5rugzp9z4g8h-3, decimal) ". "
        }

        .lst-kix_5rugzp9z4g8h-7>li:before {
            content: "" counter(lst-ctn-kix_5rugzp9z4g8h-7, lower-latin) ". "
        }

        .lst-kix_5rugzp9z4g8h-1>li:before {
            content: "" counter(lst-ctn-kix_5rugzp9z4g8h-1, lower-latin) ". "
        }

        .lst-kix_9yw4aj593kqb-3>li:before {
            content: "" counter(lst-ctn-kix_9yw4aj593kqb-3, decimal) ". "
        }

        ol.lst-kix_fbic073lo147-8.start {
            counter-reset: lst-ctn-kix_fbic073lo147-8 0
        }

        .lst-kix_9yw4aj593kqb-1>li:before {
            content: "" counter(lst-ctn-kix_9yw4aj593kqb-1, lower-latin) ". "
        }

        .lst-kix_9yw4aj593kqb-7>li:before {
            content: "" counter(lst-ctn-kix_9yw4aj593kqb-7, lower-latin) ". "
        }

        ol.lst-kix_9yw4aj593kqb-3.start {
            counter-reset: lst-ctn-kix_9yw4aj593kqb-3 0
        }

        ol.lst-kix_869vfw3c8ejv-7.start {
            counter-reset: lst-ctn-kix_869vfw3c8ejv-7 0
        }

        .lst-kix_6l90blqj72rg-5>li {
            counter-increment: lst-ctn-kix_6l90blqj72rg-5
        }

        ol.lst-kix_18aazqgvyehw-1.start {
            counter-reset: lst-ctn-kix_18aazqgvyehw-1 0
        }

        .lst-kix_ieztagj8ibd1-4>li {
            counter-increment: lst-ctn-kix_ieztagj8ibd1-4
        }

        .lst-kix_9yw4aj593kqb-5>li:before {
            content: "" counter(lst-ctn-kix_9yw4aj593kqb-5, lower-roman) ". "
        }

        ol.lst-kix_18aazqgvyehw-2.start {
            counter-reset: lst-ctn-kix_18aazqgvyehw-2 0
        }

        .lst-kix_fbic073lo147-1>li {
            counter-increment: lst-ctn-kix_fbic073lo147-1
        }

        .lst-kix_qpvilqatnhpj-8>li:before {
            content: "" counter(lst-ctn-kix_qpvilqatnhpj-8, lower-roman) ". "
        }

        ol.lst-kix_9yw4aj593kqb-4.start {
            counter-reset: lst-ctn-kix_9yw4aj593kqb-4 0
        }

        .lst-kix_qpvilqatnhpj-6>li:before {
            content: "" counter(lst-ctn-kix_qpvilqatnhpj-6, decimal) ". "
        }

        ol.lst-kix_ieztagj8ibd1-3.start {
            counter-reset: lst-ctn-kix_ieztagj8ibd1-3 0
        }

        .lst-kix_5rugzp9z4g8h-1>li {
            counter-increment: lst-ctn-kix_5rugzp9z4g8h-1
        }

        ol.lst-kix_fbic073lo147-7.start {
            counter-reset: lst-ctn-kix_fbic073lo147-7 0
        }

        .lst-kix_18aazqgvyehw-3>li {
            counter-increment: lst-ctn-kix_18aazqgvyehw-3
        }

        .lst-kix_5rugzp9z4g8h-7>li {
            counter-increment: lst-ctn-kix_5rugzp9z4g8h-7
        }

        .lst-kix_fbic073lo147-7>li {
            counter-increment: lst-ctn-kix_fbic073lo147-7
        }

        .lst-kix_9yw4aj593kqb-3>li {
            counter-increment: lst-ctn-kix_9yw4aj593kqb-3
        }

        ol.lst-kix_fbic073lo147-6.start {
            counter-reset: lst-ctn-kix_fbic073lo147-6 0
        }

        .lst-kix_5rugzp9z4g8h-6>li {
            counter-increment: lst-ctn-kix_5rugzp9z4g8h-6
        }

        .lst-kix_869vfw3c8ejv-2>li {
            counter-increment: lst-ctn-kix_869vfw3c8ejv-2
        }

        ol.lst-kix_qpvilqatnhpj-7.start {
            counter-reset: lst-ctn-kix_qpvilqatnhpj-7 0
        }

        .lst-kix_qpvilqatnhpj-6>li {
            counter-increment: lst-ctn-kix_qpvilqatnhpj-6
        }

        .lst-kix_6l90blqj72rg-7>li:before {
            content: "" counter(lst-ctn-kix_6l90blqj72rg-7, lower-latin) ". "
        }

        .lst-kix_6l90blqj72rg-8>li:before {
            content: "" counter(lst-ctn-kix_6l90blqj72rg-8, lower-roman) ". "
        }

        ol.lst-kix_5rugzp9z4g8h-2.start {
            counter-reset: lst-ctn-kix_5rugzp9z4g8h-2 0
        }

        .lst-kix_6l90blqj72rg-5>li:before {
            content: "" counter(lst-ctn-kix_6l90blqj72rg-5, lower-roman) ". "
        }

        .lst-kix_6l90blqj72rg-6>li:before {
            content: "" counter(lst-ctn-kix_6l90blqj72rg-6, decimal) ". "
        }

        .lst-kix_9yw4aj593kqb-1>li {
            counter-increment: lst-ctn-kix_9yw4aj593kqb-1
        }

        .lst-kix_ieztagj8ibd1-1>li {
            counter-increment: lst-ctn-kix_ieztagj8ibd1-1
        }

        ol.lst-kix_9yw4aj593kqb-5.start {
            counter-reset: lst-ctn-kix_9yw4aj593kqb-5 0
        }

        ol.lst-kix_2odx4ejznonj-4.start {
            counter-reset: lst-ctn-kix_2odx4ejznonj-4 0
        }

        .lst-kix_6l90blqj72rg-0>li:before {
            content: "" counter(lst-ctn-kix_6l90blqj72rg-0, decimal) ". "
        }

        .lst-kix_5rugzp9z4g8h-8>li {
            counter-increment: lst-ctn-kix_5rugzp9z4g8h-8
        }

        .lst-kix_6l90blqj72rg-1>li:before {
            content: "" counter(lst-ctn-kix_6l90blqj72rg-1, lower-latin) ". "
        }

        .lst-kix_6l90blqj72rg-2>li:before {
            content: "" counter(lst-ctn-kix_6l90blqj72rg-2, lower-roman) ". "
        }

        .lst-kix_qpvilqatnhpj-4>li {
            counter-increment: lst-ctn-kix_qpvilqatnhpj-4
        }

        .lst-kix_6l90blqj72rg-3>li:before {
            content: "" counter(lst-ctn-kix_6l90blqj72rg-3, decimal) ". "
        }

        .lst-kix_6l90blqj72rg-4>li:before {
            content: "" counter(lst-ctn-kix_6l90blqj72rg-4, lower-latin) ". "
        }

        .lst-kix_fbic073lo147-3>li {
            counter-increment: lst-ctn-kix_fbic073lo147-3
        }

        .lst-kix_869vfw3c8ejv-8>li:before {
            content: "" counter(lst-ctn-kix_869vfw3c8ejv-8, lower-roman) ". "
        }

        ol.lst-kix_5rugzp9z4g8h-8.start {
            counter-reset: lst-ctn-kix_5rugzp9z4g8h-8 0
        }

        .lst-kix_ieztagj8ibd1-3>li {
            counter-increment: lst-ctn-kix_ieztagj8ibd1-3
        }

        .lst-kix_fbic073lo147-1>li:before {
            content: "" counter(lst-ctn-kix_fbic073lo147-1, lower-latin) ". "
        }

        ol.lst-kix_ieztagj8ibd1-8.start {
            counter-reset: lst-ctn-kix_ieztagj8ibd1-8 0
        }

        ol.lst-kix_qpvilqatnhpj-1.start {
            counter-reset: lst-ctn-kix_qpvilqatnhpj-1 0
        }

        .lst-kix_fbic073lo147-0>li:before {
            content: "" counter(lst-ctn-kix_fbic073lo147-0, decimal) ". "
        }

        .lst-kix_2odx4ejznonj-8>li {
            counter-increment: lst-ctn-kix_2odx4ejznonj-8
        }

        ol.lst-kix_6l90blqj72rg-8 {
            list-style-type: none
        }

        .lst-kix_fbic073lo147-7>li:before {
            content: "" counter(lst-ctn-kix_fbic073lo147-7, lower-latin) ". "
        }

        ol.lst-kix_6l90blqj72rg-7 {
            list-style-type: none
        }

        ol.lst-kix_9yw4aj593kqb-6.start {
            counter-reset: lst-ctn-kix_9yw4aj593kqb-6 0
        }

        ol.lst-kix_869vfw3c8ejv-3.start {
            counter-reset: lst-ctn-kix_869vfw3c8ejv-3 0
        }

        .lst-kix_fbic073lo147-4>li:before {
            content: "" counter(lst-ctn-kix_fbic073lo147-4, lower-latin) ". "
        }

        .lst-kix_fbic073lo147-8>li:before {
            content: "" counter(lst-ctn-kix_fbic073lo147-8, lower-roman) ". "
        }

        .lst-kix_9yw4aj593kqb-5>li {
            counter-increment: lst-ctn-kix_9yw4aj593kqb-5
        }

        .lst-kix_869vfw3c8ejv-2>li:before {
            content: "" counter(lst-ctn-kix_869vfw3c8ejv-2, lower-roman) ". "
        }

        .lst-kix_fbic073lo147-3>li:before {
            content: "" counter(lst-ctn-kix_fbic073lo147-3, decimal) ". "
        }

        .lst-kix_869vfw3c8ejv-0>li:before {
            content: "" counter(lst-ctn-kix_869vfw3c8ejv-0, decimal) ". "
        }

        .lst-kix_869vfw3c8ejv-1>li:before {
            content: "" counter(lst-ctn-kix_869vfw3c8ejv-1, lower-latin) ". "
        }

        .lst-kix_2odx4ejznonj-1>li {
            counter-increment: lst-ctn-kix_2odx4ejznonj-1
        }

        .lst-kix_fbic073lo147-2>li:before {
            content: "" counter(lst-ctn-kix_fbic073lo147-2, lower-roman) ". "
        }

        .lst-kix_869vfw3c8ejv-6>li:before {
            content: "" counter(lst-ctn-kix_869vfw3c8ejv-6, decimal) ". "
        }

        .lst-kix_869vfw3c8ejv-7>li:before {
            content: "" counter(lst-ctn-kix_869vfw3c8ejv-7, lower-latin) ". "
        }

        .lst-kix_18aazqgvyehw-5>li {
            counter-increment: lst-ctn-kix_18aazqgvyehw-5
        }

        ol.lst-kix_5rugzp9z4g8h-7.start {
            counter-reset: lst-ctn-kix_5rugzp9z4g8h-7 0
        }

        ol.lst-kix_qpvilqatnhpj-8.start {
            counter-reset: lst-ctn-kix_qpvilqatnhpj-8 0
        }

        .lst-kix_869vfw3c8ejv-3>li:before {
            content: "" counter(lst-ctn-kix_869vfw3c8ejv-3, decimal) ". "
        }

        .lst-kix_fbic073lo147-5>li {
            counter-increment: lst-ctn-kix_fbic073lo147-5
        }

        ol.lst-kix_18aazqgvyehw-4.start {
            counter-reset: lst-ctn-kix_18aazqgvyehw-4 0
        }

        .lst-kix_fbic073lo147-5>li:before {
            content: "" counter(lst-ctn-kix_fbic073lo147-5, lower-roman) ". "
        }

        .lst-kix_18aazqgvyehw-1>li {
            counter-increment: lst-ctn-kix_18aazqgvyehw-1
        }

        .lst-kix_ieztagj8ibd1-0>li:before {
            content: "" counter(lst-ctn-kix_ieztagj8ibd1-0, decimal) ". "
        }

        .lst-kix_869vfw3c8ejv-4>li:before {
            content: "" counter(lst-ctn-kix_869vfw3c8ejv-4, lower-latin) ". "
        }

        .lst-kix_869vfw3c8ejv-5>li:before {
            content: "" counter(lst-ctn-kix_869vfw3c8ejv-5, lower-roman) ". "
        }

        ol.lst-kix_9yw4aj593kqb-0.start {
            counter-reset: lst-ctn-kix_9yw4aj593kqb-0 0
        }

        .lst-kix_fbic073lo147-6>li:before {
            content: "" counter(lst-ctn-kix_fbic073lo147-6, decimal) ". "
        }

        .lst-kix_ieztagj8ibd1-1>li:before {
            content: "" counter(lst-ctn-kix_ieztagj8ibd1-1, lower-latin) ". "
        }

        .lst-kix_869vfw3c8ejv-6>li {
            counter-increment: lst-ctn-kix_869vfw3c8ejv-6
        }

        .lst-kix_869vfw3c8ejv-0>li {
            counter-increment: lst-ctn-kix_869vfw3c8ejv-0
        }

        ol.lst-kix_qpvilqatnhpj-2.start {
            counter-reset: lst-ctn-kix_qpvilqatnhpj-2 0
        }

        ol.lst-kix_869vfw3c8ejv-4.start {
            counter-reset: lst-ctn-kix_869vfw3c8ejv-4 0
        }

        .lst-kix_ieztagj8ibd1-8>li {
            counter-increment: lst-ctn-kix_ieztagj8ibd1-8
        }

        ol.lst-kix_18aazqgvyehw-7 {
            list-style-type: none
        }

        ol.lst-kix_18aazqgvyehw-8 {
            list-style-type: none
        }

        .lst-kix_qpvilqatnhpj-8>li {
            counter-increment: lst-ctn-kix_qpvilqatnhpj-8
        }

        .lst-kix_ieztagj8ibd1-5>li {
            counter-increment: lst-ctn-kix_ieztagj8ibd1-5
        }

        ol.lst-kix_6l90blqj72rg-2 {
            list-style-type: none
        }

        ol.lst-kix_18aazqgvyehw-1 {
            list-style-type: none
        }

        ol.lst-kix_6l90blqj72rg-1 {
            list-style-type: none
        }

        ol.lst-kix_18aazqgvyehw-2 {
            list-style-type: none
        }

        ol.lst-kix_6l90blqj72rg-0 {
            list-style-type: none
        }

        ol.lst-kix_18aazqgvyehw-0 {
            list-style-type: none
        }

        ol.lst-kix_6l90blqj72rg-6 {
            list-style-type: none
        }

        ol.lst-kix_18aazqgvyehw-5 {
            list-style-type: none
        }

        ol.lst-kix_18aazqgvyehw-5.start {
            counter-reset: lst-ctn-kix_18aazqgvyehw-5 0
        }

        ol.lst-kix_6l90blqj72rg-5 {
            list-style-type: none
        }

        ol.lst-kix_18aazqgvyehw-6 {
            list-style-type: none
        }

        .lst-kix_qpvilqatnhpj-2>li {
            counter-increment: lst-ctn-kix_qpvilqatnhpj-2
        }

        ol.lst-kix_6l90blqj72rg-4 {
            list-style-type: none
        }

        ol.lst-kix_18aazqgvyehw-3 {
            list-style-type: none
        }

        ol.lst-kix_6l90blqj72rg-3 {
            list-style-type: none
        }

        .lst-kix_6l90blqj72rg-7>li {
            counter-increment: lst-ctn-kix_6l90blqj72rg-7
        }

        ol.lst-kix_18aazqgvyehw-4 {
            list-style-type: none
        }

        .lst-kix_18aazqgvyehw-6>li:before {
            content: "" counter(lst-ctn-kix_18aazqgvyehw-6, decimal) ". "
        }

        .lst-kix_qpvilqatnhpj-0>li {
            counter-increment: lst-ctn-kix_qpvilqatnhpj-0
        }

        .lst-kix_qpvilqatnhpj-3>li:before {
            content: "" counter(lst-ctn-kix_qpvilqatnhpj-3, decimal) ". "
        }

        ol.lst-kix_869vfw3c8ejv-0 {
            list-style-type: none
        }

        ol.lst-kix_qpvilqatnhpj-3.start {
            counter-reset: lst-ctn-kix_qpvilqatnhpj-3 0
        }

        .lst-kix_6l90blqj72rg-1>li {
            counter-increment: lst-ctn-kix_6l90blqj72rg-1
        }

        .lst-kix_18aazqgvyehw-4>li:before {
            content: "" counter(lst-ctn-kix_18aazqgvyehw-4, lower-latin) ". "
        }

        ol.lst-kix_869vfw3c8ejv-1 {
            list-style-type: none
        }

        ol.lst-kix_2odx4ejznonj-3.start {
            counter-reset: lst-ctn-kix_2odx4ejznonj-3 0
        }

        ol.lst-kix_6l90blqj72rg-3.start {
            counter-reset: lst-ctn-kix_6l90blqj72rg-3 0
        }

        ol.lst-kix_ieztagj8ibd1-7.start {
            counter-reset: lst-ctn-kix_ieztagj8ibd1-7 0
        }

        .lst-kix_qpvilqatnhpj-5>li:before {
            content: "" counter(lst-ctn-kix_qpvilqatnhpj-5, lower-roman) ". "
        }

        .lst-kix_9yw4aj593kqb-7>li {
            counter-increment: lst-ctn-kix_9yw4aj593kqb-7
        }

        ol.lst-kix_869vfw3c8ejv-8 {
            list-style-type: none
        }

        .lst-kix_18aazqgvyehw-0>li:before {
            content: "" counter(lst-ctn-kix_18aazqgvyehw-0, decimal) ". "
        }

        ol.lst-kix_869vfw3c8ejv-6 {
            list-style-type: none
        }

        .lst-kix_6l90blqj72rg-2>li {
            counter-increment: lst-ctn-kix_6l90blqj72rg-2
        }

        ol.lst-kix_869vfw3c8ejv-7 {
            list-style-type: none
        }

        ol.lst-kix_869vfw3c8ejv-4 {
            list-style-type: none
        }

        .lst-kix_18aazqgvyehw-2>li:before {
            content: "" counter(lst-ctn-kix_18aazqgvyehw-2, lower-roman) ". "
        }

        ol.lst-kix_869vfw3c8ejv-5 {
            list-style-type: none
        }

        ol.lst-kix_869vfw3c8ejv-2 {
            list-style-type: none
        }

        ol.lst-kix_869vfw3c8ejv-3 {
            list-style-type: none
        }

        ol.lst-kix_18aazqgvyehw-6.start {
            counter-reset: lst-ctn-kix_18aazqgvyehw-6 0
        }

        .lst-kix_2odx4ejznonj-2>li:before {
            content: "" counter(lst-ctn-kix_2odx4ejznonj-2, lower-roman) ". "
        }

        .lst-kix_2odx4ejznonj-4>li:before {
            content: "" counter(lst-ctn-kix_2odx4ejznonj-4, lower-latin) ". "
        }

        .lst-kix_qpvilqatnhpj-1>li:before {
            content: "" counter(lst-ctn-kix_qpvilqatnhpj-1, decimal) ". "
        }

        ol.lst-kix_6l90blqj72rg-6.start {
            counter-reset: lst-ctn-kix_6l90blqj72rg-6 0
        }

        ol.lst-kix_869vfw3c8ejv-2.start {
            counter-reset: lst-ctn-kix_869vfw3c8ejv-2 0
        }

        .lst-kix_ieztagj8ibd1-7>li {
            counter-increment: lst-ctn-kix_ieztagj8ibd1-7
        }

        ol.lst-kix_5rugzp9z4g8h-2 {
            list-style-type: none
        }

        .lst-kix_ieztagj8ibd1-2>li:before {
            content: "" counter(lst-ctn-kix_ieztagj8ibd1-2, lower-roman) ". "
        }

        ol.lst-kix_5rugzp9z4g8h-3 {
            list-style-type: none
        }

        .lst-kix_2odx4ejznonj-6>li:before {
            content: "" counter(lst-ctn-kix_2odx4ejznonj-6, decimal) ". "
        }

        ol.lst-kix_5rugzp9z4g8h-4 {
            list-style-type: none
        }

        .lst-kix_18aazqgvyehw-7>li {
            counter-increment: lst-ctn-kix_18aazqgvyehw-7
        }

        ol.lst-kix_5rugzp9z4g8h-5 {
            list-style-type: none
        }

        ol.lst-kix_2odx4ejznonj-6.start {
            counter-reset: lst-ctn-kix_2odx4ejznonj-6 0
        }

        ol.lst-kix_5rugzp9z4g8h-0 {
            list-style-type: none
        }

        ol.lst-kix_5rugzp9z4g8h-1 {
            list-style-type: none
        }

        .lst-kix_ieztagj8ibd1-6>li:before {
            content: "" counter(lst-ctn-kix_ieztagj8ibd1-6, decimal) ". "
        }

        .lst-kix_qpvilqatnhpj-1>li {
            counter-increment: lst-ctn-kix_qpvilqatnhpj-1
        }

        .lst-kix_ieztagj8ibd1-6>li {
            counter-increment: lst-ctn-kix_ieztagj8ibd1-6
        }

        ol.lst-kix_5rugzp9z4g8h-6 {
            list-style-type: none
        }

        .lst-kix_6l90blqj72rg-3>li {
            counter-increment: lst-ctn-kix_6l90blqj72rg-3
        }

        .lst-kix_ieztagj8ibd1-4>li:before {
            content: "" counter(lst-ctn-kix_ieztagj8ibd1-4, lower-latin) ". "
        }

        ol.lst-kix_5rugzp9z4g8h-7 {
            list-style-type: none
        }

        .lst-kix_2odx4ejznonj-8>li:before {
            content: "" counter(lst-ctn-kix_2odx4ejznonj-8, lower-roman) ". "
        }

        ol.lst-kix_5rugzp9z4g8h-8 {
            list-style-type: none
        }

        ol.lst-kix_5rugzp9z4g8h-1.start {
            counter-reset: lst-ctn-kix_5rugzp9z4g8h-1 0
        }

        .lst-kix_ieztagj8ibd1-8>li:before {
            content: "" counter(lst-ctn-kix_ieztagj8ibd1-8, lower-roman) ". "
        }

        ol.lst-kix_fbic073lo147-2.start {
            counter-reset: lst-ctn-kix_fbic073lo147-2 0
        }

        .lst-kix_2odx4ejznonj-5>li {
            counter-increment: lst-ctn-kix_2odx4ejznonj-5
        }

        ol.lst-kix_2odx4ejznonj-5.start {
            counter-reset: lst-ctn-kix_2odx4ejznonj-5 0
        }

        .lst-kix_5rugzp9z4g8h-4>li:before {
            content: "" counter(lst-ctn-kix_5rugzp9z4g8h-4, lower-latin) ". "
        }

        ol.lst-kix_qpvilqatnhpj-5.start {
            counter-reset: lst-ctn-kix_qpvilqatnhpj-5 0
        }

        ol.lst-kix_5rugzp9z4g8h-4.start {
            counter-reset: lst-ctn-kix_5rugzp9z4g8h-4 0
        }

        ol.lst-kix_18aazqgvyehw-8.start {
            counter-reset: lst-ctn-kix_18aazqgvyehw-8 0
        }

        .lst-kix_9yw4aj593kqb-0>li:before {
            content: "" counter(lst-ctn-kix_9yw4aj593kqb-0, decimal) ". "
        }

        ol.lst-kix_fbic073lo147-1.start {
            counter-reset: lst-ctn-kix_fbic073lo147-1 0
        }

        .lst-kix_18aazqgvyehw-0>li {
            counter-increment: lst-ctn-kix_18aazqgvyehw-0
        }

        .lst-kix_5rugzp9z4g8h-0>li:before {
            content: "" counter(lst-ctn-kix_5rugzp9z4g8h-0, decimal) ". "
        }

        .lst-kix_5rugzp9z4g8h-8>li:before {
            content: "" counter(lst-ctn-kix_5rugzp9z4g8h-8, lower-roman) ". "
        }

        .lst-kix_9yw4aj593kqb-2>li:before {
            content: "" counter(lst-ctn-kix_9yw4aj593kqb-2, lower-roman) ". "
        }

        ol.lst-kix_869vfw3c8ejv-0.start {
            counter-reset: lst-ctn-kix_869vfw3c8ejv-0 0
        }

        .lst-kix_5rugzp9z4g8h-2>li:before {
            content: "" counter(lst-ctn-kix_5rugzp9z4g8h-2, lower-roman) ". "
        }

        ol.lst-kix_6l90blqj72rg-4.start {
            counter-reset: lst-ctn-kix_6l90blqj72rg-4 0
        }

        .lst-kix_9yw4aj593kqb-6>li:before {
            content: "" counter(lst-ctn-kix_9yw4aj593kqb-6, decimal) ". "
        }

        .lst-kix_869vfw3c8ejv-4>li {
            counter-increment: lst-ctn-kix_869vfw3c8ejv-4
        }

        .lst-kix_9yw4aj593kqb-4>li:before {
            content: "" counter(lst-ctn-kix_9yw4aj593kqb-4, lower-latin) ". "
        }

        .lst-kix_9yw4aj593kqb-8>li:before {
            content: "" counter(lst-ctn-kix_9yw4aj593kqb-8, lower-roman) ". "
        }

        .lst-kix_869vfw3c8ejv-5>li {
            counter-increment: lst-ctn-kix_869vfw3c8ejv-5
        }

        .lst-kix_2odx4ejznonj-0>li {
            counter-increment: lst-ctn-kix_2odx4ejznonj-0
        }

        ol.lst-kix_fbic073lo147-0.start {
            counter-reset: lst-ctn-kix_fbic073lo147-0 0
        }

        ol.lst-kix_qpvilqatnhpj-6.start {
            counter-reset: lst-ctn-kix_qpvilqatnhpj-6 0
        }

        ol.lst-kix_5rugzp9z4g8h-3.start {
            counter-reset: lst-ctn-kix_5rugzp9z4g8h-3 0
        }

        .lst-kix_2odx4ejznonj-6>li {
            counter-increment: lst-ctn-kix_2odx4ejznonj-6
        }

        li.li-bullet-0:before {
            margin-left: -18pt;
            white-space: nowrap;
            display: inline-block;
            min-width: 18pt
        }

        ol.lst-kix_6l90blqj72rg-5.start {
            counter-reset: lst-ctn-kix_6l90blqj72rg-5 0
        }

        ol.lst-kix_869vfw3c8ejv-1.start {
            counter-reset: lst-ctn-kix_869vfw3c8ejv-1 0
        }

        .lst-kix_fbic073lo147-4>li {
            counter-increment: lst-ctn-kix_fbic073lo147-4
        }

        .lst-kix_qpvilqatnhpj-7>li:before {
            content: "" counter(lst-ctn-kix_qpvilqatnhpj-7, lower-latin) ". "
        }

        .lst-kix_18aazqgvyehw-6>li {
            counter-increment: lst-ctn-kix_18aazqgvyehw-6
        }

        .lst-kix_9yw4aj593kqb-0>li {
            counter-increment: lst-ctn-kix_9yw4aj593kqb-0
        }

        .lst-kix_5rugzp9z4g8h-4>li {
            counter-increment: lst-ctn-kix_5rugzp9z4g8h-4
        }

        .lst-kix_9yw4aj593kqb-6>li {
            counter-increment: lst-ctn-kix_9yw4aj593kqb-6
        }

        .lst-kix_5rugzp9z4g8h-6>li:before {
            content: "" counter(lst-ctn-kix_5rugzp9z4g8h-6, decimal) ". "
        }

        ol {
            margin: 0;
            padding: 0
        }

        table td,
        table th {
            padding: 0
        }

        .c9 {
            margin-left: 36pt;
            padding-top: 0pt;
            text-indent: -18pt;
            padding-bottom: 8pt;
            line-height: 1.15;
            orphans: 2;
            widows: 2;
            text-align: justify;
            height: 10.5pt
        }

        .c11 {
            background-color: #ffffff;
            margin-left: 72pt;
            padding-top: 21pt;
            padding-left: 0pt;
            padding-bottom: 0pt;
            line-height: 1.15;
            orphans: 2;
            widows: 2;
            text-align: left
        }

        .c15 {
            margin-left: 36pt;
            padding-top: 0pt;
            text-indent: -18pt;
            padding-bottom: 8pt;
            line-height: 1.15;
            orphans: 2;
            widows: 2;
            text-align: left;
            height: 10.5pt
        }

        .c20 {
            background-color: #ffffff;
            margin-left: 72pt;
            padding-top: 18pt;
            padding-left: 0pt;
            padding-bottom: 0pt;
            line-height: 1.15;
            orphans: 2;
            widows: 2;
            text-align: left
        }

        .c10 {
            margin-left: 72pt;
            padding-top: 0pt;
            padding-left: 0pt;
            padding-bottom: 8pt;
            line-height: 1.15;
            orphans: 2;
            widows: 2;
            text-align: justify
        }

        .c6 {
            margin-left: 36pt;
            padding-top: 0pt;
            padding-left: 0pt;
            padding-bottom: 8pt;
            line-height: 1.15;
            orphans: 2;
            widows: 2;
            text-align: justify
        }

        .c14 {
            margin-left: 36pt;
            padding-top: 0pt;
            text-indent: -18pt;
            padding-bottom: 8pt;
            line-height: 1.15;
            orphans: 2;
            widows: 2;
            text-align: right
        }

        .c5 {
            margin-left: 36pt;
            padding-top: 15pt;
            text-indent: -18pt;
            padding-bottom: 8pt;
            line-height: 1.6;
            orphans: 2;
            widows: 2;
            text-align: justify
        }

        .c0 {
            margin-left: 36pt;
            padding-top: 0pt;
            padding-left: 0pt;
            padding-bottom: 8pt;
            line-height: 1.15;
            orphans: 2;
            widows: 2;
            text-align: left
        }

        .c3 {
            color: #4d4d4d;
            font-weight: 400;
            text-decoration: none;
            vertical-align: baseline;
            font-size: 19.5pt;
            font-family: "Arial";
            font-style: normal
        }

        .c1 {
            color: #666666;
            font-weight: 400;
            text-decoration: none;
            vertical-align: baseline;
            font-size: 10.5pt;
            font-family: "Arial";
            font-style: normal
        }

        .c12 {
            padding-top: 15pt;
            padding-bottom: 8pt;
            line-height: 1.6;
            orphans: 2;
            widows: 2;
            text-align: justify
        }

        .c18 {
            padding-top: 0pt;
            padding-bottom: 8pt;
            line-height: 1.15;
            orphans: 2;
            widows: 2;
            text-align: justify
        }

        .c17 {
            font-weight: 400;
            text-decoration: none;
            vertical-align: baseline;
            font-family: "Arial";
            font-style: italic
        }

        .c4 {
            font-weight: 700;
            text-decoration: none;
            vertical-align: baseline;
            font-family: "Arial";
            font-style: normal
        }

        .c13 {
            background-color: #ffffff;
            color: #141414;
            font-size: 24pt
        }

        .c8 {
            background-color: #ffffff;
            max-width: 451.4pt;
            padding: 72pt 72pt 72pt 72pt
        }

        .c2 {
            color: #666666;
            font-size: 10.5pt
        }

        .c19 {
            color: #4d4d4d;
            font-size: 19.5pt
        }

        .c7 {
            padding: 0;
            margin: 0
        }

        .c16 {
            height: 10.5pt
        }

        .title {
            padding-top: 0pt;
            color: #666666;
            font-size: 26pt;
            padding-bottom: 3pt;
            font-family: "Arial";
            line-height: 1.15;
            page-break-after: avoid;
            orphans: 2;
            widows: 2;
            text-align: left
        }

        .subtitle {
            padding-top: 0pt;
            color: #666666;
            font-size: 15pt;
            padding-bottom: 16pt;
            font-family: "Arial";
            line-height: 1.15;
            page-break-after: avoid;
            orphans: 2;
            widows: 2;
            text-align: left
        }

        li {
            color: #666666;
            font-size: 10.5pt;
            font-family: "Arial"
        }

        p {
            margin: 0;
            color: #666666;
            font-size: 10.5pt;
            font-family: "Arial"
        }

        h1 {
            padding-top: 20pt;
            color: #666666;
            font-size: 20pt;
            padding-bottom: 6pt;
            font-family: "Arial";
            line-height: 1.15;
            page-break-after: avoid;
            orphans: 2;
            widows: 2;
            text-align: left
        }

        h2 {
            padding-top: 18pt;
            color: #666666;
            font-size: 16pt;
            padding-bottom: 6pt;
            font-family: "Arial";
            line-height: 1.15;
            page-break-after: avoid;
            orphans: 2;
            widows: 2;
            text-align: left
        }

        h3 {
            padding-top: 16pt;
            color: #434343;
            font-size: 14pt;
            padding-bottom: 4pt;
            font-family: "Arial";
            line-height: 1.15;
            page-break-after: avoid;
            orphans: 2;
            widows: 2;
            text-align: left
        }

        h4 {
            padding-top: 14pt;
            color: #666666;
            font-size: 12pt;
            padding-bottom: 4pt;
            font-family: "Arial";
            line-height: 1.15;
            page-break-after: avoid;
            orphans: 2;
            widows: 2;
            text-align: left
        }

        h5 {
            padding-top: 12pt;
            color: #666666;
            font-size: 11pt;
            padding-bottom: 4pt;
            font-family: "Arial";
            line-height: 1.15;
            page-break-after: avoid;
            orphans: 2;
            widows: 2;
            text-align: left
        }

        h6 {
            padding-top: 12pt;
            color: #666666;
            font-size: 11pt;
            padding-bottom: 4pt;
            font-family: "Arial";
            line-height: 1.15;
            page-break-after: avoid;
            font-style: italic;
            orphans: 2;
            widows: 2;
            text-align: left
        }
    </style>
</head>

<body class="c8">
    <div>
        <p class="c14 c16"><span class="c1"></span></p>
        <p class="c14"><span><br></span><span class="c2 c17">Stand: Juni 2022</span></p>
    </div>
    <h1 class="c12" id="h.75j9f7m0q4a4"><span class="c4 c13">Allgemeine Gesch&auml;ftsbedingungen der ECOMPLY GmbH
        </span></h1>
    <p class="c18"><span class="c2">Diese Nutzungsbedingungen sind Teil eines Vertrages zwischen der
        </span><span>ECOMPLY</span><span class="c2">&nbsp;GmbH</span><span>&nbsp;</span><span class="c1">(nachfolgend
            &bdquo;Ecomply.io&ldquo;) und deren Kunden &uuml;ber die Nutzung der Ecomply.io Software und damit
            verbundener L&ouml;sungen und Dienstleistungen.</span></p>
    <h3 class="c5" id="h.andu94vzt2w0"><span class="c3">1. Definitionen und Leistungsbeschreibung</span></h3>
    <ol class="c7 lst-kix_869vfw3c8ejv-0 start" start="1">
        <li class="c6 li-bullet-0"><span class="c1">Als &bdquo;Software&ldquo; werden Software Add-ins, Browser
                Extensions, mobile Applikationen, Internetseiten oder andere Medien benannt.</span></li>
        <li class="c6 li-bullet-0"><span class="c1">Als &bdquo;Nutzer&ldquo; werden Benutzer der Software und des
                gesamten Angebots bezeichnet.</span></li>
        <li class="c6 li-bullet-0"><span class="c1">Als &bdquo;Kunden&ldquo; werden Organisationen, Institutionen,
                kommunale Verwaltungen, Gewerbetreibende oder Unternehmen bezeichnet, die die Ecomply.io Software
                verwenden und einsetzen.</span></li>
        <li class="c6 li-bullet-0"><span>D</span><span class="c2">er Leistungsumfang er</span><span>gibt sich
            </span><span class="c1">aus der Beschreibung auf www.ecomply.io</span></li>
    </ol>
    <hr>
    <p class="c9"><span class="c1"></span></p>
    <h3 class="c5" id="h.h30mgj5s5l5y"><span class="c3">2. Rechte und Pflichten von Ecomply.io</span></h3>
    <ol class="c7 lst-kix_9yw4aj593kqb-0 start" start="1">
        <li class="c6 li-bullet-0"><span class="c1">Ecomply.io beh&auml;lt sich das Recht vor, die Ecomply.io-Software
                jederzeit in einer dem Kunden zumutbaren Art und Weise zu &auml;ndern, z.B. um diese weiter zu
                entwickeln oder qualitativ zu verbessern.</span></li>
        <li class="c6 li-bullet-0"><span class="c1">Ecomply.io beh&auml;lt sich vor, diese Nutzungsbedingungen / AGB
                jederzeit und ohne Nennung von Gr&uuml;nden zu &auml;ndern. Die ge&auml;nderten Bedingungen werden den
                Kunden per E-Mail sp&auml;testens zwei Wochen vor ihrem Inkrafttreten zugesendet. Widerspricht ein Kunde
                der Geltung der neuen Nutzungsbedingungen / AGB nicht innerhalb von zwei Wochen nach Empfang der E-Mail,
                gelten die ge&auml;nderten AGB als angenommen. Ecomply.io wird die Kunden in der E-Mail, die die
                ge&auml;nderten Bedingungen enth&auml;lt, auf die Bedeutung dieser Zweiwochenfrist gesondert
                hinweisen.</span></li>
        <li class="c6 li-bullet-0"><span class="c1">Aufgrund technischer St&ouml;rungen und Wartungen kann es zu einer
                zeitlich begrenzten eingeschr&auml;nkten Nutzbarkeit kommen. Ecomply.io wird eventuelle St&ouml;rungen
                stets m&ouml;glichst schnell beheben, um diese Zeiten so gering wie m&ouml;glich zu halten und den
                Kunden &ndash; sofern m&ouml;glich &ndash; &uuml;ber vorhersehbare St&ouml;rungen rechtzeitig
                informieren.</span></li>
        <li class="c6 li-bullet-0"><span class="c1">Ecomply.io beschr&auml;nkt seine Leistungen zeitweilig, wenn dies im
                Hinblick auf Kapazit&auml;tsgrenzen, die Sicherheit oder Integrit&auml;t der Server oder zur
                Durchf&uuml;hrung technischer Ma&szlig;nahmen erforderlich ist, und dies der ordnungsgem&auml;&szlig;en
                oder verbesserten Erbringung der Leistungen dient (Wartungsarbeiten). Ecomply.io ber&uuml;cksichtigt in
                diesen F&auml;llen die berechtigten Interessen der Kunden, wie z.B. durch Vorabinformationen.</span>
        </li>
        <li class="c6 li-bullet-0"><span class="c1">Ecomply.io ist berechtigt, die im Rahmen der Nutzung anfallenden
                Daten f&uuml;r Zwecke der Auswertung, Kontrolle, Verbesserung des Angebots, Analyse, Marketing und
                Werbung zu verwenden und zu speichern. Die Bestimmungen der Datenschutzerkl&auml;rung und der
                Cookie-Richtlinien finden entsprechend Anwendung. </span></li>
        <li class="c6 li-bullet-0"><span class="c1">Ecomply.io kann Ma&szlig;nahmen ergreifen, wenn konkrete
                Anhaltspunkte daf&uuml;r bestehen, dass ein Kunde gesetzliche Vorschriften, Rechte Dritter, die
                Ecomply.io-AGB verletzt oder wenn Ecomply.io ein sonstiges berechtigtes Interesse hat, insbesondere zum
                Schutz der Nutzer vor betr&uuml;gerischen Aktivit&auml;ten.</span></li>
        <li class="c6 li-bullet-0"><span class="c1">Ecomply.io ist es gestattet, den Kunden ab Zeitpunkt des
                Vertragsschlusses als Referenz zu nennen. Dies beinhaltet insbesondere die Verwendung von Namen und Logo
                des Kunden und Nennung in medialer Berichterstattung.</span></li>
    </ol>
    <hr>
    <p class="c9"><span class="c1"></span></p>
    <h3 class="c5" id="h.mkxne3bawbqw"><span class="c3">3. Rechte und Pflichten des Kunden</span></h3>
    <ol class="c7 lst-kix_qpvilqatnhpj-0 start" start="1">
        <li class="c6 li-bullet-0"><span class="c2 c4">Zulassung</span></li>
    </ol>
    <ol class="c7 lst-kix_qpvilqatnhpj-1 start" start="1">
        <li class="c10 li-bullet-0"><span class="c1">Der Kunde hat alle im Bestellprozess zu erhebenden personen- oder
                unternehmensbezogenen Daten vollst&auml;ndig und wahrheitsgem&auml;&szlig; anzugeben. Er ist f&uuml;r
                die Richtigkeit seiner Daten selbst verantwortlich und garantiert gegen&uuml;ber Ecomply.io deren
                Richtigkeit und Aktualit&auml;t.</span></li>
        <li class="c10 li-bullet-0"><span class="c1">Der Kunde hat sicherzustellen, dass Dritte nicht &uuml;ber seine
                Zugangsdaten oder seine Lizenzschl&uuml;ssel verf&uuml;gen k&ouml;nnen. F&uuml;r eventuellen Missbrauch
                ist der Kunde ausschlie&szlig;lich selbst verantwortlich.</span></li>
    </ol>
    <ol class="c7 lst-kix_qpvilqatnhpj-0" start="2">
        <li class="c6 li-bullet-0"><span class="c4 c2">Lizenz und Nutzung</span></li>
    </ol>
    <ol class="c7 lst-kix_qpvilqatnhpj-1 start" start="1">
        <li class="c10 li-bullet-0"><span class="c1">Der Kunde erh&auml;lt von Ecomply.io nach Vertragsabschluss die im
                Bestellprozess angegebene Anzahl an Lizenzschl&uuml;sseln. Damit r&auml;umt Ecomply.io dem Kunden das
                Recht zur Nutzung der bestellten Software f&uuml;r den angegebenen Nutzungszeitraum ein. </span></li>
        <li class="c10 li-bullet-0"><span class="c1">Der Kunde gestattet seinen Nutzern nicht, die Software und
                Dokumentation Dritten zug&auml;nglich zu machen.</span></li>
        <li class="c10 li-bullet-0"><span class="c1">Der Kunde darf Dokumente, die von der Software erzeugt wurden
                wiederum seinen Kunden und Datenschutz-Beh&ouml;rden zur Verf&uuml;gung stellen.</span></li>
        <li class="c10 li-bullet-0"><span class="c1">Der Kunde garantiert gegen&uuml;ber Ecomply.io, gegen keine
                gesetzlichen Vorschriften zu versto&szlig;en und sich an die g&uuml;ltigen Datenschutzrichtlinien zu
                halten. Insbesondere verst&ouml;&szlig;t er nicht gegen Eigentums-, Schutz- und Nutzungsrechte
                Dritter.</span></li>
        <li class="c10 li-bullet-0"><span class="c1">Der Kunde stellt Ecomply.io auf erstes Anfordern von
                s&auml;mtlichen Anspr&uuml;chen frei, die andere Kunden, Nutzer oder sonstige Dritte gegen&uuml;ber
                Ecomply.io geltend machen wegen Verletzung ihrer Rechte durch von dem Kunden mittels Ecomply.io-Software
                erzielten Ergebnisse oder Daten. Der Kunde &uuml;bernimmt hierbei die Kosten der notwendigen
                Rechtsverteidigung von Ecomply.io einschlie&szlig;lich s&auml;mtlicher Gerichts- und Anwaltskosten in
                gesetzlicher H&ouml;he. Dies gilt nicht, wenn die Rechtsverletzung von dem Kunden nicht zu vertreten
                ist. Der Kunde ist verpflichtet, Ecomply.io f&uuml;r den Fall einer Inanspruchnahme durch Dritte
                unverz&uuml;glich, wahrheitsgem&auml;&szlig; und vollst&auml;ndig alle Informationen zur Verf&uuml;gung
                zu stellen, die f&uuml;r die Pr&uuml;fung der Anspr&uuml;che und eine Verteidigung erforderlich
                sind.</span></li>
        <li class="c20 li-bullet-0"><span class="c1">Der Kunde hat Anspruch auf bis zu zwei Stunden pers&ouml;nliches
                Onboarding nach Vertragsabschluss ohne Mehrkosten.</span></li>
        <li class="c11 li-bullet-0"><span class="c1">Chat support steht unbegrenzt allen Kunden zur
                Verf&uuml;gung.</span></li>
        <li class="c11 li-bullet-0"><span class="c1">Abh&auml;ngig von der gebuchten Lizenz hat der Kunde
                zus&auml;tzlich Anspruch auf bis zu zwei Stunden pers&ouml;nlichen Support alle sechs Kalendermonate
                nach Vertragsabschluss.</span></li>
        <li class="c11 li-bullet-0"><span class="c1">Virtuelle Meetings und Live Support Sitzungen, die &uuml;ber die
                oben genannten hinausgehen, werden nach Stundensatz abgerechnet.</span></li>
        <li class="c11 li-bullet-0"><span class="c1">Virtuelle Meetings und Live Support Sitzungen m&uuml;ssen mit einem
                Vorlauf von sieben Tagen gebucht werden. Bei Nichterscheinen ohne vorherige Absage wird der
                Rechnungsbetrag f&auml;llig.</span></li>
    </ol>
    <ol class="c7 lst-kix_qpvilqatnhpj-0" start="3">
        <li class="c6 li-bullet-0"><span class="c4 c2">Sicherheit</span></li>
    </ol>
    <ol class="c7 lst-kix_qpvilqatnhpj-1 start" start="1">
        <li class="c10 li-bullet-0"><span class="c1">Der Kunde verpflichtet sich, keine Spamnachrichten zu verschicken
                und keine automatischen elektronischen Programme oder Robots einzusetzen oder seinen Ecomply.io-Zugang
                hierf&uuml;r zur Verf&uuml;gung zu stellen.</span></li>
        <li class="c10 li-bullet-0"><span class="c1">Der Kunde verpflichtet sich, keine Viren, Schadsoftware oder
                sch&auml;dliche Links, wie auch Links zu rechtswidrigen Internetseiten und b&ouml;sartige Inhalte zu
                verbreiten oder einzustellen.</span></li>
    </ol>
    <hr>
    <p class="c9"><span class="c1"></span></p>
    <h3 class="c5" id="h.v444ono5wubs"><span class="c3">4. Verg&uuml;tung, F&auml;lligkeit, Verzug</span></h3>
    <ol class="c7 lst-kix_ieztagj8ibd1-0 start" start="1">
        <li class="c6 li-bullet-0"><span class="c1">Alle Preisangaben verstehen sich netto, zuz&uuml;glich der
                gesetzlichen Mehrwertsteuer.</span></li>
        <li class="c6 li-bullet-0"><span class="c1">Ecomply.io kann die Preise jederzeit &auml;ndern.
                Preis&auml;nderungen werden den Kunden rechtzeitig vor dem Inkrafttreten schriftlich mitgeteilt.</span>
        </li>
        <li class="c6 li-bullet-0"><span class="c1">Der Kunde hat nur dann ein Recht zur Aufrechnung, wenn seine
                Gegenanspr&uuml;che rechtskr&auml;ftig festgestellt oder diese durch Ecomply.io anerkannt wurden.</span>
        </li>
        <li class="c6 li-bullet-0"><span class="c1">Ein Zur&uuml;ckbehaltungsrecht steht dem Kunden nur insoweit zu, als
                dass sein Gegenanspruch aus dem gleichen vertraglichen Verh&auml;ltnis herr&uuml;hrt.</span></li>
    </ol>
    <hr>
    <p class="c9"><span class="c1"></span></p>
    <h3 class="c5" id="h.5xxmq34wyt1e"><span class="c3">5. Gew&auml;hrleistung, Haftung</span></h3>
    <ol class="c7 lst-kix_5rugzp9z4g8h-0 start" start="1">
        <li class="c0 li-bullet-0"><span class="c1">Ecomply.io haftet unbeschr&auml;nkt bei Vorsatz oder grober
                Fahrl&auml;ssigkeit, f&uuml;r die Verletzung von Leben, K&ouml;rper oder Gesundheit, nach den
                Vorschriften des Produkthaftungsgesetzes sowie, im Umfang einer von Ecomply.io &uuml;bernommenen
                Garantie, datenschutzrechtliche Anspruchsgrundlagen werden von dieser Haftungsregelung nicht erfasst.
                &nbsp;</span></li>
        <li class="c0 li-bullet-0"><span class="c2">Unbeschadet der Regelung in Ziffer 5.1 haftet Ecomply.io bei
                einfacher Fahrl&auml;ssigkeit nur bei Verletzung von vertragswesentlichen Pflichten, also der Verletzung
                von Pflichten, deren Erf&uuml;llung die ordnungsgem&auml;&szlig;e Durchf&uuml;hrung des
                Nutzungsvertrages &uuml;berhaupt erst erm&ouml;glichen und auf deren Einhaltung der Kunde
                regelm&auml;&szlig;ig vertrauen darf (&bdquo;Kardinalpflicht&ldquo;). Bei einer fahrl&auml;ssigen
                Verletzung einer Kardinalpflicht ist die Haftung von Ecomply.io zudem auf den vertragstypischen
                vorhersehbaren Schaden begrenzt.</span></li>
        <li class="c6 li-bullet-0"><span class="c1">Der vertragstypische vorhersehbare Schaden gem&auml;&szlig; Ziffer
                5.2 Satz 2 betr&auml;gt pro Vertragsjahr maximal 100% des j&auml;hrlichen Vertragsvolumens (vom Kunden
                innerhalb des Vertragsjahrs, in der das Schadensereignis f&auml;llt, an Ecomply.io zu zahlende
                Verg&uuml;tung f&uuml;r alle Leistungen von Ecomply.io hinsichtlich der Nutzung des Dienstes durch den
                Kunden) oder EUR 10.000, wobei der jeweils h&ouml;here Betrag gilt.</span></li>
        <li class="c6 li-bullet-0"><span class="c1">Die Haftungsbegrenzung in Ziffer 5.2 gilt auch f&uuml;r entgangenen
                Gewinn und ausgebliebene Einsparungen.</span></li>
        <li class="c6 li-bullet-0"><span class="c1">Unbeschadet der Regelung in Ziffer 5.1 ist die
                verschuldensunabh&auml;ngige Haftung von Ecomply.io nach &sect; 536a BGB wegen M&auml;ngeln, die bereits
                zum Zeitpunkt des Vertragsabschlusses vorhanden waren, ausdr&uuml;cklich ausgeschlossen.</span></li>
        <li class="c6 li-bullet-0"><span class="c1">Die vorstehenden Haftungsbeschr&auml;nkungen gelten auch im Falle
                des Verschuldens eines Erf&uuml;llungsgehilfen von Ecomply.io sowie f&uuml;r die pers&ouml;nliche
                Haftung der Mitarbeiter, Vertreter und Organe von Ecomply.io.</span></li>
        <li class="c6 li-bullet-0"><span class="c1">Die Verj&auml;hrung von Schadensersatzanspr&uuml;chen des Kunden
                aufgrund der Haftung nach Ziffer 5.1 bemisst sich nach den gesetzlichen Vorschriften. Die
                Verj&auml;hrungsfrist hinsichtlich sonstiger Schadensersatzanspr&uuml;che des Kunden betr&auml;gt ein
                Jahr. Sie beginnt mit dem Schluss des Jahres, in dem die Anspr&uuml;che entstanden sind und der Kunde
                von den anspruchsbegr&uuml;ndenden Umst&auml;nden und der Person des Schuldners Kenntnis erlangt oder
                aufgrund grober Fahrl&auml;ssigkeit nicht erlangt hat, sp&auml;testens jedoch in f&uuml;nf Jahren von
                ihrer Entstehung an und zehn Jahre von der Begehung der Handlung, der Pflichtverletzung oder dem
                sonstigen, den Schaden ausl&ouml;senden Ereignis an.</span></li>
        <li class="c6 li-bullet-0"><span class="c1">Ecomply.io ist bestrebt, eine m&ouml;glichst hohe Verf&uuml;gbarkeit
                der Software zu erm&ouml;glichen, haftet nicht f&uuml;r die Verf&uuml;gbarkeit und die fehlerfreie
                Adress- und Kontaktdatengenerierung sowie ausdr&uuml;cklich nicht f&uuml;r die Rechtm&auml;&szlig;igkeit
                und Verf&uuml;gbarkeit generierter Daten.</span></li>
        <li class="c6 li-bullet-0"><span class="c1">Der Kunde haftet f&uuml;r alle Folgen und Nachteile, die Ecomply.io
                durch die missbr&auml;uchliche oder rechtswidrige Verwendung der Dienste oder dadurch entstehen, dass
                der Kunde seinen sonstigen Obliegenheiten nach diesen AGB nicht nachkommt.</span></li>
    </ol>
    <hr>
    <p class="c9"><span class="c1"></span></p>
    <h3 class="c5" id="h.mvph2xqtpmmn"><span class="c3">6. Vertragslaufzeit, K&uuml;ndigung</span></h3>
    <ol class="c7 lst-kix_fbic073lo147-0 start" start="1">
        <li class="c6 li-bullet-0"><span class="c1">Die Vertragslaufzeit des Nutzungsvertrages betr&auml;gt den in der
                Bestellung angegebenen Zeitraum.</span></li>
        <li class="c6 li-bullet-0"><span class="c1">Wird der Vertrag nicht mindestens drei Monate vor Ende der
                Vertragslaufzeit durch den Kunden gek&uuml;ndigt, verl&auml;ngert er sich automatisch um den gleichen
                Zeitraum zu den gleichen Bedingungen.</span></li>
        <li class="c6 li-bullet-0"><span class="c1">Ecomply.io kann den Nutzungsvertrag jederzeit mit einer Frist von
                drei Monaten zum Monatsende k&uuml;ndigen.</span></li>
        <li class="c6 li-bullet-0"><span class="c1">Eine K&uuml;ndigung bedarf der Textform.</span></li>
    </ol>
    <hr>
    <p class="c9"><span class="c1"></span></p>
    <h3 class="c5" id="h.she0z7i49gtu"><span class="c3">7. Datenschutz</span></h3>
    <ol class="c7 lst-kix_6l90blqj72rg-0 start" start="1">
        <li class="c6 li-bullet-0"><span class="c1">Ecomply.io erhebt, speichert und verarbeitet die im Rahmen des
                Vertragsverh&auml;ltnisses erlangten unternehmens- sowie personenbezogenen Daten (Bestandsdaten) und
                sonstige Daten, die im Rahmen der Nutzung durch Ecomply.io entstehen, zum Zwecke der Vertragsabwicklung,
                der Abrechnung, sowie der Durchf&uuml;hrung der von dem Kunden beanspruchten Dienste entsprechend den
                gesetzlichen datenschutzrechtlichen Bestimmungen.</span></li>
        <li class="c6 li-bullet-0"><span class="c1">Der Kunde stimmt mit Vertragsschluss explizit zu, dass Ecomply.io
                Kundendaten, das Nutzungsverhalten und deren Analyse zur Verbesserung des Angebots und
                Gew&auml;hrleistung des Gesch&auml;ftszwecks verwendet. Diese Daten k&ouml;nnen im Rahmen der geltenden
                deutschen und europ&auml;ischen Datenschutzrichtlinien anonymisiert an Dritte weitergegeben werden und
                anonymisiert zur Kommunikation nach au&szlig;en verwendet werden.</span></li>
    </ol>
    <hr>
    <p class="c9"><span class="c1"></span></p>
    <h3 class="c5" id="h.hiab8yj9cqte"><span class="c19">8. Schlussbestimmungen</span></h3>
    <ol class="c7 lst-kix_18aazqgvyehw-0 start" start="1">
        <li class="c6 li-bullet-0"><span class="c1">Es gilt das Recht der Bundesrepublik Deutschland mit Ausnahme des
                UN-Kaufrechts (CISG).</span></li>
        <li class="c6 li-bullet-0"><span class="c1">Erf&uuml;llungsort ist der Sitz der Ecomply.io GmbH.
                Ausschlie&szlig;licher Gerichtsstand f&uuml;r alle Streitigkeiten aus dem Vertrag ist der Sitz der
                Ecomply.io GmbH, wenn der Kunde Kaufmann im Sinne des HGB ist oder bei Klageerhebung keinen Sitz oder
                gew&ouml;hnlichen Aufenthaltsort in der Bundesrepublik Deutschland hat.</span></li>
    </ol>
    <p class="c15"><span class="c1"></span></p>
</body>

</html>
import { FileTreeNode } from '../components/documents/upload/select-path/select-path-dialog/select-path-dialog.component';

function buildFileTreeWithNode(nodes: FileTreeNode[], path: string[], depth: number) {
  // if path already exists, do nothing
  if (nodes.find(p => p.fullPath === path.join('/'))) {
    return;
  }
  // if full path is not yet in the tree, add it
  if (depth === path.length - 1) {
    nodes.push({
      label: path[depth],
      fullPath: path.join('/'),
      children: []
    });
  } else {
    // check if a part of the path already exists in the tree
    const parent = nodes.find(p => p.fullPath === path.slice(0, depth + 1).join('/'));
    if (parent) {
      // if it does, add the rest of the path to the children of the parent
      buildFileTreeWithNode(parent.children, path, depth + 1);
    } else {
      // if it doesn't, add the parent to the tree and add the rest of the path to the children of the parent
      nodes.push({
        label: path[depth],
        fullPath: path.slice(0, depth + 1).join('/'),
        children: []
      });
      buildFileTreeWithNode(nodes[nodes.length - 1].children, path, depth + 1);
    }
  }
}

export function buildFileTreeFromPaths(paths: string[][]): FileTreeNode[] {
  const nodes: FileTreeNode[] = [];
  for (const path of paths) {
    buildFileTreeWithNode(nodes, path, 0);
  }
  return nodes;
}

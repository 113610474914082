<h1 mat-dialog-title>
  {{'pa.fullDpia.risks.edit' | translate}}
</h1>

<mat-dialog-content>
  <form [formGroup]="formGroup">
    <div class="description">
      <mat-form-field>
        <input matInput placeholder="{{ 'pa.fullDpia.risks.description.placeholder' | translate }}"
          formControlName="description" />
        <mat-hint>{{'pa.fullDpia.risks.description.tip' | translate}}</mat-hint>
      </mat-form-field>
    </div>
    <div class="settings">
      <mat-form-field>
        <mat-select placeholder="{{'pa.fullDpia.risks.likelihood.placeholder' | translate}}"
          formControlName="likelihood">
          <mat-option value="remote">{{'pa.fullDpia.risks.likelihood.remote' | translate}}</mat-option>
          <mat-option value="possible">{{'pa.fullDpia.risks.likelihood.possible' | translate}}</mat-option>
          <mat-option value="probable">{{'pa.fullDpia.risks.likelihood.probable' | translate}}</mat-option>
        </mat-select>
        <mat-hint>{{'pa.fullDpia.risks.likelihood.tip' | translate}}</mat-hint>
      </mat-form-field>
      <mat-form-field>
        <mat-select placeholder="{{'pa.fullDpia.risks.severity.placeholder' | translate}}"
          formControlName="severity">
          <mat-option value="minimal">{{'pa.fullDpia.risks.severity.minimal' | translate}}</mat-option>
          <mat-option value="significant">{{'pa.fullDpia.risks.severity.significant' | translate}}</mat-option>
          <mat-option value="severe">{{'pa.fullDpia.risks.severity.severe' | translate}}</mat-option>
        </mat-select>
        <mat-hint>{{'pa.fullDpia.risks.severity.tip' | translate}}</mat-hint>
      </mat-form-field>
      <mat-form-field>
        <mat-select placeholder="{{'pa.fullDpia.risks.risk.placeholder' | translate}}"
          formControlName="risk">
          <mat-option value="low">{{'pa.fullDpia.risks.risk.low' | translate}}</mat-option>
          <mat-option value="medium">{{'pa.fullDpia.risks.risk.medium' | translate}}</mat-option>
          <mat-option value="high">{{'pa.fullDpia.risks.risk.high' | translate}}</mat-option>
        </mat-select>
        <mat-hint>{{'pa.fullDpia.risks.risk.tip' | translate}}</mat-hint>
      </mat-form-field>
    </div>
  </form>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close tabindex="-1">{{'word.cancel' | translate}}</button>
  <button mat-flat-button color="primary" (click)="done()">{{'word.done' | translate}}</button>
</mat-dialog-actions>

import { Injectable } from '@angular/core';

import { IPostOptions, RequestService } from '../request.service';
import { BusService } from '../bus.service';
import { EventsService } from '../events.service';

interface CouponRequest {
  email: string;
  promotionIdentifier: string;
}

export class CouponPromotion {
  id: number;
  promotionIdentifier: string;
  price: number;
  currency: string;
  volume: number;
  naturalName: string;
  couponValue: string;
  available: boolean;

  constructor({ id, promotionIdentifier, price, currency, volume, naturalName, couponValue, available }) {
    this.id = id;
    this.promotionIdentifier = promotionIdentifier;
    this.price = price;
    this.currency = currency;
    this.volume = volume;
    this.naturalName = naturalName;
    this.couponValue = couponValue;
    this.available = available;
  }

  get currencySign() {
    switch (this.currency) {
      case 'usd': return '$';
      case 'eur': return '€';
      case 'brl': return 'R$';
    }
  }
}

@Injectable()
export class PromotionCouponsService {
  api = {
    root: '/payment/promotions',
    coupon: '/coupon',
    paymentIntent: '/coupon/confirm-payment'
  }

  constructor(
    private bus: BusService,
    private events: EventsService,
    private requestService: RequestService
  ) {
    this.subscribe();
  }

  private subscribe(): void {
    this.bus.subscribe(this.events.requested.payment.promotion.coupon, this.request.bind(this));
    this.bus.subscribe(this.events.requested.payment.promotion.info, this.getPromotion.bind(this));
    this.bus.subscribe(this.events.requested.payment.promotion.paymentIntent, this.getPaymentIntent.bind(this));
  }

  public request(data: CouponRequest) {
    return this.requestService.post<CouponRequest, any>({
      uri: this.api.root + this.api.coupon,
      body: data,
      handlers: {
        success: () => this.bus.publish(this.events.received.payment.promotion.coupon.success),
        error: error => this.bus.publish(this.events.received.payment.promotion.coupon.failure, error),
      }
    });
  }

  public getPromotion(identifier: string) {
    return this.requestService.get<CouponPromotion>({
      uri: this.api.root + `/${identifier}`,
      handlers: {
        success: (item) => this.bus.publish(this.events.received.payment.promotion.info.success, new CouponPromotion(item)),
        error: error => this.bus.publish(this.events.received.payment.promotion.info.failure, error),
      }
    });
  }

  public getPaymentIntent(token: string) {
    return this.requestService.get<any>({
      uri: this.api.root + this.api.paymentIntent + `/${token}`,
      handlers: {
        success: (item) => this.bus.publish(this.events.received.payment.promotion.paymentIntent.success, item),
        error: error => this.bus.publish(this.events.received.payment.promotion.paymentIntent.failure, error),
      }
    });
  }
}

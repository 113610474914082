<div class="home">
  <h1 class="title">{{ 'governance.departmentsList' | translate}}</h1>
  <br/><br/>
  <p>
    {{ 'governance.message' | translate }}
  </p>
  <p *ngIf="accessLevel && !accessLevel.write" class="warning">
    <span>{{'word.NOTICE' | translate}}</span>
    {{'word.readonlyAccess' | translate}}
  </p>
  <br/><br/>
  <div class="departments">
    <mat-card *ngFor="let department of departments">
      <div commentable
        comment-subject-codename="dep-{{department.id}}-name"
        comment-subject-title="{{'governance.departmentName.commentSubject.part1' | translate}} {{department.name}} {{'governance.departmentName.commentSubject.part2' | translate}}">
      <mat-card-title>{{department.name}}</mat-card-title>
      </div>

      <mat-card-content class="head">
        <div class="title">{{ 'governance.departmentHead' | translate }}</div>

        <div
          commentable
          comment-subject-codename="dep-{{department.id}}-headname"
          comment-subject-title="{{'governance.departmentHeadName.commentSubject.part1' | translate}} {{department.name}} {{'governance.departmentHeadName.commentSubject.part2' | translate}}"
          class="name info" *ngIf="department.headFirstName||department.headLastName">
          {{department.headFirstName}} {{department.headLastName}}
        </div>
        <div class="empty info"
          (click)="edit(department)"
          commentable
          comment-subject-codename="dep-{{department.id}}-headname"
          comment-subject-title="{{'governance.departmentHeadName.commentSubject.part1' | translate}} {{department.name}} {{'governance.departmentHeadName.commentSubject.part2' | translate}}"
          *ngIf="!(department.headFirstName||department.headLastName)">
          <mat-icon color="warn">warning</mat-icon>
          <span>
            {{ 'governance.error.headNameMissing' | translate }}
          </span>
        </div>

        <div class="email info" *ngIf="department.headEmail"
          commentable
          comment-subject-codename="dep-{{department.id}}-heademail"
          comment-subject-title="{{'governance.departmentHeadEmail.commentSubject.part1' | translate}} {{department.name}} {{'governance.departmentHeadEmail.commentSubject.part2' | translate}}">
          {{department.headEmail}}
        </div>
        <div class="empty info" *ngIf="!department.headEmail" (click)="edit(department)"
          commentable
          comment-subject-codename="dep-{{department.id}}-heademail"
          comment-subject-title="{{'governance.departmentHeadEmail.commentSubject.part1' | translate}} {{department.name}} {{'governance.departmentHeadEmail.commentSubject.part2' | translate}}">
          <mat-icon color="warn">warning</mat-icon>
          <span>
            {{ 'governance.error.headEmailMissing' | translate }}
          </span>
        </div>
      </mat-card-content>

      <div *ngIf="!department.dpCoordinatorLastName"><!-- Backward compatibility, EC-3365-->
        <mat-card-content class="dpc">
          <div class="title">{{ 'governance.departmentDpCoordinator' | translate }}</div>

          <div class="name info" *ngIf="department.dpCoordinatorFirstName||department.dpCoordinatorLastName"
            commentable
            comment-subject-codename="dep-{{department.id}}-dpcname"
            comment-subject-title="{{'governance.departmentDPCName.commentSubject.part1' | translate}} {{department.name}} {{'governance.departmentDPCName.commentSubject.part2' | translate}}">
            {{department.dpCoordinatorFirstName}} {{department.dpCoordinatorLastName}}
          </div>
          <div class="empty info"
            (click)="edit(department)"
            *ngIf="!(department.dpCoordinatorFirstName||department.dpCoordinatorLastName)"
            commentable
            comment-subject-codename="dep-{{department.id}}-dpcname"
            comment-subject-title="{{'governance.departmentDPCName.commentSubject.part1' | translate}} {{department.name}} {{'governance.departmentDPCName.commentSubject.part2' | translate}}">
            <mat-icon color="warn">warning</mat-icon>
            <span>
              {{ 'governance.error.dpCoordinatorNameMissing' | translate }}
            </span>
          </div>

          <div class="email info" *ngIf="department.dpCoordinatorEmail"
            commentable
            comment-subject-codename="dep-{{department.id}}-dpcemail"
            comment-subject-title="{{'governance.departmentDPCEmail.commentSubject.part1' | translate}} {{department.name}} {{'governance.departmentDPCEmail.commentSubject.part2' | translate}}">
            {{department.dpCoordinatorEmail}}
          </div>
          <div class="empty info"
            (click)="edit(department)"
            *ngIf="!department.dpCoordinatorEmail"
            commentable
            comment-subject-codename="dep-{{department.id}}-dpcemail"
            comment-subject-title="{{'governance.departmentDPCEmail.commentSubject.part1' | translate}} {{department.name}} {{'governance.departmentDPCEmail.commentSubject.part2' | translate}}">
            <mat-icon color="warn">warning</mat-icon>
            <span>
              {{ 'governance.error.dpCoordinatorEmailMissing' | translate }}
            </span>
          </div>
        </mat-card-content>
      </div>

      <mat-card-actions align="end">
        <button mat-button color="primary" (click)="edit(department)" *ngIf="canWrite">
          {{ 'word.edit' | translate }}
        </button>
      </mat-card-actions>
    </mat-card>
    <div class="new-department" (click)="add()" *ngIf="canWrite">
      <div>
        <div class="plus">+</div>
        <div class="hint">
          {{ 'governance.actions.addDepartment' | translate }}
        </div>
      </div>
    </div>
  </div>
  <br/><br/>
  <button
    [disabled]="departments.length <= 0"
    mat-raised-button
    color="primary"
    (click)="done()">{{'governance.actions.done' | translate}}</button>
  <br/><br/>
</div>

import { AfterViewInit, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { curveMonotoneX } from 'd3-shape';

@Component({
  selector: 'dashboard-actions-timeseries-chart',
  templateUrl: './actions-timeseries.component.html',
  styleUrls: ['./actions-timeseries.component.scss']
})
export class ActionsTimeseriesChartComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() events = [];
  @Input() height = 200;

  eventsTimeSeries = [{
    name: 'Events',
    series: []
  }];

  curveEvents = curveMonotoneX;

  constructor(
    private translate: TranslateService
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.events) {
      this.initialize();
    }
  }

  ngAfterViewInit(): void {
  }

  ngOnInit(): void {
  }

  initialize() {
    const start = new Date();
    const end = new Date();
    start.setMonth(start.getMonth() - 6);

    this.events.forEach(event => {
      event.timestamp_at = new Date(event.timestamp_at);
    });

    for (let cursor = new Date(start); cursor <= end; cursor.setDate(cursor.getDate() + 7)) {
      const aWeekAfter = new Date(cursor);
      aWeekAfter.setDate(aWeekAfter.getDate() + 7);

      this.eventsTimeSeries[0].series.push({
        name: `${cursor.getDate()}/${cursor.getMonth() + 1}`,
        value: this.events.filter(event =>
          event.timestamp_at >= cursor && event.timestamp_at < aWeekAfter
        ).length
      });
    }

    this.translate.get('supervision.clientDetails.charts.events.axis').subscribe(n => {
      this.eventsTimeSeries[0].name = n;
    });
  }

}

import { Component, Injector, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { TrafficLightColor, TrafficLightMapping } from 'app/components/traffic-light-selector/traffic-light-selector.component';
import {
  Audit,
  AuditConfiguration,
  Question,
} from 'app/services/audits/audits.service';
import { AuditFieldState, fetchAuditLikeService } from '../../util';

@Component({
  selector: 'app-audit-form-question',
  templateUrl: './question.component.html',
  styleUrls: ['./question.component.scss'],
})
export class AuditFormQuestionComponent implements OnInit, OnDestroy, OnChanges {
  @Input() audit: Audit = null;
  @Input() question: Question = null;
  @Input() readonly = false;

  @Input() auditConfiguration: AuditConfiguration = null;

  @Input() path = '';
  @Input() nestLevel = 0;

  severity: AuditFieldState<number>;
  severityMapping: TrafficLightMapping = {};

  constructor(
    private injector: Injector
  ) {}

  ngOnChanges(changes) {
    if (changes.auditConfiguration) {
      this.severityMapping = {};
      this.auditConfiguration.severityTypes?.forEach(severity => {
        this.severityMapping[severity.level] = {
          label: 'audit.form.question.severity.name.' + severity.name,
          color: this.severityColor(severity.level),
          order: severity.order,
        };
      });
    }
  }

  get computedPath() {
    return `${this.path}_${this.question.id}`;
  }

  get shouldShowInputFields() {
    return this.question.type !== 'input';
  }

  get shouldShowSeveritySelection() {
    return (
      this.auditConfiguration.severityTypes &&
      this.auditConfiguration.severityTypes.length > 0
    );
  }

  get hasDocumentExtension() {
    return this.question.extensions && this.question.extensions.includes('document-upload');
  }

  severityColor(severity: number): TrafficLightColor {
    if (severity === 0) {
      return 'needs-clarification';
    } else if (severity === 1) {
      return 'safe';
    } else if (severity === 2) {
      return 'warning';
    } else if (severity === 3) {
      return 'danger';
    }
  }

  ngOnInit() {
    if (this.shouldShowSeveritySelection) {
      const path = `${this.computedPath}_severity`;
      let value: string | number = this.audit.answers[path];
      if (value) {
        value = parseInt(value, 10);
      }

      const service = fetchAuditLikeService(this.auditConfiguration, this.injector);

      this.severity = new AuditFieldState<number>(
        service,
        this.audit.id,
        'severity',
        path,
        value as number
      );
    }
  }

  ngOnDestroy() {
    this.severity?.clear();
  }
}

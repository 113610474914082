import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { RpaReferenceService, TreeNode } from 'app/services/rpa/rpa.reference.service';
import { PaReferenceTreeSelectDialogComponent } from '../pa-reference-tree-select-dialog/pa-reference-tree-select-dialog.component';


@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'pa-reference-tree-input',
  templateUrl: './pa-reference-tree-input.component.html',
  styleUrls: ['./pa-reference-tree-input.component.scss']
})
export class PaReferenceTreeInputComponent implements OnInit {

  @Input() allowRoot = false;
  @Input() placeholder: string;
  @Input() node: TreeNode;
  @Input() tree: TreeNode[];
  @Output() nodeChange = new EventEmitter<TreeNode>();

  constructor(
    private dialog: MatDialog,
  ) {
  }

  ngOnInit(): void {
  }

  pick() {
    this.dialog.open(PaReferenceTreeSelectDialogComponent, {
      width: '512px',
      data: {
        tree: this.tree,
        allowRoot: this.allowRoot,
      }
    }).afterClosed().subscribe((res: { node?: TreeNode }) => {
      if (res) {
        this.node = res.node;
        this.nodeChange.emit(this.node);
      }
    });
  }
}

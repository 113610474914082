<mat-dialog-content class="application-search-dialog-contents">
    <mat-form-field>
      <input placeholder="{{'pa.applications.searchDialog.search' | translate}}"
        matInput type="text" [(ngModel)]="search" autofocus/>
      <button [ngClass]="{'active': !search}" class="search"
        mat-button matSuffix mat-icon-button>
        <mat-icon>search</mat-icon>
      </button>
      <button [ngClass]="{'active': search}"
        (click)="search=''" class="clear"
        mat-button matSuffix mat-icon-button>
        <mat-icon>clear</mat-icon>
      </button>
      <mat-hint innerHTML="{{'pa.applications.searchDialog.searchHint' | translate}}">
      </mat-hint>
    </mat-form-field>

    <cdk-virtual-scroll-viewport class="application-list" itemSize="applications.length">
      <div *cdkVirtualFor="let application of applications"
        (click)="dialogRef.close(application)"
        class="application">
        <div class="avatar">
          <img *ngIf="application.logoUrl" [src]="application.logoUrl" onerror="this.onerror=null;this.src='/assets/default_vendor.svg';"/>
          <mat-icon *ngIf="!application.logoUrl" class="no-logo">location_city</mat-icon>
        </div>

        <div class="info">
          <div class="title">
            {{application.name}}
          </div>
        </div>
      </div>
    </cdk-virtual-scroll-viewport>
</mat-dialog-content>

<mat-dialog-actions *ngIf="data && data.allowCreate" align="center">
    <button mat-button color="primary" (click)="newApplication()">
      {{'pa.applications.searchDialog.new' | translate}}
    </button>
</mat-dialog-actions>

import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { GroupListService } from '../../../../services/supervision/group-list.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BusService } from '../../../../services/bus.service';

@Component({
  selector: 'app-delete-group-dialog',
  templateUrl: './delete-group-dialog.component.html',
  styleUrls: ['./delete-group-dialog.component.scss'],
})
export class DeleteGroupDialogComponent implements OnInit, OnDestroy {

  currentGroup = undefined;

  constructor(
    private groupListService: GroupListService,
    private dialogRef: MatDialogRef<DeleteGroupDialogComponent>,
    private bus: BusService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.currentGroup = data.group;
  }

  ngOnInit(): void {
    this.subscribe();
  }

  ngOnDestroy(): void {
    this.unsubscribe();
  }

  deleteGroup() {
    this.groupListService.deleteGroup(this.currentGroup.id);
  }

  deleted() {
    this.dialogRef.close(true);
  }

  deleteFailed(error: any) {
    console.error(error);
  }

  subscribe() {
    this.bus.subscribe('delete-group-event', this.deleted, this);
    this.bus.subscribe('delete-group-failed-event', this.deleteFailed, this);
  }

  unsubscribe() {
    this.bus.unsubscribe('delete-group-event', this.deleted);
    this.bus.unsubscribe('delete-group-failed-event', this.deleteFailed);
  }

}

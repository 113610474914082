import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { BusService } from 'app/services/bus.service';
import { EventsService } from 'app/services/events.service';
import { UserService } from 'app/services/user/user.service';

@Component({
  selector: 'app-update-email',
  templateUrl: './update-email.component.html',
  styleUrls: ['./update-email.component.scss'],
})
export class UpdateEmailDialogComponent implements OnInit, OnDestroy {
  updated = false;
  failed = false;

  newEmailAddress;
  newEmailAddressVerification;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<UpdateEmailDialogComponent>,
    private userService: UserService,
    private bus: BusService,
    private events: EventsService,
    private router: Router
  ) {
    this.newEmailAddress = new FormControl('', [
      Validators.required,
      Validators.email,
    ]);
    this.newEmailAddressVerification = new FormControl('', [
      Validators.required,
      Validators.email,
    ]);
  }

  ngOnInit() {
    this.bus.subscribe('change-email.success', this.onSuccess, this);
    this.bus.subscribe('change-email.error', this.onError, this);
  }

  ngOnDestroy(): void {
    this.bus.unsubscribe('change-email.success', this.onSuccess);
    this.bus.unsubscribe('change-email.error', this.onError);
  }

  onSuccess() {
    this.updated = true;
  }

  onError() {
    this.failed = true;
  }

  submit() {
    this.updated = false;
    this.failed = false;
    if (this.newEmailAddress.value && !this.newEmailAddress.errors &&
        this.newEmailAddressVerification.value && !this.newEmailAddressVerification.errors &&
        this.newEmailAddress.value === this.newEmailAddressVerification.value) {
      this.userService.updateEmailAddress(this.newEmailAddress.value);
    }
  }

  close() {
    this.dialogRef.close();
  }

  canSave(): boolean {
    return (
      this.newEmailAddress.value &&
      !this.newEmailAddress.invalid &&
      this.newEmailAddress.value !== this.data.email &&
      !this.updated &&
      this.newEmailAddress.value === this.newEmailAddressVerification.value
    );
  }

  reload() {
    this.dialogRef.close();
    location.reload();
  }
}

import { Component, OnInit } from '@angular/core';
import { DpoService } from 'app/services/dpo/dpo.service';
import { TomsService } from 'app/services/toms/toms.service';
import { UserManagementService } from 'app/services/user/user-management.service';
import { VendorsService } from 'app/services/vendors/vendors.service';
import { MetricsService } from '../../../services/metrics/metrics.service';
import {AccessLevelService, userRoles} from "../../../services/user/access-level.service";

@Component({
  selector: 'dashboard-metrics',
  templateUrl: './metrics.component.html',
  styleUrls: ['./metrics.component.scss']
})
export class DashboardMetricsComponent implements OnInit {

  metrics: any = null;
  vendors: any = [];
  users: any = [];

  constructor(
    private metricsService: MetricsService,
    private vendorsService: VendorsService,
    private usersService: UserManagementService,
    private accessLevel: AccessLevelService
  ) { }

  ngOnInit(): void {
    this.metricsService.getMetrics().subscribe(data => this.metrics = data);
    this.vendorsService.getControllerVendors().subscribe(data => this.vendors = data);
    this.usersService.users(false).subscribe((data) => this.users = data);
  }

  get canNavigateAway() {
    return this.accessLevel.checkRole(userRoles.inspector) >= 0;
  }

  get countOpenDsar() {
    return this.metrics._extra?.dsars || 0;
  }

  get countOpenIncidents() {
    return this.metrics._extra?.incidents || 0;
  }

  get countNormalPas() {
    return this.metrics._extra?.cpas.filter(d => !d.processingOnBehalf).length;
  }

  get countPasOnBehalf() {
    return this.metrics._extra?.cpas.filter(d => d.processingOnBehalf).length;
  }

  get countVendors() {
    return this.vendors.length;
  }

  get countApplicableToms() {
    return this.metrics._extra?.toms.filter(d => d.status === 'implemented').length;
  }

  get countUsers() {
    return this.users.length;
  }

}

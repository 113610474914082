import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AppConfig } from 'app/app.config';
import { DsarResourceService } from 'app/services/dsar/dsar-resource.service';
import { DsarSettings } from 'app/services/dsar/dsar-settings.service';
import { CrispService } from '../../../modules/crisp/crisp.service';
import { TranslateService } from '@ngx-translate/core';
import { FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-submit-dsar',
  templateUrl: './submit-dsar.component.html',
  styleUrls: ['./submit-dsar.component.scss']
})
export class SubmitDsarComponent implements OnInit {

  customFields = {
    subjectRelation: '',
    subjectName: '',
    lgpdCPF: '',
    lgpdRG: '',
    jur: '',
  };

  ctrl: number;
  token: string;
  name: string;
  logo: string;
  ctrlEmail: string;

  email = new FormControl('', Validators.email);
  requestType = 'dsar.request_type.unknown';
  comments: string;
  recaptcha: string;

  submitted = false;
  error = false;

  jurisdiction: string;

  constructor(
    private route: ActivatedRoute,
    private settings: DsarSettings,
    private dsar: DsarResourceService,
    private crisp: CrispService,
    private translate: TranslateService,
  ) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      if (params.token && params.ctrl) {
        this.token = params.token;
        this.ctrl = parseInt(params.ctrl, 10);

        this.settings.verifyToken(this.ctrl, this.token).subscribe(response => {
          this.name = response.name;
          this.logo = response.logo;
          this.ctrlEmail = response.email;
        }, () => this.error = true);
      }

      this.jurisdiction = params.jur;

      if (params.lang) {
        this.translate.use(params.lang);
      }
    });
    this.crisp.hide();
  }

  logoUrl() {
    return `${AppConfig.apiUrl}/controllers/logo/${this.ctrl}`;
  }

  get recpatchaKey() {
    return AppConfig.recaptchaKey;
  }

  get valid() {
    return this.email.valid && this.requestType && this.requestType !== 'dsar.request_type.unknown' && this.recaptcha;
  }

  /**
   * We send the custom fields object with the jur key:value
   *  to flag which jurisdiction the dsar is being submitted from.
   * As of now we only have extra fields for lgpd so that's the only one we care about.
   */
  submit() {
    /* eslint-disable */
    if (this.jurisdiction === 'lgpd') {
      this.customFields.jur = 'lgpd';
      // regex for CPF '/[0-9]{3}[.][0-9]{3}[.][0-9]{3}[-][0-9]{2}/g'
      // regex for RG '/[0-9]{2}[.][0-9]{3}[.][0-9]{3}[-][0-9]{1}/g'
    }

    this.dsar.submitDsar(
      this.token,
      this.ctrl,
      this.email.value,
      this.requestType,
      this.comments,
      this.recaptcha,
      (this.jurisdiction === 'lgpd') ? this.customFields : {},
    ).subscribe(() => {
      this.submitted = true;
    });
  }
}

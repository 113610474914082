<h1 mat-dialog-title>
  {{ 'audits.form.edit-title.title' | translate }}
</h1>

<mat-dialog-content>
  <mat-form-field>
    <input matInput placeholder="{{ 'audits.form.edit-title.placeholder' | translate }}" [formControl]="name" />
    <mat-hint>{{ 'audits.form.edit-title.hint' | translate }}</mat-hint>
  </mat-form-field>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>
    {{'word.close' | translate}}
  </button>

  <button mat-button color="primary" (click)="save()">
    {{'word.save' | translate}}
  </button>
</mat-dialog-actions>

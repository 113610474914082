<!-- {{ 'risk-analysis.classifications.safe' | translate}} -->
<!-- {{ 'risk-analysis.classifications.mostly-safe' | translate}} -->
<!-- {{ 'risk-analysis.classifications.warning' | translate}} -->
<!-- {{ 'risk-analysis.classifications.serious-warning' | translate}} -->
<!-- {{ 'risk-analysis.classifications.danger' | translate}} -->
<!-- {{ 'risk-analysis.classifications.catastrophe' | translate}} -->
<!-- {{ 'risk-analysis.classifications.unknown' | translate}} -->
<!-- {{ 'risk-analysis.classifications.needs-clarification' | translate}} -->
<div #paRiskContainerRef class="pa-risk-dists">
  <ngx-charts-bar-horizontal
    [results]="riskDist"
    [scheme]="riskDistColors"
    [xAxis]="true"
    [showGridLines]="true"
    [noBarWhenZero]="false"
    [xAxisTickFormatting]="integerLabel"
    [view]="[paRiskContainerRef.offsetWidth, height]"
  >
    <ng-template #tooltipTemplate let-model="model">
      {{model.label}}<br>
      {{roundValue(model.value)}}
    </ng-template>
  </ngx-charts-bar-horizontal>
</div>

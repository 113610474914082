import { Component, OnInit } from '@angular/core';
import {MatDialog} from "@angular/material/dialog";
import {Measure, MeasuresService} from "../../../services/measures.service";
import {CreateMeasureDialogComponent} from "../create-measure-dialog/create-measure-dialog.component";
import {Sort} from "@angular/material/sort";
import {DialogComponent} from "../../dialog/dialog.component";
import {TranslateService} from "@ngx-translate/core";
import {zip} from "rxjs";

@Component({
  selector: 'app-supervisor-measures',
  templateUrl: './supervisor-measures.component.html',
  styleUrls: ['./supervisor-measures.component.scss']
})
export class SupervisorMeasuresComponent implements OnInit {

  columnsToDisplay = ['title', 'showInDashboard', 'showInReport', 'actions'];

  measures: Measure[] = [];

  constructor(
    private dialog: MatDialog,
    private measureService: MeasuresService,
    private translate: TranslateService
  ) { }

  ngOnInit(): void {
    this.measureService.getSupervisorMeasures()
      .subscribe((measures) => {
        this.measures = measures;
      });
  }

  createMeasure() {
    this.dialog.open(CreateMeasureDialogComponent, { width: '600px' })
      .afterClosed()
      .subscribe((measure: Measure) => {
        if (measure) {
          this.measures.push(measure);
          this.measures = this.measures.slice();
        }
      });
  }

  showDetails(event, measure: Measure) {
    this.dialog.open(CreateMeasureDialogComponent, { width: '600px', data: { measure } })
      .afterClosed()
      .subscribe((updated: Measure) => {
        if (updated) {
          Object.assign(measure, updated);
        }
      });
  }

  deleteMeasure(event, measure: Measure) {
    if (event) {
      event.stopPropagation();
    }

    zip(
      this.translate.get('measures.delete.title'),
      this.translate.get('measures.delete.message'),
    ).subscribe(([title, message]) => {
      this.dialog.open(DialogComponent, { width: '400px', data: { header: title, message, confirm: true }})
        .afterClosed()
        .subscribe((confirmed) => {
          if (confirmed) {
            this.measureService.deleteMeasure(measure)
              .subscribe(() => {
                this.measures = this.measures.filter((m) => m.id !== measure.id);
              });
          }
        });
    });
  }

  sortData(sort: Sort) {
    const data = this.measures.slice();
    if (!sort.active || sort.direction === '') {
      this.measures = data;
      return;
    }

    this.measures = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'title': return compare(a.title, b.title, isAsc);
        case 'showInDashboard': return compare(a.showInDashboard, b.showInDashboard, isAsc);
        case 'showInReport': return compare(a.showInReport, b.showInReport, isAsc);
        default: return 0;
      }
    });
  }

}

function compare(a: number | string | boolean, b: number | string | boolean, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}

import { Component, OnInit, OnDestroy } from '@angular/core';
import { DocumentResourceService } from '../../../services/documents/document-resource.service';


@Component({
  selector: 'app-documents-misc-list',
  templateUrl: './documents-misc-list.component.html',
  styleUrls: ['./documents-misc-list.component.scss']
})
export class DocumentsMiscListComponent implements OnInit, OnDestroy {
  documents = [];

  constructor(
    private docService: DocumentResourceService
  ) {

  }

  public update() {
    this.docService.getAll()
      .subscribe((documents) => {
        this.documents = documents.filter((d) => d.type !== 'shared');
      });
  }

  public ngOnInit(): void {
    this.update();
  }

  public ngOnDestroy(): void {
  }

}

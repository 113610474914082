
<mat-dialog-content>
  {{'documents.taglist.title' | translate}} {{ document.fileName }}
  <br><br>
  <mat-chip-list *ngIf="document.tags.length > 0">
    <mat-chip color="accent" *ngFor="let tag of document.tags" selected>{{ tag.display }}</mat-chip>
  </mat-chip-list>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>
    {{'word.ok' | translate}}
  </button>
</mat-dialog-actions>

export function isJurisdiction(language: string, jurisdiction: string): boolean {
  if (jurisdiction === 'ndpr') {
    return language === 'en-NG';
  }

  if (jurisdiction === 'lgpd') {
    return language === 'pt-BR';
  }

  if (jurisdiction === 'gdpr') {
    return ['de', 'en', 'pt'].includes(language);
  }

  return false;
}

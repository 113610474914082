import { Inject, Injectable, OnDestroy } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { BusService } from 'app/services/bus.service';
import { EventsService } from 'app/services/events.service';
import { AuthenticationService } from 'app/services/authentication.service';
import { Crisp } from './wrapper';
import { CrispData } from './types/CrispData';
import { PaymentInfoGetLatestService } from '../../services/payment/info/info.latest.get.service';

@Injectable()
export class CrispService {
  private _companyName: string;
  private _controllerId: number;
  private _expiryDate: string;

  private _planName: string;

  constructor(
    private bus: BusService,
    private events: EventsService,
    private auth: AuthenticationService,
    private crisp: Crisp,
    private router: Router,
    private paymentService: PaymentInfoGetLatestService
  ) {
    this.init();
  }

  private init() {
    this.crisp.mount();

    this.loadData();
    this.subscribe();
    this.updatePlanFree();
  }

  private loadData() {
    if (this.auth.token) {
      this.bus.publish(this.events.requested.data.basicInfo.get);
      this.bus.publish(this.events.requested.payment.info.latest);
    }
  }

  private subscribe() {
    // subscribe to some of our standard events
    this.bus.subscribe(this.events.received.authentication.success, data => {
      this.update();
      this.loadData();
      this.crisp.sendSimpleEvent('login');
    });
    this.bus.subscribe(this.events.requested.data.emailAuthentication, data => {
      // this.update();
    });
    this.bus.subscribe(this.events.notified.user.logout, data => { this.logout(); })

    // subscribe to async data
    this.bus.subscribe(this.events.received.data.basicInfo.get.success, data => {
      this.updateCompanyData(data);
    });
    this.bus.subscribe(this.events.received.payment.info.latest.success, this.updateSubscriptionPlan, this);
    this.bus.subscribe(this.events.received.payment.info.latest.failure, this.updatePlanFree, this);

    // subscribe to router events
    this.router.events.forEach(event => {
      if (event instanceof NavigationEnd) {
        this.update();
      }
    });
  }

  private unsubscribe() {
    this.bus.unsubscribe(this.events.received.authentication.success, data => {
    });
    this.bus.unsubscribe(this.events.received.registration.success, data => {
    });
    this.bus.unsubscribe(this.events.notified.user.logout, () => {
    });
  }

  /**
   * Data that will be sent to Crisp.
   */
  get userData(): CrispData {
    if (this.auth.token) {
      const data: CrispData = {
        name: `${this.auth.firstName} ${this.auth.lastName}`,
        email: this.auth.email,
        emailHash: this.auth.crispHash
      };

      if (this._companyName && !localStorage.getItem('isInSupervisionMode') && this.auth.productType !== 'supervisor') {
        data.company = {
          id: this._controllerId.toString() || this.auth.controllerId?.toString(),
          name: this._companyName,
          plan_name: null,
          expiry_date: this._expiryDate
        };

        if (this._planName) {
          data.company.plan_name = this._planName;
        }
      }

      if (this.auth.productType === 'supervisor') {
        data.company = { name: null, id: null, expiry_date: null };
        data.company.plan_name = 'supervision';
      }

      if (!data.email || !data.emailHash) {
       return null;
      }

      return data;
    }
  }

  private updatePlanFree() {
    if (!this._planName || this._planName === '' || this.auth.productType === 'supervisor') {
      if (this.auth.productType === 'supervisor') {
        this._planName = 'supervision';
      } else {
        this._planName = 'not set / free';
      }
    }
  }

  private updateCompanyData(data) {
    this._companyName = data.controllerName;
    this._controllerId = data.controllerId;
    this._expiryDate = data.controllerExpiry;

    this.update();
  }

  private updateSubscriptionPlan(data) {
    if (data.subscriptionStatus === 'active') {
      this._planName = data.planName;
    }
  }

  public hide() {
    this.crisp.hide();
  }

  public forceFetchUserData() {
    this.loadData();
  }

  private update() {
    if (this.userData) {
      this.crisp.updateUser(this.userData.email, this.userData.emailHash, this.userData.name);

      if (this.userData.company) {
        this.crisp.updateCompany(this._companyName, this.userData.company.id);
        this.crisp.updateExpiry(this.userData.company?.expiry_date || this._expiryDate?.toString() || '');
      }

      this.crisp.updatePlan(this.userData.company?.plan_name || this._planName || '');
    }
  }

  private logout() {
    // this.crisp.resetSession();
    // let's keep it as-is since restoring crisp sessions is really wonky
  }
}

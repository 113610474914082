import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FullDpiaRisk } from '../../pa-details.component';

@Component({
  selector: 'app-full-dpia-edit-risk',
  templateUrl: './edit-risk.component.html',
  styleUrls: ['./edit-risk.component.scss']
})
export class FullDpiaEditRiskComponent implements OnInit {

  formGroup = new FormGroup({
    description: new FormControl(''),
    likelihood: new FormControl(''),
    severity: new FormControl(''),
    risk: new FormControl('')
  });

  constructor(
    public ref: MatDialogRef<FullDpiaEditRiskComponent>,
    @Inject(MAT_DIALOG_DATA) data: { risk: FullDpiaRisk }
  ) {
    this.formGroup.patchValue(data.risk);
  }

  done() {
    this.ref.close(this.formGroup.value);
  }

  ngOnInit(): void {
  }

}

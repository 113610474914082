import { Component, OnInit, ViewChild, ElementRef, OnDestroy,
        ContentChildren, QueryList, AfterViewInit, Input } from '@angular/core';

import { StepComponent } from './step/step.component';
import { ActivatedRoute } from '@angular/router';
import { take } from 'rxjs/operators';
import { Subscription } from 'rxjs';


@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.scss']
})
export class StepperComponent implements OnInit, OnDestroy, AfterViewInit {

  @Input() height = '85vh';
  @Input() navigation = true;
  @ViewChild('holder') holder: ElementRef;
  @ViewChild('outer') outer: ElementRef;
  @ContentChildren(StepComponent) steps: QueryList<StepComponent>;

  private _current: StepComponent;
  private _idMap: any = {};
  private _firstIndex = 0;
  private _lastInedx = 0;
  public _from_comment = false;
  private _fix_current_from_scroll_pos = true;
  private _fix_interval;
  private _sub: Subscription;

  constructor(
    private activatedRoute: ActivatedRoute,
  ) { }

  ngOnInit() {
    this._sub = this.activatedRoute.queryParams.subscribe(query => {
      if (query['comment-subject-codename']) {
        this._from_comment = true;
      } else {
        this._from_comment = false;
      }
    });

    this._fix_interval = setInterval(() => {
      if (this._fix_current_from_scroll_pos) {
        let max;
        this.steps.forEach(step => {
          if (step.holder.nativeElement.offsetLeft - this.outer.nativeElement.scrollLeft <
              this.outer.nativeElement.offsetWidth) {
            max = step;
          }
        });

        if (max !== this.current) {
          this.current = max;
        }

        this.outer.nativeElement.scrollLeft = this.current.holder.nativeElement.offsetLeft;
      }
    }, 700);
  }

  ngOnDestroy() {
    clearInterval(this._fix_interval);
    this._sub.unsubscribe();
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.steps.forEach((step, index) => {
        if (index < this._firstIndex) {
          this._firstIndex = index;
        }
        if (index > this._lastInedx) {
          this._lastInedx = index;
        }

        this._idMap[index] = step;
        step.index = index;
        if (step.id) {
          this._idMap[step.id] = step;
        }
      });

      if (!this._from_comment) {
        this.findStart();
      }
    }, 500);
  }

  findStart() {
    if (this.current) {
      return;
    }

    let candidate;
    this.steps.forEach(step => {
      if (step.initial && !candidate) {
        candidate = step;
      }
    });

    if (!candidate && this.steps.length > 0) {
      candidate = this.steps.first;
    }

    this.current = candidate;
  }

  public get current() { return this._current; }

  public set current(step) {
    if (this._current) {
      if (this._current.index < step.index) {
        this._current.pushBack();
      }

      if (this._current.index > step.index) {
        this._current.pushForward();
      }
    }

    this._current = step;
    this._current.activate();

    this.fixPositions();
  }

  public get onFirst(): boolean {
    return this.current && this.current.index === this._firstIndex;
  }

  public get onLast(): boolean {
    return this.current && this.current.index === this._lastInedx;
  }

  public switch(id) {
    if (id in this._idMap) {
      this.current = this._idMap[id];
      this.lockAutoFix();
    }
  }

  public next() {
    this.lockAutoFix();
    this.switch(this.current.index + 1);
  }

  public prev() {
    this.lockAutoFix();
    this.switch(this.current.index - 1);
  }

  public lockAutoFix() {
    this._fix_current_from_scroll_pos = false;
    setTimeout(() => this._fix_current_from_scroll_pos = true, 3000);
  }

  fixPositions() {
    const current = this.current;
    this.outer.nativeElement.scrollLeft = this.current.holder.nativeElement.offsetLeft;
    Object.values(this._idMap).forEach((step: any) => {
      if (step.index < current.index) {
        step.pushBack();
      }
      if (step.index > current.index) {
        step.pushForward();
      }
    });
  }
}

import { Inject, Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { ControllerType, SettingsType, Template } from '../types';
import { SelectTemplateDialogComponent } from '../select-template-dialog/select-template-dialog.component';
import { SupervisionTemplatesBackendService } from '../../../../services/supervision/templates-backend.service';


@Component({
  selector: 'app-create-template-dialog',
  templateUrl: './create-template-dialog.component.html',
  styleUrls: ['./create-template-dialog.component.scss']
})
export class CreateTemplateDialogComponent implements OnInit {

  public controller: ControllerType;

  original: Template;

  public templateName: string;
  public settings: SettingsType = {
      departments: true,
      dpo: true,
      rpas: true,
      toms: true,
      companyInfo: false,
      dsars: false,
      incidents: false,
      vendors: false,
  };

  constructor(
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<CreateTemplateDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data: { controller?: ControllerType },
    private backend: SupervisionTemplatesBackendService,
  ) {
    this.controller = data.controller;
  }

  ngOnInit() {
  }

  done() {
    this.dialogRef.close({
      original: this.original,
      templateName: this.templateName,
      settings: this.settings,
    });
  }

  selectTemplate() {
    this.dialog.open(SelectTemplateDialogComponent, {
      width: '350px',
      maxHeight: '90vh',
    }).afterClosed().subscribe((selected: Template) => {
      if (selected) {
        this.original = selected;
        this.templateName = selected.name;
        this.backend.get(selected.id).subscribe((template: Template) => {
          this.original = template;
          this.original.updatedAt = new Date(template.updatedAt as string);
          this.original.createdAt = new Date(template.createdAt as string);

          this.settings = {
            departments: template.data.departments && Object.keys(template.data.departments).length > 0,
            dpo: template.data.dpo && Object.keys(template.data.dpo).length > 0,
            rpas: template.data.rpas && Object.keys(template.data.rpas).length > 0,
            toms: template.data.toms && Object.keys(template.data.toms).length > 0,
            companyInfo: false,
            dsars: false,
            incidents: false,
            vendors: false,
          };
        });
      }
    });
  }

  deselectTemplate() {
    this.original = undefined;
    this.templateName = '';
    this.settings = {
      departments: true,
      dpo: true,
      rpas: true,
      toms: true,
      companyInfo: false,
      dsars: false,
      incidents: false,
      vendors: false,
    };
  }
}

<div class="contents">
  <ng-container *ngIf="vendor && controller">
    <h1 class="title">
      {{'vendors.offerDpa.title.pre' | translate}}
      <br>
      {{ vendor.name }}
      {{ 'word.and' | translate }}
      {{ controller.controllerName }}
    </h1>
    <br><br><br><br>
    <div class="circles">
      <div class="circle">
        <span>
        {{vendor.name}}
        </span>
      </div>
      <div class="circle">
        <span>
        {{controller.controllerName}}
        </span>
      </div>
    </div>
    <br><br>
    <p>
      {{'vendors.offerDpa.message1.part1' | translate}}
      <a>{{ controller.controllerName }}</a>
      {{'vendors.offerDpa.message1.part2' | translate}}
    </p>
    <br>
    <cool-upload
      upload-url="/vendors/offer-dpa/?dpa-token={{dpaToken}}"
      upload-key="doc">
      <span hint>
        {{'vendors.offerDpa.uploadHint' | translate}}
      </span>
    </cool-upload>
    <br><br>
    <p>
      {{'vendors.offerDpa.message2.part1' | translate}}
      <a>{{ controller.controllerName }}</a>
      {{'vendors.offerDpa.message2.part2' | translate}}
    </p>
    <br>
    <a mat-raised-button color="primary" target="_blank"
    href="{{docs}}/Specimen-Data-Processing-Agreement.docx">
      {{'vendors.offerDpa.templateButton' | translate}}
    </a>
    <br><br><br>
    <mat-divider></mat-divider>
    <br><br>
    <a href="https://app.ecomply.io" target="_blank">
      <img class="ecomply-logo" src="/assets/eclogo-check2.svg"/>
    </a>
    <br><br>
    <p>
      {{'vendors.offerDpa.message3.part1' | translate}}
      <a>{{vendor.name}}</a>
      {{'vendors.offerDpa.message3.part2' | translate}}
      <a>{{controller.controllerName}}</a>
      {{'vendors.offerDpa.message3.part3' | translate}}
      <a target="_blank" href="https://app.ecomply.io">ECOMPLY.io</a>
      {{'vendors.offerDpa.message3.part4' | translate}}
    </p>
    <br>
    <a mat-raised-button color="primary" target="_blank" routerLink="/registration">
      {{'vendors.offerDpa.registerButton' | translate}}
    </a>
    <br><br><br>
  </ng-container>
  <ng-container *ngIf="badToken">
    <mat-card>
      <img mat-card-image src="/assets/not-found.svg"/>
      <mat-card-title>
        {{'vendors.offerDpa.badToken.title' | translate}}
      </mat-card-title>
      <mat-card-content>
        <span innerHtml="{{'vendors.offerDpa.badToken.message' | translate}}"></span>
      </mat-card-content>
    </mat-card>
  </ng-container>
</div>

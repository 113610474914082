<h1 mat-dialog-title>
  {{'user-management.manage-invite.title' | translate}}
  {{invite.firstName}} {{invite.lastName}}
  <small>
    {{invite.email}}
  </small>
</h1>

<mat-dialog-content>
  {{'user-management.manage-invite.message' | translate}}
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button [disabled]="resending" (click)="resend()" tabindex="-1">
    {{'user-management.manage-invite.resend' | translate}}
  </button>
  <button mat-button color="warn" tabindex="-1" (click)="delete()">
    {{'user-management.manage-invite.delete' | translate}}
  </button>
</mat-dialog-actions>

<mat-dialog-content>
  <div class="info">
    <div class="avatar">
      <user-avatar [user]="user"></user-avatar>
    </div>
    <div class="texts">
      <div class="name">{{user.firstName}} {{user.lastName}}</div>
      <div class="email">{{user.email}}</div>
    </div>
  </div>

  <br>
  <mat-divider></mat-divider>
  <br>

  <div class="role">
    <mat-slide-toggle
      [(ngModel)]="isAdmin"
      color="primary"
      (change)="updateRole()"
      [disabled]="readonly">
      <b>
        {{'supervision.supervisorProfile.editUser.role.admin' | translate}}
      </b>
    </mat-slide-toggle>
    <br/>
    <p class="hint">
      {{'supervision.supervisorProfile.editUser.role.hint' | translate}}
    </p>
  </div>

  <div class="client-access">
    <mat-slide-toggle
      [checked]="user.supervisorClientAccessMode === 'all'"
      [disabled]="readonly || isAdmin"
      color="primary"
      (change)="setClientAccessMode($event)">
      <b>
        {{'supervision.clientAccess.accessMode.all' | translate}}
      </b>
    </mat-slide-toggle>
    <br/>
    <p class="hint">
      {{'supervision.clientAccess.accessMode.all.hint' | translate}}
    </p>
  </div>

  <!-- Two Factor Auth -->
  <ng-container *ngIf="twoFactorOrgRuleActive">
    <div class="two-factor-info">
      <h3>{{'user-management.edit.twoFactor.title' | translate}}</h3>
      <ng-container *ngIf="twoFactorInfo">
        <p class="enabled">{{'user-management.edit.twoFactor.enabled' | translate}}</p>
        <button mat-stroked-button color="warn" [disabled]="readonly || twoFactorPending" (click)="disableTwoFactor()">{{'user-management.edit.twoFactor.disable' | translate}}</button>
      </ng-container>
      <ng-container *ngIf="!twoFactorInfo && !twoFactorInfoError">
        <p class="not-enabled">{{'user-management.edit.twoFactor.notYetEnabled' | translate}}</p>
      </ng-container>
      <ng-container *ngIf="!twoFactorInfo && twoFactorInfoError">
        <p class="no-permissions">{{'user-management.edit.twoFactor.noPermission' | translate}}</p>
      </ng-container>
    </div>
  </ng-container>

</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button color="warn" (click)="remove()" *ngIf="!readonly">
    {{'supervision.supervisorProfile.editUser.actions.remove' | translate}}
  </button>
  <button mat-flat-button color="primary" mat-dialog-close>{{'word.done' | translate}}</button>
</mat-dialog-actions>

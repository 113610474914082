import {Injectable} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import {BusService} from '../bus.service';
import {
  IGetOptions,
  RequestService
} from '../request.service';
import {EventsService} from '../events.service';

export interface BasicInfo {
  controllerId: string;
  controllerName: string;
  controllerContactName: string;
  controllerContactRole: string;
  controllerDpCoordination: string;
  controllerAddressStreet: string;
  controllerAddressPostcode: string;
  controllerAddressCity: string;
  controllerAddressCountry: string;
  controllerTelephoneNumber: string;
  controllerEmailAddress: string;
  controllerUrl: string;

  dpoId: string;
  dpoName: string;
  dpoAddressStreet: string;
  dpoAddressPostcode: string;
  dpoAddressCity: string;
  dpoAddressCountry: string;
  dpoTelephoneNumber: string;
  dpoEmailAddress: string;
}


/*
  An interlayer service for basic-info get request between request service and basic-info component
*/
@Injectable()
export class BasicInfoGetService {

  private options: IGetOptions<BasicInfo>;

  constructor(
    private bus: BusService,
    private events: EventsService,
    private requestService: RequestService
  ) {
    this.options = {
      uri: '/basic-info',
      handlers: {
        success: this.success.bind(this),
        error: this.error.bind(this)
      }
    };
    this.subscribe();
  }

  public success(data: BasicInfo): void {
    if (data) {
      this.bus.publish(this.events.received.data.basicInfo.get.success, data);
    }
  }

  public error(httpErrorResponse: HttpErrorResponse): void {
    this.bus.publish(this.events.received.data.basicInfo.get.failure, httpErrorResponse);
  }

  private request(): void {
    this.bus.publish(this.events.notified.request.starting);
    this.options.uri = `/basic-info`;
    this.requestService.get<BasicInfo>(this.options);
  }

  public subscribe(): void {
    this.bus.subscribe(this.events.requested.data.basicInfo.get, this.request.bind(this));
  }
}

import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SupervisorProfileService } from 'app/services/supervision/supervisor-profile.service';
import { FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-supervisor-profile-invite-dialog',
  templateUrl: './supervisor-profile-invite-dialog.component.html',
  styleUrls: ['./supervisor-profile-invite-dialog.component.scss']
})
export class SupervisorProfileInviteDialogComponent implements OnInit {

  wrongEmails: string[] = [];
  email = new FormControl('', [
    Validators.required,
    Validators.email,
    control => {
      if (this.wrongEmails.includes(control.value)) {
        return { 'invite': true };
      }
    }
  ]);

  constructor(
    @Inject(MAT_DIALOG_DATA) private existingEmails: string[],
    private service: SupervisorProfileService,
    private ref: MatDialogRef<SupervisorProfileInviteDialogComponent>,
  ) {
    this.wrongEmails = existingEmails || [];
  }

  ngOnInit() {
  }

  invite() {
    this.service.invite(this.email.value).subscribe(() => {
      this.ref.close();
    }, () => {
      this.wrongEmails.push(this.email.value);
      this.email.setValue(this.email.value);
    });
  }
}

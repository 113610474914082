<div class="milestone">
  <h1 class="title">
    {{ 'milestone1.titel' | translate }}
  </h1>

  <br /><br /><br />

  <div *ngIf="milestoneProgress < 1" class="unfinished">
    <div class="progress-holder">
      <progress-indicator [progress]="milestoneProgress">
      </progress-indicator>
    </div>
    <br /><br />
    <p>
      {{ 'milestone1.unfinishedMessage' | translate }}
    </p>
    <button mat-raised-button color="primary" routerLink="">
      {{ 'word.goBack' | translate }}
    </button>
  </div>

  <ng-container *ngIf="milestoneProgress >= 1">
    <p>
      {{ 'milestone1.congratulationsMessage' | translate }}
    </p>
    <br />
    <div class="achievements" fxLayout="row">
      <div fxFlex="50%">
        <mat-card class="achievement">
          <mat-card-header>
            <img mat-card-avatar src="assets/complete.svg" />
            <mat-card-title>
              {{ 'milestone1.dpmsSetUpSubtitle' | translate }}
            </mat-card-title>
          </mat-card-header>
          <mat-card-content *ngIf="controller">
            <a>{{controller.controllerName}}</a>
            {{ 'milestone1.dpmsSetUpMessage' | translate }}
          </mat-card-content>
        </mat-card>

        <mat-card class="achievement">
          <mat-card-header>
            <img mat-card-avatar src="assets/complete.svg" />
            <mat-card-title>
              {{ 'milestone1.goalsSubtitle' | translate }}
            </mat-card-title>
          </mat-card-header>
          <mat-card-content *ngIf="controller">
            <a>{{controller.controllerName}}</a>
            {{ 'milestone1.goalsMessage' | translate }}
            <br /><br />
            <div class="upload-holder">
              <cool-upload #goalsUpload [readonly]="true">
              </cool-upload>
            </div>
          </mat-card-content>
        </mat-card>

        <mat-card class="achievement">
          <mat-card-header>
            <img mat-card-avatar src="assets/complete.svg" />
            <mat-card-title>
              {{ 'milestone1.departmentsSubtitle' | translate }}
            </mat-card-title>
          </mat-card-header>
          <mat-card-content *ngIf="departments">
            {{ 'milestone1.departmentsMessage.beforeCount' | translate }}
            <b>{{ departments.length }}</b>
            {{ 'milestone1.departmentsMessage.afterCount' | translate }}
          </mat-card-content>
        </mat-card>

        <mat-card class="achievement">
          <img mat-card-lg-image style="display: block; margin: 0 auto" src="/assets/badge.svg" />
          <br>
          <mat-card-content>
            {{'milestone1.badge.message' | translate}}
          </mat-card-content>
          <mat-card-actions align="center">
            <button mat-raised-button color="primary" routerLink="/badge/get">
              {{'milestone1.badge.action' | translate}}
            </button>
          </mat-card-actions>
        </mat-card>
      </div>

      <div fxFlex="50%">
        <mat-card class="achievement company-info">
          <mat-card-header>
            <img mat-card-avatar src="assets/complete.svg" />
            <mat-card-title>
              {{ 'milestone1.controllerSubtitle' | translate }}
            </mat-card-title>
          </mat-card-header>
          <mat-card-content *ngIf="controller">
            <iframe mat-card-image frameborder="0" style="border:0" [src]="googleMapsUrl()" allowfullscreen>
            </iframe>

            <div class="name">{{controller.controllerName}}</div>
            <div class="url"><a href="{{controller.controllerUrl}}">{{controller.controllerUrl}}</a></div>
            <div class="address">
              {{controller.controllerAddressStreet}}<br />
              {{controller.controllerAddressPostcode}} {{controller.controllerAddressCity}}<br />
              {{controller.controllerAddressCountry}}
            </div>
            <div class="email">{{controller.controllerEmailAddress}}</div>
            <div class="phone">{{controller.controllerTelephoneNumber}}</div>
          </mat-card-content>
        </mat-card>

        <mat-card class="achievement dpo">
          <mat-card-header>
            <img mat-card-avatar src="assets/complete.svg" />
            <mat-card-title>
              <ng-container *ngIf="!hasNoDpoAppointed">
                {{ 'milestone1.dpoSubtitle' | translate }}
              </ng-container>
              <ng-container *ngIf="hasNoDpoAppointed">
                {{ 'milestone1.dpoSubtitleNotDeclared' | translate }}
              </ng-container>
            </mat-card-title>
          </mat-card-header>
          <mat-card-content *ngIf="dpo">
            <div *ngIf="!hasNoDpoAppointed" class="has-dpo">
              <div class="name">{{dpo.name}}</div>
              <div class="address">
                {{dpo.addressStreet}}<br />
                {{dpo.addressPostcode}} {{dpo.addressCity}}<br />
                {{dpo.addressCountry}}
              </div>
              <div class="email">{{dpo.email}}</div>
              <div class="phone">{{dpo.telephoneNumber}}</div>
              <br /><br />
              <div class="upload-holder">
                <cool-upload #dpoUpload [readonly]="true">
                </cool-upload>
              </div>
            </div>
            <div *ngIf="hasNoDpoAppointed" class="no-dpo">
              {{ 'dpo.explicitlyNotDeclared' | translate }}
            </div>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
    <br /><br />
    <mat-divider></mat-divider>
    <br />
    <div class="buttons">
      <button mat-raised-button color="primary" (click)="continue()">
        {{ 'milestone1.endButton' | translate }}
      </button>
    </div>
  </ng-container>
</div>
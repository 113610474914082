import { Component, OnInit, OnDestroy } from '@angular/core';
import { EventsService } from 'app/services/events.service';
import { BusService } from 'app/services/bus.service';
import { MatDialog } from '@angular/material/dialog';
import { ClientManagementService } from 'app/services/supervision/client-management.service';
import { DeleteClientDialogComponent } from '../delete-client-dialog/delete-client-dialog.component';
import { AuthenticationService } from '../../../services/authentication.service';
import { SupervisionClientTokenService } from '../../../services/supervision/client-token.service';
import { ClientSupervisionService } from '../../../services/supervision/client-supervision.service';
import { SupervisionTemplatesService } from '../../../services/supervision/templates.service';
import { AppConfig } from '../../../app.config';
import { DialogComponent } from '../../dialog/dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { riskAnalysisClassificationByValue } from '../../rpa/pa-details/risk-analysis/risk-analysis.consts';
import { AddClientControllerComponent } from '../add-client/controller/add-client-controller.component';
import { SupervisorProfileService } from 'app/services/supervision/supervisor-profile.service';
import { SelectUserComponent } from 'app/components/select-user/select-user.component';
import { ClientAccessUserDialogComponent } from '../client-access/client-access-user-dialog/client-access-user-dialog.component';
import { ActivatedRoute, Router } from '@angular/router';
import { AddDemoClientComponent } from '../add-demo-client/add-demo-client.component';
import { TodoService } from '../../../services/todos/todo.service';
import { MixpanelService } from '../../../modules/mixpanel/mixpanel.service';
import {
  ClientLimitWarningDialogComponent
} from '../client-limit-warning-dialog/client-limit-warning-dialog.component';
import { GroupListService } from '../../../services/supervision/group-list.service';
import {
  CreateGroupDialogComponent
} from '../group-list/create-group-dialog/create-group-dialog.component';
import {
  DeleteGroupDialogComponent
} from '../group-list/delete-group-dialog/delete-group-dialog.component';
import {
  GroupAddClientDialogComponent
} from '../group-list/group-add-client-dialog/group-add-client-dialog.component';
import {zip} from "rxjs";


@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'supervision-client-list',
  templateUrl: './client-list.component.html',
  styleUrls: ['./client-list.component.scss']
})
export class SupervisionClientListComponent implements OnInit, OnDestroy {
  appConfig = AppConfig;

  supervisedControllers = [];
  supervisedGroups = [];

  list = [];

  pendingRequests = [];
  ordering: 'alpha' | 'activity' | 'reminders' | 'rpas' | 'meeting' | 'created_on' | 'incidents-dsars' = 'created_on';

  loadingPending = true;
  loadingAccepted = true;

  controllerId: number = undefined;
  filter = '';
  filteredUser = undefined;
  users = [];
  supervisorProfile: any;

  selectedGroup: any = undefined;

  constructor(
    private bus: BusService,
    private events: EventsService,
    private dialog: MatDialog,
    private clientService: ClientManagementService,
    private authService: AuthenticationService,
    private clientToken: SupervisionClientTokenService,
    private supervision: ClientSupervisionService,
    private profile: SupervisorProfileService,
    private templates: SupervisionTemplatesService,
    private translate: TranslateService,
    private auth: AuthenticationService,
    private router: Router,
    private todos: TodoService,
    private mixpanel: MixpanelService,
    private groupService: GroupListService,
    private activatedRoute: ActivatedRoute,
  ) {

  }


  public ngOnInit(): void {
    this.profile.isAccessExpired().subscribe((expired) => {
      if (expired) {
        this.router.navigate(['supervision/access-expired']);
      }
    });

    this.subscribe();
    this.setControllerId();

    zip(
      this.groupService.fetchGroups(),
      this.clientService.clients()
    ).subscribe(([groups, clients]) => {
      this.supervisedControllers = clients;
      this.supervisedGroups = groups.map(group => {
        group.clients = this.supervisedControllers.filter(client => client.group === group.id);
        return group;
      });
      this.navigateToGroup();
    });

    this.bus.publish(this.events.requested.data.supervision.requests.pending);
    this.profile.getUsers().subscribe(users => {
      this.users = users;
      this.loadFiltersAndOrdering();
    });

    // temporary to fetch current supervisor todos
    this.todos.fetchTodos();

    this.profile.getProfile().subscribe((profile) => {
      this.supervisorProfile = profile;
    });

    this.mixpanel.track('supervision:show_client_list');
  }

  public ngOnDestroy(): void {
    this.unsubscribe();
  }


  subscribe() {
    this.bus.subscribe(this.events.received.data.supervision.requests.pending.success, this.updatePendingRequests, this);
    this.bus.subscribe(this.events.received.data.supervision.clients.all.success, this.updateSupervisedControllers, this);
    this.bus.subscribe(this.events.received.action.supervision.clientInvitation.resend.success, this.invitationResent, this);

    this.bus.subscribe('remove-from-group-event', this.removedFromGroup, this);
    this.bus.subscribe('remove-from-group-failed-event', this.removeFromGroupFailed, this);
  }

  unsubscribe() {
    this.bus.unsubscribe(this.events.received.data.supervision.requests.pending.success, this.updatePendingRequests);
    this.bus.unsubscribe(this.events.received.data.supervision.clients.all.success, this.updateSupervisedControllers);
    this.bus.unsubscribe(this.events.received.action.supervision.clientInvitation.resend.success, this.invitationResent);

    this.bus.unsubscribe('remove-from-group-event', this.removedFromGroup);
    this.bus.unsubscribe('remove-from-group-failed-event', this.removeFromGroupFailed);
  }

  fetchAndNavigateToGroup() {
    this.groupService.fetchGroups()
      .subscribe((groups) => {
        this.supervisedGroups = groups.map(group => {
          group.clients = this.supervisedControllers.filter(client => client.group === group.id);
          return group;
        });
        this.navigateToGroup();
      });
  }

  clearFilteredUser(event) {
    event.stopPropagation();
    this.filteredUser = undefined;
    this.saveFiltersAndOrdering();
  }

  pickFilteredUser() {
    this.dialog.open(SelectUserComponent, {
      width: '512px',
      maxHeight: '90vh',
      data: {
        users: this.users,
        selected: this.filteredUser,
      }
    }).afterClosed().subscribe(user => {
      if (user) {
        this.filteredUser = user;
        this.saveFiltersAndOrdering();
      }
    });
  }

  toggleOrdering(ordering) {
    if (this.ordering === ordering) {
      this.ordering = 'created_on';
    } else {
      this.ordering = ordering;
    }

    this.saveFiltersAndOrdering();
  }

  saveFiltersAndOrdering() {
    localStorage.setItem('supervision-client-list-fo', JSON.stringify({
      ordering: this.ordering,
      filteredUserId: this.filteredUser?.id,
    }));
  }

  loadFiltersAndOrdering() {
    try {
      const saved = JSON.parse(localStorage.getItem('supervision-client-list-fo'));
      this.ordering = saved.ordering;
      if (this.users) {
        this.filteredUser = this.users.find(u => u.id === saved.filteredUserId);
      }
    } catch {
      // pass
    }
  }


  public isEmpty(list: Array<any>) {
    return !list || list.length === 0;
  }

  get showDemoPrompt() {
    return !this.clients || this.clients.length < 3;
  }

  public supervise(client) {
    this.clientToken.getClientToken(client._id).subscribe(response => {
      if (response.token) {
        this.supervision.startSupervision(response.token);
      }
    });
  }

  public addDemoClients() {
    this.dialog.open(AddDemoClientComponent, {
      maxWidth: '600px',
      maxHeight: '90vh',
    })
      .afterClosed().subscribe(mutated => {
        if (mutated) {
          this.ordering = 'created_on';
          this.bus.publish(this.events.requested.data.supervision.clients.all);
          this.bus.publish(this.events.requested.data.supervision.requests.pending);
        }
      });
  }

  public handleAddClient() {
    if (this.clients.length >= this.supervisorProfile.clientsPaidFor
      && this.supervisorProfile.clientsPaidFor !== -1) {
      return this.maxClientWarning();
    }

    this.addClient();
  }

  public handleAddGroup() {
    this.dialog.open(CreateGroupDialogComponent, {
      width: '500px',
      maxHeight: '90vh',
    }).afterClosed().subscribe(() => {
      this.fetchAndNavigateToGroup();
    });
  }

  public handleDeleteGroup(group: any, event: any) {
    if (event) {
      event.stopPropagation();
      event.preventDefault();
    }

    if (this.supervisedControllers.filter(c => c.group === group.id).length > 0) {
      this.dialog.open(DialogComponent, {
        width: '500px',
        maxHeight: '90vh',
        data: {
          header: this.translate.instant('supervision.deleteGroup.title'),
          message: this.translate.instant('supervision.deleteGroup.error'),
        }
      });
    } else {
      this.dialog.open(DeleteGroupDialogComponent, {
        width: '500px',
        maxHeight: '90vh',
        data: {
          group
        }
      }).afterClosed().subscribe((deleted) => {
        if (deleted) {
          this.bus.publish(this.events.requested.data.supervision.clients.all);
          this.fetchAndNavigateToGroup();
        }
      });
    }
  }

  public handleAddToGroup(controller: any, event: any) {
    if (event) {
      event.stopPropagation();
      event.preventDefault();
    }
    this.dialog.open(GroupAddClientDialogComponent, {
      width: '500px',
      maxHeight: '90vh',
      data: {
        controller,
        groups: this.supervisedGroups,
      }
    }).afterClosed().subscribe((added) => {
      if (added) {
        this.bus.publish(this.events.requested.data.supervision.clients.all);
        this.fetchAndNavigateToGroup();
      }
    });
  }

  public handleRemoveFromGroup(groupId, controllerId, event) {
    if (event) {
      event.stopPropagation();
      event.preventDefault();
    }
    this.groupService.removeClientFromGroup(groupId, controllerId);
  }

  public maxClientWarning() {
    if (this.supervisedControllers.length >= this.supervisorProfile.clientsPaidFor) {
      this.dialog.open(ClientLimitWarningDialogComponent, {
        width: '500px',
        maxHeight: '90vh',
        data: {
          limit: this.supervisorProfile.clientsPaidFor,
          current: this.supervisedControllers.length,
        }
      }).afterClosed().subscribe((choice: boolean) => {
        if (choice) {
          this.addClient();
        }
      });
    }
  }

  public addClient() {
    this.dialog.open(AddClientControllerComponent, {
      width: '600px',
      maxHeight: '90vh',
      data: {
        currentGroup: this.selectedGroup
      }
    }).afterClosed().subscribe(mutated => {
      if (mutated) {
        this.ordering = 'created_on';
        this.bus.publish(this.events.requested.data.supervision.clients.all);
        this.bus.publish(this.events.requested.data.supervision.requests.pending);
      }
    });
  }

  manageAccess(client) {
    this.dialog.open(ClientAccessUserDialogComponent, { data: { client }, width: '600px', maxHeight: '90vh' });
  }

  public applyTemplate(client) {
    this.templates.applyTemplate(client);
  }

  public createTemplate(client) {
    this.templates.createTemplate(client);
  }

  public deleteClient(clientOrRequest, isAccepted: boolean, event) {
    if (event) {
      event.stopPropagation();
    }

    const data: any = {};
    if (isAccepted) {
      data.client = clientOrRequest;
    } else {
      data.request = clientOrRequest;
    }

    this.dialog.open(DeleteClientDialogComponent, {
      width: '350px',
      maxHeight: '90vh',
      data,
    }).afterClosed().subscribe(mutated => {
      if (mutated) {
        if (isAccepted) {
          this.bus.publish(this.events.requested.data.supervision.clients.all);
        } else {
          this.bus.publish(this.events.requested.data.supervision.requests.pending);
        }
      }
    });
  }

  public resendInvitation(request) {
    this.bus.publish(this.events.requested.action.supervision.clientInvitation.resend, request.id);
  }

  public async invitationResent() {
    const header = await this.translate.get('supervision.clientList.client.resendInvitation.dialog.header').toPromise();
    const message = await this.translate.get('supervision.clientList.client.resendInvitation.dialog.message').toPromise();

    this.dialog.open(DialogComponent, {
      width: '350px',
      maxHeight: '90vh',
      data: {
        header,
        message
      }
    });
  }

  updatePendingRequests(requests) {
    this.pendingRequests = requests;
    this.loadingPending = false;
  }

  updateSupervisedControllers(controllers) {
    this.supervisedControllers = controllers;
    this.supervisedControllers.forEach(ctrl => {
      ctrl.createdOn = new Date(ctrl.createdOn);
      if (ctrl.nextMeetingDate) {
        ctrl.nextMeetingDate = new Date(ctrl.nextMeetingDate);
      }
      if (ctrl._extra) {
        if (ctrl._extra.tasks) {
          ctrl._extra.tasks = ctrl._extra.tasks.sort((t1, t2) => t1.index - t2.index);
        }

        if (ctrl._extra.paData) {
          ctrl._extra.paData.class = riskAnalysisClassificationByValue(ctrl._extra.paData.dpiaMax);
        }

        if (ctrl._extra.lastEventDate) {
          ctrl._extra.lastEventDate = new Date(ctrl._extra.lastEventDate);
        }
      }

      // for easier sorting, make sure everything has a title (groups AND controllers)
      if (!ctrl.title && ctrl.controllerName) {
        ctrl.title = ctrl.controllerName;
      }
    });
    this.loadingAccepted = false;
  }

  selectGroup(groupId: number) {
    this.selectedGroup = this.supervisedGroups.find(c => c.id === +groupId);
  }

  filterBySelectedGroup() {
    return this.supervisedControllers.filter(c => c.group === this.selectedGroup.id);
  }

  getGroupClients(client: any) {
    return this.supervisedControllers.filter(c => c.group === client.id).slice(0, 9);
  }

  removedFromGroup() {
    this.bus.publish(this.events.requested.data.supervision.clients.all);
    this.fetchAndNavigateToGroup();
  }

  removeFromGroupFailed(error) {
    console.error(error);
  }

  navigateToGroup() {
    this.activatedRoute.params.subscribe(params => {
      if (params && params.groupId) {
        this.selectGroup(params.groupId);
        if (!this.selectedGroup) {
          this.router.navigate(['/supervision/clients']);
        }
      }
    });
  }

  private ordered(clientList) {
    return clientList.sort((a, b) => {
      if (this.ordering === 'created_on') {
        return b.createdOn - a.createdOn;
      } else if (this.ordering === 'alpha') {
        const controllerNameA = a.title.toLowerCase();
        const controllerNameB = b.title.toLowerCase();

        if (controllerNameA < controllerNameB) {
          return -1;
        }

        if (controllerNameA > controllerNameB) {
          return 1;
        }

        return 0;
      } else if (this.ordering === 'activity') {
        return b._extra?.lastEventDate - a._extra?.lastEventDate;
      } else if (this.ordering === 'meeting') {
        if (a.nextMeeting && !b.nextMeeting) {
          return -1;
        }
        if (b.nextMeeting && !a.nextMeeting) {
          return 1;
        }
        return a.nextMeeting - b.nextMeeting;
      } else if (this.ordering === 'rpas') {
        return (b._extra?.paData?.count || 0) - (a._extra?.paData?.count || 0);
      } else if (this.ordering === 'incidents-dsars') {
        return (b._extra?.openRecordables || 0) - (a._extra?.openRecordables || 0);
      }
    });
  }

  private filtered(clientList) {
    if (this.filter || this.filteredUser) {
      const match = key => key.toLowerCase().indexOf(this.filter.toLowerCase()) !== -1;
      return clientList
        .filter(client => {
          if (!this.filter) {
            return true;
          }
          if (client.title && match(client.title)) {
            return true;
          }
          if (client.clientId && match(client.clientId)) {
            return true;
          }
          if (client.email && match(client.email)) {
            return true;
          }
          if (client.controllerContactName && match(client.controllerContactName)) {
            return true;
          }
          if (client.controllerEmailAddress && match(client.controllerEmailAddress)) {
            return true;
          }
          return false;
        })
        .filter(client => {
          if (!this.filteredUser) {
            return true;
          } else if (client.correspondingSupervisor === this.filteredUser.id) {
            return true;
          } else if (client.clients && client.clients.some(c => c.correspondingSupervisor === this.filteredUser.id)) {
            return true;
          }
          return false;
        });
    } else {
      return clientList;
    }
  }

  get clients() {
    // show all clients without group and group cards
    if (this.supervisedControllers && !this.selectedGroup && !this.filter) {
      return this.filtered(this.ordered([...this.supervisedControllers.filter(c => c.group === null), ...this.supervisedGroups]));

    // show filtered clients from only the selected group
    } else if (this.supervisedControllers && this.selectedGroup && this.filter) {
      return this.filtered(this.ordered(this.filterBySelectedGroup()));

    // show only clients of a selected group
    } else if (this.supervisedControllers && this.selectedGroup) {
      return this.ordered(this.filterBySelectedGroup());

    // show all clients and groups when there is a search
    } else if (this.supervisedControllers && this.filter) {
      return this.filtered(this.ordered(this.supervisedControllers.concat(this.supervisedGroups)));
    } else {
      return [];
    }
  }

  get pending() {
    if (this.pendingRequests) {
      return this.filtered(this.pendingRequests);
    } else {
      return [];
    }
  }

  get isAdmin() {
    if (this.users) {
      const curr = this.users.find(u => u.email === this.auth.email);
      return curr && curr.supervisorRole === 'admin';
    }

    return false;
  }

  private setControllerId() {
    this.controllerId = parseInt(localStorage.getItem('currentUserControllerId'), 10);
  }
}

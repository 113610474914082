<div class="contents">
  <stepper #stepper [navigation]="false">
    <stepper-step id="welcome">
      <mat-card>
        <img mat-card-image src="assets/dsar/intro.svg" width="100%"/>
        <p>
          {{ 'dsar.intro' | translate }}
        </p>
        <mat-card-actions align="end">
          <button mat-button
                  (click)="stepper.switch('comeBackLater')"
                  color="warn">
            {{ 'yesno.no' | translate }}
          </button>
          <button mat-flat-button
                  (click)="stepper.switch('confirmRequest')"
                  color="primary">
            {{ 'yesno.yes' | translate }}
          </button>
        </mat-card-actions>
      </mat-card>
    </stepper-step>

    <stepper-step id="comeBackLater">
      <div class="no-card">
        <img src="assets/party.svg" width="100%"/>
        <p>
          {{ 'dsar.comeBackLater' | translate }}
        </p>
        <button mat-button
                (click)="stepper.switch('welcome')"
                color="primary">
          {{ 'word.ok' | translate }}
        </button>
      </div>
    </stepper-step>

    <stepper-step id="confirmRequest">
      <mat-card>
        <img mat-card-image src="assets/dsar/reply.svg" width="100%"/>
        <p>
          {{ 'dsar.confirm' | translate }}
        </p>
        <mat-card-actions align="end">
          <button mat-button
                  (click)="stepper.switch('welcome')"
                  color="accent">
            {{ 'dsar.back' | translate }}
          </button>
          <button mat-button
                  (click)="stepper.switch('confirmRequest0')"
                  color="warn">
            {{ 'word.notYet' | translate }}
          </button>
          <button mat-flat-button
                  (click)="stepper.switch('confirmRequest1')"
                  color="primary">
            {{ 'yesno.yes' | translate }}
          </button>
        </mat-card-actions>
      </mat-card>
    </stepper-step>

    <stepper-step id="confirmRequest0">
      <mat-card>
        <img mat-card-image src="assets/welcome/Sample.svg" width="100%"/>
        <p>
          {{ 'dsar.downloadMessage' | translate }}
        </p>
        <mat-card-actions align="end">
          <button mat-button
                  (click)="stepper.switch('confirmRequest')"
                  color="accent">
            {{ 'dsar.back' | translate }}
          </button>
          <a mat-flat-button color="primary" target="_blank"
             href="assets/documents/{{ 'dsar.ende' | translate}}/dsar/{{ 'dsar.filename.01' | translate}}.docx">
            {{ 'dsar.downloadTemplateReceiptConfirmation' | translate }}
          </a>
        </mat-card-actions>
      </mat-card>
    </stepper-step>

    <stepper-step id="confirmRequest1">
      <mat-card>
        <img mat-card-image src="assets/dsar/identity.svg" width="100%"/>
        <p>
          {{ 'dsar.claim' | translate }}
        </p>
        <mat-card-actions align="end">
          <button mat-button
                  (click)="stepper.switch('confirmRequest')"
                  color="accent">
            {{ 'dsar.back' | translate }}
          </button>
          <button mat-button
                  (click)="stepper.switch('confirmIDTemplate')"
                  color="warn">
            {{ 'yesno.no' | translate }}
          </button>
          <button mat-flat-button
                  (click)="stepper.switch('haveData')"
                  color="primary">
            {{ 'yesno.yes' | translate }}
          </button>
        </mat-card-actions>
      </mat-card>
    </stepper-step>

    <stepper-step id="confirmIDTemplate">
      <mat-card>
        <img mat-card-image src="assets/welcome/Sample.svg" width="100%"/>
        <p>
          {{ 'dsar.downloadMessageUnclearID' | translate }}
        </p>
        <mat-card-actions align="end">
          <button mat-button
                  (click)="stepper.switch('welcome')"
                  color="accent">
            {{ 'dsar.back' | translate }}
          </button>
          <a mat-flat-button color="primary" target="_blank"
             href="assets/documents/{{ 'dsar.ende' | translate}}/dsar/{{ 'dsar.filename.02' | translate}}.docx">
            {{ 'dsar.downloadTemplateUnclearID' | translate }}
          </a>
        </mat-card-actions>
      </mat-card>
    </stepper-step>

    <stepper-step id="haveData">
      <mat-card>
        <img mat-card-image src="assets/dsar/data.svg" width="100%"/>
        <p>
          {{ 'dsar.anyStoredData' | translate }}
        </p>
        <mat-card-actions align="end">
          <button mat-button
                  (click)="stepper.switch('confirmRequest1')"
                  color="accent">
            {{ 'dsar.back' | translate }}
          </button>
          <button mat-button
                  (click)="stepper.switch('noDataOnPerson')"
                  color="warn">
            {{ 'yesno.no' | translate }}
          </button>
          <button mat-flat-button
                  (click)="stepper.switch('typeOfRequest')"
                  color="primary">
            {{ 'yesno.yes' | translate }}
          </button>
        </mat-card-actions>
      </mat-card>
    </stepper-step>

    <stepper-step id="noDataOnPerson">
      <mat-card>
        <img mat-card-image src="assets/welcome/Sample.svg" width="100%"/>
        <p>
          {{ 'dsar.noDataMessage' | translate }}
        </p>
        <mat-card-actions align="end">
          <button mat-button
                  (click)="stepper.prev()"
                  color="accent">
            {{ 'dsar.back' | translate }}
          </button>
          <a mat-flat-button color="primary" target="_blank"
             href="assets/documents/{{ 'dsar.ende' | translate}}/dsar/{{ 'dsar.filename.03' | translate}}.docx">
            {{ 'dsar.downloadTemplateNoData' | translate }}
          </a>
        </mat-card-actions>
      </mat-card>
    </stepper-step>

    <stepper-step id="typeOfRequest">
      <div class="scrollable">
        <mat-card>
          <img mat-card-image src="assets/welcome/Sample.svg" height="100%" width="100%"/>
          <p class="small">
            {{ 'dsar.requestType' | translate }}
            <br><br>
            {{ 'dsar.requestTypeHint' | translate }}
            <br><br>
            <mat-card-actions>
              <button mat-button
                      (click)="stepper.switch('haveData')"
                      color="accent">
                {{ 'dsar.back' | translate }}
              </button>
            </mat-card-actions>
          </p>
          <mat-card-content>
            <mat-divider></mat-divider>
            <br>

            <div class="request">
              <!-- Template 4 -->
              <h1>{{ 'dsar.T04title' | translate }}</h1>
              <p>{{ 'dsar.T04subtitle' | translate }}</p>
              <div class="buttons">
                <a mat-flat-button color="primary" target="_blank"
                   href="assets/documents/{{ 'dsar.ende' | translate}}/dsar/{{ 'dsar.filename.04' | translate}}.docx">
                  {{ 'dsar.downloadTemplate' | translate }}
                </a>
              </div>
            </div>

            <br>
            <mat-divider></mat-divider>
            <br>

            <div class="request">
              <!-- Template 5 -->
              <h1>{{ 'dsar.T05title' | translate }}</h1>
              <p>{{ 'dsar.T05subtitle' | translate }}</p>
              <div class="buttons">
                <a mat-flat-button color="primary" target="_blank"
                   href="assets/documents/{{ 'dsar.ende' | translate}}/dsar/{{ 'dsar.filename.05' | translate}}.docx">
                  {{ 'dsar.downloadTemplate' | translate }}
                </a>
              </div>
            </div>

            <br>
            <mat-divider></mat-divider>
            <br>

            <div class="request">
              <!-- Template 6 -->
              <h1>{{ 'dsar.T06title' | translate }}</h1>
              <p>{{ 'dsar.T06subtitle' | translate }}</p>
              <div class="buttons">
                <a mat-flat-button color="primary" target="_blank"
                   href="assets/documents/{{ 'dsar.ende' | translate}}/dsar/{{ 'dsar.filename.06' | translate}}.docx">
                  {{ 'dsar.downloadTemplate' | translate }}
                </a>
              </div>
            </div>

            <br>
            <mat-divider></mat-divider>
            <br>

            <div class="request">
              <!-- Template 7 -->
              <h1>{{ 'dsar.T07title' | translate }}</h1>
              <p>{{ 'dsar.T07subtitle' | translate }}</p>
              <div class="buttons">
                <a mat-flat-button color="primary" target="_blank"
                   href="assets/documents/{{ 'dsar.ende' | translate}}/dsar/{{ 'dsar.filename.07' | translate}}.docx">
                  {{ 'dsar.downloadTemplate' | translate }}
                </a>
              </div>
            </div>

            <br>
            <mat-divider></mat-divider>
            <br>


            <div class="request">
              <!-- Template 8 -->
              <h1>{{ 'dsar.T08title' | translate }}</h1>
              <p>{{ 'dsar.T08subtitle' | translate }}</p>
              <div class="buttons">
                <a mat-flat-button color="primary" target="_blank"
                   href="assets/documents/{{ 'dsar.ende' | translate}}/dsar/{{ 'dsar.filename.08' | translate}}.docx">
                  {{ 'dsar.downloadTemplate' | translate }}
                </a>
              </div>
            </div>

            <br>
            <mat-divider></mat-divider>
            <br>

            <div class="request">
              <!-- Template 9 -->
              <h1>{{ 'dsar.T09title' | translate }}</h1>
              <p>{{ 'dsar.T09subtitle' | translate }}</p>
              <div class="buttons">
                <a mat-flat-button color="primary" target="_blank"
                   matTooltip=""
                   matTooltipPosition="right"
                   href="assets/documents/{{ 'dsar.ende' | translate}}/dsar/{{ 'dsar.filename.09' | translate}}.docx">
                  {{ 'dsar.downloadTemplate' | translate }}
                </a>
              </div>
            </div>

            <br>
            <mat-divider></mat-divider>
            <br>

            <div class="request">
              <!-- Template 10 -->
              <h1>{{ 'dsar.T10title' | translate }}</h1>
              <p>{{ 'dsar.T10subtitle' | translate }}</p>
              <div class="buttons">
                <a mat-flat-button color="primary" target="_blank"
                   href="assets/documents/{{ 'dsar.ende' | translate}}/dsar/{{ 'dsar.filename.10' | translate}}.docx">
                  {{ 'dsar.downloadTemplate' | translate }}
                </a>
              </div>
            </div>

            <br>
            <mat-divider></mat-divider>
            <br>
            <mat-divider></mat-divider>
            <br>

            <div class="request">
              <!-- Appendix 1 -->
              <h1>{{ 'dsar.T11title' | translate }}</h1>
              <p>{{ 'dsar.T11subtitle' | translate }}</p>
              <div class="buttons">
                <a mat-flat-button color="primary" target="_blank"
                   href="assets/documents/{{ 'dsar.ende' | translate}}/dsar/{{ 'dsar.filename.11' | translate}}.docx">
                  {{ 'dsar.downloadTemplate' | translate }}
                </a>
              </div>
            </div>

            <br>

            <div class="request">
              <!-- Appendix 2 -->
              <h1>{{ 'dsar.T12title' | translate }}</h1>
              <p>{{ 'dsar.T12subtitle' | translate }}</p>
              <div class="buttons">
                <a mat-flat-button color="primary" target="_blank"
                   href="assets/documents/{{ 'dsar.ende' | translate}}/dsar/{{ 'dsar.filename.12' | translate}}.docx">
                  {{ 'dsar.downloadTemplate' | translate }}
                </a>
              </div>
            </div>

            <br><br>
            <mat-divider></mat-divider>
          </mat-card-content>
        </mat-card>
      </div>
    </stepper-step>
  </stepper>
</div>

import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BusService } from 'app/services/bus.service';
import { EventsService } from 'app/services/events.service';
import { RpaReferenceService, TreeNode } from 'app/services/rpa/rpa.reference.service';
import { zip } from 'rxjs';
import { StarterPasCreateDialogComponent } from './starter-pas-create-dialog/starter-pas-create-dialog.component';


@Component({
  selector: 'app-starter-pas',
  templateUrl: './starter-pas.component.html',
  styleUrls: ['./starter-pas.component.scss']
})
export class StarterPasComponent implements OnInit {

  pickedPas = [];

  tree: TreeNode[];
  freePas: any[] = [];

  constructor(
    private service: RpaReferenceService,
    private bus: BusService,
    private events: EventsService,
    private dialog: MatDialog,
    private router: Router,
  ) {
  }

  ngOnInit(): void {
    zip(
      this.service.getReferencePAs(),
      this.service.getTree(),
    )
    .subscribe(([pas, tree]) => {
      this.tree = tree;

      pas.forEach(pa => {
        if (pa.treeNode) {
          const node = this.service.findNode(pa.treeNode, tree);
          if (node) {
            node.pas = node.pas || [];
            node.pas.push(pa);
            return;
          }
        }

        this.freePas.push(pa);
      });
    });
  }

  unpick(pa) {
    const index = this.pickedPas.indexOf(pa);
    this.pickedPas.splice(index, 1);
  }

  pick(pa) {
    this.pickedPas.push(pa);
  }

  picked(pa) {
    return this.pickedPas.includes(pa);
  }

  progress() {
    this.bus.publish(this.events.requested.action.tasks.progressControllerTask, {
      progress: 1,
      workflowCodename: 'path_to_gdpr',
      taskCodename: 'ptg_pa_selection',
    });
  }

  create() {
    this.dialog.open(StarterPasCreateDialogComponent, {
      width: '350px',
      data: this.pickedPas,
    }).afterClosed().subscribe(() => {
      this.close();
    });
  }

  close() {
    this.progress();
    this.router.navigate(['/todolist']);
  }
}

<mat-dialog-content class="pick-authority-dialog-contents">
  <mat-form-field>
    <input placeholder="{{'authority.picker.search' | translate}}"
      matInput type="text" [(ngModel)]="search" autofocus/>
    <button [ngClass]="{'active': !search}" class="search"
      mat-button matSuffix mat-icon-button>
      <mat-icon>search</mat-icon>
    </button>
    <button [ngClass]="{'active': search}"
      (click)="search=''" class="clear"
      mat-button matSuffix mat-icon-button>
      <mat-icon>clear</mat-icon>
    </button>
    <mat-hint innerHTML="{{'authority.picker.searchHint' | translate}}">
    </mat-hint>
  </mat-form-field>

  <ng-container class="authorities-list" itemSize="authorities.length">
    <div *ngFor="let authority of authorities"
    (click)="selectAuthority(authority)"
      class="authority" [class.selected]="selectedAuthority && (selectedAuthority.title === authority.title)">

      <div class="avatar">
        <img *ngIf="authority.logoUrl" [src]="authority.logoUrl" onerror="this.onerror=null;this.src='/assets/default_authority.svg';"/>
        <mat-icon *ngIf="!authority.logoUrl" class="no-logo">gavel</mat-icon>
      </div>

      <div class="info">
        <div class="title">
          {{authority.title}}
        </div>
        <ng-container>
          <div class="address">
            {{authority.addressStreet}}
          </div>
          <div class="email">
            {{authority.addressPostcode}} {{authority.addressCity}}, {{authority.addressCountry}}
          </div>
        </ng-container>
      </div>
    </div>
  </ng-container>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button [mat-dialog-close]="null">{{ 'word.cancel' | translate}}</button>
  <button mat-stroked-button [disabled]="!selectedAuthority" color="primary" (click)="pick()">
    {{'authority.picker.pick' | translate}}
  </button>
</mat-dialog-actions>

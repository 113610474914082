import { Directive, HostListener, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { HelpPopupDialogComponent } from './help-popup.component';

@Directive({
  selector: '[appHelpPopup]'
})
export class HelpPopupDirective {

  @Input('appHelpPopup') helpText: string;

  constructor(
    private dialog: MatDialog
  ) { }

  @HostListener('click') click() {
    this.showModal();
  }

  showModal() {
    this.dialog.open(HelpPopupDialogComponent, {
      width: '750px',
      maxHeight: '90vh',
      data: {
        contentTranslate: this.helpText
      }
    });
  }

}

import { Component, Injector, Input, OnDestroy, OnInit } from '@angular/core';
import {
  Audit,
  AuditConfiguration,
  AuditsService,
  Category,
  Question,
} from 'app/services/audits/audits.service';
import { Observable, Subject, Subscription } from 'rxjs';
import { debounceTime, switchMap, tap } from 'rxjs/operators';
import { AuditFieldState, fetchAuditLikeService } from '../../../util';

@Component({
  selector: 'app-audit-form-question-default',
  templateUrl: './default.component.html',
  styleUrls: ['./default.component.scss'],
})
export class AuditFormQuestionDefaultComponent implements OnInit, OnDestroy {
  @Input() audit: Audit = null;
  @Input() question: Question = null;
  @Input() readonly = false;

  @Input() auditConfiguration: AuditConfiguration = null;

  @Input() path = '';
  @Input() nestLevel = 0;

  applies: AuditFieldState<string>;

  constructor(private injector: Injector) {}

  get computedPath() {
    return `${this.path}`;
  }

  get shouldShowChildren() {
    return (
      (this.applies.updatedValue === 'true') ===
      (this.question.childCondition || true)
    );
  }

  get childNestLevel() {
    return parseInt(this.nestLevel.toString(), 10) + 1;
  }

  ngOnInit() {
    const appliesFullPath = `${this.path}_applies`;
    const savedResponse = this.audit.answers[appliesFullPath];
    const service = fetchAuditLikeService(
      this.auditConfiguration,
      this.injector
    );
    this.applies = new AuditFieldState<string>(
      service,
      this.audit.id,
      'applies',
      appliesFullPath,
      savedResponse ? (savedResponse as any) : null
    ); // for some reason, the checkboxes work with string values
  }

  ngOnDestroy() {
    this.applies.clear();
  }
}

<h1 mat-dialog-title>
{{'supervision.supervisorProfile.coSupervisors.invites.dialog.title' | translate}}
</h1>

<mat-dialog-content>
  <p>
  {{'supervision.supervisorProfile.coSupervisors.invites.dialog.hint' | translate}}
  </p>
  <mat-form-field>
    <input matInput [formControl]="email" placeholder="Email"/>
    <mat-error *ngIf="email.invalid && email.hasError('required')">
    {{'supervision.supervisorProfile.coSupervisors.invites.dialog.form.errors.emailRequired' | translate}}
    </mat-error>
    <mat-error *ngIf="email.invalid && email.hasError('email') && !email.hasError('required')">
    {{'supervision.supervisorProfile.coSupervisors.invites.dialog.form.errors.emailInvalid' | translate}}
    </mat-error>
    <mat-error *ngIf="email.invalid && email.hasError('invite')">
    {{'supervision.supervisorProfile.coSupervisors.invites.dialog.form.errors.alreadyInvited' | translate}}
    </mat-error>
  </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>{{'word.cancel'|translate}}</button>
  <button mat-raised-button color="primary" (click)="invite()" [disabled]="!email.valid">
  {{'supervision.supervisorProfile.coSupervisors.invites.dialog.actions.invite' | translate}}
  </button>
</mat-dialog-actions>

import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { AbstractControl, FormControl, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { BusService } from '../../../services/bus.service';
import { EventsService } from '../../../services/events.service';

import { InvitationResultDialogComponent } from '../invitation-result-dialog/invitation-result-dialog.component';
import {AccessLevelService} from "../../../services/user/access-level.service";

@Component({
  selector: 'app-invite-user-dialog',
  templateUrl: './invite-user-dialog.component.html',
  styleUrls: ['./invite-user-dialog.component.scss']
})
export class InviteUserDialogComponent implements OnInit, OnDestroy {

  _currentSupervisorEmail = '';

  firstName = new FormControl('');
  lastName = new FormControl('');
  email = new FormControl('', [Validators.required, Validators.email,
    this.emailVerify()
  ]);
  departmentId = new FormControl(undefined);
  access = new FormControl('user-management.role.controller.manager');

  departments = [];

  roles = [];

  constructor(
    private dialogRef: MatDialogRef<InviteUserDialogComponent>,
    private bus: BusService,
    private events: EventsService,
    private dialog: MatDialog,
    private accessLevel: AccessLevelService,
    @Inject(MAT_DIALOG_DATA) private data: any,
  ) {
    this.departments = data.departments;

    this._currentSupervisorEmail = (localStorage.getItem('isInSupervisionMode') === 'true') ? localStorage.getItem('currentUserEmail') : '';
  }

  ngOnInit() {
    this.subscribe();
  }

  ngOnDestroy() {
    this.unsubscribe();
  }

  invite() {
    this.email.markAsTouched();
    if (this.email.valid) {
      this.bus.publish(this.events.requested.action.controller.users.invite, {
        firstName: this.firstName.value,
        lastName: this.lastName.value,
        email: this.email.value,
        departmentId: this.departmentId.value,
        access: this.access.value
      });
    }
  }

  invited() {
    this.dialogRef.close(true);
  }

  inviteFailed(error) {
    this.dialog.open(InvitationResultDialogComponent, {
      width: '350px',
      maxHeight: '90vh',
      data: error.error
    });
  }

  subscribe() {
    this.bus.subscribe(this.events.received.action.controller.users.invite.success, this.invited, this);
    this.bus.subscribe(this.events.received.action.controller.users.invite.failure, this.inviteFailed, this);
    this.accessLevel.availableRoles()
      .subscribe((roles) => {
        this.roles = roles;
      });
  }

  unsubscribe() {
    this.bus.unsubscribe(this.events.received.action.controller.users.invite.success, this.invited);
    this.bus.unsubscribe(this.events.received.action.controller.users.invite.failure, this.inviteFailed);
  }

  emailVerify(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const forbidden = (this._currentSupervisorEmail === control.value);
      return forbidden ? {duplicateEmail: this._currentSupervisorEmail} : null;
    };
  }
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Application, ApplicationService } from '../../../services/vendors/application.service';

@Component({
  selector: 'app-application-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class ListComponent implements OnInit {
  @Input() applications: Application[] = [];
  @Input() readonly = false;
  @Input() paId: number = null;

  @Output() updated = new EventEmitter<Application>();

  constructor(
    private applicationService: ApplicationService,
    private router: Router
  ) { }

  primary(application: Application) {
    if (this.paId) {
      return;
    }

    this.router.navigate(['rpa', 'applicable'], { queryParams: { fa: application.id }});
  }

  delete(event: any, application: Application) {
    if (event) {
      event.stopPropagation();
    }

    if (this.paId) {
      console.log(application);
      this.applicationService.removePaApplication(this.paId, application.id)
        .subscribe((deleted) => {
          this.updated.emit(null);
        });
    }
  }

  ngOnInit(): void {
  }

}

<mat-dialog-content>
  <mat-form-field>
    <input matInput type="text" placeholder="{{ 'custom-pa.namePa' | translate }}" [(ngModel)]="paName"/>
    <mat-hint>
      {{'rpa.edit-dialog.name.hint' | translate}}
    </mat-hint>
  </mat-form-field>

  <ng-container *ngIf="isReference">
    <pa-reference-tree-input placeholder="Tree Node"
      [allowRoot]="true"
      [tree]="tree"
      [(node)]="node">
    </pa-reference-tree-input>
  </ng-container>

  <div class="toggles">
    <div class="toggle">
      <mat-slide-toggle color="primary" [(ngModel)]="processingOnBehalf" (change)="handleProcessingOnBehalf()">
        {{ 'pa.processingOnBehalf' | translate }}
      </mat-slide-toggle>
    </div>

    <div class="toggle">
      <mat-slide-toggle color="primary" [(ngModel)]="jointController" (change)="handleJointController()">
        {{ 'pa.jointController' | translate }}
      </mat-slide-toggle>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <mat-menu #appMenu="matMenu">
    <button *ngIf="pa.paStatus === 'Applicable'" mat-menu-item (click)="archivePa()">{{'word.archive' | translate}}</button>
    <button *ngIf="pa.paStatus === 'Archived'" mat-menu-item (click)="unarchivePa()">{{'rpa.edit-dialog.unarchive' | translate}}</button>
    <button mat-menu-item color="warn" (click)="deletePa()">{{'word.delete' | translate}}</button>
  </mat-menu>

  <button mat-button color="warn" [matMenuTriggerFor]="appMenu">
    {{ 'word.archive' | translate}}...
  </button>

  <button mat-button mat-dialog-close>{{'word.cancel' | translate}}</button>
  <button mat-flat-button color="primary" (click)="save()">{{'word.save' | translate}}</button>
</mat-dialog-actions>

import {Component, Input, OnInit} from '@angular/core';
import {Measure, measureOverdue, measureDue, MeasuresService} from "../../../services/measures.service";
import {AccessLevelService, userRoles} from "../../../services/user/access-level.service";

@Component({
  selector: 'dashboard-measures',
  templateUrl: './measures.component.html',
  styleUrls: ['./measures.component.scss']
})
export class DashboardMeasuresComponent implements OnInit {

  measures: Measure[] = [];

  constructor(
    private measuresService: MeasuresService,
    private accessLevel: AccessLevelService
  ) { }

  get canNavigateAway() {
    return this.accessLevel.checkRole(userRoles.inspector) >= 0;
  }

  ngOnInit(): void {
    this.measuresService.getMeasures().subscribe(measures => {
      this.measures = measures.filter(measure => measure.showInDashboard);
    });
  }

  measureOverdue(measure: Measure) {
    return measureOverdue(measure);
  }

  measureDue(measure: Measure) {
    return measureDue(measure);
  }
}

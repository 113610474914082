<div class="contents">
  <h1 class="title">
    {{'vendors.list.title' | translate}}
  </h1>
  <br>
  <p>
    {{'vendors.list.message' | translate}}
  </p>
  <p *ngIf="accessLevel && !accessLevel.write" class="warning">
    <span>{{'word.NOTICE' | translate}}</span>
    {{'word.readonlyAccess' | translate}}
  </p>
  <br>

  <div class="button-row top-row">
    <button mat-flat-button color="primary" [disabled]="accessLevel && !accessLevel.write" (click)="addVendor($event)">{{'vendors.list.newVendor' | translate}}</button>
  </div>

  <app-data-recipients [vendors]="vendors" [readonly]="accessLevel && !accessLevel.write" (updated)="updated($event)"></app-data-recipients>

  <br>
  <div class="button-row">
    <button mat-button color="primary" (click)="downloadExcel()">{{'vendors.list.downloadExcel' | translate}}</button>
  </div>
</div>

<div class="contents">
  <br><br><br>
  <mat-divider></mat-divider>
  <br><br>
</div>

<app-controller-applications></app-controller-applications>

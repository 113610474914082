import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { FormControl, FormGroupDirective, NgForm } from '@angular/forms';

import { ErrorStateMatcher } from '@angular/material/core';
import { MatInput } from '@angular/material/input';

import { TomsCategory, TomsEntryAndData, TomsService } from '../../../services/toms/toms.service';
import { AccessLevel, AccessLevelService } from '../../../services/user/access-level.service';


class ExpErrorMatcher extends ErrorStateMatcher {
  constructor(
    private entry: TomsEntryAndData,
    // eslint-disable-next-line no-shadow
    private focused: (TomsEntryAndData) => boolean) {
    super();
  }

  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    return this.entry.applicable
      && this.entry.requiresExplanation
      && !this.entry.explanation
      && !this.focused(this.entry);
  }
}

@Component({
  selector: 'app-toms-category',
  templateUrl: './toms-category.component.html',
  styleUrls: ['./toms-category.component.scss']
})
export class TomsCategoryComponent implements OnInit {

  @ViewChildren(MatInput) expFields: QueryList<MatInput>;

  category: TomsCategory;
  entries: { [tag: string]: TomsEntryAndData[] };

  openedTomsExpId: number;
  focusedTomsExpId: number;

  updateExpTimeouts: { [id: number]: any } = {};

  accessLevel: AccessLevel;

  constructor(
    private acl: AccessLevelService,
    private toms: TomsService,
    private route: ActivatedRoute,
    private router: Router,
  ) {
    this.route.paramMap.subscribe((params: ParamMap) => {
      if (params.has('catId')) {
        const catId = +params.get('catId');

        this.toms.category(catId).subscribe(response => {
          this.category = response;
        });

        this.toms.categoryEntries(catId).subscribe(response => {
          this.entries = {};
          response.forEach(entry => {
            if (!this.entries[entry.tag]) {
              this.entries[entry.tag] = [];
            }
            this.entries[entry.tag].push(entry);
          });
        });
      }
    });

    this.acl.checkAccess({
      context: 'toms',
    }).subscribe(response => {
      this.accessLevel = response;
    });
  }

  ngOnInit() {
  }

  public get canWrite() {
    return this.accessLevel && this.accessLevel.write;
  }

  toggleTomsEntryExp(entry) {
    if (!this.canWrite) {
      return;
    }

    if (this.openedTomsExpId === entry.id) {
      this.openedTomsExpId = -1;
    } else {
      this.openedTomsExpId = entry.id;
      // this.updateTomStatus(entry, 'implemented');
      this.focusEntryExpField(entry);
    }
  }

  updateTomStatus(entry, value) {
    if (!this.canWrite) {
      return;
    }

    entry.applicable = true;
    entry.status = value;

    this.toms.updateEntry(entry);

    if (entry.requiresExplanation && (entry.status === 'implemented')) {
      this.focusEntryExpField(entry);
    }
  }

  updateExplanation(entry, value) {
    if (!this.canWrite) {
      return;
    }

    if (entry.explanation !== value) {
      entry.explanation = value;

      if (entry.id in this.updateExpTimeouts) {
        clearTimeout(this.updateExpTimeouts[entry.id]);
      }
      this.updateExpTimeouts[entry.id] = setTimeout(() => {
        this.toms.updateEntry(entry);
      }, 500);
    }
  }

  focusEntryExpField(entry) {
    this.expFields.find(input => input.id === `entry-exp-${entry.id}`).focus();
  }

  expErrorMatcher(entry) {
    return new ExpErrorMatcher(entry, (entry: TomsEntryAndData) => (entry.id === this.focusedTomsExpId && this.canWrite));
  }

  get valid(): boolean {
    if (!this.entries) {
      return;
    }

    for (const [tag, entries] of Object.entries(this.entries)) {
      if (entries.some(entry => entry.status === 'implemented' && entry.requiresExplanation && !entry.explanation)) {
        return false;
      }
    }

    return true;
  }

  done() {
    if (this.valid && this.canWrite) {
      this.router.navigate(['/toms']);
    }
  }

  public get tags(): string[] {
    if (this.entries) {
      return Object.keys(this.entries);
    } else {
      return [];
    }
  }
}

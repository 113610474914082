<div #containerRef class="pa-dists">
    <ngx-charts-pie-chart
    *ngIf="paDist && paDist.length > 0"
    [results]="paDist"
    [labels]="true"
    [doughnut]="true"
    [arcWidth]="0.4"
    [view]="[containerRef.offsetWidth, height]"
    >
    </ngx-charts-pie-chart>
</div>
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-document-group-container-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss']
})
export class DocumentGroupContainerDialogComponent implements OnInit {

  constructor(
    private dialogRef: MatDialogRef<DocumentGroupContainerDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { title: string; groupName: string; exact: boolean }
  ) { }

  ngOnInit(): void {
  }

}

<div class="contents">
  <ng-container *ngIf="loaded">
    <coupon-promo-new-value *ngIf="promotion.promotionIdentifier == 'new-value'" [promotion]="promotion">
    </coupon-promo-new-value>
  </ng-container>

  <br><br>

  <div class="card-info">
    <h3>
      {{ 'coupons.general.form.header' | translate }}
    </h3>
    <ng-container *ngIf="loaded">
      <mat-form-field>
        <input matInput type="text" name="email" [formControl]="emailControl"
          placeholder="{{ 'coupons.general.form.email.placeholder' | translate }}" required />
        <mat-hint>
          {{ 'coupons.general.form.email.hint' | translate }}
        </mat-hint>
        <mat-error *ngIf="emailControl.hasError('required')">
          {{ 'coupons.general.form.email.required' | translate }}
        </mat-error>
        <mat-error *ngIf="emailControl.hasError('email') && !emailControl.hasError('required')">
          {{ 'helper.pleaseEnterValidEmail' | translate }}
        </mat-error>
      </mat-form-field>

      <mat-form-field>
        <input matInput type="text" name="name" [formControl]="cardOwnerControl"
          placeholder="{{ 'coupons.general.form.cardowner.placeholder' | translate }}" required />
        <mat-hint>
          {{ 'coupons.general.form.cardowner.hint' | translate }}
        </mat-hint>
        <mat-error>
          {{ 'coupons.general.form.cardowner.invalid' | translate }}
        </mat-error>
      </mat-form-field>
    </ng-container>

    <div id="card-element" class="form-field stripe-element"></div>
    <mat-error class="card-error">{{cardError}}</mat-error>
  </div>

  <br><br>

  <ng-container *ngIf="loaded">
    <div class="price-bar">
      <div class="price">
        {{ 'coupons.general.price' | translate }}
        <b>{{promotion.currencySign}}&nbsp;{{ promotion.price }}</b>
      </div>
      <div class="price-spacer"></div>


      <button *ngIf="!loading" mat-flat-button color="primary" (click)="checkout()">
        {{ 'coupons.general.form.payNow' | translate }}
      </button>
      <mat-progress-spinner *ngIf="loading" mode="indeterminate" diameter="36">
      </mat-progress-spinner>
    </div>
  </ng-container>

  <br><br><br><br>
</div>
export function getRefNum(record) {
  return record.prefix + record.id;
}

export function getImpactScore(field: string) {
  if (field) {
    switch (field) {
      case 'incident.impact.minor': {
        return 1;
      }

      case 'incident.impact.medium': {
        return 2;
      }

      case 'incident.impact.high': {
        return 3;
      }
    }
  }

  return 0;
}

<h1 class="title" mat-dialog-title>
  <ng-container *ngIf="data.reason == 'interrupted-confirmation'">
    {{'payment.additional-authentication.interrupted.dialog-title' | translate}}
  </ng-container>
  <ng-container *ngIf="data.reason == 'source-error'">
    {{'payment.additional-authentication.error.dialog-title' | translate}}
  </ng-container>
</h1>

<mat-dialog-content>
  <br>
  <ng-container *ngIf="data.reason == 'interrupted-confirmation'">
    {{'payment.additional-authentication.interrupted.msg' | translate}}
  </ng-container>
  <ng-container *ngIf="data.reason == 'source-error'">
    {{'payment.additional-authentication.error.msg' | translate}}
  </ng-container>
  <br><br><br>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <a
    routerLink="/payment/update-details"
    [queryParams]="queryParams"
    mat-flat-button
    (click)="ref.close()"
    tabindex="-1"
    color="primary"
    class="update-details">
    <ng-container *ngIf="data.reason == 'interrupted-confirmation'">
      {{'payment.additional-authentication.interrupted.action' | translate}}
    </ng-container>
    <ng-container *ngIf="data.reason == 'source-error'">
      {{'payment.additional-authentication.error.action' | translate}}
    </ng-container>
  </a>
</mat-dialog-actions>

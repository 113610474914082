<h1 mat-dialog-title>
  {{'risk-analysis.risk.dialog.title' | translate}}
</h1>

<mat-dialog-content>
  <p>
    {{'risk-analysis.risk.dialog.level.hint' | translate}}
  </p>

  <br>
  <div class="range-buttons">
    <ng-container *ngFor="let range of riskRanges; index as i">
      <button mat-flat-button
        tabindex="-1"
        (click)="setProbability(range)"
        class="range-button"
        [ngClass]="{
          'picked': probability >= range[0] && probability < range[1],
          'green': i == 0,
          'less-green': i == 1,
          'orange': i == 2,
          'red': i >= 3
        }">
        {{ riskConstants.thresholdLabels[i] | translate }}
      </button>
    </ng-container>
  </div>

  <!--
    -- Translation Messages for Risk Threshold Labels --

    {{'risk-analysis.risk.labels.negligible'|translate}}
    {{'risk-analysis.risk.labels.low'|translate}}
    {{'risk-analysis.risk.labels.medium'|translate}}
    {{'risk-analysis.risk.labels.high'|translate}}
  -->

  <br><br>
  <p>
    {{'risk-analysis.risk.dialog.reasons.hint' | translate}}
  </p>
  <ul class="reasons">
    <li *ngFor="let reason of probabilityReasons" (click)="focusedReason = reason">
      <mat-form-field>
        <input matInput
            *ngIf="!reason.systematic"
            type="text"
            [disabled]="focusedReason != reason"
            (blur)="focusedReason = undefined"
            [(ngModel)]="reason.text" (change)="changed=true" />
        <input matInput
            *ngIf="reason.systematic"
            type="text"
            readonly
            value="{{reason.text|translate}}"
            [disabled]="focusedReason != reason"
            (blur)="focusedReason = undefined"/>
        <button mat-icon-button matSuffix
          (click)="removeReason(reason)"
          [disabled]="focusedReason != reason">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
    </li>
  </ul>
  <div class="buttons">
    <button mat-stroked-button color="primary" tabindex="-1" (click)="addReason()">
      {{'risk-analysis.risk.dialog.reasons.new' | translate}}
    </button>
  </div>

  <!--
    -- Translation Messages for Systematic Reasonings --

    {{'risk-analysis.risk.reasons.no-external'|translate}}
    {{'risk-analysis.risk.reasons.limited-external'|translate}}
    {{'risk-analysis.risk.reasons.external'|translate}}
    {{'risk-analysis.risk.reasons.multiple-external'|translate}}
    {{'risk-analysis.risk.reasons.excessive-external'|translate}}
    {{'risk-analysis.risk.reasons.no-internal'|translate}}
    {{'risk-analysis.risk.reasons.limited-internal'|translate}}
    {{'risk-analysis.risk.reasons.multiple-internal'|translate}}
    {{'risk-analysis.risk.reasons.excessive-internal'|translate}}
  -->

  <br><br>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close tabindex="-1">{{'word.cancel' | translate}}</button>
  <button mat-flat-button color="primary" (click)="done()" [disabled]="!changed">{{'word.done' | translate}}</button>
</mat-dialog-actions>

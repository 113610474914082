export function image(ctx: CanvasRenderingContext2D, img: HTMLImageElement, x, y, size) {
  let w = size;
  let h = size;

  if (img.naturalWidth > img.naturalHeight) {
    h = img.naturalHeight / img.naturalWidth * size;
    y += (size - h) / 2;
  } else {
    w = img.naturalWidth / img.naturalHeight * size;
    x += (size - w) / 2;
  }

  ctx.drawImage(img, x, y, w, h);
}

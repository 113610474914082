<div class="contents">
  <h1 class="title">
    {{'rpaob.intro.title' | translate}}
  </h1>
  <stepper #stepper [navigation]="false">
    <stepper-step initial>
      <mat-card>
        <img mat-card-image src="assets/rpa-on-behalf/processor-customers.svg" width="100%"/>
        <p>
          {{'rpaob.intro.slide1.message' | translate}}
        </p>
        <mat-card-actions align="end">
          <button mat-button color="warn" (click)="stepper.switch('no')">
            {{'yesno.no' | translate}}
          </button>
          <button mat-button color="primary" (click)="stepper.switch('maybe')">
            {{'yesno.yes' | translate}}
          </button>
        </mat-card-actions>
      </mat-card>
    </stepper-step>

    <stepper-step id="maybe" (activation)="progress(0.5)">
      <mat-card>
        <img mat-card-image src="assets/rpa-on-behalf/processor.svg" width="100%"/>
        <p>
          {{'rpaob.intro.slide2.message' | translate}}
        </p>
        <mat-card-actions align="end">
          <button mat-button color="warn" (click)="stepper.switch('no')">
            {{'yesno.no' | translate}}
          </button>
          <button mat-button color="primary" (click)="stepper.switch('yes')">
            {{'yesno.yes' | translate}}
          </button>
        </mat-card-actions>
      </mat-card>
    </stepper-step>

    <stepper-step id="yes" (activation)="finishYes()">
      <mat-card>
        <img mat-card-image src="assets/success-blue.svg" width="100%"/>
        <p>
          {{'rpaob.intro.slide-processor.message' | translate}}
        </p>
        <mat-card-actions align="end">
          <button mat-button color="primary" routerLink="">
            {{'word.ok' | translate}}
          </button>
        </mat-card-actions>
      </mat-card>
    </stepper-step>

    <stepper-step id="no" (activation)="finishNo()">
      <mat-card>
        <img mat-card-image src="assets/rpa-on-behalf/not-processor.svg" width="100%"/>
        <p>
          {{'rpaob.intro.slide-not-processor.message' | translate}}
        </p>
        <mat-card-actions align="end">
          <button mat-button routerLink="">
            {{'rpaob.intro.slide-not-processor.action' | translate}}
          </button>
        </mat-card-actions>
      </mat-card>
    </stepper-step>
  </stepper>
</div>

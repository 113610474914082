<h1 mat-dialog-title>{{ 'dpia.assistant.measureDefinition.recommendationDialog.title' | translate }}</h1>

<mat-dialog-content>
  <p class="help-text">{{ 'dpia.assistant.measureDefinition.recommendationDialog.helpText' | translate }}</p>

  <div class="search">
    <mat-form-field>
      <input matInput type="text" [(ngModel)]="keyword" placeholder="{{'word.search' | translate}}..."/>
      <button mat-button mat-icon-button matSuffix>
        <mat-icon>search</mat-icon>
      </button>
      <mat-hint>
        {{'long-select.search.hint' | translate}}
      </mat-hint>
    </mat-form-field>
  </div>

  <div class="options">
    <ng-container *ngFor="let measure of options; let last=last">
      <div class="option"
        (click)="select(measure)">
        {{getDisplay(measure).measureTitle}}
      </div>
      <hr *ngIf="!last"/>
    </ng-container>
  </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>{{ 'word.cancel' | translate }}</button>
  <!--<button mat-button color="primary" (click)="addOwn()">{{ 'dpia.assistant.measureDefinition.recommendationDialog.addOwn' | translate }}</button>-->
</mat-dialog-actions>

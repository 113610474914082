import { Component, OnInit, OnDestroy } from '@angular/core';
import { Location } from '@angular/common';
import { SafeResourceUrl, DomSanitizer } from '@angular/platform-browser';
import { MatDialog } from '@angular/material/dialog';
import { curveMonotoneX } from 'd3-shape';

import { BusService } from 'app/services/bus.service';
import { EventsService } from 'app/services/events.service';

import { ClientManagementService } from 'app/services/supervision/client-management.service';
import { DeleteClientDialogComponent } from '../delete-client-dialog/delete-client-dialog.component';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ClientSupervisionService } from '../../../services/supervision/client-supervision.service';
import { SupervisionClientTokenService } from '../../../services/supervision/client-token.service';
import { SupervisionTemplatesService } from '../../../services/supervision/templates.service';
import { WorkflowTasksService } from '../../../services/workflow-tasks/workflow-tasks.service';
import { AuthenticationService } from '../../../services/authentication.service';
import { AppConfig } from '../../../app.config';
import { of, zip } from 'rxjs';
import { SupervisionClientIdDialogComponent } from './supervision-client-id-dialog/supervision-client-id-dialog.component';
import { SupervisorProfileService } from 'app/services/supervision/supervisor-profile.service';
import { SelectUserComponent } from 'app/components/select-user/select-user.component';
import { ControllerService } from 'app/services/controller/controller.service';
import { SupervisionClientNotesDialogComponent } from './supervision-client-notes-dialog/supervision-client-notes-dialog.component';
import {
  SupervisionClientNextMeetingDialogComponent
} from './supervision-client-next-meeting-dialog/supervision-client-next-meeting-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { riskAnalysisClassificationByValue, RiskAnalysisClassificationColors, RiskAnalysisClassificationLabels } from 'app/components/rpa/pa-details/risk-analysis/risk-analysis.consts';
import { map } from 'rxjs/operators';
import { MixpanelService } from '../../../modules/mixpanel/mixpanel.service';


@Component({
  selector: 'app-supervision-client-details',
  templateUrl: './client-details.component.html',
  styleUrls: ['./client-details.component.scss']
})
export class SupervisionClientDetailsComponent implements OnInit, OnDestroy {

  appConfig = AppConfig;

  supervisedControllerId: number;
  controller: any;

  loadingToken: boolean;

  controllerId: number;
  nextUrl: string;

  users: any[];
  riskDistColors = {
    domain: []
  };
  curveEvents = curveMonotoneX;

  constructor(
    private bus: BusService,
    private events: EventsService,
    private dialog: MatDialog,
    private clientService: ClientManagementService,
    private controllerService: ControllerService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private sanitizer: DomSanitizer,
    private clientTokenService: SupervisionClientTokenService,
    private supervisionService: ClientSupervisionService,
    private profileService: SupervisorProfileService,
    private templates: SupervisionTemplatesService,
    private authService: AuthenticationService,
    private tasks: WorkflowTasksService,
    private translate: TranslateService,
    private mixpanel: MixpanelService,
  ) {

  }

  openClientIdDialog() {
    this.dialog.open(SupervisionClientIdDialogComponent, {
      width: '350px',
      maxHeight: '90vh',
      data: this.controller,
    });
  }

  openClientNotesDialog() {
    this.dialog.open(SupervisionClientNotesDialogComponent, {
      width: '512px',
      maxHeight: '90vh',
      data: this.controller,
    });
  }

  openClientNextMeetingDialog() {
    this.dialog.open(SupervisionClientNextMeetingDialogComponent, {
      width: '512px',
      maxHeight: '90vh',
      data: this.controller,
    });
  }

  clearNextMeeting() {
    this.controller.nextMeetingDate = null;
    this.controllerService.requestPut(this.controller);

    this.controller.nextMeeting = null;
    this.controllerService.requestPut(this.controller);
  }

  changeCorrespondingSupervisor() {
    this.dialog.open(SelectUserComponent, {
      width: '512px',
      maxHeight: '90vh',
      data: {
        users: this.users,
        selected: this.correspondingSupervisor,
      }
    }).afterClosed().subscribe(user => {
      if (user) {
        this.controller.correspondingSupervisor = user.id;
        this.controllerService.requestPut(this.controller);
      }
    });
  }

  updateServiced(event) {
    this.controller.serviced = event.value;
    this.controllerService.requestPut(this.controller);
  }

  superviseController() {
    this.loadingToken = true;
    this.bus.publish(this.events.requested.data.supervision.clientToken.single, this.supervisedControllerId);
  }

  startSupervision(token) {
    this.loadingToken = false;
    this.supervisionService.startSupervision(token, this.nextUrl);
  }

  setController(controller) {
    this.controller = controller;
    if (this.controller.nextMeetingDate) {
      this.controller.nextMeetingDate = new Date(this.controller.nextMeetingDate);
    }

    if (this.controller._extra) {
      this.controller._extra.auditProgress = this.controller._extra.audit.reduce(
        (total, task) => total + task.progress, 0) / this.controller._extra.audit.length;

      this.controller._extra.rpaProgress = this.controller._extra.rpa.reduce(
        (total, task) => total + task.progress, 0) / this.controller._extra.rpa.length;

      this.controller._extra.dsarIncidentTimeline = [{
        name: 'DSAR',
        series: [],
      }, {
        name: 'Incidents',
        series: [],
      }];

      this.translate.get('supervision.clientDetails.controller.open-dsars.label').subscribe(
        trans => this.controller._extra.dsarIncidentTimeline[0].name = trans
      );
      this.translate.get('supervision.clientDetails.controller.reported-incidents.label').subscribe(
        trans => this.controller._extra.dsarIncidentTimeline[1].name = trans
      );
      if (this.controller._extra.dsarHistory) {
        this.createDSARTimeSeries();
      }
      if (this.controller._extra.incidentHistory) {
        this.createIncidentTimeSeries();
      }

      this.controller._extra.paDist = [{
        name: 'Unassigned',
        value: 0,
      }];

      if (this.controller._extra.cpas && this.controller._extra.departments) {
        this.controller._extra.cpas.forEach(cpa => {
          const dep = this.controller._extra.departments.find(d => d.id === cpa.assignedDepartment);
          let entry = this.controller._extra.paDist[0];
          if (dep) {
            entry = this.controller._extra.paDist.find(e => e.id === dep.id);
            if (!entry) {
              entry = {
                id: dep.id,
                name: dep.name,
                value: 0
              };

              this.controller._extra.paDist.push(entry);
            }
          }

          entry.value++;
        });

        this.translate.get('supervision.clientDetails.charts.pa-deps.unassigned').subscribe(n => {
          this.controller._extra.paDist[0].name = n;
        });
      }

      this.controller._extra.paRiskDist = [
        { signifier: 0, name: 'safe', value: 0, },
        { signifier: 0, name: 'mostly-safe', value: 0, },
        { signifier: 0, name: 'warning', value: 0, },
        { signifier: 0, name: 'serious-warning', value: 0, },
        { signifier: 0, name: 'danger', value: 0, },
        { signifier: 0, name: 'catastrophe', value: 0, },
        { signifier: 0, name: 'unknown', value: 0, },
        { signifier: 0, name: 'needs-clarification', value: 0, }
      ];
      if (this.controller._extra.cpas) {
        this.controller._extra.cpas.forEach(cpa => {
          const classification = riskAnalysisClassificationByValue(cpa.pa_risk_analysis_value);
          const entry = this.controller._extra.paRiskDist.find(e => e.name === classification);
          // if (!entry) {
          //   entry = {
          //     signifier: cpa.pa_risk_analysis_value,
          //     name: classification,
          //     value: 0,
          //   };
          //   this.controller._extra.paRiskDist.push(entry);
          // }
          entry.signifier = cpa.pa_risk_analysis_value;

          entry.value++;
        });

        this.controller._extra.paRiskDist.sort((a, b) => a.signifier - b.signifier);
        this.controller._extra.paRiskDist.forEach(group => {
          this.riskDistColors.domain.push(RiskAnalysisClassificationColors[group.name]);
        });
      }

      zip(
        ...Object.entries(RiskAnalysisClassificationLabels).map(
          entry => this.translate.get(entry[1]).pipe(map(trans => [entry[0], trans]))
        )
      ).subscribe(trans => {
        const dict = trans.reduce((d, entry) => {
          d[entry[0]] = entry[1];
          return d;
        }, {});

        this.controller._extra.paRiskDist.forEach(entry => {
          entry.name = dict[entry.name];
        });
      });

      this.controller._extra.eventsTimeSeries = [{
        name: 'Events',
        series: []
      }];

      if (this.controller._extra.events) {
        this.createEventsTimeSeries();
        this.translate.get('supervision.clientDetails.charts.events.axis').subscribe(n => {
          this.controller._extra.eventsTimeSeries[0].name = n;
        });
      }
    }
  }

  createDSARTimeSeries() {
    const start = new Date();
    const end = new Date();
    start.setFullYear(start.getFullYear() - 1);

    this.controller._extra.dsarHistory.forEach(dsar => {
      dsar.receivedOn = new Date(dsar.receivedOn);
      dsar.completionOn = dsar.completionOn ? new Date(dsar.completionOn) : new Date('1-1-3000');
    });

    for (let cursor = new Date(start); cursor <= end; cursor.setMonth(cursor.getMonth() + 1)) {
      this.controller._extra.dsarIncidentTimeline[0].series.push({
        name: `${cursor.toLocaleString('default', { month: 'short' })} ${cursor.getFullYear()}`,
        value: this.controller._extra.dsarHistory.filter(dsar =>
          dsar.receivedOn <= cursor && dsar.completionOn > cursor
        ).length
      });
    }
  }

  createIncidentTimeSeries() {
    const start = new Date();
    const end = new Date();
    start.setFullYear(start.getFullYear() - 1);

    this.controller._extra.incidentHistory.forEach(incident => {
      incident.reportedOn = new Date(incident.reportedOn);
    });

    for (let cursor = new Date(start); cursor <= end; cursor.setMonth(cursor.getMonth() + 1)) {
      this.controller._extra.dsarIncidentTimeline[1].series.push({
        name: `${cursor.toLocaleString('default', { month: 'short' })} ${cursor.getFullYear()}`,
        value: this.controller._extra.incidentHistory.filter(incident =>
          incident.reportedOn.getMonth() === cursor.getMonth()
        ).length
      });
    }
  }

  createEventsTimeSeries() {
    const start = new Date();
    const end = new Date();
    start.setMonth(start.getMonth() - 6);

    this.controller._extra.events.forEach(event => {
      event.timestamp_at = new Date(event.timestamp_at);
    });

    for (let cursor = new Date(start); cursor <= end; cursor.setDate(cursor.getDate() + 7)) {
      const aWeekAfter = new Date(cursor);
      aWeekAfter.setDate(aWeekAfter.getDate() + 7);

      this.controller._extra.eventsTimeSeries[0].series.push({
        name: `${cursor.getDate()}/${cursor.getMonth() + 1}`,
        value: this.controller._extra.events.filter(event =>
          event.timestamp_at >= cursor && event.timestamp_at < aWeekAfter
        ).length
      });
    }
  }

  get tomsProgress() {
    return this.controller?._extra?.audit?.find(t => t.code_name === 'ad_toms')?.progress || 0;
  }

  get dpasProgress() {
    return this.controller?._extra?.audit?.find(t => t.code_name === 'ptg_vendor_dpas')?.progress || 0;
  }

  public deleteClient() {
    this.dialog.open(DeleteClientDialogComponent, {
      width: '350px',
      maxHeight: '90vh',
      data: {
        client: this.controller
      }
    }).afterClosed().subscribe(mutated => {
      if (mutated) {
        this.router.navigate(['/supervision/clients']);
      }
    });
  }

  googleMapsUrl(): SafeResourceUrl {
    if (this.controller) {
      const address = `${this.controller.controllerAddressStreet}, ` +
        `${this.controller.controllerAddressPostcode} ` +
        `${this.controller.controllerAddressCity},` +
        ` ${this.controller.controllerAddressCountry}`;
      return this.sanitizer.bypassSecurityTrustResourceUrl(
        `https://www.google.com/maps/embed/v1/place?key=AIzaSyBuRSXd5-JmVLAkFCPHd7_QQpWmi_5gfRo` +
        `&q=${encodeURIComponent(address)}`);
    }
    return undefined;
  }

  public applyTemplate() {
    this.templates.applyTemplate(this.controller);
  }

  public createTemplate() {
    this.templates.createTemplate(this.controller);
  }

  subscribe() {
    this.bus.subscribe(this.events.received.data.supervision.clients.single.success, this.setController, this);
    this.bus.subscribe(this.events.received.data.supervision.clients.single.failure, () => {
      this.router.navigate(['/not-found']);
    }, this);
    this.bus.subscribe(this.events.received.data.supervision.clientToken.single.success, this.startSupervision, this);
  }

  public ngOnInit(): void {
    this.profileService.isAccessExpired().subscribe((expired) => {
      if (expired) {
        this.router.navigate(['supervision/access-expired']);
      }
    });

    this.subscribe();
    this.setControllerId();

    this.profileService.getUsers().subscribe(users => {
      this.users = users;
    });

    zip(this.activatedRoute.params, this.activatedRoute.queryParams).subscribe(([params, query]) => {
      this.supervisedControllerId = params['clientId'];
      this.nextUrl = query['next'];

      if (this.nextUrl) {
        this.superviseController();
      } else {
        this.bus.publish(this.events.requested.data.supervision.clients.single, this.supervisedControllerId);
      }
    });

    this.mixpanel.track('supervision:show_client_details', {
      'Client ID': this.supervisedControllerId
    });
  }

  public ngOnDestroy(): void {
    this.bus.publish(this.events.ui.requested.sidenav.collapse);
  }

  get correspondingSupervisor() {
    if (this.controller && this.controller.correspondingSupervisor && this.users) {
      return this.users.find(u => u.id === this.controller.correspondingSupervisor);
    }
  }

  /**
   * round helper
   */
  roundValue(value: number): number {
    return Math.round(value);
  }
  integerLabel(value) {
    if (value % 1 === 0) {
      return value;
    }
    return '';
  }

  goBack() {
    if (this.controller.group) {
      return this.router.navigate([`/supervision/group/${this.controller.group}`]);
    }
    return this.router.navigate(['/supervision/clients']);
  }

  private setControllerId() {
    this.controllerId = this.authService.controllerId;
  }
}

<div class="authority">
  <h1 class="title">{{'authority.title' | translate }}</h1>
  <br />

  <div class="form-holder">
    <p>
      {{'authority.message' | translate }}
    </p>


    <button class="pick" mat-raised-button color="primary" (click)="pickAuthority($event)">{{'authority.form.pickAuthority' |
      translate}}</button>

    <form name="form" [formGroup]="formGroup" novalidate>
      <div commentable comment-subject-codename="authority-name"
        comment-subject-title="{{ 'authority.form.name.placeholder' | translate }}">
        <mat-form-field>
          <input matInput placeholder="{{ 'authority.form.name.placeholder' | translate }}" name="name" type="text"
            [readonly]="!canWrite" formControlName="name" (change)="submit('name')" autofocus required />
          <mat-hint *ngIf="!fieldsBeingSaved['name']">
            {{'authority.form.name.hint' | translate}}
          </mat-hint>
          <mat-hint *ngIf="fieldsBeingSaved['name']">{{'basicInfo.fields.saving' | translate}}</mat-hint>
        </mat-form-field>
      </div>


      <div commentable comment-subject-codename="authority-address-street"
        comment-subject-title="{{ 'authority.form.addressStreet.placeholder' | translate }}">
        <mat-form-field>
          <input matInput placeholder="{{ 'authority.form.addressStreet.placeholder' | translate }}"
            name="addressStreet" type="text" [readonly]="!canWrite" formControlName="addressStreet"
            (change)="submit('addressStreet')" required />
          <mat-hint *ngIf="!fieldsBeingSaved['addressStreet']">
            {{'authority.form.addressStreet.hint' | translate}}
          </mat-hint>
          <mat-hint *ngIf="fieldsBeingSaved['addressStreet']">{{'basicInfo.fields.saving' | translate}}</mat-hint>
        </mat-form-field>
      </div>

      <div commentable comment-subject-codename="authority-address-postcode"
        comment-subject-title="{{ 'authority.form.addressPostcode.placeholder' | translate }}">
        <mat-form-field>
          <input matInput placeholder="{{ 'authority.form.addressPostcode.placeholder' | translate }}"
            name="addressPostcode" type="text" [readonly]="!canWrite" formControlName="addressPostcode"
            (change)="submit('addressPostcode')" required />
          <mat-hint *ngIf="!fieldsBeingSaved['addressPostcode']">
            {{'authority.form.addressPostcode.hint' | translate}}
          </mat-hint>
          <mat-hint *ngIf="fieldsBeingSaved['addressPostcode']">{{'basicInfo.fields.saving' | translate}}</mat-hint>
        </mat-form-field>
      </div>

      <div commentable comment-subject-codename="authority-address-city"
        comment-subject-title="{{ 'authority.form.addressCity.placeholder' | translate }}">
        <mat-form-field>
          <input matInput placeholder="{{ 'authority.form.addressCity.placeholder' | translate }}" name="addressCity"
            type="text" [readonly]="!canWrite" formControlName="addressCity" (change)="submit('addressCity')"
            required />
          <mat-hint *ngIf="!fieldsBeingSaved['addressCity']">
            {{'authority.form.addressCity.hint' | translate}}
          </mat-hint>
          <mat-hint *ngIf="fieldsBeingSaved['addressCity']">{{'basicInfo.fields.saving' | translate}}</mat-hint>
        </mat-form-field>
      </div>

      <div commentable comment-subject-codename="authority-address-state"
        comment-subject-title="{{ 'authority.form.addressState.placeholder' | translate }}">
        <mat-form-field>
          <input matInput placeholder="{{ 'authority.form.addressState.placeholder' | translate }}" name="addressState"
            type="text" [readonly]="!canWrite" formControlName="addressState" (change)="submit('addressState')"
            required />
          <mat-hint *ngIf="!fieldsBeingSaved['addressState']">
            {{'authority.form.addressState.hint' | translate}}
          </mat-hint>
          <mat-hint *ngIf="fieldsBeingSaved['addressState']">{{'basicInfo.fields.saving' | translate}}</mat-hint>
        </mat-form-field>
      </div>

      <div commentable comment-subject-codename="authority-address-country"
        comment-subject-title="{{ 'authority.form.addressCountry.placeholder' | translate }}">
        <mat-form-field>
          <input matInput placeholder="{{ 'authority.form.addressCountry.placeholder' | translate }}"
            name="addressCountry" type="text" [readonly]="!canWrite" formControlName="addressCountry"
            (change)="submit('addressCountry')" required />
          <mat-hint *ngIf="!fieldsBeingSaved['addressCountry']">
            {{'authority.form.addressCountry.hint' | translate}}
          </mat-hint>
          <mat-hint *ngIf="fieldsBeingSaved['addressCountry']">{{'basicInfo.fields.saving' | translate}}</mat-hint>
        </mat-form-field>
      </div>

    </form>
    <div class="buttons">
      <button type="button" mat-raised-button color="primary" routerLink="">{{ 'word.done' | translate }}</button>
    </div>
  </div>
</div>

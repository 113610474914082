import { AfterViewInit, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { zip } from 'rxjs';
import { map } from 'rxjs/operators';
import { riskAnalysisClassificationByValue, RiskAnalysisClassificationColors, RiskAnalysisClassificationLabels } from '../../../../rpa/pa-details/risk-analysis/risk-analysis.consts';

@Component({
  selector: 'dashboard-metrics-pa-risk-distribution-chart',
  templateUrl: './pa-risk-distribution-chart.component.html',
  styleUrls: ['./pa-risk-distribution-chart.component.scss']
})
export class PaRiskDistributionChartComponent implements OnInit, AfterViewInit, OnChanges {
  @Input() pas = [];
  @Input() height = 200;

  riskDist = [];
  riskDistColors = {
    domain: []
  };

  constructor(
    private translate: TranslateService
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.pas && this.pas === []) {
      console.log(changes);
      this.setupChart();
    }
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.setupChart();
  }

  setupChart() {
    this.riskDist = [
      { signifier: 0, name: 'safe', value: 0, },
      { signifier: 0, name: 'mostly-safe', value: 0, },
      { signifier: 0, name: 'warning', value: 0, },
      { signifier: 0, name: 'serious-warning', value: 0, },
      { signifier: 0, name: 'danger', value: 0, },
      { signifier: 0, name: 'catastrophe', value: 0, },
      { signifier: 0, name: 'unknown', value: 0, },
      { signifier: 0, name: 'needs-clarification', value: 0, }
    ];

    if (this.pas) {
      this.pas.forEach(cpa => {
        const classification = riskAnalysisClassificationByValue(cpa.pa_risk_analysis_value);
        const entry = this.riskDist.find(e => e.name === classification);
        entry.signifier = cpa.pa_risk_analysis_value;

        entry.value++;
      });
      this.riskDist.sort((a, b) => a.signifier - b.signifier);
      this.riskDist.forEach(group => {
        this.riskDistColors.domain.push(RiskAnalysisClassificationColors[group.name]);
      });
    }

    zip(
      ...Object.entries(RiskAnalysisClassificationLabels).map(
        entry => this.translate.get(entry[1]).pipe(map(trans => [entry[0], trans]))
      )
    ).subscribe(trans => {
      const dict = trans.reduce((d, entry) => {
        d[entry[0]] = entry[1];
        return d;
      }, {});

      this.riskDist.forEach(entry => {
        entry.name = dict[entry.name];
      });
    });
  }

  /**
   * round helper
   */
  roundValue(value: number): number {
    return Math.round(value);
  }

  integerLabel(value) {
    if (value % 1 === 0) {
      return value;
    }
    return '';
  }
}

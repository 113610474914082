import { AfterViewInit, Component, Input, OnInit } from '@angular/core';

export interface MostUsed {
  label: string;
  count: number;
}

@Component({
  selector: 'dashboard-metrics-pa-data-categories-most-used',
  templateUrl: './pa-data-categories-most-used.component.html',
  styleUrls: ['./pa-data-categories-most-used.component.scss']
})
export class PaDataCategoriesMostUsedComponent implements OnInit, AfterViewInit {
  @Input() pas = [];

  mostUsed: MostUsed[] = [];

  constructor() { }

  ngAfterViewInit(): void {
    this.buildMostUsedDataCategories();
  }

  ngOnInit(): void {
  }

  private buildMostUsedDataCategories() {
    // get the five topmost data categories from pas -> pa_data_categories, which is an array of strings
    const dataCategories = this.pas.map(pa => pa.pa_data_categories_description).filter(d => d).flat();
    const dataCategoriesCount = dataCategories.reduce((acc, curr) => {
      if (acc[curr]) {
        acc[curr]++;
      } else {
        acc[curr] = 1;
      }
      return acc;
    }, {});
    const dataCategoriesCountSorted = Object.keys(dataCategoriesCount).sort((a, b) => dataCategoriesCount[b] - dataCategoriesCount[a]);
    this.mostUsed = dataCategoriesCountSorted.slice(0, 5).map(dataCategory => {
      return {
        label: dataCategory,
        count: dataCategoriesCount[dataCategory]
      };
    });
  }
}

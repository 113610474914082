<h1 mat-dialog-title>{{ 'measures.import.title'| translate }}</h1>

<mat-dialog-content>
  <div *ngIf="supervisorMeasures.length === 0" class="no-measures">
    <p>{{ 'measures.import.no-measures' | translate }}</p>
  </div>
  <div class="select-actions">
    <button mat-stroked-button (click)="selectAll()">{{ 'measures.import.selectAll' | translate }}</button>
    <button mat-stroked-button (click)="unselectAll()">{{ 'measures.import.unselectAll' | translate }}</button>
  </div>

  <div class="measure-list">
    <div class="measure" *ngFor="let measure of supervisorMeasures; let last = last">
      <mat-checkbox [(ngModel)]="measure.selected">{{ measure.title }}</mat-checkbox>
      <mat-divider *ngIf="!last"></mat-divider>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button [mat-dialog-close]="null">{{ 'word.cancel' | translate }}</button>
  <button mat-flat-button [disabled]="selectedMeasures.length === 0" color="primary" (click)="import()">{{ 'measures.import.confirm' | translate }}</button>
</mat-dialog-actions>

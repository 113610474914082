<div class="contents">

  <div class="section todos" [class.readonly]="readonly">
    <h1 *ngIf="!compactList" class="title">{{"todos.title" | translate}}</h1>

    <div *ngIf="!compactList" class="buttons-row">
      <div class="left-side">
        <mat-form-field appearance="legacy">
          <mat-label>{{"todos.search" | translate}}</mat-label>
          <input matInput type="text" [(ngModel)]="criteria.keyword" (input)="keywordChange.next()">
          <mat-icon matSuffix>search</mat-icon>
        </mat-form-field>
        <button mat-stroked-button [class]="{'button-selected' : !criteria.done}"
                class="left-button" (click)="fetchInProgress()">
          {{"todos.inProgress" | translate}}
        </button>
        <button mat-stroked-button [class]="{'button-selected' : criteria.done}" (click)="fetchDone()"
                class="left-button">
          {{"todos.done" | translate}}
        </button>
      </div>
      <div *ngIf="showButtons">
        <button mat-stroked-button (click)="openTodo($event)">
          {{"todos.create" | translate}}
        </button>
      </div>
    </div>

    <ng-container *ngIf="todosList.length > 0">
      <ng-container *ngFor="let todo of todosList">
        <app-todo-card [dataIn]="todo" (todoChange)="fetch.emit(criteria)"
                         (click)="!readonly ? openTodoDetails($event, todo) : {}" [readonly]="readonly"></app-todo-card>
      </ng-container>
    </ng-container>

    <mat-card class="no-todos" *ngIf="todosList.length === 0 && !criteria.keyword">
      <mat-card-content>
        <div class="container">
          <div class="texts-holder">
            <h1 class="title" *ngIf="!criteria.done">
             {{"todos.noTodos.cardTitle" | translate}}
            </h1>

            <div class="task-title">
              <ng-container *ngIf="!criteria.done">
                {{"todos.noTodos.taskTitle" | translate}}
                <mat-icon>assignment_turned_in</mat-icon>
              </ng-container>
              <ng-container *ngIf="criteria.done">
                {{"todos.noTodos.taskTitleDone" | translate}}
              </ng-container>
            </div>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>

</div>

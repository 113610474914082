import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { ChecklistData, ChecklistItem, ChecklistService, ChecklistValue } from 'app/services/checklists/checklists.service';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'checklist',
  templateUrl: './checklist.component.html',
  styleUrls: ['./checklist.component.scss']
})
export class ChecklistComponent implements OnInit, OnChanges {

  @Input() codename: '';
  @Output() progress = new EventEmitter<number>();
  items: ChecklistItem[];
  data: ChecklistData;

  constructor(
    private service: ChecklistService,
  ) { }

  ngOnInit() {
  }

  ngOnChanges(changes) {
    if (changes.codename) {
      this.service.get(this.codename).subscribe(r => {
        this.items = r.items;
        this.data = r.data;
        this.emitProgress();
      });
    }
  }

  value(item: ChecklistItem): ChecklistValue {
    if (item.codename in this.data) {
      return this.data[item.codename];
    } else {
      return 'not-done';
    }
  }

  update(item: ChecklistItem, value: boolean, event) {
    if (event) {
      event.stopPropagation();
    }

    if (value) {
      if (this.value(item) !== 'done') {
        this.set(item, 'done');
      }
    } else {
      if (this.value(item) === 'done') {
        this.set(item, 'not-done');
      }
    }
  }

  skip(item: ChecklistItem, event: Event) {
    if (event) {
      event.stopPropagation();
    }

    if (this.value(item) !== 'skipped') {
      this.set(item, 'skipped');
    }
  }

  set(item: ChecklistItem, value: ChecklistValue) {
    this.data[item.codename] = value;
    this.store();
    this.emitProgress();
  }

  store() {
    this.service.update(this.codename, this.data).subscribe();
  }

  emitProgress() {
    const items = this.items.filter(i => i.type !== 'label');
    if (items.length > 0) {
      this.progress.emit(
        items.filter(i => this.value(i) !== 'not-done').length
        / items.length
      );
    }
  }
}

import { Injectable } from '@angular/core';
import { RequestService } from '../request.service';


export interface DsarSettingsModel {
  controllerId?: number;
  linkToken?: string;
  notifySupervisor: boolean;
  notifyDpo: boolean;
}

@Injectable()
export class DsarSettings {
  api = {
    root: '/dsar/settings',
    renew: '/renew-link-token',
    verify: '/verify-token',
  };

  constructor(
    private requestService: RequestService
  ) {
  }

  get() {
    return this.requestService.get<DsarSettingsModel>({
      uri: this.api.root,
      handlers: {
        success: () => {},
        error: () => {},
      }
    });
  }

  update(settings: DsarSettingsModel) {
    return this.requestService.put({
      uri: this.api.root,
      body: settings,
      handlers: {
        success: () => {},
        error: () => {},
      }
    });
  }

  renewLinkToken() {
    return this.requestService.post<any, {token: string}>({
      uri: this.api.root + this.api.renew,
      body: {},
      handlers: {
        success: () => {},
        error: () => {},
      }
    });
  }

  verifyToken(ctrl: number, token: string) {
    return this.requestService.post<{ ctrl: number; token: string }, {name: string; logo: string; email: string}>({
      uri: this.api.root + this.api.verify,
      body: {
        ctrl,
        token
      },
      handlers: {
        success: () => {},
        error: () => {},
      }
    });
  }
}

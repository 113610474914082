<div class="category" [ngClass]="{'collapsed': collapsed}">
  <div class="indenter" [attr.data-indent]="nestLevel"></div>
  <div class="category-header" [attr.data-indent]="nestLevel">
    <div class="category-title" [attr.data-indent]="nestLevel" [ngClass]="{'collapsed': collapsed}">
      <h2>{{ category.categoryDisplay }}</h2>
      <mat-icon (click)="toggleCollapse()">keyboard_arrow_up</mat-icon>
    </div>

    <ng-container *ngIf="!collapsed">
      <div *ngIf="hasSubcategory" class="subcategories">
        <app-audit-form-category
          *ngFor="let subcategory of category.content"
          path="{{ computedPath }}"
          [audit]="audit"
          [category]="subcategory"
          [readonly]="readonly"
          [auditConfiguration]="auditConfiguration"
          nestLevel="{{ childNestLevel }}"
        ></app-audit-form-category>
      </div>

      <div *ngIf="!hasSubcategory" class="questions">
        <app-audit-form-question
          *ngFor="let question of category.content"
          [audit]="audit"
          [question]="question"
          [auditConfiguration]="auditConfiguration"
          [readonly]="readonly"
          path="{{ computedPath }}"
          nestLevel="{{ childNestLevel }}"
        ></app-audit-form-question>
      </div>
    </ng-container>
  </div>

  <hr *ngIf="nestLevel == 1 || collapsed"/>
</div>

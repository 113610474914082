import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';

@Component({
  selector: 'app-dsar-templates',
  templateUrl: './templates.component.html',
  styleUrls: ['./templates.component.scss']
})
export class DsarTemplatesComponent implements OnInit, OnDestroy {

  navigationSubscription;
  @ViewChild('stepper') stepper;

  constructor(private router: Router) {
    this.navigationSubscription = this.router.events.subscribe((e: any) => {
      // we force the stepper to restart every time the router goes to this component
      if (e instanceof NavigationEnd) {
        this.restartStepper();
      }
    });
  }

  restartStepper() {
    this.stepper.switch('welcome');
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    if (this.navigationSubscription) {
      this.navigationSubscription.unsubscribe();
    }
  }
}

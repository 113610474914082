import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Audit, AuditConfiguration, Question } from '../../../../../../../services/audits/audits.service';
import { DocumentGroupContainerDialogComponent } from '../../../../../../documents/group-container/dialog/dialog.component';

@Component({
  selector: 'app-audit-form-extension-document-upload',
  templateUrl: './document-upload.component.html',
  styleUrls: ['./document-upload.component.scss']
})
export class AuditDocumentUploadComponent implements OnInit {
  @Input() audit: Audit = null;
  @Input() question: Question = null;
  @Input() readonly = false;

  @Input() auditConfiguration: AuditConfiguration = null;

  @Input() path = '';

  constructor(
    private dialog: MatDialog,
    private translate: TranslateService
  ) { }

  get groupName() {
    return `${this.auditConfiguration.auditKind}:${this.audit.id}:${this.path}`;
  }

  addDocuments() {
    const title = this.translate.instant('audit.form.question.extension.documentUpload.dialogTitle');
    this.dialog.open(DocumentGroupContainerDialogComponent, { data: { title, groupName: this.groupName, exact: true }, minWidth: '600px' });
  }

  ngOnInit(): void {
  }

}

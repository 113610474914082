<div class="contents" [class.no-documents]="!hasDocumentAccess || !hasSupervisor" [class.standalone]="isStandalone">
  <div *ngIf="controller" class="heading">
    <h1 class="title">{{ 'dashboard.title' | translate }}</h1>
    <h2 class="title">{{ controller.controllerName }}</h2>
  </div>

  <div class="main-grid">
    <div class="grid-item metrics">
      <h3>{{ 'dashboard.item.metrics' | translate }}</h3>
      <dashboard-metrics></dashboard-metrics>
    </div>
    <div class="grid-item todos">
      <h3>{{ 'dashboard.item.todos' | translate }}</h3>
      <dashboard-todo-list></dashboard-todo-list>
      <h3>{{ 'dashboard.item.measures' | translate }}</h3>
      <dashboard-measures></dashboard-measures>
    </div>
    <div *ngIf="hasSupervisor && hasDocumentAccess" class="grid-item documents">
      <h3>{{ 'dashboard.item.documents' | translate }}</h3>
      <app-shared-documents [showTitle]="false"></app-shared-documents>
    </div>
  </div>
</div>

import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';


export type TrafficLightColor = 'unknown' | 'needs-clarification' | 'safe' | 'warning' | 'danger';

export interface TrafficLightMapping {
  [value: number]: {
    color: TrafficLightColor;
    label: string;
    order?: number;
  };
}


@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'traffic-light-selector',
  templateUrl: './traffic-light-selector.component.html',
  styleUrls: ['./traffic-light-selector.component.scss']
})
export class TrafficLightSelectorComponent implements OnInit, OnChanges {

  @Input() mapping: TrafficLightMapping;
  @Input() value: number | null;
  @Output() valueChange = new EventEmitter<number>();
  @Input() readonly = false;

  public entries = {};

  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges(changes) {
    if (changes.mapping) {
      this.entries = Object.entries(this.mapping)
        .map(([value, rest]) => ({...rest, value}))
        .sort((a, b) => b.order - a.order);
    }
  }

  select(newValue: number) {
    if (this.readonly) {
      return;
    }
    this.valueChange.emit(newValue);
  }
}

<div class="comment-box-container" #container>
  <ng-content></ng-content>

  <!-- these two are picked based on a logic, so are declared
      earlier so that the translation module picks them up. -->
  <!-- {{'commentBox.tooltip.open' | translate}} -->
  <!-- {{'commentBox.tooltip.close' | translate}} -->

  <div *ngIf='!commentButton' class="comment-box {{position}}"
    [hidden]="commentable === 'false'"
    [ngClass]="{open: (subject?.open) ? subject?.comments.length > 0 : subject?.open}"
    (click)="openDialog($event)"
    matTooltip="{{((!subject?.open)?('commentBox.tooltip.close'):('commentBox.tooltip.open')) | translate}}"
    >
    <div class="inner">
      <mat-icon color="background">comment</mat-icon>
    </div>
  </div>

  <!-- to allow a comment button to be added in a component -->
  <button *ngIf='commentButton' mat-stroked-button (click)='openDialog($event)'>{{'todos.reply'|translate}}</button>
</div>

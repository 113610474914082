import { Component, OnChanges, OnDestroy, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { Subscription, zip } from 'rxjs';
import {Todo} from '../todo.interface';
import { ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { TodoService } from '../../../services/todos/todo.service';
import { BusService } from '../../../services/bus.service';
import { TodoDialogComponent } from '../todo-dialog/todo-dialog.component';
import { SubjectTodoListComponent } from '../subject-todo-list/subject-todo-list.component';
import { TodoDetailsDialogComponent } from '../todo-details-dialog/todo-details-dialog.component';
import {AccessLevelService, userRoles} from "../../../services/user/access-level.service";

/**
 * This was taken from TUM
 * Maybe needs another look to refactor and just remove whats not necessary here
 *
 * This is not to say whether the user can or not create todo.
 *  This refers to fields allowing to create todos related to them or not.
 */

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'todoable',
  templateUrl: './todoable.component.html',
  styleUrls: ['./todoable.component.scss']
})
export class CanCreateTodoComponent implements OnInit, OnChanges, OnDestroy {

  @Input() position: 'right' | 'bottom' | 'left' | 'top' | 'documents-fluid' | 'documents' = 'right' ;
  @Input() subject: string;

  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('suggested-title') suggestedTitle: string;
  // eslint-disable-next-line @angular-eslint/no-input-rename
  // @Input('suggested-type') suggestedType: TodoType;
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('access-token') accessToken: string;
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('access-token-key') accessTokenKey = 'access-token';

  @ViewChild('holder', { static: false }) holder: ElementRef;

  expandTodo = true;
  justCreated = false;
  todo: Todo;
  queryParamsSub: Subscription;
  showCreatedSub: Subscription;
  initialScrollConducted = false;

  todosOnSubject: Todo[] = [];
  assignedTodosOnSubject: Todo[] = [];

  updateTodo: any;

  hidden = false;

  constructor(
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private todoService: TodoService,
    private bus: BusService,
    private accessLevel: AccessLevelService
  ) { }

  ngOnInit() {
    zip(
      this.todoService.fetchTodos({ done: false, subject: this.subject }),
      this.todoService.getAssigned({ done: false, subject: this.subject })
    ).subscribe(res => {
      [this.todosOnSubject, this.assignedTodosOnSubject] = res as any;
    });

    this.updateTodo = (todo: Todo) => {
      if (todo && this.todo && todo.id === this.todo.id) {
        this.todo = Object.assign({}, todo);
        this.expandTodo = !this.todo.done;
      }
    };

    // dashboard users should not be able to create todos
    if (this.accessLevel.checkRole(userRoles.rpaContributor) < 0) {
      this.hidden = true;
    }

    this.bus.subscribe('todo.update.event', this.updateTodo);
  }

  ngOnDestroy() {
    this.bus.unsubscribe('updateTodo.event', this.updateTodo);
  }

  ngOnChanges() {
    if (this.queryParamsSub) {
      this.queryParamsSub.unsubscribe();
    }
    this.queryParamsSub = this.route.queryParams.subscribe(params => {
      if (params.todo && params.subject && params.subject === this.subject) {
        this.todoService.getSingle(params.todo).pipe(this.todoService.polish).subscribe(todo => {
          this.todo = todo[0];
          if (!this.initialScrollConducted) {
            setTimeout(() => {
              window.scrollTo({
                top: this.holder.nativeElement.getBoundingClientRect().y + window.pageYOffset - 200,
                behavior: 'smooth'
              });
              this.initialScrollConducted = true;
            }, 1000);
          }
        });
      }
    });
  }

  showTodoDetails() {
    if (this.todo) {
      if (!this.expandTodo) {
        this.expandTodo = true;
        return;
      }

      this.dialog.open(TodoDetailsDialogComponent, {
        width: '700px',
        data: {
          todo: this.todo,
          canGoToTask: false,
          cleanUpURL: false,
        }
      });
    }
  }

  openTodoDialog(event?: any) {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }

    this.showCreatedSub = this.dialog.open(TodoDialogComponent, {
      width: '700px',
      data: {
        fromField: true,
        suggestedTitle: this.suggestedTitle,
        // suggestedType: this.suggestedType,
        subject: this.subject,
        link: true,
        accessToken: this.accessToken,
        accessTokenKey: this.accessTokenKey,
      }
    }).afterClosed().subscribe(created => {
      this.justCreated = created || this.justCreated;
      if (this.justCreated) {
        this.openList();
      }
    });
  }

  closeTodo(event?) {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }

    this.expandTodo = false;
  }

  openList(event?: any) {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }

    this.dialog.open(SubjectTodoListComponent, {
      width: '768px',
      data: this.subject,
    });
  }

  get notify() {
    if (this.justCreated) {
      return true;
    } else if (!this.todo) {
      return this.todosOnSubject.length > 0 || this.assignedTodosOnSubject.length > 0;
    } else {
      this.todosOnSubject.filter(t => t.id !== this.todo.id);

      if (this.assignedTodosOnSubject.length > 0) {
        this.assignedTodosOnSubject.filter(t => t.id !== this.todo.id);
      }

      return this.todosOnSubject.length > 0 || this.assignedTodosOnSubject.length > 0;
    }
  }
}

import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SupervisorProfileService } from 'app/services/supervision/supervisor-profile.service';

@Component({
  selector: 'app-confirm-supervisor-removal-dialog',
  templateUrl: './confirm-supervisor-removal-dialog.component.html',
  styleUrls: ['./confirm-supervisor-removal-dialog.component.scss']
})
export class ConfirmSupervisorRemovalDialogComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) readonly user,
    private dialogRef: MatDialogRef<ConfirmSupervisorRemovalDialogComponent>,
    private service: SupervisorProfileService,
  ) { }

  ngOnInit(): void {
  }

  remove() {
    this.service.removeUser(this.user).subscribe(() => this.dialogRef.close(true));
  }
}

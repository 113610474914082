import {Inject, Injectable, Renderer2, RendererFactory2} from "@angular/core";
import {DOCUMENT} from "@angular/common";
import {AppConfig} from "../../app.config";

@Injectable({
  providedIn: 'root'
})
export class PlausibleService {
  mounted = false;

  private renderer: Renderer2;

  constructor(
    rendererFactory: RendererFactory2,
    @Inject(DOCUMENT) private document
  ) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  mount() {
    if (this.mounted || !AppConfig.enablePlausible) return;

    const script = this.renderer.createElement('script');
    script.type = 'text/javascript';
    script.defer = true;
    script.dataset.domain = 'app.ecomply.io';
    script.src = 'https://analytics.ecomply.io/js/plausible.js';
    this.renderer.appendChild(this.document.head, script);
    this.mounted = true;
  }
}

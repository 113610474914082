import {ModuleWithProviders, NgModule} from '@angular/core';
import { CommonModule } from '@angular/common';
import {PlausibleService} from "./plausible.service";


@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ]
})
export class PlausibleModule {
  static forRoot(): ModuleWithProviders<PlausibleModule> {
    return {
      ngModule: PlausibleModule,
      providers: [
        PlausibleService,
      ]
    };
  }
}

import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TwoFactorGuard implements CanActivate {
  constructor(private router: Router) {

  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    // EC-3238: simple trick here: if the user has been locked out, prompt two factor setup before they can do anything
    if (localStorage.getItem('ec:2fa:needs-setup')) {
      this.router.navigate(['account/two-factor/enable'], { queryParams: { returnUrl: state.url, setupRequired: true } });
      return false;
    }

    return true;
  }

}

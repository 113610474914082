import { Injectable } from '@angular/core';

import { BusService } from '../bus.service';
import { EventsService } from '../events.service';
import { RequestService } from '../request.service';


export interface TomsCategory {
  id: number;
  title: string;
  description: string;
  icon: string;
  progress: number;
}

export interface TomsEntry {
  id: number;

  title: string;
  tag: string;
  requiresExplanation: boolean;
}

export interface TomsData {
  applicable: boolean;
  explanation: string;
  status: string;
}

export interface TomsEntryAndData
  extends TomsEntry, TomsData {}

@Injectable()
export class TomsService {

  api = {
    root: '/toms',
    categories: {
      root: '/categories',
      markAsDone: '/mark-as-done',
    },
    controller: {
      root: '/controller',
      entriesByCategory: '/by-category',
      update: '',
    }
  };

  constructor(
    private request: RequestService,
    private bus: BusService,
    private events: EventsService,
  ) {
    this.subscribe();
  }

  category(catId) {
    return this.request.get<TomsCategory>({
      uri: this.api.root + this.api.categories.root + `/${catId}`,
      handlers: {
        success: data => this.bus.publish(this.events.received.data.toms.category.success, data),
        error: error => this.bus.publish(this.events.received.data.toms.category.failure, error),
      }
    });
  }

  categories() {
    return this.request.get<TomsCategory[]>({
      uri: this.api.root + this.api.categories.root,
      handlers: {
        success: data => this.bus.publish(this.events.received.data.toms.categories.success, data),
        error: error => this.bus.publish(this.events.received.data.toms.categories.failure, error),
      }
    });
  }

  controllerEntries() {
    return this.request.get<any>({
      uri: this.api.root + this.api.controller.root + '/all',
      handlers: {
        success: data => {},
        error  : error => {},
      }
    });
  }

  categoryEntries(categoryId) {
    return this.request.get<TomsEntryAndData[]>({
      uri: this.api.root + this.api.controller.root + this.api.controller.entriesByCategory + `/${categoryId}`,
      handlers: {
        success: data => this.bus.publish(this.events.received.data.toms.categoryEntries.success, data),
        error: error => this.bus.publish(this.events.received.data.toms.categoryEntries.failure, error),
      }
    });
  }

  singleEntry(entryId) {
    return this.request.get<TomsEntryAndData>({
      uri: this.api.root + this.api.controller.root + `/${entryId}`,
      handlers: {
        success: data => this.bus.publish(this.events.received.data.toms.singleEntry.success, data),
        error: error => this.bus.publish(this.events.received.data.toms.singleEntry.failure, error),
      }
    });
  }

  updateEntry(entry) {
    return this.request.post<TomsData, any>({
      uri: this.api.root + this.api.controller.root + this.api.controller.update + `/${entry.id}`,
      body: {
        applicable: entry.applicable,
        explanation: entry.explanation,
        status: entry.status,
      },
      handlers: {
        success: () => this.bus.publish(this.events.received.action.toms.updateEntry.success, entry),
        error: error => this.bus.publish(this.events.received.action.toms.updateEntry.failure, {
          entry,
          error,
        }),
      }
    });
  }

  subscribe() {
    this.bus.subscribe(this.events.requested.data.toms.category, this.category.bind(this));
    this.bus.subscribe(this.events.requested.data.toms.categories, this.categories.bind(this));
    this.bus.subscribe(this.events.requested.data.toms.categoryEntries, this.categoryEntries.bind(this));
    this.bus.subscribe(this.events.requested.data.toms.singleEntry, this.singleEntry.bind(this));
    this.bus.subscribe(this.events.requested.action.toms.updateEntry, this.updateEntry.bind(this));
  }
}

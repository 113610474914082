<div *ngIf="vendorAudit && vendor" class="contents">
  <a routerLink="/vendors" class="back">
    <mat-icon>
      arrow_back
    </mat-icon>
    {{ 'vendor.audit.backToList' | translate }}
  </a>

  <br><br>
  <h1 class="title">{{ "vendor.audit.form.title" | translate }} {{ vendor.name }}</h1>
  <div class="header">
    <p class="subtitle">{{ "vendor.audit.form.subtitle" | translate }}</p>

    <!-- {{ 'audit.list.item.status.open' | translate }} -->

    <div *ngIf="vendorAudit" class="metadata">
      <p>
        {{ "audit.list.item.last-changed" | translate }}
        {{ vendorAudit.updatedAt | localizedDate }}
      </p>
    </div>
  </div>

  <br><br>

  <app-document-group-container groupName="vendor-audit-{{ vendorAudit.id }}" exact="true"></app-document-group-container>

  <br><br>

  <div class="questions" *ngIf="vendorAudit && questionnaire">
    <app-audit-form-category
      *ngFor="let category of questionnaire.questions"
      [audit]="vendorAudit"
      [category]="category"
      [auditConfiguration]="auditConfiguration"
    ></app-audit-form-category>
  </div>
</div>

<div *ngIf="!vendorAudit" class="contents">
  <div class="centered-spinner brand">
    <mat-spinner></mat-spinner>
  </div>
</div>


<br><br><br><br>

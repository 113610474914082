import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';


import { BusService } from '../../../services/bus.service';
import { EventsService } from '../../../services/events.service';

import { StripeService, Elements, Element as StripeElement } from 'ngx-stripe';
import { UpdatePaymentDetailsService } from '../../../services/payment/update-payment-details.service';

@Component({
  selector: 'app-update-payment-details',
  templateUrl: './update-payment-details.component.html',
  styleUrls: ['./update-payment-details.component.scss']
})
export class UpdatePaymentDetailsComponent implements OnInit, OnDestroy {
  elements: Elements;
  card: StripeElement;

  updateCardForm: FormGroup;

  cardError: string;
  isLoading = false;

  intent: 'update' | 're-enter' = 'update';
  next: string = undefined;

  constructor(
    private bus: BusService,
    private events: EventsService,
    private formBuilder: FormBuilder,
    private stripeService: StripeService,
    private router: Router,
    private route: ActivatedRoute,
    private updateInfoService: UpdatePaymentDetailsService
  ) {
    route.queryParams.subscribe(params => {
      if (params['intent']) this.intent = params['intent'];
      if (params['next']) this.next = params['next'];
    });
  }

  private subscribe() {
    this.bus.subscribe(this.events.received.payment.details.updateCard.failure, this.updateCardFailure, this);
    this.bus.subscribe(this.events.received.payment.details.updateCard.success, this.updateCardSuccess, this);
  }

  // Data Actions

  private updateCardFailure(error) {
    this.isLoading = false;
    console.error(error);
    this.cardError = error.error.message;
  }

  private updateCardSuccess(res) {
    this.isLoading = false;
    localStorage.removeItem('ongoing-stripe-confirmation');

    if (res.status && res.status === 'requires_action') {
      this.router.navigate(['/payment/finalize']);
    }
    else if (this.next) {
      this.router.navigate([this.next]);
    }
    else {
      this.router.navigate(['/payment/billing'],  { queryParams: { cardUpdate: 'success' } });
    }
  }

  // Stripe Element Functions

  public updateCard(event: any) {
    const name = this.updateCardForm.get('name').value;
    this.isLoading = true;
    this.stripeService
      .getInstance()
      .createToken(this.card, { name })
      .then(result => {
        if (result.token) {
          const requestData = {
            stripeToken: result.token.id,
          };

          this.bus.publish(this.events.requested.payment.details.updateCard, requestData);
        } else if (result.error) {
          // Error creating the token
          this.isLoading = false;
          console.log(result.error.message);
          this.cardError = result.error.message;
        }
      })
      .catch(error => {
        // Error creating the token
        this.isLoading = false;
        console.log(error.message);
        this.cardError = error.message;
      });
  }

  private initializeStripeElement() {
    this.stripeService.elements()
      .subscribe(elements => {
        this.elements = elements;
        // Only mount the element the first time
        if (!this.card) {
          this.card = this.elements.create('card', {
            style: {
              base: {
                iconColor: '#62bfba',
                color: 'rgba(0, 0, 0, 0.87)',
                lineHeight: '40px',
                fontWeight: 300,
                fontFamily: '"Nunito Sans", "Helvetica Neue", Helvetica, sans-serif',
                fontSize: '18px',
                '::placeholder': {
                  color: '#62bfba'
                }
              }
            }
          });
          this.card.mount('#card-element');
        }
      });
  }

  private initializeForm() {
    this.updateCardForm = this.formBuilder.group({
      name: ['', [Validators.required]]
    });

    this.initializeStripeElement();
  }

  ngOnInit() {
    this.subscribe();
    this.initializeForm();
  }

  ngOnDestroy() {
    // this.unsubscribe();
  }
}

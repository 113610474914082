import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { BusService } from '../../../../services/bus.service';
import { EventsService } from '../../../../services/events.service';

import {
  InvitationResultDialogComponent
} from 'app/components/user-management/invitation-result-dialog/invitation-result-dialog.component';
import { Router } from '../../../../../../node_modules/@angular/router';
import { ClientManagementService } from '../../../../services/supervision/client-management.service';

@Component({
  selector: 'app-add-client-controller',
  templateUrl: './add-client-controller.component.html',
  styleUrls: ['./add-client-controller.component.scss']
})
export class AddClientControllerComponent implements OnInit, OnDestroy {

  controllerName = new FormControl('', [Validators.required]);
  clientId = new FormControl('');
  group = new FormControl();

  constructor(
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<AddClientControllerComponent>,
    private bus: BusService,
    private events: EventsService,
    private router: Router,
    private clientService: ClientManagementService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    if (this.data.currentGroup && this.data.currentGroup.id) {
      this.group.setValue(this.data.currentGroup.id);
    }
  }

  ngOnInit() {
    this.subscribe();
  }

  ngOnDestroy() {
    this.unsubscribe();
  }

  create() {
    this.controllerName.markAsTouched();
    if (this.controllerName.valid) {
      this.bus.publish(this.events.requested.action.supervision.clients.add, {
        controllerName: this.controllerName.value,
        clientId: this.clientId.value,
        group: this.group.value,
      });
    }
  }

  created() {
    this.dialogRef.close(true);
  }

  inviteFailed(error) {
    this.dialog.open(InvitationResultDialogComponent, {
      width: '350px',
      maxHeight: '90vh',
      data: {
        clientError: error.error,
        type: error.type
      }
    });
  }

  subscribe() {
    this.bus.subscribe(this.events.received.action.supervision.clients.add.success, this.created, this);
    this.bus.subscribe(this.events.received.action.supervision.clients.add.failure, this.inviteFailed, this);
  }

  unsubscribe() {
    this.bus.unsubscribe(this.events.received.action.supervision.clients.add.success, this.created);
    this.bus.unsubscribe(this.events.received.action.supervision.clients.add.failure, this.inviteFailed);
  }
}

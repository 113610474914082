<mat-dialog-content>
  <h1 mat-dialog-title>
    {{'supervision.clientList.addClient.warning.title' | translate}}
  </h1>

  <div>
    <div>{{'supervision.clientList.addClient.warning.hint1' | translate : {clients: dialogData.current, limit: dialogData.limit} }}</div>
    <div [innerHtml]="'supervision.clientList.addClient.warning.hint2' | translate"></div>
  </div>

  <div class="buttons">
    <button mat-flat-button (click)="cancel()">{{'word.cancel' | translate}}</button>
    <button mat-flat-button color="primary" (click)="confirm()">{{'word.continue' | translate}}</button>
  </div>
</mat-dialog-content>

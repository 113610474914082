<div class="forgot-password">
  <h1 class="title">{{ 'forgotpw.question' | translate }}</h1>
  <br/>
  <mat-card>
    <img mat-card-image src="assets/forgot-password.svg"/>
    <mat-card-content *ngIf="state=='initial'">
      {{ 'forgotpw.noworries' | translate }}
    </mat-card-content>
    <mat-card-content *ngIf="state=='sent'">
      <p innerHTML="{{ 'forgotpw.sendemail' | translate }}"></p> <!-- contains styling -->
    </mat-card-content>
    <form name="form" [formGroup]="form.formGroup" novalidate>
      <div class="form-group">
        <mat-form-field class="form-field">
          <input matInput placeholder="{{ 'placeholder.email' | translate }}" name="email" formControlName="email" required autofocus/>
          <mat-hint *ngIf="state=='sent'">{{ 'forgotpw.emailsent' | translate }}</mat-hint>
          <mat-error *ngIf="form.getControl('email').hasError('email') && !form.getControl('email').hasError('required')">{{ 'helper.pleaseEnterValidEmail' | translate }}</mat-error>
          <mat-error *ngIf="form.getControl('email').hasError('required')">{{ 'forgotpw.emailrequ' | translate}}</mat-error>
        </mat-form-field>
      </div>
      <br *ngIf="state=='sent'">
      <mat-card-actions align="end">
        <button *ngIf="state=='initial' && !sending"
                mat-raised-button
                type="button"
                color="primary"
                (click)="submit()">{{ 'forgotpw.emailresetlink' | translate }}</button>
          <button *ngIf="sending"
                  mat-raised-button
                  type="button"
                  disabled>{{'forgotpw.sending' | translate }}</button>
          <button *ngIf="state=='sent' && !sending"
                  mat-raised-button
                  type="button"
                  color="primary"
                  (click)="submit()">{{ 'forgotpw.resend' | translate }}</button>
      </mat-card-actions>
    </form>
  </mat-card>
</div>

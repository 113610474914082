import { Component, Inject, LOCALE_ID, OnInit } from '@angular/core';
import { ControllerService } from '../../../services/controller/controller.service';
import { FormControl, Validators } from '@angular/forms';
import { TodoService } from '../../../services/todos/todo.service';
import { UserService } from '../../../services/user/user.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Todo } from '../todo.interface';
import { BusService } from '../../../services/bus.service';
import { ActivatedRoute, Router, UrlSerializer } from '@angular/router';
import { Assignee } from '../assignee-search/assignee-search.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-todo-dialog',
  templateUrl: './todo-dialog.component.html',
  styleUrls: ['./todo-dialog.component.scss']
})
export class TodoDialogComponent implements OnInit {

  title = new FormControl('', [Validators.required]);
  description = new FormControl('', []);
  assignee = new FormControl('', [Validators.required]);
  dueDate = new FormControl('', []);

  controllerData = {
    id: 0,
    name: 'name'
  };
  currentUser = {
    id: 0
  };

  assigneeList: Assignee[] = [];

  todo: Todo;
  currentTodoId = 0;
  isEditing = false;
  subject = null;

  overrideLink = null;

  constructor(
    private user: UserService,
    private controller: ControllerService,
    private todoService: TodoService,
    private dialogRef: MatDialogRef<TodoDialogComponent>,
    private bus: BusService,
    private route: ActivatedRoute, // ?
    private router: Router,
    private serializer: UrlSerializer,
    private translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) private data: any,
  ) {
    this.setExistingData();
  }

  ngOnInit(): void {
    this.controller.requestGet()
      .subscribe(c => {
        this.controllerData.name = c.controllerName;
        this.controllerData.id = c._id;
      } );

    this.user.userInfo()
      .subscribe(cu => {
        this.currentUser.id = cu.Id;
      });

    this.todoService.getFilteredAllowedUser()
      .subscribe((users) => {
        this.assigneeList = users;

        if (!this.data) {
          const selfUser = this.assigneeList.find(u => u.id === this.currentUser.id);
          if (selfUser) {
            this.assignee.setValue(selfUser);
          }
        }
      });

    this.generateLink();
  }

  /**
   * Set the data for when editing an todo
   */
  setExistingData() {
    if(this.data?.fromField) {
      this.title.setValue(this.data.suggestedTitle);
      this.description.setValue(this.data.suggestedDescription);
      this.subject = this.data.subject;
      this.overrideLink = this.data.overrideLink;
    } else if (this.data) {
      this.title.setValue(this.todoTitle(this.data.title || this.data.todo?.title));
      this.description.setValue(this.todoDescription(this.data.description || this.data.todo?.description));
      this.assignee.setValue(this.data.assignee || this.data.todo?.assignee);

      if (this.data.dueDate) {
        this.dueDate.setValue(new Date(this.data.dueDate));
      } else if (this.data.todo && this.data.todo.dueDate) {
        this.dueDate.setValue(new Date(this.data.todo.dueDate));
      }

      this.currentTodoId = this.data.id || this.data.todo?.id;
      this.isEditing = true;
    }
  }

  createTodo() {
    const todoBody: Todo = {
      assignee: { avatar: '', email: '', firstName: '', lastName: '' },
      issuer: { avatar: '', email: '', firstName: '', lastName: ''},
      done: false,
      link: null,
      parent: 0,
      response: '',
      subject: this.subject,
      type: '',
      id: null,
      title: this.title.value,
      description: this.description.value,
      issuerId: this.currentUser.id,
      // I know. This next line is weird :(
      assigneeId: this.assignee.value.id,
      controllerId: this.controllerData.id,
      dueDate: this.dueDate.value ? new Date(this.dueDate.value).toDateString() : null,
      createdAt: new Date(),
      lastChange: new Date(),
    };

    // again, I know it looks bad :(
    if (this.title.valid && this.description.valid && todoBody.assigneeId !== null && this.dueDate.valid) {
      if (!this.isEditing) {
        // generate a link if the user is creating a todo relative to a pa
        if (!this.router.url.includes('/todos')) {
          todoBody.link = this.generateLink();
        }
        if (this.overrideLink) {
          todoBody.link = this.overrideLink;
        }
        this.todoService.createTodo(todoBody);
      } else {
        todoBody.id = this.currentTodoId;
        this.todoService.updateTodo(todoBody, this.currentTodoId);
      }

      this.close();
    } else {
      // show user that validation failed
      this.title.markAsTouched();
      this.description.markAsTouched();
      this.assignee.markAsTouched();
      this.dueDate.markAsTouched();
    }
  }

  /**
   * Delete Todo
   */
  delete(event, id) {
    if(event) {
      event.preventDefault();
      event.stopPropagation();
    }

    this.todoService.deleteTodo(id);
    this.close();
  }

  close() {
    this.dialogRef.close(true);
  }

  generateLink(): string {
    const root = this.router.url.split('?')[0];
    const params = Object.assign({}, {
      todo: undefined,
      subject: undefined
    });
    return this.serializer.serialize(this.router.createUrlTree([root], {queryParams: params}));
  }

  todoTitle(d: string) {
    const title = this.translate.instant(d);
    return title ?? this.data.todo?.title;
  }

  todoDescription(d: string) {
    let description = null;
    if (this.data.todo?.description) {
      description = this.translate.instant(d, {
        name: this.data.subject
      });
    }
    return description ?? d;
  }

}

<div class="audit-list-container contents">
    <h1 class="title">{{ 'audits.list.title' | translate }}</h1>
    <p class="subtitle">{{ 'audits.list.subtitle' | translate }}</p>

    <div class="audit-items">
      <div *ngFor="let type of auditTypes; let last = last" class="audit-type">
        <div class="header">
          <div class="left">
            <div class="name">{{ type.name }}</div>
            <div class="description">{{ type.description }}</div>
          </div>
          <div class="actions">
            <button mat-icon-button (click)="createNewAudit(type)" matTooltip="{{ 'audits.list.add.tooltip' | translate }}">
              <mat-icon>add_circle_outline</mat-icon>
            </button>
          </div>
        </div>

        <div class="audits">
          <a *ngFor="let audit of auditsForType(type)" >
            <div class="audit-item audit-instance">
              <a routerLink="/audit/{{ audit.id }}">
                <p *ngIf="audit.title.length > 0">{{ audit.title }}</p>
                <p *ngIf="audit.title.length == 0">{{ 'audits.list.item.no-title' | translate }} {{ audit.createdAt | localizedDate }}</p>

                <div class="metadata">
                  <p>{{ 'audit.list.item.last-changed' | translate }} {{ audit.updatedAt | localizedDate }}</p>
                </div>
              </a>
              <div class="actions">
                <button mat-icon-button matTooltip="{{ 'audits.list.item.actionTooltip' | translate }}" [matMenuTriggerFor]="menu" [matMenuTriggerData]="{ audit: audit, type: type }">
                  <mat-icon>more_vert</mat-icon>
                </button>
              </div>
            </div>
          </a>
        </div>

        <div *ngIf="!last" class="divider"></div>
      </div>
    </div>
</div>

<!-- Audit options menu -->
<mat-menu #menu="matMenu">
  <ng-template matMenuContent let-audit="audit" let-type="type">
    <a mat-menu-item href="{{ downloadLink(audit) }}" target="blank">{{ "audits.form.download" | translate }}</a>
    <feature-access-local *ngIf="isSupervisor" featureName="shared-documents">
      <button mat-menu-item (click)="shareReport(audit)">{{ 'audits.list.item.shareWithClient' | translate }}</button>
    </feature-access-local>
    <button mat-menu-item *ngIf="isSupervisor" (click)="delegateAudit(audit)">{{ 'audits.list.item.delegate' | translate }}</button>
    <button mat-menu-item (click)="duplicateAudit(audit)">{{ 'audits.list.item.duplicate' | translate }}</button>
    <button mat-menu-item (click)="deleteAudit(audit)">{{ 'audits.list.item.delete.action' | translate }}</button>
  </ng-template>
</mat-menu>

<div class="contents">
    <stepper #stepper>
      <stepper-step initial>
        <mat-card (click)="stepper.next()">
          <img mat-card-image src="assets/welcome/dpms.svg" width="100%"/>
          <p>
            {{ 'welcome.workflow.slide1.message' | translate }}
          </p>
          <mat-card-actions>
            <button mat-button
                    color="primary">
              {{ 'welcome.workflow.slide1.action' | translate }}
            </button>
          </mat-card-actions>
        </mat-card>
      </stepper-step>
      <stepper-step (activation)="progress(0.2)">
        <mat-card (click)="stepper.next()">
          <img mat-card-image src="assets/welcome/DPMSCool.svg" width="100%"/>
          <p>
            {{'welcome.workflow.slide2.message' | translate }}
          </p>
          <mat-card-actions>
            <button mat-button
                    color="primary">
              {{'welcome.workflow.slide2.action' | translate}}
            </button>
          </mat-card-actions>
        </mat-card>
      </stepper-step>
      <stepper-step (activation)="progress(1)">
        <mat-card>
          <img mat-card-image src="assets/welcome/Customers.svg" width="100%"/>
          <p>
            {{'welcome.workflow.slide3.message' | translate}}
          </p>
          <mat-card-actions>
            <button mat-raised-button
                    routerLink=""
                    color="primary">
              {{'welcome.workflow.slide3.action' | translate}}
            </button>
          </mat-card-actions>
        </mat-card>
      </stepper-step>
    </stepper>
  </div>
  
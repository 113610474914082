import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpParams} from '@angular/common/http';
import {BusService} from '../bus.service';
import {
  IGetOptions,
  RequestService
} from '../request.service';
import {EventsService} from '../events.service';
import { AppConfig } from '../../app.config';
import { saveAs } from 'file-saver';
import { getToken } from '../../util/token';
import { downloadFromGenerator } from '../../util/documentGenerator';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { PAStatus } from '../rpa/rpa.service';
import { ShareDocumentDialogComponent } from '../../components/documents/shared/share-dialog/share-dialog.component';


interface IGetResponse {

}

/*
  An interlayer service for dashboard get request between request service and dashboard component
*/

@Injectable()
export class ReportGetService { // Status: Aug 2018 - NOT USED!!

  private options: IGetOptions<IGetResponse>;
  private router: string;

  constructor(
    private bus: BusService,
    private events: EventsService,
    private requestService: RequestService,
    private httpClient: HttpClient,
    private translate: TranslateService,
    private dialog: MatDialog
  ) {
    this.options = {
      uri: '/report',
      handlers: {
        success: this.success.bind(this),
        error: this.error.bind(this)
      }
    };
    this.subscribe();
  }

  public success(data: IGetResponse): void {
    this.bus.publish(this.events.received.data.report.success, data);
  }

  public error(httpErrorResponse: HttpErrorResponse): void {
    this.bus.publish(this.events.received.data.report.failure, httpErrorResponse);
  }

  public openPasReport(processingOnBehalf: boolean, status: PAStatus): void {
    // Directly call a backend route which will result in a PDF displayed in the browser. The user then has
    // to manually download that. TODO: Offer a document download directly.
    const paramsString = new HttpParams()
      .append('pob', processingOnBehalf.toString())
      .append('status', status)
      .toString();

    const url =
      `${AppConfig.apiReport}?${paramsString}&token=${getToken()}&lang=${localStorage.getItem('lang')}`;
    window.open(url, '_blank');
  }

  public openPasPdf(pas: any[], processingOnBehalf: boolean, status: PAStatus): void {
    // Directly call a backend route which will result in a PDF displayed in the browser. The user then has
    // to manually download that. TODO: Offer a document download directly.
    const paramsString = new HttpParams()
      .append('pas', pas.join(','))
      .append('pob', processingOnBehalf.toString())
      .append('status', status)
      .toString();

    const url =
      `${AppConfig.apiReport}/pas/pdf?${paramsString}&token=${getToken()}&lang=${localStorage.getItem('lang')}`;
    window.open(url, '_blank');
  }

  sharePasPdf(pas: any[], processingOnBehalf: boolean, status: PAStatus): void {
    const paramsString = new HttpParams()
      .append('pas', pas.join(','))
      .append('pob', processingOnBehalf.toString())
      .append('status', status)
      .toString();

    const url =
      `${AppConfig.apiReport}/pas/pdf?${paramsString}&token=${getToken()}&lang=${localStorage.getItem('lang')}`;

    this.dialog.open(ShareDocumentDialogComponent, { data: { sourceUrl: url }, width: '450px' });
  }

  public downloadWordZip(pas: any[], processingOnBehalf: boolean): void {
    const paramsString = new HttpParams()
      .append('pas', pas.join(','))
      .append('pob', processingOnBehalf.toString())
      .toString();

    const url =
      `${AppConfig.apiReport}/pas/word?${paramsString}`;

    downloadFromGenerator(url, 'export.zip', this.translate, this.httpClient, this.dialog);
  }

  public shareWordZip(pas: any[], processingOnBehalf: boolean): void {
    const paramsString = new HttpParams()
      .append('pas', pas.join(','))
      .append('pob', processingOnBehalf.toString())
      .toString();

    const url =
      `${AppConfig.apiReport}/pas/word?${paramsString}}`;

      this.dialog.open(ShareDocumentDialogComponent, { data: { sourceUrl: url }, width: '450px' });
  }

  public downloadArt13Zip(pas: any[]): void {
    const paramsString = new HttpParams()
      .append('pas', pas.join(','))
      .append('pob', 'false')
      .toString();

    const url =
      `${AppConfig.apiReport}/pas/word-art13?${paramsString}`;

    downloadFromGenerator(url, 'export.zip', this.translate, this.httpClient, this.dialog);
  }

  public downloadXlsx(pas: any[], processingOnBehalf: boolean, status: PAStatus) {
    const paramsString = new HttpParams()
      .append('pas', pas.join(','))
      .append('pob', processingOnBehalf + '')
      .append('status', status)
      .toString();

    const url = `${AppConfig.apiReport}/xls?${paramsString}`;

    downloadFromGenerator(url, 'export.xlsx', this.translate, this.httpClient, this.dialog);
  }

  shareXlsx(pas: any[], processingOnBehalf: boolean, status: PAStatus) {
    const paramsString = new HttpParams()
    .append('pas', pas.join(','))
    .append('pob', processingOnBehalf + '')
    .append('status', status)
    .toString();

    const url = `${AppConfig.apiReport}/xls?${paramsString}&token=${localStorage.getItem('currentUser')}&lang=${localStorage.getItem('lang')}`;

    this.dialog.open(ShareDocumentDialogComponent, { data: { sourceUrl: url }, width: '450px' });
  }

  private setRouter(router: string): void {
    this.router = router;
  }

  private request(): void {
    this.bus.publish(this.events.notified.request.starting);
    this.requestService.get<IGetResponse>(this.options);
  }

  public subscribe(): void {
    this.bus.subscribe(this.events.requested.data.report, this.request.bind(this));
  }
}

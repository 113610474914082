import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';

import { EmailUnsubscribeService } from '../../services/email-unsubscribe.service';

import * as decode from 'jwt-decode';


@Component({
  selector: 'app-email-unsubscribe',
  templateUrl: './email-unsubscribe.component.html',
  styleUrls: ['./email-unsubscribe.component.scss']
})
export class EmailUnsubscribeComponent implements OnInit {

  loading: boolean = true;

  constructor(
    private activatedRoute: ActivatedRoute,
    private service: EmailUnsubscribeService,
  ) { }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(params => {
      if (params['unsub_token']) {
        let token = params['unsub_token'];
        let url = decode(token)['url'];
        this.service.unsubscribe(url, token).subscribe(() => {
          setTimeout(() => this.loading = false, 1000);
        });
      }
    });
  }

}

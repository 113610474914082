import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AppConfig } from 'app/app.config';
import { AuthenticationService } from 'app/services/authentication.service';
import { BusService } from 'app/services/bus.service';
import { EventsService } from 'app/services/events.service';
import { UserService } from 'app/services/user/user.service';
import { getToken } from '../../util/token';
import { MatDialog } from '@angular/material/dialog';
import { UpdateEmailDialogComponent } from './update-email/update-email.component';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit, OnDestroy {
  @ViewChild('upload') upload;

  firstName: string;
  lastName: string;
  email: string;
  saving = false;

  constructor(
    private auth: AuthenticationService,
    private service: UserService,
    private bus: BusService,
    private events: EventsService,
    private dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    setTimeout(() => {
      if (this.auth.avatar) {
        this.upload.load(
          `${AppConfig.apiUrl}/account/profile/avatar/` +
          `?token=${getToken()}&rand=${Math.floor(Math.random() * 1000)}`
        );
      }

      this.firstName = this.auth.firstName;
      this.lastName = this.auth.lastName;
      this.email = this.auth.email;
    }, 200);

    this.bus.subscribe(this.events.notified.user.profileChanged, this.profileUpdated, this);
  }

  ngOnDestroy() {
    this.bus.unsubscribe(this.events.notified.user.profileChanged, this.profileUpdated);
  }

  avatarUpdated() {
    this.bus.publish(this.events.notified.user.avatarChanged);
  }

  profileUpdated() {
    setTimeout(() => this.saving = false, 1000);
  }

  save() {
    if (this.firstName && this.lastName) {
      this.saving = true;
      this.service.updateProfile(this.firstName, this.lastName);
    }
  }

  openChangeEmailDialog() {
    this.dialog.open(UpdateEmailDialogComponent, {
      width: '600px',
      maxHeight: '90vh',
      data: {
        email: this.email,
      }
    });
  }
}

import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthenticationService } from '../services/authentication.service';
import { ClientSupervisionService } from '../services/supervision/client-supervision.service';

@Injectable()
export class ProductTypeGuard implements CanActivate {
  constructor(
    private router: Router,
    private authService: AuthenticationService,
    private supervisionService: ClientSupervisionService,
  ) { }

  public canActivate(route: ActivatedRouteSnapshot): boolean {
    const expectedUserProductType = route.data.productType;
    // AuthenticationService doesn't catch up with the product type while localStorage is the source of truth.
    // Because of this, we need to use localStorage here to prevent unexpected behaviour.
    //
    // NOTE from Eugene: this was stopping some other methods of authentication
    // like the auth token used by EEVEE from doing proper authentication, as they tend to forget
    // to set the proper product type. I will add the 'default' here so that if everything else
    // is fine and product type is the only missing piece, the authentication can go through.
    //
    const userProductType = localStorage.getItem('currentUserProductType') || 'default';

    if (expectedUserProductType === userProductType) {
      return true;
    }

    if (localStorage.getItem('isInSupervisionMode') === 'true' && route.url[0].path == 'supervision') {
      this.supervisionService.endSupervision(false);
      return true;
    };

    if (route.data.fallback) {
      this.router.navigate([route.data.fallback]);
    } else {
      this.router.navigate(['/not-found']);
    }
    return false;
  }
}

<div #eventsContainerRef class="events-chart">
  <!-- {{'supervision.clientDetails.charts.events.axis' | translate}} -->
  <ngx-charts-area-chart
    [results]="eventsTimeSeries || []"
    [xAxis]="true"
    [yAxis]="true"
    [gradient]="true"
    [curve]="curveEvents"
    [scheme]="{domain: ['#4a47a3']}"
    [view]="[eventsContainerRef.offsetWidth, height]"
  >
  </ngx-charts-area-chart>
</div>

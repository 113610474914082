<div class="contents">
  <div class="inner">
    <img src="/assets/party.svg"/>
    <br><br>
    <h1 class="title">
      {{'todolist.complete.title' | translate}}
    </h1>
    <p>
      {{'todolist.complete.message' | translate}}
    </p>
  </div>
</div>

<div class="pa-internal-processors">
  <h1 class="title">
    {{'pa.internalProcessors.title' | translate}}
  </h1>

  <ng-container *ngIf="processingActivities.length > 0">
    <p>
      {{'pa.internalProcessors.message' | translate}}
    </p>

    <div class="pane">
      <div class="pas" *ngIf="canWrite">
        <div class="pa"
            *ngFor="let pa of processingActivities"
            (click)="selectedPa = pa"
            [ngClass]="{'active': selectedPa == pa, 'resolved': resolved(pa)}">
            <mat-icon class="marker"
              color="primary"
              *ngIf="resolved(pa)">done</mat-icon>
            <div commentable
                comment-subject-codename="pa-{{pa.paId}}-internal-processors"
                comment-subject-title="{{'pa.internalProcessors.commentSubject.part1' | translate}} {{pa.paName}} {{'pa.internalProcessors.commentSubject.part2' | translate}}">
              <todoable subject="pa-{{pa.paId}}-internal-processors" suggested-title="{{'pa.internalProcessors.commentSubject.part1' | translate}} {{pa.paName}} {{'pa.internalProcessors.commentSubject.part2' | translate}}"></todoable>
                {{pa.paName}}
            </div>
            <a target="_blank" routerLink="/rpa/pa-details/{{ pa.paId }}">
              <mat-icon color="primary">edit</mat-icon>
            </a>
        </div>
      </div>

      <div class="pas" *ngIf="!canWrite">
        <div class="pa"
            *ngFor="let pa of myActivities"
            (click)="selectedPa = pa"
            [ngClass]="{'active': selectedPa == pa, 'resolved': resolved(pa)}">
            <mat-icon class="marker"
              color="primary"
              *ngIf="resolved(pa)">done</mat-icon>
          {{pa.paName}}
          <a target="_blank" routerLink="/rpa/pa-details/{{ pa.paId }}">
            <mat-icon color="primary">edit</mat-icon>
          </a>
        </div>

        <br/>
        <mat-divider></mat-divider>
        <br/>
        <div class="showOther" (click)="showOther = !showOther">
          <span *ngIf="!showOther">
            {{'pa.internalProcessors.showOtherPas' | translate}}
          </span>
          <span *ngIf="showOther">
            {{'pa.internalProcessors.hideOtherPas' | translate}}
          </span>
        </div>

        <ng-container *ngIf="showOther">
          <br>
          <mat-divider></mat-divider>
          <br>
          <div class="pa"
              *ngFor="let pa of otherActivities"
              (click)="selectedPa = pa"
              [ngClass]="{'active': selectedPa == pa, 'resolved': resolved(pa)}">
              <mat-icon class="marker"
                color="primary"
                *ngIf="resolved(pa)">done</mat-icon>
            {{pa.paName}}
          </div>
        </ng-container>
      </div>

      <div class="departments" [ngClass]="{'active': selectedPa}">
        <div class="box" *ngIf="selectedPa">

          <p *ngIf="!canChange(selectedPa)" class="warning">
            <span>{{'word.NOTICE' | translate}}</span>
            {{'word.readonlyAccess' | translate}}
          </p>

          <div class="department responsible" matTooltip="{{'pa.internalProcessors.responsibleDept.tooltip' | translate }}">
            {{responsibleDepartment(selectedPa).name}}
          </div>
          <br/>
          <div class="department"
            *ngFor="let department of nonResponsibleDepartments(selectedPa)"
            [ngClass]="{'active': isAssociated(department, selectedPa)}"
            (click)="toggleAssociation(department, selectedPa)">
            <div>
            {{department.name}}
            </div>
          </div>
        </div>
      </div>

      <div class="buttons">
        <button *ngIf="done" mat-raised-button color="primary" (click)="finish()">
          {{'word.done' | translate}}
        </button>
        <div *ngIf="!done" class="remaining">
          {{processingActivities.length - resolvedCount}}
          {{'pa.internalProcessors.paRemaining' | translate}}
        </div>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="processingActivities.length == 0">
    <br><br>
    <img src="/assets/empty.svg"/>
    <p>
      {{'pa.internalProcessors.empty' | translate}}
    </p>
    <br><br>
    <mat-divider></mat-divider>
    <br><br>
    <button mat-button color="primary" (click)="bypass()">
      {{'pa.internalProcessors.dontHave' | translate}}
    </button>
  </ng-container>
</div>

import {Directive, EmbeddedViewRef, HostBinding, HostListener, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef, ViewRef} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable, Subscription } from 'rxjs';

/**
 * Used to display expandable rows in Angular 5
 * See https://stackblitz.com/edit/angular-material2-expandable-rows-filter-pagination-sorting?file=app%2Fcdk-detail-row.directive.ts
 */
@Directive({
  selector: '[cdkDetailRow]'
})
export class CdkDetailRowDirective {
  private row: any;
  private tRef: TemplateRef<any>;
  private opened: boolean;
  private embeddedViewRef: EmbeddedViewRef<any>;

  @Input() collapseOnOutsideClick = true;

  @HostBinding('class.expanded')
  get expended(): boolean {
    return this.opened;
  }

  @Input()
  set cdkDetailRow(value: any) {
    if (value !== this.row) {
      this.row = value;
      // this.render();
    }
  }

  @Input('cdkDetailRowEnable') enabled = true;

  @Input('cdkDetailRowTpl')
  set template(value: TemplateRef<any>) {
    if (value !== this.tRef) {
      this.tRef = value;
      // this.render();
    }
  }

  constructor(
    public vcRef: ViewContainerRef,
    private matDialog: MatDialog
    ) { }

  @HostListener('click')
  onClick(): void {
    if (this.enabled) {
      this.toggle();
    }
  }

  @HostListener('document:click', ['$event'])
  onClickOutside(event) {
    if (!this.collapseOnOutsideClick) return;

    if (this.checkIfClickedInsideOrModal(event)) {
      return;
    } else {
      if (this.vcRef && this.opened && this.embeddedViewRef) {
        this.vcRef.clear();
        this.opened = this.vcRef.length > 0;
        this.embeddedViewRef = null;
      }
    }
  }

  private checkIfClickedInsideOrModal(event) {
    return !event.target.className.includes('overlay-close')
      && (
        !this.embeddedViewRef
        || this.embeddedViewRef.rootNodes[0].contains(event.target)
        || this.vcRef.element.nativeElement.contains(event.target)
        || this.matDialog.openDialogs.length > 0
        || event.target.className.includes('mat-calendar') // EC-3720: allow clicking on calendar
        || event.target.id.includes('mat-calendar')
      );
  }

  toggle(): void {
    if (this.opened) {
      this.vcRef.clear();
    } else {
      this.render();
    }
    this.opened = this.vcRef.length > 0;
  }

  private render(): void {
    this.vcRef.clear();
    if (this.tRef && this.row) {
      this.embeddedViewRef = this.vcRef.createEmbeddedView(this.tRef, { $implicit: this.row });
    }
  }
}

import { animate, keyframes, query, stagger, style, transition, trigger } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer, SafeHtml, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { of, Subject, Subscription, zip } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { DpiaMeasure, DpiaModel, DpiaRisk, DpiaService, MeasureExample, RiskExample, RiskResources, RiskSource, RiskType } from '../../../services/rpa/dpia.service';
import { RpaGetService } from '../../../services/rpa/rpa.get.service';
import { AccessLevel, AccessLevelService } from '../../../services/user/access-level.service';
import { MeasureRecommendationDialogComponent } from './measure-recommendation-dialog/measure-recommendation-dialog.component';
import { getMeasureDisplay, isProportional, isProportionalityAnswered } from './util';

type DpiaStep = 'welcome' | 'proportionality' | 'risk-identification' | 'risk-analysis' | 'measure-definition' | 'documents' | 'summary';

@Component({
  selector: 'app-dpia-assistant',
  templateUrl: './dpia-assistant.component.html',
  styleUrls: ['./dpia-assistant.component.scss'],
  animations: [
    trigger('riskCountAnimation', [
      transition('void => *', []),
      transition('* => void', []),
      transition('* => *', [
        animate(300, keyframes([
          style ({ background : '#ffe75e', color: '#FF5500', offset: 0.0, transform: 'scale(1.25)' }),
          style ({ background : 'inherit', color: 'inherit', offset: 1.0, transform: 'scale(1)' }),
        ])),
      ]),
    ])
  ]
})
export class DpiaAssistantComponent implements OnInit {

  paId: number = null;
  step: DpiaStep = null;

  pa: any = null;
  dpia: DpiaModel = null;

  stepChanged: Subject<DpiaStep>;
  onStepChangedSubscription: Subscription;

  riskResources: RiskResources = null;
  selectedSource: RiskSource = null;
  selectedType: RiskType = null;
  risks: DpiaRisk[] = [];
  measures: DpiaMeasure[] = [];

  riskMatrices: { before: SafeHtml; current: SafeHtml; after: SafeHtml } = null;

  measureExamples: MeasureExample[] = null;

  newRisk: Pick<DpiaRisk, 'name' | 'riskSource' | 'riskType'> = { name: '', riskSource: null, riskType: null };

  availableActions: string[] = [];

  accessLevel: AccessLevel = null;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private rpaGet: RpaGetService,
    private dpiaService: DpiaService,
    private translation: TranslateService,
    private dialog: MatDialog,
    private sanitizer: DomSanitizer,
    private accessLevelService: AccessLevelService
  ) {
    this.stepChanged = new Subject();

    this.onStepChangedSubscription = this.stepChanged.subscribe(this.onStepChanged.bind(this));

    activatedRoute.params.subscribe(params => {
      if (!this.paId) {
        if (!params.paId) {
          this.router.navigate(['not-found']);
        }

        this.paId = parseInt(params.paId, 10);

        this.fetch();
      }

      // handle step change, can also come from page-internal navigation!
      if (!params.step) {
        this.goToStep('welcome');
      } else {
        this.step = params.step;
        this.stepChanged.next(this.step);
      }
    });

    this.accessLevelService.checkAccess({
      context: 'pa-details',
      details: {
        paId: this.paId,
    }
    }).subscribe((level) => {
      this.accessLevel = level;
    });
  }

  fetch() {
    zip(
      this.rpaGet.request(this.paId),
      this.dpiaService.get(this.paId)
        .pipe(
          catchError((err, _) => of(null))
        )
    )
      .subscribe(([pa, dpia]) => {
        this.pa = pa;

        if (!dpia) {
          this.createDpia();
        } else {
          this.dpia = dpia;
        }
      });
  }

  createDpia() {
    this.dpiaService.create(this.paId)
      .subscribe(dpia => {
        this.dpia = dpia;
      });
  }

  goToStep(step: DpiaStep) {
    this.step = step;
    this.router.navigate(['rpa', 'dpia', this.paId, this.step]);
    this.stepChanged.next(step);
  }

  onStepChanged(step: DpiaStep) {
    if (step === 'risk-identification') {
      this.fetchRisksAndMeasures();
    }
    if (step === 'risk-analysis') {
      this.fetchRisksAndMeasures();
    }
    if (step === 'measure-definition') {
      this.fetchRisksAndMeasures();
    }
    if (step === 'summary') {
      this.fetchRisksAndMeasures();
      this.fetchRiskMatrices();
    }
  }

  fetchRiskMatrices() {
    this.dpiaService.getMatrices(this.paId)
      .subscribe((matrices) => {
        if (matrices) {
          this.riskMatrices = matrices;
          this.riskMatrices = {
            before: this.sanitizer.bypassSecurityTrustHtml(matrices.before),
            current: this.sanitizer.bypassSecurityTrustHtml(matrices.current),
            after: this.sanitizer.bypassSecurityTrustHtml(matrices.after),
          }
        }
      });
  }

  /* =================================
   * Risk Identification Stuff
   * =================================
   */

  fetchRisksAndMeasures() {
    if (!this.riskResources) {
      zip(
        this.dpiaService.getRiskResources(),
        this.dpiaService.getRisks(this.paId),
        this.dpiaService.getMeasures(this.paId)
      )
        .subscribe(([resources, risks, measures]) => {
          this.riskResources = resources;
          this.risks = risks;
          this.measures = measures;
        });
    }
  }

  getDisplay(item: { display: { de: string; en: string; 'en-NG': string; pt: string; 'pt-BR': string } }) {
    const lang = this.translation.currentLang || this.translation.defaultLang;
    return item.display[lang] || '';
  }

  isSourceSelected(source: RiskSource) {
    if (this.selectedSource) {
      return source.id === this.selectedSource.id;
    }
  }

  getSourceAppliesStatus(source: RiskSource) {
    const existing = this.dpia.data.riskSelection.find(s => s.sourceId === source.id);
    if (existing) {
      return existing.applies ? 'applies' : 'not-applies';
    }

    return 'unset';
  }

  selectSource(source: RiskSource) {
    if (this.getSourceAppliesStatus(source) === 'applies' && this.selectedSource?.id !== source.id) {
      this.selectedSource = source;
      this.selectedType = null;
    }
  }

  setRiskSourceApplies($event, source: RiskSource) {
    $event.checked ? this.applyRiskSource(source) : this.unapplyRiskSource(source);

    if ($event.checked) {
      this.selectSource(source);
    } else if (this.selectedSource.id === source.id) {
      this.selectedSource = null;
      this.selectedType = null;
    }
  }

  setRiskTypeApplies($event, type: RiskType) {
    $event.checked ? this.applyRiskType(type) : this.unapplyRiskType(type);

    if ($event.checked) {
      this.selectType(type);
    } else if (this.selectedType.id === type.id) {
      this.selectedType = null;
    }
  }

  setRiskExampleApplies($event, example: RiskExample) {
    $event.checked ? this.applyRiskExample(example) : this.unapplyRiskExample(example);
  }

  setCustomRiskApplies($event, risk: DpiaRisk) {
    $event.checked ? this.applyCustomRisk(risk) : this.unapplyCustomRisk(risk);
  }

  private applyRiskSource(source: RiskSource) {

    const existing = this.dpia.data.riskSelection.findIndex(s => s.sourceId === source.id);

    if (existing !== -1) {
      this.dpia.data.riskSelection[existing].applies = true;
    } else {
      this.dpia.data.riskSelection.push({ sourceId: source.id, applies: true });
    }

    this.update();
  }

  private unapplyRiskSource(source: RiskSource) {
    const existing = this.dpia.data.riskSelection.findIndex(s => s.sourceId === source.id);

    if (existing !== -1) {
      this.dpia.data.riskSelection[existing].applies = false;
    } else {
      this.dpia.data.riskSelection.push({ sourceId: source.id, applies: false });
    }

    this.update();
  }

  private applyRiskType(type: RiskType) {
    const source = this.dpia.data.riskSelection.findIndex(s => s.sourceId === this.selectedSource.id);

    if (source === -1) {
      return;
    }

    if (!this.dpia.data.riskSelection[source].typeSelection) {
      this.dpia.data.riskSelection[source].typeSelection = [];
    }

    const existing = this.dpia.data.riskSelection[source].typeSelection.findIndex(t => t.typeId === type.id);

    if (existing !== -1) {
      this.dpia.data.riskSelection[source].typeSelection[existing].applies = true;
    } else {
      this.dpia.data.riskSelection[source].typeSelection.push({ typeId: type.id, applies: true });
    }

    this.update();
  }

  private unapplyRiskType(type: RiskType) {
    const source = this.dpia.data.riskSelection.findIndex(s => s.sourceId === this.selectedSource.id);

    if (source === -1) {
      return;
    }

    if (!this.dpia.data.riskSelection[source].typeSelection) {
      this.dpia.data.riskSelection[source].typeSelection = [];
    }

    const existing = this.dpia.data.riskSelection[source].typeSelection.findIndex(t => t.typeId === type.id);

    if (existing !== -1) {
      this.dpia.data.riskSelection[source].typeSelection[existing].applies = false;
    } else {
      this.dpia.data.riskSelection[source].typeSelection.push({ typeId: type.id, applies: false });
    }

    this.update();
  }

  applyRiskExample(example: RiskExample) {
    const source = this.dpia.data.riskSelection.findIndex(s => s.sourceId === this.selectedSource.id);

    if (source === -1) {
      return;
    }

    const existingType = this.dpia.data.riskSelection[source].typeSelection?.findIndex(t => t.typeId === this.selectedType.id);

    if (typeof existingType === 'undefined' || existingType === -1) {
      return;
    }

    if (!this.dpia.data.riskSelection[source].typeSelection[existingType].examples) {
      this.dpia.data.riskSelection[source].typeSelection[existingType].examples = [];
    }

    const existing = this.dpia.data.riskSelection[source].typeSelection[existingType].examples.findIndex(e => e.exampleId === example.id);

    if (existing !== -1) {
      this.dpia.data.riskSelection[source].typeSelection[existingType].examples[existing].applies = true;

      if (!this.dpia.data.riskSelection[source].typeSelection[existingType].examples[existing].riskId) {
        this.dpiaService.createRisk(this.paId, { riskSource: this.selectedSource.id, riskType: this.selectedType.id, name: this.getDisplay(example) })
        .subscribe((risk) => {
          if (risk) {
            this.risks.push(risk);
            this.dpia.data.riskSelection[source].typeSelection[existingType].examples[existing].riskId = risk.id;

            this.newRisk = { name: '', riskSource: null, riskType: null };
            this.update();
          }
        });
      } else {
        this.update();
      }
    } else {
      this.dpiaService.createRisk(this.paId, { riskSource: this.selectedSource.id, riskType: this.selectedType.id, name: this.getDisplay(example) })
        .subscribe((risk) => {
          if (risk) {
            this.risks.push(risk);
            this.dpia.data.riskSelection[source].typeSelection[existingType].examples.push({ exampleId: example.id, applies: true, riskId: risk.id });

            this.newRisk = { name: '', riskSource: null, riskType: null };
            this.update();
          }
        });
    }
  }

  unapplyRiskExample(example: RiskExample) {
    const source = this.dpia.data.riskSelection.findIndex(s => s.sourceId === this.selectedSource.id);

    if (source === -1) {
      return;
    }

    const existingType = this.dpia.data.riskSelection[source].typeSelection?.findIndex(t => t.typeId === this.selectedType.id);

    if (typeof existingType === 'undefined' || existingType === -1) {
      return;
    }

    if (!this.dpia.data.riskSelection[source].typeSelection[existingType].examples) {
      this.dpia.data.riskSelection[source].typeSelection[existingType].examples = [];
    }

    const existing = this.dpia.data.riskSelection[source].typeSelection[existingType].examples.findIndex(e => e.exampleId === example.id);

    if (existing !== -1) {
      this.dpia.data.riskSelection[source].typeSelection[existingType].examples[existing].applies = false;
    } else {
      this.dpia.data.riskSelection[source].typeSelection[existingType].examples.push({ exampleId: example.id, applies: false });
    }

    this.update();
  }

  getExampleAppliesStatus(example: RiskExample) {
    const source = this.dpia.data.riskSelection.findIndex(s => s.sourceId === this.selectedSource.id);
    if (source !== -1 && this.dpia.data.riskSelection[source].typeSelection) {
      const type = this.dpia.data.riskSelection[source].typeSelection.findIndex(t => t.typeId === this.selectedType.id);
      if (type !== -1) {
        const existing = this.dpia.data.riskSelection[source].typeSelection[type].examples?.find(e => e.exampleId === example.id);
        if (existing) {
          return existing.applies ? 'applies' : 'not-applies';
        }
      }
    }

    return 'unset';
  }

  getCustomRiskAppliesStatus(risk: DpiaRisk) {
    const source = this.dpia.data.riskSelection.findIndex(s => s.sourceId === this.selectedSource.id);
    if (source !== -1 && this.dpia.data.riskSelection[source].typeSelection) {
      const type = this.dpia.data.riskSelection[source].typeSelection.findIndex(t => t.typeId === this.selectedType.id);
      if (type !== -1) {
        const existing = this.dpia.data.riskSelection[source].typeSelection[type].examples?.find(e => e.riskId === risk.id);
        if (existing) {
          return existing.applies ? 'applies' : 'not-applies';
        }
      }
    }

    return 'unset';
  }

  isTypeSelected(type: RiskType) {
    if (this.selectedType) {
      return type.id === this.selectedType.id;
    }
  }

  getTypeAppliesStatus(type: RiskType) {
    const source = this.dpia.data.riskSelection.findIndex(s => s.sourceId === this.selectedSource.id);
    if (source !== -1) {
      const existing = this.dpia.data.riskSelection[source].typeSelection?.find(t => t.typeId === type.id);
      if (existing) {
        return existing.applies ? 'applies' : 'not-applies';
      }
    }

    return 'unset';
  }

  noRisksFilledForSource(source: RiskSource) {
    if (this.getSourceAppliesStatus(source) === 'applies') {
      const selectedSource = this.dpia.data.riskSelection.find(s => s.sourceId === source.id);

      if (!selectedSource) {
        return false;
      }

      if (!selectedSource.typeSelection) {
        return true;
      }

      const flattened = selectedSource.typeSelection
        .filter(t => t.applies)
        .map(t => {
          return !t.examples || (t.examples.filter(e => e.applies).length === 0);
        });

      // if no types have been selected or none apply, then there are also no risks filled
      if (flattened.length === 0) {
        return true;
      }

      if (flattened.filter(e => e === true).length > 0) {
        return true;
      }
    }

    return false;
  }

  noRisksFilledForType(source: RiskSource, type: RiskType) {
    if (this.getSourceAppliesStatus(source) === 'applies' && this.getTypeAppliesStatus(type) === 'applies') {
      const selectedSource = this.dpia.data.riskSelection.find(s => s.sourceId === source.id);

      if (!selectedSource) {
        return false;
      }

      const selectedType = selectedSource.typeSelection.find(t => t.typeId === type.id);
      if (selectedType) {
        return !selectedType.examples || (selectedType.examples.filter(e => e.applies).length === 0);
      }
    }

    return false;
  }

  selectType(type: RiskType) {
    if (this.getTypeAppliesStatus(type) === 'applies') {
      this.selectedType = type;
    }
  }

  getRiskTypes() {
    if (this.selectedSource) {
      const types = this.selectedSource.risk_type_suggestions.map(s => this.riskResources.risk_types.find(type => type.id === s));
      console.log(types, this.riskResources.risk_types, this.selectedSource.risk_type_suggestions);
      return types;
    }

    return [];
  }

  getSelectedRiskType() {
    const source = this.dpia.data.riskSelection.findIndex(s => s.sourceId === this.selectedSource.id);

    if (source === -1) {
      return;
    }

    const existingType = this.dpia.data.riskSelection[source].typeSelection?.findIndex(t => t.typeId === this.selectedType.id);

    if (typeof existingType === 'undefined' || existingType === -1) {
      return null;
    }

    return this.dpia.data.riskSelection[source].typeSelection[existingType];
  }

  riskAlreadyCreated(r: DpiaRisk) {
    const selectedRiskType = this.getSelectedRiskType();
    if (selectedRiskType.examples) {
      return selectedRiskType.examples.findIndex(e => e.exampleId && e.riskId === r.id) !== -1;
    }
  }

  getMatchedRisks() {
    if (this.selectedSource && this.selectedType) {
      return this.risks.filter(r => r.riskSource === this.selectedSource.id && r.riskType === this.selectedType.id && !this.riskAlreadyCreated(r));
    }

    return [];
  }

  addRisk() {
    if (this.selectedSource && this.selectedType) {
      this.newRisk.riskSource = this.selectedSource.id;
      this.newRisk.riskType = this.selectedType.id;

      this.dpiaService.createRisk(this.paId, this.newRisk)
        .subscribe((risk) => {
          if (risk) {
            this.risks.push(risk);
            this.applyCustomRisk(risk);

            this.newRisk = { name: '', riskSource: null, riskType: null };
          }
        })
    }
  }

  applyCustomRisk(risk: DpiaRisk) {
    const source = this.dpia.data.riskSelection.findIndex(s => s.sourceId === this.selectedSource.id);

    if (source === -1) {
      return;
    }

    const existingType = this.dpia.data.riskSelection[source].typeSelection?.findIndex(t => t.typeId === this.selectedType.id);

    if (typeof existingType === 'undefined' || existingType === -1) {
      return;
    }

    if (!this.dpia.data.riskSelection[source].typeSelection[existingType].examples) {
      this.dpia.data.riskSelection[source].typeSelection[existingType].examples = [];
    }

    const existing = this.dpia.data.riskSelection[source].typeSelection[existingType].examples.findIndex(e => e.riskId === risk.id);

    if (existing !== -1) {
      this.dpia.data.riskSelection[source].typeSelection[existingType].examples[existing].applies = true;
    } else {
      this.dpia.data.riskSelection[source].typeSelection[existingType].examples.push({ exampleId: null, applies: true, riskId: risk.id });
    }

    this.update();
  }

  unapplyCustomRisk(risk: DpiaRisk) {
    const source = this.dpia.data.riskSelection.findIndex(s => s.sourceId === this.selectedSource.id);

    if (source === -1) {
      return;
    }

    const existingType = this.dpia.data.riskSelection[source].typeSelection?.findIndex(t => t.typeId === this.selectedType.id);

    if (typeof existingType === 'undefined' || existingType === -1) {
      return;
    }

    if (!this.dpia.data.riskSelection[source].typeSelection[existingType].examples) {
      this.dpia.data.riskSelection[source].typeSelection[existingType].examples = [];
    }

    const existing = this.dpia.data.riskSelection[source].typeSelection[existingType].examples.findIndex(e => e.riskId === risk.id);

    if (existing !== -1) {
      this.dpia.data.riskSelection[source].typeSelection[existingType].examples[existing].applies = false;
    } else {
      this.dpia.data.riskSelection[source].typeSelection[existingType].examples.push({ exampleId: null, applies: false, riskId: risk.id });
    }

    this.update();
  }

  private getEnabledRiskIds() {
    return this.dpia.data.riskSelection
    .flatMap(s => s.typeSelection && s.applies ? s.typeSelection : [])
    .flatMap(r => r.examples && r.applies ? r.examples : [])
    .filter(e => e.applies && e.riskId)
    .map(e => e.riskId);
  }

  getSourceDisplay(sourceId: string) {
    const source = this.riskResources.risk_sources.find(s => s.id === sourceId);
    return source ? this.getDisplay(source) : '';
  }

  getTypeDisplay(typeId: string) {
    const type = this.riskResources.risk_types.find(t => t.id === typeId);
    return type ? this.getDisplay(type) : '';
  }

  getOverallRating(risk: DpiaRisk) {
    return risk.impact * risk.probability;
  }

  updateRisk(risk: DpiaRisk) {
    // todo: show saving indicator, maybe?
    this.dpiaService.updateRisk(this.paId, risk).subscribe((updated) => {
      // do nothing
    });
  }

  getMeasures(risk: DpiaRisk) {
    return this.measures.filter(m => m.riskId === risk.id).sort((a, b) => a.id - b.id);
  }

  getMeasuresAlreadyFinished(risk: DpiaRisk) {
    return this.getMeasures(risk).filter(m => m.status === 'finished');
  }

  getMeasuresJoined(risk: DpiaRisk) {
    return this.getMeasures(risk).map(m => m.name).join(', ');
  }

  getMeasuresJoinedAlreadyFinished(risk: DpiaRisk) {
    return this.getMeasuresAlreadyFinished(risk).map(m => m.name).join(', ');
  }

  updateMeasure(measure: DpiaMeasure) {
    // todo: show saving indicator, maybe?
    this.dpiaService.updateMeasure(this.paId, measure).subscribe((updated) => {
      // do nothing
    });
  }

  addMeasure(risk: DpiaRisk) {
    if (!this.measureExamples) {
      this.dpiaService.getMeasureExamples()
        .subscribe((examples) => {
          this.measureExamples = examples;

          this.dialog.open(MeasureRecommendationDialogComponent, { width: '600px', data: { measures: examples }})
            .afterClosed()
            .subscribe((selectedMeasure) => {
              if (selectedMeasure) {
                this.createMeasure(risk, selectedMeasure);
              }
            });
        });
    } else {
      this.dialog.open(MeasureRecommendationDialogComponent, { width: '600px', data: { measures: this.measureExamples }})
        .afterClosed()
        .subscribe((selectedMeasure) => {
          if (selectedMeasure) {
            this.createMeasure(risk, selectedMeasure);
          }
        });
    }
  }

  addOwnMeasure(risk: DpiaRisk) {
    this.createMeasure(risk, null);
  }

  createMeasure(risk: DpiaRisk, measure?: Pick<MeasureExample, 'display'>) {
    this.dpiaService.createMeasure(this.paId, { name: getMeasureDisplay(this.translation, measure)?.measureTitle || '', riskId: risk.id, status: 'finished' })
      .subscribe((createdMeasure) => {
        if (createdMeasure) {
          this.measures.push(createdMeasure);
        }
      });
  }

  deleteMeasure(measure: DpiaMeasure) {
    this.dpiaService.deleteMeasure(this.paId, measure.id)
      .subscribe(() => {
        const idx = this.measures.findIndex(m => m.id === measure.id);
        if (idx !== -1) {
          this.measures.splice(idx, 1);
        }
      });
  }

  get riskCount() {
    return this.getEnabledRiskIds().length;
  }

  get openMeasures() {
    return this.measures.filter(m => m.status !== 'finished');
  }

  get risksOrdered() {
    return this.risks.filter(r => this.getEnabledRiskIds().includes(r.id)).sort((a, b) => a.id - b.id);
  }

  get risksIncompletelyMeasured() {
    return this.risksOrdered.filter(r => !r.impact || !r.probability).length > 0;
  }

  get risksIncompletelyRemedied() {
    // should return true if any of the have no remedy value
    // return this.risksOrdered.filter(r =>  !r.remediedOverallRisk).length > 0;

    return this.risksOrdered.filter(r => (!r.impact || !r.probability) || ((r.impact * r.probability > 3) ? !r.remediedOverallRisk : false)).length > 0;
  }

  get contentReady() {
    return this.pa && this.dpia;
  }

  get paTitle() {
    return `VT-${this.paId}: ${this.pa.paName}`;
  }

  get isProcessingProportional() {
    return isProportional(this.dpia);
  }

  get proportionalityAnswered() {
    return isProportionalityAnswered(this.dpia);
  }

  shouldShowTargetRiskMesures(risk: DpiaRisk) {
    return this.getMeasures(risk).length > 0 && (this.getMeasuresAlreadyFinished(risk).length !== this.getMeasures(risk).length)
  }


  get readonly() {
    if (this.accessLevel) {
      return !this.accessLevel.write;
    }

    return false;
  }

  get readonlyMeasures() {
    return false;
  }

  get dpoReadonly() {
    return false;
  }

  update() {
    this.dpiaService.update(this.dpia).subscribe((updated) => {
    });
  }


  reportLink() {
    return this.dpiaService.getReportLink(this.paId);
  }

  get uploadUrl() {
    return '/pa/basics/' + this.paId + '/file';
  }

  updateDocumentList() {
    this.rpaGet.request(this.paId)
      .subscribe((pa) => {
        this.pa = pa;
      });
  }

  ngOnInit(): void {
  }

}

<div class="not-found">
  <h1 class="title">{{ 'pagenotfound.ooops' | translate }}</h1>
  <br>
  <mat-card>
    <img mat-card-image src="assets/not-found.svg"/>
    <mat-card-content>
    {{ 'pagenotfound.explanation' | translate }}
    </mat-card-content>
    <mat-card-actions align="end">
      <a mat-raised-button color="primary" routerLink="/home">{{ 'pagenotfound.exploreecomply' | translate }}</a>
    </mat-card-actions>
  </mat-card>
</div>

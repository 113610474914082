<mat-card>

  <div class="texts">
    <div class="title">{{title}}</div>
    <div class="desc">{{description}}</div>
    <div>
      <div class="issuer" *ngIf="todo.issuer && todo.issuer.firstName">
        <label>
          {{'todos.issuedBy' | translate}}
        </label>
        {{todo.issuer.firstName}} {{todo.issuer.lastName}} <span *ngIf="issuedBySelf">({{ 'todos.you' | translate }})</span>
      </div>

      <div class="assignee" [class.self]="assignedToSelf" *ngIf="todo.assignee.firstName">
        <label>
          {{'todos.assignedTo' | translate}}
        </label>
        <span>
          {{todo.assignee.firstName}} {{todo.assignee.lastName}} <span *ngIf="assignedToSelf">({{ 'todos.you' | translate }})</span>
        </span>
      </div>
    </div>
  </div>
  <div class="actions">
    <button *ngIf="!todo.done && !readonly" mat-icon-button color="primary" (click)="markDone($event)"
            matTooltip="{{'todos.markDone' | translate}}">
      <mat-icon color="primary">done</mat-icon>
    </button>
    <p *ngIf="todo.dueDate" [ngClass]="{'error': now > todo.dueDate && !todo.done }">
      <!-- Due date -->
      {{'todos.dueIn' | translate}} {{todo.dueDate | date: 'dd.MM.yyyy'}}
    </p>
    <p>
      <!-- Updated at -->
      {{'todos.updatedIn' | translate}} {{todo.lastChange | date: 'dd.MM.yyyy'}}</p>
  </div>
</mat-card>

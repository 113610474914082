<div class="container">
  <div class="title" (click)="expanded = !expanded">
    <mat-icon>storage</mat-icon>
    {{pa.pa_name}}
  </div>
  <ng-container *ngIf="expanded">
    <div class="description">
      {{pa.pa_description}}
    </div>
    <div class="actions">
      <ng-container *ngIf="!toggleAction || !toggled">
        <button mat-button color="primary" (click)="pick()" *ngIf="actionStyle === 'none'">{{action}}</button>
        <button mat-stroked-button color="primary" (click)="pick()" *ngIf="actionStyle === 'stroked'">{{action}}</button>
        <button mat-flat-button color="primary" (click)="pick()" *ngIf="actionStyle === 'flat'">{{action}}</button>
      </ng-container>

      <ng-container *ngIf="toggleAction && toggled">
        <button mat-button color="primary" (click)="unpick()" *ngIf="actionStyle === 'none'">{{toggleAction}}</button>
        <button mat-stroked-button color="primary" (click)="unpick()" *ngIf="actionStyle === 'stroked'">{{toggleAction}}</button>
        <button mat-flat-button color="primary" (click)="unpick()" *ngIf="actionStyle === 'flat'">{{toggleAction}}</button>
      </ng-container>
    </div>
  </ng-container>
</div>
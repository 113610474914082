import { Injectable } from '@angular/core';
import { zip } from 'rxjs';
import { map } from 'rxjs/operators';
import { RequestService } from '../request.service';

@Injectable({
  providedIn: 'root'
})
export class TwoFactorService {

  constructor(
    private request: RequestService
  ) { }

  getInfo() {
    return this.request.get<{ setupDate: Date } | null>({ uri: '/account/2fa/' });
  }

  createDevice() {
    return this.request.post<any, { secret: string }>({ uri: '/account/2fa/', body: null });
  }

  enable(code: string) {
    return this.request.patch<{ code: string; enable: boolean }, { code: string }>({ uri: '/account/2fa/', body: { code, enable: true } });
  }

  disable(code: string) {
    return this.request.patch<{ code: string; enable: boolean }, { code: string }>({ uri: '/account/2fa', body: { code, enable: false } });
  }

  isRequired() {
    return this.request.get<{ required: boolean }>({ uri: '/account/2fa/required' });
  }

  requiresSetup() {
    return zip(this.getInfo(), this.isRequired())
      .pipe(
        map(([info, required]) => required.required && (!info || !info.setupDate))
      );
  }

  lockForSetup() {
    localStorage.setItem('ec:2fa:needs-setup', 'true');
  }

  unlockAfterSetup() {
    localStorage.removeItem('ec:2fa:needs-setup');
  }
}

<mat-form-field>
  <mat-select [(ngModel)]="data.value"
    placeholder="{{ 'incidents.records.departmentFilter.placeholder' | translate }}">
    <mat-option *ngFor="let dep of data.departments" [value]="dep.id">
      {{dep.name}}
    </mat-option>
  </mat-select>
  <mat-hint>
    {{'incidents.records.departmentFilter.hint' | translate}}
  </mat-hint>
</mat-form-field>

<mat-dialog-actions align="end">
  <button mat-button
    color="primary"
    [mat-dialog-close]="{value: ''}">{{'word.reset' | translate}}</button>
  <button mat-flat-button
    color="primary"
    [mat-dialog-close]="{value: data.value}">{{'word.apply' | translate}}</button>
</mat-dialog-actions>

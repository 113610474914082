<h1 mat-dialog-title>
  {{'supervision.addClient.controller.title' | translate}}
</h1>

<mat-dialog-content>
  <mat-form-field>
    <input matInput type="text" name="controllerName" required
      (keyup.enter)="create()"
      placeholder="{{'supervision.addClient.controller.controllerName.placeholder' | translate}}"
      [formControl]="controllerName"/>
    <mat-hint>
      {{'supervision.addClient.controller.controllerName.hint' | translate}}
    </mat-hint>
    <mat-error *ngIf="controllerName.hasError('required')">
      {{'supervision.addClient.controller.controllerName.required' | translate}}
    </mat-error>
  </mat-form-field>
  <mat-form-field>
    <input matInput type="text" name="clientId"
      (keyup.enter)="create()"
      placeholder="{{'supervision.addClient.controller.clientId.placeholder' | translate}}"
      [formControl]="clientId"/>
    <mat-hint>
      {{'supervision.addClient.controller.clientId.hint' | translate}}
    </mat-hint>
  </mat-form-field>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button color="primary" (click)="create()">
    {{'supervision.addClient.createController' | translate}}
  </button>
</mat-dialog-actions>

<!--
<mat-card>
  <mat-card-title>
    {{'supervision.addClient.controller.title' | translate}}
  </mat-card-title>
  <mat-card-content>
    <mat-form-field>
      <input matInput type="text" name="controllerName" required
        placeholder="{{'supervision.addClient.controller.controllerName.placeholder' | translate}}"
        [formControl]="controllerName"/>
      <mat-hint>
        {{'supervision.addClient.controller.controllerName.hint' | translate}}
      </mat-hint>
      <mat-error *ngIf="controllerName.hasError('required')">
        {{'supervision.addClient.controller.controllerName.required' | translate}}
      </mat-error>
    </mat-form-field>
  </mat-card-content>
  <mat-card-actions align="end">
    <button mat-button color="primary" (click)="create()">
      {{'supervision.addClient.createController' | translate}}
    </button>
  </mat-card-actions>
</mat-card>
-->

<h1 mat-dialog-title>
  {{'supervision.addDemoClient.title' | translate}}
</h1>

<mat-dialog-content class="add-demo-client-dialog">
  <p class="description">{{ 'supervision.addDemoClient.description' | translate }}</p>
  <div class="templates">
    <div class="template" *ngFor="let template of templates" (click)="createDemoClient(template)">
      <div class="content">
        <div class="name">{{template.name}}</div>
        <div class="last-update">
          {{'supervision.templates.select.lastUpdated' | translate }}
          {{template.updatedAt | localizedDate : 'short'}}
        </div>
      </div>
      <button mat-icon-button (click)="createDemoClient(template)">
        <mat-icon>add</mat-icon>
      </button>
    </div>
  </div>
  <div class="no-templates" *ngIf="!pendingTemplates && templates.length === 0">
    <p>{{ 'supervision.addDemoClient.noTemplates' | translate }}</p>
  </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>
    {{'supervision.addDemoClient.close' | translate}}
  </button>
</mat-dialog-actions>

import { Inject, Injectable, PLATFORM_ID, Renderer2, RendererFactory2 } from '@angular/core';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { isNotCustomerInstance } from '../../util/environment';

@Injectable()
export class Crisp {
  private renderer: Renderer2;

  private created = false;

  constructor(
    @Inject(PLATFORM_ID) protected platformId: Object,
    rendererFactory: RendererFactory2,
    @Inject(DOCUMENT) private document
    ) {
    this.renderer = rendererFactory.createRenderer(null, null);
    if (!isPlatformBrowser(platformId)) {
      return;
    }
  }

  mount() {
    if (isNotCustomerInstance()) {
      const script = this.renderer.createElement('script');

      // eslint-disable-next-line max-len
      script.text = `window.$crisp=[];window.CRISP_WEBSITE_ID="f394ee36-6bd9-4e41-a646-4ace93eaebf5";(function(){d=document;s=d.createElement("script");s.src="https://client.crisp.chat/l.js";s.async=1;d.getElementsByTagName("head")[0].appendChild(s);})();`;

      this.renderer.appendChild(this.document.body, script);
    }
  }

  updateUser(email: string, emailHash: string, name: string) {
    if (this.check()) {
      (<any>window).$crisp.push(['set', 'user:email', [email, emailHash]]);
      (<any>window).$crisp.push(['set', 'user:nickname', [name]]);
    }
  }

  updateCompany(name: string, controllerId: string) {
    if (this.check()) {
      (<any>window).$crisp.push(['set', 'user:company', [name || 'no name set']]);
      (<any>window).$crisp.push(['set', 'session:data', ['controller_id', controllerId || '']]);
    }
  }

  startWithMessage(message: string) {
    if (this.check()) {
      (<any>window).$crisp.push(['set', 'message:text', [message]]);
      (<any>window).$crisp.push(['do', 'chat:open']);
    }
  }

  updatePlan(plan: string) {
    if (this.check()) {
      (<any>window).$crisp.push(['set', 'session:data', ['plan_name', plan]]);
    }
  }

  updateExpiry(expiry: string) {
    if (this.check()) {
      (<any>window).$crisp.push(['set', 'session:data', ['expire_date', expiry]]);
    }
  }

  open() {
    if (this.check()) {
      (<any>window).$crisp.push(['do', 'chat:open']);
    }
  }

  resetSession() {
    if (this.check()) {
      (<any>window).$crisp.push(['do', 'session:reset']);
    }
  }

  sendSimpleEvent(eventName: string, data?: any) {
    if (this.check()) {
      (<any>window).$crisp.push(["set", "session:event", [[[eventName, data]]]])
    }
  }

  hide() {
    if (this.check()) {
      (<any>window).$crisp.push(['do', 'chat:hide']);
    }
  }

  /**
   * Checks if running in browser and if Crisp was initialized.
   */
   private check(): boolean {
    return isPlatformBrowser(this.platformId) && ((typeof (<any>window).$crisp !== 'undefined') || this.created);
  }

}

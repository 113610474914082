/* eslint-disable @typescript-eslint/naming-convention */
import mixpanel from 'mixpanel-browser';
import { Injectable, isDevMode } from '@angular/core';
import { AppConfig } from '../../app.config';
import { getAccountFlag } from '../../util/accountFlag';

@Injectable({
  providedIn: 'root'
})
export class MixpanelService {

  private initialized = false;
  private identified = false;

  constructor() { }

  private get allowTracking() {
    return getAccountFlag<boolean>('enableMixpanel') === true && (navigator.doNotTrack !== '1');
  }


  /**
   * This shall only be called when a user has allowed tracking.
   */
  initialize() {
    if (!mixpanel || this.initialized || !this.allowTracking) {
      return;
    }

    if (AppConfig.mixpanelKey) {
      mixpanel.init(AppConfig.mixpanelKey, {
        debug: isDevMode(),
        api_host: AppConfig.mixpanelHost,
        loaded: (_) => {
          // fixme: this could create race-conditions where mixpanel is not yet loaded but the user already identified
          this.initialized = true;
        }
      });
      this.initialized = true;
    }
  }

  identify(userId: string) {
    if (userId && this.initialized && mixpanel && !this.identified) {
      mixpanel.identify(`${userId}`);
      this.identified = true;
    }
  }

  track(eventName: string, properties?: any) {
    if (this.initialized && mixpanel) {
      mixpanel.track(eventName, properties);
    }
  }

  setUser(u: any) {
    if (!u) {
      return;
    }

    if (!this.initialized) {
      if (this.allowTracking) {
        this.initialize();
      } else {
        return;
      }
    }

    if (!this.identified) {
      this.identify(u._id);
    }

    const user = {
      $email: u.email,
      $first_name: u.firstName,
      $last_name: u.lastName,
      'Product Type': u.productType
    };

    if (u.supervisorProfile) {
      user['Supervisor Profile ID'] = u.supervisorProfile;
    }

    mixpanel.people.set(user);
  }
}

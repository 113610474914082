import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import localePt from '@angular/common/locales/pt';

registerLocaleData(localeDe);
registerLocaleData(localePt);

export function _(str: string): string {
  return str;
}

<div class="website">
  <h1 class="title">
    {{'website.title' | translate}}
  </h1>
  <br><br>
  <p>
    {{'website.message' | translate}}
  </p>

  <p *ngIf="accessLevel && !accessLevel.write" class="warning">
    <span>{{'word.NOTICE' | translate}}</span>
    {{'word.readonlyAccess' | translate}}
  </p>
  <br><br>

  <div class="entry-group">

    <h2>{{'website.encryption.title' | translate }}</h2>

    <div class="subtitle">
      {{'website.encryption.subtitle' | translate }}
    </div>

    <div class="entry">

      <div class="inner">
        <big-round-checkbox
          [readonly]="!canWrite"
          [value]="controllerInfo?.httpsOn"
          (valueChanged)="updateCheckbox('httpsOn', $event)"></big-round-checkbox>
        <div class="title">
          {{'website.encryption.httpsOn.part1' | translate }}
          {{controllerInfo?.controllerUrl || '_____'}} {{'website.encryption.httpsOn.part2' | translate }}
          <br/>
          {{'website.encryption.httpsOn.part3' | translate}}
        </div>
      </div>
    </div>

    <div class="entry">

      <div class="inner">
        <big-round-checkbox
          [readonly]="!canWrite"
          [value]="controllerInfo?.httpsRedirect"
          (valueChanged)="updateCheckbox('httpsRedirect', $event)"></big-round-checkbox>
        <div class="title">
          {{'website.encryption.httpsRedirect.title' | translate }}
        </div>
      </div>
    </div>

  </div>
  <div class="entry-group">

    <h2>{{'website.infObligations.title' | translate }}</h2>

    <div class="subtitle">
      {{'website.infObligations.subtitle' | translate }}
    </div>
    <div class="entry">

      <br/>
      <div class="inner">
        <big-round-checkbox
          [readonly]="!canWrite"
          [value]="controllerInfo?.cookiePopupEnabled"
          (valueChanged)="updateCheckbox('cookiePopupEnabled', $event)"></big-round-checkbox>
        <div class="title">
          {{'website.infObligations.cookiePopupEnabled.part1' | translate}}
          {{controllerInfo?.controllerUrl || '_____'}}
          {{'website.infObligations.cookiePopupEnabled.part2' | translate}}
        </div>
      </div>
    </div>

    <div class="entry">

      <div class="inner">
        <big-round-checkbox
          [readonly]="!canWrite"
          [value]="controllerInfo?.cookiePolicyInPlace"
          (valueChanged)="updateCheckbox('cookiePolicyInPlace', $event)"></big-round-checkbox>
        <div class="title">
          {{'website.infObligations.cookiePolicyInPlace.subtitle' | translate}}

        </div>
      </div>
    </div>

    <div class="entry">

      <div class="inner">
        <div class="title">
          {{'website.infObligations.urlCookiePolicy.subtitle' | translate}}
        </div>
      </div>
      <div class="textbox">
        <mat-form-field>
            <textarea matInput matTextareaAutosize
                      [readonly]="!canWrite"
                      [ngModel]="controllerInfo?.urlCookiePolicy"
                      (ngModelChange)="updateUrl('urlCookiePolicy', $event)"
                      placeholder="{{'website.infObligations.urlCookiePolicy.placeholder' | translate}} "></textarea>
        </mat-form-field>
      </div>
    </div>

    <div class="entry">

      <div class="inner">
        <div class="title">
          {{'website.infObligations.urlPrivacyPolicy.subtitle' | translate}}
        </div>
      </div>
      <div class="textbox">
        <mat-form-field>
            <textarea matInput matTextareaAutosize
                      [readonly]="!canWrite"
                      [ngModel]="controllerInfo?.urlPrivacyPolicy"
                      (ngModelChange)="updateUrl('urlPrivacyPolicy', $event)"
                      placeholder="{{'website.infObligations.urlPrivacyPolicy.placeholder' | translate}}"></textarea>
        </mat-form-field>
      </div>
    </div>

  </div>
  <div class="entry-group">
    <h2>{{'website.contactForm.title' | translate }} </h2>

    <div class="subtitle">
      {{'website.contactForm.subtitle' | translate }}
    </div>

    <div class="entry">


      <br/>
      <div class="inner">
        <big-round-checkbox
          [readonly]="!canWrite"
          [value]="controllerInfo?.contactFormChecked"
          (valueChanged)="updateCheckbox('contactFormChecked', $event)"></big-round-checkbox>
        <div class="title">
          {{'website.contactFormChecked.title' | translate }}
        </div>
      </div>
    </div>

  </div>

  <button mat-raised-button color="primary" (click)="continue()" routerLink="">{{ 'word.done' | translate }}</button>

  <br/>
  <br/>
  <br/>
  <br/>
</div>

<h1 mat-dialog-title>
  {{ 'rpa.pa-list.actions.new-pa' | translate }}
</h1>
<p innerHTML="{{ 'rpa.new-pa-dialog.hint' | translate }}">
</p>

<mat-dialog-content>
  <br/>
  <div class="list">
    <starter-pa-tree-node *ngFor="let node of tree"
      [node]="node"
      action="{{'word.create' | translate}}"
      (picked)="create($event)">
    </starter-pa-tree-node>
    <starter-pa *ngFor="let pa of freePas" [pa]="pa" action="{{'word.create' | translate}}" (picked)="create($event)">
    </starter-pa>
  </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button tabindex="-1" color="primary" mat-dialog-close routerLink="/rpa/custom-pa">
  {{ 'custom-pa.titel' | translate }}
  </button>
  <button mat-button mat-dialog-close>{{'word.cancel' | translate}}</button>
</mat-dialog-actions>
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import {Audit, AuditsService, AuditType} from 'app/services/audits/audits.service';
import { UserManagementService } from 'app/services/user/user-management.service';
import {TranslateService} from "@ngx-translate/core";
import {ShareDocumentDialogComponent} from "../../documents/shared/share-dialog/share-dialog.component";
import {zip} from "rxjs";
import {DialogComponent} from "../../dialog/dialog.component";

@Component({
  selector: 'app-audit-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
})
export class AuditListComponent implements OnInit, OnDestroy {
  audits: Audit[] = [];
  auditTypes: AuditType[] = [];

  loadingAudits = false;

  users = [];

  constructor(
    private auditService: AuditsService,
    private userService: UserManagementService,
    private translate: TranslateService,
    private dialog: MatDialog,
    private router: Router
  ) {}

  get isSupervisor() {
    return localStorage.getItem('isInSupervisionMode') === 'true';
  }

  createNewAudit(type: AuditType) {
    this.auditService.addAudit(type.type).subscribe((audit) => {
      this.fetchAudits();
    });
  }

  auditsForType(type: AuditType) {
    return this.audits.filter((audit) => audit.auditType === type.type);
  }

  fetchAudits() {
    this.loadingAudits = true;

    this.auditService.getAllAudits().subscribe((audits) => {
      this.audits = audits;
      this.loadingAudits = false;
    });
  }

  ngOnInit() {
    this.fetchAudits();

    this.auditService.getAuditTypes().subscribe((types) => {
      const currentLang = this.translate.currentLang || this.translate.defaultLang;
      this.auditTypes = types.sort((a, b) => a.order - b.order).filter((a) => a.language === currentLang);
    });

    this.userService.users(true).subscribe((response) => {
      this.users = response.map((user) =>
        Object.assign({}, user, {
          name: `${user.firstName} ${user.lastName}`,
        })
      );
    });
  }

  ngOnDestroy() {}

  downloadLink(audit: Audit) {
    return this.auditService.auditDownloadLink(audit.id);
  }


  /*
  * Audit actions
  */

  shareReport(audit: Audit) {
    this.dialog.open(ShareDocumentDialogComponent, { data: { sourceUrl: this.downloadLink(audit) }, width: '450px' });
  }

  duplicateAudit(audit: Audit) {
    this.auditService.duplicateAudit(audit)
      .subscribe((newAudit) => {
        this.fetchAudits();
      });
  }

  delegateAudit(audit: Audit) {
    this.auditService.delegateAudit(audit);
  }

  deleteAudit(audit: Audit) {
    zip(
      this.translate.get('audits.list.item.delete.title'),
      this.translate.get('audits.list.item.delete.message')
    )
      .subscribe(([title, message]) => {
        this.dialog.open(DialogComponent, { maxWidth: '450px', data: { header: title, message, confirm: true }})
          .afterClosed()
          .subscribe((confirmed) => {
            if (confirmed) {
              this.auditService.deleteAudit(audit)
                .subscribe(() => {
                  this.fetchAudits();
                });
            }
          });
      });
  }
}

<table mat-table matSort [dataSource]="dataSource" matSortActive="dueAt" matSortDirection="asc">
  <ng-container matColumnDef="title">
    <mat-header-cell *matHeaderCellDef mat-sort-header>{{'measures.list.item.title' | translate}}</mat-header-cell>
    <mat-cell *matCellDef="let measure">
      <div>
        {{measure.title}}
      </div>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="responsibleEntity">
    <mat-header-cell *matHeaderCellDef mat-sort-header>{{'measures.list.item.responsibleEntity' | translate}}</mat-header-cell>
    <mat-cell *matCellDef="let measure">{{measure.responsibleEntity}}</mat-cell>
  </ng-container>

  <!-- {{ 'measure.status.planned' | translate }} -->
  <!-- {{ 'measure.status.done' | translate }} -->
  <!-- {{ 'measure.status.in_progress' | translate }} -->
  <ng-container matColumnDef="dueAt">
    <mat-header-cell *matHeaderCellDef mat-sort-header>{{'measures.list.item.dueAt' | translate}}</mat-header-cell>
    <mat-cell *matCellDef="let measure">
      <span [class.overdue]="measureOverdue(measure)" [class.archived]="measure.status === 'done'" [class.due]="measureDue(measure)">{{measure.dueAt | localizedDate}}</span>
      <span class="overdue" *ngIf="measureOverdue(measure)" matTooltip="{{'measures.list.item.overdue' | translate}}"></span>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="progress">
    <mat-header-cell *matHeaderCellDef mat-sort-header>{{'measures.list.item.progress' | translate}}</mat-header-cell>
    <mat-cell *matCellDef="let measure"><span [class.archived]="measure.status === 'done'"><ng-container *ngIf="!measure.progress">---</ng-container>{{measure.progress | percent }}</span></mat-cell>
  </ng-container>

  <ng-container matColumnDef="status">
    <mat-header-cell *matHeaderCellDef mat-sort-header>{{'measures.list.item.status' | translate}}</mat-header-cell>
    <mat-cell *matCellDef="let measure" matTooltip="{{ 'measure.status.' + measure.status | translate }}">
      <mat-icon *ngIf="measure.status==='done'" class="done" color="primary">done</mat-icon>
      <mat-icon *ngIf="measure.status==='planned'">schedule</mat-icon>
      <mat-icon *ngIf="measure.status==='in_progress'">pending_actions</mat-icon>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="doneAt">
    <mat-header-cell *matHeaderCellDef mat-sort-header>{{'measures.list.item.doneAt' | translate}}</mat-header-cell>
    <mat-cell *matCellDef="let measure">{{measure.doneAt | localizedDate}}</mat-cell>
  </ng-container>

  <ng-container matColumnDef="actions">
    <mat-header-cell *matHeaderCellDef>{{'measures.list.item.actions' | translate}}</mat-header-cell>
    <mat-cell *matCellDef="let measure">
      <button mat-icon-button color="primary" matTooltip="{{ 'measures.list.item.moreMenu' | translate }}" [matMenuTriggerFor]="menu" [matMenuTriggerData]="{ measure: measure }" (click)="$event.stopPropagation()">
        <mat-icon>more_vert</mat-icon>
      </button>

      <div class="comment-container">
        <div commentable
             comment-subject-codename="measure-{{measure.id}}"
             comment-subject-title="{{'measures.list.commentSubject.part1' | translate}} {{measure.title}} {{'measures.list.commentSubject.part2' | translate}}">
          <todoable subject="measure-{{measure.id}}" suggested-title="{{'measures.list.commentSubject.part1' | translate}} {{measure.title}} {{'vendors.list.commentSubject.part2' | translate}}"></todoable>
        </div>
      </div>
    </mat-cell>
  </ng-container>

  <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
  <mat-row *matRowDef="let row; columns: columns" matRipple (click)="showDetails($event, row)"></mat-row>
</table>


<!-- More Menu -->
<mat-menu #menu="matMenu">
  <ng-template matMenuContent let-measure="measure">
    <button mat-menu-item [disabled]="measure.status === 'done'" (click)="markAsDone($event, measure)">{{ "measures.list.item.markAsDone" | translate }}</button>
    <button mat-menu-item (click)="copy(null, measure)">{{ "measures.list.item.copy" | translate }}</button>
    <button mat-menu-item (click)="deleteMeasure(null, measure)">{{ "measures.list.item.delete" | translate }}</button>
  </ng-template>
</mat-menu>

import {Injectable} from '@angular/core';
import {RequestService} from '../request.service';
import {EventsService} from '../events.service';
import {BusService} from '../bus.service';


// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface GetRequest {}

export interface ControllerModel {
  controllerName: string;
  controllerUrl: string;
  controllerAddressStreet: string;
  controllerAddressPostcode: string;
  controllerAddressCity: string;
  controllerAddressCountry: string;
  controllerContactName: string;
  controllerContactRole: string;
  controllerDpCoordination: string;
  controllerEmailAddress: string;
  controllerNumEmployees: string;
  controllerTelephoneNumber: string;
  httpsOn: boolean;
  httpsRedirect: boolean;
  cookiePopupEnabled: boolean;
  cookiePolicyInPlace: boolean;
  urlPrivacyPolicy: string;
  urlCookiePolicy: string;
  contactFormChecked: boolean;
  clientId?: string;
  clientNotes?: string;
  correspondingSupervisor: number;
  serviced: boolean;
  _id?: number;
  supervisor?: number;
}

@Injectable()
export class ControllerService {

  constructor(private bus: BusService,
              private events: EventsService,
              private requestService: RequestService) {

    this.subscribe();
  }

  requestGet(_?: GetRequest) { // TODO: this argument is deprecated, remove it from the code
    return this.requestService.get<ControllerModel>({
      uri: `/controller/`,
      handlers: {
        success: result => this.bus.publish(this.events.received.data.controller.get.success, result),
        error: error =>
          this.bus.publish(this.events.received.data.controller.get.failure, error),
      }
    });
  }



  requestPut(data: ControllerModel) {
    // EC-2774: make sure we don't send more stuff (_extra) to the backend than we need to
    const model: Partial<ControllerModel> = {};
    for (const key in data) {
      if (key !== '_extra') {
        model[key] = data[key];
      }
    }

    return this.requestService.put<Partial<ControllerModel>, any> ( {
      uri: '/controller/',
      body: model,
      handlers: {
        success: result => this.bus.publish(this.events.received.data.controller.update.success, result),
        error: error =>
          this.bus.publish(this.events.received.data.controller.update.failure, error),
      }
    });
  }

  private subscribe() {
    this.bus.subscribe(this.events.requested.data.controller.get, this.requestGet.bind(this));
    this.bus.subscribe(this.events.requested.data.controller.update, this.requestPut.bind(this));
  }
}

import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ClientAccess, ClientAccessService } from '../../../../services/supervision/client-access.service';
import { SupervisorProfileService } from '../../../../services/supervision/supervisor-profile.service';

interface DialogPayload {
  client: { _id: number; controllerName: string; };
}

@Component({
  selector: 'app-client-access-user-dialog',
  templateUrl: './client-access-user-dialog.component.html',
  styleUrls: ['./client-access-user-dialog.component.scss']
})
export class ClientAccessUserDialogComponent implements OnInit {

  pendingUsers = true;
  pendingClientAccess = true;

  users = [];
  access: ClientAccess[] = [];

  constructor(
    private dialogRef: MatDialogRef<ClientAccessUserDialogComponent>,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: DialogPayload,
    private profileService: SupervisorProfileService,
    private clientAccessService: ClientAccessService
  ) { 
    this.fetchUsers();
    this.fetchClientAccess();
  }

  get pending() {
    return this.pendingUsers && this.pendingClientAccess;
  }

  fetchUsers() {
    this.profileService.getUsers().subscribe(users => {
      this.users = users;
      this.pendingUsers = false;
    });
  }

  fetchClientAccess() {
    this.clientAccessService.getAccessForClient(this.data.client._id).subscribe((access) => {
      this.access = access;
      this.pendingClientAccess = false;
    });
  }

  accessType(user: any) {
    if (user.supervisorClientAccessMode === 'all')
      return 'inherited';
    
    if (this.access.find((a) => a.supervisorId === user.id))
      return 'access-level'
  }

  hasAccess(user: any) {
    return this.access.find((a) => a.supervisorId === user.id && a.accessMode === 'allow');
  }

  canBeModified(user: any) {
    return user.supervisorRole !== 'admin';
  }

  updateAccess(checked: boolean, user) {
    if (checked) {
      this.clientAccessService.setClientAccess(user.id, this.data.client._id, 'allow')
        .subscribe((success) => {
          //console.log(success); // this is only printing null
        });
    } else {
      this.clientAccessService.deleteClientAccess(user.id, this.data.client._id)
        .subscribe((success) => {
          //console.log(success); // this is also only printing null
        });
    }
  }

  ngOnInit(): void {
  }

}

import { Injectable } from "@angular/core";
import { HttpErrorResponse } from "@angular/common/http";
import { BusService } from "../bus.service";
import {
  IGetOptions,
  IPostOptions,
  RequestService
} from "../request.service";
import { EventsService } from "../events.service";


export interface HasAppointmentResponse {
  hasAppointment: boolean
}

export interface GetDpoResponse {
  id: number;
  name: string;
  addressStreet: string;
  addressPostcode: string;
  addressCity: string;
  addressCountry: string;
  telephoneNumber: string;
  email: string;
}

export interface PutDpoRequest {
  name: string;
  addressStreet: string;
  addressPostcode: string;
  addressCity: string;
  addressCountry: string;
  telephoneNumber: string;
  email: string;
}

@Injectable()
export class DpoService {
  private api = {
    root: '/dpo',
    hasAppointment : '/has-appointment',
  }

  constructor(
    private bus: BusService,
    private events: EventsService,
    private requestService: RequestService
  ) {
    this.subscribe();
  }

  hasAppointment() {
    this.requestService.get<HasAppointmentResponse>({
      uri: this.api.root + this.api.hasAppointment,
      handlers: {
        success: response => this.bus.publish(this.events.received.data.dpo.hasAppointment.success, response),
        error: error => this.bus.publish(this.events.received.data.dpo.hasAppointment.failure, error),
      }
    });
  }

  get() {
    return this.requestService.get<GetDpoResponse>({
      uri: this.api.root,
      handlers: {
        success: dpo => this.bus.publish(this.events.received.data.dpo.get.success, dpo),
        error: error => this.bus.publish(this.events.received.data.dpo.get.failure, error),
      }
    });
  }

  put(data) {
    this.requestService.put<PutDpoRequest, {}>({
      uri: this.api.root,
      body: data,
      handlers: {
        success: () => this.bus.publish(this.events.received.data.dpo.put.success),
        error: () => this.bus.publish(this.events.received.data.dpo.put.failure),
      }
    });
  }

  subscribe() {
    this.bus.subscribe(this.events.requested.data.dpo.get, this.get.bind(this));
    this.bus.subscribe(this.events.requested.data.dpo.put, this.put.bind(this));
    this.bus.subscribe(this.events.requested.data.dpo.hasAppointment, this.hasAppointment.bind(this));
  }
}

<div class="todo-list">
  <ng-container *ngFor="let todo of todos; let last = last">
    <div [class.clickable]="canNavigateAway" (click)="canNavigateAway ? openTodoDetails($event, todo) : {}" class="todo">
      <div class="icon">
        <mat-icon>task_alt</mat-icon>
      </div>

      <div class="title" title="{{ todo.title }}">{{ todo.title }}</div>

      <div class="due-at">
        <span [class.due]="todoDue(todo)" [class.overdue]="todoOverdue(todo)" matTooltip="{{'todos.list.item.dueDate' | translate}}">{{ todo.dueDate | localizedDate }}</span>
      </div>

      <div class="assignee" matTooltip="{{'todos.list.item.assignee' | translate}}">{{todo.assignee.firstName}} {{todo.assignee.lastName}}</div>
    </div>
    <mat-divider *ngIf="!last"></mat-divider>
  </ng-container>
</div>

<a *ngIf="hasMore" routerLink="/todolist">{{'todos.list.showMore' | translate}}</a>

<p class="no-todos" *ngIf="openTodos.length === 0">
  {{"todos.noTodos.taskTitle" | translate}}
  <mat-icon>assignment_turned_in</mat-icon>
</p>

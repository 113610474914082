import { animate, state, style, transition, trigger } from '@angular/animations';
import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, zip } from 'rxjs';
import { RpaExternallyProcessedService } from '../../services/rpa/rpa.externally-processed.service';
import { VendorsService, ControllerVendor } from '../../services/vendors/vendors.service';
import { DialogComponent } from '../dialog/dialog.component';
import { VendorAddDialogComponent } from '../rpa/pa-external-processors/vendor-add-dialog/vendor-add-dialog.component';
import { auditRiskClass } from '../vendors/audit/util';

@Component({
  selector: 'app-data-recipients',
  templateUrl: './data-recipients.component.html',
  styleUrls: ['./data-recipients.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('void', style({ transform: 'translateY(-36px)', visibility: 'hidden', opacity: '0' })),
      state('*', style({ transform: 'translateY(0)', visibility: 'visible', opacity: '1' })),
      transition('void <=> *', animate('100ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ]
})
export class DataRecipientsComponent implements OnInit, AfterViewInit, OnChanges {
  @Input() vendors: ControllerVendor[] = [];
  /** whether inputs can be changed */
  @Input() readonly = false;
  /** standalone denotes if the component should add a button for new vendors to be added */
  @Input() standalone = false;
  /** paId is necessary to identify if the vendors are associated to a PA or not */
  @Input() paId: number = null;

  /** emitted whenever a vendor gets changed, added (in standalone mode) or removed */
  @Output() updated = new EventEmitter<ControllerVendor>();

  @ViewChild(MatSort, { static: false }) sort: MatSort;

  dataSource = new MatTableDataSource<ControllerVendor>();

  displayedColumns = ['metadata', 'processingStart', 'processingEnd', 'countPas', 'auditScore', 'hasDpa', 'dpaSigned', 'transfers', 'deleteAction', 'comments'];

  constructor(
    private vendorsService: VendorsService,
    private paExternalProcessors: RpaExternallyProcessedService,
    private dialog: MatDialog,
    private translate: TranslateService
  ) { }

  ngOnInit(): void {
    this.dataSource = new MatTableDataSource<ControllerVendor>();
    this.dataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'start':
          return item.contractStart ? item.contractStart.toISOString() : null;
        case 'end':
          return item.contractEnd ? item.contractEnd.toISOString() : null;
        case 'paCount':
          return item.id;
        case 'auditScore':
          return item.answers?.['_overall_rating_severity'];
        case 'dpa':
          return item.docPath || item.docPath === '' ? 'true' : 'false';
        case 'signed':
          return item.signed ? 'true' : 'false';
        case 'transfers':
          return item.answers?.['_third_country_transfers_applies'];
        case 'metadata':
          return item.name;
      }
    };
  }

  ngAfterViewInit() {
    this.dataSource.data = this.vendors;
    this.dataSource.sort = this.sort;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.vendors) {
      this.dataSource.data = this.vendors;
    }
  }

  removeAction(event: any, vendor: ControllerVendor) {
    if (event) {
      event.stopPropagation();
    }

    zip(
      this.translate.get('data-recipients.recipient.removeVendor.title'),
      this.translate.get('data-recipients.recipient.removeVendor.text', { name: vendor.name })
    ).subscribe(([title, text]) => {
      this.dialog.open(DialogComponent, { data: { header: title, message: text, confirm: true }})
        .afterClosed()
        .subscribe(result => {
          if (result) {
            this.removeVendor(vendor);
          }
        });
    });
  }

  editVendor(vendor: ControllerVendor) {
    this.vendorsService.getSingle(vendor.vendorId)
      .subscribe(v => {
        if (v) {
          this.dialog.open(VendorAddDialogComponent, { data: { vendor: v }, width: '600px' })
            .afterClosed()
            .subscribe(result => {
              if (result) {
                Object.assign(vendor, result);
                this.updated.emit(vendor);
              }
            });
        }
      });
  }

  // vendor audit specific stuff
  auditOverallRisk(vendor: ControllerVendor) {
    return auditRiskClass({ answers: vendor.answers } as any, '_overall_rating_severity');
  }

  // vendor details
  addressComplete(vendor: ControllerVendor) {
    return vendor.addressCity && vendor.addressCountry && vendor.addressStreet && vendor.addressPostcode;
  }

  private removeVendor(vendor: ControllerVendor) {
    (this.paId
      ? this.paExternalProcessors.disassocaite({ paId: this.paId, vendorId: vendor.vendorId })
      : this.vendorsService.removeControllerVendor(vendor.vendorId))
      .subscribe((success) => {
        this.updated.emit(null);
      });
  }
}

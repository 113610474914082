import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { BusService } from '../../services/bus.service';
import { EventsService } from '../../services/events.service';
import { AccessLevelService } from '../../services/user/access-level.service';

import { EditUserDialogComponent } from './edit-user-dialog/edit-user-dialog.component';
import { InviteUserDialogComponent } from './invite-user-dialog/invite-user-dialog.component';
import { ManageInviteDialogComponent } from './manage-invite-dialog/manage-invite-dialog.component';


@Component({
  selector: 'app-user-management',
  templateUrl: './user-management.component.html',
  styleUrls: ['./user-management.component.scss']
})
export class UserManagementComponent implements OnInit, OnDestroy {

  users = [];
  invites = [];
  departments = [];

  accessLevel = {
    read: true,
    write: false,
  }

  constructor(
    private bus: BusService,
    private events: EventsService,
    private dialog: MatDialog,
    private accessService: AccessLevelService,
  ) {
    this.accessService.checkAccess({
      context: 'user-management',
    }).subscribe(response => this.accessLevel = response);
  }

  ngOnInit() {
    this.subscribe();
    this.bus.publish(this.events.requested.data.controller.users);
    this.bus.publish(this.events.requested.data.controller.invites);
    this.bus.publish(this.events.requested.data.departments.get);
  }

  ngOnDestroy() {
    this.unsubscribe();
  }

  updateUsers(users) {
    this.users = users.filter(user => !user.deleted);
  }

  updateInvites(invites) {
    this.invites = invites;
  }

  updateDepartments(departments) {
    this.departments = departments;
  }

  openUserDialog(user) {
    if (this.accessLevel.write) {
      this.dialog.open(EditUserDialogComponent, {
        width: '350px',
        maxHeight: '90vh',
        data: {
          user: user,
          departments: this.departments,
        },
      });
    }
  }

  openInviteDialog(invite) {
    if (this.accessLevel.write) {
      this.dialog.open(ManageInviteDialogComponent, {
        width: '350px',
        maxHeight: '90vh',
        data: {
          invite: invite,
        }
      }).afterClosed().subscribe(mutated => {
        if (mutated) this.bus.publish(this.events.requested.data.controller.invites);
      });
    }
  }

  userDepartment(user) {
    let candidates = this.departments.filter(dep => dep.id == user.departmentId);
    if (candidates.length > 0) return candidates[0];
  }

  newInvitation() {
    if (this.accessLevel.write) {
      this.dialog.open(InviteUserDialogComponent, {
        width: '512px',
        maxHeight: '90vh',
        data: {
          departments: this.departments,
        }
      }).afterClosed().subscribe(mutated => {
        if (mutated) this.bus.publish(this.events.requested.data.controller.invites);
      });
    }
  }

  subscribe() {
    this.bus.subscribe(this.events.received.data.controller.users.success, this.updateUsers, this);
    this.bus.subscribe(this.events.received.data.controller.invites.success, this.updateInvites, this);
    this.bus.subscribe(this.events.received.data.departments.get.success, this.updateDepartments, this);
  }

  unsubscribe() {
    this.bus.unsubscribe(this.events.received.data.controller.users.success, this.updateUsers);
    this.bus.unsubscribe(this.events.received.data.controller.invites.success, this.updateInvites);
    this.bus.unsubscribe(this.events.received.data.departments.get.success, this.updateDepartments);
  }
}

import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import _authorities from '../../../../../assets/authorities.json';

interface AuthorityTemplate {
  title: string;
  addressCountry: string;
  addressState: string;
  addressStreet: string;
  addressPostcode: string;
  addressCity: string;
  logoUrl: string;
}

@Component({
  selector: 'app-pick-authority-dialog',
  templateUrl: './pick-authority-dialog.component.html',
  styleUrls: ['./pick-authority-dialog.component.scss']
})
export class PickAuthorityDialogComponent implements OnInit, OnDestroy {

  search = '';
  selectedAuthority: AuthorityTemplate = null;

  constructor(
    public dialogRef: MatDialogRef<PickAuthorityDialogComponent>,
  ) { }

  ngOnInit() {
  }

  ngOnDestroy() {
  }

  get authorities(): AuthorityTemplate[] {
    if (this.search) {
      const search = this.search.toLowerCase();
      return _authorities.filter(this.match(this.search));
    }

    return _authorities;
  }

  selectAuthority(authority) {
    this.selectedAuthority = authority;
  }

  pick() {
    this.dialogRef.close(this.selectedAuthority);
  }
  
  private match = (search: string) => (authority: AuthorityTemplate) => {
    return authority.title.toLowerCase().indexOf(search) !== -1 || authority.addressCountry.toLowerCase().indexOf(search) !== -1 || authority.addressState.toLowerCase().indexOf(search) !== -1;
  }
}

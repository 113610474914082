<div class="measure-list">
  <ng-container *ngFor="let measure of measures; let last = last">
    <a [routerLink]="canNavigateAway ? '/measures' : null" [queryParams]="{ measure: measure.id }" class="measure">

      <div class="icon">
        <mat-icon>straighten</mat-icon>
      </div>

      <div class="title" title="{{ measure.title }}">{{ measure.title }}</div>

      <div class="due-at">
        <span [class.due]="measureDue(measure)" [class.overdue]="measureOverdue(measure)" matTooltip="{{'measures.list.item.dueAt' | translate}}">{{ measure.dueAt | localizedDate }}</span>
      </div>

      <div class="status" matTooltip="{{ 'measure.status.' + measure.status | translate }}">
        <mat-icon *ngIf="measure.status==='done'" class="done" color="primary">done</mat-icon>
        <mat-icon *ngIf="measure.status==='planned'">schedule</mat-icon>
        <mat-icon *ngIf="measure.status==='in_progress'">pending_actions</mat-icon>
      </div>
    </a>
    <mat-divider *ngIf="!last"></mat-divider>
  </ng-container>
</div>

<p class="no-measures" *ngIf="measures.length === 0">{{ 'measures.list.emptyDashboard' | translate }}</p>

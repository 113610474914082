<div class="question-split">
    <mat-radio-group [disabled]="readonly" [value]="this.selectedChoice.originalValue" (change)="this.selectedChoice.submit($event.value)">
        <mat-radio-button *ngFor="let choice of question.choices; let i = index" value="{{ i }}">{{ choice }}</mat-radio-button>
    </mat-radio-group>

    <ng-content></ng-content>

    <div *ngIf="this.selectedChoice.updatedValue !== null && question.conditionChildren" class="children">
        <app-audit-form-question [readonly]="readonly" *ngFor="let question of question.conditionChildren[this.selectedChoice.updatedValue]" [audit]="audit" [question]="question" [auditConfiguration]="auditConfiguration" path="{{ computedPath }}" nestLevel="{{ childNestLevel }}"></app-audit-form-question>
    </div>
</div>

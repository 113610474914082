<ng-container *ngIf="!data.success">
  <img mat-card-image src="assets/not-found.svg"/>
  <h1 mat-dialog-title>{{ 'change-password.failedTitle' | translate }}</h1>
  <mat-dialog-content>{{ 'change-password.failedSubtitle' | translate }}</mat-dialog-content>
</ng-container>

<ng-container *ngIf="data.success">
  <img mat-card-image src="assets/password-change.svg"/>
  <h1 mat-dialog-title>{{ 'word.success' | translate }}</h1>
  <mat-dialog-content>
    {{ 'change-password.changeSuccessful' | translate }}
  </mat-dialog-content>
</ng-container>

<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>{{ 'word.close' | translate }}</button>
</mat-dialog-actions>

import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { MixpanelService } from 'app/modules/mixpanel/mixpanel.service';

@Component({
  selector: 'app-lia-dialog',
  templateUrl: './lia-dialog.component.html',
  styleUrls: ['./lia-dialog.component.scss'],
})
export class LiaDialogComponent {
  liaFormGroup: FormGroup;
  liaData: any = {};

  constructor(
    private translate: TranslateService,
    private dialogRef: MatDialogRef<LiaDialogComponent>,
    private mixPanel: MixpanelService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if (localStorage.getItem('lia-data')) {
      this.liaData =
        JSON.parse(localStorage.getItem('lia-data')).find(
          (l) => l && l.paId === this.data.paId
        ) ?? {};
    }

    if (!this.liaData.paId) {
      this.liaData.paId = this.data.paId;
    }

    this.liaFormGroup = new FormGroup({
      purpose: new FormControl(this.liaData.purpose ?? ''),
      benefit: new FormControl(this.liaData.benefit ?? ''),
      otherParties: new FormControl(this.liaData.otherParties ?? ''),
      otherPartiesList: new FormControl(this.liaData.otherPartiesList ?? ''),
      publicInterest: new FormControl(this.liaData.publicInterest ?? ''),
      importanceOfBenefits: new FormControl(
        this.liaData.importanceOfBenefits ?? ''
      ),
      assistsAchievingTargetBenefits: new FormControl(
        this.liaData.assistsAchievingTargetBenefits ?? ''
      ),
      controllerAdheres: new FormControl(this.liaData.controllerAdheres ?? ''),
      targetCanBenefit: new FormControl(this.liaData.targetCanBenefit ?? ''),
      minimallyInvasive: new FormControl(this.liaData.minimallyInvasive ?? ''),
    });

    this.mixPanel.track('lia-assistant', { event: 'opened-form' });
  }

  getLiaAssessment() {
    let response = '';
    response += this.liaPurpose() + '\n'; // lia purpose
    response += this.liaBenefits() + '\n'; // lia benefit
    // response += this.liaFormGroup.get('otherParties').value + '\n'; // lia other parties have access
    response += this.otherPartiesList() + '\n'; // if other parties then show list
    response += this.publicInterestResponse() + '\n'; // is there public interest
    response += this.importanceResponse() + '\n'; // importance of benefits
    response += this.controllerAdheresResponse() + '\n'; // controller adheres
    response += this.assistsAchievingResponse() + '\n'; // lia assists on achieving targets
    response += this.goalCanBeAchievedOnlyFromThisProcessing() + '\n'; // the target can benefit
    response += this.isPropotional(); // is it minially invasive

    return response;
  }

  getLiaPreview(): string {
    return this.getLiaAssessment().replace(/\n/g, '<br>');
  }

  submitLia() {
    this.mixPanel.track('lia-assistant', { event: 'submitted-form' });
    this.dialogRef.close(this.getLiaAssessment());
  }

  liaPurpose() {
    if (this.liaFormGroup.get('purpose').value === '') {
      return '';
    }

    return this.translate.instant(
      'pa.legitimateInterestAssessment.dialog.purpose.answer',
      { answer: this.liaFormGroup.get('purpose').value }
    );
  }

  liaBenefits() {
    if (this.liaFormGroup.get('benefit').value === '') {
      return '';
    }

    return this.translate.instant(
      'pa.legitimateInterestAssessment.dialog.benefits.answer',
      { answer: this.liaFormGroup.get('benefit').value }
    );
  }

  otherPartiesList() {
    if (this.liaFormGroup.get('otherParties').value === '') {
      return '';
    }

    if (this.liaFormGroup.get('otherParties').value) {
      return this.translate.instant(
        'pa.legitimateInterestAssessment.dialog.otherParties.answer.yes',
        { answer: this.liaFormGroup.get('otherPartiesList').value }
      );
    } else {
      return this.translate.instant(
        'pa.legitimateInterestAssessment.dialog.otherParties.answer.no'
      );
    }
  }

  publicInterestResponse() {
    if (this.liaFormGroup.get('publicInterest').value === '') {
      return '';
    }

    return this.liaFormGroup.get('publicInterest').value
      ? this.translate.instant(
          'pa.legitimateInterestAssessment.dialog.publicInterest.answer.yes'
        )
      : this.translate.instant(
          'pa.legitimateInterestAssessment.dialog.publicInterest.answer.no'
        );
  }

  importanceResponse() {
    if (this.liaFormGroup.get('importanceOfBenefits').value === '') {
      return '';
    }

    return this.translate.instant(
      'pa.legitimateInterestAssessment.dialog.importanceOfBenefits.answer',
      { answer: this.liaFormGroup.get('importanceOfBenefits').value }
    );
  }

  assistsAchievingResponse() {
    if (this.liaFormGroup.get('assistsAchievingTargetBenefits').value === '') {
      return '';
    }

    return this.liaFormGroup.get('assistsAchievingTargetBenefits').value
      ? this.translate.instant(
          'pa.legitimateInterestAssessment.dialog.assistsTarget.answer.yes'
        )
      : this.translate.instant(
          'pa.legitimateInterestAssessment.dialog.assistsTarget.answer.no'
        );
  }

  controllerAdheresResponse() {
    if (this.liaFormGroup.get('controllerAdheres').value === '') {
      return '';
    }

    return this.liaFormGroup.get('controllerAdheres').value
      ? this.translate.instant(
          'pa.legitimateInterestAssessment.dialog.controllerAdheres.answer.yes'
        )
      : this.translate.instant(
          'pa.legitimateInterestAssessment.dialog.controllerAdheres.answer.no'
        );
  }

  goalCanBeAchievedOnlyFromThisProcessing() {
    if (this.liaFormGroup.get('targetCanBenefit').value === '') {
      return '';
    }

    return this.liaFormGroup.get('targetCanBenefit').value
      ? this.translate.instant(
          'pa.legitimateInterestAssessment.dialog.targetCanBenefit.answer.yes'
        )
      : this.translate.instant(
          'pa.legitimateInterestAssessment.dialog.targetCanBenefit.answer.no'
        );
  }

  isPropotional() {
    if (this.liaFormGroup.get('minimallyInvasive').value === '') {
      return '';
    }

    return this.liaFormGroup.get('minimallyInvasive').value
      ? this.translate.instant(
          'pa.legitimateInterestAssessment.dialog.minimallyInvasive.answer.yes'
        )
      : this.translate.instant(
          'pa.legitimateInterestAssessment.dialog.minimallyInvasive.answer.no'
        );
  }

  onOptionChange(string, event) {
    this.liaFormGroup.get(string).setValue(event.value);
    this.liaData[string] = event.value;

    this.saveCurrentLiaData();
  }

  onInputFocusOut(string) {
    this.liaData[string] = this.liaFormGroup.get(string).value;

    this.saveCurrentLiaData();
  }

  saveCurrentLiaData() {
    const lias: any[] = JSON.parse(localStorage.getItem('lia-data')) ?? [];

    const lia = lias.find((l) => l && l.paId === this.data.paId);
    if (lia) {
      const index = lias.indexOf(lia);
      lias.splice(index, 1, this.liaData);
    } else {
      lias.push(this.liaData);
    }

    if (!lias.length) {
      lias.push(this.liaData);
    }

    localStorage.setItem('lia-data', JSON.stringify(lias));
  }
}

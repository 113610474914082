/* eslint-disable @typescript-eslint/naming-convention */
export const RiskAnalysisRiskConstants = {
  vendorSafetyFactor: .95,
  departmentSafetyFactor: .98,
  thresholds: [0, .15, .25, .35, 1.00],
  thresholdLabels: [
    'risk-analysis.risk.labels.negligible',
    'risk-analysis.risk.labels.low',
    'risk-analysis.risk.labels.medium',
    'risk-analysis.risk.labels.high'],
};

export const RiskAnalysisSeverityConstants = {
  thresholds: [0, 25, 50, 100, 100000000000],
  thresholdLabels: [
    'risk-analysis.severity.labels.negligible',
    'risk-analysis.severity.labels.low',
    'risk-analysis.severity.labels.medium',
    'risk-analysis.severity.labels.high'],
  scaleFactor: {
    'pa.dataSubject.categories.employees': 2,
    'pa.dataSubject.categories.applicants': 1.5,
    'pa.dataSubject.categories.trainees': .5,
    'pa.dataSubject.categories.temporaryWorkers': .3,
    'pa.dataSubject.categories.managers': .25,
    'pa.dataSubject.categories.commercialAgent': .1,
    'pa.dataSubject.categories.serviceProvider': .1,
    'pa.dataSubject.categories.suppliers': .2,
    'pa.dataSubject.categories.interestedParties': .2,
    'pa.dataSubject.categories.contractualPartner': .3,
    'pa.dataSubject.categories.customers': 6,
    'pa.dataSubject.categories.customersOfCustomers': 4,
    'pa.dataSubject.categories.employeesOfCustomers': 3,
    'pa.dataSubject.categories.members': 2,
    'pa.dataSubject.categories.debtor': .1,
    'pa.dataSubject.categories.investor': .1,
    'pa.dataSubject.categories.jointController': .1,
    'pa.dataSubject.categories.patients': 6,
  },

  sensitivityFactor: {
    'pa.dataSubject.dataCategories.name': 1.5,
    'pa.dataSubject.dataCategories.address': 1,
    'pa.dataSubject.dataCategories.email': 1,
    'pa.dataSubject.dataCategories.phone': 1,
    'pa.dataSubject.dataCategories.fax': 1,
    'pa.dataSubject.dataCategories.login': 3,
    'pa.dataSubject.dataCategories.messengerIdentity': 1,
    'pa.dataSubject.dataCategories.ageRanges': 1,
    'pa.dataSubject.dataCategories.birthInformation': 1.5,
    'pa.dataSubject.dataCategories.family': 2,
    'pa.dataSubject.dataCategories.maritalStatus': 1,
    'pa.dataSubject.dataCategories.likes': 10,
    'pa.dataSubject.dataCategories.citizenship': 1.5,
    'pa.dataSubject.dataCategories.passportId': 20,
    'pa.dataSubject.dataCategories.educationalInfo': 1.5,
    'pa.dataSubject.dataCategories.employmentInformation': 2,
    'pa.dataSubject.dataCategories.licensePlate': 2,
    'pa.dataSubject.dataCategories.insuranceInformation': 12,
    'pa.dataSubject.dataCategories.salaryInformation': 2,
    'pa.dataSubject.dataCategories.bankDetails': 12,
    'pa.dataSubject.dataCategories.orderHistory': 2,
    'pa.dataSubject.dataCategories.ip': 2,
    'pa.dataSubject.dataCategories.gps': 20,
    'pa.dataSubject.dataCategories.location': 20,
    'pa.dataSubject.dataCategories.social': 2,
    'pa.dataSubject.dataCategories.criminal': 40,
    'pa.dataSubject.dataCategories.biometricInfo': 40,
    'pa.dataSubject.dataCategories.geneticData': 40,
    'pa.dataSubject.dataCategories.physicalTraits': 3,
    'pa.dataSubject.dataCategories.imageData': 3,
    'pa.dataSubject.dataCategories.ethnicity': 20,
    'pa.dataSubject.dataCategories.healthData': 40,
    'pa.dataSubject.dataCategories.religion': 20,
    'pa.dataSubject.dataCategories.tradeUnion': 20,
    'pa.dataSubject.dataCategories.politicalInformation': 20,
    'pa.dataSubject.dataCategories.sex': 1,
    'pa.dataSubject.dataCategories.indicatorsOfBehaviour': 20,
    'pa.dataSubject.dataCategories.personalityTraits': 20,
    'pa.dataSubject.dataCategories.otherIdNumbers': 1, // lgpd only
  },
};


export function incidentProbabilityIndex(probability: number) {
  for (let i = 0; i < RiskAnalysisRiskConstants.thresholds.length; i++) {
    if (probability >= RiskAnalysisRiskConstants.thresholds[i]
      && (
        i === RiskAnalysisRiskConstants.thresholds.length - 1
        || probability < RiskAnalysisRiskConstants.thresholds[i + 1]
      )
    ) {
      return i;
    }
  }

  return -1;
}

export function incidentSeverityIndex(severity: number) {
  for (let i = 0; i < RiskAnalysisSeverityConstants.thresholds.length; i++) {
    if (severity >= RiskAnalysisSeverityConstants.thresholds[i]
      && (
        i === RiskAnalysisSeverityConstants.thresholds.length - 1
        || severity < RiskAnalysisSeverityConstants.thresholds[i + 1]
      )
    ) {
      return i;
    }
  }

  return -1;
}


export type RiskAnalysisClassification =
  'unknown' | 'safe' | 'mostly-safe' | 'warning' | 'serious-warning' | 'danger' | 'catastrophe';


export function riskAnalysisClassification(riskIndex, severityIndex): RiskAnalysisClassification {
  if (riskIndex === -1 || severityIndex === -1) {
    return 'unknown';
  }

  if (severityIndex === 3) {
    return 'catastrophe';
  }
  if (riskIndex + severityIndex <= 0) {
    return 'safe';
  }
  if (riskIndex + severityIndex <= 1) {
    return 'mostly-safe';
  }
  if (riskIndex + severityIndex <= 2) {
    return 'warning';
  }
  if (riskIndex + severityIndex <= 3) {
    return 'serious-warning';
  }
  if (riskIndex + severityIndex <= 4) {
    return 'danger';
  }

  return 'catastrophe';
}


/**
 *
 * This gives a direct and imprecise classification based on value.
 * This is dependent on actual threshold values and might not work properly
 * if thresholds are changed and orders of possible values are not preserved.
 *
 */
export function riskAnalysisClassificationByValue(dpiaValue): RiskAnalysisClassification {
  //
  // The values of this array should be ascending. When threshold values
  // are modified, ensure that this property still holds.
  //
  const thresholds = [
    0,
    RiskAnalysisRiskConstants.thresholds[1] * RiskAnalysisSeverityConstants.thresholds[1],
    RiskAnalysisRiskConstants.thresholds[2] * RiskAnalysisSeverityConstants.thresholds[1],
    RiskAnalysisRiskConstants.thresholds[3] * RiskAnalysisSeverityConstants.thresholds[1],
    RiskAnalysisRiskConstants.thresholds[3] * RiskAnalysisSeverityConstants.thresholds[2],
    RiskAnalysisRiskConstants.thresholds[2] * RiskAnalysisSeverityConstants.thresholds[3],
  ];

  if (dpiaValue === null || dpiaValue === undefined || dpiaValue < thresholds[0]) {
    return 'unknown';
  } else if (dpiaValue < thresholds[1]) {
    return 'safe';
  } else if (dpiaValue < thresholds[2]) {
    return 'mostly-safe';
  } else if (dpiaValue < thresholds[3]) {
    return 'warning';
  } else if (dpiaValue < thresholds[4]) {
    return 'serious-warning';
  } else if (dpiaValue < thresholds[5]) {
    return 'danger';
  } else {
    return 'catastrophe';
  }
}


export function riskAnalysisRiskIndex(riskValue: number) {
  return RiskAnalysisRiskConstants.thresholds.findIndex(n => n > riskValue) - 1;
}


export function riskAnalysisSeverityIndex(severityValue: number) {
  return RiskAnalysisSeverityConstants.thresholds.findIndex(n => n > severityValue) - 1;
}

/**
 *
 * NOTICE: this function is tightly coupled with "./_risk-analysis.grid.scss", as it
 *         returns class names that are picked up by mixins in that file.
 *
 */
export function riskAnalysisGridClass(riskIndex: number, severityIndex: number) {
  if (severityIndex === 3) {
    return 'catastrophe';
  }
  if (riskIndex + severityIndex <= 0) {
    return 'safe';
  }
  if (riskIndex + severityIndex <= 1) {
    return 'mostly-safe';
  }
  if (riskIndex + severityIndex <= 2) {
    return 'warning';
  }
  if (riskIndex + severityIndex <= 3) {
    return 'serious-warning';
  }
  if (riskIndex + severityIndex <= 4) {
    return 'danger';
  }
  return 'catastrophe';
}


export function riskAnalysisGridClassByValues(riskValue: number, severityValue: number) {
  return riskAnalysisGridClass(riskAnalysisRiskIndex(riskValue), riskAnalysisSeverityIndex(severityValue));
}


export const RiskAnalysisClassificationColors = {
  safe: '#4CAF50',
  'mostly-safe': '#8BC34A',
  warning: '#CDDC39',
  'serious-warning': '#FFC107',
  danger: '#FF5722',
  catastrophe: '#F44336',
  unknown: '#e0e0e0',
  'needs-clarification': '#7579e7',
};


export const RiskAnalysisClassificationLabels = {
  safe: 'risk-analysis.classifications.safe',
  'mostly-safe': 'risk-analysis.classifications.mostly-safe',
  warning: 'risk-analysis.classifications.warning',
  'serious-warning': 'risk-analysis.classifications.serious-warning',
  danger: 'risk-analysis.classifications.danger',
  catastrophe: 'risk-analysis.classifications.catastrophe',
  unknown: 'risk-analysis.classifications.unknown',
  'needs-clarification': 'risk-analysis.classifications.needs-clarification',
};

import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { TodoSearchCriteria, TodoService } from '../../../services/todos/todo.service';
import { Todo } from '../todo.interface';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { debounceTime, map, switchMap, tap } from 'rxjs/operators';

@Component({
  selector: 'app-subject-todo-list',
  templateUrl: './subject-todo-list.component.html',
  styleUrls: ['./subject-todo-list.component.scss'],
})
export class SubjectTodoListComponent implements OnInit, OnDestroy {

  todosList: Todo[] = [];
  assigned: Todo[] = [];
  showIssuer: (todo: Todo) => boolean;

  todoCriteria: TodoSearchCriteria;
  assignedCriteria: TodoSearchCriteria;

  fetchTodos = new Subject<TodoSearchCriteria>();
  fetchAssigned = new Subject<TodoSearchCriteria>();
  subs = new Subscription();

  constructor(
    private todoService: TodoService,
    @Inject(MAT_DIALOG_DATA) public subject,
  ) {
    this.todoCriteria = { subject };
    this.assignedCriteria = { subject };
  }

  ngOnInit(): void {
    this.subs.add(
      this.fetchTodos.pipe(
        tap(criteria => this.todoCriteria = Object.assign(criteria, { subject: this.subject })),
        debounceTime(0),
        switchMap(criteria => this.todoService.fetchTodos(criteria)),
        this.todoService.polish,
      ).subscribe(todos => this.todosList = todos as Todo[])
    );

    this.subs.add(
      this.fetchAssigned.pipe(
        tap(criteria => this.assignedCriteria = Object.assign(criteria, { subject: this.subject })),
        debounceTime(0),
        switchMap(criteria => this.todoService.getAssigned(criteria)),
        map(response => response.todos),
        this.todoService.polish,
      ).subscribe(todos => this.todosList = todos as Todo[])
    );
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

}

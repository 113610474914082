<mat-dialog-content #dialogContent class="vendors-add-dialog-contents">
  <form [formGroup]="formGroup" novalidate>

    <span mat-dialog-title>
      {{'pa.vendors.addDialog.sections.name.title' | translate}}
    </span>

    <mat-form-field>
      <input #nameField matInput type="text"
            name="name"
            formControlName="name"
            required
            placeholder="{{'pa.vendors.addDialog.fields.name.label' | translate}}" autofocus/>
      <mat-hint>
        {{'pa.vendors.addDialog.fields.name.hint' | translate}}
      </mat-hint>
      <mat-error *ngIf="formGroup.get('name').hasError('minlength') && !formGroup.get('name').hasError('required')">
        {{'pa.vendors.addDialog.fields.name.tooShort' | translate}}
      </mat-error>
      <mat-error *ngIf="formGroup.get('name').hasError('required')">
        {{'pa.vendors.addDialog.fields.name.required' | translate}}
      </mat-error>
    </mat-form-field>

    <mat-form-field>
      <mat-select placeholder="{{ 'pa.vendors.addDialog.type.placeholder' | translate }}" formControlName="type">
        <mat-option [value]="'vendor'"> {{ 'pa.vendors.addDialog.type.vendor' | translate }}</mat-option>
        <mat-option [value]="'generic'"> {{ 'pa.vendors.addDialog.type.generic' | translate }}</mat-option>
      </mat-select>
      <mat-hint>
        {{ 'pa.vendors.addDialog.type.hint' | translate }}
      </mat-hint>
    </mat-form-field>

    <ng-container *ngIf="formGroup.value.type === 'vendor'">
      <mat-form-field>
        <input #emailField matInput type="text"
              name="email"
              formControlName="email"
              [required]="required"
              placeholder="{{'pa.vendors.addDialog.fields.email.label' | translate}}"/>
        <mat-hint>
          {{'pa.vendors.addDialog.fields.email.hint' | translate}}
        </mat-hint>
        <mat-error *ngIf="formGroup.get('email').hasError('email') && !formGroup.get('email').hasError('required')">
          {{'helper.pleaseEnterValidEmail' | translate}}
        </mat-error>
        <mat-error *ngIf="formGroup.get('email').hasError('required')">
          {{'pa.vendors.addDialog.fields.email.required' | translate}}
        </mat-error>
      </mat-form-field>

      <br/><br/>
      <span mat-dialog-title>
        {{'pa.vendors.addDialog.sections.address.title' | translate}}
      </span>

      <mat-form-field>
        <input #streetField matInput type="text"
              name="addressStreet"
              formControlName="addressStreet"
              [required]="required"
              placeholder="{{'pa.vendors.addDialog.fields.addressStreet.label' | translate}}"/>
        <mat-hint>
          {{'pa.vendors.addDialog.fields.addressStreet.hint' | translate}}
        </mat-hint>
        <mat-error *ngIf="formGroup.get('addressStreet').hasError('required')">
          {{'pa.vendors.addDialog.fields.addressStreet.required' | translate}}
        </mat-error>
      </mat-form-field>

      <mat-form-field>
        <input #cityField matInput type="text"
              name="addressCity"
              formControlName="addressCity"
              [required]="required"
              placeholder="{{'pa.vendors.addDialog.fields.addressCity.label' | translate}}"/>
        <mat-hint>
          {{'pa.vendors.addDialog.fields.addressCity.hint' | translate}}
        </mat-hint>
        <mat-error *ngIf="formGroup.get('addressCity').hasError('required')">
          {{'pa.vendors.addDialog.fields.addressCity.required' | translate}}
        </mat-error>
      </mat-form-field>

      <mat-form-field>
        <input #postcodeField matInput type="text"
              name="addressPostcode"
              formControlName="addressPostcode"
              [required]="required"
              placeholder="{{'pa.vendors.addDialog.fields.addressPostcode.label' | translate}}"/>
        <mat-hint>
          {{'pa.vendors.addDialog.fields.addressPostcode.hint' | translate}}
        </mat-hint>
        <mat-error *ngIf="formGroup.get('addressPostcode').hasError('required')">
          {{'pa.vendors.addDialog.fields.addressPostcode.required' | translate}}
        </mat-error>
      </mat-form-field>

      <mat-form-field>
        <input #countryField matInput type="text"
              name="addressCountry"
              formControlName="addressCountry"
              [required]="required"
              placeholder="{{'pa.vendors.addDialog.fields.addressCountry.label' | translate}}"/>
        <mat-hint>
          {{'pa.vendors.addDialog.fields.addressCountry.hint' | translate}}
        </mat-hint>
        <mat-error *ngIf="formGroup.get('addressCountry').hasError('required')">
          {{'pa.vendors.addDialog.fields.addressCountry.required' | translate}}
        </mat-error>
      </mat-form-field>
    </ng-container>
  </form>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button type="button" mat-button mat-dialog-close>
    {{'word.cancel' | translate}}
  </button>
  <button type="button" mat-button [color]="(formGroup.dirty && !formGroup.valid)?'warn':'primary'" (click)="save()">
    {{'pa.vendors.addDialog.save' | translate}}
  </button>
</mat-dialog-actions>

<mat-form-field>
  <mat-label>{{'todos.create.assignee'|translate}}</mat-label>
  <input type="text" matInput [formControl]="assignee" [matAutocomplete]="auto" required>
  <mat-autocomplete #auto="matAutocomplete" [displayWith]="showAssignees">
    <mat-option *ngFor="let assignee of filteredAssignees | async" [value]="assignee">
      {{assignee.firstName}} {{assignee.lastName}} <{{assignee.email}}>
    </mat-option>
  </mat-autocomplete>
  <mat-hint>{{ 'todos.create.assignee.hint' | translate }}</mat-hint>
</mat-form-field>

<div class="contents">
  <h1 class="title">
    {{'rpa.tutorial.title' | translate}}
  </h1>
  <stepper #stepper>
    <stepper-step>
      <mat-card (click)="stepper.next()">
        <img mat-card-image src="assets/rpa-tutorial/Rpa-Next.svg"/>
        <p>
          {{'rpa.tutorial.slide1.message.beforeCompanyName' | translate}}
          <a>{{ companyName }}</a>
          {{'rpa.tutorial.slide1.message.afterCompanyName' | translate}}
        </p>
        <mat-card-actions align="end">
          <button mat-button color="primary">
            {{'rpa.tutorial.slide1.action' | translate}}
          </button>
        </mat-card-actions>
      </mat-card>
    </stepper-step>

    <stepper-step (activation)="progress(0.25)">
      <mat-card (click)="stepper.next()">
        <img mat-card-image src="assets/rpa-tutorial/Rpa-PII.svg"/>
        <p>
          {{'rpa.tutorial.slide2.message' | translate}}
        </p>
        <mat-card-actions align="end">
          <button mat-button color="primary">
            {{'rpa.tutorial.slide2.action' | translate}}
          </button>
        </mat-card-actions>
      </mat-card>
    </stepper-step>

    <stepper-step (activation)="progress(0.5)">
      <mat-card (click)="stepper.next()">
        <img mat-card-image src="assets/rpa-tutorial/Rpa-Purpose.svg"/>
        <p>
          <span innerHTML="{{'rpa.tutorial.slide3.message.beforeCompanyName' | translate}}"></span>
          <a>{{companyName}}</a>
          <span innerHTML="{{'rpa.tutorial.slide3.message.afterCompanyName' | translate}}"></span>
        </p>
        <mat-card-actions align="end">
          <button mat-button color="primary">
            {{'rpa.tutorial.slide3.action' | translate}}
          </button>
        </mat-card-actions>
      </mat-card>
    </stepper-step>

    <stepper-step (activation)="progress(0.75)">
      <mat-card (click)="stepper.next()">
        <img mat-card-image src="assets/rpa-tutorial/Rpa-Pa.svg"/>
        <p>
          <span innerHTML="{{'rpa.tutorial.slide4.message' | translate}}"></span>
        </p>
        <mat-card-actions align="end">
          <button mat-button color="primary">
            {{'rpa.tutorial.slide4.action' | translate}}
          </button>
        </mat-card-actions>
      </mat-card>
    </stepper-step>

    <stepper-step (activation)="progress(1)">
      <mat-card>
        <img mat-card-image src="assets/rpa-tutorial/Rpa.svg"/>
        <p>
          <span innerHTML="{{'rpa.tutorial.slide5.message.beforeCompanyName' | translate}}"></span>
          <a>{{companyName}}</a>
          <span innerHTML="{{'rpa.tutorial.slide5.message.afterCompanyName' | translate}}"></span>
        </p>
        <mat-card-actions align="end">
          <button mat-button color="primary" routerLink="">
            {{'rpa.tutorial.slide5.action' | translate}}
          </button>
        </mat-card-actions>
      </mat-card>
    </stepper-step>
  </stepper>
</div>

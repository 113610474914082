import { Observable, of } from 'rxjs';
import { environment } from '../../environments/environment';

const ALLOWED_HOSTS = ['https://app-be.ecomply.io', 'http://localhost'];

export function isNotCustomerInstance() {
  return environment.envName === 'production' || !environment.production || (environment.envName === 'dynamic' && ALLOWED_HOSTS.some(h => (<any>window).env?.apiUrl?.startsWith(h)));
}

/**
 * Returns the instance name of the current environment.
 * If it's a dynamic environment, detection will be based on the current URL.
 * Otherwise, it'll be based on the environment name.
 */
export function getInstanceName(): Observable<string> {
  if (environment.envName === 'dynamic') {
    const url = (<any>window).env?.apiUrl;
    if (url.startsWith('https://vater-be.')) {
      return of('vater');
    }
    if (url.startsWith('https://vater-be-staging.')) {
      return of('VSO Staging');
    }
  }

  return of(environment.envName);
}

import { Injectable } from '@angular/core';

import {HttpErrorResponse} from '@angular/common/http';
import {BusService} from '../bus.service';
import {EventsService} from '../events.service';
import {
  IGetOptions,
  IPostOptions,
  RequestService
} from '../request.service';
import { Router } from '@angular/router';
import { AuthenticationService } from '../authentication.service';
import { MixpanelService } from '../../modules/mixpanel/mixpanel.service';
import { getToken } from '../../util/token';


@Injectable()
export class ClientSupervisionService {

  constructor(
    private bus: BusService,
    private events: EventsService,
    private requestService: RequestService,
    private router: Router,
    private auth: AuthenticationService,
    private mixpanel: MixpanelService
  ) {

  }

  public get isInSupervisionMode(): boolean {
    return localStorage.getItem('isInSupervisionMode') === 'true';
  }

  public startSupervision(token: string, url: string = '/dashboard') {
    // Supervisor Token Backup
    const supervisorToken = getToken();

    localStorage.setItem('supervisorToken', supervisorToken);

    localStorage.setItem('currentUser', token);
    localStorage.setItem('currentUserProductType', 'default');
    localStorage.setItem('isInSupervisionMode', 'true');
    localStorage.setItem('supervisorControllerId', this.auth.controllerId.toString());

    // Tell subscribing services (especially AuthenticationService) about the new token.
    this.bus.publish(this.events.notified.supervision.client.changed, { newToken: token });
    this.bus.publish(this.events.notified.supervision.client.started);

    this.mixpanel.track('supervision:start_supervision');

    this.router.navigateByUrl(url);
  }

  public endSupervision(reroute: boolean = true) {
    const supervisorToken = localStorage.getItem('supervisorToken');

    localStorage.setItem('currentUser', supervisorToken);
    localStorage.setItem('currentUserProductType', 'supervisor');
    localStorage.setItem('isInSupervisionMode', 'false');
    localStorage.setItem('currentUserControllerId', localStorage.getItem('supervisorControllerId'));

    localStorage.removeItem('isInSupervisionMode');
    localStorage.removeItem('supervisorToken');
    localStorage.removeItem('supervisorControllerId');
    // EE-1586: Under the assumption that supervisors don't expire, clean up expiry.
    localStorage.removeItem('isExpired');

    this.bus.publish(this.events.notified.supervision.client.changed, { newToken: supervisorToken });
    this.bus.publish(this.events.notified.supervision.client.ended);

    this.mixpanel.track('supervision:end_supervision');

    if (reroute) {
      this.router.navigate(['/supervision/clients']);
    }
  }
}

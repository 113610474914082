import { Injectable } from '@angular/core';
import { IPostOptions, RequestService, IGetOptions } from '../request.service';
import { BusService } from '../bus.service';
import { EventsService } from '../events.service';
import { HttpErrorResponse } from '@angular/common/http';

interface PaymentPlan {
  id: string;
  productId: string;
  amount: number;
  interval: string;
  metadata?: any;
}

@Injectable()
export class PaymentPlanGetService {
  private options: IGetOptions<PaymentPlan[]>;

  constructor(
    private bus: BusService,
    private events: EventsService,
    private requestService: RequestService
  ) {
    this.options = {
      uri: '/payment/plans',
      handlers: {
        success: this.success.bind(this),
        error: this.error.bind(this)
      }
    };

    this.subscribe();
  }

  private subscribe(): void {
    this.bus.subscribe(this.events.requested.payment.plans, this.request.bind(this));
  }

  public request() {
    this.bus.publish(this.events.notified.request.starting);

    this.requestService.get(this.options);
  }

  public success(data: PaymentPlan[]) {
    this.bus.publish(this.events.received.payment.plans.success, data);
  }

  public error(httpErrorResponse: HttpErrorResponse) {
    this.bus.publish(this.events.received.payment.plans.failure, httpErrorResponse);
  }
}

<div class="event-path">
    <div class="path-items">
        <button mat-button color="primary" 
                class="path-entry event-category" [disabled]="!canFilter" (click)="requestFilter('category', $event)"
                matTooltip="{{'events.event-path.filter.by-category' | translate}}" [matTooltipDisabled]="!canFilter">
            {{ ('events.category.' + category + '.short') | translate }}
        </button>

        
        <mat-icon class="path-separator" *ngIf="entityIdentifier">chevron_right</mat-icon>
        

        <button mat-button color="primary" 
                *ngIf="entityIdentifier" class="path-entry event-entity" [disabled]="!canFilter" 
                (click)="requestFilter('item', $event)"
                matTooltip="{{'events.event-path.filter.by-entity' | translate}}" [matTooltipDisabled]="!canFilter">
            {{ entityIdentifier }}
            <span class="entity-name" *ngIf="loadedName">
                ("{{ entityName }}")
            </span>
        </button>
        <button mat-icon-button color="primary" *ngIf="canFilter" (click)="requestFilter('itemaction', $event)"
            matTooltip="{{'events.event-path.filter.by-entity-and-action' | translate}}" [matTooltipDisabled]="!canFilter">
            <mat-icon>arrow_forward</mat-icon>
        </button>
        <mat-icon *ngIf="!canFilter" class="path-separator">chevron_right</mat-icon>
        <button mat-button color="primary" class="path-entry event-action" [disabled]="!canFilter" 
                (click)="requestFilter('action', $event)"
                matTooltip="{{'events.event-path.filter.by-action' | translate}}" [matTooltipDisabled]="!canFilter">
            <!--{{ entityAction }}-->
            {{ 'events.identifier.' + event.category.toLowerCase() + '.' +
              event.eventName.toLowerCase() | translate }}
        </button>
    </div>
</div>
<div class="contents">
  <img src="/assets/badge.svg" alt="Sample Badge" />
  <br><br>
  <h1 class="title">
    {{'badge.get.title' | translate}}
  </h1>
  <p>
    {{'badge.get.message' | translate}}
  </p>
  <br>
  <mat-divider></mat-divider>
  <br>

  <p>
    {{'badge.get.message.help1' | translate}}
  </p>

  <br>

  <a href="{{badge()}}" target="_blank">
    <img src="{{badge()}}" alt="{{badge()}}" />
  </a>

  <div class="buttons">
    <button (click)="downloadBadge()" mat-raised-button color="primary">
      {{'badge.get.downloadBadge' | translate}}
    </button>
  </div>

  <br><br>

  <mat-divider></mat-divider>

  <p>
    {{'badge.get.message.help2' | translate}}
  </p>
  <p class="embed">
    {{infoUrl}}
  </p>
  <div class="buttons">
    <button (click)="visitPage()" mat-stroked-button color="primary">
      {{'badge.get.visitPage' | translate}}
    </button>
    <button
      (click)="copied()"
      mat-raised-button
      color="primary"
      ngxClipboard [cbContent]="infoUrl">{{'word.copy' | translate}}</button>
  </div>

  <br><br>
</div>

import { Injectable } from '@angular/core';
import { IPostOptions, RequestService, IGetOptions } from '../../request.service';
import { BusService } from '../../bus.service';
import { EventsService } from '../../events.service';
import { HttpErrorResponse } from '@angular/common/http';

interface SubscriptionDetails {
  id: number;
  periodStart: Date;
  periodEnd: Date;
  canceledAt: Date;
  planInterval: string;
  planAmount: number;
  planMetadata?: any;
  discount?: SubscriptionDiscount;
  status: string;
}

interface SubscriptionDiscount {
  couponId: string;
  percentOff: number;
}

@Injectable()
export class PaymentInfoGetSubscriptionDetailsService {
  private options: IGetOptions<SubscriptionDetails>;

  constructor(
    private bus: BusService,
    private events: EventsService,
    private requestService: RequestService
  ) {
    this.options = {
      uri: '/payment/info/subscription-details',
      handlers: {
        success: this.success.bind(this),
        error: this.error.bind(this)
      }
    };

    this.subscribe();
  }

  private subscribe(): void {
    this.bus.subscribe(this.events.requested.payment.info.subscriptionDetails, this.request.bind(this));
  }

  public request() {
    this.bus.publish(this.events.notified.request.starting);

    return this.requestService.get(this.options);
  }

  public success(data: SubscriptionDetails) {
    this.bus.publish(this.events.received.payment.info.subscriptionDetails.success, data);
  }

  public error(httpErrorResponse: HttpErrorResponse) {
    this.bus.publish(this.events.received.payment.info.subscriptionDetails.failure, httpErrorResponse);
  }
}

<div class="question-input">
    <mat-form-field>
        <textarea matTextareaAutosize matInput rows="3" placeholder="{{'audit.form.question.input.placeholder' | translate}}"  [disabled]="readonly" (change)="input.submit($event.target.value)">{{ input.originalValue }}</textarea>
        <mat-hint [ngClass]="{'hidden': input.saving}" innerHTML="{{ 'audit.form.question.input.hint' | translate}}">
        </mat-hint>
        <mat-hint [ngClass]="{'active': input.saving}" align="end" class="saving">
        {{ 'basicInfo.fields.saving' | translate }}
        </mat-hint>
    </mat-form-field>

    <ng-content></ng-content>
</div>

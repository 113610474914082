import { AfterViewInit, Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'dashboard-metrics-pa-department-chart',
  templateUrl: './pa-department-chart.component.html',
  styleUrls: ['./pa-department-chart.component.scss']
})
export class PaDepartmentChartComponent implements OnInit, AfterViewInit {
  @Input() pas = [];
  @Input() departments = [];
  @Input() height = 200;

  paDist = [];

  constructor() { }

  ngAfterViewInit(): void {
    this.paDist = []; // pa distribution array

    if (this.pas && this.departments) {
      this.pas.forEach(pa => {
        this.populatePaDist(pa);
      });
    }
  }

  ngOnInit(): void {
    
  }

  private populatePaDist(pa) {
    if (!pa.assignedDepartment) {
      this.unassignedDepartment()
      return;
    }

    let dept = this.departments.find(dep => dep.id === pa.assignedDepartment) // current department
    let currentDept = this.paDist.find(entry => entry.id === pa.assignedDepartment) // current distribution

    if (!currentDept) { // if the current distribution of pas is not on the list
      let entry = {
        id: dept.id,
        name: dept.name,
        value: 1
      }
      this.paDist.push(entry) // add a new distribution item
    } else {

      currentDept.value++;
    }
  }

  private unassignedDepartment() {
    let unnassigned = this.paDist.find(el => el.name === 'Unassigned');
    if (unnassigned) {
      unnassigned.value++;
    } else {
      this.paDist.push({
        // id: 'Unassigned',
        name: 'Unassigned',
        value: 1
      })
    }
  }

}

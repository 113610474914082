import { Component, OnInit, Input } from '@angular/core';

import { AuthenticationService } from '../../../services/authentication.service';
import { ProductFeaturesService } from 'app/services/product-features.service';


@Component({
  selector: 'feature-access',
  templateUrl: './access.component.html',
  styleUrls: ['./access.component.scss']
})
export class FeatureAccessComponent implements OnInit {

  @Input() feature: string;
  @Input() centered: boolean;

  constructor(
    private featureService: ProductFeaturesService,
  ) { }

  ngOnInit() {
  }

  get has(): boolean {
    return this.featureService.hasAccessToFeature(this.feature);
  }
}

import { Injectable } from '@angular/core';
import { BusService } from '../bus.service';
import { RequestService } from '../request.service';

@Injectable()
export class GroupListService {
  groups: any[];

  constructor(
    private bus: BusService,
    private request: RequestService,
  ) { }

  fetchGroups() {
    this.groups = [];
    return this.request.get<any[]>({
      uri: '/supervision/groups/all'
    });
  }

  createGroup(data: any) {
    return this.request.post({
      uri: '/supervision/groups/create',
      body: data,
      handlers: {
        success: () => this.bus.publish('create-group-event'),
        error: (error) => this.bus.publish('create-group-failed-event', error),
      }
    });
  }

  deleteGroup(id: number) {
    return this.request.delete({
      uri: `/supervision/groups/delete/${id}`,
      handlers: {
        success: () => this.bus.publish('delete-group-event'),
        error: (error) => this.bus.publish('delete-group-failed-event', error)
      }
    });
  }

  addClientToGroup(groupId: number, controllerId: number) {
    return this.request.put({
      uri: `/supervision/groups/add/${groupId}`,
      body: {
        controllerId,
      },
      handlers: {
        success: () => this.bus.publish('add-to-group-event'),
        error: (error) => this.bus.publish('add-to-group-failed-event', error),
      }
    });
  }

  removeClientFromGroup(groupId: number, controllerId: number) {
    this.request.put({
      uri: `/supervision/groups/remove/${groupId}`,
      body: {
        controllerId,
      },
      handlers: {
        success: () => this.bus.publish('remove-from-group-event'),
        error: (error) => this.bus.publish('remove-from-group-failed-event', error),
      }
    });
  }
}

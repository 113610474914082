import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

import { BusService } from '../../../services/bus.service';
import { EventsService } from '../../../services/events.service';

@Component({
  selector: 'app-custom-pa',
  templateUrl: './custom-pa.rpa.component.html',
  styleUrls: ['./custom-pa.rpa.component.scss']
})
export class CustomPaComponent implements OnInit, OnDestroy {

  formGroup: FormGroup;
  paId = null;
  departments = [];

  constructor(
    private location: Location,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private bus: BusService,
    private events: EventsService,
  ) {
    this.formGroup = new FormGroup({
      name: new FormControl('', [Validators.required, Validators.minLength(1)]),
      processingOnBehalf: new FormControl(false),
    });
  }

  ngOnInit() {
    this.subscribe();
  }

  ngOnDestroy() {
    this.unsubscribe();
  }

  submit() {
    if (this.formGroup.valid) {
      if (!this.paId) {
        this.bus.publish(this.events.requested.data.rpa.customPa.add, this.formGroup.value);
      }
    }
  }

  addSuccess(result) {
    this.paId = result.id;
    this.router.navigate([`/rpa/pa-details/${this.paId}`]);
  }

  private subscribe() {
    this.bus.subscribe(this.events.received.data.rpa.customPa.add.success, this.addSuccess, this);
  }

  private unsubscribe() {
    this.bus.unsubscribe(this.events.received.data.rpa.customPa.add.success, this.addSuccess);
  }
}

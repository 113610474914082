import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpParams} from '@angular/common/http';
import {BusService} from '../bus.service';
import {
  IPostOptions,
  RequestService
} from '../request.service';
import {EventsService} from '../events.service';


interface AssignRequest {
  paId: number;
  departmentId: number;
}

interface UpdateFieldRequest {
  value: any;
}

// eslint-disable-next-line no-shadow
export enum PASearchOrdering {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  LastStatusChange = 'last-status-change',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  DPIAValue = 'dpia-value',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  PAName = 'pa-name',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  PAID = 'pa-id'
}

export type PAStatus = 'Applicable' | 'Not Applicable' | 'Archived';

export interface PASearchCriteria {
  keyword?: string;
  departments?: number[];
  // status?: {
  //   draft?: boolean;
  //   open?: boolean;
  //   submitted?: boolean;
  //   approved?: boolean;
  //   archived?: boolean;
  // };
  status: PAStatus;
  subjects?: string[];
  tags?: string[];
  order?: PASearchOrdering;
  application?: number;
  vendor?: number;
  invalidate?: boolean;
  processingOnBehalf?: boolean;
}

@Injectable()
export class RpaService {
  private api = {
    root: '/pa',
    getApplicable: '/applicable',
    getComplex: '/complex-pa',
    assign: '/assign',
    basics: {
      root: '/basics',
      progress: '/progress',
    },
  };

  constructor(
    private bus: BusService,
    private events: EventsService,
    private requestService: RequestService
  ) {
    this.subscribe();
  }


  getPAs(criteria?: PASearchCriteria) {
    let params = new HttpParams();

    if (criteria) {
      if (criteria.processingOnBehalf) {
        params = params.set('processing-on-behalf', String(criteria.processingOnBehalf));
      }

      if (criteria.keyword && criteria.keyword.trim().length > 0) {
        params = params.set('keyword', criteria.keyword.trim());
      }

      if (criteria.departments && criteria.departments.length > 0) {
        params = params.set('departments', criteria.departments.join(','));
      }
      if (criteria.subjects && criteria.subjects.length > 0) {
        params = params.set('subjects', criteria.subjects.join(','));
      }

      if (criteria.application) {
        params = params.set('application', criteria.application.toString());
      }

      if (criteria.vendor) {
        params = params.set('vendor', criteria.vendor.toString());
      }

      if (criteria.order) {
        params = params.set('order', criteria.order);
      }

      if (criteria.status) {
        params = params.set('status', criteria.status);
      }
    }

    return this.requestService.get<any>({
      uri: this.api.root,
      parameters: params,
      handlers: {
        success: () => { },
        error: () => { }
      }
    });
  }

  getApplicable() {
    this.requestService.get<any>({
      uri: this.api.root + this.api.getApplicable,
      handlers: {
        success: data => this.bus.publish(this.events.received.data.rpa.getApplicable.success, data),
        error: error => this.bus.publish(this.events.received.data.rpa.getApplicable.failure, error),
      }
    });
  }

  getComplex() {
    this.requestService.get<any>({
      uri: this.api.getComplex,
      handlers: {
        success: data => this.bus.publish(this.events.received.data.rpa.getComplex.success, data),
        error: error => this.bus.publish(this.events.received.data.rpa.getComplex.failure, error),
      }
    });
  }

  assignPa(event) {
    this.requestService.post<AssignRequest, unknown>({
      uri: this.api.root + this.api.assign,
      body: {
        paId: event.pa.paId,
        departmentId: event.department.id,
      },
      handlers: {
        success: () => this.bus.publish(this.events.received.action.rpa.assign.success),
        error: error => this.bus.publish(this.events.received.action.rpa.assign.failure, error),
      }
    });
  }

  updateBasicField(event) {
    return this.requestService.post<UpdateFieldRequest, unknown>({
      uri: this.api.root + this.api.basics.root + `/${event.paId}/${event.fieldName}`,
      body: {
        value: event.value,
      },
      handlers: {
        success: () => this.bus.publish(this.events.received.data.rpa.basics.updateField.success, event.fieldName),
        error: error => this.bus.publish(this.events.received.data.rpa.basics.updateField.failure, {
          fieldName: event.fieldName,
          error,
        }),
      }
    });
  }

  getBasicProgress() {
    this.requestService.get<any>({
      uri: this.api.root + this.api.basics.root + this.api.basics.progress,
      handlers: {
        success: data => this.bus.publish(this.events.received.data.rpa.basics.progress.success, data),
        error: error => this.bus.publish(this.events.received.data.rpa.basics.progress.failure, error),
      }
    });
  }

  subscribe() {
    this.bus.subscribe(this.events.requested.data.rpa.getApplicable, this.getApplicable.bind(this));
    this.bus.subscribe(this.events.requested.data.rpa.getComplex, this.getComplex.bind(this));
    this.bus.subscribe(this.events.requested.action.rpa.assign, this.assignPa.bind(this));
    this.bus.subscribe(this.events.requested.data.rpa.basics.updateField, this.updateBasicField.bind(this));
    this.bus.subscribe(this.events.requested.data.rpa.basics.progress, this.getBasicProgress.bind(this));
  }
}

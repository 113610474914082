<div class="holder">
  <h1 class="title" *ngIf="!filters.processingOnBehalf">{{ 'rpa.component.titel' | translate }}</h1>
  <h1 class="title" *ngIf="filters.processingOnBehalf">{{ 'rpa.component.titel.onBehalf' | translate }}</h1>
  <br>
  <p>
    {{ 'rpa.pa-list.hint.dst' | translate }}
  </p>
  <br><br>
  <div class="content">
    <div class="collapse" (click)="collapse=true" *ngIf="!collapse">
      <div class="inner">
        <mat-icon>arrow_back_ios</mat-icon>
      </div>
    </div>

    <div class="filters" [ngClass]="{'collapsed': collapse}">
      <mat-form-field>
        <input matInput type="text" placeholder="{{ 'rpa.pa-list.filters.search.title' | translate }}"
          (input)="notifyFilterChange()" [(ngModel)]="filters.keyword" />
        <mat-hint>
          {{ 'rpa.pa-list.filters.search.hint' | translate }}
        </mat-hint>
      </mat-form-field>

      <mat-form-field>
        <mat-select placeholder="{{ 'rpa.pa-list.filters.department.title' | translate }}"
          (selectionChange)="notifyFilterChange()" [(ngModel)]="filters.departments" multiple="true" long-select>
          <mat-option *ngFor="let department of deps" [value]="department.id">
            {{department.name}}
          </mat-option>
        </mat-select>
        <mat-hint>
          {{ 'rpa.pa-list.filters.department.hint.dpo' | translate }}
        </mat-hint>
      </mat-form-field>

      <hr>

      <mat-form-field>
        <mat-select placeholder="{{ 'rpa.pa-list.filters.subject.title' | translate }}"
          (selectionChange)="notifyFilterChange()" multiple="true" [(ngModel)]="filters.subjects" long-select>
          <mat-option value="pa.dataSubject.categories.employees">{{'pa.dataSubject.categories.employees' | translate}}
          </mat-option>
          <mat-option value="pa.dataSubject.categories.applicants">
            {{'pa.dataSubject.categories.applicants' | translate}}</mat-option>
          <mat-option value="pa.dataSubject.categories.trainees">{{'pa.dataSubject.categories.trainees' | translate}}
          </mat-option>
          <mat-option value="pa.dataSubject.categories.temporaryWorkers">
            {{'pa.dataSubject.categories.temporaryWorkers' | translate}}</mat-option>
          <mat-option value="pa.dataSubject.categories.managers">{{'pa.dataSubject.categories.managers' | translate}}
          </mat-option>
          <mat-option value="pa.dataSubject.categories.commercialAgent">
            {{'pa.dataSubject.categories.commercialAgent' | translate}}</mat-option>
          <mat-option value="pa.dataSubject.categories.serviceProvider">
            {{'pa.dataSubject.categories.serviceProvider' | translate}}</mat-option>
          <mat-option value="pa.dataSubject.categories.suppliers">{{'pa.dataSubject.categories.suppliers' | translate}}
          </mat-option>
          <mat-option value="pa.dataSubject.categories.interestedParties">
            {{'pa.dataSubject.categories.interestedParties' | translate}}</mat-option>
          <mat-option value="pa.dataSubject.categories.contractualPartner">
            {{'pa.dataSubject.categories.contractualPartner' | translate}}</mat-option>
          <mat-option value="pa.dataSubject.categories.customers">{{'pa.dataSubject.categories.customers' | translate}}
          </mat-option>
          <mat-option value="pa.dataSubject.categories.customersOfCustomers">
            {{'pa.dataSubject.categories.customersOfCustomers' | translate}}</mat-option>
          <mat-option value="pa.dataSubject.categories.employeesOfCustomers">
            {{'pa.dataSubject.categories.employeesOfCustomers' | translate}}</mat-option>
          <mat-option value="pa.dataSubject.categories.members">{{'pa.dataSubject.categories.members' | translate}}
          </mat-option>
          <mat-option value="pa.dataSubject.categories.debtor">{{'pa.dataSubject.categories.debtor' | translate}}
          </mat-option>
          <mat-option value="pa.dataSubject.categories.investor">{{'pa.dataSubject.categories.investor' | translate}}
          </mat-option>
          <mat-option value="pa.dataSubject.categories.jointController">
            {{'pa.dataSubject.categories.jointController' | translate}}</mat-option>
          <mat-option value="pa.dataSubject.categories.patients">{{'pa.dataSubject.categories.patients' | translate}}
          </mat-option>
          <mat-option value="pa.dataSubject.categories.minors">{{'pa.dataSubject.categories.minors' | translate}}
          </mat-option>
          <mat-option value="pa.dataSubject.categories.other">{{'pa.dataSubject.categories.other' | translate}}
          </mat-option>
        </mat-select>
        <mat-hint>
          {{ 'rpa.pa-list.filters.subject.hint' | translate }}
        </mat-hint>
      </mat-form-field>

      <hr>

      <mat-slide-toggle color="primary" (toggleChange)="notifyFilterChange()" [(ngModel)]="filters.processingOnBehalf">
        {{ 'rpa.pa-list.processingOnBehalf' | translate }}
      </mat-slide-toggle>

      <br>

      <div class="vendor-selection">
        <mat-hint>
          {{ 'rpa.pa-list.filters.vendor' | translate }}
        </mat-hint>
        <app-vendor-input [value]="filters.vendor" (vendorChange)="setVendorFilter($event)"></app-vendor-input>
      </div>

      <div class="application-selection">
        <mat-hint>
          {{ 'rpa.pa-list.filters.application' | translate }}
        </mat-hint>
        <app-application-input [value]="filters.application" (applicationChange)="setApplicationFilter($event)"></app-application-input>
      </div>

      <hr>

      <mat-form-field>
        <mat-select placeholder="{{ 'rpa.pa-list.order.title' | translate }}" [(ngModel)]="filters.order"
          (selectionChange)="notifyFilterChange()">
          <mat-option [value]="ordering.PAName">
            <!-- Order By Name -->
            {{ 'rpa.pa-list.order.labels.pa-name' | translate }}
          </mat-option>
          <mat-option [value]="ordering.PAID">
            <!-- Order By ID -->
            {{ 'rpa.pa-list.order.labels.pa-id' | translate }}
          </mat-option>
          <mat-option [value]="ordering.LastStatusChange">
            <!-- Last Status Change -->
            {{ 'rpa.pa-list.order.labels.last-status-change' | translate }}
          </mat-option>
          <mat-option [value]="ordering.DPIAValue">
            <!-- Risk -->
            {{ 'rpa.pa-list.order.labels.dpia-value' | translate }}
          </mat-option>
        </mat-select>
        <mat-hint>
          <!-- Change how the Processing Activities are sorted. -->
          {{ 'rpa.pa-list.order.hint' | translate }}
        </mat-hint>
      </mat-form-field>

      <mat-form-field>
        <mat-select placeholder="{{ 'rpa.pa-list.status.title' | translate }}" [(ngModel)]="filters.status"
          (selectionChange)="notifyFilterChange()">
          <mat-option [value]="'Applicable'">
            {{ 'rpa.pa-list.status.labels.applicable' | translate }}
          </mat-option>
          <mat-option [value]="'Archived'">
            {{ 'rpa.pa-list.status.labels.archived' | translate }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <br><br>
      <div class="buttons">
        <button mat-stroked-button color="primary" (click)="reset()">
          {{ 'rpa.pa-list.filters.reset' | translate }}
        </button>
      </div>
    </div>

    <div class="expand" (click)="collapse=false" *ngIf="collapse">
      <div class="inner">
        <mat-icon>arrow_forward_ios</mat-icon>
      </div>
    </div>

    <div class="pas" [ngClass]="{'expanded': collapse, 'loading': loading, 'receiving': receiving}">
      <div *ngIf="pas.length > 1" class="showing-pas">
        {{'rpa.component.titel' | translate}}: {{pas.length}}
      </div>

      <a *ngFor="let pa of pas" routerLink="/rpa/pa-details/{{pa.paId}}">
        <div class="pa">
          <div class="title">
            <span class="code">VT-{{pa.paId }}</span>
            <h4 title="{{pa.paName}}" matTooltip="{{pa.paName}}" matTooltipPosition="above">{{pa.paName}}</h4>
            <div class="dpia" *ngIf="pa.paRiskAnalysisValue">

              <div class="dpia-box dpia-value" [ngClass]="dpiaValueClass(pa)"
                matTooltip="{{ 'rpa.pa-list.dpia.tooltip' |  translate }}"></div>
            </div>
            <span class="last-updated" *ngIf="getLastChange(pa.paId)">
              {{'rpa.component.card.lastChanged' | translate}}
              {{getLastChange(pa.paId).timestamp_at | date: 'dd.MM.yyyy'}}
              {{'rpa.component.card.lastChanged.by' | translate}}
              {{getLastChange(pa.paId).first_name + ' ' + getLastChange(pa.paId).last_name}}
            </span>
          </div>

          <div class="pa-metadata">
            <div class="department" *ngIf="pa.dep">
              <h4>{{pa.dep.name}}</h4>
            </div>

            <access-control atLeast="user-management.role.controller.contributor">
              <!-- default duplicate dialog for non-supervisors -->
              <ng-container *ngIf="!isInSupervisionMode">
                <button mat-icon-button matTooltip="{{ 'pa.duplicate.tooltip' | translate }}" color="primary" (click)="openDuplicateDialog($event, pa)">
                  <mat-icon>
                    content_copy
                  </mat-icon>
                </button>
              </ng-container>
            </access-control>

            <!-- show options for supervisors -->
            <ng-container *ngIf="isInSupervisionMode">
              <button mat-icon-button matTooltip="{{ 'pa.duplicate.tooltip' | translate }}" color="primary" (click)="$event.preventDefault(); $event.stopPropagation();" #menuTrigger="matMenuTrigger" [matMenuTriggerFor]="duplicateMenu">
                <mat-icon>
                  content_copy
                </mat-icon>
              </button>

              <mat-menu #duplicateMenu="matMenu" [overlapTrigger]="false">
                <button mat-button color="primary" (click)="openDuplicateDialog($event, pa)">
                  {{ 'pa.duplicate.local' | translate }}
                </button>
                <br>
                <button mat-button color="primary" (click)="openDuplicateToControllerDialog($event, pa)">
                  {{ 'pa.duplicate.to-controller' | translate }}
                </button>
                <br>
              </mat-menu>
            </ng-container>
          </div>
        </div>
      </a>

      <div class="loading">
        <mat-spinner></mat-spinner>
      </div>
    </div>

  </div>
  <div class="actions buttons">

    <access-control atLeast="user-management.role.controller.manager">
      <button mat-button color="primary" (click)="makeComplete()" *ngIf="showMarkAsCompleteButton" style="float: left;">
        {{ 'rpa.pa-list.actions.make-complete' | translate }}
      </button>
      <button mat-button *ngIf="pasComplete" style="float: left;" disabled>
        {{'word.complete' | translate}}! <mat-icon>check</mat-icon>
      </button>
    </access-control>

    <button mat-button color="primary" [matMenuTriggerFor]="rpaFormatsMenu">
      {{ 'rpa.pa-list.actions.export' | translate }}
    </button>

    <mat-menu #rpaFormatsMenu="matMenu" [overlapTrigger]="false">
      <a mat-button color="primary" (click)="exportXls()" target="_blank">
        {{ 'documents.download.xls' | translate }}
      </a>
      <br>

      <a mat-button color="primary" (click)="exportPdf()" target="_blank">
        {{'documents.download.pdf' | translate}}
      </a>

      <display-jurisdiction jurisdiction="gdpr">
        <br>
        <a mat-button color="primary" (click)="exportWord()" target="_blank">
          {{'documents.download.docx' | translate}}
        </a>

        <ng-container *ngIf="!filters.processingOnBehalf">
          <display-language language="de">
            <button mat-button color="primary" (click)="exportArt13()">{{'documents.download.art13InfoPa' | translate}}</button>
          </display-language>
        </ng-container>
      </display-jurisdiction>
    </mat-menu>

    <access-control atLeast="user-management.role.controller.contributor">
      <button mat-flat-button color="primary" (click)="newPa()">
        {{ 'rpa.pa-list.actions.new-pa' | translate }}
      </button>
    </access-control>
  </div>
</div>
<br><br>

<div class="contents">
  <mat-spinner *ngIf="!invite && !error"></mat-spinner>
  <div *ngIf="invite">
    <h1 class="title">
    {{'supervision.supervisorProfile.coSupervisors.invites.confirm.title' | translate}}
    </h1>
    <br>
    <p>
    {{
      'supervision.supervisorProfile.coSupervisors.invites.confirm.hint.pt1' | translate
    }} {{invite.profile.displayName}} {{
      'supervision.supervisorProfile.coSupervisors.invites.confirm.hint.pt2' | translate
    }}
    </p>
    <br><br>
    <div class="actions">
      <button mat-stroked-button color="warn" (click)="reject()">
      {{'supervision.supervisorProfile.coSupervisors.invites.confirm.actions.reject' | translate}}
      <!-- Don't Join -->
      </button>
      <button mat-flat-button color="primary" (click)="confirm()">
      <!-- Join -->
      {{
        'supervision.supervisorProfile.coSupervisors.invites.confirm.actions.confirm.pt1' | translate
      }} {{invite.profile.displayName}} {{
        'supervision.supervisorProfile.coSupervisors.invites.confirm.actions.confirm.pt2' | translate
      }}
      </button>
    </div>
  </div>
  <div *ngIf="error">
    <h2><mat-icon color="warn">warning</mat-icon>  {{
      'supervision.supervisorProfile.coSupervisors.invites.confirm.tokenError.title' | translate
    }}
    </h2>
    <br>
    <p>
    {{'supervision.supervisorProfile.coSupervisors.invites.confirm.tokenError.hint' | translate}}
    </p>
  </div>
</div>

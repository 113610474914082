import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {Measure, measureDue, measureOverdue, MeasuresService} from "../../../../services/measures.service";
import {MatSort, Sort} from "@angular/material/sort";
import {CreateMeasureDialogComponent} from "../../create-measure-dialog/create-measure-dialog.component";
import {MatDialog} from "@angular/material/dialog";
import {compare, compareDate} from "../../../../util/ordering";
import {MatTableDataSource} from "@angular/material/table";
import {TranslateService} from "@ngx-translate/core";
import {zip} from "rxjs";
import {DialogComponent} from "../../../dialog/dialog.component";

@Component({
  selector: 'app-measures-table',
  templateUrl: './measures-table.component.html',
  styleUrls: ['./measures-table.component.scss']
})
export class MeasuresTableComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() measures: Measure[] = [];
  @Input() showDoneAt = true;

  @Output() update = new EventEmitter<Measure>();

  @ViewChild(MatSort) sort: MatSort;

  dataSource = new MatTableDataSource<Measure>();

  columnsToDisplay = ['title', 'responsibleEntity', 'dueAt', 'progress', 'status', 'doneAt', 'actions'];

  constructor(
    private measureService: MeasuresService,
    private dialog: MatDialog,
    private translate: TranslateService
  ) { }

  get columns() {
    return this.showDoneAt ? this.columnsToDisplay : this.columnsToDisplay.filter(c => c !== 'doneAt');
  }

  ngOnInit(): void {
  }

  markAsDone(event, measure: Measure) {
    if (event) {
      event.stopPropagation();
    }

    if (measure.status === 'done') {
      return;
    }

    measure.status = 'done';
    if (measure.progress) {
      measure.progress = 1;
    }
    measure.doneAt = new Date();
    this.measureService.updateMeasure(measure).subscribe(() => {});
    this.update.emit(measure);
  }

  showDetails(event: any, measure: Measure) {
    this.dialog.open(CreateMeasureDialogComponent, { width: '600px', data: { measure } })
      .afterClosed()
      .subscribe((updated: Measure) => {
        // if updated
        if (updated) {
          Object.assign(measure, updated);
          this.update.emit(measure);
        }
      });
  }

  copy(event, measure: Measure) {
    if (event) {
      event.stopPropagation();
    }
    this.dialog.open(CreateMeasureDialogComponent, { width: '600px', data: { measure, copy: true } })
      .afterClosed()
      .subscribe((updated: Measure) => {
        // if updated
        if (updated) {
          Object.assign(measure, updated);
          this.update.emit(measure);
        }
      });
  }

  deleteMeasure(event, measure: Measure) {
    if (event) {
      event.stopPropagation();
    }

    zip(
      this.translate.get('measures.delete.title'),
      this.translate.get('measures.delete.message'),
    ).subscribe(([title, message]) => {
      this.dialog.open(DialogComponent, { width: '400px', data: { header: title, message, confirm: true }})
        .afterClosed()
        .subscribe((confirmed) => {
          if (confirmed) {
            this.measureService.deleteMeasure(measure)
              .subscribe(() => {
                this.measures = this.measures.filter((m) => m.id !== measure.id);
                this.update.emit(null);
              });
          }
        });
    });
  }

  sortData(data: Measure[], sort: Sort) {
    if (!sort.active || sort.direction === '') {
      return data;
    }

    return data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'title': return compare(a.title.toLowerCase(), b.title.toLowerCase(), isAsc);
        case 'responsibleEntity': return compare(a.responsibleEntity.toLowerCase(), b.responsibleEntity.toLowerCase(), isAsc);
        case 'status': return compare(a.status, b.status, isAsc);
        case 'progress': return compare(a.progress, b.progress, isAsc);
        case 'dueAt': return compareDate(new Date(a.dueAt), new Date(b.dueAt), isAsc);
        case 'lastUpdateAt': return compareDate(new Date(a.lastUpdateAt), new Date(b.lastUpdateAt), isAsc);
        case 'doneAt': return compareDate(new Date(a.doneAt), new Date(b.doneAt), isAsc);
        default: return 0;
      }
    });
  }

  measureOverdue(measure: Measure) {
    return measureOverdue(measure);
  }

  measureDue(measure: Measure) {
    return measureDue(measure);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.measures) {
      this.dataSource.data = this.measures;
    }
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
    this.dataSource.sortData = this.sortData;
  }
}

import { Injectable } from '@angular/core';

import {HttpErrorResponse} from '@angular/common/http';
import {BusService} from '../bus.service';
import {EventsService} from '../events.service';
import {
  IGetOptions,
  IPostOptions,
  RequestService
} from '../request.service';
import { share } from 'rxjs/operators';

interface AcceptInvitationRequest {
  token: string;
}

@Injectable()
export class SupervisionClientInvitationService {

  api = {
    root: '/supervision/client-invitations',
    invitation: {
      accept: '/accept'
    }
  };

  constructor(
    private bus: BusService,
    private events: EventsService,
    private requestService: RequestService
  ) {
    this.subscribe();
  }

  get(token) {
    return this.requestService.get<any>({
      uri: this.api.root + `/${token}`,
      handlers: {
        success: supervisor => this.bus.publish(this.events.received.data.supervision.clientInvitation.get.success, supervisor),
        error: error => this.bus.publish(this.events.received.data.supervision.clientInvitation.get.failure, error.error),
      }
    }).pipe(share());
  }

  accept(token: string) {
    return this.requestService.post<AcceptInvitationRequest, void>({
      uri: this.api.root + `/${token}` + this.api.invitation.accept,
      body: { token },
      handlers: {
        success: () => this.bus.publish(this.events.received.action.supervision.clientInvitation.accept.success),
        error: error => this.bus.publish(this.events.received.action.supervision.clientInvitation.accept.failure, error.error),
      }
    }).pipe(share());
  }

  getSupervision() {
    this.requestService.get<any>({
      uri: this.api.root,
      handlers: {
        success: supervision => this.bus.publish(this.events.received.data.supervision.clientInvitation.info.success, supervision),
        error: error => this.bus.publish(this.events.received.data.supervision.clientInvitation.info.failure, error.error),
      }
    });
  }

  subscribe() {
    this.bus.subscribe(this.events.requested.data.supervision.clientInvitation.get, this.get.bind(this));
    this.bus.subscribe(this.events.requested.action.supervision.clientInvitation.accept, this.accept.bind(this));
    this.bus.subscribe(this.events.requested.data.supervision.clientInvitation.info, this.getSupervision.bind(this));
  }
}

<h2 class="title" mat-dialog-header>
  {{'content-copied.title' | translate}}
</h2>

<mat-dialog-content>
  {{'content-copied.message' | translate}}
  <div class="shortkey">
    <mat-card *ngIf="isMac"><a class="big">⌘</a></mat-card>
    <mat-card *ngIf="!isMac"><a>Ctrl</a></mat-card>
    <span>+</span>
    <mat-card><a>V</a></mat-card>
  </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button color="primary" mat-dialog-close>{{'word.ok' | translate}}</button>
</mat-dialog-actions>

<div *ngIf="controller" class="supervision-client-details client-details">

  <div class="client-logo">
    <img
      src="{{appConfig.apiUrl}}/controllers/logo/{{controller._id}}/"
      onerror="this.src='/assets/eclogo-check.svg'"
      onabort="this.src='/assets/eclogo-check.svg'"/>
  </div>
  <br><br>
  <h1 class="title">{{ controller.controllerName }}</h1>

  <br><br>

  <div class="inner">
    <mat-divider></mat-divider>
    <br><br>

    <div class="or">
      <div>
        <div class="or-indicator" *ngIf="controller._extra?.incidents > 0"></div>
        {{'supervision.clientDetails.controller.open-incidents.label' | translate}}:
        <!-- Open Incidents -->
        <b>{{controller._extra?.incidents || 0}}</b>
      </div>
      <div>
        <div class="or-indicator" *ngIf="controller._extra?.dsars > 0"></div>
        {{'supervision.clientDetails.controller.open-dsars.label' | translate}}:
        <!-- Open Data Subject Access Requests -->
        <b>{{controller._extra?.dsars || 0}}</b>
      </div>
    </div>

    <br><br>

    <div class="dsar-incident-chart">
      <!-- {{'supervision.clientDetails.controller.reported-incidents.label' | translate}} -->
      <ngx-charts-line-chart
        [results]="controller._extra.dsarIncidentTimeline || []"
        [xAxis]="true"
        [yAxis]="true"
        [gradient]="true"
        >
      </ngx-charts-line-chart>
    </div>

    <br><br>

    <div flexLayout="row" fxLayoutGap="64px" class="metrics">
      <div fxFlex="50%">
        <progress-indicator [progress]="controller._extra?.auditProgress">
        </progress-indicator>
        <div class="label">
          {{'supervision.clientDetails.controller.audit-progress.label' | translate}}
          <!-- Data Protection Audit -->
        </div>
      </div>
      <div fxFlex="50%">
        <progress-indicator [progress]="controller._extra?.rpaProgress">
        </progress-indicator>
        <div class="label">
          {{'supervision.clientDetails.controller.rpas-progress.label' | translate}}
          <!-- Records of Processing Activities -->
        </div>
      </div>
      <br>
      <div fxFlex="50%">
        <progress-indicator [progress]="tomsProgress">
        </progress-indicator>
        <div class="label">
          {{'supervision.clientDetails.controller.toms-progress.label' | translate}}
          <!-- Technical and Organisational Measures -->
        </div>
      </div>
      <div fxFlex="50%">
        <progress-indicator [progress]="dpasProgress">
        </progress-indicator>
        <div class="label">
          {{'supervision.clientDetails.controller.dpas-progress.label' | translate}}
          <!-- Data Protection Agreements -->
        </div>
      </div>
    </div>

    <br><br>
    <mat-divider></mat-divider>
    <br><br>

    <p>{{'supervision.clientDetails.charts.pa-deps.title' | translate}}</p>
    <div class="pa-dists">
      <ngx-charts-pie-chart
      *ngIf="controller._extra.paDist && controller._extra.paDist.length > 0"
      [results]="controller._extra.paDist"
      [labels]="true"
      [doughnut]="true"
      [arcWidth]="0.5"
      [legend]="true"
      legendTitle=""
      >
      </ngx-charts-pie-chart>
    </div>

    <br><br>
    <mat-divider></mat-divider>
    <br><br>

    <div fxLayout="row">
      <div fxFlex="50%">
        <p>{{'supervision.clientDetails.charts.pa-risks.title' | translate}}</p>
        <!-- {{ 'risk-analysis.classifications.safe' | translate}} -->
        <!-- {{ 'risk-analysis.classifications.mostly-safe' | translate}} -->
        <!-- {{ 'risk-analysis.classifications.warning' | translate}} -->
        <!-- {{ 'risk-analysis.classifications.serious-warning' | translate}} -->
        <!-- {{ 'risk-analysis.classifications.danger' | translate}} -->
        <!-- {{ 'risk-analysis.classifications.catastrophe' | translate}} -->
        <!-- {{ 'risk-analysis.classifications.unknown' | translate}} -->
        <!-- {{ 'risk-analysis.classifications.needs-clarification' | translate}} -->
        <div class="pa-risk-dists">
          <ngx-charts-bar-horizontal
            [results]="controller._extra.paRiskDist || []"
            [scheme]="riskDistColors"
            [xAxis]="true"
            [showGridLines]="true"
            [noBarWhenZero]="false"
            [xAxisTickFormatting]="integerLabel"
          >
            <ng-template #tooltipTemplate let-model="model">
              {{model.label}}<br>
              {{roundValue(model.value)}}
            </ng-template>
          </ngx-charts-bar-horizontal>
        </div>
      </div>
      <div fxFlex="50%">
        <p>{{'supervision.clientDetails.charts.events.title' | translate}}</p>
        <div class="events-chart">
          <!-- {{'supervision.clientDetails.charts.events.axis' | translate}} -->
          <ngx-charts-area-chart
            [results]="controller._extra.eventsTimeSeries || []"
            [xAxis]="true"
            [yAxis]="true"
            [gradient]="true"
            [curve]="curveEvents"
            [scheme]="{domain: ['#4a47a3']}"
          >
          </ngx-charts-area-chart>
        </div>
      </div>
    </div>
    <br><br>
  </div>
</div>

<br><br><br><br>

import { Injectable } from '@angular/core';
import { HttpParams } from "@angular/common/http";

import { BusService } from '../bus.service';
import { EventsService } from '../events.service';
import { RequestService } from '../request.service';
import {AuthenticationService} from "../authentication.service";


export interface AccessLevelRequest {
  context: string;
  details?: any;
}

export class AccessLevel {
  read: boolean;
  write: boolean;
}

const roleMatrix = {
  'user-management.role.controller.dashboard': 10,
  'user-management.role.controller.vater_solution_dashboard': 11,
  'user-management.role.controller.vater_solution_premium': 20,
  'user-management.role.controller.contributor': 30,
  'user-management.role.controller.inspector': 50,
  'user-management.role.controller.manager': 50
};

export const userRoles = {
  vaterSolutionDashboard: 'user-management.role.controller.vater_solution_dashboard',
  dashboard: 'user-management.role.controller.dashboard',
  vaterSolutionPremium: 'user-management.role.controller.vater_solution_premium',
  rpaContributor: 'user-management.role.controller.contributor',
  inspector: 'user-management.role.controller.inspector',
  manager: 'user-management.role.controller.manager'
};


@Injectable()
export class AccessLevelService {

  constructor(
    private request: RequestService,
    private bus: BusService,
    private events: EventsService,
    private authService: AuthenticationService
  ) {
    this.subscribe();
  }

  public checkRole(against: string) {
      const userRole = this.authService.access;
      if (!userRole) return -1;

      if (userRole === against) {
        return 0;
      }

      const matrixUser = roleMatrix[userRole];
      const matrixAgainst = roleMatrix[against];

      return matrixUser < matrixAgainst ? -1 : 1;
  }

  checkAccess(request: AccessLevelRequest) {
    let parameters = new HttpParams();
    if (request.details) {
      for (let [key, value] of Object.entries(request.details))
        parameters = parameters.set(key, value as any);
    }

    return this.request.get<AccessLevel>({
      uri: `/access-level/${request.context}`,
      parameters: parameters,
      handlers: {
        success: (response: AccessLevel) =>
          this.bus.publish(this.events.received.data.user.access.success, {
            request: request,
            response: response,
          }),
        error: error => this.bus.publish(this.events.received.data.user.access.failure, error),
      }
    });
  }

  availableRoles() {
    return this.request.get<string[]>({
      uri: `/access-level/available-roles`
    });
  }

  subscribe() {
    this.bus.subscribe(this.events.requested.data.user.access, this.checkAccess.bind(this));
  }
}

/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable @angular-eslint/no-input-rename */
import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { EventsService } from 'app/services/events.service';
import { BusService } from 'app/services/bus.service';
import { MatDialog } from '@angular/material/dialog';
import { AppConfig } from '../../../app.config';
import { DocumentsUploadDialogComponent } from '../upload/documents-upload-dialog.component';
import { Document, DocumentResourceService } from '../../../services/documents/document-resource.service';
import { UserManagementService } from 'app/services/user/user-management.service';
import { DeleteDocumentDialogComponent } from '../delete/delete-document-dialog.component';
import { DocumentTaglistDialogComponent } from '../taglist/document-taglist-dialog.component';
import { ProductFeaturesService } from 'app/services/product-features.service';
import { TranslateService } from '@ngx-translate/core';
import * as FileSaver from 'file-saver';
import { getToken } from '../../../util/token';
import {AccessLevelService, userRoles} from '../../../services/user/access-level.service';
import { ActivatedRoute } from '@angular/router';
import { ShareDocumentDialogComponent } from '../shared/share-dialog/share-dialog.component';
import { SharedDocumentsService } from '../../../services/documents/shared.service';
import {getInstanceName} from "../../../util/environment";

type DocumentContainerDesign = 'default' | 'fluid';

@Component({
  selector: 'app-documents-container',
  templateUrl: './documents-container.component.html',
  styleUrls: ['./documents-container.component.scss']
})
export class DocumentsContainerComponent implements OnInit, OnDestroy, OnChanges {
  @Input() readonly = false;
  @Input() align = 'center';
  @Input() searchable = false;
  @Input('upload-url') uploadUrl;
  @Input('upload-key') uploadKey = 'document';
  @Input() source = [];
  @Input() uploadButtonText: string = null;
  @Input() highlightedDocument: number = null;
  @Input() design = 'default';
  @Input() showPath = true;
  @Input() emptyHint: string = null;
  @Output() documentChanged = new EventEmitter<{ document: Document; change: 'created' | 'updated' | 'deleted' }>();
  @Output() documentDownloaded = new EventEmitter<Document>();

  appConfig = AppConfig;

  filter: string;
  isTagFilter = false;

  untaggedDocuments = [];

  writeEnabled = false;
  accessWriteEnabled = false;
  accessToSharedDocuments = false;

  selectedPath: string[] = [];

  users = [];

  canDownload = true;

  constructor(
    private bus: BusService,
    private events: EventsService,
    private dialog: MatDialog,
    private docService: DocumentResourceService,
    private userService: UserManagementService,
    private featureService: ProductFeaturesService,
    private translate: TranslateService,
    private access: AccessLevelService,
    private route: ActivatedRoute,
    private sharedDocuments: SharedDocumentsService
  ) {
    if (this.access.checkRole(userRoles.vaterSolutionDashboard) === 0) {
      this.canDownload = false;
    }
  }

  get tags() {
    return [... new Set(this.source.map(doc => doc.tags).reduce((a, b) => a.concat(b), []).map(tag => tag.value))];
  }

  get isFilteredUntagged() {
    return this.untaggedDocuments.length;
  }

  get documents() {
    let doc = [];
    if (this.isFilteredUntagged) {
      doc = this.untaggedDocuments;
    } else {
      doc = this.filtered(this.source);
    }

    doc = doc.sort((a, b) => a.fileName.localeCompare(b.fileName));

    // show highlighted document first
    if (this.highlightedDocument) {
      const highlighted = doc.find(d => d.id == this.highlightedDocument);
      console.log(doc, highlighted, this.highlightedDocument);
      if (highlighted) {
        return [highlighted, ...doc.filter(d => d.id != this.highlightedDocument)];
      }
    }

    if (this.filter) {
      return doc;
    }

    return doc.filter(d => (this.selectedPath.length === 0 && (d.folderPath === null || !d.folderPath)) || (d.folderPath?.join('/') === this.selectedPath.join('/')));
  }

  get isInSupervisionMode() {
    return localStorage.getItem('isInSupervisionMode') === 'true';
  }

  get folderPaths() {
    return this.source.map(d => d.folderPath).filter(d => d && d.length > 0);
  }

  private filtered(documentList) {
    if (this.filter) {
      const match = key => key.toLowerCase().indexOf(this.filter.toLowerCase()) !== -1;
      return documentList.filter(document => {
        if (document.fileName && match(document.fileName)) return true;

        const tags = document.tags.map((tag) => tag.value);
        const matches = tags.filter(s => s.includes(this.filter));

        if (matches.length > 0) return true;

        return false;
      });
    }
    else return documentList;
  }

  uploaderName(document) {
    const candidates = this.users.filter(user => user.id === document.uploaderId);
    if (candidates.length > 0) return candidates[0].name;
    return '---';
  }

  downloadLink(document) {
    return this.appConfig.documentDownloadUrl + document.id + `/file?token=${getToken()}`;
  }

  downloadAction(event, document) {
    if (event) {
      event.stopPropagation();
    }

    this.documentDownloaded.emit(document);
  }

  docImage(document) {
    if (document.extension) {
      let ext = document.extension.toLowerCase();
      if (ext == 'pdf') return 'assets/docs/pdf.svg';
      if (ext == 'doc' || ext == 'docx') return 'assets/docs/doc.svg';
      if (ext == 'xls' || ext == 'xlsx') return 'assets/docs/xls.svg';
    }

    return 'assets/docs/generic.svg';
  }

  hasTags(document) {
    return document.tags && document.tags.length > 0;
  }

  limitTags(tags) {
    if (tags.length > 2) {
      const width = tags[0].value.length;

      // if width > 25, limit to first tag
      return width >= 20 ? tags.slice(0,1) : tags.slice(0, 2);
    }

    return tags;
  }

  showTags(document) {
    this.dialog.open(DocumentTaglistDialogComponent, {
      width: '350px',
      maxHeight: '90vh',
      data: {
        document: document
      }
    });
  }

  primaryAction(document, event) {
    if (event) event.stopPropagation();

    if (!this.readonly) {
      this.editDocument(document, event);
    } else if (this.canDownload) {
      this.download(document);
    }
  }

  private download(document) {
    FileSaver.saveAs(this.downloadLink(document), document.fileName);
    this.downloadAction(null, document);
  }

  editDocument(document, event) {
    if (this.readonly) return;
    if (event) event.stopPropagation();

    this.dialog.open(DocumentsUploadDialogComponent, {
      width: '600px',
      maxHeight: '90vh',
      data: {
        isExistingDocument: true,
        document: document,
        writeEnabled: this.writeEnabled && this.accessWriteEnabled,
        treeSource: this.source.map(d => d.folderPath).filter(d => d && d.length > 0),
        pathEnabled: this.showPath
      }
    }).afterClosed().subscribe(mutated => {
      if (mutated) {
        this.documentChanged.emit({ document, change: 'updated' });
      }
    });
  }

  deleteDocument(document, event) {
    if (event) event.stopPropagation();

    this.dialog.open(DeleteDocumentDialogComponent, {
      width: '350px',
      maxHeight: '90vh',
      data: {
        document
      }
    }).afterClosed().subscribe(mutated => {
      if (mutated) {
        this.bus.publish(this.events.requested.data.documents.all);
        this.documentChanged.emit({ document, change: 'deleted' });
      }
    });
  }

  shareDocument(document, event) {
    if (event) event.stopPropagation();

    this.dialog.open(ShareDocumentDialogComponent, {
      width: '450px',
      maxHeight: '90vh',
      data: {
        document
      }
    }).afterClosed().subscribe(shared => {
      if (shared) {

      }
    });
  }

  public addDocument() {
    this.dialog.open(DocumentsUploadDialogComponent, {
      width: '600px',
      maxHeight: '90vh',
      data: {
        uploadUrl: this.uploadUrl,
        uploadKey: this.uploadKey,
        treeSource: this.source.map(d => d.folderPath).filter(d => d && d.length > 0),
        folderPath: this.selectedPath,
        pathEnabled: this.showPath
      }
    }).afterClosed().subscribe(mutated => {
      if (mutated) {
        this.bus.publish(this.events.requested.data.documents.all);
        this.documentChanged.emit({ document: null, change: 'created' });
      }
    });
  }

  filterTags(tag: string) {
    this.resetFilter();

    this.filter = tag;
    this.isTagFilter = true;
  }

  filterUntagged() {
    this.filter = this.translate.instant('documents.taglist.untagged');
    this.untaggedDocuments = this.source.filter(d => !d.tags.length);
    this.isTagFilter = true;
  }

  resetFilter() {
    if (this.isFilteredUntagged) {
      this.untaggedDocuments = [];
    }
    this.filter = undefined;
    this.isTagFilter = false;
  }

  public ngOnInit(): void {
    this.userService.users(true).subscribe(response => {
      this.users = response.map(user => Object.assign({}, user, {
        name: `${user.firstName} ${user.lastName}`
      }));
    });

    const details = {};
    this.route.paramMap.subscribe(params => {
      const paId = parseInt(params.get('paId'), 10);
      if (paId) {
        Object.assign(details, { paId });
      }
    });

    this.access.checkAccess({ context: 'documents', details })
      .subscribe(access => {
        this.accessWriteEnabled = access.write;
        // this.readonly = !access.write;
      });

    this.writeEnabled = this.featureService.hasAccessToFeature('document_management');
    this.featureService.hasAccessToFeatureLocal('shared-documents')
      .subscribe((access) => {
        this.accessToSharedDocuments = access.access;
      });
  }

  public ngOnChanges(changes: SimpleChanges): void {
    // if (changes.source) {
    //   this.tree = buildFileTreeFromPaths(this.source.map(d => d.folderPath).filter(d => d && d.length > 0));
    //   console.log(this.tree);
    // }
  }

  public ngOnDestroy(): void {
  }

}

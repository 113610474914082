import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

import { SupervisionTemplatesBackendService } from '../../../../services/supervision/templates-backend.service';
import { DeleteTemplateDialogComponent } from '../delete-template-dialog/delete-template-dialog.component';
import { Template } from '../types';

@Component({
  selector: 'app-select-template-dialog',
  templateUrl: './select-template-dialog.component.html',
  styleUrls: ['./select-template-dialog.component.scss']
})
export class SelectTemplateDialogComponent implements OnInit {

  public templates: Template[];

  constructor(
    private dialog: MatDialog,
    private backend: SupervisionTemplatesBackendService,
    private dialogRef: MatDialogRef<SelectTemplateDialogComponent>,
  ) { }

  ngOnInit() {
    this.update();
  }

  update() {
    this.backend.all().subscribe((templates: Template[]) => {
      this.templates = templates.map(template => Object.assign(template, {
        createdAt: new Date(template.createdAt as string),
        updatedAt: new Date(template.updatedAt as string)
      }));
    });
  }

  select(template) {
    this.dialogRef.close(template);
  }

  delete(template, event) {
    if (event) event.stopPropagation();

    this.dialog.open(DeleteTemplateDialogComponent, {
      width: '300px',
      maxHeight: '90vh',
      data: template
    }).afterClosed().subscribe(mutated => {
      if (mutated) this.update();
    });
  }
}

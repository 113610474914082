import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { AppConfig } from '../../../app.config';
import { ControllerVendor, VendorsService } from '../../../services/vendors/vendors.service';
import { getToken } from '../../../util/token';

@Component({
  selector: 'app-recipient-dpa',
  templateUrl: './recipient-dpa.component.html',
  styleUrls: ['./recipient-dpa.component.scss']
})
export class RecipientDpaComponent implements OnInit, AfterViewInit {
  @Input() vendor: ControllerVendor;
  @Input() readonly = false;

  @Output() changed = new EventEmitter<ControllerVendor>();

  @ViewChild('offered') offered;
  @ViewChild('upload') upload;

  contractStart = new FormControl();
  contractEnd = new FormControl();

  uploaded = false;

  constructor(
    private vendorService: VendorsService
  ) { }

  ngOnInit(): void {
    this.contractStart.setValue(this.vendor.contractStart);
    this.contractEnd.setValue(this.vendor.contractEnd);
  }

  ngAfterViewInit() {
    this.loadDocs();
  }

  get noDpa() {
    return !this.vendor.docPath && !this.vendor.offeredDocPath || this.vendor.notRequired;
  }

  toggleRequired() {
    this.vendorService.toggleDPARequired({
      vendorId: this.vendor.vendorId,
      notRequired: this.vendor.notRequired,
    }).subscribe(() => {
      this.changed.emit(this.vendor);
    });
  }

  toggleVendorSigned() {
    this.vendor.signed = !this.vendor.signed;
    this.vendorService.toggleDPASigned(this.vendor.vendorId, this.vendor.signed).subscribe(() => {
      this.changed.emit(this.vendor);
    });
  }

  dpaUploaded() {
    this.uploaded = true;
    this.vendor.docPath = `${AppConfig.apiUrl}/vendors/${this.vendor.vendorId}/dpa/?token=${getToken()}`;
    this.loadDocs();
    this.changed.emit(this.vendor);
  }

  updateDpa() {
    Object.assign(this.vendor, {
      contractStart: this.contractStart.value,
      contractEnd: this.contractEnd.value,
    });

    this.vendorService.updateDPA(this.vendor.vendorId, { contractStart: this.vendor.contractStart, contractEnd: this.vendor.contractEnd })
      .subscribe(() => {
        this.changed.emit(this.vendor);
      });
  }

  private loadDocs() {
    if (this.vendor.docPath) {
      this.upload.load(
        `${AppConfig.apiUrl}/vendors/${this.vendor.vendorId}/dpa/?token=${getToken()}`);
    } else if (this.vendor.offeredDocPath) {
      this.offered.load(
        `${AppConfig.apiUrl}/vendors/${this.vendor.vendorId}/dpa/offered/` +
        `?token=${getToken()}`);
    }
  }
}

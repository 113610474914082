import { Injectable } from '@angular/core';
import { RequestService } from '../request.service';
import { UserManagementService } from '../user/user-management.service';
import { MentionsService } from '../mentions/mentions.service';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Todo } from '../../components/todos/todo.interface';
import { HttpParams } from '@angular/common/http';
import { BusService } from '../bus.service';

export interface TodoSearchCriteria {
  done?: boolean;
  limit?: number;
  order?: 'dueDate' | 'lastChange' | 'chronological';
  keyword?: string;
  subject?: string;
  parent?: number;
  user?: number;
}

@Injectable({
  providedIn: 'root',
})
export class TodoService {

  todosList: Todo[] = [];

  constructor(
    private request: RequestService,
    private userService: UserManagementService,
    private mentions: MentionsService,
    private route: Router,
    private bus: BusService
  ) {
  }

  createTodo(body) {
    this.request.post({
      uri: '/todos',
      body: body
    }).subscribe(() => {
    });
  }

  fetchTodos(criteria?: TodoSearchCriteria): Observable<Todo[]> {
    this.todosList = []; // quick way to reset the array
    return this.request.get<Todo[]>({
      uri: '/todos/all',
      parameters: this.paramsFromCriteria(criteria),
      handlers: {
        success: () => { },
        error: () => { },
      }
    });
  }

  getAssigned(criteria?: TodoSearchCriteria) {
    return this.request.get< {assignee: any; todos: Todo[] }>({
      uri: '/todos/assignee',
      parameters: this.paramsFromCriteria(criteria),
      handlers: {
        success: () => { },
        error: () => { },
      }
    });
  }

  getSingle(id, withoutController = false) {
    return this.request.get<Todo>({
      uri: `/todos/${id}${withoutController ? '?noController=true' : ''}`,
    });
  }

  /**
   * Todo fields that can be updated
   * ASSIGNEE_ID
   * CONTROLLER_ID -> in case we assign a different controller by mistake
   * TITLE
   * DESCRIPTION
   * LINK
   * SUBJECT
   * DONE
   * DUE_DATE
   * LAST_CHANGE
   * RESPONSE ??
   * PARENT
   *
   * returns an object of the updated todo to update the currently open todo details dialog
   */
  updateTodo(body, todoId?) {
    const uri = (todoId) ? `/todos/${todoId}` : '/todos';
    return this.request.put<Todo, any>({
      uri: uri,
      body: body
    }).subscribe((response) => {
      this.bus.publish('todo.update.event', response);
    });
  }

  markTodoAsDone(todoId) {
    return this.request.patch({
      uri: `/todos/done/${todoId}`,
      body: {},
      handlers: {
        success: () => { },
        error: () => { },
      }
    });
  }

  reopenTodo(todoId) {
    return this.request.patch({
      uri: `/todos/open/${todoId}`,
      body: {},
      handlers: {
        success: () => { },
        error: () => { },
      }
    });
  }

  deleteTodo(todoId): void {
    this.request.delete({
      uri: `/todos/${todoId}`
    }).subscribe(() => { });
  }

  polishTodos(...todos: Todo[]): Todo[] {
    todos.map(t => {
      t.dueDate = t.dueDate ? new Date(t.dueDate) : null;
      t.createdAt = new Date(t.createdAt);
      t.lastChange = new Date(t.lastChange);
    });

    return todos;
  }

  polish = (src$: Observable<Todo | Todo[]>) => src$.pipe(map(received => {
    if (Array.isArray(received)) {
      return this.polishTodos(...received);
    } else {
      return this.polishTodos(received as any);
    }
  }));

  getFilteredAllowedUser() {
    return this.userService.users(true)
      .pipe(
        map(response => this.mentions.sanitizeList(response)
          .filter(u => !u.deleted || u.supervisorClientAccessMode === 'all')
          .sort((a, b) => a.id - b.id)
        )
      );
  }

  /**
   * Ported from TUM
   *
   * @param criteria
   */
  paramsFromCriteria(criteria?: TodoSearchCriteria): HttpParams {
    let params = new HttpParams();

    if (criteria) {
      if (criteria.done) {
        params = params.set('done', '1');
      }

      if (criteria.limit) {
        params = params.set('limit', criteria.limit.toString());
      }

      if (criteria.order) {
        params = params.set('order', criteria.order);
      }

      if (criteria.keyword) {
        params = params.set('keyword', criteria.keyword);
      }

      if (criteria.subject) {
        params = params.set('subject', criteria.subject);
      }

      if (criteria.user) {
        params = params.set('user', criteria.user.toString());
      }

      if (criteria.parent) {
        params = params.set('parent', criteria.parent.toString());
      }
    }

    return params;
  }

}

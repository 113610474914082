<div class="cool-upload-wrapper">
  <div class="drop-holder" *ngIf="!filePicked" (click)="!readonly ? input.click() : {}">
    <ngx-file-drop class="cool-upload" [class.small]="small" (onFileDrop)="dropped($event)">
      <ng-template ngx-file-drop-content-tmp>
        <ng-content select="[hint]"></ng-content>
      </ng-template>
    </ngx-file-drop>
  </div>
  <input #input type="file" (change)="selected($event)" style="display: none"/>
  <a #download target="_blank" style="display: none"></a>
  <div class="preview-holder" [class.small]="small"  *ngIf="filePicked">
    <img #preview *ngIf="preview"/>
    <div class="loading" [ngClass]="{'active': uploading}">
      <progress-indicator [progress]="uploadProgress"></progress-indicator>
    </div>
    <div class="indicators" *ngIf="!preview">
      <img *ngIf="uploadProgress==1 && !uploading && !loaded"
          src="assets/complete.svg"
          class="indicator complete"/>
      <img *ngIf="loaded"
            src="assets/download.svg"
            (click)="download.click()"
            class="indicator download"/>
    </div>
    <div class="clear" (click)="clear()" *ngIf="!readonly">
      <mat-icon>close</mat-icon>
    </div>
  </div>
</div>

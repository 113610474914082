import { AfterViewInit, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { AppConfig } from 'app/app.config';
import { Audit, AuditsService } from 'app/services/audits/audits.service';
import { WorkflowTasksService } from 'app/services/workflow-tasks/workflow-tasks.service';
import { AuthenticationService } from '../../../services/authentication.service';
import { getToken } from '../../../util/token';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'audit-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss']
})
export class AuditReportComponent implements OnInit, AfterViewInit {

  @ViewChild('upload') upload;

  audit: Audit;
  downloadURL = '';
  uploadDocURL = '';
  uploaded = false;

  constructor(
    private auditService: AuditsService,
    private tasks: WorkflowTasksService,
    private changeDetector: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.auditService.getLatestAudit().subscribe(audit => {
      if (audit) {
        this.audit = audit;
        this.downloadURL = this.auditService.auditDownloadLink(audit.id);
        this.uploadDocURL = this.auditService.auditUploadDocLink(audit.id);
        this.tryToLoad();
      }
    });
  }

  ngAfterViewInit() {
    this.tryToLoad();
  }

  tryToLoad() {
    if (this.audit && this.upload && this.audit.reportDoc) {
      this.upload.load(AppConfig.documentDownloadUrl + this.audit.reportDoc + `/file?token=${getToken()}`);
      this.uploaded = true;
    }
  }

  docUploaded() {
    this.uploaded = true;
    this.changeDetector.detectChanges();
    this.tasks.progressControllerTask({
      progress: 1,
      workflowCodename: 'audit',
      taskCodename: 'ad_audit_report'
    });
  }
}

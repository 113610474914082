<h2 matDialogTitle>
  {{'supervision.addGroup.title' | translate}}
</h2>

<mat-dialog-content>
  <mat-form-field>
    <input matInput type="text" name="groupTitle" required
           placeholder="{{'supervision.addGroup.groupTitle' | translate}}"
           [formControl]="title"/>
    <mat-hint>
      {{'supervision.addGroup.hint' | translate}}
    </mat-hint>
    <mat-error *ngIf="title.hasError('required')">
      {{'supervision.addGroup.error' | translate}}
    </mat-error>
  </mat-form-field>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button color="primary" (click)="create()">
    {{'supervision.addGroup.title' | translate}}
  </button>
</mat-dialog-actions>

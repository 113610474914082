<h1 mat-dialog-title>
  {{user.firstName}} {{user.lastName}}
  <small>
    {{user.email}}
  </small>
</h1>

<mat-dialog-content>
  <mat-form-field>
    <mat-select
      (selectionChange)="updateUser()"
      placeholder="{{'user-management.edit.department.placeholder' | translate}}"
      [(value)]="user.departmentId">
      <mat-option> {{'user-management.edit.department.none' | translate}} </mat-option>
      <mat-option *ngFor="let department of departments" [value]="department.id">
        {{department.name}}
      </mat-option>
    </mat-select>
    <mat-hint>
      {{'user-management.edit.department.hint' | translate}}
    </mat-hint>
  </mat-form-field>
  <mat-form-field>
    <mat-select
      placeholder="{{'user-management.role' | translate}}"
      [(ngModel)]="user.access" (selectionChange)="updateUser()">
      <mat-option *ngFor="let r of roles" [value]="r">
        {{ r | translate }}
      </mat-option>
    </mat-select>
    <mat-hint *ngIf="!user.access">
      {{ 'user-management.invite.role.hint' | translate}}
    </mat-hint>
    <mat-hint *ngIf="user.access">
      {{ (user.access + '.hint') | translate }}
    </mat-hint>
  </mat-form-field>
  <!-- Two Factor Auth -->
  <ng-container *ngIf="isSupervisor() && twoFactorOrgRuleActive">
    <div class="two-factor-info">
      <h3>{{'user-management.edit.twoFactor.title' | translate}}</h3>
      <ng-container *ngIf="twoFactorInfo">
        <p class="enabled">{{'user-management.edit.twoFactor.enabled' | translate}}</p>
        <button mat-stroked-button color="warn" [disabled]="!canDelete() || twoFactorPending" (click)="disableTwoFactor()">{{'user-management.edit.twoFactor.disable' | translate}}</button>
      </ng-container>
      <ng-container *ngIf="!twoFactorInfo && !twoFactorInfoError">
        <p class="not-enabled">{{'user-management.edit.twoFactor.notYetEnabled' | translate}}</p>
      </ng-container>
      <ng-container *ngIf="!twoFactorInfo && twoFactorInfoError">
        <p class="no-permissions">{{'user-management.edit.twoFactor.noPermission' | translate}}</p>
      </ng-container>
    </div>
  </ng-container>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button color="warn" *ngIf="canDelete()" (click)="openDeleteDialog()">
    {{'word.delete' | translate}}
  </button>
  <button mat-button color="primary" mat-dialog-close>
    {{'word.done' | translate}}
  </button>
</mat-dialog-actions>

import {Injectable} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import {BusService} from '../bus.service';
import {
  IGetOptions,
  RequestService
} from '../request.service';
import {EventsService} from '../events.service';

interface IGetResponse {
  department: string;
  processingActivityName: string;
  total: number;
  forms: any;
}

/*
  An interlayer service for rpa get request between request service and rpa component
*/
@Injectable()
export class RpaGetService {

  private options: IGetOptions<IGetResponse>;

  constructor(private bus: BusService,
              private events: EventsService,
              private requestService: RequestService) {
    this.options = {
      uri: '/pa/',
      handlers: {
        success: this.success.bind(this),
        error: this.error.bind(this)
      }
    };
    this.subscribe();
  }

  public success(data: IGetResponse): void {
    if (data && 'paLastChangedOn' in data) {
      let paLastChangedOn = new Date(data['paLastChangedOn']);
      (<any>data)['paLastChangedOn'] = paLastChangedOn.toDateString();
    }

    this.bus.publish(this.events.received.data.rpa.get.success, data);
  }

  public error(httpErrorResponse: HttpErrorResponse): void {
    this.bus.publish(this.events.received.data.rpa.get.failure, httpErrorResponse);
  }

  // request the details of pa with pa Id paId
  public request(paId: any) {
    this.options.uri = `/pa/${paId}`;
    return this.requestService.get<IGetResponse>(this.options);
  }

  public subscribe(): void {
    this.bus.subscribe(this.events.requested.data.rpa.get, this.request.bind(this));
  }
}

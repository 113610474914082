<div *ngIf="record" class="content dsar-record-details">
  <a routerLink="/dsar/records" class="back-link">
    <mat-icon>
      arrow_back
    </mat-icon>
    {{ 'dsar.record.back' | translate }}
  </a>
  <h1 class="title">
  {{ 'dsar.record.title' | translate }}
  </h1>
  <p *ngIf="dsar.id">
  {{ 'dsar.record.hint.edit' | translate }}
  </p>
  <p *ngIf="!dsar.id">
  {{ 'dsar.record.hint.create' | translate }}
  </p>
  <br><br>

  <div commentable *ngIf="dsar.id"
    comment-subject-codename="dsar-{{dsar.id}}"
    comment-subject-title="{{ 'dsar.record.comment' | translate }}">
    <todoable subject="dsar-{{dsar.id}}" suggested-title="{{ 'dsar.record.comment' | translate }}"></todoable>

    <mat-form-field>
      <input matInput type="text" [(ngModel)]="dsar.title"
        placeholder="{{'dsar.records.add.title.placeholder' | translate}}"
        (change)="changed = true"
      />
      <mat-hint>
        {{'dsar.records.add.title.placeholder.hint' | translate}}
      </mat-hint>
    </mat-form-field>
  </div>

  <mat-form-field *ngIf="!dsar.id">
    <input matInput type="text" [(ngModel)]="dsar.title"
      placeholder="{{'dsar.records.add.title.placeholder' | translate}}"
      (change)="changed = true"
    />
    <mat-hint>
      {{'dsar.records.add.title.placeholder.hint' | translate}}
    </mat-hint>
  </mat-form-field>

  <ng-select
    [items]="allDepartments"
    required
    bindLabel="name"
    bindValue="id"
    (change)="changed = true"
    [(ngModel)]="dsar.departmentId"
    placeholder="{{ 'dsar.records.add.department.placeholder' | translate }}">
    <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
      <div class="department-item-info">
        <div class="name">
          {{item.name}}
        </div>
      </div>
    </ng-template>
  </ng-select>

  <mat-form-field>
    <input matInput required [matDatepicker]="receivedOnPicker" [(ngModel)]="dsar.receivedOn" placeholder="{{'dsar.records.add.receivedOn.placeholder' | translate}}" (change)="changed = true">
    <mat-datepicker-toggle matSuffix [for]="receivedOnPicker"></mat-datepicker-toggle>
    <mat-datepicker #receivedOnPicker></mat-datepicker>
  </mat-form-field>

  <mat-form-field>
    <input matInput [matDatepicker]="completionOnPicker" [(ngModel)]="dsar.completionOn" placeholder="{{'dsar.records.add.completionOn.placeholder' | translate}}" (change)="changed = true">
    <mat-datepicker-toggle matSuffix [for]="completionOnPicker"></mat-datepicker-toggle>
    <mat-datepicker #completionOnPicker></mat-datepicker>
  </mat-form-field>

  <mat-form-field>
    <textarea  matInput type="text" name="details"
      placeholder="{{'dsar.records.add.details.placeholder' | translate}}"
      [(ngModel)]="dsar.details" (change)="changed = true"></textarea>
    <mat-hint>
      {{'dsar.records.add.details.placeholder.hint' | translate}}
    </mat-hint>
  </mat-form-field>

  <br><br>

  <h2>
  {{ 'dsar.record.subject.title' | translate }}
  </h2>

  <!-- when we check a dsar if the customFields comes null from the database it will populate our local object and set it as null
      we then need this last check... if lgpd we need to make sure it is then not null to work properly
      line 164 is the same! -->
  <div *ngIf="dsar.customFields !== null && dsar.customFields.jur === 'lgpd'">
    <mat-form-field>
      <mat-select [(ngModel)]='dsar.customFields.subjectRelation'
                  placeholder="{{ 'dsar.submit.subject.relation' | translate }}"
                  (change)="changed = true" required>
        <mat-option value='dsar.submit.subject.client'>
          {{ 'dsar.submit.subject.client' | translate }}
        </mat-option>
        <mat-option value='dsar.submit.subject.vendor'>
          {{ 'dsar.submit.subject.vendor' | translate }}
        </mat-option>
        <mat-option value='dsar.submit.subject.consumer'>
          {{ 'dsar.submit.subject.consumer' | translate }}
        </mat-option>
        <mat-option value='dsar.submit.subject.employee'>
          {{ 'dsar.submit.subject.employee' | translate }}
        </mat-option>
<!--        <mat-option value='dsar.submit.subject.attorney'>-->
<!--          {{ 'dsar.submit.subject.attorney' | translate }}-->
<!--        </mat-option>-->
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <input matInput type="text" [(ngModel)]="dsar.customFields.subjectName"
             placeholder="{{ 'dsar.submit.subject.name' | translate }}" (change)="changed = true"
      />
    </mat-form-field>

    <mat-form-field>
      <input matInput type='text' [(ngModel)]='dsar.customFields.lgpdCPF'
             placeholder="{{ 'dsar.submit.subject.lgpd.cpf' | translate }}"
             required>
      <mat-hint>
        {{ 'dsar.submit.subject.lgpd.cpf.hint' | translate }}
      </mat-hint>
    </mat-form-field>

    <mat-form-field>
      <input matInput type='text' [(ngModel)]='dsar.customFields.lgpdRG'
             placeholder="{{ 'dsar.submit.subject.lgpd.rg' | translate }}"
             required>
      <mat-hint>
        {{ 'dsar.submit.subject.lgpd.rg.hint' | translate }}
      </mat-hint>
    </mat-form-field>
  </div>

  <mat-form-field>
    <input matInput type="text" [(ngModel)]="dsar.subjectEmail"
      placeholder="{{ 'dsar.record.subject.email.placeholder' | translate }}" (change)="changed = true"
    />
    <mat-hint>
      {{ 'dsar.record.subject.email.hint' | translate }}
    </mat-hint>
  </mat-form-field>

  <mat-form-field>
    <mat-select [(ngModel)]="dsar.requestType"
      placeholder="{{ 'dsar.record.subject.request_type.placeholder' | translate }}"
      (selectionChange)="changed = true">
      <mat-option value="dsar.request_type.unknown"
                  matTooltip="{{'dsar.request_types.unknown' | translate}}" matTooltipPosition="above">
        {{ 'dsar.request_types.unknown' | translate }}
      </mat-option>
      <mat-option value="dsar.request_type.data"
                  matTooltip="{{'dsar.request_types.data' | translate}}" matTooltipPosition="above">
        {{'dsar.request_types.data' | translate}}
      </mat-option>
      <mat-option value="dsar.request_type.deletion"
                  matTooltip="{{'dsar.request_types.deletion' | translate}}" matTooltipPosition="above">
        {{'dsar.request_types.deletion' | translate}}
      </mat-option>

      <div *ngIf="(dsar.customFields !== null && dsar.customFields.jur === 'lgpd') || currentSelectedLanguage() === 'pt-BR'">

        <!-- same as submit-dsar.component -->
        <mat-option value="dsar.request_types.lgpd.processingConfirmation"
                    matTooltip="{{'dsar.request_types.lgpd.processingConfirmation' | translate}}" matTooltipPosition="above">
          {{'dsar.request_types.lgpd.processingConfirmation' | translate}}
        </mat-option>
        <mat-option value="dsar.request_types.lgpd.correctionOfData"
                    matTooltip="{{'dsar.request_types.lgpd.correctionOfData' | translate}}" matTooltipPosition="above">
          {{'dsar.request_types.lgpd.correctionOfData' | translate}}
        </mat-option>
        <mat-option value="dsar.request_types.lgpd.deletionOfNonCompliant"
                    matTooltip="{{'dsar.request_types.lgpd.deletionOfNonCompliant' | translate}}" matTooltipPosition="above">
          {{'dsar.request_types.lgpd.deletionOfNonCompliant' | translate}}
        </mat-option>
        <mat-option value="dsar.request_types.lgpd.portDataOut"
                    matTooltip="{{'dsar.request_types.lgpd.portDataOut' | translate}}" matTooltipPosition="above">
          {{'dsar.request_types.lgpd.portDataOut' | translate}}
        </mat-option>
        <mat-option value="dsar.request_types.lgpd.sharingConfirmation"
                    matTooltip="{{'dsar.request_types.lgpd.sharingConfirmation' | translate}}" matTooltipPosition="above">
          {{'dsar.request_types.lgpd.sharingConfirmation' | translate}}
        </mat-option>
        <mat-option value="dsar.request_types.lgpd.consentConfirmation"
                    matTooltip="{{'dsar.request_types.lgpd.consentConfirmation' | translate}}" matTooltipPosition="above">
          {{'dsar.request_types.lgpd.consentConfirmation' | translate}}
        </mat-option>
        <mat-option value="dsar.request_types.lgpd.consentRevocation"
                    matTooltip="{{'dsar.request_types.lgpd.consentRevocation' | translate}}" matTooltipPosition="above">
          {{'dsar.request_types.lgpd.consentRevocation' | translate}}
        </mat-option>
      </div>

    </mat-select>
    <mat-hint>
      {{ 'dsar.record.subject.request_type.hint' | translate }}
    </mat-hint>
  </mat-form-field>

  <mat-form-field>
    <textarea  matInput type="text" name="subjectComments"
      placeholder="{{ 'dsar.record.subject.comments.placeholder' | translate }}"
      (change)="changed = true"
      [(ngModel)]="dsar.subjectComments"></textarea>
    <mat-hint>
      {{ 'dsar.record.subject.comments.hint' | translate }}
    </mat-hint>
  </mat-form-field>

  <br><br>
  <ng-container *ngIf="dsar.id">
    <h2>
    {{'dsar.record.documents.title' | translate}}
    </h2>
    <div
      commentable
      comment-subject-codename="dsar-{{dsar.id}}-documents"
      comment-subject-title="{{ 'dsar.record.comment.documents' | translate }}"
      class="documents">
      <todoable subject="dsar-{{dsar.id}}-documents" suggested-title="{{ 'dsar.record.comment.documents' | translate }}"></todoable>

      <app-documents-container
        align="left"
        [upload-url]="uploadUrl"
        upload-key="document"
        [source]="documents" (documentChanged)="updateList()"></app-documents-container>
    </div>
  </ng-container>

  <div class="actions">
    <button *ngIf="dsar.dsarId" mat-button color="accent" (click)="delete()" style="float: left;">{{'word.delete' | translate}}</button>
    <div class="status">
      <mat-slide-toggle [(ngModel)]="statusOpen" color="warn">
        {{'dsar.record.status.open' | translate}}
      </mat-slide-toggle>
    </div>
    <button mat-flat-button color="primary" [disabled]="!changed" (click)="save()">{{'word.save' | translate}}</button>
  </div>
</div>
<br><br><br>

<h1 mat-dialog-title>{{ 'supervision.clientAccess.permissions' | translate }}</h1>

<mat-dialog-content>
    <div class="access-container">
        <p class="access-description">{{ 'supervision.clientAccess.explanation.part1' | translate }} <span class="client-name">{{ data.client.controllerName }}</span>{{ 'supervision.clientAccess.explanation.part2' | translate }}</p>
        <div *ngIf="!pending" class="user-list">
            <div class="user-item" *ngFor="let user of users">
                <user-avatar [user]="user"></user-avatar>
                <div class="info">
                    <div class="main">{{user.firstName}} {{user.lastName}}</div>
                    <div class="sub">{{user.email}}</div>
                </div>
                <div class="access-switch">
                    <mat-checkbox
                    *ngIf="canBeModified(user)"
                    [checked]="hasAccess(user)"
                    [indeterminate]="accessType(user) === 'inherited'"
                    (change)="updateAccess($event.checked, user)">
                    </mat-checkbox>
                    <p *ngIf="!canBeModified(user)">{{ 'supervision.clientAccess.admin' | translate }}</p>
                </div>
            </div>
        </div>
        <!--<p class="hint">{{ 'supervision.clientAccess.helpText' | translate }}</p>-->
    </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>
        {{'word.close' | translate}}
    </button>
</mat-dialog-actions>
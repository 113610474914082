<h2 matDialogTitle>
  {{'supervision.deleteGroup.title' | translate}}
</h2>
<mat-dialog-content>
  {{'supervision.deleteGroup.hint' | translate}}
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button color="warn" (click)="deleteGroup()">{{'word.remove' | translate}}</button>
  <button mat-button [matDialogClose]="false">{{'word.cancel' | translate}}</button>
</mat-dialog-actions>

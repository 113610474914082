<div class="actions-metadata-info metadata-users">
  <ng-container *ngIf="event.eventName === 'INVITED_USER'">
    <div class="event-left">
      <span class="specimen">{{ event.metadata.email }}</span>
      <span class="type">
        {{ 'events.metadata.users.email' | translate }}
      </span>
    </div>
    <div class="event-action">
      <span class="icon">
        <mat-icon>send</mat-icon>
      </span>
      <span class="action">
        {{ 'events.metadata.users.invitationSent' | translate }}
      </span>
    </div>
  </ng-container>
  <ng-container *ngIf="event.eventName === 'RESENT_INVITE'">
    <ng-container *ngIf="!invite">
      <div class="event-loading metadata-center">
        <mat-icon>bar_chart</mat-icon>
        <span>{{ 'events.metadata.loading' | translate }}</span>
      </div>
    </ng-container>
    <ng-container *ngIf="invite">
      <div class="event-left">
        <span class="specimen">{{ invite.email }}</span>
        <span class="type">
          {{ 'events.metadata.users.email' | translate }}
        </span>
      </div>
      <div class="event-action">
        <span class="icon">
          <mat-icon>replay</mat-icon>
        </span>
        <span class="action">
          {{ 'events.metadata.users.resentInvitation' | translate }}
        </span>
      </div>
    </ng-container>
  </ng-container>
  <ng-container *ngIf="event.eventName === 'CONFIRMED_INVITE'">
    <div class="event-left">
      <span class="specimen">{{ getUser(event.userId) }}</span>
      <span class="type">
        {{ 'events.metadata.users.user' | translate }}
      </span>
    </div>
    <div class="event-action">
      <span class="icon">
        <mat-icon>check</mat-icon>
      </span>
      <span class="action">
        {{ 'events.metadata.users.acceptedInvitation' | translate }}
      </span>
    </div>
  </ng-container>
</div>

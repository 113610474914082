<div *ngIf="!hidden" class="holder {{position}}" #holder [ngClass]="{'notify': notify}">
  <div class="main-circle" matTooltip="{{'todos.create' | translate }}" (click)="openTodoDialog($event)">
    <mat-icon>assignment_turned_in</mat-icon>
  </div>

  <div class="list-circle" matTooltip="{{'todos.seeAll' | translate }}" (click)="openList($event)">
    <mat-icon>list</mat-icon>
  </div>

  <!-- CODE BELOW REFERS TO WHEN A USERS CLICKS TO GO TO A "TICKET" -->
  <div *ngIf="todo" [ngClass]="{'expanded': expandTodo,
                                 done: todo.done,
                                 'documents': position === 'documents' && expandTodo}"
       class="todo" (click)="showTodoDetails()">
    <mat-icon class=" todo-marker">highlight</mat-icon>
    <mat-icon class="todo-marker done">done</mat-icon>
    <div class="text">
      <h1>{{ todo.title }}</h1>

      <p class="description" *ngIf="todo.description">{{ todo.description }}</p>
      <p *ngIf="todo.done"><br><b>{{'todos.done' | translate}}</b></p>

      <div class="bottom">
        <span *ngIf="todo.dueDate">{{ todo.dueDate| date: 'dd.MM.yyyy' }}</span>
      </div>
    </div>

    <mat-icon class="close" *ngIf="expandTodo" (click)="closeTodo($event)">close</mat-icon>
  </div>
</div>

import { environment } from '../environments/environment';

export const AppConfig = {
  apiUrl: environment.apiUrl,
  apiReport: environment.apiUrl + '/report',
  defaultLang: 'de',
  supportedLanguages: ['en', 'en-NG', 'de', 'pt', 'pt-BR'],
  stripePublishable: environment.stripePublishable,
  documentDownloadUrl: environment.apiUrl + `/document/`,
  recaptchaKey: environment.recaptchaKey,
  mixpanelKey: environment.mixpanelKey,
  mixpanelHost: 'https://app-mp.ecomply.io',
  enablePlausible: environment.enablePlausible
};

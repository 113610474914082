import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SupervisorProfileService } from 'app/services/supervision/supervisor-profile.service';
import { delay } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { SupervisorProfileInviteConfirmDialogComponent } from './confirm-dialog/supervisor-profile-invite-confirm-dialog.component';
import { BusService } from 'app/services/bus.service';
import { EventsService } from 'app/services/events.service';


@Component({
  selector: 'app-supervisor-profile-invitation',
  templateUrl: './supervisor-profile-invitation.component.html',
  styleUrls: ['./supervisor-profile-invitation.component.scss']
})
export class SupervisorProfileInvitationComponent implements OnInit {

  invite: any;
  token: any;
  error = false;

  constructor(
    private events: EventsService,
    private bus: BusService,
    private route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    private service: SupervisorProfileService,
  ) { }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      if (params.invite) {
        this.token = params.invite;
        this.service.getInvite(this.token).pipe(delay(1000)).subscribe(invite => {
          this.invite = invite;
        }, () => this.error = true);
      }
    });
  }

  confirm() {
    this.service.confirmInvite(this.token).subscribe(() => {
      this.dialog.open(SupervisorProfileInviteConfirmDialogComponent, {
        width: '350px',
        maxHeight: '90vh',
        data: this.invite,
      }).afterClosed().subscribe(() => {
        this.bus.publish(this.events.notified.user.logout);
        setTimeout(() => {
          this.router.navigate(['/login'], { queryParams: {
            email: this.invite.email,
          }});
        }, 1);
      });
    });
  }

  reject() {
    this.service.rejectInvite(this.token).subscribe(() => this.router.navigate(['/']));
  }
}

<div #holder class="stepper-holder" [ngStyle]="{'height': height}">
  <div class="outer-container" #outer [ngClass]="{'focusing-comment': _from_comment}">
    <div class="internal-container">
      <ng-content select="stepper-step"></ng-content>
    </div>
  </div>

  <ng-container *ngIf="navigation">
    <div class="nav next">
      <button [disabled]="onLast"
        mat-icon-button
        color="primary"
        (click)="next()">
        <mat-icon>navigate_next</mat-icon>
      </button>
    </div>
    <div class="nav prev">
      <button [disabled]="onFirst"
        mat-icon-button
        color="primary"
        (click)="prev()">
        <mat-icon>navigate_before</mat-icon>
      </button>
    </div>
  </ng-container>
</div>

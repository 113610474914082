
<div class="verification">
  <h1 class="title">{{ 'registration.verification.emailverif' | translate }}</h1>
  <br>
  <mat-card *ngIf="state=='initial'">
    <img mat-card-image src="assets/email-auth.svg"/>
    <mat-card-content>
      {{ 'registration.verification.wait' | translate }}
    </mat-card-content>
    <br><br>
    <div class="spinner-container">
      <mat-spinner></mat-spinner>
    </div>
  </mat-card>

  <mat-card *ngIf="state=='success'">
    <img mat-card-image src="assets/email-auth.svg"/>
    <mat-card-content>
      {{ 'registration.verification.verified' | translate }}
    </mat-card-content>
    <mat-card-actions align="end">
      <button mat-raised-button color="primary" routerLink="/">{{ 'registration.verification.start' | translate }}</button>
    </mat-card-actions>
  </mat-card>

  <mat-card *ngIf="state=='failure'">
    <img mat-card-image src="assets/not-found.svg"/>
    <mat-card-content>
        {{ 'registration.verification.error' | translate}}
    </mat-card-content>
  </mat-card>
</div>

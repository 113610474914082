import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TreeNode } from 'app/services/rpa/rpa.reference.service';
import { PaReferenceTreeEditDialogComponent } from '../pa-reference-tree-edit-dialog/pa-reference-tree-edit-dialog.component';

@Component({
  selector: 'app-pa-reference-tree-select-dialog',
  templateUrl: './pa-reference-tree-select-dialog.component.html',
  styleUrls: ['./pa-reference-tree-select-dialog.component.scss']
})
export class PaReferenceTreeSelectDialogComponent implements OnInit {

  tree: TreeNode[];
  allowRoot = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) data: { tree: TreeNode[]; allowRoot?: boolean },
    private ref: MatDialogRef<PaReferenceTreeSelectDialogComponent>,
    private dialog: MatDialog,
  ) {
    this.tree = data.tree;
    this.allowRoot = data.allowRoot || false;
  }

  ngOnInit(): void {
  }

  pick(node: TreeNode) {
    this.ref.close({ node });
  }

  add() {
    this.dialog.open(PaReferenceTreeEditDialogComponent, {
      width: '350px',
      data: {
        tree: this.tree,
      }
    });
  }
}

<button class="big-round-button">
  <div>
    <div class="icon">
      <ng-content select="[button-icon]"></ng-content>
    </div>
    <div class="text">
      <ng-content select="[button-text]"></ng-content>
    </div>
  </div>
</button>

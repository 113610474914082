<div class="supervision-client-list contents">
  <h1 class="title">{{ 'supervision.clientList.title' | translate }}</h1>

  <br>
<!--  <h4 (click)='openTodos($event)'>Todos</h4>-->

  <br><br>
  <p>{{ 'supervision.clientList.message' | translate }}</p>
  <br><br><br>

  <div class="client-list">
    <div class="controls">
      <mat-form-field class="search" [ngClass]="{'filtered': filter}">
        <input matInput placeholder="{{ 'supervision.clientList.search.placeholder1' | translate }} {{clients.length}} {{ 'supervision.clientList.search.placeholder2' | translate }}"
          [(ngModel)]="filter"
          type="text"/>
        <button *ngIf="!filter" mat-icon-button class="search-button" matSuffix>
          <mat-icon>search</mat-icon>
        </button>
        <button *ngIf="filter" mat-icon-button class="clear-button" matSuffix (click)="filter=''">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>

      <div class="search-controls">
        <div class="user-filter" (click)="pickFilteredUser()">
          <mat-icon class="clear" *ngIf="filteredUser" (click)="clearFilteredUser($event)">close</mat-icon>
          <user-avatar [user]="filteredUser"></user-avatar>
          <user-name [user]="filteredUser" placeholder="{{'supervision.clientList.filter.byUser' | translate}}">
          </user-name>
        </div>
        <div style="flex-grow: 1"></div>
        <div class="sortings">

          <div class="sorting"
            matTooltip="{{ 'supervision.clientList.sort.alphabetically' | translate }}"
            [ngClass]="{active: ordering === 'alpha'}" (click)="toggleOrdering('alpha')">
            <mat-icon>sort_by_alpha</mat-icon>
          </div>

          <div class="sorting"
            matTooltip="{{ 'supervision.clientList.sort.lastActivity' | translate }}"
            [ngClass]="{active: ordering === 'activity'}" (click)="toggleOrdering('activity')">
            <mat-icon>history</mat-icon>
          </div>

          <div class="sorting"
            matTooltip="{{ 'supervision.clientList.sort.incidents-dsars' | translate }}"
            [ngClass]="{active: ordering === 'incidents-dsars'}" (click)="toggleOrdering('incidents-dsars')">
            <mat-icon>warning</mat-icon>
          </div>

          <div class="sorting"
            matTooltip="{{ 'supervision.clientList.sort.meeting' | translate }}"
            [ngClass]="{active: ordering === 'meeting'}" (click)="toggleOrdering('meeting')">
            <mat-icon>date_range</mat-icon>
          </div>

          <div class="sorting"
            matTooltip="{{ 'supervision.clientList.sort.openReminders' | translate }}"
            [ngClass]="{active: ordering === 'reminders'}" (click)="toggleOrdering('reminders')">
            <mat-icon>bookmark_border</mat-icon>
          </div>

          <div class="sorting"
            matTooltip="{{ 'supervision.clientList.sort.rpas' | translate }}"
            [ngClass]="{active: ordering === 'rpas'}" (click)="toggleOrdering('rpas')">
            <mat-icon>storage</mat-icon>
          </div>
        </div>
      </div>
    </div>

    <div>
      <div class="selected-group-title" *ngIf="selectedGroup">
        <div>
          <button mat-stroked-button routerLink="/supervision/clients">{{'word.goBack' | translate}}</button>
        </div>
        <div>
          {{'supervision.groupList.title' | translate}} {{selectedGroup.title}}
        </div>
      </div>

      <mat-card *ngIf="!selectedGroup" class="add-item-card">
        <div class="add-button" (click)="handleAddClient()">
          <mat-card-content>
            <div class="inner">
              <mat-icon>person_add</mat-icon>
              <div>
                {{ 'supervision.clientList.createClient' | translate }}
              </div>
            </div>
          </mat-card-content>
        </div>
        <div class="add-button" (click)="handleAddGroup()">
          <mat-card-content>
            <div class="inner">
              <mat-icon>group_add</mat-icon>
              <div>
                {{'supervision.clientList.createGroup' | translate}}
              </div>
            </div>
          </mat-card-content>
        </div>
      </mat-card>

      <mat-card *ngIf="selectedGroup" class="add-client" (click)="handleAddClient()">
        <mat-card-content>
          <div class="inner">
            <mat-icon>group_add</mat-icon>
            <div>
              {{ 'supervision.clientList.createClient' | translate }}
            </div>
          </div>
        </mat-card-content>
      </mat-card>

      <mat-card *ngFor="let client of clients">
        <a *ngIf="client._id" style="color: black" [routerLink]="['/supervision/client/' + client._id ]">
          <mat-card-header>
            <div mat-card-avatar>
              <img
                src="{{appConfig.apiUrl}}/controllers/logo/{{client._id}}/"
                onerror="this.src='/assets/eclogo-40.png'"
                onabort="this.src='/assets/eclogo-40.png'"/>
            </div>
            <mat-card-title class="client-label" title="{{ client.controllerName }}">{{ client.controllerName }}</mat-card-title>
            <mat-card-subtitle
              *ngIf="client.controllerAddressStreet && client.controllerAddressPostcode && client.controllerAddressCity">
              {{ client.controllerAddressStreet }},
              {{ client.controllerAddressPostcode }},
              {{ client.controllerAddressCity }}
            </mat-card-subtitle>

            <span class="client-id" *ngIf="client.clientId" title="{{ client.clientId }}">{{ client.clientId.length > 12 ? client.clientId.substr(0, 12) + '…' : client.clientId }}</span>
          </mat-card-header>
          <mat-card-content>
            <div commentable
                comment-subject-codename="supervision-{{client._id}}"
                comment-subject-title="Supervised Client {{client.controllerName}}">
              <div class="client-info">
                <div class="info-box">
                  <div>{{ 'supervision.clientList.card.labels.openRecordables' | translate }}:</div>
                  <div class="or-indicator" *ngIf="client._extra?.openIncidents > 0"></div>
                  <div><a><b>{{client._extra?.openIncidents || 0}} / {{client._extra?.openDsar || 0}}</b></a></div>
                </div>

                <div class="info-box">
                  <div>{{ 'supervision.clientList.card.labels.openTodos' | translate }}:</div>
                  <div><a><b>{{client._extra?.openTodos || 0}}</b></a></div>
                </div>

                <div class="info-box">
                  <div>{{ 'supervision.clientList.card.labels.rpas' | translate }}:</div>
                  <div class="risk-analysis-indicator" [ngClass]="client._extra?.paData.class"></div>
                  <div><a><b>{{client._extra?.paData?.count || 0}}</b></a></div>
                </div>

                <div class="info-box">
                  <div>{{ 'supervision.clientList.card.labels.users' | translate }}:</div>
                  <div><a><b>{{client._extra?.userCount?.count || 0}}</b></a></div>
                </div>

                <div class="info-box" *ngIf="client.nextMeetingDate || client.nextMeeting">
                  <div>{{ 'supervision.clientList.card.labels.nextMeeting' | translate }}:</div>
                  <div><a><b>{{client.nextMeeting?.date || client.nextMeetingDate | localizedDate}}</b></a></div>
                </div>

                <div class="spacer"></div>

                <div class="info-box bottom-less"
                  *ngIf="client._extra?.tasks && client._extra?.tasks.length > 0 && client._extra?.tasks[0].title">
                  <div>
                    <div class="label">{{ 'supervision.clientList.card.labels.nextStep' | translate }}</div>
                    <div>{{client._extra.tasks[0].title}}</div>
                  </div>
                  <progress-indicator [progress]="client._extra.tasks[0].progress"></progress-indicator>
                </div>

              </div>
            </div>
          </mat-card-content>

          <mat-menu #moreMenu="matMenu" xPosition="before" [overlapTrigger]="false">
            <div mat-menu-item (click)="applyTemplate(client); $event.preventDefault()">{{'supervision.templates.menu.apply' | translate}}</div>
            <div mat-menu-item (click)="createTemplate(client); $event.preventDefault()">{{'supervision.templates.menu.create' | translate}}</div>
            <div mat-menu-item *ngIf="!client.group" (click)="handleAddToGroup(client, $event)">{{'supervision.clientList.addToGroup' | translate}}</div>
            <div mat-menu-item *ngIf="client.group" (click)="handleRemoveFromGroup(client.group, client._id, $event)">{{'supervision.clientList.removeFromGroup' | translate}}</div>
            <div *ngIf="isAdmin" mat-menu-item (click)="manageAccess(client); $event.preventDefault()">{{'supervision.clientAccess.button' | translate}}</div>
          </mat-menu>

          <mat-card-actions align="end">
            <!--
            <button mat-icon-button
              matTooltip="{{ 'supervision.clientList.client.info' | translate }}"
              [routerLink]="['/supervision/client/' + client._id ]">
              <mat-icon color="accent">info_outline</mat-icon>
            </button>
            -->
            <!-- Clients can be deleted by everyone who is also able to see them -->
            <ng-container *ngIf="isAdmin">
              <button mat-icon-button
                matTooltip="{{ 'supervision.clientList.client.remove' | translate }}"
                (click)="deleteClient(client, true, $event); $event.preventDefault()">
                <mat-icon color="accent">delete_outline</mat-icon>
              </button>
            </ng-container>
            <button mat-icon-button (click)="$event.stopPropagation(); $event.preventDefault()" [matMenuTriggerFor]="moreMenu">
              <mat-icon color="accent">more_vert</mat-icon>
            </button>
          </mat-card-actions>
        </a>

        <!-- <a class="group-card" *ngIf="client.id" (click)="selectGroup(client)"> -->
        <a class="group-card" *ngIf="client.id" [routerLink]="['/supervision/group/' + client.id]">
          <mat-card-header>
            <mat-card-title>{{client.title}} ({{getGroupClients(client).length}})</mat-card-title>
          </mat-card-header>
          <mat-card-content>
            <div class="group-clients-list">
              <div *ngFor="let client of getGroupClients(client)">
                <a [routerLink]="['/supervision/client/' + client._id ]" matTooltip="{{client.controllerName}}" matTooltipPosition="above">
                <div>
                  <img class="group-card-avatar"
                    src="{{appConfig.apiUrl}}/controllers/logo/{{client._id}}/"
                    onerror="this.src='/assets/eclogo-40.png'"
                    onabort="this.src='/assets/eclogo-40.png'"
                  alt="{{client.controllerName}}"/>
                </div>
                </a>
              </div>
              <div *ngIf="!getGroupClients(client).length">
                {{'supervision.group.empty' | translate}}
              </div>
            </div>
          </mat-card-content>
          <mat-card-actions>
            <ng-container>
              <button mat-icon-button
                      matTooltip="{{ 'supervision.group.deleteGroup' | translate }}"
                      (click)="handleDeleteGroup(client, $event)">
                <mat-icon color="accent">delete_outline</mat-icon>
              </button>
            </ng-container>
          </mat-card-actions>
        </a>

        <mat-card-footer *ngIf="client._extra?.lastEventDate">
          {{'word.latestChange' | translate }}: {{ (client._extra?.lastEventDate | localizedDate : 'short') || '-' }}
        </mat-card-footer>
      </mat-card>

      <mat-card *ngFor="let request of pending" class="client-pending">
        <mat-card-header>
          <div mat-card-avatar>
            <mat-icon>mail_outline</mat-icon>
          </div>
          <mat-card-title class="client-label">{{ request.email }}</mat-card-title>
          <mat-card-subtitle>{{ 'supervision.clientList.pending' | translate }}</mat-card-subtitle>
        </mat-card-header>

        <mat-card-content>
          <div commentable
              comment-subject-codename="supervision-request-{{request._id}}"
              comment-subject-title="Supervised Request to {{request.email}}">
              {{ 'supervision.clientList.client.pending.email' | translate }}
          </div>
        </mat-card-content>

        <mat-card-actions align="end">
          <button mat-icon-button
            matTooltip="{{ 'supervision.clientList.client.resendInvitation' | translate }}"
            (click)="resendInvitation(request)">
            <mat-icon color="accent">mail_outline</mat-icon>
          </button>
          <button mat-icon-button
            matTooltip="{{ 'supervision.clientList.client.remove' | translate }}"
            (click)="deleteClient(request, false, $event)">
            <mat-icon color="accent">delete_outline</mat-icon>
          </button>
        </mat-card-actions>
      </mat-card>

      <!-- Demo Client Hint -->
      <mat-card class="add-client" (click)="addDemoClients()">
        <mat-card-content>
          <div class="inner">
            <mat-icon>group_add</mat-icon>
            <div>
              {{ 'supervision.clientList.addDemo' | translate }}
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>

  <br><br><br><br>
</div>

<h1 mat-dialog-title>
  <!-- {{ 'documents.upload.dialog.title' | translate }} -->
  <!-- {{ 'documents.update.dialog.title' | translate }} -->
  {{ (!isExistingDocument? 'documents.upload.dialog.title' : 'documents.update.dialog.title') | translate}}
</h1>

<mat-dialog-content>
  <div class="document-upload">
    <cool-upload #upload [preview]="false" (uploadedResponse)="updateDocument($event)" [upload-url]="uploadUrl()"
      upload-key="document" [readonly]="!writeEnabled">
      <span hint>
        {{ 'documents.upload.dialog.upload.desc' | translate }}
      </span>
    </cool-upload>
  </div>
  <div class="file-info">
    <mat-form-field>
      <input matInput type="text" name="fileName"
        placeholder="{{'documents.upload.dialog.form.fileName.placeholder' | translate}}" [formControl]="fileName"
        [readonly]="!writeEnabled" />
    </mat-form-field>

    <ng-container *ngIf="showPath">
      <span class="folder-hint">{{ 'documents.upload.folder' | translate }}</span>
      <app-documents-select-path [(path)]="folderPath" [treeSource]="treeSource" [readonly]="false"></app-documents-select-path>
    </ng-container>

    <div class="upgrade-hint" *ngIf="!writeEnabled">
      <p>{{ 'feature.locked.explanation.1' | translate }} <a>{{ 'feature.locked.identifier.documents' | translate }}</a>
        {{ 'feature.locked.explanation.2' | translate }}</p>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>
    {{'word.cancel' | translate}}
  </button>
  <button mat-button [disabled]="!documentId || !writeEnabled" color="primary" (click)="update()">
    <!-- {{ 'documents.upload.dialog.save' | translate }} -->
    <!-- {{ 'documents.update.dialog.save' | translate }} -->
    {{ (!isExistingDocument? 'documents.upload.dialog.save' : 'documents.update.dialog.save') | translate}}
  </button>
</mat-dialog-actions>

import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BusService } from '../../../services/bus.service';
import { EventsService } from '../../../services/events.service';
import { MixpanelService } from '../../../modules/mixpanel/mixpanel.service';

/**
 * We want the badge to show in 3 different jurisdictions.
 * The response from the backend comes with a {jurisdiction} to set the correct in the info page.
 *
 * This implies that the user serving the badge on their website sets their own jurisdiction.
 */

@Component({
  selector: 'app-badge-info',
  templateUrl: './badge-info.component.html',
  styleUrls: ['./badge-info.component.scss'],
})
export class BadgeInfoComponent implements OnInit, OnDestroy {

  token: string;
  controller: any;
  dpo: any;
  jurisdiction: string;
  loading = true;

  constructor(
    private bus: BusService,
    private events: EventsService,
    private route: ActivatedRoute,
    private mixpanel: MixpanelService,
  ) {
    this.route.paramMap.subscribe(params => {
      this.token = params.get('token');
      this.bus.publish(this.events.requested.data.badge.info, this.token);
    });
  }

  updateInfo({ info }) {
    this.controller = info.controller;
    this.dpo = info.dpo ?? undefined;
    this.jurisdiction = info.jurisdiction;
    this.loading = false;
  }

  ngOnInit() {
    this.bus.subscribe(this.events.received.data.badge.info.success, this.updateInfo, this);
    this.mixpanel.track('badge:getInfo');
  }

  ngOnDestroy() {
    this.bus.unsubscribe(this.events.received.data.badge.info.success, this.updateInfo);
  }

  badge() {
    return `assets/badge/${this.jurisdiction}-gold.png`;
  }
}

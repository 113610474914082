<div class="controller">
  <h1 class="title">{{'controller.titel' | translate }}</h1>
  <br/>
  <div class="form-holder">
    <p>
      {{'controller.message' | translate }}
    </p>
    <p *ngIf="accessLevel && !accessLevel.write" class="warning">
      <span>{{'word.NOTICE' | translate}}</span>
      {{'word.readonlyAccess' | translate}}
    </p>
    <form name="form" [formGroup]="formGroup" novalidate>
      <div commentable
        comment-subject-codename="controller-name"
        comment-subject-title="{{ 'controller.placeholder.controllerName' | translate }}"
        >
        <mat-form-field>
          <input matInput placeholder="{{ 'controller.placeholder.controllerName' | translate }}" name="controllerName"
                 type="text"
                 [readonly]="!canWrite"
                 formControlName="controllerName"
                 (change)="submit('controllerName')" autofocus required/>
           <mat-hint *ngIf="!fieldsBeingSaved['controllerName']">
             {{'basicInfo.company.nameField.hint' | translate}}
           </mat-hint>
           <mat-hint *ngIf="fieldsBeingSaved['controllerName']">{{'basicInfo.fields.saving' | translate}}</mat-hint>
        </mat-form-field>
      </div>

      <div commentable
        comment-subject-codename="controller-url"
        comment-subject-title="{{ 'controller.placeholder.controllerUrl' | translate }}"
        >
        <mat-form-field>
          <input matInput placeholder="{{ 'controller.placeholder.controllerUrl' | translate }}" name="controllerUrl"
                 type="text"
                 [readonly]="!canWrite"
                 formControlName="controllerUrl"
                 (change)="submit('controllerUrl')" required/>
           <mat-hint *ngIf="!fieldsBeingSaved['controllerUrl']">
             {{'basicInfo.company.urlField.hint' | translate}}
           </mat-hint>
           <mat-hint *ngIf="fieldsBeingSaved['controllerUrl']">{{'basicInfo.fields.saving' | translate}}</mat-hint>
        </mat-form-field>
      </div>

      <div commentable
        comment-subject-codename="controller-telephone"
        comment-subject-title="{{ 'controller.placeholder.controllerTelephoneNumberFull' | translate }}"
        >
        <mat-form-field>
          <input matInput placeholder="{{ 'controller.placeholder.controllerTelephoneNumber' | translate }}"
                 name="controllerTelephoneNumber"
                 type="text"
                 [readonly]="!canWrite"
                 formControlName="controllerTelephoneNumber"
                 (change)="submit('controllerTelephoneNumber')" required/>
           <mat-hint *ngIf="!fieldsBeingSaved['controllerTelephoneNumber']">
             {{'basicInfo.company.phoneField.hint' | translate}}
           </mat-hint>
           <mat-hint *ngIf="fieldsBeingSaved['controllerTelephoneNumber']">{{'basicInfo.fields.saving' | translate}}</mat-hint>
        </mat-form-field>
      </div>

      <div commentable
        comment-subject-codename="controller-address-street"
        comment-subject-title="{{ 'controller.placeholder.controllerAddressStreetFull' | translate }}"
        >
        <mat-form-field>
          <input matInput placeholder="{{ 'controller.placeholder.controllerAddressStreet' | translate }}"
                 name="controllerAddressStreet" type="text"
                 [readonly]="!canWrite"
                 formControlName="controllerAddressStreet"
                 (change)="submit('controllerAddressStreet')" required/>
           <mat-hint *ngIf="!fieldsBeingSaved['controllerAddressStreet']">
             {{'basicInfo.company.streetField.hint' | translate}}
           </mat-hint>
           <mat-hint *ngIf="fieldsBeingSaved['controllerAddressStreet']">{{'basicInfo.fields.saving' | translate}}</mat-hint>
        </mat-form-field>
      </div>

      <div commentable
        comment-subject-codename="controller-address-postcode"
        comment-subject-title="{{ 'controller.placeholder.controllerAddressPostcodeFull' | translate }}"
        >
        <mat-form-field>
          <input matInput placeholder="{{ 'controller.placeholder.controllerAddressPostcode' | translate }}"
                 name="controllerAddressPostcode" type="text"
                 [readonly]="!canWrite"
                 formControlName="controllerAddressPostcode"
                 (change)="submit('controllerAddressPostcode')" required/>
           <mat-hint *ngIf="!fieldsBeingSaved['controllerAddressPostcode']">
             {{'basicInfo.company.postCodeField.hint' | translate}}
           </mat-hint>
           <mat-hint *ngIf="fieldsBeingSaved['controllerAddressPostcode']">{{'basicInfo.fields.saving' | translate}}</mat-hint>
        </mat-form-field>
      </div>

      <div commentable
        comment-subject-codename="controller-address-city"
        comment-subject-title="{{ 'controller.placeholder.controllerAddressCityFull' | translate }}"
        >
        <mat-form-field>
          <input matInput placeholder="{{ 'controller.placeholder.controllerAddressCity' | translate }}"
                 name="controllerAddressCity" type="text"
                 [readonly]="!canWrite"
                 formControlName="controllerAddressCity"
                 (change)="submit('controllerAddressCity')" required/>
           <mat-hint *ngIf="!fieldsBeingSaved['controllerAddressCity']">
             {{'basicInfo.company.cityField.hint' | translate}}
           </mat-hint>
           <mat-hint *ngIf="fieldsBeingSaved['controllerAddressCity']">{{'basicInfo.fields.saving' | translate}}</mat-hint>
        </mat-form-field>
      </div>

      <div commentable
        comment-subject-codename="controller-address-country"
        comment-subject-title="{{ 'controller.placeholder.controllerAddressCountryFull' | translate }}"
        >
        <mat-form-field>
          <input matInput placeholder="{{ 'controller.placeholder.controllerAddressCountry' | translate }}"
                 name="controllerAddressCountry" type="text"
                 [readonly]="!canWrite"
                 formControlName="controllerAddressCountry"
                 (change)="submit('controllerAddressCountry')" required/>
           <mat-hint *ngIf="!fieldsBeingSaved['controllerAddressCountry']">
             {{'basicInfo.company.countryField.hint' | translate}}
           </mat-hint>
           <mat-hint *ngIf="fieldsBeingSaved['controllerAddressCountry']">{{'basicInfo.fields.saving' | translate}}</mat-hint>
        </mat-form-field>
      </div>

      <div commentable
        comment-subject-codename="controller-contact-name"
        comment-subject-title="{{ 'controller.placeholder.controllerContactNameFull' | translate }}"
        >
        <mat-form-field>
          <input matInput placeholder="{{ 'controller.placeholder.controllerContactName' | translate }}"
                 name="controllerContactName" type="text"
                 [readonly]="!canWrite"
                 formControlName="controllerContactName"
                 (change)="submit('controllerContactName')" required/>
           <mat-hint *ngIf="!fieldsBeingSaved['controllerContactName']">
             {{'basicInfo.company.contactPersonField.hint' | translate}}
           </mat-hint>
           <mat-hint *ngIf="fieldsBeingSaved['controllerContactName']">{{'basicInfo.fields.saving' | translate}}</mat-hint>
        </mat-form-field>
      </div>

      <div commentable
        comment-subject-codename="controller-contact-role"
        comment-subject-title="{{ 'controller.placeholder.controllerContactRoleFull' | translate }}"
        >
        <mat-form-field>
          <input matInput placeholder="{{ 'controller.placeholder.controllerContactRole' | translate }}"
                 name="controllerContactRole" type="text"
                 [readonly]="!canWrite"
                 formControlName="controllerContactRole"
                 (change)="submit('controllerContactRole')" required/>
           <mat-hint *ngIf="!fieldsBeingSaved['controllerContactRole']">
             {{'basicInfo.company.roleField.hint' | translate}}
           </mat-hint>
           <mat-hint *ngIf="fieldsBeingSaved['controllerContactRole']">{{'basicInfo.fields.saving' | translate}}</mat-hint>
        </mat-form-field>
      </div>

      <div commentable
        comment-subject-codename="controller-dpCoordination"
        comment-subject-title="{{ 'controller.placeholder.controllerDpCoordination' | translate }}"
        >
        <mat-form-field>
          <textarea matInput placeholder="{{ 'controller.placeholder.controllerDpCoordination' | translate }}"
                name="controllerDpCoordination" type="text"
                [readonly]="!canWrite"
                formControlName="controllerDpCoordination"
                (change)="submit('controllerDpCoordination')" required></textarea>
          <mat-hint *ngIf="!fieldsBeingSaved['controllerDpCoordination']">
            {{'basicInfo.company.controllerDpCoordination.hint' | translate}}
          </mat-hint>
          <mat-hint *ngIf="fieldsBeingSaved['controllerDpCoordination']">{{'basicInfo.fields.saving' | translate}}</mat-hint>
        </mat-form-field>
      </div>

      <div commentable
        comment-subject-codename="controller-email"
        comment-subject-title="{{ 'controller.placeholder.controllerEmailAddressFull' | translate }}"
        >
        <mat-form-field>
          <input matInput placeholder="{{ 'controller.placeholder.controllerEmailAddress' | translate }}"
                 name="controllerEmailAddress" type="text"
                 [readonly]="!canWrite"
                 formControlName="controllerEmailAddress"
                 (change)="submit('controllerEmailAddress')" required/>
           <mat-hint *ngIf="!fieldsBeingSaved['controllerEmailAddress']">
             {{'basicInfo.company.emailField.hint' | translate}}
           </mat-hint>
           <mat-hint *ngIf="fieldsBeingSaved['controllerEmailAddress']">{{'basicInfo.fields.saving' | translate}}</mat-hint>
        </mat-form-field>
      </div>

      <!-- Provisions -->
      <div class="provisions" formGroupName="provisions">
        <p [innerHTML]="'controller.provisions.message' | translate "></p>

        <div commentable
             comment-subject-codename="controller-provisions-backups"
             comment-subject-title="{{ 'controller.placeholder.provisions.backups' | translate }}"
        >
          <mat-form-field>
            <mat-select [disabled]="!canWrite" (selectionChange)="submit('provisionsBackups')" formControlName="backups" placeholder="{{ 'controller.placeholder.provisions.backups' | translate }}">
              <mat-option [value]="'exists'">{{ 'controller.provisions.exists' | translate }}</mat-option>
              <mat-option [value]="'none'">{{ 'controller.provisions.none' | translate }}</mat-option>
              <mat-option [value]="'hidden'">{{ 'controller.provisions.hidden' | translate }}</mat-option>
            </mat-select>
            <mat-hint *ngIf="!fieldsBeingSaved['provisionsBackups']">
              {{'basicInfo.provisions.hint' | translate}}
            </mat-hint>
            <mat-hint *ngIf="fieldsBeingSaved['provisionsBackups']">{{'basicInfo.fields.saving' | translate}}</mat-hint>
          </mat-form-field>
        </div>

        <div commentable
             comment-subject-codename="controller-provisions-guidelines"
             comment-subject-title="{{ 'controller.placeholder.provisions.guidelines' | translate }}"
        >
          <mat-form-field>
            <mat-select [disabled]="!canWrite" (selectionChange)="submit('provisionsGuidelines')" formControlName="guidelines" placeholder="{{ 'controller.placeholder.provisions.guidelines' | translate }}">
              <mat-option [value]="'exists'">{{ 'controller.provisions.exists' | translate }}</mat-option>
              <mat-option [value]="'none'">{{ 'controller.provisions.none' | translate }}</mat-option>
              <mat-option [value]="'hidden'">{{ 'controller.provisions.hidden' | translate }}</mat-option>
            </mat-select>
            <mat-hint *ngIf="!fieldsBeingSaved['provisionsGuidelines']">
              {{'basicInfo.provisions.hint' | translate}}
            </mat-hint>
            <mat-hint *ngIf="fieldsBeingSaved['provisionsGuidelines']">{{'basicInfo.fields.saving' | translate}}</mat-hint>
          </mat-form-field>
        </div>

        <div commentable
             comment-subject-codename="controller-provisions-itSecurity"
             comment-subject-title="{{ 'controller.placeholder.provisions.itSecurity' | translate }}"
        >
          <mat-form-field>
            <mat-select [disabled]="!canWrite" (selectionChange)="submit('provisionsItSecurity')" formControlName="itSecurity" placeholder="{{ 'controller.placeholder.provisions.itSecurity' | translate }}">
              <mat-option [value]="'exists'">{{ 'controller.provisions.exists' | translate }}</mat-option>
              <mat-option [value]="'none'">{{ 'controller.provisions.none' | translate }}</mat-option>
              <mat-option [value]="'hidden'">{{ 'controller.provisions.hidden' | translate }}</mat-option>
            </mat-select>
            <mat-hint *ngIf="!fieldsBeingSaved['provisionsItSecurity']">
              {{'basicInfo.provisions.hint' | translate}}
            </mat-hint>
            <mat-hint *ngIf="fieldsBeingSaved['provisionsItSecurity']">{{'basicInfo.fields.saving' | translate}}</mat-hint>
          </mat-form-field>
        </div>

        <div commentable
             comment-subject-codename="controller-provisions-permissions"
             comment-subject-title="{{ 'controller.placeholder.provisions.permissions' | translate }}"
        >
          <mat-form-field>
            <mat-select [disabled]="!canWrite" (selectionChange)="submit('provisionsPermissions')" formControlName="permissions" placeholder="{{ 'controller.placeholder.provisions.permissions' | translate }}">
              <mat-option [value]="'exists'">{{ 'controller.provisions.exists' | translate }}</mat-option>
              <mat-option [value]="'none'">{{ 'controller.provisions.none' | translate }}</mat-option>
              <mat-option [value]="'hidden'">{{ 'controller.provisions.hidden' | translate }}</mat-option>
            </mat-select>
            <mat-hint *ngIf="!fieldsBeingSaved['provisionsPermissions']">
              {{'basicInfo.provisions.hint' | translate}}
            </mat-hint>
            <mat-hint *ngIf="fieldsBeingSaved['provisionsPermissions']">{{'basicInfo.fields.saving' | translate}}</mat-hint>
          </mat-form-field>
        </div>

        <div commentable
             comment-subject-codename="controller-provisions-deletion"
             comment-subject-title="{{ 'controller.placeholder.provisions.deletion' | translate }}"
        >
          <mat-form-field>
            <mat-select [disabled]="!canWrite" (selectionChange)="submit('provisionsDeletion')" formControlName="deletion" placeholder="{{ 'controller.placeholder.provisions.deletion' | translate }}">
              <mat-option [value]="'exists'">{{ 'controller.provisions.exists' | translate }}</mat-option>
              <mat-option [value]="'none'">{{ 'controller.provisions.none' | translate }}</mat-option>
              <mat-option [value]="'hidden'">{{ 'controller.provisions.hidden' | translate }}</mat-option>
            </mat-select>
            <mat-hint *ngIf="!fieldsBeingSaved['provisionsDeletion']">
              {{'basicInfo.provisions.hint' | translate}}
            </mat-hint>
            <mat-hint *ngIf="fieldsBeingSaved['provisionsDeletion']">{{'basicInfo.fields.saving' | translate}}</mat-hint>
          </mat-form-field>
        </div>
      </div>

      <div commentable
           comment-subject-codename="controller-third-country-transfers"
           comment-subject-title="{{ 'controller.placeholder.thirdCountryTransfers' | translate }}"
      >
        <mat-form-field>
          <mat-select [disabled]="!canWrite" (selectionChange)="submit('thirdCountryTransfers')" formControlName="thirdCountryTransfers" placeholder="{{ 'controller.placeholder.thirdCountryTransfers' | translate }}">
            <mat-option [value]="'acceptable'">{{ 'controller.thirdCountryTransfers.acceptable' | translate }}</mat-option>
            <mat-option [value]="'unacceptable'">{{ 'controller.thirdCountryTransfers.unacceptable' | translate }}</mat-option>
            <mat-option [value]="'incomplete'">{{ 'controller.thirdCountryTransfers.incomplete' | translate }}</mat-option>
            <mat-option [value]="'none'">{{ 'controller.thirdCountryTransfers.none' | translate }}</mat-option>
            <mat-option [value]="'hidden'">{{ 'controller.provisions.hidden' | translate }}</mat-option>
          </mat-select>
          <mat-hint *ngIf="!fieldsBeingSaved['thirdCountryTransfers']">
            {{'basicInfo.provisions.hint' | translate}}
          </mat-hint>
          <mat-hint *ngIf="fieldsBeingSaved['thirdCountryTransfers']">{{'basicInfo.fields.saving' | translate}}</mat-hint>
        </mat-form-field>
      </div>

      <div class="buttons">
        <button type="button" mat-raised-button color="primary" routerLink="">{{ 'word.done' | translate }}</button>
      </div>
    </form>
  </div>
</div>

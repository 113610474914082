import { Injectable } from '@angular/core';

import {HttpErrorResponse} from '@angular/common/http';
import {BusService} from '../bus.service';
import {EventsService} from '../events.service';
import {
  IGetOptions,
  IPostOptions,
  RequestService
} from '../request.service';

interface IDocumentUpdateModel {
  id: number;
  fileName?: string;
  tags?: Array<string>;
}

export interface Document {
  id: number;
  controllerId: number;
  uploaderId: number;
  fileName: string;
  tags: string[];
  type: string;
  groupName: string;
}

@Injectable()
export class DocumentResourceService {

  api = {
    root: '/document',
    update: '/',
    getAll: '/',
    getGroup: '/group',
    delete: '/'
  };

  constructor(
    private bus: BusService,
    private events: EventsService,
    private requestService: RequestService
  ) {
    this.subscribe();
  }

  getAll() {
    return this.requestService.get<any>({
      uri: this.api.root + this.api.getAll,
      handlers: {
        success: documents => {
          this.bus.publish(this.events.received.data.documents.all.success, documents);
        },
        error: error =>  {
          this.bus.publish(this.events.received.data.documents.all.failure, error.error);
        },
      }
    });
  }

  getAllForGroup(groupName: string, exact: boolean) {
    return this.requestService.get<Document[]>({
      uri: this.api.root + this.api.getGroup + `/${groupName}` + `${exact ? '?exact=true' : ''}`
    });
  }

  getAllSupervisor() {
    return this.requestService.get<Document[]>({
      uri: this.api.root + '/supervisor'
    });
  }

  updateDocument(model: IDocumentUpdateModel) {
    return this.requestService.patch<IDocumentUpdateModel, any>({
      uri: this.api.root + this.api.update + model.id,
      body: model,
      handlers: {
        success: response => {
          this.bus.publish(this.events.received.action.documents.update.success, response);
        },
        error: error =>  {
          this.bus.publish(this.events.received.action.documents.update.failure, error.error);
        },
      }
    });
  }

  deleteDocument(document) {
    return this.requestService.delete<any, any>({
      uri: this.api.root + this.api.update + document.id,
      handlers: {
        success: response => {
          this.bus.publish(this.events.received.action.documents.delete.success, response);
        },
        error: error =>  {
          this.bus.publish(this.events.received.action.documents.delete.failure, error.error);
        },
      }
    });
  }

  subscribe() {
    this.bus.subscribe(this.events.requested.action.documents.update, this.updateDocument.bind(this));
    this.bus.subscribe(this.events.requested.action.documents.delete, this.deleteDocument.bind(this));
    this.bus.subscribe(this.events.requested.data.documents.all, this.getAll.bind(this));
  }
}

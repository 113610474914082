<mat-form-field>
  <textarea matTextareaAutosize matInput rows="3"
  placeholder="{{'supervision.clientDetails.controller.clientNotes.placeholder' | translate}}"
  [(ngModel)]="controller.clientNotes"></textarea>
  <mat-hint>
    {{'supervision.clientDetails.controller.clientNote.hint' | translate}}
  </mat-hint>
</mat-form-field>

<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>
    {{'word.cancel' | translate}}
  </button>
  <button mat-flat-button color="primary" (click)="submit()">
    {{'word.save' | translate}}
  </button>
</mat-dialog-actions>
<div class="applications" [class.pa-list]="paId">
  <ng-container *ngFor="let application of applications">
    <a class="application" (click)="primary(application)">
      <div class="left">
        <div class="avatar">
          <img
            *ngIf="application.logoUrl"
            [src]="application.logoUrl"
            onerror="this.onerror=null;this.src='/assets/default_vendor.svg';"
          />
          <mat-icon *ngIf="!application.logoUrl" class="no-logo">web_asset</mat-icon>
        </div>

        <div class="info">
          <div class="title">
            {{ application.name }}
          </div>
          <div class="vendor-label">
            <ng-container *ngIf="application.vendor">{{ 'applications.list.hasVendor' | translate }}</ng-container>
            <ng-container *ngIf="!application.vendor">{{ 'applications.list.internalApplication' | translate }}</ng-container>
          </div>
        </div>
      </div>

      <button *ngIf="paId && !readonly" matTooltip="{{ 'word.unlink' | translate }}"  mat-icon-button (click)="delete($event, application)">
        <mat-icon>link_off</mat-icon>
      </button>
    </a>
    <mat-divider></mat-divider>
  </ng-container>

</div>
<ng-container *ngIf="applications.length === 0">
  <p>
    {{ "applications.empty" | translate }}
  </p>
</ng-container>

<div class="actions-metadata-info metadata-vendors">
  <ng-container *ngIf="event.eventName === 'ADD_DPA'">
    <ng-container *ngIf="!vendor">
      <div class="event-loading metadata-center">
        <mat-icon>bar_chart</mat-icon>
        <span>{{ 'events.metadata.loading' | translate }}</span>
      </div>
    </ng-container>
    <ng-container *ngIf="vendor">
      <div class="event-left">
        <span class="specimen">{{ vendor.name }}</span>
        <span class="type">
          {{ 'events.metadata.rpaProcessors.vendor' | translate }}
        </span>
      </div>
      <div class="event-action">
        <span class="icon">
          <mat-icon>add</mat-icon>
        </span>
        <span class="action">
          {{ 'events.metadata.vendors.dpaAdded' | translate }}
        </span>
      </div>
    </ng-container>
  </ng-container>
  <ng-container *ngIf="event.eventName === 'REQUEST_DPA'">
    <ng-container *ngIf="!vendor">
      <div class="event-loading metadata-center">
        <mat-icon>bar_chart</mat-icon>
        <span>{{ 'events.metadata.loading' | translate }}</span>
      </div>
    </ng-container>
    <ng-container *ngIf="vendor">
      <div class="event-left">
        <span class="specimen">{{ vendor.name }}</span>
        <span class="type">
          {{ 'events.metadata.rpaProcessors.vendor' | translate }}
        </span>
      </div>
      <div class="event-action">
        <span class="icon">
          <mat-icon>move_to_inbox</mat-icon>
        </span>
        <span class="action">
          {{ 'events.metadata.vendors.dpaRequested' | translate }}
        </span>
      </div>
    </ng-container>
  </ng-container>
  <ng-container *ngIf="event.eventName === 'DPA_TOGGLE_REQUIRED'">
    <ng-container *ngIf="!vendor">
      <div class="event-loading metadata-center">
        <mat-icon>bar_chart</mat-icon>
        <span>{{ 'events.metadata.loading' | translate }}</span>
      </div>
    </ng-container>
    <ng-container *ngIf="vendor">
      <div class="event-left">
        <span class="specimen">{{ vendor.name }}</span>
        <span class="type">
          {{ 'events.metadata.rpaProcessors.vendor' | translate }}
        </span>
      </div>
      <ng-container *ngIf="event.metadata.notRequired">
        <div class="event-action">
          <span class="icon">
            <mat-icon>not_interested</mat-icon>
          </span>
          <span class="action">
            {{ 'events.metadata.vendors.dpaNotRequired' | translate }}
          </span>
        </div>
      </ng-container>
      <ng-container *ngIf="!event.metadata.notRequired">
        <div class="event-action">
          <span class="icon">
            <mat-icon>check</mat-icon>
          </span>
          <span class="action">
            {{ 'events.metadata.vendors.dpaRequired' | translate }}
          </span>
        </div>
      </ng-container>
    </ng-container>
  </ng-container>
</div>

import { Component, OnInit, OnDestroy, ViewChild, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BusService } from 'app/services/bus.service';
import { EventsService } from 'app/services/events.service';
import { ActionsService } from 'app/services/actions/actions.service';
import { UserManagementService } from 'app/services/user/user-management.service';
import { Event } from '../../actions.component';
import { DepartmentService } from 'app/services/departments/department.service';

@Component({
  selector: 'app-actions-metadata-rpa-processors',
  templateUrl: './rpa-processors.component.html',
  styleUrls: ['./rpa-processors.component.scss']
})
export class ActionsMetadataRpaProcessorsComponent implements OnInit {

  @Input() event: Event;
  pa: any;

  department: any;
  vendor: any;

  constructor(
    private bus: BusService,
    private events: EventsService,
    private userService: UserManagementService,
    private departmentService: DepartmentService
  ) {
  }


  private receivePa(pa) {
    this.pa = pa;
  }

  private receiveVendors(vendors) {
    this.vendor = vendors.find((v) => v.id === parseInt(this.event.metadata.vendorId, 10));
  }

  private receiveDepartments(departments) {
    this.department = departments.find((d) => d.id === parseInt(this.event.metadata.departmentId, 10));
  }

  ngOnInit() {
    this.bus.subscribe(this.events.received.data.rpa.get.success, this.receivePa, this);
    this.bus.subscribe(this.events.received.data.vendors.get.success, this.receiveVendors, this);
    this.bus.subscribe(this.events.received.data.departments.get.success, this.receiveDepartments, this);

    this.bus.publish(this.events.requested.data.rpa.get, this.event.metadata.paId);

    // For an external processor, load the vendor. For an internal processor, load the department.
    if (this.event.eventName.startsWith('EP')) {
      this.bus.publish(this.events.requested.data.vendors.get);
    } else if (this.event.eventName.startsWith('IP')) {
      this.bus.publish(this.events.requested.data.departments.get);
    }
  }
}

import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ControllerService } from 'app/services/controller/controller.service';
import { FormControl } from '@angular/forms';


@Component({
  selector: 'app-supervision-client-next-meeting-dialog',
  templateUrl: './supervision-client-next-meeting-dialog.component.html',
  styleUrls: ['./supervision-client-next-meeting-dialog.component.scss']
})
export class SupervisionClientNextMeetingDialogComponent implements OnInit {

  ogNextMeeting: any;
  meetingDate = new FormControl();
  meetingHour = new FormControl();
  meetingMinute = new FormControl();
  meetingTitle = new FormControl();

  constructor(
    @Inject(MAT_DIALOG_DATA) public controller: any,
    private ref: MatDialogRef<SupervisionClientNextMeetingDialogComponent>,
    private service: ControllerService,
  ) { }

  ngOnInit(): void {
    if (this.controller.nextMeetingDate && !this.controller.nextMeeting) {
      this.meetingDate.setValue(this.controller.nextMeetingDate);
      this.meetingHour.setValue(this.controller.nextMeetingDate.getHours());
      this.meetingMinute.setValue(this.controller.nextMeetingDate.getMinutes());
    }

    if (this.controller.nextMeeting && this.controller.nextMeeting.date) {
      this.meetingTitle.setValue(this.controller.nextMeeting.title);
      this.meetingDate.setValue(new Date(this.controller.nextMeeting.date));
      this.meetingHour.setValue(new Date(this.controller.nextMeeting.date).getHours());
      this.meetingMinute.setValue(new Date(this.controller.nextMeeting.date).getMinutes());
    }

    this.ogNextMeeting = this.controller.nextMeetingDate || this.controller.nextMeeting;
    this.ref.beforeClosed().subscribe(saved => {
      if (!saved) {
        if (this.controller.nextMeeting) {
          this.controller.nextMeeting = this.ogNextMeeting;
        } else {
          this.controller.nextMeetingDate = this.ogNextMeeting;
        }
      }
    });
  }

  submit() {
    const resolvedMeetingDate = new Date(this.meetingDate.value);
    resolvedMeetingDate.setHours(this.meetingHour.value);
    resolvedMeetingDate.setMinutes(this.meetingMinute.value);
    resolvedMeetingDate.setSeconds(0);

    this.controller.nextMeeting = {
      title: this.meetingTitle.value,
      date : resolvedMeetingDate.toString(),
    };

    this.service.requestPut(this.controller).subscribe(
      () => {
        this.ref.close(true);
      },
      () => {
        this.ref.close();
      }
    );
  }
}

<div class="vendor-library">
  <h1 class="title">{{ 'vendor-library.title' | translate }}</h1>
  <p>{{ 'vendor-library.explanation' | translate }}</p>

  <mat-form-field>
    <input placeholder="{{'pa.vendors.searchDialog.search' | translate}}"
      matInput type="text" [(ngModel)]="query" autofocus/>
    <button [ngClass]="{'active': !query}" class="search"
      mat-button matSuffix mat-icon-button>
      <mat-icon>search</mat-icon>
    </button>
    <button [ngClass]="{'active': query}"
      (click)="query=''" class="clear"
      mat-button matSuffix mat-icon-button>
      <mat-icon>clear</mat-icon>
    </button>
    <mat-hint innerHTML="{{'pa.vendors.searchDialog.searchHint' | translate}}">
    </mat-hint>
  </mat-form-field>

  <div class="actions">
    <button mat-flat-button color="primary" (click)="newVendor()">
      {{'vendor-library.addVendor' | translate}}
    </button>
  </div>

  <div class="list">
    <div *ngFor="let vendor of filtered"
      (click)="edit(vendor)"
      class="vendor">
      <div class="avatar">
        <img *ngIf="vendor.logoUrl" [src]="vendor.logoUrl" onerror="this.onerror=null;this.src='/assets/default_vendor.svg';"/>
        <mat-icon *ngIf="!vendor.logoUrl" class="no-logo">location_city</mat-icon>
      </div>

      <div class="info">
        <div class="title">
          {{vendor.name}}
        </div>
        <ng-container *ngIf="vendor.type === 'vendor'">
          <div class="address">
            {{vendor.addressCity}}, {{vendor.addressCountry}}
          </div>
          <div class="email">
            {{vendor.email}}
          </div>
        </ng-container>
        <div *ngIf="vendor.type === 'other'" class="address">
          {{ 'data-recipients.vendor.typeOther' | translate }}
        </div>
      </div>
    </div>
  </div>
</div>

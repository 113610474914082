import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthenticationService } from '../services/authentication.service';
import { ProductFeaturesService } from 'app/services/product-features.service';

@Injectable()
export class ProductFeaturesGuard implements CanActivate {
  constructor(
    private router: Router,
    private authService: AuthenticationService,
    private featuresService: ProductFeaturesService,
  ) { }

  public canActivate(route: ActivatedRouteSnapshot): boolean {
    const expectedFeatureAccess = route.data.feature;

    const access = this.featuresService.hasAccessToFeature(expectedFeatureAccess);

    if (access) {
      return true;
    } else {
      this.router.navigate(['/locked-feature'], { queryParams: { feature: expectedFeatureAccess }});
    }
    return false;
  }
}

<h1 mat-dialog-title>{{ 'dpia.necessity.dialog.title' | translate }}</h1>

<mat-dialog-content>
  <p class="help-text">{{ 'dpia.necessity.dialog.helpText' | translate }}</p>
  <div class="questionnaire">
    <div *ngFor="let question of getQuestions(); let last=last" class="item">
      <div class="question">
        <!-- {{ 'dpia.necessity.dialog.question.title.sensitive-data' | translate }} -->
        <!-- {{ 'dpia.necessity.dialog.question.title.social-data' | translate }} -->
        <!-- {{ 'dpia.necessity.dialog.question.title.new-technologies' | translate }} -->
        <!-- {{ 'dpia.necessity.dialog.question.title.automatic-scoring' | translate }} -->
        <!-- {{ 'dpia.necessity.dialog.question.title.public-surveillance' | translate }} -->
        <!-- {{ 'dpia.necessity.dialog.question.title.third-country-transfers' | translate }} -->
        <!-- {{ 'dpia.necessity.dialog.question.title.surveillance-control' | translate }} -->
        <!-- {{ 'dpia.necessity.dialog.question.title.high-volume-processing' | translate }} -->
        <!-- {{ 'dpia.necessity.dialog.question.title.two-processings-merge' | translate }} -->
        <!-- {{ 'dpia.necessity.dialog.question.title.vulnerable-people' | translate }} -->
        <!-- {{ 'dpia.necessity.dialog.question.title.limited-right-exercise' | translate }} -->

        <!-- {{ 'dpia.necessity.dialog.question.readonly.sensitive-data' | translate }} -->
        <!-- {{ 'dpia.necessity.dialog.question.tooltip.sensitive-data' | translate }} -->
        <!-- {{ 'dpia.necessity.dialog.question.tooltip.social-data' | translate }} -->
        <!-- {{ 'dpia.necessity.dialog.question.tooltip.new-technologies' | translate }} -->
        <!-- {{ 'dpia.necessity.dialog.question.tooltip.automatic-scoring' | translate }} -->
        <!-- {{ 'dpia.necessity.dialog.question.tooltip.public-surveillance' | translate }} -->
        <!-- {{ 'dpia.necessity.dialog.question.tooltip.third-country-transfers' | translate }} -->
        <!-- {{ 'dpia.necessity.dialog.question.tooltip.surveillance-control' | translate }} -->
        <!-- {{ 'dpia.necessity.dialog.question.tooltip.high-volume-processing' | translate }} -->
        <!-- {{ 'dpia.necessity.dialog.question.tooltip.two-processings-merge' | translate }} -->
        <!-- {{ 'dpia.necessity.dialog.question.tooltip.vulnerable-people' | translate }} -->
        <!-- {{ 'dpia.necessity.dialog.question.tooltip.limited-right-exercise' | translate }} -->
        <p>
          <mat-icon matTooltip="{{ ('dpia.necessity.dialog.question.tooltip.' + question.identifier) | translate }}">help_outline</mat-icon>
          {{ ('dpia.necessity.dialog.question.title.' + question.identifier) | translate }}
        </p>

        <mat-radio-group [disabled]="question.readonly" matTooltip="{{ question.readonly ? (('dpia.necessity.dialog.question.readonly.' + question.identifier) | translate) : '' }}" [value]="getItem(question.identifier)" (change)="setItem(question, $event)">
          <mat-radio-button [value]="false">{{ 'yesno.no' | translate }}</mat-radio-button>
          <mat-radio-button [value]="true">{{ 'yesno.yes' | translate }}</mat-radio-button>
        </mat-radio-group>
      </div>
      <hr *ngIf="!last"/>
    </div>
  </div>
  <div class="result" [class.filled]="allFilled" [class.dpia-necessary]="dpiaNecessary" [class.dpia-not-necessary]="!dpiaNecessary">
    <div class="result-item">
      <ng-container *ngIf="!allFilled && !dpiaNecessary">
        <p class="missing-responses">
          <mat-icon>arrow_forward</mat-icon>
          {{ 'dpia.necessity.dialog.result' | translate }}: {{ 'dpia.necessity.dialog.result.missingResponses' | translate }}
        </p>
      </ng-container>
      <ng-container *ngIf="allFilled || dpiaNecessary">
        <p class="necessary" *ngIf="dpiaNecessary">
          <mat-icon>arrow_forward</mat-icon>
          <!--{{ 'dpia.necessity.dialog.result' | translate }}:--> {{ 'dpia.necessity.dialog.result.dpiaNecessary' | translate }}
        </p>
        <p class="not-necessary" *ngIf="!dpiaNecessary">
          <mat-icon>arrow_forward</mat-icon>
          <!--{{ 'dpia.necessity.dialog.result' | translate }}:--> {{ 'dpia.necessity.dialog.result.dpiaNotNecessary' | translate }}
        </p>
      </ng-container>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>{{ 'word.cancel' | translate }}</button>
  <button mat-raised-button [disabled]="!allFilled && !dpiaNecessary" color="primary" (click)="close()">{{ 'dpia.necessity.dialog.finished' | translate }}</button>
</mat-dialog-actions>

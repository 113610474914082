<div class="contents">
  <stepper #stepper [navigation]="false">
    <stepper-step id="personalData">
      <div class="scrollable">
        <mat-card>
          <img mat-card-image src="assets/incident/personal_data.png" width="100%"/>
          <p>
            {{ 'incident.personalData' | translate }}
          </p>
          <mat-card-actions align="end">
            <button mat-button
                    (click)="stepper.switch('comeBackLater')"
                    color="warn">
              {{ 'yesno.no' | translate }}
            </button>
            <button mat-flat-button
                    (click)="stepper.switch('scope')"
                    color="primary">
              {{ 'yesno.yes' | translate }}
            </button>
          </mat-card-actions>
        </mat-card>
      </div>
    </stepper-step>

    <stepper-step id="comeBackLater">
      <div class="no-card">
        <img src="assets/party.svg" width="50%"/>
        <p>
          {{ 'incident.comeBackLater' | translate }}
        </p>
        <button mat-button
                (click)="stepper.switch('personalData')"
                color="primary">
          {{ 'word.ok' | translate }}
        </button>
      </div>
    </stepper-step>

    <stepper-step id="scope">
      <div class="scrollable">
        <mat-card>
          <img mat-card-image src="assets/incident/scope.png" width="100%"/>
          <p>
            {{ 'incident.scope' | translate }}
          </p>
          <div class="buttons">
            <a mat-button color="primary" target="_blank"
               href="assets/documents/{{ 'dsar.ende' | translate}}/incident/{{ 'incident.filenames.scope' | translate}}.docx">
              {{ 'incident.downloadTemplate.scope' | translate }}
            </a>
          </div>
          <mat-card-actions align="end">
            <button mat-button
                    (click)="stepper.switch('personalData')"
                    color="accent">
              {{ 'dsar.back' | translate }}
            </button>
            <button mat-flat-button
                    (click)="stepper.switch('affectedPeople')"
                    color="primary">
              {{ 'word.done' | translate }}
            </button>
          </mat-card-actions>
        </mat-card>
      </div>
    </stepper-step>

    <stepper-step id="affectedPeople">
      <div class="scrollable">
        <mat-card>
          <img mat-card-image src="assets/incident/affected_people.png" width="100%"/>
          <p>
            {{ 'incident.affectedPeople' | translate }}
          </p>
          <div class="buttons">
            <a mat-button color="primary" target="_blank"
               href="assets/documents/{{ 'dsar.ende' | translate}}/incident/{{ 'incident.filenames.affectedPeople' | translate}}.docx">
              {{ 'incident.downloadTemplate.affectedPeople' | translate }}
            </a>
          </div>
          <mat-card-actions align="end">
            <button mat-button
                    (click)="stepper.switch('scope')"
                    color="accent">
              {{ 'dsar.back' | translate }}
            </button>
            <button mat-flat-button
                    (click)="stepper.switch('highRisk')"
                    color="primary">
              {{ 'word.done' | translate }}
            </button>
          </mat-card-actions>
        </mat-card>
      </div>
    </stepper-step>

    <!--<stepper-step id="approval">-->
      <!--<div class="scrollable">-->
      <!--<mat-card>-->
        <!--<img mat-card-image src="assets/incident/approval.png" width="100%"/>-->
        <!--<p>-->
          <!--{{ 'incident.approval' | translate }}-->
        <!--</p>-->
        <!--<mat-card-actions align="end">-->
          <!--<button mat-button-->
                  <!--(click)="stepper.switch('affectedPeople')"-->
                  <!--color="accent">-->
            <!--{{ 'dsar.back' | translate }}-->
          <!--</button>-->
          <!--<button mat-flat-button-->
                  <!--(click)="stepper.switch('highRisk')"-->
                  <!--color="primary">-->
            <!--{{ 'word.done' | translate }}-->
          <!--</button>-->
        <!--</mat-card-actions>-->
      <!--</mat-card>-->
      <!--</div>-->
    <!--</stepper-step>-->

    <stepper-step id="highRisk">
      <div class="scrollable">
      <mat-card>
        <img mat-card-image src="assets/incident/high-risk.png" width="100%"/>
        <p>
          {{ 'incident.highRisk' | translate }}
        </p>
        <mat-card-actions align="end">
          <button mat-button
                  (click)="stepper.switch('affectedPeople')"
                  color="accent">
            {{ 'dsar.back' | translate }}
          </button>

          <button mat-button
                  (click)="stepper.switch('informPeople')"
                  color="warn">
            {{ 'yesno.no' | translate }}
          </button>
          <button mat-button
                  (click)="stepper.switch('informAll')"
                  color="primary">
            {{ 'yesno.yes' | translate }}
          </button>
        </mat-card-actions>
      </mat-card>
      </div>
    </stepper-step>

    <stepper-step id="informAll">
      <div class="scrollable">
        <mat-card>
          <img mat-card-image src="assets/incident/inform_all.png" width="100%"/>
          <p>
            {{ 'incident.informAll' | translate }}
          </p>
          <div class="buttons">
            <a mat-button color="primary" target="_blank"
               href="assets/documents/{{ 'dsar.ende' | translate}}/incident/{{ 'incident.filenames.authoritiesList' | translate}}.docx">
              {{ 'incident.downloadTemplate.authoritiesList' | translate }}
            </a>
            <br/>
            <a mat-button color="primary" target="_blank"
               href="assets/documents/{{ 'dsar.ende' | translate}}/incident/{{ 'incident.filenames.informAuthorities' | translate}}.docx">
              {{ 'incident.downloadTemplate.informAuthorities' | translate }}
            </a>
            <br/>
            <a mat-button color="primary" target="_blank"
               href="assets/documents/{{ 'dsar.ende' | translate}}/incident/{{ 'incident.filenames.informPeople' | translate}}.docx">
              {{ 'incident.downloadTemplate.informPeople' | translate }}
            </a>
          </div>
          <mat-card-actions align="end">
            <button mat-button
                    (click)="stepper.switch('highRisk')"
                    color="accent">
              {{ 'dsar.back' | translate }}
            </button>
          </mat-card-actions>
        </mat-card>
      </div>
    </stepper-step>

    <stepper-step id="informPeople">
      <div class="scrollable">
      <mat-card>
        <img mat-card-image src="assets/incident/inform_people.png" width="100%"/>
        <p>
          {{ 'incident.informPeople' | translate }}
        </p>
        <div class="buttons">
          <a mat-button color="primary" target="_blank"
             href="assets/documents/{{ 'dsar.ende' | translate}}/incident/{{ 'incident.filenames.informPeople' | translate}}.docx">
            {{ 'incident.downloadTemplate.informPeople' | translate }}
          </a>
        </div>
        <mat-card-actions align="end">
          <button mat-button
                  (click)="stepper.switch('highRisk')"
                  color="accent">
            {{ 'dsar.back' | translate }}
          </button>
        </mat-card-actions>
      </mat-card>
      </div>
    </stepper-step>
  </stepper>
</div>

export interface Event {
    id: number;
    eventName: string;
    category: string;
    label?: string;
    metadata?: any;
    timestampAt: Date;
    userId: number;
}

export const AvailableEvents = [
    { fullCategory: 'CONTROLLER.DEPARTMENT', category: 'department', fullName: 'CONTROLLER.DEPARTMENT.ADD' },
    { fullCategory: 'CONTROLLER.DEPARTMENT', category: 'department', fullName: 'CONTROLLER.DEPARTMENT.UPDATE' },
    { fullCategory: 'CONTROLLER.DOCUMENT', category: 'document', fullName: 'CONTROLLER.DOCUMENT.UPLOADED', canLoadName: true },
    { fullCategory: 'CONTROLLER.DOCUMENT', category: 'document', fullName: 'CONTROLLER.DOCUMENT.REPLACE_FILE', canLoadName: true },
    { fullCategory: 'CONTROLLER.DOCUMENT', category: 'document', fullName: 'CONTROLLER.DOCUMENT.UPDATE_DETAILS', canLoadName: true },
    { fullCategory: 'CONTROLLER.DOCUMENT', category: 'document', fullName: 'CONTROLLER.DOCUMENT.DELETE' },
    { fullCategory: 'CONTROLLER.USERS', category: 'users', fullName: 'CONTROLLER.USERS.CONFIRMED_INVITE' },
    { fullCategory: 'CONTROLLER.USERS', category: 'users', fullName: 'CONTROLLER.USERS.RESENT_INVITE' },
    { fullCategory: 'CONTROLLER.USERS', category: 'users', fullName: 'CONTROLLER.USERS.INVITED_USER' },
    { fullCategory: 'CONTROLLER.RPA', category: 'rpa', fullName: 'CONTROLLER.RPA.ASSIGN', canLoadName: true },
    { fullCategory: 'CONTROLLER.RPA', category: 'rpa', fullName: 'CONTROLLER.RPA.UPDATE_STATUS', canLoadName: true },
    { fullCategory: 'CONTROLLER.RPA', category: 'rpa', fullName: 'CONTROLLER.RPA.UPDATE_FIELD', canLoadName: true },
    { fullCategory: 'CONTROLLER.RPA', category: 'rpa', fullName: 'CONTROLLER.RPA.CUSTOM.ADD', canLoadName: true },
    { fullCategory: 'CONTROLLER.RPA', category: 'rpa', fullName: 'CONTROLLER.RPA.CUSTOM.UPDATE', canLoadName: true },
    { fullCategory: 'CONTROLLER.RPA', category: 'rpa', fullName: 'CONTROLLER.RPA.IP.ADD', canLoadName: true },
    { fullCategory: 'CONTROLLER.RPA', category: 'rpa', fullName: 'CONTROLLER.RPA.IP.DELETE', canLoadName: true },
    { fullCategory: 'CONTROLLER.RPA', category: 'rpa', fullName: 'CONTROLLER.RPA.EP.ADD', canLoadName: true },
    { fullCategory: 'CONTROLLER.RPA', category: 'rpa', fullName: 'CONTROLLER.RPA.EP.DELETE', canLoadName: true },
    { fullCategory: 'CONTROLLER.VENDORS', category: 'vendors', fullName: 'CONTROLLER.VENDORS.ADD_DPA' },
    { fullCategory: 'CONTROLLER.VENDORS', category: 'vendors', fullName: 'CONTROLLER.VENDORS.REQUEST_DPA' },
    { fullCategory: 'CONTROLLER.VENDORS', category: 'vendors', fullName: 'CONTROLLER.VENDORS.DPA_TOGGLE_REQUIRED' },
    { fullCategory: 'CONTROLLER.TOMS', category: 'toms', fullName: 'CONTROLLER.TOMS.ENTRY.SAVE', canLoadName: true },
    { fullCategory: 'CONTROLLER.TOMS', category: 'toms', fullName: 'CONTROLLER.TOMS.ENTRY.APPLICABLE_UPDATE', canLoadName: true },
    { fullCategory: 'CONTROLLER.TOMS', category: 'toms', fullName: 'CONTROLLER.TOMS.ENTRY.EXPLANATION_UPDATE', canLoadName: true },
    { fullCategory: 'CONTROLLER.DSAR', category: 'dsar', fullName: 'CONTROLLER.DSAR.CREATE', canLoadName: true  },
    { fullCategory: 'CONTROLLER.DSAR', category: 'dsar', fullName: 'CONTROLLER.DSAR.UPDATE', canLoadName: true  },
    { fullCategory: 'CONTROLLER.DSAR', category: 'dsar', fullName: 'CONTROLLER.DSAR.DELETE' },
    { fullCategory: 'CONTROLLER.INCIDENTS', category: 'incidents', fullName: 'CONTROLLER.INCIDENTS.CREATE', canLoadName: true  },
    { fullCategory: 'CONTROLLER.INCIDENTS', category: 'incidents', fullName: 'CONTROLLER.INCIDENTS.UPDATE', canLoadName: true  },
    { fullCategory: 'CONTROLLER.INCIDENTS', category: 'incidents', fullName: 'CONTROLLER.INCIDENTS.DELETE' },
    { fullCategory: 'CONTROLLER.RECORDABLES', category: 'recordables', fullName: 'CONTROLLER.RECORDABLES.DOCUMENT.UPLOAD' },
    { fullCategory: 'CONTROLLER.COMMENTS', category: 'comments', fullName: 'CONTROLLER.COMMENTS.COMMENTED' },
    { fullCategory: 'SUPERVISION.TEMPLATE', category: 'template', fullName: 'SUPERVISION.TEMPLATE.APPLY' },
    { fullCategory: 'SUPERVISION.TEMPLATE', category: 'template', fullName: 'SUPERVISION.TEMPLATE.CREATE' },
    { fullCategory: 'SUPERVISION.CLIENT', category: 'client', fullName: 'SUPERVISION.CLIENT.ADD_CONTROLLER' },
  ];

export const EntityTypes = [
    { category: 'document', shortCode: 'DOC-' },
    { category: 'rpa', shortCode: 'PA-' },
    { category: 'dsar', shortCode: 'DSAR-' },
    { category: 'incidents', shortCode: 'IN-' }
];

export interface FilterRequest {
    category: string;
    action: string;
    item: string;
}

export function getEventShortCategory(event: Event) {
    const splitted = event.category.split('.');

    if (splitted.length > 1) {
      return splitted[1].toLowerCase();
    } else {
      return splitted[0].toLowerCase();
    }
}

export function getEntityIdentifier(event: Event) {
    const identifiers = EntityTypes.find((e) => e.category === getEventShortCategory(event));
    if (identifiers) {
      let itemId = 0;

      if (event.metadata.format && event.metadata.format === 'v2') {
        itemId = event.metadata.itemId;
      } else {
        // provide a fallback for existing metadata
        for (let field in event.metadata) {
          if (field.toLowerCase().includes('id')) {
            const value = event.metadata[field]; 

            if (value) {

              // sadly, we have this weird case where the id of incidents and dsars somehow is still inside another object, so we need to unwrap it
              if (typeof value === 'object') {
                itemId = value.id;
              } else {
                itemId = value;
              }
            }
            break; // continue if id has been found
          }
        }
      }

      if (itemId) {
        if (event.metadata.format === 'v2') {
          return event.metadata.prefix + itemId;  
        } else {
          return identifiers.shortCode + itemId;
        }
      }
    }

    return null;
}

/**
 * This checks if the keyword maches the entry name (like VT-11) but is a bit more forgiving than just straight up matching the strings.
 * @param entry 
 * @param keyword 
 */
export function matchName(entry: Event, keyword: string) {
  const cleanFilter = keyword.trim().toLowerCase().replace('-', ' ');

  const identifier = getEntityIdentifier(entry);

  const result = identifier ? identifier.replace('-', ' ').toLowerCase().indexOf(cleanFilter) !== -1 : false;

  return result;
}
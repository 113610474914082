<mat-dialog-content class="with-image">
  <img mat-card-image src="assets/organisation.svg"/>
  <div commentable="false"
    comment-subject-codename="dep-{{department.id}}-name"
    comment-subject-title="{{'governance.departmentName.commentSubject.part1' | translate}} {{department.name}} {{'governance.departmentName.commentSubject.part2' | translate}}">
    <mat-form-field>
      <input matInput
        class="department-name"
        placeholder="{{ 'governance.departmentName' | translate }}"
        [formControl]="name"
        [(ngModel)]="department.name" autofocus/>
        <mat-error *ngIf="name.invalid">
          {{'governance.departmentName.hint' | translate }}
        </mat-error>
    </mat-form-field>
  </div>

  <div class="section">
    <h1 class="title">{{ 'governance.departmentHead' | translate }}</h1>

    <div
      commentable="false"
      comment-subject-codename="dep-{{department.id}}-headname"
      comment-subject-title="{{'governance.departmentHeadName.commentSubject.part1' | translate}} {{department.name}} {{'governance.departmentHeadName.commentSubject.part2' | translate}}">
      <mat-form-field>
        <input matInput
          placeholder="{{ 'word.firstName' | translate }}"
          (change)="checkAndAutofillDpc()"
          [(ngModel)]="department.headFirstName"
        />
      </mat-form-field>

      <mat-form-field>
        <input matInput
          placeholder="{{ 'word.lastName' | translate }}"
          (change)="checkAndAutofillDpc()"
          [(ngModel)]="department.headLastName"
        />
      </mat-form-field>
    </div>

    <div commentable="false"
      comment-subject-codename="dep-{{department.id}}-heademail"
      comment-subject-title="{{'governance.departmentHeadEmail.commentSubject.part1' | translate}} {{department.name}} {{'governance.departmentHeadEmail.commentSubject.part2' | translate}}">
      <mat-form-field>
        <input matInput
          placeholder="{{ 'word.email' | translate }}"
          (change)="checkAndAutofillDpc()"
          [(ngModel)]="department.headEmail"
        />
      </mat-form-field>
    </div>
  </div>

  <div *ngIf!="department.dpCoordinatorLastName" class="section">
    <h1 class="title">{{ 'governance.departmentDpCoordinator' | translate }}</h1>

    <div commentable="false"
      comment-subject-codename="dep-{{department.id}}-dpcname"
      comment-subject-title="{{'governance.departmentDPCName.commentSubject.part1' | translate}} {{department.name}} {{'governance.departmentDPCName.commentSubject.part2' | translate}}">
      <mat-form-field>
        <input matInput
          placeholder="{{ 'word.firstName' | translate }}"
          (change)="updateHeadAndDpcSync()"
          [(ngModel)]="department.dpCoordinatorFirstName"
        />
      </mat-form-field>

      <mat-form-field>
        <input matInput
          placeholder="{{ 'word.lastName' | translate }}"
          (change)="updateHeadAndDpcSync()"
          [(ngModel)]="department.dpCoordinatorLastName"
        />
      </mat-form-field>
    </div>

    <div commentable="false"
      comment-subject-codename="dep-{{department.id}}-dpcemail"
      comment-subject-title="{{'governance.departmentDPCEmail.commentSubject.part1' | translate}} {{department.name}} {{'governance.departmentDPCEmail.commentSubject.part2' | translate}}">
      <mat-form-field>
        <input matInput
          placeholder="{{ 'word.email' | translate }}"
          (change)="updateHeadAndDpcSync()"
          [(ngModel)]="department.dpCoordinatorEmail"
        />
      </mat-form-field>
    </div>
  </div>

</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button color="warn" (click)="delete()" *ngIf="this.department.id">
    {{ 'word.delete' | translate }}
  </button>
  <button mat-raised-button color="primary" (click)="save()">
    {{ 'word.save' | translate }}
  </button>
</mat-dialog-actions>

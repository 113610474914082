<div class="container">
  <div class="inside">
    <ng-container  *ngIf="loading">
      <mat-spinner></mat-spinner>
      <br><br>
      <p>
        {{'email-unsubscribe.unsubscribing' | translate}}
      </p>
    </ng-container>
    <p *ngIf="!loading">
      {{'email-unsubscribe.unsubscribed' | translate}}
    </p>
  </div>
</div>

import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { BusService } from '../bus.service';
import {
  IPostOptions,
  RequestService
} from '../request.service';
import { EventsService } from '../events.service';
import { PAStatus } from './rpa.service';

interface IPostPAStatusUpdateRequest {
  id: number;
  status: PAStatus;
}

interface IPostPAStatusUpdateResponse {
  success: boolean;
}

@Injectable()
export class RpaUpdateStatusService {
  private options: IPostOptions<
                    IPostPAStatusUpdateRequest,
                    IPostPAStatusUpdateResponse>;

  constructor(
    private bus: BusService,
    private events: EventsService,
    private requestService: RequestService
  ) {
    this.options = {
      uri: '/pa/update-status/',
      body: {
        id: -1,
        status: null,
      },
      handlers: {
        success: this.success.bind(this),
        error: this.error.bind(this)
      }
    };

    this.subscribe();
  }

  public success(result: IPostPAStatusUpdateResponse): void {
    this.bus.publish(this.events.received.data.rpa.updateStatus.success, result);
  }

  public error(error: HttpErrorResponse): void {
    this.bus.publish(this.events.received.data.rpa.updateStatus.failure, error);
  }

  public request(req: IPostPAStatusUpdateRequest) {
    this.bus.publish(this.events.notified.saving.starting);
    this.options.body = req;
    return this.requestService.post<
            IPostPAStatusUpdateRequest,
            IPostPAStatusUpdateResponse>(this.options);
  }

  private subscribe() {
    this.bus.subscribe(this.events.requested.data.rpa.updateStatus, this.request.bind(this));
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'display-language',
  templateUrl: './display-language.component.html',
  styleUrls: ['./display-language.component.scss']
})
export class DisplayLanguageComponent implements OnInit {

  @Input() language = '';
  @Input() exclude = '';

  currentLanguage = '';

  constructor(
    private translate: TranslateService
  ) {
    this.currentLanguage = this.translate.currentLang || this.translate.defaultLang;
   }

  ngOnInit(): void {
  }

  get show() {
    const parsedInclude = this.language.split(',');
    const parsedExclude = this.exclude.split(',');

    if (!parsedInclude.includes(this.currentLanguage) && this.language.length > 0) {
      return false;
    }

    if (parsedExclude.includes(this.currentLanguage)) {
      return false;
    }

    return true;
  }

}

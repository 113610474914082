 
<mat-dialog-content>
    <div class="content" [innerHtml]="content | translate">
    </div>
</mat-dialog-content>
  
<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>
        {{'word.close' | translate}}
    </button>
</mat-dialog-actions>
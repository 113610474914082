import { Component, OnInit, OnDestroy, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';

import { BusService } from '../../services/bus.service';
import { EventsService } from '../../services/events.service';
import { AppConfig } from '../../app.config';
import {HttpClient} from '@angular/common/http';
import { AuthenticationService } from '../../services/authentication.service';
import { environment } from '../../../environments/environment';
import { DsarResourceService } from 'app/services/dsar/dsar-resource.service';
import { IncidentsResourceService } from 'app/services/incidents/incidents-resource.service';
import { TodoService } from '../../services/todos/todo.service';
import { Todo } from '../todos/todo.interface';
import { getToken } from '../../util/token';
import { DashboardService } from '../../services/dashboard.service';


@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss']
})
export class SideNavComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild('controllerLogo') private controllerLogo: ElementRef;

  // eslint-disable-next-line @typescript-eslint/naming-convention
  readonly ENV = environment;

  openDSARs = 0;
  openIncidents = 0;
  openTodos = false;

  private _expanded = false;
  private _restExpanded = false;

  constructor(
    private router: Router,
    private bus: BusService,
    private events: EventsService,
    private http: HttpClient,
    private authService: AuthenticationService,
    private dsar: DsarResourceService,
    private incidents: IncidentsResourceService,
    private todoService: TodoService,
    private dashboardService: DashboardService
  ) { }

  public get route() {
    return this.router.url;
  }

  get loggedIn() {
    return getToken();
  }

  get isSupervisor() {
    return this.authService.productType === 'supervisor';
  }

  get logoLink() {
    switch (this.authService.productType) {
      case 'default':
        return 'todolist';
      case 'supervisor':
        return 'supervision/clients';
    }
  }

  public get expanded() {
    return this._expanded;
  }

  public get shouldNotShow() {
    return this.dashboardService.shouldOnlyShowDashboard;
  }

  public expand() {
    if (!this._restExpanded) {
      this._restExpanded = true;
      this.tempExpand();
    }
  }

  public collapse() {
    if (this._restExpanded) {
      this._restExpanded = false;
      this.tempCollapse();
    }
  }

  public tempExpand() {
    if (!this._expanded) {
      this._expanded = true;
      this.bus.publish(this.events.ui.occured.sidenav.expand);
    }
  }

  public tempCollapse() {
    if (this._expanded) {
      this._expanded = false || this._restExpanded;
      if (!this._expanded) {
        this.bus.publish(this.events.ui.occured.sidenav.collapse);
      }
    }
  }

  updateOpenDSARsAndIncidents() {
    // only check/update when user is authenticated
    if (this.authService.token) {
      this.dsar.getOpen().subscribe(c => this.openDSARs = c);
      this.incidents.getOpen().subscribe(c => this.openIncidents = c);
    }
  }

  /**
   * Checks for any overdue todo and shows a notification on the side-nav if there are any
   */
  updateOpenTodos() {
    if (this.authService.token) {
      this.todoService.fetchTodos({ done: false }).subscribe((res: Todo[]) => {
        this.openTodos = res.length > 0;
      });
    }
  }

  ngOnInit() {
    this.bus.subscribe(this.events.ui.requested.sidenav.expand, this.expand, this);
    this.bus.subscribe(this.events.ui.requested.sidenav.collapse, this.collapse, this);
    this.bus.subscribe(this.events.notified.user.logoUpdate, this.updateControllerLogo, this);
    this.bus.subscribe(this.events.notified.user.logout, this.updateControllerLogo, this);
    this.bus.subscribe(this.events.received.authentication.success, this.updateControllerLogo, this);
    this.bus.subscribe(this.events.notified.supervision.client.changed, this.updateControllerLogo, this);

    this.updateOpenDSARsAndIncidents();
    this.updateOpenTodos();
    this.bus.subscribe(this.events.received.authentication.success, this.updateOpenDSARsAndIncidents, this);
    this.bus.subscribe(this.events.notified.supervision.client.changed, this.updateOpenDSARsAndIncidents, this);
    this.bus.subscribe(this.events.notified.dsar.status.updated, this.updateOpenDSARsAndIncidents, this);
    this.bus.subscribe(this.events.notified.incident.status.updated, this.updateOpenDSARsAndIncidents, this);
    this.bus.subscribe('todo.update.event', this.updateOpenTodos, this);
  }

  ngOnDestroy() {
    this.bus.unsubscribe(this.events.ui.requested.sidenav.expand, this.expand);
    this.bus.unsubscribe(this.events.ui.requested.sidenav.collapse, this.collapse);
    this.bus.unsubscribe(this.events.notified.user.logoUpdate, this.updateControllerLogo);
    this.bus.unsubscribe(this.events.notified.user.logout, this.updateControllerLogo);
    this.bus.unsubscribe(this.events.received.authentication.success, this.updateControllerLogo);
    this.bus.unsubscribe(this.events.notified.supervision.client.changed, this.updateControllerLogo);

    this.bus.unsubscribe(this.events.received.authentication.success, this.updateOpenDSARsAndIncidents);
    this.bus.unsubscribe(this.events.notified.supervision.client.changed, this.updateOpenDSARsAndIncidents);
    this.bus.unsubscribe(this.events.notified.dsar.status.updated, this.updateOpenDSARsAndIncidents);
    this.bus.unsubscribe(this.events.notified.incident.status.updated, this.updateOpenDSARsAndIncidents);
    this.bus.subscribe('todo.update.event', this.updateOpenTodos);
  }

  ngAfterViewInit() {
    this.updateControllerLogo();
  }

  updateControllerLogo() {
    // this function might be called by angular when the status changed, but the view is not yet rendered. So wait for the view to finish.
    if (!this.loggedIn) {
      return;
    }
    if (this.loggedIn && !this.controllerLogo) {
      setTimeout(() => this.updateControllerLogo(), 10);
      return;
    }

    if (getToken()) {
      if (this.isSupervisor) {
        this.controllerLogo.nativeElement.src = `${AppConfig.apiUrl}/supervision/supervisor/profile/logo/` +
        `?token=${getToken()}&rand=${Math.floor(Math.random() * 1000)}`;
      } else {
        this.controllerLogo.nativeElement.src =
        `${AppConfig.apiUrl}/controllers/logo/` +
        `?token=${getToken()}` +
        `&${new Date().getTime()}`;
      }
    } else {
      this.controllerLogo.nativeElement.src = '#';
    }
  }
}

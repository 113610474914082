<!-- The i18n script scans source files for translatable strings and collects them in the .json files. But some strings
are NOT part of the source files and only exist in the database by default. Note here any strings that you STILL want to
be correctly recognized for translation:
{{'events.empty' | translate }}
{{'events.loadMore' | translate}}
{{'events.category.comments' | translate}}
{{'events.category.rpa' | translate}}
{{'events.category.document' | translate}}
{{'events.category.toms' | translate}}
{{'events.category.vendors' | translate}}
{{'events.category.data' | translate}}
{{'events.category.dpo' | translate}}
{{'events.category.dsar' | translate}}
{{'events.category.incidents' | translate}}
{{'events.category.payment' | translate}}
{{'events.category.department' | translate}}
{{'events.category.users' | translate}}
{{'events.category.steps' | translate}}
{{'events.category.system' | translate}}
{{'events.category.milestone' | translate}}
{{'events.category.template' | translate}}
{{ 'events.category.client' | translate}}
{{ 'events.category.client.short' | translate}}
{{ 'events.category.audit.short' | translate}}
{{ 'events.category.dpia.short' | translate}}
{{ 'events.category.audit' | translate}}
{{ 'events.identifier.controller.audit.update_field' | translate}}
{{ 'events.identifier.controller.audit.create' | translate}}
{{ 'events.identifier.controller.dpia.update' | translate}}
{{'events.identifier.controller.comments.commented' | translate}}
{{'events.identifier.controller.users.invited_user' | translate}}
{{'events.identifier.controller.users.deleted_invite' | translate}}
{{'events.identifier.controller.users.resent_invite' | translate}}
{{'events.identifier.controller.users.confirmed_invite' | translate}}
{{'events.identifier.controller.users.changed_password' | translate}}
{{'events.identifier.controller.data.set_goals' | translate}}
{{'events.identifier.controller.data.set_logo' | translate}}
{{'events.identifier.controller.data.set_name' | translate}}
{{'events.identifier.controller.data.update' | translate}}
{{'events.identifier.controller.data.basic_info.complete' | translate}}
{{'events.identifier.controller.department.add' | translate}}
{{'events.identifier.controller.department.update' | translate}}
{{'events.identifier.controller.department.delete' | translate}}
{{'events.identifier.controller.document.uploaded' | translate}}
{{'events.identifier.controller.document.replace_file' | translate}}
{{'events.identifier.controller.document.update_details' | translate}}
{{'events.identifier.controller.document.delete' | translate}}
{{'events.identifier.controller.document.shared' | translate}}
{{'events.identifier.controller.dpo.set_appointment' | translate}}
{{'events.identifier.controller.dsar.update' | translate}}
{{'events.identifier.controller.dsar.create' | translate}}
{{'events.identifier.controller.dsar.documents.upload' | translate}}
{{'events.identifier.controller.dsar.document.upload' | translate}}
{{'events.identifier.controller.dsar.delete' | translate}}
{{'events.identifier.controller.dpo.update' | translate}}
{{'events.identifier.controller.incidents.update' | translate}}
{{'events.identifier.controller.incidents.create' | translate}}
{{'events.identifier.controller.incidents.documents.upload' | translate}}
{{'events.identifier.controller.incidents.document.upload' | translate}}
{{'events.identifier.controller.incidents.delete' | translate}}
{{'events.identifier.controller.payment.subscription_update' | translate}}
{{'events.identifier.controller.payment.subscription_create' | translate}}
{{'events.identifier.controller.payment.update_card' | translate}}
{{'events.identifier.controller.rpa.assign' | translate}}
{{'events.identifier.controller.rpa.update_field' | translate}}
{{'events.identifier.controller.rpa.custom.add' | translate}}
{{'events.identifier.controller.rpa.custom.update' | translate}}
{{'events.identifier.controller.rpa.custom.delete' | translate}}
{{'events.identifier.controller.rpa.ep.add' | translate}}
{{'events.identifier.controller.rpa.ep.delete' | translate}}
{{'events.identifier.controller.rpa.ip.add' | translate}}
{{'events.identifier.controller.rpa.ip.delete' | translate}}
{{'events.identifier.controller.rpa.update_status' | translate}}
{{'events.identifier.controller.toms.entry.save' | translate}}
{{'events.identifier.controller.toms.entry.applicable_update' | translate}}
{{'events.identifier.controller.toms.entry.explanation_update' | translate}}
{{'events.identifier.controller.vendors.add_dpa' | translate}}
{{'events.identifier.controller.vendors.request_dpa' | translate}}
{{'events.identifier.controller.vendors.dpa_toggle_required' | translate}}
{{'events.identifier.controller.steps.pa_tutorial.complete' | translate}}
{{'events.identifier.controller.steps.gdpr_applies.complete' | translate}}
{{'events.identifier.controller.steps.intro.complete' | translate}}
{{'events.identifier.controller.milestone.i.complete' | translate}}
{{'events.identifier.controller.milestone.ii.complete' | translate}}
{{'events.identifier.supervision.client.invitation_accepted' | translate}}
{{'events.identifier.supervision.client.add_invite' | translate}}
{{'events.identifier.supervision.client.add_controller' | translate}}
{{'events.identifier.supervision.client.resend_invitation' | translate}}
{{'events.identifier.supervision.client.delete_supervision' | translate}}
{{'events.identifier.supervision.template.create' | translate}}
{{'events.identifier.supervision.template.apply' | translate}}
{{'events.identifier.system.tracking_start' | translate}}
-->



<div class="contents">
  <h1 class="title">{{ 'events.title' | translate }}</h1>

  <div class="action-items">
    <mat-table #table [dataSource]="dataSource" matSort multiTemplateDataRows>
      <ng-container matColumnDef="timestampAt">
        <mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'events.table.timestamp' | translate }}
        </mat-header-cell>
        <mat-cell *matCellDef="let event"> {{ event.timestampAt | localizedDate : 'medium' }} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="category">
        <mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'events.table.category' | translate }} </mat-header-cell>
        <mat-cell *matCellDef="let event"> {{ 'events.category.' + getCategory(event) | translate }} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="eventName">
        <mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'events.table.eventName' | translate }}
        </mat-header-cell>
        <mat-cell *matCellDef="let event"> {{ 'events.identifier.' + event.category.toLowerCase() + '.' +
            event.eventName.toLowerCase() | translate }} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="user">
        <mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'events.table.user' | translate }} </mat-header-cell>
        <mat-cell *matCellDef="let event"> {{ getUser(event) }} </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="columnsToDisplay"></mat-header-row>
      <mat-row *matRowDef="let row; columns: columnsToDisplay" matRipple class="element-row"
        [cdkDetailRow]="row" [cdkDetailRowTpl]="metadataTemplate"></mat-row>
    </mat-table>

    <mat-paginator #paginator [pageSize]="20" [pageSizeOptions]="[10, 20, 50]" [showFirstLastButtons]="true">
    </mat-paginator>
  </div>
</div>

<ng-template #metadataTemplate let-element>
  <div class="mat-row detail-row" [@detailExpand] style="overflow: hidden">

		<app-actions-metadata-users *ngIf="hasMetadata('users', element)" [event]="element"></app-actions-metadata-users>
		<app-actions-metadata-department *ngIf="hasMetadata('department', element)" [event]="element"></app-actions-metadata-department>
    <app-actions-metadata-document *ngIf="hasMetadata('document', element)" [event]="element"></app-actions-metadata-document>
    <app-actions-metadata-rpa *ngIf="hasMetadata('rpa', element)" [event]="element"></app-actions-metadata-rpa>
    <app-actions-metadata-rpa-processors *ngIf="hasMetadata('rpa-processors', element)" [event]="element"></app-actions-metadata-rpa-processors>
    <app-actions-metadata-vendors *ngIf="hasMetadata('vendors', element)" [event]="element"></app-actions-metadata-vendors>
    <app-actions-metadata-toms *ngIf="hasMetadata('toms', element)" [event]="element"></app-actions-metadata-toms>

    <div *ngIf="!hasMetadata(undefined, element)" class="no-metadata metadata-center">
      <mat-icon>layers_clear</mat-icon>
      <span>{{ 'events.metadata.empty' | translate }}</span>
    </div>
	</div>
</ng-template>

<mat-dialog-content #dialogContent class="vendors-add-dialog-contents">
    <form [formGroup]="formGroup" novalidate>
        <span mat-dialog-title>
            {{'pa.applications.addDialog.sections.name.title' | translate}}
        </span>

        <mat-form-field>
            <input #nameField matInput type="text"
                  name="name"
                  formControlName="name"
                  required
                  placeholder="{{'pa.applications.addDialog.fields.name.label' | translate}}" autofocus/>
            <mat-hint>
              {{'pa.applications.addDialog.fields.name.hint' | translate}}
            </mat-hint>
            <mat-error *ngIf="formGroup.get('name').hasError('minlength') && !formGroup.get('name').hasError('required')">
              {{'pa.applications.addDialog.fields.name.tooShort' | translate}}
            </mat-error>
            <mat-error *ngIf="formGroup.get('name').hasError('required')">
              {{'pa.applications.addDialog.fields.name.required' | translate}}
            </mat-error>
        </mat-form-field>

        <input type="hidden" formControlName="vendorId" />

        <div class="select-vendor">
            <button mat-button color="primary" (click)="selectVendor()">
                {{'pa.applications.addDialog.select-vendor' | translate}}
            </button>
        </div>

        <div *ngIf="selectedVendor" class="vendor">
            <div class="avatar">
                <img *ngIf="selectedVendor.logoUrl" [src]="selectedVendor.logoUrl" onerror="this.onerror=null;this.src='/assets/default_vendor.svg';"/>
                <mat-icon *ngIf="!selectedVendor.logoUrl" class="no-logo">web_asset</mat-icon>
              </div>
        
              <div class="info">
                <div class="title">
                  {{selectedVendor.name}}
                </div>
                <div class="address">
                  {{selectedVendor.addressCity}}, {{selectedVendor.addressCountry}}
                </div>
                <div class="email">
                  {{selectedVendor.email}}
                </div>
            </div>
        </div>
        <div *ngIf="!selectedVendor" class="no-vendor">
            <p>{{ 'pa.applications.addDialog.no-vendor' | translate }}</p>
        </div>
    </form>
</mat-dialog-content>


<mat-dialog-actions align="end">
    <button type="button" mat-button [disabled]="!formGroup.valid" [color]="(formGroup.dirty && !formGroup.valid)?'warn':'primary'" (click)="save()">
      {{'pa.applications.addDialog.save' | translate}}
    </button>
</mat-dialog-actions>  
import { Component, OnInit } from '@angular/core';

import { WorkflowTasksService } from '../../../services/workflow-tasks/workflow-tasks.service';
import { RpasOnBehalfService } from '../../../services/rpas-on-behalf/rpas-on-behalf.service';


@Component({
  selector: 'app-is-processor',
  templateUrl: './is-processor.component.html',
  styleUrls: ['./is-processor.component.scss']
})
export class IsProcessorComponent implements OnInit {

  constructor(
    private tasks: WorkflowTasksService,
    private rpaob: RpasOnBehalfService,
  ) { }

  ngOnInit() {
  }

  progress(progress) {
    this.tasks.progressControllerTask({
      progress: progress,
      workflowCodename: 'path_to_gdpr',
      taskCodename: 'ptg_is_processor',
    });
  }

  finishYes() {
    this.rpaob.addWorkflow();
    this.progress(1);
  }

  finishNo() {
    this.rpaob.deleteWorkflow();
    this.progress(1);
  }
}

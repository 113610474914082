import { Component, OnInit } from '@angular/core';
import { BusService } from '../../../../services/bus.service';
import { EventsService } from '../../../../services/events.service';

@Component({
  selector: 'app-delete',
  templateUrl: './delete.component.html',
  styleUrls: ['./delete.component.scss']
})
export class DeleteDsarComponent implements OnInit {

  deleteDocuments = true;

  constructor(
    private bus: BusService,
    private events: EventsService,
  ) { }

  ngOnInit(): void {
  }

  confirm() {
    // console.log(this.keepDocuments);
    this.bus.publish('delete-dsar', this.deleteDocuments);
    // this.bus.publish(this.events.requested.action.dsar.delete, 'id');
  }

}

import {Injectable} from '@angular/core';
import { map } from 'rxjs/operators';
import { RequestService } from '../request.service';

export interface TreeNode {
  id?: number;
  title: string;
  description: string;
  parent?: number;
  children?: TreeNode[];
  pas?: any[];
}

@Injectable()
export class RpaReferenceService {
  private api = {
    root: '/pa/reference',
    isReference: '/is-reference',
    tree: {
      root: '/tree',
      assign: '/assign',
    },
  };

  constructor(
    private requestService: RequestService
  ) {
  }

  public getReferencePAs() {
    return this.requestService.get<any>({
      uri: this.api.root,
      handlers: {
        success: () => {},
        error: () => {},
      }
    });
  }

  public isReferenceController() {
    return this.requestService.get<{ is: boolean }>({
      uri: this.api.root + this.api.isReference,
      handlers: {
        success: () => {},
        error: () => {},
      }
    }).pipe(map(r => r.is));
  }

  public getTree() {
    return this.requestService.get<TreeNode[]>({
      uri: this.api.root + this.api.tree.root,
      handlers: {
        success: () => {},
        error: () => {},
      }
    });
  }

  public addTreeNode(node: TreeNode) {
    return this.requestService.post<TreeNode, TreeNode>({
      uri: this.api.root + this.api.tree.root,
      body: node,
      handlers: {
        success: () => {},
        error: () => {},
      }
    });
  }

  public updateTreeNode(node: TreeNode) {
    return this.requestService.put<TreeNode, void>({
      uri: this.api.root + this.api.tree.root + '/' + node.id,
      body: node,
      handlers: {
        success: () => {},
        error: () => {}
      }
    });
  }

  public deleteTreeNode(node: TreeNode) {
    return this.requestService.delete({
      uri: this.api.root + this.api.tree.root + '/' + node.id,
      handlers: {
        success: () => {},
        error: () => {},
      }
    });
  }

  public assignPaToTreeNode(paId, node?: TreeNode) {
    return this.requestService.post({
      uri: this.api.root + this.api.tree.root + this.api.tree.assign,
      body: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        node_id: node?.id,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        pa_id: paId,
      },
      handlers: {
        success: () => {},
        error: () => {}
      }
    });
  }


  // DFS search for a particular node
  findNode(id: number, tree: TreeNode[]) {
    let candidate = tree.find(node => node.id === id);
    if (candidate) {
      return candidate;
    } else {
      for (const node of tree) {
        if (node.children) {
          candidate = this.findNode(id, node.children);
          if (candidate) {
            return candidate;
          }
        }
      }

      return undefined;
    }
  }
}

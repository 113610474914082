<div *ngIf="record" class="content incidents-record-details">
  <a routerLink="/incidents/records" class="back-link">
    <mat-icon>
      arrow_back
    </mat-icon>
    {{ 'incidents.record.back' | translate }}
  </a>
  <h1 *ngIf="record && record.id" class="title">{{ 'records.incidents.details.title' | translate : { id: ref, title: record.title, date: ((record.customFields.incidentTime || record.customFields.incidentTimeStart) | localizedDate) || '--' } }}</h1>
  <p *ngIf="record" [innerHtml]="'records.incidents.details.hint' | translate"></p>

  <br>

  <div class="actions">
    <div class="downloads">
      <!-- {{ 'documents.generated.incidents.agency' | translate }} -->
      <button mat-stroked-button color="primary" matTooltip="{{ 'records.incidents.export.tooltip' | translate }}" (click)="downloadWord('documents.generated.incidents.agency')">
        <mat-icon color="primary">cloud_download</mat-icon>
        {{ 'records.incidents.export.agency' | translate }}
      </button>
      <!-- {{ 'documents.generated.incidents.affectedPeople' | translate }}-->
      <button mat-stroked-button color="primary" matTooltip="{{ 'records.incidents.export.tooltip' | translate }}" (click)="downloadWord('documents.generated.incidents.affectedPeople')">
        <mat-icon color="primary">cloud_download</mat-icon>
        {{ 'records.incidents.export.affectedPeople' | translate }}
      </button>
      <!-- {{ 'documents.generated.incidents.internal' | translate }}-->
      <button mat-stroked-button color="primary" matTooltip="{{ 'records.incidents.export.tooltip' | translate }}" (click)="downloadWord('documents.generated.incidents.internal')">
        <mat-icon color="primary">cloud_download</mat-icon>
        {{ 'records.incidents.export.internal' | translate }}
      </button>
    </div>

    <button mat-icon-button color="warn" (click)="delete()" matTooltip="{{'word.delete' | translate}}">
      <mat-icon>delete</mat-icon>
    </button>
  </div>

  <br>

  <ng-container *ngIf="record">
    <div #details class="section section-lg incident prominent">
      <h2 class="section-title">{{'records.incidents.incident.title' | translate}}</h2>

      <h2>{{'records.incidents.overview.title' | translate}}</h2>
      <!-- title -->
      <div commentable
           comment-subject-codename="incidents-{{identifier}}-title"
           comment-subject-title="[{{ref}}] {{'records.incidents.add.title.placeholder' | translate}}">
        <todoable subject="incident-{{identifier}}-title"
                  suggested-title="[{{ref}}] {{'records.incidents.add.title.placeholder' | translate}}: "></todoable>
        <mat-form-field>
          <input type="text" matInput [(ngModel)]="record.title" [disabled]="readonly"
                 placeholder="{{'records.incidents.add.title.placeholder' | translate}}"
                 (change)="update()"/>
          <mat-hint>
            {{'records.incidents.add.title.placeholder.hint' | translate}}
          </mat-hint>
        </mat-form-field>
      </div>

      <!-- details -->
      <div commentable
           comment-subject-codename="incidents-{{identifier}}-details"
           comment-subject-title="[{{ref}}] {{'records.incidents.add.details.placeholder' | translate}}">
        <todoable subject="incident-{{identifier}}-details"
                  suggested-title="[{{ref}}] {{'records.incidents.add.details.placeholder' | translate}}: "></todoable>
        <mat-form-field>
          <textarea matInput [(ngModel)]="record.details"  [disabled]="readonly"
                    placeholder="{{'records.incidents.add.details.placeholder' | translate}}"
                    (change)="update()"></textarea>
          <mat-hint>
            {{'records.incidents.add.details.placeholder.hint' | translate}}
          </mat-hint>
        </mat-form-field>
      </div>

      <!-- status -->
      <div commentable
           comment-subject-codename="incidents-{{identifier}}-status"
           comment-subject-title="[{{ref}}] {{'records.incidents.add.details.placeholder' | translate}}">
        <todoable subject="incident-{{identifier}}-details"
                  suggested-title="[{{ref}}] {{'records.incidents.add.details.placeholder' | translate}}: "></todoable>
        <mat-form-field>
          <mat-select [(ngModel)]="record.status"
                      placeholder="{{'records.incidents.status.placeholder' | translate}}"
                      [disabled]="readonly"
                      (selectionChange)="update()">
            <mat-option value="recordable.open">{{'records.incidents.status.open' | translate}}</mat-option>
            <mat-option value="recordable.archived">{{'records.incidents.status.archived' | translate}}</mat-option>
          </mat-select>
          <mat-hint>
            {{'records.incidents.status.hint' | translate}}
          </mat-hint>
        </mat-form-field>
      </div>

      <br>
      <mat-divider></mat-divider>

      <h2>{{'records.incidents.reporter.title' | translate}}</h2>

      <!-- reporter name -->
      <div commentable
           comment-subject-codename="incidents-{{identifier}}-reporter-name"
           comment-subject-title="[{{ref}}] {{'records.incidents.reporter-name.placeholder' | translate}}">
        <todoable subject="incident-{{identifier}}-reporter-name"
                  suggested-title="[{{ref}}] {{'records.incidents.reporter-name.placeholder' | translate}}: "></todoable>
        <mat-form-field>
          <input type="text" matInput [(ngModel)]="record.customFields.reporterName"  [disabled]="readonly"
                 placeholder="{{'records.incidents.reporter-name.placeholder' | translate}}"
                 (change)="update()" />
          <mat-hint>
            {{'records.incidents.reporter-name.hint' | translate}}
          </mat-hint>
        </mat-form-field>
      </div>

      <!-- reporter phone number -->
      <div commentable
           comment-subject-codename="incidents-{{identifier}}-reporter-phone"
           comment-subject-title="[{{ref}}] {{'records.incidents.reporter-phone.placeholder' | translate}}">
        <todoable subject="incident-{{identifier}}-reporter-phone"
                  suggested-title="[{{ref}}] {{'records.incidents.reporter-phone.placeholder' | translate}}: "></todoable>
        <mat-form-field>
          <input type="text" matInput [(ngModel)]="record.customFields.reporterPhone"  [disabled]="readonly"
                 placeholder="{{'records.incidents.reporter-phone.placeholder' | translate}}"
                 (change)="update()"/>
          <mat-hint>
            {{'records.incidents.reporter-phone.hint' | translate}}
          </mat-hint>
        </mat-form-field>
      </div>

      <!-- reporter email -->
      <div commentable
           comment-subject-codename="incidents-{{identifier}}-reporter-email"
           comment-subject-title="[{{ref}}] {{'records.incidents.reporter-email.placeholder' | translate}}">
        <todoable subject="incident-{{identifier}}-reporter-email"
                  suggested-title="[{{ref}}] {{'records.incidents.reporter-email.placeholder' | translate}}: "></todoable>
        <mat-form-field>
          <input type="text" matInput [(ngModel)]="record.customFields.reporterEmail"  [disabled]="readonly"
                 placeholder="{{'records.incidents.reporter-email.placeholder' | translate}}"
                 (change)="update()" />
          <mat-hint>
            {{'records.incidents.reporter-email.hint' | translate}}
          </mat-hint>
        </mat-form-field>
      </div>

      <!-- reporter function -->
      <div commentable
           comment-subject-codename="incidents-{{identifier}}-reporter-function"
           comment-subject-title="[{{ref}}] {{'records.incidents.reporter-function.placeholder' | translate}}">
        <todoable subject="incident-{{identifier}}-reporter-function"
                  suggested-title="[{{ref}}] {{'records.incidents.reporter-function.placeholder' | translate}}: "></todoable>
        <mat-form-field>
          <input type="text" matInput [(ngModel)]="record.customFields.reporterFunction"  [disabled]="readonly"
                 placeholder="{{'records.incidents.reporter-function.placeholder' | translate}}"
                 (change)="update()"/>
          <mat-hint>
            {{'records.incidents.reporter-function.hint' | translate}}
          </mat-hint>
        </mat-form-field>
      </div>

      <br><br>
      <mat-divider></mat-divider>
      <br><br>

      <h2>{{'records.incidents.incident-details.title' | translate}}</h2>

      <!-- incident type -->
      <div commentable
           comment-subject-codename="incidents-{{identifier}}-incident-type"
           comment-subject-title="[{{ref}}] {{'records.incidents.incident-type.placeholder' | translate}}">
        <todoable subject="incident-{{identifier}}-incident-type"
                  suggested-title="[{{ref}}] {{'records.incidents.incident-type.placeholder' | translate}}: "></todoable>
        <mat-form-field>
          <mat-select [(ngModel)]="record.customFields.incidentType"
                      placeholder="{{'records.incidents.incident-type.placeholder' | translate}}"
                      [disabled]="readonly"
                      (selectionChange)="update()">
            <mat-option value="incident.types.sending-error">{{'records.incidents.types.sending-error' | translate}}</mat-option>
            <mat-option value="incident.types.disclosure">{{'records.incidents.types.disclosure' | translate}}</mat-option>
            <mat-option value="incident.types.theft">{{'records.incidents.types.theft' | translate}}</mat-option>
            <mat-option value="incident.types.burglary">{{'records.incidents.types.burglary' | translate}}</mat-option>
            <mat-option value="incident.types.wrong-disposal">{{'records.incidents.types.wrong-disposal' | translate}}</mat-option>
            <mat-option value="incident.types.loss">{{'records.incidents.types.loss' | translate}}</mat-option>
            <mat-option value="incident.types.lack-of-encryption">{{'records.incidents.types.lack-of-encryption' | translate}}</mat-option>
            <mat-option value="incident.types.hacking">{{'records.incidents.types.hacking' | translate}}</mat-option>
            <mat-option value="incident.types.skimming">{{'records.incidents.types.skimming' | translate}}</mat-option>
            <mat-option value="incident.types.software-fault">{{'records.incidents.types.software-fault' | translate}}</mat-option>
            <mat-option value="incident.types.other">{{'records.incidents.types.other' | translate}}</mat-option>
          </mat-select>
          <mat-hint>
            {{'records.incidents.incident-type.hint' | translate}}
          </mat-hint>
        </mat-form-field>

        <mat-form-field *ngIf="record.customFields.incidentType === 'incident.types.other'">
          <input matInput type="text" [(ngModel)]="record.customFields.incidentTypeOther"  [disabled]="readonly"
                 placeholder="{{'records.incidents.incident-type-other.placeholder' | translate}}"
                 (change)="update()"/>
          <mat-hint>
            {{'records.incidents.incident-type-other.hint' | translate}}
          </mat-hint>
        </mat-form-field>
      </div>

      <!-- incident time -->
      <div commentable
           comment-subject-codename="incidents-{{identifier}}-incident-time"
           comment-subject-title="[{{ref}}] {{'records.incidents.incident-time.placeholder' | translate}}">
        <todoable subject="incident-{{identifier}}-incident-time"
                  suggested-title="[{{ref}}] {{'records.incidents.incident-time.placeholder' | translate}}: "></todoable>
        <br><label>{{'records.incidents.incident-time.placeholder' | translate }}</label><br>
        <mat-radio-group [(ngModel)]="record.customFields.timePeriodStatus" [disabled]="readonly" (change)="update()">
          <mat-radio-button value="incident.time-period.once">
            {{'records.incidents.datetime.on' | translate}}
            <mat-form-field class="inline">
              <input matInput [(ngModel)]="record.customFields.incidentTime"  [disabled]="readonly"
                     (change)="update()" [matDatepicker]="incidentTimePicker"
                     (dateChange)="update()"/>
              <mat-datepicker-toggle matSuffix [for]="incidentTimePicker"></mat-datepicker-toggle>
              <mat-datepicker #incidentTimePicker></mat-datepicker>
            </mat-form-field>
          </mat-radio-button>
          <br>
          <mat-radio-button value="incident.time-period.started-ongoing">
            {{'records.incidents.datetime.since' | translate}}
            <mat-form-field class="inline">
              <input matInput [(ngModel)]="record.customFields.incidentTimeStart"  [disabled]="readonly"
                     (change)="update()" [matDatepicker]="incidentTimeStartPicker"
                     (dateChange)="update()"/>
              <mat-datepicker-toggle matSuffix [for]="incidentTimeStartPicker"></mat-datepicker-toggle>
              <mat-datepicker #incidentTimeStartPicker></mat-datepicker>
            </mat-form-field>
            {{'records.incidents.datetime.until' | translate}}
          </mat-radio-button>
          <br>
          <mat-radio-button value="incident.time-period.started-finished">
            {{'records.incidents.datetime.between-beginning' | translate}}
            <mat-form-field class="inline">
              <input matInput [(ngModel)]="record.customFields.incidentTimeStart"  [disabled]="readonly"
                     (change)="update()" [matDatepicker]="incidentTimeStartPicker2"
                     (dateChange)="update()"/>
              <mat-datepicker-toggle matSuffix [for]="incidentTimeStartPicker2"></mat-datepicker-toggle>
              <mat-datepicker #incidentTimeStartPicker2></mat-datepicker>
            </mat-form-field>
            {{'records.incidents.datetime.between-end' | translate}}
            <mat-form-field class="inline">
              <input matInput [(ngModel)]="record.customFields.incidentTimeEnd"  [disabled]="readonly"
                     (change)="update()" [matDatepicker]="incidentTimeEndPicker"
                     (dateChange)="update()"/>
              <mat-datepicker-toggle matSuffix [for]="incidentTimeEndPicker"></mat-datepicker-toggle>
              <mat-datepicker #incidentTimeEndPicker></mat-datepicker>
            </mat-form-field>
          </mat-radio-button>
          <br>
          <mat-radio-button value="incident.time-period.unknown">
            {{'records.incidents.datetime.unknown' | translate}}
          </mat-radio-button>
          <br>
          <mat-radio-button value="incident.time-period.unknown-ongoing">
            {{'records.incidents.datetime.unknown-unfinished' | translate}}
          </mat-radio-button>
        </mat-radio-group>
        <br><br><br>
      </div>

      <!-- incident detection time -->
      <div commentable
           comment-subject-codename="incidents-{{identifier}}-incident-detection-time"
           comment-subject-title="[{{ref}}] {{'records.incidents.incident-detection-time.placeholder' | translate}}">
        <todoable subject="incident-{{identifier}}-incident-detection-time"
                  suggested-title="[{{ref}}] {{'records.incidents.incident-detection-time.placeholder' | translate}}: "></todoable>
        <mat-form-field>
          <input matInput [matDatepicker]="incidentDetectionTimePicker" [(ngModel)]="record.customFields.incidentDetectionTime"  [disabled]="readonly"
                 placeholder="{{'records.incidents.incident-detection-time.placeholder' | translate}}"
                 (change)="update()"
                 (dateChange)="update()"/>
          <mat-datepicker-toggle matSuffix [for]="incidentDetectionTimePicker"></mat-datepicker-toggle>
          <mat-datepicker #incidentDetectionTimePicker></mat-datepicker>
          <mat-hint>
            {{'records.incidents.incident-detection-time.hint' | translate}}
          </mat-hint>
        </mat-form-field>
      </div>

      <!-- incident location -->
      <div commentable
           comment-subject-codename="incidents-{{identifier}}-incident-location"
           comment-subject-title="[{{ref}}] {{'records.incidents.incident-location.placeholder' | translate}}">
        <todoable subject="incident-{{identifier}}-incident-location"
                  suggested-title="[{{ref}}] {{'records.incidents.incident-location.placeholder' | translate}}: "></todoable>
        <mat-form-field>
          <input matInput [(ngModel)]="record.customFields.incidentLocation"  [disabled]="readonly"
                 placeholder="{{'records.incidents.incident-location.placeholder' | translate}}"
                 (change)="update()"/>
          <mat-hint>
            {{'records.incidents.incident-location.hint' | translate}}
          </mat-hint>
        </mat-form-field>
      </div>

      <!-- incident location select -->
      <div commentable
           comment-subject-codename="incidents-{{identifier}}-incident-data-storage"
           comment-subject-title="[{{ref}}] {{'records.incidents.incident-data-storage.placeholder' | translate}}">
        <todoable subject="incident-{{identifier}}-incident-data-storage"
                  suggested-title="[{{ref}}] {{'records.incidents.incident-data-storage.placeholder' | translate}}: "></todoable>
        <mat-form-field>
          <mat-select [(ngModel)]="record.customFields.dataStorage" [ngModelOptions]="{standalone: true}"
                      placeholder="{{'records.incidents.incident-data-storage.placeholder' | translate}}"
                      multiple long-select [disabled]="readonly" (selectionChange)="update()">
            <mat-option value="incident.data-storage.local">
              {{'records.incidents.data-storage.local' | translate}}
            </mat-option>
            <mat-option value="incident.data-storage.mobileDevice">
              {{'records.incidents.data-storage.mobileDevice' | translate}}
            </mat-option>
            <mat-option value="incident.data-storage.networked">
              {{'records.incidents.data-storage.networked' | translate}}
            </mat-option>
            <mat-option value="incident.data-storage.paper">
              {{'records.incidents.data-storage.paper' | translate}}
            </mat-option>
            <mat-option value="incident.data-storage.other">
              {{'records.incidents.data-storage.other' | translate}}
            </mat-option>
            <mat-option value="incident.data-storage.unknown">
              {{'records.incidents.data-storage.unknown' | translate}}
            </mat-option>
          </mat-select>
          <mat-hint>
            {{'records.incidents.incident-data-storage.hint' | translate}}
          </mat-hint>
        </mat-form-field>
      </div>

      <!-- access type other -->
      <div commentable
           comment-subject-codename="incidents-{{identifier}}-incident-data-storage-other"
           comment-subject-title="[{{ref}}] {{'records.incidents.incident-data-storage.other.placeholder' | translate}}">
        <todoable subject="incident-{{identifier}}-incident-data-storage-other"
                  suggested-title="[{{ref}}] {{'records.incidents.incident-data-storage.other.placeholder' | translate}}: "></todoable>
        <mat-form-field
          *ngIf="
          record.customFields.dataStorage &&
          (record.customFields.dataStorage.includes('incident.data-storage.other') || record.customFields.dataStorage.includes('incident.data-storage.unknown'))"
        >
          <input matInput [(ngModel)]="record.customFields.dataStorageOther"
                 placeholder="{{'records.incidents.incident-data-storage.other.placeholder' | translate}}" name="dataStorageOther" (change)="update()" [disabled]="readonly" />
          <mat-hint>
            {{'records.incidents.incident-data-storage.other.hint' | translate}}
          </mat-hint>
        </mat-form-field>
      </div>

      <!-- type of access -->
      <div commentable
           comment-subject-codename="incidents-{{identifier}}-type-of-access"
           comment-subject-title="[{{ref}}] {{'records.incidents.type-of-access.placeholder' | translate}}">
        <todoable subject="incident-{{identifier}}-type-of-access"
                  suggested-title="[{{ref}}] {{'records.incidents.type-of-access.placeholder' | translate}}: "></todoable>
        <mat-form-field>
          <mat-select [(ngModel)]="record.customFields.typeOfAccess" [disabled]="readonly"
                      placeholder="{{'records.incidents.type-of-access.placeholder' | translate}}"
                      multiple long-select (selectionChange)="update()">
            <mat-option value="incident.access-types.read">
              {{'records.incidents.type-of-access.type-read' | translate}}
            </mat-option>
            <mat-option value="incident.access-types.copy">
              {{'records.incidents.type-of-access.type-copy' | translate}}
            </mat-option>
            <mat-option value="incident.access-types.change">
              {{'records.incidents.type-of-access.type-change' | translate}}
            </mat-option>
            <mat-option value="incident.access-types.delete">
              {{'records.incidents.type-of-access.type-delete' | translate}}
            </mat-option>
            <mat-option value="incident.access-types.steal">
              {{'records.incidents.type-of-access.type-theft' | translate}}
            </mat-option>
            <mat-option value="incident.access-types.other">
              {{'records.incidents.type-of-access.type-other' | translate}}
            </mat-option>
            <mat-option value="incident.access-types.unknown">
              {{'records.incidents.type-of-access.type-unknown' | translate}}
            </mat-option>
          </mat-select>
          <mat-hint>
            {{'records.incidents.type-of-access.hint' | translate}}
          </mat-hint>
        </mat-form-field>

        <!-- access type other -->
        <mat-form-field
          *ngIf="
          record.customFields.typeOfAccess &&
          record.customFields.typeOfAccess.includes('incident.access-types.other')"
        >
          <input matInput [(ngModel)]="record.customFields.typeOfAccessOther"  [disabled]="readonly"
                 placeholder="{{'records.incidents.type-of-access.other.placeholder' | translate}}"
                 (change)="update()"/>
          <mat-hint>
            {{'records.incidents.type-of-access.other.hint' | translate}}
          </mat-hint>
        </mat-form-field>
      </div>

      <br><br>
      <mat-divider></mat-divider>
      <br><br>

      <h2>{{'records.incidents.data-subjects.title' | translate}}</h2>


      <!-- number of affected people -->
      <div commentable
           comment-subject-codename="incidents-{{identifier}}-affected-people-number"
           comment-subject-title="[{{ref}}] {{'records.incidents.affected-people-number.placeholder' | translate}}">
        <todoable subject="incident-{{identifier}}-affected-people-number"
                  suggested-title="[{{ref}}] {{'records.incidents.affected-people-number.placeholder' | translate}}: "></todoable>

        <div class="number-precision">
          <!-- number of affected people precision -->
          <mat-form-field class="precision">
            <mat-select [(ngModel)]="record.customFields.affectedPeopleNumberPrecision"  [disabled]="readonly"
                        placeholder="{{'records.incidents.affected-people-number-precision.placeholder' | translate}}"
                        (selectionChange)="update()">
              <mat-option value="incident.precision.precise">
                {{'records.incidents.affected-people-number-precision.precise' | translate}}
              </mat-option>
              <mat-option value="incident.precision.estimate">
                {{'records.incidents.affected-people-number-precision.estimate' | translate}}
              </mat-option>
              <mat-option value="incident.precision.min">
                {{'records.incidents.affected-people-number-precision.min' | translate}}
              </mat-option>
              <mat-option value="incident.precision.max">
                {{'records.incidents.affected-people-number-precision.max' | translate}}
              </mat-option>
              <mat-option value="incident.precision.unknown">
                {{'records.incidents.affected-people-number-precision.unknown' | translate}}
              </mat-option>
            </mat-select>
            <mat-hint>
              {{'records.incidents.affected-people-number-precision.hint' | translate}}
            </mat-hint>
          </mat-form-field>

          <mat-form-field class="number">
            <input matInput [(ngModel)]="record.customFields.affectedPeopleNumber"  [disabled]="readonly"
                   placeholder="{{'records.incidents.affected-people-number.placeholder' | translate}}"
                   type="number"
                   (change)="update()"/>
            <mat-hint>
              {{'records.incidents.affected-people-number.hint' | translate}}
            </mat-hint>
          </mat-form-field>
        </div>
      </div>

      <!-- number of affected records -->
      <div commentable
           comment-subject-codename="incidents-{{identifier}}-affected-records-number"
           comment-subject-title="[{{ref}}] {{'records.incidents.affected-records-number.placeholder' | translate}}">
        <todoable subject="incident-{{identifier}}-affected-records-number"
                  suggested-title="[{{ref}}] {{'records.incidents.affected-records-number.placeholder' | translate}}: "></todoable>

        <div class="number-precision">
          <!-- number of affected records precision -->
          <mat-form-field class="precision">
            <mat-select [(ngModel)]="record.customFields.affectedRecordsNumberPrecision"  [disabled]="readonly"
                        placeholder="{{'records.incidents.affected-records-number-precision.placeholder' | translate}}"
                        (selectionChange)="update()">
              <mat-option value="incident.precision.precise">
                {{'records.incidents.affected-records-number-precision.precise' | translate}}
              </mat-option>
              <mat-option value="incident.precision.estimate">
                {{'records.incidents.affected-records-number-precision.estimate' | translate}}
              </mat-option>
              <mat-option value="incident.precision.min">
                {{'records.incidents.affected-records-number-precision.min' | translate}}
              </mat-option>
              <mat-option value="incident.precision.max">
                {{'records.incidents.affected-records-number-precision.max' | translate}}
              </mat-option>
              <mat-option value="incident.precision.unknown">
                {{'records.incidents.affected-records-number-precision.unknown' | translate}}
              </mat-option>
            </mat-select>
            <mat-hint>
              {{'records.incidents.affected-records-number-precision.hint' | translate}}
            </mat-hint>
          </mat-form-field>
          <mat-form-field class="number">
            <input matInput [(ngModel)]="record.customFields.affectedRecordsNumber"  [disabled]="readonly"
                   placeholder="{{'records.incidents.affected-records-number.placeholder' | translate}}"
                   type="number"
                   (change)="update()"/>
            <mat-hint>
              {{'records.incidents.affected-records-number.hint' | translate}}
            </mat-hint>
          </mat-form-field>
        </div>
      </div>

      <!-- categories of data subjects -->
      <div commentable
           comment-subject-codename="incidents-{{identifier}}-data-subject-categories"
           comment-subject-title="[{{ref}}] {{'records.incidents.data-subject-categories.placeholder' | translate}}">
        <todoable subject="incident-{{identifier}}-data-subject-categories"
                  suggested-title="[{{ref}}] {{'records.incidents.data-subject-categories.placeholder' | translate}}: "></todoable>
        <mat-form-field>
          <mat-select [(ngModel)]="record.customFields.dataSubjectCategories"  [disabled]="readonly"
                      placeholder="{{'records.incidents.data-subject-categories.placeholder' | translate}}"
                      (selectionChange)="update()" multiple long-select>
            <mat-option value="incident.data-subject-category.members">
              {{'records.incidents.data-subject-categories.members' | translate}}
            </mat-option>
            <mat-option value="incident.data-subject-category.contractors">
              {{'records.incidents.data-subject-categories.contractors' | translate}}
            </mat-option>
            <mat-option value="incident.data-subject-category.employees">
              {{'records.incidents.data-subject-categories.employees' | translate}}
            </mat-option>
            <mat-option value="incident.data-subject-category.employer">
              {{'records.incidents.data-subject-categories.employer' | translate}}
            </mat-option>
            <mat-option value="incident.data-subject-category.supervisor">
              {{'records.incidents.data-subject-categories.supervisor' | translate}}
            </mat-option>
            <mat-option value="incident.data-subject-category.children">
              {{'records.incidents.data-subject-categories.children' | translate}}
            </mat-option>
            <mat-option value="incident.data-subject-category.other">
              {{'records.incidents.data-subject-categories.other' | translate}}
            </mat-option>
          </mat-select>
          <mat-hint>
            {{'records.incidents.data-subject-categories.hint' | translate}}
          </mat-hint>
        </mat-form-field>

        <!-- categories of data subjects other -->
        <mat-form-field
          *ngIf="
          record.customFields.dataSubjectCategories &&
          record.customFields.dataSubjectCategories.includes('incident.data-subject-category.other')">
          <input matInput [(ngModel)]="record.customFields.dataSubjectCategoriesOther"  [disabled]="readonly"
                 placeholder="{{'records.incidents.data-subject-categories.other.placeholder' | translate}}"
                 type="text"
                 (change)="update()"/>
          <mat-hint>
            {{'records.incidents.data-subject-categories.other.hint' | translate}}
          </mat-hint>
        </mat-form-field>
      </div>

      <br><br>
      <label>{{'records.incidents.dataCategories.title' | translate}}</label>
      <br><br>
      <div commentable
           comment-subject-codename="incidents-{{identifier}}-personal-data-categories"
           comment-subject-title="[{{ref}}] {{'records.incidents.personal-data-categories.placeholder' | translate}}">
        <todoable subject="incident-{{identifier}}-personal-data-categories"
                  suggested-title="[{{ref}}] {{'records.incidents.personal-data-categories.placeholder' | translate}}: "></todoable>

        <!-- data categories contact -->
        <mat-form-field>
          <mat-select long-select [(ngModel)]="record.customFields.dataCategories"  [disabled]="readonly"
                      placeholder="{{'records.incidents.personal-data-categories.placeholder' | translate}}"
                      (selectionChange)="update()" multiple>
            <mat-option value="incident.data-category.unknown">
              {{ 'records.incidents.personal-data-categories.unknown' | translate }}
            </mat-option>

            <mat-option value="incident.data-category.contact.name">
              {{'records.incidents.personal-data-categories.contact.name' | translate}}
            </mat-option>
            <mat-option value="incident.data-category.contact.address">
              {{'records.incidents.personal-data-categories.contact.address' | translate}}
            </mat-option>
            <mat-option value="incident.data-category.contact.phone">
              {{'records.incidents.personal-data-categories.contact.phone' | translate}}
            </mat-option>
            <mat-option value="incident.data-category.contact.email">
              {{'records.incidents.personal-data-categories.contact.email' | translate}}
            </mat-option>

            <mat-option value="incident.data-category.bank.contact">
              {{'records.incidents.personal-data-categories.bank.contact' | translate}}
            </mat-option>
            <mat-option value="incident.data-category.bank.credit-debit">
              {{'records.incidents.personal-data-categories.bank.credit-debit' | translate}}
            </mat-option>

            <mat-option value="incident.data-category.id.userid">
              {{'records.incidents.personal-data-categories.id.userid' | translate}}
            </mat-option>
            <mat-option value="incident.data-category.id.password">
              {{'records.incidents.personal-data-categories.id.password' | translate}}
            </mat-option>
            <mat-option value="incident.data-category.id.token">
              {{'records.incidents.personal-data-categories.id.token' | translate}}
            </mat-option>
            <mat-option value="incident.data-category.id.pin-puk">
              {{'records.incidents.personal-data-categories.id.pin-puk' | translate}}
            </mat-option>
            <mat-option value="incident.data-category.id.access-card">
              {{'records.incidents.personal-data-categories.id.access-card' | translate}}
            </mat-option>
            <mat-option value="incident.data-category.id.signature">
              {{'records.incidents.personal-data-categories.id.signature' | translate}}
            </mat-option>

            <mat-option value="incident.data-category.personal.gender">
              {{'records.incidents.personal-data-categories.personal.gender' | translate}}
            </mat-option>
            <mat-option value="incident.data-category.personal.age">
              {{'records.incidents.personal-data-categories.personal.age' | translate}}
            </mat-option>
            <mat-option value="incident.data-category.personal.birth-name">
              {{'records.incidents.personal-data-categories.personal.birth-name' | translate}}
            </mat-option>
            <mat-option value="incident.data-category.personal.kv-number">
              {{'records.incidents.personal-data-categories.personal.kv-number' | translate}}
            </mat-option>
            <mat-option value="incident.data-category.personal.rv-number">
              {{'records.incidents.personal-data-categories.personal.rv-number' | translate}}
            </mat-option>
            <mat-option value="incident.data-category.personal.employer-number">
              {{'records.incidents.personal-data-categories.personal.employer-number' | translate}}
            </mat-option>

            <mat-option value="incident.data-category.special.ethnicity">
              {{'records.incidents.personal-data-categories.special.ethnicity' | translate}}
            </mat-option>
            <mat-option value="incident.data-category.special.politics">
              {{'records.incidents.personal-data-categories.special.politics' | translate}}
            </mat-option>
            <mat-option value="incident.data-category.special.religion">
              {{'records.incidents.personal-data-categories.special.religion' | translate}}
            </mat-option>
            <mat-option value="incident.data-category.special.sex-life">
              {{'records.incidents.personal-data-categories.special.sex-life' | translate}}
            </mat-option>
            <mat-option value="incident.data-category.special.health">
              {{'records.incidents.personal-data-categories.special.health' | translate}}
            </mat-option>

            <mat-option value="incident.data-category.other">
              {{'records.incidents.personal-data-categories.other' | translate}}
            </mat-option>
          </mat-select>
          <mat-hint>
            {{'records.incidents.personal-data-categories.hint' | translate}}
          </mat-hint>
        </mat-form-field>

        <!-- data categories other -->
        <mat-form-field *ngIf="
            record.customFields.dataCategories &&
            record.customFields.dataCategories.includes('incident.data-category.other')">
          <input matInput [(ngModel)]="record.customFields.dataCategoriesOther" [disabled]="readonly"
                 placeholder="{{'records.incidents.personal-data-categories.other.placeholder' | translate}}"
                 type="text"
                 (change)="update()"/>
          <mat-hint>
            {{'records.incidents.personal-data-categories.other.hint' | translate}}
          </mat-hint>
        </mat-form-field>


        <ng-container *ngIf="
            !record.customFields.dataCategories ||
            !record.customFields.dataCategories.includes('incident.data-category.unknown')">
          <!-- data categories additional info -->
          <mat-form-field>
              <textarea matInput [(ngModel)]="record.customFields.dataCategoriesAdditionalInfo"  [disabled]="readonly"
                        placeholder="{{'records.incidents.personal-data-categories.additional-info.placeholder' | translate}}"
                        (change)="update()" rows="1"></textarea>
            <mat-hint>
              {{'records.incidents.personal-data-categories.additional-info.hint' | translate}}
            </mat-hint>
          </mat-form-field>
        </ng-container>

        <!-- data categories unknown justification -->
        <mat-form-field *ngIf="
            record.customFields.dataCategories &&
            record.customFields.dataCategories.includes('incident.data-category.unknown')">
            <textarea matInput [(ngModel)]="record.customFields.dataCategoriesUnknownJustification"  [disabled]="readonly"
                      placeholder="{{'records.incidents.personal-data-categories.unknown.justification.placeholder' | translate}}"
                      rows="4"
                      (change)="update()"></textarea>
          <mat-hint>
            {{'records.incidents.personal-data-categories.unknown.justification.hint' | translate}}
          </mat-hint>
        </mat-form-field>
      </div>

      <!-- name(s) of affected people -->
      <div commentable
           comment-subject-codename="incidents-{{identifier}}-affected-people-names"
           comment-subject-title="[{{ref}}] {{'records.incidents.affected-people-names.placeholder' | translate}}">
        <todoable subject="incident-{{identifier}}-affected-people-names"
                  suggested-title="[{{ref}}] {{'records.incidents.affected-people-names.placeholder' | translate}}: "></todoable>
        <mat-form-field>
          <textarea matInput [(ngModel)]="record.customFields.affectedPeopleNames"
                    placeholder="{{'records.incidents.affected-people-names.placeholder' | translate}}"
                    rows="2" name="affectedPeopleNames" (change)="update()" [disabled]="readonly"></textarea>
          <mat-hint>
            {{'records.incidents.affected-people-names.hint' | translate}}
          </mat-hint>
        </mat-form-field>
      </div>

      <br><br>
      <mat-divider></mat-divider>
      <br><br>

      <h2>{{'records.incidents.consequences.title' | translate}} </h2>
      <br>

      <!-- anticipated consequences -->
      <div commentable
           comment-subject-codename="incidents-{{identifier}}-anticipated-consequences"
           comment-subject-title="[{{ref}}] {{'records.incidents.anticipated-consequences.placeholder' | translate}}">
        <todoable subject="incident-{{identifier}}-anticipated-consequences"
                  suggested-title="[{{ref}}] {{'records.incidents.anticipated-consequences.placeholder' | translate}}: "></todoable>
        <mat-form-field>
          <mat-select [(ngModel)]="record.customFields.anticipatedConsequences"  [disabled]="readonly"
                      placeholder="{{'records.incidents.anticipated-consequences.placeholder' | translate}}"
                      (selectionChange)="update()" multiple long-select>
            <mat-option value="incident.anticipated-consequences.control-loss">
              {{'records.incidents.anticipated-consequences.control-loss' | translate}}
            </mat-option>
            <mat-option value="incident.anticipated-consequences.limitation-of-rights">
              {{'records.incidents.anticipated-consequences.limitation-of-rights' | translate}}
            </mat-option>
            <mat-option value="incident.anticipated-consequences.discrimination">
              {{'records.incidents.anticipated-consequences.discrimination' | translate}}
            </mat-option>
            <mat-option value="incident.anticipated-consequences.identity-theft">
              {{'records.incidents.anticipated-consequences.identity-theft' | translate}}
            </mat-option>
            <mat-option value="incident.anticipated-consequences.financial-loss">
              {{'records.incidents.anticipated-consequences.financial-loss' | translate}}
            </mat-option>
            <mat-option value="incident.anticipated-consequences.danger-to-life">
              {{'records.incidents.anticipated-consequences.danger-to-life' | translate}}
            </mat-option>
            <mat-option value="incident.anticipated-consequences.pseudonymization-cancellation">
              {{'records.incidents.anticipated-consequences.pseudonymization-cancellation' | translate}}
            </mat-option>
            <mat-option value="incident.anticipated-consequences.reputation">
              {{'records.incidents.anticipated-consequences.reputation' | translate}}
            </mat-option>
            <mat-option value="incident.anticipated-consequences.professional-secrecy">
              {{'records.incidents.anticipated-consequences.professional-secrecy' | translate}}
            </mat-option>
            <mat-option value="incident.anticipated-consequences.social">
              {{'records.incidents.anticipated-consequences.social' | translate}}
            </mat-option>
            <mat-option value="incident.anticipated-consequences.other">
              {{'records.incidents.anticipated-consequences.other' | translate}}
            </mat-option>
          </mat-select>
          <mat-hint>
            {{'records.incidents.anticipated-consequences.hint' | translate}}
          </mat-hint>
        </mat-form-field>
        <mat-form-field *ngIf="
            record.customFields.anticipatedConsequences &&
            record.customFields.anticipatedConsequences.includes('incident.anticipated-consequences.other')">
          <input matInput [(ngModel)]="record.customFields.anticipatedConsequencesOther"  [disabled]="readonly"
                 placeholder="{{'records.incidents.anticipated-consequences.other.placeholder' | translate}}"
                 type="text"
                 (change)="update()"/>
          <mat-hint>
            {{'records.incidents.anticipated-consequences.other.hint' | translate}}
          </mat-hint>
        </mat-form-field>
      </div>


      <!-- toms initiated -->
      <div commentable
           comment-subject-codename="incidents-{{identifier}}-toms-initiated"
           comment-subject-title="[{{ref}}] {{'records.incidents.toms-initiated.placeholder' | translate}}">
        <todoable subject="incident-{{identifier}}-toms-initiated"
                  suggested-title="[{{ref}}] {{'records.incidents.toms-initiated.placeholder' | translate}}: "></todoable>
        <label>{{'records.incidents.toms-initiated.label' | translate}}</label><br>
        <mat-radio-group [(ngModel)]="record.customFields.tomsInitiated"  [disabled]="readonly" (change)="update()">
          <mat-radio-button [value]="false">{{'yesno.no' | translate}}</mat-radio-button><br>
          <mat-radio-button [value]="true">
            {{'records.incidents.toms-initiated.from' | translate}}
            <mat-form-field class="inline">
              <input matInput [(ngModel)]="record.customFields.tomsInitiationDate"  [disabled]="readonly"
                     (change)="update()" [matDatepicker]="tomsInitiationDatePicker"
                     (dateChange)="update()"/>
              <mat-datepicker-toggle matSuffix [for]="tomsInitiationDatePicker"></mat-datepicker-toggle>
              <mat-datepicker #tomsInitiationDatePicker></mat-datepicker>
            </mat-form-field>
          </mat-radio-button>
        </mat-radio-group>

        <br>

        <!-- toms not initiated justification -->
        <mat-form-field *ngIf="!record.customFields.tomsInitiated">
          <textarea matInput [(ngModel)]="record.customFields.tomsNotInitiatedJustification"
                    [disabled]="readonly"
                    placeholder="{{'records.incidents.toms-not-initiated-justification.placeholder' | translate}}"
                    (change)="update()"
                    rows="3"></textarea>
          <mat-hint>
            {{'records.incidents.toms-not-initiated-justification.hint' | translate}}
          </mat-hint>
        </mat-form-field>


        <ng-container *ngIf="record.customFields.tomsInitiated">
          <br>
          Folgende technischen und/oder organisatorischen Maßnahmen wurden eingeleitet,

          <mat-checkbox [(ngModel)]="record.customFields.tomsDamageControl" [disabled]="readonly"
                        (change)="update()">
            {{'records.incidents.toms-damage-control.label' | translate}}
          </mat-checkbox><br>
          <mat-form-field *ngIf="record.customFields.tomsDamageControl">
            <textarea matInput [(ngModel)]="record.customFields.tomsDamageControlExplanation"  [disabled]="readonly"
                      placeholder="{{'records.incidents.toms-damage-control.explanation.placeholder' | translate}}"
                      type="text"
                      (change)="update()"></textarea>
            <mat-hint>
              {{'records.incidents.toms-damage-control.explanation.hint' | translate}}
            </mat-hint>
          </mat-form-field>


          <mat-checkbox [(ngModel)]="record.customFields.tomsPreventiveMeasures" [disabled]="readonly"
                        (change)="update()">
            {{'records.incidents.toms-preventive-measures.label' | translate}}
          </mat-checkbox><br>
          <mat-form-field *ngIf="record.customFields.tomsPreventiveMeasures">
            <textarea matInput [(ngModel)]="record.customFields.tomsPreventiveMeasuresExplanation"  [disabled]="readonly"
                      placeholder="{{'records.incidents.toms-preventive-measures.explanation.placeholder' | translate}}"
                      type="text"
                      (change)="update()"></textarea>
            <mat-hint>
              {{'records.incidents.toms-preventive-measures.explanation.hint' | translate}}
            </mat-hint>
          </mat-form-field>

        </ng-container>
      </div>
    </div>

    <br><br><br><br><br><br>

    <div id="legal" class="section legal prominent">
      <h2 class="section-title">{{'records.incidents.legal.title' | translate}}</h2>

      <h2>{{'records.incidents.legalIsIncident.title' | translate}}</h2>
      <div commentable
           comment-subject-codename="incidents-{{identifier}}-legal-is-incident"
           comment-subject-title="[{{ref}}] {{'records.incidents.legalIsIncident.placeholder' | translate}}">
        <todoable subject="incident-{{identifier}}-legal-is-incident"
                  suggested-title="[{{ref}}] {{'records.incidents.legalIsIncident.placeholder' | translate}}: "></todoable>
        <mat-form-field>
          <mat-select [(ngModel)]="record.customFields.legalIsIncident" [disabled]="readonly"
                      placeholder="{{'records.incidents.legalIsIncident.placeholder' | translate}}"
                      (selectionChange)="update()" required>
            <mat-option [value]="true">
              {{'yesno.yes' | translate}}
            </mat-option>
            <mat-option [value]="false">
              {{'yesno.no' | translate}}
            </mat-option>
          </mat-select>
          <mat-hint>{{'records.incidents.legalIsIncident.hint' | translate}}</mat-hint>
        </mat-form-field>
      </div>

      <!--
      <div commentable
        comment-subject-codename="incidents-{{identifier}}-legal-description"
        comment-subject-title="[{{ref}}] {{'records.incidents.legalDescription.placeholder' | translate}}">
        <todoable subject="incident-{{identifier}}-legal-description"
          suggested-title="[{{ref}}] {{'records.incidents.legalDescription.placeholder' | translate}}: "></todoable>
        <br>
        <mat-form-field>
          <textarea matInput [(ngModel)]="record.customFields.legalDescription"
            [disabled]="readonly"
            placeholder="{{'records.incidents.legalDescription.placeholder' | translate}}"
            (change)="update()"
            rows="3"></textarea>
          <mat-hint>
            {{'records.incidents.legalDescription.hint' | translate}}
          </mat-hint>
        </mat-form-field>
      </div>
      -->

      <div *ngIf="record.customFields.legalIsIncident" class="legal-risk-assessment">
        <h2>{{'records.incidents.risk-assessment.title' | translate}}</h2>

        <!-- severity -->
        <div commentable
             comment-subject-codename="incidents-{{identifier}}-severity"
             comment-subject-title="[{{ref}}] {{'records.incidents.severity.placeholder' | translate}}">
          <todoable subject="incident-{{identifier}}-severity"
                    suggested-title="[{{ref}}] {{'records.incidents.severity.placeholder' | translate}}: "></todoable>

          <h3 class="subsection-title">{{'records.incidents.severity.placeholder' | translate}}</h3>
          <p>{{'records.incidents.severity.hint' | translate}}</p>
          <div class="range-buttons">
            <button mat-flat-button tabindex="-1" (click)="setSeverity('legal', 1)" class="range-button green" [ngClass]="{
                'picked': getSeverityScore('legalSeverity') === 1
              }" [disabled]="readonly">
              {{'records.incidents.severity.low' | translate}}
            </button>
            <button mat-flat-button tabindex="-1" (click)="setSeverity('legal', 2)" class="range-button orange" [ngClass]="{
              'picked': getSeverityScore('legalSeverity') === 2
              }" [disabled]="readonly">
              {{'records.incidents.severity.medium' | translate}}
            </button>
            <button mat-flat-button tabindex="-1" (click)="setSeverity('legal', 3)" class="range-button red" [ngClass]="{
                'picked': getSeverityScore('legalSeverity') === 3
              }" [disabled]="readonly">
              {{'records.incidents.severity.high' | translate}}
            </button>
          </div>

          <!-- severity justification -->
          <mat-form-field>
            <textarea matInput [(ngModel)]="record.customFields.legalSeverityJustification"
                      [disabled]="readonly"
                      placeholder="{{'records.incidents.severity-justification.placeholder' | translate}}"
                      (change)="update()"
                      rows="3" required></textarea>
            <mat-hint>
              {{'records.incidents.severity-justification.hint' | translate}}
            </mat-hint>
          </mat-form-field>
        </div>


        <!-- risk -->
        <div commentable
             comment-subject-codename="incidents-{{identifier}}-risk"
             comment-subject-title="[{{ref}}] {{'records.incidents.risk.placeholder' | translate}}">
          <todoable subject="incident-{{identifier}}-risk"
                    suggested-title="[{{ref}}] {{'records.incidents.risk.placeholder' | translate}}: "></todoable>

          <h3 class="subsection-title">{{'records.incidents.risk.placeholder' | translate}}</h3>
          <p>{{'records.incidents.risk.hint' | translate}}</p>

          <div class="range-buttons">
            <button mat-flat-button tabindex="-1" (click)="setRisk('legal', 1)" class="range-button green" [ngClass]="{
                'picked': getRiskScore('legalRisk') === 1
              }" [disabled]="readonly">
              {{'records.incidents.risk.low' | translate}}
            </button>
            <button mat-flat-button tabindex="-1" (click)="setRisk('legal', 2)" class="range-button orange" [ngClass]="{
              'picked': getRiskScore('legalRisk') === 2
              }" [disabled]="readonly">
              {{'records.incidents.risk.medium' | translate}}
            </button>
            <button mat-flat-button tabindex="-1" (click)="setRisk('legal', 3)" class="range-button red" [ngClass]="{
                'picked': getRiskScore('legalRisk') === 3
              }" [disabled]="readonly">
              {{'records.incidents.risk.high' | translate}}
            </button>
          </div>

          <!-- risk justification -->
          <mat-form-field>
            <textarea matInput [(ngModel)]="record.customFields.legalRiskJustification"
                      [disabled]="readonly"
                      placeholder="{{'records.incidents.risk-justification.placeholder' | translate}}"
                      (change)="update()"
                      rows="3" required></textarea>
            <mat-hint>
              {{'records.incidents.risk-justification.hint' | translate}}
            </mat-hint>
          </mat-form-field>
        </div>

        <!-- impact -->
        <div commentable
             comment-subject-codename="incidents-{{identifier}}-impact"
             comment-subject-title="[{{ref}}] {{'records.incidents.impact.placeholder' | translate}}">
          <todoable subject="incident-{{identifier}}-impact"
                    suggested-title="[{{ref}}] {{'records.incidents.impact.placeholder' | translate}}: "></todoable>

          <h3>{{'records.incidents.impact.placeholder' | translate}}</h3>
          <div class="range-buttons">
            <button mat-flat-button tabindex="-1" (click)="setImpact('legalImpact', 1)" class="range-button green" [ngClass]="{
                'picked': getImpactScore('legalImpact') === 1
              }" [disabled]="readonly">
              {{'records.incidents.impact.minor' | translate}}
            </button>
            <button mat-flat-button tabindex="-1" (click)="setImpact('legalImpact', 2)" class="range-button orange" [ngClass]="{
              'picked': getImpactScore('legalImpact') === 2
              }" [disabled]="readonly">
              {{'records.incidents.impact.medium' | translate}}
            </button>
            <button mat-flat-button tabindex="-1" (click)="setImpact('legalImpact', 3)" class="range-button red" [ngClass]="{
                'picked': getImpactScore('legalImpact') === 3
              }" [disabled]="readonly">
              {{'records.incidents.impact.high' | translate}}
            </button>
          </div>
        </div>

        <div class="big-indicator-container impact-container">
          <div class="content">
            <ng-container *ngIf="record.customFields.legalImpact === 'incident.impact.minor'">
              <mat-icon>
                arrow_forward
              </mat-icon>

              <p class="impact-hint">{{ 'records.incidents.impact.minor.documentation-required' | translate }}</p>
            </ng-container>

            <ng-container *ngIf="record.customFields.legalImpact === 'incident.impact.medium'">
              <mat-icon>
                arrow_forward
              </mat-icon>

              <p class="impact-hint">{{ 'records.incidents.impact.medium.report-required' | translate }}</p>
            </ng-container>

            <ng-container *ngIf="record.customFields.legalImpact === 'incident.impact.high'">
              <mat-icon>
                arrow_forward
              </mat-icon>

              <p class="impact-hint">{{ 'records.incidents.impact.high.notification-required' | translate }}</p>
            </ng-container>
          </div>
        </div>
      </div>


      <div commentable
           comment-subject-codename="incidents-{{identifier}}-legal-additional-remarks"
           comment-subject-title="[{{ref}}] {{'records.incidents.legalAdditionalRemarks.placeholder' | translate}}">
        <todoable subject="incident-{{identifier}}-legal-additional-remarks"
                  suggested-title="[{{ref}}] {{'records.incidents.legalAdditionalRemarks.placeholder' | translate}}: "></todoable>
        <br>
        <mat-form-field>
          <textarea matInput [(ngModel)]="record.customFields.legalAdditionalRemarks"
                    [disabled]="readonly"
                    placeholder="{{'records.incidents.legalAdditionalRemarks.placeholder' | translate}}"
                    (change)="update()"
                    rows="3"></textarea>
          <mat-hint>
            {{'records.incidents.legalAdditionalRemarks.hint' | translate}}
          </mat-hint>
        </mat-form-field>
      </div>

    </div>

    <br><br><br><br><br><br>


    <div #remedies class="section response prominent">
      <div class="section-header" commentable
           comment-subject-codename="incidents-{{identifier}}-response"
           comment-subject-title="[{{ref}}] {{'records.incidents.response.title' | translate}}">
        <todoable subject="incident-{{identifier}}-response"
                  suggested-title="[{{ref}}] {{'records.incidents.response.title' | translate}}: "></todoable>

        <h2 class="section-title">{{'records.incidents.response.title' | translate}}</h2>
      </div>

      <br>
      <mat-divider></mat-divider>


      <form>

        <!-- notification of data subjects -->
        <h2>{{'records.incidents.notification-of-data-subjects.title'|translate}}</h2>
        <p [innerHtml]="'records.incidents.notification-of-data-subjects.label' | translate"></p>
        <br>

        <div class="downloads">
          <button mat-stroked-button color="primary" matTooltip="{{ 'records.incidents.export.tooltip' | translate }}" (click)="downloadWord('documents.generated.incidents.affectedPeople')">
            <mat-icon color="primary">cloud_download</mat-icon>
            {{ 'records.incidents.export.affectedPeople' | translate }}
          </button>
        </div>

        <br><br>

        <div commentable
             comment-subject-codename="incidents-{{identifier}}-notification-of-data-subjects-yes-no"
             comment-subject-title="[{{ref}}] {{'records.incidents.notification-of-data-subjects.placeholder' | translate}}">
          <todoable subject="incident-{{identifier}}-notification-of-data-subjects-yes-no"
                    suggested-title="[{{ref}}] {{'records.incidents.notification-of-data-subjects.placeholder' | translate}}: "></todoable>
          <mat-form-field>
            <mat-select [(ngModel)]="record.customFields.notificationOfDataSubjectsYesNo" [disabled]="readonly"
                        placeholder="{{'records.incidents.notification-of-data-subjects-yes-no.placeholder' | translate}}"
                        (selectionChange)="update()" required [ngModelOptions]="{standalone: true}">
              <mat-option value="true">
                {{'records.incidents.notification-of-data-subjects-yes-no.yes' | translate}}
              </mat-option>
              <mat-option value="false">
                {{'records.incidents.notification-of-data-subjects-yes-no.no' | translate}}
              </mat-option>
            </mat-select>
            <mat-hint>{{'records.incidents.notification-of-data-subjects-yes-no.hint' | translate}}</mat-hint>
          </mat-form-field>
        </div>

        <div *ngIf="record.customFields.notificationOfDataSubjectsYesNo == 'true'">
          <mat-form-field>
            <input matInput [(ngModel)]="record.customFields.notificationOfDataSubjectsScheduledDate"
                   [disabled]="readonly"
                   placeholder="{{'records.incidents.notification-of-data-subjects-scheduled-date.placeholder' | translate}}"
                   (change)="update()"
                   (dateChange)="update()"
                   required [ngModelOptions]="{standalone: true}"
            />
            <mat-hint>{{'records.incidents.notification-of-data-subjects-scheduled-date.hint' | translate}}</mat-hint>
          </mat-form-field>
          <!-- notification of data subjects text -->
          <mat-form-field>
          <textarea matInput [(ngModel)]="record.customFields.notificationOfDataSubjectsText"
                    [disabled]="readonly"
                    placeholder="{{'records.incidents.notification-of-data-subjects-text.placeholder' | translate}}"
                    (change)="update()"
                    rows="3" required [ngModelOptions]="{standalone: true}"></textarea>
            <mat-hint>{{'records.incidents.notification-of-data-subjects-text.hint' | translate}}</mat-hint>
          </mat-form-field>
        </div>

        <br><br>
        <mat-divider></mat-divider>
        <br><br>

        <!-- notification of data subjects -->
        <h2>{{'records.incidents.remedial-toms.title'|translate}}</h2>
        <p [innerHtml]="'records.incidents.remedial-toms.label' | translate"></p>
        <br>

        <!-- Department Remedial Info -->
        <div commentable
             comment-subject-codename="incidents-{{identifier}}-remedies-toms-initiated"
             comment-subject-title="[{{ref}}] {{'records.incidents.toms-initiated.placeholder' | translate}}">
          <todoable subject="incident-{{identifier}}-remedies-toms-initiated"
                    suggested-title="[{{ref}}] {{'records.incidents.toms-initiated.placeholder' | translate}}: "></todoable>
          <label>{{'records.incidents.remedial-toms.initiated.label' | translate}}</label><br>
          <mat-radio-group [(ngModel)]="record.customFields.remediesTomsInitiated" [ngModelOptions]="{standalone: true}" [disabled]="readonly" (change)="update()" required>
            <mat-radio-button [value]="false">{{'yesno.no' | translate}}</mat-radio-button><br>
            <mat-radio-button [value]="true">{{'yesno.yes' | translate}}</mat-radio-button>
          </mat-radio-group>

          <br><br>

          <!-- toms not initiated justification -->
          <mat-form-field *ngIf="!record.customFields.remediesTomsInitiated">
          <textarea matInput [(ngModel)]="record.customFields.remediesTomsNotInitiatedJustification"
                    [disabled]="readonly"
                    placeholder="{{'records.incidents.toms-not-initiated-justification.placeholder' | translate}}"
                    (change)="update()"
                    rows="3" required [ngModelOptions]="{standalone: true}"></textarea>
            <mat-hint>
              {{'records.incidents.toms-not-initiated-justification.hint' | translate}}
            </mat-hint>
          </mat-form-field>


          <ng-container *ngIf="record.customFields.remediesTomsInitiated">
            <br>
            {{'records.incidents.remedial-toms.initiated.explanation' | translate}}

            <mat-checkbox [(ngModel)]="record.customFields.remediesTomsDamageControl" [disabled]="readonly"
                          (change)="update()" [ngModelOptions]="{standalone: true}">
              {{'records.incidents.toms-damage-control.label' | translate}}
            </mat-checkbox><br>
            <mat-form-field *ngIf="record.customFields.remediesTomsDamageControl">
              <input matInput [(ngModel)]="record.customFields.remediesTomsDamageControlExplanation"  [disabled]="readonly"
                     placeholder="{{'records.incidents.toms-damage-control.explanation.placeholder' | translate}}"
                     type="text"
                     (change)="update()" [ngModelOptions]="{standalone: true}" required/>
              <mat-hint>
                {{'records.incidents.toms-damage-control.explanation.hint' | translate}}
              </mat-hint>
            </mat-form-field>


            <mat-checkbox [(ngModel)]="record.customFields.remediesTomsPreventiveMeasures" [disabled]="readonly"
                          (change)="update()" [ngModelOptions]="{standalone: true}">
              {{'records.incidents.toms-preventive-measures.label' | translate}}
            </mat-checkbox><br>
            <mat-form-field *ngIf="record.customFields.remediesTomsPreventiveMeasures">
              <input matInput [(ngModel)]="record.customFields.remediesTomsPreventiveMeasuresExplanation"  [disabled]="readonly"
                     placeholder="{{'records.incidents.toms-preventive-measures.explanation.placeholder' | translate}}"
                     type="text"
                     (change)="update()" [ngModelOptions]="{standalone: true}" required/>
              <mat-hint>
                {{'records.incidents.toms-preventive-measures.explanation.hint' | translate}}
              </mat-hint>
            </mat-form-field>

          </ng-container>
        </div>

      </form>
    </div>

    <br><br>
    <mat-divider></mat-divider>
    <br><br>

    <div class="section dpo">
      <!-- dpo notice -->
      <div commentable
           comment-subject-codename="incidents-{{identifier}}-dpo-notice"
           comment-subject-title="[{{ref}}] {{'records.incidents.dpo-notice.placeholder' | translate}}">
        <todoable subject="incident-{{identifier}}-dpo-notice"
                  suggested-title="[{{ref}}] {{'records.incidents.dpo-notice.placeholder' | translate}}: "></todoable>
        <mat-form-field>
          <textarea matInput [(ngModel)]="record.dpoNotice"
                    [disabled]="readonly"
                    placeholder="{{'records.incidents.dpo-notice.placeholder' | translate}}"
                    (change)="update()"
                    rows="3"></textarea>
          <mat-hint>{{'records.incidents.dpo-notice.hint' | translate}}</mat-hint>
        </mat-form-field>
      </div>
    </div>
  </ng-container>

  <br><br>
  <div
    commentable
    comment-subject-codename="incident-{{incident.id}}-documents"
    comment-subject-title="{{ 'incidents.record.comment.documents' | translate }}"
    class="documents">
    <todoable subject="incident-{{incident.id}}-documents" suggested-title="{{ 'incidents.record.comment.documents' | translate }}"></todoable>

    <app-documents-container
      align="left"
      [upload-url]="uploadUrl" upload-key="document"
      [source]="documents" (documentChanged)="updateList()"></app-documents-container>
  </div>
</div>
<br><br><br>

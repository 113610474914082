import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'client-limit-warning',
  templateUrl: './client-limit-warning-dialog.component.html',
  styleUrls: ['./client-limit-warning-dialog.component.scss'],
})
export class ClientLimitWarningDialogComponent {
  dialogData: any;

  constructor(
    private dialogRef: MatDialogRef<ClientLimitWarningDialogComponent>,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) private data: any,
  ) {
    this.dialogData = data;
  }

  confirm() {
    this.dialogRef.close(true);
  }

  cancel() {
    this.dialogRef.close(false);
  }
}

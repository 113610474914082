<div class="container">
  <h1 class="title">{{ 'user.two-factor.enable.title' | translate }}</h1>

  <ng-container *ngIf="alreadyEnabled">
    <p>{{ 'user.two-factor.enable.alreadyEnabled' | translate }}</p>
    <a mat-button color="warn" routerLink="/account/two-factor/disable">{{ 'user.two-factor.enable.disable' | translate }}</a>
  </ng-container>

  <ng-container *ngIf="!alreadyEnabled">
    <ng-container *ngIf="step === 'intro'">
      <h2>{{ 'user.two-factor.enable.intro.title' | translate }}</h2>

      <div *ngIf="setupRequiredByOrg" class="org-hint">
        <p>{{ 'user.two-factor.enable.intro.orgRequiresSetup' | translate }}</p>
      </div>

      <p>{{ 'user.two-factor.enable.intro.explanation' | translate }}</p>


      <button mat-raised-button color="primary" (click)="setup()">{{ 'user.two-factor.enable.intro.action' | translate }}</button>
    </ng-container>

    <ng-container *ngIf="step === 'pair'">
      <h2>{{ 'user.two-factor.enable.pair.title' | translate }}</h2>
      <p>{{ 'user.two-factor.enable.pair.explanation' | translate }}</p>

      <qrcode [qrdata]="codeUrl" [width]="256" [errorCorrectionLevel]="'M'" [colorDark]="'#50b1ab'"></qrcode>
      <div class="alternative-input">
        <!-- <a [href]="sanitizedAppleOtp">{{ secret }}</a> -->
        {{ secret }}
      </div>

      <mat-form-field>
        <mat-label>{{ 'user.two-factor.enable.pair.code' | translate }}</mat-label>
        <input matInput placeholder="{{ 'user.two-factor.enable.pair.code.placeholder' | translate }}" [formControl]="code" inputmode="numeric" pattern="[0-9]*" autocomplete="one-time-code" />

        <mat-error *ngIf="code.hasError('wrongCode')">{{ 'login.two-factor.code.previousCodeWrong' | translate }}</mat-error>
      </mat-form-field>

      <br>

      <button mat-raised-button color="primary" (click)="verify()">{{ 'user.two-factor.enable.pair.action' | translate }}</button>
    </ng-container>

    <ng-container *ngIf="step === 'done'">
      <h2>{{ 'user.two-factor.enable.done.title' | translate }}</h2>
      <p>{{ 'user.two-factor.enable.done.explanation' | translate }}</p>

      <a *ngIf="!returnUrl" mat-raised-button color="primary" routerLink="/">{{ 'user.two-factor.enable.done.action' | translate }}</a>
      <button *ngIf="returnUrl" mat-raised-button color="primary" (click)="redirect()">{{ 'user.two-factor.enable.intro.action' | translate }}</button>
    </ng-container>
  </ng-container>
</div>

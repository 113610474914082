import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';

@Component({
  selector: 'app-locked-feature',
  templateUrl: './locked.component.html',
  styleUrls: ['./locked.component.scss']
})
export class LockedFeatureComponent implements OnInit, OnDestroy {
  featureName = 'fallback';

  constructor(
    private activatedRoute: ActivatedRoute
  ) { }

  public ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe((params: Params) => {
      this.featureName = params['feature'] || 'fallback';
    });
  }

  public ngOnDestroy(): void {}


}

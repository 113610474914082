import { DatePipe } from '@angular/common';
import {Component, Inject, OnInit} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-choose-date-dialog',
  templateUrl: './choose-date-dialog.component.html',
  styleUrls: ['./choose-date-dialog.component.scss']
})
export class ChooseDateDialogComponent implements OnInit {

  selectedDate = new FormControl(null);

  constructor(
    private dialogRef: MatDialogRef<ChooseDateDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private datePipe: DatePipe
  ) {
    this.selectedDate.setValue(this.datePipe.transform(data.date, 'yyyy-MM-dd'));
  }

  close() {
    this.dialogRef.close({ update: false });
  }

  save() {
    this.dialogRef.close({update: true, date: this.datePipe.transform(this.selectedDate.value, 'yyyy-MM-dd') });
  }

  reset() {
    this.dialogRef.close({update: true, date: null });
  }

  ngOnInit(): void {
  }

}

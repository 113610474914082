<div class="container" id="canvas" #container></div>

<div class="infobox" [ngClass]="{ collapsed: infoCollapsed }">
  <div class="inside" *ngIf="!infoCollapsed">
    <h1 *ngIf="!focused">
      {{'data-map.info.default.title' | translate}}
    </h1>
    <h1 *ngIf="focused">{{focused.name}}</h1>

    <ng-container *ngIf="focused">
      <h3 *ngIf="focused.type === 'vendor'">
      {{'data-map.info.vendor.subtitle' | translate}}
      </h3>
      <h3 *ngIf="focused.type === 'connection'">
      {{'data-map.info.connection.subtitle' | translate}}
      </h3>
      <h3 *ngIf="focused.type === 'ctrl'">
      {{'data-map.info.controller.subtitle' | translate}}
      </h3>
      <h3 *ngIf="focused.type === 'department'">
      {{'data-map.info.department.subtitle' | translate}}
      </h3>
    </ng-container>

    <p *ngIf="!focused">
      {{'data-map.info.default.hint' | translate}}
    </p>

    <p *ngIf="focused">
      <ng-container *ngIf="focused.type === 'vendor'">
        {{'data-map.info.vendor.hint' | translate}}
      </ng-container>
      <ng-container *ngIf="focused.type === 'connection'">
        {{'data-map.info.connection.hint' | translate}}
      </ng-container>
      <ng-container *ngIf="focused.type === 'ctrl'">
        {{'data-map.info.controller.hint' | translate}}
      </ng-container>
      <ng-container *ngIf="focused.type === 'department' && focused.pas.length > 0">
        {{'data-map.info.deparment.hint' | translate}}
      </ng-container>
    </p>

    <div *ngIf="focused" class="pas">
      <a class="pa" *ngFor="let pa of focused.pas" [routerLink]="'/rpa/pa-details/' + pa.paId" target="_blank">
        <div class="risk-analysis-indicator {{riskAnalysisClass(pa)}}"
          matTooltip="
          {{'risk-analysis.chart.axis.risk' | translate}}: {{ riskAnalysisRiskLabel(pa) | translate }},
          {{'risk-analysis.chart.axis.severity' | translate}}: {{ riskAnalysisSeverityLabel(pa) | translate }}"></div>
        {{pa.paName}}
      </a>
    </div>

    <ng-container *ngIf="focused && focused.type === 'department' && focused.recPas && focused.recPas.length > 0">
      <p>
        {{'data-map.info.department.hint-extra' | translate}}
      </p>
      <div class="pas">
        <a class="pa" *ngFor="let pa of focused.recPas" [routerLink]="'/rpa/pa-details/' + pa.paId" target="_blank">
          <div class="risk-analysis-indicator {{riskAnalysisClass(pa)}}"
            matTooltip="
            {{'risk-analysis.chart.axis.risk' | translate}}: {{ riskAnalysisRiskLabel(pa) | translate }},
            {{'risk-analysis.chart.axis.severity' | translate}}: {{ riskAnalysisSeverityLabel(pa) | translate }}"></div>
          {{pa.paName}}
        </a>
      </div>
    </ng-container>
    <button mat-icon-button class="close" (click)="collapseInfo()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div *ngIf="infoCollapsed">
    <button mat-icon-button (click)="downloadDataMap()">
      <mat-icon>download</mat-icon>
    </button>
    <br>
    <button mat-icon-button (click)="openInfo()">
      <mat-icon>info</mat-icon>
    </button>
  </div>
</div>
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Application, ApplicationService } from '../../../services/vendors/application.service';
import { Vendor } from '../../../services/vendors/vendors.service';
import { VendorSearchDialogComponent } from '../../rpa/pa-external-processors/vendor-search-dialog/vendor-search-dialog.component';

@Component({
  selector: 'app-add-application-dialog',
  templateUrl: './add-application-dialog.component.html',
  styleUrls: ['./add-application-dialog.component.scss']
})
export class AddApplicationDialogComponent implements OnInit {

  formGroup = new FormGroup({
    name: new FormControl('', [Validators.required]),
    vendorId: new FormControl(null, [])
  });

  selectedVendor: Vendor = null;

  constructor(
    private dialogRef: MatDialogRef<AddApplicationDialogComponent>,
    private dialog: MatDialog,
    private applicationService: ApplicationService
  ) { }

  ngOnInit(): void {
  }

  save() {
    if (this.formGroup.valid) {
      const application: Partial<Application> = { name: this.formGroup.value.name, vendorId: this.formGroup.value.vendorId };
      this.applicationService
        .createApplication(application)
        .subscribe((added) => {
          application.id = added.id;
          this.dialogRef.close(application);
        });
    }
  }

  selectVendor() {
    this.dialog.open(VendorSearchDialogComponent, { width: '480px', maxHeight: '90vh' })
      .afterClosed()
      .subscribe((vendor: Vendor) => {
        if (vendor) {
          this.selectedVendor = vendor;
          this.formGroup.controls['vendorId'].setValue(vendor.id);
        }
      });
  }

}

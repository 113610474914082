import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { BusService } from '../../services/bus.service';
import { EventsService } from '../../services/events.service';
import { StripeService, StripeCardComponent, ElementOptions, Elements, Element as StripeElement } from 'ngx-stripe';
import { PromotionCouponsService, CouponPromotion } from 'app/services/payment/promotion.coupons.service';

@Component({
  selector: 'app-coupon',
  templateUrl: './coupon.component.html',
  styleUrls: ['./coupon.component.scss']
})
export class CouponComponent implements OnInit, OnDestroy {
  elements: Elements;
  card: StripeElement;

  emailControl: FormControl = new FormControl('', [Validators.required, Validators.email]);
  cardOwnerControl: FormControl = new FormControl('', [Validators.required]);
  loading = false;

  promotion: CouponPromotion;
  loaded = false;

  cardError = '';
  cardOptions: ElementOptions = {
    style: {
      base: {
        iconColor: '#62bfba',
        lineHeight: '40px',
        fontWeight: 400,
        color: 'rgba(0, 0, 0, .87)',
        fontFamily: '"Nunito Sans", "Helvetica Neue", Helvetica, sans-serif',
        fontSize: '18px',
        '::placeholder': {
          color: 'rgba(0, 0, 0, .54)'
        }
      }
    }
  };

  constructor(
    private bus: BusService,
    private events: EventsService,
    private stripe: StripeService,
    private router: Router,
    private stripeService: StripeService,
    private route: ActivatedRoute,
    private couponService: PromotionCouponsService
  ) { }

  ngOnInit() {
    this.subscribe();

    this.route.params.subscribe(params => {
      this.bus.publish(this.events.requested.payment.promotion.info, params.campaign);
    });
  }

  ngOnDestroy() {
    this.unsubscribe();
  }

  subscribe() {
    this.bus.subscribe(this.events.received.payment.promotion.info.success, this.infoSuccessful, this);
    this.bus.subscribe(this.events.received.payment.promotion.info.failure, this.infoFailed, this);
  }

  unsubscribe() {
    this.bus.unsubscribe(this.events.received.payment.promotion.info.success, this.infoSuccessful);
    this.bus.unsubscribe(this.events.received.payment.promotion.info.failure, this.infoFailed);
  }

  infoSuccessful(info) {
    this.promotion = info;

    this.setLoaded();
  }

  setLoaded() {
    this.loaded = true;
    this.initializeStripeElement();
  }

  infoFailed(error) {
    this.router.navigate(['/not-found']);
  }

  checkout() {
    this.emailControl.markAsTouched();
    this.cardOwnerControl.markAsTouched();

    if (this.emailControl.valid && this.cardOwnerControl.valid) {
      this.loading = true;

      this.couponService.request({ email: this.emailControl.value, promotionIdentifier: this.promotion.promotionIdentifier })
        .subscribe((info) => {
          this.stripeService
            .getInstance()
            .handleCardPayment(info.paymentIntent.client_secret, this.card, {
              payment_method_data: {
                billing_details: {
                  name: this.cardOwnerControl.value,
                  email: this.emailControl.value
                }
              }
            })
            .then(result => {
              if (result.error) {
                // Show error to your customer (e.g., insufficient funds)
                this.cardError = result.error.message;
                this.loading = false;
              } else {
                // The payment has been processed!
                if (result.paymentIntent.status === 'succeeded') {
                  console.log('okay  gut');
                  this.loading = false;
                  this.router.navigate(['/coupon/thanks'], {
                    queryParams: {
                      success: true,
                      email: this.emailControl.value,
                    }
                  });
                }
              }
            });
        });
    }
  }

  cardEvent(event) {
    if (event.type === 'change') {
      if (event.event.error) { this.cardError = event.event.error.message; } else { this.cardError = ''; }
    }
  }

  private initializeStripeElement() {
    this.stripeService.elements()
      .subscribe(elements => {
        this.elements = elements;
        // Only mount the element the first time
        if (!this.card) {
          this.card = this.elements.create('card', this.cardOptions);
          this.card.mount('#card-element');
        }
      });
  }
}

<feature-access feature="logbook" centered>
  <div #itemsContainer class="contents">
    <h1 class="title">{{ title || 'events.title' | translate }}</h1>
    <p class="subtitle" *ngIf="!title">{{ 'events.subtitle' | translate }}</p>

    <div class="container">

      <!-- display filtering options/search -->

      <div *ngIf="!userFilteringDisabled" class="filters" [ngClass]="{'collapsed': collapse}">
        <!-- general search -->
        <mat-form-field>
          <input matInput type="text" placeholder="{{ 'events.filters.search.title' | translate }}"
            (input)="notifyFilterChange()" [(ngModel)]="filters.keyword" />
          <mat-hint>
            {{ 'events.filters.search.hint' | translate }}
          </mat-hint>
        </mat-form-field>

        <!-- category filter -->
        <mat-form-field>
          <mat-select placeholder="{{ 'events.filters.category.placeholder' | translate }}"
            (selectionChange)="notifyFilterChange()" [(ngModel)]="filters.category" long-select>
            <mat-option *ngFor="let category of categories" [value]="category.fullCategory"> {{ 'events.category.' +
              category.category | translate }}
            </mat-option>
          </mat-select>
          <mat-hint>
            {{ 'events.filters.category.hint' | translate }}
          </mat-hint>
        </mat-form-field>

        <!-- action filter -->
        <mat-form-field>
          <mat-select placeholder="{{ 'events.filters.action.placeholder' | translate }}"
            (selectionChange)="notifyFilterChange()" [(ngModel)]="filters.action" long-select>
            <mat-option *ngFor="let event of getEventsForCategory(filters.category)" [value]="event.fullName"> {{
              'events.identifier.' +
              event.fullName.toLowerCase() | translate }}
            </mat-option>
          </mat-select>
          <mat-hint>
            {{ 'events.filters.action.hint' | translate }}
          </mat-hint>
        </mat-form-field>

        <!-- time range -->
        <mat-form-field>
          <mat-label>{{ 'events.filters.date.placeholder' | translate }}</mat-label>
          <mat-date-range-input [formGroup]="range" [max]="maxDate" [rangePicker]="picker">
            <input matStartDate formControlName="start" (dateChange)="notifyFilterChange()">
            <input matEndDate formControlName="end" (dateChange)="notifyFilterChange()">
          </mat-date-range-input>
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-date-range-picker #picker></mat-date-range-picker>

          <mat-hint>
            {{ 'events.filters.date.hint' | translate }}
          </mat-hint>
        </mat-form-field>

        <!--
      <select-directory-user placeholder="{{'events.filters.user.description' | translate}}"
        [allowEmpty]="true"
        emptyUserTitle="{{ 'events.filters.user.emptyUser' | translate }}"
        [user]="displayableFilterUser"
        (userChange)="setFilterUser($event)">
        <mat-hint>
          {{'events.filters.user.tip' | translate}}
        </mat-hint>
      </select-directory-user>
      -->

        <!-- filter hints (for unselectable stuff) -->
        <div *ngIf="isFilteredByItem" class="filter-hint item-filter">
          <p>{{ 'events.filters.item.hint' | translate }} <span>{{ filters.item }}</span></p>
        </div>

        <br><br>
        <div class="buttons">
          <button mat-stroked-button color="primary" *ngIf="state === 'filtered'" (click)="resetFilters()">
            {{ 'rpa.pa-list.filters.reset' | translate }}
          </button>
        </div>
      </div>

      <div class="items-container">
        <div class="group-items">
          <!--
        <div class="group-items-header">
          <span class="column column-header column-1">{{ 'events.table.time' | translate }}</span>
          <span class="column column-header column-2">{{ 'events.table.event' | translate }}</span>
          <span class="column column-header column-3">{{ 'events.table.user' | translate }}</span>
          <span class="column column-header column-4"></span>
        </div>
        -->
          <div *ngIf="isFilteredAndEmpty" class="no-results">
            <p>{{ 'events.empty' | translate }}</p>
          </div>
          <div class="groups">
            <div class="group" *ngFor="let group of grouped | async">
              <p class="group-name">{{ group.groupName }}</p>

              <div class="group-events">
                <div class="event" *ngFor="let event of group.items" (click)="showDetails(event)">
                  <div class="column column-1" title="{{ event.timestampAt | date : 'dd.MM.yyyy, HH:mm:ss (z)' }}">
                    {{ event.timestampAt | date : 'HH:mm' }}
                  </div>
                  <div class="column column-2">
                    <app-event-path [event]="event" [shouldLoadEntity]="true" [canFilter]="!userFilteringDisabled"
                      (makeFilterSelection)="pathFilterSelected($event)"></app-event-path>
                  </div>
                  <div class="column column-3">
                    <button mat-button color="primary" class="username path-entry" [disabled]="filteringDisabled"
                      (click)="filterUser(event.userId, $event)"
                      matTooltip="{{'events.event-path.filter.by-user' | translate}}"
                      [matTooltipDisabled]="filteringDisabled">
                      <user-avatar [user]="getUser(event)"></user-avatar>
                      <!-- {{ getUser(event)?.fullName }} -->
                      <user-name [user]="getUser(event)"></user-name>
                    </button>
                  </div>
                  <div class="column column-4">
                    <button class="info-button" mat-icon-button
                      matTooltip="{{ 'events.item.details.tooltip' | translate }}" (click)="showDetails(event, $event)"
                      [matTooltipDisabled]="filteringDisabled">
                      <mat-icon color="accent">info</mat-icon>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="item-navigation">
          <button *ngIf="canGoBack" mat-button color="primary" (click)="previous()">{{ 'events.table.previous' |
            translate }}</button>

          <div *ngIf="!canGoBack" class="spacer"></div>

          <button *ngIf="canLoadMore" mat-button color="primary" (click)="next()">{{ 'events.table.next' | translate
            }}</button>
        </div>
      </div>
    </div>
  </div>
</feature-access>

<!-- {{ 'events.category.comments' | translate}} -->
<!-- {{ 'events.category.data' | translate}} -->
<!-- {{ 'events.category.department' | translate}} -->
<!-- {{ 'events.category.document' | translate}} -->
<!-- {{ 'events.category.dpo' | translate}} -->
<!-- {{ 'events.category.dsar' | translate}} -->
<!-- {{ 'events.category.incidents' | translate}} -->
<!-- {{ 'events.category.milestone' | translate}} -->
<!-- {{ 'events.category.payment' | translate}} -->
<!-- {{ 'events.category.rpa' | translate}} -->
<!-- {{ 'events.category.steps' | translate}} -->
<!-- {{ 'events.category.system' | translate}} -->
<!-- {{ 'events.category.template' | translate}} -->
<!-- {{ 'events.category.toms' | translate}} -->
<!-- {{ 'events.category.users' | translate}} -->
<!-- {{ 'events.category.vendors' | translate}} -->
<!-- {{ 'events.category.record' | translate}} -->
<!-- {{ 'events.category.training' | translate}} -->
<!-- {{ 'events.category.login' | translate}} -->
<!-- {{ 'events.category.recordables' | translate }} -->
<!-- {{ 'events.category.measures' | translate }} -->

<!-- {{ 'events.category.comments.short' | translate}} -->
<!-- {{ 'events.category.data.short' | translate}} -->
<!-- {{ 'events.category.department.short' | translate}} -->
<!-- {{ 'events.category.document.short' | translate}} -->
<!-- {{ 'events.category.dpo.short' | translate}} -->
<!-- {{ 'events.category.dsar.short' | translate}} -->
<!-- {{ 'events.category.incidents.short' | translate}} -->
<!-- {{ 'events.category.milestone'.short | translate}} -->
<!-- {{ 'events.category.payment.short' | translate}} -->
<!-- {{ 'events.category.rpa.short' | translate}} -->
<!-- {{ 'events.category.steps.short' | translate}} -->
<!-- {{ 'events.category.system.short' | translate}} -->
<!-- {{ 'events.category.template.short' | translate}} -->
<!-- {{ 'events.category.toms.short' | translate}} -->
<!-- {{ 'events.category.users.short' | translate}} -->
<!-- {{ 'events.category.vendors.short' | translate}} -->
<!-- {{ 'events.category.record.short' | translate}} -->
<!-- {{ 'events.category.training.short' | translate}} -->
<!-- {{ 'events.category.login.short' | translate}} -->
<!-- {{ 'events.category.recordables.short' | translate }} -->
<!-- {{ 'events.category.ticket.short' | translate }} -->
<!-- {{ 'events.category.measures.short' | translate }} -->

<!-- {{ 'events.empty' | translate}} -->
<!-- {{ 'events.identifier.controller.comments.commented' | translate}} -->
<!-- {{ 'events.identifier.controller.comments.deleted' | translate}} -->
<!-- {{ 'events.identifier.controller.data.basic_info.complete' | translate}} -->
<!-- {{ 'events.identifier.controller.data.set_goals' | translate}} -->
<!-- {{ 'events.identifier.controller.data.set_logo' | translate}} -->
<!-- {{ 'events.identifier.controller.data.delete_logo' | translate}} -->
<!-- {{ 'events.identifier.controller.data.set_name' | translate}} -->
<!-- {{ 'events.identifier.controller.data.update' | translate}} -->
<!-- {{ 'events.identifier.controller.department.add' | translate}} -->
<!-- {{ 'events.identifier.controller.department.delete' | translate}} -->
<!-- {{ 'events.identifier.controller.department.update' | translate}} -->
<!-- {{ 'events.identifier.controller.document.delete' | translate}} -->
<!-- {{ 'events.identifier.controller.document.replace_file' | translate}} -->
<!-- {{ 'events.identifier.controller.document.update_details' | translate}} -->
<!-- {{ 'events.identifier.controller.document.uploaded' | translate}} -->
<!-- {{ 'events.identifier.controller.dpo.set_appointment' | translate}} -->
<!-- {{ 'events.identifier.controller.dpo.update' | translate}} -->
<!-- {{ 'events.identifier.controller.dsar.create' | translate}} -->
<!-- {{ 'events.identifier.controller.dsar.delete' | translate}} -->
<!-- {{ 'events.identifier.controller.dsar.document.upload' | translate}} -->
<!-- {{ 'events.identifier.controller.dsar.documents.upload' | translate}} -->
<!-- {{ 'events.identifier.controller.dsar.update' | translate}} -->
<!-- {{ 'events.identifier.controller.incidents.create' | translate}} -->
<!-- {{ 'events.identifier.controller.incidents.delete' | translate}} -->
<!-- {{ 'events.identifier.controller.incidents.document.upload' | translate}} -->
<!-- {{ 'events.identifier.controller.incidents.documents.upload' | translate}} -->
<!-- {{ 'events.identifier.controller.incidents.update' | translate}} -->
<!-- {{ 'events.identifier.controller.milestone.i.complete' | translate}} -->
<!-- {{ 'events.identifier.controller.milestone.ii.complete' | translate}} -->
<!-- {{ 'events.identifier.controller.measures.update' | translate}} -->
<!-- {{ 'events.identifier.controller.measures.delete' | translate}} -->
<!-- {{ 'events.identifier.controller.measures.create' | translate}} -->
<!-- {{ 'events.identifier.controller.payment.subscription_create' | translate}} -->
<!-- {{ 'events.identifier.controller.payment.subscription_update' | translate}} -->
<!-- {{ 'events.identifier.controller.payment.update_card' | translate}} -->
<!-- {{ 'events.identifier.controller.record.create' | translate}} -->
<!-- {{ 'events.identifier.controller.record.update' | translate}} -->
<!-- {{ 'events.identifier.controller.rpa.assign' | translate}} -->
<!-- {{ 'events.identifier.controller.rpa.custom.add' | translate}} -->
<!-- {{ 'events.identifier.controller.rpa.custom.delete' | translate}} -->
<!-- {{ 'events.identifier.controller.rpa.custom.update' | translate}} -->
<!-- {{ 'events.identifier.controller.rpa.duplicate' | translate}} -->
<!-- {{ 'events.identifier.controller.rpa.ep.add' | translate}} -->
<!-- {{ 'events.identifier.controller.rpa.ep.delete' | translate}} -->
<!-- {{ 'events.identifier.controller.rpa.ip.add' | translate}} -->
<!-- {{ 'events.identifier.controller.rpa.ip.delete' | translate}} -->
<!-- {{ 'events.identifier.controller.rpa.update_field' | translate}} -->
<!-- {{ 'events.identifier.controller.rpa.update_status' | translate}} -->
<!-- {{ 'events.identifier.controller.rpa.document.upload' | translate}} -->
<!-- {{ 'events.identifier.controller.rpa.application.delete' | translate}} -->
<!-- {{ 'events.identifier.controller.steps.gdpr_applies.complete' | translate}} -->
<!-- {{ 'events.identifier.controller.steps.intro.complete' | translate}} -->
<!-- {{ 'events.identifier.controller.steps.pa_tutorial.complete' | translate}} -->
<!-- {{ 'events.identifier.controller.toms.entry.applicable_update' | translate}} -->
<!-- {{ 'events.identifier.controller.toms.entry.explanation_update' | translate}} -->
<!-- {{ 'events.identifier.controller.toms.entry.save' | translate}} -->
<!-- {{ 'events.identifier.controller.users.delete_user' | translate}} -->
<!-- {{ 'events.identifier.controller.users.changed_password' | translate}} -->
<!-- {{ 'events.identifier.controller.users.confirmed_invite' | translate}} -->
<!-- {{ 'events.identifier.controller.users.deleted_invite' | translate}} -->
<!-- {{ 'events.identifier.controller.users.invited_user' | translate}} -->
<!-- {{ 'events.identifier.controller.users.resent_invite' | translate}} -->
<!-- {{ 'events.identifier.controller.vendors.add_dpa' | translate}} -->
<!-- {{ 'events.identifier.controller.vendors.dpa_toggle_required' | translate}} -->
<!-- {{ 'events.identifier.controller.vendors.request_dpa' | translate}} -->
<!-- {{ 'events.identifier.controller.vendors.audit.create' | translate}} -->
<!-- {{ 'events.identifier.supervision.client.add_controller' | translate}} -->
<!-- {{ 'events.identifier.supervision.client.add_invite' | translate}} -->
<!-- {{ 'events.identifier.supervision.client.delete_supervision' | translate}} -->
<!-- {{ 'events.identifier.supervision.client.invitation_accepted' | translate}} -->
<!-- {{ 'events.identifier.supervision.client.resend_invitation' | translate}} -->
<!-- {{ 'events.identifier.supervision.template.apply' | translate}} -->
<!-- {{ 'events.identifier.supervision.template.create' | translate}} -->
<!-- {{ 'events.identifier.system.tracking_start' | translate}} -->
<!-- {{ 'events.identifier.user.login.successful' | translate}} -->
<!-- {{ 'events.identifier.ticket.create_ticket' | translate}} -->
<!-- {{ 'events.loadMore' | translate}} -->
<!-- {{ 'events.metadata.document.changedFile' | translate}} -->
<!-- {{ 'events.metadata.document.document' | translate}} -->
<!-- {{ 'events.metadata.document.updatedDetails' | translate}} -->
<!-- {{ 'events.metadata.document.uploaded' | translate}} -->
<!-- {{ 'events.metadata.empty' | translate}} -->
<!-- {{ 'events.metadata.loading' | translate}} -->
<!-- {{ 'events.metadata.rpa.created' | translate}} -->
<!-- {{ 'events.metadata.rpa.customPa' | translate}} -->
<!-- {{ 'events.metadata.rpa.department' | translate}} -->
<!-- {{ 'events.metadata.rpa.edited' | translate}} -->
<!-- {{ 'events.metadata.rpa.linked' | translate}} -->
<!-- {{ 'events.metadata.rpa.pa' | translate}} -->
<!-- {{ 'events.metadata.rpa.unlinked' | translate}} -->
<!-- {{ 'events.metadata.rpaProcessors.vendor' | translate}} -->
<!-- {{ 'events.metadata.toms.entry' | translate}} -->
<!-- {{ 'events.metadata.toms.updated' | translate}} -->
<!-- {{ 'events.metadata.toms.updatedApplicable' | translate}} -->
<!-- {{ 'events.metadata.toms.updatedExplanation' | translate}} -->
<!-- {{ 'events.metadata.users.acceptedInvitation' | translate}} -->
<!-- {{ 'events.metadata.users.email' | translate}} -->
<!-- {{ 'events.metadata.users.invitationSent' | translate}} -->
<!-- {{ 'events.metadata.users.resentInvitation' | translate}} -->
<!-- {{ 'events.metadata.users.user' | translate}} -->
<!-- {{ 'events.metadata.vendors.dpaAdded' | translate}} -->
<!-- {{ 'events.metadata.vendors.dpaNotRequired' | translate}} -->
<!-- {{ 'events.metadata.vendors.dpaRequested' | translate}} -->
<!-- {{ 'events.metadata.vendors.dpaRequired' | translate}} -->
<!-- {{ 'events.table.category' | translate}} -->
<!-- {{ 'events.table.eventName' | translate}} -->
<!-- {{ 'events.table.timestamp' | translate}} -->
<!-- {{ 'events.table.user' | translate}} -->
<!-- {{ 'events.title' | translate}} -->
<!-- {{ 'events.identifier.controller.training.add' | translate }} -->
<!-- {{ 'events.identifier.controller.training.delete' | translate }} -->
<!-- {{ 'events.identifier.controller.training.update' | translate }} -->
<!-- {{ 'events.identifier.controller.training.participant.add' | translate }} -->
<!-- {{ 'events.identifier.controller.training.participant.remove' | translate }} -->
<!-- {{ 'events.identifier.controller.recordables.document.upload' | translate }}-->

import { Component, OnInit, Input } from '@angular/core';
import { CouponPromotion } from 'app/services/payment/promotion.coupons.service';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'coupon-promo-new-value',
  templateUrl: './new-value.component.html',
  styleUrls: ['./new-value.component.scss']
})
export class NewValueComponent implements OnInit {

  @Input() promotion: CouponPromotion;

  constructor() { }

  ngOnInit() {
  }

}

import { Injectable } from '@angular/core';

import {HttpErrorResponse} from '@angular/common/http';
import {BusService} from '../bus.service';
import {EventsService} from '../events.service';
import {
  IGetOptions,
  IPostOptions,
  RequestService
} from '../request.service';

interface DsarUpdateModel {
  id?: number;
  title?: string;
  details?: string;
}

@Injectable()
export class DsarResourceService {

  api = {
    root: '/dsar',
    open: '/open',
    submit: '/submit',
    update: '/',
    create: '/',
    getAll: '/',
    getSingle: '/',
    delete: '/',
  };

  constructor(
    private bus: BusService,
    private events: EventsService,
    private requestService: RequestService
  ) {
    this.subscribe();
  }

  getAll() {
    return this.requestService.get<any>({
      uri: this.api.root + this.api.getAll,
      handlers: {
        success: documents => {
          this.bus.publish(this.events.received.data.dsar.all.success, documents);
        },
        error: error =>  {
          this.bus.publish(this.events.received.data.dsar.all.failure, error.error);
        },
      }
    });
  }

  getOpen() {
    return this.requestService.get<any>({
      uri: this.api.root + this.api.open,
      handlers: {
        success: () => {},
        error: () => {},
      }
    });
  }

  getSingle(id) {
    return this.requestService.get<any>({
      uri: this.api.root + this.api.getSingle + id,
      handlers: {
        success: documents => {
          this.bus.publish(this.events.received.data.dsar.single.success, documents);
        },
        error: error =>  {
          this.bus.publish(this.events.received.data.dsar.single.failure, error.error);
        },
      }
    });
  }

  create(model: DsarUpdateModel) {
    return this.requestService.post<DsarUpdateModel, any>({
      uri: this.api.root + this.api.create,
      body: model,
      handlers: {
        success: response => {
          this.bus.publish(this.events.received.action.dsar.create.success, response);
        },
        error: error =>  {
          this.bus.publish(this.events.received.action.dsar.create.failure, error.error);
        },
      }
    });
  }

  updateDsar(model: DsarUpdateModel) {
    return this.requestService.patch<DsarUpdateModel, any>({
      uri: this.api.root + this.api.update + model.id,
      body: model,
      handlers: {
        success: response => {
          this.bus.publish(this.events.received.action.dsar.update.success, response);
        },
        error: error =>  {
          this.bus.publish(this.events.received.action.dsar.update.failure, error.error);
        },
      }
    });
  }

  deleteDsar(id, deleteDocs) {
    return this.requestService.delete<any, any>({
      uri: this.api.root + this.api.delete + id,
      body: {
        deleteDocs,
      },
      handlers: {
        success: response => {
          this.bus.publish(this.events.received.action.dsar.delete.success, response);
        },
        error: error =>  {
          this.bus.publish(this.events.received.action.dsar.delete.failure, error.error);
        },
      }
    });
  }

  submitDsar(
    token: string,
    controllerId: number,
    email: string,
    type: string,
    comments: string,
    recaptcha: string,
    customFields: Record<string, string>,
  ) {
    return this.requestService.post({
      uri: this.api.root + this.api.submit,
      body: {
        token, controllerId, email, type, comments, recaptcha, customFields
      },
      handlers: {
        success: () => {},
        error: () => {},
      }
    });
  }

  subscribe() {
    this.bus.subscribe(this.events.requested.action.dsar.create, this.create.bind(this));
    this.bus.subscribe(this.events.requested.action.dsar.update, this.updateDsar.bind(this));
    this.bus.subscribe(this.events.requested.action.dsar.delete, this.deleteDsar.bind(this));
    this.bus.subscribe(this.events.requested.data.dsar.all, this.getAll.bind(this));
    this.bus.subscribe(this.events.requested.data.dsar.single, this.getSingle.bind(this));
  }
}

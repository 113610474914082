import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';

import { AppConfig } from '../../../app.config';

import { BusService } from '../../../services/bus.service';
import { EventsService } from '../../../services/events.service';

import { StripeService, Elements, Element as StripeElement, ElementsOptions } from 'ngx-stripe';
import { Subscription } from 'rxjs';

import { Upgrade3dsIssueDialogComponent } from '../upgrade-3ds-issue-dialog/upgrade-3ds-issue-dialog.component';
import { debounceTime } from 'rxjs/operators';

const FEATURE_LIST = [
  'badge_access',
  'gdpr_basics',
  'document_management',
  'logbook',
  'reminders',
  'dpia',
  'premium_support',
  'collaboration_tools',
];

export interface PaymentCoupon {
  id: string;
  percentOff?: number;
  amountOff?: number;
  valid: boolean;
  metadata: any;
  appliesToProducts?: string[];
}

@Component({
  selector: 'app-create-subscription',
  templateUrl: './create-subscription.component.html',
  styleUrls: ['./create-subscription.component.scss']
})
export class CreateSubscriptionComponent implements OnInit, OnDestroy {
  public taxRate = 0.16;

  elements: Elements;
  card: StripeElement;

  paymentForm: FormGroup;

  selectedPlanId: string;
  paymentInterval = 'year';

  stripePlans = undefined;
  coupon: PaymentCoupon = null;

  couponCodeSub: Subscription;

  subscriptionError: string;
  isLoading = false;

  isEuCountry = false;

  isIncomplete = false;

  // Existing Subscription
  currentSubscription = undefined;
  loadingSubscription = true;
  paymentPreview = undefined;

  newContact = false;

  upgrade3DSIssue = false;

  constructor(
    private bus: BusService,
    private events: EventsService,
    private formBuilder: FormBuilder,
    private stripeService: StripeService,
    private router: Router,
    private dialog: MatDialog,
  ) {
  }

  // Getters

  get isPlanSelected() {
    if (this.selectedPlanId) {
      return true;
    }
    return false;
  }

  get selectedPlan() {
    if (this.isPlanSelected) {
      return this.stripePlans.find(item => item.stripeId === this.selectedPlanId);
    }
    return null;
  }

  get currentPlan() {
    if (this.currentSubscription && this.stripePlans) {
      return this.stripePlans.find(item => item.metadata.name.toLowerCase() === this.currentSubscription.planName.toLowerCase() &&
        item.interval.toLowerCase() === this.currentSubscription.paymentInterval.toLowerCase());
    }

    return undefined;
  }

  get intervalPlans() {
    if (!this.stripePlans) {
      return null;
    }

    return this.stripePlans.filter(item => item.interval === this.paymentInterval);
  }

  get couponPrice() {
    if (!this.selectedPlan || !this.coupon) {
      return 0;
    }

    if (this.coupon.amountOff) {
      return this.selectedPlan.price - this.coupon.amountOff;
    } else {
      return this.selectedPlan.price - (this.selectedPlan.price * (this.coupon.percentOff / 100));
    }
  }

  get couponSaving() {
    if (!this.coupon || !this.selectedPlan) {
      return 0;
    }

    if (this.coupon.amountOff) {
      return this.coupon.amountOff / 100;
    } else {
      return this.selectedPlan.price / 100 * (this.coupon.percentOff / 100);
    }
  }


  get requiresTax() {
    if (this.isEuCountry) {
      const vatId = this.paymentForm.get('vatId').value;
      if (vatId && vatId.substring(0, 2).toUpperCase() !== 'DE') {
        return false;
      }
      return true;
    }

    return false;
  }

  get priceWithTax() {
    if (this.coupon) {
      return (this.couponPrice / 100) * this.taxRate;
    }

    return this.selectedPlan.price / 100 * this.taxRate;
  }

  public isSelected(stripeId: string) {
    return this.selectedPlanId === stripeId;
  }

  get inactiveSubscription() {
    if (!this.loadingSubscription) {
      if (this.currentSubscription) {
        return this.currentSubscription.subscriptionStatus === 'canceled';
      }
    }

    return false;
  }

  get alreadySubscribed() {
    if (!this.loadingSubscription) {
      if (this.currentSubscription) {
        return this.currentSubscription.subscriptionStatus === 'active';
      }
    }

    return false;
  }

  get isDowngrade() {
    if (!this.currentPlan) return true;
    if (this.alreadySubscribed) {
      if (this.selectedPlan) {

        if (this.selectedPlan.metadata.max_users < this.currentPlan.metadata.max_users) {
          return false;
        }

        if (this.selectedPlan.metadata.upgrade_rank) {
          const currentRank = this.currentPlan.metadata.upgrade_rank;

          if (!currentRank) {
            return true;
          }

          if (currentRank < this.selectedPlan.metadata.upgrade_rank) {
            return false;
          } else {
            return true;
          }
        } else {
          return false;
        }
        // return this.selectedPlan.score <= this.currentPlan.score;
      }
    }

    return false;
  }

  public isCurrentPlan(planName: string, paymentInterval: string) {
    if (this.alreadySubscribed) {
      return planName.toLowerCase() === this.currentSubscription.planName.toLowerCase() &&
        paymentInterval.toLowerCase() === this.currentSubscription.paymentInterval.toLowerCase();
    }

    return false;
  }

  ngOnInit() {
    this.subscribe();
    this.requestData();
    this.initializeForm();
  }

  ngOnDestroy() {
    // this.unsubscribe();
  }

  // Component Actions

  public selectPlan(plan: any) {
    if (this.isCurrentPlan(plan.metadata.name, plan.interval)) {
      return;
    }

    const planId = plan.stripeId;
    this.selectedPlanId = planId;

    if (this.alreadySubscribed) {
      this.paymentPreview = undefined;
      this.getPaymentPreview(planId);
    }
  }

  public changePaymentInterval() {
    if (this.isPlanSelected) {
      let newPlan = this.intervalPlans.find(plan => plan.productId === this.selectedPlan.productId);
      if (newPlan) this.selectPlan(newPlan);
    }
  }

  public checkCouponCode(code) {
    if (code && code !== '') {
      this.bus.publish(this.events.requested.payment.coupon, code);
    }

    this.coupon = null;
    this.paymentForm.controls.couponCode.setErrors(null);

    // reset error if it's a coupon error
    if (this.subscriptionError && this.subscriptionError.includes('coupon')) {
      this.subscriptionError = '';
    }
  }

  // Stripe Element Functions

  get isFreeCoupon() {
    return this.coupon && this.priceWithTax === 0;
  }

  get canUseCouponWithPlan() {
    if (!this.coupon || !this.isPlanSelected) {
      return true;
    }

    if (this.coupon.appliesToProducts) {
      return this.coupon.appliesToProducts.includes(this.selectedPlan.productId);
    }

    return true;
  }

  public checkout() {
    if (this.isFreeCoupon && this.canUseCouponWithPlan) {
      this.checkoutWithoutCard();
    } else {
      this.checkoutWithCard();
    }
  }

  checkoutWithCard() {
    const name = this.paymentForm.get('name').value;
    const vatId = this.paymentForm.get('vatId').value;
    this.isLoading = true;
    this.stripeService
      .getInstance()
      .createToken(this.card, { name })
      .then(result => {
        if (result.token) {
          const requestData = {
            stripeToken: result.token.id,
            planName: this.selectedPlanId,
            couponCode: '',
            vatId: '',
            isEuCountry: this.isEuCountry,
            existingCustomer: false
          };

          if (this.coupon) {
            requestData.couponCode = this.coupon.id;
          }

          if (vatId) {
            requestData.vatId = vatId;
          }

          this.bus.publish(this.events.requested.payment.subscribe, requestData);
        } else if (result.error) {
          // Error creating the token
          this.isLoading = false;
          this.subscriptionError = result.error.message;
        }
      })
      .catch(error => {
        // Error creating the token
        this.isLoading = false;
        this.subscriptionError = error.message;
      });
  }

  checkoutWithoutCard() {
    this.isLoading = true;
    const name = this.paymentForm.get('name').value;
    const vatId = this.paymentForm.get('vatId').value;

    const requestData = {
      stripeToken: null,
      planName: this.selectedPlanId,
      couponCode: this.coupon.id,
      vatId: '',
      isEuCountry: this.isEuCountry,
      existingCustomer: false
    };

    if (vatId) {
      requestData.vatId = vatId;
    }

    this.bus.publish(this.events.requested.payment.subscribe, requestData);
  }

  public createSubscription() {
    this.isLoading = true;
    const vatId = this.paymentForm.get('vatId').value;

    const requestData = {
      isEuCountry: this.isEuCountry,
      planName: this.selectedPlanId,
      vatId: '',
      existingCustomer: true
    };

    if (vatId) {
      requestData.vatId = vatId;
    }

    this.bus.publish(this.events.requested.payment.subscribe, requestData);
  }

  public upgradePlan() {
    if (!this.isDowngrade) {
      this.isLoading = true;
      const requestData = {
        newPlan: this.selectedPlanId
      };

      this.bus.publish(this.events.requested.payment.upgrade.upgrade, requestData);
    }
  }

  public impliedFeature(plan, feature): boolean {
    if (plan.child)
      return plan.child.metadata[feature] === plan.metadata[feature];
    else return false;
  }

  public addNewPaymentInfo() {
    this.newContact = true;
    this.initializeStripeElement();
  }

  private initializeStripeElement() {
    this.stripeService.elements()
      .subscribe(elements => {
        this.elements = elements;
        // Only mount the element the first time
        if (!this.card) {
          this.card = this.elements.create('card', {
            style: {
              base: {
                iconColor: '#62bfba',
                color: 'rgba(0, 0, 0, 0.87)',
                lineHeight: '40px',
                fontWeight: 300,
                fontFamily: '"Nunito Sans", "Helvetica Neue", Helvetica, sans-serif',
                fontSize: '18px',
                '::placeholder': {
                  color: '#62bfba'
                }
              }
            }
          });
          this.card.mount('#card-element');
        }
      });
  }

  private initializeForm() {
    this.paymentForm = this.formBuilder.group({
      name: ['', [Validators.required]],
      couponCode: [''],
      vatId: [''],
      countryCode: ['']
    });

    this.couponCodeSub = this.paymentForm.controls.couponCode.valueChanges
      .pipe(debounceTime(750))
      .subscribe(newValue => { this.checkCouponCode(newValue); });
  }

  private updatePlan(success: boolean) {
    if (!success) {
      this.initializeStripeElement();
    } else {
      this.paymentInterval = 'year'; // fix 11.03.20: as we only have yearly plans now, it would show no plans when a user is currently subscribed to a monthly plan
    }
  }

  private requestData() {
    this.bus.publish(this.events.requested.payment.plans);
    this.bus.publish(this.events.requested.payment.info.latest);
  }


  private subscribe() {
    this.bus.subscribe(this.events.received.payment.subscribe.failure, this.subscribeFailure, this);
    this.bus.subscribe(this.events.received.payment.subscribe.success, this.subscribeSuccess, this);

    this.bus.subscribe(this.events.received.payment.plans.success, this.loadPlans, this);
    this.bus.subscribe(this.events.received.payment.plans.failure, this.dataFailure, this);

    this.bus.subscribe(this.events.received.payment.coupon.success, this.couponSuccess, this);
    this.bus.subscribe(this.events.received.payment.coupon.failure, this.couponFailure, this);

    this.bus.subscribe(this.events.received.payment.info.latest.success, this.latestInfoSuccess, this);
    this.bus.subscribe(this.events.received.payment.info.latest.failure, this.latestInfoFailure, this);

    this.bus.subscribe(this.events.received.payment.upgrade.preview.success, this.paymentPreviewSuccess, this);
    this.bus.subscribe(this.events.received.payment.upgrade.preview.failure, this.paymentPreviewFailure, this);

    this.bus.subscribe(this.events.received.payment.upgrade.upgrade.success, this.paymentUpgradeSuccess, this);
    this.bus.subscribe(this.events.received.payment.upgrade.upgrade.failure, this.paymentUpgradeFailure, this);
  }

  private getPaymentPreview(newPlanName: string) {
    this.bus.publish(this.events.requested.payment.upgrade.preview, newPlanName);
  }

  // Data Actions

  private subscribeFailure(error) {
    this.isLoading = false;
    console.error(error);
    this.subscriptionError = error.error.message;
  }

  private subscribeSuccess(result) {
    this.isLoading = false;

    // additional steps required (3d-secure)
    if (result.subscriptionInfo.status === 'incomplete') {
      this.router.navigate(['/payment/finalize']);
    } else {
      localStorage.setItem('isExpired', 'false');
      localStorage.setItem('planMetadata', JSON.stringify(result.subscriptionInfo.planMetadata));
      if (this.isFreeCoupon) {
        this.router.navigate(['/payment/billing']);
      } else {
        this.router.navigate(['/payment/thank-you']);
      }
    }

    this.bus.publish(this.events.requested.payment.info.latest);
  }

  private loadPlans(data) {
    this.stripePlans = data;
    this.stripePlans.forEach(plan => {
      // the stripe metadata contains the feature information. more active features yield a higher score, good for ordering plans by score.
      plan.score = FEATURE_LIST.map(feature => plan.metadata[feature] === 'true' ? 1 : 0).reduce((x, s) => x + s, 0);

      // Angular currency pipe needs uppercase
      plan.currency = plan.currency.toUpperCase();

      if (plan.metadata.preselect === 'true' && plan.interval == this.paymentInterval) {
        this.selectPlan(plan);
      }
    });
    /*
    this.stripePlans.forEach(plan => {
      this.stripePlans.forEach(otherPlan => {
        if (plan != otherPlan && plan.productId != otherPlan.productId) {
          if (FEATURE_LIST.every(feature => otherPlan.metadata[feature] !== 'true' || plan.metadata[feature] === 'true')) {
            if (plan.child) {
              if (plan.child.score < otherPlan.score)
                plan.child = otherPlan;
            }
            else {
              plan.child = otherPlan;
            }
          }
        }
      });
    });
    */
  }


  private dataFailure(error) {
  }

  private couponSuccess(coupon) {
    this.coupon = coupon;
  }

  private couponFailure(error) {
    this.paymentForm.controls.couponCode.setErrors({ incorrect: true });
    this.subscriptionError = error.error.message;
  }

  private latestInfoSuccess(paymentInfo) {
    this.currentSubscription = paymentInfo;
    this.loadingSubscription = false;
    this.updatePlan(true);
  }

  private latestInfoFailure(error) {
    this.currentSubscription = undefined;
    this.loadingSubscription = false;
    this.updatePlan(false);
  }

  private paymentPreviewSuccess(preview) {
    this.paymentPreview = preview;
  }

  private paymentPreviewFailure(error) {
    console.error(error);
    this.paymentPreview = undefined;
  }

  private paymentUpgradeSuccess(data) {
    this.isLoading = false;
    this.router.navigate(['/payment/thank-you']);
    localStorage.setItem('isExpired', 'false');
    localStorage.setItem('planMetadata', JSON.stringify(data.subscriptionInfo.planMetadata));
    this.bus.publish(this.events.requested.payment.info.latest);
  }

  private paymentUpgradeFailure(error) {
    this.isLoading = false;
    console.log(error);
    if (error.error && error.error.upgrade_3ds_issue) {
      this.upgrade3DSIssue = true;
      //
      // UPGRADE 3DS ISSUE
      // FIX THIS LATER ON
      //
      this.dialog.open(Upgrade3dsIssueDialogComponent, {
        width: '512px',
        maxHeight: '90vh'
      });
    }

    this.subscriptionError = error.error.message;
  }
}

import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CurrencyPipe } from '@angular/common';


@Pipe({
  name: 'localizedCurrency',
  pure: false
})
export class LocalizedCurrencyPipe implements PipeTransform {
  constructor(private translateService: TranslateService, private currencyPipe: CurrencyPipe) {}

  transform(value: any, currencyCode: string = 'EUR', display: string | boolean = 'symbol', digitsInfo: string = undefined): any {
    return this.currencyPipe.transform(value, currencyCode, display, digitsInfo, this.translateService.currentLang);
  }
}

import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DsarSettings, DsarSettingsModel } from 'app/services/dsar/dsar-settings.service';
import { ClipboardService } from 'ngx-clipboard';
import { DsarLinkCopiedDialogComponent } from './dsar-link-copied-dialog/dsar-link-copied-dialog.component';
import { DsarLinkRegenConfirmDialogComponent } from './dsar-link-regen-confirm-dialog/dsar-link-regen-confirm-dialog.component';
import { DialogComponent } from 'app/components/dialog/dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { zip } from 'rxjs';
import { UserService } from '../../../services/user/user.service';

@Component({
  selector: 'app-settings-dialog',
  templateUrl: './settings-dialog.component.html',
  styleUrls: ['./settings-dialog.component.scss']
})
export class DsarSettingsDialogComponent implements OnInit {
  settings: DsarSettingsModel;

  jurisdiction: string;

  constructor(
    private service: DsarSettings,
    private dialog: MatDialog,
    private clipboard: ClipboardService,
    private translate: TranslateService,
    private userService: UserService,
  ) { }

  ngOnInit(): void {
    this.service.get().subscribe(settings => {
      this.settings = settings;
    });

    this.translate.get('dsar.link.jurisdiction').subscribe(jur => this.jurisdiction = jur);
  }

  get link() {
    let requestLink = window.location.origin +
      `/dsar/submit?ctrl=${this.settings?.controllerId}&token=${this.settings?.linkToken}&jur=${this.jurisdiction}`
    ;

    if (this.userService.currentSelectedLanguage() === 'pt') {
      requestLink += '&lang=pt';
    }

    return requestLink;
  }

  copyLink() {

    if(!this.jurisdiction) {
      const $header = this.translate.get('word.WARNING');
      const $message = this.translate.get('dsar.link.jurisdiction.select');

      return zip(
        $header,
        $message
      ).subscribe(([header, message]) => {
        this.dialog.open(DialogComponent, {
          width: '350px',
          data: {
            header,
            message
          }
        });
      });
    }

    this.clipboard.copy(this.link);
    this.dialog.open(DsarLinkCopiedDialogComponent, {
      width: '350px'
    });
  }

  renewLink() {
    this.dialog.open(DsarLinkRegenConfirmDialogComponent, {
      width: '350px'
    }).afterClosed().subscribe(confirmed => {
      if (confirmed) {
        this.service.renewLinkToken().subscribe(response => {
          this.settings.linkToken = response.token;
        });
      }
    });
  }

  update() {
    this.service.update(this.settings);
  }
}

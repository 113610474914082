import { Injectable, OnInit } from '@angular/core';
import { AuthenticationService } from '../authentication.service';
import { RequestService } from '../request.service';

@Injectable({
  providedIn: 'root'
})
export class MetricsService implements OnInit {

  constructor(
    private auth: AuthenticationService,
    private requestService: RequestService
  ) { }

  ngOnInit() {
  }

  getMetrics() {
    return this.requestService.get({
      uri: `/metrics/`
    })
  }

}
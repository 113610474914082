<mat-dialog-content>
  <ng-container *ngIf="data.tooManyUsers">
    {{'user-management.invite-errors.too-many-users.part1' | translate}}
    <a target="_blank" tabindex="-1" routerLink="/payment/billing">
      {{'word.here' | translate}}
    </a>
    {{'user-management.invite-errors.too-many-users.part2' | translate}}
  </ng-container>
  <ng-container *ngIf="data.alreadyUser">
    {{'user-management.invite-errors.already-user' | translate}}
  </ng-container>
  <ng-container *ngIf="data.alreadyInvited">
    {{'user-management.invite-errors.already-invited' | translate}}
  </ng-container>
  <ng-container *ngIf="data.clientError && !data.alreadyInvited">
    {{'supervision.clientList.error' + data.type | translate}}
  </ng-container>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button color="primary" mat-dialog-close>
    {{'word.ok' | translate}}
  </button>
</mat-dialog-actions>

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Components
import { HomeComponent } from './components/home/home.component';
import { LoginComponent } from './components/login/login.component';
import { UserExpiredComponent } from './components/user-expired/user-expired.component';
import { EmailLoginComponent } from './components/login/email/email.login.component';
import { RegistrationComponent } from './components/registration/registration.component';
import { VerificationComponent } from './components/registration/verification/verification.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { BasicInfoComponent } from './components/rpa/basic-info/basic-info.rpa.component';
import { InfoRpaComponent } from './components/rpa/info/info.rpa.component';
import { PaSelectionComponent } from './components/rpa/pa-selection/pa-selection.rpa.component';
import { CustomPaComponent } from './components/rpa/custom-pa/custom-pa.rpa.component';
import { ReportComponent } from './components/rpa/report/report.rpa.component';
import { ChangePasswordComponent } from './components/settings/change-password/change-password.settings.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { EmailNotificationComponent } from './components/settings/email-notification/email-notification.settings.component';
import { ProfilePictureComponent } from './components/settings/profile-picture/profile-picture.settings.component';
import { ImprintComponent } from './components/footer/imprint/imprint.component';
import { UnderConstructionComponent } from './components/under-construction/under-construction.component';
import { TodolistComponent } from './components/todolist/todolist.component';
import { TodosCompleteComponent } from './components/todolist/todos-complete/todos-complete.component';
import { DepartmentsComponent } from './components/organisation/department/departments.component';
import { DpGoalsComponent } from './components/organisation/dp-goals/dp-goals.component';
import { DeclareDpoComponent } from './components/organisation/declare-dpo/declare-dpo.component';
import { CreateSubscriptionComponent } from './components/payment/create-subscription/create-subscription.component';
import { ThankYouComponent } from './components/payment/thank-you/thank-you.component';
import { BillingComponent } from './components/payment/billing/billing.component';
import { UpdatePaymentDetailsComponent } from './components/payment/update-payment-details/update-payment-details.component';
// eslint-disable-next-line max-len
import { AdditionalPaymentAuthenticationComponent } from './components/payment/additional-authentication/additional-authentication.component';
import { MetricsComponent } from './components/metrics/metrics.component';

import { DsarTemplatesComponent } from './components/dsar/templates/templates.component';
import { DsarRecordsComponent } from './components/dsar/records/records.component';

// Guards
import { AuthenticationGuard } from './guards/authentication.guard';
import { NotAuthenticatedGuard } from './guards/not-authenticated.guard';
import { ProductTypeGuard } from './guards/product-type.guard';

import { ControllerComponent } from './components/organisation/controller/controller.component';
import { MilestoneOneComponent } from './components/milestones/milestone-one/milestone-one.component';
import { OrganisationComponent } from './components/organisation/organisation.component';
import { RpaComponent } from './components/rpa/rpa.component';
import { RpaTutorialComponent } from './components/rpa/tutorial/tutorial.component';
import { PaAssigningComponent } from './components/rpa/pa-assigning/pa-assigning.component';
import { ApplicableComponent } from './components/rpa/applicable/applicable.component';
import { PaDetailsComponent } from './components/rpa/pa-details/pa-details.component';
import { PaInternalProcessorsComponent } from './components/rpa/pa-internal-processors/pa-internal-processors.component';
import { PaExternalProcessorsComponent } from './components/rpa/pa-external-processors/pa-external-processors.component';
import { MilestoneTwoComponent } from './components/milestones/milestone-two/milestone-two.component';
import { OfferDpaComponent } from './components/vendors/offer-dpa/offer-dpa.component';
import { AuthTokenLoginComponent } from './components/auth-token-login/auth-token-login.component';
import { UserManagementComponent } from './components/user-management/user-management.component';
import { ConfirmInvitationComponent } from './components/user-management/confirm-invitation/confirm-invitation.component';
import { PrivacyPolicyEnComponent } from './components/footer/privacy-policy-en/privacy-policy-en.component';
import { PrivacyPolicyDeComponent } from './components/footer/privacy-policy-de/privacy-policy-de.component';
import { PrivacyPolicyPtComponent } from './components/footer/privacy-policy-pt/privacy-policy-pt.component';
import { TosPtComponent } from './components/footer/tos-pt/tos-pt.component';
import { TosDeComponent } from './components/footer/tos-de/tos-de.component';
import { TosEnComponent } from './components/footer/tos-en/tos-en.component';
import { CookiePolicyPtComponent } from './components/footer/cookie-policy-pt/cookie-policy-pt.component';
import { CookiePolicyDeComponent } from './components/footer/cookie-policy-de/cookie-policy-de.component';
import { CookiePolicyEnComponent } from './components/footer/cookie-policy-en/cookie-policy-en.component';
import { TomsComponent } from './components/toms/toms.component';
import { TomsCategoryComponent } from './components/toms/toms-category/toms-category.component';
import { GetBadgeComponent } from './components/badge/get-badge/get-badge.component';
import { BadgeInfoComponent } from './components/badge/badge-info/badge-info.component';
import { IsProcessorComponent } from './components/rpa-on-behalf/is-processor/is-processor.component';

import { SupervisionClientListComponent } from './components/supervision/client-list/client-list.component';
import { ConfirmSupervisionInvitationComponent } from './components/supervision/invitation/invitation.component';
import { SupervisionClientDetailsComponent } from './components/supervision/client-details/client-details.component';
import { SupervisorProfileComponent } from './components/supervision/profile/supervisor-profile.component';
import { WebsiteComplianceComponent } from './components/website-compliance/website-compliance.component';
import { AddClientEmailComponent } from './components/supervision/add-client/email/add-client-email.component';
import { AddClientControllerComponent } from './components/supervision/add-client/controller/add-client-controller.component';
import { DocumentsMiscListComponent } from './components/documents/misc-list/documents-misc-list.component';
import { GeneratorDashboardComponent } from './components/documents/generator/generator-dashboard.component';
import { FilesDashboardComponent } from './components/documents/files/files-dashboard.component';
import { ActionsComponent } from './components/actions/actions.component';

import { ChangelogComponent } from './components/changelog/changelog.component';
import { DsarRecordDetailsComponent } from './components/dsar/records/details/details.component';
import { IncidentTemplatesComponent } from './components/incident/templates/templates.component';
import { IncidentRecordsComponent } from './components/incident/records/records.component';
import { IncidentRecordDetailsComponent } from './components/incident/records/details/details.component';
import { ProductFeaturesGuard } from './guards/product-features.guard';
import { LockedFeatureComponent } from './components/product-features/locked-feature/locked.component';
import { EmailUnsubscribeComponent } from './components/email-unsubscribe/email-unsubscribe.component';
import { PrivacyPolicyPtBrComponent } from './components/footer/privacy-policy-pt-br/privacy-policy-pt-br.component';
import { TosPtBrComponent } from './components/footer/tos-pt-br/tos-pt-br.component';
import { CookiePolicyPtBrComponent } from './components/footer/cookie-policy-pt-br/cookie-policy-pt-br.component';

import { CouponComponent } from './components/coupon/coupon.component';
import { CouponThanksComponent } from './components/coupon/thanks/thanks.component';
import { SupervisorProfileInvitationComponent } from './components/supervision/profile/invitation/supervisor-profile-invitation.component';
import { DataMapComponent } from './components/data-map/data-map.component';
import { PaListComponent } from './components/rpa/pa-list/pa-list.component';
import { ActionsListComponent } from './components/actions/list/list.component';
import { SelfSignUpGuard } from './guards/self-signup.guard';
import { AuditFormComponent } from './components/audit/form/form.component';
import { AuditWelcomeComponent } from './components/audit/welcome/welcome.component';
import { AuditPrepareComponent } from './components/audit/prepare/prepare.component';
import { AuditCheckComponent } from './components/audit/check/check.component';
import { AuditReportComponent } from './components/audit/report/report.component';
import { WelcomeWorkflowComponent } from './components/welcome/workflow/workflow.component';
import { UserProfileComponent } from './components/user-profile/user-profile.component';
import { SupervisionExpiredComponent } from './components/supervision/supervision-expired/supervision-expired.component';
import { ClientApplicationsComponent } from './components/supervision/applications/client-applications/client-applications.component';
import { ClientApplicationsDetailComponent } from './components/supervision/applications/detail/detail.component';
import { SubmitDsarComponent } from './components/dsar/submit-dsar/submit-dsar.component';
import { StarterPasComponent } from './components/rpa/starter-pas/starter-pas.component';
import { VendorAuditComponent } from './components/vendors/audit/audit.component';
import { VendorListComponent } from './components/vendors/list/list.component';
import { TodosComponent } from './components/todos/todos.component';
import { DpiaAssistantComponent } from './components/rpa/dpia-assistant/dpia-assistant.component';
import { TodoEntryComponent } from './components/todos/todo-entry/todo-entry.component';
import { SetupTwoFactorComponent } from './components/user-profile/two-factor/setup/setup.component';
import { DisableTwoFactorComponent } from './components/user-profile/two-factor/disable/disable.component';
import { SupervisorDocumentsAdminListComponent } from './components/documents/supervisor/admin-list/admin-list.component';
import { TwoFactorGuard } from './guards/two-factor.guard';
import { VendorLibraryComponent } from './components/vendors/library/library.component';
import { ControllerApplicationListComponent } from './components/applications/controller-applications/controller-applications.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { DashboardOnlyGuard } from './guards/dashboard-only.guard';
import { SharedDocumentsComponent } from './components/documents/shared/shared.component';
import {AuditListComponent} from "./components/audit/list/list.component";
import {AuthorityComponent} from './components/organisation/authority/authority.component';
import {MeasuresListComponent} from "./components/measures/list/list.component";
import {SupervisorMeasuresComponent} from "./components/measures/supervisor-measures/supervisor-measures.component";
import {ReportIncidentComponent} from "./components/incident/report-incident/report-incident.component";

const routes: Routes = [
  {
    path: 'email-unsubscribe',
    component: EmailUnsubscribeComponent,
  },
  {
    path: 'changelog',
    component: ChangelogComponent
  },
  {
    path: 'home',
    component: HomeComponent,
    canActivate: [NotAuthenticatedGuard],
  },
  {
    path: 'profile',
    component: UserProfileComponent,
    canActivate: [AuthenticationGuard, TwoFactorGuard]
  },
  {
    path: 'audit',
    component: AuditListComponent,
    canActivate: [AuthenticationGuard, TwoFactorGuard]
  },
  {
    path: 'audit/welcome',
    component: AuditWelcomeComponent,
    canActivate: [AuthenticationGuard, TwoFactorGuard]
  },
  {
    path: 'audit/prepare',
    component: AuditPrepareComponent,
    canActivate: [AuthenticationGuard, TwoFactorGuard]
  },
  {
    path: 'audit/check',
    component: AuditCheckComponent,
    canActivate: [AuthenticationGuard, TwoFactorGuard]
  },
  {
    path: 'audit/report',
    component: AuditReportComponent,
    canActivate: [AuthenticationGuard, TwoFactorGuard]
  },
  {
    path: 'audit/:id',
    component: AuditFormComponent,
    canActivate: [AuthenticationGuard, TwoFactorGuard]
  },
  {
    path: 'dsar/templates',
    component: DsarTemplatesComponent,
    canActivate: [AuthenticationGuard, TwoFactorGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'dsar/submit',
    component: SubmitDsarComponent,
  },
  {
    path: 'dsar/records',
    component: DsarRecordsComponent,
    canActivate: [AuthenticationGuard, TwoFactorGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'dsar/records/new',
    component: DsarRecordDetailsComponent,
    canActivate: [AuthenticationGuard, TwoFactorGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'dsar/records/:id',
    component: DsarRecordDetailsComponent,
    canActivate: [AuthenticationGuard, TwoFactorGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'incidents/templates',
    component: IncidentTemplatesComponent,
    canActivate: [AuthenticationGuard, TwoFactorGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'incidents/records',
    component: IncidentRecordsComponent,
    canActivate: [AuthenticationGuard, TwoFactorGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'incidents/records/:id',
    component: IncidentRecordDetailsComponent,
    canActivate: [AuthenticationGuard, TwoFactorGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'incidents/records/create',
    component: IncidentRecordsComponent,
    canActivate: [AuthenticationGuard, TwoFactorGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'incidents/report',
    component: ReportIncidentComponent,
    canActivate: [AuthenticationGuard, TwoFactorGuard]
  },
  {
    path: 'incidents/report/:key',
    component: ReportIncidentComponent,
    canActivate: []
  },
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [NotAuthenticatedGuard],
  },
  {
    path: 'coupon/thanks',
    component: CouponThanksComponent,
  },
  {
    path: 'coupon/:campaign',
    component: CouponComponent,
  },
  {
    path: 'access-expired',
    component: UserExpiredComponent,
    // canActivate: [NotAuthenticatedGuard],
  },
  {
    path: 'email-login',
    component: EmailLoginComponent,
    canActivate: [NotAuthenticatedGuard],
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent,
    canActivate: [NotAuthenticatedGuard],
  },
  {
    path: 'registration',
    component: RegistrationComponent,
    canActivate: [NotAuthenticatedGuard, SelfSignUpGuard],
  },
  {
    path: 'verification',
    component: VerificationComponent,
    canActivate: [AuthenticationGuard, TwoFactorGuard],
  },
  {
    path: 'welcome/workflow',
    component: WelcomeWorkflowComponent,
    canActivate: [AuthenticationGuard, TwoFactorGuard],
  },
  {
    path: 'organisation',
    component: OrganisationComponent,
    canActivate: [AuthenticationGuard, TwoFactorGuard],
  },
  {
    path: 'organisation/controller',
    component: ControllerComponent,
    canActivate: [AuthenticationGuard, TwoFactorGuard],
  },
  {
    path: 'organisation/dp-goals',
    component: DpGoalsComponent,
    canActivate: [AuthenticationGuard, TwoFactorGuard],
  },
  {
    path: 'organisation/dpo',
    component: DeclareDpoComponent,
    canActivate: [AuthenticationGuard, TwoFactorGuard],
  },
  {
    path: 'organisation/authority',
    component: AuthorityComponent,
    canActivate: [AuthenticationGuard, TwoFactorGuard],
  },
  {
    path: 'metrics',
    component: MetricsComponent,
    canActivate: [AuthenticationGuard, TwoFactorGuard]
  },
  {
    path: 'milestones/one',
    component: MilestoneOneComponent,
    canActivate: [AuthenticationGuard, TwoFactorGuard],
  },
  {
    path: 'milestones/two',
    component: MilestoneTwoComponent,
    canActivate: [AuthenticationGuard, TwoFactorGuard],
  },
  {
    path: 'rpa',
    component: RpaComponent,
    canActivate: [AuthenticationGuard, TwoFactorGuard] // covers entire module
  },
  {
    path: 'rpa/tutorial',
    component: RpaTutorialComponent,
    canActivate: [AuthenticationGuard, TwoFactorGuard]
  },
  {
    path: 'rpa/basic-info',
    component: BasicInfoComponent,
    canActivate: [AuthenticationGuard, TwoFactorGuard] // covers entire module
  },
  {
    path: 'rpa/info',
    component: InfoRpaComponent,
    canActivate: [AuthenticationGuard, TwoFactorGuard] // covers entire module
  },
  {
    path: 'rpa/pa-selection',
    component: StarterPasComponent,
    canActivate: [AuthenticationGuard, TwoFactorGuard]
  },
  {
    path: 'rpa/pa-assigning',
    component: PaAssigningComponent,
    canActivate: [AuthenticationGuard, TwoFactorGuard]
  },
  {
    path: 'rpa/applicable',
    component: PaListComponent,
    canActivate: [AuthenticationGuard, TwoFactorGuard]
  },
  {
    path: 'rpa/pa-details/:paId',
    component: PaDetailsComponent,
    canActivate: [AuthenticationGuard, TwoFactorGuard]
  },
  {
    path: 'rpa/pa-internal-processors',
    component: PaInternalProcessorsComponent,
    canActivate: [AuthenticationGuard, TwoFactorGuard]
  },
  {
    path: 'rpa/pa-external-processors',
    component: PaExternalProcessorsComponent,
    canActivate: [AuthenticationGuard, TwoFactorGuard]
  },
  {
    path: 'rpa/dpia/:paId',
    component: DpiaAssistantComponent,
    canActivate: [AuthenticationGuard, TwoFactorGuard]
  },
  {
    path: 'rpa/dpia/:paId/:step',
    component: DpiaAssistantComponent,
    canActivate: [AuthenticationGuard, TwoFactorGuard]
  },
  {
    path: 'rpa/custom-pa',
    component: CustomPaComponent,
    canActivate: [AuthenticationGuard, TwoFactorGuard]
  },
  {
    path: 'generator',
    component: GeneratorDashboardComponent,
    canActivate: [AuthenticationGuard, TwoFactorGuard]
  },
  {
    path: 'files',
    component: FilesDashboardComponent,
    canActivate: [AuthenticationGuard, TwoFactorGuard]
  },
  {
    path: 'documents',
    component: SharedDocumentsComponent,
    canActivate: [AuthenticationGuard, TwoFactorGuard]
  },
  {
    path: 'measures',
    component: MeasuresListComponent,
    canActivate: [AuthenticationGuard, TwoFactorGuard]
  },
  {
    path: 'reports/data-map',
    component: DataMapComponent,
    canActivate: [AuthenticationGuard, TwoFactorGuard]
  },
  {
    path: 'rpa/report',
    component: ReportComponent,
    canActivate: [AuthenticationGuard, TwoFactorGuard] // covers entire module
  },
  {
    path: 'rpa-on-behalf/intro',
    component: IsProcessorComponent,
    canActivate: [AuthenticationGuard, TwoFactorGuard]
  },
  {
    path: 'vendors',
    component: VendorListComponent,
    canActivate: [AuthenticationGuard, TwoFactorGuard]
  },
  {
    path: 'vendors/:vendorId/audit',
    component: VendorAuditComponent,
    canActivate: [AuthenticationGuard, TwoFactorGuard]
  },
  {
    path: 'vendors/offer-dpa',
    component: OfferDpaComponent,
  },
  {
    path: 'applications',
    component: ControllerApplicationListComponent,
    canActivate: [AuthenticationGuard, TwoFactorGuard]
  },
  {
    path: 'toms',
    component: TomsComponent,
    canActivate: [AuthenticationGuard, TwoFactorGuard]
  },
  {
    path: 'toms/category/:catId',
    component: TomsCategoryComponent,
    canActivate: [AuthenticationGuard, TwoFactorGuard]
  },
  {
    path: 'organisation/departments',
    component: DepartmentsComponent,
    canActivate: [AuthenticationGuard, TwoFactorGuard] // covers entire module
  },
  {
    path: 'todolist',
    component: TodolistComponent,
    canActivate: [AuthenticationGuard, ProductTypeGuard, TwoFactorGuard, DashboardOnlyGuard],
    data: {
      productType: 'default',
      fallback: 'supervision/clients'
    }
  },
  {
    path: 'todos',
    component: TodoEntryComponent,
    canActivate: [AuthenticationGuard, TwoFactorGuard],
  },
  {
    path: 'todolist/complete',
    component: TodosCompleteComponent,
    canActivate: [AuthenticationGuard, TwoFactorGuard]
  },
  {
    path: 'change-password',
    component: ChangePasswordComponent,
    canActivate: [AuthenticationGuard] // covers entire module
  },
  {
    path: 'account/two-factor/enable',
    component: SetupTwoFactorComponent,
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'account/two-factor/disable',
    component: DisableTwoFactorComponent,
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'email-notification',
    component: EmailNotificationComponent,
    canActivate: [AuthenticationGuard, TwoFactorGuard] // covers entire module
  },
  {
    path: 'profile-pictures',
    component: ProfilePictureComponent,
    canActivate: [AuthenticationGuard, TwoFactorGuard] // covers entire module
  },
  {
    path: 'under-construction',
    component: UnderConstructionComponent,
  },
  {
    path: 'imprint',
    component: ImprintComponent,
    // canActivate: [AuthenticationGuard] // covers entire module
  },
  {
    path: 'privacy-policy/de',
    component: PrivacyPolicyDeComponent,
    // canActivate: [AuthenticationGuard] // covers entire module
  },
  {
    path: 'privacy-policy/en',
    component: PrivacyPolicyEnComponent,
    // canActivate: [AuthenticationGuard] // covers entire module
  },
  {
    path: 'privacy-policy/pt',
    component: PrivacyPolicyPtComponent,
    // canActivate: [AuthenticationGuard] // covers entire module
  },
  {
    path: 'privacy-policy/pt-BR',
    component: PrivacyPolicyPtBrComponent,
    // canActivate: [AuthenticationGuard] // covers entire module
  },
  {
    path: 'privacy-policy',
    redirectTo: 'privacy-policy/en',
  },
  {
    path: 'terms/de',
    component: TosDeComponent,
    // canActivate: [AuthenticationGuard] // covers entire module
  },
  {
    path: 'terms/en',
    component: TosEnComponent,
    // canActivate: [AuthenticationGuard] // covers entire module
  },
  {
    path: 'terms/pt',
    component: TosPtComponent,
    // canActivate: [AuthenticationGuard] // covers entire module
  },
  {
    path: 'terms/pt-BR',
    component: TosPtBrComponent,
    // canActivate: [AuthenticationGuard] // covers entire module
  },
  {
    path: 'terms',
    redirectTo: 'terms/en',
  },
  {
    path: 'cookie-policy/de',
    component: CookiePolicyDeComponent,
    // canActivate: [AuthenticationGuard] // covers entire module
  },
  {
    path: 'cookie-policy/en',
    component: CookiePolicyEnComponent,
    // canActivate: [AuthenticationGuard] // covers entire module
  },
  {
    path: 'cookie-policy/pt',
    component: CookiePolicyPtComponent,
    // canActivate: [AuthenticationGuard] // covers entire module
  },
  {
    path: 'cookie-policy/pt-BR',
    component: CookiePolicyPtBrComponent,
    // canActivate: [AuthenticationGuard] // covers entire module
  },
  {
    path: 'cookie-policy',
    redirectTo: 'cookie-policy/en',
  },
  {
    path: 'payment/subscribe',
    component: CreateSubscriptionComponent,
    canActivate: [AuthenticationGuard, TwoFactorGuard]
  },
  {
    path: 'payment/thank-you',
    component: ThankYouComponent,
    canActivate: [AuthenticationGuard, TwoFactorGuard]
  },
  {
    path: 'payment/billing',
    component: BillingComponent,
    canActivate: [AuthenticationGuard, TwoFactorGuard]
  },
  {
    path: 'payment/update-details',
    component: UpdatePaymentDetailsComponent,
    canActivate: [AuthenticationGuard, TwoFactorGuard]
  },
  {
    path: 'payment/finalize',
    component: AdditionalPaymentAuthenticationComponent,
    canActivate: [AuthenticationGuard, TwoFactorGuard]
  },
  {
    path: 'badge/get',
    component: GetBadgeComponent,
    canActivate: [AuthenticationGuard, TwoFactorGuard],
  },
  {
    path: 'badge/info/:token',
    component: BadgeInfoComponent,
  },
  {
    path: 'supervision/access-expired',
    component: SupervisionExpiredComponent,
    canActivate: [AuthenticationGuard, ProductTypeGuard, TwoFactorGuard],
    data: {
      productType: 'supervisor'
    }
  },
  {
    path: 'supervision/clients',
    component: SupervisionClientListComponent,
    canActivate: [AuthenticationGuard, ProductTypeGuard, TwoFactorGuard],
    data: {
      productType: 'supervisor'
    }
  },
  {
    path: 'supervision/client/:clientId',
    component: SupervisionClientDetailsComponent,
    canActivate: [AuthenticationGuard, ProductTypeGuard, TwoFactorGuard],
    data: {
      productType: 'supervisor'
    }
  },
  {
    path: 'supervision/group/:groupId',
    component: SupervisionClientListComponent,
    canActivate: [AuthenticationGuard, ProductTypeGuard, TwoFactorGuard],
    data: {
      productType: 'supervisor'
    }
  },
  {
    path: 'supervision/confirm-invitation',
    component: ConfirmSupervisionInvitationComponent,
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'supervision/supervisor/profile',
    component: SupervisorProfileComponent,
    canActivate: [AuthenticationGuard, ProductTypeGuard, TwoFactorGuard],
    data: {
      productType: 'supervisor'
    }
  },
  {
    path: 'supervision/documents',
    component: SupervisorDocumentsAdminListComponent,
    canActivate: [AuthenticationGuard, ProductTypeGuard, TwoFactorGuard],
    data: {
      productType: 'supervisor'
    }
  },
  {
    path: 'supervision/vendors',
    component: VendorLibraryComponent,
    canActivate: [AuthenticationGuard, ProductTypeGuard, TwoFactorGuard],
    data: {
      productType: 'supervisor'
    }
  },
  {
    path: 'supervision/applications',
    component: ClientApplicationsComponent,
    canActivate: [AuthenticationGuard, ProductTypeGuard, TwoFactorGuard],
    data: {
      productType: 'supervisor'
    }
  },
  {
    path: 'supervision/measures',
    component: SupervisorMeasuresComponent,
    canActivate: [AuthenticationGuard, ProductTypeGuard, TwoFactorGuard],
    data: {
      productType: 'supervisor'
    }
  },
  {
    path: 'supervision/applications/:applicationId',
    component: ClientApplicationsDetailComponent,
    canActivate: [AuthenticationGuard, ProductTypeGuard, TwoFactorGuard],
    data: {
      productType: 'supervisor'
    }
  },
  {
    path: 'supervision/supervisor/profile/confirm-invitation',
    component: SupervisorProfileInvitationComponent,
    canActivate: [AuthenticationGuard],
  },
  {
    path: 'user-management',
    component: UserManagementComponent,
    canActivate: [AuthenticationGuard, ProductTypeGuard, TwoFactorGuard],
    data: {
      productType: 'default'
    }
  },
  {
    path: 'confirm-invitation',
    component: ConfirmInvitationComponent,
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'website',
    component: WebsiteComplianceComponent,
    canActivate: [AuthenticationGuard, TwoFactorGuard]
  },
  {
    path: 'documents/misc',
    component: DocumentsMiscListComponent,
    canActivate: [AuthenticationGuard, ProductFeaturesGuard, TwoFactorGuard],
    data: {
      feature: 'document_management'
    }
  },
  {
    path: 'actions',
    component: ActionsListComponent,
    canActivate: [AuthenticationGuard, ProductFeaturesGuard, TwoFactorGuard],
    data: {
      feature: 'logbook'
    }
  },
  {
    path: 'locked-feature',
    component: LockedFeatureComponent,
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'auth-token-login',
    component: AuthTokenLoginComponent
  },
  {
    path: '',
    redirectTo: '/dashboard',
    pathMatch: 'full'
  },
  {
    path: 'todos',
    component: TodosComponent,
    canActivate: [AuthenticationGuard, TwoFactorGuard]
  },
  {
    path: 'todos/:id',
    component: TodosComponent,
    canActivate: [AuthenticationGuard, TwoFactorGuard]
  },
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [AuthenticationGuard, ProductTypeGuard, TwoFactorGuard],
    data: {
      productType: 'default',
      fallback: 'supervision/clients'
    }
  },
  {
    path: '**',
    component: PageNotFoundComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: false, enableTracing: false, onSameUrlNavigation: 'reload', relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
  providers: [
  ]
})
export class RoutingConfig { }

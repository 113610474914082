import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TreeNode } from 'app/services/rpa/rpa.reference.service';
import { PaReferenceTreeEditDialogComponent } from '../pa-reference-tree-edit-dialog/pa-reference-tree-edit-dialog.component';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'pa-reference-tree-node',
  templateUrl: './pa-reference-tree-node.component.html',
  styleUrls: ['./pa-reference-tree-node.component.scss']
})
export class PaReferenceTreeNodeComponent implements OnInit {

  @Input() node: TreeNode;
  @Input() tree: TreeNode[];
  @Output() picked = new EventEmitter<TreeNode>();

  expanded = false;

  constructor(
    private dialog: MatDialog,
  ) { }

  ngOnInit(): void {
  }

  edit() {
    this.dialog.open(PaReferenceTreeEditDialogComponent, {
      width: '350px',
      data: {
        node: this.node,
        tree: this.tree,
      }
    });
  }

  pick(node: TreeNode) {
    this.picked.emit(node);
  }

  add() {
    this.dialog.open(PaReferenceTreeEditDialogComponent, {
        width: '350px',
        data: {
          tree: this.tree,
          parent: this.node,
        }
    });
  }
}

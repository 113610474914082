import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { AppConfig } from '../../../app.config';

import { BusService } from '../../../services/bus.service';
import { EventsService } from '../../../services/events.service';
import { AccessLevelService, AccessLevel } from '../../../services/user/access-level.service';

import {TranslateService} from '@ngx-translate/core';
import { Crisp } from '../../../modules/crisp/wrapper';
import { getToken } from '../../../util/token';


@Component({
  selector: 'app-declare-dpo',
  templateUrl: './declare-dpo.component.html',
  styleUrls: ['./declare-dpo.component.scss']
})
export class DeclareDpoComponent implements OnInit, OnDestroy {

  @ViewChild('upload') upload;

  companyName: string;
  formGroup: FormGroup;
  fieldsBeingSaved = {};
  accessLevel: AccessLevel;

  constructor(
    private bus: BusService,
    private events: EventsService,
    private router: Router,
    private accessService: AccessLevelService,
    private crisp: Crisp,
    private translate: TranslateService
  ) {
    this.formGroup = new FormGroup({
      name: new FormControl('', [Validators.required]),
      addressStreet: new FormControl('', [Validators.required]),
      addressPostcode: new FormControl('', [Validators.required]),
      addressCity: new FormControl('', [Validators.required]),
      addressCountry: new FormControl('', [Validators.required]),
      telephoneNumber: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required, Validators.email]),
      reportedToAuthority: new FormControl(false, []),
    });

    this.accessService.checkAccess({
      context: 'dpo',
    }).subscribe(response => this.accessLevel = response);
  }

  ngOnInit() {
    this.subscribe();
    this.bus.publish(this.events.requested.data.controller.name);
    this.bus.publish(this.events.requested.data.dpo.hasAppointment);
    this.bus.publish(this.events.requested.data.dpo.get);
  }

  ngOnDestroy() {
    this.unsubscribe();
  }

  public updateCompanyName(data) {
    if (data.controllerName) {
      this.companyName = data.controllerName;
    }
  }

  public progress(progress) {
    this.bus.publish(this.events.requested.action.tasks.progressControllerTask, {
      progress: progress,
      workflowCodename: 'path_to_gdpr',
      taskCodename: 'ptg_dpo',
    });
  }

  public get docs() {
    return `assets/documents/${localStorage['lang'] || 'en'}`;
  }

  public done() {
    if (this.formGroup.valid) {
      // EE-2050: Make sure that noDpo is reset once a DPO has been set
      this.bus.publish(this.events.requested.data.dpo.put, { ...this.formGroup.value, noDpo: false });
      this.router.navigate([""]);
    }
  }

  public dontAppoint() {
    // EE-2050: Save explicit choice of not wanting to appoint a DPO
    this.bus.publish(this.events.requested.data.dpo.put, { ...this.formGroup.value, noDpo: true });
    this.progress(1);
    this.router.navigate([""]);
  }

  public get canWrite() {
    return this.accessLevel && this.accessLevel.write;
  }

  updateAppointmentDoc(data) {
    if (data.hasAppointmentDoc) {
      this.upload.load(
        `${AppConfig.apiUrl}/dpo/appointment/`+
        `?token=${getToken()}`);
    }
  }

  updateDpoInfo(data) {
    this.formGroup.patchValue(data);
  }

  submit(field?: string) {
    if (field) {
      this.fieldsBeingSaved[field] = true;
      setTimeout(() => {
        this.fieldsBeingSaved[field] = false;
      }, 1000);
    }

    // EE-2050: Make sure that noDpo is reset once a DPO has been set
    this.bus.publish(this.events.requested.data.dpo.put, { ...this.formGroup.value, noDpo: false });
  }

  subscribe() {
    this.bus.subscribe(this.events.received.data.controller.name.success, this.updateCompanyName, this);
    this.bus.subscribe(this.events.received.data.dpo.hasAppointment.success, this.updateAppointmentDoc, this);
    this.bus.subscribe(this.events.received.data.dpo.get.success, this.updateDpoInfo, this);
  }

  unsubscribe() {
    this.bus.unsubscribe(this.events.received.data.controller.name.success, this.updateCompanyName);
    this.bus.unsubscribe(this.events.received.data.dpo.hasAppointment.success, this.updateAppointmentDoc);
    this.bus.unsubscribe(this.events.received.data.dpo.get.success, this.updateDpoInfo);
  }

  public notifyCrispForDocumentCheck(): void {
    this.translate.get('organisation.dpo.slide2.actions.intercomMessage').subscribe(translated => {
      this.notifyCrisp(translated);
    });
  }

  private notifyCrisp(input: string): void{
    this.crisp.startWithMessage(input);
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'display-jurisdiction',
  templateUrl: './display-jurisdiction.component.html',
  styleUrls: ['./display-jurisdiction.component.scss']
})
export class DisplayJurisdictionComponent implements OnInit {

  @Input() jurisdiction: string;

  currentLanguage = '';

  constructor(
    private translate: TranslateService
  ) {
    this.currentLanguage = this.translate.currentLang || this.translate.defaultLang;

    // EC-2870: we need to observe to language changes as currentLang can still be undefined when the component is initialized
    this.translate.onLangChange.subscribe((_) => {
      this.currentLanguage = this.translate.currentLang || this.translate.defaultLang;
    });
   }

  ngOnInit(): void {
  }

  /**
   * Right now, we're basing the jurisdiction switch on the user's language,
   * which is fine for now, but may not be in the future. Keep that in mind.
   */
  get show() {
    const jurisdictions = this.jurisdiction.split(',');

    let applies = false;

    if (jurisdictions.includes('ndpr')) {
      applies = applies || this.currentLanguage === 'en-NG';
    }

    if (jurisdictions.includes('lgpd')) {
      applies = applies || this.currentLanguage === 'pt-BR';
    }

    if (jurisdictions.includes('gdpr')) {
      applies = applies || ['de', 'en', 'pt'].includes(this.currentLanguage);
    }

    return applies;
  }

}

<h1 mat-dialog-title>
    {{ (identifier ? identifier + ': ' : '') }} {{ 'events.identifier.' + event.category.toLowerCase() + '.' +
    event.eventName.toLowerCase() | translate }}
</h1>
  
<mat-dialog-content>
    <div class="event-metadata">
        <div class="category field"><label>{{ 'events.details.category' | translate }}</label> <span>{{ 'events.category.' + category | translate }}</span></div>
        <div class="timestamp field"><label>{{ 'events.details.timestamp' | translate }}</label> <span>{{ event.timestampAt | localizedDate : 'short' }}</span></div>
        <div class="eventuser field"><label>{{ 'events.details.user' | translate }}</label> 
        <span><user-name [user]="user"></user-name></span></div>
    </div>

    <div *ngIf="hasUsableMetadata" class="tracked-changes">
        <h4 class="title">{{ 'events.details.change.title' | translate }}</h4>
        <div class="changes" *ngFor="let change of event.metadata.changes">
            <div class="field">
                <label>{{ 'events.details.change.fieldName' | translate }}</label>
                <span>{{ change.fieldName }}</span>
            </div>
            <div class="value old-value">
                <div><label>{{ 'events.details.change.oldValue' | translate }}</label></div>
                <pre class="changed-value">{{ jsonPretty(change.oldValue) }}</pre>
            </div>
            <div class="value new-value">
                <div><label>{{ 'events.details.change.newValue' | translate }}</label></div>
                <pre class="changed-value">{{ jsonPretty(change.newValue) }}</pre>
            </div>
        </div>
    </div>
</mat-dialog-content>
  
<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>
        {{'word.close' | translate}}
    </button>
</mat-dialog-actions>
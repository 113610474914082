<div class="container">
  <h1 *ngIf="showTitle" class="title">{{ 'documents.sharedDocuments.title' | translate }}</h1>
  <p *ngIf="showTitle">{{ 'documents.sharedDocuments.explanation' | translate }}</p>

  <mat-tab-group mat-stretch-tabs class="example-stretched-tabs">

    <mat-tab label="{{'documents.sharedDocuments.switch.basketDocuments' | translate }}"> 
      <app-documents-container align="left" [source]="sharedDocuments"
        [readonly]="true" [searchable]="true" [highlightedDocument]="highlighted" [design]="'fluid'" [showPath]="false"
        emptyHint="{{ 'documents.sharedDocuments.empty' | translate }}" (documentDownloaded)="downloaded($event)"
        (documentChanged)="update()"></app-documents-container>
    </mat-tab>

    <mat-tab label="{{'documents.sharedDocuments.switch.referenceDocuments' | translate }}">
      <app-documents-container align="left" [source]="supervisorDocuments" [readonly]="true"
        [searchable]="true" [highlightedDocument]="highlighted" [design]="'fluid'" [showPath]="false"
        emptyHint="{{ 'documents.sharedDocuments.empty' | translate }}" (documentDownloaded)="downloaded($event)"
        (documentChanged)="update()"></app-documents-container>
    </mat-tab>
  </mat-tab-group>
</div>
import { Component, EventEmitter, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { zip } from 'rxjs';
import { ClientManagementService } from '../../../services/supervision/client-management.service';
import { SupervisionTemplatesBackendService } from '../../../services/supervision/templates-backend.service';
import { SupervisionTemplatesService } from '../../../services/supervision/templates.service';
import { ApplyingTemplateEvent, TemplateAppliedEvent, TemplatingEvent } from '../templates/events';
import { TemplateInfoDialogComponent } from '../templates/template-info-dialog/template-info-dialog.component';
import { Template } from '../templates/types';

@Component({
  selector: 'app-add-demo-client',
  templateUrl: './add-demo-client.component.html',
  styleUrls: ['./add-demo-client.component.scss']
})
export class AddDemoClientComponent implements OnInit {

  templates: Template[] = [];

  currentLanguage: string;

  pending = false;

  pendingTemplates = true;

  constructor(
    private dialogRef: MatDialogRef<AddDemoClientComponent>,
    private dialog: MatDialog,
    private templateService: SupervisionTemplatesBackendService,
    private templateApplicationService: SupervisionTemplatesService,
    private clientManagementService: ClientManagementService,
    private translateService: TranslateService
  ) {
    this.currentLanguage = this.translateService.currentLang || this.translateService.defaultLang;

    this.templateService.getGlobal().subscribe((globalTemplates) => {
      this.pendingTemplates = false;
      this.templates = globalTemplates
        .filter(t => t.globalAccessSettings.for === 'demo'
          && this.forJurisdiction(t.globalAccessSettings.legislation)
          && this.currentLanguage.startsWith(t.globalAccessSettings.language));
    });
  }

  ngOnInit(): void {
  }

  createDemoClient(template: Template) {
    if (this.pending) {
      return;
    }

    this.pending = true;

    // first, we need to create the client for this
    const emitter = new EventEmitter<TemplatingEvent>();

    const event = new ApplyingTemplateEvent({ controllerName: template.name, _id: null }, template, null);

    emitter.emit(event);
    this.dialog.open(TemplateInfoDialogComponent, {
      width: '350px',
      maxHeight: '90vh',
      data: { event, emitter }
    }).afterClosed().subscribe((_) => {
      this.dialogRef.close(true);
    });

    this.clientManagementService.addClient({ controllerName: template.name, clientId: '' })
      .subscribe((client) => {
        if (client) {
          // then, we will apply the template to the newly created client
          this.templateService.apply(template.id, client.clientId, {
            departments: true,
            dpo: true,
            rpas: true,
            toms: true,
            companyInfo: true,
            dsars: true,
            incidents: true,
            vendors: true,
          }).subscribe(() => {
              const appliedEvent = new TemplateAppliedEvent(event);

              setTimeout(() => {
                emitter.emit(appliedEvent);
              }, 1000);
            }, (error) => {
              console.error(error);
              this.pending = false;
            });
        }
      });
  }

  private forJurisdiction(jurisdiction: string) {
    if (jurisdiction === 'all') {
      return true;
    }

    if (jurisdiction === 'ndpr') {
      return this.currentLanguage === 'en-NG';
    }

    if (jurisdiction === 'lgpd') {
      return this.currentLanguage === 'pt-BR';
    }

    if (jurisdiction === 'gdpr') {
      return ['de', 'en', 'pt'].includes(this.currentLanguage);
    }

    return true;
  }
}

<h1 mat-dialog-title>{{ 'user.profile.updateEmail.title' | translate }}</h1>

<mat-dialog-content>
  <mat-form-field>
    <input
      matInput
      placeholder="{{ 'user.profile.updateEmail.newEmailAddress' | translate }}"
      [formControl]="newEmailAddress"
      required
    />
    <mat-hint>
      {{ 'user.profile.updateEmail.hint' | translate }}
    </mat-hint>
    <mat-error *ngIf="newEmailAddress.invalid">
      {{ 'helper.pleaseEnterValidEmail' | translate }}
    </mat-error>
  </mat-form-field>

  <mat-form-field>
    <input
      matInput
      placeholder="{{ 'user.profile.updateEmail.verify.newEmailAddress' | translate }}"
      [formControl]="newEmailAddressVerification"
      required
    />
    <mat-error *ngIf="newEmailAddress.invalid || newEmailAddress.value !== newEmailAddressVerification.value">
      {{ 'user.profile.updateEmail.verify.invalid' | translate }}
    </mat-error>
  </mat-form-field>

  <div *ngIf="updated">
    {{ 'user.profile.updateEmail.success' | translate }}
  </div>

  <div *ngIf="failed">
    {{ 'user.profile.updateEmail.error' | translate }}
  </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button *ngIf="!updated" mat-button color="error" (click)="close()">
    {{ 'word.cancel' | translate }}
  </button>
  <button *ngIf="!updated"
    mat-button
    color="primary"
    (click)="submit()"
    [disabled]="!canSave()"
  >
    {{ 'word.save' | translate }}
  </button>
  <button *ngIf="updated" mat-button color="primary" (click)="reload()">{{ 'word.ok' | translate }}</button>
</mat-dialog-actions>

<mat-form-field>
  <input matInput placeholder="{{'supervision.clientDetails.controller.nextMeeting.title' | translate}}"
         required [formControl]="meetingTitle">
  <mat-hint>{{'supervision.clientDetails.controller.nextMeeting.title.hint' | translate}}</mat-hint>
</mat-form-field>

<mat-form-field>
  <input matInput placeholder="{{'supervision.clientDetails.controller.nextMeeting.placeholder' | translate}}"
         [matDatepicker]="picker"
         [formControl]="meetingDate">
<!--         [(ngModel)]="controller.nextMeetingNew.date">-->
  <mat-datepicker  #picker></mat-datepicker>
  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
  <mat-hint>
    {{'supervision.clientDetails.controller.nextMeeting.hint' | translate}}
  </mat-hint>
</mat-form-field>

<div flexLayout="row" fxLayoutGap="16px">
  <div fxFlex="47.5%">
    <mat-form-field>
      <input matInput
        [formControl]="meetingHour"
        type="number"
        min="0"
        max="24"
        placeholder="{{'supervision.clientDetails.controller.nextMeeting.hours.placeholder' | translate}}"/>
      <mat-hint>
        {{'supervision.clientDetails.controller.nextMeeting.hours.hint' | translate}}
      </mat-hint>
    </mat-form-field>
  </div>
  <div fxFlex="5%" style="display: flex; align-items: center; justify-content: center; font-weight: bold; font-size: 24px;">:</div>
  <div fxFlex="47.5%">
    <mat-form-field>
      <input matInput
        [formControl]="meetingMinute"
        type="number"
        min="0"
        max="60"
        placeholder="{{'supervision.clientDetails.controller.nextMeeting.minutes.placeholder' | translate}}"/>
      <mat-hint>
        {{'supervision.clientDetails.controller.nextMeeting.minutes.hint' | translate}}
      </mat-hint>
    </mat-form-field>
  </div>
</div>

<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>
    {{'word.cancel' | translate}}
  </button>
  <button [disabled]="false" mat-flat-button color="primary" (click)="submit()">
    {{'word.save' | translate}}
  </button>
</mat-dialog-actions>

import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AppConfig } from 'app/app.config';
import { BusService } from 'app/services/bus.service';
import { EventsService } from 'app/services/events.service';
import { getToken } from '../../../util/token';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'audit-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss']
})
export class AuditWelcomeComponent implements OnInit, OnDestroy {
  @ViewChild('upload') upload;

  public filePicked = false;
  public uploading = false;
  public uploadProgress = 0;

  companyName = new FormControl('', [Validators.required]);

  constructor(
    private bus: BusService,
    private events: EventsService,
    private router: Router,
  ) {}

  ngOnInit() {
    this.subscribe();
    this.bus.publish(this.events.requested.data.controller.name);
    this.bus.publish(this.events.requested.data.controller.hasLogo);
  }

  ngOnDestroy() {
    this.unsubscribe();
  }

  logoUpdated() {
    this.bus.publish(this.events.notified.user.logoUpdate);
  }

  public submit() {
    if (this.companyName.valid) {
      // EE-1755: We don't need to update the controller name in case it hasn't changed.
      if (this.companyName.dirty) {
        this.bus.publish(this.events.requested.data.controller.updateName, {
          controllerName: this.companyName.value,
        });
      }

      if (this.upload.filePicked && this.upload.uploadProgress === 1) {
        this.progress(1);
      } else {
        this.progress(.8);
      }

      this.router.navigate(['']);
    }
  }

  public updateCompanyName(data) {
    if (data.controllerName) {
      this.companyName.setValue(data.controllerName);
    }
  }

  public updateCompanyLogo(data) {
    if (data.hasLogo) {
      this.upload.load(
        `${AppConfig.apiUrl}/controllers/logo/` +
        `?token=${getToken()}`);
    }
  }

  public progress(progress) {
    this.bus.publish(this.events.requested.action.tasks.progressControllerTask, {
      progress,
      workflowCodename: 'audit',
      taskCodename: 'ad_intro',
    });
  }

  subscribe() {
    this.bus.subscribe(this.events.received.data.controller.name.success, this.updateCompanyName, this);
    this.bus.subscribe(this.events.received.data.controller.hasLogo.success, this.updateCompanyLogo, this);
  }

  unsubscribe() {
    this.bus.unsubscribe(this.events.received.data.controller.name.success, this.updateCompanyName);
    this.bus.unsubscribe(this.events.received.data.controller.hasLogo.success, this.updateCompanyLogo);
  }
}

<div class="contents">
  <br><br><br><br>
  <div>
    <h1 class="title">{{ 'payment.billing.title' | translate }}</h1>

    <br />

    <div class="card-update-success" *ngIf="showCardUpdateSuccess">
      {{ 'payment.billing.cardUpdate.success' | translate }}
    </div>

    <br />

    <div *ngIf="isIncomplete" class="incomplete-payment">
      <mat-card>
        <mat-card-content>
          <p>{{ 'payment.billing.incompletePayment' | translate }}</p>
        </mat-card-content>
        <mat-card-actions align="end">
          <a routerLink="/payment/finalize" mat-button color="primary" class="finalize-payment">
            {{ 'payment.header-authenticate.finish' | translate }}
          </a>
        </mat-card-actions>
      </mat-card>
    </div>

    <ng-container *ngIf="latestPaymentInfo">
      <ng-container *ngIf="isStripeSubscription && subscriptionDetails">
        <div *ngIf="isSubscriptionActive" class="latest-active">
          <p>
            {{ 'payment.billing.current.active.message' | translate }}
          </p>
          <div class="plan-info">
            <div class="plan-info-item">
              <span class="label">{{ 'payment.billing.current.plan.name' | translate }}</span>
              <span class="value">{{ latestPaymentInfo.planName | translate }}</span>
            </div>
            <mat-divider></mat-divider>
            <div class="plan-info-item">
              <span class="label">{{ 'payment.billing.current.plan.cycle.start' | translate }}</span>
              <span class="value">{{  latestPaymentInfo.periodStart | localizedDate }}</span>
            </div>

            <!-- Only show payment-relevant information for actually paying customers -->
            <ng-container *ngIf="!isLifetimeFree">
              <mat-divider></mat-divider>
              <div class="plan-info-item">
                <span class="label">{{ 'payment.billing.current.plan.nextInvoice' | translate }}</span>
                <span class="value">{{ latestPaymentInfo.periodEnd | localizedDate }}</span>
              </div>
              <mat-divider></mat-divider>
              <div class="plan-info-item">
                <span class="label">{{ 'payment.billing.current.plan.upcomingAmountDue' | translate }}</span>
                <span
                  class="value">{{ subscriptionDetails.upcomingAmountDue / 100 | localizedCurrency : subscriptionDetails.planCurrency : 'symbol' : '1.2-2' }}</span>
              </div>
              <mat-divider></mat-divider>
              <div class="plan-info-item">
                <span class="label">{{ 'payment.billing.current.plan.cycle.interval' | translate }}</span>
                <span
                  class="value">{{ 'payment.create-subscription.plan.card.interval.' + subscriptionDetails.planInterval | translate }}</span>
              </div>
              <mat-divider></mat-divider>
              <div class="plan-info-item">
                <span class="label">{{ 'payment.billing.current.plan.payingUser' | translate }}</span>
                <span class="value">
                  <span class="payer-name">
                    {{ latestPaymentInfo.firstName }} {{ latestPaymentInfo.lastName }}
                  </span>
                  <span class="payer-email">
                    {{ latestPaymentInfo.email }}
                  </span>
                </span>
              </div>
              <mat-divider></mat-divider>
            </ng-container>
          </div>

          <div *ngIf="isLifetimeFree" class="lifetime-free-info">
            <p>{{ 'payment.billing.current.lifetimeFree.message' | translate }}</p>
          </div>

          <br><br>

          <div class="buttons">
            <a (click)="cancelMessage()" mat-button color="warn" class="cancel-plan">
              {{ 'payment.billing.current.cancel' | translate }}
            </a>
            <a routerLink="/payment/update-details" mat-button color="primary" class="update-details">
              {{ 'payment.billing.current.updateDetails' | translate }}
            </a>
            <a routerLink="/payment/subscribe" mat-button color="primary" class="upgrade-plan">
              {{ 'payment.billing.current.upgrade' | translate }}
            </a>
          </div>
        </div>

        <div *ngIf="!isSubscriptionActive" class="latest-inactive">
          <p>
            {{ 'payment.billing.current.inactive.message' | translate }}
          </p>
          <div class="plan-info">
            <div class="plan-info-item">
              <span class="label">{{ 'payment.billing.current.plan.name' | translate }}</span>
              <span class="value">{{ latestPaymentInfo.planName | translate }}</span>
            </div>
            <mat-divider></mat-divider>
            <!-- somehow, the date pipe isn't formatting our date
            <div *ngIf="subscriptionDetails.canceledAt" class="plan-info-item">
              <span class="label">{{ 'payment.billing.current.plan.cycle.canceled' | translate }}</span><span class="value">{{ subscriptionDetails.canceledAt | date }}</span>
            </div>
            -->
          </div>

          <br><br>

          <div class="buttons">
            <a routerLink="/payment/update-details" mat-button color="primary" class="update-details">
              {{ 'payment.billing.current.updateDetails' | translate }}
            </a>
            <a routerLink="/payment/subscribe" mat-raised-button color="primary" class="subscribe">
              {{ 'user-expired.subscribe' | translate }}
            </a>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="!isStripeSubscription">
        <p>
          {{ 'payment.billing.current.otherPaymentType' | translate }}
        </p>
        <div class="buttons">
          <a (click)="contactSupport()" mat-button color="primary" class="cancel-plan">
            {{ 'payment.billing.current.contactSupport' | translate }}
          </a>
        </div>
      </ng-container>
    </ng-container>

    <div *ngIf="!latestPaymentInfo" class="no-subscription">
      <p>
        {{ 'payment.billing.notSubscribed.message' | translate }}
      </p>
      <br>
      <mat-divider></mat-divider>
      <br><br>
      <a routerLink="/payment/subscribe" mat-raised-button color="primary" class="subscribe">
        {{ 'user-expired.subscribe' | translate }}
      </a>
    </div>

  </div>
</div>

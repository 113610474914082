import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '../../../services/authentication.service';
import { ClientSupervisionService } from '../../../services/supervision/client-supervision.service';
import { SupervisionClientTokenService } from '../../../services/supervision/client-token.service';
import { TodoService } from '../../../services/todos/todo.service';
import { Todo } from '../todo.interface';

@Component({
  selector: 'app-todo-entry',
  templateUrl: './todo-entry.component.html',
  styleUrls: ['./todo-entry.component.css']
})
export class TodoEntryComponent implements OnInit {

  constructor(
    private todos: TodoService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private supervision: ClientSupervisionService,
    private clientToken: SupervisionClientTokenService,
    private auth: AuthenticationService
  ) {
    this.activatedRoute.queryParams.subscribe(params => {
      this.todos.getSingle(params.todo, true).subscribe(todo => {
        this.handleTodoNavigation(todo);
      }, (error) => {
        // user has no access
        this.router.navigate(['/not-found']);
      });
    });
  }

  /**
   * Handles the various cases of the todo navigation.
   * As the user (supervisor) can be inside a different client, for example, they need to be redirected to the correct client.
   */
  handleTodoNavigation(todo: Todo) {
    // 1. check if user is not a supervisor
    // --> if user is not a supervisor, just redirect to todolist
    // 2. if user is supervisor, check if in supervision mode
    // --> a. if todo is from client, navigate to todolist
    // --> b. if todo is from another client / not supervision: end supervision, supervise client and navigate to todolist

    if (this.supervision.isInSupervisionMode) {
      // eslint-disable-next-line eqeqeq
      if (this.auth.controllerId != todo.controllerId) {
        console.log('not in correct client');

        this.supervision.endSupervision();

        console.log('loading client');
        this.clientToken.getClientToken(todo.controllerId)
          .subscribe((response) => {
            this.supervision.startSupervision(response.token, `/todolist?todo=${todo.id}`);
          });
      } else {
        this.router.navigate(['/todolist'], { queryParams: { todo: todo.id } });
      }
    } else if (this.auth.productType === 'supervisor') {
      this.clientToken.getClientToken(todo.controllerId)
          .subscribe((response) => {
            this.supervision.startSupervision(response.token, `/todolist?todo=${todo.id}`);
          });
    } else {
      this.router.navigate(['/todolist'], { queryParams: { todo: todo.id } });
    }
  }

  ngOnInit(): void {
  }

}

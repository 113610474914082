<div class="registration">
  <h1 class="title">{{ 'registration.registration' | translate }}</h1>
  <br/>
  <!-- <p *ngIf="!inviteToken && !coSupervisionInviteToken">
    {{'registration.message' | translate}}
  </p> -->
  <p *ngIf="inviteToken && inviteData && !inviteData.department">
    {{ 'registration.inviteMsg.part1' | translate }}
    <a>{{inviteData.controller.controllerName}}</a>
    {{'registration.inviteMsg.part2' | translate}}
  </p>
  <p *ngIf="inviteToken && inviteData && inviteData.department">
    {{ 'registration.inviteMsg.withDep.part1' | translate }}
    <a>{{inviteData.controller.controllerName}}</a>
    {{ 'registration.inviteMsg.withDep.part2' | translate }}
    <a>{{inviteData.department.name}}</a>
    {{ 'registration.inviteMsg.withDep.part3' | translate }}
  </p>

  <p *ngIf="inviteToken && !inviteData" class="warning">
    <span>
      {{ 'word.WARNING' | translate }}
      <!--
      WARNING!
      -->
    </span>
    <br>
    {{'registration.badInviteMsg' | translate}}
  </p>
  <br/><br/>
  <mat-card *ngIf="state=='initial'">
    <img mat-card-image src="assets/registration.svg"/>
    <form name="form" [formGroup]="form.formGroup" novalidate>
      <div class="form-group">
           <mat-form-field class="form-field">
             <input matInput placeholder="{{ 'registration.firstname' | translate}}" type="text" class="form-control" name="firstName" formControlName="firstName" required autofocus/>
             <mat-error>{{ 'registration.firstnamerequ' | translate }}</mat-error>
           </mat-form-field>
      </div>
      <div class="form-group">
          <mat-form-field class="form-field">
            <input matInput placeholder="{{ 'registration.lastname' | translate}}" type="text" class="form-control" name="lastName" formControlName="lastName" required />
            <mat-error>{{ 'registration.lastnamerequ' | translate }}</mat-error>
          </mat-form-field>
      </div>
      <div class="form-group">
          <mat-form-field class="form-field">
            <input matInput
              placeholder="{{ 'registration.company' | translate}}"
              [readonly]="inviteData || (coSupervisionInvite && coSupervisionInvite.controller)"
              type="text"
              class="form-control"
              name="company"
              formControlName="company"
              required />
            <mat-hint *ngIf="inviteData">
              {{'registration.filledByInvitation' | translate}}
            </mat-hint>
            <mat-error>{{ 'registration.companyrequ' | translate }}</mat-error>
          </mat-form-field>
      </div>
      <div class="form-group">
          <mat-form-field class="form-field">
            <input matInput
              placeholder="{{ 'registration.password' | translate}}"
              type="password"
              class="form-control"
              name="password"
              formControlName="password"
              required />
            <mat-error *ngIf="form.getControl('password').hasError('minlength') && !form.getControl('password').hasError('required')">Password must has more than 8 characters</mat-error>
            <mat-error *ngIf="form.getControl('password').hasError('required')">{{ 'registration.pwrequ' | translate }}</mat-error>
          </mat-form-field>
      </div>
      <div class="form-group">
          <mat-form-field class="form-field">
            <input matInput
              placeholder="{{ 'registration.email' | translate}}"
              [readonly]="inviteData || clientInviteSupervisor || coSupervisionInvite || registrationPayload"
              type="text"
              class="form-control"
              name="email"
              formControlName="email"
              required />
            <mat-hint *ngIf="inviteData">
              {{'registration.filledByInvitation' | translate}}
            </mat-hint>
            <mat-error *ngIf="form.getControl('email').hasError('required')">{{ 'registration.emailrequ' | translate }}</mat-error>
            <mat-error *ngIf="form.getControl('email').hasError('email') && !form.getControl('email').hasError('required')">Please enter a valid email address.</mat-error>
          </mat-form-field>
      </div>

      <div class="form-group">
        <mat-form-field class="form-field">
          <input matInput
                 placeholder="{{ 'registration.heardAboutUsHow' | translate}}"
                 type="text"
                 class="form-control"
                 name="heardAboutUsHow"
                 formControlName="heardAboutUsHow"
                 />
        </mat-form-field>
      </div>

      <display-environment not="vater">
        <div class="form-group">
          <mat-form-field class="form-field mat-form-field--no-underline">
            <input matInput style="display:none" formControlName="tos"/>
            <mat-checkbox color="primary" matInput formControlName="tos">
              <span> {{ 'registration.declaration.part1' | translate }} <a target="_blank" routerLink="/{{'link.terms' | translate}}">{{ 'registration.termsservice' | translate }}</a> {{ 'registration.declaration.part2' | translate }} <a target="_blank" routerLink="/{{'link.privacy-policy' | translate}}">{{ 'registration.privacypol' | translate }}</a> {{ 'registration.declaration.part3' | translate }}</span>
            </mat-checkbox>
            <mat-error>{{ 'registration.pleaseaccept' | translate }}</mat-error>
          </mat-form-field>
        </div>
      </display-environment>

      <div *ngIf="clientInviteSupervisor" class="supervisor-hint">
        {{ clientInviteSupervisor.displayName }} {{ 'registration.supervisior.hint' | translate }}.
      </div>

      <mat-card-actions align="end">
        <a mat-button [routerLink]="['/login']" class="btn btn-link">{{ 'registration.login' | translate }}</a>
        <button mat-raised-button type="button" color="primary" [disabled]="loading" (click)="submit()" class="btn btn-primary">{{ 'registration.registration' | translate }}</button>
      </mat-card-actions>
    </form>
  </mat-card>

  <mat-card *ngIf="state=='success' && !inviteData && !clientVerified">
    <img mat-card-image src="assets/verification.svg"/>
    <mat-card-content>
      {{ 'registration.regsuccessful1' | translate }}
      <br/>
      {{ 'registration.regsuccessful2' | translate }}
    </mat-card-content>
    <mat-card-actions align="end">
      <button type="button" mat-raised-button color="primary" *ngIf="!resending" (click)="resendEmail()">{{ 'registration.resend' | translate }}</button>
      <button  type="button" mat-raised-button disabled *ngIf="resending">{{ 'registration.sending' | translate }}</button>
    </mat-card-actions>
  </mat-card>

  <mat-card *ngIf="state=='success' && inviteData">
    <img mat-card-image src="assets/success.svg"/>
    <mat-card-content>
      {{'registration.inviteSuccessful.part1' | translate}}
      <a>{{inviteData.controller.controllerName}}</a>{{'registration.inviteSuccessful.part2' | translate}}
    </mat-card-content>
    <mat-card-actions align="end">
      <button mat-raised-button color="primary"
        type="button" 
        [routerLink]="['/login']"
        [queryParams]="{email: email}">
        {{'registration.loginAndStart' | translate}}
      </button>
    </mat-card-actions>
  </mat-card>

  <mat-card *ngIf="state=='success' && coSupervisionInvite && clientVerified">
    <img mat-card-image src="assets/success.svg"/>
    <mat-card-content>
      You have successfully joined {{coSupervisionInvite.profile.displayName}} as a Co-Supervisor.
      Login to start supervising your clients via ECOMPLY.io!
    </mat-card-content>
    <mat-card-actions align="end">
      <button type="button" mat-raised-button color="primary"
        [routerLink]="['/login']"
        [queryParams]="{email: email}">
        {{'registration.loginAndStart' | translate}}
      </button>
    </mat-card-actions>
  </mat-card>

  <mat-card *ngIf="state=='success' && clientVerified && clientInviteSupervisor">
    <img mat-card-image src="assets/success.svg"/>
    <mat-card-content>
      {{ 'registration.supervisedSuccess.part1' | translate }}
      <a>{{model.company}}</a>
      {{ 'registration.supervisedSuccess.part2' | translate }}
      <a>{{clientInviteSupervisor.displayName}}</a>
      {{ 'registration.supervisedSuccess.part3' | translate }}
    </mat-card-content>
    <mat-card-actions align="end">
      <button type="button" mat-raised-button color="primary"
        [routerLink]="['/login']"
        [queryParams]="{email: email}">
        {{'registration.loginAndStart' | translate}}
      </button>
    </mat-card-actions>
  </mat-card>
</div>
